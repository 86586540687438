/* globals window, setTimeout, clearTimeout */
import m from 'bacta'
import css from 'bss'
function debounce(f, ms=0){
	let id;
	return () => {
		clearTimeout(id)
		id = setTimeout(f, ms)
	}
}
function Responsive({
	attrs: {
		breakpoints={
			mobile:0
			,desktop:1000
			,tablet:700
			,hd:1800
		}
		,useDisplayNone=false
	}
}){

	const sorted_breakpoints =
		Object.entries(breakpoints).sort(
			([,a],[,b]) => a - b
		)
		.map( ([k]) => k )

	const selectors =
		sorted_breakpoints.reduce( (prev, k, i) => {
			const current = breakpoints[k]
			const next = breakpoints[sorted_breakpoints[i+1]]

			let def =
				prev
				.$nest(`&& .${k}`,
					css`
						${ useDisplayNone ? `display: none` : ``}
					`
					.$media(`(min-width: ${current}px)`, `
						${ useDisplayNone ? `display: grid` : ``}
					`)
				)
				.$nest(`&& .lt-${k}`,
					css`
						${ useDisplayNone ? `display: none` : ``}
					`
					.$media(`(max-width: ${current}px)`, `
						${ useDisplayNone ? `display: grid` : ``}
					`)
				)
				.$nest(`&& .${k} > *`, css`
						opacity: 0;
						transition: 0.2s;
					`
					.$media(`(min-width: ${current}px)`, `
						opacity: 1;
						transition: 0.5s;
					`)
				)
				.$nest(`&& .lt-${k} > *`,
					css`
						opacity: 0;
						transition: 0.2s;
					`
					.$media(`(max-width: ${current}px)`, `
						opacity: 1;
						transition: 0.5s;
					`)
				)

			if ( next ) {
				def =
				def
				.$nest(`&& .${k}-only`,
					css`
						${ useDisplayNone ? `display: none` : ``}
					`
					.$media(`(min-width: ${current}px) and (max-width: ${next}px)`, `
						${ useDisplayNone ? `display: grid` : ``}
					`)
				)
				.$nest(`&& .${k}-only > *`, css`
						opacity: 0;
						transition: 0.2s;
					`
					.$media(
						`(min-width: ${current}px) and (max-width: ${next}px)`, `
						opacity: 1;
						transition: 0.5s;
					`)
				)
			}

			return def
		}, css )

	const combinators =
		sorted_breakpoints.reduce( (p,k, i) => {

			const breakpoint = breakpoints[k]
			const next = breakpoints[sorted_breakpoints[i+1]]

			const render = (cond, f, k) => {
				const vtree = cond() && f()
				if( vtree && vtree.attrs ) {
					vtree.attrs.className += ' ' + k
					return vtree
				} else {
					return m('.'+k , vtree )
				}
			}

			p[k] = function(f){
				return render( () => width >= breakpoint,  f, k )
			}

			p['lt_'+k] = function(f){
				return render( () => width < breakpoint, f, 'lt-'+k )
			}

			if( next ) {
				p[k+'Only'] = function(f){
					return render( () => width >= breakpoint && width < next, f, k+'-only')
				}
			}

			return p
		}, {})

	let width = window.innerWidth
	let listener;

	const updateState = debounce(() => {
		width = window.innerWidth
		m.redraw()
	}, 500)

	window.addEventListener('resize', listener = () => updateState())
	function onremove(){
		window.removeEventListener('resize', listener)
	}

	function view({ children }){
		return m('.responsive'
			+ selectors
			,
			{ oncreate: updateState }
			, children.map( f => f(Object.assign({}, combinators, {width})) )
		)
	}
	return { view, onremove }
}
Responsive.legacyRenderProps = true
export default Responsive