/* globals window, clearInterval, setInterval*/
import m from 'bacta'
import css from 'bss'
import * as elements from '../components/elements'

function Main({
	attrs: {
		data
		,oauths
		,saving
	}
}){

	const settings = m.prop([])
	const selected = m.prop({})
	const connected = {}

	const oauthIndex = {
		Xero: {
			settingsView:
				(data, api) =>
					m(''+css`
							display: grid;
							grid-auto-flow: column;
						`
						,{}
						,data().map((d) =>
							m(''+css`
								display: grid;
								grid-auto-flow: row;
								justify-content: center;
								text-align: center;
								gap: 1em;
							`
								,m('', d.tenantName)
								,elements.checkbox(
									{
										onchange: m.withAttr(
											'checked'
											, (c) => {

												saving(true)

													return api.xero.xero_tenants_import({...d, imports: c })
													.then( () => oauthIndex.Xero.settingsFetch().then(settings) )
													.then( m.redraw )
													.then( () => saving(false) )
											}
										)
										,checked: d.imports
										,title: `Import data from your organization to ` + d.tenantName
										,style: {
											"justify-content": "center"
											,"text-align": "center"
											,"height": '1.5em'
										}
										,disabled: saving()
									}
								)
							)
						)
					)
				,settingsFetch: data && data.api.xero.xero_tenants_fetch
				,authsFetch: data && data.api.xero.xero_auths_fetch
		}
	}


	const authfetch =
		(k) => {
			const o = oauthIndex[k]
			return o.authsFetch()
			.then((res) => {
				const og = oauths.find((o) => o.name == k)
				connected[o.name] = Number(res.auth_count)
				if( connected[o.name] ){
					clearInterval(og.authpoll)
					return o.settingsFetch().then(settings).then( m.redraw ).then(() => connected[o.name])
				} else {
					return connected[o.name]
				}
			})
		}


	if( data ) {
		data.initiateContractFetch
		.map((s) => {
			if( s.ochange ){
				return Promise.all(Object.keys(oauthIndex).map( authfetch ))
			}
			else {
				return s.ochange
			}
		})
	}

	function view({
		attrs
	}){
			const {
				data
				,oauths
			} = attrs

			return m(''
				+ css`
					display: grid;
					--background-color: rgba(0,0,0,0.1);
					--justify-content: start;
					--max-width: max-content;
					transition: opacity 0.2s;
					border-radius: 4px
					user-select: none;
					position: relative;
					transform: translateY(1px);
					padding-bottom: 3em;
					z-index: 1;
				`
				.$hover(
					`opacity: 0.8;`
				)
				,{
					onremove(){
						oauths.map((k) => k.authpoll ? clearInterval(k.authpoll) : null )
					}
				}
				,m('h1' + css`
						justify-content: center;
						text-align: center;
						gap: 4em;
					`
					, 'Connect to'
				)
				,m('' + css`
						display: grid;
						grid-auto-flow: column;
						justify-content: center;
						padding-bottom: 1em;
					`
					,oauths.map((o) =>
						m('button'
							+ css(`
								background-color: rgba(0,0,0,0);
								border: none;
								outline: none;
								padding: 6px 12px
								max-width: 6em;
								max-height: 2.4em;
								backgroundColor: transparent;
								border: solid 1px #3380c2;
								position: relative;
								color: #434aa3;
								textDecoration: underline;
								justify-content: center;
								text-align: center;
								gap: 3em;
							` + (o.name == selected().name ? 'border-width: 0.2rem;' : '')
							)
							, {
								...attrs
								, async onclick () {

									selected(o)

									if( !connected[o.name] ){
										const res = await authfetch(o.name)
										if( !res ){
											const {state} = await data.api.request('POST', '/integrations/xero/authorize')
											window.open(
												'/api/integrations/xero/authorize?' + new window.URLSearchParams({ state })
											)
											clearInterval(o.authpoll)
											o.authpoll = setInterval(() => authfetch(o.name), 5000)
										}
									} else {
										settings(await oauthIndex.Xero.settingsFetch())
									}
									m.redraw()
									return null
							}
						}
						,o.name
					)
				)
				)
				,selected && selected().name
					? oauthIndex[selected().name].settingsView(settings, data.api)
					: m('','')
			)
	}

	return {
		view
	}
}
export default Main