/* globals requestAnimationFrame, window, document, setTimeout */
import m from 'bacta'
import css from 'bss'
import { prop as stream } from '../../../stream'

m.stream = stream

let dragging = false

window.addEventListener('mousedown', () => {
	dragging = true
})

window.addEventListener('mouseup', () => {
	dragging = false
})

function __selector(){
	return ''
}

function __unformat(x){
	if( typeof x != 'string' ) {
		return NaN
	} else if ( x.match(/^\s+$/)) {
		return NaN
	}
	return Number(x)
}

function __formatNatural(x){
	return x
}

function __formatDecimal(x){
	return x
}

function __validate(value, { min, max, required }={}){
	if( value == null ) {
		if( required ) {
			return 'required'
		}
		return null
	} else if( value < min ) {
		return 'must be greater than ' + min
	} else if( value > max ) {
		return 'must be less than ' + max
	} else {
		return null
	}
}

function Main({
	attrs: {
		prop
		, unformat:theirUnformat=__unformat
		, formatNatural: theirFormatNatural=__formatNatural
		, formatDecimal: theirFormatDecimal=__formatDecimal
		, selector: theirSelector=__selector
		, toFixed
		, validate=__validate
	}
}){

	let prevPropValue;
	let natural, decimal, rootEl;

	let refreshInputs = ({ preferSkipSelectionChange=true }={}) => {
		const proposal = theirFormatNatural(~~prop())
		updateTargetValue(natural, proposal, { preferSkipSelectionChange })

		if(decimal) {
			const proposal = theirFormatDecimal(prop().toFixed(toFixed)
				.slice(-toFixed)).padEnd(toFixed, '0')

			if ( Number('0.'+decimal.value) != Number('0.'+proposal) ) {
				updateTargetValue(decimal, proposal, { preferSkipSelectionChange })
			}
		}
	}

	let diffValue = () => {
		if( prevPropValue != prop() ) {
			refreshInputs()
		}
		prevPropValue = prop()
	}

	function updateSelection(target, start, end){
		target.setSelectionRange(start, end)
	}

	function updateTargetValue(
		target
		, value
		,
		{ visitor=x => x
		, start=target.selectionStart
		, end=target.selectionEnd
		, preferSkipSelectionChange=false
		}={}
	){
		requestAnimationFrame(() => {
			value = value + ''
			const old = target.value
			if( old == value ) {
				return;
			}
			if( old.length != value.length ) {
				const delta = old.length - value.length
				start -= delta
				end -= delta
			}

			if( value.length >= old.length ) {
				// we were only guarding against backspace
				preferSkipSelectionChange = false
			}
			const { start: a, end: b } = visitor({ start, end })
			target.value = value
			if( !preferSkipSelectionChange ) {
				updateSelection(target, a, b)
			}
			return;
		})
		return value
	}

	let selectableMode = true

	function oncopy(e){

		// For now, when they copy, we always give them
		// the prop value, instead of whatever they actually selected
		// it's super hard to extract the exact selection text from the selection
		// API across multiple selected elements
		// or at least I can't figure it out at 10:30PM 😂

		if(
			selectableMode
			|| e.target.selectionStart == 0
			&& e.target.selectionEnd == e.target.value.length
		) {
			e.preventDefault()
			// e.target.value = natural.value + '.' + decimal.value
			// e.target.selectionEnd = e.target.value.length
			e.clipboardData.setData('text/plain', prop());
		}
	}
	let lastPaste;
	let history = []
	const isPropValid = x => {
		return typeof x == 'number' && !Number.isNaN(x)
	}

	function selectionModeScenario({ scenarioA, scenarioB, scenarioBAutoFocus }){

		if( scenarioA ) {
			selectableMode = true
		} else if ( scenarioB ) {
			selectableMode = false
			if( scenarioBAutoFocus ) {
				natural.focus()
			}
		}

		if( scenarioA || scenarioB ) {
			// hide the fake elements
			// unhide the real elements
			Array.from(
				rootEl.querySelectorAll('.fake-natural,.fake-decimal,.natural,.decimal')
			)
			.forEach(
				el => {
					el.classList.toggle('number-hide')
				}
			)
		}

		// todo-james just use toggle hide
		if([decimal, natural].includes(document.activeElement)){
			document.activeElement.selectionStart = 0
			document.activeElement.selectionEnd = document.activeElement.value.length
		}
	}

	function manageSelectionModeFromFocusEvent(e){

		// Scenario A =
		// if we're focusing out, and the activeElement isn't decimal or natural
		// then selectableMode should be on
		// if its not already on, toggle everything, if it is, do nothing.

		// Scenario B =
		// if we're focusing in, selectable mode should always be off
		// if the activeELement is the rootEl, then focus natural
		// otherwise accept the existing focus element

		const scenarioA =
			e.type == 'focusout'
			&& ![natural,decimal].includes(e.relatedTarget)
			&& !selectableMode

		const scenarioB =
			e.type == 'focusin'
			&& selectableMode
			&& !dragging

		const scenarioBAutoFocus =
			scenarioB && document.activeElement == rootEl

		selectionModeScenario({
			scenarioA, scenarioB, scenarioBAutoFocus
		})

		// todo-james just use toggle hide
		if([decimal, natural].includes(document.activeElement)){
			document.activeElement.selectionStart = 0
			document.activeElement.selectionEnd = document.activeElement.value.length
		}
	}

	function view({
		attrs: {
			toFixed=0
			, prefix= () => ''
			, suffix= () => ''
			, min=-Infinity
			, max=+Infinity
			, required=false
			, title=null
			, name=null
			, id=null
			, attrs = {}
		}
	}){
		const prefixed = prefix(prop())
		const suffixed = suffix(prop())

		let lastValidValue = () => prop()

		return m('.number-input'
			+  theirSelector(prop())

			+ (selectableMode ? '.selectable' : '.editable')
			// just while debugging read only mode
			+ (
				toFixed > 0
				? css`
					grid-template-columns: var(--prefix) 1fr 0.5em var(--fixed) var(--suffix);
				`
				: css`
					grid-template-columns: var(--prefix) 1fr var(--suffix);
					gap: var(--gap, ${prefixed || suffixed ? '0.3em' : '0em'});
				`
			)
			+ css`
				--fixed: ${toFixed*0.92}ch;
				--prefix: ${prefixed.length *0.6}ch;
				--suffix: ${suffixed.length *0.6}ch;
			`
			+ css`
				display: grid;
				box-sizing: border-box;
				border-bottom: solid 1px rgba(0,0,0,0.1);
				grid-template-rows: 1.5em;
				align-items: center;
			`
			// .$nest('.natural::selection,.decimal::selection', `
			// 	background: var(--selection-bg-color, rgb(51 144 255 / 0.9));
			// 	color: var(--selection-color, white);
			// `)
			.$nest('.group', `
				display: grid;
			`)
			.$nest('.number-hide,.number-hide *', `
				background-color: yellow;
				max-height: 0;
				min-height: 0;
				padding: 0;
				margin: 0;
				overflow: hidden;
				font-size: 0em;
			`)
			.$nest('&& .selected', `
				background-color: hsl(228deg 100% 97% / 90%);
				color: black;
			`)
			.$nest('*', `
				box-sizing: border-box;
				padding: 0em;
				margin: 0em;
			`)
			.$nest('.point', `
				text-align: center;
				display: grid;
				justify-content: center;
				align-items: end;
				max-height: 0.75em;
			`)

			.$nest('.natural,.decimal,.fake-natural,.fake-decimal', `
				font-feature-settings: "tnum";
				text-align: right;
				max-width: 100%;
				min-width: 0em;
				border: none;
				letter-spacing: 1px;
				height: 100%;
				line-height: calc(1.5); // the calc is to stop bss from auto pixeling
			`)
			.$nest('input:focus', `
				outline: none;
			`)
			.$nest('.decimal,.fake-decimal', `
				text-align: left;
				font-size: 0.8em;
				height: 1.5em;
			`)
			.$nest('.fake-decimal', `
				padding-bottom: 0em;
			`)
			// .$nest('&& .prefix', `
			// 	text-align: right;
			// `)
			.$nest('.prefix,.suffix', `
				font-size: 0.8em;
				color: rgba(0,0,0,0.5);
				whitespace: pre-wrap;
			`)
			, Object.assign(
				{ onupdate: diffValue
				, oncreate({ dom }){
					rootEl = dom
					setTimeout(diffValue)
					setTimeout( () => {
						m.redraw()
					}, 100)
				}
				, title
				, onmouseup(){
					if(
						document.getSelection().type != 'Range'
						&& document.activeElement != natural
					) {
						selectionModeScenario({ scenarioB: true, scenarioBAutoFocus: true })
					}
				}
				, onfocusin(e){
					manageSelectionModeFromFocusEvent(e)
				}
				,oncopy
				,onfocusout(e){
					manageSelectionModeFromFocusEvent(e)
				}
				,tabIndex: selectableMode ? 0 : -1
				,onkeydown(e){
					if(e.target == natural){
						if( e.key == 'ArrowRight' && natural.selectionStart == natural.value.length ) {
							decimal.focus()
							requestAnimationFrame(() => {
								updateSelection(decimal, 0, 0)
							})
						} else if (e.key =='ArrowUp' ) {
							if( prop() + 1 <= max ) {
								prop( prevPropValue = prop() + 1 )
								refreshInputs({ preferSkipSelectionChange: false })
							}
						} else if (e.key =='ArrowDown' ) {
							if( prop() -1 >= min ) {
								prop( prevPropValue = prop() - 1 )
								refreshInputs({ preferSkipSelectionChange: false })
							}
						}
					} else if (e.target == decimal) {
						if( e.key == 'ArrowLeft' && decimal.selectionEnd == 0 ) {
							natural.focus()
							requestAnimationFrame(() => {
								updateSelection(natural, natural.value.length+1, natural.value.length+1)
							})
						} else if ( e.key == 'Backspace' && decimal.selectionEnd == 0  ) {

							if( decimal.value ) {
								// trying to delete decimal point
								const out =
									theirUnformat(
										(natural.value == '0' ? '' : natural.value)
										+ (decimal.value || '')
											.padEnd(toFixed, '0')
											.slice(0, toFixed)
									)

								if( isPropValid(out) ) {
									prop(out)
									// skip diff
									prevPropValue = out
									// manually update values
									// now we won't have 2 different cursor updates
									refreshInputs({ preferSkipSelectionChange: false })

									natural.setCustomValidity(
										validate(prop(), {min, max, required})
										|| ''
									)

								}
							}

							const start = natural.value.length
							const end = natural.value.length
							requestAnimationFrame( () => {
								natural.focus()

								// todo-james I need this to happen after diff
								updateSelection(natural, start, end)
							})
						} else if (e.key =='ArrowUp' ) {
							if( prop() + 0.1 <= max ) {
								prop( prevPropValue = prop() + 0.1 )
								refreshInputs({ preferSkipSelectionChange: false })
								natural.setCustomValidity(
									validate(prop(), {min, max, required})
									|| ''
								)
							}

						} else if (e.key =='ArrowDown' ) {

							if( prop() - 0.1 >= min ) {
								prop( prevPropValue = prop() - 0.1 )
								refreshInputs({ preferSkipSelectionChange: false })

								natural.setCustomValidity(
									validate(prop(), {min, max, required})
									|| ''
								)
							}
						}
					}
				}
				,onpaste(e){
					lastPaste = e.clipboardData.getData('text/plain')
				}
				}
				, attrs
			)
			,m('.prefix',prefixed)
			,m('.group'
				,m('span.fake-natural', natural && natural.value)
				,m('input.natural.number-hide', {
					tabIndex: -1
					,name
					,id
					,oncopy
					,inputmode: 'decimal'
					,oninput(e){
						let data = e.data || ''
						const inputType = e.inputType || ''
						let _update = false
						let negate = 1 / prop() < 0
						let scheduledValue = e.target.value

						if( scheduledValue == '.' ) {
							// todo-james hopefully updating the value doesn't lose decimal focus
							scheduledValue =
								updateTargetValue(e.target, theirFormatNatural(~~prop()))
							decimal.focus()
							return;
						}

						if( inputType.includes('Paste') ){
						data = lastPaste
						}
						if( toFixed && data && data.includes('.') ) {
							const original = scheduledValue
							const i = scheduledValue.lastIndexOf('.')

							if( e.inputType == 'insertText' ) {
								decimal.focus()
							}

							{
								let newDecimal =
									((original.slice(i+1) + decimal.value).match(/\d+/g)||[])
										.join('')
										.slice(0, toFixed)

								updateTargetValue(
									decimal, newDecimal, { start: 0, end: 0 }
								)
							}
							scheduledValue = updateTargetValue(e.target, original.slice(0, i))
						}
						if(
								inputType == 'deleteContentBackward'
								|| inputType == 'deleteContentForward'
								|| inputType == 'deleteByCut'
						) {
							// allow deletion
							_update = false
						} else if ( data == '-' ) {
							if( e.target.value == '-' ) {
								prop(-0)
								prevPropValue = -0
								natural.setCustomValidity(
									validate(prop(), {min, max, required})
									|| ''
								)
							}
							scheduledValue = updateTargetValue(e.target, scheduledValue.replace('-', ''), {
								// visitor: ({ start, end }) => ({ start: start -1, end: end - 1 })
							})
							negate = true
						} else if ( data == '+' ) {
							if( e.target.value == '+' ) {
								prop(0)
								prevPropValue = 0
								natural.setCustomValidity(
									validate(prop(), {min, max, required})
									|| ''
								)
							}
							scheduledValue = updateTargetValue(e.target, scheduledValue.replace('+', ''), {
								// visitor: ({ start, end }) => ({ start: start -1, end: end - 1 })
							})
							negate = false
						} else if( !data.match(/^-?\s*\d+$/g) ) {
							_update = true
						}

						let unformatted = theirUnformat(
							scheduledValue
							+ (toFixed > 0 ? '.' + decimal.value : '' )
						)
						* ( negate ? -1 : 1 )

						if( isPropValid(unformatted) ){
							history.push(unformatted)
							prop(unformatted)

							if(
								inputType == 'deleteContentBackward'
								|| inputType == 'deleteContentForward'
								|| inputType == 'deleteByCut'
							) {
								prevPropValue = prop()
							}

							natural.setCustomValidity(
								validate(prop(), {min, max, required})
								|| ''
							)
						}

						if( _update ) {
							let out = theirFormatNatural(Math.floor(lastValidValue()))

							scheduledValue = updateTargetValue(e.target, out)

						}
					}
					,onblur(e){
						const out = theirFormatNatural(~~lastValidValue())
						e.target.value = out
					}
					,oncreate({ dom: $ }){
						natural = $
						natural.setCustomValidity(
							validate(prop(), {min, max, required})
							|| ''
						)
					}
				})
			)
			, toFixed > 0
				&& m('.point'
					, m(''
							+ css`
								width: 2px;
								height: 2px;
								border-radius: 100%;
								background-color: rgba(0,0,0,0.5);
								user-select: none;
							`
						)
				)
			, toFixed > 0 && m('.group'
				, m('span.fake-decimal'
					, decimal && (decimal.value || '').padEnd(toFixed, '0')
				)
				, m('input.decimal.number-hide', {
					tabIndex: -1
					,placeholder: Array(toFixed).fill(null).map( () => 0).join('')
					,inputmode: 'decimal'
					,oncreate({ dom: $ }){
						decimal = $
					}
					,oninput(e){
						let value = e.target.value
						let scheduledValue = value

						if( value.length > toFixed ) {
							value = value.slice(0, toFixed)
						}

						if( value == '' ) {
							return;
						} else if( !value.match(/^\d+$/g) ) {
							value = theirFormatDecimal(value.match(/\d+/g) || []).join('')
						}

						let proposal = value

						proposal = proposal.slice(0, toFixed)

						scheduledValue = updateTargetValue(
							e.target
							, proposal
						)

						let unformatted = theirUnformat(
							natural.value + '.' + scheduledValue
						)

						if( isPropValid(unformatted) ){
							history.push(unformatted)
							prop(unformatted)
							natural.setCustomValidity(
								validate(prop(), {min, max, required})
								|| ''
							)
						}
					}
					,onblur(e){
						let out =
						theirFormatDecimal(e.target.value.match(/\d+/g) || []).join('')
							.padEnd(toFixed, '0')

						updateTargetValue(e.target, out)
					}
					,oncopy
				})
			)
			, m('.suffix', suffixed)
		)
	}

	function selectionchange(){
		const activeElement = document.activeElement
		if(decimal && natural){
			if( ![decimal, natural].includes(activeElement) ){
				decimal.classList.remove('selected')
				natural.classList.remove('selected')
			} else {
				const el = activeElement
				if(
					el.selectionStart == 0
					&& el.selectionEnd == el.value.length
				) {
					natural.classList.add('selected')
					decimal.classList.add('selected')
				} else {
					natural.classList.remove('selected')
					decimal.classList.remove('selected')
				}
			}
		}
	}

	return {
		view
		,oncreate(){
			document.addEventListener('selectionchange', selectionchange)
		}
		,onremove(){
			document.removeEventListener('selectionchange', selectionchange)
		}
	}
}

function MoneyInput({ attrs }){

	function view(){
		return m(Main, Object.assign({
			toFixed: 2
			, prefix: x => (1 / x < 0 ? '-' : ' ') + ' $'
			, formatNatural: x =>
				Math.abs(x).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
			, unformat(x){
				return __unformat(x.replace(/\,/g, ''))
			}
			, selector(x){
				return (
					x < 0
						? '.negative'
					: x > 0
						? '.positive'
						: ''
				)
			}
		}, attrs))
	}

	return {
		view
	}
}

export default {
	component: Main, MoneyInput, unformat: __unformat,
}
