/* global setTimeout */

import m from 'bacta'
import * as elements from '../components/elements'
import {
	filterView
	,ListProcessing
} from '../components/filter'

import moment from 'moment'
import humanList from '../utils/human_list'
import * as R from 'ramda'
import {identical} from '../utils/harth-identical'
import NumberInput from '../components/number'

import scrollableTable from '../components/scrollableTable'
import autocomplete from './autocomplete'
import Permissions from '../models/permissions'
import Promise from 'bluebird'
import { prop } from '../../../stream'
/*
	Model, View, Intent

	Model: Any data storage.  Both the current state of the user interface, and
		data that will be persisted to a database layer.
		It also includes any functions for selecting or manipulating that data.

	Intent: Any functions that represent the user performing an action.  These functions represent
		what the user wants to do.  Whether it be creating a new invite, logging in.
		That's all representing the users intent.

	View: The view is a representation of the Model and provides affordances for the user
		to express their intent.  These affordances are input elements such as
		dropdowns, text inputs, checkboxes etc...

	Controller: The Controller binds the Model, View and Intent together.  It do some
		setup code and faciliate communication between the three other layers.
*/

function InterruptionAffected({
	interruptions_affected_entity_name
	,interruptions_affected_entity_id
	,interruptions_id
	,interruptions_affected_id
	,ParentRecognitionID
	,ParentRecognition
	,created
}){
	return {
		interruptions_affected_entity_name:
			m.prop(interruptions_affected_entity_name)
		,interruptions_affected_entity_id:
			m.prop(interruptions_affected_entity_id)
		,interruptions_affected_id:
			m.prop(interruptions_affected_id)
		,interruptions_id:
			m.prop(interruptions_id)
		,ParentRecognitionID: ParentRecognitionID
		,ParentRecognition: ParentRecognition
		,created
	}
}

/*
	Intent: Functions that help the user do what they want to do...
*/
function Intent(model){

	function removeModelAffected(entity_id){
		const index =
			R.findIndex(function(chr) {
				return chr.interruptions_affected_entity_id() == entity_id
			}, model.form().interruption.interruptions_affected)

		if(index > -1){
		!model.form().interruption.interruptions_affected[index]
			.interruptions_affected_id()
				? null
				: model.deleteAffected.push(
					model.form().interruption.interruptions_affected[index]
						.interruptions_affected_id()
				)
		}

		index > -1
			? model.form().interruption.interruptions_affected
				.splice(index, 1)
			: null
	}

	function deleteInterruptionsAffected(entity_id, makeAPICalls){
		let requests = []

		makeAPICalls
			? requests = model.deleteAffected.map((t) => model.api.interruptionsAffected.remove.one(t))
			: removeModelAffected(entity_id)

		return makeAPICalls
			? Promise.all(requests)
			: true
	}

	let sendInterruptions = function(){
		if (identical(model.vm().selected.interruption, model.form().interruption)){
			return Promise.resolve([])
				.then(m.redraw)
		} else {
			return deleteInterruptionsAffected(null, true)
			.then(function(){
				model.deleteAffected = []
				return model.api.interruptions.patch.many(
					[model.form().interruption]
					,model.data.schedule_id()
				)
					.then( model.interruptions )
					.then(function(){
						let updated_res = model.interruptions().find((i) => i.interruptions_name() == model.form().interruption.interruptions_name() )
						model.vm().selected.interruption = updated_res
						model.cloningInterruptions(false)
						model.viewtype = EditInterruption
						return model.edit(true)
					})
					.then(updateInterruptionsList)
					.then(m.redraw)
			})
		}

	}

    let deleteInterruptions = function(){
        let interruptions_id = model.vm().selected.interruption.interruptions_id()
        return Promise.resolve(
            interruptions_id && model.api.interruptions.remove.one(interruptions_id)
        )
        .then(model.deleteInterruptions)
		.then(m.redraw)
    }


	function updateInterruptionsList(){
		const partitionedList =
			R.partition(
				(a) =>
					!model.form().interruption.interruptions_affected
					.find((b) =>
						b.interruptions_affected_entity_id()
						== a.CommonRecognition
					)
				, model.resourceProjectTotal()
			)

		model.resourceProjectList(
			R.flatten(partitionedList[0])
		)

		model.resourceProjectInterrupted(
			R.flatten(partitionedList[1])
		)

		return true
	}


	function fetchProjects(){
		model.loading(true)
		return model.data.fetchProjectsBySchedule({
			schedule_id: model.data.schedule_id()
			,completedProjects:model.completedProjects()
			,depth: 2
			, props: {
				projects: [
					'project_id'
					,'project_name'
				]
				,disciplines: [
					'discipline_id'
					,'project_id'
					,'discipline_name'
				]
			}
		})
			.then((res) => {
				model.projects(res)
				model.loading(false)
			})
			.then( m.redraw )

	}

	model.data.currentRoute('Nothing')
	prop.merge(
		[
			model.data.initiateContractFetch
		]
	).map(function([i]){
		model.loading(true)
		const s = i.schedule_id
		const schange = i.schange || !model.data.routeInitialized()['interruptions']
		const ochange = i.ochange || !model.data.routeInitialized()['interruptions']

		if ( ochange || schange){
			model.vm().selected.interruption = {}
			model.viewtype = ListInterruption
			model.deleteAffected = []
			model.deleteStateProp(false)
		}

		return Promise.all([
			model.data.fetchInterruptions({schedule_id: s})
				.then(model.interruptions)
		])
			.then(function(){
				model.metaDataLoading(true)

				!schange
				? null
				: model.data.fetchProjectsBySchedule({
					schedule_id: model.data.schedule_id()
					,completedProjects:model.completedProjects()
					,depth: 2
					, props: {
						projects: [
							'project_id'
							,'project_name'
						]
						,disciplines: [
							'discipline_id'
							,'project_id'
							,'discipline_name'
						]
					}
				})
					.then(model.projects)

				!ochange
				? null
				: model.data.fetchResource({
					depth: 2
					, props: {
						resources: [
							'contractor_id'
							,'contractor_name'
							,'user_id'
						]
						,teams: [
							'crew_id'
							,'crew_name'
							,'contractor_id'
							,'user_id'
						]
					}
				})
					.then(model.resources)

				i.schange = false
				i.ochange = false

				return model.loading(false)
			})
			.then( m.redraw )
	})

	return {
		sendInterruptions
		, deleteInterruptions
		, updateInterruptionsList
		, deleteInterruptionsAffected
		, fetchProjects
	}
}



/*
	Model: Data storage and functions that interact with that data.

	The vm represents the view model, any thing to do with the state of the UI
	that doesn't map to a database record is stored on the view model.

	It could be whether or not a button is disabled due to a background process.
	Or it could be storing how many items are selected in order to render a tally.

*/
function Model(data){

	const loading = m.prop(false)
	const interruptions = prop([])
	const projects = prop([])
	const resources = prop([])
	const form = m.prop({
			interruption: {}
		}
	)
	const currentSchedule = data.scoped(null)
	const resourceProjectList = prop([])
	const resourceProject =
		prop.merge(
			[
				projects
				,resources
			]
		).map(([p,r]) => [].concat(p,r) )

	const metaDataLoading = prop(false)
	const resourceProjectTotal =
		prop.merge(
			[
				projects
				,resources
			]
		).map(([p,r]) => {

			const oList =
				R.flatten(
					p.map(
						(p) => R.flatten(p.disciplines)
					)
				)
				.concat(
					R.flatten(
						r.map(
							(r) => R.flatten(r.crews)
						)
					)
				)
			metaDataLoading(false)

			return oList
		})



	const resourceProjectInterrupted = prop([])
	const editPermissions = m.prop(false)
	const deleteAffected = []
	const completedProjects = m.prop('inprogress')

	const vm = m.prop({
		pending: m.prop(false)
		,selected: {
			interruption: {}
		}
	})

	const missingPermissions =
		data.initiateContractFetch.map(() =>
			humanList(
				[
					'Project'
					,'Resource'
				]
					.map((p)=>
						data['read'+p+'Permissions']()
							? ''
							: p
					)
					.filter((p) => p)
			)
		)

	const errors = data.scoped({})
	const permissions = data.permissions

	// eslint-disable-next-line no-unused-vars
	const forceRedraw =
		errors
		.map(function(){
			return setTimeout(function(){
				m.redraw()
			}, 0)
		})

	const deleteStateProp = m.prop(false)

	let cloningInterruptions = function(clonemarker){
		let clone = resetForm().interruption
		clone.interruptions_name(
			clonemarker == false
			? vm().selected.interruption.interruptions_name()
			: vm().selected.interruption.interruptions_name() + " (Clone)"
		)
		clone.interruptions_weather( vm().selected.interruption.interruptions_weather() )
		clone.interruptions_start_date( vm().selected.interruption.interruptions_start_date() )
		clone.interruptions_duration( vm().selected.interruption.interruptions_duration() )
		clone.interruptions_end_date( vm().selected.interruption.interruptions_end_date() )
		clone.interruptions_repetition_mode( vm().selected.interruption.interruptions_repetition_mode() )
		clone.interruptions_id( clonemarker == false ? vm().selected.interruption.interruptions_id() : ""  )
		clone.interruptions_affected =
			vm().selected.interruption.interruptions_affected
			.map((iaffected) => {
				return Object.assign(
					{}
					, iaffected
					,{
						interruptions_affected_id:
							m.prop(
								clonemarker == false
								? iaffected.interruptions_affected_id()
								: ""
							)
						,interruptions_id: m.prop(
							clonemarker == false ? iaffected.interruptions_id() : "")
						,interruptions_affected_entity_name:
							m.prop(iaffected.interruptions_affected_entity_name())
						,interruptions_affected_entity_id:
							m.prop(iaffected.interruptions_affected_entity_id())
						,created: clonemarker
					}
				)
			})
		form().interruption = clone
		return clone
	}


	let deleteInterruptions = function(){
		let index = R.findIndex(
            function(chr) { return chr.interruptions_id() == vm().selected.interruption.interruptions_id()},
            interruptions()
        )
		interruptions().splice(index, 1)
		vm().selected.interruption = {}
	}


	function resetForm(){
		let form = {
			interruption : {
				interruptions_id: m.prop()
				,interruptions_weather: m.prop(false)
				,interruptions_name: m.prop(null)
				,interruptions_start_date: m.prop(new Date().setHours(0,0,0,0))
				,interruptions_duration: m.prop(null)
				,interruptions_end_date: m.prop(new Date().setHours(0,0,0,0))
				,interruptions_repetition_mode: m.prop('Once')
				,interruptions_affected: []
			}
		}
		return form
	}



	let check_Inames = function(){
		let state = false
		interruptions().every((interruption) => {
			if (interruption.interruptions_name() == null
				|| interruption.interruptions_name() == ""
				|| R.countBy(function(chr) { return chr.interruptions_name() == interruption.interruptions_name() }, interruptions() ).true > 1
				 ){ state = true}
			return state == false
		})
		return state
	}

	let checked_crews = function(resource){
		let state = false
		resource.crews.every((crew) => {
			state = R.findIndex(
                function(chr) {  return chr.interruptions_affected_entity_id()  == crew.crew_id() },
                form().interruption.interruptions_affected
            ) >= 0 ? true : false
			return state == false
		})
		return state
	}


	let DeleteDisabled = function(){
		return	interruptions().length > 0
			?  				!vm().selected.interruption.interruptions_name ? true : false
			 : true
	}

	function SaveDisabled(returnBoolean){
		const count = R.countBy(
			chr => chr.interruptions_name()
			,interruptions().filter((r) =>
				r.interruptions_id() != form().interruption.interruptions_id()
			).concat(form().interruption)
		)
		const errorArray = [
			(d) => !d.interruptions_name() ? `Interruption Name must not be empty ` : null
			,(d) => !d.interruptions_duration() ? `Duration must not be empty ` : null
			,(d) => !d.interruptions_start_date() ? `Start Date must not be empty ` : null
			,(d) =>	count[d.interruptions_name()] > 1 ? `Interruption Names must not be duplicated` : null
		]

		return elements.errorAlert(
			`To save, `
			,' and '
			,''
			,[form().interruption]
			,errorArray
			,returnBoolean
			,false
			,'warning'
			,null
			,Object.keys(errors())
				.map((a) =>
					errors()[a]
						? a + ' ' + errors()[a]
						: ''
				)
				.filter((a) => a)
		)
	}

	let WholePeriod = function(){
		let count = 1
		let nextdate = new Date(form().interruption.interruptions_start_date()).setHours(0,0,0,0)
		let lastdate = new Date(form().interruption.interruptions_end_date()).setHours(0,0,0,0)
		while ( nextdate <= lastdate && nextdate != lastdate){
			count = count + 1
			nextdate = nextdate + 24 * 60 * 60 * 1000
		}
		form().interruption.interruptions_duration(count)
		return form().interruption.interruptions_duration()
	}

	let edit = m.prop(false)

	let viewtype = ListInterruption

	permissions.map(function(p){

		editPermissions(
			Permissions.hasWrite(p, 'interruption_management')
		)
		m.redraw()
		return null
	})

	let filterFunction = {
		changeNames: [
			{ugly_name: 'interruptions_weather', better_name: "Weather Interruption" }
			,{ugly_name: 'interruptions_name', better_name: "Interruption Name" }
			,{ugly_name: 'interruptions_affected', better_name: "Amount of Affected Projects/Resources/Teams" }
			,{ugly_name: 'interruptions_affected_entity_name', better_name: "Affected Projects/Resources/Teams" }
		]
		,uniqueArrays: []
		,template: {
			interruptions_name: ""
			,interruptions_weather: true
			,interruptions_start_date: "date"
			,interruptions_duration: 0
			,interruptions_end_date: "date"
			,interruptions_repetition_mode: ""
			,interruptions_affected: [{
				interruptions_affected_entity_name: ""
			}]
		},
	}

	let getfilters = function(button, marker){
		let datainput = marker == "Interruption"
			? filterFunction.interruptionFilters
			: filterFunction.affectedFilters
		if (datainput == null){
			filterFunction.interruptionFilters = {
				filters: m.prop([])
				,operationalFilters: m.prop([])
				,changeNames: filterFunction.changeNames
				,uniqueArrays: filterFunction.uniqueArrays
				,template: filterFunction.template
				,disabled: () => interruptions().length == 0
			}
			filterFunction.affectedFilters = {
				filters: m.prop([])
				,operationalFilters: m.prop([])
				,changeNames: filterFunction.changeNames
				,uniqueArrays: filterFunction.uniqueArrays
				,template: filterFunction.template.interruptions_affected[0]
				,disabled: () => form().interruption.interruptions_affected.length == 0
			}
			datainput = marker == "Interruption" ? filterFunction.interruptionFilters : filterFunction.affectedFilters
		}
		let gotfilters = datainput == null ? null : datainput.operationalFilters(
			filterView( datainput.template, datainput.filters(), datainput.changeNames, datainput.uniqueArrays, datainput.disabled, marker, data.scoped )
		)
		datainput != null ? datainput.filters( gotfilters.filters ) : null
		return gotfilters.generatedElements
	}

	return {
		data
		,interruptions
		,projects
		,resources
		,vm
		,cloningInterruptions
		,DeleteDisabled
		,edit
		,WholePeriod
		,form
		,deleteInterruptions
		,resetForm
		,check_Inames
		,checked_crews
		,SaveDisabled
		,viewtype
		,request: data.request
		,editPermissions
		,permissions
		,getfilters
		,filterFunction
        ,loading
		,resourceProject
		,resourceProjectList
		,resourceProjectTotal
		,resourceProjectInterrupted
		,deleteAffected
		,deleteStateProp
		,api: data.api
		,errors
		,missingPermissions
		,completedProjects
		,metaDataLoading
		,currentSchedule
	}
}

/*
	The controller is the glue that sticks the model and intent together.
	It exposes the model and intent to the view by returning them.

	The controller "controls" how each layer interacts.
*/

function Main({ attrs: { data } }){

	let model = Model(data)
	let intent = Intent(model)

	const ctrl = { model, intent }
	function view(){
		return m('div', ctrl.model.viewtype(ctrl))
	}

	return { view }
}



function ListInterruption(ctrl){
	return m('div.harth.mb6',[
		m('h4','Create, edit or delete an interrupt that will affect the schedule')
		,m('hr')
		,elements.list([
			m("button.btn.", {
				disabled: !!ctrl.model.check_Inames()
				,onclick: () => {
					ctrl.model.viewtype = EditInterruption
					ctrl.model.form().interruption = ctrl.model.resetForm().interruption
					ctrl.intent.updateInterruptionsList()
					ctrl.model.edit(false)
				}
				,title: "Create an event or some recurring conditions that interrupts progress"
			},"Create")
			,elements.confirmDelete(ctrl.intent.deleteInterruptions, ctrl.model.vm().pending, ctrl.model.DeleteDisabled, ctrl.model.deleteStateProp )
			// ,elements.action(
			// 	ctrl.model.completedProjects() == "completed"
			// 		? 'Current Projects'
			// 		: 'Completed Projects'
			// 	,() => {
			// 		ctrl.model.completedProjects() == "completed"
			// 			? ctrl.model.completedProjects("inprogress")
			// 			: ctrl.model.completedProjects("completed")
			// 		return ctrl.intent.fetchProjects()
			// 	}
			// 	, ctrl.model.vm().pending
			// )
		])
		,ctrl.model.getfilters(null, "Interruption")
		,elements.table(ctrl.model.data, ['Manage', 'Interruption Name', 'Start Date', 'End Date', 'Repetition Mode', '', 'Applies to'],
			ListProcessing(ctrl.model.interruptions(), ctrl.model.filterFunction.interruptionFilters.filters(), 'interruptions_name' ).map(
				(interruption) => [
					elements.checkbox({
						onchange: 	function(event){
										if(event.currentTarget.checked == true){
											ctrl.model.vm().selected.interruption = interruption
										}
										else if (event.currentTarget.checked == false){
											ctrl.model.vm().selected.interruption = {}
										}
									}
						,checked: !ctrl.model.vm().selected.interruption.interruptions_id
							? false
							: ctrl.model.vm().selected.interruption.interruptions_id()
							== interruption.interruptions_id()
							? true
							: false
						,disabled: !ctrl.model.vm().selected.interruption.interruptions_id
							? false
							: ctrl.model.vm().selected.interruption.interruptions_id()
							== interruption.interruptions_id()
							? false
							: true
					})
					,m(`a`
						,{
							onclick:
								() => {
									ctrl.model.vm().selected.interruption = interruption
									ctrl.model.cloningInterruptions(false)
									ctrl.model.viewtype = EditInterruption
									ctrl.intent.updateInterruptionsList()
									ctrl.model.edit(true)
								}
							,title: "Edit an existing interruption and all of its details"
						}
						,interruption.interruptions_name()
					)
					,moment( interruption.interruptions_start_date() ).format("ll")
					,moment( interruption.interruptions_end_date() ).format("ll")
					,interruption.interruptions_repetition_mode()
					,m(`a`
						,{
							onclick:
								() => {
									ctrl.model.vm().selected.interruption = interruption
									ctrl.model.cloningInterruptions(true)
									ctrl.model.viewtype = EditInterruption
									ctrl.intent.updateInterruptionsList()
									ctrl.model.edit(false)
								}
							,title: "Copy an existing interruption and all of its details"
						}
						,'Clone'
					)
					,elements.list( R.pipe(R.map( R.invoker(0, 'interruptions_affected_entity_name')),R.uniq)(interruption.interruptions_affected))
				]
			)
		)
		,m('br')
		,ctrl.model.interruptions().length == 0
			? ctrl.model.loading()
				? elements.centeredSpinner()
				: elements.alert('info',`No Interruptions have been created`)
			: null
	])
}




function EditInterruption(ctrl){
	return m('div.harth.mb6',[
		m('h4','Specify interruptions')
		,m('hr')
		,ctrl.model.editPermissions()
			? elements.list([
				ctrl.model.edit()
					? elements.back(
						"Go back to the main list without saving"
						,() => {
							ctrl.model.errors({})
							ctrl.model.cloningInterruptions(false)
							ctrl.model.deleteAffected = []
							ctrl.model.viewtype = ListInterruption
						}
					)
					: m("button.btn.", {
						disabled: false
						,onclick: () => {
							ctrl.model.errors({})
							ctrl.model.form(ctrl.model.resetForm())
							ctrl.model.vm().selected.interruption = {}
							ctrl.model.deleteAffected = []
							ctrl.model.viewtype = ListInterruption
						}
					},"Discard")
				,ctrl.model.edit()
					? m("button.btn.btn-warning", {
						disabled: identical(ctrl.model.vm().selected.interruption, ctrl.model.form().interruption)
						,onclick: () => {
							ctrl.model.errors({})
							ctrl.model.cloningInterruptions(false)
							ctrl.model.deleteAffected = []
							ctrl.intent.updateInterruptionsList()
						}
						,title: "Discard all changes made since last saving this interruption"
					},"Discard Changes")
					: null
				,ctrl.model.SaveDisabled(true) || identical(ctrl.model.vm().selected.interruption, ctrl.model.form().interruption)
					? m('button.btn', {disabled: true}, 'Save')
					: elements.action('Save', ctrl.intent.sendInterruptions, ctrl.model.vm().pending)
			])
			: elements.list([
				elements.back(
					"Go back to the main list without saving"
					,() => {
						ctrl.model.errors({})
						ctrl.model.form(ctrl.model.resetForm())
						ctrl.model.vm().selected.interruption = {}
						ctrl.model.viewtype = ListInterruption
					}
				)
			])

		,m("br")

		,elements.splitPane([
			elements.formInput([
				"Interruption Name"
				, ctrl.model.form().interruption.interruptions_name
				, {disabled: !ctrl.model.editPermissions()}
			])
			,ctrl.model.SaveDisabled()
			,elements.list([
				m('label.control-label', 'Start Date'
					,elements.dateInput(
						ctrl.model.form().interruption.interruptions_start_date
						,{
							disabled: ctrl.model.editPermissions() == false
							,title: "Date the interruption will start"
						}
					)
				)
				,m('label.control-label', 'End Date'
					,elements.dateInput(
						ctrl.model.form().interruption.interruptions_end_date
						,{
							disabled: 	ctrl.model.editPermissions() == false
							,title: "Date the interruption will end"
						}
					)
				)
				,m('label.control-label', 'Duration'
					,ctrl.model.form().interruption.interruptions_repetition_mode() == 'Once'
						?  ": " + ctrl.model.WholePeriod()
						: m(
							NumberInput
							,R.merge(
								ctrl.model.data
								,{
									errors: ctrl.model.errors
									,errorLabel: 'Duration'
								}
							)
							,{
								prop: ctrl.model.form().interruption.interruptions_duration
								,attrs: {
									min: 0
									, step: 0.01
									, disabled: ctrl.model.editPermissions() == false
									, title: "The length of the delay as a number of consecutive days including the start date"
								}
							}
						)
				)
			])
			,m('label.control-label', 'Repetition'
				,elements.selectbuttons(
					['Once', 'Weekly', 'Monthly', 'Yearly']
					, ctrl.model.form().interruption.interruptions_repetition_mode
					,{ disabled: ctrl.model.editPermissions() == false
					, title: "The repetitive cycle of this interruption"
					}
				)
			)
		]
		,[
			m(`p`, `If this interruption is repeated, the days considered unworkable are the start day plus each consecutive day counting up the duration period.
				The start of each period is the start date entered plus the repeating pattern until the end date is reached.`
			)

			,m(`p`, `As an example, the weekend can be an interruption, it starts on Saturday and ends 2 days later (which is the duration) on Sunday. if we consider Saturday the 1st of September
				the start date and it will be an interruption for the next 3 months, then the End Date of this interruption is the 1st of September + 3 months, the 1st of December.
				The duration will be 2 consecutive days (every Saturday and Sunday).`
			)

		])

		,ctrl.model.editPermissions()
		? [

			ctrl.model.resourceProject().filter((parent) =>
				ctrl.model.resourceProjectInterrupted().filter((child) =>
					child.ParentRecognitionID == parent.CommonRecognition
				).length > 0
			)
			.map(parent =>
				elements.list([
					parent.contractor_name
						? parent.contractor_name()
						: parent.project_name()

					, ctrl.model.resourceProjectInterrupted().filter((child) =>
							child.ParentRecognitionID == parent.CommonRecognition
						).length
						+ " out of "
						+  (parent.contractor_name
							? parent.crews.length
							: parent.disciplines.length)
						+ " Interrupted"

						, ctrl.model.resourceProjectInterrupted()
						.filter((child) =>
							child.ParentRecognitionID == parent.CommonRecognition
						).length
						== ( parent.contractor_name
								? parent.crews.length
								: parent.disciplines.length
						)
						? null
						: m('button.btn.btn-primary', {
							disabled: ctrl.model.resourceProjectInterrupted().filter((child) =>
											child.ParentRecognitionID == parent.CommonRecognition
									).length
									== ( parent.contractor_name
										? parent.crews.length
										: parent.disciplines.length )
							,onclick: () => {
								ctrl.model.form().interruption.interruptions_affected = R.uniqBy(
									R.invoker(0, 'interruptions_affected_entity_id')
									,ctrl.model.form().interruption.interruptions_affected.concat(
										parent.disciplines
											? parent.disciplines.map( d =>
												InterruptionAffected({
													interruptions_affected_entity_id: d.discipline_id()
													,interruptions_affected_entity_name: d.ParentRecognition
													,ParentRecognitionID: d.project_id()
													,created: true
													,ParentRecognition: d.ParentRecognition
												})
											)
											: parent.crews.map( c =>
												InterruptionAffected({
													interruptions_affected_entity_id: c.crew_id()
													,interruptions_affected_entity_name: c.teamresourcename
													,ParentRecognitionID: c.contractor_id()
													,created: true
													,ParentRecognition: c.ParentRecognition
												})
											)
									)
								)
								ctrl.intent.updateInterruptionsList()
							}
						}, 'Add All Colleagues')
				])
			)

			,elements.errorAlert(
				`All `
				,' and '
				,' have been interrupted'
				,[
					ctrl.model.resourceProjectList().length == 0 && ctrl.model.resources().some((r) => r.crews.length > 0)
					,ctrl.model.resourceProjectList().length == 0 && ctrl.model.projects().some((r) => r.disciplines.length > 0)
				]
				,[
					(d) => d ? `Teams` : null
					,(d) => d ? `all Disciplines in this schedule` : null
				]
				,false
				,true
				,'info'
			)

			,m('hr')

			,m('label.control-label.w-100', 'Select Interruptable Entities'
				,autocomplete.strict(
					ctrl.model.resourceProjectList
					,(v, model) => {
						ctrl.model.form().interruption.interruptions_affected
							.push(
								InterruptionAffected({
									interruptions_affected_entity_id:
										v.discipline_id
											? v.discipline_id()
											: v.crew_id()
									, interruptions_affected_entity_name:
										v.discipline_name
											? v.ParentRecognition
											: v.teamresourcename
									,ParentRecognitionID:
										v.discipline_name
											? v.project_id()
											: v.contractor_id()
									,created: true
									,ParentRecognition: v.ParentRecognition
								})
							)

						ctrl.intent.updateInterruptionsList()
						model.input('')
						model.chosen(null)
					}
					,'ParentRecognition'
					,() => ({
						disabled: ctrl.model.resourceProjectList().length == 0 || !ctrl.model.editPermissions()
					})
					,(value, element) => {
						element.value = ""
					}
				)
			)
		]
		: null

		,m('p')

		,ctrl.model.missingPermissions() == 'Project and Resource'
			? elements.alert(`info`, `This selection list is empty because permissions have not been granted for Resources or Projects`)
			: !ctrl.model.resourceProject().length
			? elements.errorAlert(
					'This selection list is empty because '
					,' or '
					, ` may not been created. Or all viewable options are chosen`
				,[
					false
					,ctrl.model.resources()
					,false
					,ctrl.model.projects()
				]
				,[
					() => !ctrl.model.metaDataLoading() && ctrl.model.resources().length == 0 ? ` Resources` : null
					,(d) => !ctrl.model.metaDataLoading() && d.crews.length == 0 ? `Teams` : null
					,() => !ctrl.model.metaDataLoading() && ctrl.model.projects().length == 0 && ctrl.model.data.schedule_id() ? `Projects` : null
					,(d) => !ctrl.model.metaDataLoading() && d.disciplines.length == 0 && ctrl.model.data.schedule_id() ? `Disciplines` : null
				]
				,false
				,true
			)
			: null

		,ctrl.model.getfilters(null, "Affected")

		,m("hr")
		,scrollableTable.scrollableTable(
			ctrl.model.data
			,[
				['Interrupted Entities', 0.60]
				,[' ', 0.20]
				,['', 0.20]
			]
			.map(
				([text,width]) => ({ text, width })
			)
			,() =>
				ListProcessing(
					[].concat(R.unnest(R.partition(
						(i) => i.created,
						R.sortBy(
							(a) => a.interruptions_affected_entity_name()
							,ctrl.model.form().interruption.interruptions_affected
						)
					)))
					, ctrl.model.filterFunction.affectedFilters.filters()
					, 'interruptions_affected_entity_name'
				)
				.map(
					(i) => [
						i.interruptions_affected_entity_name()

						,m('button.btn.btn-warning', {
							disabled: !ctrl.model.editPermissions()
							,onclick: () => {
								ctrl.intent.deleteInterruptionsAffected(
									i.interruptions_affected_entity_id()
								)
								ctrl.intent.updateInterruptionsList()
							}
						}, 'Discard')

						,m('button.btn.btn-warning', {
							disabled: !ctrl.model.editPermissions()
							,onclick: () => {
								const parent = ctrl.model.resourceProject().find(
									(parent) =>
										parent.CommonRecognition
										== ctrl.model.resourceProjectTotal().find((child) =>
												child.CommonRecognition
												== i.interruptions_affected_entity_id()
										).ParentRecognitionID
								)
								parent.disciplines
									? parent.disciplines.forEach((d) =>
										ctrl.intent.deleteInterruptionsAffected( d.CommonRecognition ))
									: parent.crews.forEach((d) =>
										ctrl.intent.deleteInterruptionsAffected( d.CommonRecognition ))
								ctrl.intent.updateInterruptionsList()
							}
						}, 'Discard All Relations')
					]
				)
				.map(
					r => ({ children: r , height: 55 })
				)
				,{ height: 400, headerHeight: 45, minWidth: 600, multilineHeader: false }
		)
	])
}

/*
	In order for mithril to mount this component.  We have to expose these two properties.
*/
export default Main