/* globals window, process */
import m from 'bacta'
import css from 'bss'

const marketingURL =
	process.env.HARTH_MARKETING_URL
	|| 'https://harth.io'

const external =
	m.trust(`
		<svg xmlns="http://www.w3.org/2000/svg" width="1em" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-external-link"><path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path><polyline points="15 3 21 3 21 9"></polyline><line x1="10" y1="14" x2="21" y2="3"></line></svg>
	`)

// [] 						= do nothing
// [{ content, ...etc }] 	= write to modal stream
// [null] 					= write null to modal stream

function consents({ auth, modal, api, auth_permissions }){
	return auth.stream.map( authData => {
		if( authData._name == 'LoggedOut' ) {
			// Nothing to do
			return [];
		} else if ( authData._name == 'Refresh' ) {
			return []
		} else if (auth_permissions().consented) {
			// Nothing to do
			return [];
		} else {
			if( modal() && modal().consentModal ) {
				// Nothing to do
				return [];
			} else {
				return [{
					dismissable: false
					, offset: { desktop: { y: '0vh'}, mobile: '0vh' }
					,content(){
						return m(''
							+ css`
								display: grid;
								height: 100%;
								--orange: hsl(31deg 100% 72%);;
								border-top: solid 0.2em var(--orange);
							`
							.$nest('.loading', `
								pointer-events: none;
								touch-action: none;
								opacity: 0.5;
							`)
							.$nest('a', `
								color: var(--orange);
								letter-spacing: 0.5px;
								text-decoration: none;
							`)
							.$nest('a:visited', `
								color: var(--orange);
							`)
							.$nest('.consent-checkbox', `
								display: grid;
								gap: 1em;
								grid-template-columns: auto auto;
								gap: 0.5em;
								justify-content: start;
								align-items: center;
								font-weight: 100;
							`)
							.$nest('.consent-checkbox input', `
								margin: 0px;
								line-height: normal;
								width: var(--dim);
								height: var(--dim);
								--dim: 2em;
							`)
							.$nest('.consent-checkbox span', `
								font-size: 1.1em;
							`)
							.$nest('.cta', `
								background-color: black;
								color: white;
								text-align: center;
								border-radius: 0.5em;
								padding: 0.5em;
								border: none;
								font-size: 0.8em;
								transition: background-color 0.2s, box-shadow 0.5s, color 0.5s;
								font-size: 1.5em;
								border: solid 2px var(--orange);
							`)
							, m(''
								+ css`
									padding: 1.2em;
									display: grid;
									grid-template-rows: ${
										`max-content max-content 1fr`
									};
									row-gap: 0.2em;
								`
								,m('h1', 'Odin')
								,m('hr' + css.m(0) + css`
									margin: 0em 0em 0.4em 0em;
									background-color: white;
								`)
								,m('form.content'
									+ css`
										display: grid;
										position: relative;
										height: 100%;
										align-content: space-evenly;
										row-gap: 1em;
										overflow-y: auto;
									`
									.tablet(`
										align-content: start;
										padding: 0.5em 0em;
									`)
									,
									{ onsubmit(e){
										e.preventDefault()

										const el = e.target

										el.classList.add('loading')

										let $=	new window.FormData(el)
										$=	Object.fromEntries($.entries())

										api
										.request('POST', '/users/consents', $)
										.then( () => {
											return api.auths.refresh()
										})
										.then(function(response){
											auth.stream(
												auth.type.LoggedInOf(response)
											)
										})
										.then(() => {

											// refresh so code is guaranteed to have the right permissions
											// shouldn't be necessary but just being safe
											window.location = window.location
											modal(null)
										})
										.finally( () => {
											el.classList.remove('loading')
										})

									}
									}
									,m('h4'
										+ css`
											color: var(--orange);
										`
										, 'Terms of Service & Privacy Policy'
									)
									,m('p', 'As we introduce new features and upgrades to Odin we will occasionally update our Terms of Service and Privacy Policy.')
									,m('p', 'In order to continue your usage of Odin we require that you read and consent to the follow declrations.')
									,m('p', 'If you have any questions or concerns please do not hesitate to reach out at ', m('a', { href: 'mailto:support@harth.io' }, 'support@harth.io'))

									,m(''
										,m('h4'
											+ css`
												color: var(--orange);
											`
											, 'Declarations'
										)
										,m('hr' + css.m(0) + css`
											margin: 0em 0em 0.4em 0em;
											background-color: white;
										`)
									)
									,m('p'+css`font-size: 1.1em;`, 'I have read and agreed to the following:')
									,m('label.consent-checkbox'
										,m('input[type=checkbox]', { name: 'tac', required: true})
										, m('a'
											,
											{ href: marketingURL+'/terms-and-conditions'
											, target: '_blank'
											, rel:"noopener noreferrer"
											}
											, 'Terms and Conditions '
											, external
										)
									)
									,m('label.consent-checkbox'
										,m('input[type=checkbox]', { name: 'privacy', required: true})
										, m('a'
											,
											{ href: marketingURL+'/privacy'
											, target: '_blank'
											, rel: 'noopener noreferrer'
											}
											, 'Privacy Policy '
											, external
										)
									)
									, m('button.cta', { type: 'submit' }, 'Submit')
								)

							)
						)
					}
				}]
			}
			return []
		}
	})
}

export default consents