/* globals Promise */
/* eslint-disable max-len */

import m from 'bacta'
import css from 'bss'
import * as H from '../../../how/index.js'
import * as elements from '../components/elements'
import NumberInput from '../components/number'
import Responsive from '../components/responsive'
import * as uuid from 'uuid'
import {
	filterView
	,ListProcessing
} from '../components/filter'
import * as R from 'ramda';
import autocomplete from './autocomplete'
import scrollableTable from '../components/scrollableTable'
import HarthUppy from '../components/harth-uppy'
import byProp from '../utils/byProp'
import humanList from '../utils/human_list'
import { money as makeMoney } from '../utils/regExes'
import { Light as DetailsPane } from '../components/detailspane2'
import Pencil2 from '../components/pencil'
import * as modalStack from '../components/modal-stack'

import {identical} from '../utils/harth-identical'

const autouidContracts = Math.random().toString(15).slice(2, 8)
const milliperday = 1000*60*60*24
import { prop } from '../../../stream/index'
import Permissions from '../models/permissions'
import ProjectDocument from '../models/project_documents'
import NotificationBar from '../components/notification'
import moment from 'moment'

const nullUser = {
	user_id: m.prop(null)
	, user_username: m.prop('Unassigned')
	, usersFullName: 'Unassigned'
	, contractrecognitionname: 'Unassigned'
	, contractrecognitionid: null
}
const asyncMergeAll =
	xs => prop.afterSilence(
		0
		, prop.merge(xs)
	)

const exposer = (a) => (typeof a == 'function' ? a() : a) || null

// const dateProjects = [
// 	{project_name: 'Yesterday', project_id: 'Yesterday'}
// 	,{project_name: 'Today', project_id: 'Today' }
// 	,{project_name: 'This Week', project_id: 'This Week'}
// 	,{project_name: 'Last Week', project_id: 'Last Week'}
// ]

const dateProjects = [
	{project_name: 'Yesterday'}
	,{project_name: 'Today' }
	,{project_name: 'This Week'}
	,{project_name: 'Next Week'}
	,{project_name: 'Last Week'}
]

const projectDocumentsView =
	({ editProjectPermissions, xs }) =>
		[xs]
		.map( R.filter( ProjectDocument.filter({ editProjectPermissions }) ) )
		.map( R.partition( x => x.project_documents_is_deliverable ))
		.map(
			([ , documents]) => [
				m('label.control-label', 'Project Lnks')
				,elements.list(
					documents
					.map( ProjectDocument.toVTree )
				)
				// ,m('label.control-label', 'Project Deliverables:')
				// ,elements.list(
				// 	deliverables
				// 	.map( ProjectDocument.toVTree )
				// )
			]
		)

const processProjectDisciplineNods = ({
	allocations
}) => today => d => {

	const pd_allocation =
		allocations()
		.filter((allocation) =>
			allocation.discipline_id()
			== d.discipline_id()
		)

	const current_index = R.findIndex(
		(a) => a.allocations_allocation_date == today
		,pd_allocation
	)

	let previous_index = current_index - 1

	let default_nod = []

	if (previous_index >= 0) {
		while (
			default_nod.length == 0
			&& previous_index > 0
			&& pd_allocation[previous_index].allocations_eots.length == 0
		){
			if (
				pd_allocation[previous_index].allocations_eots.length == 0
				&& pd_allocation[previous_index].allocations_nods.length == 0
			){
				previous_index = previous_index - 1
			}
			else if (
				pd_allocation[previous_index].allocations_eots.length == 0
				&& pd_allocation[previous_index].allocations_nods.length > 0
			){
				default_nod = pd_allocation[previous_index].allocations_nods
			}
		}
		for (let i = previous_index + 1; i <= current_index; i++) {
			pd_allocation[i].allocations_nods = default_nod
		};
	}
}

const forecastedWorkAmount = ({ contractItem, fAmount }) =>
	!contractItem.crew_id && !contractItem.user_id
	? contractItem.organizations_disciplines_id
		? fAmount
		: fAmount.allocations_contractors_crews_work_forecasted()
		* fAmount.allocations_contractors_crews_work_application()
	: contractItem.contract_items_recorded_reference_standard


const accruedAmount = ({ contractItem, allocatioCrewOldData, timesheets, vm }) =>
	!contractItem.crew_id
	&& !contractItem.user_id
		? contractItem.organizations_disciplines_id
			? R.sum(
				allocatioCrewOldData()
				.map((acc) =>
					Number(acc.allocations_contractors_crews_work_completed())
					|| 0
				)
			)
			: Number((
				allocatioCrewOldData().find((acc) =>
					acc.crews_discipline_rates[0].crews_discipline_rates_id
					== contractItem.crews_discipline_rates_id
				) || {allocations_contractors_crews_work_completed: m.prop(0)}
			).allocations_contractors_crews_work_completed())

		: contractItem.contract_items_recorded_reference != 'Timesheets'
			? 1
			: R.sum(
				timesheets()
				.filter((t) =>
					t.allocation_id() == vm().selected.allocation.allocation_id()
					&& ( contractItem.crew_id
					? t.crew_id() == contractItem.crew_id
					: t.user_id() == contractItem.user_id)
				)
				.map((t) => t.crew_hours() )
			)

const dollarsArrayWhenAllocated =
	({
		userDayContracts
		,crewDayContracts
		,crewWorkContracts
		,disciplineContract
		,todaysInvoices
		,allocatioCrewnData
		,criteriaBasedCalculation
		,allocatioCrewOldData
		,vm
		,timesheets
	}) =>
		[].concat(
			userDayContracts
			,crewDayContracts
			,crewWorkContracts
			,disciplineContract
			,todaysInvoices
		)
		.filter((cItems) => cItems.contract_items_applied != false )
		.map((contractItem) => {

			// when resources are deleted and old versions still
			// have resources the below errors
			// at crews_discipline_rates[0]
			const fAmount =
				!contractItem.crew_id
				&& !contractItem.user_id
				? contractItem.organizations_disciplines_id
					? R.sum(
						allocatioCrewnData().map((acc) =>
							acc.allocations_contractors_crews_work_forecasted()
							* acc.allocations_contractors_crews_work_application()
							|| 0
						)
					)
					: allocatioCrewnData().find((acc) =>
						acc.crews_discipline_rates[0]
						&& acc.crews_discipline_rates[0]
							.crews_discipline_rates_id
						== contractItem.crews_discipline_rates_id
					) || {
						allocations_contractors_crews_work_forecasted:
							m.prop(0)
						,allocations_contractors_crews_work_application:
							m.prop(1)
					}
				: contractItem.contract_items_recorded_reference_standard

			return criteriaBasedCalculation(
				contractItem
				, contractItem.invoice_id
					? {
						incriment: 'invoice'
						,forecastedWorkAmount: null
						,forecastedDamagesAmount: null
					}
					: {
						incriment: 'contract'
						,forecastedWorkAmount:
							forecastedWorkAmount({
								contractItem, fAmount
							})

						,accruedAmount:
							accruedAmount({
								contractItem
								, allocatioCrewOldData
								, timesheets
								, vm
							})
						,forecastedDamagesAmount:
							vm().selected.allocation.allocations_allocation_date()
							>= new Date(contractItem.contractEnd).getTime()
							&& vm().selected.allocation.allocations_allocation_date()
							<= new Date(contractItem.damagesEnd).getTime()
								? 1
								: 0
					}
			)
		})



// ADD MULTIPLIER HOW
const OldDollarsArray =
	({
		userDayContracts
		,crewDayContracts
		,crewWorkContracts
		,disciplineContract
		,todaysInvoices
		,workDifference
		,allocatioCrewnData
		,criteriaBasedCalculation
		,allocatioCrewOldData
		,vm
		,timesheets
	}) =>
		asyncMergeAll([
			userDayContracts
			,crewDayContracts
			,crewWorkContracts
			,disciplineContract
			,todaysInvoices
			,workDifference
		])
		.map(([
			userDayContracts
			,crewDayContracts
			,crewWorkContracts
			,disciplineContract
			,todaysInvoices
		]) =>
			vm().selected.allocation.allocations_allocation_date
				? R.unnest(
					dollarsArrayWhenAllocated({
						userDayContracts
						,crewDayContracts
						,crewWorkContracts
						,disciplineContract
						,todaysInvoices
						,allocatioCrewnData
						,criteriaBasedCalculation
						,allocatioCrewOldData
						,vm
						,timesheets
					})
				)
				: []
			)

function deletetInvoices({
	makeAPICalls
	, api
	, invoice
	, deleteInvoiceItems
	, todaysInvoices
	, errors
}){

	if( !makeAPICalls ){
		deleteInvoiceItems().push(
			invoice.invoice_id()
		)
		todaysInvoices().splice(
			todaysInvoices()
				.findIndex((i) =>
					i.invoice_id()
					== invoice.invoice_id()
				)
			,1
		)
		delete errors()[invoice.invoice_name() + ' Working Amount']
		delete errors()[invoice.invoice_name() + ' Depreciation Amount']
		delete errors()[invoice.invoice_name() + ' Service Amount']
		delete errors()[invoice.invoice_name() + ' Appreciation Amount']
		delete errors()[invoice.invoice_name() + ' Damages Amount']
	}

	return makeAPICalls
		? Promise.all(
			deleteInvoiceItems()
				.filter((i) => i)
				.map(
					(t) => api.invoices.remove.one(t)
				)
		)
		: Promise.resolve([])
}

function fetchAllocations({
	data
	, allocations
	, allocationsHash
	, SelectedProject
	, SelectedDiscipline
	, projectOptions
	, loading
	, fetchedExtras
	, allProjects
	, dbp_vm
	, disciplineExtraOptions
	, disciplineOptions
	, allDisciplines
	, discipline_box
	, activeLandingTab
	, retrieveAllocations
	, dateframeprojects
	, SelectedResource
	, selectedProjectExtra
}){
	if (
		data.schedule_id()
		&& (
			allocations().length == 0
			|| SelectedProject().project_id()
			!= allocations().slice(0,1).map( x => x.project_id )[0]
		)
	){

		if(
			!SelectedProject().organization_id
			|| SelectedProject().organization_id() != data.organization_id()
			|| allocations().length && R.last(allocations()).schedule_version_id()
			!= data.selectedVersion().schedule_version_id
		){projectOptions([])}

		loading(true)
		const schedule_index =
			R.findIndex(
				R.propEq(
					'schedule_id'
					,data.schedule_id()
				)
				,data.schedules()
			)

		if (schedule_index != -1 ){
			const most_recent_version =
				R.sortBy(
					R.prop("schedule_version_created"),
					data.schedules()[schedule_index]
						.schedule_versions
				)

			const LatestVerionIndex = most_recent_version.length - 1
			const schedule_version_id =
				data.selectedVersion().schedule_version_id
				|| most_recent_version[LatestVerionIndex]
					.schedule_version_id

			const project_id =
				SelectedProject().project_id
					? SelectedProject().project_id()
					: null

			const schedule_id =
				selectedProjectExtra().schedule_id
				|| SelectedProject().schedule_id
				|| data.schedule_id()

			const initialfetch = !projectOptions().length

			allocations([])
			allocationsHash({})
			return Promise.all(

				!projectOptions().length
					? [ activeLandingTab != 'Tasks'
						? data.fetchAllocationsbyScheduleVersionShallow(
							R.merge(
								dateframeprojects
								,{
									schedule_version_id: schedule_version_id
									,uniqBy: 'project_id'
									,pcomplete: `not completed`
								}
							)
						)
						: []
						,activeLandingTab == 'Tasks'
						? data.fetchProjectExtras({
							project_id: null
							,discipline_id: null
							,f: fetchedExtras
							,typesObj: {
								completed: 'any'
								, invalid: 'any'
								, verified: 'any'
								, assigned: 'any'
							}
							,uniqBy: true
							,pcomplete: `not completed`
							,shallowQuery: true
							,schedule_id: data.schedule_id()
						})
						: []
						,data.fetchProjectsBySchedule(
							{
								schedule_id: data.schedule_id()
								,depth:2
								,props: {
									projects: [
										'project_name'
										,'project_id'
										,'schedule_id'
									]
									,disciplines: [
										'discipline_id'
										,'discipline_name'
										,'organizations_disciplines_id'
									]
								}
								,getProjectExtras: true
								,taskcount: 0
							}
						)
					]
					: retrieveAllocations() || activeLandingTab == 'Days'
					? [data.fetchAllocationsbyScheduleVersion(
						R.merge(
							dateframeprojects
							,{
								project_id: SelectedProject().project_id && SelectedProject().project_id()
								,schedule_version_id:
									data.schedules().find((s) =>
										s.schedule_id
										== exposer(schedule_id)
									)
									.schedule_versions[0].schedule_version_id
								,contractor_id: SelectedResource().contractor_id && SelectedResource().contractor_id()
							}
						)
					)]
					: []
			)
				.then((totres) => {

					const res = R.unnest(totres)

					res.forEach((p) => p.project_name = exposer(p.project_name))

					const tasks =
						!projectOptions().length
						? totres[1]
						: []

					if (
						!projectOptions().length
						&& (
							res.length
							|| tasks.length
						)
					){

						projectOptions(
							R.uniqBy(
								(a) =>
									typeof a.project_id == 'function'
									? a.project_id()
									: a.project_id
								,res.concat(tasks)
								.concat(
									!data.readProjectPermissions()
									? R.merge(
										allProjects()
										,{ organization_id:
											m.prop(data
												.initiateContractFetch()
												.organization_id)
										}
									)
									: []
								)
							)
							.concat(
								dateProjects.map((dp) =>
									R.merge(
										dp
										,{ organization_id:
											m.prop(
												data.initiateContractFetch()
												.organization_id
											)
										}
									)
								)
							)
						)

						res.length
						? SelectedProject( projectOptions()[0] )
						: SelectedProject( tasks[0] )

						res.length
						? dbp_vm(projectOptions()[0].project_name)
						: dbp_vm(tasks[0].project_name)

						fetchedExtras(false)
					}
					else if(
						!projectOptions().length
						&& !res.length
						&& !tasks.length
					){
						loading(false)
						fetchedExtras(false)
					}


					if (
						!initialfetch
						&& (
							project_id
							|| SelectedProject().project_name == 'All Projects'
							|| SelectedProject().project_name == 'Yesterday'
							|| SelectedProject().project_name == 'Today'
							|| SelectedProject().project_name == 'This Week'
							|| SelectedProject().project_name == 'Next Week'
							|| SelectedProject().project_name == 'Last Week'
						)
					){

						allocations(R.unnest(res))

						const uniqDisciplines =
							R.uniqBy(
								(a) =>
								typeof a.organizations_disciplines_id
									== 'function'
								? a.organizations_disciplines_id()
								: a.organizations_disciplines_id
								, allocations()
							)
							.map(
								R.tap((t) =>
									t.discplineNameDescription =
									t.relatedDiscipline
									|| t.discplineNameDescription
								)
							)

						disciplineExtraOptions( uniqDisciplines )
						disciplineOptions(
							[].concat(
								allDisciplines
								,uniqDisciplines
							)
						)

						SelectedDiscipline().discipline_id
							? null
							: discipline_box('All Disciplines')

						const today = new Date().getTime()

						disciplineExtraOptions().forEach(
							processProjectDisciplineNods({
								allocations
							}) (today)
						)

						allocationsHash(
							R.groupBy(
								(a) => a.project_id()
								,allocations()
							)
						)
					}
					loading(false)
					return true
				})
				.catch(
					e => NotificationBar.Notifications.alertError(e.message)
				)
		}
	} else { loading(false) }
	return Promise.resolve([])
}

function Main({ attrs: { data } }){

	const activeTab = data.scoped('Timesheets')
	const activeLandingTab = data.scoped('Tasks')
	const retrieveAllocations = data.scoped(false)
	const schedule_id = data.schedule_id
	const loading = data.scoped(true)
	const usersIndex = data.scoped({})
	const tools = m.prop([])
	const modules = m.prop([])
	const allocationsHash = data.scoped({})
	const allocations = data.scoped([])
	const fetchedInv = data.scoped(false)
	const fetchedTime = data.scoped(false)
	const users = data.scoped([])
	const timesheetlist = data.scoped([])
	const eotDeletions = m.prop([])
	const nodDeletions = m.prop([])
	const varDeletions = m.prop([])
	const contractvm = m.prop({})
	const fullView = data.scoped(false)
	const errors = data.scoped({})
	const allocationChange = data.scoped(false)
	const baseProjectDetails = data.scoped([])
	const forecastedWork = data.scoped(0)
	const editProjectPermissions = m.prop(false)
	const editMaterialPermissions = m.prop(false)
	const editContractRecognitions = m.prop(false)
	const editContractPermissions = m.prop(false)
	const editInvoicePermissions = m.prop(false)
	const editTimePermissions = m.prop(false)
	const editUpdatePermissions = m.prop(false)
	const editToolPermissions = m.prop(false)
	const editAssignedTimesheetsPermissions = m.prop(false)
	const editOwnedInvoicesPermissions = m.prop(false)
	const editAssignedToolPermissions = m.prop(false)
	const organizationsDisciplines = data.scoped([])
	const selectedProjectExtra = data.scoped({})
	const metaDataLoading = data.scoped(true)
	const allDisciplines = { discplineNameDescription: 'All Disciplines' }
	const allProjects = data.readProjectPermissions.map((rpp) => rpp ? {} : { project_name:'All Projects' })
	const SelectedProject = data.scoped({})
	const SelectedResource = data.scoped({})
	const resfetch = data.scoped(true)
	const SelectedDiscipline = data.scoped(allDisciplines)
	const projectMetaDataLoading = data.scoped(false)
	const projectMetaData = data.scoped({})
	const baseProjectDetailsHash =
		baseProjectDetails.map((tlist) => R.indexBy((t) => t.project_extras_id(), tlist))
	let viewType = null

	/**
	 * Prevent API from returning photos for the entire org
	 * https://github.com/harth-systems/odin/pull/160
	 */
	const FAKE_ORG_D = uuid.v4()

	function uploadmetadata({only}){
		const intasks = activeLandingTab() == 'Tasks'
		const photosids =
			R.pickBy(
				(a, k) => a && (!only || !Object.keys(only).length || only && only[k] || k == 'user_id' || k == 'organization_id')
				,{
					organization_id: data.initiateContractFetch().organization_id
						// (SelectedProject().project_id || intasks && exposer(selectedProjectExtra().project_extras_id))
					, user_id: data.auth.stream().user_id
						// (SelectedProject().project_id || intasks && exposer(selectedProjectExtra().project_extras_id))
						// && data.auth.stream().user_id
					, schedule_id: data.schedule_id()
					, project_id:
						SelectedProject().project_id
						&& SelectedProject().project_id()
					, project_extras_id:
						intasks
						? exposer(selectedProjectExtra().project_extras_id)
						: null
					, allocation_id:
						activeLandingTab() == 'Days'
						? exposer(
							vm().selected.allocation.allocation_id
							|| selectedProjectExtra().allocation_id
						)
						: null
					, organizations_disciplines_id:
						only && only.organizations_disciplines_id
							? intasks && exposer(selectedProjectExtra().organizations_disciplines_id)
								? selectedProjectExtra().organizations_disciplines_id
								&& exposer(selectedProjectExtra().organizations_disciplines_id)
								: intasks
								? FAKE_ORG_D
								: vm().selected.allocation
								&& exposer(vm().selected.allocation.organizations_disciplines_id) || FAKE_ORG_D
						: null
				}
			)
		return photosids
	}

	const restrictedorganizationsDisciplines =
		asyncMergeAll(
			[
				organizationsDisciplines
				,selectedProjectExtra
				,SelectedProject
			]
		)
		.map(([ods, spe, sp]) =>
			ods.filter((od) =>
				!sp.contract_id
				|| !spe.contract_id
				|| od.applicablecontracts
				.find((c) =>
					c.contract_id == spe.contract_id
					|| c.contract_id == sp.contract_id
				)
			)
		)

	const dateframeprojects =
		SelectedProject.map((p) =>
			({
				startDate:
					p.project_name == 'Yesterday'
					? new Date( new Date().setHours(0,0,0,1) ).getTime() - 1 * milliperday
					: p.project_name == 'Today'
					? new Date( new Date().setHours(0,0,0,1) ).getTime()
					: p.project_name == 'This Week'
					? new Date( new Date().setHours(0,0,0,1) ).getTime() - (new Date().getDay() == 0 ? 6 : new Date().getDay() - 1) * milliperday
					: p.project_name == 'Last Week'
					? new Date( new Date().setHours(0,0,0,1) ).getTime() - ((new Date().getDay() == 0 ? 6 : new Date().getDay() - 1) + 7) * milliperday
					: p.project_name == 'Next Week'
					? new Date( new Date().setHours(0,0,0,1) ).getTime() + (new Date().getDay() == 0 ? 6 : 6 - new Date().getDay() + 1) * milliperday
					: ''
				,endDate:
					p.project_name == 'Yesterday'
					? new Date( new Date().setHours(0,0,0,1) ).getTime()
					: p.project_name == 'Today'
					? new Date( new Date().setHours(0,0,0,1) ).getTime() + 1 * milliperday
					: p.project_name == 'This Week'
					? new Date( new Date().setHours(0,0,0,1) ).getTime() + (6 - new Date().getDay()) * milliperday
					: p.project_name == 'Last Week'
					? new Date( new Date().setHours(0,0,0,1) ).getTime() - (new Date().getDay() == 0 ? 6 : new Date().getDay() - 1) * milliperday
					: p.project_name == 'Next Week'
					? new Date( new Date().setHours(0,0,0,1) ).getTime() + ((new Date().getDay() == 0 ? 6 : 6 - new Date().getDay() + 1) + 7) * milliperday
					: ''
			})
		)

	const newSelectedValue = [
		[ selectedProjectExtra
		, allocationChange
		]
	]
		.map( xs => asyncMergeAll(xs) )
		.map(
			s => s.map( ([pe, ac]) => {
				return String(
					(activeTab() + activeLandingTab()).includes('Task')
					? pe.key
						|| Object.keys(pe).length
						&& !pe.newtask

					// return a random uuid when ac is true
					// so dropRepeats can actually notice ac changes
					: ac && uuid.v4()
				)
			})
		)
		.map( s => prop.dropRepeats(s) )
		.shift()

	const detailsPaneKey = newSelectedValue.map( () => uuid.v4() )

	const nullCrew = {
		crew_id: m.prop(null)
		,teamresourcename: 'Unassigned'
		,user_id: m.prop(null)
	}

	const vm = m.prop({
		pending: m.prop(false)
		,selected: {allocation: {}}
		,revert: {}
	})

	const childResUsers = data.scoped([])
	const resources = data.scoped([])
	const mchildResUsers =
		childResUsers
		.map(
			R.filter((u) =>
				u.user_id()
				&& (
					!selectedProjectExtra().users
					|| !selectedProjectExtra().users()
					.find((tu) =>
						tu.user_id() == u.user_id()
					)
				)
			)
		)
	// resPrep
	allocationChange
		.map((a) =>{
			resources([])
			return a
				&& vm().selected.allocation.allocation_id
					? Promise.all(
						vm().selected.allocation.allocations_contractors
							.map((ac) =>
								ac.contractor_id()
								? data.fetchResource({
									contractor_id: ac.contractor_id()
									, props: {
										resources: [
											'contractor_id'
											,'contractor_name'
											,'user_id'
										]
										,rates: [
											'crew_id'
											,'crews_discipline_rates_id'
										]
									}
								})
								: []
							)
					)
					.then(function(res){
						const contractList = R.unnest(res)
						userResCrew(
							[].concat(
								nullCrew
								,R.unnest(
									contractList.map((r) =>
										editToolPermissions()
										|| r.user_id()
											== data.auth.stream().user_id
											? r.crews
											: r.crews.filter((c) =>
												c.user_id()
												== data.auth.stream().user_id
											)
									)
								)
							)
						)
						return resources(contractList)
					})
					: []
		})


	const allResources = { ParentRecognition: 'All Resources' }
	const loadingMeta = data.scoped(true)
	const workList = data.scoped({})
	const toolDetail = data.scoped({})

	const fectchedResources = data.scoped([])
	const fectchedTeams = data.scoped([])
	const projectOptions = data.scoped([])
	const disciplineOptions	= data.scoped([])
	const disciplineExtraOptions = data.scoped([])

	const resourceOptions =
		asyncMergeAll(
			[
				SelectedProject
				,fectchedResources
			]
		)
		.map(([p, rlist]) =>
			p.project_name != 'All Projects'
			? rlist.concat(allResources)
			: rlist
		)

	// const missingPermissions =
		data.initiateContractFetch.map(() =>
			humanList(
				[
					'Project'
					,'Resource'
					,'Tool'
					,'Timesheet'
					,'Contract Obligation'
					,'Contract'
					,'Invoice'
				]
					.map((p)=>
						data['read'+p.replace(' ', '')+'Permissions']()
							? ''
							: p
					)
					.filter((p) => p)
			)
		)

	const contractOptions = data.scoped(
		[
			'Variation'
			,'Notice of Delay'
			,'Extension of Time'
		]
	)


	const setContractOptions =
		(a) =>
			contractOptions(

					a.allocations_nods.length > 0
					&& a.allocations_eots.length < a.allocations_nods.length

					? [
						'Variation'
						,'Notice of Delay'
						,'Extension of Time'
					]
					: [
						'Variation'
						,'Notice of Delay'
					]
			)


	const contractList = m.prop([])
	const consolidateContractList = (a) =>
		contractList(
			R.sortBy(
				(i) => i['allocations_' + i.midfix + '_description']()
				,R.unnest([
					a.allocations_vars
					,a.allocations_nods
					,a.allocations_eots
				])
			)
		)


	const tools_vm = m.prop([])

	const timesheet_vm = m.prop({
		pending: m.prop(false)
		,selected: { timesheet: [] }
		,revert: []
	})

	const deleteInvoiceItems = data.scoped([])

	const originalInvoices =
		asyncMergeAll(
			[
				allocationChange
				,data.calculatedData
				,data.selectedVersion
			]
		)
		.map(([aChange, cd, sv]) => {

			fetchedInv(false)
			const invoiceList =
				!vm().selected.allocation.allocation_id || !aChange
				? []
				: (
					cd[sv.schedule_version_id + '0Infinity']
					|| {focussedInvoices:[]}
				)['focussedInvoices']
				// .filter((i) =>
				// 	i.allocation_id
				// 	== vm().selected.allocation.allocation_id()
				// )

			return invoiceList
		})

	const todaysInvoices =
		originalInvoices.map((invoiceList) => {
			const invList = data.cloneInvoice(invoiceList)
			fetchedInv(true)
			return invList
		})

	const crewsIndexedByAID =
		allocationChange.map((ac) =>
			ac && vm().selected.allocation.allocation_id
				? R.indexBy(
					(a) => a.user_id() || a.crew_id()
					,R.unnest(
						vm().selected.allocation
						.allocations_contractors.map((ac) =>
							ac.allocations_contractors_crews
						)
					)
				)
				: {}
		)


	const crewWorkers =
		allocationChange.map((ac) =>
			ac && vm().selected.allocation.allocation_id
				? R.unnest(
					vm().selected.allocation
					.allocations_contractors.map((ac) =>
						ac.allocations_contractors_crews
					)
				)
				: []
		)

	const currAllocationDate =
		allocationChange.map((ac) =>
			ac && vm().selected.allocation.allocation_id
				? new Date(
					vm().selected.allocation.allocations_allocation_date()
				)
				: Infinity
		)

	function resetVARS(){
		const variation = {
			allocations_vars_id: m.prop('')
			,project_extras_id: m.prop(null)
			,allocation_id: m.prop(null)
			,allocations_vars_amount: m.prop(null)
			,allocations_vars_description: m.prop('')
			,allocations_vars_escalated: m.prop(false)
			,allocations_vars_clause: m.prop(null)
			,allocations_vars_approved: m.prop(false)
			,user_id: m.prop(data.auth.stream().user_id)
			,midfix: 'vars'
		}
		return variation
	}

	function resetNODS(){
		const nod = {
			allocations_nods_id: m.prop('')
			,project_extras_id: m.prop(null)
			,allocation_id: m.prop(null)
			,allocations_nods_description: m.prop('')
			,allocations_nods_escalated: m.prop(false)
			,allocations_nods_clause: m.prop(null)
			,allocations_nods_approved: m.prop(false)
			,user_id: m.prop(data.auth.stream().user_id)
			,midfix: 'nods'
		}
		return nod
	}

	function resetEOTS(){
		const eot = {
			allocations_eots_id: m.prop('')
			,project_extras_id: m.prop(null)
			,allocation_id: m.prop(null)
			,allocations_eots_description: m.prop('')
			,allocations_eots_escalated: m.prop(false)
			,allocations_eots_clause: m.prop(null)
			,allocations_eots_approved: m.prop(false)
			,user_id: m.prop(data.auth.stream().user_id)
			,midfix: 'eots'
		}
		return eot
	}

	const userResCrew = data.scoped([nullCrew])
	const dbp_vm = m.prop()

	function working_contractors(allocation){
		const contractor_list =
			R.map(
				R.invoker(0, 'allocations_contractors_name')
			)(allocation.allocations_contractors)

		return contractor_list.join(', ')
	}


	function working_crews(allocation){
		const crew_list = []
		allocation.allocations_contractors.forEach((contractor) => {
			const c_list =
				R.map(R.invoker(0, 'allocations_contractors_crews_name'))
					(contractor.allocations_contractors_crews)
			crew_list.push(...c_list)
		})
		return crew_list.join(', ')
	}

	const project_box = data.scoped('')
	const discipline_box = data.scoped('')
	const viewWindow = data.scoped('All Dates')

	const today = new Date( new Date().setHours(0,0,0,1) )
	const localToday = today.setMinutes( - today.getTimezoneOffset() )
	const localTodayStart = new Date(localToday).setHours(0,0,0,1)
	const localTodayEnd = new Date(localToday).setHours(23,59,59,999)

	const shortDates = m.prop([
		{ name:'Today'
		, dateA: localTodayStart
		, dateB: localTodayEnd
		}
		,{name: 'Tomorrow'
		, dateA: localTodayStart + milliperday * 1
		, dateB: localTodayEnd + milliperday * 1
		}
		,{name: 'The Week'
		, dateA: localTodayStart
		, dateB: localTodayEnd + milliperday * 7
		}
		,{name: 'All Dates'
		, dateA: 0
		, dateB: Infinity
		}
	])

	const CONSTANTProjectExtras = data.scoped([])
	const projectDetails = data.scoped([])

	SelectedProject.map((p) => {
		projectMetaDataLoading(true)

		timesheet_vm().selected.timesheet = []
		eotDeletions([])
		nodDeletions([])
		varDeletions([])
		vm().selected.allocation = {}
		vm().revert = {}
		allocations([])
		deleteInvoiceItems([])
		projectDetails([])
		baseProjectDetails([])

		data.currentRoute('Nothing')
		return !p.project_id
			? Promise.resolve({}).then(() => projectMetaDataLoading(false))
			: Promise.all([
				data.fetchProjectsByProjectId(
					{
						project_id: p.project_id()
						,schedule_id: data.schedule_id()
						,depth:1
						,props: {
							projects: [
								'project_documents'
								,'project_id'
							]
						}
					}
				)
				,p.project_id() ? data.fetchProjectContacts({project_id: p.project_id()}) : []
			])
			.then(([md, pc]) => {
				if( md && md.project_id ){
					projectMetaData(
						R.merge(
							data.api.APIProjectToProject(md)
							,{project_contacts: pc }
						)
					)
				}
				activeLandingTab() == 'Tasks' ? retrieveAllocations(false) : null
				return projectMetaDataLoading(false)
			})
			.then(() => {

				const uids =
					!Permissions.hasRead(data.permissions(), 'contract_management')
					? [data.auth.stream().user_id]
					: (
						SelectedProject().project_name == 'This Week'
						|| SelectedProject().project_name == 'Last Week'
						|| SelectedProject().project_name == 'Next Week'
						|| SelectedProject().project_name == 'Today'
						|| SelectedProject().project_name == 'Yesterday'
							? projectDetails.map((p) => p.project_id())
							: [SelectedProject().project_id()]
					)
					.concat([data.auth.stream().user_id])

				data.routeids({
					routeids: uids.filter(R.identity)
					, getledgers: true
					, financialsubsets: []
					, ledgerdetails:true
					, getmatdata: false
					, specificproject:
						SelectedProject().project_name == 'This Week'
						|| SelectedProject().project_name == 'Last Week'
						|| SelectedProject().project_name == 'Next Week'
						|| SelectedProject().project_name == 'Today'
						|| SelectedProject().project_name == 'Yesterday'
							? projectDetails.map((p) => p.project_id()).filter(R.identity)
							: [SelectedProject().project_id()].filter(R.identity)

				})

				return data.currentRoute('updates')
			})
	})

	const SelectedDate =
		viewWindow.map((v) =>
			shortDates().find((d) => d.name == v).dateA
		)

	const SelectedSecondDate =
		viewWindow.map((v) =>
			shortDates().find((d) => d.name == v).dateB
		)

	const sortedAllocations =
		asyncMergeAll(
			[
				SelectedDate
				,SelectedSecondDate
				,SelectedProject
				,SelectedDiscipline
				,allocationsHash
				,discipline_box
				,project_box
				,data.selectedVersion
			]
		)
		.map(([
			dateA
			, dateB
			, project
			, discipline
			, curentAllocations
			, allDisciplines
		]) =>
			(
				!project
					|| !project.project_id
					|| project.project_name == 'All Projects'
					? allocations()
					: curentAllocations[project.project_id()] || []
			)
			.filter((a) => {
				return a.allocations_allocation_date() >= dateA
				&& a.allocations_allocation_date() <= dateB
				&& (
					!discipline.allocations_work_name
					|| a.allocations_work_name()
						== discipline.allocations_work_name()
					|| allDisciplines
				)
			})
		)

	const makeTimesheetCrew = (crew) => {

		const realContractor =
			resources()
				.find((c) => c.contractor_id() == crew.ParentRecognitionID )
		const realCrew =
			realContractor.crews.find((c) => c.crew_id() == crew.crew_id() )

		const subjectContractor =
			!crew.ParentRecognitionID
				? null
				: vm().selected.allocation.allocations_contractors
				.find((chr) => chr.contractor_id() == crew.ParentRecognitionID)

		const subjectCrew =
			!crew.ParentRecognitionID
				? null
				: subjectContractor.allocations_contractors_crews
				.find((chr) => chr.crew_id() == crew.crew_id())

		subjectCrew
			? null
			: subjectContractor.allocations_contractors_crews.push(
				{
					crew_id: m.prop(crew.crew_id())
					,user_id: m.prop(crew.user_id())
					,crews_discipline_rates:
						realCrew.crews_discipline_rates.filter((crate) =>
							crate.organizations_disciplines_id()
							== vm().selected.allocation
								.organizations_disciplines_id()
						)
					,allocations_contractors_crews_crew_rating:
						m.prop(crew.crew_rating())
					,allocations_contractors_crews_crew_common:
						m.prop(crew.crew_common())
					,allocations_contractors_crews_excess:
						m.prop(crew.crew_excess())
					,allocations_contractors_crews_utilized:
						m.prop(crew.crew_utilized())
					,allocations_contractors_crews_invalid:
						m.prop(crew.crew_invalid())
					,allocations_contractors_crews_work_completed:
						m.prop(0)
					,allocations_contractors_crews_work_forecasted:
						m.prop(0)
					,allocations_contractors_crews_work_application:
						m.prop(0)
					,allocations_contractors_crews_name:
						m.prop(crew.crew_name())
					,allocations_contractors_crews_contract_multiplier:
						m.prop(1)
					,allocations_contractors_crews_id:
						m.prop(null)
					,allocations_contractors_id:
						m.prop(null)
					,teamratemultiplier:
						1
				}
			)

		return {
			timesheets_id: m.prop(null)
			,organization_id: m.prop(null)
			,allocation_id: m.prop( vm().selected.allocation.allocation_id() )
			,user_id: m.prop( realCrew.user_id() )
			,crew_id: m.prop( crew.crew_id() )
			,crew_name: m.prop(
				crew.crew_name
				? crew.crew_name()
				: crew.allocations_contractors_crews_name()
			)
			,user_name: m.prop( null )
			,crew_hours: m.prop(0)
			,crew_work: m.prop(
				subjectCrew
					? subjectCrew.allocations_contractors_crews_work_completed()
					: 0
			)
			,work_conditions: m.prop(null)
			,crew_performance: m.prop(null)
		}
	}

	function make_timesheet(crews){
		if (crews != null){
			const uniqueCrews =
				crews.filter((crew) =>
					!timesheet_vm().selected.timesheet
						.find((t) => t.crew_id() == crew.crew_id())
				)

			timesheet_vm().selected.timesheet =
				timesheet_vm().selected.timesheet.concat(
					uniqueCrews.map(makeTimesheetCrew)
				)
			resources(resources())
		}
	}



	function new_user_timesheet(){
		const new_timesheet = {
			timesheets_id: m.prop(null)
			,organization_id: m.prop(null)
			,allocation_id: m.prop( vm().selected.allocation.allocation_id() )
			,user_id: m.prop( data.auth.stream().user_id )
			,crew_id: m.prop( null )
			,crew_name: m.prop( null )
			,user_name: m.prop( data.auth.stream().user_username )
			,crew_hours: m.prop(0)
			,crew_work: m.prop(0)
			,work_conditions: m.prop(null)
			,crew_performance: m.prop(null)
		}
		timesheet_vm().selected.timesheet.unshift(new_timesheet)
	}

	function cloningTimesheet(timesheet){
		const t_clone = []

		if ( timesheet.length > 0 ) {
			timesheet.forEach((t) => {
				const new_timesheet = {
					timesheets_id: m.prop( exposer(t.timesheets_id) )
					,organization_id: m.prop( exposer(t.organization_id) )
					,allocation_id: m.prop( exposer(t.allocation_id) )
					,user_id: m.prop( exposer(t.user_id) )
					,crew_id: m.prop( exposer(t.crew_id) )
					,crew_name: m.prop( exposer(t.crew_name) )
					,user_name: m.prop( exposer(t.user_name) )
					,crew_hours: m.prop( Number( exposer(t.crew_hours)).toFixed(2) )
					,crew_work: m.prop( Number( exposer(t.crew_work)).toFixed(2) )
					,work_conditions: m.prop( exposer(t.work_conditions) )
					,crew_performance: m.prop( exposer(t.crew_performance) || '' )
					,organizations_disciplines_id: m.prop( exposer(t.organizations_disciplines_id) )
					,sharedtimesheet: t.sharedtimesheet
				}
				t_clone.push(new_timesheet)
			})
		}
		return t_clone
	}

	function cloningACC(new_ac, acc){

		const new_acc = {
			crews_discipline_rates:
				acc.crews_discipline_rates
			,allocations_contractors_crews_excess:
				m.prop( acc.allocations_contractors_crews_excess() )
			,allocations_contractors_crews_utilized:
				m.prop( acc.allocations_contractors_crews_utilized() )
			,allocations_contractors_crews_invalid:
				m.prop( acc.allocations_contractors_crews_invalid() )
			,allocations_contractors_crews_id:
				m.prop( acc.allocations_contractors_crews_id() )
			,allocations_contractors_id:
				m.prop( acc.allocations_contractors_id() )
			,crew_id:
				m.prop( acc.crew_id() )
			,allocations_contractors_crews_name:
				m.prop( acc.allocations_contractors_crews_name() )
			,allocations_contractors_crews_contract_multiplier:
				m.prop( acc.allocations_contractors_crews_contract_multiplier())
			,allocations_contractors_crews_work_completed:
				m.prop( acc.allocations_contractors_crews_work_completed() )
			,allocations_contractors_crews_work_forecasted:
				m.prop( acc.allocations_contractors_crews_work_forecasted() )
			,allocations_contractors_crews_work_application:
				m.prop( acc.allocations_contractors_crews_work_application() )
			,allocations_contractors_crews_crew_rating:
				m.prop( acc.allocations_contractors_crews_crew_rating() )
			,allocations_contractors_crews_crew_common:
				m.prop( acc.allocations_contractors_crews_crew_common() )
			,teamratemultiplier:
				acc.teamratemultiplier
			,user_id:
				m.prop(acc.user_id())
		}

		forecastedWork(
			forecastedWork()
				+ acc.allocations_contractors_crews_work_forecasted()
				* acc.allocations_contractors_crews_work_application()

		)

		new_ac.allocations_contractors_crews.push(new_acc)

	}

	function cloningAllocation(allocation){
		contractList([])
		forecastedWork(0)
		const a_clone = {
			schedule_id:
				m.prop(allocation.schedule_id())
			,organizations_disciplines_id:
				m.prop(allocation.organizations_disciplines_id())
			,allocations_completion_comments:
				m.prop( allocation.allocations_completion_comments() )
			,organization_id:
				m.prop( allocation.organization_id() )
			,allocation_id:
				m.prop( allocation.allocation_id() )
			,project_id:
				m.prop( allocation.project_id() )
			,discipline_id:
				m.prop( allocation.discipline_id() )
			,project_name:
				allocation.project_name
			,allocations_work_name:
				m.prop( allocation.allocations_work_name() )
			,allocations_crew_required:
				m.prop( allocation.allocations_crew_required() )
			,allocations_project_harshness:
				m.prop( allocation.allocations_project_harshness() )
			,allocations_custom_status:
				m.prop( allocation.allocations_custom_status() )
			,allocations_allocation_date:
				m.prop( allocation.allocations_allocation_date() )
			,allocations_bad_day:
				m.prop( allocation.allocations_bad_day() )
			,allocations_day_status:
				m.prop( allocation.allocations_day_status() )
			,allocations_weather_status:
				m.prop( allocation.allocations_weather_status() )
			,allocations_allocation_complete:
				m.prop( allocation.allocations_allocation_complete() )
			,allocations_allocation_forecast:
				m.prop( allocation.allocations_allocation_forecast() )
			,allocations_version_key:
				m.prop( allocation.allocations_version_key() )
			,schedule_version_id:
				m.prop( allocation.schedule_version_id() )
			,discplineNameDescription:
				allocation.discplineNameDescription
			,discipline_description:
				allocation.discipline_description
			,disciplineratemultiplier:
				allocation.disciplineratemultiplier
			,allocations_contractors:
				[]
			,allocations_eots:
				[]
			,allocations_vars:
				[]
			,allocations_nods:
				[]
			,allocations_transposes:
				[]
		}
		allocation.allocations_contractors.forEach((ac) => {
			const new_ac = {
				contractor_id:
					m.prop( ac.contractor_id() )
				,allocation_id:
					m.prop( ac.allocation_id() )
				,allocations_contractors_id:
					m.prop( ac.allocations_contractors_id() )
				,allocations_contractors_name:
					m.prop( ac.allocations_contractors_name() )
				,allocations_contractors_utilized:
					m.prop( ac.allocations_contractors_utilized() )
				,allocations_contractors_total_rating:
					m.prop( ac.allocations_contractors_total_rating() )
				,allocations_contractors_quality_rating:
					m.prop( ac.allocations_contractors_quality_rating() )
				,allocations_contractors_common:
					m.prop( ac.allocations_contractors_common() )
				,allocations_contractors_mixed_crews:
					m.prop( ac.allocations_contractors_mixed_crews() )
				,allocations_contractors_crews:
					[]
				,user_id:
					m.prop(ac.user_id())
			}
			ac.allocations_contractors_crews.forEach((acc) => {
				cloningACC(new_ac, acc)
			})
			a_clone.allocations_contractors.push(new_ac)
		})
		allocation.allocations_nods.forEach((contract_item) => {
			const new_n ={
				allocations_nods_id:
					m.prop( contract_item.allocations_nods_id() )
				,project_extras_id:
					m.prop( contract_item.project_extras_id() )
				,allocation_id:
					m.prop( contract_item.allocation_id() )
				,allocations_nods_description:
					m.prop( contract_item.allocations_nods_description() )
				,allocations_nods_escalated:
					m.prop( contract_item.allocations_nods_escalated() )
				,allocations_nods_clause:
					m.prop( contract_item.allocations_nods_clause() )
				,midfix:
					'nods'
				,allocations_nods_approved:
					m.prop(contract_item.allocations_nods_approved())
				,user_id:
					m.prop(contract_item.user_id())
			}
			a_clone.allocations_nods.push(new_n)
			contractList().push(new_n)
		})
		allocation.allocations_eots.forEach((contract_item) => {
			const new_e ={
				allocations_eots_id:
					m.prop( contract_item.allocations_eots_id() )
				,project_extras_id:
					m.prop(  contract_item.project_extras_id() )
				,allocation_id:
					m.prop( contract_item.allocation_id() )
				,allocations_eots_description:
					m.prop( contract_item.allocations_eots_description() )
				,allocations_eots_escalated:
					m.prop( contract_item.allocations_eots_escalated() )
				,allocations_eots_clause:
					m.prop( contract_item.allocations_eots_clause() )
				,midfix:
					'eots'
				,allocations_eots_approved:
					m.prop(contract_item.allocations_eots_approved())
				,user_id:
					m.prop(contract_item.user_id())
			}
			a_clone.allocations_eots.push(new_e)
			contractList().push(new_e)
		})
		allocation.allocations_vars.forEach((contract_item) => {
			const new_v ={
				allocations_vars_id:
					m.prop( contract_item.allocations_vars_id() )
				,project_extras_id:
					m.prop( contract_item.project_extras_id() )
				,allocation_id:
					m.prop( contract_item.allocation_id() )
				,allocations_vars_description:
					m.prop( contract_item.allocations_vars_description() )
				,allocations_vars_escalated:
					m.prop( contract_item.allocations_vars_escalated() )
				,allocations_vars_clause:
					m.prop( contract_item.allocations_vars_clause() )
				,allocations_vars_amount:
					m.prop( contract_item.allocations_vars_amount() )
				,midfix:
					'vars'
				,allocations_vars_approved:
					m.prop(contract_item.allocations_vars_approved())
				,user_id:
					m.prop(contract_item.user_id())
			}
			a_clone.allocations_vars.push(new_v)
			contractList().push(new_v)
		})


		setContractOptions(a_clone)

		return a_clone
	}

	const timesheets = data.scoped([])
	// const fetchtimesheets =
		allocationChange.map((ac) => {
			ac ? fetchedTime(false) : null
			const allocation_id =
				vm().selected.allocation.allocation_id
				&& vm().selected.allocation.allocation_id()
				|| selectedProjectExtra().allocation_id
				&& selectedProjectExtra().allocation_id()

			return !ac
				? null
				: Promise.resolve(
					allocation_id
						? Promise.all([
							data.fetchTimesheetsByAllocation({
								allocation_id: allocation_id
								,sharedtimesheet: 'all'
							})
						])
						: [[]]
				)
					.then(function([result]){
						timesheets(result)
						timesheet_vm().selected.timesheet =
							cloningTimesheet( result )
						fetchedTime(true)
						return true
					})
		})


	// const sortContractorCrews =
		asyncMergeAll(
			[
				resources
			]
		)
		.map(() => {
			const allocation = vm().selected.allocation

			if (vm().selected.allocation.allocation_id){
				crewsIndexedByAID(
					R.indexBy(
						(a) => a.user_id() || a.crew_id()
						,R.unnest(
							allocation.allocations_contractors.map((ac) =>
								ac.allocations_contractors_crews
							)
						)
					)
				)

				crewWorkers(
					R.unnest(
						vm().selected.allocation.allocations_contractors
							.map((ac) =>
								ac.allocations_contractors_crews
							)
					)
				)

				const crewlist =
					R.differenceWith(
						(a,b) => a.crew_id() == b.crew_id()
						,R.chain(
							(c) => c.user_id() == data.auth.stream().user_id
								|| editTimePermissions()
								? c.crews
								: c.crews.filter(
									(crew) =>
										crew.user_id()
										== data.auth.stream().user_id
								)
							,!resources().length
								? []
								: allocation.allocations_contractors
								.map((ac) =>
									resources().find((con) =>
										con.contractor_id()
											== ac.contractor_id()
									)
								)
						)
						,timesheet_vm().selected.timesheet
					)

				timesheetlist(crewlist)
			}

			return true
		})


	function DisableEmptySave(returnBoolean){
		const allocation = vm().selected.allocation
		const counteots = R.countBy( chr =>
			chr.allocations_eots_description()
			, allocation.allocations_eots || []
		)
		const countnods = R.countBy( chr =>
			chr.allocations_nods_description()
			, allocation.allocations_nods || []
		)
		const countvars = R.countBy( chr =>
			chr.allocations_vars_description()
			, allocation.allocations_vars || []
		)
		const errorArray = [
			(d) => !d.allocations_eots_description()
				? `Extension of Time Descriptions`
				: null
			,(d) => !d.allocations_nods_description()
				? `Notification of Delay Descriptions`
				: null
			// ,(d) => !d.allocations_vars_description()
			// 	? `Variation Descriptions`
			// 	: null
			,(d, prop='allocations_eots_description') =>
				d[prop] && counteots[d[prop]()] > 1
					? `Extension of Time Descriptions are duplicated, check `
						+ d[prop]()
					: null
			,(d, prop='allocations_nods_description') =>
				d[prop] && countnods[d[prop]()] > 1
					? `Notification of Delay Descriptions are duplicated, check `
						+ d[prop]()
					: null
			,(d, prop='allocations_vars_description') =>
				d[prop] && countvars[d[prop]()] > 1
					? `Variation Descriptions are duplicated, check `
						+ d[prop]()
					: null
		]

		return elements.errorAlert(
			`To save these contract items `
			,` and `
			,` must not be empty`
			,[
				allocation.allocations_eots || []
				,allocation.allocations_nods || []
				,allocation.allocations_vars || []
				,allocation.allocations_eots || []
				,allocation.allocations_nods || []
				,allocation.allocations_vars || []
			]
			,errorArray
			,returnBoolean
			,true
		)
	}

	function DisableDuplicateSave(returnBoolean){
		const allocation = vm().selected.allocation
		const uniqAllocation =
			(a) => [
				a.project_id && a.project_id()
				,a.discipline_id && a.discipline_id()
				,a.allocations_allocation_date && a.allocations_allocation_date()
			].join()

		const allocationIndex =
			R.countBy((chr) =>
				uniqAllocation(chr)
				, sortedAllocations()
					.filter((r) =>
						allocation.allocation_id
						&& r.allocation_id()
						!= allocation.allocation_id()
					)
					.concat(allocation)
			)

		const errorArray = [
			(d) =>
				d.allocations_allocation_date
				&& d.allocations_allocation_date() > localTodayEnd
				&& currentVersion()
				? `update the Completed Work Date to be today or sometime in the past`
				: null
			, d => STRING.ERROR.DUPLICATED_COMPLETED_WORK_DATE({
				d, allocationIndex, uniqAllocation
			})
		]

		return elements.errorAlert(
			`To save and report this progress, `
			,` and `
			,``
			,[allocation]
			,errorArray
			,returnBoolean
			,false
			,'warning'
			,null
			,[].concat(
				Object.keys(errors())
				.map((a) =>
					errors()[a]
						? a + ' ' + errors()[a]
						: ''
				)
				.filter((a) => a)
			,!currentVersion()
				? [
					`Change to the latest schedule version to report updates`
				]
				: []
			)
		)
	}

	const currentVersion = data.scoped(false)

	const ActiveContractsFeature = function(){
		return loading() == null
			? modules().find((module) =>
				module.modules_name()
				== 'Contract Obligation Tools'
			).modules_active()
			: true
	}
	const ActiveDetailsFeature = function(){
		return loading() == null
			? modules().find((module) =>
				module.modules_name()
				== 'Project Tasks'
			).modules_active()
			: true
	}


	crewWorkers.map((wList) =>
		!vm().selected.allocation.allocations_allocation_complete
		? 0
		: vm().selected.allocation.allocations_allocation_complete(
			R.max(
				R.sum(
					wList.map(
						R.pipe(
							R.prop('allocations_contractors_crews_work_completed')
							,R.call
						)
					)
				)
				,vm().selected.allocation.allocations_allocation_complete()
			)
		)
	)

	const completedWork =
		allocationChange.map(() =>
			vm().selected.allocation.allocations_allocation_complete
				? vm().selected.allocation.allocations_allocation_complete()
				: 0
		)


	const workDifference =
		completedWork.map((cWork) =>
			vm().revert.allocations_allocation_complete
				? cWork - Number(vm().revert.allocations_allocation_complete())
				: 0
		)

	completedWork.map((totalValue) => {
		if (
			totalValue
			!= R.sum(
				crewWorkers()
					.map( R.pipe(
						R.prop('allocations_contractors_crews_work_completed')
						, R.call
					))
				)
			&& ( totalValue || totalValue == 0 )
			&& crewWorkers().length
		){

			const averageWork =
				totalValue == 0
				? 0
				: Number(totalValue/crewWorkers().length).toFixed(2)

			crewWorkers()
			.forEach((w) => {
				w.allocations_contractors_crews_work_completed(averageWork)
				const timeUpdate =
					timesheet_vm().selected.timesheet
						.find((t) => t.crew_id() == w.crew_id())
				timeUpdate
					? timeUpdate
						.crew_work(
							w.allocations_contractors_crews_work_completed()
						)
					: null
			})

		}

		vm().selected.allocation.allocations_allocation_complete
		? vm().selected.allocation.allocations_allocation_complete(totalValue)
		: null

		return true
	})


	function addSpecificWork(ts, v){

		if ( v != null ){

			const focusCrew = ts.user_id()
				? crewsIndexedByAID()[ts.user_id()]
				: crewsIndexedByAID()[ts.crew_id()]


			ts.crew_work( v )
			focusCrew
				? focusCrew.allocations_contractors_crews_work_completed( v )
				: null

			crewWorkers(crewWorkers())
		}

		return ts.crew_work()
	}

	function ContractAddButton(contractType){
		const a = vm().selected.allocation
		contractType == 'Variation'
			? 				a.allocations_vars.unshift(resetVARS())
			 	&& contractvm(a.allocations_vars[0])

			: contractType == 'Notice of Delay'
				?					a.allocations_nods.unshift(resetNODS())
					&& contractvm(a.allocations_nods[0])

				: a.allocations_eots.unshift(resetEOTS())
					&& contractvm(a.allocations_eots[0])

		contractIndex(0)
		setContractOptions(a)
		consolidateContractList(a)
	}

    function getActivateDeleteButton(contractItem, returnTRUE){
		const descriptionprop =
			'allocations_' + contractItem.midfix + '_description'
		const idprop =
			'allocations_' + contractItem.midfix + '_id'

        const descriptionSelected = contractvm()[descriptionprop]
		const idSelected = contractvm()[idprop]
        const activated =
			idSelected
			&& idSelected() + descriptionSelected()
				== contractItem[idprop]() + contractItem[descriptionprop]()

        return returnTRUE
			? activated
			:  activated
				? m('button.btn', {
					onclick: () => { contractvm({}) }
					,title: "Confirm Changes"
				}, 'Done')
				: m('button.btn', {
					onclick: () => { contractvm(contractItem) }
					,title: "Option to delete this tool"
				}, 'Edit')
    }

	function ContractDeleteButton(){
		return currentVersion()
			? m("button.btn.btn-warning", {
					disabled: !contractvm().midfix
					|| !editUpdatePermissions()
					|| !currentVersion()
					,onclick: () => {
						const a = vm().selected.allocation


						contractvm().allocations_vars_description
							? 								a.allocations_vars.splice(
									a.allocations_vars
										.findIndex((c) =>
											c.allocations_vars_description()
											== contractvm().allocations_vars_description()
										)
									, 1
								)
								&& contractvm().allocations_vars_id()
								&& varDeletions().push(contractvm().allocations_vars_id())
								&& delete errors()
									[contractvm().allocations_vars_description() + ' +/- Work']

							: contractvm().allocations_nods_description
								? 									a.allocations_nods.splice(
										a.allocations_nods
											.findIndex((c) =>
												c.allocations_nods_description()
												== contractvm().allocations_nods_description()
											)
										, 1
									)
									&& contractvm().allocations_nods_id()
									&& nodDeletions().push(contractvm().allocations_nods_id())

								:									a.allocations_eots.splice(
										a.allocations_eots
											.findIndex((c) =>
												c.allocations_eots_description()
												== contractvm().allocations_eots_description()
											)
										, 1
									)
									&& contractvm().allocations_eots_id()
									&& eotDeletions().push(contractvm().allocations_eots_id())

						setContractOptions(a)
						contractvm({})
						consolidateContractList(a)
						if(!fullView()){
							contractIndex() > contractList().length - 1
								? contractIndex(contractList().length - 1)
								: null
							!contractList()[contractIndex()]
								? contractvm({})
								: contractvm(contractList()[contractIndex()])
						}
					}
					,title: 'Delete a Contract item'
				}
				, "Discard"
			)
			: null
	}


	const toolContracts =
		data.scoped(data.allContractItems().filter((cItems) => cItems.tools_id))

	const disciplineCalculated =
		asyncMergeAll(
			[
				data.coalatedSelectedVersion
				,allocationChange
			]
		)
		.map(([cd]) =>
			vm().selected.allocation.discipline_id
				? cd[vm().selected.allocation.discipline_id()]
				: null
		)

	const projectCalculated =
		asyncMergeAll(
			[
				data.coalatedSelectedVersion
				,allocationChange
			]
		)
		.map(([cd]) =>
			vm().selected.allocation.project_id
				? cd[vm().selected.allocation.project_id()]
				: null
		)

	const userDayContracts =
		data.auth.stream.map((auth) =>
			data.readContractPermissions()
				? data.allUserContractItems()
				: data.allContractItemsIndexByItemID()[
					auth.user_id
				] || []
		)

	const allocatioCrewnData =
		allocationChange.map(() =>
			vm().selected.allocation.allocations_contractors
				? R.unnest(
					vm().selected.allocation.allocations_contractors
					.map((ac) =>
						ac.allocations_contractors_crews
					)
				)
				: []
		)

	const allocatioCrewOldData =
		allocationChange.map(() =>
			vm().revert.allocations_contractors
				? R.unnest(
					vm().revert.allocations_contractors
					.map((ac) =>
						ac.allocations_contractors_crews
					)
				)
				: []
		)

	const crewDayContracts =
		allocatioCrewnData.map((acd) =>
			R.unnest(
				acd.map((acc) =>
					acc.user_id()
					== data.auth.stream().user_id
					|| data.readContractPermissions()

					? data.allContractItemsIndexByItemID()[acc.crew_id()] || []
					: []
				)
			)
		)

	const crewWorkContracts =
		allocatioCrewnData.map((acd) =>
			R.unnest(
				acd.map((acc) =>
					(
						acc.user_id()
						== data.auth.stream().user_id
						|| data.readContractPermissions()
					)
					&& acc.crews_discipline_rates.length
						? data.allContractItemsIndexByItemID()[
							acc.crews_discipline_rates[0]
							.crews_discipline_rates_id
						] || []
						: []
				)
			)
		)

	const resourceContracts =
		allocationChange.map(() =>
			vm().selected.allocation.allocations_contractors
				? R.unnest(
					vm().selected.allocation.allocations_contractors
						.map((ac) =>

								ac.user_id()
								== data.auth.stream().user_id
								|| data.readContractPermissions()

								? data.allContractItemsIndexByItemID()[ac.contractor_id()] || []
								: []
						)
				)
				: []
		)


	const currentProject =
		allocationChange.map(() =>
			vm().selected.allocation.allocations_contractors
				? vm().selected.allocation
				: {}
		)

	// const workEvaluation =
		asyncMergeAll(
			[
				currentProject
				,allocationChange
			]
		)
		.map(() =>
			R.indexBy(
				(a) => a.discipline_id()
				,disciplineExtraOptions()
					.map((d) => {

						d.DisciplineWork =
							R.sum(
								(allocationsHash()[d.project_id()] || [] )
								.filter((a) =>
									d.discipline_id()
									== a.discipline_id()
								)
								.map((a) => a.allocations_allocation_forecast() )
							)

						d.DisciplineCompleted =
							R.sum(
								(allocationsHash()[d.project_id()] || [] )
								.filter((a) =>
									d.discipline_id()
									== a.discipline_id()
								)
								.map((a) => a.allocations_allocation_complete() )
							)

						return d
					})
			)
	)

	const currentDiscipline =
		currentProject.map(() =>
			vm().selected.allocation.allocations_contractors
				? vm().selected.allocation
				: {}
		)

	const fetchedExtras = data.scoped(false)
	const projectExtrasStat =
		prop.scan(
			function(p, [, f]){
				// console.log('resetter', f)
				return !f
					? {
						project_extras_date_assigned: 0
						,project_extras_date_completed: 0
						,project_extras_date_verified: 0
						,project_extras_date_invalid: 0
					}
					: p
			}
			,{}
			,asyncMergeAll(
				[
					allocationChange
					,fetchedExtras
				]
			)
		)

	function projectExtrasSum(pE, onlyprop){
		[
			'project_extras_date_assigned'
			,'project_extras_date_completed'
			,'project_extras_date_verified'
			,'project_extras_date_invalid'
		]
		.filter((theprops) => theprops == onlyprop || !onlyprop)
		.forEach((prop) => {
			pE[prop]()
				? projectExtrasStat()[prop] =
					projectExtrasStat()[prop] + 1
				: onlyprop
					? projectExtrasStat()[prop] =
						projectExtrasStat()[prop] - 1
					: null
		})
	}

	const projectExtrasTypes =
		data.scoped(
			{
				completed: ''
				, invalid: ''
				, verified: ''
				, assigned: 'all'
			}
		)

	const cloningProjectExtra = (pe) => ({
		project_id:
			m.prop(pe.project_id())
		,organizations_disciplines_id:
			pe.organizations_disciplines_id
		,discipline_id:
			m.prop(pe.discipline_id())
		,relatedDiscipline:
			pe.relatedDiscipline
		,parents: m.prop(pe.parents())
		,users: m.prop([])
		,project_extras_id:
			m.prop(pe.project_extras_id())
		,project_extras_planned_amount:
			m.prop(pe.project_extras_planned_amount())
		,project_extras_actual_amount:
			m.prop(pe.project_extras_actual_amount())
		,user_id:
			m.prop(pe.user_id())
		,allocation_id:
			m.prop(pe.allocation_id())
		,username:
			pe.username
		,revenueinvoices:
			pe.revenueinvoices
		,costinvoices:
			pe.costinvoices
		,invite_email: pe.invite_email
		,project_extras_type:
			m.prop(pe.project_extras_type())
		,project_extras_frequency:
			m.prop(pe.project_extras_frequency())
		,project_extras_custom:
			m.prop(pe.project_extras_custom())
		,project_extras_description:
			m.prop(pe.project_extras_description())
		,project_extras_date_assigned:
			m.prop(pe.project_extras_date_assigned())
		,project_extras_date_completed:
			m.prop(pe.project_extras_date_completed())
		,project_extras_priority:
			m.prop(pe.project_extras_priority())
		,project_extras_date_verified:
			m.prop(pe.project_extras_date_verified())
		,project_extras_date_invalid:
			m.prop(pe.project_extras_date_invalid())
		,project_extras_notes:
			m.prop(pe.project_extras_notes())
		,project_extras_address:
			m.prop(pe.project_extras_address())
		,organization_id:
			m.prop(data.initiateContractFetch().organization_id)
		,contract_id:
			pe.contract_id
		,key: Math.random().toString(15).slice(2, 8)
		,filecount: pe.filecount
		,project_name: pe.project_name
		,invite_id: pe.invite_id
		,schedule_id: pe.schedule_id
	})

	// const fetchprojectDetails =
		prop.scan(
			function(previous, [
				allocationD
				, activeTab
				, activeLandingTab
				, SelectedDiscipline
				, SelectedProject
				, peT
				// , dbox
				, fView
			]){

				const gettasks =
					allocationD.discipline_id
					&& allocationD.discipline_id()
					&& !fetchedExtras()
					&& activeTab == 'Project Tasks'
					&& fView

					|| activeLandingTab == 'Tasks'
					&& !fetchedExtras()
					&& SelectedProject.project_name

				if( gettasks ){
					fetchedExtras(true)
					materials({actual: [], suggested: []})
				}

				const peList =
					gettasks
					? Promise.all([
						data.fetchProjectExtras(
							R.merge(
								dateframeprojects()
								,{
									project_id:
										SelectedProject.project_id
										&& SelectedProject.project_id()
									,discipline_id: SelectedDiscipline.discipline_id
										? SelectedDiscipline.discipline_id()
										: allocationD.discipline_id && fView
										? allocationD.discipline_id()
										: null
									,f: fetchedExtras
									,pcomplete: `not completed`
									,typesObj: !fView
										? peT
										: {
											completed: 'any'
											, invalid: 'any'
											, verified: 'any'
											, assigned: 'any'
										}
									,uniqBy: ''
								}
							)
						)
					])
						.then(function([result]){

							prevtasks([])

							const eView =
								projectDetails()
								.filter(R.prop('created'))

							const sView =
								R.differenceWith(
									(a, b) => a.project_extras_id()
									== b.project_extras_id()
									,result
									,eView
								)

							const beView =
								baseProjectDetails()
								.filter((t) =>
									eView.find((ft) =>
										ft.project_extras_id
										== t.project_extras_id
									)
								)

							eView.map((t) => projectExtrasSum(t, null))

							disciplineOptions(
								[].concat(
									allDisciplines
									,R.uniqBy(
										(a) =>
										typeof a.organizations_disciplines_id == 'function'
										? a.organizations_disciplines_id()
										: a.organizations_disciplines_id
										,result
									)
									.map(
										R.tap((t) =>
											t.discplineNameDescription = t.relatedDiscipline
										)
									)
								)
							)

							baseProjectDetails(beView.concat(sView))
							projectDetails(
								eView.concat(sView)
								.map((pe) => {
									projectExtrasSum(pe, null)
									return cloningProjectExtra(pe)
								})
							)
							m.redraw()
							fetchedExtras(true)
							masterTaskList(true)
							return result
						})
					: previous

				return peList
			}
			,[]
			,asyncMergeAll(
				[
					currentDiscipline
					,activeTab
					,activeLandingTab
					,SelectedDiscipline
					,SelectedProject
					,projectExtrasTypes
					// ,discipline_box
					,fullView
				]
			)
		)

	const projectNotes =
		asyncMergeAll(
			[
				SelectedProject
				,SelectedDiscipline
				,allocationsHash
				,activeLandingTab
				,CONSTANTProjectExtras
			]
		)
		.map(([p, ,a,l,nt]) =>
			!p.project_id
			? []
			: l == 'Tasks'
			? R.groupWith(
				(t, a) => t.project_extras_type() == a.project_extras_type()
				,R.sortBy(
					(t) => t.project_extras_type()
					,nt.filter((t) =>
						t.project_extras_notes()
						|| t.project_extras_date_completed()
						|| t.project_extras_date_invalid()
					)
				)
			)
			.map((st) =>
				[st[0].project_extras_type() + ' - '
				,st.map((t) => [(t.relatedDiscipline || "") + ' x ' + t.project_extras_actual_amount() + ' - ', t.project_extras_notes() || ""] )]
			)
			: !a[p.project_id()]
			? []
			: R.groupWith(
				(t, a) => t.allocations_allocation_date() == a.allocations_allocation_date()
				,R.sortBy(
					(t) => t.allocations_allocation_date()
					,a[p.project_id()]
					.filter((a) => a.allocations_completion_comments() || nt.find((t) => t.allocation_id() == a.allocation_id()))
				)
			)
			.map((a) => {
				const repDate = new Date( a[0].allocations_allocation_date() )
				const localrepDate = new Date(repDate.setMinutes( + repDate.getTimezoneOffset())).toString(15)
				return [
					moment(localrepDate).format("ll") + ' - '
					,a.map((ac) => ac.allocations_completion_comments())
					.filter(R.identity)
					.map((ac) => [ac, ''])
					.concat(
						R.uniqBy(
							(t) => t.project_extras_type() + t.relatedDiscipline
							,R.sortBy(
								(t) => t.project_extras_type()
								,nt.filter((t) =>
									t.project_extras_date_completed()
									&& a.find((aa) => aa.allocation_id() == t.allocation_id())
								)
							)
						)
						.map((t) => [t.project_extras_type() + ' - ' +  t.relatedDiscipline, t.project_extras_notes()])
					)
				]
			})
		)

	function DisableProjectExtraSave(returnBoolean){
		const errorArray = [
			(d) => d && !d.project_extras_type()
				? `Task Name`
				: null
			,(d) =>
				d
				&& !d.organizations_disciplines_id
				&& d.newtask
				? `Related Discipline`
				: null
		]
		return elements.errorAlert(
			`To save these project tasks `
			,` and `
			,` must not be empty`
			,projectDetails()
			,errorArray
			,returnBoolean
			,false
			,'warning'
			,null
			,[].concat(
				Object.keys(errors())
				.map((a) =>
					errors()[a]
						? a + ' ' + errors()[a]
						: ''
				)
				.filter((a) => a)
			)
		)
	}

	asyncMergeAll(
		[
			data.calculated
			,errors
			,baseProjectDetails
			,projectDetails
			,timesheets
			,allocationsHash
			,sortedAllocations
			,resources
			,childResUsers
			,allocations
		]
	)
	.map(() => {
		// eslint-disable-next-line no-undef
		setTimeout(function(){
			m.redraw()
		}, 0)

		return null
	})


	const projectContracts =
		allocationChange.map(() =>
			vm().selected.allocation.discipline_id
				? data.allContractItemsIndexByItemID()[
					vm().selected.allocation.project_id()
				] || []
				: []
		)

	const disciplineContract =
		allocationChange.map(() =>
			vm().selected.allocation.project_id
				? data.allContractItemsIndexByItemID()[
					vm().selected.allocation.organizations_disciplines_id()
				] || []
				: []
		)



	const contractInvoiceables =
		asyncMergeAll(
			[
				toolContracts
				,projectContracts
				,resourceContracts
				,disciplineContract
				,crewDayContracts
				,userDayContracts
				,crewWorkContracts
			]
		)
		.map((contractData) =>
			R.unnest(
				contractData
			)
		)

	// ADD MULTIPLIER HOW
	const newDollarsArray =
		asyncMergeAll(
			[
				userDayContracts
				,crewDayContracts
				,crewWorkContracts
				,disciplineContract
				,todaysInvoices
				,workDifference
			]
		).map(([
			userDayContracts
			,crewDayContracts
			,crewWorkContracts
			,disciplineContract
			,todaysInvoices
		]) =>
			vm().selected.allocation.allocations_allocation_date
				? R.unnest(
					[].concat(
						userDayContracts
						,crewDayContracts
						,crewWorkContracts
						,disciplineContract
						,todaysInvoices
					)
					.filter((cItems) => cItems.contract_items_applied != false )
					.map((contractItem) => {

						// when resources are deleted and old versions still
						// have resources the below errors
						// at crews_discipline_rates[0]
						const fAmount =
							!contractItem.crew_id
							&& !contractItem.user_id
								? contractItem.organizations_disciplines_id
									? R.sum(
										allocatioCrewnData().map((acc) =>
											acc.allocations_contractors_crews_work_forecasted()
											* acc.allocations_contractors_crews_work_application()
											|| 0
										)
									)
									: allocatioCrewnData().find((acc) =>
										acc.crews_discipline_rates[0]
										&& acc.crews_discipline_rates[0].crews_discipline_rates_id
										== contractItem.crews_discipline_rates_id
									) || {
										allocations_contractors_crews_work_forecasted: m.prop(0)
										,allocations_contractors_crews_work_application: m.prop(1)
									}
								: contractItem.contract_items_recorded_reference_standard

						return data.criteriaBasedCalculation(
							contractItem
							, contractItem.invoice_id
								? {
									incriment: 'invoice'
									,forecastedWorkAmount: null
									,forecastedDamagesAmount: null
								}
								: {
									incriment: 'contract'
									,forecastedWorkAmount:
										!contractItem.crew_id && !contractItem.user_id
											? contractItem.organizations_disciplines_id
												? fAmount
												: fAmount
													.allocations_contractors_crews_work_forecasted()
												* fAmount
													.allocations_contractors_crews_work_application()
											: contractItem.contract_items_recorded_reference_standard

									,accruedAmount:
										!contractItem.crew_id
										&& !contractItem.user_id
											? contractItem.organizations_disciplines_id
												? R.sum(
													allocatioCrewnData()
													.map((acc) =>
														Number(
															acc.allocations_contractors_crews_work_completed()
														)
														|| 0
													)
												)
												: Number((
													allocatioCrewnData().find((acc) =>
														acc.crews_discipline_rates[0]
															.crews_discipline_rates_id
														== contractItem.crews_discipline_rates_id
													)
													|| {
														allocations_contractors_crews_work_completed:
															m.prop(0)
													}
												).allocations_contractors_crews_work_completed())

											: contractItem.contract_items_recorded_reference
												!= 'Timesheets'
												? 1
												: R.sum(
													timesheet_vm().selected.timesheet
													.filter((t) =>
														contractItem.crew_id
														? t.crew_id() == contractItem.crew_id
														: t.user_id() == contractItem.user_id
													)
													.map((t) => t.crew_hours() )
												)

									,forecastedDamagesAmount:
										vm().selected.allocation.allocations_allocation_date()
										>= new Date(contractItem.contractEnd).getTime()
										&& vm().selected.allocation.allocations_allocation_date()
										<= new Date(contractItem.damagesEnd).getTime()
											? 1
											: 0
								}
						)
					})
				)
				: []
		)

	const oldDollarsArray =
		OldDollarsArray({
			userDayContracts
			,crewDayContracts
			,crewWorkContracts
			,disciplineContract
			,todaysInvoices
			,workDifference
			,allocatioCrewnData
			,criteriaBasedCalculation: data.criteriaBasedCalculation
			,allocatioCrewOldData
			,vm
			,timesheets
		})

	const oldDollars =
		oldDollarsArray.map((iArray) =>
			R.reduce(
				data.accumulator
				,{
					currentRevenue: 0
					,forecastedRevenue: 0
					,forecastedExpenditures: 0
					,currentExpenditures: 0
					,currentProfits: 0
					,forecastedProfits: 0
					,outstandingRevenue: 0
					,outstandingExpenditures: 0
					,outstandingProfits: 0
					,accruedExpenditures: 0
					,accruedRevenue: 0
					,accruedProfits: 0
					,budget: 0
				}
				,R.unnest(iArray)
			)
		)

	const newDollars =
		newDollarsArray.map((iArray) =>
			R.reduce(
				data.accumulator
				,{
					currentRevenue: 0
					,forecastedRevenue: 0
					,forecastedExpenditures: 0
					,currentExpenditures: 0
					,currentProfits: 0
					,forecastedProfits: 0
					,outstandingRevenue: 0
					,outstandingExpenditures: 0
					,outstandingProfits: 0
					,accruedExpenditures: 0
					,accruedRevenue: 0
					,accruedProfits: 0
					,budget: 0
				}
				,R.unnest(iArray)
			)
		)

	const differenceDollars = asyncMergeAll(
		[
			newDollars
			,oldDollars
		]
	).map(([oldDollars, newDollars]) => {
		return {
			currentRevenue:
				newDollars.currentRevenue
				- oldDollars.currentRevenue
			,forecastedRevenue:
				newDollars.forecastedRevenue
				- oldDollars.forecastedRevenue
			,forecastedExpenditures:
				newDollars.forecastedExpenditures
				- oldDollars.forecastedExpenditures
			,currentExpenditures:
				newDollars.currentExpenditures
				- oldDollars.currentExpenditures
			,currentProfits:
				newDollars.currentProfits
				- oldDollars.currentProfits
			,forecastedProfits:
				newDollars.forecastedProfits
				- oldDollars.forecastedProfits
			,outstandingRevenue:
				newDollars.outstandingRevenue
				- oldDollars.outstandingRevenue
			,outstandingExpenditures:
				newDollars.outstandingExpenditures
				- oldDollars.outstandingExpenditures
			,outstandingProfits:
				newDollars.outstandingProfits
				- oldDollars.outstandingProfits
			,accruedExpenditures:
				newDollars.accruedExpenditures
				- oldDollars.accruedExpenditures
			,accruedRevenue:
				newDollars.accruedRevenue
				- oldDollars.accruedRevenue
			,accruedProfits:
				newDollars.accruedProfits
				- oldDollars.accruedProfits
			,budget:
				newDollars.budget
				- oldDollars.budget
		}
	})

	// const projectDollars =
	asyncMergeAll(
		[
			projectCalculated
			,differenceDollars
		]
	).map(([pDollars, difference]) => {
		return pDollars
			? {
				currentRevenue:
					pDollars.currentRevenue
					 + difference.currentRevenue
				,forecastedRevenue:
					pDollars.forecastedRevenue
					 + difference.forecastedRevenue
				,forecastedExpenditures:
					pDollars.forecastedExpenditures
					 + difference.forecastedExpenditures
				,currentExpenditures:
					pDollars.currentExpenditures
					 + difference.currentExpenditures
				,currentProfits:
					pDollars.currentProfits
					 + difference.currentProfits
				,forecastedProfits:
					pDollars.forecastedProfits
					 + difference.forecastedProfits
				,outstandingRevenue:
					pDollars.outstandingRevenue
					 + difference.outstandingRevenue
				,outstandingExpenditures:
					pDollars.outstandingExpenditures
					 + difference.outstandingExpenditures
				,outstandingProfits:
					pDollars.outstandingProfits
					 + difference.outstandingProfits
				,accruedExpenditures:
					pDollars.accruedExpenditures
					 + difference.accruedExpenditures
				,accruedRevenue:
					pDollars.accruedRevenue
					 + difference.accruedRevenue
				,accruedProfits:
					pDollars.accruedProfits
					 + difference.accruedProfits
				,accruedDailyRevenue:
					pDollars.accruedDailyRevenue
				,outstandingDailyRevenue:
					pDollars.outstandingDailyRevenue
				,currentDailyRevenue:
					pDollars.currentDailyRevenue
				,forecastedDailyRevenue:
					pDollars.forecastedDailyRevenue
				,accruedDailyProfits:
					pDollars.accruedDailyProfits
				,outstandingDailyProfits:
					pDollars.outstandingDailyProfits
				,currentDailyProfits:
					pDollars.currentDailyProfits
				,forecastedDailyProfits:
					pDollars.forecastedDailyProfits
				,budget:
					pDollars.budget - pDollars.budget
			}
		: false
	})

	// const disciplineDollars =
	asyncMergeAll(
		[
			disciplineCalculated
			,differenceDollars
		]
	).map(([dDollars, difference]) => {
		return dDollars
			? {
				currentRevenue:
					dDollars.currentRevenue
					 + difference.currentRevenue
				,forecastedRevenue:
					dDollars.forecastedRevenue
					 + difference.forecastedRevenue
				,forecastedExpenditures:
					dDollars.forecastedExpenditures
					 + difference.forecastedExpenditures
				,currentExpenditures:
					dDollars.currentExpenditures
					 + difference.currentExpenditures
				,currentProfits:
					dDollars.currentProfits
					 + difference.currentProfits
				,forecastedProfits:
					dDollars.forecastedProfits
					 + difference.forecastedProfits
				,outstandingRevenue:
					dDollars.outstandingRevenue
					 + difference.outstandingRevenue
				,outstandingExpenditures:
					dDollars.outstandingExpenditures
					 + difference.outstandingExpenditures
				,outstandingProfits:
					dDollars.outstandingProfits
					 + difference.outstandingProfits
				,accruedExpenditures:
					dDollars.accruedExpenditures
					 + difference.accruedExpenditures
				,accruedRevenue:
					dDollars.accruedRevenue
					 + difference.accruedRevenue
				,accruedProfits:
					dDollars.accruedProfits
					 + difference.accruedProfits
				,accruedDailyRevenue:
					dDollars.accruedDailyRevenue
				,outstandingDailyRevenue:
					dDollars.outstandingDailyRevenue
				,currentDailyRevenue:
					dDollars.currentDailyRevenue
				,forecastedDailyRevenue:
					dDollars.forecastedDailyRevenue
				,accruedDailyProfits:
					dDollars.accruedDailyProfits
				,outstandingDailyProfits:
					dDollars.outstandingDailyProfits
				,currentDailyProfits:
					dDollars.currentDailyProfits
				,forecastedDailyProfits:
					dDollars.forecastedDailyProfits
				,budget: dDollars.budget - dDollars.budget
			}
			: false
	})


	const filterFunction = {
		changeNames: [
			{ ugly_name: 'tools_'}
			,{ ugly_name: 'teamresourcename', better_name: 'Assigned'}
			,{ ugly_name: 'allocations_'}
			,{ ugly_name: 'allocations_contractors_'}
			,{ ugly_name: "allocations_eots_description"
			, better_name: "Extension of Time Description"
			}
			,{ ugly_name: "allocations_eots_clause"
			, better_name: "Extension of Time Clause"
			}
			,{ ugly_name: "allocations_eots_escalated"
			, better_name: "Escalated Extension of Time"
			}
			,{ ugly_name: "allocations_eots_approved"
			, better_name: "Approved Extension of Time"
			}

			,{ ugly_name: "allocations_vars_description"
			, better_name: "Variation Description"
			}
			,{ ugly_name: "allocations_vars_clause"
			, better_name: "Variation Clause"
			}
			,{ ugly_name: "allocations_vars_amount"
			, better_name: "Variation Amount"
			}
			,{ ugly_name: "allocations_vars_escalated"
			, better_name: "Escalated Variation"
			}
			,{ ugly_name: "allocations_vars_approved"
			, better_name: "Approved Variation"
			}

			,{ ugly_name: "allocations_nods_description"
			, better_name: "Notice of Delay Description"
			}
			,{ ugly_name: "allocations_nods_escalated"
			, better_name: "Escalated Notice of Delay"
			}
			,{ ugly_name: "allocations_nods_clause"
			, better_name: "Notice of Delay Clause"
			}
			,{ ugly_name: "allocations_nods_approved"
			, better_name: "Approved Notice of Delay"
			}
			,{ ugly_name: "allocations_work_name"
			, better_name: "Discipline Name"
			}
			,{ ugly_name: "allocations_project_harshness"
			, better_name: "Discipline Harshness"
			}
			,{ ugly_name: "allocations_allocation_date"
			, better_name: "Work Date"
			}
			,{ ugly_name: "allocations_bad_day"
			, better_name: "Work Days unable to Resource"
			}
			,{ ugly_name: "allocations_weather_status"
			, better_name: "Weather interrupted Days"
			}
			,{ ugly_name: "allocations_day_status"
			, better_name: "Event interrupted Days"
			}
			,{ ugly_name: "allocations_allocation_complete"
			, better_name: "Work Complete"
			}
			,{ ugly_name: "allocations_allocation_forecast"
			, better_name: "Forecasted Work"
			}
			,{ ugly_name: "allocations_completion_comments"
			, better_name: "Work Notes"
			}
			,{ ugly_name: "allocations_project_name"
			, better_name: "Project Name"
			}
			,{ ugly_name: "allocations_contractors_name"
			, better_name: "Resource Name"
			}
			,{ ugly_name: "allocations_contractors_quality_rating"
			, better_name: "Resource Quality Rating"
			}
			,{ ugly_name: "allocations_contractors"
			, better_name: "Amount of Resources"
			}
			,{ ugly_name: "allocations_contractors_crews"
			, better_name: "Amount of Teams"
			}
			,{ ugly_name: "allocations_contractors_crews_name"
			, better_name: "Team Name"
			}
			,{ ugly_name: "allocations_contractors_crews_contract_multiplier"
			, better_name: "Team Contract Rate Multiplier"
			}
			,{ ugly_name: "allocations_contractors_crews_work_completed"
			, better_name: "Teams Completed Work"
			}
			,{ ugly_name: "allocations_contractors_crews_work_forecasted"
			, better_name: "Teams Forecasted Work"
			}
			,{ ugly_name: 'relatedDiscipline'
			, better_name: "Related Discipline"
			}
			,{ ugly_name: 'project_extras_type'
			, better_name: "Task Name"
			}
			,{ ugly_name: 'project_extras_frequency'
			, better_name: 'Task Frequency'
			}
			,{ ugly_name: 'project_extras_custom'
			, better_name: "Task Manually Allocated"
			}
			,{ ugly_name: 'project_extras_priority'
			, better_name: "Task Prioriy"
			}
			,{ ugly_name: 'project_extras_description'
			, better_name: "Task Description"
			}
			,{ ugly_name: 'project_extras_date_assigned'
			, better_name: "Task Date Assigned"
			}
			,{ ugly_name: 'project_extras_date_completed'
			, better_name: "Task Date Completed"
			}
			,{ ugly_name: 'project_extras_date_verified'
			, better_name: "Task Date Verified"
			}
			,{ ugly_name: 'project_extras_date_invalid'
			, better_name: "Task Date Invalid"
			}
			,{ ugly_name: 'project_extras_notes'
			, better_name: "Work Notes"
			}
			,{ ugly_name: 'project_extras_address'
			, better_name: "Task Address"
			}
			,{ ugly_name: 'project_extras_planned_amount'
			, better_name: "Task Amount"
			}
			,{ ugly_name: 'project_extras_actual_amount'
			, better_name: "Task Actual Amount"
			}
			,{ ugly_name: 'username'
			, better_name: "Task Assignee"
			}
			,{ ugly_name: 'filecount'
			, better_name: 'File Count'
			}
		]
		,uniqueArrays: []
		,template: {
			allocations_completion_comments: ""
			,project_name: ""
			,allocations_work_name: ""
			,allocations_project_harshness: 0
			,allocations_allocation_date: "date"
			,allocations_bad_day: ""
			,allocations_day_status: ""
			,allocations_weather_status: ""
			,allocations_allocation_complete: 0
			,allocations_allocation_forecast: 0
			,allocations_contractors: [{
				allocations_contractors_name: ""
				,allocations_contractors_quality_rating: 0
				,allocations_contractors_crews: [{
					allocations_contractors_crews_name: ""
					,allocations_contractors_crews_work_completed: 0
					,allocations_contractors_crews_work_forecasted: 0
					,allocations_contractors_crews_contract_multiplier: 0
				}],
			}]
			,allocations_eots: [{
				allocations_eots_description: ""
				,allocations_eots_clause: ""
				,allocations_eots_escalated: true
				,allocations_eots_approved: true
			}]
			,allocations_vars: [{
				allocations_vars_description: ""
				,allocations_vars_clause: ""
				,allocations_vars_amount: 0
				,allocations_vars_escalated: true
				,allocations_vars_approved: true
			}]
			,allocations_nods: [{
				allocations_nods_description: ""
				,allocations_nods_clause: ""
				,allocations_nods_escalated: true
				,allocations_nods_approved: true
			}],
		}
		,Ttemplate: {
			teamresourcename: ""
			,tools_name: ""
			,tools_description: ""
			,tools_condition: ""
			,tools_location: ""
			,tools_ownership: ""
			,tools_type: ""
		}
		,project_extras:[{
			relatedDiscipline: ""
			,project_extras_planned_amount: 0
			,project_extras_actual_amount: 0
			,project_extras_type: ""
			,project_extras_frequency: 0
			,project_extras_custom: true
			,project_extras_description: ""
			,project_extras_priority: 0
			,project_extras_date_assigned: "date"
			,project_extras_date_completed: "date"
			,project_extras_date_verified: "date"
			,project_extras_date_invalid: "date"
			,project_extras_notes: ""
			,project_extras_address: ""
			,username: ""
			,invite_email: ""
			,filecount: 0
		}]
	}

	function getfilters(button, marker){
		let datainput = marker == "Update"
			? filterFunction.updateFilters
			: marker == "Tasks"
				? filterFunction.projectExtrasFilters
				: filterFunction.ToolsFilters

		if (datainput == null){
			filterFunction.updateFilters = {
				filters: m.prop([])
				,operationalFilters: m.prop([])
				,changeNames: filterFunction.changeNames
				,uniqueArrays: filterFunction.uniqueArrays
				,template: filterFunction.template
				,disabled: () => !allocations() || allocations().length == 0
			}
			filterFunction.ToolsFilters = {
				filters: m.prop([])
				,operationalFilters: m.prop([])
				,changeNames: filterFunction.changeNames
				,uniqueArrays: filterFunction.uniqueArrays
				,template: filterFunction.Ttemplate
				,disabled:  () => tools().length == 0
			}
			filterFunction.projectExtrasFilters = {
				filters: m.prop([])
				,operationalFilters: m.prop([])
				,changeNames: filterFunction.changeNames
				,uniqueArrays: filterFunction.uniqueArrays
				,template: filterFunction.project_extras[0]
				,disabled: () => projectDetails().length == 0
			}
			datainput = marker == "Update"
				? filterFunction.updateFilters
				: marker == "Tasks"
					? filterFunction.projectExtrasFilters
					: filterFunction.ToolsFilters
		}

		const gotfilters =
			datainput == null
				? null
				: datainput.operationalFilters(
					filterView(
						datainput.template
						, datainput.filters()
						, datainput.changeNames
						, datainput.uniqueArrays
						, datainput.disabled
						, marker
						, data.scoped
					)
				)
		datainput != null ? datainput.filters( gotfilters.filters ) : null

		return gotfilters.generatedElements
	}

	function cloningTool(){
		const newtools = tools().map((t) => {
			const newtool = {
				tools_id: m.prop( t.tools_id() )
				,crew_id: m.prop( t.crew_id() )
				,teamresourcename: m.prop( t.teamresourcename )
				,organization_id: m.prop( t.organization_id())
				,tools_description: m.prop( t.tools_description() )
				,tools_name: m.prop(t.tools_name())
				,tools_condition: m.prop(t.tools_condition())
				,tools_location: m.prop(t.tools_location())
				,tools_ownership: m.prop(t.tools_ownership())
				,tools_type: m.prop(t.tools_type())
				,assigneduser: t.assigneduser
			}
			return newtool
		})
		return newtools
	}

	// eslint-disable-next-line complexity
	function createTask(task, cloning){

		const disciplineId =
			SelectedDiscipline().discipline_id
				? SelectedDiscipline().discipline_id()
				: currentDiscipline().discipline_id
				? currentDiscipline().discipline_id()
				: null

		const organizationDisciplineId =
			SelectedDiscipline().organizations_disciplines_id
				? SelectedDiscipline().organizations_disciplines_id
				: currentDiscipline().organizations_disciplines_id
				? currentDiscipline().organizations_disciplines_id
				: null

		const dName =
			SelectedDiscipline().discplineNameDescription
			|| currentDiscipline().discplineNameDescription
			|| ''

		const p = SelectedProject()

		const newTask = {
			project_id: m.prop(
				task
					? task.project_id()
					: p.project_id()
			)
			,project_name:
				task
					? task.project_name
					: p.project_name
			,discipline_id: m.prop(
				task
					? task.discipline_id()
					: disciplineId
			)
			,relatedDiscipline:
				task
					? task.relatedDiscipline
					: dName
			,newtask: true
			,invite_id: m.prop(null)
			,invite_email: ''
			,project_extras_id: m.prop(uuid.v4())
			,parents: m.prop([])
			,users: m.prop([])
			,project_extras_planned_amount: m.prop( 0 )
			,project_extras_actual_amount: m.prop(
				task
					? task.project_extras_actual_amount()
					: 0
			)
			,user_id: task
				? m.prop(task.user_id())
				: m.prop(data.auth.stream().user_id)
			,allocation_id: m.prop(null)
			,revenueinvoices: []
			,costinvoices: []
			,contract_id:
				task
					? task.contract_id
					: p.contract_id
			,username: data.auth.stream().user_username
			,project_extras_custom: m.prop(false)
			,project_extras_type: m.prop(
				task
					? task.project_extras_type()
					: ''
			)
			,project_extras_frequency: m.prop(
				task
					? task.project_extras_frequency()
					: 0
			)
			,project_extras_description: m.prop(
				task
					? task.project_extras_description()
					: ''
			)
			,project_extras_priority: m.prop(
				task
					? task.project_extras_priority()
					: 0
			)
			,project_extras_date_assigned: m.prop(new Date())
			,project_extras_date_completed:
				task
					? m.prop(task.project_extras_date_completed())
					: m.prop(null)
			,project_extras_date_verified:
				task && !cloning
					? m.prop(task.project_extras_date_verified())
					: m.prop(null)
			,project_extras_date_invalid:
				task
					? m.prop(task.project_extras_date_invalid())
					: m.prop(null)
			,project_extras_notes: m.prop(
				task
					? task.project_extras_notes()
					: ''
			)
			,project_extras_address: m.prop(
				task
					? task.project_extras_address()
					: ''
			)
			,key: Math.random().toString(15).slice(2, 8)
			,organizations_disciplines_id:
				task
					? task.organizations_disciplines_id
					: organizationDisciplineId
			,created: true
		}

		projectDetails(
			[].concat(
				projectDetails()
				,[newTask]
			)
		)
		selectedProjectExtra(newTask)
		masterTaskList(true)
	}

	const discardButtonAc = () =>
		vm().selected.allocation.allocations_contractors.forEach((ac) => {
			const contractorObj = R.find(
				function(chr) {
					return chr.allocations_contractors_id()
						== ac.allocations_contractors_id()
				},
				vm().revert.allocations_contractors
			)
			const removals = []

			ac.allocations_contractors_crews.forEach((acc) => {
				const crewObjindex = R.findIndex(
					function(chr) { return chr.crew_id() == acc.crew_id()},
					contractorObj.allocations_contractors_crews
				)
				if (crewObjindex >= 0){
					acc.allocations_contractors_crews_work_completed(
						Number(
							contractorObj
								.allocations_contractors_crews[crewObjindex]
								.allocations_contractors_crews_work_completed()
						)
					)
				} else {
					removals.push(acc)
				}
			})
			removals.forEach((cre) => {
				const remindex = R.findIndex(
					(chr) => chr.crew_id() == cre.crew_id(),
					ac.allocations_contractors_crews
				)
				ac.allocations_contractors_crews.splice(remindex, 1)
			})
		})

	const saveButton = () =>
		DisableProjectExtraSave(true)
		|| DisableEmptySave(true)
		|| vm().selected.allocation.allocation_id && DisableDuplicateSave(true)
		|| identical(
			vm().revert
			, vm().selected.allocation
		)
		&& identical(
			timesheets()
			, timesheet_vm().selected.timesheet
		)
		&& identical(
			todaysInvoices()
			, originalInvoices()
		)
		&& identical(
			tools()
			, tools_vm()
		)
		&& identical(
			projectDetails()
			, baseProjectDetails()
		)

		|| !currentVersion()
		|| vm().pending()
			? m('button.btn'
				, {
					disabled: true
				}
				, 'Save'
			)
			: elements.action(
				'Save'
				, sendAllocations
				, vm().pending
			)

	const discardButton = () =>
		elements.undoDiscard({
			discard: null
			,doneUndo: {
				label:
					vm().pending()
					|| identical(vm().revert, vm().selected.allocation)
					&& identical(todaysInvoices(), originalInvoices())
					&& identical(timesheets(), timesheet_vm().selected.timesheet)
					&& identical(projectDetails(), baseProjectDetails())
					&& (
						activeLandingTab() == 'Tools'
						? identical(tools(), tools_vm())
						: true
					)
					? 'Done'
					: ''
				,attrs: {
					onclick: () => {
						if( vm().selected.allocation.allocation_id ){

							vm().selected.allocation = cloningAllocation(vm().revert)
							consolidateContractList(vm().selected.allocation)
							setContractOptions(vm().selected.allocation)
							contractvm({})
							contractList().length > 0
								? contractIndex(0)
								: contractIndex(-1)
							// Discard Timesheets
							discardButtonAc()
							timesheet_vm().selected.timesheet = []
							vm().selected.allocation.allocations_allocation_complete(
								Number(vm().revert.allocations_allocation_complete())
							)
							timesheets([])
							timesheet_vm().selected.timesheet.length > 0
								? timesheetIndex(0)
								: timesheetIndex(-1)

						}

						vm().revert =
							vm().selected.allocation.allocation_id
								? vm().revert
								: {}

						vm().selected.allocation =
							vm().selected.allocation.allocation_id
								? vm().selected.allocation
								: {}

						// Discard Invoice Updates
						// data.calculatedData(data.calculatedData())
						tools_vm(cloningTool())
						projectDetails([])
						baseProjectDetails([])
						// todo-james replace filter with actual predicate

						fetchedExtras(false)
						// Discard Project and Discipline Details, and reinitiate streams
						allocationChange(true)
						// Discard Work Updates and Contract Info
						errors({})
						selectedProjectExtra({})
					}
					,title: STRING.TITLE.DISCARD_DATES()
					,style: {
						transition: '0.5s'
						,width: '5em'
					}
				}
			}
		})

	const timesheetAddView = function(){

		const autocompleteBox =
			autocomplete.strict(
				timesheetlist
				,R.when( Boolean, function(v){
					make_timesheet([v])
					timesheetIndex(0)
					return v.ParentRecognition
				})
				,'ParentRecognition'
				,() => ({
					title: "Add a Timesheet for a Team"
					,disabled: !timesheetlist().length
				})
				,(value, element) => {
					element.value = ""
				}
			)

		const AddMineButton = [
			!timesheet_vm().selected.timesheet
			.find((t) =>
				t.user_id()
				== data.auth.stream().user_id
			)
			&& (
				editAssignedTimesheetsPermissions()
				|| editTimePermissions()
			)
				? m("button.btn.", {
					disabled: !editAssignedTimesheetsPermissions()
					,onclick: () => {
						new_user_timesheet()
						timesheetIndex(0)
					}
					,title: "Add a timesheet for yourself on this day"
				}, "Add mine" )
				: null
		]

		return currentVersion()
			? [
				editTimePermissions()
				|| timesheetlist().length > 1
				? elements.list([
					m('label.control-label', `Add a Timesheet`)
					,Pencil2(
						() => ''
						,() => autocompleteBox
					)
					,AddMineButton
				])
				: AddMineButton
			]
			: null
	}

	const contractAddView = function(){

		const autocompleteBox =
			autocomplete.strict(
				contractOptions
				,R.when( Boolean, function(v){
					ContractAddButton(v)
					return v
				})
				,null
				,() => ({
					title: "Add a contract item to todays work"
					,disabled: !!DisableEmptySave(true)
						|| !editUpdatePermissions()
					,key: autouidContracts
				})
				,(value, element) => {
					element.value = ""
				}
			)

		return currentVersion()
			? elements.list([
				m('label.control-label', 'Add a Contract item')
				,null
				,Pencil2(
					() => ''
					,() => autocompleteBox
				)
			])
			: null
	}

	const timesheetIndex = m.prop(-1)
	const contractIndex = m.prop(-1)
	const underlinedHeader = a => m('h5.pt3.bw1.bb.pb2.b--black-20', a)

	function linkCompeltion(v, o){
		if(v || v == 0){

			const diffState =
				!!o.project_extras_date_invalid()
				|| v == 0

			if (diffState){
				o.project_extras_date_invalid(null)
				projectExtrasSum(
					o
					,'project_extras_date_invalid'
				)
			}

			o.project_extras_actual_amount(v)

			if ( v == 0 ){
				o.project_extras_date_completed(null)
				projectExtrasSum(
					o
					,'project_extras_date_completed'
				)
			}

			if( !o.project_extras_date_completed() && v ){
				if(!o.newtask){o.project_extras_date_completed(Date.now())}
				projectExtrasSum(
					o
					,'project_extras_date_completed'
				)
			}

			o.created = true
		}
		return o.project_extras_actual_amount()
	}

	const taskSaveDiscardButton = function(){
		return [
			elements.action(
				'Save'
				, sendAllocations
				, vm().pending
				, 'primary'
				,() =>
					DisableProjectExtraSave(true)
					|| DisableEmptySave(true)
					|| (
						vm()
							.selected.allocation.allocation_id
							? DisableDuplicateSave(true)
							: false
					)
					|| !currentVersion()
					|| identical(
						projectDetails()
						, baseProjectDetails()
					)
			)

			,selectedProjectExtra().newtask
				? discardTaskButton(selectedProjectExtra())
				: discardButton()
		]
	}

	const detailPaneInputs = function(d){

		function workUpdates(){
			return [

				elements.list([
					m('label.control-label.mb1'
						,'Work Date '
						, editUpdatePermissions()
						&& currentVersion()
							? Pencil2(
								() => moment( d.allocations_allocation_date() ).format("ll")
								,() =>
									elements.dateInput(
										(aDate) => {
											if(aDate){
												d.allocations_allocation_date(aDate)
												projectDetails()
													.filter((pe) =>
														pe.allocation_id()
														== d.allocation_id()
													)
													.forEach((pe) => {
														pe.created = true
														pe.project_extras_date_invalid()
															? pe.project_extras_date_invalid( aDate )
															: pe.project_extras_date_completed()
																? pe.project_extras_date_completed( aDate )
																: null
													})
											}
											return d.allocations_allocation_date()
										}
										, {
											title: STRING.TITLE.WORK_COMPLETE_DATE()
										}
									)
							)
							: moment( d.allocations_allocation_date() ).format("ll")
					)
				])
				,workCompletedOfForecasted()
				,importantNotes()

				,m(HarthUppy, {
					getFields: () => uploadmetadata({})
					, data
				})
			]
		}

		function addRemoveWork(c){
			return m('label.control-label.mb1'
				,'+/- Work'
				,editUpdatePermissions()
				&& currentVersion()
				&& !c.project_extras_id()
					? m(
						NumberInput
						,R.merge(
							data
							,{
								errors
								,errorLabel:
									c['allocations_' + c.midfix + '_description']() + ' +/- Work'
							}
						)
						,{
							prop:
								c['allocations_' + c.midfix + '_amount'] || m.prop()
							,attrs: {
								step: 0.01
								, title: "plus or minus required works"
								,disabled:
									!editUpdatePermissions()
									|| !c['allocations_' + c.midfix + '_amount']
									|| c.project_extras_id()
							}
						}
					)
					: c['allocations_' + c.midfix + '_amount']
						? c['allocations_' + c.midfix + '_amount']()
						: null
			)
		}

		function allocationsContractDescription(c){
			return (
				!editUpdatePermissions()
				&& currentVersion()
					? c['allocations_' + c.midfix + '_description']()
					: elements.list([
						m('label.control-label.mb1'
							,'Description'
							,elements.textInput(
								c['allocations_' + c.midfix + '_description'], {
								title: STRING.TITLE.CONTRACT_DESCRIPTION()
							})
						)
					])
			)
		}

		function contractClause(c){
			return !editUpdatePermissions()
			&& currentVersion()
				? c['allocations_' + c.midfix + '_clause']()
				: elements.list([
					m('label.control-label.mb1'
						,'Contract Clause'
						,elements.textInput( c['allocations_' + c.midfix + '_clause'], {
							title: "The Formal contract clause that forces this to apply"
						})
					)
				])
		}

		function contractUpdates(){
			const c = contractList()[contractIndex()]
			return [

					contractAddView()
					,contractList().length
						? [
							elements.list([
								m(
									'label.control-label.mb1'
									,contractIndex() == -1
										? contractList().length + ` Contract Items`
										: contractIndex()
											+ 1 + ` of ` + contractList().length + ` Contract Items`
								)
								,elements.go(
									"Next contract Item",
									() => {
										contractIndex() == contractList().length - 1
											? contractIndex(0)
											: contractIndex(contractIndex() + 1)
										contractvm(contractList()[contractIndex()])
									},
									{disabled: contractList().length < 1}
								)
								,m("button.btn.", {
									disabled: contractIndex() == -1
									,onclick: () => {
										contractIndex(-1)
									}
									,title: "Hide the contracts"
								},"Hide")
							])

							,contractIndex() > - 1
								? elements.list([
									m('div.form-group', {style: {'height': '300px'}}, [
										contractList().length == 0 || !c
											? []
											: [
												elements.list([
													m('label.control-label.mb1' ,'Type')
													,c.midfix == 'vars'
														? 'Variation'
														: c.midfix == 'nods'
															? 'Notice of Delay'
															: 'Extension of Time'
													,ContractDeleteButton()
												])

												,elements.list([
													addRemoveWork(c)
												])
												,allocationsContractDescription(c)
												,contractClause(c)
												,DisableEmptySave()
											]
									])
								])
								: null
						]
						: [
							m('br')
							,`Contract Obligations haven't been recorded`
						]
			]

		}

		function workCompletedByTeam(t){
			return (
				!crewsIndexedByAID()[t.user_id()]
				&& !crewsIndexedByAID()[t.crew_id()]
				? STRING.WARNING.TEAM_CONNECTION()
				: m('label.control-label.mb1'
					,'Completed'
					,!editAssignedTimesheetsPermissions()
					&& !editTimePermissions()
					&& currentVersion()
						? t.crew_work()
						: m(
							NumberInput
							,R.merge(
								data
								,{
									errors
									,errorLabel:
										(t.crew_name() || t.user_name()) + 'Completed'
								}
							)
							,{
								prop: (v) => addSpecificWork(t, v)
								,attrs: {
									min: 0
									, step: 0.01
									, title: "Work Completed by this team"
									, disabled: t.work_conditions() == 'Auto Created Timesheet'
								}
							}
						)
				)
			)
		}

		function hoursWorked(t){
			return (
				m('label.control-label.mb1'
					,'Hours Worked'
					,!editAssignedTimesheetsPermissions()
					&& !editTimePermissions()
					&& currentVersion()
						? t.crew_hours()
						: m(
							NumberInput
							,R.merge(
								data
								,{
									errors
									,errorLabel:
										(t.crew_name() || t.user_name()) + ' Hours Worked'
								}
							)
							,{
								prop: t.crew_hours
								,attrs: {
									min: 0
									, step: 0.01
									, title: "Hours worked by this team"
								}
							}
						)
				)
			)
		}

		function projectNotes(t){
			return (
				m('label.control-label.mb1'
					,'Project Notes'
					,!editAssignedTimesheetsPermissions()
					&& !editTimePermissions()
					&& currentVersion()
						? t.work_conditions()
						: elements.textInput(
							t.work_conditions
							,{ title: "Impairing Work Conditions"
							, disabled: t.work_conditions() == 'Auto Created Timesheet'
							}
						)
				)
			)
		}

		function performanceNotes(t){
			return (
				m('label.control-label.mb1'
					,'Performance Notes'
					,!editAssignedTimesheetsPermissions()
					&& !editTimePermissions()
					&& currentVersion()
						? t.crew_performance()
						: elements.textInput(
							t.crew_performance,
							{title: "Teams Performance"}
						)
				)
			)
		}

		function timesheetUpdates(){
			const tArray = timesheet_vm().selected.timesheet
			const t = timesheet_vm().selected.timesheet[timesheetIndex()]
			const sharedtimesheets =
				!t || !t.sharedtimesheet
				? []
				: t.sharedtimesheet.filter((st) =>
					st.crew_id == t.crew_id()
					&& st.user_id == t.user_id()
				)
			return [

					timesheetAddView()
					,m('br')
					,tArray.length
						? [
							elements.list([
								m(
									'label.control-label.mb1'
									, timesheetIndex() == -1
										? tArray.length + ` Timesheets`
										: timesheetIndex()
											+ 1 + ` of ` + tArray.length + ` Timesheets`
								)
								,elements.go(
									"Next Timesheet",
									() => {
										timesheetIndex() == tArray.length - 1
											? timesheetIndex(0)
											: timesheetIndex(timesheetIndex() + 1)
									},
									{disabled: tArray.length < 1}
								)
								,m("button.btn.", {
									disabled: timesheetIndex() == -1
									,onclick: () => {
										timesheetIndex(-1)
									}
									,title: "Hide these timesheets"
								},"Hide")
							])

							,timesheetIndex() > -1
								? m('div.form-group', {style: {'height': '350px'}}, [
									tArray.length == 0 || !t
										? []
										: [
											elements.list([
												m('label.control-label.mb1', 'Team Name')
												,t.crew_name() ? t.crew_name() : t.user_name()
											])

											,elements.list([
												workCompletedByTeam(t)
											])

											,elements.list([
												hoursWorked(t)
											])
											,elements.list([
												projectNotes(t)
											])
											,elements.list([
												performanceNotes(t)
											])
										]
								])
								: null

							,sharedtimesheets.length
								? m(
									'label.control-label.mb1'
									,'Time Shared with'
								)
								: null
							,sharedtimesheets.length
								? elements.list(
									sharedtimesheets
									.map((st) =>
										m('a' + (st.timesheets_id == t.timesheets_id() ? '.fw6' : '')
											,{
												onclick: () => {
													st.sharedtimesheet = sharedtimesheets
													const editT = cloningTimesheet([st])
													const tindex = timesheet_vm().selected.timesheet
														.findIndex((t) => t.timesheets_id() == st.timesheets_id)

													if( tindex == -1 ){
														timesheet_vm().selected.timesheet =
															R.insert(timesheetIndex(), R.last(editT), timesheet_vm().selected.timesheet)
													}
													{ timesheetIndex(tindex) }
												}
												,disabled: st.timesheets_id == t.timesheets_id() || loading()
												,title: "Load shared timesheet",
											}
											, st.project_name
											+ ' x ' + st.crew_hours + ' hours'
											+ ' and ' + st.discipline_name
											+ ' x ' + st.crew_work
										)
									)
								)
								: null

						]
						: [
							m('br')
							,`Timesheets haven't been gathered`
						]
			]
		}


		function financials(){
			return [
				m('a'
					,{
						onclick: () => {
							activeTab(`Create Ledgers`)
							fetchedExtras(false)
							fullView(true)
							viewType = EditAllocations
						}
						,disabled:
							!editInvoicePermissions()
							&& !editOwnedInvoicesPermissions()
							|| !contractInvoiceables().length && !todaysInvoices().length
						, title: "Create a Ledger",
					}
					, 'Ledgers'
				)
			]
		}

		function tasks(){

			const assignSomeone =
				Pencil2(
					() =>
						selectedProjectExtra().musers
						? ''
						: (
							users().find((u) =>
								u.user_id()
								== selectedProjectExtra().user_id()
							)
							|| nullUser
						)
						.user_username()
					,() =>
						autocomplete.strict(
							selectedProjectExtra().musers ? mchildResUsers : childResUsers
							,(v, model) => {
								if( v ) {
									selectedProjectExtra().created = true

									if( selectedProjectExtra().musers ){
										selectedProjectExtra().users().unshift(v)
										childResUsers(childResUsers())
										model.input('')
										model.chosen(null)
									}
									else {
										selectedProjectExtra().user_id(v.user_id())
									}

									v.user_id()
										? selectedProjectExtra()
											.project_extras_custom(true)
										: selectedProjectExtra()
											.project_extras_custom(false)

									selectedProjectExtra()
										.project_extras_custom(true)

									selectedProjectExtra().invite_id = null
									selectedProjectExtra().invite_email = ''

									// editing(false)
									// model.input('')
								}
							}
							, 'usersFullName'
							, () => ({
								placeholder: ""
								,disabled: !childResUsers().length
								,title: "Assigned to task"
								,key: selectedProjectExtra().musers
							})
						)
					, { toggleable: true }
				)

			const assigners =
				m('.assigners'
					+ H.css`
						display: grid;
						gap: 0.5em;

						& .pencil {
							padding: 1em 0em;
						}

						& .control-label {
							margin-bottom: 0px;
						}

						& ul {
							margin-bottom: 0em;
						}
					`
					, m('label.control-label', 'Assigned ')
					, m('.assign-content'
						+ H.css`
							display: grid;
							gap: 0.5em;
							background-color: white;
							border: 1px solid #eaeaea;
							border-radius: 0.25em;
							padding: 1em;

							& .pencil.measure > p {
								padding: 0.5em;
								border-radius: 0.25em;
								background-color: #f9f9f9;
								display: grid;
								justify-content: space-between;
								grid-auto-flow: column;
							}
						`
						, m('.btns'
							+ css`
								display: grid;
								grid-auto-flow: column;
								gap: 1em;
							`
							, m(
								'button.btn.btn-secondary'
								+ css`
									backgroundColor: 'transparent';
									border: 'solid 1px #3380c2';
									position: 'relative';
									top: '-0.1em';
									height: '2.9em';
									width: '5em';
									color: '#434aa3';
								`
								, {
									onclick: () => {

										if( selectedProjectExtra().musers ){
											selectedProjectExtra().users().unshift(
												users()
												.find((ds) =>
													ds.user_id()
													== data.auth.stream().user_id
												)
											)
										}
										else {
											selectedProjectExtra().created = true
											selectedProjectExtra().user_id(
												data.auth.stream().user_id
											)
										}
									}
									,title: "Assign yourself to this Task"
									,disabled:
										selectedProjectExtra().user_id()
											== data.auth.stream().user_id
										|| selectedProjectExtra().users().find((u) =>
											u.user_id() == data.auth.stream().user_id)
								}
								,'Me'
							)
							,m(
								'button.btn.btn-secondary'
								+ css`
									border-radius: 0.5em;
									border: solid 0.1px rgba(0,0,0,0.4);
									background-color: ` + (selectedProjectExtra().musers ? `grey;` : `white;`) + `
								`
								, {
									onclick: () => {
										selectedProjectExtra().musers =
											!selectedProjectExtra().musers
											? uuid.v4()
											: ''

										if( selectedProjectExtra().musers ){

											selectedProjectExtra().oldusers
												? selectedProjectExtra().users(selectedProjectExtra().oldusers)
												: selectedProjectExtra().user_id()
												? selectedProjectExtra().users().push(
													users()
													.find((ds) =>
														ds.user_id()
														== selectedProjectExtra().user_id()
													)
												)
												: null

											;childResUsers(childResUsers())
										}
										else {
											selectedProjectExtra().oldusers = selectedProjectExtra().users()
											selectedProjectExtra().users([])
										}

									}
									,title: "Assign many users to this Task"
									,disabled: false
								}
								, selectedProjectExtra().musers ? 'Single' : '+ More'
							)
						)
						, assignSomeone
						, selectedProjectExtra().users().length > 0 && elements.strikeList(
							selectedProjectExtra().users()
							.map(
								od => ({
									label: od.user_username()
									,action: () => {
										selectedProjectExtra().users()
										.splice(
											selectedProjectExtra().users()
											.findIndex((ds) =>
												ds.user_id()
												== od.user_id()
											)
											,1
										)
									}
								})
							)
						)
					)
				)


			return [

				m(HarthUppy, {
					getFields:
						() => uploadmetadata({
							only: { organizations_disciplines_id: 'organizations_disciplines_id' }
						})
					, data
					, listOnly: true
					, readOnly: true
					, embedded: true
				})

				,DisableProjectExtraSave()

				,elements.list([
					m('label.control-label', 'Discipline ')
					, !selectedProjectExtra().newtask
					&& selectedProjectExtra().discipline_id()
						? selectedProjectExtra().relatedDiscipline
						: Pencil2(
							() => selectedProjectExtra().relatedDiscipline
							,() =>
								m(autocomplete.Main, {
									list: restrictedorganizationsDisciplines
									,onselect: R.when( Boolean, function(v){
										const discipline =
											disciplineExtraOptions()
											.find((d) =>
												exposer(d.organizations_disciplines_id)
												== v.organizations_disciplines_id
											)
										selectedProjectExtra()
											.created = true
										selectedProjectExtra()
											.relatedDiscipline =
											v.orgDisciplineNameDescription
										selectedProjectExtra()
											.organizations_disciplines_id =
											v.organizations_disciplines_id
										selectedProjectExtra()
											.project_extras_description(v.orgDisciplineNameDescription)

										selectedProjectExtra()
											.discipline_id(
												discipline ? v.discipline_id() : null
											)
									})
									,field: 'orgDisciplineNameDescription'
									,attrs: {
										placeholder: ""
										,disabled: !restrictedorganizationsDisciplines().length
										,title: "Related discipline"
									}
									,sort: R.prop('completedcount')
								})
						)
				])

				,m('label.control-label.db.pv2'
					, 'Task Name '
					, !selectedProjectExtra().newtask
						? elements.plainTxt(
							selectedProjectExtra().project_extras_type()
						)
						: Pencil2(
							() => selectedProjectExtra().project_extras_type()
							,() =>
								elements.textInput(
									selectedProjectExtra().project_extras_type
									, {
										disabled: !editUpdatePermissions()
									}
								)
						)
				)

				,m('label.control-label.db.pv2'
					, 'Task Notes '
					, Pencil2(
						() => selectedProjectExtra().project_extras_notes()
						,() =>
							elements.textArea(
								selectedProjectExtra().project_extras_notes
								, {
									placeholder: "Task notes"
									,disabled: !editUpdatePermissions()
								}
							)
					)
				)


				,m('label.control-label.db.pv2'
					, 'Address '
					, Pencil2(
						() => selectedProjectExtra().project_extras_address()
						,() =>
							m(autocomplete.Main, {
								field: 'title'
								,throttleSearch: 500
								,inputClassName: 'form-control'
								,initialValue: selectedProjectExtra().project_extras_address()
								,attrs: {
									placeholder: '14 Lonsdale St, Melbourne, Victoria'
									,name: 'project_extras_address'
									,required: true
									,disabled: !editUpdatePermissions()
									,value: selectedProjectExtra().project_extras_address()
								}
								,oninput: selectedProjectExtra().project_extras_address
								,manuelConfig: {
									filter: () => true
								}
								,search(value){
									if(value){
										return data.api.suburbs.search(value)
									} else {
										return []
									}
								}
								,onselect(value){
									selectedProjectExtra().project_extras_address(value.title)

									// collect data as we use it for later features
									data.api.suburbs.here.patch(value)
									// eslint-disable-next-line no-undef
									.catch( e => console.error('Could not save here data ', e))
								}
							})
					)
				)

				,m('label.control-label'
					, 'Amount'
					, Pencil2(
						() =>
							selectedProjectExtra()
								.project_extras_actual_amount()
							+ ' of '
							+ selectedProjectExtra()
								.project_extras_planned_amount()
							+ ' Completed'
						,() =>
							m(
								NumberInput
								,R.merge(
									data
									,{
										errors: errors
										,errorLabel: 'Task Actual Amount'
									}
								)
								,{
									prop: (v) => linkCompeltion(
										v, selectedProjectExtra()
									)
									,attrs: {
										min: 0
										, step: 0.01
										, disabled:
											!editUpdatePermissions()
											|| selectedProjectExtra().project_extras_date_verified()
										, title: "Task Actual Amount"
										, className: 'pv2'
									}
								}
							)
					)
				)

				,editProjectPermissions()
				? elements.list([
					m('label.control-label', 'Repeat Every ')
					,Pencil2(
						() => selectedProjectExtra().project_extras_frequency()
						,() =>
							m(
								NumberInput
								,R.merge(
									data
									,{
										errors
										,errorLabel: 'Task Frequency'
									}
								)
								,{
									prop: selectedProjectExtra().project_extras_frequency
									,attrs: {
										min: 0
										, title: "Task Frequency"
									}
								}
							)
					)
					,m('label.control-label', ' Days')
				])
				: null


				,elements.list([
					m('label.control-label', 'Assigned Date: ')
					,selectedProjectExtra().project_extras_date_assigned()
						? moment(
							selectedProjectExtra().project_extras_date_assigned()
						)
						.format("ll")
						: ''
				])

				,changeCompletedDate('Completed Date')(selectedProjectExtra())

				,elements.list([
					m('label.control-label', 'Verified Date: ')
					,selectedProjectExtra().project_extras_date_verified()
						? moment(
							selectedProjectExtra().project_extras_date_verified()
						)
						.format("ll")
						: ''
				])

				,elements.list([
					m('label.control-label', 'Invalid Date: ')
					,selectedProjectExtra().project_extras_date_invalid()
						? moment(
							selectedProjectExtra().project_extras_date_invalid()
						)
						.format("ll")
						: ''
				])

				,m('label.control-label.db.pv2'
					, 'Task Description '
					, !selectedProjectExtra().newtask
						? elements.plainTxt(
							!selectedProjectExtra().project_extras_description()
							? selectedProjectExtra().relatedDiscipline
							: selectedProjectExtra().project_extras_description()
						)
						: Pencil2(
							() =>
								!selectedProjectExtra().project_extras_description()
								? selectedProjectExtra().relatedDiscipline
								: selectedProjectExtra().project_extras_description()
							,() =>
								elements.textArea(
									selectedProjectExtra()
										.project_extras_description
									,{
										title: "Description of the task"
										,disabled:
											!editUpdatePermissions()
									}
								)
						)
				)

				,assigners

				,m('br')

				, m(HarthUppy, {
					getFields: () => uploadmetadata({})
					, data
				})

				,m('br')

				,!retrieveAllocations()
				&& !allocations().length
				? m('a.p.pv1.ma0'
					,{
						title: "View Variations, Timesheets and Financial Data"
						, onclick: () => {retrieveAllocations(true)}
					}
					, `Variations, Timesheets and Financial Data`
				)
				: [

					m('hr')
					,m('label.control-label.span', 'Expenditure Ledgers ')
					,m('br')
					,R.intersperse(
						m('br')
						,selectedProjectExtra()
						.costinvoices
						.map(s =>
							m('a.p.pv1.ma0'
								,{
									title: "View Invoices"
									, onclick: () =>{
										activeTab(`Create Ledgers`)
										fullView(true)
										viewType = EditAllocations
									}
									, disabled: !selectedProjectExtra().allocation_id() || loading()
								}
								, s
							)
						)
					)

					,m('hr')
					,m('label.control-label.span', 'Revenue Ledgers ' )
					,m('br')
					,R.intersperse(
						m('br')
						,selectedProjectExtra().revenueinvoices
						.map(s =>
							m('a.p.pv1.ma0'
								,{
									title: "View Invoices"
									, onclick: () =>{
										activeTab(`Create Ledgers`)
										fullView(true)
										viewType = EditAllocations
									}
									, disabled: !selectedProjectExtra().allocation_id() || loading()
								}
								, s
							)
						)
					)

					,m('hr')
					,m('label.control-label', 'Timesheets ')
					,m('br')
					,fetchedTime()
						? R.intersperse(
							m('br')
							,timesheet_vm().selected.timesheet
							.filter((t) =>
								activeLandingTab() == 'Days'
								? vm().selected.allocation.allocation_id
								&& (
									selectedProjectExtra().user_id()
									&& (t.user_id() == selectedProjectExtra().user_id()
									|| connectedTeams().find((c) =>t.crew_id() == c.crew_id()))
								)
								&& selectedProjectExtra().allocation_id()
								== vm().selected.allocation.allocation_id()
								: t.allocation_id() == selectedProjectExtra().allocation_id()
							)
							.map(t =>
								m('a.p.pv1.ma0'
									,{
										title: "View Timesheets"
										, onclick: () =>{
											activeTab(`Timesheets`)
											fullView(true)
											viewType = EditAllocations
										}
										, disabled:
											!selectedProjectExtra().allocation_id() || loading()
									}
									, (t.crew_name() || '')
									+ (t.user_name() ? ' - ' : '' )
									+ (t.user_name() || '')
									+ ' - '
									+  t.crew_hours()
									+ ' hours'
								)
							)
						)
						: ' ... '

					,m('hr')
					,m('label.control-label', 'Variations ')
					,m('br')
					,R.intersperse(
						m('br')
						,contractList()
						.filter((c) =>
							c.project_extras_id()
							== selectedProjectExtra().project_extras_id()
						)
						.map((c) =>
							m('a.p.pv1.ma0'
								,{
									title: "View Contract Obligations"
									, onclick: () =>{
										activeTab(`Update Contractual Information`)
										fullView(true)
										viewType = EditAllocations
									}
									, disabled:
										!selectedProjectExtra().allocation_id() || loading()
								}
								,c.midfix == 'vars'
								? 'Variation - ' + c['allocations_' + c.midfix + '_description']()
								: c.midfix == 'nods'
									? 'Notice of Delay - '
										+ c['allocations_' + c.midfix + '_description']()
									: 'Extension of Time - '
										+ c['allocations_' + c.midfix + '_description']()
							)
						)
					)
				]
			]
		}


		return d.allocations_allocation_date
			&& activeLandingTab() == 'Days'
			&& !fullView()
			? [
				elements.list([
					DisableEmptySave(true)
					|| DisableDuplicateSave(true)
					|| identical(vm().revert, d)
					&& identical(timesheets(), timesheet_vm().selected.timesheet)
					|| !currentVersion()
					|| vm().pending()
						? m('button.btn', {disabled: true}, 'Save')
						: elements.action('Save', sendAllocations, vm().pending)

					,discardButton()
				])

				,DisableDuplicateSave(true)
					? DisableDuplicateSave()
					: elements.alert(`info`, `Please be concise with the Important Notes`)


				,m(HarthUppy, {
					getFields:
						() => uploadmetadata({
							only: { organizations_disciplines_id: 'organizations_disciplines_id' }
						})
					, data
					, listOnly: true
				})

				,underlinedHeader('Updates')
				, workUpdates()
				// ,underlinedHeader('Financials')
				, financials()
				,underlinedHeader('Contracts')
				, contractUpdates()
				,underlinedHeader('Timesheets')
				, timesheetUpdates()
			]
			: Object.keys(selectedProjectExtra()).length
			&& (
				activeLandingTab() == 'Tasks'
				|| activeTab() == 'Project Tasks'
			)
				? [

					!selectedProjectExtra().newtask
						? underlinedHeader('Existing Task - ' + selectedProjectExtra().project_extras_type())
						: underlinedHeader('New Task - ' + selectedProjectExtra().project_extras_type())

					,elements.list([
						taskSaveDiscardButton()
						.concat(
							m("button.btn",{
								onclick: () => { createTask(selectedProjectExtra(), 'cloning') }
								,title: 'Clone this Task'
								,disabled:
									!editUpdatePermissions()
							}, "Clone")
						)
					])
					,tasks()
				]
				: []
	}

	function discardTaskButton(t){
		return m("button.btn.btn-warning"
			,{
				onclick: () => {
					projectDetails(
						projectDetails()
						.filter((e) =>
							t.key != e.key
						)
					)
					selectedProjectExtra({})
					masterTaskList(true)
				}
				,title: 'Remove a Task'
			}
			, 'Discard'
		)
	}

	const detailsPaneOpen =
		() => {
			const answer =
				vm().selected.allocation.allocations_completion_comments
				&& !fullView()
				&& activeLandingTab() == 'Days'

			|| 				Object.keys(selectedProjectExtra()).length
				&& activeLandingTab() == 'Tasks'

			|| 				fullView()
				&& activeTab() == 'Project Tasks'
				&& Object.keys(selectedProjectExtra()).length


			return answer
		}

	// function selectedItemIsNew(){
	// 	return selectedItemType() == 'Task'
	// 		? Object.keys(selectedProjectExtra()).length
	// 			? selectedProjectExtra().project_extras_id() == null
	// 			: true // shouldn't happen, but it certainly isn't saved if {}
	// 		: false
	// }

	const connectedAllocation =
		asyncMergeAll(
			[
				selectedProjectExtra
				,allocations
			]
		)
		.map(([s]) => {

			const refAllocation =
				s.allocation_id
				&& s.allocation_id()
				? allocations().find((a) =>
					a.allocation_id()
					== s.allocation_id()
				)
				: null

			if( refAllocation && !fullView() ){
				contractIndex(-1)
				timesheetIndex(-1)
				vm().revert = refAllocation
				vm().selected.allocation = cloningAllocation(refAllocation)
				allocationChange(true)
			} else if ( !fullView() ){
				contractIndex(-1)
				timesheetIndex(-1)
				vm().revert = {}
				vm().selected.allocation = {}
				allocationChange(true)
			}

			return refAllocation
		})

	const connectedTeams =
		connectedAllocation.map((s) =>
			!s
			? []
			: R.unnest(
				s.allocations_contractors
				.filter((r) => r.user_id() == selectedProjectExtra().user_id() )
				.map((r) => R.prop('allocations_contractors_crews', r))
			)
		)

	getfilters(null, "Tasks")
	const masterTaskList = function(mcreate){

		if (mcreate){
			CONSTANTProjectExtras( projectDetails() )
		}
		return ListProcessing(
			CONSTANTProjectExtras()
			,filterFunction.projectExtrasFilters.filters()
			,'project_extras_priority'
			,filteredTaskOptions
		)
	}

	const addLedger = () =>
		elements.list([
			m('label.control-label.mb1', 'Add Ledger')
			,autocomplete.strict(
				contractInvoiceables
				,R.when( Boolean, function(v){

					const contract =
						data.contracts().find((c) =>
							c.contract_id
							== v.contract_id()
						)

					const raeteMultiplier =
						v.organizations_disciplines_id
							? vm()
								.selected
								.allocation.disciplineratemultiplier
								: v.crews_discipline_rates_id
							? crewWorkers()
								.find((ac) =>
									ac.crews_discipline_rates
									.find((acr) =>
										acr.crews_discipline_rates_id
										== v.crews_discipline_rates_id
									)
								)
								.teamratemultiplier
								|| 1
							: 1

					todaysInvoices(
						[].concat(
							todaysInvoices()
							,data.cloneInvoice(
								[v]
								,data.auth.stream()
									.user_id
								,contract
								,vm().selected.allocation
								,{
									preFillAmount: 0
									,rateMultiplier: raeteMultiplier
								}
							)
						)
					)
				})
				,'contractrecognitionname'
				,() => ({
					title: "Select a contract item to base the invoice on"
					,disabled: contractInvoiceables().length == 0
				})
				,(value, element) => {
					element.value = ""
				}
			)
		])

	const invoiceTable = () =>
		elements.table(data,
			[
				''
				, 'No.'
				, 'Received'
				, 'Name'
				, 'Description'
				, 'Working Amount'
				, 'Service Amount'
				, 'Depreciation Amount'
				, 'Appreciation Amount'
				, 'Damages Amount'
				, 'Total Amount'
				, 'Approver'
			]
			,todaysInvoices().map((i) => [

				m("button.btn.btn-warning", {
					disabled:
						!editOwnedInvoicesPermissions()
						&& !editInvoicePermissions()
					,onclick: () => {
						deletetInvoices({
							makeAPICalls: null
							, api: data.api
							, invoice: i
							, deleteInvoiceItems
							, todaysInvoices
							, errors
						})
					}
					,title: "Delete a Ledger"
				},'Delete')

				,[i.invoice_inv_id(), i.invoice_no() ].filter(Boolean).join('|')


				,i.invoice_id()
				&& i.invoice_approval()
					? elements.checkbox(
						{
							onchange: m.withAttr(
								'checked'
								 , (e) => {
										i.invoice_received(e)

										if(e){
											i.invoice_items.forEach((ii) =>
												ii.invoice_items_received_amount = ii.invoice_items_amount
											)
											i.invoice_received_date(new Date().getTime())
										}
										else {
											i.invoice_items.forEach((ii) =>
												ii.invoice_items_received_amount = 0
											)
											i.invoice_received_date(null)
										}

										return i.invoice_received()
									}
							)
							,checked: i.invoice_received()
							,title: `Set ledger to be recieved`
							,disabled:
								!editOwnedInvoicesPermissions()
								&& !editInvoicePermissions()
						}
					)
					: 'Not Approved'

				,i.invoice_name()

				,editOwnedInvoicesPermissions()
				|| editInvoicePermissions()
					? Pencil2(
						() => i.invoice_description()
						,() =>
							elements.textInput(
								i.invoice_description
							)
					)
					: i.invoice_description()

				,(
					editOwnedInvoicesPermissions()
					|| editInvoicePermissions()
				)
				&& !i.invoice_items.length
					? Pencil2(
						() => i.invoice_amount()
						,() =>
							m(
								NumberInput
								,R.merge(
									data
									,{
										errors: errors
										,errorLabel:
											i.invoice_name() + ' Working Amount'
									}
								)
								,{
									prop: i.invoice_amount
									,attrs: {
										step: 0.01
										,disabled: i.invoice_approval()
									}
								}
							)
					)
					: i.invoice_amount()

				,(
					editOwnedInvoicesPermissions()
					|| editInvoicePermissions()
				)
				&& !i.invoice_items.length
					? Pencil2(
						() => i.invoice_service_amount()
						,() =>
							m(
								NumberInput
								,R.merge(
									data
									,{
										errors: errors
										,errorLabel:
											i.invoice_name() + ' Service Amount'
									}
								)
								,{
									prop: i.invoice_service_amount
									,attrs: {
										step: 0.01
										,disabled: i.invoice_approval()
									}
								}
							)
					)
					: i.invoice_service_amount()

				,(
					editOwnedInvoicesPermissions()
					|| editInvoicePermissions()
				)
				&& !i.invoice_items.length
					? Pencil2(
						() => i.invoice_depreciation_amount()
						,() =>
							m(
								NumberInput
								,R.merge(
									data
									,{
										errors: errors
										,errorLabel:
											i.invoice_name()
												+ ' Depreciation Amount'
									}
								)
								,{
									prop: i.invoice_depreciation_amount
									,attrs: {
										step: 0.01
										,disabled: i.invoice_approval()
									}
								}
							)
					)
					: i.invoice_depreciation_amount()

				,(
					editOwnedInvoicesPermissions()
					|| editInvoicePermissions()
				)
				&& !i.invoice_items.length
					? Pencil2(
						() => i.invoice_appreciation_amount()
						,() =>
							m(
								NumberInput
								,R.merge(
									data
									,{
										errors: errors
										,errorLabel:
											i.invoice_name()
												+ ' Appreciation Amount'
									}
								)
								,{
									prop: i.invoice_appreciation_amount
									,attrs: {
										step: 0.01
										,disabled: i.invoice_approval()
									}
								}
							)
					)
					: i.invoice_appreciation_amount()

				,(
					editOwnedInvoicesPermissions()
					|| editInvoicePermissions()
				)
				&& !i.invoice_items.length
					? Pencil2(
						() => i.invoice_damages_amount()
						,() =>
							m(
								NumberInput
								,R.merge(
									data
									,{
										errors: errors
										,errorLabel:
											i.invoice_name() + ' Damages Amount'
									}
								)
								,{
									prop: i.invoice_damages_amount
									,attrs: {
										step: 0.01
										,disabled: i.invoice_approval()
									}
								}
							)
					)
					: i.invoice_damages_amount()

				,editInvoicePermissions()
				|| editOwnedInvoicesPermissions()
					? makeMoney(
						Number(
							i.invoice_amount()
								* i.invoice_rate()
							+ i.invoice_service_amount()
								* i.invoice_service_rate()
							+ i.invoice_depreciation_amount()
								* i.invoice_depreciation_rate()
							+ i.invoice_appreciation_amount()
								* i.invoice_appreciation_rate()
							+ i.invoice_damages_amount()
								* i.invoice_damages_rate()
						)
					)
					: null

				,!i.invoice_approval()
					? Pencil2(
						() => {
							const iapprover =
								users().find((u) =>
									u.user_id()
									== i.invoice_approver()
								)

							return iapprover
								? iapprover.usersFullName
								: 'Unassigned'
						}
						,() =>
							autocomplete.strict(
								users
								, (v) => {

									v
										? i.invoice_approver(v.user_id())
										: null

									const assignedUser =
										users().find((u) =>
											u.user_id()
											== i.invoice_approver()
										)

									return assignedUser
										? assignedUser.usersFullName
										: 'Unassigned'

								}
								,'usersFullName'
								, () => ({
									style: {
										height: '3em'
									}
									,disabled: !editOwnedInvoicesPermissions()
										&& !editInvoicePermissions()
								})
							)
					)
					: !i.user_id()
					? 'Unassigned'
					: users().find((u) =>
						u.user_id()
						== i.invoice_approver()
					).usersFullName

				]
			)
		)

	function CreatInvoicePO(){
		return [
			m('br')
			,elements.alert(`info`,
				'Rate polarity determines how Odin interprets your input.  '
				+ 'Negative rates are treated as expenditures and '
				+ 'positive rates are interpreted as revenue.'
			)

			,!data.calculated()
			|| vm().pending()
			|| !fetchedInv()
				? elements.centeredSpinner()
				: [
					(
						editOwnedInvoicesPermissions()
						|| editInvoicePermissions()
					)
					&& currentVersion()
					? addLedger()
					: null
					,m('br')
					,todaysInvoices().length
					? invoiceTable()
					: null
		]
		]
	}

	const resourcing = () =>
		elements.list([
			m('label'
				+ css`
					display: grid;
					grid-auto-flow: column;
					justify-content: center;
					align-items: center;
					gap: 0.5em;
					font-size: 1.5em;
					min-height: 1.6em;
				`
				,'Resource'
			)
			,Pencil2(
				() => SelectedResource().ParentRecognition
				,() =>
					autocomplete.strict(
						resourceOptions
						,R.when( Boolean, function(v){
							if(v != SelectedResource()){
								SelectedResource(v)
								vm().selected.allocation = {}
								vm().revert = {}
							}
						})
						, 'ParentRecognition'
						, () => ({
							placeholder: 'Select a resource'
							,title: 'Resources registered to works'
						})
					)
			)
		])

	const projectNameUnlabelled = () =>
		Pencil2(
			() => SelectedProject().project_name
			,() => autocomplete.strict(
				projectOptions
				,R.when( Boolean, function(v){

					if ( v != SelectedProject() ){
						SelectedProject(v)
						vm().selected.allocation = {}
						vm().revert = {}
						fetchedExtras(false)
					}

				})
				, 'project_name'
				, () => ({
					placeholder: 'Select a project'
					,disabled:
						!vm().selected.allocation.allocation_id
						&& !identical(
							projectDetails()
							, baseProjectDetails()
						)
					,title: 'Project Works'
				})
			)
		)

	const disciplineNameUnlabelled = () =>
		!disciplineOptions().length
		|| !data.advancedView()
		? null
		: Pencil2(
				() =>
					' - '
					+ (
						discipline_box()
						|| SelectedDiscipline().discplineNameDescription
					)

				,() =>
					autocomplete.strict(
						disciplineOptions
						,R.when( Boolean, function(v){

							if ( v != allDisciplines ){
								fetchedExtras(false)
								SelectedDiscipline(v)
								vm().selected.allocation = {}
								vm().revert = {}
								discipline_box('')
							} else {
								discipline_box('All Disciplines')
								SelectedDiscipline({})
								fetchedExtras(false)
							}
						})
						, 'discplineNameDescription'
						, () => ({
							placeholder: 'Select a discipline'
							,disabled:
								!identical(
									projectDetails()
									, baseProjectDetails()
								)
							,title: 'Discipline Works'
						})
					)
			)

	const daySelectButtons = () =>
		elements.selectbuttons(
			shortDates().map((d) => d.name)
			,(a) => {
				if(a){ viewWindow(a) }
			}
			,{ title: `Show representative items`}
			,(notReq, a) =>
				shortDates().find((d) =>
					d.name == a
					&& d.dateA == SelectedDate()
					&& d.dateB == SelectedSecondDate()
				)
			,() => activeLandingTab() == 'Tasks'
		)

	const taskSelectButtons = () =>
		elements.selectbuttons(
			[
				'Assigned'
				,'Completed'
				,'Verified'
				,'Invalid'
			].map((prop) =>
				prop
				+ ' '
				+ (projectExtrasStat()['project_extras_date_' + prop.toLowerCase()] || 0)
			)
			.concat('All Tasks ')
			,(t) => {
				if(t){

					// !projectExtrasTypes()['completed']
					// 	? projectExtrasTypes()['completed'] = 'any'
					// 	: ''
					// !projectExtrasTypes()['invalid']
					// 	? projectExtrasTypes()['invalid'] = 'any'
					// 	: ''
					// !projectExtrasTypes()['assigned']
					// 	? projectExtrasTypes()['assigned'] = 'any'
					// 	: ''
					// !projectExtrasTypes()['verified']
					// 	? projectExtrasTypes()['verified'] = 'any'
					// 	: ''

					fetchedExtras(false)

					selectedProjectExtra({})
					const type = t.toLowerCase().split(' ')[0]

					const newtypes =
						R.merge(
							{
								completed: 'any'
								, invalid: 'any'
								, verified: 'any'
								, assigned: 'any'
							}
							,t.indexOf('All Tasks') > -1
							? {}
							: {
								[type]:
									projectExtrasTypes()[type] == 'all'
									? projectExtrasTypes()[type] = 'any'
									: projectExtrasTypes()[type] = 'all'
							}
						)

					if(
						projectExtrasTypes()['completed'] == 'any'
						&& projectExtrasTypes()['invalid'] == 'any'
						&& projectExtrasTypes()['assigned'] == 'any'
						&& projectExtrasTypes()['verified'] == 'any'
						&& t.indexOf('All Tasks') == -1
					){
						projectExtrasTypes()['completed'] = ''
						projectExtrasTypes()['invalid'] = ''
						projectExtrasTypes()['assigned'] = ''
						projectExtrasTypes()['verified'] = ''
					}

					projectExtrasTypes( newtypes )


				}
			}
			,{
				disabled:
					projectDetails().some((p) => !p.project_id() )
				,title: `Task selection`
			}
			,(notReq, t) =>
				projectExtrasTypes()[ t.toLowerCase().split(' ')[0] ] == 'all' ||  t.indexOf('All Tasks') > -1
				&& projectExtrasTypes()['completed'] == 'any'
				&& projectExtrasTypes()['invalid'] == 'any'
				&& projectExtrasTypes()['assigned'] == 'any'
				&& projectExtrasTypes()['verified'] == 'any'
		)

	const between = () =>
		activeLandingTab() == 'Days'
		? [
			elements.list([
				m('label.control-label', 'Between')
				,Pencil2(
					() =>
						(
							SelectedDate()
							? moment( SelectedDate()).format("ll")
							: 'Project Start'
						)
						+ ' - '
						+ (
							SelectedSecondDate() != Infinity
							? moment( SelectedSecondDate()).format("ll")
							: 'Project End'
						)
					,() =>
						elements.list([
							elements.dateInput(
								SelectedDate
								,{
									disabled: activeLandingTab() == 'Tasks'
									,title: 'Edit the date range'
								}
							)
							,elements.dateInput(
								SelectedSecondDate
								,{
									disabled: activeLandingTab() == 'Tasks'
									,title: 'Edit the date range'
								}
							)
						])
				)
			])
		]
		: null

	const taskReports = () =>
		activeLandingTab() == 'Days'
		? [
			!data.advancedView() && elements.alert(
				`info`, `Odin summarises events and related efforts in an allocated day for each discipline`
				+ `The Search + Filter inputs can sort this data in many inclusion and exclusion search combinations, but to sort through the work promptly Odin also provides the day select buttons`
			)
			,daySelectButtons()
		]
		: activeLandingTab() == 'Tasks'
		? [
			elements.alert('info',
				`Tasks are small unscheduled activites that are useful for record keeping. Tasks can be related to disciplines and account for working quantities. `
				+ `The Search + Filter inputs can sort this data in many inclusion and exclusion search combinations, but to sort through the work promptly Odin also provides the task select buttons`
			)
			, taskSelectButtons()
		]
		: null

	// todo-james files list
	const readOnlyFiles = ({only}) =>
		m('.read-only-files'
			, m('h4',  'Documents')
			, [
				m(''
					+ css`
						margin-bottom: 1em;
						margin-top: 1em;
					`
					,
					{
						// onclick: () => readOnlyFiles.open = !readOnlyFiles.open
						// , open: !!readOnlyFiles.open
						key: JSON.stringify(uploadmetadata({}))
					}
					,m('.event-breaker'
						,
						{ onclick: e => e.stopPropagation() }
						,
							m(HarthUppy, {
								getFields: () => uploadmetadata(only || {})
								, data
								, listOnly: true
							})
					)
				)
			]
		)

	const notes = () =>
		data.advancedView()
		? m(''+css`padding-top: 0.75em`
			,elements.details(
				'Notes'
				,m(''
					,[
						m('br')
						,projectNotes().map((s) => [
							elements.list([m('p.pv1.underline.fw5', s[0])])
							, s[1].map((ss) => m('.pa0.relative.left-1.top--1', elements.list([m('.fw5', ss[0]), m('.fw6.gold', ss[1])], '.pa0')))
						])
					]
				)
				,projectNotes().length ? {} : { disabled: true }
			)
		)
		: null


	const filteredTaskOptions = data.scoped([])
	const filteredAllocationOptions = data.scoped([])

	const materialloading = data.scoped(false)
	const toolloading = data.scoped(false)
	const materials = data.scoped({actual: [], suggested: []})

	const transfers = data.scoped([])
	const forceMaterialFetch = data.scoped(false)
	const forceToolfetch = data.scoped(false)
	function fetchWarehouses(){
		return data.fetchWarehouses({})
			.then(function(res){
				return transfers(
					res.map((w) =>
						R.merge(
							{
								id: w.warehouse_id()
								,name: w.warehouse_name()
							}
							,w
						)
					)
				)
			})
	}

	function transferItems(item, ti){

		const items = item.returnquantity ? [item] : materials().suggested[ti.id].fmgroupedbyitem

		item.combine = true

		item.transfers =
			items.map((i) => {

				const tranferqty = i.returnquantity && i.returnquantity() || item.takequantity()
				const newid = i.returnWarehouse && i.returnWarehouse().id || i.discipline_id
				const newname = i.returnWarehouse && i.returnWarehouse().name || i.discipline_name

				return {
					transferqty: tranferqty
					,transferid: newid
					,transfername: newname
					,transfernotes:
						( i.returnquantity ? ' returned from ' : tranferqty + ' taken from ')
						+ ( i.returnquantity ? i.distribution_entity_name() : item.distribution_entity_name )
				}
			})

		return [item]
	}
	const requiredTools = data.scoped({actual: [], suggested: []})
	const prevtasks = data.scoped([])

	prop.merge(
		[
			filteredTaskOptions
			,forceMaterialFetch
			,forceToolfetch
			// ,filteredAllocationOptions
		]
	)
	.map(([tasks, ff, fft]) => {

		const tassksid =
			prevtasks().map((t) => exposer(t.project_extras_id)).join('')
			== tasks.map((t) => exposer(t.project_extras_id)).join('')

		return (
			(!materialloading() || !toolloading())
			&& tasks.length
			&& (ff || fft || !tassksid)
			&& (
				!materialloading() && !toolloading()
				? toolloading(true) && materialloading(true)
				: !materialloading()
				? materialloading(true)
				: toolloading(true)
			)
			? Promise.all([
				data.fetchSuggestedMaterials({
					projects: []
					,warehouse_id: null
					,completedProjects: null
					,tasks: tasks.map((t) => t.project_extras_id())
				})
				, data.fetchMaterials({
					projects: tasks.map((t) => t.project_id())
					,warehouse_id: null
					,completedProjects: null
					,tasks: tasks.map((t) => t.project_extras_id())
				})
				, data.readToolPermissions()
				? data.api.tools.presets.fetch({
					perorganizatond:
						tasks.map((d) => exposer(d.organizations_disciplines_id) ).filter(R.identity)
					,suggested: true
					,users:
						tasks.map((d) => exposer(d.user_id)).filter(R.identity)
				})
				: []
			])
			.then(([suggested, actual, rt]) => {
				prevtasks(tasks)

				const itemsindex =
					R.groupBy(
						R.pipe(R.prop('supplier_items_id'), R.call)
						,actual
					)

				const ditemsindex =
					R.mapObjIndexed(
						R.groupBy((o) => R.prop('discipline_id', o) || o.project_id)
						,itemsindex
					)


				if( fft || !ff){
					requiredTools(
						rt.map((t) => {
							t.itemdetailarray = data.scoped(t.actual)
							t.dispersion = R.indexBy(R.prop('organizations_disciplines_id'), t.actual)
							return t
						})
					)

					const atools = rt.filter((r) => r.actual.length)
					const stools = rt.filter((r) => r.suggested.length)

					requiredTools({
						actual:
							atools
							.concat(stools.filter((s) => !atools.find((a) => a.tools_type == s.tools_type)))
							.map((s) => R.merge(
								s,
								{
									name: s.tools_type || 'General Tools'
									,total: Math.max(s.required, s.assigned)
								})
							)
						,suggested: rt.filter((r) => r.suggested.length)
					})

					forceToolfetch(false)
					toolloading(false)
				}

				if( ff || !fft){
					materials({
						actual:
							R.uniqBy(R.pipe(R.prop('supplier_items_id'), R.call), actual)
							.map((i) => {
								const suggestedmaterial =
									suggested.find((s) => s.supplier_items_id == i.supplier_items_id()) || {requiredqty: 0, currentqty: 0}
								const totalqty = (suggestedmaterial['taskrequiredqty'] || suggestedmaterial['requiredqty']) - suggestedmaterial['currentqty']
								return {
									name: i.distribution_material_name()
									,id: i.supplier_items_id()
									,dispersion:
										R.mapObjIndexed(
											(di) => {
												return di.map((m) => {
													m.returnWarehouse = data.scoped({})
													m.returnquantity = data.scoped(0)
													m.returnable = true
													return m
												})
											}
											,ditemsindex[i.supplier_items_id()]
										)

									,received:
										R.sum(R.unnest(Object.keys(ditemsindex[i.supplier_items_id()])
										.map((k) => ditemsindex[i.supplier_items_id()][k].map((si) => si['order_received']))))
									,allocated:
										R.sum(R.unnest(Object.keys(ditemsindex[i.supplier_items_id()])
										.map((k) => ditemsindex[i.supplier_items_id()][k].map((si) => si['distribution_amount']()))))
									,orders:
										R.uniq(
											R.unnest(Object.keys(ditemsindex[i.supplier_items_id()])
											.map((k) => ditemsindex[i.supplier_items_id()][k].map((si) => si['order_name'])))
											.filter(R.identity)
										)
										.join()
									,frequired: suggestedmaterial['taskrequiredqty'] || suggestedmaterial['requiredqty']
									,required: (suggestedmaterial['taskrequiredqty'] || suggestedmaterial['requiredqty']) - suggestedmaterial['currentqty']
									,supplier_items_id: i.supplier_items_id()
									,value: i.order_items_cost || i.supplier_items_cost * totalqty
								}
							})
							.concat(
								suggested.filter((s) => !itemsindex[s.supplier_items_id])
								.map((s) => ({
									name: R.last(s.fmgroupedbyitem)['distribution_material_name']
									,id: s.supplier_items_id
									,dispersion: {}
									,received: ''
									,allocated: ''
									,orders: ''
									,required: s.taskrequiredqty || s.requiredqty
									,supplier_items_id: s.supplier_items_id
									,value: s.supplier_items_cost* (s.taskrequiredqty || s.requiredqty)
								}))
							)

						,suggested:
							R.indexBy(
								R.prop('supplier_items_id')
								,suggested.map((s) => {
									const requiredqty = Number(Number(s['requiredqty']-s['currentqty']).toFixed(3))

									s.mogroupedbyitem
									&& s.mogroupedbyitem.forEach((i) =>
										i.takequantity = data.scoped(Math.min(i.distribution_amount, requiredqty))
									)

									return R.merge(
										{ditemsindex: R.indexBy(R.prop('discipline_id'), s.fmgroupedbyitem)
										, editquantity: m.prop(requiredqty)}
										, s
									)
								})
							)
					})

					forceMaterialFetch(false)
				}

				return true
			})
			: Promise.resolve( !tasks.length && materials({actual: [], suggested: []}) && requiredTools({actual: [], suggested: []}) && [[], []] )
		)
	})

	materials
	.map((materialist) =>
		materialist.actual.map((item) => {
			item.itemdetailarray =
				data.scoped(
					R.uniqBy((d) => d.discipline_id(), filteredTaskOptions())
					.map((pd) => ({
						id: pd.discipline_id ? pd.discipline_id() : pd.project_id()
						,name: pd.discipline_name ? pd.relatedDiscipline : pd.project_name
					}))
					.filter((pd) =>
						item.dispersion[pd.id]
						|| materials().suggested[item.supplier_items_id]
						&& materials().suggested[item.supplier_items_id]['ditemsindex'][pd.id]
					)
				)
			return item
		})
		&& materialloading(false)
	)


	const takeItemButton = (i, item) => {
		const onclick = () => {
			materialloading(true)
			return data.api.materials.patch.many(
				i.newOrder
				? materials().suggested[item.id]['fmgroupedbyitem']
				.map((objtref) => {
					// const actuals =
					// 	materials().actual.find((a) => a.supplier_items_id == item.id)

					const required = objtref.requiredqty

					return {
						organization_id: m.prop(objtref.organization_id)
						,distribution_id: m.prop(uuid.v4())
						,distribution_entity_name: m.prop(objtref.distribution_entity_name)
						,distribution_entity_id: m.prop(objtref.distribution_entity_id)
						,supplier_id: m.prop(objtref.supplier_id)
						,supplier_items_id: m.prop(objtref.supplier_items_id)
						,distribution_material_name: m.prop(objtref.distribution_material_name)
						,distribution_material_description: m.prop(objtref.distribution_material_description)
						,distribution_amount: m.prop(required)
						,distribution_batch: m.prop(objtref.distribution_batch)
						,distribution_container_name: m.prop(objtref.distribution_container_name)
						,distribution_container_id: m.prop(objtref.distribution_container_id)
						,distribution_silo: m.prop(objtref.distribution_silo)
						,distribution_ordered: m.prop(objtref.distribution_ordered)
						,distribution_float: m.prop(objtref.distribution_float)
						,distribution_stocked: m.prop(objtref.project_name + " - " + objtref.project_material_suburb)
						,supplier_name: m.prop(objtref.supplier_name)
						,supplier_items_cost: m.prop(objtref.supplier_items_cost)
						,supplier_items_batch_cost: m.prop(objtref.supplier_items_batch_cost)
						,autoorder: i.newOrder
					}
				})
				: transferItems(i, item)
			)
			.then(function(){
				materialloading(false)
				return forceMaterialFetch(true)
			})
		}


		return fancyButton({
			onclick
			, disabled: !i.newOrder && i.takequantity() > i.distribution_amount
		}, i.newOrder ? `New Order` : `Take Items`)
	}


	const suggestedMaterialItems = (item, suggestedActions) => {
		let i = suggestedActions[0]

		let headerContent =
			i
			&& [
				materials().suggested[item.id]['editquantity']()
				,'required' + ( !i.suggestions ? ' ( not yet ordered ) ' : ' ( not yet allocated ) ' )
			]
			.join(' ')

		const numberInput = (i) =>
			m(
				NumberInput
				,R.merge(
					data
					,{
						errors
						,errorLabel: 'Material Qty'
					}
				)
				,{
					prop:
						i.returnable
						? i.returnquantity
						: i.takequantity
					,attrs: {
						min: 0
						,max: i.distribution_amount
						,step: 0.001
					}
				}
			)

		const complexSummary = (i) =>
			m('.complex-summary'+css`margin-bottom: 2em;`
				, elements.infoPills({
					[i.newOrder ? 'Supplementing' : 'Warehouse' ]: i.distribution_entity_name
					,[i.newOrder ? 'Amount' : 'Available']: [i.distribution_amount * 4 ]
					,'Received': i.newOrder ? '' : [i.order_received , ' / ', i.distribution_amount * 4]
					,'Order': i.order_name
					,'ETA on Remaining': i.order_eta || 'Not Confirmed'
				})
				, elements.infoPill('Notes', i.order_notes)
			)

		return m('.suggested-items'
			+ css`
				display: grid;
				gap: 1em;
			`
			, elements.infoPill('Required', i && headerContent)
			, m('.items'
				,suggestedActions
				.map((i) =>
					m(''
						+ css`
							display: grid;
							align-items: start;
							align-content: start;
							margin-bottom: 1em;
							gap: 0.5em
						`
						, m('.take-items'
							+ css`
								display: grid;
								gap: 1em;
							`
							,m(''
								+ css`
									display: flex;
									gap: 1em;
									align-items: center;
									justify-self: start;
								`
								,[

									takeItemButton(i, item)
									,i.newOrder
									? ''
									: numberInput(i)
								]
							)
							, complexSummary(i)
						)
					)
				)
			)
		)
	}

	const materialItemSuggestions = (item, suggestedActions) => {
		return editMaterialPermissions()
			? suggestedMaterialItems(item, suggestedActions)
			: []
	}

	const toolItemSuggestions = (item, suggestedActions) => {
		return editToolPermissions() || editAssignedToolPermissions()
			? suggestedToolItems(item, suggestedActions)
			: []
	}


	const suggestedToolItems = (item, suggestedActions) => {
		let i = suggestedActions[0]

		let headerContent =
			i
			&& [
				item.required
				, 'required'
				, '('
				, item.assigned
				, 'assigned'
				, ')'
			]
			.join(' ')

		const complexSummary = (i) =>
			m('.complex-summary'+css`margin-bottom: 2em;`
				, elements.infoPills({
					'Tool': i.tools_name || ''
					,'Take Tool': assignAToolPencil()(i)
					,'Description': i.tools_description || ''
					,'Location': i.tools_location || ''
					,'Ownership': i.tools_ownership || ''
					,'Condition': i.tools_condition || ''
				})
			)

		return m('.suggested-items'
			+ css`
				display: grid;
				gap: 1em;
			`
			, elements.infoPill('Required', i && headerContent)
			, m('.items'
				,suggestedActions
				.map((i, iindex) =>
					m(''
						+ css`
							display: grid;
							align-items: start;
							align-content: start;
							margin-bottom: 1em;
							gap: 0.5em
						`
						, m('.take-items'
							+ css`
								display: grid;
								gap: 1em;
							`
							,m(''
								+ css`
									display: flex;
									gap: 1em;
									align-items: center;
									justify-self: start;
								`
								,[
									!iindex
									? `Users connected to teams may take tools`
									: ``
								]
							)
							, complexSummary(i)
						)
					)
				)
			)
		)
	}


	const fancyButton = (attrs, text) =>
		m(
			'button.btn.btn-secondary'
			+ css`
				background-color: transparent;
				border: solid 1px #3380c2;
				min-width: 7em;
				min-height: 1.8em;
				color: #434aa3;
				padding: 0.2em;
				height: 2.5em;
			`
			.$nest(':hover', `
				opacity: 0.8;
				height: 2.5em;
			`)
			, attrs
			, text
		)

	const transferItemsButton =
		(i, { stateChanger, attrs={} }={}) => fancyButton(
			{
				 ...attrs
				, onclick:
					() => {
						return !i.returnactive || stateChanger
						? i.returnactive = !i.returnactive
						: materialloading(true)
						&& data.api.materials.patch.many(transferItems(i))
							.then(function(){
								materialloading(false)
								return forceMaterialFetch(true)
							})
					}
				,disabled: attrs.disabled || i.returnquantity() > i.distribution_amount()
				,title: ''
			}
			, i.returnactive && stateChanger ? `Keep Items` : 'Return Items'
		)

	const returnForm = i =>
		[

			m('', 'Return Quantity')

			,m(
				NumberInput
				,R.merge(
					data
					,{
						errors
						,errorLabel: 'Material Qty'
					}
				)
				,{
					prop: i.returnquantity
					,attrs: {
						min: 0
						,max: i.distribution_amount()
						,step: 0.001
					}
				}
			)

			,m('', 'to')

			,autocomplete.strict(
				transfers
				,R.when(Boolean, function(v){
					i.returnWarehouse(v)
				})
				,'name'
				,() => ({})
			)

			, transferItemsButton(i, {
				statechanger: false
				,attrs: {
					disabled:  !(
						i.returnWarehouse().id
						&& i.returnquantity() > 0
					)
				}
			})
		]

	const toolItemDetails =
		(item, config) => {
			const suggestedActions = config.suggestedActions(item)
			const items =
				m(''
					+ css`
						display: grid;
						gap: 3em;
					`
					, item.itemdetailarray().length > 0
					&& m('.detail-items'
						,item.itemdetailarray()
						.map((i) => {
							const colourstate = config.colorfun(i)
							return m(''
								+ css`
									display: grid;
									gap: 1em;
								`
								// all this padding bs is just to have a
								// border separator between items
								// I wish there was a border-spacing property
								// or something...
								.$nest(':not(:first-child)',`
									padding-top: 18px;
								`)
								.$nest(':not(:last-child)',`
									border-bottom: solid 1px rgba(0,0,0,0.05);
									padding-bottom: 18px;
								`)

								,m('.details'
									+ css`
										display: grid;
										gap: 1em;
										min-height: 8em;
									`
									,elements.infoPills({
										Tool: {
											icon: elements.icons.tool()
											,value: i.tools_name
										}
										,Release: {
											icon: elements.icons.user()
											, value: m(`` + colourstate, assignAToolPencil()(i) )
										}
										,Description: {
											icon: elements.icons.hash()
											, value: i.tools_description
										}
										,Location: {
											icon: elements.icons.mapPin()
											,value: i.tools_location
										}
										,Ownership: {
											icon: ''
											,value: i.tools_ownership
										}
										,Condition: {
											icon: ''
											,value: i.tools_condition
										}
									})
								)
							)
						})
					)
					, toolItemSuggestions(item, suggestedActions)
				)

			return items
		}

	const materialItemDetails =
		(item, config) => {
			const suggestedActions = config.suggestedActions(item)
			const items =
				m(''
					+ css`
						display: grid;
						gap: 3em;
					`
					, item.itemdetailarray().flatMap(
						pd => item.dispersion[pd.id] || []
					)
					.length > 0
					&& m('.detail-items', item.itemdetailarray()
					.map((pd) =>
						(item.dispersion[pd.id] || [])
						.map((i) => {

							const colourstate = config.colorfun(i)

							return m(''
								+ css`
									display: grid;
									gap: 1em;
								`
								// all this padding bs is just to have a
								// border separator between items
								// I wish there was a border-spacing property
								// or something...
								.$nest(':not(:first-child)',`
									padding-top: 18px;
								`)
								.$nest(':not(:last-child)',`
									border-bottom: solid 1px rgba(0,0,0,0.05);
									padding-bottom: 18px;
								`)

								,m('.details'
									+ css`
										display: grid;
										gap: 1em;
										min-height: 8em;
									`
									,elements.infoPills({
										Order: {
											icon: elements.icons.truck()
											,value: [ i.order_name, '  |  ', i.distribution_container_name() ]
										}
										,Stocked: {
											icon: elements.icons.mapPin()
											,value: i.distribution_stocked()
										}
										,Received: {
											icon: elements.icons.hash()
											, value: m(''+ colourstate, [
												i.suggestion == 'requires' ? i.requiredqty : i.order_received || 0
												,i.suggestion == 'requires'
													? 'required' + ( !i.suggestions ? ' ( not yet ordered ) ' : ' ( not yet allocated ) ' )
													: '/ ' + i.distribution_amount()
												,i.distribution_amount() - i.order_received > 0
												? [
													'receive date '
													, i.order_eta
													? new Date(i.order_eta).toLocaleDateString()
													: 'to be confirmed'
												].join(' ')
												: i.distribution_amount() && i.order_received >= i.distribution_amount()
												? 'Received'
												: i.suggestion == 'requires'
												? ''
												: ''
											].join(' '))
										}
									})

									,i.suggestion != 'requires'
									&& editMaterialPermissions()
									? m(''+css`
											display: flex;
											gap: 1.5em;
											align-items: center;
										`
										.$nest('.form-control', `
											padding: 0em;
										`)
										.$nest('input', `
											height: 100%;
											text-align: center;
											border-top: none;
											border-left: none;
											border-right: none;
										`)
										.$nest('.manuel-complete', `
											height: 100%;
										`)
										, !i.returnactive
										? [ transferItemsButton(i, { stateChanger: true }),  ]
										: returnForm(i)
									)
									: ''

								)
							)
						})
					))
					, materialItemSuggestions(item, suggestedActions)
				)

			return items
		}


	function saidItem(i, config){

		let statuses = { OK: 3, WARN: 2, ERR: 1 }

		let worstStatus = config.warnings(i, statuses)

		return m('.material-item-content'
			+ css`
				display: grid;
				gap: 1em;
				background-color: white;
				padding: 1em;
				border-radius: 0em 0.25em 0.25em 0em;
				box-shadow: 0px 2.8px 11px -5px rgb(0 0 0 / 30%)

				border-left: solid 0.2em #00b8ff;
			`
			.$nest('&.status-ok', `
				border-color: #00b8ff;
			`)
			.$nest('&.status-warn', `
				border-color: #ffe000;
			`)
			.$nest('&.status-err', `
				border-color: #ff0000;
			`)
			+ (
				worstStatus == statuses.ERR
				? '.status-err'
				: worstStatus == statuses.WARN
				? '.status-warn'
				: '.status-ok'
			)
			,m('h5'
				+ css`
					display: flex;
					align-content: center;
					gap: 0.4em;
					font-size: 1.15em;
					color: #4e4e4e;
				`
				, config.icon()
				, i.name + ' x ' + config.qtyfun(i)
			)
			, i.itemdetailarray().concat(config.suggestedActions(i)).length > 0
			&& m('.item-details', config.itemDetails(i, config))
		)
	}

	function openModal(config={}){

		const {
			selected = null
			,title
			,data
			,selectedId
			,loaders
		} = config

		modalStack.add({
			title
			, view: () =>
				elements.loadingRetain(
					loaders()
					,m('.materials'
						+ H.css`
							& {
								margin-top: 2em;
							}
							& h1,& h2,& h3,& h4,& h5,& h6 {
								margin: 0em;
							}

							& {
								display: grid;
								gap: 1em;
							}
						`
						, selected
						&& m('.selected'
							, saidItem(
								data().actual.find(
									x => x[selectedId] == selected[selectedId]
								)
								,config
							)
						)
						, data().actual.length == 1 && !data().suggested.length
						? null
						: selected && data().actual.length > 1
						? elements.centerdHorizontalRule('Other ' + title)
						: data().suggested.length == 1
						? null
						: m('h4', 'All ' + title)
						, m('.items'
							+ css`
								display: grid;
								gap: 2em;
							`
							,data().actual.map((i) =>
								selected && i[selectedId] == selected[selectedId]
								? null
								: saidItem(i, config)
							)
						)
					)
				)
			, type: 'example'
			, dismissable: true
		})
	}

	const suggestedActionsView = (config) => {

		let showModal =
			config.data().actual.length > 0
			|| Object.keys(config.data().suggested).length > 0

		let list = config.data().actual

		return showModal && m('.materials-read-only-summary'
			, m('h4', config.title)

			, elements.mobileButtonStack(
				{
					container: {
						attrs: {
							onclick: () =>
								openModal(R.merge({selected: null}, config))
						}
					}
				},
				list.map(
					i => elements.mobileButtonStack.item({
						label: [
							m(''
								+ css`
									display: none;
								`
								.tablet`
									display: grid;
									align-content: center;
									font-size: 1.5em;
								`
								, config.icon()
							)
							, m('span', i.name + ' x ' + config.qtyfun(i))
						]
						,onclick(){
							openModal(R.merge({selected: i}, config))
						}
					})
				)
			)
		)

	}

	const contacts = () =>
		data.advancedView()
		? [
			m('label.dib.control-label', 'Contacts')
			,(projectMetaData() ? projectMetaData().project_contacts : [])
				.map((pd) =>
					elements.list(
						['type'
						,'name'
						,'phone'
						,'email'
						]
						.map((ex) =>
							ex != 'user_id'
							? m(
								'p.w5'
								,pd['project_contacts_' +ex]()
							)
							: m('p.w5',
								(users().find((u) =>
								u.user_id() == pd[ex]())
								|| nullUser)
								.usersFullName
							)
						)
					)
				)
		]
		: []

	const documents = () =>
		data.advancedView()
		? projectDocumentsView({
			editProjectPermissions:
				editProjectPermissions()
			,xs: projectMetaData().project_documents()
		})
		: null

	const scheduleWarnings = () =>
		elements.alert(
			`warning` + (
				!currentVersion()
				&& data.selectedVersion()
				&& !loading()
				|| !vm().selected.allocation.allocation_id
				&& !identical(
					projectDetails()
					, baseProjectDetails()
				)
				? ''
				: '-invisible'
			)
			,!currentVersion()
			&& data.selectedVersion()
			&& !loading()
				? `An outdated schedule version has been selected.
				Work cannot be updated on this version, but any previous
				work can be found on the latest version with all remaining
				works. Make amendments in the new version if required`
				: !vm().selected.allocation.allocation_id
				&& !identical(
					projectDetails()
					, baseProjectDetails()
				)
					? STRING.INFO.SAVE_OR_DISCARD_TO_UPDATE_OTHERS()
					: ''
		)

	const contextSelection = () =>
		m(''
			+ css`
				display: grid;
				grid-auto-flow: column;
				justify-content: center;
				align-items: center;
				gap: 0.5em;
				font-size: 1.5em;
				min-height: 1.6em;
				padding-bottom: 2em;
			`
			.desktop(`
				justify-content: start;
			`)
			.$nest('.manuel-complete', `
				font-size: 0.7em;
			`)
			,m('label'+css`margin: 0px;`, 'Project ')
			,projectNameUnlabelled()
			,disciplineNameUnlabelled()
		)


	const topSection = () => [
		m('.no-splitpane',
			data.advancedView()
			? [
				contextSelection()
				,resourcing()
				,between()
				,taskReports()
				,m('br')
				,scheduleWarnings()
			]
			: [
				contextSelection()
				,taskReports()
			]
			, projectMetaDataLoading()
			? elements.centeredSpinner()
			: [
				m('br')
				,documents()
				,...contacts()
				,notes()
				,suggestedActionsView({
					title: 'Materials'
					,data: materials
					,selectedId: 'supplier_items_id'
					,loaders: () => materialloading() || !fetchedExtras() || forceMaterialFetch()
					,icon: elements.icons.box
					,qtyfun: (i) => i.frequired || i.allocated + i.required
					,suggestedActions: item =>
						(
							materials().suggested[item.id]
							&& materials().suggested[item.id]['mogroupedbyitem'] || []
						)
						.concat(
							materials().suggested[item.id]
							? {
								order_name: ''
								,distribution_amount:
									materials().suggested[item.id]['requiredqty']
								,order_received: 0
								,newOrder: true
								,distribution_entity_name:
									elements.nonStrikeList(
										R.uniqBy(
											R.prop('project_name')
											,materials().suggested[item.id]['fmgroupedbyitem']
										)
										.map((m) => ({label: m.project_name}))
									)
							}
							: []
						)
					,warnings:
						(i, statuses) => {
							i.itemdetailarray()
							.flatMap((pd) => i.dispersion[pd.id] || [])
							.map((x) => {
								if ( x.distribution_ordered ) {
									if ( x.order_received == 0 && x.distribution_amount() > 0 ) {
										return statuses.ERR
									} else if ( x.order_received < x.distribution_amount() ) {
										return statuses.WARN
									}  else {
										return statuses.OK
									}
								}  else if ( x.newOrder ) {
									return statuses.WARN
								} else {
									return statuses.OK
								}
							})
							.sort((a,b) => a - b )
							[0]
						}
					,colorfun:
						(i) =>
							i.distribution_ordered
							&& i.order_received < i.distribution_amount()
							|| i.newOrder
							? '.red' : ''
					,itemDetails: materialItemDetails
				})
				,suggestedActionsView({
					title: 'Tools'
					,data: requiredTools
					,selectedId: 'tools_id'
					,loaders: () => toolloading() || !fetchedExtras() || forceToolfetch()
					,icon: elements.icons.tool
					,qtyfun: (i) => i.total
					,suggestedActions: (i) => i.suggested
					,warnings:
						(i, statuses) => {
							if ( i.required && i.assigned < i.required && !i.suggested.length ) {
								return statuses.ERR
							} else if ( i.assigned < i.required ) {
								return statuses.WARN
							} else {
								return statuses.OK
							}
						}
					,colorfun: R.F
					,itemDetails: toolItemDetails
				})
			]
		)
		, readOnlyFiles({})
	]

	const dayTaskPicker = () => [
		elements.tabset(['Days', 'Tasks', 'Tools'], activeLandingTab)
		,loading()
		|| vm().pending()
			? elements.centeredSpinner()
			: activeLandingTab() == 'Days'
				? disciplineListView()
				: activeLandingTab() == 'Tools'
				? ToolsTab()
				: ExtraDetailTab()
	]

	const scheduleWarnings2 = () =>
		schedule_id()
		&& projectOptions().length
		? null
		: [

			loading()
			|| vm().pending()
				? null
				: data.readSchedulePermissions()
					&& (
						!projectOptions()
						|| !projectOptions().length
					)
					&& schedule_id()
						? elements.alert(
							'warn',`Work has not been allocated in this schedule`
						)
			: data.readSchedulePermissions()
				&& !schedule_id()
					? elements.alert(
						'warn'
						,`Select a schedule to see all works assoicated with it`
					)
				: elements.alert(
					'warn'
					,`Requires Schedule Permissions`
				)
		]

	const topSectionAndPickers = () =>
		schedule_id()
		&& projectOptions().length > 0
		&& [
			...topSection()
			,m('br')
			,dayTaskPicker()
		]

	const allocationdata =
		(a) => ({
			Project: a.project_name
			,Date: moment( a.allocations_allocation_date())
				.format("ll")
			,Discipline: a.discplineNameDescription

			,Completed:
				a.allocations_allocation_complete()
				+ (
					a.allocations_allocation_forecast()
					? ' of ' + a.allocations_allocation_forecast()
					: ''
				)
			,Resources:
				data.readResourcePermissions()
					|| !data.readProjectPermissions()
					? working_contractors(a)
						|| a.allocations_bad_day()
						|| a.allocations_day_status()
						|| a.allocations_weather_status()
					: 'Requires Resource Permissions'
			,Teams:
				data.readResourcePermissions()
				|| !data.readProjectPermissions()
					? working_crews(a)
					: 'Requires Resource Permissions'
		})

	const largeAllocationsTable = () =>
		scrollableTable.advanced({
			scoped: data.scoped
			,resize$: data.resize$
			,alwaysopen: true
			,rows:
				ListProcessing(
					sortedAllocations()
					, filterFunction.updateFilters.filters()
					, null
					, filteredAllocationOptions
				)
				.map((t, tindex) => ({
					key: t.key
					,selection: completedCheckboxAttrs(t)
					,header:
						tindex != 0
						? ''
						: m('.header'
							+ css`
								font-weight: bold;
								display: flex;
								justify-content: space-between;
								align-items: center;
								padding-bottom: 0.5em;
							`
							,m('span'
								+ css`
									font-weight: bold;
								`
								,''
							)
						)
					,layouts: {
						expanded: ({ x, headers, i }) =>
							m('.expanded'
								+ css`
									display: grid;
									justify-content: space-between;
									grid-template-columns: 1fr;
								`
								, m('.data'
									+ css`
										display: grid;
										justify-content: space-between;
										grid-template-columns: 1fr 0.75fr 1.5fr 1.25fr 0.75fr 1.5fr;
										overflow: hidden;
										gap: 1em;
										align-items: start;
										align-content: start;
									`
									, headers
										.map(
											k => [
												i == 0
												? m('.data'
													+ css`
														display: grid;
														grid-template-rows: 0.25fr 0.5fr;
														overflow: hidden;
													`
													,[m('label.control-label', k), x[k]]
												)
												: m('.data'
													+ css`
														display: grid;
														overflow: hidden;
													`
													,x[k]
												)
											]
										)

								)
							)
					}
					,data: allocationdata(t)
				}))
		})

	const smallAllocationsTable = () =>
		scrollableTable.advanced({
			scoped: data.scoped
			,resize$: data.resize$
			,alwaysopen: true
			,rows:
				ListProcessing(
					sortedAllocations()
					, filterFunction.updateFilters.filters()
					, null
					, filteredTaskOptions
				)
				.map((t) => ({
					key: t.key
					,header:
						m('span'
							+ css`
								font-weight: bold;
								display: flex;
								justify-content: space-between;
								align-items: center;
								padding-bottom: 0.5em;
							`
							,m('input'
								+ css`
									width: 25px;
									height: 25px;
									margin: 0px;
								`
								.$media('(hover: hover)',
									css`
										width: 20px;
										height: 20px;
									`
								)
								,completedCheckboxAttrs(t)
							)
							,m('.data'
								+ css`
									display: grid;
									grid-template-columns: 0.5fr 0.5fr;
									justify-content: space-between;
									align-items: start;
									padding-right: 0.25em
								`
								,[

										t.allocations_allocation_complete()
										+ ' of '
										+ t.allocations_allocation_forecast()

									,moment(t.allocations_allocation_date()).format("ll")
								]
								.map((k) =>
									m('.data'
										+ css`
											display: grid;
											padding-right: 1em
										`
										,k
									)
								)
							)
						)
					,layouts: {
						expanded: ({ x }) =>
							m('.expanded'
								+ css`
									display: grid;
									justify-content: space-between;
									grid-template-columns: 1fr;
								`
								,m('.data'
									+ css`
										display: grid;
										justify-content: space-between;
										grid-template-columns: 0.25fr 0.5fr 0.25fr;
										padding-bottom: 2em;
									`
									,['Project', 'Discipline', 'Completed']
									.map((k) =>
										m('.data'
											+ css`
												display: grid;
												grid-template-rows: 0.25fr 0.75fr;
												padding-right: 0.25em
											`
											,[
												m('label.control-label.f4', k)
												, x[k]
											]
										)
									)
								)

								, m('.data'
									+ css`
										display: grid;
										justify-content: space-between;
										grid-template-columns: 0.25fr 0.75fr;
										padding-bottom: 1em;
									`
									,['Resources', 'Teams']
									.map((k) =>
										m('.data'
											+ css`
												display: grid;
												grid-template-rows: 0.25fr 0.25fr;
												padding-right: 0.5em;
											`
											,[m('label.control-label.f4', k), x[k]]
										)
									)
								)
							)
					}
					,data: allocationdata(t)
				}))
		})


	const allocationsTable = () =>
		m(Responsive
			,
			{
				breakpoints: {
					mobile:0
					,desktop:900
					,tablet:700
					,hd:1800
				}
			}
			,({ hd, desktopOnly, mobileOnly, tabletOnly }) => [
				hd( largeAllocationsTable )
				,desktopOnly( largeAllocationsTable  )
				,mobileOnly( smallAllocationsTable )
				,tabletOnly( smallAllocationsTable )
			]
		)

	function layout(...children){
		return m('div.harth'
			+ css
				.desktop`
					display: grid;
					grid-template-columns: max(90vw);
					justify-content: center;
					padding-top: 2em;
				`
			, ...children
		)
	}

	function ListAllocations(){
		return layout(
			topSectionAndPickers()
			, scheduleWarnings2()
		)
	}

	const completedCheckboxAttrs = (a) =>
		({
			onchange(event){
				if(event.currentTarget.checked == true){
					contractIndex(-1)
					timesheetIndex(-1)
					vm().revert = cloningAllocation(a)
					vm().selected.allocation = a

					allocationChange(true)

					tools_vm(cloningTool())
					data.calculatedData(
						data.calculatedData()
					)
					activeTab('Timesheets')


				} else if (event.currentTarget.checked == false) {
					timesheet_vm().selected.timesheet = []
					eotDeletions([])
					nodDeletions([])
					varDeletions([])
					vm().selected.allocation = {}
					vm().revert = {}
					allocationChange(false)
				}

				// stop row from opening from
				// clicking the checkbox
				event.stopPropagation()
			}
			,type: 'checkbox'
			,checked: vm().revert.allocation_id
				? vm().revert.allocation_id() == a.allocation_id()
				: false
			,disabled: false,
		})

	function disciplineListView(){
		return [
			m('br')
			,getfilters(null, "Update")
			,m('br')
			,loading()
			|| vm().pending()
				? elements.centeredSpinner()
					: !allocations()
						|| !allocations().length
				? data.readProjectPermissions()
					&& data.selectedVersion()
					? elements.alert(
						'warning'
						,`I don't have scheduled work on this date and project`
					)
				: data.readProjectPermissions()
					? elements.alert(
						'warning'
						,`Select a schedule version`
					)
					: null
				: allocationsTable()
		]
	}

	const STRING = {
		WARNING: {
			TEAM_CONNECTION: () =>
				'Connection to a team is required to update completed quantities for this team on this day'

			,NO_RECORDED_TIMESHEETS: () =>
				`I don't have any recorded timesheets for this work`
		}
		,INFO: {
			NOTICE_OF_DELAY: () =>
				`A Notice of Delay is a potential delay to today's work that releases liability to the current time frames. This notice is applied to every
				future day of work until an Extension of Time is created to flag that the delay has ended and works can resume. An Extension of Time as mentioned,
				indicates a previous Notification of Delay has been resolved and works can continue from this date with contractual liability to time`

			,NO_CONTRACT_OBLIGATIONS: () =>
				`I have no contract obligations recorded for reference`
			,WORKS_MODULE_ACTIVATION: () =>
				`Activate the module for Recording EOTs, Variations and Delays Tools to use this tracking`
			,CHANGE_COMPLETED_WORK_DATE: () =>
				`Change the Completed Work Date to today or sometime in the past to report progress`

			,NO_TOOLS_AGAINST_SCHEDULES_AND_TEAMS: () =>
				'Tools have not been created for tracking against work schedules and Teams'
			,ALLOCATION_COMPLETION_COMMENTS: () =>
				"This important Information is fed back to the project screens for use as a summary to upper level management"
			,SAVE_OR_DISCARD_TO_UPDATE_OTHERS: () =>
				'Save or Discard these items to continue updating other projects or disciplines'
		}
		,TITLE: {
			CONTRACT_DESCRIPTION: () =>
				"A Description of why this contract occurred for formal documentation"
			,CONTRACT_CLAUSE: () =>
				"The Formal contract clause that forces this to apply"
			,ALLOCATION_DATE: () =>
				"Date this work is complete. Can be altered but won't be counted if placed in the future"
			,TOTAL_WORK_COMPLETED: () =>
				"Total work completed by all teams, filling this in will distribute the work evenly between the working teams"
			,DISCARD_DATES: () =>
				"Discard date and completion updates since last saved, this does not include tools or timesheets. Seperate buttons conduct this action in their representative view"
			,WORK_COMPLETE_DATE: () =>
				"Date this work is complete. Can be altered but won't be counted if placed in the future"
		}
		,ERROR: {
			DUPLICATED_COMPLETED_WORK_DATE: ({
				d, allocationIndex, uniqAllocation
			}) =>
				allocationIndex[uniqAllocation(d)] > 1 && currentVersion()
					? `change the Completed Work Date to a day that is not duplicated with other pieces of work under the same project and discipline`
					: null
		}
	}

	const TimesheetsTable = () =>
		elements.table(data,
			[''
			, 'Completed Work'
			, 'Hours Worked'
			, 'Project Notes'
			, 'Performance Notes'
			],
			timesheet_vm().selected.timesheet.map((t) => [

				t.crew_name() ? t.crew_name() : t.user_name()

				,!editAssignedTimesheetsPermissions()
					&& !editTimePermissions()
					? t.crew_work()
					: !crewsIndexedByAID()[t.user_id()]
					&& !crewsIndexedByAID()[t.crew_id()]
						? STRING.WARNING.TEAM_CONNECTION()
						: m(
							NumberInput
							,R.merge(
								data
								,{
									errors: errors
									,errorLabel:
										(t.crew_name() || t.user_name())
										+ ' Completed Work'
								}
							)
							,{
								prop: (v) => addSpecificWork(t, v)
								,attrs: {
									min: 0
									, step: 0.01
									, title: "Completed Work by this team"
									, disabled: t.work_conditions() == 'Auto Created Timesheet'
								}
							}
						)

				,!editAssignedTimesheetsPermissions()
					&& !editTimePermissions()
					? t.crew_hours()
					: m(
						NumberInput
						,R.merge(
							data
							,{
								errors: errors
								,errorLabel:
									(t.crew_name() || t.user_name())
										+ ' Hours Worked'
							}
						)
						,{
							prop: t.crew_hours
							,attrs: {
								min: 0
								, step: 0.01
								, title: "Hours worked by this team"
							}
						}
					)
				,!editAssignedTimesheetsPermissions()
					&& !editTimePermissions()
					? t.work_conditions()
					: elements.textInput(
						t.work_conditions
						,{title: "Impairing Work Conditions"
						, disabled: t.work_conditions() == 'Auto Created Timesheet'
						}
					)
				,!editAssignedTimesheetsPermissions()
					&& !editTimePermissions()
					? t.crew_performance()
					: elements.textInput(
						t.crew_performance
						,{ title: "Teams Performance"
						})
			])
		)

	function TimesheetsTab(){
		return [
			m('hr')

			,vm().pending()
			|| !fetchedTime()
				? elements.centeredSpinner()
				: [
					elements.list([
						editTimePermissions()
						|| editAssignedTimesheetsPermissions()
							? timesheetAddView()
							: null
					])
					,m('br')
					,timesheet_vm().selected.timesheet.length != 0
						? TimesheetsTable()
						: data.readTimesheetPermissions()
						|| data.readAssignedTimesheetsPermissions()
							? elements.alert(
								'info'
								,STRING.WARNING.NO_RECORDED_TIMESHEETS()
							)
							: elements.alert(
								'info'
								,`Requires Timesheet Permissions`
							)
				]
		]
	}

	const contractTable = () =>
		elements.table(data,
			[
			''
			, 'Type'
			, '+/- Work'
			, 'Description'
			, 'Contract Clause'
			, 'Escalated'
			, 'Approval'
			, 'Created By'
			],
			contractList().map((c) => [
				editUpdatePermissions()
				&& c.newtask
					? getActivateDeleteButton(c)
					: !c.newtask
						? 'Linked to Task'
						: null

				,c.midfix == 'vars'
					? 'Variation'
					: c.midfix == 'nods'
						? 'Notice of Delay'
						: 'Extension of Time'

				,!editUpdatePermissions()
					|| !getActivateDeleteButton(c, true)
					? c['allocations_' + c.midfix + '_amount']
						? c['allocations_' + c.midfix + '_amount']()
						: null
					: c['allocations_' + c.midfix + '_amount']
					? m(
						NumberInput
						,R.merge(
							data
							,{
								errors: errors
								,errorLabel:
									c['allocations_'
										+ c.midfix
										+ '_description'
									]() + ' +/- Work'
							}
						)
						,{
							prop: c['allocations_' + c.midfix + '_amount']
							,attrs: {
								step: 0.01
								,title: "plus or minus required works"
							}
						}
					)
					: null

				,!editUpdatePermissions()
					|| !getActivateDeleteButton(c, true)
					? c['allocations_' + c.midfix + '_description']()
					: elements.textInput(
						c['allocations_' + c.midfix + '_description']
						,{ title: STRING.TITLE.CONTRACT_DESCRIPTION()
						}
					)

				,!editUpdatePermissions()
					|| !getActivateDeleteButton(c, true)
					? c['allocations_' + c.midfix + '_clause']()
					: elements.textInput(
						c['allocations_' + c.midfix + '_clause']
						,{ title: STRING.TITLE.CONTRACT_DESCRIPTION()
						}
					)

				,editContractRecognitions()
					? elements.checkbox(
						{
							onchange: m.withAttr(
								'checked'
								, c['allocations_' + c.midfix + '_escalated']
							)
							,checked:
								c['allocations_' + c.midfix + '_escalated']()
							,title: `Set to be escalated`
							,disabled: !editContractRecognitions()
						}
					)
					: c['allocations_' + c.midfix + '_escalated']()
						? 'Yes'
						: 'No'

				,editContractRecognitions()
					? elements.checkbox(
						{
							onchange: m.withAttr(
								'checked'
								, c['allocations_' + c.midfix + '_approved']
							)
							,checked:
								c['allocations_' + c.midfix + '_approved']()
							,title: `Set to be approved`
							,disabled: !editContractRecognitions()
						}
					)
					: c['allocations_' + c.midfix + '_approved']()
						? 'Yes'
						: 'No'

				,usersIndex()[c.user_id()].usersFullName

			])
		)

	function WorksTab(){
		return [
			m('hr')
			,ActiveContractsFeature()
			? [

				!vm().pending()
				&& editUpdatePermissions()
					? elements.list([
						contractAddView()
						,ContractDeleteButton()
					])
					: null

				,elements.alert(`info`,
					STRING.INFO.NOTICE_OF_DELAY()
				)

				,vm().pending()
					? elements.centeredSpinner()
					: [

						contractList().length > 0
							? m('label.control-label', 'Contract Items')
							: null

						,contractList().length > 0
							? contractTable()
							: elements.alert(
								'info'
								,STRING.INFO.NO_CONTRACT_OBLIGATIONS()
							)
					]

			]
			: elements.alert(
				'info'
				,STRING.INFO.WORKS_MODULE_ACTIVATION()
			)
		]
	}

	const projectExtrasPencil = () => t =>
		Pencil2(
			() =>
				t.project_extras_actual_amount()
				+ ' of '
				+ t.project_extras_planned_amount()
			,() =>
				m(
					NumberInput
					,R.merge(
						data
						,{
							errors: errors
							,errorLabel:
								'Task Actual Amount'
									+ ' - '
									+ t.project_extras_description()
						}
					)
					,{
						prop: (v) => linkCompeltion(v, t)
						,attrs: {
							min: 0
							, step: 0.01
							, disabled:
								!editUpdatePermissions()
							, title: "Task Actual Amount"
						}
					}
				)
		)

	const markCompletedDate = () => t =>
		elements.checkbox({
			title: 'Mark Completed Date'
			,onchange:
				m.withAttr('checked', function(e){
					const v = e ? Date.now() : false
					markTaskCompleted(t, v, v)
				})
			,disabled: !editUpdatePermissions()
			,checked: t.project_extras_date_completed()
		})

	const markTaskCompleted =
		(t, e, v) => {
			if( e != null){
				const value = !e
					? null
					: e && fullView()
					&& currAllocationDate() < Date.now()
						? currAllocationDate()
						: v || Date.now()

				t.project_extras_date_completed(value)

				e && !t.project_extras_actual_amount()
					? t.project_extras_actual_amount(
						t.project_extras_planned_amount()
					)
					: !e
					? t.project_extras_actual_amount(0)
					: null

				projectExtrasSum(
					t
					,'project_extras_date_completed'
				)

				const diffState =
					!!t.project_extras_date_invalid()

				!e ? t.project_extras_date_verified(null) : null

				t.created = true

				if (diffState){
					t.project_extras_date_invalid(null)
					projectExtrasSum(
						t
						,'project_extras_date_invalid'
					)
				}
			}
			return t.project_extras_date_completed && t.project_extras_date_completed()
		}

	const changeCompletedDate =
		(label) => t =>
			elements.dateCheckboxLabel({
				label: label
				,date: {
					title: 'Record Completed Date'
					, prop: (v) => markTaskCompleted(t, v, v)
					, attrs: {
						disabled: !editUpdatePermissions()
					}
				}
				,checkbox: {
					title: 'Mark Completed Date'
					,prop: (v) => markTaskCompleted(t, v)
					,disabled: !editUpdatePermissions()
					,attrs: {
						onchange:
							m.withAttr('checked', function(e){
								const v = e ? Date.now() : false
								markTaskCompleted(t, v, v)
							})
					}
				}
			})

	const markTestDate = () => t =>
		!editProjectPermissions() && !t.project_extras_date_verified()
		? ''
		: !editProjectPermissions()
		? moment(t.project_extras_date_verified()).format("ll")
		: elements.checkbox({
			title: 'Mark Verified Date'
			,onchange:
				m.withAttr('checked', function(e){

					const value = !e
						? null
						: e && fullView()
						&& currAllocationDate() < Date.now()
							? currAllocationDate()
							: Date.now()

					t.project_extras_date_verified(value)

					t.created = true
					projectExtrasSum(
						t
						,'project_extras_date_verified'
					)

				})
			,disabled: !editProjectPermissions() || !t.project_extras_date_completed() && !t.project_extras_date_invalid()
			,checked: t.project_extras_date_verified()
		})

	const markInvalidDate = () => t =>
		elements.checkbox({
			title: 'Mark the date this task turned invalid '
			,onchange:
				m.withAttr('checked', function(e){

					const value = !e
						? null
						: e && fullView()
						&& currAllocationDate() < Date.now()
							? currAllocationDate()
							: Date.now()

					t.project_extras_date_invalid(value)
					!e ? t.project_extras_date_verified(null) : null
					// t.project_extras_actual_amount(0)
					projectExtrasSum(
						t
						,'project_extras_date_invalid'
					)

					t.created = true
					const diffState = !!t.project_extras_date_completed()

					if (diffState){
						t.project_extras_date_completed(null)
						projectExtrasSum(
							t
							,'project_extras_date_completed'
						)
					}
				})
			,disabled: !editUpdatePermissions()
			,checked: t.project_extras_date_invalid()
		})

	const taskdata =
	(t, tableSize) =>
		({

			'Name': t.project_extras_type()
			,'Description':
				t.project_extras_description()
				|| t.relatedDiscipline
			,'Address': t.project_extras_address()
			,'Assigned':
				t.project_extras_date_assigned()
					? moment( t.project_extras_date_assigned())
						.format("ddd Do MMM")
					: ''
			,'Completed':
				fullView() && DisableDuplicateSave(true)
				? t.project_extras_date_completed()
					? moment( t.project_extras_date_completed())
						.format("ddd Do MMM")
					: ''
				: tableSize
				? changeCompletedDate()(t)
				: markCompletedDate()(t)
			,'Invalid':
				fullView() && DisableDuplicateSave(true)
				? t.project_extras_date_invalid()
					? moment( t.project_extras_date_invalid())
					.format("ddd Do MMM")
					: ''
				: markInvalidDate()(t)
			,'Verified':
				fullView() && DisableDuplicateSave(true)
				? t.project_extras_date_verified()
					? moment( t.project_extras_date_verified())
						.format("ddd Do MMM")
					: ''
				: markTestDate()(t)

			,'Amount':
				fullView() && DisableDuplicateSave(true)
				? t.project_extras_actual_amount() + ' of ' + t.project_extras_planned_amount()
				: !t.newtask
				&& !t.project_extras_date_verified()
				? projectExtrasPencil () (t)
				: t.newtask
				? t.project_extras_actual_amount()
				: t.project_extras_actual_amount()
					+ ' of '
					+ t.project_extras_planned_amount()
			,'Files': t.filecount
			,'Invoice No': [].concat(
				t.revenueinvoices
				,t.costinvoices
			)
			.join(', ')

			,'Tasked': t.username || t.invite_email
			,'Project': t.project_name
			,'Notes': t.project_extras_notes()
		})


	const largeProjectDetailsTable = () =>
		scrollableTable.advanced({
			scoped: data.scoped
			,resize$: data.resize$
			,alwaysopen: true
			,rows:
				masterTaskList()
				.map((t, tindex) => ({
					key: t.key
					,header:
						tindex != 0
						&& SelectedProject().project_name != 'Yesterday'
						&& SelectedProject().project_name != 'Today'
						&& SelectedProject().project_name != 'This Week'
						&& SelectedProject().project_name != 'Next Week'
						&& SelectedProject().project_name != 'Last Week'
						? ''
						: m('.header'
							+ css`
								font-weight: bold;
								display: flex;
								justify-content: space-between;
								align-items: center;
								padding-bottom: 0.5em;
							`
							,m('span'
								+ css`
									font-weight: bold;
								`
								,t.project_extras_priority()
								+ ' - '
								+ t.project_extras_type()
								+ ' - '
								,!t.newtask
									? t.project_name
									: discardTaskButton(t)
							)
							,m(`button`
								+ css`
									backgroundColor: transparent;
									transition: 0.2s;
									color: #434aa3;
									border: none;
								`
								.$hover(`
									opacity: 0.8;
								`)
								.$active(`
									opacity: 0.5;
								`)
								,{
									onclick: () => createTask()
									,title: 'Create a Task'
									,disabled:
										!editUpdatePermissions()
										|| !SelectedProject().project_id
								}
								,'Create a New Task '
							)
						)
					,selection: {
						checked: selectedProjectExtra() == t
						,onclick(e){
							selectedProjectExtra() == t
								? selectedProjectExtra({})
								: selectedProjectExtra(t)

							selectedProjectExtra()
								.project_extras_id
							? selectedProjectExtra()
								.created = true
							: null
							// stop row from opening from
							// clicking the checkbox
							e.stopPropagation()
						}
						,type: 'checkbox'
						,title: selectedProjectExtra() == t
							? 'Done editing'
							: 'Edit the type of this task'
					}
					,layouts: {
						expanded: ({ x, headers, i }) =>
							m('.expanded'
								+ css`
									display: grid;
									justify-content: space-between;
									grid-template-columns: 1fr;
								`
								, m('.data'
									+ css`
										display: grid;
										justify-content: space-between;
										grid-template-columns: 7.5fr 15fr 10fr 6em 6em 4em 4.5em 5fr 4fr 6fr 6fr 6fr 10fr;
										overflow: hidden;
										`
									, headers
										.map(
											k => [
												i == 0
												? m('.data'
													+ css`
														display: grid;
														grid-template-rows: 0.25fr 0.5fr;
														margin-right: 0.75em;
														padding-right: 0.75em;
														overflow: hidden;
													`
													,[m('label.control-label', k), x[k]]
												)
												: m('.data'
													+ css`
														display: grid;
														margin-right: 0.75em;
														padding-right: 0.75em;
														overflow: hidden;
													`
													,x[k]
												)
											]
										)

								)
							)
					}
					,data: taskdata(t)
					,attrs: {
						className: t.project_extras_date_completed()
								? t.project_extras_actual_amount()
									? 'highlight-ok'
									: 'highlight-error'
								: 'highlight-default'
					}
			}))
		})

	const smallProjectDetailsTable = () =>
		scrollableTable.advanced({
			scoped: data.scoped
			,resize$: data.resize$
			,alwaysopen: true
			,rows:
				masterTaskList()
				.map((t) => ({
					key: t.key
					,header:
						m('span'
							+ css`
								font-weight: bold;
								display: flex;
								justify-content: space-between;
								align-items: center;
								padding-bottom: 0.5em;
							`
							,m('input'
								+ css`
									width: 25px;
									height: 25px;
									margin: 0px;
								`
								.$media('(hover: hover)',
									css`
										width: 20px;
										height: 20px;
									`
								)
								,{
									checked: selectedProjectExtra() == t
									,onclick(e){
										selectedProjectExtra() == t
											? selectedProjectExtra({})
											: selectedProjectExtra(t)

										selectedProjectExtra()
											.project_extras_id
										? selectedProjectExtra()
											.created = true
										: null
										// stop row from opening from
										// clicking the checkbox
										e.stopPropagation()
									}
									,type: 'checkbox'
									,title: selectedProjectExtra() == t
										? 'Done editing'
										: 'Edit the type of this task'
								}
							)
							,m(`button.pl2`
								+ css`
									backgroundColor: transparent;
									transition: 0.2s;
									opacity: 1;
									color: #434aa3;
									border: none;
								`
								.$hover(`
									opacity: 0.8;
								`)
								.$active(`
									opacity: 0.5;
								`)
								,{
									onclick: () => createTask()
									,title: 'Create a Task'
									,disabled:
										!editUpdatePermissions()
										|| !SelectedProject().project_id
								}
								,'Create a New Task '
							)
							,m('span'
								+ css`
									font-weight: bold;
								`
								,''
							)
						)
					,layouts: {
						expanded: ({ x }) =>
							m('.expanded'
								+ css`
									display: grid;
									justify-content: space-between;
									grid-template-columns: 1fr;
								`
								,m('.data'
									+ css`
										display: grid;
										justify-content: space-between;
										grid-template-columns: 0.25fr 0.45fr 0.3fr;
										padding-bottom: 1em;
										gap: 0.25em
									`
									,['Name', 'Description', 'Project']
									.map((k) =>
										m('.data'
											+ css`
												display: grid;
												grid-template-rows: 0.25fr 0.25fr;
												padding-right: 0.25em
											`
											,[
												m('label.control-label.f4', k)
												, k != 'Description'
													? x[k]
													: x[k] == t.relatedDiscipline
														? x[k]
														: [x[k], t.relatedDiscipline].filter(R.identity).join(' - ')

											]
										)
									)
								)
								, m('.data'
									+ css`
										display: grid;
										justify-content: space-between;
										grid-template-columns: 0.25fr 0.25fr 0.2fr 0.3fr;
										padding-bottom: 1em;
									`
									,[ 'Verified', 'Invalid', '', 'Completed']
									.map((k) =>
										m('.data'
											+ css`
												display: grid;
												grid-template-rows: 0.25fr 0.25fr;
												padding-right: 0.5em;
											`
											,[
												m('label.control-label.f4', k)
												, m(''+css`align-items: center`, x[k])
											]
										)
									)
								)


								, m('.data'
									+ css`
										display: grid;
										justify-content: space-between;
										grid-template-columns: 0.5fr 0.2fr 0.3fr;
										padding-bottom: 0.25em;
										gap: 0.25em
									`
									,['Address', 'Tasked', 'Amount']
									.map((k) =>
										m('.data'
											+ css`
												display: grid;
												grid-template-rows: 0.5fr 0.5fr;
												padding-right: 0.5em;
											`
											,[
												m('label.control-label.f4', k)
												,x[k]
											]
										)
									)
								)


								, m('.data'
									+ css`
										display: grid;
										justify-content: space-between;
										grid-template-columns: 0.5fr 0.2fr 0.3fr;
									`
									,['Invoice No', 'Files', 'Assigned']
									.map((k) =>
										m('.data'
											+ css`
												display: grid;
												grid-template-rows: 1fr;
												padding-right: 0.5em;
											`
											,elements.list([
												m('label.control-label.f4', k)
												,x[k]
											])
										)
									)
								)


								, m('.data'
									+ css`
										display: grid;
										justify-content: space-between;
										grid-template-columns: 1fr;
										padding-bottom: 1em;
									`
									,['Notes']
									.map((k) =>
										m('.data'
											+ css`
												display: grid;
												grid-template-rows: 1fr;
												padding-right: 0.5em;
											`
											,[m('label.control-label.f4', k), x[k]]
										)
									)
								)
							)

					}
					,data:  taskdata(t, 'smallTable')
					,attrs: {
						className: t.project_extras_date_completed()
							? t.project_extras_actual_amount()
								? 'highlight-ok'
								: 'highlight-error'
							: 'highlight-default'
					}
			}))
		})


	const projectDetailsTable = () =>
		masterTaskList().length
		? m(Responsive
			,({ desktop, mobileOnly, tabletOnly }) => [
				desktop( largeProjectDetailsTable )
				,mobileOnly( smallProjectDetailsTable )
				,tabletOnly( smallProjectDetailsTable )
			]
		)
		: [
			m('br')
			, m('div'
				+ css`
				display: grid;
				height: 15vh;
				align-content: center;
				`
				,m(`button`
					+ css`
					height: 15vh;
					align-content: center;
					`
					,m(`button`
						+ css`
							backgroundColor: transparent;
							transition: 0.2s;
							opacity: 1;
							color: #434aa3;
							border: solid 1px #3380c2;
							align-items: center;
							display: grid;
							position: relative;
							height: 2.5em;
							width: 10em;
							margin-right: auto;
							margin-left: auto;
							border-radius: 0.25em;
							`
						.$hover(`
							opacity: 0.8;
						`)
						.$active(`
							opacity: 0.5;
						`)
						,{
							onclick: () => createTask()
							,title: 'Create a Task'
							,disabled:
								!editUpdatePermissions()
								|| !SelectedProject().project_id
						}
						,'Create a New Task '
					)
					,m('br')
					,m('div'
						+ css`
						align-items: center;
						text-align: center;
						display: grid;
						position: relative;
						margin-right: auto;
						margin-left: auto;
						padding: 10px;
						background-color: #fcf8e3;
						`
						,`We have zero planned tasks to be Updated.
						To review or clone previous tasks use the Quick Filters and switch to their category
						or Create new Tasks with the Create a new Task button`
					)
				)
			)
		]

	function ExtraDetailTab(){
		return [
			m('br')
			,!ActiveDetailsFeature()
				? elements.alert(
					'info'
					,`Activate the project tasks module to use this tracking`
				)
				: !fetchedExtras()
				? elements.centeredSpinner()
				: currentVersion()
				? [

					fullView()
						? elements.list([
							m("button.btn",{
								onclick: () => {
									createTask()
								}
								,title: 'Create a Task'
								,disabled:
									!editUpdatePermissions()
									|| !SelectedProject().project_id
							}, "Create a new Task")
						])
						: ''

					,getfilters(null, "Tasks")

					,fullView()
					&& DisableDuplicateSave(true)
					&& editUpdatePermissions()
						? elements.alert(
							'info'
							, STRING.INFO.CHANGE_COMPLETED_WORK_DATE()
						)
						: null

					,!projectDetails().length && fetchedExtras() || fullView()
						? null
						: elements.list( taskSaveDiscardButton() )

					,projectDetailsTable()
				]
				: elements.alert(
					'info'
					, `Work cannot be updated in an outdated schedule version.
					Amendments or new updates can be made in the newest version`
				)
		]
	}

	const assignATool = () => tool =>
		elements.checkbox({
			onchange: m.withAttr(
				'checked'
				, (e) => {
					e
						? tool.crew_id =
							(
								editToolPermissions()
								? fectchedTeams()
								: userResCrew()
							)
							.find((c) =>
								c.user_id()
								== data.auth.stream().user_id
							)
							.crew_id()

						: tool.crew_id = null

					// tools_vm([tool])
					// data.getLocation([tool], loading, sendTools)
					data.getLocation([tool], loading)
				}
			)
			,checked: exposer(tool.crew_id)
			,title: `Assign a tool`
			,disabled:

				!editToolPermissions()
				&& !editAssignedToolPermissions()
				|| !editToolPermissions()
				&& editAssignedToolPermissions()
				&& !!exposer(tool.crew_id)
				&& userResCrew().find((chr) =>
					chr.crew_id()
					== exposer(tool.crew_id)
				).user_id()
				!= data.auth.stream().user_id

		})

	const assignAToolPencil = () => tool => {

		const reflist =
			editToolPermissions()
			? fectchedTeams
			: userResCrew

		return reflist().length == 1
		? userResCrew().find((c) => c.crew_id() == tool.crew_id()).teamresourcename
		: Pencil2(
			() =>
				(reflist().find((c) => c.crew_id() == exposer(tool.crew_id)) || {teamresourcename: 'Busy'})
				.teamresourcename
			,() =>
				autocomplete.strict(
					reflist
					,R.when( Boolean, function(v){

						tool.crew_id = v.crew_id()
						tools_vm([tool])

						data.getLocation([tool], loading, sendTools)

						return v.teamresourcename
					})
					,'teamresourcename'
					,() => ({
						title: 'Assign a tool'
						,disabled:
							!userResCrew().length
							|| !editToolPermissions()
							&& !editAssignedToolPermissions()
							|| !editToolPermissions()
							&& editAssignedToolPermissions()
							&& !!tool.crew_id()
							&& userResCrew().find((chr) =>
								chr.crew_id()
								== tool.crew_id()
							).user_id()
							!= data.auth.stream().user_id

					})
				)
		)
	}

	const toggleWorkDetail = () => tool =>
		!tool.crew_id()
			? 'Assign to a team to show work forecasts'
		: loadingMeta()
			? ' ... '
		: !workList()[tool.crew_id()]
			? 'Save these tools to re-evaluate'
		: !workList()[tool.crew_id()].workList.length
			&& workList()[tool.crew_id()].totalcount
				? 'Busy'
				: m("button.btn."
					+ (
						toolDetail()[tool.crew_id()]
							? '.btn-warning'
							: ''
					)
					, {
						disabled: false
						,onclick: () => {
							toolDetail()[tool.crew_id()]
								? delete toolDetail()
									[tool.crew_id()]
								: toolDetail(
									R.merge(
										toolDetail()
										,{[tool.crew_id()]: tool.crew_id()}
									)
								)
						}
						,title: toolDetail()[tool.crew_id()]
							? "Hide the next organized work for this tool"
							: "Show the next organized work for this tool"
					}
					,toolDetail()[tool.crew_id()]
						? "Hide Detail"
						: "Show Works"
				)

	const tooldata =
		(t) => ({
			'Self Assign':
				userResCrew().length >= 2
				? assignATool()(t)
				: `Users connected to teams may take tools`
			,'Assign or Release': assignAToolPencil()(t)
			,'Tool': exposer( t.tools_name )
			,'Description': exposer( t.tools_description )
			,'Ownership': exposer( t.tools_ownership )
			,'Condition': exposer( t.tools_condition )
			,'Location': exposer( t.tools_location )
			,' ':
				!data.readProjectPermissions()
				? "Requires Project Permissions"
				: toggleWorkDetail()(t)
		})

	const largeToolsTable = () =>
		scrollableTable.advanced({
			scoped: data.scoped
			,resize$: data.resize$
			,alwaysopen: true
			,rows:
				ListProcessing(
					tools_vm()
					,filterFunction.ToolsFilters.filters()
					,'tools_name'
				)
				.map((t, tindex) => ({
					key: t.key
					,header:
						tindex != 0
						? ''
						: m('.header'
							+ css`
								font-weight: bold;
								display: flex;
								justify-content: space-between;
								align-items: center;
								padding-bottom: 0.5em;
							`
							,m('span'
								+ css`
									font-weight: bold;
								`
								,''
							)
						)
					,layouts: {
						expanded: ({ x, headers, i }) =>
							m('.expanded'
								+ css`
									display: grid;
									justify-content: space-between;
									grid-template-columns: 1fr;
								`
								, m('.data'
									+ css`
										display: grid;
										justify-content: space-between;
										grid-template-columns: 0.75fr 1.15fr 0.75fr 1.25fr 0.65fr 0.65fr 0.9fr 1.15fr;
										overflow: hidden;
										height: 10em;
										gap: 1em;
										align-items: start;
										align-content: start;
									`
									, headers
										.map(
											k => [
												i == 0
												? m('.data'
													+ css`
														display: grid;
														grid-template-rows: 0.25fr 0.5fr;
													`
													,[m('label.control-label', k), x[k]]
												)
												: m('.data'
													+ css`
														display: grid;
													`
													,x[k]
												)
											]
										)

								)
								,m('hr')
								,toolDetail()[t.crew_id()]
								? [{
									project: 'Project'
									,disciplines:'Disciplines'
								}]
								.concat(workList()[t.crew_id()].workList)
								.map((k) =>
									m('.data'
										+ css`
											display: grid;
											grid-template-columns: 0.36fr 0.64fr;
											overflow: hidden;
											align-items: start;
											align-content: start;
										`
										,['project', 'disciplines']
										.map((h) =>
											m('.data'
												+ css`
													display: grid;
													padding-top: 0.5em;
												`
												,k['project'] == 'Project'
												? m('label.control-label.f4', k[h])
												: !h
												? ''
												: h == 'project'
												? m(`li.pa0`, k[h])
												: k[h].map((d) => d.discipline_name + ' - ' + d.discipline_description).join(', ')
											)
										)
									)
								)
								: ''
							)
					}
					,data: tooldata(t)
				}))
		})

	const smallToolsTable = () =>
		scrollableTable.advanced({
			scoped: data.scoped
			,resize$: data.resize$
			,alwaysopen: true
			,rows:
				ListProcessing(
					tools_vm()
					,filterFunction.ToolsFilters.filters()
					,'tools_name'
				)
				.map((t) => ({
					key: t.key
					,header:
						m('span'
							+ css`
								font-weight: bold;
								display: flex;
								justify-content: space-between;
								align-items: center;
								padding-bottom: 0.5em;
							`
						)
					,layouts: {
						expanded: ({ x }) =>
							m('.expanded'
								+ css`
									display: grid;
									justify-content: space-between;
									grid-template-columns: 1fr;
								`
								,m('.data'
									+ css`
										display: grid;
										justify-content: space-between;
										grid-template-columns: 0.25fr 0.5fr 0.25fr;
										padding-bottom: 2em;
									`
									,['Tool', 'Description', 'Assign or Release']
									.map((k) =>
										m('.data'
											+ css`
												display: grid;
												grid-template-rows: 0.25fr 0.25fr;
												padding-right: 0.25em
											`
											,[
												m('label.control-label.f4', k)
												, x[k]
											]
										)
									)
								)


								, m('.data'
									+ css`
										display: grid;
										justify-content: space-between;
										grid-template-columns: 0.25fr 0.25fr 0.25fr 0.25fr;
										padding-bottom: 1em;
									`
									,['Self Assign', 'Ownership', 'Condition', 'Location']
									.map((k) =>
										m('.data'
											+ css`
												display: grid;
												grid-template-rows: 0.25fr 0.25fr;
												padding-right: 0.5em;
											`
											,[m('label.control-label.f4', k), x[k]]
										)
									)
								)


								, m('.data'
									+ css`
										display: grid;
										justify-content: space-between;
										grid-template-columns: 1fr;
										padding-bottom: 1em;
									`
									,[' ']
									.map((k) =>
										m('.data'
											+ css`
												display: grid;
												grid-template-rows: 1fr;
												padding-right: 0.5em;
											`
											,[m('label.control-label.f4', k), x[k]]
										)
									)
								)

								,toolDetail()[t.crew_id()]
								? [{
									project: 'Project'
									,disciplines: 'Disciplines'
									,'': ''
								}]
								.concat(workList()[t.crew_id()].workList)
								.map((k) =>
									m('.data'
										+ css`
											display: grid;
											grid-template-columns: 0.05fr 0.35fr 0.6fr;
											overflow: hidden;
											align-items: start;
											align-content: start;
										`
										,['', 'project', 'disciplines'].map((h) =>
											m('.data'
												+ css`
													display: grid;
													padding-top: 0.5em;
													margin-right: 0.75em;
													padding-right: 0.75em;
												`
												,k['project'] == 'Project'
												? m('label.control-label.f4', k[h])
												: !h
												? ''
												: h == 'project'
												? m(`li.pa0`, k[h])
												: k[h].map((d) => d.discipline_name + ' - ' + d.discipline_description).join(', ')
											)
										)
									)
								)
								: ''
							)

					}
					,data: tooldata(t)
				}))
		})


	const toolsTable = () =>
		m(Responsive
			,
			{
				breakpoints: {
					mobile:0
					,desktop:900
					,tablet:700
					,hd:1800
				}
			}
			,({ hd, desktopOnly, mobileOnly, tabletOnly }) => [
				hd( largeToolsTable )
				,desktopOnly( largeToolsTable  )
				,mobileOnly( smallToolsTable )
				,tabletOnly( smallToolsTable )
			]
		)

	function ToolsTab(){
		return [
			m('hr')
			,toolloading()
				? elements.centeredSpinner()
				: [
					getfilters(null, "Tool")
					,!fullView()
						? elements.list([
							saveButton()
							,identical(tools(), tools_vm())
								? null
								: discardButton()
						])
						: null
					,m('br')
					,tools_vm().length != 0
					? toolsTable()
					: data.readToolPermissions()
					|| editAssignedToolPermissions()
						? elements.alert(
							'info'
							,STRING.INFO.NO_TOOLS_AGAINST_SCHEDULES_AND_TEAMS()
						)
						: elements.alert(
							'info'
							,'Requires Tool Permissions'
						)
				]
		]
	}

	const allocationCompletionCommentsPencil = () =>
		Pencil2(
			() =>
				vm().selected.allocation.allocations_completion_comments
				&& vm().selected.allocation.allocations_completion_comments()
			,() => elements.textInput(
				vm()
					.selected.allocation.allocations_completion_comments
					,{ disabled: !editUpdatePermissions()
					, title: STRING.INFO.ALLOCATION_COMPLETION_COMMENTS()
					}
			)
		)

	const allocationCompletionCommentStrikeList = () =>
		elements.details(
			'Notes'
			,m(''
				,[
					m('br')
					,projectNotes().map((s) => [
						elements.list([m('p.pv1.underline.fw5', s[0])])
						, s[1].map((ss) => m('.pa0.relative.left-1.top--1', elements.list([m('.fw5', ss[0]), m('.fw6.gold', ss[1])], '.pa0')))
					])
				]
			)
			,projectNotes().length ? {} : { disabled: true }
		)

	const allocationDate = () =>
		Pencil2(
			() =>
				moment(
					vm().selected.allocation
						.allocations_allocation_date()
				)
				.format("ll")
			,() => elements.dateInput(
				(aDate) => {
					const allocationDate = vm().selected.allocation
					if(aDate){
						allocationDate.allocations_allocation_date(aDate)
						projectDetails()
							.filter((pe) =>
								pe.allocation_id()
								== allocationDate.allocation_id()
							)
							.forEach((pe) => {
								pe.created = true
								pe.project_extras_date_invalid()
									? pe.project_extras_date_invalid( aDate )
								: pe.project_extras_date_completed()
									? pe.project_extras_date_completed( aDate )
									: null
							})
					}
					return allocationDate.allocations_allocation_date()
				}
				, {
					title: STRING.TITLE.ALLOCATION_DATE()
				}
			)
		)

	const workCompletedOfForecasted = () =>
		m('label.control-label'
			,'Completed of Forecasted' // Full view
			,vm().pending()
				? ' ... '
				: editUpdatePermissions()
				&& currentVersion()
					? Pencil2(
						() => (
							completedWork()
							|| vm().selected.allocation
								.allocations_allocation_complete()
						)
							+ ' of '
							+ forecastedWork()
						,() =>
							m(
								NumberInput
								,R.merge(
									data
									,{
										errors: errors
										,errorLabel: 'Total Work Completed'
									}
								)
								,{
									prop: completedWork
									,attrs: {
										min: 0
										, step: 0.01
										, disabled:
											!editUpdatePermissions()
										, title:
											STRING.TITLE.TOTAL_WORK_COMPLETED()
									}
								}
							)
					)
					: completedWork()
		)

	const importantNotes = () =>
		elements.list([
			m('label.control-label.mb1'
				,'Important Notes'
				,editUpdatePermissions()
				&& currentVersion()
					?  Pencil2(
						() => vm()
							.selected.allocation
							.allocations_completion_comments()
							|| ' Report important information'
						,() =>
							elements.textArea(
								vm()
								.selected
								.allocation.allocations_completion_comments
								,{
									disabled: !editUpdatePermissions()
									,title:
										STRING.INFO
											.ALLOCATION_COMPLETION_COMMENTS()
								}
							)
					)
					: vm()
						.selected.allocation.allocations_completion_comments()
					|| ' Report important information'
			)
		])

	function EditAllocations(){
		return layout(

			elements.list([
				m('h4','Field Updates')
				,vm().selected.allocation.discplineNameDescription
			])

			,m('hr')

			,m('.no-split-pane',

				m('h4','Project History')
				,m('br')

				,m('label.control-label', 'Important Notes '
					,vm().pending()
						? elements.centeredSpinner()
						: [
							editUpdatePermissions()
							&& currentVersion()
								? allocationCompletionCommentsPencil()
								: null

							,m('br')
							, allocationCompletionCommentStrikeList()
						]
				)
				, projectMetaDataLoading()
					? elements.centeredSpinner()
					: [

						projectDocumentsView({
							editProjectPermissions:
								editProjectPermissions()
							,xs: projectMetaData().project_documents
								? projectMetaData().project_documents()
								: []
						})
						,m('label.dib.control-label', 'Contacts')
							,(projectMetaData() ? projectMetaData().project_contacts : [])
							.map((pd) =>
								elements.list(
									(
										editProjectPermissions()
										? ['type'
										,'name'
										,'phone'
										,'email'
										]
										: ['type'
										,'name'
										]
									)
									.map((ex) =>
										ex != 'user_id'
										? m(
											'p.w5'
											,pd['project_contacts_' +ex]()
										)
										: m('p.w5',
											(users().find((u) =>
											u.user_id() == pd[ex]())
											|| nullUser)
											.usersFullName
										)
									)
								)
							)
					]
			)

			,m('br')
			,m('br')
			,elements.list([
				m('label.control-label', 'Date '
					, editUpdatePermissions()
						&& currentVersion()
						? allocationDate()
						: moment(
							vm().selected.allocation
								.allocations_allocation_date()
						)
						.format("ll")
				)
			])
			,elements.list([
				workCompletedOfForecasted()
			])
			,activeTab() == 'Project Tasks'
				? [
					elements.list([
						m('label.control-label', 'Task Summary')
						,[
							'assigned'
							,'completed'
							,'verified'
							,'invalid'
						].map((prop) =>
							prop.charAt(0).toUpperCase() + prop.slice(1)
							+ ': '
							+ projectExtrasStat()
								['project_extras_date_' + prop]
							+ ' '
						).concat('Total: ' + projectDetails().length)
					])
				]
				: elements.list([
					m('label.control-label', 'Contract Obligations ')
					,[
						'Variations'
						,'Notice of Delays'
						,'Extensions of Time'
					].map((prop) =>
						prop
						+ ': '
						+ (

								prop == 'Variations'
									? (vm().selected.allocation.allocations_vars || []).length
									: prop == 'Notice of Delays'
										? (vm().selected.allocation.allocations_nods || []).length
										: (vm().selected.allocation.allocations_eots || []).length

						)
						+ ' '
					).concat('Total: ' + contractList().length)
				])

			,editUpdatePermissions()
				? [
					elements.list([
						elements.back(
							"Go Back to the allocations list without saving"
							,() => {

								!selectedProjectExtra().project_extras_id
									? vm().selected.allocation = {}
									: null
								!selectedProjectExtra().project_extras_id
									? vm().revert = {}
									: null
								!selectedProjectExtra().project_extras_id
									? currentDiscipline({})
									: null

								errors({})
								fullView(false)
								eotDeletions([])
								nodDeletions([])
								varDeletions([])
								fetchedExtras(false)
								viewType = ListAllocations
							}
						)

						,saveButton()
						,discardButton()
					])
				]
				: elements.list([
					elements.back(
						"Go Back to the allocations list without saving"
						,() => {

							!selectedProjectExtra().project_extras_id
								? vm().selected.allocation = {}
								: null
							!selectedProjectExtra().project_extras_id
								? vm().revert = {}
								: null
							!selectedProjectExtra().project_extras_id
								? currentDiscipline({})
								: null

							errors({})
							fullView(false)
							eotDeletions([])
							nodDeletions([])
							varDeletions([])
							fetchedExtras(false)
							viewType = ListAllocations
						}
					)
				])
			,m('br')
			,DisableEmptySave()
			,DisableDuplicateSave()
			,elements.tabset(
				['Timesheets'
				, 'Update Contractual Information'
				, 'Retrieve or Release Tools'
				, `Create Ledgers`
				, `Project Tasks`
				]
				, activeTab
			)
			,activeTab() == 'Timesheets'
				? TimesheetsTab()
			: activeTab() == 'Retrieve or Release Tools'
				? ToolsTab()
			: activeTab() == `Create Ledgers`
				? CreatInvoicePO()
			: activeTab() == 'Project Tasks'
				? ExtraDetailTab()
				: WorksTab()
		)
	}

	const view = function(){

		return m('div'
			// todo-james this should be closer to the scollable table
			// callsite, but I'll leave that to @eja as he knows
			// this route better than me
			+ H.css`
				& .scrollable-table-3 .highlight-ok {
					--border-color: #6b5b95;
					--shadow-color: hsl(257deg 24% 47% / 47%);
					box-shadow: -1px 3px 5px 0px var(--shadow-color);
					border-left-color: var(--border-color);
				}

				& .scrollable-table-3 .highlight-error {
					--border-color: #d64141;
					--shadow-color: hsl(0deg 100% 95%);
					box-shadow: -1px 3px 5px 0px var(--shadow-color);
					border-left-color: var(--border-color);
				}
			`
			,viewType()
			,DetailsPane(
				() => detailsPaneOpen()
				,vm().selected.allocation.discplineNameDescription
					? elements.list([
						m('a'
							,{
								onclick: () => {
									fetchedExtras(false)
									fullView(true)
									viewType = EditAllocations
								}
								, title: "Full View of todays work"
							}
							, 'Full View'
						)
						, ' - '
						,vm().selected.allocation.discplineNameDescription
					])
					: selectedProjectExtra().project_name
				,() => detailPaneInputs(
					vm().selected.allocation
				)
				, { key: detailsPaneKey() }

			)
		)
	}

	const deleteEOTS = () =>
		Promise.all(eotDeletions().map((e) =>
			data.api.allocations_eots.remove.one(e)
		))

	const deleteNODS = () =>
		Promise.all(nodDeletions().map((n) =>
			data.api.allocations_nods.remove.one(n)
		))

	const deleteVARS = () =>
		Promise.all(varDeletions().map((v) =>
			data.api.allocations_vars.remove.one(v)
		))

	function sendInvoices(){
		const payload = todaysInvoices()
		payload.forEach((i) => {
			i.project_name(
				vm().selected.allocation.project_name
					? vm().selected.allocation.project_name
					: SelectedProject().project_name
			)
			i.project_id(
				vm().selected.allocation.project_name
					? vm().selected.allocation.project_id()
					: SelectedProject().project_id()
			)
			i.invoice_type(
				Number(
					i.invoice_amount() * i.invoice_rate()
					+ i.invoice_service_amount() * i.invoice_service_rate()
					+ i.invoice_depreciation_amount() * i.invoice_depreciation_rate()
					+ i.invoice_appreciation_amount() * i.invoice_appreciation_rate()
					+ i.invoice_damages_rate() * i.invoice_damages_amount()
				) > 0
					? i.invoice_received()
						? 'Receipted Revenue'
						: 'Accrued Revenue'
					: i.invoice_received()
						? 'Receipted Expenditure'
						: 'Accrued Expenditure'
			)
		})
		return data.api.invoices.patch.many(payload, 'disableList')
			.then(function(res){
				deleteInvoiceItems([])
				data.originalInvoices(
					editInvoicePermissions()
						? res
						: res.filter((i) =>
							i.user_id() == data.auth.stream().user_id
							|| i.invoice_approver() == data.auth.stream().user_id
						)
				)
				return true
			})
	}


	function sendTools(){
		toolloading(true)
		m.redraw()
		return Promise.resolve(
			editToolPermissions()
			|| editAssignedToolPermissions()
			? data.api.tools.patch.many(tools_vm())
			: []
		)
			.then(() => {
				if( activeLandingTab() == 'Tools'){
					forceToolfetch(true)
					return fetchTools()
				}
				else {
					toolloading(false)
					forceToolfetch(true)
					return true
				}
			})
	}

	function fetchTools(){
		toolloading(true)
		return data.fetchTools({})
			.then( tools )
			.then( fetchWorkList )
			.then( function(){
				tools_vm(cloningTool())
				toolloading(false)
				return true
			})
	}

	const sendTimesheets = function(){
		const timesheet_list =
			timesheet_vm().selected.timesheet.map((t) =>
				identical(t, timesheets().find((st) => st.timesheets_id() == t.timesheets_id()))
				? null
				: t
			)
			.filter(R.identity)

		fetchedTime(false)
		return timesheet_list.length ? data.api.timesheets.patch.many(timesheet_list, 'disableList', 'aligntimesheets') : []
	}

	function fetchOrganizationsDisciplines(){
		return data.fetchOrganizationsDisciplines({})
			.then(organizationsDisciplines)
			.then(m.redraw)
	}

	function fetchWorkList(){
		loadingMeta(true)
		return Promise.all(
			R.uniqBy(
				byProp('crew_id')
				,tools()
			)
			.filter((a) => a.crew_id() )
			.map((a) =>
				data.fetchResourceWork(
					R.merge(
						a
						, {
							schedule_id: data.selectedVersion().schedule_id
							, schedule_versions_id: data.selectedVersion().schedule_versions_id
						}
					)
				)
			)
		)
			.then(
				R.pipe(
					R.unnest
					,R.map((a) =>
						({
							crew_id: a.crew_id
							,contractor_id: a.contractor_id
							,totalcount: Number(a.totalcount)
							,workList: R.pipe(
								R.filter(
									(p) => p.schedule_id
									== data.schedule_id()
								)
								,R.groupBy(R.prop('project_id'))
								,R.map((p) => ({
									project: p[0].project_name
									,disciplines: p
								}))
								,R.toPairs
								,R.map(R.last)
								,R.unnest
							)(a.workingarray)
						})
					)
					,R.indexBy( R.prop('crew_id') )
				)
			)
			.then( workList )
			.catch(
				e => NotificationBar.Notifications.alertError(e.message)
			)
			.then( () => loadingMeta(false) )
			.then( m.redraw )
	}

	const sendAllocations = function(){

		loading(true)
		allocations([])
		data.currentRoute('Nothing')
		allocationChange(false)

		const updateAllocations =
			vm().selected.allocation.allocation_id
			&& !identical(vm().revert, vm().selected.allocation)
			? [vm().selected.allocation]
			: []

		contractList([])

		const schedule_index =
			R.findIndex(
				R.propEq('schedule_id', data.schedule_id())
				, data.schedules()
			)

		const most_recent_version =
			R.sortBy(
				R.prop("schedule_version_created")
				,data.schedules()[schedule_index].schedule_versions
			)

		const LatestVerionIndex =
			most_recent_version.length - 1
		const schedule_version_id =
			most_recent_version[LatestVerionIndex].schedule_version_id

		const project_id =
			!SelectedProject().project_id
				? vm().selected.allocation.project_id
				: SelectedProject().project_id
				? SelectedProject().project_id()
				: null

		const patchTasks = projectDetails().filter(R.prop('created'))
		const splitTasks =
			R.unnest(
				patchTasks.filter((t) => t.users().length > 1)
				.map((t) => {
					let i = 0
					t.project_extras_actual_amount(
						Number(Number(t.project_extras_actual_amount()/t.users().length).toFixed(3)))
					t.project_extras_planned_amount(
						Number(Number(t.project_extras_planned_amount()/t.users().length).toFixed(3)))
					return R.unfold(
						(n) => {
							const tuser = n.users()[i]
							const vadd =
								cloningProjectExtra(t)

							!vadd.parents() ? vadd.parents([]) : null

							;vadd.user_id(tuser.user_id())

							!t.newtask
								? vadd.parents().push(t.project_extras_id())
								: null

							vadd.project_extras_id(uuid.v4())
							vadd.allocation_id(null)
							vadd.invite_id = null
							vadd.invite_email = null

							i = i + 1

							return i == n.users().length
								? false
								: [ vadd, n ]
						}
						, t
					)
				})
			)

		const projectExtraArray =
			R.splitEvery(
				100
				,patchTasks
				.concat(splitTasks)
				.map((t) => {
					const dprops =
						!t.newtask
						? identical(
							t
							,baseProjectDetailsHash()[t.project_extras_id()]
							,[]
							,'returnKeys'
						)
						: []

					return t.newtask || true
						? t
						: R.pick(
							R.concat(
								['project_extras_id'
								,'project_id'
								,'orphanedFiles'
								,'invite_id'
								]
								,dprops
								.some((a) =>
									a == 'user_id'
									|| a == 'discipline_id'
									|| a == 'organizations_disciplines_id'
									|| a == 'project_extras_planned_amount'
									|| a == 'project_extras_actual_amount'
									|| a == 'project_extras_date_completed'
									|| a == 'project_extras_date_invalid'
								)
								? [
									'user_id'
									,'discipline_id'
									,'organizations_disciplines_id'
									,'project_extras_planned_amount'
									,'project_extras_actual_amount'
									,'project_extras_date_completed'
									,'project_extras_date_invalid'
									,'allocation_id'
								].concat(dprops)
								: dprops
							)
							,t
						)
				})
				.map(R.omit(['newtask', 'users', 'oldusers', 'musers']))
			)

		const aclanding = activeLandingTab()

		return Promise.all([
			deleteEOTS()
			,deleteNODS()
			,deleteVARS()
			,deletetInvoices({
				makeAPICalls: true
				, api: data.api
				, invoice: null
				, deleteInvoiceItems
				, todaysInvoices
				, errors
			})
		])
		.then(function(){
			return Promise.all(
				projectExtraArray.map((sendArray) => {
					return Promise.resolve(
						data.api.project_extras.patch.many(sendArray)
					)
				})
			)
			// ALWAYS PATCH UPDATES FIRST SO OUR AUTOINVOICING WORKS FIRST
			// AND USER UPDATES AFTER OVERRIDE US
			.then(function(){
				return Promise.all(
					[
						sendInvoices()
						,sendTools()
						,sendTimesheets()
					]
					.concat(
						updateAllocations.map((a) =>
							project_id
								? data.api.allocations.patch(
									[a]
									, schedule_version_id
									, project_id
									, 'disableList'
								)
								: data.api.allocations.patch(
									[a]
									, schedule_version_id
									, null
									, 'disableList'
								)
						)
					)
				)
			})
			.then( () => fetchAllocations({
				data
				, allocations
				, allocationsHash
				, SelectedProject
				, SelectedDiscipline
				, projectOptions
				, loading
				, fetchedExtras
				, allProjects
				, dbp_vm
				, disciplineExtraOptions
				, disciplineOptions
				, allDisciplines
				, discipline_box
				, activeLandingTab: aclanding
				, retrieveAllocations
				, dateframeprojects: dateframeprojects()
				, SelectedResource
				, selectedProjectExtra
			}) )
			.then(function(){
				data.currentRoute('updates')
				eotDeletions([])
				nodDeletions([])
				varDeletions([])

				projectDetails([])
				baseProjectDetails([])

				vm().revert =
					vm().selected.allocation.allocation_id
						? allocations().find((a) =>
							a.allocation_id()
							== vm()
								.selected.allocation.allocation_id()
						)
						: {}

				vm().selected.allocation =
					vm().selected.allocation.allocation_id
						? cloningAllocation(vm().revert)
						: {}

				if( vm().selected.allocation.allocation_id ){
					consolidateContractList(
						vm().selected.allocation
					)
					setContractOptions(
						vm().selected.allocation
					)
				}

				selectedProjectExtra({})
				allocationChange(true)
				// used to bump the project extras fetch
				// and update the new saved and returned object
				// IF we are on the project extras tab
				fetchedExtras(false)
				activeTab(activeTab())


				return true
			})
			.catch( e =>
				NotificationBar.Notifications.alertError(e.message)
			)
			.then( () => loading(false) )
		})
	}

	function fetchUsers(){
		return data.fetchUsers()
		.then((res) => {
			res.forEach((u) => {
				if (data.auth.stream().user_id == u.user_id()){
					vm().current_user = u
				}
			})
			users(
				R.concat(
					[nullUser]
					,res
				)
			)
			usersIndex(
				R.indexBy(
					(u) => u.user_id()
					,users()
				)
			)
			m.redraw()
			return true
		})
		.then( getChildusers )
	}


	function getChildusers(){
		return data.fetchResource({
			props: {
				resources: [
					'contractor_id'
					,'contractor_name'
					,'user_id'
				]
				,teams: [
					'contractor_id'
					,'crew_id'
					,'crew_name'
					,'user_id'
				]
			}
			, depth: 2
		})
		.then(function(res){

			const applicableUsers =
				[].concat(
					res
					,R.unnest(
						res.map((r) =>
							r.crews
						)
					)
				)
				fectchedResources(res)
				fectchedTeams(R.unnest(res.map(R.prop('crews'))).concat(nullCrew))

				childResUsers(
					editProjectPermissions()
						? users()
						: users().filter((u) =>
							applicableUsers.find((au) =>
								u.user_id()
								== au.user_id()
							)
						)
				)
		})
	}

	asyncMergeAll(
		[
			SelectedProject
			,data.selectedVersion
			,activeLandingTab
			,retrieveAllocations
			,SelectedResource
		]
	)
	.map(function([sp , sv, ac, ra, sr]){

		if ( sv.schedule_version_id && data.schedules().length ){
			const scheduleVersions =
				R.sortBy(
					R.prop("schedule_version_created")
					,data.schedules()[
						R.findIndex(
							R.propEq('schedule_id', data.schedule_id())
							, data.schedules()
						)
					].schedule_versions
				)

			currentVersion(
				sv.schedule_version_id
				== scheduleVersions[scheduleVersions.length-1].schedule_version_id
			)
		} else {
			projectOptions([])
		}

		if(
			sp.project_name == 'All Projects'
			&& sr.ParentRecognition == 'All Resources'
		){ sr = R.head(fectchedResources()) || {} }

		allocations([])
		sortedAllocations([])

		return ac != 'Tasks' || !projectOptions().length && loading()|| ac == 'Tasks' && ra
			? Promise.all([
				fetchAllocations({
					data
					, allocations
					, allocationsHash
					, SelectedProject
					, SelectedDiscipline
					, projectOptions
					, loading
					, fetchedExtras
					, allProjects
					, dbp_vm
					, disciplineExtraOptions
					, disciplineOptions
					, allDisciplines
					, discipline_box
					, activeLandingTab: ac
					, retrieveAllocations
					, dateframeprojects: dateframeprojects()
					, SelectedResource
					, selectedProjectExtra
				})
				,ac == 'Days' && resfetch()
				? data.fetchResource({
					props: {
						resources: [
							'contractor_id'
							,'contractor_name'
							,'user_id'
						]
					}
					,depth: 1
				})
					.then((res) => {
						fectchedResources(res)
						return resfetch(false)
					})
				: []
			])
			: Promise.resolve(null)
	})

	data.currentRoute('Nothing')
	asyncMergeAll(
		[
			data.initiateContractFetch
		]
	)
	.map(function([i]){
		const ochange = i.ochange
			|| !data.routeInitialized()['updates']

		loading(true)

		const checkFunction =
			i.organization_id
			? k => Permissions.hasWrite(data.permissions(), k)
			: R.F

		editUpdatePermissions(
			['updates_management'].some(checkFunction)
		)
		editTimePermissions(
			['timesheet_management'].some(checkFunction)
		)
		editToolPermissions(
			['tool_management'].some(checkFunction)
		)
		editContractPermissions(
			['contract_management'].some(checkFunction)
		)
		editInvoicePermissions(
			['invoice_management'].some(checkFunction)
		)
		editContractRecognitions(
			['contract_recognition'].some(checkFunction)
		)
		editAssignedTimesheetsPermissions(
			['assigned_timesheets'].some(checkFunction)
		)
		editProjectPermissions(
			['project_management'].some(checkFunction)
		)
		editOwnedInvoicesPermissions(
			['owned_invoices'].some(checkFunction)
		)
		editAssignedToolPermissions(
			['assigned_tools'].some(checkFunction)
		)
		editMaterialPermissions(
			['material_management'].some(checkFunction)
		)

		ochange
			? viewType = ListAllocations
			: null

		timesheet_vm().selected.timesheet = []
		eotDeletions([])
		nodDeletions([])
		varDeletions([])
		SelectedProject({})
		vm().selected.allocation = {}
		vm().revert = {}
		projectOptions([])

		SelectedResource(allResources)
		return !ochange
			? Promise.resolve([])
			: Promise.all([
				[]
				,fetchUsers()
				,fetchOrganizationsDisciplines()
				,fetchWarehouses()
				,metaDataLoading(false)
			])
	})

	asyncMergeAll(
		[
			loading
			,activeLandingTab
		]
	)
	.map(([o]) => {

		return (
			!o && !metaDataLoading() && activeLandingTab() == 'Tools'
			? Promise.all([
				fetchTools()
				// ,data.currentRoute('updates')
				,metaDataLoading(true)
			])
			: Promise.resolve([])
		)
			.then(function(){
				if( data.initiateContractFetch() ){
					data.initiateContractFetch().schange = false
					data.initiateContractFetch().ochange = false
				}
				return true
			})
	})

	viewType = ListAllocations

	return { view }
}

export default Main