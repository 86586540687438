/* global document addEventListener window, localStorage, Promise */
import {} from './env-setup'
import {} from './polyfills'
import {} from './mithril-compat'
import m from 'bacta'
import * as H from '../../how'
import css from 'bss'
import SQL from './modules/sql'
import * as R from 'ramda'
import API from './modules/api'
import auth from './modules/auth'
import AuthPermissions from './modules/auth_permissions'
import { prop } from '../../stream'
import ScheduleModel from './models/schedules'
import ContractModel from './models/contracts'
import traverse from 'traverse'
import * as Loadable from '../../types/loadable'
import assign from 'lodash/assign'
import Features from './models/features'
import * as details from './components/detailspane2'
import * as ContextBar from './components/context-bar'
import * as topnav from './components/topnav'
import Modal from './components/modal'
import renderStream from './utils/render-stream'
import modalStream from './utils/modal-stream'

import * as ModalStack from './components/modal-stack'

import request from './request'

import consentModalService from './services/consents'
import authExpiry from './services/auth-expiry'
import onFirstAuthSuccess from './services/on-first-auth-success'

import ContractorsRoute from './modules/contractors'
import ProjectsRoute from './modules/projects'
import FlowsRoute from './modules/flows'
import InterruptionsRoute from './modules/interruptions'
import WarehousesRoute from './modules/warehouses'
import ToolsRoute from './modules/tools'
import UpdatesRoute from './modules/updates'
import ExportsRoute from './modules/exports'
import PaymentsRoute from './modules/payments'

import OrganizationsRoute2 from './modules/organizations2'
import Files from './modules/files'
import Dashboard from './modules/dashboard'
import Schedules from './modules/schedule'
import Contracts from './modules/contracts'
import NotificationBar from './components/notification'
import Access from './modules/access2'
import colors from './utils/colors'
import Organizations from './models/organizations'


css.setDebug(true)
css.$animate.out = (time, styles) => ({ dom }) => new Promise(res => {
	dom.addEventListener('animationend', res, { once: true })
	dom.classList.add(css.$animate(time, styles))
})
css.helper('hd', x =>
	css.$media('(min-width: 1800px)', x)
)
css.helper('desktop', x =>
	css.$media('(min-width: 1000px)', x)
)
css.helper('tablet', x =>
    css.$media('(min-width: 700px)', x)
)
css.helper('thinTablet', x =>
    css.$media('(min-width: 600px)', x)
)
css.helper('mouse', x =>
	css.$media('(hover:hover)', x)
)
css.helper('touch', x =>
	css.$media('(hover:none)', x)
)

css.thinTablet(
	css.$nest('body', `
		--context-bar-height: 10em;
	`)
)

css.css({
	'body': `
		--top-nav-alert: 0em;
		--color-dark: #24394b;

		--color-light: #e6eefa;
		--color-selected: #f1f9ff;
		--color-muted: #f1f9ff;
		--color-empty: #577288;
		--color-github-selected: #4078C0;
		--top-nav-height: calc(6.5em + var(--top-nav-alert));
		--color-mid: rgb(12, 15, 39);
		--color-blackboard: hsl(254, 72%, 5%);
		--top-nav-alert: 0em;
		--context-bar-height: 7em;
		position: fixed;
		height: 100%;
	`
	,'body.alerted': `
		--top-nav-alert: 4em;
	`
})


NotificationBar.component(
	document.querySelector('#notification-bar-container')
)

{
	// migrate from old url scheme
	const firstHash = window.location.hash.replace('#', '')

	// todo-james could this just be `startsWith('/')`?
	if(
		firstHash.startsWith('/dashboard')
		|| firstHash.startsWith('/data')
		|| firstHash.startsWith('/access')
	){
		window.location.href = firstHash
	}
}

Promise.resolve().then(
	() => {
		modalStream.map(
			x => {
				// duplicate history so back button dismisses modal
				if( x && x.dismissable ) {
					window.history.pushState(null, '', window.location.href)
					m.redraw()
				} else {
					m.redraw()
				}
				return null
			}
		)
		window.addEventListener('popstate', () => {
			if( modalStream() && modalStream().dismissable ) {
				modalStream(null)
			}
		})
	}
)

addEventListener('resize', () => m.redraw())

const GANTT_PREFERENCES = {
	hidden: 'rgba(0,0,0,0.5)'
	,middle: colors.mid
	,dark: colors.dark
	,font: colors.light
	,muted: colors.muted
	,link: colors.light
}

const DEFAULT_PREFERENCES = {
	hidden: 'white'
	,middle: 'rgb(246, 246, 246)'
	,dark: 'white'
	,font: 'black'
	,muted: 'black'
	,link: '#212d39'
}

const preferences = prop(DEFAULT_PREFERENCES)
function Scoped(ends){
	function scoped(...args){
		const s = prop(...args)

		if(ends){
			ends.push(s)
		}
		return s
	}


	scoped['clone'] = function(stream){


		const n = stream.map(function(i){
			return i
		})

		n.map(function(v){
			if( R.equals(stream(), n()) ){
			} else {
				stream(v)
			}

			return null

		})

		if(ends){
			ends.push(n)
		}

		return n
	}

	return scoped
}

const resize$ = prop()
{
	window.addEventListener( 'resize', onresize )
	function onresize(){
		resize$( Date.now() )
	}
	onresize()
}
window.resize$ = resize$

const onRouteChange = function({ Route }, originalData){

	const route = Route.toURL(Route.get())

	if( route.indexOf('/dashboard') > -1 ){
		preferences(GANTT_PREFERENCES)
	} else {
		preferences(DEFAULT_PREFERENCES)
	}

	const matchesOne = R.any( part => route.indexOf(part) > - 1)

	// routes that don't need an auth token
	const accessRoutes = [
		"/access/login"
		,"/access/verify"
		,"/access/forgot"
		,"/access/reset"
		,"/access/checkEmail"
		,'/access/invite'
	,]

	// routes that trigger a logout
	const logoutRoutes = [
		"/access/login"
		,"/access/forgot"
	]

	const isAccessRoute = matchesOne(accessRoutes)
	const isLogoutRoute = matchesOne(logoutRoutes)

	const loggedOut =
		originalData.auth.stream().case({
			LoggedIn: () => false
			,LoggedOut: () => true
			,Refresh: () => false
		})

	const loggedIn =
		originalData.auth.stream().case({
			LoggedIn: () => true
			,LoggedOut: () => false
			,Refresh: () => false
		})

	const redirect = !isAccessRoute && loggedOut

	if(redirect){
		localStorage.setItem('route-before-logout', m.route.get())
		m.route.set('/access/login')
	}

	if(!redirect && !isLogoutRoute && loggedIn ){

		// test if the token has expired on every route change
		// users is just an endpoint that would need an auth token
		// and would fail if their token had expired

		;( originalData.organizations.organization_id()
			? originalData.api.auths.get()
				.then(async function(response){

					auth.stream(
						auth.type.LoggedInOf(response)
					)

					try {
						const { sql } = originalData

						// destructured expression works
						const users = await sql`
							select user_username, user_id, user_email from users
						`

						// member expression works
						const orgs = await originalData.sql`
							select organization_name from organizations
						`

						// and fetching projects for good measure
						const projects = await sql`
							select project_name from projects
						`
						// eslint-disable-next-line no-undef
						console.log({ users, orgs, projects })
					} catch (e) {
						// eslint-disable-next-line no-undef
						console.error(e)
					}
				})
			: originalData.api.auths.ping()
		)
			.catch(function(error){
				NotificationBar.Notifications.alertError(error)
				m.route.set('/access/login')
			})
	}

	let ends = []
	const scoped = Scoped(ends)


	// parameterize component with data object


	function scopeStreams (a){
		if( a === modalStream ){
			return modalStream
		} else if( prop.isStream(a) ){

			return scoped.clone(a)

		} else {
			return a
		}
	}

	const componentData =
		traverse(originalData)
			.map( scopeStreams )

	componentData.scoped = scoped;

	if(isLogoutRoute){
		originalData.auth.stream( originalData.auth.type.LoggedOutOf({}) )
		originalData.organizations.organization_id(null)
		originalData.organizations.organization_name(null)
	}

	window.addEventListener('touchmove', function(e){
		if ( e.touches.length > 1 ){
			e.preventDefault()
		}
	}, { passive: false })

	return {
		view({ attrs: { attrs, render } }){
			return auth.isRefresh()
				? null
				: render({ ...attrs, data: componentData })
		}
		, onbeforeremove(){
			ends.forEach(
				s => prop.end(s)
			)

			// clear the ends list
			ends.splice(0, ends.length)
		}
	}
}

const layout = component => attrs => {

	const features = () =>
		attrs.data.getState().features

	const contentManager =
		x => m('.content-manager'
			+ css`
				margin: 0em;
				background-color: white;
				max-height: calc( 100vh - var(--top-nav-height) );
				min-height: calc( 100vh - var(--top-nav-height) );
				overflow-y: auto;
				overflow-x: hidden;
				border-radius: 0em;
				padding: 1em;
			`
			// only use this once, for the top level component of a route
			// it allows a route to opt into grid, which is only
			// necessary because not all components render correctly
			// with a grid parent right now
			.$nest('.grid-content-full-height', `
				display: grid;
				min-height: calc(100vh - var(--top-nav-height) - 2em )
				padding-bottom: var(--context-bar-height);
			`)
			,x
		)

	const nav =
		m(topnav.component, attrs)

	renderStream(Date.now())

	return m('.app' + css`
			position: relative;
			overflow: hidden;
			opacity: 0;
		`
		.$animate('ease-in-out 1s 0.2s forwards', {
			'0%': 'opacity: 0;'
			,'1%': 'opacity: 0;'
			,'100%': 'opacity: 1;'
		})
		,m('.layout'+css`
			--harth-blue: rgb(19, 15, 60);
			height: 100vh;
			background-color: #1b1a26;
		`
			,nav
			,contentManager(m(component, attrs))
			,m('.details'
				// // todo-james this span magically stops mithril from
				// // remounting the details pane when cloning in updates
				// // not sure why, but I think it's related to our misuse of
				// // arrays as fragments when migrating from 0.2x
				// // could totally disappear when going to mithril v2
				// // so retest then
				// ,m('span')
				,details.stream() ( features() )

			)
			,m(ContextBar.Portal)
		)
		,m('.overlay' + css`
			background-color: #110b17;
			transition: 0.2s;
			transform: translate3d(0,0,0);
			position: absolute;
			top: 0px;
			left: 0px;
			width: 100vw;
			height: 100vh;
			z-index: 2;
		`
		+ css`
			opacity: ${ modalStream() ? '0.85' : '0' };
			pointer-events: ${ modalStream() ? 'inherit' : 'none' };
		`
		, {
			onclick() {
				if( modalStream() && modalStream().dismissable ) {
					window.history.back()
				}
			}
		}
		)
		,modalStream() && typeof modalStream().content === 'function'
			? m(Modal,
				R.mergeAll([
					{ content: modalStream().content }
					,modalStream().offset ? { offset: modalStream().offset } : {}
				])
			)
			: null
		, m(ModalStack.Overlay)
		, m(ModalStack.Main)
	)
}

const remountOnHrefChange = Component => {
	return function(){
		return {
			view({ attrs }){
				return m(Component
					, Object.assign({}, attrs, {key: m.route.get()})
				)
			}
		}
	}
}

H.start({
	defaultRoute: x => x.Autodirect()
	,container: window.container
	,routes: {
		Resources: [
			'/data/resources', () => layout(ContractorsRoute)
		]
		,Projects: [
			'/data/projects',() => layout(ProjectsRoute)
		]
		,Workflows: [
			'/data/workflows',() => layout(FlowsRoute)
		]
		,Interruptions: [
			'/data/interruptions',() => layout(InterruptionsRoute)
		]
		,Financials: [
			'/data/contracts/',() => layout(remountOnHrefChange(Contracts))
		]
		,FinancialsCreate: [
			'/data/contracts/contracts/create/forecastdateA/:forecastdateA/forecastdateB/:forecastdateB'
			,() => layout(remountOnHrefChange(Contracts))
		]
		,FinancialsClone: [
			'/data/contracts/contracts/clone/forecastdateA/:forecastdateA/forecastdateB/:forecastdateB'
			,() => layout(remountOnHrefChange(Contracts))
		]
		,FinancialsView: [
			'/data/contracts/contracts/view/forecastdateA/:forecastdateA/forecastdateB/:forecastdateB'
			,() => layout(remountOnHrefChange(Contracts))
		]
		,FinancialsViewLedgers: [
			'/data/contracts/contracts/view/forecastdateA/:forecastdateA/forecastdateB/:forecastdateB/ledger/:contract_id'
			,() => layout(remountOnHrefChange(Contracts))
		]
		,FinancialsEdit: [
			'/data/contracts/contracts/edit/:contract_id/forecastdateA/:forecastdateA/forecastdateB/:forecastdateB'
			,() => layout(remountOnHrefChange(Contracts))
		]
		,FinancialsEditLedgers: [
			'/data/contracts/contracts/edit/:contract_id/forecastdateA/:forecastdateA/forecastdateB/:forecastdateB/ledger/:contract_items_id'
			,() => layout(remountOnHrefChange(Contracts))
		]
		,FinancialsItems: [
			'/data/contracts/contractitems/forecastdateA/:forecastdateA/forecastdateB/:forecastdateB'
			,() => layout(remountOnHrefChange(Contracts))
		]
		,FinancialsItemsLedgers: [
			'/data/contracts/contractitems/forecastdateA/:forecastdateA/forecastdateB/:forecastdateB/ledger/:contractrecognitionid'
			,() => layout(remountOnHrefChange(Contracts))
		]
		,FinancialsOverviewLedgers: [
			'/data/contracts/overview/forecastdateA/:forecastdateA/forecastdateB/:forecastdateB/'
			,() => layout(remountOnHrefChange(Contracts))
		]
		,FinancialsFocussedLedgers: [
			'/data/contracts/overview/forecastdateA/:forecastdateA/forecastdateB/:forecastdateB/focus/:focus/ledger/:contractrecognitionid'
			,() => layout(remountOnHrefChange(Contracts))
		]
		,Materials: [
			'/data/materials',() => layout(WarehousesRoute)
		]
		,Tools: [
			'/data/tools',() => layout(ToolsRoute)
		]
		,Updates: [
			'/data/updates',() => layout(UpdatesRoute)
		]
		,ScheduleVersion: [
			'/data/exports/:schedule_id/schedule_versions/:schedule_version_id'
			, () => layout(ExportsRoute)
		]
		,Exports: [
			'/data/exports'
			, () => layout(ExportsRoute)
		]
		,Payments: [
			'/data/payments'
			, () => layout(PaymentsRoute)
		]
		,Organizations2: [
			'/admin/organizations'
			, () => layout(OrganizationsRoute2)
		]
		,Invites: [
			'/access/invite', () => attrs => m(Access, { ...attrs,  mode: 'Invite', key: 'access-2' })
		]
		,Login: [
			'/access/login', () => attrs => m(Access, { ...attrs,  mode: 'Login', key: 'access-2' })
		]
		,Verify: [
			'/access/verify', () => attrs => m(Access, { ...attrs,  mode: 'Verify', key: 'access-2' })
		]
		,Forgot: [
			'/access/forgot', () => attrs => m(Access, { ...attrs,  mode: 'Forgot', key: 'access-2' })
		]
		,Reset: ['/access/reset', () => attrs => m(Access, { ...attrs,  mode: 'Reset', key: 'access-2' })]

		,CheckEmailType: [
			'/access/checkEmail/:message_type'
			, () => attrs => m(Access, { ...attrs,  mode: 'CheckEmail', key: 'access-2' })
		]

		,CheckEmail: [
			'/access/checkEmail'
			, () => attrs => m(Access, { ...attrs,  mode: 'CheckEmail', key: 'access-2' })
		]

		,ScheduleClone: [
			'/data/schedules/clone/schedule_parent/:schedule_parent'
			,() => layout(remountOnHrefChange(Schedules))
		]
		,Schedule: [
			'/data/schedules/:schedule_id'
			,() => layout(remountOnHrefChange(Schedules))
		]
		,ScheduleCreate: [
			'/data/schedules/create'
			,() => layout(remountOnHrefChange(Schedules))
		]
		,Schedules: [
			'/data/schedules', () => layout(remountOnHrefChange(Schedules))
		]
		,ScheduleParameter: [
			'/data/schedules_parameters/:schedules_parameters_id'
			,() => layout(remountOnHrefChange(Schedules))
		]
		,ScheduleParameters: [
			'/data/schedules_parameters', () => layout(remountOnHrefChange(Schedules))
		]
		,Autodirect: [
			'/autodirect', () => layout(Dashboard)
		]
		,HomeEmpty: [
			'/dashboard', () => layout(Dashboard)
		]
		,HomeVersion: [
			'/dashboard/:organization_id/:schedule_id/:schedule_version_id'
			, () => layout(Dashboard)
		]
		,HomeSchedule: [
			'/dashboard/:organization_id/:schedule_id', () => layout(Dashboard)
		]
		,HomeOrganization: [
			'/dashboard/:organization_id', () => layout(Dashboard)
		]
		,Files: [
			'/data/files', () => (attrs) => m(Files, attrs)
		]
	}
	,services({ $, Route }){

		const organizations = Organizations(auth, $)

		organizations.organization_name.map(function(organization_name){

			if( typeof trackJs != 'undefined' ){
				// eslint-disable-next-line no-undef
				trackJs.addMetadata(
					"organization_name", organization_name
				)
			}

			return null
		})

		const organization = organizations.organization
		const organization_id = organizations.organization_id

		const auth_permissions =
			AuthPermissions(auth.stream, organization_id)

			organization_id(null)

		const api = API(auth.stream, organization_id)

		const permissions = prop.dropRepeats(auth_permissions)

		const initial = {
			api
			, organization
			, organization_id
			, permissions
		}

		window['expose'] = function expose(){
			window['prop'] = prop
			window['R'] = R
			window['m'] = m
			window['H'] = H
			window['css'] = css
			window['data'] = originalData
			window['initial'] = initial
		}

		const scheduleData = ScheduleModel(initial)

		// todo-james this is a hack to ensure the schedule version is cleared
		// when an org is deleted.  We should instead make the schedule model
		// clear the version if the schedule is loaded and empty
		organization_id.map(function(id){
			if( id == null ){
				scheduleData.schedulesLoadable(Loadable.Loaded([]))
			}
			return null
		})


		const sql = SQL({
			organization_id: organizations.organization_id,
			schedule_id: scheduleData.schedule_id,
			auth_token: auth.stream.map( x => x.auth_token ),
		})

		let originalData = assign(
			initial
			,scheduleData
			,ContractModel(
				api
				,scheduleData
				,auth_permissions
				,organization_id
				,permissions
				,api.buildRequest
			)
			,{
				auth
				,preferences: prop.dropRepeats(preferences)
				,organizations
				,auth_permissions
				,permissions
				,modal: modalStream
				,topNavBanner: prop()
				,requests: request.requestStream
				,responses: request.responseStream
				, sql
			}
		)

		originalData.resize$ = resize$

		Route.isLogin =
			Route.fold({
				Login: () => true
				, Updates: () => false
				, HomeEmpty: () => false
				, Autodirect: () => false
				, HomeVersion: () => false
				, HomeSchedule: () => false
				, HomeOrganization: () => false
				, Files: () => false
				, Projects: () => false
				, Resources: () => false
				, Workflows: () => false
				, Tools: () => false
				, Financials: () => false
				, FinancialsItems: () => false
				, FinancialsCreate: () => false
				, FinancialsClone: () => false
				, FinancialsView: () => false
				, FinancialsViewLedgers: () => false
				, FinancialsEdit: () => false
				, FinancialsEditLedgers: () => false
				, FinancialsItemsLedgers: () => false
				, FinancialsOverviewLedgers: () => false
				, FinancialsFocussedLedgers: () => false
				, Materials: () => false
				, Exports: () => false
				, Organizations2: () => false
				, Reset: () => false
				, Interruptions: () => false
				, ScheduleVersion: () => false
				, Payments: () => false
				, Invites: () => false
				, Verify: () => false
				, Forgot: () => false
				, CheckEmailType: () => false
				, CheckEmail: () => false
				, ScheduleClone: () => false
				, ScheduleCreate: () => false
				, Schedule: () => false
				, Schedules: () => false
				, ScheduleParameter: () => false
				, ScheduleParameters: () => false
			})

		Route.isDataRoute =
			Route.fold({
				Login: () => false
				, Updates: () => true
				, HomeEmpty: () => true

				// true because autodirect assumes they are logged in
				// if you are logged in, it is a data route
				, Autodirect: () => true

				, HomeVersion: () => true
				, HomeSchedule: () => true
				, HomeOrganization: () => true
				, Files: () => true

				, Projects: () => true
				, Resources: () => true
				, Workflows: () => true
				, Tools: () => true
				, Financials: () => true
				, FinancialsItems: () => true
				, FinancialsCreate: () => true
				, FinancialsClone: () => true
				, FinancialsView: () => true
				, FinancialsViewLedgers: () => true
				, FinancialsEdit: () => true
				, FinancialsEditLedgers: () => true
				, FinancialsItemsLedgers: () => true
				, FinancialsOverviewLedgers: () => true
				, FinancialsFocussedLedgers: () => true
				, Materials: () => true
				, Exports: () => true
				, Organizations2: () => true
				, Reset: () => false
				, Interruptions: () => true
				, ScheduleVersion: () => true
				, Payments: () => true
				, Invites: () => false
				, Verify: () => false
				, Forgot: () => false
				, CheckEmailType: () => false
				, CheckEmail: () => false
				, ScheduleClone: () => true
				, ScheduleCreate: () => true
				, Schedule: () => true
				, Schedules: () => true
				, ScheduleParameter: () => true
				, ScheduleParameters: () => true
			})

		Route.isAccessRoute =
			Route.fold({
				Login: () => true
				, Updates: () => false
				, HomeEmpty: () => false
				, Autodirect: () => false
				, HomeVersion: () => false
				, HomeSchedule: () => false
				, HomeOrganization: () => false
				, Files: () => false
				, Projects: () => false
				, Resources: () => false
				, Workflows: () => false
				, Tools: () => false
				, Financials: () => false
				, FinancialsItems: () =>  false
				, FinancialsCreate: () => false
				, FinancialsClone: () => false
				, FinancialsView: () => false
				, FinancialsViewLedgers: () => false
				, FinancialsEdit: () => false
				, FinancialsEditLedgers: () => false
				, FinancialsItemsLedgers: () => false
				, FinancialsOverviewLedgers: () => false
				, FinancialsFocussedLedgers: () => false
				, Materials: () => false
				, Exports: () => false
				, Organizations2: () => false
				, Reset: () => true
				, Interruptions: () => false
				, ScheduleVersion: () => false
				, Payments: () => false
				, Invites: () => true
				, Verify: () => true
				, Forgot: () => true
				, CheckEmailType: () => true
				, CheckEmail: () => true
				, ScheduleClone: () => false
				, ScheduleCreate: () => false
				, Schedule: () => false
				, Schedules: () => false
				, ScheduleParameter: () => false
				, ScheduleParameters: () => false
			})

		Route.requiresAuth =
			route => !Route.isAccessRoute(route)

		const setState = f => $(f)
		const getState = $.$stream()
		m.route.Route = Route
		m.route.setState = setState
		m.route.getState = getState

		setState(
			R.merge({
				features: {}
			})
		)

		Features.service({ getState, auth: auth.stream })
			.map(setState)

		// do GPU styles only when resizing
		// e.g opacity 0 on context bar when resizing ✅
		// and changing dimensions, fonts, layout when resizing ❌
		resize$
			.map( () => {
				document
					.body
					.classList
					.add('resizing')
				return null
			})

		// after resizing has ceased for 1/2 second
		// update a viewport height css var
		// other components can use this to know the current
		// visible height
		resize$
			.afterSilence(500)
			.map( () => {

				document
					.body
					.classList
					.remove('resizing')
				return null
			})

		const features = prop.dropRepeats(getState.map( x => x.features ))

		originalData.features = features
		originalData.getState = getState
		originalData.setState = setState
		originalData.Route = Route

		prop.dropRepeats(getState.map( x => x.route.value )).map(
			x => {
				if(
					'organization_id' in x
					&& x.organization_id != organization_id()
				){
					organization_id(x.organization_id || null)
				}

				if(
					'schedule_id' in x
					&& x.schedule_id
						!= scheduleData.actual.schedule_id()
				){
					scheduleData.actual.schedule_id(
						x.schedule_id || null
					)
				}

				if(
					'schedule_version_id' in x
					&& x.schedule_version_id
						!= scheduleData.actual.schedule_version_id()
				){
					scheduleData.actual.schedule_version_id(
						x.schedule_version_id || null
					)
				}

				return null
			}
		)

		// Picker services
		const PickerService = ({readId, preferredId, id, list}) => {
			const out = prop()
			list.map(
				xs => {
					if( xs && xs.length && readId() == null ){

						const next =
							preferredId
							? xs.find( x => id(x) == preferredId ) || xs[0]
							: xs[0]

						out(id(next))
					}

					return null
				}
			)
			return out
		}

		PickerService({
			readId: originalData.organization_id
			, preferredId: localStorage.previousOrganizationId
			, id: x => x.organization_id
			, list: originalData.organizations.organizations
		})
		.map( id =>
			originalData.organization_id(id)
		)

		originalData.organization_id.map(
			x => x
			? localStorage.previousOrganizationId = x
			: null
		)

		PickerService({
			readId: originalData.actual.schedule_id
			, preferredId: localStorage.previousScheduleId
			, id: x => x.schedule_id
			, list: originalData.schedules
		})
		.map(
			id => {
				if( originalData.schedules().length ) {
					originalData.actual.schedule_id(id)
				}
				return null
			}
		)

		originalData.actual.schedule_id.map(
			x => x
			? localStorage.previousScheduleId = x
			: null
		)

		originalData.versions.map(
			() => {
				if( originalData.versions().length
					&& (
						!originalData.actual.schedule_version_id()
						|| !originalData.versions().find(
							x => x.schedule_version_id
								== originalData.actual.schedule_version_id()
						)
					)
				) {
					originalData.actual.schedule_version_id(
						originalData.versions()[0].schedule_version_id
					)
				}

				return null
			}
		)

		features.map(
			() => m.redraw()
		)

		async function auth_refresh(){
			if( Route.isAccessRoute( Route.get() ) ) {
				return auth.stream(auth.type.LoggedOut())
			}
			return api.auths.refresh()
				.then(function(r){
					auth.stream(auth.type.LoggedInOf(r))
				})
				.catch(function(e){
					auth.stream(auth.type.LoggedOut())
					throw e
				})
		}
		originalData.auth_refresh = auth_refresh

		originalData.organizations.services({ auth_refresh })

		auth.stream
			.dropRepeats()
			.filter( () => auth.isRefresh() )
			.throttle(1000)
			.map( auth_refresh )

		auth.stream
			.dropRepeats()
			.filter( () => auth.isLoggedOut() )
			.throttle(1000)
			.map( async () => {
				if ( Route.isDataRoute(Route.get()) ) {
					m.route.set('/access/login')
				}

				await api.auths.logout()
				return null
			} )

		// A BIG HACK
		// There's a bug in the state-router where it diffs values
		// And decides for some unknown reason not to re-emit a change
		// I'm going to make the state router more explicit in the future
		// but this hack simply check if the schedule_version_id is invalid and
		// sets it to null.
		// Once it's null the Picker will set it to the first available id in
		// the versions list
		prop.afterSilence(0, originalData.schedule_id).map(function(){
			const s_id = originalData.schedule_id()
			const sv_id = originalData.schedule_version_id()
			const vs = originalData.versions()

			if (
				s_id != null
				&& sv_id != null
				&& vs.length > 0
				&& vs.find( s => s.schedule_version_id == sv_id ) == null
			){
				originalData.schedule_version_id( null )
			}

			return null
		})

		{
			const topNavBanner = prop()
			const {
				auth
				, api
			} = originalData

			// const topNavBanner = prop()

			consentModalService({
				auth, modal: modalStream, api, auth_permissions
			})
			.map( decision =>
				decision.map(modalStream)
			)

			originalData.topNavBanner = topNavBanner
			// cloning the stream would delete this
		}

		{
			const { responses, auth } = originalData
			authExpiry({ responses, auth }).map( x => {
				if ( x ) {
					localStorage.setItem('route-before-logout', m.route.get())
					m.route.set('/access/login')
				}
				return null
			})

			onFirstAuthSuccess({ auth })
		}

		// Route away based on permissions
		// controlled by contract model
		originalData.routeChangeCommands.map( x => {
			if( x && auth.stream().auth_token ) {
				m.route.set(x)
			}
			return null
		})

		return attrs => {
			return onRouteChange(attrs, originalData)
		}
	}
})
