/* globals window, URLSearchParams */

import * as superouter from './superouter.js'
import { Stream as stream } from 'bacta'

const Search = ($, { redraw=()=>{} },defaults={}) => {

	const sync = (defaults) => {
		const usp = new URLSearchParams(window.location.search)

		const existing =
			Object.fromEntries(usp)

		Object.entries(defaults).forEach( ([k,v]) => {
			if( !(k in existing) ) {
				existing[k] = v
			}
		})

		$(existing)
	}

	const service =
		$.$stream().map( x => {
			let $ = new URLSearchParams()

			Object.entries(x).forEach(
				([key, value ]) => {
					$.set(key, value)
				}
			)
			$= $.toString()
			$= $ ? '?'+$ : ''
			$= `${window.location.pathname}${$}`
			window.history.replaceState('', {}, $)

			redraw()
			return null
		})

	// can also just end the query
	const end = () => {
		service.end(true)
	}

	sync(defaults)

	return { sync, $, end }
}

// The internal recursive subRoute instance generator
// called internally via `subroute.subRoute()`
// the `$parent` and `Parent` are threaded through from the initial
// call.
function SubRoute(
	$root,
	Root,
	Parent = null,
	typeName,
	defaultRoute,
	cases,
	{ pushState = () => {}
	, replaceState = () => {}
	, redraw
	, $:$$
	, search:searchOptions={}
	, searchInstance=Search($$.search, {redraw}, searchOptions)
	, ...defaultOptions
	}
) {
	let ends = []
	let $ = null
	if( $$ ) {
		$ = $$.route
		ends.push( () => $.$end() )
	}
	if( Parent != null ) {
		searchInstance.sync(searchOptions)
	}
	let affixesCache
	function _affixes() {
		if (Parent == null) {
			return {
				suffix: $root.route.value.args()
				,prefix: '',
			}
		} else {
			const {
				value: { args },
			} = Parent.get()
			const suffix = args

			const rootHREF = Root.toURL($root.route())

			const i = rootHREF.lastIndexOf(suffix)
			const prefix = rootHREF.substring(0, i)

			return { suffix, prefix }
		}
	}

	function affixes() {
		if (affixesCache) {
			return affixesCache
		} else {
			return affixesCache = _affixes()
		}
	}

	affixes()

	const Type = superouter.type(typeName, cases)

	function fromURL(theirURL) {
		const { prefix } = affixes()
		const url = theirURL.replace(prefix, '')

		const x = ('/' + url).replace(/\/\//g, '/')

		const match = Type.matchOr(() => defaultRoute(Type), x)

		return match
	}

	function asRootRoute(x) {
		const { prefix } = affixes()
		return Root.matchOr(
			() => null,
			(prefix + Type.toURL(x)).replace(/\/\//g, '/'),
		)
	}

	function subroute(typeName, defaultRoute, cases, options = {}) {
		return SubRoute($root, Root, out, typeName, defaultRoute, cases, {
			pushState
			,replaceState
			,redraw
			, searchInstance
			,...defaultOptions
			,...options,
		})
	}

	function set(request, theirOptions = {}) {
		const options = { ...defaultOptions, ...theirOptions }

		const route = typeof request == 'function' ? request(get()) : request
		const rootRoute = asRootRoute(route)
		const url = toURL(route)

		if (options.replace) {
			replaceState(url+window.location.search)
		} else {
			pushState(url+window.location.search)
		}

		$root.route(rootRoute)

		return route
	}

	/**
	 * When you want to go back before doing a set
	 *
	 * Async because history.back is async, we only perform the set
	 * after the browser has registered a popstate.
	 *
	 * Useful for rewriting the past when leaving a modal for example
	 */
	async function back(request=null, theirOptions = {}){
		// Route state before going back
		const got = request && get()

		// In Chrome calling history.back withing the same
		// stack as an anchor onclick will be a no-op
		// so this just puts it on a different stack
		// which we wait below
		window.setTimeout( () => window.history.back(), 0)

		await new Promise( Y => {
			window.addEventListener('popstate', Y, { once: true })
		})

		if( request ) {

			const route = typeof request == 'function'
				? await request(got, get())
				: request

			const out = set(route, { ...theirOptions, replace: true })
			return out
		}

		return get()
	}

	function replace(request, theirOptions) {
		return set(request, { replace: true, ...theirOptions })
	}

	function setValue(request, theirOptions) {
		const got = get()
		const gotValue = got.value || {}
		const routeValue =
			typeof request == 'function' ? request(gotValue) : request
		const route = { ...got, value: routeValue }

		return set(route, theirOptions)
	}

	function replaceValue(request, theirOptions) {
		return setValue(request, { replace: true, ...theirOptions })
	}

	function get() {
		return fromURL(Root.toURL($root.route()))
	}

	function getValue() {
		return get().value || {}
	}

	// m.route.get()
	function toURL(...args) {
		if (args.length == 0) {
			return Root.toURL($root.route())
		} else {
			return Root.toURL(asRootRoute(args[0]))
		}
	}

	function link(route, { back: _back, ...theirOptions}={}) {
		const href = toURL(route)
		const options = { ...defaultOptions, ...theirOptions}
		return {
			href
			,async onclick(e = EventMock) {
				e.preventDefault()

				// prevent double clicking = duplicate history nodes
				if( href == toURL(get()) ) {
					return;
				}

				try {
					if( _back && options.replace) {
						// If we are replacing the current route
						// we have to go back first
						// update the past then apply set(route) afterwards
						await back(_back, options)
						set(route, { ...options, replace: false})
					} else if (_back && !options.replace) {
						// if we are pushing onto the stack
						// we just need to replace the current item
						// before applying the set
						// so when they hit back they return to _back
						// not the actual past route
						set( _back, {...options, replace: true})
						set(route, { ...options, replace: false })
					} else {
						set(route, options)
					}

				} catch(e) {
					throw e
				}
			},
		}
	}

	const normalize = x => x.split('/').filter(Boolean).join('/')
	let $stream = stream()
	if( $ ) {

		let s =
			$stream.map( x => {
				$(x)
				return null
			})

		ends.push( () => s.end(true) )
	}

	{
		let s =
			$root.route.$stream().map(() => {
				const { prefix: a } = affixesCache
				const { prefix: b } = _affixes()
				const f = normalize

				if (f(a) == f(b)) {
					$stream(get())
				}
				return null
			})

		ends.push( () => s.end(true) )
	}



	function end(){
		// Note we do not include searchInstance.end
		// here as it is shared by all instances by default
		for(let end of ends){
			end()
		}
	}

	const out = {
		affixes
		,fromURL
		,asRootRoute
		,subroute
		,toURL
		,get
		,getValue
		,set
		,setValue
		,back
		,link
		,$stream: () => $stream
		,replace
		,replaceValue
		,search: searchInstance
		,end
		,$

		// can be null
		,z: defaultOptions.z
	}

	Object.assign(out, Type, {
		toURL
		,fromURL,
	})

	return out
}

const WindowMock = {
	history: {
		pushState(_, __, href) {
			WindowMock.location.pathname = href
		}
		,replaceState(_, __, href) {
			WindowMock.location.pathname = href
		},
	}
	,addEventListener() {}
	,location: { pathname: '' },
}

const EventMock = {
	preventDefault() {},
}

// Exposes the subroute interface but for the parent route
// so that even the parent route has the same interface to ensure
// consistency
function RootRoute({
	$,
	name: typeName,
	defaultRoute,
	routes: cases,
	window: theirWindow = typeof window != 'undefined' ? window : WindowMock,
	pathname: getPathName = () => theirWindow.location.pathname,
	redraw = () => {},
	onpopstate = f =>
		theirWindow.addEventListener('popstate', e => {
			f(e)
			redraw()
		}),
	pushState = href => theirWindow.history.pushState('', {}, href),
	replaceState = href => theirWindow.history.replaceState('', {}, href),
	...defaultOptions
}) {
	const Type = superouter.type(typeName, cases)

	const subRoute = SubRoute($, Type, null, typeName, defaultRoute, cases, {
		pushState
		,replaceState
		,$
		,redraw
		,...defaultOptions,
	})

	function refresh() {
		let defaulted
		$.route(Type.matchOr(() => defaulted = defaultRoute(Type), getPathName()))
		if (defaulted) {
			subRoute.set(subRoute.get())
		}
	}

	// when popstate happens refresh state tree route
	onpopstate(refresh)

	// write to state tree initial route state
	refresh()

	return subRoute
}

export default { Root: RootRoute, WindowMock }
