const traverse = R => input => visit => {

    let paths = Object.keys(input).map( s => [s])
    let out = input

    let keys = x =>
      Object.prototype.toString.call(x) == '[object Object]'
        ? Object.keys(x)
      : Array.isArray(x)
        ? Object.keys(x).map(Number)
        : []

    while ( paths.length > 0 ) {
      let path = paths.shift()

      let target = R.path(path, out)

      let newPaths =
        keys(target)
          .map( x => [].concat(path, x) )

      paths.push(...newPaths)

      let parentPath = path.slice(0, -1)

      let resultFn = visit({
        target
        , path
        , parentPath
        , paths
        , root: out
        , parent: R.path(parentPath, out)
      })

      out = resultFn(out)

      let result = R.path(path, out)

      const newExpandedObject =
        typeof target == 'string' && typeof result != 'string'

      if ( newExpandedObject ) {
        paths.push(
          ...keys(result)
            .map( x => [].concat(path, x) )
        )
      }
    }

    return out
}


export default traverse