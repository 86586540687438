/* globals process */
// @ts-ignore
import m from 'bacta'
// @ts-ignore
import * as R from 'ramda'
import { prop } from '../../../stream/index'
// @ts-ignore
import css from 'bss'

const TRANSLOADIT_TEMPLATE = process.env.TRANSLOADIT_TEMPLATE
const TRANSLOADIT_AUTH_KEY = process.env.TRANSLOADIT_AUTH_KEY


function Uppy({
	attrs: {
		uppy: options
		,Transloadit: transloaditOptions
		,UppyDashboard: dashboardOptions
		,data
		,instance=()=>{}
	}
}){

	const Uppy = prop()
	const domNodeRendered = prop()

	prop.merge([
		Uppy
		,domNodeRendered
	])
	.map(
		([Uppy, target]) => {
			let uppy =
				Uppy.Core(R.merge({ id: target }, options))
				.use(Uppy.Transloadit, R.merge(transloaditOptions, { target }))
				.use(Uppy.Dashboard, R.merge(dashboardOptions, { target }))

			instance(uppy)

			if( data.features().uppyWebcam ) {
				uppy.use(Uppy.Webcam, {
					target: Uppy.Dashboard
					,facingMode: 'environment'
					,mirror: false
				})
			}

			if( data.features().uppyGoogleDrive ) {
				uppy.use(Uppy.GoogleDrive
					,{ target: Uppy.Dashboard
					, companionUrl: 'https://companion.uppy.io'
					}
				)
			}

			if( data.features().uppyDropbox ) {
				uppy.use(Uppy.Dropbox
					,{ target: Uppy.Dashboard
					, companionUrl: 'https://companion.uppy.io'
					}
				)
			}

			return null
		}
	)

	const src =
		'https://transloadit.edgly.net/releases/uppy/v1.7.0/uppy.min.js'

	return {
		onremove: () => Uppy.end(true)
		,view(){
			return data.features().uppy
			? m(
				'.uppy'
				+ css`
					min-height: 248px;
					opacity: 0;
				`
				.$animate('ease-in-out 1s 1s forwards', {
					from: 'o 0'
					,to: 'o 1'
				})
				,m('script',
					{ src
					// eslint-disable-next-line no-undef
					, onload: () => Uppy(window.Uppy)
					}
				)
				,m('',
					{ oncreate: ({ dom }) => domNodeRendered(dom)
					}
				)
			)
			: m('.uppy')
		}
	}
}


const simple = ({
	data
	, getFields
	, instance
	, width=300
	, height=250
	, uppy={}
}) =>
	m(Uppy, {
		uppy: Object.assign({
			autoProceed: false
			,allowMultipleUploads: true
			,restrictions: {
				maxNumberOfFiles: 50
			}
		}, uppy)
		,Transloadit:{
			waitForEncoding: true
			,waitForMetadata: true
			,getAssemblyOptions(){
				return data.api.files.getTransLoaditSignature({
					params: {
						auth: {
							key: TRANSLOADIT_AUTH_KEY
							,expires:
								new Date(
									new Date().getTime()
										+ 60 * 5 * 1000
								)
								.toISOString()
						}
						, template_id: TRANSLOADIT_TEMPLATE
					}
					, fields: getFields()
				})
			}
		}
		,UppyDashboard: {
			autoProceed: false
			,id: 'Dashboard'
			,target: 'body'
			,metaFields: []
			//,plugins: ['Webcam', 'Transloadit', 'FileInput']
			,trigger: '#uppy-select-files'
			,inline: true
			,proudlyDisplayPoweredByUppy: false
			,width
			,height
			,showProgressDetails: true
			,thumbnailWidth: 40
		}
		,data
		,instance
	}
)


export default {
	Uppy, simple
}