//eslint-disable-next-line no-undef
const checkTypes = !!process.env.CHECK_TYPES

import $ from 'sanctuary-def'
import SumType from 'sum-type-legacy'
import { prop as stream } from '../../../stream'
import $AuthPermission from '../../../types/auth_permissions'

const Type = SumType($, { checkTypes, env: $.env })

const $T = $.RecordType(
	{ auth_token: $.String
	, user_id: $.String
	, auth_permissions: $.Array($AuthPermission)
})

const $Auth = Type.Named('Auth', {
	LoggedOut: {}
	,LoggedIn:
		{ auth_token: $.String
		, user_id: $.String
		, auth_permissions: $.Array($AuthPermission)
		}
	,Refresh: {}

})

const auth = stream($Auth.Refresh({}))

export const isRefresh = () => auth()._name == 'Refresh'
export const isLoggedOut = () => auth()._name == 'LoggedOut'
export const isLoggedIn = () => auth()._name == 'LoggedIn'

export default {
	stream: auth
	, type: $Auth
	, recordType: $T
	, isRefresh
	, isLoggedOut
	, isLoggedIn
}