/* globals console, setTimeout */
import m from 'bacta'
import * as R from 'ramda'
import Uppy from './uppy'
import * as uuid from 'uuid'
import css from 'bss'
import moment from 'moment'

function diffFields(a,b){
	return !R.equals(a,b)
}

const hideScrollbars =
  css`
    -ms-overflow-style: none;
    scrollbar-width: none;
  `
  .$nest('::-webkit-scrollbar', `
    display: none;
  `)

const image_exts = ['jpg', 'jpeg', 'png', 'gif']

/**
 * files = List of Harth files
 * data = Harth data object, for API access
 * embedded = Do not create the white area
 * listOnly = Do not show the uploader
 * readOnly = No Delete Action
 */
function MiniGallery(){
	return {
		view({ attrs: { files, data, listOnly, readOnly, embedded, download } }){
			return m('.mini-gallery'
				+ hideScrollbars
				+ (embedded ? '.embedded' : '')
				+ css`
					display: flex;
					flex-wrap: wrap;
					--width: 5.7em;
					background-color: #fafafa;
					padding: 0.5em;
					border-radius: 0.5em;
					border: 1px solid #eaeaea;
					gap: 0.3em;
					opacity: 0;
					cursor: default;

					max-height: 18.1em;
					overflow-y: auto;
				`
				.$nest('&.embedded', `
					background-color: transparent;
					padding: 0em;
					border: none;
				`)
				.$nest('&.embedded .file', `
					margin: 0em;
					border: none;
				`)
				.$animate('ease-in-out 1s 1s forwards', {
					from: 'o 0'
					,to: 'o 1'
				})
				.$nest('.file',
					css`
						width: var(--width);
						height: var(--width);
						min-height: var(--width);
						background-size: cover;
						transition: 0.2s;
						border: solid 1px #EEE;
						background-color: #EEE;
						border-radius: 0.5em;
						opacity: 0;
						position: relative;

						margin: ${ listOnly ? '0.5em' : '0em' };
					`
					.$nest('*', `
						cursor: pointer;
					`)
					.$animate('ease-in-out 1s 2s forwards',{
						from: 'o 0%'
						,to: 'o 100%'
					})
					.$hover(
						css`
							opacity: 0.9;
						`
						.$media('(hover: hover)', `
							transform: scale(0.95);
						`)
						.$nest('.actions',
							css.$animate('ease-in-out 0.1s forwards', {
								from: {
									opacity: 0
								}
								,to: {
									opacity: 1
									,pointerEvents: 'inherit'
									,cursor: 'pointer'
								}
							})
						)
					)
				)
				.$nest('.action:hover', `
					text-decoration: underline;
				`)
				.$nest('.action.disabled:hover', `
					text-decoration: none;
				`)
				.$nest('&&& .file:hover .ext', `
					opacity: 0;
					animation: none;
				`)
				,(
					download && files.length
					? [{
						zipRequest: true
						,file_ext:
							download.reference.zip_error
							? 'Error'
							: download.reference.is_zip_processing
							? 'Processing'
							: Date.now() - new Date(download.reference.latest_zip_date ) < 60 * 60 * 1000
							? 'Completed'
							: ''
						,file_date: moment(download.reference.latest_zip_date).fromNow()
						,file_url: download.reference.latest_zip_url

						,file_thumbnail_url:
							'../assets/icons8-download.gif'
						,file_thumb_url:
							'../assets/icons8-download.gif'
						,mithrilKey: 'zip-indicator'
					}]
					: []
				)
				.concat(files)
				.map( x =>
					m('.file'
						+ '.'+x.file_id
						,
						{ style:
							{ backgroundImage:
								!image_exts.includes(x.file_ext)
								? `url("${
									x.file_thumbnail_url
								}")`

								: `url("${
									x.file_thumb_url
								}")`
							}
							, key: x.mithrilKey || x.file_id || x.file_url
						}
						, m('.overlay'
							+ css`
								min-width: 100%;
								min-height: 100%;
								background-color: black;
								display: block;
								position: absolute;
								border-radius: 0.5em;
								top: 0px;
								pointer-events: none;
								opacity: ${
									x.file_deleted_at != null
										? 0.8
										: 0
								};
								transition: 2s;
							`
						)
						,x.zipRequest && ['Processing', 'Completed', 'Error'].includes(x.file_ext)
						? m('.ext'
							+ css`
								position: relative;
								top: -4px;
								color: white;
								background-color: ${x.file_ext == 'Error' ? 'red' : 'black'};
								border-radius: 0.5em;
								padding: 0.2em 0.5em;
								opacity: 0;
								animation: bdcmli51 ease-in-out 1s forwards;
								text-align: center;
								font-size: 13px;
								height: 2em;
								align-content: center;
								display: grid;
							`
							.$animate('ease-in-out 1s forwards', {
								from: 'o 0'
								,to: 'o 1'
							})
							, x.file_ext
						)
						: (!image_exts.includes(x.file_ext) || x.file_deleted_at)
							&& !x.zipRequest
							&& m('.ext'
								+ css`
									position: relative;
									left: -4px;
									top: -4px;
									color: white;
									background-color: black;
									border-radius: 0.5em;
									padding: 0.2em 0.5em;
									opacity: 0;
								`
								.$animate('ease-in-out 1s forwards', {
									from: 'o 0'
									,to: 'o 1'
								})
								, x.file_deleted_at
									? 'Deleted'
									: x.file_ext
							)


						, m('.actions'
							+ css`
								position: absolute;
								top: 0px;
								background-color: rgba(0,0,0,0.8)
								display: grid;
								grid-template-rows: ${
									(x.file_deleted_at || readOnly)
									? `1fr`
									: `1fr 1fr`
								};
								gap: 0.1em;
								height: 100%;
								width: 100%;
								justify-content: center;
								align-items: space-evenly;
								border-radius: 0.5em;
								opacity: 0;
								transition: 0.2s;
								pointer-events: none;

							`
							.$nest('.action',`
								color: white;
								justify-content: center;
								align-items: center;
								display: grid;
								transition: 0.2s;
							`)
							.$nest('.action:hover', `
								opacity: 0.8;
							`)
							,x.file_deleted_at != null
								|| (!x.zipRequest || x.zipRequest && x.file_url)
								&& m('a.action'
									+ css`
										color: white;
										text-decoration: none;
										pointer-events: all;
									`
									.$nest('visited', `
										color: white;
									`)
									,
									{ href:  x.zipRequest ? x.file_url : `/data/files/${data.organization_id()}/view/${x.file_id}`
									, target: '_blank'
									, rel: 'noopener noreferrer'
									, title: 'Created ' + x.file_date
									}
									, x.zipRequest && x.file_url
									? Date.now() - new Date(download.reference.latest_zip_date) < 30_000
										? 'Latest'
										: 'Previous'
									: 'View'
								)
							, x.file_deleted_at != null
								|| !readOnly
								&& (!x.zipRequest || x.file_id != 'Processing')
								&& m('.action'
									+ css`
										pointer-events: all;
									`
									+ (
										download && download.reference.is_zip_processing ? '.disabled' : ''
									)
									,
									{ onclick:
										download && download.reference.is_zip_processing
										? null
										: x.zipRequest
										? async () => {
											download.reference.is_zip_processing = true
											m.redraw()
											await download.request()
											m.redraw()
										}
										: () => {
											x._loading = Date.now()
											return data.api.files.DELETE([x])
												.then(
													() => x.file_deleted_at = Date.now()
												)
												.finally(
													() => x._loading = false
												)
												.finally(
													() => m.redraw()
												)
										}
										, disabled: x._loading
									}
									, x.zipRequest && download.reference.is_zip_processing
									? 'Processing'
									: x.zipRequest && download.reference.zip_error
									? 'Retry?'
									: x.zipRequest && x.file_url
									? 'New Zip'
									: x.zipRequest
									? 'Create Zip'
									: 'Delete'
								)
							, x.file_deleted_at != null
								&& !readOnly
								&& m('.action'
									+ css`
										pointer-events: all;
									`
									,
									{ onclick(){
										x._loading = Date.now()
										data.api.files.UNDELETE([x])
											.then(
												() => delete x.file_deleted_at
											)
											.finally(
												() => x._loading = false
											)
											.finally(
												() => m.redraw()
											)
									}
									, disabled: x._loading
									}
									, 'Restore'
								)
						)
					)
				)
			)
		}
	}
}

function Main({ attrs }){
	let prevFields = attrs.getFields()
	let loading = true
	let files = []
	let uppy;

	const syncFiles = (fks) => {
		return attrs.data.api.files.LIST(fks)
			.then( xs => files = xs, console.error )
			.finally( () => loading = false )
			.then( () => m.redraw() )
	}

	syncFiles(prevFields)

	function view({ attrs }){
		const {
			getFields, data, listOnly
			,width=300
			,height=250
			,download
		} = attrs

		const nextFields = getFields()

		const fieldsChanged = diffFields( prevFields,  nextFields )

		if(!loading && fieldsChanged) {
			// re-fetch list, reset uppy, clear deleted files
			files = []
			syncFiles(nextFields)
			.then(
				() => uppy && uppy.setState({ files: {} })
			)
		}
		prevFields = nextFields


		return m('.harth-uppy'
			, listOnly
			|| Uppy.simple({
				width
				,height
				,getFields(){
					return Object.assign({ file_id: uuid.v4() }, prevFields)
				}
				, instance: _uppy => {
					uppy = _uppy
					uppy.on('complete', () => {
						// give the harth API time to receive the notification
						const NOTIFY_TIMEOUT = 3000

						setTimeout( () => {
							syncFiles(prevFields).then(
								() => uppy.setState({ files: {} })
							)
						}, NOTIFY_TIMEOUT)
					})
				}
				, data
			})
			, files.length > 0
				&& m(MiniGallery, {
					files
					, loading
					, data
					, listOnly
					, embedded: attrs.embedded
					, readOnly: attrs.readOnly
					, download
				})
		)
	}

	return { view }
}

export default Main