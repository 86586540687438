
import flagon from 'flagon'
import * as R from 'ramda'

import permissions from '../../../api/permissions.json'

const nameIndex =
	R.indexBy( (p) => p.permission_name, permissions )

function hasWrite(auth_permissions, permission_name, user){

	const permission = nameIndex[permission_name]

	return permission != null
		&& flagon(
			user
				? user.auth_permissions_write()

				: auth_permissions.auth_permissions_write
		)
		.contains(permission.permission_flag)
}

function hasRead(auth_permissions, permission_name, user){

	const permission = nameIndex[permission_name]

	return permission != null
		&& flagon(
			user
				? user.auth_permissions_read()

				: auth_permissions.auth_permissions_read
		)
		.contains(permission.permission_flag)

}

export default {
	hasRead
	,hasWrite
}