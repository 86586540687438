/* globals setTimeout, clearTimeout */
import * as R from 'ramda'
import m from 'bacta'
import byProp from '../utils/byProp'
import Promise from 'bluebird'
import * as elements from '../components/elements'
import { uniqPattern } from '../utils/regExes'
import { money as makeMoney } from '../utils/regExes'
import { prop } from '../../../stream'
import css from 'bss'
import * as F from '../utils/financialsUtils'

const milliperday = 1000*60*60*24
const asyncMergeAll =
	xs => prop.afterSilence(
		0
		, prop.merge(xs)
	)


const overHEadVersion = {
	scheduleNameVersion: 'Overheads'
	,schedule_version_id: 'Overheads'
	,schedule_id: null
}

const accumulator =
	(a, b) =>
		R.mapObjIndexed((object, propIndex) =>
			object = object + b[propIndex]
		)(a)

const checkprop = (a, b, c) =>
	c && c[a] && b && c[a][b]
		? typeof c[a][b] == 'function'
			? c[a][b]()
			: c[a][b]
		: c && a
		? typeof c[a] == 'function'
			? c[a]()
			: c[a]
		: typeof a == 'function'
			? a()
			: a

import Permissions from './permissions'

export default function Model(
	api
	, scheduleData
	, authPermissions
	, organizationID
	, permissions
	, buildRequest
){


	const advancedView = prop( false  )
	const readWarehousePermissions = prop( false  )
	const readProjectPermissions = prop( false  )
	const readSupplierPermissions = prop( false  )
	const readOrderPermissions = prop( false  )
	const readMaterialPermissions = prop( false  )
	const readResourcePermissions = prop( false  )
	const readFlowPermissions = prop( false  )
	const readInterruptionsPermissions = prop( false  )
	const readSchedulePermissions = prop( false  )
	const readToolPermissions = prop( false )
	const readContractPermissions = prop( false )
	const readInvoicePermissions = prop( false )
	const readTimesheetPermissions = prop( false )
	const readUpdatePermissions = prop( false )
	const readUserPermissions = prop( false )
	const readModulePermissions = prop( false )
	const readAssignedTimesheetsPermissions = prop( false )
	const readOwnedInvoicesPermissions = prop( false )
	const readContractObligationPermissions = prop( false )
	const readAssignedToolsPermissions = prop( false )
	const readAssignedContractsPermissions = prop( false )
	const readOwnedResourcePermissions = prop( false )

	const toolsIdentical = prop(true)

	const routeChangeCommands = prop()

	const permissionsArray = [
		{ name: 'warehouse_management', stream: readWarehousePermissions  }
		,{ name: 'project_management', stream: readProjectPermissions  }
		,{ name: 'supplier_management', stream: readSupplierPermissions  }
		,{ name: 'resource_management', stream: readResourcePermissions  }
		,{ name: 'flow_management', stream: readFlowPermissions  }
		,{ name: 'interruption_management', stream: readInterruptionsPermissions  }
		,{ name: 'schedule_management', stream: readSchedulePermissions  }
		,{ name: 'tool_management', stream: readToolPermissions  }
		,{ name: 'contract_management', stream: readContractPermissions  }
		,{ name: 'invoice_management', stream: readInvoicePermissions  }
		,{ name: 'timesheet_management', stream: readTimesheetPermissions  }
		,{ name: 'updates_management', stream: readUpdatePermissions  }
		,{ name: 'user_management', stream: readUserPermissions  }
		,{ name: 'order_management', stream: readOrderPermissions  }
		,{ name: 'material_management', stream: readMaterialPermissions  }
		,{ name: 'plan_management', stream: readModulePermissions  }
		,{ name: 'assigned_timesheets', stream: readAssignedTimesheetsPermissions  }
		,{ name: 'owned_invoices', stream: readOwnedInvoicesPermissions  }
		,{ name: 'contract_recognition', stream: readContractObligationPermissions  }
		,{ name: 'assigned_tools', stream: readAssignedToolsPermissions }
		,{ name: 'assigned_contracts', stream: readAssignedContractsPermissions  }
		,{ name: 'assigned_resources', stream: readOwnedResourcePermissions }
	]

	const editContractPermissions = prop(false)
	const editInvoicePermissions = prop(false)
	const currentPermissions = prop({})
	const forecastdateA = prop(0)
	const forecastdateB = prop(Infinity)
	const focussedEntities = prop([])
	const calculatedData = prop({})
	const currentRoute = prop('Nothing')
	const currentSchedule = prop(null)
	const disconnectedcontract =
		{
			contract_end_date: Infinity
			,contract_start_date: 0
			,contract_name:  'Disconnected Ledgers'
			,contract_id:  null
			,organization_id:  null
			,contract_description:  'Ledgers tied to deleted records'
			,contract_payment_term: 0
			,contract_count_invoices: true
			,supplier_id: null
			,supplier_name: ''
			,contract_items: [{
				contract_id: null
				,contract_items_id: null
				,contract_items_name: 'Disconnected Ledgers'
				,contract_items_description: ''
				,contract_items_client_reference: ''
				,contract_items_budget: 0
				,contract_items_rate: 0
				,contract_items_service: 0
				,contract_items_depreciation: 0
				,contract_items_appreciation: 0
				,contract_items_uom: ''
				,contract_items_damages_rate: 0
				,contract_items_damages_period: 0
				,contract_items_applied: true
				,contract_items_recorded_reference: 0
				,contract_items_recorded_reference_standard: 0
				,crews_discipline_rates_id: null
				,modules_id: null
				,warehouse_id: null
				,contractor_id: null
				,crew_id: null
				,project_id: null
				,organizations_disciplines_id: null
				,tools_id: null
				,user_id: null
			}]
		}
	const routeInitialized =
		currentRoute.map((cr) => {
			return {
				'projects': cr == 'projects'
				,'exports': cr == 'exports'
				,'schedules': cr == 'schedules'
				,'gantt': cr == 'gantt'
				,'updates': cr == 'updates'
				,'contractors': cr == 'contractors'
				,'financials': cr == 'financials'
				,'tools': cr == 'tools'
				,'users': cr == 'users'
				,'materials': cr == 'materials'
				,'modules': cr == 'modules'
				,'interruptions': cr == 'interruptions'
				,'flows': cr == 'flows'
			}
		})

	const routeids = prop({
		routeids: []
		, getledgers: false
		, financialsubsets: []
		, ledgerdetails:false
		, getmatdata: false
		, specificproject: null
	})


	const reroute =
		(auth_permissions) =>
			routeChangeCommands(
				Permissions.hasRead(
					auth_permissions
					,'project_management'
				)
				? '/data/projects'
				: Permissions.hasRead(
					auth_permissions
					,'contract_management'
				)
				? '/data/contracts'
				: Permissions.hasRead(
					auth_permissions
					,'warehouse_management'
				)
				? '/data/materials'
				: Permissions.hasRead(
					auth_permissions
					,'updates_management'
				)
				? '/data/updates'
				: '/data/updates'
			)

	const sumtype = prop(null)
	const groups = prop([])
	const loadchart = prop({})
	const initiateContractFetch =
		prop.dropRepeats(
			asyncMergeAll(
				[
					scheduleData.schedule_id
				]
			)
		).map(([ s ]) => {

			const auth_permissions = permissions()
			editContractPermissions(
				Permissions.hasWrite(
					auth_permissions, 'contract_management'
				)
			)
			editInvoicePermissions(
				Permissions.hasWrite(
					auth_permissions, 'invoice_management'
				)
			)

			calculatedData({})

			const ochange =
				permissionsArray
				.map((aPermissions) =>
					aPermissions.stream(
						Permissions.hasRead(
							auth_permissions
							, aPermissions.name
						)
					)
				).length
				&& currentPermissions().organization_id
				!= auth_permissions.organization_id
				&& auth_permissions.organization_id

			const schange = s != currentSchedule()
			const opsum = currentPermissions().auth_permissions_read + currentPermissions().auth_permissions_read
			const psumm = auth_permissions.auth_permissions_read + auth_permissions.auth_permissions_read
			currentPermissions(auth_permissions)
			currentSchedule(s)

			if(
				( ochange && opsum && psumm != opsum )
				|| m.route.get().indexOf('/autodirect') > -1
			){ reroute(auth_permissions) }

			if(ochange || schange){
				loading(true)
				;Promise.all([
					currentRoute() == 'financials' && ochange
					? fetchContracts({depth:'', props:{}})
					: fetchContracts({depth:1})
					, auth_permissions.organization_id
						? api.groups2.fetch.byOrganization({
							organization_id: auth_permissions.organization_id
						})
						: []
				])
				.then(([clist, g]) => {

					contracts(
						R.concat(
							clist
							,[disconnectedcontract]
						)
					)

					groups(g)

					return contracts()
				})
				.then(R.F)
				.then(loading)
			}

			return {
				ochange
				,schange
				,schedule_id: s
				,organization_id: auth_permissions.organization_id
				,ids: []
			}
		})

	 function cloneInvoice(
		 ilist
	 	, userID
	 	, baseContract
	 	, prefillObject={
			 project_id: m.prop(null)
			 ,project_name: null
			 ,allocation_id: m.prop(null)
		 }
	 	, amountMultiplier={
			 preFillAmount: 0
			,rateMultiplier: 1
		}
	 	, invoiceItems
	){

		const sType = Array.isArray(ilist)
			? ilist[0]
			: ilist[Object.keys(ilist)[0]]


		const PreFillProjectId = prefillObject.project_id()
		const PreFillProjectName = prefillObject.project_name
		const PreFillAllocationId = prefillObject.allocation_id()

		const exposer =
			ilist
			&& (
				Array.isArray(ilist) && ilist.length
				|| Object.keys(ilist).length
			)
			&& typeof sType.contract_id == 'function'
				? R.call
				: R.identity

		if(ilist.length && !ilist[0].invoice_id ){

			var supplierID = typeof baseContract.supplier_id  == 'function'
				? byProp('supplier_id')(baseContract)
				: R.prop('supplier_id')(baseContract)
			var supplierName = typeof baseContract.supplier_name  == 'function'
				? byProp('supplier_name')(baseContract)
				: R.prop('supplier_name')(baseContract)
		}

		return (
			Array.isArray(ilist)
				? ilist
				: Object.keys(ilist)
		)
			.map((aitem, index, theArray) => {

				const structure = Array.isArray(ilist)
					? aitem
					: ilist[aitem]

				const contractItemInfo =
					structure.invoice_id
						? { contractprojectid: structure.contractprojectid }
						: {
							crews_discipline_rates_id:
								structure.crews_discipline_rates_id
							,contractor_id:
								structure.contractor_id
							,crew_id:
								structure.crew_id
							,contractprojectid:
								structure.project_id
							,organizations_disciplines_id:
								structure.organizations_disciplines_id
						}

				const copiedList = R.merge(
					contractItemInfo
					,{
						contractrecognitionid: structure.invoice_type
							? structure.contractrecognitionid
							: structure.modules_id
							|| structure.warehouse_id
							|| structure.contractor_id
							|| structure.project_id
							|| structure.organizations_disciplines_id
							|| structure.tools_id
							|| structure.user_id
							|| structure.crews_discipline_rates_id
							|| structure.crew_id

						,key: structure.key
							? structure.key
							: Math.random().toString(15).slice(2, 8)
						,contract_id: m.prop(
							structure.invoice_type
								? exposer(structure.contract_id)
								: exposer(structure.contract_id)
							)
						,organization_id: m.prop(
							structure.invoice_type
								? exposer(structure.organization_id)
								: exposer(structure.organization_id)
						)
						,contract_items_id: m.prop(
							structure.invoice_type
								? exposer(structure.contract_items_id)
								: exposer(structure.contract_items_id)
						)

						,invoice_type: m.prop(
							structure.invoice_type
								? exposer(structure.invoice_type)
								: Number(
									exposer(structure.contract_items_rate)
									+ exposer(structure.contract_items_appreciation)
									+ exposer(structure.contract_items_service)
									+ exposer(structure.contract_items_depreciation)
									+ exposer(structure.contract_items_damages_rate)
								) > 0
									? 'Accrued Revenue'
									: 'Accrued Expenditure'
						)
						,project_id: m.prop(
							structure.invoice_id
								? exposer(structure.project_id)
								: PreFillProjectId
								|| structure.project_id
								|| null
						)
						,project_name: m.prop(
							structure.invoice_id
								? exposer(structure.project_name)
								: PreFillProjectName
								|| structure.project_id && structure.contract_items_name()
								|| null
						)
						,invoice_id: m.prop(
							structure.invoice_id
								? exposer(structure.invoice_id)
								: null
						)
						,user_id: m.prop(
							structure.invoice_id
								? exposer(structure.user_id)
								: userID
						)
						,invoice_date: m.prop(
							structure.invoice_date
								? exposer(structure.invoice_date)
								: new Date().getTime()
						)
						,invoice_received_date: m.prop(
							structure.invoice_id
								? exposer(structure.invoice_received_date)
								: null
						)
						,invoice_due_date: m.prop(
							structure.invoice_id
								? exposer(structure.invoice_due_date)
								: new Date().getTime() + milliperday * Number(structure.contract_payment_term)
						)
						,invoice_no: m.prop(
							structure.invoice_no
								? exposer(structure.invoice_no)
								: new Date().toLocaleString().replace(':', '').replace(':', '').replace(',', '').replace(' ', '').replace(' ', '').replace('AM', '').replace('PM', '').replace('am', '').replace('pm', '')
									+ (
										supplierName
											? '-' + supplierName.split(' ').map((word) => word.replace(uniqPattern, '').slice(0, word.length > 2 ? 1 : 2).charAt(0).toUpperCase()).join('')
											: 'UKN'
									)
									+ (
										structure.contract_items_name()
											? '-' + exposer(structure.contract_items_name).split(' ').map((word) => word.replace(uniqPattern, '').slice(0, word.length > 2 ? 1 : 2).charAt(0).toUpperCase()).join('')
											: ''
									)
									+ (
										structure.contract_items_description()
											? '-' +  exposer(structure.contract_items_description).split(' ').map((word) => word.replace(uniqPattern, '').slice(0, word.length > 2 ? 1 : 2).charAt(0).toUpperCase()).join('')
											: ''
									)
									+ theArray.length

						)
						,invoice_inv_id: m.prop(
							exposer(structure.invoice_inv_id)
						)
						,allocation_id: m.prop(
							structure.allocation_id
								? exposer(structure.allocation_id)
								: PreFillAllocationId
						)
						,invoice_received: m.prop(
							structure.invoice_received
								? exposer(structure.invoice_received)
								: false
						)
						,invoice_name: m.prop(
							structure.invoice_name
								? exposer(structure.invoice_name)
								: exposer(structure.contract_items_name)
						)
						,invoice_description: m.prop(
							structure.invoice_description
								? exposer(structure.invoice_description)
								: exposer(structure.contract_items_description)
						)
						,invoice_client_reference: m.prop(
							structure.invoice_id
								? exposer(structure.invoice_client_reference)
								: ''
						)
						,invoice_items_client_reference: m.prop(
							structure.invoice_id
								? exposer(structure.invoice_items_client_reference)
								: exposer(structure.contract_items_client_reference)
						)
						,invoice_uom: m.prop(
							structure.invoice_id
								? exposer(structure.invoice_uom)
								: exposer(structure.contract_items_uom)
						)

						,invoice_amount: m.prop(
							structure.invoice_id
								? exposer(structure.invoice_amount)
								: amountMultiplier.preFillAmount || 0
						)

						,invoice_service_amount: m.prop(
							structure.invoice_id
								? exposer(structure.invoice_service_amount)
								: amountMultiplier.preFillAmount || 0
						)

						,invoice_depreciation_amount: m.prop(
							structure.invoice_id
								? exposer(structure.invoice_depreciation_amount)
								: amountMultiplier.preFillAmount || 0
						)

						,invoice_appreciation_amount: m.prop(
							structure.invoice_id
								? exposer(structure.invoice_appreciation_amount)
								: amountMultiplier.preFillAmount || 0
						)

						,invoice_damages_amount: m.prop(
							structure.invoice_id
								? exposer(structure.invoice_damages_amount)
								: amountMultiplier.preFillAmount || 0
						)

						,invoice_rate: m.prop(
							structure.invoice_id
								? exposer(structure.invoice_rate)
								: exposer(
									structure.contract_items_rate
								) * amountMultiplier.rateMultiplier
						)

						,invoice_service_rate: m.prop(
							structure.invoice_id
								? exposer(structure.invoice_service_rate)
								: exposer(
									structure.contract_items_service
								) * amountMultiplier.rateMultiplier
						)

						,invoice_depreciation_rate: m.prop(
							structure.invoice_id
								? exposer(structure.invoice_depreciation_rate)
								: exposer(
									structure.contract_items_depreciation
								) * amountMultiplier.rateMultiplier
						)

						,invoice_appreciation_rate: m.prop(
							structure.invoice_id
								? exposer(structure.invoice_appreciation_rate)
								: exposer(
									structure.contract_items_appreciation
								) * amountMultiplier.rateMultiplier
						)

						,invoice_damages_rate: m.prop(
							structure.invoice_id
								? exposer(structure.invoice_damages_rate)
								: exposer(
									structure.contract_items_damages_rate
								) * amountMultiplier.rateMultiplier
						)

						,invoice_approval: m.prop(
							structure.invoice_approval
								? exposer(structure.invoice_approval)
								: false
						)

						,invoice_approver: m.prop(
							exposer(structure.invoice_approver)
						)

						,supplier_name: m.prop(
							structure.invoice_id
								? exposer(structure.supplier_name)
								: supplierName
						)
						,supplier_id: m.prop(
							structure.invoice_id
								? exposer(structure.supplier_id)
								: supplierID
						)
						,created:
							structure.invoice_id
								? false
								: true
						,invoice_reversal: m.prop(
							structure.invoice_id
								? exposer(structure.invoice_reversal)
								: false
						)
						,invoice_amount_total: m.prop(
							structure.invoice_id
								? exposer(structure.invoice_amount_total)
								: 0
						)
						,invoice_total: m.prop(
							structure.invoice_id
							? structure.invoice_amount*structure.invoice_rate
							+ structure.invoice_service_amount*structure.invoice_service_rate
							+ structure.invoice_depreciation_amount*structure.invoice_depreciation_rate
							+ structure.invoice_appreciation_amount*structure.invoice_appreciation_rate
							+ structure.invoice_damages_amount*structure.invoice_damages_rate
							: 0
						)
						,contract_payment_term:
							structure.invoice_id
							? structure.contract_payment_term
							: checkprop('contract_payment_term', null, baseContract)
						,invoice_items: structure.invoice_items || invoiceItems || []
					}
				)
				return copiedList
			})
	}

	const routeParam = function(){

		const url = m.route.get()

		const scopedURL = (url || '').replace('/data/contracts', '')
		const viewType =
			scopedURL.startsWith('/contracts')
			? 'contracts'
			: scopedURL.startsWith('/overview')
			? 'overview'
			: 'contractitems'
		const modeURL = scopedURL.replace('/' + viewType, '')
		const modeType =
			viewType == 'contracts'
			? modeURL.split('/')[1]
			: 'view'

		const invoiceType =
			modeURL.indexOf('ledger') > -1
			? modeURL.split('ledger/')[1]
			: ''

		const contractId =
			modeType == 'edit' || modeType == 'clone'
			? modeURL.split('/')[2]
			: ''

		const focus =
			modeURL.indexOf('focus') > -1
			? modeURL.split('focus/')[1].split('/ledger')[0]
			: ''

		return {
			viewType
			,modeType
			,invoiceType
			,contractId
			,focus
		}
	}

	function routeParams(scheduleonly, schedule_version_id){
		let {
			viewType
			,invoiceType
			,contractId
		} = routeParam()

		const odinItems =
			R.unnest(
				viewWindow()
				.map((v) =>
					v == 'Projects'
					? projects()
					: v == 'Project Disciplines'
					? R.unnest(
						projects().map((p) =>
							p.disciplines
						)
					)
					: v == 'Resources'
					? resources()
					: v == 'Teams'
					? R.unnest(
						resources().map((r) =>
							r.crews
						)
					)
					: v == 'Team Capabilities'
					? R.unnest(
						resources().map((r) =>
							R.unnest(
								r.crews.map((crew) =>
									crew.crews_discipline_rates
								)
							)
						)
					)
					: v == 'Organization Disciplines'
					? organizations_disciplines()
					: v == 'Users'
					? users()
					: v == 'Tools'
					? tools()
					: v == 'Warehouses'
					? warehouses()
					: v == 'Odin Modules'
					? modules()
					: []
				)
			)
			.map(R.prop('contractrecognitionid'))
			.filter(R.identity).join(',')
			|| 'none'

		return currentRoute() != 'financials'
		? {
			contracts: 'none'
			,invoices: routeids().getledgers ? 'all' : 'none'
			,contract_items: 'none'
			,items: routeids().routeids.join(',') || 'none'
			,viewwindow: 'none'
			,viewType
			,sumtype: sumtype() || ''
			,breakdown: 'breakdown'
			,getchartdata:
				Object.keys(loadchart()).length
				? 'getcharts'
				: ''
		}
		: viewType == 'contracts'
		? {
			contracts:
				invoiceType
				|| contractId
				|| ['all'].concat('null').join(',')
				// || contracts().map(R.prop('contract_id'))
				// .filter(R.identity).concat('null').join(',')
			,invoices: invoiceType || 'count'
			,contract_items:
				!contractId ? 'none' : invoiceType ? invoiceType : 'all'
			,items: 'none'
			,viewwindow: 'none'
			,viewType
			,sumtype: sumtype()
			,props: {
				financials: [
					'contract_id'
					,'contract_items_id'
					,"contractStart"
					,"contractEnd"
					,"damagesEnd"
				]
			}
		}
		: viewType == 'contractitems' || viewType == 'overview'
		? {
			contracts: 'none'
			,invoices: invoiceType || 'count'
			,contract_items: 'none'
			,viewwindow: viewWindow().join(',') || 'none'
			,items:
				scheduleonly
				|| schedule_version_id == overHEadVersion.schedule_version_id
					? 'all'
					: odinItems
			,viewType
			,props:
				viewType == 'overview'
				? {
					financials: [
						'currentProfits'
						,'forecastedProfits'
						,'contractrecognitionid'
						,'contractrecognitionname'
						,'costedto'
						,'currentRevenue'
					]
				}
				: null
			,getchartdata:
				Object.keys(loadchart()).length
				? 'getcharts'
				: ''
			,sumtype: sumtype()
		}
		: {
			contracts: 'none'
			,invoices: 'none'
			,contract_items: 'none'
			,items: 'none'
			,viewwindow: 'none'
			,viewType
			,sumtype: sumtype()
		}
	}


	const summation =
		(i) =>
			R.sum(
				Object.keys({
					currentProfits: 0
					,forecastedProfits: 0
					// ,materialActual: 0
					// ,materialForecasted: 0

					// ,budget: 0
					// ,contractList: []
					// ,totalScheduleData: []
					// ,totalDayData: []
					// ,invoicecount: 0
				})
				.map((k) => i[k])
			)

	const route_contract_id = prop(null)
	const route_original_contract_id = prop(null)
	const route_view_type =	prop(null)
	const prevview = prop(null)
	const chartCreation = prop({})

	function sumSchedule({data, fe}){
		if( fe ){
			fe.odinitems =
				data.scheduleonly
				? R.last(data.possibleContractEntities).odinitems
				: data.possibleContractEntities
				.map((e) => R.merge(baseItems() && baseItems()[e.contractrecognitionid] || {}, e))
				.filter((e) =>
					!fe.schedule_id
					? (summation(e) || e.invoicecount)
					&& !projects().find((p) => p.project_id() == e.contractrecognitionid)
					: e.schedule_id && e.schedule_id() == fe.schedule_id
				)

			fe.odincount =
				data.scheduleonly
					? R.last(data.possibleContractEntities).odincount || 0
					: data.odincount || fe.odinitems.length

			fe.odinsum =
				data.scheduleonly
					? R.last(data.possibleContractEntities).odinsum || 0
					: data.odinsum || F.financialReduce({items: fe.odinitems})

			fe.tp = prop(20)
			fe.groupings = []
			fe.makeChart =
				summation(fe.odinsum)
				? chartCreation({fe})
				: null
		}
	}

	function fetchCalculations(paramObj){
		calculated(false)
		delete calculatedData()[
			paramObj.scheduleList[0].schedule_version_id
			+ forecastdateA()
			+ forecastdateB()
		]

		const sv = R.last(paramObj.scheduleList).schedule_version_id
		const cparams = routeParams(paramObj.scheduleonly, sv)

		const paramObjUpdated =
			R.merge(
				route_view_type() == 'Overview'
				&& sv == 'Overheads' ? {nochildren: true} : {}
				,paramObj
			)

		recordedFocus({
			schedules: focussedEntities()
				.map((a) => ({
					snv: a.scheduleNameVersion
					,dReceived: {
						coalatedSelectedVersion: false
						,possibleContractEntities: false
						,focussedInvoices: false
						,allContractItems: false
						,allContracts: false
					}
					,schedule_version_id: a.schedule_version_id
				}))
			,dateA: forecastdateA()
			,dateB: forecastdateB()
		})

		return (
			[
				readContractPermissions
				,readInvoicePermissions
				,readOwnedInvoicesPermissions
				,readAssignedContractsPermissions
			].some((a) => a())
				? api.contracts_invoices_calculations.fetch.all(
					R.merge(paramObjUpdated, cparams)
					, routeids()
				)
				: Promise.resolve(
					[
						R.merge(
							paramObj
							,{
								forecastdateA: Number(forecastdateA()) || 0
								,forecastdateB: Number(forecastdateB()) || Infinity
								,now: Number(Date.now())
								,allContractItems: []
								,allContracts: []
								,focussedInvoices: []
								,possibleContractEntities: []
								,totalDayData: []
								,totalScheduleData: []
								,schedule_version_id: paramObj.scheduleList[0].schedule_version_id
							}
						)
					]
				)
		)
		.then(function([res]){
			const calculationID = res.schedule_version_id
			const fe = focussedEntities().find((sv) => sv.schedule_version_id == calculationID)
			calculatedData()[calculationID + res.forecastdateA + (res.forecastdateB || Infinity)] = res
			calculatedData(calculatedData())
			sumSchedule({data: res, fe })
			return res
		})
	}

	function fetchInterruptions(params){
		return readInterruptionsPermissions()
			? api.interruptions.fetch.all(params)
			: Promise.resolve([])
	}

	function fetchFlowsByScheduleId(schedule_id){
		return readFlowPermissions()
			? api.flows.fetch.bySchedule(schedule_id)
			: Promise.resolve([])
	}

	function fetchGeographies(){
		return api.geographies.fetch.all()
	}

	function fetchSchedules(stats){
		return !readSchedulePermissions()
			? Promise.resolve([])
			: stats
			? api.schedules.fetch.allStats()
			: api.schedules.fetch.all()
	}

	function fetchScheduleParameters(){
		return readSchedulePermissions()
			? api.schedules_parameters.fetch.all()
			: Promise.resolve([])
	}


	function fetchAllocationsbyProjectAndScheduleVersion(params){
		return readUpdatePermissions() || readProjectPermissions()
			? api.allocations.fetch
				.byProjectAndScheduleVersion(params)
			: Promise.resolve([])
	}

	function fetchAllocationsbyDiscipline(allocation_id){
		return readUpdatePermissions() || readProjectPermissions()
			? api.allocations.fetch.disciplines(allocation_id)
			: Promise.resolve([])
	}

	function fetchAllocationsbyScheduleVersion(params, load){
		return !readUpdatePermissions()
			? Promise.resolve([])
			: load
			? api.allocations.fetch.byAllocationScheduleVersionKey[load](params)
			: api.allocations.fetch.byScheduleVersion(params)
	}

	function fetchAllocationsbyScheduleVersionShallow(params){
		return readUpdatePermissions() || readProjectPermissions()
			? api.allocations.fetch
				.byAllocationScheduleVersionKey
				.shallowUniq(params)
			: Promise.resolve([])
	}

	function fetchSuppliers(params){
		return readSupplierPermissions()
			? api.suppliers.fetch.all(params)
			: Promise.resolve([])
	}

	function fetchPresets(){
        return readMaterialPermissions()
			? api.presets.fetch.all()
			: Promise.resolve([])
	}

	function getLocation(items, disableSave, postRequest){
		const setlatlong =
			(s) => {
				items.forEach((i) => i.location = s && s.coords || null)
				;postRequest ? postRequest() : null
				return disableSave(false)
			}
		// eslint-disable-next-line no-undef
		if (!navigator.geolocation) {
			return (postRequest ? postRequest() : true)
		} else {
			disableSave(true)
		// eslint-disable-next-line no-undef
			navigator.geolocation.getCurrentPosition(setlatlong, setlatlong)
			return true
		}
	  }


	let fetchMaterials = function(
		params={
			projects: []
			,warehouse_id: null
			,completedProjects: null
		}
	){
		return readMaterialPermissions()
			? api.materials.fetch.all(params)
			: Promise.resolve([])
	}

	let fetchSuggestedMaterials = function(
		params={
			project_id: null
			,warehouse_id: null
		}
	){
		return readMaterialPermissions()
			? api.materials.fetch.suggested(params)
			: Promise.resolve([])
	}

	let fetchOrders = function(
		params={
			project_id: null
			,warehouse_id: null
			,completedProjects: null
		}
	){
		return readOrderPermissions()
			? api.orders.fetch.all(params)
			: Promise.resolve([])
	}

	function fetchProjectsBySchedule(params){
		return readProjectPermissions()
		&& params.schedule_id || params.gettemplates
			? api.projects.fetch.bySchedule(params)
			: Promise.resolve([])
	}

	function fetchProjectsByProjectId(params){
		return (readProjectPermissions() || readUpdatePermissions())
		&& params.project_id
			? api.projects.fetch.one_shallow(params)
			: Promise.resolve([])
	}

	function fetchProjectExtras(paramObj){
		return readProjectPermissions()
		|| readUpdatePermissions()
			? api.project_extras.fetch(paramObj)
				.then(function(res){
					paramObj.f(true)
					return res
				})
			: Promise.resolve([])
	}

	function fetchProjectContacts(paramObj){
		return readProjectPermissions()
		|| readUpdatePermissions()
			? api.project_contacts.fetch(paramObj)
				.then(function(res){
					return res
				})
			: Promise.resolve([])
	}

	function fetchContainers(){
		return readMaterialPermissions()
			? api.containers.fetch.all()
			: Promise.resolve([])
	}

	function fetchInvoices(){
		return readInvoicePermissions()
		|| readOwnedInvoicesPermissions()
			? api.invoices.fetch.all()
			: Promise.resolve([])
	}

	function fetchContracts(params){
		return readContractPermissions()
		|| readAssignedContractsPermissions()
			? api.contracts.fetch.all(params)
			: Promise.resolve([])
	}

	function fetchOrganizationsDisciplines(params){
		return api.organizations_disciplines.fetch(params)
	}

	function fetchUsers(){
		return api.users.fetch.all()
	}

	function fetchResource(params){
		return readResourcePermissions()
		|| readOwnedResourcePermissions()
			? api.contractors.fetch.all(params)
			: Promise.resolve([])
	}

	function fetchResourceWork(a){
		return readResourcePermissions()
		|| readOwnedResourcePermissions()
			? api.contractors.fetch.workList(a)
			: Promise.resolve([])
	}

	function fetchProjects(params){
		return readProjectPermissions()
			? api.projects.fetch.all(params)
			: Promise.resolve([])
	}

	function fetchWarehouses(params){
		return readWarehousePermissions()
			? api.warehouses.fetch.all(params)
			: Promise.resolve([])
	}

    function fetchTools(params){
		return readToolPermissions()
		|| readAssignedToolsPermissions()
			? api.tools.fetch.all(params)
			: Promise.resolve([])
	}

	function fetchTimesheetsByAllocation(props){
		return readTimesheetPermissions()
		|| readAssignedTimesheetsPermissions()
			? api.timesheets.fetch.byAllocationId(props)
			: Promise.resolve([])
	}

	function fetchTimesheetsByProject({project_id}){
		return readTimesheetPermissions()
		|| readAssignedTimesheetsPermissions()
			? api.timesheets.fetch.byProjectId({project_id})
			: Promise.resolve([])
	}

	function transferProjects(d){
		return api.projects.transfer(d)
	}

	function progressCalculation(processingItem, summaryType, dAverage){
		const summaryPeriod =
			dAverage() == 'Total Period'
			? ''
			:  dAverage() == 'Work-Day Average'
			? 'Daily'
			: 'DailyPeriod'
		const a =
			// processingItem['accrued' + summaryPeriod + summaryType]
			processingItem['outstanding' + summaryPeriod + summaryType]
			+ processingItem['current' + summaryPeriod + summaryType]
		const b = processingItem['forecasted' + summaryPeriod + summaryType]
		return (
			(
				a != 0
				&& a/b != 0
				&& Math.abs(b) > 1/10000
					? a/b
					: a != 0 && (b == 0 || Math.abs(b) < 1/10000)
						? 1
						: 0

			) * 100
		).toFixed(3) + ' %'
	}

	function renderSummary(
		item
		, summaryType
		, dAverage
		, overwriteProgress
		, progressOption
		, open
		, attrs={
			style:{
				width: '10rem'
			}
		}
	){

		const processingItem = item || {}
		const summaryPeriod =
			dAverage() == 'Total Period'
			? ''
			:  dAverage() == 'Work-Day Average'
			? 'Daily'
			: 'DailyPeriod'

		const progress =
			overwriteProgress
			? overwriteProgress + ' %'
			: processingItem['forecasted' + summaryPeriod + summaryType] != null
				? progressCalculation(processingItem, summaryType, dAverage)
				: ""

		const accrued =
			processingItem['accrued' + summaryPeriod + summaryType] != null
				? makeMoney(processingItem['accrued' + summaryPeriod + summaryType])
				: ""

		const outstanding =
			processingItem['outstanding' + summaryPeriod + summaryType] != null
				? makeMoney(processingItem['outstanding' + summaryPeriod + summaryType])
				: ""

		const current =
			processingItem['current' + summaryPeriod + summaryType] != null
				? makeMoney(processingItem['current' + summaryPeriod + summaryType])
				: ""

		const forecasted =
			processingItem['forecasted' + summaryPeriod + summaryType] != null
				? makeMoney(processingItem['forecasted' + summaryPeriod + summaryType])
				: ""

		const renderArray =
			[
				{k:'Accrued ', v: accrued}
				,{k:'Requisitions ', v: outstanding}
				,{k:'Receipted ', v: current}
				,{k:'Forecasts ', v: forecasted}
			]

		const tvalue = !calculated()
		return m(
			'div'
			,{style: attrs.style ? R.pick('font-size', attrs.style) : {}}
			,elements.details(
				progressOption == 'inline'
				? progress
				: summaryType + ' ' + progress
				,progressOption == 'inline'
				? renderArray.map((s) => tvalue ? elements.spinner() : s.v).join(' | ')
				: progressOption == 'tabled'
				? elements.list(R.intersperse(' | ', renderArray.map((s) => m('p.tc', attrs, tvalue ? elements.spinner() : s.v))))
				: m('p.pt3'
					, renderArray.map((s) =>
						m('p.pv1.ma0.pl3'
							+css`
								display: grid;
								justify-content: space-between;
								grid-template-columns: 1fr;
							`
							,m('.data'
								+ css`
									display: grid;
									justify-content: space-between;
									grid-template-columns: 0.4fr 0.6fr;
								`
								,[s.k, tvalue ? elements.spinner() : s.v]
								.map((k) =>
									m('.data'
										+ css`
											display: grid;
											padding-right: 0.25em
										`
										,k || elements.spinner()
									)
								)
							)
						)

					)
				)
				,{open}
			)
		)
	}

	const currentVersion =
		asyncMergeAll(
			[
				scheduleData.schedule_version_id
				,scheduleData.schedules
			]
		)
		.map(([sversion, schedules]) =>
			sversion
				&& schedules.length
				&& scheduleData.schedule_id() != null
				? schedules
					.find((s) =>
						s.schedule_id
						== scheduleData.schedule_id()
					)
					.schedule_versions.find((svs) =>
						svs.schedule_version_id == sversion
					)
					|| {}
				: {}
		)

	const contracts = prop([])
	const organizations_disciplines = prop([])
	const users = prop([])
	const modules = prop([])
	const projects = prop([])
	const resources = prop([])
	const tools = prop([])
	const materials = prop([])
	const warehouses = prop([])
	const teams = prop([])
	const rates = prop([])

	const loading = prop(false)
	const metaDataLoading = prop(true)
	const metaDataIndexing = prop(true)
	const invoiceDataLoading = prop(false)

	const allContractItems =
		contracts.map((contract) =>
			R.unnest(
				contract.map((c) =>
					c.contract_items
				)
			) || []
		)


	const allUserContractItems =
		allContractItems.map((cI) =>
			cI.filter((contractItem) =>
				contractItem.user_id
			)
		)


	const allContractItemsIndexByItemID =
		allContractItems.map((cI) =>
			R.groupBy(
				R.prop('contractrecognitionid')
				, cI
			)
		)

	const allContractItemsIndexByContractItemId =
		prop.scan(
			// eslint-disable-next-line no-unused-vars
			function(p, [calculatedData, contracts]){

				return currentRoute() == 'financials'
				? R.indexBy(
					R.prop('contract_items_id')
					,coalator(
						{
							subSetFilter: null
							, subSetFilterValue: null
							, groupByProp: 'contract_items_id'
							, focusArray: 'allContractItems'
							, focusSchedules: focussedEntities()
							, calculatedData: calculatedData
							, startDate: forecastdateA()
							, endDate: forecastdateB()
							, findBaseBy: 'contract_items_id'
							, mergeBase: false
						}
					)
				)
				: {}
			}
			,{}
			,asyncMergeAll(
					[
					calculatedData
					,contracts
				]
			)
		)

	const baseItemsSelectList = prop()

	const baseItemsList = asyncMergeAll(
		[
			contracts
			,metaDataLoading
		]
	).map(([contracts, ]) => {

		teams(
			R.unnest(
				resources().map((r) =>
					r.crews
				)
			)
		)

		rates(
			R.unnest(
				teams().map((crew) =>
					crew.crews_discipline_rates
				)
			)
		)

		baseItemsSelectList(
			[].concat(
				organizations_disciplines().map(R.assocPath(['tag'], 'Organization Disciplines'))
				,users().map(R.assocPath(['tag'], 'Users'))
				,modules().map(R.assocPath(['tag'], 'Odin Modules'))
				,projects().map(R.assocPath(['tag'], 'Projects'))
				,resources().map(R.assocPath(['tag'], 'Resources'))
				,tools().map(R.assocPath(['tag'], 'Tools'))
				//,materials().map(R.assocPath(['tag'], 'Projects'))
				,warehouses().map(R.assocPath(['tag'], 'Warehouses'))
				,R.unnest(
					projects().map((p) =>
						p.disciplines
					)
				).map(R.assocPath(['tag'], 'Project Disciplines'))
				,teams().map(R.assocPath(['tag'], 'Teams'))
				,rates().map(R.assocPath(['tag'], 'Team Capabilities'))
			)
			.filter(R.prop('contractrecognitionid'))
		)

		return [].concat(
			baseItemsSelectList()
			,contracts
			,R.unnest(
				contracts.map((c) =>
					c.contract_items
				)
			)
		)
	})

	/* eslint-disable */
	const baseItems = asyncMergeAll(
		[
			baseItemsList
		]
	).map(([bi]) => {
		const itemIndex =
			R.indexBy(
				(a) =>
					( a.invoice_id
						? byProp('invoice_id')
						: a.contract_id && !a.contract_items_id && !a.project_id
							? R.prop('contract_id')
							: a.contract_id && a.contract_items_id
								? byProp('contract_items_id')
								: R.prop('contractrecognitionid')
					)(a)
				,bi
			)

		itemIndex[null] = {
			contract_end_date: Infinity
			,contract_start_date: 0
			,contract_name:  'Disconnected Ledgers'
			,contract_id:  null
			,organization_id:  null
			,contract_description:  'Ledgers tied to deleted records'
			,contract_payment_term: 0
			,contract_count_invoices: true
			,supplier_id: null
			,supplier_name: ''
			,contract_items: [{
				contract_id: null
				,contract_items_id: null
				,contract_items_name: 'Disconnected Ledgers'
				,contract_items_description: ''
				,contract_items_client_reference: ''
				,contract_items_budget: 0
				,contract_items_rate: 0
				,contract_items_service: 0
				,contract_items_depreciation: 0
				,contract_items_appreciation: 0
				,invoicecount: 0
				,contract_items_uom: ''
				,contract_items_damages_rate: 0
				,contract_items_damages_period: 0
				,contract_items_applied: true
				,contract_items_recorded_reference: 0
				,contract_items_recorded_reference_standard: 0
				,crews_discipline_rates_id: null
				,modules_id: null
				,warehouse_id: null
				,contractor_id: null
				,crew_id: null
				,project_id: null
				,organizations_disciplines_id: null
				,tools_id: null
				,user_id: null
			}]
		}
		return itemIndex
	})

	const multiplierIndex =
		projects.map((p) => {

			const pobjects =
				p.map((project) => {

					const dobjects =
						project.disciplines

					return R.merge(
						{project_id: project.project_id}
						,R.indexBy(
							(a) =>
								a.disciplines_contractors_crews_name
									? a.crews_discipline_rates_id
									: a.organizations_disciplines_id()
							,[].concat(
								dobjects
								,R.unnest(
									project.disciplines.map((d) =>
										R.unnest(
											d.disciplines_contractors.map((dc) =>
												dc.disciplines_contractors_crews
											)
										)
									)
								)
							)
						)
					)
				})


			return R.indexBy(
				(a) => a.project_id()
				,pobjects
			)
		})

	function coalator(paramObj){

		return R.filter(
				(a) => typeof(a) != 'string'
				,R.unnest(
					R.toPairs(
						R.map((oArray) =>
							R.tap(
								(finalObject) => {
									finalObject.contractList =
										finalObject.contractList
										? R.uniq(
											finalObject.contractList
												.map((c) => c.contractListName)
										)
										: []
								}
								,paramObj.focusArray == 'focussedInvoices'
									? oArray
									: R.merge(
										!paramObj.mergeBase
											? oArray[0]
											: baseItems()[oArray[0][paramObj.findBaseBy]]
										, R.reduce(
											F.coalatorAccumulator
											,R.clone(F.financialprops)
											,oArray
										)
									)
							)
							,R.groupBy(
								R.prop(paramObj.groupByProp)
								,R.unnest(
									paramObj.focusSchedules.map((s) => {
										var afocusObject =
											paramObj.calculatedData[
												s.schedule_version_id
												+ paramObj.startDate
												+ paramObj.endDate
											]

										var rF = recordedFocus().schedules
											.find((r) =>
												r.schedule_version_id
												== s.schedule_version_id
											)

										if(afocusObject && rF){
											currentRoute() != 'financials'
												? rF.dReceived['coalatedSelectedVersion'] = true
												: rF.dReceived[paramObj.focusArray] = true
										}

										return afocusObject
										|| {
											possibleContractEntities: []
											,chartdata: []
											//,possibleContractEntities: baseItemsList()
											,allContracts: []
											,focussedInvoices: []
											,allContractItems: []
										}
									})
									.map((dataObject) =>
										dataObject[paramObj.focusArray]
										.filter((a) =>
											(
												baseItems()[a[paramObj.findBaseBy]]
												|| !paramObj.mergeBase
											)
											&& (
												!paramObj.subSetFilter
												|| paramObj.subSetFilterValue ==
													a[paramObj.subSetFilter]
											)
										)
										.concat(
											(
												paramObj.focusArray == 'allContracts'
												? contracts()
												: []
											)
											.filter((a) =>
												!dataObject[paramObj.focusArray]
												.find((ra) =>
													ra[paramObj.findBaseBy]
												 	== a[paramObj.findBaseBy]
												)
											)
										)
									)
								)
							)
						)
					)
				)
			)
	}

	const calculateSelectedVersion =
		asyncMergeAll(
			[
				currentVersion
				,currentRoute
				,routeids
			]
		)
		.map(([i, currentRoute, ri]) => {

			var routeChange = false
			if(
				currentRoute != 'Nothing'
				&& currentRoute != 'financials'
				&& currentVersion()
				&& currentVersion().schedule_version_id
			){
				routeChange = true
				coalatedSelectedVersion({})
				calculatedData({})
				focussedEntities(
					currentRoute == 'projects'
					|| currentRoute == 'materials'
					|| currentRoute == 'contractors'
					|| currentRoute == 'gantt'
					|| currentRoute == 'updates'
					? [
						overHEadVersion
						,currentVersion()
					]
					: currentRoute == 'financials'
						? [currentVersion()]
						: [overHEadVersion]
				)
			}
			else if( currentRoute == 'financials' ){

				// bug this is happening every link touch because currentVersion doesn't want to droprepeats

				focussedEntities().length
				!= scheduleData.schedules().length + 1
				? focussedEntities(
					[overHEadVersion]
					.concat(
						scheduleData
						.schedules().map((s) => s.schedule_versions[0])
						.filter(R.identity)
					)
				)
				: null
			}

			return !routeChange
				? {}
				: Promise.all(
					focussedEntities()
					.map((a) =>
						fetchCalculations({
							startDate: 0
							,endDate: Infinity
							,currentRoute: currentRoute
							,scheduleList: [
								R.omit(
									[
										'label'
										, '_keys'
										, '_name'
										, '@@type'
										, 'case'
										, 'env'
										, 'odinitems'
										, 'odincount'
										, 'odinsum'
									]
									, a
								)
							]
						})
					)
				)
		})
	/* eslint-enable */


	const coalatedSelectedVersion =
		asyncMergeAll(
			[
				calculatedData
				,currentRoute
			]
		).map(([calculatedData, currentRoute]) => {
			return R.indexBy(
				R.prop('contractrecognitionid')
				,coalator(
					{
						subSetFilter: null
						, subSetFilterValue: null
						, groupByProp: 'contractrecognitionid'
						, focusArray: 'possibleContractEntities'
						, focusSchedules:
							!currentVersion()
							? []
							: currentRoute == 'tools'
							|| currentRoute == 'users'
							|| currentRoute == 'modules'
							? [ overHEadVersion ]
							: currentRoute == 'financials'
							|| currentRoute == 'materials'
							|| currentRoute == 'projects'
							|| currentRoute == 'contractors'
							|| currentRoute == 'gantt'
							|| currentRoute == 'updates'
							? [
								overHEadVersion
								,currentVersion()
							]
							: []
						, calculatedData: calculatedData
						, startDate: 0
						, endDate: Infinity
						, findBaseBy: 'contractrecognitionid'
						, mergeBase: false
					}
				)
			)
		})

	const contractEntities =
		prop.scan(
			function(p, [calculatedData, focussedEntities, baseItems]){
				return Object.keys(baseItems).length > 0
				&& focussedEntities.length > 0
				&& currentRoute() == 'financials'
					? coalator(
						{
							subSetFilter: null
							, subSetFilterValue: null
							, groupByProp: 'contractrecognitionid'
							, focusArray: 'possibleContractEntities'
							, focusSchedules: focussedEntities
							, calculatedData: calculatedData
							, startDate: forecastdateA()
							, endDate: forecastdateB()
							, findBaseBy: 'contractrecognitionid'
							, mergeBase: true
						}
					)
					: p
			}
			,[]
			,asyncMergeAll(
				[
					calculatedData
					,focussedEntities
					,baseItems
				]
			)
		)

	const contractEntitiesIndexedById =
		contractEntities
		.map((c) => R.indexBy(R.prop('contractrecognitionid'), c))

	const indexedContractEntities =
		contractEntities
			.map((c) => {

				const i =
					R.groupBy(
						(a) =>
							a['project_id'] && !a['discipline_id']
								? 'Projects'
								: a['project_id'] && a['discipline_id']
								? 'Project Disciplines'
								: a['contractor_id'] && !a['crew_id']
								? 'Resources'
								: a['contractor_id'] && a['crew_id']
								? 'Teams'
								: a['crews_discipline_rates_id']
								? 'Team Capabilities'
								: a['organizations_disciplines_name']
								? 'Organization Disciplines'
								: a['user_username']
								? 'Users'
								: a['tools_name']
								? 'Tools'
								: a['warehouse_id']
								? 'Warehouses'
								: 'Odin Modules'
						,c.map((c) => R.merge(c, {key: Math.random().toString(15).slice(2, 8)}))
					)

				return i
			})

	indexedContractEntities
		.map(() =>
			!metaDataLoading()
				? metaDataIndexing(false)
				: metaDataIndexing(true)
		)

	const focussedContracts =
		prop.scan(
			// eslint-disable-next-line no-unused-vars
			function(p, [calculatedData, focussedEntities, contracts, baseItems]){
				return focussedEntities.length > 0
				&& recordedFocus().schedules.every((sch) =>
					calculatedData[
						sch.schedule_version_id
						+ forecastdateA()
						+ forecastdateB()
					]
				)
					? coalator(
						{
							subSetFilter: null
							, subSetFilterValue: null
							, groupByProp: 'contract_id'
							, focusArray: 'allContracts'
							, focusSchedules: focussedEntities
							, calculatedData: calculatedData
							, startDate: forecastdateA()
							, endDate: forecastdateB()
							, findBaseBy: 'contract_id'
							, mergeBase: true
						}
					)
					: contracts
			}
			,[]
			,asyncMergeAll(
				 [
					calculatedData
					,focussedEntities
					,contracts
					,baseItems
				]
			)
		)


	const ctypes = prop([
		{cname:'Projects', list: projects}
		,{cname: 'Resources', list: resources}
		,{cname: 'Teams', list: teams}
		,{cname: 'Team Disciplines', list: rates}
		,{cname: 'Disciplines', list: organizations_disciplines}
		,{cname: 'Tools', list: tools}
		,{cname: 'Users', list: users}
		,{cname: 'Warehouses', list: warehouses}
		,{cname: 'Odin Modules', list: modules}
	])
	const coption = prop(ctypes()[4])

	const viewOptions = prop([
		'Projects'
		// ,'Project Disciplines'
		,'Resources'
		,'Teams'
		// ,'Team Capabilities'
		,'Organization Disciplines'
		,'Tools'
		,'Users'
		,'Warehouses'
		,'Odin Modules'
	])

	const viewWindow = prop([])
	const invoiceWindow = prop([])
	const focus_schedules = prop([])

	const viewWindowList = asyncMergeAll(
		[
			viewWindow
			,indexedContractEntities
		]
	).map(([viewWindow,indexedContractEntities]) =>
		R.unnest(
			viewWindow.map((view) =>
				indexedContractEntities[view] || []
			)
		)
	)

	const reMapdateChange = prop(false)
	const recordedFocus = prop({
		schedules: []
		,dateA: 0
		,dateB: Infinity
	})

	/* eslint-disable */
	const reMapCalculated = asyncMergeAll(
		[
			loading
			,reMapdateChange
			,currentRoute
			,viewWindow
			,metaDataLoading
		]
	)
	.map(([load, reMapdateChange, currentRoute, vw, metaDataLoading]) => {

		const cparams = routeParams()

		return !load
			&& !reMapdateChange
			&& calculated()
			&& !metaDataLoading
			&& currentRoute == 'financials'
			&& route_view_type() != 'Overview'
				|| focussedEntities()
				.some((sv) => sv.chartBreakdown)
			&& (cparams.invoices == 'count' || cparams.invoices == 'none')

			? Promise.all(
				focussedEntities().length
					? focussedEntities()
						.map(
							(sv) =>
								fetchCalculations({
									startDate: forecastdateA()
									,endDate: forecastdateB()
									,currentRoute: currentRoute
									,scheduleList: [
										{
											scheduleNameVersion: sv.scheduleNameVersion
											,schedule_priority: sv.schedule_priority
											,schedule_version_created: sv.schedule_version_created
											,schedule_version_id: sv.schedule_version_id
											,schedule_version_old_id: sv.schedule_version_old_id
											,schedules_parameters_id: sv.schedules_parameters_id
											,schedule_id: sv.schedule_id
										}
									]
								})
						)
					: [
						contractEntities().forEach((ac) => {
							ac.currentRevenue = 0
							ac.currentExpenditures = 0
							ac.currentProfits = 0
							ac.forecastedRevenue = 0
							ac.forecastedExpenditures = 0
							ac.forecastedProfits = 0
							ac.outstandingRevenue = 0
							ac.outstandingExpenditures = 0
							ac.outstandingProfits = 0

							ac.currentDailyPeriodRevenue = 0
							ac.currentDailyPeriodExpenditures = 0
							ac.forecastedDailyPeriodRevenue = 0
							ac.forecastedDailyPeriodExpenditures = 0
							ac.currentDailyPeriodProfits = 0
							ac.forecastedDailyPeriodProfits = 0
							ac.outstandingDailyPeriodRevenue = 0
							ac.outstandingDailyPeriodExpenditures = 0
							ac.outstandingDailyPeriodProfits = 0

							ac.currentDailyRevenue = 0
							ac.currentDailyExpenditures = 0
							ac.forecastedDailyRevenue = 0
							ac.forecastedDailyExpenditures = 0
							ac.currentDailyProfits = 0
							ac.forecastedDailyProfits = 0
							ac.outstandingDailyRevenue = 0
							ac.outstandingDailyExpenditures = 0
							ac.outstandingDailyProfits = 0

							ac.accruedExpenditures = 0
							ac.accruedRevenue = 0
							ac.accruedProfits = 0
							ac.accruedDailyRevenue = 0
							ac.accruedDailyExpenditures = 0
							ac.accruedDailyPeriodRevenue = 0
							ac.accruedDailyPeriodExpenditures = 0
							ac.accruedDailyProfits = 0
							ac.accruedDailyPeriodProfits = 0
							ac.budget = 0
							ac.contractList = []
						})
						,focussedContracts().forEach((c) => {
							c.currentRevenue = 0
							c.currentExpenditures = 0
							c.currentProfits = 0
							c.forecastedRevenue = 0
							c.forecastedExpenditures = 0
							c.forecastedProfits = 0
							c.outstandingRevenue = 0
							c.outstandingExpenditures = 0
							c.outstandingProfits = 0

							c.currentDailyPeriodRevenue = 0
							c.currentDailyPeriodExpenditures = 0
							c.forecastedDailyPeriodRevenue = 0
							c.forecastedDailyPeriodExpenditures = 0
							c.currentDailyPeriodProfits = 0
							c.forecastedDailyPeriodProfits = 0
							c.outstandingDailyPeriodRevenue = 0
							c.outstandingDailyPeriodExpenditures = 0
							c.outstandingDailyPeriodProfits = 0

							c.currentDailyRevenue = 0
							c.currentDailyExpenditures = 0
							c.forecastedDailyRevenue = 0
							c.forecastedDailyExpenditures = 0
							c.currentDailyProfits = 0
							c.forecastedDailyProfits = 0
							c.outstandingDailyRevenue = 0
							c.outstandingDailyExpenditures = 0
							c.outstandingDailyProfits = 0

							c.accruedExpenditures = 0
							c.accruedRevenue = 0
							c.accruedProfits = 0
							c.accruedDailyRevenue = 0
							c.accruedDailyExpenditures = 0
							c.accruedDailyPeriodRevenue = 0
							c.accruedDailyPeriodExpenditures = 0
							c.accruedDailyProfits = 0
							c.accruedDailyPeriodProfits = 0
							c.budget = 0
							c.contractList = []
						})
						,focussedEntities(focussedEntities())
						,calculatedData(calculatedData())
						,calculated(true)
					]
			)
			: []
		})

	const calculated = asyncMergeAll(
		[
			coalatedSelectedVersion
		]
	).map(([s]) => {
		// finanicals route taken care of inside the route
		return currentRoute() == 'financials'
		? s
		: currentRoute() != 'financials'
		&& Object.keys(s).length > 0
		&& recordedFocus().schedules.every((s) =>
			s.dReceived['coalatedSelectedVersion']
		)
			? true
			: false
	})

	const originalInvoices = prop([])
	const focussedInvoices = prop([])
	const hiddenInvoiceData =
		advancedView.map((av) => ({
			invoice_rate: !av
			,invoice_service_rate: !av && !originalInvoices().filter(R.prop('invoice_service_rate')).length
			,invoice_depreciation_rate: !av && !originalInvoices().filter(R.prop('invoice_depreciation_rate')).length
			,invoice_appreciation_rate: !av && !originalInvoices().filter(R.prop('invoice_appreciation_rate')).length
			,invoice_damages_rate: !av && !originalInvoices().filter(R.prop('invoice_damages_rate')).length
			,invoice_type: !av
		}))
	/* eslint-enable */
	asyncMergeAll(
		[
			invoiceWindow
			,metaDataLoading
		]
	).map(([ , ]) => {

		const cparams = routeParams()
		const fe =
			focussedEntities()
			.map((sv) =>
				R.merge(
					cparams
					,{
						nochildren: cparams.viewType == 'overview' && sv.schedule_version_id == 'Overheads'
						,items: cparams.items != 'none' ? cparams.invoices : cparams.items
						,startDate: forecastdateA()
						,endDate: forecastdateB()
						,currentRoute: currentRoute()
						,scheduleList: [
							{
								scheduleNameVersion: sv.scheduleNameVersion
								,schedule_priority: sv.schedule_priority
								,schedule_version_created: sv.schedule_version_created
								,schedule_version_id: sv.schedule_version_id
								,schedule_version_old_id: sv.schedule_version_old_id
								,schedules_parameters_id: sv.schedules_parameters_id
								,schedule_id: sv.schedule_id
							}
						]
					}
				)
			)

		return Promise.all(
			cparams.invoices == 'count'
			|| cparams.invoices == 'none'
			|| currentRoute() == 'Nothing'
			|| currentRoute() != 'financials'
			|| !calculated()
			// || !iW.length
			|| invoiceDataLoading()
			|| metaDataLoading()
			? Promise.resolve([])
			: fe
			.filter((fea) =>
				!R.last(focus_schedules())
				|| focus_schedules()
				.find((fs) => fea.items && fs == R.last(fea.scheduleList).schedule_version_id)
			)
			.map((f) => {
				invoiceDataLoading(true)
				return api.contracts_invoices_calculations.fetch.all(
					f, R.merge(routeids(), {ledgerdetails:true, specificproject: null}))
			})
		)
		.then((invdata) => {

			let sortedata = []

			if(invdata.length > 0){

				recordedFocus()
				.schedules.forEach((sch) => sch.dReceived['focussedInvoices'] = true )

				sortedata =
					cparams.items == 'none'
						? R.sortBy(
							R.prop('invoice_id')
							,R.unnest(
								R.unnest(R.unnest(invdata).map(R.prop('focussedInvoices')))
								.map(R.prop('invoicelist'))
							)
						)
						: R.sortBy(
							R.prop('invoice_id')
							,R.unnest(R.unnest(invdata).map(R.prop('focussedInvoices')))
						)

				hiddenInvoiceData({
					invoice_rate: !advancedView()
					,invoice_service_rate: !advancedView() && !sortedata.filter(R.prop('invoice_service_rate')).length
					,invoice_depreciation_rate: !advancedView() && !sortedata.filter(R.prop('invoice_depreciation_rate')).length
					,invoice_appreciation_rate: !advancedView() && !sortedata.filter(R.prop('invoice_appreciation_rate')).length
					,invoice_damages_rate: !advancedView() && !sortedata.filter(R.prop('invoice_damages_rate')).length
					,invoice_type: !advancedView()
				})

				originalInvoices(sortedata)
				focussedInvoices(cloneInvoice(sortedata))
				invoiceDataLoading(false)

			}

			return sortedata
		})
	})

	function makeContractItem(v, originalObjects){

		const baseObject =
			originalObjects.find(
				(c) => c.contractrecognitionid
					==  v.contractrecognitionid
			)

			// const baseParent = baseObject.crew_id
			// 	? originalObjects.find(
				// 		(c) => c.crew_id
				// 			&& c.crew_id() == baseObject.crew_id()
				// 	)
				// 	: null

		return {
			key: Math.random().toString(15).slice(2, 8)
			,created: true
			,contract_id: m.prop(null)
			,contract_items_id: m.prop(null)
			,contract_items_applied: m.prop(true)
			,organization_id: m.prop(initiateContractFetch().organization_id)

			,modules_id: checkprop(baseObject.modules_id) || null
			,warehouse_id: checkprop(baseObject.warehouse_id) || null
			,contractor_id:
				checkprop(baseObject.contractor_id) && !checkprop(baseObject.crew_id)
					? checkprop(baseObject.contractor_id)
					: null
			,project_id:
				checkprop(baseObject.project_id) && !checkprop(baseObject.discipline_id) && !checkprop(baseObject.organizations_disciplines_id)
					? checkprop(baseObject.project_id)
					: null
			,organizations_disciplines_id:
				checkprop(baseObject.organizations_disciplines_id) && !checkprop(baseObject.crews_discipline_rates_id)
					? checkprop(baseObject.organizations_disciplines_id)
					: null
			,tools_id: checkprop(baseObject.tools_id) || null
			,user_id: !checkprop(baseObject.crew_id) && checkprop(baseObject.user_id) ? checkprop(baseObject.user_id) : null
			,crew_id:
				checkprop(baseObject.crew_id) && !checkprop(baseObject.crews_discipline_rates_id)
					? checkprop(baseObject.crew_id)
					: null
			,crews_discipline_rates_id:
				checkprop(baseObject.crews_discipline_rates_id)
					? checkprop(baseObject.crews_discipline_rates_id)
					: null

			,contract_items_recorded_reference:
				checkprop(baseObject.organizations_disciplines_id)
				|| checkprop(baseObject.crews_discipline_rates_id)
				|| checkprop(baseObject.project_id) && !checkprop(baseObject.discipline_id) && !checkprop(baseObject.organizations_disciplines_id)
				|| checkprop(baseObject.contractor_id) && !checkprop(baseObject.crew_id)
				? m.prop('Contract Quantity')
				: m.prop('Contract Days')

			,contract_items_recorded_reference_standard:
				checkprop(baseObject.organizations_disciplines_id)
				|| checkprop(baseObject.crews_discipline_rates_id)
				|| checkprop(baseObject.project_id) && !checkprop(baseObject.discipline_id) && !checkprop(baseObject.organizations_disciplines_id)
				|| checkprop(baseObject.contractor_id) && !checkprop(baseObject.crew_id)
				? m.prop(1)
				: m.prop(8)

			,contract_items_damages_period: m.prop(365)
			,contract_items_rate: m.prop(
				checkprop(baseObject.modules_id)
				? Number(baseObject.modules_rate()/28).toFixed(2)
				: checkprop(baseObject.organizations_disciplines_id)
				&& !checkprop(baseObject.crews_discipline_rates_id)
				? checkprop(baseObject.organizations_disciplines_nominal_revenue_rate)
				: checkprop(baseObject.organizations_disciplines_id)
				? checkprop(baseObject.organizations_disciplines_nominal_expenditure_rate)
				: 0
			)
			,contract_items_budget: m.prop(0)

			,contractrecognitionid: baseObject.contractrecognitionid

			,currentRevenue: 0
			,currentExpenditures: 0
			,currentProfits: 0
			,forecastedRevenue: 0
			,forecastedExpenditures: 0
			,forecastedProfits: 0
			,outstandingRevenue: 0
			,outstandingExpenditures: 0
			,outstandingProfits: 0

			,currentDailyPeriodRevenue: 0
			,currentDailyPeriodExpenditures: 0
			,forecastedDailyPeriodRevenue: 0
			,forecastedDailyPeriodExpenditures: 0
			,currentDailyPeriodProfits: 0
			,forecastedDailyPeriodProfits: 0
			,outstandingDailyPeriodRevenue: 0
			,outstandingDailyPeriodExpenditures: 0
			,outstandingDailyPeriodProfits: 0

			,currentDailyRevenue: 0
			,currentDailyExpenditures: 0
			,forecastedDailyRevenue: 0
			,forecastedDailyExpenditures: 0
			,currentDailyProfits: 0
			,forecastedDailyProfits: 0
			,outstandingDailyRevenue: 0
			,outstandingDailyExpenditures: 0
			,outstandingDailyProfits: 0

			,accruedExpenditures: 0
			,accruedRevenue: 0
			,accruedProfits: 0
			,accruedDailyRevenue: 0
			,accruedDailyExpenditures: 0
			,accruedDailyPeriodRevenue: 0
			,accruedDailyPeriodExpenditures: 0
			,accruedDailyProfits: 0
			,accruedDailyPeriodProfits: 0
			,budget: 0
			,invoicecount: 0

			,contract_items_service: m.prop(0)
			,contract_items_depreciation: m.prop(0)
			,contract_items_appreciation: m.prop(0)
			,contract_items_damages_rate: m.prop(0)

			,contract_items_description: m.prop(
				// baseObject['contractrecognitiondescription']
				checkprop(baseObject['crews_discipline_rates_id'])
				|| checkprop(baseObject['organizations_disciplines_id'])
				? 'UOM Completed'
				: baseObject['contractrecognitiondescription'] || ''
			)
			,contract_items_client_reference: m.prop('')
			,contract_items_name: m.prop(
				baseObject['contractrecognitionname']
			)

			,contract_items_uom: m.prop(
				checkprop(baseObject['project_name'])
					? 'Per Project'
					: checkprop(baseObject['contractor_name'])
					? 'Per Contractor'
					: checkprop(baseObject['user_username'])
					? 'Per Day'
					: checkprop(baseObject['organizations_disciplines_name'])
					? checkprop(baseObject['organizations_disciplines_uom'])
					: checkprop(baseObject['crews_discipline_rates_name'])
					? checkprop(baseObject['crews_discipline_rates_uom'])
					: checkprop(baseObject['crew_name'])
					? 'Per Day'
					: 'Per Day'
			)
		}

	}


	function criteriaBasedCalculation(
		contractItem
		, ratingCriteria
	) {

		const criteriaResults = {
			currentRevenue: 0
			, currentExpenditures: 0
			, currentProfits: 0
			, forecastedRevenue: 0
			, forecastedExpenditures: 0
			, forecastedProfits: 0
			, materialActual: 0
			, materialForecasted: 0
			, outstandingRevenue: 0
			, outstandingExpenditures: 0
			, outstandingProfits: 0
			, accruedExpenditures: 0
			, accruedRevenue: 0
			, accruedProfits: 0
			, budget: 0
		}

		const incriment = ratingCriteria.incriment

		if ( incriment != 'invoice' ) {
			var periodRatedAdjustment =
				ratingCriteria.forecastedWorkAmount

			var damageRatedAdjustment =
				ratingCriteria.forecastedDamagesAmount

			var periodAccruedAdjustment =
				ratingCriteria.accruedAmount
		}


		; (
			incriment == 'invoice'
				? [
					'invoice_rate'
					, 'invoice_service_rate'
					, 'invoice_depreciation_rate'
					, 'invoice_appreciation_rate'
					, 'invoice_damages_rate'
				]
				: [
					'contract_items_rate'
					, 'contract_items_service'
					, 'contract_items_depreciation'
					, 'contract_items_appreciation'
					, 'contract_items_damages_rate'
					, 'contract_items_budget'
				]
		)
			.forEach((iRate) => {

				periodRatedAdjustment =
					incriment == 'invoice'
						? contractItem[iRate.replace('_rate', '_amount')]()
						: periodRatedAdjustment

				periodRatedAdjustment =
					iRate == 'contract_items_damages_rate'
						? damageRatedAdjustment
						: periodRatedAdjustment

				periodAccruedAdjustment =
					incriment == 'invoice'
						? 0
						: periodAccruedAdjustment

				const value = periodRatedAdjustment * contractItem[iRate]()
				const accruedValue = periodAccruedAdjustment * contractItem[iRate]()

				iRate == 'contract_items_budget'
				? criteriaResults.budget = criteriaResults.budget + value
				: value > 0 && incriment == 'invoice'
					? !contractItem['invoice_received'] || !contractItem['invoice_received']()
						? criteriaResults.outstandingRevenue = criteriaResults.outstandingRevenue + value
						: criteriaResults.currentRevenue = criteriaResults.currentRevenue + value
					: value < 0 && incriment == 'invoice'
						? contractItem['invoice_received'] || !contractItem['invoice_received']()
							? criteriaResults.outstandingExpenditures = criteriaResults.outstandingExpenditures + value
							: criteriaResults.currentExpenditures = criteriaResults.currentExpenditures + value
						: value > 0 && incriment != 'invoice'
							? criteriaResults.forecastedRevenue = criteriaResults.forecastedRevenue + value
							: criteriaResults.forecastedExpenditures = criteriaResults.forecastedExpenditures + value

				accruedValue > 0
					? criteriaResults.accruedRevenue = criteriaResults.accruedRevenue + accruedValue
					: criteriaResults.accruedExpenditures = criteriaResults.accruedExpenditures + accruedValue

				criteriaResults.accruedProfits = criteriaResults.accruedRevenue + criteriaResults.accruedExpenditures
				criteriaResults.currentProfits = criteriaResults.currentRevenue + criteriaResults.currentExpenditures
				criteriaResults.forecastedProfits = criteriaResults.forecastedRevenue + criteriaResults.forecastedExpenditures
				criteriaResults.outstandingProfits = criteriaResults.outstandingRevenue + criteriaResults.outstandingExpenditures

			})

		return [criteriaResults]
	}

	function fetchXeroExport(bdata){
		const dfci = originalInvoices().map(R.prop('contract_items_id')).join(',')
		const dfii = originalInvoices().map(R.prop('invoice_id')).join(',')

		return buildRequest(
			'GET'
			,'/exports/xero/'
			,R.merge(
				{
					organization_id: initiateContractFetch().organization_id
					, xeroexport: true
					, startDate: forecastdateA()
					, endDate: forecastdateB()
					, contract_items: dfci
					, invoices: dfii
				}
				,bdata
			)
		).url
	}

	function reChartButton({versions, disabled=R.F}){
		return m('button.btn.btn'
			,{
				onclick: () => {

					loadchart(versions)

					routeids( routeids() )

				}
				, disabled: disabled()
				, title: "Load chart view"
				, style:
					{ backgroundColor: 'transparent'
					, position: 'relative'
					, top: '-0.1em'
					, color: '#434aa3'
					}
			}
			, elements.refresher()
		)
	}

	function debounce(f, ms=0){
		let id;
		return ( ...args ) => {
			clearTimeout(id)
			id = setTimeout(() => f(...args), ms)
		}
	}

	return {
		contracts
		,originalInvoices
		,focussedContracts
		,organizations_disciplines
		,users
		,modules
		,projects
		,resources
		,tools
		,materials
		,warehouses
		,calculatedData
		,contractEntities
		,forecastdateA
		,forecastdateB
		,fetchCalculations
		,allContractItems
		,focussedEntities
		,calculated
		,fetchInvoices
		,fetchOrganizationsDisciplines
		,fetchUsers
		,fetchContracts
		,fetchResource
		,fetchProjects
		,fetchWarehouses
		,fetchTools
		,fetchTimesheetsByAllocation
		,fetchContainers
		,fetchGeographies
		,fetchProjectsBySchedule
		,fetchFlowsByScheduleId
		,fetchInterruptions
		,fetchAllocationsbyProjectAndScheduleVersion
		,fetchAllocationsbyScheduleVersion
		,fetchSuppliers
		,fetchMaterials
		,fetchSuggestedMaterials
		,fetchPresets
		,fetchOrders
		,makeContractItem
		,coalator
		,overHEadVersion
		,reMapCalculated
		,cloneInvoice
		,viewOptions
		,viewWindow
		,viewWindowList
		,indexedContractEntities
		,coalatedSelectedVersion
		,loading
		,reMapdateChange
		,recordedFocus
		,accumulator
		,criteriaBasedCalculation
		,api
		,readContractPermissions
		,readWarehousePermissions
		,readProjectPermissions
		,readSupplierPermissions
		,readResourcePermissions
		,readFlowPermissions
		,readInterruptionsPermissions
		,readSchedulePermissions
		,readToolPermissions
		,readInvoicePermissions
		,readTimesheetPermissions
		,readUpdatePermissions
		,readUserPermissions
		,readOrderPermissions
		,readMaterialPermissions
		,readModulePermissions
		,readAssignedTimesheetsPermissions
		,readContractObligationPermissions
		,readAssignedToolsPermissions
		,readOwnedResourcePermissions
		,initiateContractFetch
		,contractEntitiesIndexedById
		,allContractItemsIndexByContractItemId
		,allUserContractItems
		,renderSummary
		,fetchProjectExtras
		,fetchAllocationsbyScheduleVersionShallow
		,editContractPermissions
		,editInvoicePermissions
		,currentRoute
		,currentVersion
		,baseItems
		,allContractItemsIndexByItemID
		,invoiceWindow
		,focussedInvoices
		,schedules: scheduleData.schedules
		,fetchResourceWork
		,fetchAllocationsbyDiscipline
		,fetchTimesheetsByProject
		,fetchProjectsByProjectId
		,fetchSchedules
		,fetchScheduleParameters
		,multiplierIndex
		,metaDataLoading
		,metaDataIndexing
		,invoiceDataLoading
		,routeInitialized
		,progressCalculation
		,fetchProjectContacts
		,routeids
		,baseItemsList
		,ctypes
		,coption
		,advancedView
		,hiddenInvoiceData
		,disconnectedcontract
		,calculatedData
		,transferProjects
		,focus_schedules
		,route_contract_id
		,route_original_contract_id
		,route_view_type
		,prevview
		,summation
		,routeParam
		,routeids
		,fetchCalculations
		,scheduleData
		,chartCreation
		,toolsIdentical
		,groups
		,routeChangeCommands
		,fetchXeroExport
		,loadchart
		,baseItemsSelectList
		,reChartButton
		,debounce
		,sumSchedule
		,reroute
		,getLocation
	}
}