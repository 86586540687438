/* globals process, window */
import * as R from 'ramda'
import moment from 'moment'
import request from '../request'
import { prop } from '../../../stream'
import * as uuid from 'uuid'

const exposer = (prop) =>
	typeof prop == 'function'
		? R.call(prop)
		: R.identity(prop)


import m from 'bacta'
const I = i => i

export default function API(auth, organization_id){

	const HARTH_API_URL = process.env.HARTH_API_URL

	const milliperday = 1000*60*60*24

	function baseHeaders({ organization_id: theirOrgId }={}){
		const auth_token = auth().auth_token

		let headers = {}

		if ( auth_token) {
			headers.Authorization = `Bearer ${auth_token}`
		}
		const someOrgId = theirOrgId || organization_id()
		if( someOrgId ) {
			headers['X-Odin-Organization'] = someOrgId
		}

		headers['X-Odin-Route'] = window.location.pathname

		return headers
	}

	let baseURL = HARTH_API_URL || "/api"
	if ( baseURL === "/api" ) {
		baseURL = window.location.origin + '/api'
	}

	function GET ([method, url]) {
		return request.request(
			baseURL
			, baseHeaders()
			, method
			, url.join('')
		)
	}

	function last ( list ) {
		return list[list.length -1]
	}

	function toParamHash( unsafeURL ) {
		const url = unsafeURL.slice()

		const tail = last(url)

		const head =
			url.slice(0,-1)

		if( tail && Array.isArray(tail)){

			const q = tail.reduce(function(p, n){
								p[ n[0] ] = n[1]
				return p
			},{})

			return [
				head
				,q
			]

		} else {
			return [unsafeURL, {}]
		}

	}

	function LIST([method, inURL]) {
		return LIST_ATTRS(baseURL, baseHeaders(), [method, inURL])
	}

	function LIST_ATTRS(_baseURL=baseURL, _baseHeaders=baseHeaders, [method, inURL]) {
		return LIST_TRANSPORT_ATTRS(_baseURL, _baseHeaders=baseHeaders, [method, inURL])
			.promise
	}

	function LIST_TRANSPORT_ATTRS (
		_baseURL=baseURL, _baseHeaders=baseHeaders, [method, inURL]
	) {

		const [outURL,paramHash] = toParamHash(inURL)
		const transport = m.prop()

		paramHash['now'] = Date.now();

		return {
			promise: request.request(
				_baseURL
				,_baseHeaders()
				, method
				, outURL.join('')
				, paramHash
				, transport
			)
				.then( r => r.list )
			,transport: transport()
		}
	}

	function POST([method, url], [body, requestParser, responseParser, source]) {
		return request.request(
			baseURL
			,baseHeaders()
			,method
			,url.join('')
			,R.merge(requestParser(body), {source})
		)
			.then(responseParser)
	}

	function PATCH_MANY ([method, url], [body, requestParser, responseParser, source]) {

		return request.request(
			baseURL
			, baseHeaders()
			, method
			, url.join('')
			, { list: body.map(requestParser), source }
		)
			.then(
				r => r.list.map(responseParser)
			)
	}

	function DELETE ([method, url]) {
		return request.request(
			baseURL
			,baseHeaders()
			, method, url.join('')
		)
	}


	function APIOrganizationsDisciplineToOrganizationsDiscipline(d) {
		return {
			organization_id:
				d.organization_id || null
			,organizations_disciplines_id:
				d.organizations_disciplines_id || null
			,organizations_disciplines_name:
				d.organizations_disciplines_name
			,organizations_disciplines_description:
				d.organizations_disciplines_description || ""
			,organizations_disciplines_nominal_rate:
				d.organizations_disciplines_nominal_rate || 0
			,organizations_disciplines_uom:
				d.organizations_disciplines_uom || "N/A"
			,contractrecognitionid:
				d.contractrecognitionid
			,contractrecognitionname:
				d.contractrecognitionname
			,contractrecognitiondescription:
				d.contractrecognitiondescription
			,orgDisciplineNameDescription:
				d.organizations_disciplines_name
				+ (
					d.organizations_disciplines_description
						? ' - ' + d.organizations_disciplines_description
						: ''
				)
			,organizations_disciplines_nominal_expenditure_rate:
				d.organizations_disciplines_nominal_expenditure_rate || 0
			,organizations_disciplines_nominal_revenue_rate:
				d.organizations_disciplines_nominal_revenue_rate || 0
			,completedcount: Number(d.completedcount)
			,completedsum: Number(d.completedsum)
			,scheduledsum: Number(d.scheduledsum)
			,applicablecontracts: d.applicablecontracts
			,applytoteams: []
		}
	}


	function OrganizationsDisciplineToAPIOrganizationsDiscipline(d) {
		return {
			organization_id:
				d.organization_id || null
			,organizations_disciplines_id:
				d.organizations_disciplines_id || null
			,organizations_disciplines_name:
				d.organizations_disciplines_name
			,organizations_disciplines_description:
				d.organizations_disciplines_description || ""
			,organizations_disciplines_nominal_rate:
				d.organizations_disciplines_nominal_rate || null
			,organizations_disciplines_uom:
				d.organizations_disciplines_uom || "N/A"
			,organizations_disciplines_nominal_expenditure_rate:
				d.organizations_disciplines_nominal_expenditure_rate || 0
			,organizations_disciplines_nominal_revenue_rate:
				d.organizations_disciplines_nominal_revenue_rate || 0
		}
	}


	const APIDisciplinesContractorsCrewtoDisciplinesContractorsCrew = (cid) =>
		function(dcc){
			return {
				crew_id: m.prop(dcc.crew_id || null)
				,contractor_id: m.prop(cid)
				,disciplines_contractors_crews_common:
					m.prop(dcc.disciplines_contractors_crews_common)
				,disciplines_contractors_crews_excess:
					m.prop(dcc.disciplines_contractors_crews_excess)
				,disciplines_contractors_crews_invalid:
					m.prop(dcc.disciplines_contractors_crews_invalid)
				,disciplines_contractors_crews_id:
					m.prop(dcc.disciplines_contractors_crews_id || null)
				,disciplines_contractors_crews_name:
					m.prop(dcc.disciplines_contractors_crews_name)
				,disciplines_contractors_crews_contract_multiplier:
					m.prop(dcc.disciplines_contractors_crews_contract_multiplier)
				,disciplines_contractors_crews_rating:
					m.prop(dcc.disciplines_contractors_crews_rating)
				,disciplines_contractors_crews_utilized:
					m.prop(dcc.disciplines_contractors_crews_utilized)
				,disciplines_contractors_crews_work_completed:
					m.prop(dcc.disciplines_contractors_crews_work_completed)
				,disciplines_contractors_id:
					m.prop(dcc.disciplines_contractors_id || null)
				,user_id:
					m.prop(dcc.user_id || null)
				,crews_discipline_rates_id: dcc.crews_discipline_rates_id
			}
	}

	const APIDisciplineToDiscipline = (project_name) =>
		function(d) {
			return {
				project_id:
					m.prop(d.project_id || null)
				,organizations_disciplines_id:
					m.prop(d.organizations_disciplines_id)
				,discipline_name:
					m.prop(d.discipline_name)
				,discipline_description:
					m.prop(d.discipline_description)
				,discipline_order:
					m.prop(d.discipline_order)
				,discipline_contract_multiplier:
					m.prop(
						d.discipline_contract_multiplier == Infinity
						? 1
						: d.discipline_contract_multiplier
					)
				,discipline_priority:
					m.prop(d.discipline_order)
				,discipline_work:
					m.prop(d.discipline_work)
				,discipline_planned:
					m.prop(d.discipline_planned)
				,discipline_combination_limit:
					m.prop(d.discipline_combination_limit)
				,discipline_combine:
					m.prop(d.discipline_combine)
				,discipline_custom:
					m.prop(d.discipline_custom)
				,discipline_completed:
					m.prop(d.discipline_completed)
				,discipline_harshness:
					m.prop(d.discipline_harshness)
				,discipline_percent:
					m.prop(d.discipline_percent)
				,discipline_id:
					m.prop(d.discipline_id || null)
				,discipline_flow:
					m.prop(
					d.discipline_flow == 'Conc'
						? 'Workflows'
						: 'Consecutive'
				)

				,discipline_start_date:
					m.prop(timestampToIsoUnsafe(d.discipline_start_date))
				,discipline_end_date:
					m.prop(timestampToIsoUnsafe(d.discipline_end_date))
				,discipline_uom:
					m.prop(d.discipline_uom)
				,discipline_recovery:
					m.prop(d.discipline_recovery)
				,discipline_auto_assign_tasks:
					m.prop(d.discipline_auto_assign_tasks)
				,discipline_strict_end:
					m.prop(d.discipline_strict_end)
				,discipline_strict_start:
					m.prop(d.discipline_strict_start)
				,discipline_consistency:
					m.prop(d.discipline_consistency)
				,discipline_consolidate_start:
					m.prop(d.discipline_consolidate_start)
				,disciplines_contractors:
					d.disciplines_contractors
						.map(APIDisciplinesContractorToDisciplinesContractor)

				,organizations_disciplines_nominal_expenditure_rate:
					m.prop(d.organizations_disciplines_nominal_expenditure_rate)
				,organizations_disciplines_nominal_revenue_rate:
					m.prop(d.organizations_disciplines_nominal_revenue_rate)
				,contract_items_client_reference: m.prop('')
				,organizations_disciplines_nominal_rate:
					m.prop(d.organizations_disciplines_nominal_rate)

				,contract_items_rate: d.contract_items_rate
				,contractrecognitionid:
					d.contractrecognitionid
				,contractrecognitionname:
					d.contractrecognitionname
				,contractrecognitiondescription:
					d.contractrecognitiondescription

				,CommonRecognition: d.discipline_id || null
				,ParentRecognition: d.ParentRecognition
				,ParentRecognitionID: d.project_id || null
				,disciplineName: d.discipline_name
				,discplineNameDescription: d.discplinenamedescription
				,eotSum: d.eotsum
				,nodSum: d.nodsum
				,varSum: d.varsum
				,lastAllocationDate: d.lastallocationdate
				,workdayeffort: d.workdayeffort
				,badeffortteams: d.badeffortteams
				,taskcount: Number(d.taskcount)
				,users: []
			}
		}

	function ProjectDetailsToAPIProjectDetails(d){
		return R.mapObjIndexed((k) => typeof k == 'function' ? k() : k, d)
	}

	function ProjectContactsToAPIProjectContacts(c){
		return {
			project_id: c.project_id()
			,project_contacts_name: c.project_contacts_name()
			,project_contacts_type: c.project_contacts_type()
			,project_contacts_id: c.project_contacts_id()
			,project_contacts_phone: c.project_contacts_phone()
			,project_contacts_email: c.project_contacts_email()
			,user_id: c.user_id()
		}
	}

	function ProjectToAPIProject(o){
		return {
			__requestedAt: o.__requestedAt
			,project_name: o
				.project_name()
			,project_parent: o.project_parent()
			,project_template_id: o.project_template_id()
			,project_draft_at: o.project_draft_at()
			,project_tendered_at: o.project_tendered_at()
			,project_rejected_at: o.project_rejected_at()
			,project_accepted_at: o.project_accepted_at()
			,project_site_type: o
				.project_site_type()
			,project_start_date:
				timestampToIsoUnsafe( o.project_start_date() )
			,project_dead_date:
				timestampToIsoUnsafe(o.project_dead_date() )
			,project_final_acceptance:
				timestampToIsoUnsafe(o.project_final_acceptance() )
			,project_commissioning_date:
				timestampToIsoUnsafe(o.project_commissioning_date() )
			,project_completion_comments: o
				.project_completion_comments()
			,project_completion_marker:
				timestampToIsoUnsafe(o.project_completion_marker() )
			,project_documents:
				o.project_documents()
					.filter( doc => doc.project_documents_href )
					.map(
						doc => ({
							project_documents_href:
								doc.project_documents_href
							, project_documents_label:
								doc.project_documents_label
							, project_documents_is_deliverable:
								doc.project_documents_is_deliverable
							, project_documents_id: doc.project_documents_id
						})
					)

			,project_suburb: o
				.project_suburb()
			,project_material_suburb:
				o.project_material_suburb()
			,project_priority:
				o.project_priority()
			,disciplines:
				o.disciplines.map(DisciplineToAPIDiscipline)
			,project_extras:
				o.project_extras.map(ProjectDetailsToAPIProjectDetails)
			,project_id:
				o.project_id()
				|| undefined
			,schedule_id:
				o.schedule_id()
				|| undefined
			,organization_id:
				o.organization_id()
				|| undefined
			,contract_id:
				exposer(o.contract_id)
				|| undefined
			,project_contacts:
				o.project_contacts.map(ProjectContactsToAPIProjectContacts)
		}
	}

	function APIDisciplinesContractorToDisciplinesContractor(
		dc
	) {
		const f =
			APIDisciplinesContractorsCrewtoDisciplinesContractorsCrew

		return {
			discipline_id:
				m.prop(dc.discipline_id || null)
			,contractor_id:
				m.prop(dc.contractor_id || null)
			,contractrecognitionid:
				dc.contractor_id
			,disciplines_contractors_name:
				m.prop(dc.disciplines_contractors_name)
			,disciplines_contractors_common:
				m.prop(dc.disciplines_contractors_common)
			,disciplines_contractors_mixed_crews:
				m.prop(dc.disciplines_contractors_mixed_crews)
			,disciplines_contractors_quality_rating:
				m.prop(dc.disciplines_contractors_quality_rating)
			,disciplines_contractors_total_rating:
				m.prop(dc.disciplines_contractors_total_rating)
			,disciplines_contractors_utilized:
				m.prop(dc.disciplines_contractors_utilized)
			,disciplines_contractors_id:
				m.prop(dc.disciplines_contractors_id || null)
			,user_id:
				m.prop(dc.user_id || null)
			,disciplines_contractors_crews:
				dc.disciplines_contractors_crews
					.map(
						f(dc.contractor_id)
					)
		}
	}

	function timestampToIsoSafe(timestamp) {
		return new Date(timestamp)
			.toISOString()
	}

	function timestampToIsoUnsafe(
		timestamp
	) {
		return timestamp
			? timestampToIsoSafe(timestamp)
			: null
	}

	function isoToTimeStampUnsafe(
		iso
	) {
		return iso
			? isoToTimeStampSafe(iso)
			: null
	}

	function isoToTimeStampSafe(
		iso
	) {
		return new Date(iso).getTime()
	}

	function APIProjectDetailsToProjectDetails(d){
		return {
			discipline_id:
				m.prop( d.discipline_id )
			,project_id:
				m.prop( d.project_id )
			,user_id:
				m.prop( d.user_id )
			,allocation_id:
				m.prop( d.allocation_id )
			,invite_id: m.prop( d.invite_id )
			,invite_email: d.invite_email
			,project_extras_planned_amount:
				m.prop( d.project_extras_planned_amount )
			,project_extras_custom:
				m.prop( d.project_extras_custom )
			,project_extras_actual_amount:
				m.prop( d.project_extras_actual_amount )
			,project_extras_id:
				m.prop( d.project_extras_id )
			,parents:
				m.prop( d.parents )
			,project_extras_type:
				m.prop( d.project_extras_type )
			,project_extras_frequency:
				m.prop( d.project_extras_frequency )
			,project_extras_description:
				m.prop( d.project_extras_description )
			,project_extras_notes:
				m.prop( d.project_extras_notes )
			,project_extras_address:
				m.prop( d.project_extras_address )
			,project_extras_priority:
				m.prop( d.project_extras_priority )
			,project_extras_date_assigned:
				m.prop( isoToTimeStampUnsafe(d.project_extras_date_assigned) )
			,project_extras_date_completed:
				m.prop( isoToTimeStampUnsafe(d.project_extras_date_completed) )
			,project_extras_date_verified:
				m.prop( isoToTimeStampUnsafe(d.project_extras_date_verified) )
			,project_extras_date_invalid:
				m.prop( isoToTimeStampUnsafe(d.project_extras_date_invalid) )
			,organization_id: m.prop( d.organization_id )
			,project_name: d.project_name
			,organizations_disciplines_id: m.prop(d.organizations_disciplines_id)
			,relatedDiscipline: d.relateddiscipline
			,username: d.username
			,costinvoices: d.costinvoices
			,revenueinvoices: d.revenueinvoices
			,filecount: Number(d.filecount)
			,contract_id: d.contract_id
			,schedule_id: d.schedule_id
		}
	}

	function APIProjectContactsToProjectContacts(c){
		return {
			project_id: m.prop(c.project_id)
			,project_contacts_id: m.prop(c.project_contacts_id)
			,project_contacts_type: m.prop(c.project_contacts_type)
			,project_contacts_name: m.prop(c.project_contacts_name)
			,project_contacts_phone: m.prop(c.project_contacts_phone)
			,project_contacts_email: m.prop(c.project_contacts_email)
			,user_id: m.prop(c.user_id)
			,nameRole: c.project_contacts_name + ' - ' + c.project_contacts_type
		}
	}

	function APIProjectToProject(o) {
		return R.merge(o, {
			project_id:
				m.prop(o.project_id || null)
			,project_name:
				m.prop(o.project_name)
			,project_parent: m.prop(o.project_parent)
			,project_template_id: m.prop(o.project_template_id)
			,project_draft_at: m.prop( isoToTimeStampUnsafe(o.project_draft_at) )
			,project_tendered_at: m.prop( isoToTimeStampUnsafe(o.project_tendered_at) )
			,project_rejected_at: m.prop( isoToTimeStampUnsafe(o.project_rejected_at) )
			,project_accepted_at: m.prop( isoToTimeStampUnsafe(o.project_accepted_at) )
			,project_site_type:
				m.prop(o.project_site_type)
			,schedule_id:
				m.prop(o.schedule_id || null)
			,project_start_date:
				m.prop( isoToTimeStampUnsafe( o.project_start_date) )
			,project_dead_date:
				m.prop( isoToTimeStampUnsafe(o.project_dead_date) )
			,project_final_acceptance:
				m.prop( isoToTimeStampUnsafe(o.project_final_acceptance) )
			,project_commissioning_date:
				m.prop( isoToTimeStampUnsafe(o.project_commissioning_date) )
			,project_completion_comments:
				m.prop(o.project_completion_comments)
			,project_completion_marker:
				m.prop( isoToTimeStampUnsafe(o.project_completion_marker) )
			,project_documents:
				[ o.project_documents ]
				.filter(Boolean)
				.concat([[]])
				.map(
					xs => xs.filter( x =>
						x.project_documents_href
						&& x.project_documents_label
					)
					.map(
						doc => ({
							project_documents_href:
								doc.project_documents_href
							, project_documents_label:
								doc.project_documents_label
							, project_documents_is_deliverable:
								doc.project_documents_is_deliverable
							, project_documents_id: doc.project_documents_id
						})
					)
				)
				.map( xs => m.prop(xs) )
				.shift()

			,project_suburb:
				m.prop( o.project_suburb )
			,project_material_suburb:
				m.prop( o.project_material_suburb )
			,project_priority:
				m.prop( Number(o.project_priority) )
			,ParentRecognition:
				o.project_name
			,CommonRecognition:
				o.project_id
			,disciplines:
				(o.disciplines || []).map(APIDisciplineToDiscipline(o.project_name))
			,project_extras:
				(o.project_extras || []).map(APIProjectDetailsToProjectDetails)
			,project_contacts:
				(o.project_contacts || []).map(APIProjectContactsToProjectContacts)
			,organization_id: m.prop(o.organization_id || null)
			,contract_id: o.contract_id
			,contract_name: o.contract_name
			,contractrecognitionid: o.contractrecognitionid
			,contractrecognitionname: o.contractrecognitionname
			,contractrecognitiondescription: o.contractrecognitiondescription
			,eotSum: o.eotsum
			,nodSum: o.nodsum
			,varSum: o.varsum
			,incompletetaskcount: o.incompletetaskcount
			,lastAllocationDate: o.lastallocationdate
			,zip_url: o.zip_url
			,latest_zip_url: o.latest_zip_url
			,latest_zip_date: o.latest_zip_date
			,is_zip_processing: o.is_zip_processing
			,workdays:
				R.sum(
					o.disciplines
					.map((d) =>
						d.discipline_work-d.discipline_completed > 0 && d.workdayeffort
						? (d.discipline_work-d.discipline_completed)/d.workdayeffort
						: 0
					)
				)
			,worksummaries: o.worksummaries
		})
	}

	function APIProjectToProject(o) {
		return R.merge(o, {
			project_id:
				m.prop(o.project_id || null)
			,project_name:
				m.prop(o.project_name)
			,project_parent: m.prop(o.project_parent)
			,project_template_id: m.prop(o.project_template_id)
			,project_draft_at: m.prop( isoToTimeStampUnsafe(o.project_draft_at) )
			,project_tendered_at: m.prop( isoToTimeStampUnsafe(o.project_tendered_at) )
			,project_rejected_at: m.prop( isoToTimeStampUnsafe(o.project_rejected_at) )
			,project_accepted_at: m.prop( isoToTimeStampUnsafe(o.project_accepted_at) )
			,project_site_type:
				m.prop(o.project_site_type)
			,schedule_id:
				m.prop(o.schedule_id || null)
			,project_start_date:
				m.prop( isoToTimeStampUnsafe( o.project_start_date) )
			,project_dead_date:
				m.prop( isoToTimeStampUnsafe(o.project_dead_date) )
			,project_final_acceptance:
				m.prop( isoToTimeStampUnsafe(o.project_final_acceptance) )
			,project_commissioning_date:
				m.prop( isoToTimeStampUnsafe(o.project_commissioning_date) )
			,project_completion_comments:
				m.prop(o.project_completion_comments)
			,project_completion_marker:
				m.prop( isoToTimeStampUnsafe(o.project_completion_marker) )
			,project_documents:
				[ o.project_documents ]
				.filter(Boolean)
				.concat([[]])
				.map(
					xs => xs.filter( x =>
						x.project_documents_href
						&& x.project_documents_label
					)
					.map(
						doc => ({
							project_documents_href:
								doc.project_documents_href
							, project_documents_label:
								doc.project_documents_label
							, project_documents_is_deliverable:
								doc.project_documents_is_deliverable
							, project_documents_id: doc.project_documents_id
						})
					)
				)
				.map( xs => m.prop(xs) )
				.shift()

			,project_suburb:
				m.prop( o.project_suburb )
			,project_material_suburb:
				m.prop( o.project_material_suburb )
			,project_priority:
				m.prop( Number(o.project_priority) )
			,ParentRecognition:
				o.project_name
			,CommonRecognition:
				o.project_id
			,disciplines:
				(o.disciplines || []).map(APIDisciplineToDiscipline(o.project_name))
			,project_extras:
				(o.project_extras || []).map(APIProjectDetailsToProjectDetails)
			,project_contacts:
				(o.project_contacts || []).map(APIProjectContactsToProjectContacts)
			,organization_id: m.prop(o.organization_id || null)
			,contract_id: o.contract_id
			,contract_name: o.contract_name
			,contractrecognitionid: o.contractrecognitionid
			,contractrecognitionname: o.contractrecognitionname
			,contractrecognitiondescription: o.contractrecognitiondescription
			,eotSum: o.eotsum
			,nodSum: o.nodsum
			,varSum: o.varsum
			,incompletetaskcount: o.incompletetaskcount
			,lastAllocationDate: o.lastallocationdate
			,zip_url: o.zip_url
			,latest_zip_url: o.latest_zip_url
			,latest_zip_date: o.latest_zip_date
			,is_zip_processing: o.is_zip_processing
			,workdays:
				R.sum(
					o.disciplines
					.map((d) =>
						d.discipline_work-d.discipline_completed > 0 && d.workdayeffort
						? (d.discipline_work-d.discipline_completed)/d.workdayeffort
						: 0
					)
				)
			,worksummaries: o.worksummaries
		})
	}


	function ContractToAPIContract(o){
		return {
			organization_id:
				exposer(o.organization_id)
				|| undefined
			,contract_id: exposer(o.contract_id)
			,contract_name: exposer(o.contract_name)
			,contract_description: exposer(o.contract_description)
			,contract_payment_term: exposer(o.contract_payment_term)
			,contract_count_invoices: exposer(o.contract_count_invoices)
			,contract_start_date: timestampToIsoUnsafe(exposer(o.contract_start_date))
			,contract_end_date: timestampToIsoUnsafe(exposer(o.contract_end_date))
			,supplier_id: exposer(o.supplier_id)
			,supplier_name: exposer(o.supplier_name)
			,contract_items: o.contract_items.map(ContractItemToAPIContractItem)
			,contract_terms: exposer(o.contract_terms)
			,accounts:
				exposer(o.account_bsb)
				|| exposer(o.account_no)
				|| exposer(o.account_bank)
				|| exposer(o.account_bic)
				|| exposer(o.account_iban)
				|| exposer(o.account_swift_code)
				? [{
					account_id: exposer(o.account_id)
					,account_bsb: exposer(o.account_bsb)
					,account_no: exposer(o.account_no)
					,account_bank: exposer(o.account_bank)
					,account_bic: exposer(o.account_bic)
					,account_iban: exposer(o.account_iban)
					,account_swift_code: exposer(o.account_swift_code)
				}]
				: []
			,clients:
				exposer(o.client_organization_name)
				|| exposer(o.client_incorporation_no)
				|| exposer(o.client_email)
				|| exposer(o.client_phone)
				|| exposer(o.client_address)
				|| exposer(o.client_state)
				|| exposer(o.client_postcode)
					? [{
						client_id: exposer(o.client_id)
						,client_organization_name: exposer(o.client_organization_name)
						,client_incorporation_no: exposer(o.client_incorporation_no)
						,client_email: exposer(o.client_email)
						,client_phone: exposer(o.client_phone)
						,client_address: exposer(o.client_address)
						,client_state: exposer(o.client_state)
						,client_postcode: exposer(o.client_postcode)
					}]
					: []
		}
	}


	function ContractItemToAPIContractItem(i){
		return {
			contract_id:
				i.contract_id
			,modules_id:
				i.modules_id
			,contractor_id:
				i.contractor_id
			,project_id:
				i.project_id
			,organizations_disciplines_id:
				i.organizations_disciplines_id
			,tools_id:
				i.tools_id
			,user_id:
				i.user_id
			,crew_id:
				i.crew_id
			,crews_discipline_rates_id:
				i.crews_discipline_rates_id
			,warehouse_id:
				i.warehouse_id
			,contract_items_id:
				exposer(i.contract_items_id)
			,contract_items_name:
				exposer(i.contract_items_name)
			,contract_items_description:
				exposer(i.contract_items_description)
			,contract_items_client_reference:
				exposer(i.contract_items_client_reference)
			,contract_items_rate:
				exposer(i.contract_items_rate)
			,contract_items_budget:
				exposer(i.contract_items_budget)
			,contract_items_service:
				exposer(i.contract_items_service)
			,contract_items_depreciation:
				exposer(i.contract_items_depreciation)
			,contract_items_appreciation:
				exposer(i.contract_items_appreciation)
			,contract_items_uom:
				exposer(i.contract_items_uom)
			,contract_items_damages_rate:
				exposer(i.contract_items_damages_rate)
			,contract_items_damages_period:
				exposer(i.contract_items_damages_period)
			,contract_items_applied:
				exposer(i.contract_items_applied)
			,contract_items_recorded_reference:
				exposer(i.contract_items_recorded_reference)
			,contract_items_recorded_reference_standard:
				exposer(i.contract_items_recorded_reference_standard)
		}
	}


	function APIContractToContract(o){
		return {
			organization_id:
				o.organization_id
			,created:
				false
			// ,contractrecognitionid:
				// o.contract_id
			,contract_id:
				o.contract_id
			,contract_name:
				o.contract_name
			,contract_description:
				o.contract_description
			,contract_payment_term:
			 	o.contract_payment_term
			,contract_count_invoices:
				o.contract_count_invoices
			,contract_start_date:
				timestampToIsoUnsafe(o.contract_start_date)
			,contract_end_date:
				timestampToIsoUnsafe(o.contract_end_date)
			,supplier_id:
				o.supplier_id
			,supplier_name:
				o.supplier_name
			,contract_items:
				o.contract_items.map(APIContractItemToContractItem(o))

			,client_id: o.client_id
			,client_organization_name: o.client_organization_name
			,client_incorporation_no: o.client_incorporation_no
			,client_email: o.client_email
			,client_phone: o.client_phone
			,client_address: o.client_address
			,client_state: o.client_state
			,client_postcode: o.client_postcode

			,account_id: o.account_id
			,account_bsb: o.account_bsb
			,account_no: o.account_no
			,account_bank: o.account_bank
			,account_bic: o.account_bic
			,account_iban: o.account_iban
			,account_swift_code: o.account_swift_code

			,contract_terms: o.contract_terms
			,badcontractcount: o.badcontractcount
			,goodcontractcount: o.goodcontractcount
		}
	}


	const APIContractItemToContractItem = (contract) => (i) => {
		return {
			key: Math.random().toString(15).slice(2, 8)
			,contract_name: contract.contract_name
			,contractStart: contract.contract_start_date
			,contractEnd: contract.contract_end_date
			,damagesEnd: new Date(contract.contract_end_date).getTime()
				+ i.contract_items_damages_period * milliperday

			,contractrecognitionname: i.contract_items_name + ' - ' + i.contract_items_description
			,contractrecognitiondescription: i.contract_items_description
			,contractrecognitionid:
				i.modules_id
				|| i.warehouse_id
				|| i.contractor_id
				|| i.project_id
				|| i.organizations_disciplines_id
				|| i.tools_id
				|| i.user_id
				|| i.crew_id
				|| i.crews_discipline_rates_id
			,created: false

			,organizations_disciplines_id: i.organizations_disciplines_id
			,crews_discipline_rates_id: i.crews_discipline_rates_id
			,contractor_id: i.contractor_id
			,crew_id: i.crew_id
			,modules_id: i.modules_id
			,project_id: i.project_id
			,tools_id: i.tools_id
			,user_id: i.user_id
			,warehouse_id: i.warehouse_id

			,organization_id:
				m.prop( i.organization_id )
			,contract_id:
				m.prop( i.contract_id )
			,contract_items_id:
				m.prop( i.contract_items_id )
			,contract_items_name:
				m.prop( i.contract_items_name )
			,contract_items_description:
				m.prop( i.contract_items_description )
			,contract_items_client_reference:
				m.prop( i.contract_items_client_reference )
			,contract_items_rate:
				m.prop( i.contract_items_rate )
			,contract_items_budget:
				m.prop( i.contract_items_budget )
			,contract_items_service:
				m.prop( i.contract_items_service )
			,contract_items_depreciation:
				m.prop( i.contract_items_depreciation )
			,contract_items_appreciation:
				m.prop( i.contract_items_appreciation )
			,contract_items_uom:
				m.prop( i.contract_items_uom )
			,contract_items_damages_rate:
				m.prop( i.contract_items_damages_rate )
			,contract_items_damages_period:
				m.prop( i.contract_items_damages_period )
			,contract_items_applied:
				m.prop( i.contract_items_applied )
			,contract_items_recorded_reference:
				m.prop( i.contract_items_recorded_reference )
			,contract_items_recorded_reference_standard:
				m.prop( i.contract_items_recorded_reference_standard )
		}
	}

	const contracts = {
		patch: {
			many: function( list ) {

				return PATCH_MANY(
					['PATCH', ['/contracts']]
					,[ list
					, ContractToAPIContract
					, APIContractToContract
					]
				)
			}
		}
		,fetch: {
			all: function({props, depth, contracts, getorganizationoh}) {
				const params = [
					['props', props]
					,['depth', depth]
					,['contracts', contracts || null]
					,['getorganizationoh', getorganizationoh || null]
				]

				return LIST(['GET', ['/contracts', params]])
				.then( list => list.map( APIContractToContract ))
			}
		}
		,remove: {
			one: function(id){
				return DELETE(['DELETE', ['/contracts/', id]])
			}
		}
	}
	const contract_items = {
		remove: {
			one: function(id){
				return DELETE(['DELETE', ['/contracts/contract_items/', id]])
			}
		}
	}

	const contracts_invoices_calculations = {
		fetch: {
			all: function(
				paramObj, {financialsubsets, ledgerdetails, getmatdata, specificproject}
			){

				const params =
					[
						['scheduleList', paramObj.scheduleList.map(R.omit(['makeChart','chart', 'chartElement', 'tp']))]
						,['financialsubsets', financialsubsets]
						,['ledgerdetails', ledgerdetails]
						,['getmatdata', getmatdata]
						,['specificproject', specificproject]
						,['contracts', paramObj.contracts]
						,['invoices', paramObj.invoices]
						,['contract_items', paramObj.contract_items]
						,['items',  paramObj.items]
						,['viewwindow', paramObj.viewwindow]
						,['nochildren', paramObj.nochildren]
						,['props', paramObj.props]
						,['sumtype', paramObj.sumtype]
						,['scheduleonly', paramObj.scheduleonly]
						,['getchartdata', paramObj.getchartdata]
						,['now', new Date().getTime()]
						,['endDate', paramObj.endDate]
						,['startDate', paramObj.startDate]
						,['currentRoute', paramObj.currentRoute]
						,['breakdown', paramObj.breakdown]
					]

				const baseURL = process.env.HARTH_API_CALC_URL
				return LIST_ATTRS(
					baseURL
					, baseHeaders()
					, [
						'GET'
						,[

								'/contracts_invoices_calculations'
								+ '/endDate/'
								+ paramObj.endDate
								+ '/startDate/'
								+ paramObj.startDate
								+ '/currentRoute/'
								+ paramObj.currentRoute

							, params
						]
					]
				)
			}
		}
	}

	function APIInvoiceItemsToInvoiceItems(i){
		return {
			project_extras_id: i.project_extras_id
			,invoice_items_id: i.invoice_items_id
			,invoice_items_amount: i.invoice_items_amount
			,invoice_items_received_amount: i.invoice_items_received_amount
			,timesheets_id: i.timesheets_id
			,invoice_id: i.invoice_id
		}
	}

	function APIInvoiceToInvoice(i){
		return {
			invoice_type: m.prop(i.invoice_type)
			,allocation_id: m.prop(i.allocation_id)
			,organization_id: m.prop(i.organization_id)
			,contract_id: m.prop(i.contract_id)
			,contract_items_id: m.prop(i.contract_items_id)
			,invoice_id: m.prop(i.invoice_id)
			,invoice_inv_id: m.prop(i.invoice_inv_id)
			,invoice_date: m.prop(timestampToIsoUnsafe(i.invoice_date))
			,invoice_received_date: m.prop(timestampToIsoUnsafe(i.invoice_received_date))
			,invoice_due_date: m.prop(timestampToIsoUnsafe(i.invoice_due_date))
			,invoice_no: m.prop(i.invoice_no)
			,invoice_received: m.prop(i.invoice_received)
			,invoice_name: m.prop(i.invoice_name)
			,invoice_description: m.prop(i.invoice_description)
			,invoice_client_reference: m.prop(i.invoice_client_reference)
			,invoice_items_client_reference: m.prop(i.invoice_items_client_reference)
			,invoice_uom: m.prop(i.invoice_uom)
			,invoice_amount: m.prop(i.invoice_amount)
			,invoice_reversal: m.prop(false)
			,invoice_service_amount: m.prop(i.invoice_service_amount)
			,invoice_depreciation_amount: m.prop(i.invoice_depreciation_amount)
			,invoice_appreciation_amount: m.prop(i.invoice_appreciation_amount)
			,invoice_damages_amount: m.prop(i.invoice_damages_amount)
			,invoice_rate: m.prop(i.invoice_rate)
			,invoice_service_rate: m.prop(i.invoice_service_rate)
			,invoice_depreciation_rate: m.prop(i.invoice_depreciation_rate)
			,invoice_appreciation_rate: m.prop(i.invoice_appreciation_rate)
			,invoice_damages_rate: m.prop(i.invoice_damages_rate)
			,invoice_approval: m.prop(i.invoice_approval)
			,invoice_approver: m.prop(i.invoice_approver)
			// ,contractrecognitionid: i.invoice_id
			,supplier_name: m.prop(i.supplier_name)
			,supplier_id: m.prop(i.supplier_id)
			,user_id: m.prop(i.user_id)
			,project_id: m.prop(i.project_id)
			,project_name: m.prop(i.project_name)
			,created: false
			,contract_payment_term: i.contract_payment_term
			,invoice_amount_total: i.invoice_amount_total
			,invoice_items: i.invoice_items.map( APIInvoiceItemsToInvoiceItems )
		}
	}

	function InvoiceItemsToAPIInvoiceItems(i){
		return {
			project_extras_id: i.project_extras_id
			,invoice_items_id: i.invoice_items_id
			,invoice_items_amount: i.invoice_items_amount
			,invoice_items_received_amount: i.invoice_items_received_amount
			,timesheets_id: i.timesheets_id
			,invoice_id: i.invoice_id
		}
	}


	function InvoiceToAPIInvoice(i){
		return {
			invoice_type: i.invoice_type()
			,contract_id: i.contract_id()
			,contract_items_id: i.contract_items_id()
			,organization_id: i.organization_id()
			,invoice_date: timestampToIsoUnsafe(i.invoice_date())
			,invoice_received_date: timestampToIsoUnsafe(i.invoice_received_date())
			,invoice_due_date: timestampToIsoUnsafe(i.invoice_due_date())
			,invoice_id: i.invoice_id()
			,invoice_no: i.invoice_no()
			,allocation_id: i.allocation_id()
			,invoice_received: i.invoice_received()
			,invoice_name: i.invoice_name()
			,invoice_description: i.invoice_description()
			,invoice_client_reference: i.invoice_client_reference()
			,invoice_items_client_reference: i.invoice_items_client_reference()
			,invoice_uom: i.invoice_uom()
			,invoice_amount: i.invoice_amount()
			,invoice_reversal: i.invoice_reversal()
			,invoice_service_amount: i.invoice_service_amount()
			,invoice_depreciation_amount: i.invoice_depreciation_amount()
			,invoice_appreciation_amount: i.invoice_appreciation_amount()
			,invoice_damages_amount: i.invoice_damages_amount()
			,invoice_rate: i.invoice_rate()
			,invoice_service_rate: i.invoice_service_rate()
			,invoice_depreciation_rate: i.invoice_depreciation_rate()
			,invoice_appreciation_rate: i.invoice_appreciation_rate()
			,invoice_damages_rate: i.invoice_damages_rate()
			,invoice_approval: i.invoice_approval()
			,invoice_approver: i.invoice_approver()
			,supplier_name: i.supplier_name()
			,supplier_id: i.supplier_id()
			,project_id: i.project_id()
			,project_name: i.project_name()
			,user_id: i.user_id()
			,invoice_items: i.invoice_items.map( InvoiceItemsToAPIInvoiceItems )
		}
	}



	const invoices = {
		patch: {
			many: function(list, disableList) {
				const params = [['disableList', disableList]]
				return PATCH_MANY(
					['PATCH', ['/invoices/', params]]
					,[ list
					, InvoiceToAPIInvoice
					, APIInvoiceToInvoice
					]
				)
			}
		}
		,fetch: {
			all: function() {
				return LIST(['GET', ['/invoices']])
					.then( list => list.map( APIInvoiceToInvoice ))
			}
		}
		,remove: {
			one: function(id){
				return DELETE(['DELETE', ['/invoices/', id]])
			}
		}
		,itemsremove: {
			one: function(id){
				return DELETE(['DELETE', ['/invoice_items/', id]])
			}
		}
		,generateqi({
			type
			,project_extras
			,invoices
			,chargeDetails
			,contract_id
			,contacts
			,generateOnly
		}){
			const body = {
				type
				,project_extras
				,invoices
				,chargeDetails
				,contract_id
				,contacts: {
					phones: contacts.phones()
						,emails: contacts.emails()
						,message: contacts.message()
				}
				,generateOnly
			}
			return POST(
				['POST', ['/quote/']],
				[body, I, I]
			)
		}
	}


	function DisciplineToAPIDiscipline(d) {

		return {
			discipline_name: d.discipline_name()
			,organizations_disciplines_id:  d.organizations_disciplines_id()
			,discipline_description: d.discipline_description()
			,discipline_order: d.discipline_order()
			,discipline_priority: d.discipline_order()
			,discipline_contract_multiplier: d.discipline_contract_multiplier()
			,discipline_work: d.discipline_work()
			,discipline_planned: d.discipline_planned()
			,discipline_combination_limit: d.discipline_combination_limit()
			,discipline_combine: d.discipline_combine()
			,discipline_custom: d.discipline_custom()
			,discipline_completed: d.discipline_completed()
			,discipline_harshness: d.discipline_harshness()
			,discipline_percent: d.discipline_percent()
			,discipline_flow:
				d.discipline_flow() == 'Workflows'
					? 'Conc'
					: 'Cons'
			,discipline_start_date:
				timestampToIsoUnsafe(d.discipline_start_date())
			,discipline_end_date:
				timestampToIsoUnsafe(d.discipline_end_date())
			,discipline_recovery: d.discipline_recovery()
			,discipline_auto_assign_tasks: d.discipline_auto_assign_tasks()
			,discipline_strict_end: d.discipline_strict_end()
			,discipline_strict_start: d.discipline_strict_start()
			,discipline_consistency: d.discipline_consistency()
			,discipline_consolidate_start: d.discipline_consolidate_start()
			,discipline_uom: d.discipline_uom()
			,disciplines_contractors: d.disciplines_contractors
				.map(DisciplinesContractorToAPIDisciplinesContractor)
			,project_id:
				d.project_id() || undefined
			,discipline_id:
				d.discipline_id() || undefined
			,organizations_disciplines_nominal_revenue_rate:
				d.organizations_disciplines_nominal_revenue_rate() || 0
			,organizations_disciplines_nominal_expenditure_rate:
				d.organizations_disciplines_nominal_expenditure_rate() || 0
			,contract_items_client_reference: d.contract_items_client_reference() || ''
			,organizations_disciplines_nominal_rate: d.organizations_disciplines_nominal_rate() || 0
		}
	}

	function DisciplinesContractorToAPIDisciplinesContractor(
		dc
	) {
		return {
			disciplines_contractors_common:
				dc.disciplines_contractors_common()
			,disciplines_contractors_mixed_crews:
				dc.disciplines_contractors_mixed_crews()
			,disciplines_contractors_name:
				dc.disciplines_contractors_name()
			,disciplines_contractors_quality_rating:
				dc.disciplines_contractors_quality_rating()
			,disciplines_contractors_total_rating:
				dc.disciplines_contractors_total_rating()
			,disciplines_contractors_utilized:
				dc.disciplines_contractors_utilized()
			,disciplines_contractors_crews:
				dc.disciplines_contractors_crews
				.map(
					DisciplinesContractorsCrewToAPIDisciplinesContractorsCrew
				)
			,disciplines_contractors_id:
				dc.disciplines_contractors_id()
				|| undefined
			,contractor_id:
				dc.contractor_id()
				|| undefined
			,discipline_id:
				dc.discipline_id()
				|| undefined
			,user_id:
				dc.user_id()
				|| undefined
		}
	}

	function DisciplinesContractorsCrewToAPIDisciplinesContractorsCrew(
		dcc
	) {

		return {
			disciplines_contractors_crews_common:
				dcc.disciplines_contractors_crews_common()
			,disciplines_contractors_crews_excess:
				dcc.disciplines_contractors_crews_excess()
			,disciplines_contractors_crews_invalid:
				dcc.disciplines_contractors_crews_invalid()
			,disciplines_contractors_crews_name:
				dcc.disciplines_contractors_crews_name()
			,disciplines_contractors_crews_contract_multiplier:
				dcc.disciplines_contractors_crews_contract_multiplier()
			,disciplines_contractors_crews_rating:
				dcc.disciplines_contractors_crews_rating()
			,disciplines_contractors_crews_utilized:
				dcc.disciplines_contractors_crews_utilized()
			,disciplines_contractors_crews_work_completed:
				dcc.disciplines_contractors_crews_work_completed()
			,crew_id:
				dcc.crew_id()
				|| undefined
			,disciplines_contractors_crews_id:
				dcc.disciplines_contractors_crews_id()
				|| undefined
			,disciplines_contractors_id:
				dcc.disciplines_contractors_id()
				|| undefined
		}
	}

	function APIScheduleToSchedule(o) {
		return {
			schedule_id: o.schedule_id
			,eotSum: o.eotsum
			,nodSum: o.nodsum
			,varSum: o.varsum
			,lastAllocationDate: o.lastallocationdate
			,schedule_shared_resources: o.schedule_shared_resources
			,schedule_name: o.schedule_name
			,schedules_parameters_id: o.schedule_versions[0]
				.schedules_parameters_id
			,schedule_versions: o.schedule_versions
				.map(
					v => ({
						schedule_version_id:
							v.schedule_version_id
						,schedule_version_created:
							isoToTimeStampUnsafe(v.schedule_version_created)
						,schedule_id: v.schedule_id
						,schedule_version_old_id: v.schedule_version_old_id
						,schedule_priority: v.schedule_priority
						,schedules_parameters_id: v.schedules_parameters_id
						,scheduleNameVersion:
							o.schedule_name
							+ ' - '
							+ v.schedule_priority
							+ ' - '
							+ moment(new Date(isoToTimeStampUnsafe(v.schedule_version_created))).format("lll")
					})
				)
		}
	}

	function ScheduleToAPISchedule(o) {
		return {
			schedule_name: o.schedule_name
			,schedule_id: o.schedule_id
			,schedule_parent: o.schedule_parent || null
			,schedule_shared_resources: o.schedule_shared_resources
			,schedule_versions: o.schedule_versions
				.map(
					v => {
						return {
							schedule_version_created:
								timestampToIsoUnsafe(v.schedule_version_created)
							,schedule_priority:
								v.schedule_priority
							,schedules_parameters_id:
								v.schedules_parameters_id
							,schedule_version_old_id:
								v.schedule_version_old_id
							,schedule_id:
								v.schedule_id || undefined
							,schedule_version_id:
								v.schedule_version_id || undefined
						}
					}
				)
			,project_id:
				o.project_id || undefined
		}
	}


	function APISchedulesParametersToUI(t) {
		return {
			organization_id:
				m.prop(t.organization_id  || null)
			,schedules_parameters_id:
				m.prop(t.schedules_parameters_id || null)
			,schedules_parameters_name:
				m.prop(t.schedules_parameters_name)
			,schedules_parameters_forward_limit:
				m.prop(t.schedules_parameters_forward_limit)
			,schedules_parameters_consolidate_limit:
				m.prop(t.schedules_parameters_consolidate_limit)
			,schedules_parameters_skip_consolidate_ratio:
				m.prop(t.schedules_parameters_skip_consolidate_ratio)
			,schedules_parameters_skip_consolidate_saving:
				m.prop(t.schedules_parameters_skip_consolidate_saving)
			,schedules_parameters_mobilisation_limit:
				m.prop(t.schedules_parameters_mobilisation_limit)
			,schedules_parameters_start_date_weighting:
				m.prop(t.schedules_parameters_start_date_weighting)
			,schedules_parameters_mobilisation_distance_weighting:
				m.prop(t.schedules_parameters_mobilisation_distance_weighting)
			,schedules_parameters_discipline_level_weighting:
				m.prop(t.schedules_parameters_discipline_level_weighting)
			,schedules_parameters_business_level_weighting:
				m.prop(t.schedules_parameters_business_level_weighting)
			,schedules_parameters_quality_rating_weighting:
				m.prop(t.schedules_parameters_quality_rating_weighting)
			,schedules_parameters_comfort_zone_weighting:
				m.prop(t.schedules_parameters_comfort_zone_weighting)
			,schedules_parameters_work_rates_weighting:
				m.prop(t.schedules_parameters_work_rates_weighting)
			,schedules_parameters_team_required_25:
				m.prop(t.schedules_parameters_team_required_25)
			,schedules_parameters_team_required_5:
				m.prop(t.schedules_parameters_team_required_5)
			,schedules_parameters_team_required_75:
				m.prop(t.schedules_parameters_team_required_75)
			,schedules_parameters_team_required_10:
				m.prop(t.schedules_parameters_team_required_10)
			,schedules_parameters_team_required_125:
				m.prop(t.schedules_parameters_team_required_125)
			,schedules_parameters_team_required_100:
				m.prop(t.schedules_parameters_team_required_100)
			,schedules_parameters_team_required_recovery_25:
				m.prop(t.schedules_parameters_team_required_recovery_25)
			,schedules_parameters_team_required_recovery_5:
				m.prop(t.schedules_parameters_team_required_recovery_5)
			,schedules_parameters_team_required_recovery_75:
				m.prop(t.schedules_parameters_team_required_recovery_75)
			,schedules_parameters_team_required_recovery_10:
				m.prop(t.schedules_parameters_team_required_recovery_10)
			,schedules_parameters_team_required_recovery_125:
				m.prop(t.schedules_parameters_team_required_recovery_125)
			,schedules_parameters_team_required_recovery_100:
				m.prop(t.schedules_parameters_team_required_recovery_100)
		}
	}

	function SchedulesParametersToAPISchedulesParameters(t) {
		return {
			schedules_parameters_name:
				t.schedules_parameters_name()
			,schedules_parameters_forward_limit:
				t.schedules_parameters_forward_limit()
			,schedules_parameters_consolidate_limit:
				t.schedules_parameters_consolidate_limit()
			,schedules_parameters_skip_consolidate_ratio:
				t.schedules_parameters_skip_consolidate_ratio()
			,schedules_parameters_skip_consolidate_saving:
				t.schedules_parameters_skip_consolidate_saving()
			,schedules_parameters_mobilisation_limit:
				t.schedules_parameters_mobilisation_limit()
			,schedules_parameters_start_date_weighting:
				t.schedules_parameters_start_date_weighting()
			,schedules_parameters_mobilisation_distance_weighting:
				t.schedules_parameters_mobilisation_distance_weighting()
			,schedules_parameters_discipline_level_weighting:
				t.schedules_parameters_discipline_level_weighting()
			,schedules_parameters_business_level_weighting:
				t.schedules_parameters_business_level_weighting()
			,schedules_parameters_quality_rating_weighting:
				t.schedules_parameters_quality_rating_weighting()
			,schedules_parameters_comfort_zone_weighting:
				t.schedules_parameters_comfort_zone_weighting()
			,schedules_parameters_work_rates_weighting:
				t.schedules_parameters_work_rates_weighting()
			,schedules_parameters_team_required_25:
				t.schedules_parameters_team_required_25()
			,schedules_parameters_team_required_5:
				t.schedules_parameters_team_required_5()
			,schedules_parameters_team_required_75:
				t.schedules_parameters_team_required_75()
			,schedules_parameters_team_required_10:
				t.schedules_parameters_team_required_10()
			,schedules_parameters_team_required_125:
				t.schedules_parameters_team_required_125()
			,schedules_parameters_team_required_100:
				t.schedules_parameters_team_required_100()
			,schedules_parameters_team_required_recovery_25:
				t.schedules_parameters_team_required_recovery_25()
			,schedules_parameters_team_required_recovery_5:
				t.schedules_parameters_team_required_recovery_5()
			,schedules_parameters_team_required_recovery_75:
				t.schedules_parameters_team_required_recovery_75()
			,schedules_parameters_team_required_recovery_10:
				t.schedules_parameters_team_required_recovery_10()
			,schedules_parameters_team_required_recovery_125:
				t.schedules_parameters_team_required_recovery_125()
			,schedules_parameters_team_required_recovery_100:
				t.schedules_parameters_team_required_recovery_100()
			,organization_id:
				t.organization_id()
				|| undefined
			,schedules_parameters_id:
				t.schedules_parameters_id()
				|| undefined
		}
	}


	const schedules = {
		fetch: {
			all: function() {
				return LIST(['GET', ['/schedules']])
					.then( list => list.map(APIScheduleToSchedule) )
			}
			,allStats: function(){
				return LIST(['GET', ['/schedules/statistics']])
					.then( function(list){
						return list
					})
			}
		}
		,remove: function(schedule_id) {
			return DELETE(['DELETE', ['/schedules/', schedule_id]])
		}
		,patch: function(schedules){
			return PATCH_MANY(
				[ 'PATCH', ['/schedules'] ]
				,[
					schedules
					,ScheduleToAPISchedule
					,APIScheduleToSchedule
				]
			)
		}
	}

	const schedules_parameters = {
		fetch: {
			all: function() {
				return LIST(['GET', ['/schedules_parameters']])
					.then( list => list.map(APISchedulesParametersToUI) )
			}
		}

		,patch: {
			many: function(SchedulesParameters){
				return PATCH_MANY(
					['PATCH', ['/schedules_parameters']]
					,[ SchedulesParameters
					, SchedulesParametersToAPISchedulesParameters
					, APISchedulesParametersToUI
					]
				)
			}
		}


		,remove: {
			one: function(schedules_parameters_id){
				return DELETE([
					'DELETE'
					, ['/schedules_parameters/', schedules_parameters_id]
				])
			}
		}
	}


	const projects = {
		fetch: {
			all: function({
				schedule_id
				, completedProjects
				, props
				, depth
				, project_id
				, gettemplates
				, taskcount
			}){
				const t = request.MetadataTracker()

				const params = [
					['schedule_id', schedule_id]
					,['completedProjects', completedProjects]
					,['props', props]
					,['depth', depth]
					,['project_id', project_id]
					,['gettemplates', gettemplates]
					,['taskcount', taskcount]
				]

				return LIST(['GET', ['/projects', params]])
					.then( (list) => list.map(APIProjectToProject) )
					.then( t.addMetaData )
			}
			,bySchedule: function({
				schedule_id
				, completedProjects
				, props
				, depth
				, project_id
				, gettemplates
				, taskcount
			}) {
				const t = request.MetadataTracker()

				const params = [
					['schedule_id', schedule_id]
					,['completedProjects', completedProjects]
					,['props', props]
					,['depth', depth]
					,['project_id', project_id]
					,['gettemplates', gettemplates]
					,['taskcount', taskcount]
				]

				return LIST(
					['GET', ['/projects/schedule/', schedule_id, params ]]
				)
					.then( (list) => list.map(APIProjectToProject) )
					.then( t.addMetaData )
			}
			,one_shallow({project_id, props, depth}){
				const t = request.MetadataTracker()

				const params = [
					['project_id', project_id]
					,['props', props]
					,['depth', depth]
				]

				return LIST(['GET', ['/projects', params]])
					.then(
						list => list[0]
					)
					.then(t.addMetaData)
			}
			,one: function(project_id) {
				const t = request.MetadataTracker()

				const params =
					[['project_id', project_id]]

				return LIST(['GET', ['/projects', params]])
					.then(
						list => list[0]
					)
					.then( t.addMetaData )
			}
			,zip: function({
				project_id
			}) {
				const t = request.MetadataTracker()

				return LIST(
					['GET', ['/projects/zip/project_id/' + project_id]]
				)
					.then( I )
					.then( t.addMetaData )
					.then( R.map(R.evolve({
						watch(url){
							const stream = prop()
							const source = new window.EventSource(
								new window.URL('.'+url, baseURL+'/')
							)

							source.onmessage = (e) => {
								stream(JSON.parse(e.data))
							}

							source.onerror = () => {
								source.close()
								stream.end(true)
							}

							return stream
						}
					})))
			}
		}
		,transfer({projects, schedule_id}){
			const t = request.MetadataTracker()

			return PATCH_MANY(
				['PATCH', [
					'/projects/schedule_id/'
					+ schedule_id
					+ '/transfer'
				]]
				,[ t.removeMetaData(projects)
				, I
				, I
				]
			)
			.then( t.addMetaData )
		}
		,patch({schedule_id, completedProjects, projects}){

			const t = request.MetadataTracker()

			return PATCH_MANY(
				['PATCH', [
					'/projects/schedule_id/'
					+ schedule_id
					+ '/completedProjects/'
					+ completedProjects
				]]
				,[ t.removeMetaData(projects)
				, ProjectToAPIProject
				, APIProjectToProject
				]
			)
			.then( t.addMetaData )
		}
		,remove: {
			one: function(project_id){
				const t = request.MetadataTracker()
				return DELETE(['DELETE', ['/projects/', project_id]])
					.then(t.addMetaData)
			}
		}
	}

	function APIUserToUser(u) {
		return {
			auth_permissions_read: m.prop(u.auth_permissions_read)
			,auth_permissions_write: m.prop(u.auth_permissions_write)
			,role_id: m.prop( u.role_id || null)
			,user_country: m.prop( u.user_country )
			,user_email: m.prop( u.user_email )
			,user_name: m.prop( u.user_name )
			,user_id: m.prop( u.user_id || null)
			,user_permissions: m.prop( u.user_permissions )
			,user_username: m.prop( u.user_username )
			,auth_token: m.prop( u.auth_token )
			,auth_permissions: m.prop( [] )
			,contractrecognitionid: u.contractrecognitionid
			,contractrecognitionname: u.contractrecognitionname
			,contractrecognitiondescription: u.contractrecognitiondescription
			,usersFullName: u.user_name + ' - ' + u.user_username
		}
	}

	const users2 = {
		fetch: {
			all(){
				return users.fetch.byOrganization({})
			}
		}
	}

	const users = {
		fetch: {

			all: function() {
				return users.fetch.byOrganization({})
					.then( xs => xs.map(APIUserToUser) )
			}

			,byOrganization({ organization_id }){
				return LIST(['GET', ['/users']])
					.then(
						list => list
							.filter(
								u => u.user_username
									.indexOf( 'Scheduling Worker Task' ) == -1
							)
					)
			}
		}
		,get: {
			by_search({ without_group_id, search, limit }){
				return LIST(
					['GET'
					,[
						'/users/search/', search
						, [['limit', limit]]
							.concat(
								without_group_id != null
								? [['without_group_id', without_group_id]]
								: []
							)
					]]
				)
			}
		}

		,patch: {
			permissions(user_id, role_id, role_permissions, user_permissions){
				const endpoint =
					['PATCH'
					, [ '/users/'
						, user_id
						, '/role_id/'
						, role_id
						, '/role_permissions/'
						, role_permissions
						, '/user_permissions/'
						, user_permissions
					]
					]

				const [method, url] = endpoint

				const r =
					request.request(
						baseURL
						,baseHeaders()
						,method
						,url.join('')
					)
					.then( r => r.list )

				return r.then(
					list => list.map(APIUserToUser)
				)

			}
			,password: function(auth_token, user_id, user_password) {

				const r =
					request.request(
						baseURL
						,{}
						,'PATCH'
						,['/users/',user_id,'/user_password'].join('')
						,{ user_password, auth_token }
					)

				return r.then(
					APIUserToUser
				)
			}
			,verification_token ( user_verification_token, user_id ) {
				return request.request(
					baseURL
					,baseHeaders()
					,'PATCH'
					,[''
					, 'users'
					, user_id
					, 'user_verification_token'
					, user_verification_token
					]
					.join('/')
				)
				.then(
					APIUserToUser
				)
			}
		}
		,remove: {
			one: function(id) {
				return DELETE(['DELETE', ['/users/', id]])
			}
		}
		,create: {
			signup ({
				user_email
				,user_username
				,user_name
				,user_password
			}) {

				const r =
					request.request(
						baseURL
						,baseHeaders()
						,'POST'
						,'/users'
						,{ user_email
						, user_username
						, user_name
						, user_password
						}
					)

				return r.then(APIUserToUser)
			}
		}
	}

	function APICrewsDisciplineRatesToCrewsDiscipineRates(
		c
	) {
		return {
			crew_id:
				m.prop( c.crew_id || null)
			,crews_discipline_rates_feedback_rate:
				m.prop( c.crews_discipline_rates_feedback_rate )
			,organizations_disciplines_id:
				m.prop( c.organizations_disciplines_id )
			,crews_discipline_rates_id:
				m.prop( c.crews_discipline_rates_id || null )
			,crews_discipline_rates_name:
				m.prop( c.crews_discipline_rates_name )
			,crews_discipline_rates_rate:
				m.prop( c.crews_discipline_rates_rate )
			,crews_discipline_rates_uom:
				m.prop( c.crews_discipline_rates_uom )
			,crews_discipline_rates_description:
				m.prop( c.crews_discipline_rates_description )
			,contractrecognitionid: c.contractrecognitionid
			,contractrecognitionname: c.contractrecognitionname
			,contractrecognitiondescription: c.contractrecognitiondescription
			,createcontracts: m.prop(false)
			,organizations_disciplines_nominal_expenditure_rate: m.prop( c.organizations_disciplines_nominal_expenditure_rate )
		}
	}

	const APICrewsToCrews = (contractor_name) =>
		function (c) {
			return {
				teamresourcename: c.teamresourcename
				,contractor_id: m.prop(c.contractor_id || null)
				,crew_common: m.prop(c.crew_common)
				,crew_excess: m.prop(c.crew_excess)
				,crew_id: m.prop(c.crew_id || null)
				,crew_invalid: m.prop(c.crew_invalid)
				,crew_name: m.prop(c.crew_name)
				,crew_rating: m.prop(c.crew_rating)
				,crew_utilized: m.prop(c.crew_utilized)
				,crew_work_completed: m.prop(c.crew_work_completed)
				,user_id: m.prop(c.user_id || null)
				,ParentRecognition: contractor_name + " - " + c.crew_name
				,CommonRecognition: c.crew_id || null
				,ParentRecognitionID: c.contractor_id || null
				,crews_discipline_rates: c.crews_discipline_rates.map(
					APICrewsDisciplineRatesToCrewsDiscipineRates
				)
				,contractrecognitionid:
					c.contractrecognitionid
				,contractrecognitionname:
					c.contractrecognitionname
				,contractrecognitiondescription:
					c.contractrecognitiondescription
			}
		}


	function APIContractorToContractor(c) {
		return R.merge(c,{
			contractor_comfortable_work_zone: m.prop(
				c.contractor_comfortable_work_zone
			)
			,contractor_common: m.prop(c.contractor_common)
			,contractor_first_onsite_start_date: m.prop(
				isoToTimeStampUnsafe(
					c.contractor_first_onsite_start_date
				)
			)
			,contractor_id: m.prop(c.contractor_id || null)
			,contractor_mixed_crews: m.prop(c.contractor_mixed_crews)
			,contractor_name: m.prop(c.contractor_name)
			,contractor_quality_rating: m.prop(c.contractor_quality_rating)
			,contractor_total_rating: m.prop(c.contractor_total_rating)
			,contractor_utilized: m.prop(c.contractor_utilized)
			,contractor_yard: m.prop(c.contractor_yard)
			,user_id: m.prop( c.user_id || null)
			,ParentRecognition: c.contractor_name
			,CommonRecognition: c.contractor_id || null
			,crews: c.crews.map( APICrewsToCrews(c.contractor_name) )
			,organization_id: m.prop(c.organization_id || null)
			,contractrecognitionid: c.contractrecognitionid
			,contractrecognitionname: c.contractrecognitionname
			,contractrecognitiondescription: c.contractrecognitiondescription
		})
	}

	function ContractorToAPIContractor(c) {
		return R.merge( c, {
			contractor_comfortable_work_zone:
				c.contractor_comfortable_work_zone()
			,contractor_common:
				c.contractor_common()
			,contractor_first_onsite_start_date:

				timestampToIsoSafe(
					c.contractor_first_onsite_start_date()
					|| new Date().getTime()
				)
			,contractor_mixed_crews:
				c.contractor_mixed_crews()
			,contractor_name:
				c.contractor_name()
			,contractor_quality_rating:
				c.contractor_quality_rating()
			,contractor_total_rating:
				c.contractor_total_rating()
			,contractor_utilized:
				c.contractor_utilized()
			,contractor_yard:
				c.contractor_yard()
			,crews:
				c.crews
				.map(
					c => ({
						teamresourcename: c.teamresourcename
						,contractor_id: c.contractor_id()
						,crew_common: c.crew_common()
						,crew_excess: c.crew_excess()
						,crew_id: c.crew_id()
						,user_id: c.user_id()
						,crew_invalid: c.crew_invalid()
						,crew_name: c.crew_name()
						,crew_rating: c.crew_rating()
						,crew_utilized: c.crew_utilized()
						,crew_work_completed: c.crew_work_completed()
						,crews_discipline_rates: c.crews_discipline_rates
							.map(
								c => ({
									crew_id:
										c.crew_id()
									,organizations_disciplines_id:
										c.organizations_disciplines_id()
									,crews_discipline_rates_feedback_rate:
										c.crews_discipline_rates_feedback_rate()
									,crews_discipline_rates_id:
										c.crews_discipline_rates_id()
									,crews_discipline_rates_name:
										c.crews_discipline_rates_name()
									,crews_discipline_rates_rate:
										c.crews_discipline_rates_rate()
									,crews_discipline_rates_uom:
										c.crews_discipline_rates_uom()
									,crews_discipline_rates_description:
										c.crews_discipline_rates_description()
									,organizations_disciplines_nominal_expenditure_rate:
										c.organizations_disciplines_nominal_expenditure_rate() || 0
									,createcontracts: c.createcontracts
								})
							)
					})
				)
			, organization_id: c.organization_id() || undefined
			, contractor_id: c.contractor_id() || undefined
			, user_id: c.user_id() || undefined
			, contract_id: c.contract_id
		})
	}

	const contractors = {
		fetch: {
			all: function({props, depth, contractor_id, organizations_disciplines, exclusive}){

				const t = request.MetadataTracker()

				const params =
					[
						['contractor_id', contractor_id ]
						,['props', props]
						,['depth', depth]
						,['organizations_disciplines', organizations_disciplines]
						,['exclusive', exclusive]
					]

				return LIST(['GET', ['/contractors/', contractor_id, params]])
					.then( list => list.map(APIContractorToContractor) )
					.then( t.addMetaData )
			}
			,workList: function({crew_id, contractor_id, schedule_id, schedule_versions_id}){


				const params = [
					['crew_id', crew_id ? crew_id() : null ]
					,['contractor_id', contractor_id ? contractor_id() : null ]
					,['schedule_id', schedule_id ]
					,['schedule_versions_id', schedule_versions_id]
				]
				.filter((a) => a[1])

				return LIST(['GET', [
					'/contractors/workList/'
					+ (
						crew_id
						? 'crew_id'
						: 'contractor_id'
					)
					+ '/', params
					]
				])
			}
		}
		,patch: {
			one: function(contractor) {

				const t = request.MetadataTracker()


				return PATCH_MANY(
					['PATCH', ['/contractors']]
					,[[t.removeMetaData(contractor)]
					, ContractorToAPIContractor
					, APIContractorToContractor
					]
				)
				.then(t.addMetaData)
			}
		}
		,remove: {
			one: function(contractor_id){
				const t = request.MetadataTracker()
				return DELETE(['DELETE', ['/contractors/', contractor_id]])
					.then( t.addMetaData )
			}
		}
	}

	function APIToolToTool(t){
		return {
			tools_id: m.prop(t.tools_id || null)
			,tools_name: m.prop(t.tools_name)
			,tools_condition: m.prop(t.tools_condition)
			,tools_location: m.prop(t.tools_location)
			,tools_description: m.prop(t.tools_description)
			,tools_ownership: m.prop(t.tools_ownership)
			,tools_type: m.prop(t.tools_type)
			,teamresourcename: t.teamresourcename
			,crew_id: m.prop(t.crew_id)
			,assigneduser: t.assigneduser
			,contractrecognitionid: t.contractrecognitionid
			,contractrecognitionname: t.contractrecognitionname
			,contractrecognitionnametype: t.contractrecognitionnametype
			,contractrecognitiondescription: t.contractrecognitiondescription
			,organization_id: m.prop( t.organization_id  || null)
		}
	}

	function ToolToAPITool(t){

		return {
			tools_name: exposer( t.tools_name )
			,tools_condition: exposer( t.tools_condition )
			,tools_location: exposer( t.tools_location )
			,tools_description: exposer( t.tools_description )
			,tools_ownership: exposer( t.tools_ownership )
			,tools_type: exposer( t.tools_type )
			,teamresourcename: exposer( t.teamresourcename )
			,crew_id: exposer( t.crew_id )
			,organization_id: exposer( t.organization_id ) || undefined
			,tools_id: exposer( t.tools_id ) || undefined
			,location:
				t.location
				? {
					latitude: t.location.latitude
					, longitude: t.location.longitude
				}
				: null
		}
	}

	const tools = {
		fetch: {
			all: function({props, depth}) {

				const params = [
					['props', props]
					,['depth', depth]
				]

				return LIST(['GET', ['/tools', params]])
					.then(
						list => list.map(APIToolToTool)
					)
			}
		}
		,patch: {
			many: function( tools) {
				return PATCH_MANY(
					['PATCH', ['/tools']]
					, [
						tools
						,ToolToAPITool
						,APIToolToTool
					]
				)
			}
		}
		,remove: function( id ){
			return DELETE(['DELETE', ['/tools/', id]])
		}
		,presets: {
			remove: function( id ){
				return DELETE(['DELETE', ['/tools_presets/', id]])
			}
			,patch: function(tps) {
				return PATCH_MANY(
					['PATCH', ['/tools_presets']]
					, [
						tps
						,I
						,I
					]
				)
			}
			,fetch: function({props, depth, perorganizatond, suggested, users}) {

				const params = [
					['props', props]
					,['depth', depth]
					,['perorganizatond', perorganizatond]
					,['suggested', suggested]
					,['users', users]
				]
				.filter((ar) => ar[0] && ar[1])

				return LIST(['GET', ['/tools_presets', params]])
					.then( I )
			}
		}
	}

	const geographies = {
		fetch: {
			all: function() {
				return LIST(['GET', ['/geographies']])
			}
		}
		,patch: {
			one: function( geography ){
				// todo this we can just dispatch to geographies.many( [g] )
				// I couldn't do it at time of writing because of:
				// https://github.com/Microsoft/TypeScript/issues/10641
				return PATCH_MANY(
					['PATCH', ['/geographies']]
					,[
						[geography], g => g, g => g
					]
				)
			}
			,many: function( geographies ) {
				return PATCH_MANY (
					['PATCH', ['/geographies']]
					,[
						geographies
						,g => g
						,g => g
					]
				)
			}
		}
	}

	const suburbs = {
		fetch: {
			all: function(){
				return LIST(['GET', ['/suburbs']])
			}
			,byTextInput: function(text){
				const query = [['query', text], ['max', 15]]
				const resource = ['/suburbs', query]

				return LIST(
					['GET', resource]
				)
			}
		}
		,search(q){
			return request.request(
				baseURL
				, baseHeaders()
				, 'GET'
				, `/suburbs/search`
				, { q }
			)
		}
		,here: {
			async patch(data){
				const out = await request.request(
					baseURL
					, baseHeaders()
					, 'PATCH'
					, `/suburbs/here`
					, data
				)
				return out
			}
		}
	}



	const crews = {
		remove: {
			one: function(crew_id){
				return DELETE(['DELETE', ['/crews/', crew_id]])
			}
		}
	}

	const crews_discipline_rates = {
		remove: {
			one: function(crews_discipline_rates_id){
				return DELETE(
					['DELETE'
					, ['/crews_discipline_rates/', crews_discipline_rates_id]
					]
				)
			}
		}
		,patch: {
			many: function(crews_discipline_rates) {
				const t = request.MetadataTracker()
				return PATCH_MANY(
					['PATCH', ['/crews_discipline_rates']]
					,[crews_discipline_rates
					, I
					, I
					]
				)
				.then(t.addMetaData)
			}
		}
	}

	function APIInterruptionToInterruption (
		i
	) {
		return {
			interruptions_weather:
				m.prop(i.interruptions_weather)
			,interruptions_start_date:
				m.prop(
					isoToTimeStampSafe( i.interruptions_start_date )
				)
			,interruptions_end_date:
				m.prop(
					isoToTimeStampSafe( i.interruptions_end_date )
				)
			,interruptions_name:
				m.prop(i.interruptions_name)
			,interruptions_repetition_mode:
				m.prop(i.interruptions_repetition_mode)
			,interruptions_duration:
				m.prop(i.interruptions_duration)
			,interruptions_id:
				m.prop(i.interruptions_id)
			,interruptions_affected:
				i.interruptions_affected.map(
					APIInterruptionsAffectedToInterruptionsAffected
				)
		}
	}

	function APIInterruptionsAffectedToInterruptionsAffected (
		i
	) {
		return Object.assign({
			interruptions_affected_id:
				m.prop(i.interruptions_affected_id || null)
			,interruptions_id:
				m.prop(i.interruptions_id || null)
		},
			typeof i.interruptions_affected_entity_id != 'undefined'
				? { interruptions_affected_entity_id:
						m.prop(i.interruptions_affected_entity_id)
				}
				: {}
			,typeof i.interruptions_affected_entity_name != 'undefined'
				? { interruptions_affected_entity_name:
						m.prop(i.interruptions_affected_entity_name)
				}
				: {}
		)
	}

	function InterruptionToAPIInterruption (
		i
	) {
		return {
			interruptions_weather:
				i.interruptions_weather()
			,interruptions_start_date:
				timestampToIsoSafe( i.interruptions_start_date() )
			,interruptions_end_date:
				timestampToIsoSafe( i.interruptions_end_date() )
			,interruptions_name:
				i.interruptions_name()
			,interruptions_repetition_mode:
				i.interruptions_repetition_mode()
			,interruptions_duration:
				i.interruptions_duration()
			,interruptions_affected:
				i.interruptions_affected.map(
					InterruptionsAffectedToAPIInterruptionsAffected
				)
			,interruptions_id: i.interruptions_id() || undefined
		}
	}

	function InterruptionsAffectedToAPIInterruptionsAffected (
		i
	) {
		return {
			interruptions_affected_id:
				i.interruptions_affected_id()
			,interruptions_id:
				i.interruptions_id()
			,interruptions_affected_entity_id:
				i.interruptions_affected_entity_id
				&& i.interruptions_affected_entity_id()
			,interruptions_affected_entity_name:
				i.interruptions_affected_entity_name
				&& i.interruptions_affected_entity_name()
		}
	}

	const interruptions = {
		fetch: {
			all: function({schedule_id}) {
				const params = [
					['schedule_id', schedule_id]
				]

				return LIST(['GET', ['/interruptions', params]])
					.then(
						list => list.map(APIInterruptionToInterruption)
					)
			}
		}
		,patch: {
			many: function(interruptions, schedule_id) {
				return PATCH_MANY(
					['PATCH', ['/interruptions/schedule_id/' + schedule_id]]
					,[ interruptions
					, InterruptionToAPIInterruption
					, APIInterruptionToInterruption
					]
				)
			}
		}
		,remove: {
			one: function(interruption_id){
				return DELETE(
					['DELETE', ['/interruptions/',interruption_id]]
				)
			}
		}
	}

	const interruptionsAffected = {
		remove: {
			one: function(interruptions_affected_id){
				return DELETE(
					['DELETE'
					, ['/interruptions_affected/'
					,interruptions_affected_id]
					]
				)
			}
		}
	}


	function APIAllocationsVarToAllocationsVar(
		a
	) {
		return {
			allocation_id:
				m.prop(a.allocation_id)
			,project_extras_id:
				m.prop(a.project_extras_id)
			,allocations_vars_amount:
				m.prop(a.allocations_vars_amount)
			,allocations_vars_clause:
				m.prop(a.allocations_vars_clause)
			,allocations_vars_description:
				m.prop(a.allocations_vars_description)
			,allocations_vars_escalated:
				m.prop(a.allocations_vars_escalated)
			,allocations_vars_id:
				m.prop(a.allocations_vars_id)
			,allocations_vars_approved:
				m.prop(a.allocations_vars_approved)
			,user_id:
				m.prop(a.user_id)
		}
	}

	function AllocationsVarToAPIAllocationVar(
		a
	) {
		return {
			allocation_id: a.allocation_id()
			,allocations_vars_id: a.allocations_vars_id()
			,project_extras_id: a.project_extras_id()
			,allocations_vars_amount: a.allocations_vars_amount()
			,allocations_vars_clause: a.allocations_vars_clause()
			,allocations_vars_description: a.allocations_vars_description()
			,allocations_vars_escalated: a.allocations_vars_escalated()
			,allocations_vars_approved: a.allocations_vars_approved()
			,user_id: a.user_id()
		}
	}

	function APIAllocationsNODToAllocationsNOD(
		a
	) {
		return {
			allocation_id:
				m.prop(a.allocation_id)
			,allocations_nods_id:
				m.prop(a.allocations_nods_id)
			,project_extras_id:
				m.prop(a.project_extras_id)
			,allocations_nods_clause:
				m.prop(a.allocations_nods_clause)
			,allocations_nods_description:
				m.prop(a.allocations_nods_description)
			,allocations_nods_escalated:
				m.prop(a.allocations_nods_escalated)
			,allocations_nods_approved:
				m.prop(a.allocations_nods_approved)
			,user_id:
				m.prop(a.user_id)
		}
	}

	function AllocationsNODToAPIAllocationsNOD(
		a
	) {
		return {
			allocation_id: a.allocation_id()
			,allocations_nods_id: a.allocations_nods_id()
			,project_extras_id: a.project_extras_id()
			,allocations_nods_clause: a.allocations_nods_clause()
			,allocations_nods_description: a.allocations_nods_description()
			,allocations_nods_escalated: a.allocations_nods_escalated()
			,allocations_nods_approved: a.allocations_nods_approved()
			,user_id: a.user_id()
		}
	}

	function APIAllocationsEOTtoAllocationsEOT(
		a
	) {
		return {
			allocation_id:
				m.prop(a.allocation_id)
			,project_extras_id:
				m.prop(a.project_extras_id)
			,allocations_eots_clause:
				m.prop(a.allocations_eots_clause)
			,allocations_eots_description:
				m.prop(a.allocations_eots_description)
			,allocations_eots_escalated:
				m.prop(a.allocations_eots_escalated)
			,allocations_eots_id:
				m.prop(a.allocations_eots_id)
			,allocations_eots_approved:
				m.prop(a.allocations_eots_approved)
			,user_id:
				m.prop(a.user_id)
		}
	}

	function AllocationsEOTtoAPIAllocationsEOT(
		a
	) {
		return {
			allocation_id:a.allocation_id()
			,project_extras_id: a.project_extras_id()
			,allocations_eots_clause: a.allocations_eots_clause()
			,allocations_eots_description: a.allocations_eots_description()
			,allocations_eots_escalated: a.allocations_eots_escalated()
			,allocations_eots_id: a.allocations_eots_id()
			,allocations_eots_approved: a.allocations_eots_approved()
			,user_id: a.user_id()
		}
	}


	function APIACCToACC(
		a
	) {
		return {
			allocations_contractors_crews_crew_common:
				m.prop(a.allocations_contractors_crews_crew_common)
			,allocations_contractors_crews_crew_rating:
				m.prop(a.allocations_contractors_crews_crew_rating)
			,allocations_contractors_crews_excess:
				m.prop(a.allocations_contractors_crews_excess)
			,allocations_contractors_crews_id:
				m.prop(a.allocations_contractors_crews_id)
			,allocations_contractors_crews_invalid:
				m.prop(a.allocations_contractors_crews_invalid)
			,allocations_contractors_crews_name:
				m.prop(a.allocations_contractors_crews_name)
			,allocations_contractors_crews_contract_multiplier:
				m.prop(a.allocations_contractors_crews_contract_multiplier)
			,allocations_contractors_crews_utilized:
				m.prop(a.allocations_contractors_crews_utilized)
			,allocations_contractors_crews_work_completed:
				m.prop(a.allocations_contractors_crews_work_completed)
			,allocations_contractors_crews_work_forecasted:
				m.prop(a.allocations_contractors_crews_work_forecasted)
			,allocations_contractors_crews_work_application:
				m.prop(a.allocations_contractors_crews_work_application)
			,allocations_contractors_id:
				m.prop(a.allocations_contractors_id)
			,crew_id:
				m.prop(a.crew_id)
			,user_id:
				m.prop(a.user_id)
			,crews_discipline_rates:
				a.crews_discipline_rates
			,teamratemultiplier:
				a.teamratemultiplier
		}
	}

	function ACCToAPIACC(
		a
	) {
		return {
			allocations_contractors_crews_crew_common:
				a.allocations_contractors_crews_crew_common()
			,allocations_contractors_crews_crew_rating:
				a.allocations_contractors_crews_crew_rating()
			,allocations_contractors_crews_excess:
				a.allocations_contractors_crews_excess()
			,allocations_contractors_crews_id:
				a.allocations_contractors_crews_id()
			,allocations_contractors_crews_invalid:
				a.allocations_contractors_crews_invalid()
			,allocations_contractors_crews_name:
				a.allocations_contractors_crews_name()
			,allocations_contractors_crews_contract_multiplier:
				a.allocations_contractors_crews_contract_multiplier()
			,allocations_contractors_crews_utilized:
				a.allocations_contractors_crews_utilized()
			,allocations_contractors_crews_work_completed:
				a.allocations_contractors_crews_work_completed()
			,allocations_contractors_crews_work_forecasted:
				a.allocations_contractors_crews_work_forecasted()
			,allocations_contractors_crews_work_application:
				a.allocations_contractors_crews_work_application()
			,allocations_contractors_id:
				a.allocations_contractors_id()
			,crew_id:
				a.crew_id()
			,user_id:
				a.user_id()
		}
	}

	function APIAllocationsContractorToAllocationsContractor(
		a
	) {
		return {
			allocation_id:
				m.prop(a.allocation_id)
			,allocations_contractors_common:
				m.prop(a.allocations_contractors_common)
			,allocations_contractors_id:
				m.prop(a.allocations_contractors_id)
			,allocations_contractors_mixed_crews:
				m.prop(a.allocations_contractors_mixed_crews)
			,allocations_contractors_name:
				m.prop(a.allocations_contractors_name)
			,allocations_contractors_quality_rating:
				m.prop(a.allocations_contractors_quality_rating)
			,allocations_contractors_total_rating:
				m.prop(a.allocations_contractors_total_rating)
			,allocations_contractors_utilized:
				m.prop(a.allocations_contractors_utilized)
			,contractor_id:
				m.prop(a.contractor_id)
			,user_id:
				m.prop(a.user_id)
			,allocations_contractors_crews:
				a.allocations_contractors_crews
					.map(APIACCToACC)
		}
	}

	function AllocationsContractorToAPIAllocationsContractor(
		a
	) {
		return {
			allocation_id:
				a.allocation_id()
			,allocations_contractors_common:
				a.allocations_contractors_common()
			,allocations_contractors_id:
				a.allocations_contractors_id()
			,allocations_contractors_mixed_crews:
				a.allocations_contractors_mixed_crews()
			,allocations_contractors_name:
				a.allocations_contractors_name()
			,allocations_contractors_quality_rating:
				a.allocations_contractors_quality_rating()
			,allocations_contractors_total_rating:
				a.allocations_contractors_total_rating()
			,allocations_contractors_utilized:
				a.allocations_contractors_utilized()
			,contractor_id:
				a.contractor_id()
			,user_id:
				a.user_id()
			,allocations_contractors_crews:
				a.allocations_contractors_crews
					.map(ACCToAPIACC)
		}
	}

	function APIAllocationToAllocation( a ){
		return {
			organization_id:
				m.prop(a.organization_id)
			,organizations_disciplines_id:
				m.prop(a.organizations_disciplines_id)
			,project_id:
				m.prop(a.project_id)
			,discipline_id:
				m.prop(a.discipline_id)
			,allocation_id:
				m.prop(a.allocation_id)
			,project_name: a.project_name
			,allocations_work_name:
				m.prop(a.allocations_work_name)
			,allocations_crew_required:
				m.prop(a.allocations_crew_required)
			,allocations_project_harshness:
				m.prop(a.allocations_project_harshness)
			,allocations_custom_status:
				m.prop(a.allocations_custom_status)
			,allocations_allocation_complete:
				m.prop(a.allocations_allocation_complete)
			,allocations_allocation_forecast:
				m.prop(a.allocations_allocation_forecast)
			,allocations_allocation_date:
				m.prop( isoToTimeStampSafe( a.allocations_allocation_date ) )
			,schedule_id:
				m.prop(a.schedule_id)
			,allocations_version_key:
				m.prop(a.allocations_version_key)
			,schedule_version_id:
				m.prop(a.schedule_version_id)
			,allocations_completion_comments:
				m.prop(a.allocations_completion_comments)
			,allocations_bad_day:
				m.prop(a.allocations_bad_day)
			,allocations_day_status:
				m.prop(a.allocations_day_status)
			,allocations_weather_status:
				m.prop(a.allocations_weather_status)
			,allocations_vars: a.allocations_vars
				.map(APIAllocationsVarToAllocationsVar)
			,allocations_nods: a.allocations_nods
				.map(APIAllocationsNODToAllocationsNOD)
			,allocations_eots: a.allocations_eots
				.map(APIAllocationsEOTtoAllocationsEOT)
			,allocations_contractors: a.allocations_contractors
				.map(APIAllocationsContractorToAllocationsContractor)
			,allocations_transposes: []
			,discplineNameDescription:
				a.allocations_work_name
				+ ' - '
				+ a.discipline_description
			,discipline_description: a.discipline_description
			,disciplineratemultiplier: a.disciplineratemultiplier
			// ,allocations_transposes: a.allocations_transposes
			// 	.map(APIAllocationsTransposeToAllocationsTranspose)
		}
	}

	function AllocationToAPIAllocation( a ){
		return {
			organization_id:
				a.organization_id()
			,project_id:
				a.project_id()
			,discipline_id:
				a.discipline_id()
			,allocation_id:
				a.allocation_id()
			,project_name:
				a.project_name
			,allocations_work_name:
				a.allocations_work_name()
			,allocations_crew_required:
				a.allocations_crew_required()
			,allocations_project_harshness:
				a.allocations_project_harshness()
			,allocations_custom_status:
				a.allocations_custom_status()
			,allocations_allocation_complete:
				a.allocations_allocation_complete()
			,allocations_allocation_forecast:
				a.allocations_allocation_forecast()
			,allocations_allocation_date:
				 timestampToIsoSafe( a.allocations_allocation_date() )
			,schedule_id:
				a.schedule_id()
			,allocations_version_key:
				a.allocations_version_key()
			,schedule_version_id:
				a.schedule_version_id()
			,allocations_completion_comments:
				a.allocations_completion_comments()
			,allocations_bad_day:
				a.allocations_bad_day()
			,allocations_day_status:
				a.allocations_day_status()
			,allocations_weather_status:
				a.allocations_weather_status()
			,allocations_vars: a.allocations_vars
				.map(AllocationsVarToAPIAllocationVar)
			,allocations_nods: a.allocations_nods
				.map(AllocationsNODToAPIAllocationsNOD)
			,allocations_eots: a.allocations_eots
				.map(AllocationsEOTtoAPIAllocationsEOT)
			,allocations_contractors: a.allocations_contractors
				.map(AllocationsContractorToAPIAllocationsContractor)
			,allocations_transposes: []
			// ,allocations_transposes: a.allocations_transposes
			// 	.map(AllocationsTransposeToAPIAllocationsTranspose)
		}
	}

	const allocations = {
		fetch: {
			disciplines(allocation_id){
				const url =
					[ '/allocations/', allocation_id, '/disciplines' ]
				return GET(
					['GET', url]
				)
			}
			,byAllocationScheduleVersionKey: {
				shallow: function({schedule_version_id, breadth, startDate, endDate, contractor_id}){
					const params =
						[ ['schedule_version_id', schedule_version_id ]
						, ['depth', 1]
						, ['breadth', breadth]
						, ['startDate', startDate]
						, ['endDate', endDate]
						, ['contractor_id', contractor_id]
						]

					const url =
						[ '/allocations', params]

					return LIST(
						['GET', url]
					)
				}
				,shallowUniq: function({schedule_version_id, uniqBy, breadth, pcomplete, startDate, endDate}){
					const params =
						[ ['schedule_version_id', schedule_version_id ]
						, ['depth', 1]
						, ['uniqBy', uniqBy]
						, ['breadth', breadth]
						, ['pcomplete', pcomplete]
						, ['startDate', startDate]
						, ['endDate', endDate]
						]

					const url =
						[
							'GET'
							,[
								'/allocations'
								, params
							]
						]

					return LIST(url)
						.then((list) =>
							list.map(
								R.pipe(
									R.merge(
										{
											allocations_vars: []
											,allocations_nods: []
											,allocations_eots: []
											,allocations_contractors: []
											,allocations_transposes: []
										}
									)
									,APIAllocationToAllocation
								)
							)
						)
				}
				,complete: function({schedule_version_id}){
					const params =
						[ ['schedule_version_id', schedule_version_id ]
						]

					const url =
						[ '/allocations', params]

					return LIST(
						['GET', url]
					)

				}
			}

			,byProjectAndScheduleVersion: function({
				project_id
				,schedule_version_id
				,breadth
				,startDate
				,endDate
				,contractor_id
			}) {


				const url =
					[ 'GET'
						,[ '/allocations'
						, 	[ ['project_id', project_id]
							, ['schedule_version_id', schedule_version_id]
							, ['breadth', breadth]
							, ['startDate', startDate]
							, ['endDate', endDate]
							, ['contractor_id', contractor_id]
							]
						]
					]

				return LIST(url)
					.then(
						list => list.map(APIAllocationToAllocation)
					)
			}
			,byScheduleVersion: function({schedule_version_id, startDate, endDate, project_id, contractor_id}){

				const url =
					[ 'GET'
						,[ '/allocations'
						, 	[ ['schedule_version_id', schedule_version_id]
							, ['startDate', startDate]
							, ['endDate', endDate]
							, ['contractor_id', contractor_id]
							, ['project_id', project_id]
							]
						]
					]

				return LIST(url)
					.then(
						list => list.map(APIAllocationToAllocation)
					)
			}
		}
		,patch: function(list, schedule_version_id, project_id, disableList ) {

			const endpoint =
				['PATCH', ['/allocations']]


			const [method, url] = endpoint

			return request.request(
				baseURL
				,baseHeaders()
				, method
				, url.join('')
				,{ list: list.map(AllocationToAPIAllocation)
				, schedule_version_id
				, project_id
				, disableList
				, source: {name: ''}
				}
			)
				.then( r => r.list.map(APIAllocationToAllocation) )
		}
	}

	const allocations_eots = {
		remove: {
			one: function(allocations_eots_id){
				return DELETE(
					['DELETE', ['/allocations_eots/',allocations_eots_id]]
				)
			}
		}
	}

	const allocations_nods = {
		remove: {
			one: function(allocations_nods_id){
				return DELETE([
					'DELETE', ['/allocations_nods/',allocations_nods_id]
				])
			}
		}
	}

	const allocations_vars = {
		remove: {
			one: function(allocations_vars_id){
				return DELETE([
					'DELETE', ['/allocations_vars/',allocations_vars_id]
				])
			}
		}
	}

	const project_extras = {
		remove: {
			one: function(project_extras_id){
				return DELETE([
					'DELETE', ['/project_extras/',project_extras_id]
				])
			}
			,many: function(tasklist){
				const taskDeletions = tasklist.join('--')
				return DELETE(['DELETE', ['/project_extras/tasklist/' + taskDeletions]])
			}
		}
		,patch: {
			many: function(project_extras) {

				const t = request.MetadataTracker()

				return PATCH_MANY(
					['PATCH', ['/project_extras']]
					,[
						project_extras
						, ProjectDetailsToAPIProjectDetails
						, APIProjectToProject
						, {name: ''}
					]
				)
				.then( t.addMetaData )
			}
		}
		,fetch: function({
			project_id
			,discipline_id
			,typesObj
			,uniqBy
			,pcomplete
			,shallowQuery
			,startDate
			,endDate
			,schedule_id
		}){
			const typeparams =
				[
					['completed', typesObj.completed ]
					, ['invalid', typesObj.invalid ]
					, ['verified', typesObj.verified ]
					, ['assigned', typesObj.assigned ]
					, ['uniqBy', uniqBy]
					, ['pcomplete', pcomplete]
					, ['shallowQuery', shallowQuery]
					, ['startDate', startDate]
					, ['endDate', endDate]
					, ['schedule_id', schedule_id]
				]

			const params =
				discipline_id
				? [['project_id', project_id]
				, ['discipline_id', discipline_id]]
				: project_id
				? [['project_id', project_id]]
				: []

			const allparams = params.length
				? typeparams.concat(params)
				: typeparams

			return LIST([
				'GET', ['/project_extras', allparams ]
			])
				.then(
					list => list.map(APIProjectDetailsToProjectDetails)
				)
		}
	}

	const disciplines = {
		remove: {
			one: function(discipline_id){
				return DELETE(['DELETE', ['/disciplines/',discipline_id]])
			}
		}
	}

	const disciplines_contractors = {
		remove: {
			one(
					disciplines_contractors_id
					, schedule_version_id
					, contractor_id
					, discipline_id
					, contractoruserid
					, crewuserid
			){
				return DELETE(
					[
						'DELETE'
						,[
							'/disciplines_contractors/'
							, disciplines_contractors_id || uuid.v4()
							,'/schedule_version/'
							, schedule_version_id
							,'/contractor/'
							, contractor_id
							,'/discipline/'
							, discipline_id
							,'/contractoruserid/'
							, contractoruserid || uuid.v4()
							,'/crewuserid/'
							, crewuserid || uuid.v4()
						]
					]
				)
			}
		}
	}

	const disciplines_contractors_crews = {
		remove: {
			one: function(
				disciplines_contractors_crews_id
				, schedule_version_id
				, crew_id
				, discipline_id
				, crewuserid
			){
				return DELETE(
					[
						'DELETE'
						, [
							'/disciplines_contractors_crews/'
							, disciplines_contractors_crews_id || uuid.v4()
							,'/schedule_version/'
							, schedule_version_id
							,'/crew/'
							, crew_id
							,'/discipline/'
							, discipline_id
							,'/crewuserid/'
							, crewuserid || uuid.v4()
						]
					]
				)
			}
		}
	}

	function WarehouseToAPIWarehouse(w) {
		return R.merge(w, {
			warehouse_delivery:
				w.warehouse_delivery()
			,warehouse_email:
				w.warehouse_email()
			,warehouse_location:
				w.warehouse_location()
			,warehouse_name:
				w.warehouse_name()
			,warehouse_operating_hours_public_holiday:
				w.warehouse_operating_hours_public_holiday()
			,warehouse_operating_hours_regular:
				w.warehouse_operating_hours_regular()
			,warehouse_operating_hours_weekend:
				w.warehouse_operating_hours_weekend()
			,warehouse_phone:
				w.warehouse_phone()
			,warehouse_items:
				w.warehouse_items
					.map(MaterialToAPIMaterial)
			,organization_id:
				w.organization_id() || undefined
			, warehouse_id:
				w.warehouse_id() || undefined
		})
	}

	function APIWarehouseToWarehouse (w){
		return R.merge(w, {
			warehouse_delivery:
				m.prop(w.warehouse_delivery)
			,warehouse_email:
				m.prop(w.warehouse_email)
			,warehouse_location:
				m.prop(w.warehouse_location)
			,warehouse_name:
				m.prop(w.warehouse_name)
			,warehouse_operating_hours_public_holiday:
				m.prop(w.warehouse_operating_hours_public_holiday)
			,warehouse_operating_hours_regular:
				m.prop(w.warehouse_operating_hours_regular)
			,warehouse_operating_hours_weekend:
				m.prop(w.warehouse_operating_hours_weekend)
			,warehouse_phone:
				m.prop(w.warehouse_phone)
			,warehouse_items:
				w.warehouse_items.map(APIMaterialToMaterial)
			,organization_id:
				m.prop(w.organization_id || null)
			,warehouse_id:
				m.prop(w.warehouse_id || null)
			,contractrecognitionid:
				w.contractrecognitionid
			,contractrecognitionname:
				w.contractrecognitionname
			,contractrecognitiondescription:
				w.contractrecognitiondescription
		})
	}

	function MaterialToAPIMaterial(o){
		return {
			distribution_amount:
				exposer(o.distribution_amount)
			,distribution_batch:
				exposer(o.distribution_batch)
			,distribution_container_id:
				exposer(o.distribution_container_id)
			,distribution_container_name:
				exposer(o.distribution_container_name)
			,distribution_entity_name:
				exposer(o.distribution_entity_name)
			,distribution_float:
				exposer(o.distribution_float)
			,distribution_material_description:
				exposer(o.distribution_material_description)
			,distribution_material_name:
				exposer(o.distribution_material_name)
			,distribution_ordered:
				exposer(o.distribution_ordered)
			,distribution_silo:
				exposer(o.distribution_silo)
			,distribution_stocked:
				exposer(o.distribution_stocked)
			,supplier_id:
				exposer(o.supplier_id) || ""
			,distribution_entity_id:
				exposer(o.distribution_entity_id) || undefined
			,distribution_id:
				exposer(o.distribution_id) || undefined
			,organization_id:
				exposer(o.organization_id) || undefined
			,supplier_items_id:
				exposer(o.supplier_items_id) || undefined
			,supplier_name:
				exposer(o.supplier_name) || undefined
			,supplier_items_cost:
				exposer(o.supplier_items_cost) || undefined
			,supplier_items_batch_cost:
				exposer(o.supplier_items_batch_cost) || undefined
			,autoorder: exposer(o.autoorder)
			,autoreceived: exposer(o.autoreceived)
			,transfers: o.transfers
			,combine: o.combine
		}
	}

	function APIMaterialToMaterial (o){
		return {
			distribution_amount:
				m.prop(o.distribution_amount)
			,distribution_batch:
				m.prop(o.distribution_batch)
			,distribution_container_id:
				m.prop(o.distribution_container_id || null)
			,distribution_container_name:
				m.prop(o.distribution_container_name)
			,distribution_entity_id:
				m.prop(o.distribution_entity_id || null)
			,distribution_entity_name:
				m.prop(o.distribution_entity_name)
			,distribution_float:
				m.prop(o.distribution_float)
			,distribution_id:
				m.prop(o.distribution_id || null)
			,distribution_material_description:
				m.prop(o.distribution_material_description)
			,distribution_material_name:
				m.prop(o.distribution_material_name)
			,distribution_ordered:
				m.prop(o.distribution_ordered)
			,distribution_silo:
				m.prop(o.distribution_silo)
			,distribution_stocked:
				m.prop(o.distribution_stocked)
			,organization_id:
				m.prop(o.organization_id || null)
			,supplier_id:
				m.prop(o.supplier_id || "")
			,supplier_items_id:
				m.prop(o.supplier_items_id || null)
			,order_eta: o.order_eta
			,order_name: o.order_name
			,order_received: o.order_received
			,requiredby: o.requiredby
			,project_start_date: o.project_start_date
			,discipline_start_date: o.discipline_start_date
			,project_id: o.project_id
			,warehouse_id: o.warehouse_id
			,discipline_id: o.discipline_id
			,fmgroupedbyitem: o.fmgroupedbyitem
			,currentqty: o.currentqty
			,requiredqty: o.requiredqty
			,wastediff: o.wastediff
		}
	}

	const warehouses = {
		fetch: {
			all: function({props, depth}) {
				const t = request.MetadataTracker()

				const params = [
					['props', props]
					,['depth', depth]
				]

				return LIST(['GET', ['/warehouses', params]])
					.then( list => list.map(APIWarehouseToWarehouse) )
					.then(t.addMetaData)
			}
		}
		,patch(warehouses) {

			const t = request.MetadataTracker()

			return PATCH_MANY(
				['PATCH', ['/warehouses']]
				,[ t.removeMetaData(warehouses)
				, WarehouseToAPIWarehouse
				, APIWarehouseToWarehouse
				]
			)
			.then( t.addMetaData )
		}
		,remove: {
			one: function(id){
				const t = request.MetadataTracker()

				return DELETE(['DELETE', ['/warehouses/', id]])
					.then( t.addMetaData )
			}
		}
	}

	function APISupplierItemToSupplierItem(
		o
	) {
		return R.merge(o, {
			supplier_id:
				m.prop(o.supplier_id || null)
			,supplier_items_supply_time:
				m.prop(o.supplier_items_supply_time)
			,supplier_items_available:
				m.prop(o.supplier_items_available)
			,supplier_items_batch:
				m.prop(o.supplier_items_batch)
			,supplier_items_batch_cost:
				m.prop(o.supplier_items_batch_cost)
			,supplier_items_cost:
				m.prop(o.supplier_items_cost)
			,supplier_items_description:
				m.prop(o.supplier_items_description)
			,supplier_items_id:
				m.prop(o.supplier_items_id || null)
			,supplier_items_minimum_order:
				m.prop(o.supplier_items_minimum_order)
			,supplier_items_name:
				m.prop(o.supplier_items_name)
			,supplier_items_uom:
				m.prop(o.supplier_items_uom)
		})
	}

	function APISupplierLocationToSupplierLocation(
		o
	) {
		return {
			supplier_id:
				m.prop(o.supplier_id || null)
			,supplier_locations_email:
				m.prop(o.supplier_locations_email)
			,supplier_locations_id:
				m.prop(o.supplier_locations_id || null)
			,supplier_locations_location:
				m.prop(o.supplier_locations_location)
			,supplier_locations_phone:
				m.prop(o.supplier_locations_phone)
			,supplier_locations_public_holiday_operating_hours:
				m.prop(o.supplier_locations_public_holiday_operating_hours)
			,supplier_locations_regular_operating_hours:
				m.prop(o.supplier_locations_regular_operating_hours)
			,supplier_locations_weekend_operating_hours:
				m.prop(o.supplier_locations_weekend_operating_hours)
		}
	}

	function APISupplierToSupplier(a){
		return {
			organization_id: m.prop(a.organization_id || null)
			,supplier_id: m.prop(a.supplier_id || null)
			,supplier_delivery: m.prop(a.supplier_delivery)
			,supplier_name: m.prop(a.supplier_name)
			,supplier_items: a.supplier_items
				.map(APISupplierItemToSupplierItem)
			,supplier_locations: a.supplier_locations
				.map(APISupplierLocationToSupplierLocation)
		}
	}


	function SupplierItemToAPISupplierItem(o){
		return {
			supplier_items_available: o.supplier_items_available()
			,supplier_items_supply_time: o.supplier_items_supply_time()
			,supplier_items_batch: o.supplier_items_batch()
			,supplier_items_batch_cost: o.supplier_items_batch_cost()
			,supplier_items_cost: o.supplier_items_cost()
			,supplier_items_description: o.supplier_items_description()
			,supplier_items_minimum_order: o.supplier_items_minimum_order()
			,supplier_items_name: o.supplier_items_name()
			,supplier_items_uom: o.supplier_items_uom()
			,supplier_id: o.supplier_id() || undefined
			,supplier_items_id: o.supplier_items_id() || undefined
		}
	}

	function SupplierLocationToAPISupplierLocation(o){
		return {
			supplier_locations_email:
				o.supplier_locations_email()
			,supplier_locations_location:
				o.supplier_locations_location()
			,supplier_locations_phone:
				o.supplier_locations_phone()
			,supplier_locations_public_holiday_operating_hours:
				o.supplier_locations_public_holiday_operating_hours()
			,supplier_locations_regular_operating_hours:
				o.supplier_locations_regular_operating_hours()
			,supplier_locations_weekend_operating_hours:
				o.supplier_locations_weekend_operating_hours()
			,supplier_id:
				o.supplier_id()
			,supplier_locations_id:
				o.supplier_locations_id()
		}
	}

	function SupplierToAPISupplier(o){
		return R.merge(o, {
			organization_id:
				o.organization_id()
				|| undefined
			, supplier_id:
				o.supplier_id()
				|| undefined
			,supplier_name:
				o.supplier_name()
			,supplier_delivery:
				o.supplier_delivery()
			,supplier_items:
				o.supplier_items.map(SupplierItemToAPISupplierItem)
			,supplier_locations:
				o.supplier_locations.map(SupplierLocationToAPISupplierLocation)
		})
	}

	const suppliers = {
		patch(list) {
			const t = request.MetadataTracker()

			return PATCH_MANY(
				['PATCH', ['/suppliers']]
				,[ t.removeMetaData(list)
				, SupplierToAPISupplier
				, APISupplierToSupplier
				]
			)
			.then( t.addMetaData )
		}
		,fetch: {
			all: function({props, depth}) {
				const t = request.MetadataTracker()

				const params = [
					['props', props]
					,['depth', depth]
				]

				return LIST(['GET', ['/suppliers', params]])
					.then( list => list.map( APISupplierToSupplier ))
					.then( t.addMetaData )
			}
		}
		,remove: {
			one: function(id){
				const t = request.MetadataTracker()
				return DELETE(['DELETE', ['/suppliers/', id]])
					.then( t.addMetaData )
			}
		}
	}

	const supplier_items = {
		remove: {
			one: function(id){
				const t = request.MetadataTracker()

				return DELETE(['DELETE', ['/suppliers/supplier_items/', id]])
					.then( t.addMetaData )
			}
		}
	}

	function PresetToAPIPreset ( o ) {

		return {
			presets_name:
				o.presets_name()
			,distribution_material_name:
				o.distribution_material_name()
			,distribution_material_description:
				o.distribution_material_description()
			,distribution_amount:
				o.distribution_amount()
			,distribution_batch:
				o.distribution_batch()
			,distribution_container_name:
				o.distribution_container_name()
			,distribution_container_id:
				o.distribution_container_id()
			,distribution_silo:
				o.distribution_silo()
			,distribution_ordered:
				o.distribution_ordered()
			,distribution_stocked:
				o.distribution_stocked()
			,distribution_float:
				o.distribution_float()
			,distribution_entity_name:
				o.distribution_entity_name()
			,supplier_id:
				o.supplier_id()
			,presets_id:
				o.presets_id()
				|| undefined
			,organization_id:
				o.organization_id()
				|| undefined
			,supplier_items_id:
				o.supplier_items_id()
				|| undefined
			,distribution_entity_id:
				o.distribution_entity_id()
				|| undefined
		}
	}

	function APIPresetToPreset (o){

		return {
			presets_id:
				m.prop(o.presets_id || null)
			,presets_name:
				m.prop(o.presets_name)
			,organization_id:
				m.prop(o.organization_id || null)
			,distribution_entity_id:
				m.prop(o.distribution_entity_id || null)
			,supplier_items_id:
				m.prop(o.supplier_items_id || null)
			,distribution_material_name:
				m.prop(o.distribution_material_name)
			,distribution_material_description:
				m.prop(o.distribution_material_description)
			,distribution_amount:
				m.prop(o.distribution_amount)
			,distribution_batch:
				m.prop(o.distribution_batch)
			,distribution_container_name:
				m.prop(o.distribution_container_name)
			,distribution_container_id:
				m.prop(o.distribution_container_id || null)
			,distribution_silo:
				m.prop(o.distribution_silo)
			,distribution_ordered:
				m.prop(o.distribution_ordered)
			,distribution_stocked:
				m.prop(o.distribution_stocked)
			,distribution_float:
				m.prop(o.distribution_float)
			,distribution_entity_name:
				m.prop(o.distribution_entity_name)
			,supplier_id:
				m.prop(o.supplier_id)
		}
	}

	const presets = {
		fetch: {
			all: function(){
				return LIST(
					['GET', ['/presets']]
				)
					.then(
						list => list.map(APIPresetToPreset)
					)
			}
		}
		,patch: {
			many: function(presets){
				return PATCH_MANY(
					['PATCH', ['/presets']]
					,[ presets
					, PresetToAPIPreset
					, APIPresetToPreset
					]
				)
			}
		}
		,remove: {
			one: function(id){
				return DELETE(['DELETE', ['/presets/', id]])
			}
		}
	}

	const materials = {
		fetch: {
			all: function({projects, warehouse_id, completedProjects}){

				const params =
					[['projects', projects ]
					,['warehouse_id', warehouse_id]
					,['completedProjects', completedProjects]]

				return LIST(
					['GET', ['/materials', params]]
				)
					.then(
						list => list.map(APIMaterialToMaterial)
					)
			}
			,suggested: function({projects, warehouse_id, tasks}){

				const params =
					[['projects', projects ]
					,['warehouse_id', warehouse_id]
					,['tasks', tasks]]

				return LIST(
					['GET', ['/materials/suggested', params]]
				)
					.then(list => list.map(R.identity))
			}
		}
		,patch: {
			many: function(materials){
				return PATCH_MANY(
					['PATCH', ['/materials']]
					,[ materials
					, MaterialToAPIMaterial
					, APIMaterialToMaterial
					]
				)
			}
		}
		,remove: {
			one: function(id){
				return DELETE(['DELETE', ['/materials/', id]])
			}
		}
	}

	const supplier_locations = {
		remove: {
			one: function(id){
				return DELETE([
					'DELETE', ['/suppliers/supplier_locations/', id]
				])
			}
		}
	}

	function OrderToAPIOrder ( o ) {

		return {
			order_name:
				o.order_name()
			,order_items_name:
				o.order_items_name()
			,order_items_description:
				o.order_items_description()
			,order_amount:
				o.order_amount()
			,order_notes:
				o.order_notes()
			,order_received:
				o.order_received()
			,order_items_cost:
				o.order_items_cost()
			,order_supplier_name:
				o.order_supplier_name()
			,order_stock_location:
				o.order_stock_location()
			,order_submission_date:
				isoToTimeStampSafe( o.order_submission_date() )
			,order_eta:
				isoToTimeStampUnsafe( o.order_eta() )
			,order_id: o.order_id() || undefined
			,organization_id: o.organization_id() || undefined
			,distribution_id: o.distribution_id() || undefined
			,supplier_id: o.supplier_id() || undefined
			,supplier_items_id: o.supplier_items_id() || undefined
		}
	}

	function APIOrderToOrder ( o ) {

		return {
			order_id:
				m.prop(o.order_id || null)
			,organization_id:
				m.prop(o.organization_id || null)
			,distribution_id:
				m.prop(o.distribution_id || null)
			,supplier_id:
				m.prop(o.supplier_id || null)
			,supplier_items_id:
				m.prop(o.supplier_items_id || null)
			,order_name:
				m.prop(o.order_name)
			,order_items_name:
				m.prop(o.order_items_name)
			,order_items_description:
				m.prop(o.order_items_description)
			,order_amount:
				m.prop(o.order_amount)
			,order_notes:
				m.prop(o.order_notes)
			,order_received:
				m.prop(o.order_received)
			,order_items_cost:
				m.prop(o.order_items_cost)
			,order_supplier_name:
				m.prop(o.order_supplier_name)
			,order_stock_location:
				m.prop(o.order_stock_location)
			,order_submission_date:
				m.prop( timestampToIsoSafe( o.order_submission_date ) )
			,order_eta:
				m.prop( timestampToIsoUnsafe( o.order_eta ))
			,filecount: o.filecount
		}
	}

	const orders = {
		fetch: {
			all: function({project_id, warehouse_id, completedProjects}) {

				const params =
					[['project_id', project_id ]
					,['warehouse_id', warehouse_id]
					,['completedProjects', completedProjects]]

				return LIST(
					['GET', ['/orders', params]]
				)
					.then(
						list => list.map(APIOrderToOrder)
					)
			}
		}
		,patch: {
			many: function(orders){
				return PATCH_MANY(
					['PATCH', ['/orders']]
					,[ orders
					, OrderToAPIOrder
					, APIOrderToOrder
					]
				)
			}
		}
		,remove: {
			one: function(id){
				return DELETE(['DELETE', ['/orders/', id]])
			}
		}
	}

	function ContainerToAPIContainer ( o ){

		return {
			container_name: o.container_name()
			,container_silo: o.container_silo()
			,organization_id: o.organization_id() || undefined
			,container_id: o.container_id() || undefined
		}
	}

	function APIContainerToContainer ( o ) {

		return {
			organization_id: m.prop(o.organization_id || null)
			,container_id: m.prop(o.container_id || null)
			,container_name: m.prop(o.container_name)
			,container_silo: m.prop(o.container_silo)
			,holdings: null
			,capacity: null
			,containerIsFull: null
		}
	}

	const containers = {
		fetch: {
			all: function() {
				return LIST(
					['GET', ['/containers']]
				)
					.then(
						list => list.map(APIContainerToContainer)
					)
			}
		}
		,patch: {
			many: function(containers) {
				return PATCH_MANY(
					['PATCH', ['/containers']]
					,[ containers
					, ContainerToAPIContainer
					, APIContainerToContainer
					]
				)
			}
		}
		,remove: {
			one: function(id){
				return DELETE(['DELETE', ['/containers/', id]])
			}
		}
	}

	function APIRoleToRole ( o ){

		return {
			role_name: m.prop(o.role_name)
			,role_description: m.prop(o.role_description)
			,role_permissions: m.prop(o.role_permissions)
			,role_id: m.prop(o.role_id)
		}
	}

	const groups2 = {
		fetch: {
			byOrganization({ organization_id }){
				return LIST(
					['GET', ['/groups/', organization_id]]
				)
			}
		}
		, update(group){
			return POST(
				['PATCH', ['/groups/', group.group_id]]
				, [group, I, I]
			)
		}
		, create(group){
			return POST(
				['POST', ['/groups']]
				, [group, I, I]
			)
		}
		,delete(group){
			return DELETE(
				['DELETE', ['/groups/', group.group_id]]
			)
		}
	}

	const roles = {
		fetch: {
			all() {
				return LIST(
					['GET', ['/roles']]
				)
					.then(
						list => list.map(APIRoleToRole)
					)
			}
			,byOrganization({ organization_id }){
				return LIST(
					['GET', ['/roles/', organization_id]]
				)
			}
		}
		,reqParser: I
		,resParser: I
		,update(role){
			return POST(
				['PATCH', ['/roles/', role.role_id]]
				, [role, roles.reqParser, roles.resParser]
			)
		}
		,create(role){
			return POST(
				['POST', ['/roles']]
				, [role, roles.reqParser, roles.resParser]
			)
		}
		,delete(role){
			return DELETE(
				['DELETE', ['/roles/', role.role_id]]
			)
		}
	}

	function InviteToAPIInvite ( o ){

		return {
			invite_id: o.invite_id()
			,invite_email: o.invite_email()
			,invite_creation_time:
				timestampToIsoUnsafe(o.invite_creation_time())
			,role_id: o.role_id()
			,organization_id: o.organization_id()
			,invite_groups: o.invite_groups()
		}
	}

	function APIInviteToInvite ( o ){

		return {
			invite_id:
				m.prop(o.invite_id || null)
			,invite_email:
				m.prop(o.invite_email)
			,invite_creation_time:
				m.prop( isoToTimeStampUnsafe(o.invite_creation_time) )
			,role_id:
				m.prop(o.role_id || null)
			,organization_id:
				m.prop(o.organization_id || null)
		}
	}

	const invites = {
		fetch: {
			all: function() {
				return LIST(
					['GET', ['/invites']]
				)
					.then(
						list => list.map(APIInviteToInvite)
					)
			}
		}
		,ok({ invite_email, invite_id, user_id }){
			return POST(
				['POST', ['/invites/ok?']]
				,[ user_id
					? { invite_email, invite_id, user_id }
					: { invite_email, invite_id }
					, I, I
				]
			)
		}
		,post: {
			one: function(invite) {
				return POST(
					['POST', ['/invites']]
					,[ invite
					, InviteToAPIInvite
					, APIInviteToInvite
					]
				)
			}
		}
		,accept({
			invite_id
			, user_username
			, user_email
			, user_name
			, user_password
		}){
			return POST(
				['POST', ['/users/invites/', invite_id ]]
				,[ { user_username
				, user_email
				, user_name
				, user_password
				}
				, I
				, I
				]
			)
		}
		,remove: {
			one: function(id){
				return DELETE(['DELETE', ['/invites/', id]])
			}
		}
	}


	function TimesheetToAPITimesheet ( o ){

		return {
			user_id: o.user_id()
			,user_name: o.user_name()
			,crew_id: o.crew_id()
			,crew_name: o.crew_name()
			,crew_hours: o.crew_hours()
			,crew_work: o.crew_work()
			,crew_performance: o.crew_performance()
			,work_conditions: o.work_conditions()
			,timesheets_id: o.timesheets_id() || undefined
			,organization_id: o.organization_id() || undefined
			,allocation_id: o.allocation_id() || undefined
		}
	}

	function APITimesheetToTimesheet ( o ){

		return {
			timesheets_id: m.prop(o.timesheets_id || null)
			,organization_id: m.prop(o.organization_id || null)
			,allocation_id: m.prop(o.allocation_id || null)
			,user_id: m.prop(o.user_id || null)
			,crew_id: m.prop(o.crew_id || null)
			,user_name: m.prop(o.user_name || null)
			,crew_name: m.prop(o.crew_name || null)
			,crew_hours: m.prop(Number(o.crew_hours).toFixed(2))
			,crew_work: m.prop(Number(o.crew_work).toFixed(2))
			,crew_performance: m.prop(o.crew_performance)
			,work_conditions: m.prop(o.work_conditions)
			,owner_id: o.crew_id || o.user_id
			,organizations_disciplines_id: o.organizations_disciplines_id
			,sharedtimesheet: o.sharedtimesheet
		}
	}

	const timesheets = {
		fetch: {
			all: function() {
				return LIST(
					['GET', ['/timesheets']]
				)
					.then(
						list => list.map(APITimesheetToTimesheet)
					)
			}
			,byAllocationId: function({allocation_id, sharedtimesheet}) {
				const params =
					[['allocation_id', allocation_id ]
					,['sharedtimesheet', sharedtimesheet]
					]

				const url =
					[ '/timesheets', params]

				return LIST(
					['GET', url]
				)
					.then(function(list){
						return list.map(APITimesheetToTimesheet)
					})
			}
			,byProjectId: function({project_id}) {
				const params =
					[ ['project_id', project_id ]
					]

				const url =
					[ '/timesheets', params]

				return LIST(
					['GET', url]
				)
					.then(function(list){
						return list.map(APITimesheetToTimesheet)
					})
			}
			,byScheduleVersionId: function(schedule_version_id) {
				const params = [
					['schedule_version_id', schedule_version_id ]
				]

				const url =
					[ '/timesheets', params]

				return LIST(
					['GET', url]
				)
					.then(
						list => list.map(APITimesheetToTimesheet)
					)
			}
		}
		,patch: {
			many: function(timesheets, disableList, aligntimesheets){

				const paramst =
					'disableList/'
					+ disableList
					+ '/aligntimesheets/'
					+ aligntimesheets

				return PATCH_MANY(
					['PATCH', ['/timesheets/' + paramst]]
					,[ timesheets
					, TimesheetToAPITimesheet
					, APITimesheetToTimesheet
					]
				)
			}
		}
		,remove: {
			one: function(id){
				return DELETE(['DELETE', ['/timesheets/', id]])
			}
		}
	}

	const organizations = {

		create: function({
			organization_name
			, organization_id
			, organization_incorporation_no
			, organizations_details_id
			, organizations_details_phone
			, organizations_details_address
			, organizations_details_state
			, organizations_details_postcode
		}){
			return request.request(
				baseURL
				, baseHeaders()
				,'POST'
				,'/organizations'
				,{
					organization_name
					, _organization_id: organization_id
					, organization_incorporation_no
					, organizations_details:
						organizations_details_phone
						|| organizations_details_address
						|| organizations_details_state
						|| organizations_details_postcode
						? [{
							organizations_details_id
							, organizations_details_phone
							, organizations_details_address
							, organizations_details_state
							, organizations_details_postcode
						}]
						: []
				}
			)
		}
		,update: function({
			organization_name
			, organization_id
			, organization_incorporation_no
			, organizations_details_id
			, organizations_details_phone
			, organizations_details_address
			, organizations_details_state
			, organizations_details_postcode
		 }){
			return request.request(
				baseURL
				, baseHeaders()
				,'PATCH'
				,'/organizations/'+organization_id
				,{
					organization_id
					, organization_name
					, organization_incorporation_no
					, organizations_details:
						organizations_details_phone
						|| organizations_details_address
						|| organizations_details_state
						|| organizations_details_postcode
						? [{
							organizations_details_id
							, organizations_details_phone
							, organizations_details_address
							, organizations_details_state
							, organizations_details_postcode
						}]
						: []
				}
			)
		}

		,delete({ organization_id }){
			return request.request(
				baseURL
				, baseHeaders()
				,'DELETE'
				,'/organizations/'+organization_id
				,{}
			)
		}

		,get({ organization_id }){
			return request.request(
				baseURL
				, baseHeaders()
				,'GET'
				,'/organizations/'+organization_id
				,{ organization_id }
			)
		}
	}

	const auths = {

		create: function(a) {

			return request.request(
				baseURL
				,{}
				,'POST'
				,'/auths'
				, a
			)
		}

		,magicLink(a) {

			return request.request(
				baseURL
				,{}
				,'POST'
				,'/auths/magic'
				, a
			)
		}

		,ping(){
			return GET(
				['GET', ['/auths/ok']]
			)
		}

		,get(){
			return GET(
				['GET', ['/auths']]
			)
		}

		,async refresh(){
			return await request.request(
				baseURL
				,{}
				,'POST'
				,'/auths/jwt/refresh'
				, {}
			)
		}

		, async logout(){
			await request.request(
				baseURL
				,{}
				,'POST'
				,'/auths/jwt/logout'
				, {}
			)
		}
	}

	const mail = {
		create: {
			forgottenPassword (o) {

				return request.request(
					baseURL
					,{}
					,'POST'
					,'/mail/forgotten_password'
					,o
				)
			}
		}
	}

	function APIFlowDependentsToFlowDependents(f){
		return {
			organization_id: f.organization_id || null
			,flow_id: f.flow_id || null
			,organizations_disciplines_id: f.organizations_disciplines_id
			,flows_dependent_activity_id: f.flows_dependent_activity_id || null
			,discipline_name: f.discipline_name
				+ (
					f.discipline_description
						?  ' - ' + f.discipline_description
						: ''
				)
			,flow_dependent_activity_name: f.discipline_name
		}
	}


	function FlowDependentsToAPIFlowDependents(o){

		return {
			organization_id: o.organization_id
			,flow_id: o.flow_id
			,organizations_disciplines_id: o.organizations_disciplines_id
			,flows_dependent_activity_id: o.flows_dependent_activity_id
			,discipline_name: o.discipline_name
		}
	}


	function APIFlowPrimarysToFlowPrimarys(f){
		return {
			organization_id: f.organization_id || null
			,flow_id: f.flow_id || null
			,organizations_disciplines_id: f.organizations_disciplines_id
			,flows_primary_activity_id: f.flows_primary_activity_id || null
			,discipline_name: f.discipline_name
				+ (
					f.discipline_description
						?  ' - ' + f.discipline_description
						: ''
				)
			,flow_primary_activity_name: f.discipline_name
		}
	}


	function FlowPrimarysToAPIFlowPrimarys(o) {
		return {
			organization_id: o.organization_id
			,flow_id: o.flow_id
			,organizations_disciplines_id: o.organizations_disciplines_id
			,flows_primary_activity_id: o.flows_primary_activity_id
			,discipline_name: o.discipline_name
		}
	}

	function APIFlowToFlow(o){
		return {
			organization_id:
				m.prop(o.organization_id || null)
			,schedule_id:
				m.prop(o.schedule_id || null)
			,flow_id:
				m.prop(o.flow_id || null)
			,flow_delay_in_days:
				m.prop(o.flow_delay_in_days)
			,flow_delay_type:
				m.prop(o.flow_delay_type)
			,flow_dependency_relationship:
				m.prop(o.flow_dependency_relationship)
			,flow_dependent_activity:
				o.flow_dependent_activity
					.map(APIFlowDependentsToFlowDependents)
			,flow_primary_activity:

				o.flow_primary_activity
					.map(APIFlowPrimarysToFlowPrimarys)
			,flow_safety_ratio:
				m.prop(o.flow_safety_ratio)
			,flow_name:
				m.prop(o.flow_name)
		}
	}

	function FlowToAPIFlow(o) {

		return {
			flow_delay_in_days: o.flow_delay_in_days()
			,flow_delay_type: o.flow_delay_type()
			,flow_dependency_relationship: o.flow_dependency_relationship()
			,flow_dependent_activity:
				o.flow_dependent_activity
					.map(FlowDependentsToAPIFlowDependents)
			,flow_primary_activity:
				o.flow_primary_activity
					.map(FlowPrimarysToAPIFlowPrimarys)
			,flow_safety_ratio: o.flow_safety_ratio()
			,flow_name: o.flow_name()
			,organization_id: o.organization_id() || undefined
			,schedule_id: o.schedule_id() || undefined
			,flow_id: o.flow_id() || undefined
		}
	}

	const flows = {
		fetch: {
			bySchedule(id) {
				return LIST(
					['GET', ['/flows/schedule/', id]]
				)
				.then(
					list => list.map(APIFlowToFlow)
				)
			}
		}
		,patch (flows) {
			return PATCH_MANY(
				['PATCH', ['/flows']]
				,[
					flows
					,FlowToAPIFlow
					,APIFlowToFlow
				]
			)
		}
		,remove: {
			one (id){
				return DELETE(
					['DELETE', ['/flows/', id]]
				)
			}
		}
	}



	const flows_dependent_activities = {
		remove: {
			one (id){
				return DELETE(
					['DELETE', ['/flows_dependent_activities/', id]]
				)
			}
		}
	}

	const flows_primary_activities = {
		remove: {
			one (id){
				return DELETE(
					['DELETE', ['/flows_primary_activities/', id]]
				)
			}
		}
	}

	const organizations_disciplines = {
		fetch: function({props, depth}) {

			const params = [
				['props', props]
				,['depth', depth]
			]

			return LIST(
				['GET', ['/organizations_disciplines', params]]
			)
				.then(
					list => list.map(
						APIOrganizationsDisciplineToOrganizationsDiscipline
					)
				)
		}
		,patch (organizations_disciplines) {
			return PATCH_MANY(
				['PATCH', ['/organizations_disciplines']]
				,[
					organizations_disciplines
					,OrganizationsDisciplineToAPIOrganizationsDiscipline
					,APIOrganizationsDisciplineToOrganizationsDiscipline
				]
			)
		}
		,remove: {
			one (id){
				return DELETE(
					['DELETE', ['/organizations_disciplines/', id]]
				)
			}
			,many (ids){
				const disciplinelist = ids.join('--')
				return DELETE(['DELETE', ['/organizations_disciplines/disciplinelist/' + disciplinelist]])
			}
		}
	}

	const tasks = {
		fetch: {
			since( since ){
				return LIST(
					['GET', ['/schedule_tasks/since/', since.getTime()]]
				)
			}
			,async inProgress() {
				const xs = await LIST(
					['GET', ['/schedule_tasks/in_progress']]
				)

				return xs
			}
			,byScheduleVersion(id) {
				return GET(
					['GET', ['/schedule_tasks/', id]]
				)
			}
		}
	}

	const project_contacts = {
		remove: {
			one: function(project_contacts_id){
				return DELETE([
					'DELETE', ['/project_contacts/',project_contacts_id]
				])
			}
			,many: function(tasklist){
				const contactDeletions = tasklist.join('--')
				return DELETE(['DELETE', ['/project_contacts/contactlist/' + contactDeletions]])
			}
		}
		,patch: {
			many: function(project_contacts) {

				const t = request.MetadataTracker()

				return PATCH_MANY(
					['PATCH', ['/project_contacts']]
					,[
						project_contacts
						, ProjectContactsToAPIProjectContacts
						, APIProjectContactsToProjectContacts
						, {name: ''}
					]
				)
				.then( t.addMetaData )
			}
		}
		,fetch: function({
			project_id, getOptions
		}){

			const params =
				[['project_id', project_id]
				,['getOptions', getOptions]]

			return LIST([
				'GET', ['/project_contacts', params ]
			])
				.then(
					list => list.map(APIProjectContactsToProjectContacts)
				)
		}
	}
	const template_schedules = {
		fetch: function(){
			return LIST(
				['GET', ['/template_schedules']]
			)
		}
		,post: function(templates_type){
			let headers = { ...baseHeaders() }
			delete headers['X-Odin-Organization']
			return request.request(
				baseURL
				// sending an org id when creating an org can confuse
				// the api
				, headers
				, 'PATCH'
				, ['/template_schedules/' + templates_type].join('')
			)
		}
	}

	function Session(){
		/* globals XMLHttpRequest */

		const after = Date.now()

		let aborted = false;
		const { DONE } = XMLHttpRequest

		function active(){
			return Object.keys(request.requests).map(function(id){
				return request.requests[id]
			})
			.filter( x => x.transport.readyState != DONE )
			.filter( x => x.when > after )
		}

		function onerror(f){
			return function receiver(e){
				if( !aborted ){
					return f(e)
				}
				return null
			}
		}

		function abort(){

			aborted = true

			active()
			.forEach( x => {
				delete request.requests[x.id]
				x.transport.abort()

			} )

		}

		return {
			abort
			,active
			,onerror
		}

	}

	const xero = {
		fetch:
			({
				sheets
				,organization_id
				,xeroexport
				,sales
				,bills
				,startDate
				,endDate
				,contract_items
				,invoices
			}) => {
				// const t =
					request.MetadataTracker()

				const params = [
					['sheets', sheets]
					,['organization_id', organization_id]
					,['xeroexport', xeroexport]
					,['sales', sales]
					,['bills', bills]
					,['startDate', startDate]
					,['endDate', endDate]
					,['contract_items', contract_items]
					,['invoices', invoices]
				]

				return LIST(
					['GET', ['/exports/xero/', params]]
					// ,[bodydata, R.identity, R.identity]
				)
			}
		,xero_auths_fetch:
			() => {
				// const t =
					request.MetadataTracker()

				// const params = [
				// 	['user_id', user_id]
				// 	,['organization_id', organization_id]
				// ]

				return LIST(
					['GET', ['/invoices/xero_auths']]
					// ,[bodydata, R.identity, R.identity]
				)
			}
		,xero_tenants_fetch:
			() => {
				// const t =
					request.MetadataTracker()

				// const params = [
				// 	['user_id', user_id]
				// 	,['organization_id', organization_id]
				// ]

				return LIST(
					['GET', ['/invoices/xero_tenants']]
					// ,[bodydata, R.identity, R.identity]
				)
			}
		,xero_tenants_import:
			({tenantName, tenantId, imports}) => {
				// const t =
					request.MetadataTracker()

				const body = {
					'tenantName': tenantName
					,'tenantId': tenantId
					,'imports': imports
				}

				return POST(
					['POST', ['/invoices/xero_tenants']]
					,[body, R.identity, R.identity]
				)
			}
	}

	const files = {

		SIGN(filename, filetype, associations){
			return POST(['POST', ['/files']], [Object.assign({
				filename
				,filetype
			}, associations), I, I])
		}
		,getTransLoaditSignature(body){

			return POST(
				['POST', ['/files/transloadit/signature']]
				,[ body, I, I ]
			)
		}
		,LIST({
			allocation_id
			, user_id
			, project_id
			, project_extras_id
			, schedule_id
			, contractor_id
			, discipline_id
			, organizations_disciplines_id
			, crew_id
			, file_id
			, urls_only
			, file_error
			, file_processed=true
			, organization_id
			, invoice_id
			, tools_id
			, supplier_items_id
			, order_id
		}){
			const params =
				R.reject( R.isNil, {
					allocation_id
					, user_id
					, project_id
					, project_extras_id
					, schedule_id
					, contractor_id
					, discipline_id
					, organizations_disciplines_id
					, crew_id
					, file_id
					, file_error
					, file_processed
					, urls_only
					, invoice_id
					, tools_id
					, supplier_items_id
					, order_id
				})

			return request.request(
				baseURL
				,baseHeaders({ organization_id })
				, 'GET'
				, '/files'
				, params
			)
		}

		,DELETE(files){
			return request.request(
				baseURL
				,baseHeaders()
				, 'DELETE', '/files'
				, { files: files.map( f => f.file_id ) }
			)
		}

		,UNDELETE(files){
			return request.request(
				baseURL
				,baseHeaders()
				, 'PATCH', '/files/undelete'
				, { files: files.map( f => f.file_id ) }
			)
		}
	}

	return {
		tasks
		,files
		,Session
		,organizations_disciplines
		,APISchedulesParametersToUI
		,SchedulesParametersToAPISchedulesParameters
		,schedules
		,schedules_parameters
		,projects
		,users
		,users2
		,contractors
		,tools
		,geographies
		,suburbs
		,crews
		,crews_discipline_rates
		,interruptions
		,interruptionsAffected
		,allocations
		,allocations_eots
		,allocations_nods
		,allocations_vars
		,disciplines
		,disciplines_contractors
		,disciplines_contractors_crews
		,warehouses
		,suppliers
		,supplier_items
		,presets
		,materials
		,supplier_locations
		,orders
		,containers
		,roles
		,groups2
		,invites
		,timesheets
		,organizations
		,auths
		,mail
		,flows
		,flows_dependent_activities
		,flows_primary_activities
		,contracts
		,template_schedules
		,contract_items
		,contracts_invoices_calculations
		,invoices
		,project_extras
		,APIProjectToProject
		,APIProjectContactsToProjectContacts
		,requests: request.requests
		,project_contacts
		,xero
		,buildRequest(method, url, params={}){
			return request.buildRequest(
				baseURL
				,baseHeaders()
				, method
				, url
				, params
			)
		}
		,request(method, url, params={}){
			return request.request(
				baseURL
				,baseHeaders()
				, method
				, url
				, params
				, () => {}
			)
		}
	}
}