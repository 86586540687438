/* globals */
import m from 'bacta'
import * as R from 'ramda'
import Chartist from 'chartist'
import moment from 'moment'
import css from 'bss'
// const Loaded = sst.yslashn.either('Loaded')
// const files = data.scoped( Loaded.N([]) )
import * as F from '../utils/financialsUtils'
import { money as makeMoney } from '../utils/regExes'
const milliperday = 1000*60*60*24
const spreader =
	(a, b, c) => R.mapObjIndexed((objVal, key) => objVal + a[key]/b, c)

const segmentSeries =
	({data, threshold, cumulative, nameappendage, className}) => {
		const series = {}
		const seriesdata = []

		if( cumulative ){
			R.reduce(
				(a, b) => {
					const cumulativevalue =
						{
							x: b['x']
							,y: b['y'] + a['y']
						}

					seriesdata.push(cumulativevalue)
					return cumulativevalue
				}
				,{x:0, y:0}
				,data
			)
		}
		else { seriesdata.push(data) }

		R.reduce(
			(v) => {

				const transitionIndex =
					v.findIndex((value, i, a) =>
						value.y < threshold && i+1 <= a.length-1 && a[i+1].y >= threshold
						|| value.y > threshold && i+1 <= a.length-1 && a[i+1].y <= threshold
					)

				const cutposition = transitionIndex + 1

				const splitArray =
					transitionIndex == -1
					? v
					: R.splitAt(cutposition, v)

				if( splitArray.length && v.length ){
					if( transitionIndex == -1 ){
						series[Object.keys(series).length] = v
					}
					else {
						series[Object.keys(series).length] = splitArray[0]
						series[Object.keys(series).length] = [v[transitionIndex], v[transitionIndex+1]]
					}
				}

				return transitionIndex == -1 ? [] : splitArray[1]
			}
			,seriesdata
			,seriesdata
		)

		return R.toPairs(series).map((a) => ({
			name: nameappendage+a[0]
			, data: a[1]
			, className
		}))
	}


const drawChart =
	function({fe, data, features}){
		const labels = []
		const sdata = data
		if( features().Charts && sdata && (sdata.chartdata || sdata.series)){

			const series =
				(sdata.series || (sdata && [sdata]) || [])
				.map((os) => {

					const actualChartData = []
					const forecastChartData = []

					R.pipe(
						R.groupBy(
							(i) =>
								new Date(
									R.prop('invoice_date', i)
									|| R.prop('allocations_allocation_date', i)
									|| R.prop('spreaddate', i)
								).getTime()
						)
						,R.mapObjIndexed((i, k, o) => {

							const financialposition = F.financialReduce({items: i})
							const date = new Date(Number(k)).getTime()

							actualChartData.push({
								x: date
								,y:
									R.sum(
										(sdata.ftypeActual || ['currentProfits'])
										.map((ft) => financialposition[ft])
									)
							})

							forecastChartData.push({
								x: date
								,y:
									R.sum(
										(sdata.ftypeForecast || ['forecastedProfits'])
										.map((ft) => financialposition[ft])
									)
							})

							if( Object.keys(o).length == 1 ){

								R.unfold(
									(n) => {
										const vadd =
											{
												x: n.x - milliperday
												,y: 0
											}

										actualChartData.push(vadd)
										forecastChartData.push(vadd)
										labels.unshift(vadd.x)

										return n.x < date - 15*milliperday
											? false
											: [ vadd, vadd ]
									}
									, R.last(actualChartData)
								)
							}

							labels.push(date)

							return {
								x: date
								,y: financialposition
							}
						})
					)(
						R.sortBy(
							(i) =>
								new Date(
									R.prop('invoice_date', i)
									|| R.prop('allocations_allocation_date', i)
									|| R.prop('spreaddate', i)
								).getTime()
							,R.unnest(
								(os && os.chartdata || [])
								.map((i) =>
									i.invoice_date
									|| i.allocations_allocation_date
									? i
									: R.unfold(
										(n) => {
											const vadd =
												R.mergeAll([
													n
													, spreader(i, (new Date(i.enddate).getTime()-new Date(i.startdate).getTime())/milliperday, R.clone(F.financialprops))
													, {spreaddate: new Date(new Date(n.spreaddate || i.startdate).getTime() + milliperday)}
												])

											return new Date(n.spreaddate).getTime() > new Date(i.enddate).getTime()
												? false
												: [ vadd, vadd ]
										}
										, i
									)
								)
							)
						)
					)

					return {
						adata: R.sortBy(R.prop('x'), actualChartData)
						, fdata: R.sortBy(R.prop('x'), forecastChartData)
						, bdata: data
						, name: os.chartname || os.contractrecognitionname
					}
				})


			const addColours =
				(context) => {
					const defaultv = {y:0, x: 0}
					const threshold = fe.odinsum.currentRevenue*(fe.tp()/100)
					const sortedValues = R.sortBy(R.prop('y'), context.values || [{x:0, y:0}])
					const high = (R.last(sortedValues) || defaultv).y
					const low = (sortedValues[0] || defaultv).y
					if(context.type == 'point' || context.type == 'line'){
						context.element.attr({
							style: 'stroke: hsl('
								+ (
									context.type == 'line'
									&& (low < threshold && high >= threshold
									|| low > threshold && high <= threshold)
									? '0, 0%, 50%);'
									: Math.min(
										Math.max(
											Math.floor(
												(
													context.type == 'line'
													? high
													: Chartist.getMultiValue(context.value)
												) * ( high > threshold ? 1 : 0.75) / threshold * 100
											)
											, 0
										)
										, 100
									)
									+ ', 50%, 50%);'
								)
						});
					}
				}

			const getChartData =
				({labels, series}) => {

					return {
						labels: R.sort((a,b)=> a-b, R.uniq(labels))
						,series:
							R.unnest(
								series.map(({adata, fdata, bdata}) =>
									segmentSeries({
										data: adata
										, threshold:
											(
												bdata.currentRevenue
												|| bdata.currentRevenue == 0
												? bdata.currentRevenue
												: fe.odinsum.currentRevenue
											)*(fe.tp()/100)
										, cumulative: true
										, nameappendage: 'actual'
										, className: `.ct-series-a .ct-line`
									})
									.concat(
										segmentSeries({
											data: fdata
											, threshold:
												(
													bdata.forecastedRevenue
													|| bdata.forecastedRevenue == 0
													? bdata.forecastedRevenue
													: fe.odinsum.forecastedRevenue
												)*(fe.tp()/100)
											, cumulative: true
											, nameappendage: 'forecast'
											, className: `ct-dash`
										})
									)
									.filter(R.pipe(R.prop('data'), R.prop('length')))
								)
							)
					}
				}

			const makeSingleChart =
				({series, width, index}) => {
					const chartname = R.unnest(series.map(R.propOr('','name')))
					return m(
							'.ct-chart'
							+ css`
								max-height: 30em;
								padding: 1em;
							`
							,{
								key: width + (fe.schedule_version_id || fe.id)
								,oncreate({ dom }){
									let tvalue = null
									const x =
										getChartData({
											labels
											,series
										})


									const feChart =
										new Chartist.Line(
											dom
											,{ labels: x.labels, series: x.series}
											,{
												width: '100%'
												,height: '24em'
												,chartPadding: 0
												,fullWidth: true
												,showPoint: false
												,lineSmooth: true
												,axisX: {
													showGrid: true
													,showLabel: true
													,scaleMinSpace: 50
													,labelInterpolationFnc: function(value) {
														const t = moment( value ).format("ll")

														const renderyear =
															tvalue
															&& new Date(value).getYear()
															!= new Date(tvalue).getYear()

														tvalue = value

														return renderyear
															? t
															: t.split(',')[0]
													}
													,type: Chartist.AutoScaleAxis
													,offset: 60
												}
												,axisY: {
													offset: 60
													,showGrid: true
													,scaleMinSpace: 20
													,labelInterpolationFnc: function(value) {
														return makeMoney( value, true );
													}
													,type: Chartist.AutoScaleAxis
												}
											}
										)

									!fe.chartElements
									? fe.chartElements = [feChart]
									: fe.chartElements.push(feChart)

									feChart.on('draw', addColours);

									return fe.chartElements[index]
								}
							}
							,m(`p.v-btm`, chartname.join(' | '))
					)
				}


			fe.chart =
				({width}) =>
					m('div'
						,(!fe.split ? [series] : series)
						.map((s, i) => makeSingleChart({series: fe.split ? [s] : s, width, index: i}))
					)

			// should the creaion part be in the else?
			if( fe.chartElements ){
				fe.chartElements.forEach((fec, i) => {
					if (series[i]){
						const x =
						getChartData({
							labels
							,series: [ series[i] ]
						})


						fec.update( x )
						fec.on('draw', addColours);
					}
				})
			}

			m.redraw()

		}


		return !features().Charts ? m('div', ) : fe.chart
	}


export default drawChart