/* globals document, setTimeout, clearTimeout, localStorage, Promise */
import * as R from 'ramda'
import css from 'bss'
import m from 'bacta'
// import * as z from './z/index.js'

import * as z from '../../../how/z/index.js'
import { prop  } from '../../../stream'
import Traverse from '../utils/traverse2'
const traverse = Traverse(R)
const octagonAlert = m.trust(`
	<svg xmlns="http://www.w3.org/2000/svg" width="100%" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-alert-octagon"><polygon points="7.86 2 16.14 2 22 7.86 22 16.14 16.14 22 7.86 22 2 16.14 2 7.86 7.86 2"></polygon><line x1="12" y1="8" x2="12" y2="12"></line><line x1="12" y1="16" x2="12.01" y2="16"></line></svg>
`)

const abbreviations = {
	cvc: true
}

const __toRamdaPath = name => name.split('.').map(
	x => isNaN(x) ? x : Number(x)
)

const magic = s => {
	s= s || ''
	s= s.split('_').join(' ')
	s= s.split('-').join(' ')
	s= s.split(' ').map(
		x => x[0].toUpperCase() + x.slice(1)
	)
	.join(' ')

	s= s.split(' ').map(
		x => {
			if (x.toLowerCase() in abbreviations) {
				return x.toUpperCase()
			}
			return x
		}
	)
	.join(' ')

	return s
}

let proceedTimer;

const Model = ({
	initialValues=prop({})
	,key=null
	,errors=initialValues.map( () => ({}) )
	,theirErrors=initialValues.map( () => ({}) )
	,touched=prop({})
	,changes=prop({})
	,saving=prop(false)
	,errored=prop(true)
	,changed=prop(false)
	,toRamdaPath=__toRamdaPath
	,mergedErrors=prop.merge([errors, theirErrors]).map(
		([a,b]) => {
			const out = R.mergeDeepLeft(a,b)
			return out
		}
	)

	// when the original values change we reset ours
	,values=initialValues.map( x => x )
	,pathCache={}
	,validate=() =>({})
	,onsubmit
}={}) => {


	const $ = z.Z({ state: values }).$

	const initialSnapShot = JSON.parse(JSON.stringify(initialValues()))

	if( key != null ) {
		try {
			const saved = JSON.parse(localStorage.getItem('form.unsaved.'+key))
			const merged = R.mergeDeepRight( initialValues(), saved )
			initialValues(merged)
			changes(merged)
		} catch (e){}

		values.map(
			x => localStorage.setItem('form.unsaved.'+key, JSON.stringify(x))
		)
	}

	const clearStorage = () => {
		if( key != null ) {
			localStorage.removeItem('form.unsaved.'+key)
		}
	}

	let nextInputOriginName = null
	let nextInputDelay = null
	let nextInputRevision=Date.now

	const clearNextInputTimer = () => {
		clearTimeout(proceedTimer)
		nextInputOriginName = null
		nextInputDelay=0
	}

	const nextInputImmediate = (predicate) => {
		if(
			predicate()
		) {
			let $0 = document.activeElement
			let $$ = x => Array.from(document.querySelectorAll(x))
			let xs = $$('form input')
			xs[(xs.indexOf($0)+1) % xs.length].focus()
		}

		nextInputOriginName = null
		nextInputDelay=0
	}

	const nextInput = (predicate=() => true, { delay=1000 }={}) => {
		const validateDelay = 200
		const ourDelay = validateDelay+1

		delay = delay - ourDelay

		clearTimeout(proceedTimer)
		nextInputOriginName = null
		nextInputDelay=0

		m.redraw()

		// wait for validation to catch up
		proceedTimer=setTimeout( () => {
			if( predicate() ) {
				const $0 = document.activeElement

				nextInputOriginName = $0.name || $0.id
				nextInputDelay = delay
				nextInputRevision=Date.now()

				m.redraw()

				clearTimeout(proceedTimer)
				proceedTimer = setTimeout( () => {
					nextInputImmediate(() => {
						return $0 == document.activeElement
							&& predicate()
					})
				}, delay )
			}
		}, 201)

	}

	const getNextInputOriginName = () =>
		nextInputOriginName

	const getNextInputDelay = () =>
		nextInputDelay

	const getNextInputRevision = () =>
		nextInputRevision

	let validateTimeout = 200
	let validateTimeoutId;
	values.map( x => {
		clearTimeout(validateTimeoutId)
		validateTimeoutId = setTimeout( () => {
			const o = validate(x) || {}
			theirErrors(
				Object.fromEntries(
					Object.entries(o).filter(
						([_,v]) => v // error must be truthy
					)
				)
			)
			m.redraw()
		}, validateTimeout )
		return null
	})

	// track if there's any changes the changes stream
	changes.map( x => Object.keys(x).length > 0 )
		.map(changed)

	// track if there's any errors in the errors stream
	mergedErrors
		.map( x => Object.keys(x).length > 0 )
		.map(errored)

	errored.map( () => m.redraw() )

	const errorInfo = (theirKeys, { checkTouched=true }={}) =>
		theirErrors && errors
		&& m('label.error'
			+ css`
				display: grid;
				grid-auto-flow: column;
				gap: 1em;
				align-items: center;
				margin-top: 0em;
				justify-content: start;
			`
			+ (
				[].concat(theirKeys).some(
					k => mergedErrors()[k]
						&& (!checkTouched || touched()[k])
				)
				? '.error-show' : ''
			)
			, m('.icon'
				+ css`
					display: grid;
					width: 1.5em;
					align-content: center;
				`
				, octagonAlert
			)
			, m('span'
				,
				{ oncreate: ({ dom }) => {
					const s = [].concat(theirKeys).flatMap(
						k => mergedErrors()[k]
							&& (!checkTouched || touched()[k])
							? [mergedErrors()[k]]
							: []
					)
					.shift()

					if(s) {
						m.render(dom, s)
					}
				}
				, onupdate: ({ dom }) => {
					const s = [].concat(theirKeys).flatMap(
						k => mergedErrors()[k]
							&& (!checkTouched || touched()[k])
							? [mergedErrors()[k]]
							: []
					)
					.shift()

					if(s) {
						m.render(dom, s)
					}
				}
				}
			)
		)

	function nextInputProgress(k){
		return m(''
			+ css`
				opacity: ${
					getNextInputOriginName() == k
					? 1
					: 0
				}
				transition: ${
					getNextInputOriginName() == k
					? 0
					: 1
				}
			`
			, m(''
				+ css`
					width: 0%;
					border: solid 1px #00ff1f;
				`
				+ css
				.$animate(`ease-in-out ${getNextInputDelay()}ms forwards`, {
					from: 'width: 0%'
					,to: 'width: 100%'
				})
				, { key:
					String(getNextInputOriginName())
					+ String(getNextInputRevision())
				}
			)
		)
	}

	function isSaveDisabled(){
		// todo: model.saveDisabled()
		return !changed()
			|| errored()
			|| saving()
	}

	function reset({
		values:_values=initialSnapShot
		, changes:_changes={}
		, errors:_errors={}
		, touched:_touched={}
	}={}){
		theirErrors({})
		changes(_changes)
		errors(_errors)
		values(_values)
		touched(_touched)
		clearStorage()
	}

	function saveWith(f=onsubmit, childrenArgs){
		saving(true)
		return new Promise( (Y,N) => {
			return Promise.resolve(f(childrenArgs)).then(Y,N)
		})
			.then( (options={}) => {
				reset(options)
			})
			.finally( () => {
				saving(false)
			} )
	}

	function setTouch(path, value){
		if( value ) {
			touched(R.assocPath(path, Date.now(), touched()))
		} else {
			touched(R.dissocPath(path, touched()))
		}
	}

	function setValue(path, value){
		if( value != null ) {
			values(R.assocPath(path, value, values()))
		} else {
			values(R.dissocPath(path, values()))
		}
	}

	function setError(path, value){
		if( value != null ) {
			theirErrors(R.assocPath(path, value, theirErrors()))
		} else {
			theirErrors(R.dissocPath(path, theirErrors()))
		}
	}

	// bind an event target to a stream/prop
	function bind(mapping){
		let stream;

		return {
			oncreate: (vnode) => {
				stream  = initialValues.map( () => {
					Object.entries(mapping).forEach( ([k,v]) => {
						vnode.dom[k] = v() ?? ''
					})
					return null;
				})
			}
			, onremove: () => stream?.end(true)
		}
	}

	return {
		initialValues
		, errors
		, setTouch, setValue, setError
		, isSaveDisabled
		, saveWith
		, onsubmit
		, theirErrors
		, mergedErrors
		, touched
		, reset
		, changes
		, errored
		, changed
		, values
		, validate
		, errorInfo
		, nextInput
		, nextInputImmediate
		, clearStorage
		, pathCache
		, isModel: true
		, getNextInputOriginName
		, nextInputProgress
		, clearNextInputTimer
		, saving
		, toRamdaPath
		, $
		, bind
	}
}

const Form = ({
	attrs
}) => {

	const model =
		attrs.isModel
		? attrs
		: Model(attrs)

	const {
		initialValues
		,errors
		,theirErrors
		,mergedErrors
		,touched
		,changes
		,errored
		,changed
		,values
		,$
		,nextInput
		,toRamdaPath
	} = model

	const modelKeys = Object.keys(model)

	const el = prop()

	// window.form = {
	// 	errors, theirErrors, values, originalValues: initialValues, changes
	// }

	let prev = {}
	// update validation message of matching form elements when key changes
	prop.merge([el, theirErrors])
		.map(
			([el, latest]) => {

				traverse ( R.mergeDeepLeft(latest, prev) ) (
					({ path }) => {

						const x = R.path(path, latest)

						if( !x || typeof x == 'string' ) {
							const name = path.join('.')

							const found = el.querySelector('[name="'+name+'"]')

							if( found ) {
								found.setCustomValidity( x || '')

								if( x ) {
									errors(R.assocPath( path, x, errors() ))
								} else {
									errors(R.dissocPath( path, errors() ))
								}
								setTitle(found)
							}
						}

						return () => x
					}
				)

				return prev = latest
			}
		)

	const scanFormElements = el => {
		const o =
			Array.from(el.querySelectorAll('[name]'))
			.reduce( (p, x) => {
				if( x.validationMessage ) {
					return R.assocPath(
						toRamdaPath(x.id || x.name)
						, `${magic(x.name || x.id)} ${x.validationMessage}`
						, p
					)
				}
				return p
			}, errors())

		return errors(o)
	}

	el.map(
		// make sure value has been set by prop
		el => setTimeout( () => scanFormElements(el), 1000 )
	)

	const over = f => s => s(f(s()))

	const getValidValue = target => {
		// todo-james add more as needed
		if( target.type == 'date' ) {
			return target.valueAsDate
		} else if (target.type == 'number' ) {
			return target.valueAsNumber
		} else {
			return target.value
		}
	}

	const recordError = x => {
		const path = toRamdaPath(x.id || x.name)

		if( x.validationMessage ) {
			const useMagic =
				x.validationMessage
					!= R.path( toRamdaPath(x.id || x.name), theirErrors())
			const s =
				useMagic
				? `${magic(x.name || x.id)} ${x.validationMessage}`
				: x.validationMessage

			over( R.assocPath(path, s) ) ( errors )
		} else {
			over( R.dissocPath(path) ) (errors)
		}
	}

	const setTitle = target => {
		if( typeof target.oldTitle == 'undefined' ) {
			target.oldTitle = target.title || ""
		}

		if( target.validationMessage ) {
			target.title = target.validationMessage
		} else if (
			target.title != target.oldTitle
		) {
			target.title = target.oldTitle
		}
	}

	const inputHandler = ({ path:pathStr, target=null, value=null }) => {
		const path = toRamdaPath(pathStr)

		const original = R.path(path, initialValues())
		const latest =
			model.pathCache[pathStr]
				? model.pathCache[pathStr]()
			: target
				? getValidValue(target)
				: value

		if( original !== latest ) {
			over(
				R.assocPath(path, latest)
			) (changes)
		} else {
			over( R.dissocPath(path) ) (changes)
		}



		if( target ) {
			recordError(target)
			setTitle(target)
		}

		// record new value
		over( R.assocPath(path, latest) ) ( values )
	}

	const oninput = ({target}) => {
		if( target.name || target.id ) {

			const pathStr = target.id || target.name

			inputHandler({ path: pathStr, target })
		}
	}

	const childrenArgs = {
		oninput

		, values
		, originalValues: initialValues
		, errors: mergedErrors
		, touched
		, changes

		, errored
		, changed
		, errorInfo: model.errorInfo

		, scanFormElements: () => {
			errors({})
			scanFormElements(el())
		}
		, nextInput
		, isSaveDisabled: model.isSaveDisabled
		, reset: model.reset
		, $
		, inputHandler
		, bind: model.bind
	}

	return {
		onbeforeremove(){
			$.$end()
		}
		,view: ({ attrs: theirAttrs, children }) => {
			const attrs = R.omit(modelKeys, theirAttrs)

			const onsubmit = theirAttrs.onsubmit || (() => {})

			return m('form'
				+ ( attrs.showFocused ? '.show-focused' : '' )
				+ css`
					display: grid;
					gap: 1em;
				`
				.$nest('.touched:invalid:not(:focus)', `
					color: red;
					border-color: red;
				`)
				.$nest('.show-focused input:focus:not(:invalid)', `
					border-bottom: solid 2px #00ff1f;
				`)
				.$nest('.error',
					css`
						max-height: 0px;
						padding: 0px;
						overflow: hidden;
						transition: 1s 0.5s;
						color: red;
					`
				)
				.$nest('.error.error-show',
					css`
						max-height: 4em;
					`
				)
				,
				Object.assign(
					{}
					, attrs
					,
					{ onsubmit: e => {
						e.preventDefault()
						model.saveWith(onsubmit, childrenArgs)
					}
					, oninput: e => oninput(e)
					, onfocusout: e => {
						model.clearNextInputTimer()
						oninput(e)
						if( e.target.name || e.target.id ) {
							over(
								R.assocPath(
									toRamdaPath(e.target.id || e.target.name), Date.now()
								)
							) (touched)
							e.target.classList.add('touched')
						}
					}
					, oncreate: ({ dom }) => el(dom)
					}
				)
				, children.map(
					f => f(childrenArgs)
				)
			)
		}
	}
}

Form.Model = Model

Form.legacyRenderProps = true
export default Form