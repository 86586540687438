import h, { css, options } from '../../../how/index'

const Resource =
	options.tags('Resource', ['Loading', 'Loaded', 'Empty', 'Error'])

const extensions = {
	dwg: {
		description: 'CAD Drawing'
		,preview: 'link'
	}
	,doc: {
		description: 'Word Document'
		,preview: 'link'
	}
	,docx: {
		description: 'Word Document'
		,preview: 'link'
	}
	,xls: {
		description: 'Excel Document'
		,preview: 'link'
	}
	,xlsx: {
		description: 'Excel Document'
		,preview: 'link'
	}
	,msg: {
		description: 'Outlook Message'
		,preview: 'link'
	}
	,eml: {
		description: 'Email Message'
		,preview: 'link'
	}
	,dat: {
		description: 'Unknown'
		,preview: 'link'
	}
	,webm: {
		description: 'Video'
		,preview: 'link'
	}
	,heic: {
		description: 'Video'
		,preview: 'link'
	}
	,mov: {
		description: 'Video'
		,preview: 'link'
	}
	,png: {
		description: 'Image'
		,preview: 'img'
	}
	,svg: {
		description: 'Vector Image'
		,preview: 'img'
	}
	,jpg: {
		description: 'Image'
		,preview: 'img'
	}
	,jpeg: {
		description: 'Image'
		,preview: 'img'
	}
	,gif: {
		description: 'Image'
		,preview: 'img'
	}
	,csv: {
		description: 'CSV File'
		,preview: 'link'
	}
	,pdf: {
		description: 'PDF Document'
		,preview: 'object'
		,contentType: 'application/pdf'
	}
	,txt: {
		description: 'Text File'
		,preview: 'link'
	}
	,lnk: {
		description: 'Windows Shortcut'
		,preview: 'link'
	}
	,zip: {
		description: 'Zip Archive'
		,preview: 'link'
	}
}

function message(...content){
	return h('.message'
		+ css`
			display: grid;
			min-height: 100vh;
			justify-content: center;
			align-content: center;
			color: white;
			text-align: center;
		`
		, content
	)
}

export default h.routed(
	'Files'
	, (x) => x.List()
	, {
		Redirect: '/'
		,View: '/:organization_id/view/:file_id'
	}
	, { search: {} }
	, (v) => {

		const { attrs: { data, Route, $, ParentRoute } } = v

		if (Route.isRedirect(Route.get())) {
			ParentRoute.set( ParentRoute.Autodirect() )
		}


		const id$ = $.route.$stream().map(
			Route.fold({
				Redirect: () => null
				,View: ({ file_id, organization_id }) => ({file_id, organization_id})
			})
		)

		$.file = Resource.Empty()
		v.useEffect({ name: 'fetch-file' }, function * (){
			const { file_id, organization_id } = (yield id$() || {})
			if (!file_id) $.file = Resource.Empty()

			try {
				$.file = Resource.Loading()
				const [file] = yield data.api.files.LIST({ file_id, organization_id })

				if (!file) {
					$.file = Resource.Empty()
				} else {
					$.file = Resource.Loaded(file)
				}
			} catch(e) {
				$.file = Resource.Error(e)
			}
		})

		const view = Route.fold({
			Redirect: () => null
			,View
		})

		function View({ file_id }){

			const resourceView = Resource.fold({
				Empty: () =>
					message(
						h('p', `We couldn't retrieve the requested file, please try again and if problems continue contact support@harth.io`)
					)
				, Loading: () => null
				, Error: () =>
					message(
						h('p', `There was an error retrieving the requested file, please try again and if problems continue contact support@harth.io`)
					)
				, Loaded: LoadedView
			})

			return resourceView($.file())
		}

		function LoadedView(file){
			const { file_url, file_ext } = file

			const ext = file_ext.replace('.', '').toLowerCase()

			const info = extensions[ext] || {
				description: 'Unknown File'
				,preview: 'link'
			}

			if ( info.preview === 'img' ) {
				return h('img' + css`
					max-width: 100vw;
					max-height: 100vh;
				`, { src: file_url })
			} else if ( info.preview === 'object' ) {
					return h('object'
					+ css`
						min-height: 100vh;
					`
					, {data: file_url, type: info.contentType, width: '100%', height: '100%' }
				)
			} else {
				return message(
					h('p', 'Download ')
					, h('p', h('a'
						, { href: file_url }
						, file.file_upname,'.',file.file_ext
					))
				)
			}
		}

		$.file.$stream().map( () => h.redraw() )

		return {
			view: () => view(Route.get())
		}
	}
)