

import m from 'bacta'
import { Light as DetailsPane } from '../components/detailspane2'
import * as elements from '../components/elements'
import {
	filterView
	,ListProcessing
} from '../components/filter'
import humanList from '../utils/human_list'
import * as R from 'ramda'
import { money as makeMoney } from '../utils/regExes'
import Promise from 'bluebird'
import { deBounceIdentical as identical } from '../utils/harth-identical'
import scrollableTable from '../components/scrollableTable'
import Permissions from '../models/permissions'
import Pencil from '../components/pencil'
import autocomplete from './autocomplete'
import byProp from '../utils/byProp'
import css from 'bss'
import Responsive from '../components/responsive'
import HarthUppy from '../components/harth-uppy'
import * as uuid from 'uuid'
import { prop } from '../../../stream'
/*
	Model, View, Intent

	Model: Any data storage.  Both the current state of the user interface, and
		data that will be persisted to a database layer.
		It also includes any functions for selecting or manipulating that data.

	Intent: Any functions that represent the user performing an action.  These functions represent
		what the user wants to do.  Whether it be creating a new invite, logging in.
		That's all representing the users intent.

	View: The view is a representation of the Model and provides affordances for the user
		to express their intent.  These affordances are input elements such as
		dropdowns, text inputs, checkboxes etc...

	Controller: The Controller binds the Model, View and Intent together.  It do some
		setup code and faciliate communication between the three other layers.

*/

/*
	Intent: Functions that help the user do what they want to do...
*/
function Intent(model, activeTab){


	const deleteTool = function(makeAPICalls){

		return makeAPICalls
			? Promise.all(
				model.deleteTools()
					.filter((t) => t )
					.map((t) => model.api.tools.remove(t) )
			)
			: model.checkMultiple().map((tO) => {
				model.deleteTools().push(tO.tools_id())
				model.toolDetail({})
				return true
			})
			&& model.form(
				R.differenceWith(
					(a, b) => a.tools_id() == b.tools_id()
					&& a.tools_name() == b.tools_name()
					,model.form()
					,model.checkMultiple()
				)
			)
			&& model.vm({})
			&& model.checkMultiple([])
			&& model.masterLIST(true)
	}


    function sendTools(){
		model.data.currentRoute('Nothing')
		return deleteTool(true)
		.then(function(){
			model.deleteTools([])
			return model.api.tools.patch.many(model.form().filter(R.prop('created')))
            .then(function(){
                return fetchTools()
                .then(function(){
					activeTab('Summary')
					model.checkMultiple([])
					model.vm({})
					return true
                })
            })
		})
    }

    function fetchTools(){
		return model.data.fetchTools({})
            .then((list) => {
				const ttindex = {}

                model.tools([])
				model.form([])
				model.deleteTools([])
				model.tools(list)
				model.tools().forEach((t) => {
					model.vm(t)
					model.form().push(model.cloningTool(false))
					if( !ttindex[t.tools_type()] && t.tools_type() ){
						model.toolTypeOptions().push({type: t.tools_type()})
						ttindex[t.tools_type()]
					}
				})
				model.masterLIST(true)
				model.vm({})
				model.loading(false)
				// model.data.routeids(model.tools().map(R.prop('contractrecognitionid')))
				return true
			})
			.then( fetchWorkList )
			.then(function(){
				model.loadingMeta(false)
				model.data.currentRoute('tools')
				return true
			})
    }

    function fetchWorkList(){
		model.loadingMeta(true)
        return Promise.all(
			R.uniqBy(
				byProp('crew_id')
				,model.tools()
			)
			.filter((a) => a.crew_id() )
			.map((a) =>
				model.data.api.contractors.fetch.workList({
					contractor_id: a.contractor_id
					,crew_id: a.crew_id
					,schedule_id: model.data.schedule_id()
					,schedule_version_id: model.data.schedule_version_id()
				})
			)
		)
			.then(
				R.pipe(
					R.unnest
					,R.map((a) =>
						({
							crew_id: a.crew_id
							,contractor_id: a.contractor_id
							,totalcount: Number(a.totalcount)
							,workList: a.workingarray
						})
					)
					,R.indexBy( R.prop('crew_id') )
				)
			)
			.then(model.workList)
			// .then( () => model.loadingMeta(false) )
			// .then( m.redraw )
    }

	function fetchResource(){
		return model.data.fetchResource({
			depth: 2
			, props: {
				resources: [
					'contractor_id'
					,'contractor_name'
					,'user_id'
				]
				,teams: [
					'crew_id'
					,'crew_name'
					,'contractor_id'
					,'user_id'
				]
			}
		})
		.then(function(res){
			model.resources(res)
			model.userResCrew([
				{
					crew_id: m.prop(null)
					,teamresourcename: 'Unassigned'
				}
			])
			res.forEach((r) => {
				model.userResCrew(
					model.userResCrew().concat(r.crews)
				)
			})
			return true
		})
		.then(m.redraw)
	}


    function editAllButton(){
		const refContainter = model.checkMultiple
		const errorFunction = R.F
		const originalArray = model.form()

        const activated =
			originalArray.length == refContainter().length
			&& originalArray.length
			|| model.filteredToolOptions().length == refContainter().length
			&& model.filteredToolOptions().length

		return elements.checkbox({
			onchange: () => {
				if( activated ){
					refContainter([])
					model.vm({})
				}
				else {
					refContainter([])
					model.vm({})
					refContainter(
						model.filteredToolOptions().length
							? model.filteredToolOptions()
							: model.form()
					)
				}
			}
			,title: "Set particular options for all Disciplines"
			,disabled: errorFunction(true) || originalArray.length == 0
		})
    }

    function getActivateDeleteButton(tool, returnTRUE){
        const name = model.vm().tools_name
		// const id = model.vm().tools_id

		const activated =
			model.checkMultiple()
			.findIndex( (chr) =>
				chr.tools_name() == tool.tools_name()
				&& chr.tools_id() == tool.tools_id()
			)

        return returnTRUE
			? activated && name
			: {
				onclick: (e) => {
					if(!e.currentTarget.checked){
						model.checkMultiple().splice(activated, 1)
						if (model.checkMultiple().length >= 1 ){
							model.vm(model.checkMultiple()[0])
						} else if (model.checkMultiple().length <= 0){
							model.vm({})
						}
					}
					else {
						model.vm(tool)
						model.checkMultiple().push(tool)
					}
				}
				,type: 'checkbox'
				,disabled:
					!tool.tools_name()
					|| !model.editToolPermissions()
				,checked: activated > -1
			}
	}

	model.loading(true)
	model.data.currentRoute('Nothing')
	model.data.initiateContractFetch
	.map(function(i){
		// const s = i.schedule_id
		const schange = i.schange
		const ochange = i.ochange || !model.data.routeInitialized()['tools']
		return !ochange && !model.loading()
			? schange
				? fetchWorkList()
				: Promise.resolve([])
			: fetchTools().then(function(){
				model.data.currentRoute('tools')
				fetchResource()
				i.schange = false
				i.ochange = false
				return true
			})
	})


	return {
		 sendTools
		, fetchTools
		, deleteTool
		, getActivateDeleteButton
		, fetchResource
		, fetchWorkList
		, editAllButton
	}
}

/*
	Model: Data storage and functions that interact with that data.

	The vm represents the view model, any thing to do with the state of the UI
	that doesn't map to a database record is stored on the view model.

	It could be whether or not a button is disabled due to a background process.
	Or it could be storing how many items are selected in order to render a tally.

*/
function Model(data){
	const loading = m.prop(true)
	const loadingMeta = m.prop(true)
	const tools = data.scoped([])
	const resources = m.prop([])
	const deleteTools = m.prop([])
	const workList = data.scoped({})
	const filteredToolOptions = data.scoped([])
	const toolDetail = data.scoped({})

	const missingPermissions =
		data.initiateContractFetch.map(() =>
			humanList(
				[
					'Project'
					,'Resource'
					,'Contract'
					,'Invoice'
				]
					.map((p)=>
						data['read'+p+'Permissions']()
							? ''
							: p
					)
					.filter((p) => p)
			)
		)


	// const forceRedraw =
		prop.merge([
			data.calculated
			,tools
		])
		.map(function(){
			// eslint-disable-next-line no-undef
			setTimeout(function(){
				m.redraw()
			}, 0)
			return null
		})

	const editToolPermissions = m.prop(false)
	const editAssignedToolPermissions = m.prop(false)

	const form = m.prop([])
	const pending = m.prop(false)
	const vm = m.prop({})

	function SaveDisabled(returnBoolean){
		const count =
			R.countBy(
				chr => chr.tools_name()
				,form()
			)
		const errorArray = [
			(d, prop='tools_name') => !d[prop]() ? `Tool/Machinery Name must not be empty ` : null
			,(d, prop='tools_name') =>	count[d[prop]()] > 1 ? `Tool/Machinery Names must not be duplicated, check ` + d[prop]() : null
		]

		return elements.errorAlert(
			`To save, `
			,' and '
			,''
			,form()
			,errorArray
			,returnBoolean
			,false
		)
	}

	const cloningTool = function(clonemarker){
		const clone = resetForm().tool
		clone.teamresourcename = clonemarker == false ? vm().teamresourcename : null
		clone.contractrecognitionid = clonemarker == false ? vm().contractrecognitionid : null
		clone.tools_id( clonemarker == false ? vm().tools_id() : uuid.v4() )
		clone.crew_id( clonemarker == false ? vm().crew_id() : null)
		clone.organization_id( vm().organization_id() )
		clone.tools_description( vm().tools_description() )
		clone.tools_name( clonemarker == false ? vm().tools_name() : vm().tools_name() + " (Clone)" )
		clone.tools_condition( vm().tools_condition() )
		clone.tools_location( vm().tools_location() )
		clone.tools_ownership( vm().tools_ownership() )
		clone.tools_type( vm().tools_type() )
		clone.newTool = clonemarker
		clone.assigneduser = vm().assigneduser

		if (clonemarker){
			checkMultiple().splice(
				checkMultiple()
				.findIndex( (chr) =>
					chr.tools_name() == vm().tools_name()
					&& chr.tools_id() == vm().tools_id()
				)
				, 1
			)
			checkMultiple().push(clone)
		}
		vm(clone)
		return clone
	}

	const resetForm = function(){
		return {
			tool: {
		        tools_id: m.prop(uuid.v4())
		        ,crew_id: m.prop(null)
				,teamresourcename: 'Unassigned'
				,tools_name: m.prop(null)
				,tools_condition: m.prop(null)
				,tools_location: m.prop(null)
		        ,tools_description: m.prop(null)
		        ,organization_id: m.prop()
		        ,tools_ownership: m.prop(null)
				,tools_type: m.prop('')
				,created: false
				,newTool: true
			}
		}
	}

	const userResCrew = data.scoped([])
	const edit = m.prop(false)
	const viewtype = ListTools
	const permissions = data.permissions

	permissions.map(function(p){
		editToolPermissions(Permissions.hasWrite(p, 'tool_management'))
		editAssignedToolPermissions(Permissions.hasWrite(p, 'assigned_tools'))
		m.redraw()
		return null
	})

	const filterFunction = {
		changeNames: [
			{ugly_name: 'tools_'}
			,{ugly_name: 'teamresourcename', better_name: 'Tools Team Name'}
		]
		,uniqueArrays: []
		,template: {
			tools_name: ""
			,tools_description: ""
			,tools_ownership: ""
			,tools_condition: ""
			,tools_location: ""
			,teamresourcename: ""
			,tools_type: ""
		}
	}

	let getfilters = function(button, marker){
		let datainput = filterFunction.ToolsFilters
		if ( !datainput ){
			filterFunction.ToolsFilters = {
				filters: m.prop([])
				,operationalFilters: m.prop([])
				,changeNames: filterFunction.changeNames
				,uniqueArrays: filterFunction.uniqueArrays
				,template: filterFunction.template
				,disabled: () => tools().length == 0
			}
			datainput = filterFunction.ToolsFilters
		}
		const gotfilters =
			!datainput
				? null
				: datainput.operationalFilters(
					filterView(
						datainput.template
						, datainput.filters()
						, datainput.changeNames
						, datainput.uniqueArrays
						, datainput.disabled
						, marker
						, data.scoped
					)
				)
		datainput != null ? datainput.filters( gotfilters.filters ) : null
		return gotfilters.generatedElements
	}

	getfilters(null, "Tool")

	const setOptions = function(value, prop){


		var nullIdEdit =
			'user_id' == prop
			|| 'crew_id' == prop

		const dtype = typeof checkMultiple()[0][prop] == 'function'
		value || value == false || value == '' || nullIdEdit
			? checkMultiple().forEach((d) => {
				d.created = true
				dtype
					? d[prop](value)
					: d[prop] = value
			})
			: null

		identical(tools(), form(), [], false, data.toolsIdentical)

		return dtype
			? checkMultiple()[0][prop]()
			: checkMultiple()[0][prop]
	}

	const underlinedHeader =
		a => m('h4.pt3.bw1.bb.pb2.b--black-20', a)

	const checkMultiple = data.scoped([])
	const toolTypeOptions = data.scoped([])

	function uploadmetadata({only}){
		return R.pickBy(
			(a, k) => a && (!only || !Object.keys(only).length || (only && only[k]) || k == 'user_id' || k == 'organization_id')
			,{
				organization_id: data.initiateContractFetch().organization_id
				, user_id: data.auth.stream().user_id
				, tools_id: R.last(checkMultiple() || {}).tools_id()
			}
		)
	}

	const detailPaneInputs = function(ctrl){
		function toolView(){
			return [

				elements.list([
					underlinedHeader('Tool Details - ')
					,m('button.btn.btn-secondary'
						, {
							disabled:
								!!ctrl.model.SaveDisabled(true)
								|| ctrl.model.checkMultiple().length > 1
								|| !ctrl.model.vm().tools_name
							,onclick: () => {
								const newT = ctrl.model.cloningTool(true)
								newT.created = true
								ctrl.model.form().unshift(newT)
								ctrl.model.masterLIST(true)
								ctrl.model.vm(newT)
								m.redraw()
							}
							,style: {
								backgroundColor: 'transparent'
								, border: 'solid 1px #3380c2'
								, position: 'relative'
								, top: '-0.1em'
								, height: '2.5em'
								, width: '5em'
								, color: '#434aa3'
							}
							,title:
								`Copy the details of this tool to create a new tool with identical specifications`
						}
						, 'Clone'
					)
				])

				,checkMultiple().length == 1
				? elements.list([
					m('label.control-label', 'General name of this Tool')
					,Pencil(
						() => checkMultiple()[0].tools_type()
						,() =>
							autocomplete.all(
								toolTypeOptions
								,(v) => {
									const ReturnedAutocompleteObj =
										toolTypeOptions()
										.find((pc) =>
											pc['type']
											== v
										)

									v || v == ''
										? setOptions(
											ReturnedAutocompleteObj
												? ReturnedAutocompleteObj['type']
												: R.trim(v.toUpperCase())
											, 'tools_type'
										)
										: null

									return ReturnedAutocompleteObj
										? ReturnedAutocompleteObj['type']
										: checkMultiple()[0].tools_type()
								}
								,'type'
								,() => ({
									title: `Select the general name from existing entries`
									,placeholder: 'Something like, Hammer'
									,key: checkMultiple()[0].key
								})
							)
					)
				])
				: ''

				,checkMultiple().length > 1
					? elements.list([
						m('label.control-label.mb1', 'Tool Name - Designation')
						,ctrl.model.checkMultiple()
							.map( s =>
								m(
									'p.pv1.ma0'
									, s['tools_type']()
									+' '
									+ s['tools_name']()
									+ ' Assigned to '
									+ 										(
											ctrl.model.userResCrew().find((c) =>
												c.crew_id() == s.crew_id()
											) || { teamresourcename:'Busy'}
										).teamresourcename

								)
							)
					])
					: elements.list([
						m('label.control-label', 'Tool Name ')
						,Pencil(
							() => checkMultiple()[0].tools_name()
							,() =>
								elements.textInput(
									(value) => setOptions(value, 'tools_name')
									,{}
								)
						)
					])

				,elements.list([
					m('label.control-label', 'Tool Description ')
					,Pencil(
						() => checkMultiple()[0].tools_description()
						,() =>
							elements.textInput(
								(value) => setOptions(value, 'tools_description')
								,{}
							)
					)
				])

				,elements.list([
					m('label.control-label', 'Ownership ')
					,Pencil(
						() => checkMultiple()[0].tools_ownership()
						,() =>
							elements.textInput(
								(value) => setOptions(value, 'tools_ownership')
								,{}
							)
					)
				])

				,elements.list([
					m('label.control-label', 'Condition ')
					,Pencil(
						() => checkMultiple()[0].tools_condition()
						,() =>
							elements.textInput(
								(value) => setOptions(value, 'tools_condition')
								,{
									placeholder: "New"
								}
							)
					)
				])

				,elements.list([
					m('label.control-label', 'Location ')
					,Pencil(
						() => checkMultiple()[0].tools_location()
						,() =>
							elements.textInput(
								(value) => setOptions(value, 'tools_location')
								,{
									placeholder: "WAREHOUSE A - ROW 1A"
								}
							)
					)
				])


				, !ctrl.model.data.readResourcePermissions()
				&& !editAssignedToolPermissions()
					? 'Requires Resource Permissions to Assign'
					: elements.list([
						m('label.control-label', 'Assigned ')
						,Pencil(
							() => checkMultiple().length == 1
								|| checkMultiple().every((a) =>
									a.teamresourcename
									== checkMultiple()[0].teamresourcename
								)
								? checkMultiple()[0].teamresourcename
								: ''
							,() =>
								autocomplete.strict(
									ctrl.model.userResCrew
									,R.when( Boolean, function(v){

										setOptions(v.crew_id(), 'crew_id')
										setOptions(v.teamresourcename, 'teamresourcename')

										ctrl.model.data.getLocation(checkMultiple(), SaveDisabled)

										return checkMultiple()[0].teamresourcename
									})
									,'teamresourcename'
									,() => ({
										title: 'Assign a tool'
										,disabled: !ctrl.model.userResCrew().length
											|| 												!ctrl.model.editToolPermissions()
												&& !ctrl.model.editAssignedToolPermissions()

											|| 												!ctrl.model.editToolPermissions()
												&& ctrl.model.editAssignedToolPermissions()
												&& checkMultiple().some((t) => t.crew_id())
												&& checkMultiple().some((t) =>
													ctrl.model.userResCrew().find((chr) =>
														chr.crew_id()
														== t.crew_id()
													).user_id()
													!= ctrl.model.data.auth.stream().user_id
												)

									})
								)
						)
					])

				,elements.list([
					m('label.control-label', 'Expenditure ')
					,!ctrl.model.data.calculated()
						? ' ... '
						: makeMoney(
							R.sum(
								checkMultiple().map((t) =>
									ctrl.model.data.coalatedSelectedVersion()[t.contractrecognitionid]
										? 											ctrl.model.data.coalatedSelectedVersion()[t.contractrecognitionid].currentProfits
											+ ctrl.model.data.coalatedSelectedVersion()[t.contractrecognitionid].outstandingProfits

										: 0
								)
							)
						)
				])
				,elements.list([
					m('label.control-label', 'Forecasted Expenditure ')
					,!ctrl.model.data.calculated()
						? ' ... '
						: makeMoney(
							R.sum(
								checkMultiple().map((t) =>
									ctrl.model.data.coalatedSelectedVersion()[t.contractrecognitionid]
										? ctrl.model.data.coalatedSelectedVersion()
											[t.contractrecognitionid]
											.forecastedProfits
										: 0
								)
							)
						)
				])

				,m(HarthUppy, {
					getFields: () => uploadmetadata({})
					, data
					, listOnly: checkMultiple().length > 1
				})

				, m('br')
			]
		}

		return [
			checkMultiple().length
				? [
					elements.list(
						 [
							ctrl.model.SaveDisabled(true)
							|| ctrl.model.data.toolsIdentical()
								? m('button.btn', {disabled: true}, 'Save')
								: elements.action(
									'Save'
									, ctrl.intent.sendTools
									, ctrl.model.pending
									, 'primary'
									, R.F
									, ''
									,{style: {
										opacity:
											ctrl.model.editToolPermissions() ? 1 : 0
										,transition: '0.5s'
									}}
								)
						]
						.concat(
							elements.undoDiscard({
								discard:
									!ctrl.model.vm().newTool
									? {
										label: 'Discard'
										,attrs: {
											onclick: () => ctrl.intent.deleteTool()
											,disabled:
												ctrl.model.vm().tools_name == null
												&& ctrl.model.checkMultiple().length == 0
													? R.always(true)
													: ctrl.model.pending()
										}
									}
									: null
								,doneUndo: {
									label:
										ctrl.model.data.toolsIdentical()
										? 'Done'
										: ''
									,attrs: {
										onclick: () => {
											ctrl.model.form([])
											ctrl.model.checkMultiple([])
											ctrl.model.tools().forEach((t) => {
												ctrl.model.vm(t)
												ctrl.model.form().push(
													ctrl.model.cloningTool(false)
												)
											})
											ctrl.model.vm({})
											ctrl.intent.sendTools()
											ctrl.model.masterLIST(true)
										}
										,title: `Discard all changes made to these tools since last saving them`
										,style: {
											opacity: ctrl.model.editToolPermissions() ? 1 : 0
											,transition: '0.5s'
											,width: '5em'
										}
									}
								}
							})
						)
					)
					,ctrl.model.SaveDisabled()
					,toolView()
				]
				: null
		]
	}

	const detailsPaneOpen = () => checkMultiple().length >= 1

	const CONSTANTtools = m.prop([])
	const masterLIST = function(mcreate){
		if (mcreate){
			CONSTANTtools(
				R.sortBy(
					R.prop('tools_name')
					,[].concat(
						R.unnest(
							R.partition(
								(c) => c.created,
								form()
							)
						)
					)
				)
			)

			identical(tools(), form(), [], false, data.toolsIdentical)
		}
		return ListProcessing(
			CONSTANTtools()
			,filterFunction.ToolsFilters.filters()
			,null
			,filteredToolOptions
		)
	}

	const createNewTool =
		() => {
			checkMultiple([])
			let newT = resetForm().tool
			newT.created = true
			checkMultiple().push(newT)
			form().unshift(newT)
			masterLIST(true)
			vm(newT)
			m.redraw()
		}

	const discardChangesButton =
		(ctrl) =>
			ctrl.model.data.toolsIdentical()
			?  m("button.btn"
				,{
					disabled: true
					,onclick: () => {}
					,title: `Discard all changes made to these tools since last saving them`
					,style: {
						transition: '0.5s'
					}
				}
				, 'Discard Changes'
			)
			: m("button.btn.btn-warning"
				,{
					disabled: false
					,onclick: () => {
						ctrl.model.form([])
						ctrl.model.checkMultiple([])
						ctrl.model.tools().forEach((t) => {
							ctrl.model.vm(t)
							ctrl.model.form().push(
								ctrl.model.cloningTool(false)
							)
						})
						ctrl.model.vm({})
						ctrl.intent.sendTools()
						ctrl.model.masterLIST(true)
					}
					,title: `Discard all changes made to these tools since last saving them`
					,style: {
						transition: '0.5s'
					}
				}
				, 'Discard Changes'
			)

	return {
		tools
		, form
		, data
		, vm
		, cloningTool
		, edit
		, resources
		, resetForm
		, SaveDisabled
		, viewtype
		, request: data.request
		, editToolPermissions
		, permissions
		, filterFunction
		, getfilters
		, editAssignedToolPermissions
		, loading
		, masterLIST
		, userResCrew
		, deleteTools
		, api: data.api
		, missingPermissions
		, workList
		, detailsPaneOpen
		, detailPaneInputs
		, checkMultiple
		, pending
		, toolDetail
		, loadingMeta
		, filteredToolOptions
		, createNewTool
		, discardChangesButton
		, toolTypeOptions
	}
}

/*
	The controller is the glue that sticks the model and intent together.
	It exposes the model and intent to the view by returning them.

	The controller "controls" how each layer interacts.
*/

function Main({ attrs: { data} }){

	let model = Model(data)
	let activeTab = m.prop('Summary')
	let intent = Intent(model, activeTab)

	return {
		view(){
			return view({ model, intent, activeTab })
		}
	}
}

/*
	The view provides the user ways to transfer their intent into really action within the system.
	The view accesses data from the model layer, and binds intent functions to user inputs.
*/

const view = function(ctrl){
	return m('div'
		,ctrl.model.viewtype(ctrl)
		,DetailsPane(
			ctrl.model.detailsPaneOpen
			,ctrl.model.checkMultiple().length > 1
				? 'Edit Multiple Tools'
				: ctrl.model.vm().tools_name
					? ctrl.model.vm().tools_name()
					: ''
			,() => ctrl.model.detailPaneInputs(ctrl)
		)
	)
}


const ListTools = function(ctrl){

	const tooldata =
		(tool) => ({
			'Tool Name': tool.tools_name()
			,'Description': tool.tools_description()
			,'Ownership': tool.tools_ownership()
			,'Condition': tool.tools_condition()
			,'Location': tool.tools_location()
			,'Assigned':
				!ctrl.model.data.readResourcePermissions()
				&& !ctrl.model.editAssignedToolPermissions()
				? 'Requires Resource Permissions or Tool Assignment Permissions'
				: !tool.crew_id()
				? 'Unassigned'
				: ctrl.model.loadingMeta()
				? ' ... '
				:(
					ctrl.model.userResCrew().find((c) =>
						c.crew_id() == tool.crew_id()
					) || { teamresourcename: 'Busy'}
				).teamresourcename
			,'Expenditure': !ctrl.model.data.calculated()
				? ' ... '
				: makeMoney(
					ctrl.model.data.coalatedSelectedVersion()[tool.contractrecognitionid]
					? 												ctrl.model.data.coalatedSelectedVersion()[tool.contractrecognitionid].currentProfits
						+ ctrl.model.data.coalatedSelectedVersion()[tool.contractrecognitionid].outstandingProfits

					: 0
				)
			,'Forecasted Expenditure': !ctrl.model.data.calculated()
				? ' ... '
				: makeMoney(
					(
						ctrl.model.data.coalatedSelectedVersion()
							[tool.contractrecognitionid]
						|| {}
					)
					.forecastedProfits
				)
			,' ': !ctrl.model.data.readProjectPermissions()
				? "Requires Project Permissions"
				: !tool.crew_id()
					? 'Assign this tool to a team to track its current works'
					: ctrl.model.loadingMeta()
						? ' ... '
						: ctrl.model.workList()[tool.crew_id()]
						&& !ctrl.model.workList()[tool.crew_id()].workList.length
						&& ctrl.model.workList()[tool.crew_id()].totalcount
							? 'Busy'
							: ctrl.model.workList()[tool.crew_id()]
							? m("button.btn."
								+ (
									ctrl.model.toolDetail()[tool.crew_id()]
										? '.btn-warning'
										: ''
								)
								, {
									disabled: false
									,onclick: () => {

										ctrl.model.toolDetail()[tool.crew_id()]

											? delete ctrl.model.toolDetail()[tool.crew_id()]
											: ctrl.model.toolDetail(
												R.merge(
													ctrl.model.toolDetail()
													,{
														[tool.crew_id()]: {
															projectList:
																R.pipe(
																	R.unnest
																	,R.uniqBy(R.prop('discipline_id'))
																	,R.groupBy(R.prop('project_id'))
																	,R.map((p) => ({
																		project: p[0].project_name
																		,disciplines: p
																	}))
																	,R.toPairs
																	,R.map(R.last)
																	,R.unnest
																)(
																	[tool.crew_id()]
																	.map((c) => ctrl.model.workList()[c].workList)
																)
														}
													}
												)
											)
									}
									,style: {
										transition: `0.2s;`
										,opacity: `1;`
									}
									,title: ctrl.model.toolDetail()[tool.crew_id()]
										? "Hide the next organized work for this tool"
										: "Show the next organized work for this tool"
								}
								,ctrl.model.toolDetail()[tool.crew_id()]
									? "Hide Forecast"
									: "Show Forecast"
							)
							:  'No Work Allocated'
		})

	const largeToolsTable = () =>
		scrollableTable.advanced({
			scoped: ctrl.model.data.scoped
			,resize$: ctrl.model.data.resize$
			,alwaysopen: true
			,rows:
				ctrl.model.masterLIST()
				.map((tool, tindex) => ({
					key: tool.key
					,selection: ctrl.intent.getActivateDeleteButton(tool)
					,header:
						tindex != 0
						? ''
						: m('.header'
							+ css`
								font-weight: bold;
								display: flex;
								justify-content: space-between;
								align-items: center;
								padding-bottom: 0.5em;
							`
							,m('span'
								+ css`
									font-weight: bold;
								`
								,''
							)
							,m('.data'
								+ css`
									display: grid;
									grid-template-rows: 0.25fr 0.5fr;
									overflow: hidden;
								`
								,m(`button.pl2`
									+ css`
										backgroundColor: transparent;
										transition: 0.2s;
										color: #434aa3;
										border: none;
									`
									.$hover(`
										opacity: 0.8;
									`)
									.$active(`
										opacity: 0.5;
									`)
									,{
										onclick: ctrl.model.createNewTool
										,disabled: !!ctrl.model.SaveDisabled(true)
										,title: "Create tools to track against teams and project locations"
									}
									, 'Create a New Tool'
								)
							)
							, tindex == 0 && false
							? ctrl.model.discardChangesButton(ctrl)
							: m('span'
								+ css`
									font-weight: bold;
								`
								,''
							)
						)
					,layouts: {
						expanded: ({ x, headers, i }) =>
							m('.expanded'
								+ css`
									display: grid;
									justify-content: space-between;
									grid-template-columns: 1fr;
								`
								, m('.data'
									+ css`
										display: grid;
										justify-content: space-between;
										grid-template-columns: 0.75fr 1.15fr 0.75fr 0.65fr 0.65fr 0.65fr 0.9fr 0.9fr 1.15fr;
										overflow: hidden;
										gap: 1em;
										align-items: start;
										align-content: start;
									`
									, headers
										.map(
											k => [
												i == 0
												? m('.data'
													+ css`
														display: grid;
														grid-template-rows: 0.25fr 0.5fr;
														overflow: hidden;
													`
													,[m('label.control-label', k), x[k]]
												)
												: m('.data'
													+ css`
														display: grid;
														overflow: hidden;
													`
													,x[k]
												)
											]
										)

								)
								,m('hr')
								,ctrl.model.toolDetail()[tool.crew_id()]
								? [{
									project: 'Project'
									,disciplines:'Disciplines'
								}]
								.concat(ctrl.model.toolDetail()[tool.crew_id()].projectList)
								.map((k) =>
									m('.data'
										+ css`
											display: grid;
											grid-template-columns: 0.36fr 0.64fr;
											overflow: hidden;
											align-items: start;
											align-content: start;
										`
										,['project', 'disciplines']
										.map((h) =>
											m('.data'
												+ css`
													display: grid;
													padding-top: 0.5em;
													margin-right: 0.75em;
													padding-right: 0.75em;
												`
												,k['project'] == 'Project'
												? m('label.control-label.f4', k[h])
												: !h
												? ''
												: h == 'project'
												? m(`li.pa0`, k[h])
												: k[h].map((d) => d.discipline_name + ' - ' + d.discipline_description).join(', ')
											)
										)
									)
								)
								: ''
							)
					}
					,data: tooldata(tool)
				}))
		})

	const smallToolsTable = () =>
		scrollableTable.advanced({
			scoped: ctrl.model.data.scoped
			,resize$: ctrl.model.data.resize$
			,alwaysopen: true
			,rows:
				ctrl.model.masterLIST()
				.map((tool, tindex) => ({
					key: tool.key
					,header:
						m('span'
							+ css`
								font-weight: bold;
								display: flex;
								justify-content: space-between;
								align-items: center;
								padding-bottom: 0.5em;
							`
							,m('input'
								+ css`
									width: 25px;
									height: 25px;
									margin: 0px;
								`
								.$media('(hover: hover)',
									css`
										width: 20px;
										height: 20px;
									`
								)
								,ctrl.intent.getActivateDeleteButton(tool)
							)
							,m(`button.pl2`
								+ css`
									backgroundColor: transparent;
									transition: 0.2s;
									opacity: 1;
									color: #434aa3;
									border: none;
								`
								.$hover(`
									opacity: 0.8;
								`)
								.$active(`
									opacity: 0.5;
								`)
								,{
									onclick: ctrl.model.createNewTool
									,disabled: !!ctrl.model.SaveDisabled(true)
									,title: "Create tools to track against teams and project locations"

								}
								, 'Create a New Tool '
							)
							, tindex == 0 && false
							? ctrl.model.discardChangesButton(ctrl)
							: m('span'
								+ css`
									font-weight: bold;
								`
								,''
							)
						)
					,layouts: {
						expanded: ({ x }) =>
							m('.expanded'
								+ css`
									display: grid;
									justify-content: space-between;
									grid-template-columns: 1fr;
								`
								,m('.data'
									+ css`
										display: grid;
										justify-content: space-between;
										grid-template-columns: 0.25fr 0.75fr;
										padding-bottom: 2em;
									`
									,['Tool Name', 'Description']
									.map((k) =>
										m('.data'
											+ css`
												display: grid;
												grid-template-rows: 0.25fr 0.25fr;
												padding-right: 0.25em
											`
											,[
												m('label.control-label.f4', k)
												, k != 'Description'
													? x[k]
													: x[k] == tool.tools_name()
														? x[k]
														: [x[k], tool.tools_name()].filter(R.identity).join(' - ')

											]
										)
									)
								)


								, m('.data'
									+ css`
										display: grid;
										justify-content: space-between;
										grid-template-columns: 0.25fr 0.25fr 0.25fr 0.25fr;
										padding-bottom: 1em;
									`
									,['Ownership', 'Condition', 'Location', 'Assigned']
									.map((k) =>
										m('.data'
											+ css`
												display: grid;
												grid-template-rows: 0.25fr 0.25fr;
												padding-right: 0.5em;
											`
											,[m('label.control-label.f4', k), x[k]]
										)
									)
								)

								, m('.data'
									+ css`
										display: grid;
										justify-content: space-between;
										grid-template-columns: 0.5fr 0.5fr;
									`
									,['Expenditure', 'Forecasted Expenditure']
									.map((k) =>
										m('.data'
											+ css`
												display: grid;
												grid-template-rows: 1fr;
												padding-right: 0.5em;
											`
											,elements.list([
												m('label.control-label.f4', k)
												,x[k]
											])
										)
									)
								)


								, m('.data'
									+ css`
										display: grid;
										justify-content: space-between;
										grid-template-columns: 1fr;
										padding-bottom: 1em;
									`
									,[' ']
									.map((k) =>
										m('.data'
											+ css`
												display: grid;
												grid-template-rows: 1fr;
												padding-right: 0.5em;
											`
											,[m('label.control-label.f4', k), x[k]]
										)
									)
								)

								,ctrl.model.toolDetail()[tool.crew_id()]
								? [{
									project: 'Project'
									,disciplines:'Disciplines'
								}]
								.concat(ctrl.model.toolDetail()[tool.crew_id()].projectList)
								.map((k) =>
									m('.data'
										+ css`
											display: grid;
											grid-template-columns: 0.3fr 0.7fr;
											overflow: hidden;
											align-items: start;
											align-content: start;
										`
										,['project', 'disciplines']
										.map((h) =>
											k['project'] == 'Project'
											? m('label.control-label.f4', k[h])
											: h == 'project'
											? m(`li.pa0`, k[h])
											: k[h].map((d) => d.discipline_name + ' - ' + d.discipline_description).join(', ')
										)
									)
								)
								: []
							)

					}
					,data: tooldata(tool)
				}))
		})


	const toolsTable = () =>
		m(Responsive
			,
			{
				breakpoints: {
					mobile:0
					,desktop:900
					,tablet:700
					,hd:1800
				}
			}
			,({ hd, desktopOnly, mobileOnly, tabletOnly }) => [
				hd( largeToolsTable )
				,desktopOnly( largeToolsTable  )
				,mobileOnly( smallToolsTable )
				,tabletOnly( smallToolsTable )
			]
		)


	return m('div.harth.mb6',[


		elements.list([
			ctrl.model.getfilters(null, "Tool")
			,ctrl.intent.editAllButton()
		])

		,elements.list([
			ctrl.model.SaveDisabled(true)
			|| ctrl.model.data.toolsIdentical()
				? m('button.btn'
					, {
						disabled: true
						,style: {
							height: '2.5em'
							,width: '5em'
						}
					}
					, 'Save'
				)
				: elements.action(
					'Save'
					, ctrl.intent.sendTools
					, ctrl.model.pending
					, 'primary'
					, R.F
					, ''
					,{style: {
						opacity:
							ctrl.model.editToolPermissions() ? 1 : 0
						,height: '2.5em'
						,width: '5em'
						,transition: '0.5s'
					}}
				)
			,ctrl.model.discardChangesButton(ctrl)
		])

		,ctrl.model.masterLIST().length
			? m('div'
				, { style:
					{ transition: 'opacity 1s'
					, opacity: ctrl.model.loading() ? 0 : 1
					}
				}
				, ctrl.model.loading() ? null : toolsTable()
			)
			: ctrl.model.loading()
			? elements.centeredSpinner()
			: m('div'
				+ css`
				display: grid;
				height: 15vh;
				align-content: center;
				`
				,ctrl.model.editToolPermissions()
				? m(`button`
					+ css`
						backgroundColor: transparent;
						transition: 0.2s;
						opacity: 1;
						color: #434aa3;
						border: solid 1px #3380c2;
						align-items: center;
						display: grid;
						position: relative;
						height: 2.5em;
						width: 10em;
						margin-right: auto;
						margin-left: auto;
						border-radius: 0.25em;
						`
					.$hover(`
						opacity: 0.8;
					`)
					.$active(`
						opacity: 0.5;
					`)
					,{
						onclick: ctrl.model.createNewTool
						,disabled: !!ctrl.model.SaveDisabled(true)
						,title: "Create tools to track against teams and project locations"
					}
					,'Create a New Tool '
				)
				: null
				,elements.alert('info', `Tools can be tracked financially and secured against teams to promote security and liability`)
			)
	])
}




/*
	In order for mithril to mount this component.  We have to expose these two properties.
*/
export default Main