/* eslint-disable */
export default {
	dark: 'var(--color-dark)'// '#24394b'
	,mid: 'var(--color-mid)'//'#3c4f5f' // 'rgb(12, 15, 39)'
	,light: 'var(--color-light)'// '#e6eefa'
	,selected: 'var(--color-selected)' //'#f1f9ff'
	,muted: 'var(--color-muted)' // '#c0c4c7'
	,empty: 'var(--color-empty)' //'#577288'
	,github_selected: 'var(--color-github-selected)' // '#4078C0'
	,blackboard: 'var(--color-blackboard)' // '#2F3031' // 'hsl(254, 72%, 5%)'
}
