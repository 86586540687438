/* globals process, location, window */
import css from 'bss'
import * as elements from '../components/elements'
import m from 'bacta'
import * as R from 'ramda'
import NotificationBar from '../components/notification'
import Form from '../components/form'

const alert = NotificationBar.Notifications.alertError

const glow = css
	.$keyframes({
		from: {
			'box-shadow': '0px 0px 10px 2px rgba(255,255,255,0.5)'
			,'transform': 'translateY(0px)'
		}
		,to: {
			'box-shadow': '0px 0px 20px 2px rgba(255,255,255,0.1)'
			,'transform': 'translateY(0px)'
		}
	})

const fadeIn = css
	.$keyframes({
		from: 'opacity: 0'
		,to: 'opacity: 1'
	})

const formLayout = (...children) =>
	m('.form-layout'
		+ css`
			display: grid;
			justify-content: center;
			align-content: center;
		`
		.$nest('*', `
			max-width: 40em;
		`)
		, children
	)

const modalForm = (selector, ...children) =>
	m('.modal-form'
		+ css`
			background-color: white;

			display: grid;
			min-width: 21em;
			gap: 3em;
			padding: 2em;
			justify-content: normal;
			animation ${fadeIn} 1s, ${glow} 5s ease-in-out alternate infinite;
		`
		.desktop(`
			border-radius: 0.25em;
			min-width: 32em;
			padding: 3em;
		`)
		.$nest('input', `
			--size: 2em;
		`)
		+ selector
		, children
	)

const fatButton = (
	selector
	, ...args
) =>
	m(
		( selector || 'button.btn.btn-primary' )
		+ fatButton.css
		,...args
	)

fatButton.css =
	css`
		border: none;
		padding: 0.7em;
		color: white;
		background-color: var(--theme, blue);
		font-size: 1.2em;
		border-radius: 0.2em;
		transition: 0.5s;
	`
	.$hover(`
		color: var(--theme);
		box-shadow: 0 0 0px 1px var(--theme) inset;
		background-color: white;
	`)
	.$disabled(`
		color: white;
	`)

function NavBar(){
	const link =
		css
			.c('white')
			.textDecoration('none')
			.$visited(css.c('white'))

	return {
		view(){
			// @ts-ignore
			return m('nav'
				+ css`
					background-color: #030207;
					display: grid;
					height: 3.5em;
					align-content: center;
					border-bottom: solid 2px #140927;
					padding: 0em 0.5em;
					padding-right: 0.8em;
					grid-auto-flow: column;
					transform: translateY(-100%);
				`
				.$animate('ease-in-out 0.2s 1s forwards', {
					to: {
						transform: 'translateY(0%)'
					}
				})
				, m('img'
					+ css`
						max-height: 2.5em;
						opacity: 0;
					`
					.$animate('ease-in-out 0.5s 1.2s forwards', {
						to: {
							opacity: 1
						}
					})
					, { src: 'https://odin.harth.io/assets/android-icon-192x192.png' })
				, m('ul'
					+ css`
						display: grid;
						grid-auto-flow: column;
						list-style: none;
						align-content: center;
						justify-content: end;
						gap: 1em;
						transform: translateX(100%);
						margin: 0em;
					`
					.$animate('ease-in-out 0.5s 1.4s forwards', {
						to: {
							transform: `translateX(0%)`
						}
					})
					, location.href.includes('/access/login')
					|| m('li'
						+ css`
							list-style: none;
						`
						, m('a' + link
							, { href: '/access/login', oncreate: m.route.link }
							, 'Login'
						)
					)
					, m('li'
						, m('a' + link
							, { href: process.env.HARTH_MARKETING_URL + '/odin/signup/' }
							, 'Sign up'
						)
					)
					,m('li'
						, m('a' + link
							, { href: process.env.HARTH_MARKETING_URL }
							, 'About'
						)
					)
				)
			)
		}
	}
}

function Login({ attrs: {data} }){

	const loading = m.prop(false)
	const success = m.prop(false)
	const error = m.prop(false)

	const user_username = m.prop(null)
	const form = {
		user_username
		,user_email: user_username
	}

	async function onsubmit(){
		loading(true)

		let payload = JSON.parse(JSON.stringify(form))

		try {

			await data.api.auths.magicLink(payload)
			loading(false)
			success(true)
		} catch (e) {
			loading(false)
			alert( e.message )
			error('Try Again')
		}
	}

	function view(){
		return formLayout(
			modalForm(
				css``
				, m('h3'
					+ css`
						font-weight: 700;
						text-align: center;
					`
					, success() ? 'Thank you!' : 'Welcome to Odin'
				)
				,success()
				? m('p'+ css`text-align: center;`, 'Please check your email for a login link')
				: m(Form, { onsubmit: onsubmit },
					() => [
						elements.formInput([
							'Username or Email'
							, form.user_username
							,{ type: 'text'
							, autofocus: true
							, required: true
							}
						])
						, m('.btns'
							+ css`
								display: grid;
								gap: 0.5em;
								--theme: ${ error() ? 'red' : 'inherit' }
							`
							, fatButton(
								'button[type=submit]'

								, {disabled: loading()}
								, error() || 'Login via Email'
							)
						)
					]
				)
			)
		)
	}

	return { view }
}

function Verify({ attrs: { data } }){
	let vm = {
		message: m.prop("Please wait while we verify your account")
	}
	let id = m.route.param('user_id')
	let token = m.route.param('user_verification_token')

	data.api.users.patch.verification_token(token, id)
		.then(function(){
			vm.message([
				'Your Account has been validated, please '
				,m('a', { style: { textDecoration: 'underline' }, href: '/access/login', oncreate: m.route.link }, 'Log In')
				,' to complete the verification process.'
			])
			m.redraw()
		})
		.catch(function(){
			vm.message('Sorry, your verification token does not seem to be valid.  It may have expired.')
			m.redraw()
		})

	const ctrl = {vm}

	return {
		view(){
			return formLayout(
				modalForm(''
					, m(''
						, m('h4', 'Welcome to Odin!')
						, m('.message', ctrl.vm.message())
					)
				)
			)
		}
	}
}

function SignUp({ attrs: {data} }){

	const pending = data.scoped(false)

	function submit(){
		pending(true)
		return data.api.users.create.signup(payload())
			.then(function(){
				m.route.set('/access/checkEmail/verify')
			})
			.catch(function(error){
				alert( error.message )
			})
			.finally( () => pending( false ))
	}

	const form = data.scoped({
		user_email: ''
		,user_username: ''
		,user_name: ''
		,user_password: ''
		,user_password2: ''
	})

	const errors = data.scoped({})
	const values = data.scoped({})

	const validate = ({ user_password: a, user_password2: b }) =>
		a != b
		? {
			'user_password2': 'Passwords do not match!'
		}
		: {}

	const payload = R.pipe(
		() => values()
		,R.omit(['user_password2'])
		,JSON.stringify
		,JSON.parse
	)

	const view = () =>
		formLayout(
			modalForm(
				css.desktop(
					css`
						min-width: 30em;
						padding 2em 3em;
					`
				)
				, m('h3'
					+ css`
						font-weight: 700
					`
					, 'Welcome to Odin'
				)
				, m(Form
					,
					{ onsubmit: submit
					, initialValues: form
					, values
					, errors
					, validate
					}

					, ({ values, changed, errored }) => [
						elements.formInput([
							'Email Address'
							, null
							,{ type: 'email'
							, placeholder: "admin@yourorganization.com"
							, autofocus: true
							, title: 'The email address we will use to reset your password and to notify you of alerts relating to your Odin data.'
							, required: true
							, name: 'user_email'
							, value: values.user_email
							}
						])
						,elements.formInput([
							'Username'
							, null
							,{ type: 'text'
							, placeholder: 'JGreenwood'
							, title: 'Used to assign you to tasks and refer to you throughout Odin'
							, required: true
							, name: 'user_username'
							, value: values.user_username
							}
						])
						,elements.formInput([
							'Name'
							, null
							,{ type: 'text'
							, placeholder: 'Jonny Greenwood'
							, required: true
							, name: 'user_name'
							, value: values.user_name
							}
						])
						,elements.formInput([
							'Password'
							, null
							,{ type: 'password'
							, minlength: 6
							, required: true
							, name: 'user_password'
							, value: values.user_password
							}
						])
						,elements.formInput([
							'Re-enter Password'
							, null
							,{ type: 'password'
							, title: 'Confirm your password by entering it again.'
							, required: true
							, name: 'user_password2'
							, value: values.user_password2
							}
						])
						, fatButton(
							'input[type=submit]'
							,
							{ disabled:
								!changed()
								|| errored()
								|| pending()
							}
							, 'Create Account'
						)
					]
				)
			)
		)

	return { view }
}


function Forgot({ attrs: {data} }){
	let form = { user_username: m.prop() }
	form.user_email = form.user_username

	let submit = function(){
		data.api.mail.create.forgottenPassword(form)
			.then(function(){
				m.route.set('/access/checkEmail/forgot')
			})
			.catch(function(e){
				alert( e.message )
			})
	}

	const ctrl = {form, submit}

	function view(){
		return formLayout(
			modalForm(
				css`
					& * {
						margin: 0em;
					}
				`
				,m('h4', 'Password Reset')
				,elements.formInput(['Username or Email', ctrl.form.user_username, { type: 'text' } ])
				,m('.info'
					,m('p', 'After you hit reset a verification email will be set to the email account you used to create your Harth account.')
					,m('p', 'The link in that email will re-direct you to a secure screen to create a new password.')
				)
				,m('button.btn.btn-primary', { onclick: ctrl.submit }, 'Reset'),
			)
		)
	}

	return {
		view
	}
}

function Invite({ attrs: { data } }){

	const omit = R.omit
	const redraw = () => m.redraw()
	const routeParam=  k => m.route.param(k)
	const form =
		{ user_email: m.prop( routeParam('user_email') )
		, invite_id: m.prop( routeParam('invite_id') )
		, user_id: m.prop( routeParam('user_id') )
		, user_name: m.prop('')
		, user_username: m.prop('')
		, user_password: m.prop('')
		, user_password2: m.prop('')
		}

	/**
	 * @typedef $State
	 * @property {function} VerifyingURL
	 * @property {function} VerifiedExistingUser
	 * @property {function} InvalidURL
	 * @property {function} SavingForm
	 * @property {function} EditingSignUpForm
	 * @property {function} UnexpectedError
	 */

	/**
	 * @type {$State}
	 */
	const State =
		{ VerifyingURL: () => [State.VerifyingURL]
		, VerifiedExistingUser:
			(organization_name) =>
				[State.VerifiedExistingUser, organization_name]
		, InvalidURL: (why) => [State.InvalidURL, why]
		, SavingForm: () => [State.SavingForm]
		, EditingSignUpForm: () => [State.EditingSignUpForm]
		, UnexpectedError: () => [State.UnexpectedError]
		}

	const model =
		{ verifyURL(){
			data.api.invites.ok(
				form.user_id()
				? { invite_email: form.user_email()
				, invite_id: form.invite_id()
				, user_id: form.user_id()
				}
				: {invite_email: form.user_email()
				, invite_id: form.invite_id()
				}
			)
			.then(function({ ok, message, organization_name }){
				return state$(
					ok
					? form.user_id()
						? State.VerifiedExistingUser(organization_name)
						: State.EditingSignUpForm()
					: State.InvalidURL( message )
				)
			})
			.catch(function(e){
				// eslint-disable-next-line
				console.error(e)

				state$( State.UnexpectedError() )
			})

			return State.VerifyingURL()
		}
		, urlInterpreter(){
			return form.user_email()
				&& form.invite_id()
				? model.verifyURL()
				: State.InvalidURL()
		}
		, invalid(){

			const all_filled =
				Object.keys(form)
				.filter( k => k != 'user_id')
				.every( k => form[k]() )

			const matching_passwords =
				form.user_password() == form.user_password2()

			const atLeast6 =
				form.user_password().length > 6

			const valid_email =
				/.+@.+\..{2,3}/.test(form.user_email() || "")

			return {
				valid_email
				,matching_passwords
				,all_filled
				,atLeast6
				,invalid: !(
					valid_email
					&& atLeast6
					&& matching_passwords
					&& all_filled
				)
			}
		}
		, submitSignUp(){

			state$(State.SavingForm())

			Promise.resolve(form)
				.then( JSON.stringify )
				.then( JSON.parse )
				.then( omit(['user_password2']) )
				.then( data.api.invites.accept )
				.then(function(response){
					data.auth.stream(
						data.auth.type.LoggedInOf(
							JSON.parse(
								JSON.stringify(response)
							)
						)
					)
					data.reroute(data.auth.stream())
				})
				.catch(function(error){
					state$( State.EditingSignUpForm() )
					alert(error.message)
				})
		}
		}

	const state$ =
		data.scoped(model.urlInterpreter())

	state$.map( () => redraw() )

	const vw =
		{ form(){

			const {
				all_filled
				, matching_passwords
				, valid_email
				, invalid
			} = model.invalid()

			return [
				m(
					'' + css`
						max-width: 40em;
						max-height: 56vh;
						overflow-y: auto;
					`
					,[[ 'Email'
					, form.user_email
					, { type: 'email'
					, className: valid_email
						? 'validate'
						: 'validate invalid'
					, onupdate: ({ dom }) =>
						dom.setCustomValidity(
							valid_email
							? ''
							: 'An email must have a domain'
						)
					}
					]
					,[ 'Name'
					, form.user_name
					, { type: 'text'
					, className:
						'validate'
					, required: true
					}
					]
					, ['Username'
					, form.user_username
					,{ type: 'text'
					, required: true
					, className: 'validate'
					}
					]
					,[ 'Password'
					, form.user_password
					, { type: 'password'
					, required: true
					, className: 'validate'
					, onupdate: ({ dom }) =>
						dom.setCustomValidity(
							!matching_passwords
							? 'Both passwords fields must be equal.'
							: form.user_password().length < 6
							? 'The password must be at least 6 characters'
							: ''
						)
					}
					]
					,[ 'Retype Password'
					, form.user_password2
					,{ type:'password'
					, required: true
					, className: 'validate'
					}
					]
					].map(elements.formInput)
				)
				,m(''
					+ css`
						display: grid;
						align-items: center;
						grid-auto-flow: column;
						gap: 1em;
					`
					,m(
						'button.btn.btn-primary'
						, { onclick: model.submitSignUp
						, title:
							all_filled
							? ''
							: 'Please fill in all form fields'
						, disabled: invalid
						}
						, 'Sign up'
					)
				)
			]
		}

		, saving_form(){
			return elements.centeredSpinner()
		}

		, unexpected_state(){
			return m(''
				,['We are very sorry, but the app has entered '
				, 'an unexpected state and we do not know how to '
				, 'recover.  '
				,m('br')
				, 'Please contact '
				, m('a[href=mailto:support@harth.io]',
						'support@harth.io'
				)
				, ' for assistance.'
				]
			)
		}

		, invalid_url(){
			return m(''
				,[ 'I cannot interpret the URL used to load this page.'
				, '  Please contact '
				, m('a[href=mailto:support@harth.io]'
					, 'support@harth.io'
				)
				, ' for assistance.'
				]
			)
		}

		, verifiying_url(){
			return elements.centeredSpinner()
		}
		, verifiedExisting([ _, organization_name]){

			return m(
				'p.vw_verifiedExisting', [
					'Your invite has been verified. '
					, 'You are now a member of the'
					, organization_name
					, 'organization.'
				]
				.join(' ')
			)
		}
		, view(){
			const [$State] = state$()

			return formLayout(
				modalForm(css`
					gap: 1em;
				`,
					m('h4', 'Welcome to Odin!')
					,{ [ State.EditingSignUpForm.name ]:
						vw.form()
					, [ State.InvalidURL.name ]:
						vw.invalid_url()
					, [ State.SavingForm.name ]:
						vw.saving_form()
					, [ State.VerifyingURL.name ]:
						vw.verifiying_url()
					, [ State.UnexpectedError.name ]:
						false
					, [ State.VerifiedExistingUser.name ]:
						vw.verifiedExisting( state$() )
					}[ $State.name ]
					|| vw.unexpected_state()
				)

			)
		}
		}

	return {
		view: vw.view
	}
}

function CheckEmail(){

	function view(){
		let message_type = m.route.param('message_type') || 'generic'

		let message = {
			verify: "We've sent a verification email to the address you provided."
			,forgot: "If your email exists in our system we will send you an email to reset your password.  It will arrive at the email address you provided when you created your account."
			,generic: "Please check your inbox.  We've sent you some something."
		}[message_type]

		return formLayout(
			modalForm('', m('h4.dib', message ))
		)
	}

	return { view }
}


function Reset({ attrs: {data} }){

	let form = {
		user_password: m.prop("")
		,auth_token: m.prop(m.route.param('auth_token'))
	}

	let user_id = m.route.param('user_id')

	if( !form.auth_token || !user_id ){
		m.route.set('/access/forgot')
	}
	let vm = { user_password2: m.prop("") }

	let success = m.prop(false)

	let submit = function(){
		let all_filled = Object.keys(form).every( k => form[k]() )
		let matching_passwords = vm.user_password2() == form.user_password()

		if(!all_filled) {
			alert('Please fill in all the form fields')
			return;
		}
		if(!matching_passwords) {
			alert('The passwords you entered do not match')
			return;
		}

		data.api.users.patch.password(
			form.auth_token()
			, user_id
			, form.user_password()
		)
			.then(function(){
				success(true)
				m.redraw()
			})
			.catch(function(e){
				alert( e.message )
				m.redraw()
			})
	}

	function formView(){
		return [
			m('h4', 'Please enter a new password')
			,m('br')
			,elements.formInput(['Password', form.user_password, { type: 'password' } ])
			,elements.formInput(['Confirm Password', vm.user_password2, { type: 'password' } ])
			,m('button.btn.btn-primary', { onclick: submit }, 'Reset Password')
		]
	}

	function loginView(){
		m.route.set('/access/login')
		return m('',
			'You have succesfully reset your password.'
			,m('p', m('a', { style: {color: 'white', textDecoration: 'underline' }, href: '/access/login', oncreate: m.route.link }, 'Log In'))
		)


	}

	function view(){
		return formLayout(
			modalForm(''
				,success()
				? loginView()
				: formView()
			)
		)
	}

	return { view }
}

function Access(){

	const night = css.$keyframes({
		from: {
		  filter: `brightness(0)`
		}

		,to: {
		  filter: `brightness(1)`
		}
	})

	const fadeInBg = css.$keyframes({
		from: {
		  opacity: `0`
		}
		,to: {
		  opacity: `1`
		}
	})

	const res = Math.max(1000, Math.floor(window.innerWidth / 300) * 300)
	// todo-james self host
	const workers_on_site = `https://images.unsplash.com/photo-1541888946425-d81bb19240f5?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=${res}&q=80`
	const workers_orange_bg = `https://images.unsplash.com/photo-1563257764-ec4bd2983cbe?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=${res}&q=80`
	const workers_aerial_mesh = `https://images.unsplash.com/photo-1533378890784-b2a5b0a59d40?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=${res}&q=80`
	const purple_sky=`https://images.unsplash.com/photo-1485083269755-a7b559a4fe5e?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=${res}&q=80`
	const complex_architecture=`https://images.unsplash.com/photo-1603909417128-a6214fdea26c?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=${res}&q=80`
	const orange_windows_city=`https://images.unsplash.com/photo-1578088369622-b26f899a016e?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=${res}&q=80`

	const urls = [
		workers_on_site, workers_orange_bg, workers_aerial_mesh
		,purple_sky, complex_architecture, orange_windows_city
	]
	const i = Math.floor(Date.now() / 10000 % urls.length )
	const url = urls[i]
	const bgImage = css`
		background-image: url("${url}");
		background-size: cover;
		background-position: bottom;
		position: absolute;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
		opacity: 0;
		z-index: 0;
		animation: ${fadeInBg} 1s 1s forwards, ${night} 10s 1s;
	`

	return {
		onbeforeremove: css.$animate.out('ease-in-out 1s forwards', {
			'0%': {
				position: 'absolute'
				,top: '0px'
				,left: '0px'
				,width: '100vw'
				,height: '100vh'
				,opacity: 1
				,zIndex: 1
			}
			,'1%': {
				position: 'absolute'
				,opacity: 1

			}
			,'100%': {
				opacity: 0
				,zIndex: 1
			}
		})
		,view: ({ attrs }) =>
			m('.whatever'
				+ css`
					height: 100%;
					display: grid;
					grid-template-rows: auto 1fr;
					--theme: #643fea;
					position: absolute;
					top: 0px;
					left: 0px;
					width: 100vw;
					height: 100vh;
				`
				,m('.bg-image'
					+ bgImage
				)
				, m(NavBar)
				, attrs.mode == 'Login'
					? m(Login, R.omit(['key'], attrs))
				: attrs.mode == 'Verify'
					? m(Verify, R.omit(['key'], attrs))
				: attrs.mode == 'SignUp'
					? m(SignUp, R.omit(['key'], attrs ))
				: attrs.mode == 'Forgot'
					? m(Forgot, R.omit(['key'], attrs ))
				: attrs.mode == 'CheckEmail'
					? m(CheckEmail, R.omit(['key'], attrs ))
				: attrs.mode == 'Reset'
					? m(Reset, R.omit(['key'], attrs ))
				: attrs.mode == 'Invite'
					? m(Invite, R.omit(['key'], attrs ))
					: null
			)
	}
}

export default Access