/* globals setTimeout, console */
import { Stream as prop } from 'bacta'

prop.once = s => {
	let times = 0;
	let out = prop()

	let watch =
		s.map(
			x => {
				times++
				if( times == 1 ){
					return out(x)
				} else {
					setTimeout( () => {
						if( out ) {
							out.end(true)
							watch.end(true)
							watch = null
							out = null
							// eslint-disable-next-line no-param-reassign
							s = null
						}
					} )
					return null
				}
			}
		)

	return out
}

prop.end = s => {
	s.end(true)
}

prop.fromFlydStream = function(flydStream){
	const out = prop()

	flydStream.map(out)

	flydStream.end.map(
		t => t === true ? out.end(true) : null
	)
	return out
}

// todo-james remove mergeAll usages in favour of merge
prop.merge = prop.merge

prop.dropRepeatsWith = (equals, s) => {
	let prev = NaN
	const out = prop();
	s.map(
		x => {
			if( equals(x,prev) ) {
				prev=x
				return null
			} else {
				prev=x
				return out(x)
			}
		}
	)

	return out
}

prop.dropRepeats = s => {
	return prop.dropRepeatsWith( (a,b) => a == b, s )
}

prop.isStream = function(s){
	return s != null && s.constructor == prop
}

prop.afterSilence = function(ms, s){
	return s.afterSilence(ms)
}


prop.log = function(o){
	Object.keys(o)
		.map(function(k){
			const s = o[k]

			return s.map(
				x => console.log(k, x)
			)
		})
	}

prop.over = f => s => s( f(s() ) )

export {
	prop
}
