/* globals setTimeout */


import m from 'bacta'
import css from 'bss'
import autocomplete from './autocomplete'
import scrollableTable from '../components/scrollableTable'
import * as elements from '../components/elements'
import NumberInput from '../components/number'
import Permissions from '../models/permissions'
import * as uuid from 'uuid'
import moment from 'moment'
import Pencil from '../components/pencil'
import HarthUppy from '../components/harth-uppy'
import { Light as DetailsPane } from '../components/detailspane2'
import { money as makeMoney } from '../utils/regExes'
import Responsive from '../components/responsive'
import {
	filterView
	,ListProcessing
} from '../components/filter'

const checkprop = (a, b, o) =>
	o[a] && b && o[a][b]
		? typeof o[a][b] == 'function'
			? o[a][b]()
			: o[a][b]
		: typeof o[a] == 'function'
			? o[a]()
			: o[a]

import {identical} from '../utils/harth-identical'
import * as R from 'ramda'
import { prop } from '../../../stream'

import Promise from 'bluebird'

const truncate =
  css`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `

const hideScrollbars =
  css`
    -ms-overflow-style: none;
    scrollbar-width: none;
  `
  .$nest('::-webkit-scrollbar', `
    display: none;
  `)

function MiniTable(){

	function view({ attrs: { rows, format=x=>x }}){

		const headers = rows.slice(-1).flatMap( x => Object.keys(x.fields) )

		const values = rows.map(
			x => {
				const fields = Object.fromEntries(
					Object.entries(x.fields).map(
						([key, value]) =>
							[key, format(value, key)]
					)
				)

				return { key: x.key, fields }
			}
		)

		if(headers.length == 0) {return null}

		return m('.overview'
			+ css`
				display: grid;
				--col: 3em;
			`
			, m('.headers'
				+ css`
					display: grid;
					grid-template-columns: repeat(4, var(--col));
					gap: 1em;
					text-align: left;
					justify-content: space-between;
				`
				,headers.map(
					x => m('.header'
						+ css`
							font-weight: bold;
						`
						, x
					)
				)
			)
			, m('.values'
				+ css`
					display: grid;
					overflow-y: auto;
					max-height: 20em;
					gap: 0.5em;
				`
				+ hideScrollbars
				, values.map(
					x =>
						m('.hth-row'
							+ css`
								display: grid;
								grid-template-columns: repeat(4, var(--col));
								gap: 1em;
								text-align: left;
								justify-content: space-between;
							`
							,
							{ key: x.key }
							, headers.map(
								key =>
									m('.cell'
										+ css`
											max-width: calc( var(--col) * 1.8*1);
											width: calc( var(--col) * 1.8*1);

										`
										+ truncate
										,
										{ title: x.fields[key]
										}
										, x.fields[key]
									)
							)
						)
				)
			)
		)

	}

	return { view }
}

function Main({ attrs: {data} }){

	const errors = data.scoped({})
	const deleteFlowStateProp = m.prop(false)
	const deleteOrganizationDisciplineStateProp = m.prop(false)
	let deleteObjective = []
	let deleteDependent = []
	const loading = m.prop(true)

	function getflow_dependent_activity (flow){
		let name = vm().selected.flow && vm().selected.flow.flow_id
		let activated = name && name() == flow.flow_id()
		let showing = vm().dependant()
		return  activated && showing
			? m("button.btn.btn-warning", {
				disabled: false
				,onclick: () => {
					vm().dependant(false)
				}
			},"Hide Dependant Activities")
			: m("button.btn.", {
				disabled: false
				,onclick: () => {
					vm().selected.flow = flow
					vm().dependant(true)
				}
			},"Show Dependant Activities")

	}

	function getflow_projects(flow){
		const name = vm().selected.flow && vm().selected.flow.flow_id
		const activated = name && name() == flow.flow_id()
		const showing = vm().project()
		return  activated && showing
			? elements.list([
				appliedProjects()[flow.flow_id()]['projectString']
				,m("button.btn.btn-warning", {
					disabled: false
					,onclick: () => {
						vm().project(false)
					}
				},"Hide Relevant Projects")
			])
			: m("button.btn.", {
				disabled: false
				,onclick: () => {
					vm().selected.flow = flow
					vm().project(true)
				}
			},"Show Relevant Projects")
	}

	function getflow_primary_activity(flow){
		let name = vm().selected.flow && vm().selected.flow.flow_id
		let activated = name && name() == flow.flow_id()
		let showing = vm().primary()
		return  activated && showing
			? m("button.btn.btn-warning", {
				disabled: false
				,onclick: () => {
					vm().primary(false)
				}
			},"Hide Objective Activities")
			: m("button.btn.", {
				disabled: false
				,onclick: () => {
					vm().selected.flow = flow
					vm().primary(true)
				}
			},"Show Objective Activities")
	}

	function deleteFlow(){
		let flow_id = vm().selected.flow.flow_id()
		return Promise.resolve(
			flow_id && data.api.flows.remove.one(flow_id)
		)
		.then(function(){
			let index = R.findIndex(function(chr) { return chr.flow_id() == vm().selected.flow.flow_id()}, flows())
			flows().splice(index, 1)
			vm().selected.flow = null
		})
		.then(m.redraw)
	}

	function deleteOrganizationDisciplines(){
		const ldlist = R.pipe(R.map(R.prop('key')))(checkMultiple())
		const dlist =
			R.pipe(R.map(R.pipe(R.prop('organizations_disciplines_id'), R.call)),R.filter(R.identity))
			(checkMultiple())
		return Promise.resolve(
			dlist.length
			? data.api.organizations_disciplines.remove.many(dlist)
			: []
		)
		.then(function(){

			checkMultiple([])

			ldlist.map((d) =>
				organizations_disciplines()
				.splice(
					R.findIndex(R.propEq('key', d), organizations_disciplines())
					,1
				)
			)

			dlist.map((d) =>
				baseOrganizationsDisciplines()
				.splice(
					R.findIndex(R.propEq('organizations_disciplines_id', d), baseOrganizationsDisciplines())
					,1
				)
			)

			return loading()
		})
		.then(m.redraw)
	}

	function deleteFlowPriDep(FlowRelation, makeAPICalls){

		if (FlowRelation){
			FlowRelation.flows_dependent_activity_id
				? deleteDependent.push(FlowRelation.flows_dependent_activity_id)
				: deleteObjective.push(FlowRelation.flows_primary_activity_id)
		}

		return makeAPICalls
			? Promise.all(
				deleteObjective.map((t) => data.api.flows_primary_activities.remove.one(t))
				,deleteDependent.map((t) => data.api.flows_dependent_activities.remove.one(t))
			)
			: null
	}


	function fetchFlows(){
		if(schedule_id() != null){
			return data.fetchFlowsByScheduleId(schedule_id())
				.then(flows)
				.then(m.redraw)
		} else {
			return Promise.reject(
				new Error('A schedule_id is required to fetch flows')
			)
		}
	}

	function fetchOrganizationsDisciplines(){
		return data.fetchOrganizationsDisciplines({})
			.then(baseOrganizationsDisciplines)
			.then(function(){
				checkMultiple([])
				deleteOrganizationDisciplineStateProp(false)
				return deleteOrganizationDisciplineStateProp()
			})
			.then(m.redraw)
	}

	function sendOrganizationsDisciplines(){

		const updatedogds = organizations_disciplines().filter(R.prop('created'))
		const newcdrs =
			R.unnest(
				updatedogds.map((d) =>
					d.applytoteams.filter((t) =>
						!t.crews_discipline_rates.find((cdr) =>
							cdr.organizations_disciplines_id()
							== d.organizations_disciplines_id()
						)
					)
					.map((t) => ({
						crew_id: t.crew_id()
						,contractor_id: t.contractor_id()
						,crew_name: t.crew_name()
						,crews_discipline_rates_feedback_rate: 0
						,organizations_disciplines_id: d.organizations_disciplines_id()
						,crews_discipline_rates_uom: d.organizations_disciplines_uom()
						,crews_discipline_rates_rate: d.organizations_disciplines_nominal_rate()
						,crews_discipline_rates_name: d.organizations_disciplines_name()
						,crews_discipline_rates_description: d.organizations_disciplines_description()
						,createcontracts: true
					}))
				)
			)


		// const newcontractors =
		// 	R.uniqBy(R.prop('contractor_id'), newcdrs)
		// 	.map((c) => ({
		// 		contractor_id: c.contractor_id
		// 		,crews:
		// 			R.uniqBy(R.prop('crew_id'), newcdrs)
		// 			.map((t) => ({
		// 				crew_id: t.crew_id
		// 				,crews_discipline_rates: newcdrs.filter((cdr) => cdr.crew_id == t.crew_id)
		// 			}))
		// 	}))

		return data.api.organizations_disciplines.patch(updatedogds)
			.then(data.api.crews_discipline_rates.patch.many(newcdrs))
			// .then(data.api.contractors.patch.many(newcontractors))
			.then(fetchOrganizationsDisciplines)
			.then(deleteToolPresets)
			.then(sendToolPresets)
			.then(m.redraw)
	}

	function sendToolPresets(){
		return data.api.tools.presets.patch( newtoolPresets() )
		.then(() => {
			newtoolPresets([])
			toolPresetDeletions([])
		})
	}

	function deleteToolPresets(){
		return Promise.all(
			toolPresetDeletions().map((tp) =>
				data.api.tools.presets.remove(tp.tools_presets_id)
			)
		)
	}

	function sendFlows(){
		form().flow.flow_delay_type(
			form().flow.flow_dependency_relationship()
				== "Risk Ratio"
					? "Safety"
					: "Delay"
		)

		const flow = form().flow

		if( flow != null && !identical(vm().selected.flow, form().flow) ){
			return Promise.all([
				deleteFlowPriDep(null, true)
			])
			.then(function(){
				deleteObjective = []
				deleteDependent = []
				return data.api.flows.patch([flow])
					.then(function(){
						return fetchFlows()
						.then(function(){
							let updated_res = R.find(
								(f) => f.flow_name() == form().flow.flow_name()
								, flows()
							)
							vm().selected.flow = updated_res
							cloningFlow(false)
							edit(true)
							return getAvailableOptions()
						})
					})
					.then(m.redraw)
			})
		} else {
			return Promise.resolve([])
				.then(m.redraw)
		}
	}

	function fetchProjects(){
		return !schedule_id()
			? Promise.reject(
				new Error('A schedule_id is required to fetchProjects')
			)
			: data.fetchProjectsBySchedule({
				schedule_id: schedule_id()
				, completedProjects: completedProjects()
				, depth: 2
				, props: {
					projects:[
						'project_id'
						,'project_name'
						,'project_start_date'
						,'project_dead_date'
						,'project_commissioning_date'
					]
					,disciplines:[
						'organizations_disciplines_id'
						,'discipline_name'
					]
				}
			})
				.then( projects )
				.then( m.redraw )
	}

	function getfilters(button, marker){
		let datainput = marker == "Flows"
		 ? filterFunction.FlowsFilters
		 : filterFunction.OrganizationDisciplineFilters

		if (datainput == null){
			filterFunction.FlowsFilters = {
				filters: m.prop([])
				,operationalFilters: m.prop([])
				,changeNames: filterFunction.changeNames
				,uniqueArrays: filterFunction.uniqueArrays
				,template: filterFunction.template.flows
				,disabled: () => flows().length == 0
			}

			filterFunction.OrganizationDisciplineFilters = {
				filters: m.prop([])
				,operationalFilters: m.prop([])
				,changeNames: filterFunction.changeNames
				,uniqueArrays: filterFunction.uniqueArrays
				,template: filterFunction.template.organizations_disciplines
				,disabled: () => organizations_disciplines().length == 0
			}

			datainput = marker == "Flow"
				? filterFunction.FlowsFilters
				: filterFunction.OrganizationDisciplineFilters
		}

		let gotfilters =
			datainput == null
			? null
			: datainput.operationalFilters(
				filterView(
					datainput.template
					, datainput.filters()
					, datainput.changeNames
					, datainput.uniqueArrays
					, datainput.disabled
					, marker
					, data.scoped
				)
			)

		datainput != null ? datainput.filters( gotfilters.filters ) : null
		return gotfilters.generatedElements
	}

	function SaveDisabled(returnBoolean){
		const count = R.countBy(
			chr => chr.flow_name()

			,flows().filter((r) =>
				r.flow_id() != form().flow.flow_id()
			).concat(form().flow)
		)
		const errorArray = [
			(d) => !d.flow_name() ? `Workflow Designation must not be empty ` : null
			,(d) =>	count[d.flow_name()] > 1 ? `Workflow Designations must not be duplicated` : null
		]

		return elements.errorAlert(
			`To save, `
			,' and '
			,''
			,[form().flow]
			,errorArray
			,returnBoolean
			,false
			,'warning'
			,null
			,Object.keys(errors())
				.map((a) =>
					errors()[a]
						? a + ' ' + errors()[a]
						: ''
				)
				.filter((a) => a)
		)
	}

	function DeleteDisabled(){
		return flows().length == 0 || vm().selected.flow == null
	}

	function DeleteOrganizationDisciplineDisabled(){
		return organizations_disciplines().length == 0 || !checkMultiple().length
	}

	function cloningFlowPri(cloneItem, clonemarker){
		let clone  = {
			flow_id: !clonemarker ? cloneItem.flow_id : null
			,organization_id: cloneItem.organization_id
			,flows_primary_activity_id: !clonemarker ? cloneItem.flows_primary_activity_id : null
			,organizations_disciplines_id: cloneItem.organizations_disciplines_id
			,discipline_name: cloneItem.discipline_name
			,flow_primary_activity_name: cloneItem.flow_primary_activity_name
		}
		return clone
	}

	function cloningFlowDep(cloneItem, clonemarker){
		let clone  = {
			flow_id: !clonemarker ? cloneItem.flow_id : null
			,organization_id: cloneItem.organization_id
			,flows_dependent_activity_id: !clonemarker ? cloneItem.flows_dependent_activity_id : null
			,organizations_disciplines_id: cloneItem.organizations_disciplines_id
			,discipline_name: cloneItem.discipline_name
			,flow_dependent_activity_name: cloneItem.flow_dependent_activity_name
		}
		return clone
	}

	function cloningOrganizationDisciplines(clonemarker, cloneItem){
		let clone = resetOrganizationDisciplinesForm()

		clone.organization_id(
			clonemarker ? cloneItem.organization_id() : cloneItem.organization_id
		)

		clone.organizations_disciplines_description(
			clonemarker ? cloneItem.organizations_disciplines_description() : cloneItem.organizations_disciplines_description
		)

		clone.organizations_disciplines_nominal_rate(
			clonemarker ? cloneItem.organizations_disciplines_nominal_rate() : cloneItem.organizations_disciplines_nominal_rate
		)

		clone.organizations_disciplines_uom(
			clonemarker ? cloneItem.organizations_disciplines_uom() : cloneItem.organizations_disciplines_uom
		)
		clone.organizations_disciplines_nominal_expenditure_rate(cloneItem.organizations_disciplines_nominal_expenditure_rate)
		clone.organizations_disciplines_nominal_revenue_rate(cloneItem.organizations_disciplines_nominal_revenue_rate)
		clone.listindex = cloneItem.listindex
		clone.completedsum = cloneItem.completedsum
		clone.scheduledsum = cloneItem.scheduledsum
		clone.completedcount =  cloneItem.completedcount
		clone.contractrecognitiondescription = cloneItem.contractrecognitiondescription
		clone.contractrecognitionid = cloneItem.contractrecognitionid
		clone.contractrecognitionname = cloneItem.contractrecognitionname
		clone.orgDisciplineNameDescription = cloneItem.orgDisciplineNameDescription

		clone.organizations_disciplines_name(
			clonemarker ? cloneItem.organizations_disciplines_name() + " (Clone " : cloneItem.organizations_disciplines_name
		)
		clone.organizations_disciplines_id(clonemarker ? clone.organizations_disciplines_id() : cloneItem.organizations_disciplines_id)
		clone.created = clonemarker
		clone.neworgd = clonemarker
		clone.applytoteams = cloneItem.applytoteams
		return clone
	}

	function cloningFlow(clonemarker){
		let clone = resetForm().flow
		clone.schedule_id( vm().selected.flow.schedule_id() )
		clone.organization_id( vm().selected.flow.organization_id() )
		clone.flow_delay_in_days( vm().selected.flow.flow_delay_in_days() )
		clone.flow_delay_type( vm().selected.flow.flow_delay_type() )
		clone.flow_dependency_relationship( vm().selected.flow.flow_dependency_relationship() )
		clone.flow_dependent_activity =
			vm().selected.flow.flow_dependent_activity
			.map((f) => cloningFlowDep(f, clonemarker) )
		clone.flow_primary_activity =
			vm().selected.flow.flow_primary_activity
			.map((f) => cloningFlowPri(f, clonemarker) )
		clone.flow_safety_ratio( vm().selected.flow.flow_safety_ratio() )
		clone.flow_name( clonemarker == false ? vm().selected.flow.flow_name() : vm().selected.flow.flow_name() + " (Clone)" )
		clone.flow_id(clonemarker == false ? vm().selected.flow.flow_id() : null)
		form().flow = clone
		return clone
	}

	function resetOrganizationDisciplinesForm(){
		return {
			organization_id: m.prop(null)
			,organizations_disciplines_id: m.prop(uuid.v4())
			,organizations_disciplines_name: m.prop(null)
			,organizations_disciplines_description: m.prop(null)
			,organizations_disciplines_nominal_rate: m.prop(0)
			,organizations_disciplines_uom: m.prop('')
			,neworgd: true
			,organizations_disciplines_nominal_expenditure_rate: m.prop(0)
			,organizations_disciplines_nominal_revenue_rate: m.prop(0)
			,key: Math.random().toString(15).slice(2, 8)
			,created: true
			,applytoteams: []
		}
	}

	function getActivateDeleteButton(object){
		const activated =
			checkMultiple()
			.findIndex((chr) => chr.key == object.key)

		const listindex =
			organizations_disciplines()
			.findIndex((chr) => chr.key == object.key)

		object.listindex = listindex

		return {
			disabled: !(editProjectPermissions() || editOrganizationPermissions())
			,type: 'checkbox'
			,onclick: (v) => {
				if(!v.currentTarget.checked){
					checkMultiple().splice(activated, 1)
					checkMultiple(checkMultiple())
				}
				else {
					checkMultiple().push(object)
					checkMultiple(checkMultiple())
				}
			}
			,checked: activated >= 0
		}
	}

	function resetForm(){
		return {
			flow:{
				flow_id: m.prop(null)
				,flow_name: m.prop("")
				,organization_id: m.prop(null)
				,flow_delay_in_days: m.prop(0)
				,flow_delay_type: m.prop("Safety")
				,flow_dependency_relationship: m.prop("Risk Ratio")
				,flow_dependent_activity: []
				,flow_primary_activity: []
				,flow_safety_ratio: m.prop(40)
				,schedule_id: m.prop(schedule_id())
				,key: Math.random().toString(15).slice(2, 8)
			}
		}
	}

	function matchingID(x, y){
		return x.organizations_disciplines_id == y.organizations_disciplines_id
	}

	function getAvailableOptions(){

		const discipline_selection_list = baseOrganizationsDisciplines()
		const restrictedDisciplines = available_disciplines()

		const dependants = form().flow.flow_dependent_activity || []
		const primaries = form().flow.flow_primary_activity || []

		primaryOptions(
			R.differenceWith(
				matchingID
				,R.differenceWith(
					matchingID
					,discipline_selection_list
					,restrictedDisciplines.restrictedPri
				)
				,dependants.concat(primaries)
			)
		)
		dependentOptions(
			R.differenceWith(
				matchingID
				,R.differenceWith(
					matchingID
					,discipline_selection_list
					,restrictedDisciplines.restrictedDep
				)
				,dependants.concat(primaries)
			)
		)
	}


	function editOption(addORsub, property, d){
		if ( addORsub ){
			let flowRelation = {
				discipline_name:
					d.organizations_disciplines_name
					+ ' - '
					+ d.organizations_disciplines_description
				,flow_id: form().flow.flow_id()
				,organizations_disciplines_id: d.organizations_disciplines_id
			}
			property.push(flowRelation)
		} else if ( !addORsub && d ){
			let index = property.findIndex((flowRelation) =>
				flowRelation.organizations_disciplines_id
				== d.organizations_disciplines_id
			)
			index > -1 ? property.splice(index, 1) : null
			index > -1 && (d.flows_dependent_activity_id || d.flows_primary_activity_id)
			 ? deleteFlowPriDep(d, null)
			 : null
		}
	}


	function available_disciplines(){
		let restrictedDep = []
		let restrictedPri = []
		let foundnewLinks = []
		let flowArrays = []
		let newLinkcount = 0

		function AddHiddenLinks(FlowsObjCantBeDep, FlowsDepCantBeObj){
			let reverseHidden = []
			let forwardHidden = []
			if (FlowsObjCantBeDep){
				FlowsObjCantBeDep.forEach((f) => {
					reverseHidden = reverseHidden.concat(
						R.differenceWith(
							matchingID
							,f.flow_primary_activity
							,reverseHidden.concat(restrictedDep)
						)
					)

				})
			} else {
				FlowsDepCantBeObj.forEach((f) => {
					forwardHidden = forwardHidden.concat(
						R.differenceWith(
							matchingID
							,f.flow_dependent_activity
							,forwardHidden.concat(restrictedPri)
						)
					)
				})
			}
			return FlowsObjCantBeDep ? reverseHidden : forwardHidden
		}

		// A dependency in other flows cannot appear in this flows objectives if the otherhas an objective equal to this dependency
			// this then applies to all those dependecnies we just found! so we loop back up the links to find the start points and all hiddent dependencies
		// An objective in other flows cannot appear in this flows dependents if the other flow has a dependent equal to this objective
			// this then applies to all those objectives we just found! so we loop back up the links to find the start points and all hiddent dependencies
		if ( form().flow.flow_primary_activity ){
			foundnewLinks = form().flow.flow_primary_activity
			newLinkcount = 0
			while (newLinkcount <= foundnewLinks.length - 1){
				flowArrays = flows().filter((f) =>
					f.flow_dependent_activity.find((fd) =>
						fd.organizations_disciplines_id
						== foundnewLinks[newLinkcount].organizations_disciplines_id
					)
				)
				foundnewLinks = foundnewLinks.concat(AddHiddenLinks( flowArrays, null ))
				restrictedDep = restrictedDep.concat(foundnewLinks)
				newLinkcount = newLinkcount + 1
			}

			foundnewLinks = form().flow.flow_dependent_activity
			newLinkcount = 0
			while (newLinkcount <= foundnewLinks.length - 1){
				flowArrays = flows().filter((f) =>
					f.flow_primary_activity.find((fd) =>
						fd.organizations_disciplines_id
						== foundnewLinks[newLinkcount].organizations_disciplines_id
					)
				)
				foundnewLinks = foundnewLinks.concat(AddHiddenLinks( null, flowArrays ))
				restrictedPri = restrictedPri.concat(foundnewLinks)
				newLinkcount = newLinkcount + 1
			}
		}

		return {restrictedDep: restrictedDep
				,restrictedPri: restrictedPri}
	}

	function check_fnames(){
		let state = false
		flows().every((flow) => {
			if (flow.flow_name() == null
				|| flow.flow_name() == ""
				|| R.countBy(function(chr) { return chr.flow_name() == flow.flow_name() }, flows() ).true > 1
				){ state = true}
			return state == false
		})
		return state
	}

	const setOptions = function(value, prop, container){

		let refContainter = container || checkMultiple()

		if( refContainter[0] ){

			var dtype = typeof refContainter[0][prop] == 'function'

			value || value === false || value === 0 || value === ""
				? refContainter.forEach((d) => {

					// const ov
					dtype
						? d[prop]()
						: d[prop]

					dtype
						? d[prop](value)
						: d[prop] = value

					d.created = true
						// value != ov
						// ? true
						// : false

				})
				: null
		}

		return !refContainter.length
			? null
			: dtype
				? refContainter[0][prop]()
				: refContainter[0][prop]
	}

	const check_onames = function(returnBoolean){

		const count =
			R.countBy((chr) =>
				chr.organizations_disciplines_name()
				,organizations_disciplines()
			)

		const errorArray = [
			(d, prop='organizations_disciplines_name') => !d[prop]()
				? `Discipline Name must not be empty` : null
			,(d, prop='organizations_disciplines_description') => !d[prop]()
				? `Discipline Description must not be empty` : null
			,(d, prop='organizations_disciplines_name') => count[d[prop]()] > 1
				? `Discipline Names must not be duplicated. Check ` + d[prop]()
				: null
		]

		return elements.errorAlert(
				`To save, `
				,' and '
				,''
				,organizations_disciplines()
				,errorArray
				,returnBoolean
				,false
				,'warning'
				,null
				,Object.keys(errors())
					.map((a) =>
						errors()[a]
							? a + ' ' + errors()[a]
							: ''
					)
					.filter((a) => a)
		)
	}

	const metaDataLoading = data.scoped(true)
	const form = m.prop({ flow: {} })
	const organizations_disciplines = data.scoped([])
	const baseOrganizationsDisciplines = data.scoped([])
	const flows = data.scoped([])
	const schedule_id = data.schedule_id
	const projects = data.scoped([])
	const editPermissions = m.prop(false)
	const editProjectPermissions = data.scoped(false)
	const editResourcePermissions = data.scoped(false)
	const editOrganizationPermissions = data.scoped(false)
	const dependentOptions = data.scoped([])
	const primaryOptions = data.scoped([])
	const completedProjects = data.scoped("inprogress")
	const checkMultiple = data.scoped([])
	const resources = data.scoped([])
	const teams =
		resources
		.map((resList) =>
			R.unnest(resList.map((c) => c.crews))
			.filter((t) =>
				checkMultiple().some((d) => !d.applytoteams.find((apt) => apt.crew_id() == t.crew_id()))
				&& (
					!t.crews_discipline_rates.length
					|| t.crews_discipline_rates.some((cdr) =>
						!checkMultiple().find((ogd) =>
							ogd.organizations_disciplines_id() == cdr.organizations_disciplines_id()
						)
					)
				)
			)
		)

	function uploadmetadata(){

		const od =
			checkMultiple()[0]

		return R.pickBy(
			(a) => a
			,{
				organization_id: data.initiateContractFetch().organization_id
				, user_id: data.auth.stream().user_id
				, organizations_disciplines_id:
					od.organizations_disciplines_id()
			}
		)
	}

	checkMultiple.map((c) =>
		(
			c.length
			? data.fetchResource({
				props: {
					resources: [
						'contractor_id'
						,'contractor_name'
						,'user_id'
					]
					,teams: [
						'crew_id'
						,'contractor_id'
						,'crew_name'
						,'user_id'
					]
					,rates: [
						'crew_id'
						,'crews_discipline_rates_id'
						,'organizations_disciplines_id'
					]
				}
				,exclusive: true
				,organizations_disciplines:
					c.map(R.pipe(R.prop('organizations_disciplines_id'), R.call))
			})
			: Promise.resolve([])
		)
			.then(resources)
	)

	baseOrganizationsDisciplines
	.map(R.pipe(R.map((i) => cloningOrganizationDisciplines(false, i)), organizations_disciplines))

	const availableUOMS =
	baseOrganizationsDisciplines
	.map(R.uniqBy(R.prop('organizations_disciplines_uom')))

	const projectDisciplines =
		projects.map((projectList) =>
			R.unnest(
				projectList.map((p) =>
					p.disciplines
				)
			)
		)

	const linkedFlowDisciplines =
		flows.map((flowList) =>
			[].concat(
				R.unnest(
					flowList.map((f) => f.flow_dependent_activity)
				)
				,R.unnest(
					flowList.map((f) => f.flow_primary_activity)
				)
			)
		)

	const linkedDisciplines =
		prop.merge(
			[
				linkedFlowDisciplines
				,primaryOptions
			]
		).map((streams) =>
			R.uniqBy(
				(a) => checkprop('organizations_disciplines_id', null, a)
				,[].concat(
					streams[0]
					,form().flow.flow_dependent_activity || []
					,form().flow.flow_primary_activity || []
				)
			)
		)

	const disconnectedDisciplines = prop.merge(
		[
			projectDisciplines
			,linkedDisciplines
		]
	).map(([projectDisciplines, linkedDisciplines]) =>
		R.differenceWith(
			(a,b) =>
				checkprop('organizations_disciplines_id', null, a)
				== checkprop('organizations_disciplines_id',  null, b)
			,projectDisciplines
			,linkedDisciplines
		)
	)

	const appliedProjects =
		prop.merge(
			[
				projects
				,flows
			]
		)
		.map(([projects, flows]) => {

			const pIndex =
				R.indexBy(
					R.pipe(R.prop('flow_id'), R.call)
					,flows.map((f) => {
						const flowPrimaries = R.pluck('organizations_disciplines_id', f.flow_primary_activity)
						const flowDependants = R.pluck('organizations_disciplines_id', f.flow_dependent_activity)
						const valueList = []
						projects.forEach((project) => {
							const projectDisciplines = R.pluck('organizations_disciplines_id', project.disciplines).map(s => s())
							const discplineflowPri =
								R.intersection(
									projectDisciplines
									,flowPrimaries
								).length

							const discplineflowDep =
								R.intersection(
									projectDisciplines
									,flowDependants
								).length

							if ( discplineflowPri > 0 && discplineflowDep > 0){
								const entry = project.project_name()
								valueList.push(entry)
							}

						})
						return R.merge(
							f
							,{
								projectString: valueList.length != 0
								? valueList.join(', ')
								: data.readProjectPermissions()
									? `There are no Projects with relations to this Flow`
									: `Project permissions are required to view relevant Projects`
							}
						)
					})
				)

			metaDataLoading(false)

			return pIndex
		})

	baseOrganizationsDisciplines
	.map((bo) =>
		bo.length
		? data.fetchTools({}).then( tools )
		: []
	)

	const tools = data.scoped([])
	const toolsets = data.scoped([])
	const toolPresetDeletions = data.scoped([])
	const newtoolPresets = data.scoped([])

	const toolPresetOptions =
		prop.merge(
			[
				tools
				,checkMultiple
				,toolsets
			]
		)
		.map(([t, orgds, ts]) =>
			t.filter((t) =>
				orgds.some((d) =>
					!ts.find((tp) =>
						tp.tools_id == t.tools_id()
						&& d.organizations_disciplines_id()
						== tp.organizations_disciplines_id
					)
				)
			)
		)

	checkMultiple
	.map((ogd) => {
		return ogd.length
			? data.api.tools.presets.fetch({
				perorganizatond:
					ogd.map((d) => d.organizations_disciplines_id())
			})
			.then((ts) => {

				const cp =
					ts.filter((o) =>
						!newtoolPresets()
						.find((nt) =>
							nt.tools_presets_id
							== o.tools_presets_id
						)
					)

				ogd.map((d) =>
					d.presets =
						newtoolPresets()
						.concat(cp)
						.filter((ts) =>
							ts.organizations_disciplines_id
							== d.organizations_disciplines_id()
						)
				)

				toolsets(newtoolPresets().concat(cp))
				m.redraw()
			})
			: Promise.resolve([])
	})

	const requiredTools =
		() =>
			elements.list([
				m('label.control-label.mb1'
					,'Set tools required for this Work'
				)
				,Pencil(
					() => ''
					,() =>
						m(autocomplete.Main, {
							list: toolPresetOptions
							,onselect(v, model){

								checkMultiple()
								.forEach((d) => {

									if (
										!toolsets().find((p) =>
											p.tools_id == v.tools_id()
											&& p.organizations_disciplines_id == d.organizations_disciplines_id()
										)
									){
										const newpreset = {
											tools_presets_id: uuid.v4()
											,organizations_disciplines_id: d.organizations_disciplines_id()
											,tools_id: v.tools_id()
											,organization_id: data.initiateContractFetch().organization_id
											,created: true
											,contractrecognitionname: v.contractrecognitionname
											,tools_name: v.tools_name()
											,tools_description: v.tools_description()
											,tools_presets_quantity: 1
										}

										newtoolPresets().push( newpreset )
										toolsets().push(newpreset)
										toolsets( toolsets() )

										d.presets
											? d.presets.push(newpreset)
											: d.presets = [newpreset]

									}
								})

								model.input('')
								model.chosen(null)

							}
							,field: 'contractrecognitionnametype'
							,attrs: {
								title: "Select the type of tools required to conduct this discipline"
							}
							,sort: R.prop('tools_name')
						})
				)
				,checkMultiple().map((d) => [
					m('hr', d.organizations_disciplines_name() )
					,m('br')
					,elements.strikeTable(
						(d.presets || []).map(
							t => ({
								label: t.tools_name + ' - ' + t.tools_description + ' x '
								,action: () => {


									newtoolPresets(
										newtoolPresets()
										.filter((tp) =>
											tp.tools_presets_id
											!= t.tools_presets_id
										)
									)


									toolsets(
										toolsets()
										.filter((tp) =>
											tp.tools_presets_id
											!= t.tools_presets_id
										)
									)

									d.presets =
										d.presets
										.filter((tp) =>
											tp.tools_presets_id
											!= t.tools_presets_id
										)


									!t.created
									? toolPresetDeletions().push(t)
									: null

								}
								,meta:
									Pencil(
										() => t.tools_presets_quantity
										,() =>
											m(
												NumberInput
												,R.merge(
													data
													,{
														errors
														,errorLabel: d.tools_name + ' Quantity'
													}
												)
												,{
													prop: (value) => {
														if( value && !t.created){
															newtoolPresets().push(t)
														}
														return setOptions(value, 'tools_presets_quantity', [t])
													}
													,attrs: {
														min: 0
														,step: 1
														,width: '2em'
													}
												}
											)
									)
							})
						)
					)
				])
			])

	const underlinedHeader =
		a => m('h4.pt3.bw1.bb.pb2.b--black-20', a)

	const detailsPaneOpen =
		() => checkMultiple().length >= 1 && indexby() == 'Organization Disciplines'


	const saveDiscardButtons =
		() =>
			elements.list(
				[
					editOrganizationPermissions()
					|| editProjectPermissions()
					? check_onames(true)
					|| identical(baseOrganizationsDisciplines(), organizations_disciplines())
					&& !newtoolPresets().length
					&& !toolPresetDeletions().length
						? m('button.btn', {disabled: true}, 'Save')
						: elements.action('Save', sendOrganizationsDisciplines, loading)
					: null
				]
				.concat(
					elements.undoDiscard({
						discard:
							{
								label: 'Discard'
								,attrs: {
									onclick: deleteOrganizationDisciplines
									,disabled:
										() =>
											DeleteOrganizationDisciplineDisabled()
											|| (!editProjectPermissions() && !editOrganizationPermissions())
								}
								,loading: loading
								,state: deleteOrganizationDisciplineStateProp
							}
						,doneUndo: {
							label:
								identical(
									baseOrganizationsDisciplines()
									, organizations_disciplines()
								)
								&& !newtoolPresets().length
								&& !toolPresetDeletions().length
								? 'Done'
								: ''
							,attrs: {
								onclick: () => {
									checkMultiple([])
									baseOrganizationsDisciplines(baseOrganizationsDisciplines())
									deleteOrganizationDisciplineStateProp(false)

									newtoolPresets([])
									toolPresetDeletions([])
								}
								,title: `Discard all changes since last saving Disciplines`
								,style: {
									transition: '0.5s'
									,width: '5em'
								}
								,disabled:
									!organizations_disciplines()
									|| (
										organizations_disciplines()
										&& !newtoolPresets().length
										&& !toolPresetDeletions().length
									)
							}
						}
					})
				)
			)

	const detailPaneInputs =
		function(){
			const D = checkMultiple()[0]

			return [

				saveDiscardButtons()

				,elements.list([
					underlinedHeader('Discipline Details - ')
					,m("button.btn.btn-secondary."
						, {
							disabled: !!check_onames(true)
							,onclick:
								() => {
									const insindex = checkMultiple()[checkMultiple().length-1]['listindex']
									const newitems =
										checkMultiple()
										.map((d) => cloningOrganizationDisciplines(true, d))
									newitems.map((d) =>
										organizations_disciplines(
											R.insert(
												insindex
												, d
												, organizations_disciplines()
											)
										)
									)
									checkMultiple(newitems)
								}
							,style: {
								backgroundColor: 'transparent'
								, border: 'solid 1px #3380c2'
								, position: 'relative'
								, top: '-0.1em'
								, height: '2.5em'
								, width: '5em'
								, color: '#434aa3'
							}
							,title: "Create a new type of Discipline from the copy of another, for the organization to use to register works"
						}
						,"Clone"
					)
				])

				,m(MiniTable, {
					rows: checkMultiple().map(
						d => ({
							key: d.organizations_disciplines_id()
							,fields : {
								Name: d.organizations_disciplines_name()
								,Desc: d.organizations_disciplines_description()
								,NWR: d.organizations_disciplines_nominal_rate()
								,UoM: d.organizations_disciplines_uom()
							}
						})
					)
					,format: (x, key) =>
						key == 'Name'
							? x
						: x == null
							? 'N/A'
						: Number.isNaN(x)
							? 'N/A'
							: x
				})

				,m('br')

				,checkMultiple().length < 2
				&& elements.list([
					m('label.control-label.mb1'
						,'Discipline Name'
					)
					,Pencil(
						() => D.organizations_disciplines_name()
						,() =>
							elements.textInput(
								(value) =>
									setOptions(
										value, 'organizations_disciplines_name'
									)
								,{
									title: "Name of the discipline"
								}
							)
					)
				])

				,elements.list([
					m('label.control-label.mb1', ' Discipline Description ')
					,Pencil(
						() =>
							checkMultiple().length == 1
							? D.organizations_disciplines_description()
							: ''
						,() =>
							elements.textInput(
								(value) => setOptions(value, 'organizations_disciplines_description')
								,{
									title: "Description of the discipline"
								}
							)
					)
				])

				,elements.list([
					m('label.control-label.mb1', ' Unit of Measure ')
					,Pencil(
						() =>
							checkMultiple().length == 1
							? D.organizations_disciplines_uom()
							: ''
						,() =>
							autocomplete.all(
								availableUOMS
								,(value) => {
									if(value){ setOptions(value, 'organizations_disciplines_uom') }
								}
								,'organizations_disciplines_uom'
								, () => ({
									style: {
										height: '3em'
									}
								})
							)
					)
				])

				,elements.list([
					m('label.control-label.mb1', ' Average Unit of Measure / Day ')
					,m(
						NumberInput
						,R.merge(
							data
							,{
								errors
								,errorLabel: 'Work Rate Per Day'
							}
						)
						,{
							prop: (value) => setOptions(value, 'organizations_disciplines_nominal_rate')
							,attrs: {
								min: 0
								,step: 0.01
							}
						}
					)
				])

				,data.editContractPermissions()
				? elements.list([
					m('label.control-label.mb1', 'Nominal Charge Amount Per UOM')
					,Pencil(
						() => makeMoney(checkMultiple()[0].organizations_disciplines_nominal_revenue_rate())
						,() =>
							m(
								NumberInput
								,R.merge(
									data
									,{
										errors
										,errorLabel: 'Nominal Charge Amount Per UOM'
									}
								)
								,{
									prop: (value) => setOptions(Math.abs(value), 'organizations_disciplines_nominal_revenue_rate')
									,attrs: {
										min: 0
										,step: 0.01
										,title: `Average amount to charge clients when this disciplines is used. May be altered for specific clients via financials and the contract selection`
									}
								}
							)
					)
				])
				: data.readContractPermissions()
				? elements.list([
					m('label.control-label.mb1', 'Nominal Charge Amount Per UOM')
					,makeMoney(checkMultiple()[0].organizations_disciplines_nominal_revenue_rate())
				])
				: null

				,errors()['Nominal Charge Amount Per UOM']
				? elements.alert(
					`warning`
					, `Charge Amounts must be positive as this is money that would be gained as revenue by the organization`
				)
				: null

				,data.editContractPermissions()
				? elements.list([
					m('label.control-label.mb1', 'Nominal Pay Amount Per UOM')
					,Pencil(
						() => makeMoney(checkMultiple()[0].organizations_disciplines_nominal_expenditure_rate())
						,() =>
							m(
								NumberInput
								,R.merge(
									data
									,{
										errors
										,errorLabel: 'Nominal Pay Amount Per UOM'
									}
								)
								,{
									prop: (value) => setOptions(Math.abs(value)*-1, 'organizations_disciplines_nominal_expenditure_rate')
									,attrs: {
										max: 0
										,step: 0.01
										,title: `Average amount that would be paid when a team or resource conducts this discipline. May be altered for specific teams via financials and the contract selection`
									}
								}
							)
					)
				])
				: data.readContractPermissions()
				? elements.list([
					m('label.control-label.mb1', 'Nominal Pay Amount Per UOM')
					,makeMoney(checkMultiple()[0].organizations_disciplines_nominal_expenditure_rate())
				])
				: null

				,errors()['Nominal Pay Amount Per UOM']
				? elements.alert(
					`warning`
					, `Pay Amounts must be negative as this is money that would be expenditures of the organization`
				)
				: null

				,editResourcePermissions()
				? elements.list([
					m('label.control-label.mb1'
						,'Make a team capable of this Work'
					)
					,Pencil(
						() => ''
						,() =>
							m(autocomplete.Main, {
								list: teams
								,onselect(v, model){

									checkMultiple()
									.forEach((d) => {
										const alreadyadded =
											d.applytoteams.find((t) => t.crew_id() == v.crew_id())

										if(!alreadyadded){
											d.created = true
											d.applytoteams = (d.applytoteams || []).concat(v)
										}
									})

									resources(resources())
									model.input('')
									model.chosen(null)

								}
								,field: 'teamresourcename'
								,attrs: {
									title: "Select teams that are able to conduct this discipline and it will become part of their capabilities"
								}
								,sort: R.prop('teamresourcename')
							})
					)
					,elements.alert('info', `Giving a team the capability to do this work will allow Odin to automatically schedule them for this type of work on any project`)
				])
				: null


				,checkMultiple().map((d) =>
					!d.applytoteams.length
					? []
					: [
						m('hr', d.organizations_disciplines_name())
						,m('br')
						,elements.strikeList(
							d.applytoteams
							.map(
								t => ({
									label: t.teamresourcename
									,action: () => {
										checkMultiple().forEach((d) =>
											d.applytoteams
											.splice(
												d.applytoteams
												.findIndex((dt) =>
													dt.crew_id()
													== t.crew_id()
												)
												,1
											)
										)
									}
								})
							)
						)
					]
				)

				,requiredTools()

				,elements.list([
					m('label.control-label.mb1', ' Task Count ')
					,[R.sum(R.map(R.prop('completedcount'), checkMultiple()))]
						.filter( x => !Number.isNaN(x)).concat('N/A').shift()
				])
				,elements.list([
					m('label.control-label.mb1', ' Task Completed Sum ')
					,[R.sum(R.map(R.prop('completedsum'), checkMultiple()))]
						.filter( x => !Number.isNaN(x)).concat('N/A').shift()
				])
				,elements.list([
					m('label.control-label.mb1', ' Task Scheduled Sum ')
					,[R.sum(R.map(R.prop('scheduledsum'), checkMultiple()))]
						.filter( x => !Number.isNaN(x)).concat('N/A').shift()
				])

				,checkMultiple().length > 1
				? elements.alert(`warning`, `Files must be uploaded to one discipline at a time`)
				: m(HarthUppy, {
					getFields: uploadmetadata
					, data
				})
			]


		}

	const appliedFlows =
		prop.merge(
			[
				projects
				,flows
			]
		)
		.map(([projects, flows]) => {

			return R.indexBy(
				R.pipe(R.prop('project_id'), R.call)
				,projects.map((p) => {
					let flow_list = []
					// var dList = []
					flows.forEach((flow) => {
						const flowPrimaries = flow.flow_primary_activity
						const flowDependants = flow.flow_dependent_activity

						const priInter =
							R.innerJoin(
								(a,b) =>
									checkprop('organizations_disciplines_id', null, a)
									== checkprop('organizations_disciplines_id', null, b)
								,p.disciplines
								,flowPrimaries
							)

						const secInter =
							R.innerJoin(
								(a,b) =>
									checkprop('organizations_disciplines_id', null, a)
									== checkprop('organizations_disciplines_id', null, b)
								,p.disciplines
								,flowDependants
							)

						if ( priInter.length && secInter.length ) {

							flow_list.push({
								fname: flow.flow_name()
								,priInter: elements.nonStrikeList(
									priInter
									.map(
										a => ({
											label:
											a.discipline_description
											? a.discipline_name() + ' - ' + a.discipline_description()
											: a.discipline_name
											,action: () => {}
										})
									)
								)
								,secInter: elements.nonStrikeList(
									secInter
									.map(
										a => ({
											label:
											a.discipline_description
											? a.discipline_name() + ' - ' + a.discipline_description()
											: a.discipline_name
											,action: () => {}
										})
									)
								)
							})
						}
					})

					return R.merge(
						p
						,{
							flow_list:
								elements.table(data,
									['', 'Objectives', 'Dependants', ]
									,flow_list.map(
										(f) => [
											f.fname
											,f.priInter
											,f.secInter
										]
									)
								)
							,activeRelation: flow_list.length
						}
					)
				})
			)
		})


	const vm = m.prop({
		project: m.prop(false)
		,dependant: m.prop(false)
		,primary: m.prop(false)
		,pending: m.prop(false)
		,selected: {
			flow: null
		}
	})

	const edit = m.prop(false)

	let viewtype = ListFlow

	const filterFunction = {
		changeNames: [
			{ugly_name: 'flow_'}
			,{ugly_name: 'flow_dependent_activity', better_name: "Number of Dependents"}
			,{ugly_name: 'flow_primary_activity', better_name: "Number of Objectives"}
			,{ugly_name: 'flow_primary_activity_name', better_name: "Objective Names"}
			,{ugly_name: 'flow_dependent_activity_name', better_name: "Dependent Names"}
			,{ugly_name: 'scheduledsum', better_name: 'Sum of Tasks Scheduled'}
			,{ugly_name: 'completedsum', better_name: 'Sum of Tasks Completed'}
			,{ugly_name: 'completedcount', better_name: 'Count of Tasks Completed'}
		]
		,uniqueArrays: [
			{array_name: 'flow_dependent_activity', unique: "flows_dependent_activity_id"}
			,{array_name: 'flow_primary_activity', unique: "flows_primary_activity_id"}
		]
		,template: {
			flows: {
				flow_name: ""
				,flow_safety_ratio: 0
				,flow_delay_in_days: 0
				,flow_delay_type: ""
				,flow_dependency_relationship: ""
				,flow_dependent_activity: [{
					flow_dependent_activity_name: ""
				}]
				,flow_primary_activity: [{
					flow_primary_activity_name: ""
				}]
			}
			,organizations_disciplines: {
				organizations_disciplines_description: ""
				,organizations_disciplines_nominal_rate: 0
				,organizations_disciplines_uom: ""
				,completedsum: 0
				,scheduledsum: 0
				,completedcount: 0
				,organizations_disciplines_name: ""
			}
		}
		,FlowsFilters: null
	}

	data.currentRoute('Nothing')
	data.initiateContractFetch
	.map(function(i){
		loading(true)
		metaDataLoading(true)

		const schange = i.schange || !data.routeInitialized()['flows']

		if ( schange ){
			vm().selected.flow = null
			viewtype = ListFlow
			deleteFlowStateProp(false)
			deleteObjective = []
			deleteDependent = []
		}

		return (
			!schange
			? Promise.resolve([])
			: Promise.all([
				fetchFlows()
				,fetchOrganizationsDisciplines()
			])
			.then(function(){
				metaDataLoading(true)
				fetchProjects()
				editPermissions(
					Permissions.hasWrite(
						data.permissions()
						, 'flow_management'
					)
				)
				editResourcePermissions(
					Permissions.hasWrite(
						data.permissions()
						, 'resource_management'
					)
				)
				editProjectPermissions(
					Permissions.hasWrite(
						data.permissions()
						, 'project_management'
					)
				)
				editOrganizationPermissions(
					Permissions.hasWrite(
						data.permissions()
						, 'organization_management'
					)
				)
				return true
			})
		)
			.then(function(){
				i.schange = false
				i.ochange = false
				return loading(false)
			})
	})

	let delaytypemessageblock;
	let indexby = m.prop('Organization Disciplines')

	let delaytypemessage = function(delaytypeti){
		if (delaytypeti == "End to Start"){
			delaytypemessageblock = elements.alert(
				'info'
				, `An End to Start delay will take a reference at the conclusion of the dependant discipline to govern the commencement of the objective discipline.
				The delay can be negative or positive in time. As an example, setting the duration to -2 will set the objective discipline to start 2 days BEFORE the dependant discipline is finished.
				Similarly setting the duration to 2 will set the objective discipline to start 2 days after the dependant discipline finishes`
			)
		}
		else {delaytypemessageblock =
			elements.alert(
				'info'
				, `A Start to Start delay will take a reference at the commencement of the dependant discipline to govern the commencement of the objective discipline.
				The delay can be negative or positive in time. As an example, setting the duration to -3 will set the objective discipline to start 3 days BEFORE the dependant discipline start time.
				Similarly setting the duration to 3 will set the objective discipline to start 3 days after the dependant discipline start time`
			)
		}


		return delaytypemessageblock
	}


	let delaymessage = function(safety){
		return	safety == "Risk Ratio"
			? `This setting lets the objective discipline run in parallel with the dependant discipline to save project time whilst ensuring the dependant discipline
			always finishes first but with a minimum buffer relative to the value of the Risk Ratio, increasing the ratio will close the buffer and save more time.
			Lowering the ratio will be safer and give more time to dependant disciplines before commencing objective disciplines.
			`
			: null
	}

	//todo-james hack to make the screen update when the schedule id changes

	/* eslint-disable array-callback-return, no-unused-vars */
	const forceRedraw = prop.merge(
		[
			data.calculated
			,errors
			,appliedProjects
			,appliedFlows
		]
	).map(function(){
		setTimeout(function(){
			m.redraw()
		}, 0)
	})
	/* eslint-enable array-callback-return, no-unused-vars */

	const discardChangesButton =
		() =>
			identical(baseOrganizationsDisciplines(), organizations_disciplines())
			? m('span'
				+ css`
					font-weight: bold;
				`
				,''
			)
			: m("button.btn.btn-warning"
				, {
					disabled: false
					,onclick: () => {
						baseOrganizationsDisciplines(baseOrganizationsDisciplines())
						deleteOrganizationDisciplineStateProp(false)
						newtoolPresets([])
						toolPresetDeletions([])
						checkMultiple([])
					}
					,title: "Discard all changes since last saving Disciplines"
				}
				,"Discard Changes"
			)

	const orgddata =
		(d) =>
			editOrganizationPermissions() || editProjectPermissions()
			? {
				'Discipline Name':
					Pencil(
						() => d.organizations_disciplines_name()
						,() =>
							elements.textInput(
								(value) => setOptions(value, 'organizations_disciplines_name', [d])
								,{
									title: "Name of the discipline"
								}
							)
					)
				,'Discipline Description':
					Pencil(
						() => d.organizations_disciplines_description()
						,() =>
							elements.textInput(
								(value) => setOptions(value, 'organizations_disciplines_description', [d])
								,{
									title: "Description of the discipline"
								}
							)
					)
				,'Unit of Measure':  d.organizations_disciplines_uom()
				,'Average Unit of Measure / Day':
					m(
						NumberInput
						,R.merge(
							data
							,{
								errors
								,errorLabel: 'Work Rate Per Day'
							}
						)
						,{
							prop: (value) => setOptions(value, 'organizations_disciplines_nominal_rate', [d])
							,attrs: {
								min: 0
								,step: 0.01
							}
						}
					)
				,'Task Count': d.completedcount
				,'Task Completed Sum': d.completedsum
				,'Task Scheduled Sum': d.scheduledsum
			}
			: {
				'Discipline Name': d.organizations_disciplines_name()
				,'Discipline Description': d.organizations_disciplines_description()
				,'Unit of Measure': d.organizations_disciplines_uom()
				,'Average Unit of Measure / Day': d.organizations_disciplines_nominal_rate()
				,'Task Count': d.completedcount
				,'Task Completed Sum': d.completedsum
				,'Task Scheduled Sum': d.scheduledsum
			}


	const largeOrgdTable = () =>
		scrollableTable.advanced({
			scoped: data.scoped
			,resize$: data.resize$
			,alwaysopen: true
			,rows:
				ListProcessing(
					organizations_disciplines()
					, filterFunction.OrganizationDisciplineFilters.filters()
					, 'organizations_disciplines_name'
				)
				.map((o, oindex) => ({
					key: o.key
					,selection: getActivateDeleteButton(o)
					,header:
						oindex != 0
						? ''
						: m('.header'
							+ css`
								font-weight: bold;
								display: flex;
								justify-content: space-between;
								align-items: center;
								padding-bottom: 0.5em;
							`
							,m('span'
								+ css`
									font-weight: bold;
								`
								,''
							)
							,m('.data'
								+ css`
									display: grid;
									grid-template-rows: 0.25fr 0.5fr;
									overflow: hidden;
								`
								,m(`button.pl2`
									+ css`
										backgroundColor: transparent;
										transition: 0.2s;
										opacity: 1;
										color: #434aa3;
										border: none;
									`
									.$hover(`
										opacity: 0.8;
									`)
									.$active(`
										opacity: 0.5;
									`)
									,{
										disabled: !!check_onames(true)
										,onclick: () => {
											const neworgd = resetOrganizationDisciplinesForm()
											organizations_disciplines().unshift(neworgd)
											checkMultiple([neworgd])
										}
										,title: "Create a new type of Discipline for the organization to use to register works"
									}
									, 'Create a New Discipline '
								)
							)
							,oindex == 0 && false
							? discardChangesButton()
							: m('span'
								+ css`
									font-weight: bold;
								`
								,''
							)
						)
					,layouts: {
						expanded: ({ x, headers, i }) =>
							m('.expanded'
								+ css`
									display: grid;
									justify-content: space-between;
									grid-template-columns: 1fr;
								`
								, m('.data'
									+ css`
										display: grid;
										justify-content: space-between;
										grid-template-columns: 1fr 1.5fr 0.75fr 0.75fr 0.5fr 0.5fr 0.5fr;
										overflow: hidden;
										gap: 1em;
										align-items: start;
										align-content: start;
									`
									, headers
										.map(
											k => [
												i == 0
												? m('.data'
													+ css`
														display: grid;
														grid-template-rows: 0.25fr 0.5fr;
														overflow: hidden;
													`
													,[m('label.control-label', k), x[k]]
												)
												: m('.data'
													+ css`
														display: grid;
														overflow: hidden;
													`
													,x[k]
												)
											]
										)

								)
							)
					}
					,data: orgddata(o)
				}))
		})

	const smallOrgdTable = () =>
		scrollableTable.advanced({
			scoped: data.scoped
			,resize$: data.resize$
			,alwaysopen: true
			,rows:
				ListProcessing(
					organizations_disciplines()
					, filterFunction.OrganizationDisciplineFilters.filters()
					, 'organizations_disciplines_name'
				)
				.map((o, oindex) => ({
					key: o.key
					,header:
						m('span'
							+ css`
								font-weight: bold;
								display: flex;
								justify-content: space-between;
								align-items: center;
								padding-bottom: 0.5em;
							`
							,m('input'
								+ css`
									width: 25px;
									height: 25px;
									margin: 0px;
								`
								.$media('(hover: hover)',
									css`
										width: 20px;
										height: 20px;
									`
								)
								,getActivateDeleteButton(o)
							)
							,m(`button.pl2`
								+ css`
									backgroundColor: transparent;
									transition: 0.2s;
									opacity: 1;
									color: #434aa3;
									border: none;
								`
								.$hover(`
									opacity: 0.8;
								`)
								.$active(`
									opacity: 0.5;
								`)
								,{
									disabled: !!check_onames(true)
									,onclick: () => {
										const neworgd = resetOrganizationDisciplinesForm()
										organizations_disciplines().unshift(neworgd)
										checkMultiple([neworgd])
									}
									,title: "Create a new type of Discipline for the organization to use to register works"
								}
								, 'Create a New Discipline '
							)
							,oindex == 0 && false
							? discardChangesButton()
							: m('span'
								+ css`
									font-weight: bold;
								`
								,''
							)
						)
					,layouts: {
						expanded: ({ x }) =>
							m('.expanded'
								+ css`
									display: grid;
									justify-content: space-between;
									grid-template-columns: 1fr;
								`
								,m('.data'
									+ css`
										display: grid;
										justify-content: space-between;
										grid-template-columns: 0.25fr 0.75fr;
										padding-bottom: 2em;
									`
									,['Discipline Name', 'Discipline Description']
									.map((k) =>
										m('.data'
											+ css`
												display: grid;
												grid-template-rows: 0.25fr 0.25fr;
												padding-right: 0.25em
											`
											,[
												m('label.control-label.f4', k)
												, k != 'Description'
													? x[k]
													: x[k] == o.organizations_disciplines_name()
														? x[k]
														: [x[k], o.organizations_disciplines_name()].filter(R.identity).join(' - ')

											]
										)
									)
								)

								, m('.data'
									+ css`
										display: grid;
										justify-content: space-between;
										grid-template-columns: 0.5fr 0.5fr;
										padding-bottom: 1em;
									`
									,['Unit of Measure', 'Average Unit of Measure / Day']
									.map((k) =>
										m('.data'
											+ css`
												display: grid;
												grid-template-rows: 0.25fr 0.25fr;
												padding-right: 0.5em;
											`
											,[m('label.control-label.f4', k), x[k]]
										)
									)
								)

								, m('.data'
									+ css`
										display: grid;
										justify-content: space-between;
										grid-template-columns: 0.25fr 0.25fr 0.25fr 0.25fr;
										padding-bottom: 1em;
									`
									,['Task Count', 'Task Completed Sum', 'Task Scheduled Sum', '']
									.map((k) =>
										m('.data'
											+ css`
												display: grid;
												grid-template-rows: 0.25fr 0.25fr;
												padding-right: 0.5em;
											`
											,[m('label.control-label.f4', k), x[k]]
										)
									)
								)

							)
					}
					,data: orgddata(o)
				}))
		})


	const OrgdTable = () =>
		m(Responsive
			,
			{
				breakpoints: {
					mobile:0
					,desktop:900
					,tablet:700
					,hd:1800
				}
			}
			,({ hd, desktopOnly, mobileOnly, tabletOnly }) => [
				hd( largeOrgdTable )
				,desktopOnly( largeOrgdTable  )
				,mobileOnly( smallOrgdTable )
				,tabletOnly( smallOrgdTable )
			]
		)


	function ListFlow(){
		return m('div.harth', [
			elements.tabset(['Organization Disciplines', 'Workflow Relationships', 'Affected Projects'], indexby)
			,m('br')
			,indexby() == 'Organization Disciplines'
			? [

				getfilters(null, "Discipline")

				,check_onames(false)
				,saveDiscardButtons()

				,loading()
				? elements.centeredSpinner()
				: organizations_disciplines().length == 0
				? elements.alert('info',`Organization Disciplines haven't been created yet`)
				: OrgdTable()
			]
			: indexby() == 'Workflow Relationships'
			? [
				elements.list([
					m("button.btn.", {
						disabled: schedule_id() == "" || schedule_id() == null ? true : !!check_fnames()
						,onclick: () => {
							viewtype = EditFlow
							form(resetForm())
							edit(false)
							getAvailableOptions()
						}
						,title: "Create a relationship between existing types of work to automate schedules"
					},"Create")
					// elements.action(
					// 	completedProjects() == "completed"
					// 		? 'Current Projects'
					// 		: 'Completed Projects'
					// 	,() => {
					// 		completedProjects() == "completed"
					// 			? completedProjects("inprogress")
					// 			: completedProjects("completed")
					// 		return fetchProjects()
					// 	}
					// 	, vm().pending
					// 	// , ' '
					// ),
					,elements.confirmDelete(deleteFlow, vm().pending, DeleteDisabled, deleteFlowStateProp )
					,m("button.btn.btn-warning", {
						disabled: !vm().selected.flow
							|| !vm().primary() && !vm().dependant()
						,onclick: () => {
							vm().selected.flow = null
							vm().primary(false)
							vm().dependant(false)
						}
						,title: "Hide intricate details"
					},"Hide Workflow Data")
				])
				,getfilters(null, "Flow")

				,elements.table(data, ['Manage', 'Flow Name', 'Objective', 'Dependency', 'Relationship', '', 'Applies to Projects'],
					ListProcessing(flows(), filterFunction.FlowsFilters.filters(), 'flow_name' ).map(
						(flow) => [
							elements.checkbox({
								onchange:
									function(event){
										if(event.currentTarget.checked == true){
											vm().selected.flow = flow
										}
										else if (event.currentTarget.checked == false){
											vm().selected.flow = null
										}
									}
								,checked: vm().selected.flow == null ? false : vm().selected.flow.flow_id() == flow.flow_id() ? true : false
								,disabled: vm().selected.flow == null ? false : vm().selected.flow.flow_id() == flow.flow_id() ? false : true
							})
							,m(`a`
								,{
									onclick:
										() => {
											vm().selected.flow = flow
											cloningFlow(false)
											viewtype = EditFlow
											edit(true)
											getAvailableOptions()
										}
									,title: "Edit a relationship to create a new relationship with identical details"
								}
								,flow.flow_name()
							)
							,flow.flow_primary_activity.length < 10
								? R.pluck('discipline_name', flow.flow_primary_activity).join(', ')
								: getflow_primary_activity(flow)
							,flow.flow_dependent_activity.length < 10
								? R.pluck('discipline_name', flow.flow_dependent_activity).join(', ')
								: getflow_dependent_activity(flow)
							,flow.flow_dependency_relationship()
							+ ' '
							+ (
								flow.flow_delay_type() == "Safety"
									? flow.flow_safety_ratio() + ' %'
									: flow.flow_delay_in_days()
							)
							,m(`a`
								,{
									onclick:
										() => {
											vm().selected.flow = flow
											cloningFlow(true)
											viewtype = EditFlow
											edit(false)
											getAvailableOptions()
										}
									,title: "Copy a relationship to create a new relationship with identical details"
								}
								,'Clone'
							)
							,metaDataLoading()
								? ' ... '
								: appliedProjects()[flow.flow_id()]['projectString'].length > 100
								? getflow_projects(flow)
								: appliedProjects()[flow.flow_id()]['projectString']
						]
					)
				)

				,m('br')

				,schedule_id() == null
					? elements.alert('info',`Please select a schedule.`)
					: flows().length
						? null
					: loading()
						? elements.centeredSpinner()
						: elements.alert('info',`Workflow Relationships can be used to govern work priorities in this schedule`)

				,m('br')
				,vm().primary() == true && vm().selected.flow
					?					scrollableTable.legacy(
						data
						,[
							['Objective Disciplines', 1]
						]
						.map(
							([text,width]) => ({ text, width })
						)
						,() => vm().selected.flow.flow_primary_activity.map( r => [r.discipline_name]).map(
							r => ({ children: r , height: 55 })
						)
						,{ height: 300, headerHeight: 25, minWidth: 700 }
					) : null

				,m('br')
				,vm().dependant() == true && vm().selected.flow
					? scrollableTable.legacy(
						data
						,[
							['Dependant Disciplines', 1]
						]
						.map(
							([text,width]) => ({ text, width })
						)
						,() => vm().selected.flow.flow_dependent_activity.map( r => [r.discipline_name]).map(
							r => ({ children: r , height: 40 })
						)
						,{ height: 300, headerHeight: 25, minWidth: 600 }
					) : null

			]
			: [
				metaDataLoading()
				? elements.centeredSpinner()
				: projects().length != 0
				? elements.table(data, ['Project', 'Start Date', 'Construction End', 'Handover Date', ''],
					projects().map(
						(p) => [
							p.project_name()
							,moment(p.project_start_date()).format("ll")
							,moment(p.project_dead_date()).format("ll")
							,moment(p.project_commissioning_date()).format("ll")
							,elements.details(
								'Flows'
								, appliedFlows()[p.project_id()]['flow_list']
								, appliedFlows()[p.project_id()]['activeRelation']
								? {}
								: { disabled: true }
							)
						]
					)
				)
				: elements.alert('info',`There aren't any projects in this schedule`)
			]
		])
	}


	function EditFlow(){

		return m('div.harth',[
			m('h4','Specify dependency relationships')
			,m('br')
			,editPermissions() ? elements.list([
				edit() == true
					? elements.back(
						"Go Back to the Workflow list without saving"
						,() => {
							cloningFlow(false)
							deleteObjective = []
							deleteDependent = []
							errors({})
							viewtype = ListFlow
						}
					)
				 : 					m("button.btn.", {
						disabled: false
						,onclick: () => {
							errors({})
							form(resetForm())
							vm().selected.flow = null
							deleteObjective = []
							deleteDependent = []
							viewtype = ListFlow
						}
						,title: "Discard this Workflow without saving"
					},"Discard")


				,SaveDisabled(true) || identical(vm().selected.flow, form().flow)
					? m('button.btn', {disabled: true}, 'Save')
					: elements.action('Save', sendFlows, vm().pending)
				,edit() == true
					? m("button.btn.btn-warning", {
						disabled: identical(vm().selected.flow, form().flow)
						,onclick: () => {
							errors({})
							cloningFlow(false)
							deleteObjective = []
							deleteDependent = []
							getAvailableOptions()
						}
						,title: "Discard all changes since last saving this flow"
					},"Discard Changes")
				 : null
			]) : elements.list([
				elements.back(
					"Go Back to the Workflows list without saving"
					,() => {
						cloningFlow(false)
						errors({})
						viewtype = ListFlow
					}
				)
			])

			,SaveDisabled(false)

			,elements.list([
				m('label.control-label', 'Designation '
					,elements.textInput(
						form().flow.flow_name, {
							disabled: editPermissions() == false
					})
				)
			])

			,elements.alert('info',
				"All objectives have this equivalent relationship "
				+ "to each dependent workflow. Options will get restricted when items are added "
				+ "to prevent the creation of circular references "
			)

			,organizations_disciplines().length == 0
				? elements.alert('warning', `To help you build relationships  `
					+ `disciplines must be created on projects or resources`
				)
				: null

			,elements.errorAlert(
				`I've restricted `
				,' and '
				,' to prevent detrimental relationships'
				,[primaryOptions(), dependentOptions()]
				,[
					(d) => d.length == 0 ? `Objective Options` : null
					,(d) => d.length == 0 ? `Dependent Options` : null
				]
				,false
				,true
				,'warning'
				,disconnectedDisciplines().length > 0
					? [
						m('label.control-label', 'Disconnected Disciplines')
						,disconnectedDisciplines().map((d, dindex) =>
							dindex + 1
							+ '. '
							+ d.discipline_name()
							+ (
								d.discipline_description()
									? ' - ' + d.discipline_description()
									: ''
							)
						)
						.map(s => m('p.pv1.ma0', s ))
						,m('hr')
					]
					: null
			)

			,elements.splitPane(
				[ m('h4','Objectives')
				,elements.list([
					m('label.control-label', 'Add objectives ')
					,autocomplete.strict(
						primaryOptions
						,(v, model) => {
							editOption(true, form().flow.flow_primary_activity , v)
							getAvailableOptions()
							model.input('')
							model.chosen(null)
						}
						,'orgDisciplineNameDescription'
						,() => ({
							disabled: editPermissions() == false || primaryOptions().length == 0
							,title: "Select a discipline to add to the objectives"
						})
						,(value, element) => {
							element.value = ""
						}
					)
				])
				,!form().flow.flow_primary_activity.length
					? null
					:( editPermissions()
						? elements.strikeList
						: elements.nonStrikeList
					)(
							form().flow.flow_primary_activity
								.map(
									label => ({
										label: label.discipline_name
										,action: () => {
											editOption(false, form().flow.flow_primary_activity , label )
											getAvailableOptions()
										}
									})
								)
						)
				]
				,[m('h4','Dependents')
					,elements.list([
						m('label.control-label', 'Add dependents ')
						,autocomplete.strict(
							dependentOptions
							,(v, model) => {
								editOption(true, form().flow.flow_dependent_activity , v)
								getAvailableOptions()
								model.input('')
								model.chosen(null)
							}
							,'orgDisciplineNameDescription'
							,() => ({
								disabled: editPermissions() == false || dependentOptions().length == 0
								,title: "Select a discipline that will affect objectives"
							})
							,(value, element) => {
								element.value = ""
							}
						)
					])
					,!form().flow.flow_dependent_activity.length
						? null
						:( editPermissions()
							? elements.strikeList
							: elements.nonStrikeList
						)(
							form().flow.flow_dependent_activity
								.map(
									label => ({
										label: label.discipline_name
										,action: () => {
											editOption(false, form().flow.flow_dependent_activity , label )
											getAvailableOptions()
										}
									})
								)
						)
					]
			)

			,m('br')

			,elements.list([
				m('label.control-label', 'Dependency Relationship '
					,elements.select(
						["Start to Start", "End to Start", "Risk Ratio"]
						, form().flow.flow_dependency_relationship
						, {disabled: editPermissions() == false }
					)
				)
			])

			,delaymessage(form().flow.flow_dependency_relationship())

			,m('hr')

			,form().flow.flow_dependency_relationship() == "Start to Start"
			|| form().flow.flow_dependency_relationship() == "End to Start"

			? [
				elements.list([
					m('label.control-label', 'Offset Duration '
						,m(
							NumberInput
							,R.merge(
								data
								,{
									errors
									,errorLabel: 'Offset Duration'
								}
							)
							,{
								prop: form().flow.flow_delay_in_days
								,attrs: {
									disabled: editPermissions() == false
								}
							}
						)
					)
				])
				,delaytypemessage(form().flow.flow_dependency_relationship())
			]
			: [
				elements.list([
					m('label.control-label', 'Risk Ratio % '
						,m(
							NumberInput
							,R.merge(
								data
								,{
									errors
									,errorLabel: 'Risk Ratio'
								}
							)
							,{
								prop: form().flow.flow_safety_ratio
								,attrs: {
									min: 0.01
									, step: 0.01
									, max: 100
									, disabled: editPermissions() == false
								}
							}
						)
					)
				])

				,`The value of the ratio is used to make predictions
				of exceeding limits that would prove detrimental to the flow of work.
				A larger Risk Ratio saves more time but will be very dependant on
				reliable progressive work flow, (higher ratio, higher risk) 40 is a safe medium`
			]

		])
	}


	function view(){
		return m('div'
			, viewtype()
			, DetailsPane(
				detailsPaneOpen
				,checkMultiple().length > 1
					? 'Edit Multiple Disciplines'
					: ''
				,() => detailPaneInputs()
			)
		)
	}

	return { view }
}

export default Main