import m from 'bacta'

const ProjectDocument = {
	toString: x =>
		[ x.project_documents_label || 'Untitled'
		, ProjectDocument.toStringDeliverable(x)
		]
		.join(' ')
	,toStringDeliverable: x =>
		x.project_documents_is_deliverable
		? ' (Deliverable)'
		: ''
	,toTitle: x =>
		x.project_documents_label +' ('+x.project_documents_href+')'

	,toVTree: x =>
		m('a',
			{ href: x.project_documents_href
			, key: x.project_documents_id
			, onclick: e => e.stopImmediatePropagation()
			, rel: 'noopener noreferrer'
			, title: ProjectDocument.toTitle(x)
			}
			, ProjectDocument.toString(x)
		)

	,filter: ({editProjectPermissions}) => x =>
		x.project_documents_is_deliverable
		? editProjectPermissions
		: true
}

export default ProjectDocument