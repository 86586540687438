/* globals setTimeout */

import m from 'bacta'
import * as elements from '../components/elements'
import NumberInput from '../components/number'
import humanList from '../utils/human_list'
import Permissions from '../models/permissions'
import {
	filterView
	,ListProcessing
	,FilterInputs
} from '../components/filter'

import HarthUppy from '../components/harth-uppy'
import { Light as DetailsPane } from '../components/detailspane2'
import Pencil from '../components/pencil'
import * as R from 'ramda'
import {identical} from '../utils/harth-identical'
import scrollableTable from '../components/scrollableTable'
import autocomplete from './autocomplete'
import moment from 'moment'
import { prop as prop2 } from '../../../stream'
import css from 'bss'
import Responsive from '../components/responsive'
import { money as makeMoney } from '../utils/regExes'
import Promise from 'bluebird'
import * as uuid from 'uuid'

const checkprop = (a, b, c) =>
	c[a] && b && c[a][b]
		? typeof c[a][b] == 'function'
			? c[a][b]()
			: c[a][b]
		: typeof c[a] == 'function'
			? c[a]()
			: c[a]

const nullUser =
	{
		user_id: m.prop(null)
		, user_username: m.prop('Unassigned')
		, usersFullName: 'Unassigned'
	}


function Intent(model){

	function fetchOrganizationsDisciplines(){
		return model.data.fetchOrganizationsDisciplines({})
			.then(model.organizations_disciplines)
			.then(function(){
				return model.organizations_disciplines()
				? model.availableUOMS(
					R.uniqBy(
						(a) => a.organizations_disciplines_uom
						, model.organizations_disciplines()
					)
				)
				: null
			})
			.then(m.redraw)
	}

    const deleteResource = (r) =>
    	r
    	? model.api.contractors.remove.one(r.contractor_id()).then(() => model.deleteResource(r))
		: Promise.reject('Can not delete a contractor without a contractor_id')

	const deleteCapability = (makeAPICalls) =>
		makeAPICalls
			? Promise.all( model.deleteTeams.map((t) => model.api.crews.remove.one(t)) )
			: ( !model.crew_vm().selected.capability.crew_id()
					? null
					: model.deleteTeams.push(
						model.crew_vm().selected.capability.crew_id()
					)
			) && model.deleteCapability()

	const deleteDisciplineRate = (makeAPICalls) =>
		makeAPICalls
			? Promise.all( model.deleteRates().map((t) => model.api.crews_discipline_rates.remove.one(t)) )
			: model.checkMultiple().data.map((discipline) => {
				!discipline.crews_discipline_rates_id()
					? null
					: model.deleteRates().push(discipline.crews_discipline_rates_id())
				model.deleteDisciplineR(discipline)
				return true
			})
			&& model.masterLIST(true)


    const sendResource = function(){

		model.metaDataLoading(true)
        if (model.crew_form().capability.crew_name != null){
            let index = R.findIndex(function(chr) { return chr.crew_id() == model.crew_form().capability.crew_id() }, model.form().resource.crews)
            model.form().resource.crews.splice(index, 1)
            model.form().resource.crews.push(model.crew_form().capability)
        }

        let resourceList = [model.form().resource]
        let geoy = model.process_geo(resourceList[0].contractor_yard())
        let geolist = geoy ? [geoy] : []

		model.data.currentRoute('Nothing')

        //todo-james why are we mapping if there is only ever one item in the list?
        let resources = resourceList.map((res) => {
			let newcrews = res.crews.map((crew) => {
				let newrates = crew.crews_discipline_rates.map((rate) => {
					return Object.assign({}, rate, {})
				})
				let newcrew = Object.assign({}, crew, {})
				newcrew.crews_discipline_rates = newrates
				return newcrew
			})
            let newcontractor = Object.assign({}, res)
			newcontractor.crews = newcrews
			return newcontractor
        })

		resources.forEach((r) => {
			delete r.showWork
			r.crews.forEach((crew) => {
				delete crew.showWork
				crew.crews_discipline_rates.forEach((d) => {
					delete d.created
				})
			})
		})

		const toolschange = identical(model.tools(), model.tools_vm())
		const contractorchange =
			model.viewtype == EditResource
				? identical(model.vm().selected.resource, model.form().resource)
				: identical(model.crew_vm().selected.capability, model.crew_form().capability)

		return Promise.all([
			model.deleteProjectResource(true)
			,model.deleteProjectTeam(true)
			,toolschange
				? Promise.resolve(null)
				: model.api.tools.patch.many( model.tools_vm() )
					.then(function(toolList){
						model.tools(toolList)
						return model.cloningTools()
					})
			,(
				geolist.length
					? model.api.geographies.patch.one(geolist[0])
					: model.api.geographies.fetch.all()
			).then( model.geographies )
				,contractorchange
				? Promise.resolve(null)
				: deleteDisciplineRate(true)
					.then(function(){
						return deleteCapability(true)
						.then(function(){
							model.deleteRates([])
							model.deleteTeams = []
							return model.api.contractors.patch.one(resources[0])
								.then( prepareResources )
						})
					})

		])
		.then(function(){
			fetchOrganizationsDisciplines()
			model.fetchWorkList('crew_id')
			model.fetchWorkList('contractor_id')
			model.data.currentRoute('contractors')
			model.metaDataLoading(false)
			return true
		})
	}

	function prepareResources(contractors){
		contractors.forEach((contractor) => {
			contractor.showWork = m.prop(false)
			contractor.crews.forEach((crew) => {
				crew.showWork = m.prop(false)
			})
		})

		const comparisonProp =
			model.form().resource.contractor_id
			&& model.form().resource.contractor_id()
			? 'contractor_id'
			: 'contractor_name'

		model.resources(
			R.uniqBy(
				(c) => c.contractor_id()
				,R.sort(
					R.descend(R.prop('__requestedAt'))
					,[].concat(
						contractors
						,model.resources()
					)
				)
			)
		)

		const newResource =
			model.form().resource.contractor_id
				? model.resources().find((p) =>
					R.prop(comparisonProp, p)()
					== R.prop(comparisonProp,  model.form().resource)()
				)
				: null

		model.data.routeids({
			routeids: model.resources().map(R.prop('contractrecognitionid'))
			,getledgers: false
			,ledgerdetails: false
			,getmatdata: false
			,specificproject: null
		})

		if(
			newResource
			&& (
				!model.form().resource['__requestedAt']
				|| newResource['__requestedAt']
				>= model.form().resource['__requestedAt']
			)
		){

			model.discipline_vm({})
			model.checkMultiple().data = []
			model.setOptionsMark(false)

			model.vm().selected.resource = newResource
			model.form().resource = model.cloningResource(false, true)
			model.contractor_edit(true)
			if (model.crew_form().capability.crew_name != null){
				const updated_crew = model.form().resource.crews.find(
					(c) => c.crew_name() == model.crew_form().capability.crew_name()
				)
				model.crew_vm().selected.capability = updated_crew
				model.crew_form().capability = model.cloningResourceCapability(false, true)
				model.crew_edit(true)
				model.masterLIST(true)
			}
		}
		return contractors
	}

	function fetchResource(params){
		return model.data.fetchResource(
			R.merge(
				{props:{}}
				,params
			)
		)
		.then( prepareResources )
	}


	model.data.currentRoute('Nothing')
	model.data.initiateContractFetch
	.map(function(i){

		const ochange = i.ochange || !model.data.routeInitialized()['contractors']

		if ( ochange ){
			model.viewtype = ListResource
			model.vm().selected.resource = {}
			model.deleteDisciplinesTeamsArrays([])
			model.deleteDisciplinesResourceArrays([])
			model.tools_vm([])
			model.disciplinesResources([])
			model.deleteTeams = []
			model.resources([])
			model.deleteRates([])
			model.deleteStateProp({})
		}

		model.loading(true)
		model.metaDataLoading(true)
		return (
			!i.organization_id
			|| !ochange
			? Promise.resolve([])
			: Promise.all([
				fetchResource({ depth: 2 })
				,fetchOrganizationsDisciplines()
				,model.data.fetchUsers()
					.then(
						R.concat([nullUser])
					)
					.then(model.users)
				,model.data.fetchGeographies()
					.then( model.geographies )
				,model.data.fetchTools({})
					.then(function(toolList){
						model.tools(toolList)
						model.cloningTools()
						return true
					})
			])
		)
			.then(function(){
				 model.loading(false)
				 model.metaDataLoading(true)
				 return fetchResource()
				 .then(function(){
					return (
						i.schange || ochange
						? model.fetchWorkList('contractor_id')
						: Promise.resolve([])
					)
					.then(function(){
						model.data.currentRoute('contractors')
						model.metaDataLoading(false)
						i.schange = false
						i.ochange = false
						m.redraw()
						return true
					})
				 })
			})

	})


	return {
        sendResource
		, fetchResource
		, deleteResource
		, deleteCapability
		, deleteDisciplineRate
		, suburbs: model.suburbs
    }
}


/*
	Model: Data storage and functions that interact with that data.

	The vm represents the view model, any thing to do with the state of the UI
	that doesn't map to a database record is stored on the view model.

	It could be whether or not a button is disabled due to a background process.
	Or it could be storing how many items are selected in order to render a tally.

*/
function Model(data){

	const filteredRateOptions = data.scoped([])
	const metaDataLoading = data.scoped(true)
	const loading = data.scoped(true)
	const teamWorkList = m.prop({})
	const resourceWorkList = m.prop({})
	const loadingMeta = m.prop(true)
	const availableDisciplineSelections = data.scoped([])
	const availableUOMS = data.scoped([])
	const tools = m.prop([])
	const resources = data.scoped([])
	const geographies = m.prop([])
    const suburbs = data.scoped([])
	const deleteTeams = []
	const deleteRates = m.prop([])
	const organizations_disciplines = data.scoped()
	const deleteDisciplinesTeamsArrays = m.prop([])
	const deleteDisciplinesResourceArrays = m.prop([])
	const disciplinesResources = data.scoped([])
	const errors = data.scoped({})
	const missingPermissions =
		data.initiateContractFetch.map(() =>
			humanList(
				[
					'Project'
					,'Tool'
					,'Contract'
					,'Invoice'
				]
					.map((p)=>
						data['read'+p+'Permissions']()
							? ''
							: p
					)
					.filter((p) => p)
			)
		)

	const CONSTANTdisciplines = data.scoped([])

	const restrictedOrgDisc = prop2.merge(
		[
			organizations_disciplines
			,CONSTANTdisciplines
		]
	).map(([organizationDisciplines, CONSTANTdisciplines]) =>
		R.differenceWith(
			(a, b) =>
				checkprop('organizations_disciplines_id', null, a)
				== checkprop('organizations_disciplines_id',  null, b)
			,organizationDisciplines
			,CONSTANTdisciplines
		)
	)

	const permissions = data.permissions
	// const forceRedraw =
		prop2.merge(	[
			data.calculated
			,errors
			,metaDataLoading
			,loading
		])
		.map(function(){
			// eslint-disable-next-line no-undef
			setTimeout(function(){
				m.redraw()
			}, 0)
			return null
		})

	const deleteStateProp = m.prop({})
	const deleteWorkProp = m.prop(false)
	const placeLong = suburbs.map(R.map(s => s.placeLong ))
	const suburbsInput = data.scoped()

	suburbsInput.map(
		text =>
			data.api.suburbs.fetch.byTextInput(text)
			.then(suburbs)
	)

	let editResourcePermissions = m.prop(false)
	let editAssignedToolPermissions = m.prop(false)
	let editToolPermissions = m.prop(false)

	let users = data.scoped([])


	let tools_vm = m.prop([])

	let form = m.prop({
		resource: {
			crews: []
		}
	})

	let crew_form = m.prop({
		capability: {
			crews_discipline_rates: []
		}
	})

    function deleteProjectResource(makeAPICalls){
    	return makeAPICalls
			? Promise.all(
				deleteDisciplinesResourceArrays().map((c) =>
					data.api.disciplines_contractors.remove.one(
						c.disciplines_contractors_id
						,data.selectedVersion().schedule_version_id
						,c.contractor_id
						,c.discipline_id
						,c.contractoruserid
						,c.crewuserid
					)
				)
			)
				.then(function(){

					R.uniqBy(
						R.prop('contractor_id')
						,deleteDisciplinesResourceArrays()
					)
					.forEach((c) =>
						!R.unnest(
							resourceWorkList()[c.contractor_id].workList
							.map((p) => p.disciplines )
						)
						.length
							? delete resourceWorkList()[c.contractor_id]
							: null
					)

					deleteDisciplinesResourceArrays([])
					return true
				})
			: Promise.resolve([])
	}

    function deleteProjectTeam(makeAPICalls){
		return makeAPICalls
			? Promise.all(
				deleteDisciplinesTeamsArrays().map((c) =>
					data.api.disciplines_contractors_crews.remove.one(
						c.disciplines_contractors_crews_id
						,data.selectedVersion().schedule_version_id
						,c.crew_id
						,c.discipline_id
						,c.crewuserid
					)
					&& ((data.coalatedSelectedVersion()[c.crew_id] || {}).forecastedProfits = 0)
				)
			)
				.then(function(){

					R.uniqBy(
						R.prop('crew_id')
						,deleteDisciplinesTeamsArrays()
					)
					.forEach((c) =>
						!R.unnest(
							teamWorkList()[c.crew_id].workList
							.map((p) => p.disciplines )
						)
						.length
							? delete teamWorkList()[c.crew_id]
							: null
					)

					deleteDisciplinesTeamsArrays([])
					return true
				})
			: Promise.resolve([])
	}

	let process_geo = function(geo_name){
		let index = R.findIndex(R.propEq('geography_depot', geo_name), geographies())
		let geo_item = R.find(R.propEq('placeLong', geo_name), suburbs())
		if (index == -1 && geo_item ){
			var new_geography = {
				geography_depot: geo_name
				,geography_latitude: Number(geo_item.latitude)
				,geography_longitude: Number(geo_item.longitude),
			}
			geographies().push(new_geography)
		}
		return new_geography
	}



	let cloningResource = function(clonemarker, pedit){
		let clone = resetForm().resource
		clone.contractrecognitionid = clonemarker == true ? null : vm().contractrecognitionid
		clone.contractor_name( clonemarker == true ? vm().selected.resource.contractor_name() + " (Clone)" : vm().selected.resource.contractor_name() )
		clone.organization_id( vm().selected.resource.organization_id() )
		clone.contractor_yard( vm().selected.resource.contractor_yard())
		clone.contractor_comfortable_work_zone( vm().selected.resource.contractor_comfortable_work_zone() )
		clone.contractor_total_rating( vm().selected.resource.contractor_total_rating() )
		clone.user_id( vm().selected.resource.user_id() )
		clone.contractor_quality_rating( vm().selected.resource.contractor_quality_rating() )
		clone.contractor_first_onsite_start_date( vm().selected.resource.contractor_first_onsite_start_date() )
		clone.contractor_id( clonemarker == true ? "" : vm().selected.resource.contractor_id() )
		clone.contractor_common("Not utilized across previous disciplines")
		clone.contractor_utilized("")
		clone.contractor_mixed_crews(0)
		clone.showWork( vm().selected.resource.showWork() )
		clone.crews = []
		clone.__requestedAt = vm().selected.resource.__requestedAt
		let reAPPLYC = crew_vm().selected.capability
		vm().selected.resource.crews.forEach((c) => {
			crew_vm().selected.capability = c
			clone.crews.push( cloningResourceCapability(false, pedit) )
		})
		crew_vm().selected.capability = reAPPLYC
		form().resource = clone
        return clone
	}


	var cloningResourceCapability = function(clonemarker, pedit){
		let crew_clone = resetCrewForm().capability
		crew_clone.contractrecognitionid = clonemarker == false && pedit == true ? crew_vm().selected.capability.contractrecognitionid : null
		crew_clone.contractor_id( clonemarker == false && pedit == true ? crew_vm().selected.capability.contractor_id() : "")
		crew_clone.crew_name( clonemarker == true ? crew_vm().selected.capability.crew_name() + " (Clone)" : crew_vm().selected.capability.crew_name() )
		crew_clone.crew_id( clonemarker == false && pedit == true ? crew_vm().selected.capability.crew_id() : "")
		crew_clone.teamresourcename( crew_vm().selected.capability.teamresourcename )
		crew_clone.user_id(crew_vm().selected.capability.user_id())
		crew_clone.crew_invalid("")
		crew_clone.crew_excess("")
		crew_clone.crew_utilized("")
		crew_clone.crew_common("Not utilized across previous disciplines")
		crew_clone.crew_work_completed(0)
		crew_clone.showWork( crew_vm().selected.capability.showWork() )
		crew_clone.crews_discipline_rates = []
		crew_vm().selected.capability.crews_discipline_rates.forEach((rate) => {
			crew_clone.crews_discipline_rates.push(
				cloningResourceCapabilityRate(rate, pedit)
			)
		})
		// crew_form().capability = crew_clone
        return crew_clone
	}

	var cloningResourceCapabilityRate = function(rate, clonemarker){
		let newDR = resetDRateForm()
		if (rate){
			newDR.contractrecognitionid = !clonemarker ? null : rate.contractrecognitionid
			newDR.crews_discipline_rates_id( !clonemarker ? '' : rate.crews_discipline_rates_id() )
			newDR.crew_id( !clonemarker ? '' : rate.crew_id() )
			newDR.organizations_disciplines_id( rate.organizations_disciplines_id() )
			newDR.crews_discipline_rates_rate( rate.crews_discipline_rates_rate() )
			newDR.crews_discipline_rates_description( rate.crews_discipline_rates_description() )
			newDR.crews_discipline_rates_name( rate.crews_discipline_rates_name() )
			newDR.crews_discipline_rates_feedback_rate( !clonemarker ? 0 : rate.crews_discipline_rates_feedback_rate() )
			newDR.crews_discipline_rates_uom( rate.crews_discipline_rates_uom() )
			newDR.organizations_disciplines_nominal_expenditure_rate( rate.organizations_disciplines_nominal_expenditure_rate() )
		} else {
			newDR.created = true
		}
		newDR.newcdr = !clonemarker || !rate ? true : false
		return newDR
	}


    //todo-james repetitive
        let deleteResource = function(r){
            let index = R.findIndex(function(chr) { return chr.contractor_id() == r.contractor_id()}, resources())
            resources().splice(index, 1)
            vm().selected.resource = {}
        }

        let deleteDisciplineR = function(discipline){
			let index =
				crew_form().capability.crews_discipline_rates
				.findIndex((chr) =>
					chr.crews_discipline_rates_name() == discipline.crews_discipline_rates_name()
					&& chr.crews_discipline_rates_id() == discipline.crews_discipline_rates_id()
					&& chr.key == discipline.key
				)

			crew_form().capability.crews_discipline_rates.splice(index, 1)
            discipline_vm({})
        }

        let deleteCapability = function(){
            let index = R.findIndex(function(chr) { return chr.crew_id() == crew_vm().selected.capability.crew_id()}, form().resource.crews)
            form().resource.crews.splice(index, 1)
            crew_vm().selected.capability = {}
        }


	var vm = m.prop({
		pending: m.prop(false)
		,selected: {
			resource: {}
		}
	})

	var crew_vm = m.prop({
		pending: m.prop(false)
		,selected: {
			capability: {}
		}
	})

	var discipline_vm = m.prop({})
	let setOptionsMark = m.prop(false)
	let savingProp = m.prop(false)
	let checkMultiple = m.prop({
		status: false
		,data: []
	})

	// repetitive
		let check_dnames = function(returnBoolean){
			const count = R.countBy( chr =>
				chr.crews_discipline_rates_name()
				, crew_form().capability.crews_discipline_rates || []
			)
			const errorArray = [
				(d, prop='crews_discipline_rates_name') => !d[prop]()
					? `Discipline Name must not be empty` : null
				,(d, prop='crews_discipline_rates_name') => count[d[prop]()] > 1
					? `Discipline Names must not be duplicated, check ` + d[prop]() : null
			]

			return elements.errorAlert(
					`To save, `
					,' and '
					,''
					,crew_form().capability.crews_discipline_rates || []
					,errorArray
					,returnBoolean
					,false
			)
		}

		let check_cnames = function(){
			return resources().some((res) =>
					!res.contractor_name()
				)
		}
		let check_crew_names = function(){
			return form().resource.crews.some(
					(crew) =>
					!crew.crew_name()
				)
		}


	var resetForm = function(){
		let form = {
			resource: {
				organization_id: m.prop("")
				,contractor_id: m.prop(uuid.v4())
				,contractor_name: m.prop("")
				,contractor_yard: m.prop("")
				,contractor_comfortable_work_zone: m.prop("Suburban")
				,contractor_mixed_crews: m.prop(0)
				,contractor_first_onsite_start_date: m.prop(new Date().getTime())
				,contractor_total_rating: m.prop(0)
				,contractor_quality_rating: m.prop(0.8)
				,contractor_common: m.prop("Not utilized across previous disciplines")
				,contractor_utilized: m.prop("")
				,user_id: m.prop()
				,crews: []
				,showWork: m.prop(false)
			}
		}
		return form
	}

	var resetCrewForm = function(){
		let crew_form = {
			capability: {
				teamresourcename: m.prop("")
				,contractor_id: m.prop("")
				,crew_id:  m.prop("")
				,crew_invalid:  m.prop("")
				,crew_excess:  m.prop("")
				,crew_utilized:  m.prop("")
				,crew_name: m.prop("")
				,crew_rating: m.prop(0)
				,user_id: m.prop(null)
				,crew_common:  m.prop("Not utilized across previous disciplines")
				,crew_work_completed: m.prop(0)
				,crews_discipline_rates: []
				,showWork: m.prop(false)
			}
		}
		return crew_form
	}

	var resetDRateForm = function(){
		return {
			organizations_disciplines_id: m.prop("")
			,crews_discipline_rates_id : m.prop("")
			,crew_id : m.prop("")
			,newcdr: true
			,created: false
			,crews_discipline_rates_rate: m.prop(1)
			,crews_discipline_rates_description: m.prop("")
			,crews_discipline_rates_name: m.prop("")
			,crews_discipline_rates_feedback_rate: m.prop(0)
			,crews_discipline_rates_uom: m.prop(null)
			,key: Math.random().toString(15).slice(2, 8)
			,organizations_disciplines_nominal_expenditure_rate: m.prop(0)
			,createcontracts: m.prop(false)
		}
	}

	let DeleteDisabled = function(r){
		let state = false
		if ( resources().length == 0
			|| r.contractor_name == null
			|| stillWorking(true, null, r)
			|| loadingMeta()
		) {state = true}
		return state
	}

	let DeleteDisabledCapability = function(team){
		return form().resource.crews.length == 0
			|| team.crew_name == null
			|| stillWorking(true, team)
			|| loadingMeta()
	}

	let crew_edit = m.prop(false)

	let contractor_edit = m.prop(false)

	let SaveRDisabled = function(returnBoolean){
		const count = R.countBy(
			chr => chr.contractor_name()
			,resources().filter((r) =>
				r.contractor_id() != form().resource.contractor_id()
			).concat(form().resource)
		)
		const errorArray = [
			(d) => !d.contractor_name() ? `Resource Name must not be empty` : null
			,(d) => !d.contractor_comfortable_work_zone() ? `Work Zone must not be empty` : null
			,(d) => !d.contractor_yard() ? `Base Of Operations must not be empty` : null
			,(d) =>	count[d.contractor_name()] > 1 ? `Resource Names must not be duplicated` : null
		]

		return elements.errorAlert(
			`To save, `
			,' and '
			,''
			,[form().resource]
			,errorArray
			,returnBoolean
			,false
			,'warning'
			,null
			,Object.keys(errors())
				.map((a) =>
					errors()[a]
						? a + ' ' + errors()[a]
						: ''
				)
				.filter((a) => a)
		)
	}


	let SaveCDisabled = function(returnBoolean){
		const count = R.countBy(
			chr => chr.crew_name()
			, form().resource.crews.filter((c) =>
				c.crew_id() != crew_form().capability.crew_id()
			).concat(crew_form().capability)
		)
		const errorArray = [
			(d) => !d.crew_name() ? `Team Name must not be empty` : null
			,(d) => count[d.crew_name()] > 1 ? `Team Names must not be duplicated`: null
		]
		return elements.errorAlert(
				`To save, `
				,' and '
				,''
				,[crew_form().capability]
				,errorArray
				,returnBoolean
				,false
				,'warning'
				,null
				,Object.keys(errors())
					.map((a) =>
						errors()[a]
							? a + ' ' + errors()[a]
							: ''
					)
					.concat(check_dnames(true))
					.filter((a) => a)
			)
	}

    const stillWorking = function(returnBoolean, team, r){
		const resource = r || vm().selected.resource
		const crew = team || crew_vm().selected.capability
		const indexArray = ['','','']
    	const errorArray = [
			(d) => {
				if (d.contractor_id){

					var Dindex = resourceWorkList()[d.contractor_id()]
					var Tindex = crew.crew_id && teamWorkList()[crew.crew_id()]
					var Cindex = checkMultiple().data.length
						? R.unnest(
							R.pluck(
								'disciplines'
								,(teamWorkList()[crew.crew_id()] || {workList:[]}).workList
							)
						)
						.filter((dc) =>
							checkMultiple().data.find((cd) =>
								cd.crews_discipline_rates_name()
								== dc
							)
						).length
						: [].length

					indexArray[0] = Dindex && !form().resource.contractor_id && !crew_form().capability.crew_id
						? `resource from working projects before deleting their records`
						: ''
					indexArray[1] = Dindex && Tindex && !crew_form().capability.crew_id
						? `team from working projects before deleting their records`
						: ''
					indexArray[2] = Dindex && Tindex && Cindex
						? `discipline from working projects of this team before deleting their records`
						: ''
				}

				return Dindex && !form().resource.contractor_id && !crew_form().capability.crew_id
				|| Dindex && Tindex && !crew_form().capability.crew_id
				|| Dindex && Tindex && Cindex
					? indexArray.filter((m) => m).join()
					: ''
			}
		]

		return elements.errorAlert(
				`Remove this `
				,''
				,'. Including those from other schedules'
				,[resource]
				,errorArray
				,returnBoolean
				,false
			)
    }

	let getUser = function(resource){
		const userObj = users().find(
				(chr) => chr.user_id() == resource.user_id()
			)
		return userObj
			? userObj.user_username()
			: null
	}

	let viewtype = ListResource

    permissions.map(function(p){

		editResourcePermissions(Permissions.hasRead(p, 'resource_management'))
		editToolPermissions(Permissions.hasRead(p, 'tool_management'))
		editAssignedToolPermissions(Permissions.hasRead(p, 'assigned_tools'))

		m.redraw()

		return null
	})


	let baseFilterFunction = {
		changeNames: [
			{ugly_name: "contractor_name", better_name: "Resource Name"}
			,{ugly_name: "contractor_yard", better_name: "Resource Base"}
			,{ugly_name: "contractor_comfortable_work_zone", better_name: "Resource Comfortable Work Zone"}
			,{ugly_name: "contractor_first_onsite_start_date", better_name: "Resource First Start Date"}
			,{ugly_name: "contractor_quality_rating", better_name: "Resource Quality Rating"}
			,{ugly_name: "crews_discipline_rates_rate", better_name: "Discipline Rate"}
			,{ugly_name: "crews_discipline_rates_name", better_name: "Discipline Name"}
			,{ugly_name: "crews_discipline_rates_description", better_name: "Discipline Description"}
			,{ugly_name: "crews_discipline_rates", better_name: "Amount Of Disciplines" }
			,{ugly_name: "crews", better_name: "Amount Of Teams" }
			,{ugly_name: "crew_name", better_name: "Team Name" }
		]
		,uniqueArrays: [
			{array_name: 'crews_discipline_rates', unique: "crews_discipline_rates_name"}
		]
		,template: {
			contractor_name: ""
			,contractor_yard: ""
			,contractor_comfortable_work_zone: ""
			,contractor_first_onsite_start_date: "date"
			,contractor_quality_rating: 0
			,crews: [{
				crew_name: ""
				,crews_discipline_rates: [{
                    crews_discipline_rates_rate: 0
                    ,crews_discipline_rates_name: ""
					,crews_discipline_rates_description: ""
                }]
			}]
		}
	}
	let I = R.identity
	let resourceFilters = {
		marker: "Resources"
		,filters: m.prop([])
		,disabled: () => resources().length == 0
		,changeNames: baseFilterFunction.changeNames
		,uniqueArrays: baseFilterFunction.uniqueArrays
		,template: baseFilterFunction.template
		,scoped: data.scoped
		,tableHeadings: [
			'Manage'
			, 'Resource'
			, 'Rating'
			, 'Assigned User Responsibility'
			, 'Expenditure'
			, 'Forecasted Expenditure'
			, 'Teams'
			, 'Scheduled Work'
		]
		,resources: R.pipe(resources, R.reverse)

		,resourceView: (resource) => [
			elements.checkbox({
				onchange: m.withAttr('checked', function(checked){
					vm().selected.resource = checked ? resource : {}
				})
				,checked: (vm().selected.resource.contractor_id || I)() == resource.contractor_id()
				,disabled: !(vm().selected.resource.contractor_id || I)() == resource.contractor_id()
			})
			,resource.contractor_name()
			,Number(resource.contractor_quality_rating()).toFixed(2)

			,getUser(resource)

			,!data.calculated()
				? ' ... '
				: R.find(
					(a) => a.crew_id
					== resource.contractor_id()
					,deleteDisciplinesResourceArrays()
				)
					? 'Save to re-calculate'
					: makeMoney(
						data.coalatedSelectedVersion()[resource.contractrecognitionid]
						? 							data.coalatedSelectedVersion()[resource.contractrecognitionid].currentProfits
							+ data.coalatedSelectedVersion()[resource.contractrecognitionid].outstandingProfits

						: 0
					)

			,!data.calculated()
				? ' ... '
				: R.find(
					(a) => a.crew_id
					== resource.contractor_id()
					,deleteDisciplinesResourceArrays()
				)
					? 'Save to re-calculate'
					: makeMoney(
						(
							data.coalatedSelectedVersion()
								[resource.contractrecognitionid]
							|| {}
						).forecastedProfits
					)

			,resource.crews.length

			,!data.readProjectPermissions()
				? elements.alert(`info`, "Requires Project Permissions are required to see scheduled works")
				: loadingMeta()
					? ' ... '
					: !resourceWorkList()[resource.contractor_id()]
						? "This Resource has no scheduled work"
						: !resourceWorkList()[resource.contractor_id()].workList.length
						&& resourceWorkList()[resource.contractor_id()].totalcount
						&& !deleteDisciplinesResourceArrays().some((r) =>
							r.contractor_id == resource.contractor_id()
						)
							? 'Busy on other scheduled works'
							: elements.list([
								m("button.btn.", {
									disabled: false
										,onclick: () => {
											resource.showWork(
												resource.showWork()
													? false
													: true
											)
										}
									}
									,resource.showWork()
										? "Hide Detail"
										: "Show Works"
								)
								,resource.showWork()
								&& deleteDisciplinesResourceArrays().length
									? elements.confirmDelete(
										() =>
											Promise.all([
												deleteProjectResource(true)
												,deleteProjectTeam(true)
											])
												.then(() => fetchWorkList('contractor_id'))
										, savingProp
										, R.F
										, deleteWorkProp
										, null
										, 'Remove Works'
										, 'Irrecoverable'
										, reinstateWork
										, R.T
									)
									: null
							])


			,!resource.showWork()
			|| !resourceWorkList()[resource.contractor_id()]
				? ''
				: resourceWorkList()[resource.contractor_id()].workList.length
				&& resourceWorkList()[resource.contractor_id()].totalcount
					? elements.table(data, ['Project', 'Disciplines/Tasks'],
						workMapping(
							resourceWorkList
							,deleteDisciplinesResourceArrays
							,resource
							,'contractor_id'
						)
					)
					: ''

		]

		,sortField: 'contractor_name'
	}

	let crewFilters = {
		marker: "Teams"
		,filters: m.prop([])
		,disabled: () => form().resource.crews.length == 0
		,changeNames: baseFilterFunction.changeNames
		,uniqueArrays: baseFilterFunction.uniqueArrays
		,template: baseFilterFunction.template.crews[0]
		,scoped: data.scoped
	}

	let crewratesFilters = {
		marker: "Capabilities"
		,filters: m.prop([])
		,disabled: () => crew_form().capability.crews_discipline_rates.length == 0
		,changeNames: baseFilterFunction.changeNames
		,uniqueArrays: baseFilterFunction.uniqueArrays
		,template: baseFilterFunction.template.crews[0].crews_discipline_rates[0]
		,scoped: data.scoped
	}

	let getFilterInputs =
		R.pipe(
		getFilters,
		R.prop('generatedElements')
	)


	function getFilters(datainput){
		return filterView( datainput.template, datainput.filters(), datainput.changeNames, datainput.uniqueArrays, datainput.disabled, datainput.marker, data.scoped )
	}


	getFilterInputs(crewratesFilters)
	getFilterInputs(crewFilters)

	const masterLIST = function(mcreate){
		if (mcreate){
			CONSTANTdisciplines(
				R.unnest(
					R.partition(
						(c) => c.created,
						crew_form().capability.crews_discipline_rates
					)
				)
			)
		}
		return ListProcessing(
				CONSTANTdisciplines()
				,crewratesFilters.filters()
				,CONSTANTdisciplines().filter((c) => c.created).length > 0
					? null
					: "discipline_order"
				,filteredRateOptions
			)
	}

	let cloningTools = function(){
		let newList = tools().map((t) => {
			let newtool = {
				tools_id: m.prop( t.tools_id() )
				,crew_id: m.prop( t.crew_id() )
				,teamresourcename: m.prop( t.teamresourcename )
				,organization_id: m.prop( t.organization_id())
				,tools_description: m.prop( t.tools_description() )
				,tools_name: m.prop(t.tools_name())
				,tools_condition: m.prop(t.tools_condition())
				,tools_location: m.prop(t.tools_location())
				,tools_ownership: m.prop(t.tools_ownership() )
				,tools_type: m.prop(t.tools_type())
				,assigneduser: t.assigneduser
			}
			return newtool
		})
		tools_vm( newList )
	}



	const setOptions = function(value, prop){
		value || value == false || value == '' || value == 0
			? checkMultiple().data
				.forEach((d) => {
					d[prop](value)
				})
			: null
		return checkMultiple().data[0][prop]()
	}

	const adjustPercenageValue = data.scoped(0)
	const adjustPercenage = (value, dataObject) => {
		R.map(
			(aitem) =>
				[
					'crews_discipline_rates_rate'
				]
				.forEach((iRate) => {
					aitem[iRate](
						aitem[iRate]() * ( 1 + Number(value/100) )
					)
				})
			,dataObject
		)
	}

	const createContractsCheckbox =
		(ctrl) =>
			elements.list([
				m('label.control-label.mb1', 'Create Financial Contracts')
				,elements.checkbox({
					onchange: m.withAttr('checked', (value) => setOptions(value, 'createcontracts'))
					,title: "Create contracts for this item to register pay for this team when work is completed"
					,checked: ctrl.model.checkMultiple().data.some((r) => r.createcontracts())
				})
			])

	const detailPaneInputs = function(D, ctrl){

		const rateItem = () => checkMultiple().data[0]
		return [
			elements.list(
				[
					ctrl.model.SaveCDisabled(true)
					|| ctrl.model.check_dnames(true)
					|| identical(ctrl.model.crew_vm().selected.capability, ctrl.model.crew_form().capability)
					&& identical(ctrl.model.tools(), ctrl.model.tools_vm())
						? m('button.btn', {
							disabled: true
						}, 'Save')
						: elements.action('Save', ctrl.intent.sendResource, ctrl.model.savingProp)
				]
				.concat(
					elements.undoDiscard({
						discard:
							{
								label: 'Discard'
								,attrs: {
									onclick: () => {
										ctrl.intent.deleteDisciplineRate()
										ctrl.model.checkMultiple().data = []
									}
									,disabled:
										!!ctrl.model.stillWorking(true)
										|| ctrl.model.checkMultiple().data.length == 0
								}
							}
						,doneUndo: {
							label:
								identical(
									ctrl.model.crew_vm().selected.capability
									,ctrl.model.crew_form().capability
								)
								? 'Done'
								: ''
							,attrs: {
								onclick: () => {
									errors({})
									crew_form().capability = cloningResourceCapability(false, true)
									checkMultiple().status = false
									checkMultiple().data = []
									discipline_vm({})
									setOptionsMark(false)
									deleteRates([])
									cloningTools()
									masterLIST(true)
									m.redraw()
								}
								,title: `Discard all changes made to these tools since last saving them`
								,style: {
									transition: '0.5s'
									,width: '5em'
								}
							}
						}
					})
				)
			)
			,check_dnames()
			,crew_form().capability.crews_discipline_rates
			&& organizations_disciplines()
			&& organizations_disciplines().length
			== crew_form().capability.crews_discipline_rates.length
			&& crew_form().capability.crews_discipline_rates.every((cdr) => cdr.organizations_disciplines_id())
				? `This team is capable of all organizational works`
				: ''

			,rateItem()
			&& rateItem().crews_discipline_rates_id
			&& checkMultiple().data.length == 1
				? [
					elements.list([
						organizations_disciplines().length
						== crew_form().capability.crews_discipline_rates.length
						&& crew_form().capability.crews_discipline_rates.every((cdr) => cdr.organizations_disciplines_id())
						|| rateItem().crews_discipline_rates_id()
							? elements.list([
								m('label.control-label.mb1', 'Discipline Name ')
								, rateItem().crews_discipline_rates_name()
							])
							: m('label.control-label.mb1'
								,'Discipline Name '
								,Pencil(
									() => rateItem().crews_discipline_rates_name()
									,() =>
										autocomplete.all(
											restrictedOrgDisc
											,(v) => {

												const ReturnedAutocompleteObj =
													organizations_disciplines()
													.find( R.propEq('orgDisciplineNameDescription', v) )
													|| organizations_disciplines().find(
														R.propEq(
															'organizations_disciplines_id'
															, rateItem().organizations_disciplines_id()
														)
													)


												v || v == ''
													? rateItem().crews_discipline_rates_name(
														ReturnedAutocompleteObj
															? ReturnedAutocompleteObj.organizations_disciplines_name
															: v
													)
													: null

												if (
													ReturnedAutocompleteObj
												){

													rateItem().crews_discipline_rates_uom(
														ReturnedAutocompleteObj.organizations_disciplines_uom
													)
													rateItem().organizations_disciplines_id(
														ReturnedAutocompleteObj.organizations_disciplines_id
													)
													rateItem().crews_discipline_rates_description(
														ReturnedAutocompleteObj.organizations_disciplines_description
													)
													rateItem().crews_discipline_rates_rate(
														ReturnedAutocompleteObj
														.organizations_disciplines_nominal_rate
														|| 1
													)

													rateItem().organizations_disciplines_nominal_expenditure_rate(
														ReturnedAutocompleteObj
														.organizations_disciplines_nominal_expenditure_rate
														|| 0
													)

												} else if( !ReturnedAutocompleteObj ){
													rateItem().crews_discipline_rates_uom(null)
													rateItem().organizations_disciplines_id(null)
													!rateItem().crews_discipline_rates_rate()
														? rateItem().crews_discipline_rates_rate(1)
														: null
												}

												return ReturnedAutocompleteObj
													? ReturnedAutocompleteObj.orgDisciplineNameDescription
													: rateItem().crews_discipline_rates_name()
											}
											,'orgDisciplineNameDescription'
											, () => ({
												key: rateItem().key
												,style: {
													height: '3em'
												}
												,disabled:
													organizations_disciplines()
													&& organizations_disciplines().length
													== crew_form().capability.crews_discipline_rates.length
													&& crew_form().capability.crews_discipline_rates.every((cdr) => cdr.organizations_disciplines_id())
											})
										)
								)
							)
					])

					,elements.list([
						m('label.control-label.mb1'
							, 'Description '
							,Pencil(
								() => rateItem().crews_discipline_rates_description()
								,() =>
									elements.textInput(
										rateItem().crews_discipline_rates_description
										,{
											title: "Description of the discipline"
										}
									)
							)
						)
					])

					,elements.list([
						m('label.control-label.mb1'
							, 'Unit of Measure'
							,Pencil(
								() => rateItem().crews_discipline_rates_uom()
								,() =>
									autocomplete.all(
										availableUOMS
										,(value) => setOptions(value, 'crews_discipline_rates_uom')
										,'organizations_disciplines_uom'
										, () => ({
											style: {
												height: '3em'
											}
										})
									)
							)
						)
					])
					,elements.list([
						m('label.control-label.mb1'
							,'Work Rate Per Day'
							,m(
								NumberInput
								,R.merge(
									data
									,{
										errors
										,errorLabel: 'Work Rate Per Day'
									}
								)
								,{
									prop: rateItem().crews_discipline_rates_rate
									,attrs: {
										min: 0
										,step: 0.01
									}
								}
							)
						)
					])

					,data.editContractPermissions()
					&& checkMultiple().data.every(R.prop('newcdr'))
					? elements.list([
						m('label.control-label.mb1', 'Pay Amount Per UOM')
						,Pencil(
							() => makeMoney(rateItem().organizations_disciplines_nominal_expenditure_rate())
							,() =>
								m(
									NumberInput
									,R.merge(
										data
										,{
											errors
											,errorLabel: 'Pay Amount Per UOM'
										}
									)
									,{
										prop: rateItem().organizations_disciplines_nominal_expenditure_rate
										,attrs: {
											max: 0
											,step: 0.01
											,title: `Average amount that would be paid when a team or resource conducts this discipline. May be altered for specific teams via financials and the contract selection`
										}
									}
								)
						)
					])
					: data.readContractPermissions()
					? elements.list([
						m('label.control-label.mb1', 'Pay Amount Per UOM')
						,makeMoney(rateItem().organizations_disciplines_nominal_expenditure_rate())
					])
					: null

					,errors()['Pay Amount Per UOM']
					? elements.alert(
						`warning`
						, `Pay Amounts must be negative as this is money that would be expenditures of the organization`
					)
					: null

					,createContractsCheckbox(ctrl)

					,elements.list([
						m('button.btn.btn-secondary', {
							style: {
								backgroundColor: 'transparent'
								,border: 'solid 1px #3380c2'
								,position: 'relative'
								,top: '-0.1em'
								,height: '2.9em'
								,width: '5em'
								,color: '#434aa3'
								,textDecoration: 'underline'
							}
							,onclick: () => {
								adjustPercenage(adjustPercenageValue(), checkMultiple().data)
							}
						}, 'Adjust')
						,m('label.control-label.mb1' ,' Rates by ')
						,Pencil(
							() => adjustPercenageValue()
							,() =>
								m(
									NumberInput
									,R.merge(
										data
										,{
											errors
											,errorLabel: 'Rate Adjustment'
										}
									)
									,{
										prop: adjustPercenageValue
										,attrs: {
											step: 0.01
										}
									}
								)
						)
						,m('label.control-label.mb1' ,'Percent')
					])
					,elements.list([
						m('label.control-label.mb1'
							,'Performance'
						)
						,rateItem().crews_discipline_rates_feedback_rate() != null
							? rateItem().crews_discipline_rates_feedback_rate()
							: 'I have not calculated a Performance value yet'
					])

				]
				: [
					checkMultiple().data.length > 1
						? [

							elements.list([
								m('label.control-label.mb1', 'Discipline Name')
								,ctrl.model.checkMultiple().data
								.map((s) =>
									m(
										'p.pv1.ma0'
										,s.crews_discipline_rates_name()
										+ ' - '
										+s.crews_discipline_rates_description()
									)
								)
							])
							,elements.list([
								m('label.control-label.mb1'
									, 'Unit of Measure'
									, autocomplete.all(
										availableUOMS
										,(value) => setOptions(value, 'crews_discipline_rates_uom')
										,'organizations_disciplines_uom'
										, () => ({
											style: { height: '3em' }
										})
									)
								)
							])
							,elements.list([
								m('label.control-label.mb1'
									,'Work Rate Per Day'
									,m(
										NumberInput
										,R.merge(
											data
											,{
												errors
												,errorLabel: 'Work Rate Per Day'
											}
										)
										,{
											prop: (value) => setOptions(value, 'crews_discipline_rates_rate')
											,attrs: {
												min: 0
												,step: 0.01
											}
										}
									)
								)
							])
							,createContractsCheckbox(ctrl)
							,elements.list([
								m('button.btn.btn-secondary', {
									style: {
										backgroundColor: 'transparent'
										,border: 'solid 1px #3380c2'
										,position: 'relative'
										,top: '-0.1em'
										,height: '2.9em'
										,width: '5em'
										,color: '#434aa3'
										,textDecoration: 'underline'
									}
									,onclick: () => {
										adjustPercenage(adjustPercenageValue(), checkMultiple().data)
									}
								}, 'Adjust')
								,m('label.control-label.mb1' ,' Rates by ')
								,Pencil(
									() => adjustPercenageValue()
									,() =>
										m(
											NumberInput
											,R.merge(
												data
												,{
													errors
													,errorLabel: 'Rate Adjustment'
												}
											)
											,{
												prop: adjustPercenageValue
												,attrs: {
													step: 0.01
												}
											}
										)
								)
								,m('label.control-label.mb1' ,'Percent')
							])
						]
						: []
				]

			,crew_form().capability.crews_discipline_rates
			&& organizations_disciplines()
			&& organizations_disciplines().length
			&& organizations_disciplines().length
			!= crew_form().capability.crews_discipline_rates.length
				? elements.list([
					m('button.btn.btn-secondary',
						{
							style: {
								backgroundColor: 'transparent'
								,border: 'solid 1px #3380c2'
								,position: 'relative'
								,top: '-0.1em'
								,height: '2.9em'
								,width: '5em'
								,color: '#434aa3'
								,textDecoration: 'underline'
							}
							,title: 'Apply a set of the organizations default capabilities'
							,onclick: () => {

								crew_form().capability.crews_discipline_rates =
									organizations_disciplines()
									.filter((ods) =>
										!crew_form().capability.crews_discipline_rates
										.find((cr) =>
											cr.organizations_disciplines_id()
											== ods.organizations_disciplines_id
										)
									)
									.map((o) => {
										const newD = cloningResourceCapabilityRate()

										newD.crews_discipline_rates_uom(
											o.organizations_disciplines_uom
										)
										newD.organizations_disciplines_id(
											o.organizations_disciplines_id
										)
										newD.crews_discipline_rates_rate(
											o.organizations_disciplines_nominal_rate
											|| 1
										)
										newD.crews_discipline_rates_name(
											o.organizations_disciplines_name
											// o.orgDisciplineNameDescription
										)
										newD.crews_discipline_rates_description(
											o.organizations_disciplines_description
										)

										return newD
									})
									.concat(
										crew_form().capability.crews_discipline_rates
										.filter((cr) => cr.organizations_disciplines_id())
									)


								checkMultiple().status = true
								discipline_vm(crew_form().capability.crews_discipline_rates[0])
								masterLIST(true)

								crew_form().capability.crews_discipline_rates
								.forEach((a) => checkMultiple().data.push(a))
							}
						}
						,'Apply'
					)
					,m(`label`, ' All Organization Capabilities')
				])
				: null
		]
	}
	let detailsPaneOpen = () => discipline_vm().crews_discipline_rates_id || checkMultiple().data.length > 0

	function reinstateWork(){
		deleteDisciplinesResourceArrays()
			.map((w) =>
				resourceWorkList()[w.contractor_id]
				.workList.find((p) => p.project == w.project_name)
				.disciplines
				.push(w)
			)
		deleteDisciplinesResourceArrays([])

		deleteDisciplinesTeamsArrays()
			.map((w) =>
				teamWorkList()[w.crew_id]
				.workList.find((p) => p.project == w.project_name)
				.disciplines
				.push(w)
			)
		deleteDisciplinesTeamsArrays([])
		return true
	}


    function fetchWorkList(indexing){
		loadingMeta(true)

		const modelProp =
			indexing == 'contractor_id'
			? resourceWorkList
			: teamWorkList


		const mapList =
			indexing == 'contractor_id'
			&& form().resource.contractor_name
			? resources().filter((r) =>
				r.contractor_name()
				== form().resource.contractor_name()
			)
			: indexing == 'contractor_id'
			? resources()
			: vm().selected.resource.crews

		const resUnnest =
			indexing == 'contractor_id'
				? R.pipe(
					R.map(R.prop('workingarray'))
					, R.unnest
					, R.uniqBy(R.prop('disciplines_contractors_id'))
				)
				: R.prop('workingarray')

		const resReducer = indexing == 'contractor_id'
				? R.pipe(R.map(R.prop('totalcount')), R.sum)
				: (a) => Number(R.prop('totalcount')(a))

		const resID =
			indexing == 'contractor_id'
				? R.pipe(R.last, R.prop('contractor_id') )
				: R.prop('contractor_id')

		const teamID =
			indexing == 'contractor_id'
				? R.pipe(R.last, R.prop('crew_id') )
				: R.prop('crew_id')

		const unWrapper = indexing == 'contractor_id'
				? R.filter(R.prop('length'))
				: R.unnest

        return Promise.all(
			mapList
			.map((a) =>
				data.api.contractors.fetch.workList({
					contractor_id: a.contractor_id
					,crew_id: a.crew_id
					,schedule_id: data.schedule_id()
					,schedule_version_id: data.schedule_version_id()
				})
			)
		)
			.then(
				R.pipe(
					unWrapper
					,R.map((a) =>
						({
							crew_id: teamID(a)
							,contractor_id: resID(a)
							,totalcount: resReducer(a)
							,workList: R.pipe(
								R.groupBy(R.prop('project_id'))
								,R.map((p) => ({
									project: p[0].project_name
									,disciplines: p
								}))
								,R.toPairs
								,R.map(R.last)
								,R.unnest
							)(resUnnest(a))
						})
					)
					,R.indexBy( R.prop(indexing) )
				)
			)
			.then((newIndex) =>
				modelProp(
					R.merge(
						modelProp()
						,newIndex
					)
				)
			)
			.then( () =>
				resources().length
				&& loadingMeta(false)
			)
			.then( m.redraw )
    }


	function workMapping(wObject, deleteBay, rObject, prop){
		const id = rObject[prop]()
		const wList = wObject()[id].workList
		return wList.map((p) =>
			[
				elements.inlinestrikeList(
					[{
						label: p.project
						,action: () => {
							deleteBay(deleteBay().concat(p.disciplines))
							p.disciplines = []
						}
					}]
				)
				,elements.inlinestrikeList(
					p.disciplines.map((label, k, dArray) =>
						({
							label: label.discipline_name + ' - '  + label.discipline_description
							,action: () => {
								deleteBay(deleteBay().concat(label))
								dArray.splice(
									p.disciplines.findIndex((di) =>
										di.discipline_name
										== label.discipline_name
									)
									,1
								)
							}
						})
					)
				)
			]
		)
	}

	function createNewWorkRate(){
		let newD = cloningResourceCapabilityRate()
		checkMultiple().status = false
		checkMultiple().data = []
		discipline_vm({})
		crew_form().capability.crews_discipline_rates.unshift(newD)
		checkMultiple().data.unshift(newD)
		discipline_vm(newD)
		masterLIST(true)
		m.redraw()
	}

	function getActivateDeleteButton(o){

		if (o.crews_discipline_rates_id){
			let activated =
				checkMultiple().data
				.findIndex((chr) =>
					chr.crews_discipline_rates_name() == o.crews_discipline_rates_name()
					&& chr.crews_discipline_rates_id() == o.crews_discipline_rates_id()
					&& chr.key == o.key
				)

			let vmEntry = discipline_vm().crews_discipline_rates_name
			let vmDetail = vmEntry != null ? o.key == discipline_vm().key : false

			return {
				disabled: false
				,type: 'checkbox'
				,onclick:
					(v) => {
						if( !v.currentTarget.checked ){
							checkMultiple().status = false
							checkMultiple().data.splice(activated, 1)
							if (vmDetail == true && checkMultiple().data.length > 0 ){
								discipline_vm(checkMultiple().data[0])
							} else if (vmDetail == true && checkMultiple().data.length <= 0){
								discipline_vm({})
							}
						}
						else {
							discipline_vm(o)
							checkMultiple().data.push(o)
						}
					}
				,checked: activated >= 0 || vmDetail
			}
		}
		else {
			return {
				onchange: function(event){
					if(event.currentTarget.checked == true){
						o.crew_id(crew_form().capability.crew_id())
						o.teamresourcename(
							form().resource.contractor_name() + " - " + crew_form().capability.crew_name()
						)
						o.assigneduser = data.auth.stream().user_id
					}
					else if (event.currentTarget.checked == false){
						o.teamresourcename('Unassigned')
						o.crew_id(null)
						o.assigneduser = null
					}
				}
				,type: 'checkbox'
				,checked: o.crew_id() == crew_form().capability.crew_id()
				,disabled:
					!editToolPermissions()
					&& !editAssignedToolPermissions()
					|| !editToolPermissions()
						&& editAssignedToolPermissions()
						&& !!o.crew_id()
						&& (
							o.crew_id() != crew_form().capability.crew_id()
							|| o.crew_id() == crew_form().capability.crew_id()
							&& crew_form().capability.user_id()
							!= data.auth.stream().user_id
						)
				,title: "Take possession of a tool. I can only do this if it's released by the current team who occupies it"
			}
		}
	}


	const ratedata =
		(r) => ({
			'Discipline': r.crews_discipline_rates_name() + ' - ' + r.crews_discipline_rates_description()
			,'Unit of Measure': r.crews_discipline_rates_uom() || 'N/A'
			,'Work Rate Per Day': r.crews_discipline_rates_rate()
			,'Performance': r.crews_discipline_rates_feedback_rate()

			,'Expenditure': !data.calculated()
				? ' ... '
				: makeMoney(
					data.coalatedSelectedVersion()[r.contractrecognitionid]
					? data.coalatedSelectedVersion()[r.contractrecognitionid].currentProfits
						+ data.coalatedSelectedVersion()[r.contractrecognitionid].outstandingProfits

					: 0
				)

			,'Forecasted Expenditure': !data.calculated()
				? ' ... '
				: makeMoney(
					(
						data.coalatedSelectedVersion()
							[r.contractrecognitionid]
						|| {}
					)
					.forecastedProfits
				)
		})

	const largeRatesTable = () =>
		scrollableTable.advanced({
			scoped: data.scoped
			,resize$: data.resize$
			,alwaysopen: true
			,rows:
				masterLIST()
				.map((r, rindex) => ({
					key: r.key
					,selection: getActivateDeleteButton(r)
					,header:
						rindex != 0
						? ''
						: m('.header'
							+ css`
								font-weight: bold;
								display: flex;
								justify-content: space-between;
								align-items: center;
								padding-bottom: 0.5em;
							`
							,m('span'
								+ css`
									font-weight: bold;
								`
								,''
							)
							,m('.data'
								+ css`
									display: grid;
									grid-template-rows: 0.25fr 0.5fr;
									overflow: hidden;
								`
								,editResourcePermissions()
								? m(`button.pl2`
									+ css`
										backgroundColor: transparent;
										transition: 0.2s;
										opacity: 1;
										color: #434aa3;
										border: none;
									`
									.$hover(`
										opacity: 0.8;
									`)
									.$active(`
										opacity: 0.5;
									`)
									, {
										disabled: !!check_dnames(true) || metaDataLoading()
										,onclick: createNewWorkRate
										,title: "Create a type of work this team is capable of conducting"
									}
									, 'Create a New Team Discipline'
								)
								: null
							)
							, rindex == 0 && false
							? discardChangesButton()
							: m('span'
								+ css`
									font-weight: bold;
								`
								,''
							)
						)
					,layouts: {
						expanded: ({ x, headers, i }) =>
							m('.expanded'
								+ css`
									display: grid;
									justify-content: space-between;
									grid-template-columns: 1fr;
								`
								, m('.data'
									+ css`
										display: grid;
										justify-content: space-between;
										grid-template-columns: 1.5fr 0.75fr 0.75fr 0.75fr 1fr 1fr;
										overflow: hidden;
										gap: 1em;
										align-items: start;
										align-content: start;
									`
									, headers
										.map(
											k => [
												i == 0
												? m('.data'
													+ css`
														display: grid;
														grid-template-rows: 0.25fr 0.5fr;
														overflow: hidden;
													`
													,[m('label.control-label', k), x[k]]
												)
												: m('.data'
													+ css`
														display: grid;
														overflow: hidden;
													`
													,x[k]
												)
											]
										)
								)
							)
					}
					,data: ratedata(r)
				}))
		})

	const smallRatesTable = () =>
		scrollableTable.advanced({
			scoped: data.scoped
			,resize$: data.resize$
			,alwaysopen: true
			,rows:
				masterLIST()
				.map((r, rindex) => ({
					key: r.key
					,header:
						m('span'
							+ css`
								font-weight: bold;
								display: flex;
								justify-content: space-between;
								align-items: center;
								padding-bottom: 0.5em;
							`
							,m('input'
								+ css`
									width: 25px;
									height: 25px;
									margin: 0px;
								`
								.$media('(hover: hover)',
									css`
										width: 20px;
										height: 20px;
									`
								)
								,getActivateDeleteButton(r)
							)
							,editResourcePermissions()
								? m(`button.pl2`
									+ css`
										backgroundColor: transparent;
										transition: 0.2s;
										opacity: 1;
										color: #434aa3;
										border: none;
									`
									.$hover(`
										opacity: 0.8;
									`)
									.$active(`
										opacity: 0.5;
									`)
									, {
										disabled: !!check_dnames(true) || metaDataLoading()
										,onclick: () => {
											let newD = cloningResourceCapabilityRate()
											checkMultiple().status = false
											checkMultiple().data = []
											discipline_vm(newD)
											crew_form().capability.crews_discipline_rates.unshift(newD)
											masterLIST(true)
											checkMultiple().data.unshift(newD)
											m.redraw()
										}
										,title: "Create a type of work this team is capable of conducting"
									}
									, 'Create a new Team Discipline'
								)
								: null
							, rindex == 0 && false
							? discardChangesButton()
							: m('span'
								+ css`
									font-weight: bold;
								`
								,''
							)
						)
					,layouts: {
						expanded: ({ x }) =>
							m('.expanded'
								+ css`
									display: grid;
									justify-content: space-between;
									grid-template-columns: 1fr;
								`
								,m('.data'
									+ css`
										display: grid;
										justify-content: space-between;
										grid-template-columns: 0.75fr 0.25fr;
										padding-bottom: 2em;
									`
									,['Discipline', 'Unit of Measure']
									.map((k) =>
										m('.data'
											+ css`
												display: grid;
												grid-template-rows: 0.25fr 0.25fr;
												padding-right: 0.25em
											`
											,[
												m('label.control-label.f4', k)
												, x[k]

											]
										)
									)
								)


								, m('.data'
									+ css`
										display: grid;
										justify-content: space-between;
										grid-template-columns: 0.25fr 0.25fr 0.25fr 0.25fr;
										padding-bottom: 1em;
									`
									,['Work Rate Per Day', 'Performance', 'Expenditure', 'Forecasted Expenditure']
									.map((k) =>
										m('.data'
											+ css`
												display: grid;
												grid-template-rows: 0.25fr 0.25fr;
												padding-right: 0.5em;
											`
											,[m('label.control-label.f4', k), x[k]]
										)
									)
								)
							)

					}
					,data: ratedata(r)
				}))
		})


	const ratesTable = () =>
		m(Responsive
			,
			{
				breakpoints: {
					mobile:0
					,desktop:900
					,tablet:700
					,hd:1800
				}
			}
			,({ hd, desktopOnly, mobileOnly, tabletOnly }) => [
				hd( largeRatesTable )
				,desktopOnly( largeRatesTable  )
				,mobileOnly( smallRatesTable )
				,tabletOnly( smallRatesTable )
			]
		)

		function editAllButton(){

			const activated =
				checkMultiple().status
				&& checkMultiple().data.length > 0

			return elements.checkbox({
				onchange: () => {
					if( activated ){
						setOptionsMark(false)
						checkMultiple().status = false
						checkMultiple().data = []
						discipline_vm({})
					}
					else {
						checkMultiple().status = true
						checkMultiple().data =
							filteredRateOptions().length
								? filteredRateOptions()
								: crew_form().capability.crews_discipline_rates
					}
				}
				,title: "Select or De-select viewed rates"
				,disabled: crew_form().capability.crews_discipline_rates.length <= 1
			})
		}

		const discardChangesButton =
			() =>
				savingProp()
				|| identical(crew_vm().selected.capability, crew_form().capability)
				&& identical(tools_vm(), tools())
				? m('span'
					+ css`
						font-weight: bold;
					`
					,''
				)
				: m("button.btn.btn-warning", {
					disabled: false
					,onclick: () => {
						errors({})
						crew_form().capability = cloningResourceCapability(false, true)
						discipline_vm({})
						setOptionsMark(false)
						checkMultiple().data = []
						deleteRates([])
						cloningTools()
						masterLIST(true)
						m.redraw()
					}
					,title: "Discard all changes since last saving this team"
				},"Discard Changes")



		const tooldata =
			(tool) => ({
				'Tool Name': tool.tools_name()
				,'Description': tool.tools_description()
				,'Ownership': tool.tools_ownership()
				,'Condition': tool.tools_condition()
				,'Location': tool.tools_location()
				,'':
					elements.list([
						!tool.crew_id()
							? 'Assign this Team'
							: tool.teamresourcename()
						,!tool.crew_id()
						|| tool.crew_id() == crew_form().capability.crew_id()
						|| tool.crew_id() != crew_form().capability.crew_id()
						&& tool.assigneduser == data.auth.stream().user_id
							? elements.checkbox(getActivateDeleteButton(tool))
							: ''
					])
			})

		const largeToolsTable = () =>
			scrollableTable.advanced({
				scoped: data.scoped
				,resize$: data.resize$
				,alwaysopen: true
				,rows:
					tools_vm()
					.map((tool, tindex) => ({
						key: tool.key
						,header:
							tindex != 0
							? ''
							: m('.header'
								+ css`
									font-weight: bold;
									display: flex;
									justify-content: space-between;
									align-items: center;
									padding-bottom: 0.5em;
								`
								,m('span'
									+ css`
										font-weight: bold;
									`
									,''
								)
							)
						,layouts: {
							expanded: ({ x, headers, i }) =>
								m('.expanded'
									+ css`
										display: grid;
										justify-content: space-between;
										grid-template-columns: 1fr;
									`
									, m('.data'
										+ css`
											display: grid;
											justify-content: space-between;
											grid-template-columns: 0.75fr 1.15fr 0.75fr 0.65fr 0.65fr 0.65fr;
											overflow: hidden;
											gap: 1em;
											align-items: start;
											align-content: start;
										`
										, headers
											.map(
												k => [
													i == 0
													? m('.data'
														+ css`
															display: grid;
															grid-template-rows: 0.25fr 0.5fr;
															overflow: hidden;
														`
														,[m('label.control-label', k), x[k]]
													)
													: m('.data'
														+ css`
															display: grid;
															overflow: hidden;
														`
														,x[k]
													)
												]
											)

									)
								)
						}
						,data: tooldata(tool)
					}))
			})

		const smallToolsTable = () =>
			scrollableTable.advanced({
				scoped: data.scoped
				,resize$: data.resize$
				,alwaysopen: true
				,rows:
					tools_vm()
					.map((tool) => ({
						key: tool.key
						,header:
							m('span'
								+ css`
									font-weight: bold;
									display: flex;
									justify-content: space-between;
									align-items: center;
									padding-bottom: 0.5em;
								`
							)
						,layouts: {
							expanded: ({ x }) =>
								m('.expanded'
									+ css`
										display: grid;
										justify-content: space-between;
										grid-template-columns: 1fr;
									`
									,m('.data'
										+ css`
											display: grid;
											justify-content: space-between;
											grid-template-columns: 0.25fr 0.75fr;
											padding-bottom: 2em;
										`
										,['Tool Name', 'Description']
										.map((k) =>
											m('.data'
												+ css`
													display: grid;
													grid-template-rows: 0.25fr 0.25fr;
													padding-right: 0.25em
												`
												,[
													m('label.control-label.f4', k)
													, x[k]
												]
											)
										)
									)

									, m('.data'
										+ css`
											display: grid;
											justify-content: space-between;
											grid-template-columns: 0.25fr 0.25fr 0.25fr 0.25fr;
											padding-bottom: 1em;
										`
										,['Ownership', 'Condition', 'Location', '']
										.map((k) =>
											m('.data'
												+ css`
													display: grid;
													grid-template-rows: 0.25fr 0.25fr;
													padding-right: 0.5em;
												`
												,[m('label.control-label.f4', k), x[k]]
											)
										)
									)
								)
						}
						,data: tooldata(tool)
					}))
			})


		const toolsTable = () =>
			m(Responsive
				,
				{
					breakpoints: {
						mobile:0
						,desktop:900
						,tablet:700
						,hd:1800
					}
				}
				,({ hd, desktopOnly, mobileOnly, tabletOnly }) => [
					hd( largeToolsTable )
					,desktopOnly( largeToolsTable  )
					,mobileOnly( smallToolsTable )
					,tabletOnly( smallToolsTable )
				]
			)

		function uploadmetadata({only}){
			return R.pickBy(
				(a, k) => a && (!only || !Object.keys(only).length || (only && only[k]) || k == 'user_id' || k == 'organization_id')
				,{
					organization_id: data.initiateContractFetch().organization_id
					, contractor_id:
						vm().selected.resource
						&& vm().selected.resource.contractor_id()
					, user_id:
						data.auth.stream().user_id
				}
			)
		}


	return {
		resources, geographies, data, form, crew_form, resetForm, resetDRateForm, vm, crew_vm, discipline_vm, resetCrewForm, tools, availableDisciplineSelections
		,deleteCapability, deleteResource, deleteDisciplineR, cloningResourceCapability, cloningResource, check_dnames, check_cnames, check_crew_names
		,availableUOMS, DeleteDisabled, DeleteDisabledCapability, crew_edit, contractor_edit, SaveRDisabled, SaveCDisabled, process_geo, viewtype, crewratesFilters
		,request: data.request, suburbs, stillWorking, permissions, crewFilters, resourceFilters, getFilterInputs, users
		, loading
		, masterLIST
		, placeLong
		, suburbsInput
		, tools_vm
		, cloningTools
		, organizations_disciplines
		, deleteTeams
		, deleteRates
		, cloningResourceCapabilityRate
		, CONSTANTdisciplines
		, deleteProjectResource
		, deleteProjectTeam
		, deleteDisciplinesTeamsArrays
		, deleteDisciplinesResourceArrays
		, disciplinesResources
		, detailPaneInputs
		, detailsPaneOpen
		, setOptionsMark
		, savingProp
		, checkMultiple
		, deleteStateProp
		, deleteWorkProp
		, api: data.api
		, restrictedOrgDisc
		, errors
		, missingPermissions
		, editResourcePermissions
		, editAssignedToolPermissions
		, teamWorkList
		, resourceWorkList
		, loadingMeta
		, workMapping
		, fetchWorkList
		, reinstateWork
		, editToolPermissions
		, filteredRateOptions
		, metaDataLoading
		, getUser
		, ratesTable
		, editAllButton
		, discardChangesButton
		, toolsTable
		, createNewWorkRate
		, uploadmetadata
	}
}

/*
	The controller is the glue that sticks the model and intent together.
	It exposes the model and intent to the view by returning them.

	The controller "controls" how each layer interacts.
*/

function Main({ attrs: { data } }){

	let model = Model(data)
	let intent = Intent(model)
	let activeTeamTab = m.prop('Team Disciplines')
	const ctrl =
		{model, intent, activeTeamTab}

	function view(){

		return m('div'
			,ctrl.model.viewtype(ctrl)
			,DetailsPane(
				ctrl.model.detailsPaneOpen
				,ctrl.model.checkMultiple().data.length > 0
					? 'Editing Multiple Disciplines'
					: ctrl.model.discipline_vm().crews_discipline_rates_name
						? ctrl.model.discipline_vm().crews_discipline_rates_name()
						: ''
				,() => ctrl.model.detailPaneInputs(
					ctrl.model.discipline_vm
					,ctrl
				)
			)
		)
	}

	return {
		view
	}
}


function ListResource(ctrl){
	return m('div.harth.mb6'
		+ css`
			display: grid;
			gap: 1em;
		`
		, [

		m(''
			+ css`
				display: flex;
				flex-flow: wrap;
				column-gap: 2em;
				row-gap: 1em;
				padding-bottom: 1em;
				justify-content: space-between;
				padding-bottom: 2em;
			`
			,[

				m(''
					+ css`
						display: grid;
						grid-template-columns: 1fr;
						grid-template-rows: 0.6fr 0.4fr;
						gap: 0.5em;
						max-width: 40em;
					`
					.$nest('p', `margin: 0em;`)
					,[
						elements.list([
							m('h4'+css`margin: 0em;`, `Resource Pool`)
							,ctrl.model.editResourcePermissions()
							? m(`button`
								+ css`
									border-radius: 0.5em;
									background-color: white;
									padding: 0.7em 1em;
									border: solid 0.1px rgba(0,0,0,0.4);
									transition: 0.2s;
									opacity: 1;
								`
								.$hover(`
									opacity: 0.8;
								`)
								.$active(`
									opacity: 0.5;
								`)
								,{
									disabled: !!ctrl.model.check_cnames()
									,onclick: () => {
										ctrl.model.vm().selected.resource =
											ctrl.model.resetForm().resource
										ctrl.model.form().resource =
											ctrl.model.cloningResource(false, true)
										ctrl.model.viewtype = EditResource
										ctrl.model.contractor_edit(false)
									}
									,title: "Create a resource and its individual work teams"
								}
								, '+ Resource '
							)
							: null
						])
						,m('p', `Create both Internal and External resources to work on your projects.`)
						,m('p', `Internal Resources include full and part time employees, each employee is designated as a team inside the resource.`)
						,m('p',`External resources (business and contractors) would encompass atleast 1 team to quantify updates, time spent and contract rates for each type of work completed.`)

					]
				)
			]
		)

		,ctrl.model.resources().length == 0
			? null
			: !ctrl.model.data.readProjectPermissions()
				? 'Project permissions are required to see scheduled works'
				: ctrl.model.data.schedules().length == 0 && ctrl.model.data.readSchedulePermissions()
					? elements.alert(
						'info'
						, `I have no records of any projects,
						some can be created to assign these resources to work`
					)
					: null
					// elements.alert(
					// 	'info'
					// 	,`Scheduled work for these resources `
					// 	+ (
					// 		!ctrl.model.data.schedule_id()
					// 			? ` pertains to all existing schedules`
					// 			: ` only pertains to this schedule and the latest version of this schedule`
					// 	)
					// )


		,m(FilterInputs, ctrl.model.resourceFilters)
		// ,m.component(FilterTable, ctrl.model.resourceFilters)

		,ctrl.model.loading() && ctrl.model.resources().length
		? elements.centeredSpinner()
		: elements.table(ctrl.model.data,
			[
				'Resource'
				, 'Rating'
				, 'Assigned User Responsibility'
				, 'Expenditure'
				, 'Forecasted Expenditure'
				, ''
				, 'Teams'
				, ''
				, 'Current Scheduled Work'
			]
			,ListProcessing(
				ctrl.model.loading()
					? []
					: ctrl.model.resources()
				, ctrl.model.resourceFilters.filters()
				, 'contractor_name'
			).map((resource) => [
				m(`a`
					,{
						onclick:
							() => {
								ctrl.model.vm().selected.resource = resource
								ctrl.model.fetchWorkList('crew_id')
								ctrl.model.form().resource = ctrl.model.cloningResource(false, true)
								ctrl.model.viewtype = EditResource
								ctrl.model.contractor_edit(true)
								ctrl.model.crew_vm().selected.capability = {}
								ctrl.model.data.routeids({
									routeids: ctrl.model.vm().selected.resource.crews.map(R.prop('contractrecognitionid'))
									,getledgers: false
									,ledgerdetails: false
									,getmatdata: false
									,specificproject: null
								})
							}
					}
					,resource.contractor_name()
				)
				,Number(resource.contractor_quality_rating()).toFixed(2)

				,ctrl.model.getUser(resource)

				,!ctrl.model.data.calculated()
					? ' ... '
					: R.find(
						(a) => a.contractor_id
						== resource.contractor_id()
						,ctrl.model.deleteDisciplinesResourceArrays()
					)
						? 'Save to re-calculate'
						: makeMoney(
							ctrl.model.data.coalatedSelectedVersion()[resource.contractrecognitionid]
							? 								ctrl.model.data.coalatedSelectedVersion()[resource.contractrecognitionid].currentProfits
								+ ctrl.model.data.coalatedSelectedVersion()[resource.contractrecognitionid].outstandingProfits

							: 0
						)

				,!ctrl.model.data.calculated()
					? ' ... '
					: R.find(
						(a) => a.contractor_id
						== resource.contractor_id()
						,ctrl.model.deleteDisciplinesResourceArrays()
					)
						? 'Save to re-calculate'
						: makeMoney(
							(
								ctrl.model.data.coalatedSelectedVersion()
									[resource.contractrecognitionid]
								|| {}
							).forecastedProfits
						)

				,m(`a`
					,{
						onclick:
							() => {
								ctrl.model.vm().selected.resource = resource
								ctrl.model.form().resource = ctrl.model.cloningResource(true, false)
								ctrl.model.viewtype = EditResource
								ctrl.model.contractor_edit(false)
							}
						,disabled:
							ctrl.model.savingProp()
							|| !!ctrl.model.check_cnames()
							|| ctrl.model.metaDataLoading()
						,title: "Copy all the details of another team to create a new team"
					}
					,'Clone'
				)

				,resource.crews.length

				,m(''
					+css`
						display: grid;
						grid-template-columns: 1fr;
						gap: 0.5em;
						align-items: start;
						width: ` + (!ctrl.model.stillWorking(true, null, resource) ? '5em' : '20em')
					,[

						ctrl.model.editResourcePermissions()
						&& !ctrl.model.stillWorking(true, null, resource)
						? elements.confirmDelete(
							() => ctrl.intent.deleteResource(resource)
							, ctrl.model.savingProp
							, () => ctrl.model.DeleteDisabled(resource)
							, (a) =>
								a
								? ctrl.model.deleteStateProp()[resource.contractor_id()] = a
								: a == false
								? ctrl.model.deleteStateProp({})
								: ctrl.model.deleteStateProp()[resource.contractor_id()]
						)
						: ctrl.model.stillWorking(true, null, resource)

					]
				)

				,!ctrl.model.data.readProjectPermissions()
					? elements.alert(`info`, "Requires Project Permissions are required to see scheduled works")
					: ctrl.model.loadingMeta()
						? ' ... '
						: !ctrl.model.resourceWorkList()[resource.contractor_id()]
							? "This Resource has no scheduled work"
							: !ctrl.model.resourceWorkList()[resource.contractor_id()].workList.length
							&& ctrl.model.resourceWorkList()[resource.contractor_id()].totalcount
							&& !ctrl.model.deleteDisciplinesResourceArrays().some((r) =>
								r.contractor_id == resource.contractor_id()
							)
								? 'Busy on other scheduled works'
								: elements.list([
									m("button.btn.", {
										disabled: false
											,onclick: () => {
												resource.showWork(
													resource.showWork()
														? false
														: true
												)
											}
										}
										,resource.showWork()
											? "Hide Detail"
											: "Show Works"
									)
									,resource.showWork()
									&& ctrl.model.deleteDisciplinesResourceArrays().length
										? elements.confirmDelete(
											() => {
												return Promise.all([
													ctrl.model.deleteProjectResource(true)
													,ctrl.model.deleteProjectTeam(true)
												])
													.then(function(){
														ctrl.model.data.currentRoute('Nothing')
														ctrl.model.data.currentRoute('contractors')
														ctrl.model.fetchWorkList('contractor_id')
														return true
													})
											}
											, ctrl.model.savingProp
											, R.F
											, (state) => {
												state != null
													? ctrl.model.deleteWorkProp(state)
													: null
												return ctrl.model.deleteWorkProp()
												&& ctrl.model.deleteDisciplinesResourceArrays()
												.some((a) =>
													a.contractor_id
													== resource.contractor_id()
												)
											}
											, null
											, 'Remove Works'
											, 'Irrecoverable'
											, ctrl.model.reinstateWork
											, R.T
										)
										: null
								])


				,!resource.showWork()
				|| !ctrl.model.resourceWorkList()[resource.contractor_id()]
					? ''
					: ctrl.model.resourceWorkList()[resource.contractor_id()].workList.length
					&& ctrl.model.resourceWorkList()[resource.contractor_id()].totalcount
						? elements.table(ctrl.model.data, ['Project', 'Disciplines/Tasks'],
							ctrl.model.workMapping(
								ctrl.model.resourceWorkList
								,ctrl.model.deleteDisciplinesResourceArrays
								,resource
								,'contractor_id'
							)
						)
						: ''

			])
		)


		,m('br')
		,ctrl.model.resources().length
			? null
			: ctrl.model.loading()
				? elements.centeredSpinner()
				: elements.alert('info',`I don't have resources or teams that can be assigned to project work`)
	])
}

function ResourceHeader(ctrl){
	return [
		m(''
			+ css`
				display: grid;
				grid-template-columns: auto minmax(0em, 10em) minmax(0em, 10em);
				align-items: center;
				justify-content: start;
				gap: 1em;
			`
			,[

				m('h4'
					,ctrl.model.contractor_edit() == true
						? ctrl.model.form().resource.contractor_name()
						: 'Create a new resource '
				)


				,ctrl.model.contractor_edit() == true
				? ' is associated with '
				: ' associated with '


				,ctrl.model.editResourcePermissions() && !ctrl.model.metaDataLoading()
				? Pencil(
					() =>
						ctrl.model.users().length > 0
						? (R.find(
							(c) => c.user_id()
							== ctrl.model.form().resource.user_id()
							, ctrl.model.users()
						)
						|| nullUser)
						.user_username()
						: '...'

					,() =>
						autocomplete.strict(
							ctrl.model.users
							,R.when( Boolean, function(v){
								ctrl.model.form().resource.user_id(v.user_id())
							})
							, 'usersFullName'
							,() => ({
								placeholder: "John Smith"
							})
						)
				)
				: (R.find(
					(c) => c.user_id()
					== ctrl.model.form().resource.user_id()
					, ctrl.model.users()
				)
				|| nullUser)
				.user_username()
			]
		)
	]
}



function EditResource(ctrl){
	return m('div.harth.mb6',[
		ResourceHeader(ctrl)
		,m('br')
		,ctrl.model.editResourcePermissions() ? [
			elements.list([
				ctrl.model.contractor_edit() == true
				? elements.list([
						elements.back(
							"Go back to the resource list without saving"
							,() => {
								ctrl.model.errors({})
								ctrl.model.cloningResource(false, true)
								ctrl.model.crew_vm().selected.capability = {}
								ctrl.model.deleteTeams = []
								ctrl.model.viewtype = ListResource
								ctrl.model.form().resource = {}
								ctrl.model.deleteDisciplinesResourceArrays([])
								ctrl.model.deleteDisciplinesTeamsArrays([])
								ctrl.model.data.routeids({
									routeids: ctrl.model.resources().map(R.prop('contractrecognitionid'))
									,getledgers: false
									,ledgerdetails: false
									,getmatdata: false
									,specificproject: null
								})
							}
							,{disabled: ctrl.model.savingProp()}
						)
						,m("button.btn.btn-warning", {
							disabled: ctrl.model.savingProp()
								|| !ctrl.model.deleteDisciplinesTeamsArrays().length
								&& !ctrl.model.deleteDisciplinesResourceArrays().length
								&& identical(ctrl.model.vm().selected.resource, ctrl.model.form().resource)
							,onclick: () => {
								ctrl.model.errors({})
								ctrl.model.cloningResource(false, true)
								ctrl.model.crew_vm().selected.capability = {}
								ctrl.model.deleteTeams = []
								ctrl.model.reinstateWork()
							}
							,title: "Discard all changes since last saving this resource"
						},"Discard Changes")
				])
				: elements.list([
					m("button.btn.btn-warning", {
						disabled: ctrl.model.savingProp()
						,onclick: () => {
							ctrl.model.errors({})
							ctrl.model.form().resource = {}
							ctrl.model.resetCrewForm()
							ctrl.model.vm().selected.resource = {}
							ctrl.model.viewtype = ListResource
							ctrl.model.deleteTeams = []
						}
						,title: "Discard this resource without saving"
					},"Discard")
				])
				,!ctrl.model.SaveRDisabled(true)
				&& (
					!identical(ctrl.model.vm().selected.resource, ctrl.model.form().resource)
					|| ctrl.model.deleteDisciplinesTeamsArrays().length
					|| ctrl.model.deleteDisciplinesResourceArrays().length
				)
					? elements.action('Save', ctrl.intent.sendResource, ctrl.model.savingProp)
					: m('button.btn', {
						disabled: true
						,title: "Save this Resource"
					}, 'Save')
				,elements.alert('warn', ctrl.model.SaveRDisabled(true))
			])
        ]
		: elements.list([
			elements.back(
				"Go back to the resource list without saving"
				,() => {
					ctrl.model.form().resource = {}
					ctrl.model.errors({})
                    ctrl.model.resetCrewForm()
                    ctrl.model.vm().selected.resource = {}
					ctrl.model.viewtype = ListResource
					ctrl.model.data.routeids({
						routeids: ctrl.model.resources().map(R.prop('contractrecognitionid'))
						,getledgers: false
						,ledgerdetails: false
						,getmatdata: false
						,specificproject: null
					})
				}
			)
        ])

		,m('hr')

		,m('h4','Details')
		,m('br')
		,elements.splitPane(
			[

				elements.list([
					m('label.control-label', 'Resource Name'
						,ctrl.model.editResourcePermissions() && !ctrl.model.metaDataLoading()
							? Pencil(
								() => ctrl.model.form().resource.contractor_name()
								,() =>
									elements.textInput(
										ctrl.model.form().resource.contractor_name
										,{ disabled: !ctrl.model.editResourcePermissions()
										,placeholder: 'Internal Resources'
										}
									)
							)
							: elements.plainTxt(ctrl.model.form().resource.contractor_name())
					)
				])

				,elements.list([
					m('label.control-label', 'Base of Operations'
						,ctrl.model.editResourcePermissions() && !ctrl.model.metaDataLoading()
							? Pencil(
								() => ctrl.model.form().resource.contractor_yard()
								,() =>
									m(
										autocomplete.Main
										,{
											list: ctrl.model.placeLong
											,field: null
											,onselect: ctrl.model.form().resource.contractor_yard
											,oninput: ctrl.model.suburbsInput
											,placeholder: "Suburb of choice"
										}
									)
							)
							: elements.plainTxt(ctrl.model.form().resource.contractor_yard())
					)
				])
				,elements.list([
					m('label.control-label', 'Estimated Quality Rating (Between 0 and 1)'
						,ctrl.model.editResourcePermissions() && !ctrl.model.metaDataLoading()
							? Pencil(
								() => ctrl.model.form().resource.contractor_quality_rating()
								,() =>
									m(
										NumberInput
										,R.merge(
											ctrl.model.data
											,{
												errors: ctrl.model.errors
												,errorLabel: 'Work Rate Per Day'
											}
										)
										,{
											prop: ctrl.model.form().resource.contractor_quality_rating
											,attrs: {
												min: 0
												, max: 1
												, step: 0.01
												, disabled: !ctrl.model.editResourcePermissions()
												, title: "An estimate or actual rating of the quality of this resource and their work between 0 and 1. This will be used to match resource's to projects"
											}
										}
									)
							)
							: elements.plainTxt(ctrl.model.form().resource.contractor_quality_rating())
					)
				])
				,elements.list([
					m("label" , "First Start Date"
						,ctrl.model.editResourcePermissions() && !ctrl.model.metaDataLoading()
							? Pencil(
								() => moment(ctrl.model.form().resource.contractor_first_onsite_start_date()).format("ll")
								,() =>
									elements.dateInput(
										ctrl.model.form().resource.contractor_first_onsite_start_date
										,{
											disabled: !ctrl.model.editResourcePermissions()
											, title: "The resource's first availability to work on projects"
										}
									)
							)
							: elements.plainTxt(
								moment(
									ctrl.model.form()
									.resource
									.contractor_first_onsite_start_date()
								).format("ll")
							)
					)
				])
				,elements.list([
					m('label.control-label', 'Comfortable Work Zone'
					,elements.selectbuttons(
						['Rural', 'Suburban', 'CBD']
						,ctrl.model.form().resource.contractor_comfortable_work_zone
						,{
							disabled: !ctrl.model.editResourcePermissions() || ctrl.model.metaDataLoading()
							,title: "The most productive environment for this resource"
						}
					)
					)
				])
			],
			[
				m(''
					+ css`
						display: grid;
						grid-template-columns: 1fr;
						grid-template-rows: 0.6fr 0.4fr;
						gap: 0.5em;
						align-items: end;
						margin-bottom: 2em
					`
					,m('p', `Resource details are used to match the most suitable Resources to running Projects in Odin's automatic scheduling`)
					,m('p', `Odin can designate works and run your schedules automatically which can help the business re-focus
					and find the best solutions for project delivery without spending any admin effort. If this is not ideal, resources can be manually assigned via projects`)
				)
				,m(HarthUppy, {
					getFields: () => ctrl.model.uploadmetadata({})
					, data: ctrl.model.data
				})
			]
		)

		,m('h4.pt1'
			,elements.list([
				'Teams in this Resource'
				,ctrl.model.editResourcePermissions()
				? m(`button`
					+ css`
						border-radius: 0.5em;
						background-color: white;
						padding: 0.7em 1em;
						border: solid 0.1px rgba(0,0,0,0.4);
						transition: 0.2s;
						opacity: 1;
					`
					.$hover(`
						opacity: 0.8;
					`)
					.$active(`
						opacity: 0.5;
					`)
					,{
						disabled:
							ctrl.model.metaDataLoading()
							? true : ctrl.model.check_crew_names()
						,onclick: () => {
							ctrl.model.crew_vm().selected.capability = ctrl.model.resetCrewForm().capability
							ctrl.model.crew_form().capability = ctrl.model.cloningResourceCapability(false, true)
							ctrl.model.viewtype = EditCapabilities
							ctrl.model.crew_edit(false)
							ctrl.model.masterLIST(true)
						}
						,title: "Create a team that is a part of this resource"
					}
					, '+ Team '
				)
				: null
			])
		)

		,m(''
			+ css`s
				display: grid;
				grid-template-columns: 1fr;
				grid-template-rows: 0.6fr 0.4fr;
				gap: 0.5em;
				align-items: end;
				margin-top: 2em
			`
			,[
				m('p', `Each team will be used to forecast and record work responsibilities across projects`)
				,m('p', `The team can represent a group of people or an individual and should be linked to a user when collecting real world updates.
				The teams may also be used to model future growth and project capacity.`)
			]
		)
		,m('hr')

		,ctrl.model.getFilterInputs(ctrl.model.crewFilters)

		,ctrl.model.metaDataLoading()
		? elements.centeredSpinner()
		: elements.table(ctrl.model.data,
			[
				 'Team Name'
				, 'Rating'
				, 'Assigned User Responsibility'
				, ''
				, 'Expenditure'
				, 'Forecasted Expenditure'
				, ''
				, 'Current Scheduled Work'
			]
			,ListProcessing(
				ctrl.model.form().resource.crews
				, ctrl.model.crewFilters.filters()
				, 'crew_name'
			).map(
				(rc) => [

					m(`a`
						,{
							onclick:
								() => {
									ctrl.model.crew_vm().selected.capability = rc
									ctrl.model.crew_form().capability = ctrl.model.cloningResourceCapability(false, true)
									ctrl.model.viewtype = EditCapabilities
									ctrl.model.crew_edit(true)
									ctrl.model.discipline_vm({})
									ctrl.model.masterLIST(true)
									ctrl.model.data.routeids({
										routeids: ctrl.model.crew_vm().selected.capability.crews_discipline_rates.map(R.prop('contractrecognitionid'))
										,getledgers: false
										,ledgerdetails: false
										,getmatdata: false
										,specificproject: null
									})
								}
						}
						,rc.crew_name()
					)
					,Number(rc.crew_rating()).toFixed(2)

					,ctrl.model.editResourcePermissions()
						? Pencil(
							() =>
								(R.find(
									(c) => c.user_id() == rc.user_id()
									, ctrl.model.users()
								)
								|| nullUser)
								.user_username()
							,() =>
								autocomplete.strict(
									ctrl.model.users
									,R.when( Boolean, function(v){
										rc.user_id(v.user_id())
									})
									, 'usersFullName'
									,() => ({
										placeholder: "John Smith"
									})
								)
						)
						: (R.find(
							(c) => c.user_id() == rc.user_id()
							, ctrl.model.users()
						)
						|| nullUser)
						.user_username()

					,m(`a`
						,{
							onclick:
								() => {
									ctrl.model.crew_vm().selected.capability = rc
									ctrl.model.crew_form().capability = ctrl.model.cloningResourceCapability(true, false)
									ctrl.model.viewtype = EditCapabilities
									ctrl.model.crew_edit(false)
									ctrl.model.masterLIST(true)
								}
							,disabled:
								ctrl.model.savingProp()
								|| !!ctrl.model.check_cnames()
								|| ctrl.model.metaDataLoading()
							,title: "Copy all the details of another team to create a new team"
						}
						,'Clone'
					)
					,!ctrl.model.data.calculated()
						? ' ... '
						: R.find(
							(a) => a.crew_id
							== rc.crew_id()
							,ctrl.model.deleteDisciplinesTeamsArrays()
						)
							? 'Save to re-calculate'
							: makeMoney(
								ctrl.model.data.coalatedSelectedVersion()[rc.contractrecognitionid]
								? 									ctrl.model.data.coalatedSelectedVersion()[rc.contractrecognitionid].currentProfits
									+ ctrl.model.data.coalatedSelectedVersion()[rc.contractrecognitionid].outstandingProfits

								: 0
							)

					,!ctrl.model.data.calculated()
						? ' ... '
						: R.find(
							(a) => a.crew_id
							== rc.crew_id()
							,ctrl.model.deleteDisciplinesTeamsArrays()
						)
							? 'Save to re-calculate'
							: makeMoney(
								(
									ctrl.model.data.coalatedSelectedVersion()
										[rc.contractrecognitionid]
									|| {}
								).forecastedProfits
							)

					,m(''
						+css`
							display: grid;
							grid-template-columns: 1fr;
							gap: 0.5em;
							align-items: start;
							width: ` + (!ctrl.model.stillWorking(true, rc) ? '5em' : '20em')
						,[
							ctrl.model.editResourcePermissions()
							&& !ctrl.model.stillWorking(true, rc)
							? elements.xRemoval({
								disabled: ctrl.model.DeleteDisabledCapability(rc)
								,onclick: () => {
									ctrl.model.crew_vm().selected.capability = rc
									ctrl.intent.deleteCapability()
								}
							})
							: ctrl.model.stillWorking(true, rc)
						]
					)

					,!ctrl.model.data.readProjectPermissions()
						? elements.list(['Project permissions are required to see scheduled works'])
						: ctrl.model.loadingMeta()
							? ' ... '
							: !ctrl.model.teamWorkList()[rc.crew_id()]
								? "This Team has no scheduled work"
								: !ctrl.model.teamWorkList()[rc.crew_id()].workList.length
								&& ctrl.model.teamWorkList()[rc.crew_id()].totalcount
								&& !ctrl.model.deleteDisciplinesTeamsArrays().some((t) =>
									t.crew_id == rc.crew_id()
								)
									? 'Busy on other scheduled works'
									: m("button.btn.", {
											disabled: false
											,onclick: () => {
												rc.showWork(
													rc.showWork()
														? false
														: true
												)
											}
											,title: rc.showWork()
												? "Hide the next organized work for this team"
												: "Show the next organized work for this team"
										}
										,rc.showWork()
											? "Hide Detail"
											: "Show Works"
									)



					,!rc.showWork()
					|| !ctrl.model.teamWorkList()[rc.crew_id()]
					|| ctrl.model.loadingMeta()
						? ''
						: ctrl.model.teamWorkList()[rc.crew_id()].workList.length
						&& ctrl.model.teamWorkList()[rc.crew_id()].totalcount
							? elements.table(ctrl.model.data, ['Project', 'Disciplines/Tasks'],
								ctrl.model.workMapping(
									ctrl.model.teamWorkList
									,ctrl.model.deleteDisciplinesTeamsArrays
									,rc
									,'crew_id'
								)
							)
							: ''

				]
			)
		)
		// ,ctrl.model.form().resource.crews.length == 0 && !ctrl.model.metaDataLoading()
		// 	? elements.alert(
		// 		'info'
		// 		,`I haven't found any teams that are a part of this resource. Teams are required to schedule work`
		// 	)
		// 	: null
	])
}


function CapabilityHeader(ctrl){
	return [
		m('h4',
			(
				ctrl.model.crew_edit()
					? ''
					: 'Create a new Team '
			)
			+ ctrl.model.crew_form().capability.crew_name()
			+ ' in ' +  ctrl.model.form().resource.contractor_name()
		)
		,m('br')
		,elements.list([
			m('label', 'Team')
			,elements.textInput(
				ctrl.model.crew_form().capability.crew_name
				,{
					disabled: !ctrl.model.editResourcePermissions() || ctrl.model.metaDataLoading()
				}
			)
			,m('label', ' is associated with')

			,ctrl.model.editResourcePermissions() && !ctrl.model.metaDataLoading()
				? Pencil(
					() =>
						(R.find(
							(c) => c.user_id()
							== ctrl.model.crew_form().capability.user_id()
							, ctrl.model.users()
						)
						|| nullUser)
						.user_username()
					,() =>
						autocomplete.strict(
							ctrl.model.users
							,R.when( Boolean, function(v){
								 ctrl.model.crew_form().capability.user_id(v.user_id())
							})
							, 'usersFullName'
							,() => ({
								placeholder: "John Smith"
							})
						)
				)
				: (R.find(
					(c) => c.user_id()
					== ctrl.model.crew_form().capability.user_id()
					, ctrl.model.users()
				)
				|| nullUser)
				.user_username()
		])
	]
}


let WorkRatesTab = function(ctrl){
	return [
		m('br')

		,ctrl.model.crew_form().capability.crews_discipline_rates.length
			? elements.list([
				ctrl.model.getFilterInputs(ctrl.model.crewratesFilters)
				,ctrl.model.editAllButton()
			])
			: null
		,ctrl.model.crew_form().capability.crews_discipline_rates.length == 0
			? m('div'
				+ css`
				display: grid;
				height: 15vh;
				align-content: center;
				`
				,ctrl.model.editToolPermissions() && false
				? m(`button`
					+ css`
						backgroundColor: transparent;
						transition: 0.2s;
						opacity: 1;
						color: #434aa3;
						border: solid 1px #3380c2;
						align-items: center;
						display: grid;
						position: relative;
						height: 2.5em;
						width: 10em;
						margin-right: auto;
						margin-left: auto;
						border-radius: 0.25em;
						`
					.$hover(`
						opacity: 0.8;
					`)
					.$active(`
						opacity: 0.5;
					`)
					,{
						onclick: ctrl.model.createNewWorkRate
						,disabled: !!ctrl.model.check_dnames(true) || ctrl.model.metaDataLoading()
						,title: "Create a type of work this team is capable of conducting"
					}
					,'Create a New Team Discipline '
				)
				: null
				,m('br')
				,m('div'
					+ css`
					align-items: center;
					text-align: center;
					display: grid;
					position: relative;
					margin-right: auto;
					margin-left: auto;
					padding: 10px;
					`
					,`Team Disciplines will represent the team's prodcutivity for a discipline
					and can be connected to contracts for monetary compensation after works are completed`
				)
			)
			: null


		,ctrl.model.metaDataLoading()
		? elements.centeredSpinner()
		: [].concat(
			R.unnest(
				R.partition(
					(c) => c.created
					,ctrl.model.crew_form().capability.crews_discipline_rates
				)
			)
		).length != 0
		? ctrl.model.ratesTable()
		: null
	]
}

let ToolsTab = function(ctrl){
	return [
		ctrl.model.tools_vm().length != 0 || !ctrl.model.crew_edit()
			? ctrl.model.toolsTable()
			: elements.alert('info'
			,`I have no records of any tools, they can be created`
			 + ` for tracking via the Tools route `
		)
	]
}


function EditCapabilities(ctrl){
	return m('div.harth.mb6', [

		CapabilityHeader(ctrl)
		,ctrl.model.editResourcePermissions()
		? elements.list([
			ctrl.model.crew_edit() == true
			? elements.list([
				elements.back(
					"Return to the team list without saving"
					,() => {
						ctrl.model.crew_form().capability = {}
						ctrl.model.discipline_vm({})
						ctrl.model.setOptionsMark(false)
						ctrl.model.checkMultiple().data = []
						ctrl.model.deleteRates([])
						ctrl.model.errors({})
						ctrl.model.viewtype = EditResource
						ctrl.model.data.routeids({
							routeids: ctrl.model.vm().selected.resource.crews.map(R.prop('contractrecognitionid'))
							,getledgers: false
							,ledgerdetails: false
							,getmatdata: false
							,specificproject: null
						})
					}
					,{disabled: ctrl.model.savingProp()}
				)

				,ctrl.model.SaveCDisabled(true)
				|| ctrl.model.check_dnames(true)
				|| identical(ctrl.model.crew_vm().selected.capability, ctrl.model.crew_form().capability)
				&& identical(ctrl.model.tools(), ctrl.model.tools_vm())
					? m('button.btn', {
						disabled: true
					}, 'Save')
					: elements.action('Save', ctrl.intent.sendResource, ctrl.model.savingProp)

				,ctrl.model.discardChangesButton(ctrl)
				,ctrl.model.SaveCDisabled(true)
			])
			: elements.list([
				ctrl.model.SaveCDisabled(true)
				|| identical(ctrl.model.crew_vm().selected.capability, ctrl.model.crew_form().capability)
					? m('button.btn', {
						disabled: true
					}, 'Save')
					: elements.action('Save', ctrl.intent.sendResource, ctrl.model.savingProp)
				,m("button.btn.", {
					disabled: false
					,onclick: () => {
						ctrl.model.errors({})
						ctrl.model.crew_form().capability = {}
						ctrl.model.crew_vm().selected.capability = {}
						ctrl.model.discipline_vm({})
						ctrl.model.checkMultiple().data = []
						ctrl.model.setOptionsMark(false)
						ctrl.model.deleteRates([])
						ctrl.model.viewtype = EditResource
					}
					,title: "Discard this team without saving"
				},"Discard")
				,elements.alert('error', ctrl.model.SaveCDisabled(true))
			])
		])
		: elements.back(
			"Return to the team list without saving"
			,() => {
				ctrl.model.crew_form().capability = {}
				ctrl.model.discipline_vm({})
				ctrl.model.checkMultiple().data = []
				ctrl.model.setOptionsMark(false)
				ctrl.model.errors({})
				ctrl.model.viewtype = EditResource
				ctrl.model.data.routeids({
					routeids: ctrl.model.vm().selected.resource.crews.map(R.prop('contractrecognitionid'))
					,getledgers: false
					,ledgerdetails: false
					,getmatdata: false
					,specificproject: null
				})
			}
		)

		,m('hr')

		,m(''
			+ css`s
				display: grid;
				grid-template-columns: 1fr;
				grid-template-rows: 0.6fr 0.4fr;
				gap: 0.5em;
				align-items: end;
				margin-top: 2em
			`
			,[
				elements.list([
					m('h4', ctrl.activeTeamTab() )
					,ctrl.model.editResourcePermissions()
					&& ctrl.activeTeamTab() == 'Team Disciplines'
					? m(`button`
						+ css`
							border-radius: 0.5em;
							background-color: white;
							padding: 0.7em 1em;
							border: solid 0.1px rgba(0,0,0,0.4);
							transition: 0.2s;
							opacity: 1;
						`
						.$hover(`
							opacity: 0.8;
						`)
						.$active(`
							opacity: 0.5;
						`)
						,{
							disabled: !!ctrl.model.check_dnames(true) || ctrl.model.metaDataLoading()
							,onclick: ctrl.model.createNewWorkRate
							,title: "Create a type of work this team is capable of conducting"
						}
						, '+ Disciplines '
					)
					: null
				])
				, elements.alert(
					'verbose'
					,ctrl.activeTeamTab() == 'Team Disciplines'
					? `Team Disciplines describe this team's capabilities and how fast they can complete the works. This is used in Odins automatic schedulling to allocate works to projects.  `
					+ `A team discipline may be assigned a rate to pay the team for work completed. Alternatively teams may be designated an hourly rate instead of discipline rates.
					Or, they can be combined to pay a team a base hourly rate with a commisions based on completed discipline quantities.`
					: `Tools may be assigned to teams to track their use and stored liabilities`
				)
			]
		)

		,ctrl.model.stillWorking()


		,ctrl.model.metaDataLoading()
			? elements.centeredSpinner()
			: [
				elements.tabset(['Team Disciplines', 'Assign a Tool'], ctrl.activeTeamTab)
				,ctrl.activeTeamTab() == 'Assign a Tool' ? ToolsTab(ctrl) : WorkRatesTab(ctrl)
			]
	])
}


/*
	In order for mithril to mount this component.  We have to expose these two properties.
*/
export default Main