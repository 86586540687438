import m from 'bacta'
import * as R from 'ramda'
import Gantt from './gantt'

import NotificationBar from '../components/notification'
import { Router, Manager, HREF } from '../utils/state-router'
import Promise from 'bluebird'
import { prop as stream } from '../../../stream'
import css from 'bss'

function Dashboard({ attrs: { data: universe } }) {

	const allocations = universe.scoped()

	const href = HREF('/dashboard')

	const writeState = universe.scoped()
	const readState = stream.scan(
		function(p, { state, _ }){

			return state
		}
		, m.route.get().includes('/dashboard')
			? href.from(
				['organization_id'
				, 'schedule_id'
				, 'schedule_version_id'
				]
				// eslint-disable-next-line no-undef
				,  m.route.get()
			)
			: { path: {}, search: {} }
		, writeState
	)

	const manager = Manager(readState, writeState)
	const router = Router(href, manager)

	const path = router.createPathStream()

	// window.writeState = writeState
	// window.pathStream = path
	const [
		organization_id
		, schedule_id
		, schedule_version_id
		, routeState
	] =
		[
		['path','organization_id']
		, ['path','schedule_id']
		, ['path','schedule_version_id']
		, ['search']

		]
			.map( manager.streamFromPath )

	/**
	 * Side effects!
	 */
	path.map(function(path){
		// todo-james this runs even though writeState is ended
		// I'm not sure if it is a bug in mithril-stream
		// or a bug in state-router
		// Instead of spending too long investigating, I'm just putting this
		// guard in.  Long term, I want us to use Z instead of mithril-stream
		// and state-router is deprecated, so not worth the effort probably
		if( m.route.get().startsWith('/dashboard') ) {
			// eslint-disable-next-line
			return history.replaceState(null, null, path)
		}
		return null
	})


	const redraw = function(){
		// eslint-disable-next-line no-var
		var requested = false

		function loop(){
			if( requested ){
				m.redraw()

				requested = false
			}
			// eslint-disable-next-line no-undef
			requestAnimationFrame(loop)
		}

		loop()

		return function redraw(){

			requested = true
		}
	}.call()


	const world = Object.assign({
		schedule_id
		, schedule_version_id
		, organization_id
		, allocations
		, routeState
		, redraw
	}, universe)

	const loading = m.prop(!!schedule_version_id())


	let session

	stream.merge(
		[
			universe.initiateContractFetch
			,universe.schedule_version_id
		]
	)
	.map(function([ready, sv]){

		if( !ready ) return null;

		// const schedule_version_id = universe.actual.schedule_version_id
		const organization_id = universe.organization_id

		if(
			organization_id == null
			|| sv == null
		){
			allocations([])
			return Promise.resolve(null)
		} else {
			allocations([])

			if( session != null ){
				session.abort()
			}


			session = world.api.Session()

			loading(true)
			return world
				.fetchAllocationsbyScheduleVersion(
					{schedule_version_id: sv}
					,'shallow'
				)
			.then(R.sortBy(R.prop('allocation_id')))
			.then(
				R.reject(
					x => x.allocations_allocation_date == null
						|| x.allocations_allocation_date == 0
				)
			)
			.then(world.allocations)

			.then(function(){

				redraw()

				return world
					.fetchAllocationsbyScheduleVersion(
						{schedule_version_id: sv}
						,'complete'
					)

			})
			.then(R.sortBy(R.prop('allocation_id')))
			.then(
				R.reject(
					x => x.allocations_allocation_date == null
						|| x.allocations_allocation_date == 0
				)
			)
			.then(world.allocations)
			.catch(
				session.onerror(NotificationBar.Notifications.alertError)
			)
			.then(function(){
				redraw()
				loading(false)
				world.currentRoute('gantt')

				ready.schange = false

				ready.ochange = false
			})
		}
	})

	const gantt = Gantt(world)

	const centeredSpinner = () =>
		m('img', {
			src: '/assets/spinner.gif'
			, style:
				{ transform:
					'translate(calc(50vw - 100%), calc(50vh - 100% - var(--top-nav-height)))'
				, transformOrigin: 'top left'
				, filter: 'saturate(0%) brightness(100%)'
				, width: '3em'
				, userSelect: 'none'
				}
		})

	return {
		view: function(){

			return m('div'
				, { style: { touchAction: 'none' } }
				, gantt(world)
				, m('div.vw-100.vh-100.absolute.bg-black'
					+ css`
						min-width: 100vw;
						min-height: 100vh;
						position: absolute:
						top: 0px;
						left: 0px;
						opacity: ${ loading() ? 0.9 : 0 }
						pointer-events: none;
						transition: opacity 1s;
					`
				)
				, loading()
					? centeredSpinner()
					: null
			)
		}
		,onremove(){
			if( session != null ){
				session.abort()
			}

			// eslint-disable-next-line
			allocations([])
		}
	}
}

export default Dashboard