/* globals setTimeout, document, process */
/* eslint-disable no-var, complexity, max-len */
import { Light as DetailsPane } from '../components/detailspane2'
import Pencil from '../components/pencil'
import DropdownButton from '../components/buttonDropdown'
import HarthUppy from '../components/harth-uppy'
import scrollableTable from '../components/scrollableTable'
import {identical} from '../utils/harth-identical'
import humanList from '../utils/human_list'
import NumberInput from '../components/number'
import m from 'bacta'
import css from 'bss'
import * as H from '../../../how/index.js'
import * as elements from '../components/elements'
import Responsive from '../components/responsive'
import {
	filterView
	,ListProcessing
} from '../components/filter'
import autocomplete from './autocomplete'
import InitialProjectView from './projects-initial'
import Promise from 'bluebird'
import * as R from 'ramda';
import byProp from '../utils/byProp'
import Permissions from '../models/permissions'
import ProjectDocument from '../models/project_documents'
import { money as makeMoney } from '../utils/regExes'
import * as uuid from 'uuid'
import drawChart from '../components/harth-chartist'
import OAuthConnector from '../components/oauthConnector'
import * as modalStack from '../components/modal-stack'
// import Permissions from '../models/permissions'
import moment from 'moment'
const milliperday = 1000*60*60*24
const aMonth = 1000*60*60*24*28

const nullUser = {
	user_id: m.prop(null)
	, user_username: m.prop('Unassigned')
	, usersFullName: 'Unassigned'
	, contractrecognitionname: 'Unassigned'
	, contractrecognitionid: null
}
import { prop } from '../../../stream'

const exposer = (prop) =>
	typeof prop == 'function'
		? R.call(prop)
		: R.identity(prop)

const checkprop = (a, b, c) =>
	c[a] && b && c[a][b]
		? typeof c[a][b] == 'function'
			? c[a][b]()
			: c[a][b]
		: typeof c[a] == 'function'
			? c[a]()
			: c[a]

const documentLabel = (project_documents, doc) =>
	m('label', 'Label'
		,m('input.validate',
			{ required: true
			, style:
					{ padding: '0.2em' }
			, placeholder: 'My document'
			, value: doc.project_documents_label
			, oninput: e => {
					const project_documents_label = e.target.value
					const validationMessage = e.target.validationMessage
					const errors = R.merge(doc.errors, {
						project_documents_label: validationMessage
							? doc.defaultErrors.project_documents_label
							: null
					})

				project_documents(
					project_documents().map(
						x => x.project_documents_id
							== doc.project_documents_id
						? R.merge(x, { project_documents_label, errors })
						: x
					)
				)
			}
			}
		),
	)

const documentLink = (project_documents, doc) =>
	m('label', 'Document Link'
		,m('input.validate',
			{ required: true
			, style:
				{ padding: '0.2em' }
			, type: 'url'
			, pattern: "https://.+"
			, placeholder: 'https://box.com/yourdoc.pdf'
			, oninput: e => {
					const project_documents_href = e.target.value
					const validationMessage = e.target.validationMessage
					const errors = R.merge(doc.errors, {
						project_documents_href: validationMessage
							? doc.defaultErrors.project_documents_href
							: null
					})
					project_documents(
						project_documents().map(
							x =>
								x.project_documents_id == doc.project_documents_id
							? R.merge(x, { project_documents_href, errors })
							: x
						)
					)

			}
			, value: doc.project_documents_href
			, title: 'https://box.com/yourdoc.pdf'
			}
		),
	)

const documentCheckbox = (project_documents, doc) =>
	m('div.checkbox',
		m('label', [
			m('input[type=checkbox].checkbox-size'
				, Object.assign(
					{ checked: doc.project_documents_is_deliverable
					, onchange: m.withAttr('checked',
						project_documents_is_deliverable =>
							project_documents(
								project_documents().map(
									x =>
										x.project_documents_id
											== doc.project_documents_id
									? R.merge(
										x, { project_documents_is_deliverable }
									)
									: x
								)
							)
					)
					}
				)
			)
			,'Deliverable'
		])
	)

const documentWarningList = doc =>
	[
		Object.values(doc.errors).filter(Boolean).map(
			err => m('li', err)
		)
	]
	.map(
		xs => xs.length
			? elements.alert('warning', [
				m('ul.list', xs)
			])
			: null
	)
	.shift()

const documentSaveCancel = ({ doc, project_documents, editing }) =>
	elements.list([
		m('button.btn.btn-secondary',
			{ onclick: () => {
				editing(false)
				project_documents(
					project_documents().filter(
						x => x.project_documents_id
							!= doc.project_documents_id
					)
				)
			}
			, type: 'button'
			,style:
			{ position: 'relative'
			, fontSize: '0.9em'
			, padding: '0.2em 0.5em'
			}
			}
			, doc.project_documents_href ? 'Remove' : 'Discard'
		)
		,m('button.btn.btn-primary',
			{ onclick: e => {
				e.preventDefault()
				e.stopImmediatePropagation()
				editing(false)
			}
			,style:
			{ position: 'relative'
			, fontSize: '0.9em'
			, padding: '0.2em 0.5em'
			}
			, disabled:
				Object.values(doc.errors).filter(Boolean).length > 0
			, type: 'button'
			}
			, 'Confirm'
		)
	])

const mergeInDocumentValidationProps = x =>
	[x]
	.map(
		R.merge({
			defaultErrors:
				{ project_documents_href:
						'Document link should be a valid https path.'
				, project_documents_label:
					'Document label is a required field.'
				}
		})
	)
	.map(
		doc => R.merge({
			errors:
				{ project_documents_href:
					doc.project_documents_href
						? ''
						: doc.defaultErrors.project_documents_href
				, project_documents_label:
					doc.project_documents_label
						? ''
						: doc.defaultErrors.project_documents_label
				}
		}, doc)
	)
	.shift()

const editProjectDocuments = (project_documents) =>
	m('div.db'
		,elements.list([
			m('button.btn.btn-secondary',
				{ style:
					{ backgroundColor: 'transparent'
					, border: 'solid 1px #3380c2'
					, position: 'relative'
					, top: '-0.1em'
					, height: '2.9em'
					, width: '5em'
					, color: '#434aa3'
					, textDecoration: 'underline'
					// , fontSize: '0.9em'
					// , padding: '0.2em 0.5em'
					}
					,onclick: () => project_documents(
						[
							{ project_documents_href: ''
							, project_documents_label: ''
							, project_documents_is_deliverable: false
							, project_documents_id: uuid.v4()
							}
						]
						.concat( project_documents() )
					)
				}
				, 'Add'
			)
			,m('label.dib.control-label', 'Project Links')
		])
		,project_documents().find( x => !x.project_documents_href )
			&& elements.alert(
				'warning', 'Unconfirmed documents will be lost on Save'
			)
		,project_documents()
			.map( mergeInDocumentValidationProps )
			.map(
				doc =>
					m('div',
						{ key: doc.project_documents_id },
						Pencil(
							() => doc.project_documents_href
								? ProjectDocument.toVTree(doc)
								: 'Untitled Document (Unconfirmed)'
							,editing => elements.list([
									m('form'
										,m('.hth-input-grid',
											{ style:
												{ display: 'grid'
												, 'gridTemplateColumns': '1fr 1fr'
												}
											}
											,documentLabel( project_documents, doc )
											,documentLink( project_documents, doc )
											,documentCheckbox( project_documents, doc )
										)
										,documentWarningList( doc )
										,documentSaveCancel({
											project_documents
											, doc
											, editing
										})
									)
								])
						, { toggleable: true }
					)
				)
			)
	)

/*
	Intent: Functions that help the user do what they want to do...
*/

function Intent(model){

	function fetchOrganizationsDisciplines(){
		return model.data.fetchOrganizationsDisciplines({})
			.then(model.organizations_disciplines)
			.then(m.redraw)
	}

	function fetchAllocations(){
		if (model.data.schedule_id() != null){
			const schedule_index =
				R.findIndex(
					R.propEq('schedule_id', model.data.schedule_id())
					, model.data.schedules()
				)

			if (schedule_index != -1 ){

				const most_recent_version = R.sortBy(
                    R.prop("schedule_version_created"),
                    model.data.schedules()[schedule_index].schedule_versions
                )

				const LatestVerionIndex = most_recent_version.length - 1

				const schedule_version_id =
					model.data.selectedVersion().schedule_version_id
					|| most_recent_version[LatestVerionIndex].schedule_version_id

				const project_id =
					!model.form().project.project_id
						? null
						: model.form().project.project_id()

				if ( project_id ){
					return model.data
					.fetchAllocationsbyProjectAndScheduleVersion({
						project_id: project_id
						, schedule_version_id: schedule_version_id
						, breadth: [
							'allocations_vars'
							,'allocations_nods'
							,'allocations_eots'
						]
						, props: {
							allocations: [
								'allocations_work_name'
								,'allocations_completion_comments'
								,'allocations_allocation_date'
								,'allocation_id'
								,'project_id'
							]
						}
					})
					.then(
						list => list.map(
							function(a){

								const maxLENGTH =
									25

								const workname =
									a.allocations_work_name()

								const thiscomment =
									!a.allocations_completion_comments()

								const sliceINDEX =
									workname.length > maxLENGTH
										? workname.indexOf(" ", maxLENGTH)
										: workname.length

								const workdisplay =
									workname.slice(0, sliceINDEX)

								const commentREF =
									moment(a.allocations_allocation_date()).format("ll")
										+ ': (' + workdisplay + ') '

								a.allocations_completion_comments(
									thiscomment
										? a.allocations_completion_comments()
										: commentREF
										+ a.allocations_completion_comments()
								)

								return a
							}
						)
					)
					.then( R.tap(model.allocations))
					.then(
						list => list
							.filter(a => a.allocations_completion_comments() )
					)
					.then(
						R.sortBy( (a) => a.allocations_allocation_date() )
					)
					.then(m.redraw)
				}
			}

		}

		return Promise.resolve([])
	}

	function fetchProjects(params){
		return model.data.fetchProjectsBySchedule(
			R.merge(
				{
					schedule_id: model.schedule_id()
					,completedProjects: model.completedProjects()
				}
				,params
			)
		)
		.then( resetView )
	}

	function fetchProjectTemplates(){
		return model.data.fetchProjectsBySchedule({
			schedule_id: model.schedule_id()
			,completedProjects: null
			,gettemplates: true
		})
		.then((res) =>
			model.projectTemplates(
				res.concat(
					R.merge(
						model.resetPForm()
						,{
							project_name: m.prop('Custom')
							,custom: true
						}
					)
				)
			)
		)
		.then( m.redraw )
	}

	function resetView(projectList, state){

		const comparisonProp =
			model.form().project.project_id
			&& model.form().project.project_id()
			? 'project_id'
			: 'project_name'

		model.projects(
			R.uniqBy(
				(c) => checkprop('project_id', null, c)
				,R.sort(
					R.descend(R.prop('__requestedAt'))
					,[].concat(
						projectList.filter((p) =>
							(model.completedProjects() == 'inprogress' && !p.project_completion_marker())
							|| (model.completedProjects() != 'inprogress' && p.project_completion_marker())
						)
						,model.projects()
						.filter((p) =>
							p.schedule_id() == model.data.selectedVersion().schedule_id
							&& !projectList.find((np) => np.project_id() == p.project_id())
							&& (
								model.completedProjects() == 'inprogress'
									? !checkprop('project_completion_marker', null, p)
									: checkprop('project_completion_marker', null, p)
							)
						)
					)
				)
			)
		)

		const newProject =
			model.form().project.project_id
			&& !model.onProjectList()
				? projectList.find((p) =>
					checkprop(comparisonProp, null, p)
					== checkprop(comparisonProp, null, model.form().project)
				)
				: null

		if(
			newProject
			&& (
				!model.form().project['__requestedAt']
				|| newProject['__requestedAt']
				>= model.form().project['__requestedAt']
			)
		){
			model.checkMultipleProjects([])
			model.checkMultiple().data = []
			model.contractPatchIndex({})
			model.checkMultipleProjectExtras().data = []
			model.discipline_vm({})
			model.vm( newProject )
			model.checkMultipleProjects().push( newProject )
			model.cloningProject(false, true)
			model.masterLIST(true)
			model.masterPELIST(true)
			model.fetchedExtras(false)
			// used to bump the project extras fetch and update the new saved and returned object
			// IF we are on the project extras tab
			model.activeTab(model.activeTab())
			model.edit(true)
		}

		if ( !model.metaDataLoading() || state && state.forcereq){
			model.form().project.project_id
				? model.data.routeids({
					routeids:
						[].concat(
							newProject
							? newProject.disciplines
							: []
							, model.form().project
						)
						.map(R.prop('contractrecognitionid'))
					,getledgers: true
					,ledgerdetails: true
					,getmatdata: true
					,specificproject: [model.form().project.project_id()]
				})
				: model.data.routeids({
					routeids: model.projects().map(R.prop('contractrecognitionid'))
					,getledgers: false
					,ledgerdetails: false
					,getmatdata: false
					,specificproject: model.projects().map(R.prop('contractrecognitionid'))
				})
		}

		model.newUser(false)

		return true
	}

	function deleteProjectContacts(){
		return model.deleteProjectContacts().length
		? model.api.project_contacts.remove.many(model.deleteProjectContacts())
		: Promise.resolve([])
	}

	function deletetInvoices(){
		return Promise.all(
			Object.keys(model.deleteInvoiceItemsHash())
			.map((t) => model.api.invoices.itemsremove.one(t))
		)
		.then(() => {
			return Promise.all(
				Object.keys(model.deleteInvoiceHash())
				.map((t) => model.api.invoices.remove.one(t))
			)
		})
	}

	function sendInvoices(){
		return model.newInvoices().length
		? model.api.invoices.patch.many(model.newInvoices(), true)
		: Promise.resolve([])
	}

	function sendContractItems(){

		const currentContract =
			model.data.contracts().find((c) =>
				model.form().project.contract_id
				&& c.contract_id
				== model.form().project.contract_id()
			)

		const newitemspatch =
			!model.form().project.contract_id
			|| !model.form().project.contract_id
			&& !model.form().project.contract_id()
			? null
			: R.merge(
				model.data.contracts().find((c) =>
					c.contract_id
					== model.form().project.contract_id()
				)
				|| {
					contract_name: m.prop(model.form().project.contract_name)
					,contract_id: m.prop(model.form().project.contract_id())
					,contract_description: m.prop('Initial Contract')
					,contract_payment_term: m.prop(42)
					,contract_count_invoices: m.prop(true)
					,contract_start_date: m.prop( new Date().toISOString() )
					,contract_end_date: m.prop( new Date ( new Date().getTime() + milliperday * 365 ).toISOString() )
					,supplier_id: m.prop( null )
					,supplier_name: m.prop( null )
					,contract_terms: m.prop(null)
				}
				,{contract_items: model.newContracts()}
			)

		return model.newContracts().length || newitemspatch && !currentContract
			? model.api.contracts.patch.many([newitemspatch])
			: Promise.resolve([])
	}

	function splitInvoices(){

		const newapprovedORrecevedinvoices =
			model.baseInvoiceRender()
			.filter((i) => i.forcesplit)
			.map((i) => {

				const newuidforcesplit = uuid.v4()
				const spliteitems =
					i.invoice_items.filter((ii) => ii.forcesplit)
				const ratiosplit =
					i.invoice_items.length
						? ''
						: Number(i.recqty/i.invoice_amount()).toFixed(0)
						+ (new Date().getTime() + '').slice(0,13)

				const newinvoice =
					R.merge(
						R.last(model.data.cloneInvoice([i]))
						,{
							invoice_id: m.prop(newuidforcesplit)
							,invoice_no: m.prop(i.invoice_no() + '-ST' + i.invoice_items.length + spliteitems.length + ratiosplit)
							,invoice_client_reference: m.prop('')
							,invoice_items:
								spliteitems
								.map((ii) =>({
									invoice_id: newuidforcesplit
									,invoice_items_id: uuid.v4()
									,invoice_items_received_amount: 0
									,timesheets_id: ii.timesheets_id
									,project_extras_id: ii.project_extras_id
									,invoice_items_amount: ii.invoice_items_amount-ii.invoice_items_received_amount
								}))
							,recqty: i.recqty
							,invoice_received: m.prop(false)
							,invoice_received_date: m.prop(null)
							,created: true
							,invoice_amount:
								m.prop(
									i.invoice_amount()
									? i.invoice_amount()-i.recqty
									: i.invoice_amount()
								)
							,invoice_service_amount:
								m.prop(
									i.invoice_service_amount()
									? i.invoice_service_amount()-i.recqty
									: i.invoice_service_amount()
								)
							,invoice_depreciation_amount:
								m.prop(
									i.invoice_depreciation_amount()
									? i.invoice_depreciation_amount()-i.recqty
									: i.invoice_depreciation_amount()
								)
							,invoice_appreciation_amount:
								m.prop(
									i.invoice_appreciation_amount()
									? i.invoice_appreciation_amount()-i.recqty
									: i.invoice_appreciation_amount()
								)
							,invoice_damages_amount:
								m.prop(
									i.invoice_damages_amount()
									? i.invoice_damages_amount()-i.recqty
									: i.invoice_damages_amount()
								)
						}
					)

				if( spliteitems.length ){
					spliteitems.forEach((ii) => ii.invoice_items_amount = ii.invoice_items_received_amount)
				}
				else {
					i.invoice_amount(i.invoice_amount() ? i.recqty : i.invoice_amount())
					i.invoice_service_amount( i.invoice_service_amount() ? i.recqty : i.invoice_service_amount())
					i.invoice_depreciation_amount( i.invoice_depreciation_amount() ? i.recqty : i.invoice_depreciation_amount())
					i.invoice_appreciation_amount( i.invoice_appreciation_amount() ? i.recqty : i.invoice_appreciation_amount())
					i.invoice_damages_amount( i.invoice_damages_amount() ? i.recqty : i.invoice_damages_amount())
				}

				return newinvoice
			})

		const newpaymentrefenceinvoices = []

		return model.baseInvoiceRender()
		.filter((i) => i.created)
		.map((i) => {

			if( i.created ){

				[
					i.invoice_items.filter((ii) => !ii.approved && !ii.received)
					,i.invoice_items.filter((ii) => !ii.approved && ii.received)
					,i.invoice_items.filter((ii) => ii.approved && !ii.received)
					,i.invoice_items.filter((ii) => ii.approved && ii.received)
					,!i.invoice_items.length ? [i] : []
				]
				.filter(R.prop('length'))
				.forEach((ii, iiindex, iiarray) => {

					const ref = R.last(ii)
					const newuuid = uuid.v4()
					const ratiosplit =
						i.invoice_items.length
							? ''
							: Number(i.recqty/i.invoice_amount()).toFixed(0)
							+ (new Date().getTime() + '').slice(10,13)
					const refinvoice =
						iiindex < iiarray.length-1
						? R.merge(
							R.last(model.data.cloneInvoice([i]))
							,{
								invoice_id: m.prop(newuuid)
								,invoice_no: m.prop(i.invoice_no() + '-ST' + (i.invoice_items.length-ii.length) + ii.length + ratiosplit)
								,invoice_items:
									R.unnest(
										ii.map((item) =>
											i.invoice_items.splice(
												i.invoice_items
												.findIndex((bii) =>
													item.invoice_items_id
													== bii.invoice_items_id
												)
												,1
											)
										)
									)
									.map((ii) => R.merge(ii, {invoice_id: newuuid}))
							}
						)
						: i

					iiindex < iiarray.length-1 ? newapprovedORrecevedinvoices.push(refinvoice) : null

					if(ref.approved){
						refinvoice.invoice_approval(true)
						refinvoice.invoice_approver(model.data.auth.stream().user_id)
						!refinvoice.invoice_due_date()
							? refinvoice.invoice_due_date(
								new Date().getTime()
								+ Number(refinvoice.contract_payment_term)*milliperday
							)
							: null
					}
					else if(ref.approved == false){
						refinvoice.invoice_approval(false)
						refinvoice.invoice_approver(null)
						!refinvoice.invoice_due_date()
							? refinvoice.invoice_due_date(null)
							: null
					}

					if(ref.received){
						refinvoice.invoice_received(true)
						refinvoice.invoice_received_date(
							ref.receiveddate || new Date().getTime()
						)
					}
					else if(ref.received == false) {
						refinvoice.invoice_received(false)
						refinvoice.invoice_received_date(null)
					}

				})
			}
			return i
		})
		.concat(newapprovedORrecevedinvoices)
		.map((i) => {

			const newreceivedtiemsdiffref =
				i.invoice_items.filter((ii) => ii.paymentref && ii.paymentref != i.invoice_client_reference())

			if(newreceivedtiemsdiffref.length){

				const paymentrefIndex =
					R.groupBy(
						R.prop('paymentref')
						,i.invoice_items
					)

				Object.keys(paymentrefIndex).length == 1
				? i.invoice_client_reference(R.last(i.invoice_items)['paymentref'] || '')
				: R.mapObjIndexed(
					(o, k) => {
						const newuuid = uuid.v4()
						newpaymentrefenceinvoices.push(
							R.merge(
								R.last(model.data.cloneInvoice([i]))
								,{
									invoice_id: m.prop(newuuid)
									,invoice_no: m.prop(i.invoice_no() + '-ST' + (i.invoice_items.length-o.length) + '' + o.length)
									,invoice_client_reference: m.prop(k || '')
									,invoice_items:
										R.unnest(
											o.map((item) =>
												i.invoice_items.splice(
													i.invoice_items
													.findIndex((bii) =>
														item.invoice_items_id
														== bii.invoice_items_id
													)
													,1
												)
											)
										)
										.map((ii) => R.merge(ii, {invoice_id: newuuid}))
								}
							)
						)
					}
					,paymentrefIndex
				)
			}

			return i
		})
		.concat(newpaymentrefenceinvoices)
		.map((i) => {
			i.invoice_type(
				Number(
					i.invoice_amount() * i.invoice_rate()
					+ i.invoice_service_amount() * i.invoice_service_rate()
					+ i.invoice_depreciation_amount() * i.invoice_depreciation_rate()
					+ i.invoice_appreciation_amount() * i.invoice_appreciation_rate()
					+ i.invoice_damages_amount() * i.invoice_damages_rate()
				) > 0
					? i.invoice_received()
						? 'Receipted Revenue'
						: 'Accrued Revenue'
					: i.invoice_received()
						? 'Receipted Expenditure'
						: 'Accrued Expenditure'
			)

			if(i.invoice_items.length){

				const newamount =
					R.sum(
						i.invoice_items.map((ii) =>
							i.invoice_id() == ii.invoice_id
							? ii.invoice_items_received_amount || ii.invoice_items_amount
							: 0
						)
					)

				i.invoice_amount(newamount)
				i.invoice_service_amount(newamount)
				i.invoice_depreciation_amount(newamount)
				i.invoice_appreciation_amount(newamount)
				i.invoice_damages_amount(newamount)

			}
			else if (i.recqty) {

				i.invoice_amount(i.recqty)
				i.invoice_service_amount(i.recqty)
				i.invoice_depreciation_amount(i.recqty)
				i.invoice_appreciation_amount(i.recqty)
				i.invoice_damages_amount(i.recqty)

			}

			return i
		})

	}

	function sendNewInvites(){

		const inviteIndex =
			R.indexBy(
				(t) =>
					model.baseProjectDetailsHash()[t.project_extras_id()]
					? model.baseProjectDetailsHash()[t.project_extras_id()].invite_email + t.invite_email
					: null
				,(model.form().project.project_extras || [])
			)

		const newInvites =
			R.uniqBy(
				R.prop('invite_email')
				,(model.form().project.project_extras || [])
				.map((t) => {
					if(
						t.invite_email
						&& inviteIndex[t.invite_email+t.invite_email]
					){
						const inviteExists = inviteIndex[t.invite_email+t.invite_email]
						t.user_id(inviteExists.invite_id())
						t.invite_id(inviteExists.invite_id())
						t.project_extras_custom(true)
					}
					return t
				})
				.filter((t) =>
					t.invite_email && t.created
					&& model.baseProjectDetailsHash()[t.project_extras_id()]
					&& model.baseProjectDetailsHash()[t.project_extras_id()].invite_email != t.invite_email
					&& !inviteIndex[t.invite_email+t.invite_email]
				)
			)

		return Promise.all(
			newInvites.map((i) => {
				const newid = uuid.v4()
				return model.data.api.invites.post.one(
					{
						invite_id: m.prop(newid)
						,role_id: m.prop(newid)
						,invite_email: m.prop(i.invite_email)
						,invite_creation_time: m.prop(new Date().getTime())
						// todo-james should we just hardcode this to ['Worker']  ?
						,invite_groups: m.prop(
							model.data.groups().filter((a) => a.group_name == 'Worker')
							.map(R.omit('users'))
						)
						,organization_id: m.prop(model.data.initiateContractFetch().organization_id)
					}
				)
			})
		)
		.then((res) => {

			res.forEach((i) =>
				model.form().project.project_extras
				.filter((t) => t.invite_email == i.invite_email())
				.forEach((t) => {
					t.user_id(i.invite_id())
					t.invite_id(i.invite_id())
					t.project_extras_custom(true)
				})
			)
			model.newUser(false)
			return true
		})
	}

	function sendProjects(projectPatch){

		model.metaDataLoading(true)

		if( model.form().project.disciplines ){

			model.form().project.disciplines
				= model.form().project.disciplines.filter((d) => d.discipline_name())

			const { teamAmount } = findidealUOM( model.form().project.disciplines )
			model.form().project.disciplines.forEach((d) => {
				delete d.created
				if( !d.organizations_disciplines_nominal_rate() ){
					d.organizations_disciplines_nominal_rate(
						(teamAmount[d.discipline_order()] || teamAmount['cons'])
						.find((dt) => dt.discipline_name() == d.discipline_name())
						.uomrate
					)
				}
			})
		}

		const newinvoicelist = splitInvoices()
		const newResource =
			!model.resources().length
			&& model.firstproject()
			&& model.editResourcePermissions()

		const firstproject = model.firstproject()
		const templateClone =
			model.vm().project_template_id
			&& model.vm().project_template_id()
			&& model.vm().organization_id() != model.data.initiateContractFetch().organization_id

		model.data.currentRoute('Nothing')

		const projectlist =
			projectPatch
			|| [
				R.merge(
					model.form().project
					,{project_extras: []}
				)
			]

		;(model.form().project.project_extras || [])
		.forEach((t) => {
			const founduser =
				exposer(t.invite_email)
				&& model.users().find((u) =>
					exposer(u.user_email)
					&& t.invite_email == exposer(u.user_email)
				)

			if ( founduser ){
				t.user_id(founduser.user_id())
				t.invite_email = null
				t.created = true
			}
		})

		const params = {
			projects: projectlist
			,schedule_id: model.schedule_id()
			,completedProjects: model.completedProjects()
		}

		const geop = projectPatch
			? null
			: model.process_geo(projectlist[0].project_suburb())

		const geolist = geop
			? [geop]
			: []

		return sendContractItems()
		.then(() => {
 			return Promise.all([
				(
					geolist.length
					? model.api.geographies.patch.many(geolist)
					: model.api.geographies.fetch.all()
				)
					.then( model.geographies )

				,sendInvoices()

				,(
					!identical(
						model.extraContractObligationData()
						,model.clonedContracts()
					)
						? Promise.all(
							R.pipe(
								R.toPairs
								,R.unnest
								,R.filter((pa) => typeof pa == 'object')
								,R.map(
									(a) =>
										model.api.allocations.patch(
											[a]
											, a.schedule_version_id()
											, a.project_id()
											, 'disableList'
										)
								)
							)(model.contractPatchIndex())
						)
						.then(() => resetView([]))
						: Promise.resolve([])
				)
					.then(function(){
						return Promise.all([
							deleteDiscipline(true)
							,deletetInvoices()
						])
						.then(function(){
							model.deleteDisciplines([])
							return model.api.projects.patch(params)
							.then(function(res){
								return Promise.all([
									deleteProjectExtra(true)
									,deleteProjectContacts()
									,sendNewInvites()
								])
								.then(function(){

									const newProject = R.last(res)

									!model.form().project.project_extras
									? []
									: newProject.disciplines.filter((d) => {
										const od =
											model.form().project.disciplines
											.find((discipline) => discipline.discipline_id() == d.discipline_id()) || {}

										return !model.form().project.project_extras.find((pe) =>
											exposer(pe.organizations_disciplines_id)
											== d.organizations_disciplines_id()
										)
										&& !model.deleteProjectExtras().find((pe) =>
											model.baseProjectDetailsHash()[pe]
											&& exposer(model.baseProjectDetailsHash()[pe].organizations_disciplines_id)
											== d.organizations_disciplines_id()
										)
										&& !od.taskcount
										&& !od.newdiscipline
										&& !d.taskcount
									})
									.forEach((d) => {
										const newD = model.resetPEForm()
										newD.created = true
										newD.project_id(d.project_id())
										newD.discipline_id(d.discipline_id())
										newD.project_extras_planned_amount(d.discipline_planned())
										newD.project_extras_custom(false)
										newD.project_extras_actual_amount(d.discipline_completed())
										newD.project_extras_type(d.discipline_name())
										newD.project_extras_description(
											d.discipline_name()
											+ (
												d.discipline_description()
												? ' - ' + d.discipline_description()
												: ''
											)
										)
										newD.project_extras_address(newProject.project_suburb())
										newD.project_extras_priority(newProject.project_extras.length + 1)
										newD.organizations_disciplines_id(d.organizations_disciplines_id())
										newD.newtask = 'New Task'
										newD.users =
											model.form().project.disciplines
											.find((od) => od.discipline_name() == d.discipline_name())
											.users || []

										newD.user_id( (R.last(newD.users()) || nullUser).user_id() )

										model.form().project.project_extras.unshift(newD)

										const splitasks =
											!newD.users().length
											? []
											: [newD].map((t) => {
												let i = 0
												t.project_extras_actual_amount(
													Number(Number(t.project_extras_actual_amount()/t.users().length).toFixed(3)))
												t.project_extras_planned_amount(
													Number(Number(t.project_extras_planned_amount()/t.users().length).toFixed(3)))

												model.pe_vm(t)

												return R.unfold(
													(n) => {
														const tuser = n.users()[i]
														const vadd = model.cloningProjectE(false, true)

														if( tuser) { vadd.user_id(tuser.user_id()) }

														vadd.project_extras_id(uuid.v4())
														vadd.allocation_id(null)
														vadd.invite_id = null
														vadd.invite_email = null
														vadd.created = true

														model.userSelectionIndex()[d.key]

														i = i + 1

														return i == n.users().length
															? false
															: [ vadd, n ]
													}
													, t
												)
											})

										model.form().project.project_extras =
											model.form().project.project_extras.concat(R.unnest(splitasks))

									})

									model.deleteProjectContacts([])
									model.deleteProjectExtras([])
									if(model.cloning()){
										model.form().project.project_extras
										.forEach((t) => {

											const relatedd =
												newProject.disciplines
												.find((d) =>
													d.discipline_name()
													+ ' - '
													+ d.discipline_description()
													== t.relatedDiscipline
												)

											t.project_id(newProject.project_id())

											t.project_extras_actual_amount(0)
											t.project_extras_date_completed(null)
											t.project_extras_notes('')

											relatedd
												? t.discipline_id(relatedd.discipline_id())
												: t.discipline_id(null)

											relatedd
												? t.relatedDiscipline =
													relatedd.discipline_name()
													+ (
														relatedd.discipline_description()
														? ' - ' + relatedd.discipline_description()
														: ''
													)
												: t.relatedDiscipline = ''

										})
										model.cloning(false)
									}

									const projectExtraArray =
										R.splitEvery(
											100
											,!model.form().project.project_extras
											|| !model.form().project.project_extras.length
												? []
												: model.form().project.project_extras
												.filter(R.prop('created'))
												.map((t) => {
													const dprops =
														t.project_extras_id()
														? identical(
															t
															,model.baseProjectDetailsHash()[t.project_extras_id()]
															,[]
															,'returnKeys'
														)
														: []

													return R.omit(
														['newtask']
														, R.pick(
															R.concat(
																['project_extras_id'
																,'project_id'
																,'orphanedFiles'
																,'invite_id'
																]
																,dprops
																.some((a) =>
																	a == 'user_id'
																	|| a == 'discipline_id'
																	|| a == 'organizations_disciplines_id'
																	|| a == 'project_extras_planned_amount'
																	|| a == 'project_extras_actual_amount'
																	|| a == 'project_extras_date_completed'
																	|| a == 'project_extras_date_invalid'
																)
																? [
																	'user_id'
																	,'discipline_id'
																	,'organizations_disciplines_id'
																	,'project_extras_planned_amount'
																	,'project_extras_actual_amount'
																	,'project_extras_date_completed'
																	,'project_extras_date_invalid'
																	,'allocation_id'
																].concat(dprops)
																: dprops
															)
															,t
														)
													)
												})
										)

									return (
										!projectPatch
										&& projectExtraArray.length
										? Promise.all(
											projectExtraArray
											.map((pArray) =>
												model.api.project_extras.patch.many(pArray)
											)
										)
										.then(R.last)
										: res
									)
								})
								.then(function(pres){
									return model.deleteDisciplineC({makeAPICalls: true })
									.then(function(/*res*/){
										model.deleteDisciplinesCrews([])
										model.deleteDisciplinesContractors([])
										model.data.api.invoices.patch.many(newinvoicelist, true)
										return pres
									})
								})
								.then(function(res){
									return model.data.fetchProjectsBySchedule(
										{
											project_id: R.last(res).project_id()
											,schedule_id: R.last(res).schedule_id()
										}
									)
								})
								.then((res) => resetView(res, {forcereq:true}))
								.then(() => {

									model.viewType(
										model.viewType() == InitialProject
										? EditProject
										: model.viewType()
									)

									return Promise.resolve(
										!newResource
										? true
										: createIdealResource(model.form().project.disciplines)
									)
										.then(function(){

											if(firstproject || templateClone){

												return model.api.projects.patch({
													projects: [model.form().project]
													,schedule_id: model.schedule_id()
													,completedProjects: model.completedProjects()
												})
												.then((res) => resetView(res, {forcereq:true}))
												.then(() => {
													if( firstproject && !model.data.progress()[model.data.schedule().schedule_id] ){
														return model.data.api.schedules.patch([
															R.merge(
																model.data.schedule()
																,{
																	schedule_parent: null
																	,schedule_versions: [
																		{
																			schedule_priority: model.data.schedule().schedule_versions[0].schedule_priority
																			,schedules_parameters_id: model.data.schedule().schedule_versions[0].schedules_parameters_id
																			,schedule_version_old_id: model.data.schedule().schedule_versions[0].schedule_version_id
																		}
																	]
																}
															)
														])
													}
													else { return true }
												})
											}
											else { return true }
										})

								})
							})
						})
					})
			])
		})
		.then(function(){
			fetchOrganizationsDisciplines()
			fetchAllocations()
			model.data.currentRoute('projects')
			model.metaDataLoading(false)
			model.projectOptions(false)
		})
		.then(m.redraw)
	}

    function deleteProject(){
        return Promise.all(
			model.checkMultipleProjects().map((p) =>
				model.api.projects.remove.one(p.project_id())
			)
		)
			.then(function(){
				model.checkMultipleProjects().forEach((p) =>
					model.projects().splice(
						model.projects().findIndex((project) =>
							project.project_id() == p.project_id()
						)
						, 1
        			)
				)
				model.vm({})
				model.checkMultipleProjects([])
				model.projectOptions(false)
			})
			.then(m.redraw)
    }


	function deleteDiscipline(makeAPICalls){
		return makeAPICalls
			? Promise.all(
				model.deleteDisciplines().map((t) => model.api.disciplines.remove.one(t))
			)
			: model.checkMultiple().data.map((discipline) => {
				!discipline.discipline_id()
					? null
					: model.deleteDisciplines().push(discipline.discipline_id())
				return true
			})
			&& removeDisciplineForm()
			&& model.masterLIST(true)
	}

	function removeDisciplineForm(){
		model.form().project.disciplines =
			R.differenceWith(
				(a, b) =>
					a.discipline_name() == b.discipline_name()
					&& a.discipline_id() == b.discipline_id()
					&& a.key == b.key
				,model.form().project.disciplines
				,model.checkMultiple().data
			)
		model.checkMultiple().data = []
		model.errors({})
		model.discipline_vm({})
		return true
	}


	function deleteProjectExtra(makeAPICalls){
		return makeAPICalls
			? Promise.all(
				model.deleteProjectExtras().length
				? [model.api.project_extras.remove.many(model.deleteProjectExtras())]
				: []
			)
			: model.checkMultipleProjectExtras()
			.data.map((pExtra) => {
				!pExtra.project_extras_id()
					? null
					: model.deleteProjectExtras()
					.push(pExtra.project_extras_id())
				return true
			})
			&& removeExtraForm()
			&& model.masterPELIST(true)
	}


	function removeExtraForm(){
		model.spreading(false)
		model.disciplineSpread([])
		model.form().project.project_extras =
			R.differenceWith(
				(a, b) =>
					a.project_extras_type() == b.project_extras_type()
					&& a.project_extras_id() == b.project_extras_id()
					&& a.key == b.key
				,model.form().project.project_extras
				,model.checkMultipleProjectExtras().data
			)
		model.checkMultipleProjectExtras().data = []
		model.errors({})
		model.pe_vm({})
		return true
	}

    function fetchGeographies(){
		return model.data.fetchGeographies()
        	.then( model.geographies )
    }

	function reOrganizeProjects(){

		return [
			elements.list([

				m('button.btn.btn-secondary'
					,{
						disabled: !model.projects().length
							|| !model.projectSortRequired()
						,style: {
							backgroundColor: 'transparent'
							,border: 'solid 1px #3380c2'
							,position: 'relative'
							,top: '-0.1em'
							,height: '2.9em'
							,width: '5em'
							,color: '#434aa3'
							,textDecoration: 'underline'
						}
						,onclick: () => {
							model.vm({})
							model.checkMultipleProjects([])
							sendProjects(
								model.projectsSortedResult()
								.map((p, pindex) => {
									p.project_priority(pindex + 1)
									return R.merge( p ,{disciplines: []})
								})
							)
						}
					}
					, "Adjust"
				)
				,m(`label`, ' Project Priorities')
			])
			,m('br')
			,model.projectSortRequired()
				? m('label', `This will change the priorities of all projects to match the chronological order of their construction end date`)
				: m('label', `Project priorities match the chronological order of their construction end date, no changes required`)

			,m('br')
			,model.projectsSortedResult()
			.map((s, sIndex) =>
				m('p.pv1.ma0'
					,(
						model.projectSortRequired()
							? s.project_priority()
								+ (
									sIndex + 1 == s.project_priority()
									? ' ⇋ '
									: sIndex + 1 > s.project_priority()
									? ' ↓ '
									: ' ↑ '
								)

							: ''
					)
					+ ( sIndex + 1 )
					+ '. ' + s.project_name()
				)
			)
		]
	}

	function showComments(project){
		const commentArray =
			!project.project_completion_comments()
			? []
			: project.project_completion_comments().split("\n")

		const pencilEdit =
			Pencil(
				() => commentArray.map(s => m('p.pv1.ma0.f4',s))
				,() =>
					elements.textArea(
						(v) => {
							if(v || v == ''){
								project.project_completion_comments(v)
								model.notesexpanded[project.project_id()] = true
								project.created = true
							}
							return project.project_completion_comments()
						}
						,{
							placeholder: "Any types of communications or status"
							, disabled: !model.editPermissions() || model.metaDataLoading()
							, style: {
								"padding-block-start": "0.5em"
							}
							,onblur:
								() => {
									return project.created
									? sendProjects([
										R.merge(
											project
											, {
												disciplines: []
												,project_extras: []
												,project_contacts: []
												,project_documents: m.prop([])
											}
										)
									])
									: null
								}
						}
					)
			)

		return elements.details(
			!commentArray.length ? '' : ' ... '
			,pencilEdit
			,{
				disabled: false
				,onclick: () => model.notesexpanded[project.project_id()] = true
				,open:
					model.notesexpanded[project.project_id()]
					|| !commentArray.length
					|| model.vm().project_id && model.vm().project_id() == project.project_id()
			}
		)
	}


	function sendOrganizationsDisciplines(orgDList){
		return model.api.organizations_disciplines.patch(orgDList)
			.then(fetchOrganizationsDisciplines)
	}


	function bulkDisciplineAddition(){

		var orgDiscipline = {
			organizations_disciplines_id: model.discipline_vm().organizations_disciplines_id()
			,organizations_disciplines_name: model.discipline_vm().discipline_name()
			,organizations_disciplines_description: ""
			,organizations_disciplines_nominal_rate: model.discipline_vm().discipline_planned()
			,organizations_disciplines_uom: model.discipline_vm().discipline_uom()
		}
		return (
			model.discipline_vm().organizations_disciplines_id()
				? Promise.resolve([])
				: sendOrganizationsDisciplines([orgDiscipline])
		)
		.then(function(){

			var newOrganizationDiscipline =
				model.organizations_disciplines().find((od) =>
					od.organizations_disciplines_name
					== model.discipline_vm().discipline_name()
				)

			model.discipline_vm().organizations_disciplines_id(
				newOrganizationDiscipline.organizations_disciplines_id
			)
			model.discipline_vm().discipline_uom(
				newOrganizationDiscipline.organizations_disciplines_uom
			)
			model.discipline_vm().discipline_description(
				newOrganizationDiscipline.organizations_disciplines_description
			)

			model.checkMultipleProjects()
				.forEach((p) => {

					var existingD =
						p.disciplines.find((discipline) =>
							discipline.organizations_disciplines_id()
							== model.discipline_vm().organizations_disciplines_id()
						)

					var newD = existingD
						? R.merge(
							existingD
							,R.omit(
								[
									'project_id'
									,'contractrecognitionid'
									,'organizations_disciplines_id'
									,'discipline_custom'
									,'discipline_completed'
									,'discipline_percent'
									,'discipline_id'
								]
								,model.cloningProjectD(false, true)
							)
						)
						: model.discipline_vm()

					existingD
						? p.disciplines.splice(
							p.disciplines.findIndex((d) =>
								d.organizations_disciplines_id()
								== model.discipline_vm().organizations_disciplines_id()
							)
							,1
						)
						: null

					p.disciplines.push(newD)
				})

			return sendProjects(model.checkMultipleProjects())
			.then(function(){
				var updatedProjects =
					model.checkMultipleProjects()
					.map((p) =>
						model.projects().find((project) =>
							project.project_id()
							== p.project_id()
						)
					)
				model.checkMultipleProjects(updatedProjects)
				model.projectOptions(false)
				// clear discipline container and remove pane
				model.discipline_vm({})
				model.checkMultiple().data = []
				model.vm(
					model.checkMultipleProjects()
					.find((p) =>
						model.vm().project_id()
						== p.project_id()
					)
				)

				return true
			})
		})
	}


	function bulkDisciplineDeletion(){
		model.checkMultipleProjects().map((p) =>
			p.disciplines.filter((d) =>
				d.organizations_disciplines_id()
				== model.discipline_vm().organizations_disciplines_id()
			)
			.forEach((t) => {
				model.deleteDisciplines().push(t.discipline_id())
			})
		)
		return deleteDiscipline(true)
			.then(function(){
				model.deleteDisciplines([])
				model.checkMultipleProjects().forEach((p) => {
					var dIndex =
						p.disciplines.findIndex((d) =>
							 d.organizations_disciplines_id()
							 == model.discipline_vm().organizations_disciplines_id()
						)

					dIndex > -1
						? p.disciplines.splice(
							dIndex
							,1
						)
						: null
				})
				model.discipline_vm({})
				model.checkMultiple().data = []
				return model.projectOptions(false)
			})
	}

	function sendResources(r){
		return model.api.contractors.patch.one(r)
		.then(function(){
			return model.data.fetchResource({
				props: {
					resources: [
						'contractor_id'
						,'contractor_name'
						,'user_id'
					]
					,teams: [
						'crew_id'
						,'contractor_id'
						,'crew_name'
						,'user_id'
					]
					,rates: [
						'crew_id'
						,'crews_discipline_rates_id'
						,'organizations_disciplines_id'
					]
				}
				,organizations_disciplines: model.vm().disciplines.map((a) => a.organizations_disciplines_id()).filter((a) => a)
			})
			.then(function(res){
				model.resources(res)
				model.resources_available()
				model.savingProp(false)
				return res
			})
		})
	}

	model.data.currentRoute('Nothing')
	prop.merge(
		[
			model.data.initiateContractFetch
		]
	)
	.map(function([i]){ //eslint-disable-line no-unused-vars

		const schange = i.schange || !model.data.routeInitialized()['projects']
		const ochange = i.ochange || !model.data.routeInitialized()['projects']


		if ( ochange || schange){
			model.vm({})
			model.checkMultipleProjects([])
			model.projects([])
			model.deleteDisciplines([])
			model.deleteDisciplinesContractors([])
			model.deleteDisciplinesCrews([])
			model.deleteProjectExtras([])
			model.deleteStateProp(false)
		}

		model.loading(true)
		model.metaDataLoading(true)

		return Promise.all([
			!ochange ? [] : fetchOrganizationsDisciplines()
			,!ochange ? [] : fetchGeographies()
			,!schange ? [] : fetchProjects({depth:1})
			,!schange ? [] :model.data.fetchProjectsBySchedule({
				schedule_id: model.schedule_id()
				,completedProjects: model.completedProjects() == 'inprogress'
					? 'completed'
					: 'inprogress'
				,depth: 1
				,props: {
					projects: [
						'project_name'
						,'project_id'
					]
				}
			})
				.then( model.inverseProjectsList )
			,!ochange
				? []
				: model.data.fetchUsers()
					.then(
						R.concat([
							{
								user_id: m.prop(null)
								, user_username: m.prop('Unassigned')
								, usersFullName: 'Unassigned'
							}
						])
					)
					.then(model.users)
		])
			.then(function(){

				model.viewType(
					model.firstproject()
					&& model.data.features().quickStartProject
					? InitialProject
					: ListProject
				)
				model.metaDataLoading(true)
				model.loading(false)
				model.usersIndex(
					R.indexBy(
						(u) => u.user_id()
						,model.users()
					)
				)
				model.data.routeids({
					routeids: model.projects().map(R.prop('contractrecognitionid'))
					,getledgers: false
					,ledgerdetails: false
					,getmatdata: false
					,specificproject: model.projects().map(R.prop('contractrecognitionid'))
				})
				return m.redraw()
			})
			.then(function(){
				model.data.currentRoute('projects')
				return Promise.all([
					!schange ? [] : fetchProjects()
				])
				.then(function(){
					fetchProjectTemplates()
					model.metaDataLoading(false)
					i.schange = false
					i.ochange = false
					return true
				})
			})
	})

	const findidealUOM =
		(disciplineList) => {

			model.savingProp(true)
			const refDisciplines =
				disciplineList || model.checkMultiple().data

			const theTime = new Date().getTime() - milliperday
			const theTimeString = moment().format("ll")
			const compromisedTime =
				(new Date(model.form().project.project_dead_date()).getTime() - theTime)/(1000*60*60*24)

			const dtindex = {}
			const dgroups =
				R.groupBy(
					(d) => d.discipline_order()
					,refDisciplines
				)

			R.mapObjIndexed((v, k) =>
				{
					v.length == 1
					? dtindex['cons'] =
						dtindex['cons']
						? dtindex['cons'].concat(v)
						: v
					: dtindex[k] = v
				}
				, dgroups
			)

			const teamAmount =
				R.uniqBy(
					(d) => d.discipline_order()
					,refDisciplines
				)
				.length
				== refDisciplines.length
					? { ['cons']: refDisciplines }
					: dtindex

			const teamMultiple =
				compromisedTime >= 30
				|| Object.keys(dtindex).length > refDisciplines.length*0.5
				? 1
				: compromisedTime >= 10
				|| Object.keys(dtindex).length > refDisciplines.length*0.35
				? 2
				: 3

			R.mapObjIndexed
				((t) => {
					return t.forEach((tr) => {
						return tr.uomrate =
							Number(Number(
								tr.discipline_work() || tr.discipline_planned() <= 50
								? ( Math.random() + 1 ) * 3
								: tr.discipline_work() || tr.discipline_planned() >= 100
								&& tr.discipline_work() || tr.discipline_planned() <= 150
								? ( Math.random() + 1 ) * 8
								: tr.discipline_work() || tr.discipline_planned() >= 150
								&& tr.discipline_work() || tr.discipline_planned() <= 1000
								? ( Math.random() + 1 ) * 60
								: ( Math.random() + 1 ) * 500
							).toFixed(2))
					})
				}
				, teamAmount
			)

			return {
				teamMultiple
				,teamAmount
				,refDisciplines
				,theTime
				,theTimeString
			}
		}

	const createIdealResource =
		(disciplineList) => {

			const {
				teamMultiple
				,teamAmount
				,refDisciplines
				,theTime
				,theTimeString
			} = findidealUOM(disciplineList)

			let teamcount = teamMultiple + 2
			const newResource =
				{
					organization_id: m.prop(model.data.auth.stream().organization_id)
					,contractor_id: m.prop("")
					,contractor_name: m.prop(model.idealResourceName() || "New Resource " + theTimeString)
					,contractor_yard: m.prop(
						model.form().project.project_material_suburb()
					)
					,contractor_comfortable_work_zone: m.prop(
						model.form().project.project_site_type()
					)
					,contractor_first_onsite_start_date: m.prop(theTime)
					,contractor_total_rating: m.prop(0)
					,contractor_quality_rating: m.prop(0.8)
					,contractor_common: m.prop("Not utilized across previous disciplines")
					,contractor_utilized: m.prop("")
					,user_id: m.prop(null)
					,contractor_mixed_crews: m.prop(0)
					,contract_id: model.form().project.contract_id()
					,crews:
						R.unnest(
							Object.keys(teamAmount)
							.map((t) =>
								R.times(
									() => {
										teamcount = teamcount - 1
										return {
											contractor_id: m.prop("")
											,crew_id:  m.prop(null)
											,crew_invalid:  m.prop("")
											,crew_excess:  m.prop("")
											,crew_utilized:  m.prop("")
											,crew_name: m.prop("New Team " + ( teamcount - 1 ) + " " + theTimeString)
											,crew_rating: m.prop(0)
											,user_id: m.prop(null)
											,crew_common:  m.prop("Not utilized across previous disciplines")
											,crew_work_completed: m.prop(0)
											,crews_discipline_rates:
												teamAmount[t].map((tr) => {

													const trate = tr.uomrate

													return {
														organizations_disciplines_id: m.prop(tr.organizations_disciplines_id())
														,crews_discipline_rates_id : m.prop("")
														,crew_id : m.prop("")
														,createcontracts: m.prop(true)
														,organizations_disciplines_nominal_rate: m.prop()
														,crews_discipline_rates_rate: m.prop(Number(trate))
														,crews_discipline_rates_description: m.prop(tr.discipline_description())
														,crews_discipline_rates_name: m.prop(tr.discipline_name())
														,crews_discipline_rates_feedback_rate: m.prop(0)
														,crews_discipline_rates_uom: m.prop(tr.discipline_uom())
														,organizations_disciplines_nominal_expenditure_rate: m.prop(tr.organizations_disciplines_nominal_expenditure_rate())
													}
												})
										}
									}
									,teamMultiple
								)
							)
						)
				}

			model.idealResourceName(newResource.contractor_name())

			return (
				sendResources( newResource )
				.then(function(res){
					const newresource =
						res.find((r) => r.contractor_name && r.contractor_name() == model.idealResourceName())

					model.dc_mapping(newresource, refDisciplines)
					model.idealResourceName('')
					m.redraw()
					return true
				})
			)
		}

	function sendQI({generateOnly}){
		model.reconloading(true)
		model.data.currentRoute("Nothing")
		return model.api.invoices.generateqi({
			generateOnly
			,type: model.qiSelection()
			,project_extras:
				model.qiSelection()
				== 'Quote'
				? model.requestedItems().map(R.pipe(R.prop('project_extras_id'), R.call))
				: []
			,invoices:
				model.qiSelection()
				!= 'Quote'
				? model.requestedItems().map(R.prop('invoice_id'))
				: []
			,chargeDetails: {
				contract_payment_term: model.newqi().contract_payment_term()
				, contract_terms: model.newqi().contract_terms()
			}
			,contract_id: model.vm().contract_id
			,contacts: model.qiContactList()
		})
		.then((res) => {
			model.reconloading(true)
			model.data.currentRoute("projects")
			return res
		})
	}


    return {
		sendProjects
		, fetchGeographies
		, reOrganizeProjects
		, showComments
		, deleteProject
		, deleteDiscipline
		, fetchProjects
		, suburbs: model.suburbs
        , fetchAllocations
		, bulkDisciplineAddition
		, bulkDisciplineDeletion
		, sendOrganizationsDisciplines
		, deleteProjectExtra
		, sendResources
		, createIdealResource
		, sendNewInvites
		, deletetInvoices
		, sendQI
    }
}

/*
	Model: Data storage and functions that interact with that data.

	The vm represents the view model, any thing to do with the state of the UI
	that doesn't map to a database record is stored on the view model.

	It could be whether or not a button is disabled due to a background process.
	Or it could be storing how many items are selected in order to render a tally.

*/
function Model(data){

	const viewType = data.scoped(() => null)
	const inverseProjectsList = data.scoped([])
	const modules = m.prop([])
	const users = data.scoped([])
	const usersIndex = data.scoped({})
	const userSelectionIndex = data.scoped({})
	const schedule_id = data.schedule_id
	const loading = m.prop(true)
	const projects =  data.scoped([])
	const geographies = m.prop([])
	const resources = data.scoped([])
	const allocations = data.scoped([])
	const suburbs = data.scoped([])
	const rList = data.scoped([])
	const deleteDisciplines = m.prop([])
	const deleteDisciplinesContractors = m.prop([])
	const deleteDisciplinesCrews = m.prop([])
	const deleteProjectExtras = m.prop([])
	const deleteStateProp = m.prop(false)
	const projectOptions = m.prop(false)
	const onProjectList = m.prop(true)
	const checkMultipleProjects = m.prop([])
	const errors = data.scoped({})
	const projectPercentComplete = m.prop(0)
	const projectDisciplines = data.scoped([])
	const projectExtrasIndex = data.scoped({})
	const contractPatchIndex = data.scoped({})
	const completedProjects = data.scoped('inprogress')
	const filteredTaskOptions = data.scoped([])
	const filteredDisciplineOptions = data.scoped([])
	const metaDataLoading = data.scoped(true)
	const seperateTasks = data.scoped(false)
	const deleteProjectContacts = data.scoped([])
	const baseProjectDetailsHash = data.scoped({})
	const reconArray = data.scoped([])
	const showUpload = data.scoped(false)
	const projectTemplates = data.scoped([])
	const newUser = data.scoped(false)
	const deleteInvoiceItemsHash = data.scoped({})
	const deleteInvoiceHash = data.scoped({})
	const newContracts = data.scoped([])
	const newInvoices = data.scoped([])
	const selectedInvoices = data.scoped([])
	const notesexpanded = {}

	const oauths = [{name: 'Xero'}]

	const allResourceTypes =
		resources.map((resList) =>
			[].concat(
				resList
				,R.unnest(
					resList.map((c) => c.crews)
				)
			)
		)

	const projectSortRequired =
		projects.map((projects) =>
			R.sortBy(
				byProp("project_dead_date")
				,projects
			)
			.some((p, pIndex) =>
				p.project_priority()
				!= pIndex + 1
			)
		)

	const projectsSortedResult =
		projectSortRequired
		.map((psr) =>
			R.sortBy(
				psr
					? byProp("project_dead_date")
					: byProp("project_priority")
				,projects()
			)
		)

	const resRatesAvailable =
		resources.map((res) =>
			R.indexBy(
				R.prop('contractor_id')
				,res.map((r) => {
					return {
						contractor_id: r.contractor_id()
						,rates: R.unnest(
							r.crews.map((c) =>
								c.crews_discipline_rates
							)
						)
						,ratesIByOrgD:
							R.groupBy(
								(a) => a.organizations_disciplines_id()
								,R.unnest(
									r.crews.map((c) =>
										c.crews_discipline_rates
									)
								)
							)
					}
				})
			)
		)

	const missingPermissions =
		data.initiateContractFetch.map(() =>
			humanList(
				[
					'Update'
					,'Resource'
					,'Contract'
					,'Invoice'
					,'Contract Obligation'
				]
					.map((p)=>
						data['read'+p.replace(' ', '')+'Permissions']()
							? ''
							: p
					)
					.filter((p) => p)
			)
		)


	const checkInvoiceDeletion =
		({o, v, e}) => {
			return m(
				(
					deleteInvoiceHash()[o.invoice_id]
					|| deleteInvoiceItemsHash()[o.invoice_items_id]
					|| deleteProjectExtras().find((t) =>
						t == o.project_extras_id
					)
					|| o.subitems.invoice_items
					&& o.subitems.invoice_items.length
					&& o.subitems.invoice_items.every((ii) =>
						deleteProjectExtras().find((t) =>
							t == ii.project_extras_id
						)
					)
					? `strike` + (e || '')
					: (e || '')
				)
				, v
			)
		}

	const disciplineTabEditArray = ['Disciplines', 'Contract Obligations', 'Tasks', 'Reconciliation', 'Materials']
	const disciplineTabReadArray = ['Disciplines', 'Contract Obligations', 'Tasks', 'Reconciliation', 'Materials']
	const ActiveContractsFeature = function(){
		// (modules().find((module) => module.modules_name() == 'Contract Obligation Tools')).modules_active()
		return true
	}
	const ActiveDetailsFeature = function(){
		// (modules().find((module) => module.modules_name() == 'Project Tasks')).modules_active()
		return true
	}

	const projectSummary =
		allocations.map((listAllocations) =>
			R.pipe(
				R.pluck('allocations_completion_comments')
				,R.when(
					(a) => !R.isEmpty(a)
					,R.map(R.call)
				)
				,R.filter( R.identity )
			)(listAllocations)
		)

	const removeContactForm =
		(pc) => {
			pc.project_contacts_id()
			? deleteProjectContacts().push(pc.project_contacts_id())
			: null
			form().project.project_contacts =
				R.differenceWith(
					(a, b) =>
						a.project_contacts_id() == b.project_contacts_id()
						&& a.key == b.key
					,form().project.project_contacts
					,[pc]
				)
		}

	const lastProjectDate =
		allocations.map((alist) =>
			alist.length > 0
				? moment(
					alist.reverse()[0]
					.allocations_allocation_date()
				).format("ll")
				: ''
		)

	const disciplineLastDateIndex =
		allocations.map((alist) =>
			R.groupBy(
				byProp('allocations_allocation_date')
				,alist.slice().reverse()
			)
		)

	const allocationIndex =
		allocations.map((aList) =>
			R.indexBy(
				(a) => a.allocation_id()
				,aList
			)
		)

	const vm = data.scoped({})

	function uploadmetadata({uploadbox}){
		return R.pickBy(
			(a) => a
			,{
				organization_id: data.initiateContractFetch().organization_id
				, user_id: data.auth.stream().user_id
				, project_id: uploadbox == 'taskfiles' ? null : form().project.project_id()
				, project_extras_id: uploadbox == 'taskfiles' ? checkMultipleProjectExtras().data.length && R.last(checkMultipleProjectExtras().data).project_extras_id() : null
			}
		)
	}

	const timesheets = data.scoped([])
	vm.map((p) =>
		p.project_id && p.project_id()
		? data.fetchTimesheetsByProject({project_id: p.project_id()}).then(timesheets)
		: timesheets([])
	)

	const contract = data.scoped({})
	const contractItems = data.scoped([])
	vm.map((p) => {

		selectedInvoices([])
		newInvoices([])
		newContracts([])

		return p.project_id
		&& p.project_id()
		&& exposer(p.contract_id)
		&& data.editInvoicePermissions()
			? data.api.contracts.fetch.all({contracts: [exposer(p.contract_id)], getorganizationoh: true})
				.then(R.pipe(R.filter((a) => a.contract_id == p.contract_id), R.last, R.tap(contract)))
				.then(R.pipe(R.prop('contract_items'), R.unnest))
				.then(contractItems)
			: contractItems([])
	})

	const materialloading = data.scoped(true)
	const materials = data.scoped({actual: [], suggested: []})
	const forceMaterialFetch = data.scoped(false)

	prop.merge(
		[
			vm
			,forceMaterialFetch
		]
	)
	.map(([p, ]) => {
		return Promise.all(
			materialloading(true)
			&& p.project_id && p.project_id()
			? [
				data.fetchSuggestedMaterials({
					projects: [p.project_id()]
					,warehouse_id: null
					,completedProjects: null
				})
				, data.fetchMaterials({
					projects: [p.project_id()]
					,warehouse_id: null
					,completedProjects: null
				})
			]
			: [[], []]
		)
		.then(([suggested, actual]) => {
			materialloading(false)

			const itemsindex =
				R.groupBy(
					R.pipe(R.prop('supplier_items_id'), R.call)
					,actual
				)

			const ditemsindex =
				R.mapObjIndexed(
					R.groupBy((o) => R.prop('discipline_id', o) || o.project_id)
					,itemsindex
				)

			materials({
				actual:
					R.uniqBy(R.pipe(R.prop('supplier_items_id'), R.call), actual)
					.map((i) => {
						const suggestedmaterial = suggested.find((s) => s.supplier_items_id == i.supplier_items_id()) || {requiredqty: 0, currentqty: 0}
						const totalqty = suggestedmaterial['requiredqty'] - suggestedmaterial['currentqty']
						return {
							name: i.distribution_material_name()
							,id: i.supplier_items_id()
							,dispersion: ditemsindex[i.supplier_items_id()]
							,received:
								R.sum(R.unnest(Object.keys(ditemsindex[i.supplier_items_id()])
								.map((k) => ditemsindex[i.supplier_items_id()][k].map((si) => si['order_received']))))
							,allocated:
								R.sum(R.unnest(Object.keys(ditemsindex[i.supplier_items_id()])
								.map((k) => ditemsindex[i.supplier_items_id()][k].map((si) => si['distribution_amount']()))))
							,orders:
								R.uniq(
									R.unnest(Object.keys(ditemsindex[i.supplier_items_id()])
									.map((k) => ditemsindex[i.supplier_items_id()][k].map((si) => si['order_name'])))
									.filter(R.identity)
								)
								.join()
							,required: suggestedmaterial['requiredqty'] - suggestedmaterial['currentqty']
							,supplier_items_id: i.supplier_items_id()
							,value: i.order_items_cost || i.supplier_items_cost * totalqty
						}
					})
					.concat(
						suggested.filter((s) => !itemsindex[s.supplier_items_id])
						.map((s) => ({
							name: R.last(s.fmgroupedbyitem)['distribution_material_name']
							,id: s.supplier_items_id
							,dispersion: {}
							,received: ''
							,allocated: ''
							,orders: ''
							,required: s.requiredqty
							,supplier_items_id: s.supplier_items_id
							,value: s.supplier_items_cost*s.requiredqty
						}))
					)

				,suggested:
					R.indexBy(
						R.prop('supplier_items_id')
						,suggested.map((s) => {
							const dindex = R.indexBy(R.prop('discipline_id'), s.fmgroupedbyitem)
							return R.merge(
								{ditemsindex: R.indexBy(R.prop('discipline_id'), s.fmgroupedbyitem)
								, editquantity: m.prop(Number(Number(s['requiredqty']-s['currentqty']).toFixed(3)))
								, editentity:
									Object.keys(dindex).length > 1
									? m.prop({id: vm().project_id(), name: vm().project_name()})
									: m.prop({id: s.fmgroupedbyitem[0].discipline_id, name: s.fmgroupedbyitem[0].discipline_name})}
								, s
							)
						})
					)
			})

			return materials()
		})
	})

	materials
	.map((materialist) =>
		materialist.actual.map((item) => {
			item.itemdetailarray =
				data.scoped(
					[vm()].concat(vm().disciplines)
					.map((pd) => ({
						id: pd.discipline_id ? pd.discipline_id() : pd.project_id()
						,name: pd.discipline_name ? pd.discipline_name() : pd.project_name()
					}))
					.filter((pd) =>
						item.dispersion[pd.id]
						|| materials().suggested[item.supplier_items_id]
						&& materials().suggested[item.supplier_items_id]['ditemsindex'][pd.id]
					)
				)

			item.itemchoicearray =
				data.scoped(
					(item.itemdetailarray().find((a) => a.id == vm().project_id())
					? [] : [{id: vm().project_id(), name: vm().project_name(), parentproject: true}])
					.concat(item.itemdetailarray())
				)

			return item
		})
	)

	const itemDetails =
		(item) =>
			elements.splitPane(
				m('.mw12'
					,item.itemdetailarray()
					.map((pd) =>
						[
							elements.list([
								pd.name
								,materials().suggested[item.id]
								&& Object.keys(materials().suggested[item.id]['ditemsindex']).length > 1
								? elements.checkbox(
									{
										onchange:
											m.withAttr(
												'checked'
												,() => materials().suggested[item.id].editentity(pd)
											)
										,checked: materials().suggested[item.id].editentity().id == pd.id
										,title: 'Discipline nominated to absorp materials'
									}
								)
								: ''
							])
							,(
								item.dispersion[pd.id]
								? item.dispersion[pd.id]
								: materials().suggested[item.id]
								&& materials().suggested[item.id]['ditemsindex'][pd.id]
								? [{
									order_name: 'requires'
									,distribution_amount: m.prop(0)
									,requiredqty:
										materials().suggested[item.id]
										['ditemsindex'][pd.id]['requiredqty']
									,order_received: 0
								}]
								: [{
									order_name: ''
									,distribution_amount: m.prop(0)
									,required: 0
									,order_received: 0
								}]
							)
							.map((i) =>
								m('li.pa2.fw6' + (i.distribution_ordered && i.distribution_ordered() == "Cancelled" ? '.red.strike' : '.gold' )
									,[i.order_name == 'requires' ? i.requiredqty : i.order_received || 0
									, i.order_name == 'requires' ? 'required' : 'received on order'
									, i.order_name != 'requires' ? i.order_name : ''
									]
									.concat(
										i.distribution_amount() - i.order_received > 0
										? [
											'-'
											, i.distribution_amount() - i.order_received
										]
										: []
										,i.distribution_ordered && i.distribution_ordered() == "Cancelled"
										? 'Cancelled'
										: i.distribution_amount() - i.order_received > 0
										? [
											 'due,'
											, 'ETA'
											, i.order_eta ? new Date(i.order_eta).toLocaleDateString() :  'not confirmed'
										]
										: []
									)
									.join(' ')
								)
							)
						]
					)
				)
				,m('.mw12'
					,(
						materials().suggested[item.id]
						&& materials().suggested[item.id]['mogroupedbyitem'] || []
					)
					.concat(
						materials().suggested[item.id]
						? {
							order_name: ''
							,distribution_amount: ''
							,order_received: ''
							,newOrder: true
						}
						: []
					)
					.map((i) =>
						elements.list([
							m(
								'button.btn.btn-secondary'
							    + css
								.$nest(':hover', `
									opacity: 0.8;
									height: 2.5em;
								`)
								, {
									onclick:
										() => {
											const idref = materials().suggested[item.id].editentity()
											const objtref =
												!materials().suggested[item.id].editentity().parentproject
												? materials().suggested[item.id]['ditemsindex'][idref.id]
												: materials().suggested[item.id]['ditemsindex'][R.last(Object.keys(materials().suggested[item.id]['ditemsindex']))]

											return data.api.materials.patch.many([
												i.newOrder
												? {
													organization_id: m.prop(objtref.organization_id)
													,distribution_id: m.prop(uuid.v4())
													,distribution_entity_name: m.prop(idref.name)
													,distribution_entity_id: m.prop(idref.id)
													,supplier_id: m.prop(objtref.supplier_id)
													,supplier_items_id: m.prop(objtref.supplier_items_id)
													,distribution_material_name: m.prop(objtref.distribution_material_name)
													,distribution_material_description: m.prop(objtref.distribution_material_description)
													,distribution_amount: m.prop(materials().suggested[item.id].editquantity())
													,distribution_batch: m.prop(objtref.distribution_batch)
													,distribution_container_name: m.prop(objtref.distribution_container_name)
													,distribution_container_id: m.prop(objtref.distribution_container_id)
													,distribution_silo: m.prop(objtref.distribution_silo)
													,distribution_ordered: m.prop(objtref.distribution_ordered)
													,distribution_float: m.prop(objtref.distribution_float)
													,distribution_stocked: m.prop(vm().project_name() + " - " + vm().project_material_suburb())
													,supplier_name: m.prop(objtref.supplier_name)
													,supplier_items_cost: m.prop(objtref.supplier_items_cost)
													,supplier_items_batch_cost: m.prop(objtref.supplier_items_batch_cost)
													,autoorder: i.newOrder
												}
												: R.merge(i, {distribution_entity_id: m.prop(idref.id), distribution_entity_name: m.prop(idref.name)})
											])
											.then(function(){
												forceMaterialFetch(true)
												data.routeids(data.routeids())
												materials().suggested[item.id].editentity({id: '', name: ''})
												return true
											})
										}

									,disabled: !materials().suggested[item.id].editentity().id
									,title: ''
									,style: {
										backgroundColor: 'transparent'
										,border: 'solid 1px #3380c2'
										,width: '5.5em'
										,color: '#434aa3'
										,textDecoration: 'underline'
										,padding: '0.3em'
									}
								}
								,i.newOrder ? `New Order` : `Transfer`
							)

							,'x'

							,m(
								NumberInput
								,R.merge(
									data
									,{
										errors
										,errorLabel: 'Material Qty'
									}
								)
								,{
									prop: materials().suggested[item.id]['editquantity']
									,attrs: {
										min: 0
										,step: 0.001
										,style: {
											'max-width': '5em'
										}
									}
								}
							)

							,!i.newOrder
							? ['from'
							, i.distribution_entity_name + ','
							, i.order_received
							, 'stocked'
							]
							.concat(
								i.distribution_amount - i.order_received > 0
								? [i.distribution_amount - i.order_received, 'due,', 'ETA' , i.order_eta || 'not confirmed']
								: []
							)
							.join(' ')
							: ''
						])
					)
				)
			)

	const timesheetsIndex =
		timesheets.map((times) => R.indexBy((t) => t.allocation_id(), times) )
	const totalRecon = data.scoped({materialvalue: 0, expenditurevalue:0, revenuevalue: 0})
	const reconloading = data.scoped(true)
	const reconRender = data.scoped({})
	const invoiceRender = data.scoped([])
	const baseInvoiceRender =
		invoiceRender.map(data.cloneInvoice)

	const baseInvoiceIndex =
		invoiceRender
		.map((a) => ({
			i: R.indexBy(R.prop('invoice_id'), a)
			,ii: R.indexBy(
				R.prop('invoice_items_id')
				,R.unnest(a.map(R.prop('invoice_items')))
			)
		}))

	prop.merge(
		[
			data.calculatedData
			,timesheetsIndex
			,vm
			,metaDataLoading
		]
	)
	.map(([cd, ts, p, mdl]) => {
		if (
			p.project_id && !mdl
			&& Object.keys(cd).length == 2
			&& cd['Overheads0Infinity']
			&& cd['Overheads0Infinity']['possibleContractEntities'].length == p.disciplines.length + 1
		 ){

			deleteInvoiceHash({})
			deleteInvoiceItemsHash({})

			const invoicelist =
				R.unnest(
					Object.keys(cd)
					.map((k) => data.calculatedData()[k].focussedInvoices)
				)

			reconArray(
				[p]
				.concat(p.disciplines.length ? p.disciplines : [])
				.concat(
					invoicelist.filter((i) =>
						i.organizations_disciplines_id
						&& !p.disciplines.find((d) =>
							d.organizations_disciplines_id()
							== i.organizations_disciplines_id
						)
					)
					.map((id) => {
						const ogd =
							organizations_disciplines()
							.find((od) =>
								od.organizations_disciplines_id
								== id.organizations_disciplines_id
							)
						return {
							organizations_disciplines_id: m.prop(ogd.organizations_disciplines_id)
							,discipline_name: m.prop(ogd.organizations_disciplines_name)
							,discipline_description: m.prop(ogd.organizations_disciplines_description)
							,discplineNameDescription:
								ogd.organizations_disciplines_name + " - " + ogd.organizations_disciplines_description
						}
					})
				)
			)

			reconArray().forEach((r) => {
				r.materialvalue = 0
				r.revenuevalue = 0
				r.expenditurevalue = 0
			})


			invoiceRender(invoicelist)

			const materiallist =
				R.unnest(
					Object.keys(cd)
					.map((k) => data.calculatedData()[k].totalmaterialData)
				)

			const [ dconnection, pconnection ] =
					R.partition(
						(i) =>
							i.crew_id
							|| (i.contractuserid && i.invoice_items.length)
							|| i.organizations_disciplines_id
							|| i.crews_discipline_rates_id
						,invoicelist
					)


			const timeInvoiceList =
				dconnection.filter((t) =>
					t.crew_id
					|| t.contractuserid
				)

			const taskInvoiceList =
				dconnection.filter((t) =>
					t.organizations_disciplines_id
					|| t.crews_discipline_rates_id
				)

			const materialitems = materiallist

			const timeitems =
				R.unnest(
					timeInvoiceList.map((i) =>
						i.invoice_items
						.map((ti) => R.mergeAll([i, ti, ts[ti['allocation_id']] ]))
					)
				)

			const taskitems =
				R.unnest(
					taskInvoiceList.map((i) =>
						i.invoice_items
						.map((ti) => R.mergeAll([i, ti]))
					)
				)

			const disciplineitems =
				R.unnest(
					taskInvoiceList.filter((i) =>
						!i.invoice_items.length
					)
				)

			const ogditems =
				R.mapObjIndexed(
					(k, kkey) => {
						const pitem =
							p.project_id() != kkey
							? p.disciplines.find((d) => d.organizations_disciplines_id() == kkey) || p
							: p

						return R.mapObjIndexed(
							(ks) =>
								R.map(
									(i) => {

										const iiamount =
											i.distribution_amount
											|| i.invoice_items_received_amount
											|| i.invoice_items_amount
											|| R.sum(
												i.invoice_items
												.filter((ii) => ii.organizations_disciplines_id == kkey)
												.map((ii) =>
													ii.invoice_items_received_amount
													|| ii.invoice_items_amount
												)
											)

										const value =
											i.distribution_amount
											? i.mvalue
											: i.warehouse_id
											|| i.contractor_id
											|| i.modules_id
											|| i.tools_id
											|| i.contractprojectid
											|| !i.invoice_items.length
											? i.invoice_rate * i.invoice_amount
												+ i.invoice_service_rate * i.invoice_service_amount
												+ i.invoice_depreciation_rate * i.invoice_depreciation_amount
												+ i.invoice_appreciation_rate * i.invoice_appreciation_amount
												+ i.invoice_damages_rate * i.invoice_damages_amount

											: ((i.crew_id || i.contractuserid) && i.invoice_items.length)
											? i.invoice_rate * iiamount
												+ i.invoice_service_rate * iiamount
												+ i.invoice_depreciation_rate * iiamount
												+ i.invoice_appreciation_rate * iiamount
												+ i.invoice_damages_rate * iiamount
											// ORDER IS IMPORTANT HERE AS MANY ITEMS WILL HAVE organizations_disciplines_id
											// ,so it can't be used as recog for how we treat each type of invoice
											: i.invoice_rate * iiamount
												+ i.invoice_service_rate * iiamount
												+ i.invoice_depreciation_rate * iiamount
												+ i.invoice_appreciation_rate * iiamount
												+ i.invoice_damages_rate * iiamount


										i.moneyvalue = value
										i.rate =
											i.distribution_amount
											? i.mvalue/i.distribution_amount
											: i.invoice_rate
											+ i.invoice_service_rate
											+ i.invoice_depreciation_rate
											+ i.invoice_appreciation_rate
											+ i.invoice_damages_rate
										i.qty =
											i.distribution_amount
											? i.distribution_amount
											: i.warehouse_id
											|| i.contractor_id
											|| i.modules_id
											|| i.tools_id
											|| i.contractprojectid
											? i.invoice_amount
											: ((i.crew_id || i.contractuserid) && i.invoice_items.length)
											? i.invoice_amount/R.max(1, i.invoice_items.length)
											: i.crews_discipline_rates_id || i.organizations_disciplines_id
											? i.invoice_amount
											: iiamount

										// WHY DON"T WE USE invoice_items_amount for sors?

										i.recqty =
											i.distribution_amount
											? i.distribution_amount
											: i.warehouse_id
											|| i.contractor_id
											|| i.modules_id
											|| i.tools_id
											|| i.contractprojectid
											? i.invoice_received_date && i.invoice_amount || 0
											: ((i.crew_id || i.contractuserid) && i.invoice_items.length)
											? i.invoice_amount/R.max(1, i.invoice_items.length)
											: i.crews_discipline_rates_id || i.organizations_disciplines_id
											? i.invoice_received_date && i.invoice_amount || 0
											: i.invoice_items_received_amount

										i.distribution_amount
										? pitem.materialvalue = pitem.materialvalue + value
										: value > 0
										? pitem.revenuevalue = pitem.revenuevalue + value
										: pitem.expenditurevalue = pitem.expenditurevalue + value


										i.materialvalue = i.distribution_amount ? value : 0
										i.revenuevalue = value > 0 ? value : 0
										i.expenditurevalue = value < 0 ? value : 0

										return i
										// return i.distribution_amount
										// 	? 'materialitems'
										// 	: value > 0
										// 	? 'revenueitems'
										// 	: 'expenditureitems'
									}
									,ks
								)

							,R.groupBy(
								(a) =>
									a.project_extras_id
									|| a.warehouse_id
									|| a.contractor_id
									|| a.modules_id
									|| a.tools_id
									|| a.project_id
								,k
							)
						)
					}
					,R.groupBy(
						(a) => a.organizations_disciplines_id || a.project_id
						,[].concat(timeitems, taskitems, materialitems, pconnection, disciplineitems)
					)
				)

			totalRecon(
				R.reduce(
					(a, b) => R.mapObjIndexed((objVal, key) => objVal + b[key], a)
					, {
						materialvalue: 0
						,expenditurevalue: 0
						,revenuevalue: 0
					}
					, reconArray()
				)
			)

			reconArray().forEach((pd) => {
				pd.subitems =
					ogditems[
						pd.organizations_disciplines_id
						? pd.organizations_disciplines_id()
						: pd.project_id()
					] || {}

				pd.paymentref =
					R.uniq(
						R.unnest(Object.keys(pd.subitems).map((si) => pd.subitems[si]))
						.filter(R.prop('invoice_client_reference'))
						.map(R.prop('invoice_client_reference'))
					)
					.join(', ')

				pd.receiveddate =
					R.last(
						R.unnest(Object.keys(pd.subitems).map((si) => pd.subitems[si]))
						.filter(R.prop('invoice_received_date'))
						.map(R.prop('invoice_received_date'))
					)
			})

			reconRender(ogditems)
			reconloading(false)
		}
		else { reconArray([p]) }
		return true
	})

	const clonedContracts = m.prop([])
	const extraContractObligationData =
		allocations.map((tlist) =>
			R.tap(
				(cList) =>
					clonedContracts(
						cList.map((contract) => ({
							['allocations_' + contract.midfix + '_id']: contract['allocations_' + contract.midfix + '_id']()
							,project_extras_id: contract.project_extras_id()
							,allocation_id: contract.allocation_id()
							,['allocations_' + contract.midfix + '_description']: contract['allocations_' + contract.midfix + '_description']()
							,['allocations_' + contract.midfix + '_escalated']: contract['allocations_' + contract.midfix + '_escalated']()
							,['allocations_' + contract.midfix + '_clause']: contract['allocations_' + contract.midfix + '_clause']()
							,['allocations_' + contract.midfix + '_amount']: contract['allocations_' + contract.midfix + '_amount']
								? contract['allocations_' + contract.midfix + '_amount']()
								: ''
							,midfix: contract.midfix
							,['allocations_' + contract.midfix + '_approved']: contract['allocations_' + contract.midfix + '_approved']()
							,user_id: contract.user_id()
							,contractDate: contract.contractDate
						}))
					)
				,R.unnest(
					tlist.map((a) =>
						R.unnest([
							a.allocations_eots.map(R.merge({midfix: 'eots', contractDate: a.allocations_allocation_date()}))
							,a.allocations_nods.map(R.merge({midfix: 'nods', contractDate: a.allocations_allocation_date()}))
							,a.allocations_vars.map(R.merge({midfix: 'vars', contractDate: a.allocations_allocation_date()}))
						])
					)
				)
			)
		)




	const placeLong = suburbs.map(R.map(s => s.placeLong ))
	const suburbsInput = data.scoped()

	suburbsInput.map(
		text => data.api.suburbs.fetch.byTextInput(text)
			.then(suburbs)
	)

	const CONSTANTdisciplines = data.scoped([])
	const organizations_disciplines = data.scoped([])
	const CONSTANTProjectExtras = data.scoped([])

	const odinItems =
		prop.merge(
			[
				users
				,allResourceTypes
				,organizations_disciplines
				,contractItems
			]
		)
		.map(([u,a,o,ci]) => {

			 return vm().contract_id
			 ? R.pipe(R.unnest, R.filter(R.prop('contractrecognitionname')))([u,a,o])
			 .map((option) => {

				const existingoption =
					ci.find((coption) => option.contractrecognitionid == coption.contractrecognitionid)

				option.name =
					existingoption
					? existingoption.contract_name + ' - ' + existingoption.contractrecognitionname
					:'New Contract - ' + option.contractrecognitionname

				return option
			})
			: []
		})

	const restrictedOrgDisc =
		prop.merge(
			[
				organizations_disciplines
				,CONSTANTdisciplines
				,vm
			]
		).map(([organizationDisciplines, CONSTANTdisciplines]) => {
			return (
				projectOptions()
				? organizationDisciplines
				: R.differenceWith(
					(a, b) =>
						checkprop('organizations_disciplines_id', null, a)
						== checkprop('organizations_disciplines_id',  null, b)
					,organizationDisciplines
					,CONSTANTdisciplines
				)
			)
			.filter((a) =>
				!exposer(vm().contract_id)
				|| a.applicablecontracts.find((c) => c.contract_id == exposer(vm().contract_id))
			)
		})

	const editContractPermissions = m.prop(false)
	const editContractRecognitions = m.prop(false)
	const editResourcePermissions = m.prop(false)
	const editPermissions = m.prop(false)
	const fetchedcontacts = data.scoped(false)
	const form = m.prop({ project:{} })
	const pe_vm = data.scoped({})
	const cloning = data.scoped(false)
	const activeTab = data.scoped('Disciplines')
	const fetchedExtras = data.scoped(false)
	const projectExtrasStat = vm.map(() => {
		return {
			project_extras_date_assigned: 0
			,project_extras_date_completed: 0
			,project_extras_date_verified: 0
			,project_extras_date_invalid: 0
		}
	})

	prop.merge(
		[
			vm
			,users
			,CONSTANTdisciplines
		]
	).map(([, users]) =>
		userSelectionIndex(
			R.mapObjIndexed((i) =>
				i =
					data.scoped(
						users.filter((u) =>
							u.user_id()
							&& !i.users().find((du) =>
								du.user_id() == u.user_id()
							)
						)
					)
			)(
				R.indexBy(
					R.prop('key')
					,(form().project.disciplines || [])
					.map( R.pick(['users', 'key']) )
				)
			)
		)
	)

	vm.map((p) =>
		p.project_id
			? Promise.all([
				data.fetchResource({
					props: {
						resources: [
							'contractor_id'
							,'contractor_name'
							,'user_id'
						]
						,teams: [
							'crew_id'
							,'contractor_id'
							,'crew_name'
							,'user_id'
						]
						,rates: [
							'crew_id'
							,'crews_discipline_rates_id'
							,'organizations_disciplines_id'
						]
					}
					,organizations_disciplines: p.disciplines.map((a) => a.organizations_disciplines_id()).filter((a) => a)
				})
					.then(resources)
			])
		: null
	)

	const projectContactOptions = data.scoped([])

	function projectExtrasSum(pE, onlyprop){
		[
			'project_extras_date_assigned'
			,'project_extras_date_completed'
			,'project_extras_date_verified'
			,'project_extras_date_invalid'
		]
		.filter((theprops) => theprops == onlyprop || !onlyprop)
		.forEach((prop) => {
			pE[prop]()
				? projectExtrasStat()[prop] =
					projectExtrasStat()[prop] + 1
				: onlyprop
					? projectExtrasStat()[prop] =
						projectExtrasStat()[prop] - 1
					: null
		})
	}

	prop.merge(
		[
			vm
			,fetchedcontacts
		]
	)
	.map(([p, fc]) => {
		if(p.project_id && p.project_id() && fc && p.organization_id() == data.initiateContractFetch().organization_id ){
			return Promise.all([
				data.fetchProjectContacts({project_id: p.project_id()})
				,data.fetchProjectContacts({project_id: p.project_id(), getOptions:'getOptions'})
			])
			.then(([pclist, options]) => {
				p.project_contacts = pclist
				form().project.project_contacts =
					p.project_contacts.map((pc) => ({
						project_contacts_id: m.prop(pc.project_contacts_id())
						,project_contacts_type: m.prop(pc.project_contacts_type())
						,project_contacts_name: m.prop(pc.project_contacts_name())
						,project_contacts_phone: m.prop(pc.project_contacts_phone())
						,project_contacts_email: m.prop(pc.project_contacts_email())
						,project_id: m.prop(pc.project_id())
						,user_id: m.prop(pc.user_id())
						,key: Math.random().toString(15).slice(2, 8)
					}))

				projectContactOptions(
					options.filter((x) =>
						!form().project.project_contacts.find((y) =>
							x.project_contacts_name() + x.project_contacts_type()
							== y.project_contacts_name() + y.project_contacts_type()
						)
					)
				)

				fetchedcontacts(false)
				return true
			})
		}
		return true
	})

	const projectExtrasTypes =
		data.scoped(
			{
				completed: ''
				, invalid: ''
				, verified: ''
				, assigned: ''
			}
		)

	function getTasks(prev, [p, activeTab, peT]){
		return p.project_id
		&& p.project_id()
		&& !fetchedExtras()
		&& activeTab == 'Tasks'
			? Promise.all([
				data.fetchProjectExtras({
					project_id: p.project_id()
					,discipline_id: null
					,f:fetchedExtras
					,typesObj:peT
					,uniqBy: ''
				})
			])
				.then(function([result]){
					vm().project_extras = result

					baseProjectDetailsHash(R.indexBy((t) => t.project_extras_id(), result))

					projectExtrasStat({
						project_extras_date_assigned: 0
						,project_extras_date_completed: 0
						,project_extras_date_verified: 0
						,project_extras_date_invalid: 0
					})

					form().project.project_extras =
						result.map((pe) => {
							pe_vm(pe)
							projectExtrasSum(pe, null)
							return cloningProjectE(cloning(), true)
						})

					masterPELIST(true)
					m.redraw()
					return result
				})
			: prev
	}

	const projectExtras =
		prop.scan(
			getTasks
			,[]
			,prop.merge(
				[
					vm
					,activeTab
					,projectExtrasTypes
					,fetchedExtras
				]
			)
		)

	// eslint-disable-next-line no-unused-vars
	const forceRedraw = prop.merge(
		[
			data.calculated
			,errors
			,projectExtras
			,metaDataLoading
			,projects
			,allResourceTypes
			,reconloading
			,materials
		]
	).map(function(){
		return setTimeout(function(){
			m.redraw()
		}, 0)
	})


	const resetLoaders =
		() =>
			data.currentRoute('projects')
			&& metaDataLoading(false)


	const discipline_vm = data.scoped({})
	const savingProp = data.scoped(false)
	const disciplineSpread = data.scoped([])
	const spreading = data.scoped(false)

	savingProp.map((s) => !s ? resetLoaders() : null)

	const restrictedOrgTask =
	prop.merge(
		[
			organizations_disciplines
			,disciplineSpread
			,vm
		]
	).map(([organizationDisciplines, disciplineSpread]) =>
		R.differenceWith(
			(a, b) =>
				checkprop('organizations_disciplines_id', null, a)
				== checkprop('organizations_disciplines_id',  null, b)
			,organizationDisciplines
			,disciplineSpread
		)
		.filter((a) =>
			!exposer(vm().contract_id)
			|| a.applicablecontracts.find((c) => c.contract_id == exposer(vm().contract_id) )
		)
	)

	const checkMultiple =
		m.prop({ data: [] })

	const checkMultipleProjectExtras =
		m.prop({ data: [] })

	const processedTasksSelected =
		() => checkMultipleProjectExtras()
		.data.some((t) => t.allocation_id())

	const process_geo = function(geo_name){
		var index = R.findIndex( (g) => g.geography_depot == geo_name, geographies() )
		var geo_item = R.find(R.propEq('placeLong', geo_name), suburbs())
		if (index == -1 && geo_item ){
			var new_geography = {
				organization_id: m.prop("")
				,geography_id: m.prop("")
				,geography_depot: m.prop(geo_name)
				,geography_latitude: m.prop(Number(geo_item.latitude))
				,geography_longitude: m.prop(Number(geo_item.longitude)),
			}
			geographies().push(new_geography)
		}
		return new_geography
	}


	const chartview =
		prop.merge(
			[
				data.coalatedSelectedVersion
				//,discipline_vm
				,data.calculated
			]
		)
		.map(([cv, c]) => {

			const chartobject =
				{
					data: {
						possibleContractEntities:
							(
								!cv || !Object.keys(cv).length
								? []
								// : !checkMultiple().data.length
								// ?
								: [cv[form().project.contractrecognitionid]]
								// : checkMultiple().data.map((diascipline) =>
								// 	cv[diascipline.contractrecognitionid]
								// )
							)
							.filter(R.identity)

					}
					,id: uuid.v4()
				}

			if( c ){
				data.sumSchedule({fe: chartobject, data: chartobject.data})
			}

			return chartobject
		})

	const debounceDrawChart = data.debounce(drawChart, 200)

	prop.merge(
		[
			chartview
			,data.calculated
		]
	)
	.map(([cv, c]) => {
		return !cv || !c || !Object.keys(data.loadchart()).length
		? null
		: debounceDrawChart({
			fe: cv
			,data: {
				series:
					!cv.data.possibleContractEntities.length
					? []
					: cv.data.possibleContractEntities
				,ftypeActual: ['currentProfits', 'outstandingProfits']
				,ftypeForecast: ['forecastedProfits']
			}
			,features: data.features
		})
	})

	const ifCharts = (fe, f) => data.features().Charts && fe && fe.chart && f()

	var cloningProjectE = function(clonemarker, pedit, peIndex){
		var pe_clone = resetPEForm(peIndex)
		pe_clone.relatedDiscipline = pe_vm().relatedDiscipline
		pe_clone.organizations_disciplines_id = exposer(pe_vm().organizations_disciplines_id)
		pe_clone.project_id( pedit || clonemarker ? pe_vm().project_id() : null )
		pe_clone.discipline_id( pe_vm().discipline_id() )
		pe_clone.invite_id( pe_vm().invite_id() )
		pe_clone.project_extras_planned_amount( pe_vm().project_extras_planned_amount() )
		pe_clone.project_extras_actual_amount( pe_vm().project_extras_actual_amount() )
		pe_clone.user_id( pe_vm().user_id() )
		pe_clone.project_extras_custom( pe_vm().project_extras_custom() )
		pe_clone.parents(
			clonemarker || !pedit
			? []
			: pe_vm().parents()
		)
		pe_clone.project_extras_id(
			clonemarker || !pedit
			? pe_clone.project_extras_id()
			: pe_vm().project_extras_id()
		)
		pe_clone.allocation_id(
			clonemarker || !pedit
			? null
			: pe_vm().allocation_id()
		)
		pe_clone.costinvoices =
			clonemarker || !pedit
			? []
			: pe_vm().costinvoices

		pe_clone.revenueinvoices =
			clonemarker || !pedit
			? []
			: pe_vm().revenueinvoices
		pe_clone.newtask = clonemarker || !pedit ? 'New Task' : ''
		pe_clone.invite_email = pe_vm().invite_email
		pe_clone.contract_id = pe_vm().contract_id
		pe_clone.filecount = pe_vm().filecount
		pe_clone.username = pe_vm().username
		pe_clone.project_name = pe_vm().project_name
		pe_clone.organization_id = pe_vm().organization_id
		pe_clone.project_extras_type(  pe_vm().project_extras_type() )
		pe_clone.project_extras_frequency( pe_vm().project_extras_frequency() )
		pe_clone.project_extras_description(  pe_vm().project_extras_description() )
		pe_clone.project_extras_priority( pe_vm().project_extras_priority() )
		pe_clone.project_extras_date_assigned(  pe_vm().project_extras_date_assigned() )
		pe_clone.project_extras_date_completed(  pe_vm().project_extras_date_completed() )
		pe_clone.project_extras_date_verified( pedit ? pe_vm().project_extras_date_verified() : null )
		pe_clone.project_extras_date_invalid(  pe_vm().project_extras_date_invalid() )
		pe_clone.project_extras_notes(  pe_vm().project_extras_notes() )
		pe_clone.project_extras_address(  pe_vm().project_extras_address() )
		return pe_clone
	}

	var cloningProject = function(clonemarker, pedit){
		var clone = {}
		const templateclone =
			vm().project_template_id()
			&& vm().organization_id() != data.initiateContractFetch().organization_id


		if( vm() ){
			clone = resetPForm()
			clone.organization_id( templateclone ? '' : vm().organization_id() )
			clone.contractrecognitionid = clonemarker == true ? null : vm().contractrecognitionid
			clone.eotSum = clonemarker == true ? null : vm().eotSum
			clone.nodSum = clonemarker == true ? null : vm().nodSum
			clone.varSum = clonemarker == true ? null : vm().varSum
			clone.incompletetaskcount = clonemarker == true ? null : vm().incompletetaskcount
			clone.lastAllocationDate = clonemarker == true ? null : vm().lastAllocationDate
			clone.project_id(clonemarker == true ? clone.project_id() : vm().project_id())
			clone.project_name( templateclone || vm().custom ? '' : clonemarker == true ? vm().project_name() + " (Clone)" : vm().project_name() )
			clone.project_parent( clonemarker == true && !templateclone ? vm().project_id() : vm().project_parent() )
			clone.project_template_id( vm().project_template_id() )
			clone.project_priority( vm().project_priority() )
			clone.contract_id( templateclone ? null : exposer(vm().contract_id) )
			clone.contract_name = vm().contract_name
			clone.project_site_type( vm().project_site_type() )
			clone.project_suburb( templateclone ? '' : vm().project_suburb() )
			clone.project_material_suburb( templateclone ? '' : vm().project_material_suburb() )
			clone.project_start_date( vm().project_start_date() )
			clone.project_dead_date( vm().project_dead_date() )
			clone.project_commissioning_date( vm().project_commissioning_date() )
			clone.project_final_acceptance( vm().project_final_acceptance() )
			clone.project_completion_marker( clonemarker == true ? null : vm().project_completion_marker() )
			clone.project_completion_comments( clonemarker == true ? '' : vm().project_completion_comments() )
			clone.project_draft_at( clonemarker == true ? new Date().setHours(24, 0, 0, 0) : vm().project_draft_at() )
			clone.project_tendered_at( clonemarker == true ? null : vm().project_tendered_at() )
			clone.project_rejected_at( clonemarker == true ? null : vm().project_rejected_at() )
			clone.project_accepted_at( clonemarker == true ? null : vm().project_accepted_at() )
			clone.workdays = vm().workdays
			clone.project_documents( clonemarker == true || templateclone ? [] : vm().project_documents() )
			clone.disciplines = []
			clone.project_extras = []
			clone.__requestedAt = vm().__requestedAt
			vm().disciplines.forEach((d) => {
				discipline_vm(d)
				clone.disciplines.push(cloningProjectD(false, pedit, clone, templateclone))
			})

			vm().project_extras.forEach((pe, peIndex) => {
				pe_vm(pe)
				clone.project_extras.push(cloningProjectE(false, pedit, peIndex))
			})

			clone.newproject = clonemarker == false ? false : true
			clone.project_completion = vm().project_completion
			clone.schedule_name = vm().schedule_name
			clone.schedule_version_id = vm().schedule_version_id

			form().project = clone
			discipline_vm({})
			projectDisciplines(vm().disciplines)
			fetchedcontacts(true)
		}

		return clone
	}

	var cloningProjectD = function(clonemarker, pedit, project, templateclone){
		var discipline_clone = resetDForm().discipline[0]
		discipline_clone.project_id( discipline_vm().project_id() )

		const newodg =
			organizations_disciplines()
			.find((ogd) =>
				ogd.organizations_disciplines_name
				+ ogd.organizations_disciplines_description
				== discipline_vm().discipline_name()
				+ discipline_vm().discipline_description()
			)

		discipline_clone.organizations_disciplines_id(
			clonemarker == true || templateclone
				? newodg && templateclone
					? newodg.organizations_disciplines_id
					: null
			: discipline_vm().organizations_disciplines_id()
		)

		clonemarker || templateclone
		? discipline_clone.newdiscipline = true
		: null

		discipline_clone.contractrecognitionid = clonemarker == true || templateclone ? null : discipline_vm().contractrecognitionid
		discipline_clone.taskcount = discipline_vm().taskcount
		discipline_clone.eotSum = clonemarker == true || templateclone ? null : discipline_vm().eotSum
		discipline_clone.nodSum = clonemarker == true || templateclone ? null : discipline_vm().nodSum
		discipline_clone.varSum = clonemarker == true || templateclone ? null : discipline_vm().varSum
		discipline_clone.lastAllocationDate = clonemarker == true || templateclone ? null : discipline_vm().lastAllocationDate
		discipline_clone.discplineNameDescription = discipline_vm().discplineNameDescription

		discipline_clone.organizations_disciplines_nominal_expenditure_rate(discipline_vm().organizations_disciplines_nominal_expenditure_rate())
		discipline_clone.organizations_disciplines_nominal_revenue_rate(discipline_vm().organizations_disciplines_nominal_revenue_rate())
		discipline_clone.contract_items_client_reference(discipline_vm().contract_items_client_reference())
		discipline_clone.organizations_disciplines_nominal_rate(discipline_vm().organizations_disciplines_nominal_rate())

		discipline_clone.discipline_name( clonemarker == true ? discipline_vm().discipline_name() + " (Clone)" : discipline_vm().discipline_name() )
		discipline_clone.discipline_description( clonemarker == true ? discipline_vm().discipline_description() + " (Clone)" : discipline_vm().discipline_description() )
		discipline_clone.discipline_contract_multiplier( discipline_vm().discipline_contract_multiplier() )
		discipline_clone.discipline_order( discipline_vm().discipline_order() )
		discipline_clone.discipline_priority( discipline_vm().discipline_priority() )
		discipline_clone.discipline_work( discipline_vm().discipline_work() )
		discipline_clone.discipline_planned( discipline_vm().discipline_planned() )
		discipline_clone.discipline_combination_limit( discipline_vm().discipline_combination_limit() )
		discipline_clone.discipline_combine( discipline_vm().discipline_combine() )
		discipline_clone.discipline_custom( discipline_vm().discipline_custom() )
		discipline_clone.discipline_completed( clonemarker == false && pedit == true ? discipline_vm().discipline_completed() : 0 )
		discipline_clone.discipline_harshness( discipline_vm().discipline_harshness() )
		discipline_clone.discipline_percent( discipline_vm().discipline_percent() )
		discipline_clone.discipline_id( clonemarker == false && pedit == true ? discipline_vm().discipline_id() : "" )
		discipline_clone.discipline_flow( discipline_vm().discipline_flow() )
		discipline_clone.discipline_start_date(
			clonemarker == false && pedit == true || !project
			? discipline_vm().discipline_start_date()
			: project.project_start_date()
		)
		discipline_clone.discipline_end_date(
			clonemarker == false && pedit == true || !project
			? discipline_vm().discipline_end_date()
			: project.project_dead_date()
		)
		discipline_clone.discipline_recovery( discipline_vm().discipline_recovery() )
		discipline_clone.discipline_auto_assign_tasks( discipline_vm().discipline_auto_assign_tasks() )
		discipline_clone.discipline_strict_end( discipline_vm().discipline_strict_end() )
		discipline_clone.discipline_strict_start( discipline_vm().discipline_strict_start() )
		discipline_clone.discipline_uom( discipline_vm().discipline_uom() )
		discipline_clone.discipline_consistency( discipline_vm().discipline_consistency() )
		discipline_clone.discipline_consolidate_start( discipline_vm().discipline_consolidate_start() )
		discipline_clone.workdayeffort = discipline_vm().workdayeffort
		discipline_clone.badeffortteams = discipline_vm().badeffortteams
		discipline_clone.disciplines_contractors =
			!templateclone
			? discipline_vm().disciplines_contractors.map((dc) => {
				return {
					discipline_id: m.prop( clonemarker == false && pedit == true ? dc.discipline_id() : null )
					,user_id: m.prop( dc.user_id() )
					,disciplines_contractors_id: m.prop( clonemarker == false && pedit == true ? dc.disciplines_contractors_id() : null )
					,disciplines_contractors_name: m.prop( dc.disciplines_contractors_name() )
					,contractor_id: m.prop( dc.contractor_id()  )
					,disciplines_contractors_mixed_crews: m.prop( dc.disciplines_contractors_mixed_crews() )
					,disciplines_contractors_total_rating: m.prop( dc.disciplines_contractors_total_rating() )
					,disciplines_contractors_quality_rating: m.prop( dc.disciplines_contractors_quality_rating() )
					,disciplines_contractors_common: m.prop( dc.disciplines_contractors_common() )
					,disciplines_contractors_utilized: m.prop( dc.disciplines_contractors_utilized() )
					,disciplines_contractors_crews:
						dc.disciplines_contractors_crews.map((dcc) => {
							return {
								user_id: m.prop( dcc.user_id() )
								,contractor_id: m.prop( dc.contractor_id()  )
								,disciplines_contractors_crews_name: m.prop( dcc.disciplines_contractors_crews_name() )
								,disciplines_contractors_crews_contract_multiplier: m.prop( dcc.disciplines_contractors_crews_contract_multiplier() )
								,disciplines_contractors_crews_work_completed: m.prop( dcc.disciplines_contractors_crews_work_completed() )
								,disciplines_contractors_id: m.prop( clonemarker == false && pedit == true ? dcc.disciplines_contractors_id() : null )
								,disciplines_contractors_crews_invalid: m.prop( dcc.disciplines_contractors_crews_invalid() )
								,disciplines_contractors_crews_excess: m.prop( dcc.disciplines_contractors_crews_excess() )
								,disciplines_contractors_crews_utilized: m.prop( dcc.disciplines_contractors_crews_utilized() )
								,disciplines_contractors_crews_rating: m.prop( dcc.disciplines_contractors_crews_rating() )
								,disciplines_contractors_crews_common: m.prop( dcc.disciplines_contractors_crews_common() )
								,disciplines_contractors_crews_id: m.prop( clonemarker == false && pedit == true ? dcc.disciplines_contractors_crews_id() : null )
								,crew_id: m.prop( dcc.crew_id() )
								,crews_discipline_rates_id: m.prop( dcc.crews_discipline_rates_id )
							}
						})
				}
			})
			: []

		return discipline_clone
	}



	var DeleteProjectMode = m.prop(false)
	var DeleteProjectExtrasMode = m.prop(false) //eslint-disable-line
	var DeleteDisciplineMode = m.prop(false)

	var resetPForm = function(){
		return {
			schedule_id: m.prop(schedule_id())
			,project_id: m.prop(uuid.v4())
			,organization_id: m.prop("")
			,project_name: m.prop("")
			,project_parent: m.prop(null)
			,project_template_id: m.prop(null)
			,project_priority: m.prop( projects().length + 1 )
			,contract_id: m.prop(null)
			,contract_name: "Disconnected Ledgers"
			,project_site_type: m.prop("Suburban")
			,project_suburb: m.prop("")
			,project_material_suburb: m.prop("")
			,project_start_date: m.prop(
				new Date().setHours(24, 0, 0, 0)
			)
			,project_dead_date: m.prop(
				new Date().getTime() +  aMonth * 3
			)
			,project_commissioning_date: m.prop(
				new Date().getTime() +  aMonth * 4
			)
			,project_final_acceptance: m.prop(
				new Date().getTime() +  aMonth * 12
			)
			,project_draft_at: m.prop( new Date().setHours(24, 0, 0, 0) )
			,project_tendered_at: m.prop( null )
			,project_rejected_at: m.prop( null )
			,project_accepted_at: m.prop( null )
			,project_completion_marker: m.prop()
			,project_completion_comments: m.prop("")
			,project_contacts: []
			,project_documents:  m.prop([])
			,disciplines: []
			,project_extras: []
			,newproject: true
		}
	}


	var resetDForm = function(){
		var resetD = {
			discipline: [{
				project_id: m.prop("")
				,users: m.prop([])
				,organizations_disciplines_id: m.prop("")
				,discipline_name: m.prop("")
				,discipline_description: m.prop(' ')
				,discipline_order: m.prop(
					form().project.project_id
						? form().project.disciplines.length + 1
						: 0
				)
				,discipline_contract_multiplier: m.prop(1)
				,discipline_priority: m.prop(0)
				,discipline_work: m.prop(0)
				,discipline_planned: m.prop(1)
				,discipline_combination_limit: m.prop(1)
				,discipline_combine: m.prop(0)
				,discipline_custom: m.prop(0)
				,discipline_completed: m.prop(0)
				,discipline_harshness: m.prop(1)
				,discipline_percent: m.prop(0)
				,discipline_id: m.prop("")
				,discipline_flow: m.prop("Consecutive Operation")
				,discipline_start_date: m.prop(
					form().project.project_id
						? new Date(form().project.project_start_date()).getTime()
						: vm().project_start_date
						? new Date(vm().project_start_date()).getTime()
						: new Date().getTime() + milliperday
					)
				,discipline_end_date: m.prop(
					form().project.project_id
						? new Date(form().project.project_dead_date()).getTime()
						: vm().project_dead_date
						? new Date(vm().project_dead_date()).getTime()
						: new Date().getTime() + aMonth * 3
					)
				,discipline_recovery: m.prop(true)
				,discipline_auto_assign_tasks: m.prop(true)
				,discipline_strict_end: m.prop(false)
				,discipline_strict_start: m.prop(false)
				,discipline_uom: m.prop('')
				,discipline_consistency: m.prop(false)
				,discipline_consolidate_start: m.prop(true)
				,disciplines_contractors: []
				,key: Math.random().toString(15).slice(2, 8)
				,created: false
				,newdiscipline: true
				,organizations_disciplines_nominal_expenditure_rate: m.prop(0)
				,organizations_disciplines_nominal_revenue_rate: m.prop(0)
				,contract_items_client_reference: m.prop('')
				,organizations_disciplines_nominal_rate: m.prop(0)
			}]
		}
		return resetD
	}

	var resetPEForm = function(peIndex){
		return {
			project_id: m.prop(
				form().project.project_id
					? form().project.project_id()
					: vm().project_id()
			)
			,discipline_id: m.prop(null)
			,relatedDiscipline: ''
			,parents: m.prop([])
			,project_extras_id: m.prop( uuid.v4() )
			,project_extras_planned_amount: m.prop(0)
			,invite_id: m.prop(null)
			,invite_email: ''
			,project_extras_custom: m.prop(false)
			,project_extras_actual_amount: m.prop(0)
			,username: ''
			,costinvoices: []
			,revenueinvoices: []
			,user_id: m.prop(null)
			,allocation_id: m.prop(null)
			,project_extras_type: m.prop('')
			,project_extras_frequency: m.prop(0)
			,project_extras_description: m.prop('')
			,project_extras_date_assigned: m.prop(null)
			,project_extras_date_completed: m.prop(null)
			,project_extras_priority: m.prop(
				form().project.project_id
					? form().project.project_extras.length + 1
					: peIndex
					? peIndex
					: 0
			)
			,project_extras_date_verified: m.prop(null)
			,project_extras_date_invalid: m.prop(null)
			,project_extras_notes: m.prop(null)
			,project_extras_address: m.prop(null)
			,key: Math.random().toString(15).slice(2, 8)
			,created: cloning()
			,organizations_disciplines_id: m.prop(null)
			,newtask: 'New Task'
		}
	}

	var check_dnames = function(returnBoolean, extraChecks){
		const count = R.countBy( chr =>
			chr.discipline_name()
			,form().project.disciplines
		)

		const errorArray = [
			(d, prop='discipline_description') => d['discipline_name']() && !d[prop]()
				? `Discipline Description must not be empty` : null
			,(d, prop='discipline_name') => count[d[prop]()] > 1
				? `Discipline Names must not be duplicated. Check ` + d[prop]()
				: null
		]

		return elements.errorAlert(
				`To save, `
				,' and '
				,''
				,form().project.disciplines
				,errorArray
				,returnBoolean
				,false
				,'warning'
				,null
				,(extraChecks || [])
					.map((a) =>
						errors()[a]
							? a + ' ' + errors()[a]
							: ''
					)
					.filter((a) => a)
		)
	}

	const checkInvoiceDates = function(returnBoolean){

		const errorArray = [
			(d) => d && new Date(d.receiveddate).getTime() > new Date().getTime()
				? `Invoice received dates must be in the past`
				: null
		]

		return elements.errorAlert(
			`To save, `
			,', '
			,''
			,Object.keys(baseInvoiceIndex()['i']).map((k) => baseInvoiceIndex()['i'][k])
			,errorArray
			,returnBoolean
			,false
			,'warning'
			,null
		)
	}


	const checkTaskDates = function(returnBoolean){

		const errorArray = [
			(d) => d && !d.project_extras_type()
				? `Task Name must not be empty`
				: null
		]

		return elements.errorAlert(
			`To save, `
			,', '
			,''
			,checkMultipleProjectExtras().data
			,errorArray
			,returnBoolean
			,false
			,'warning'
			,null
		)
	}

	const checkUserTaskMatch = function(returnBoolean){
		const errorArray = [
			(d, prop ='project_extras_description') => {

				const suggested =
					allResourceTypes()
					.find((r) =>
						r.user_id()
						== checkMultipleProjectExtras().data[0].user_id()
					)

				const totalRates =
					!suggested
					? null
					: suggested.crew_id
					? suggested.crews_discipline_rates
					: R.unnest(
						suggested.crews.map((c) =>
							c.crews_discipline_rates
						)
					)

				return d.organizations_disciplines_id
				&& suggested
				&& !totalRates.find((c) =>
					d.organizations_disciplines_id
					== c.organizations_disciplines_id()
				)
				? d[prop]()
					? `, check ` + d[prop]()
					: ' '
				: ''
			}
		]

		return elements.errorAlert(
			`This resource is not able to perform all tasks `
			,''
			,''
			,checkMultipleProjectExtras().data
			,errorArray
			,returnBoolean
			,false
			,'warning'
			,null
		)
	}


	var check_pnames = function (){
		var state = false
		projects().every((project) => {
			if (project.project_name() == "" || project.project_name() == null){ state = true}
			return project.project_name() != "" && project.project_name() != null
		})
		return state
	}


	var PercentComplete = function(project){
		var complete = 0
		var dperc = 0
		var discipline_portions = project.disciplines.filter((d) => d.discipline_work() != 0)
		var discipline_portion = discipline_portions.length
		if (discipline_portion != 0){
			discipline_portions.forEach((discipline) => {
				dperc = discipline.discipline_completed()/discipline.discipline_work() >= 1 ? 1
					: discipline.discipline_completed()/discipline.discipline_work()
				complete = complete + dperc/discipline_portion
			})
		}
		projectPercentComplete(complete == 1 ? Number(100).toFixed(2) : Number(complete * 100).toFixed(2))
		project.projectPercentComplete = Number(projectPercentComplete())
		return projectPercentComplete()
	}


	var edit = m.prop(false)

	var SaveDisabled = function(returnBoolean){
		const count = R.countBy(
			chr => chr.project_name()
			,projects().filter((r) => r.project_id() != form().project.project_id())
			.concat(form().project)
		)
		const errorArray = [
			(d) => !d.project_name() ? `Project Name must not be empty` : null
			// ,(d) => !d.project_suburb() ? `Location must not be empty` : null
			// ,(d) => !d.project_material_suburb() ? `Material Store must not be empty` : null
			,(d) =>	count[d.project_name()] > 1 ? `Project Names must not be duplicated` : null
			,() =>
				form().project.project_contacts.length
				> R.uniqBy(
					(a) => a.project_contacts_email() + a.project_contacts_type()
					, form().project.project_contacts
				).length
				? `Project Contacts are duplicated`
				: null
			,() =>
				newInvoices().length
				&& newInvoices().some((ni) =>
					!ni.invoice_name
					|| ni.invoice_amount() * ni.invoice_rate() == 0
				)
				? `New invoices must be completed`
				: null
		]

		const taskErrors = checkTaskDates(true)
		const taskErrorMessage =
			taskErrors
			? 'Task Name must not be empty, check ' + taskErrors
			: ''

		const invoiceErrors = checkInvoiceDates(true)
		const invoiceErrorMessage =
			invoiceErrors
			? 'Invoice received dates must be in the past'
			: ''

		return elements.errorAlert(
			`To save, `
			,' and '
			,''
			,[form().project]
			,errorArray
			,returnBoolean
			,false
			,'warning'
			, [
				data.advancedView()
				? elements.list([
					m('label.control-label', 'Project Completion')
					,PercentComplete(form().project)
					,m('label.control-label',
						form().project.project_completion_marker()
						&& Number(projectPercentComplete()) != 100
							? 'Marked Complete, works until '
							: !form().project.project_completion_marker()
							&& Number(projectPercentComplete()) == 100
								? 'Not marked complete, but works completed on '
								: !form().project.project_completion_marker()
								&& Number(projectPercentComplete()) != 100
									? 'Expected completion'
									: 'Complete'
					)
					,lastProjectDate()
				])
				: null
			]
			,Object.keys(errors())
			.map((a) =>
				errors()[a]
					? a + ' ' + errors()[a]
					: ''
			)
			.filter((a) => a)
			.concat(taskErrorMessage)
			.concat(invoiceErrorMessage)
		)
	}

	var resources_available = function(){

		const nResList = resources()
		const nTeamList = []

		if (checkMultiple().data.length != 0){

			nResList.forEach((r) => {

				checkMultiple().data.every((d) =>
					resRatesAvailable()
					[r.contractor_id()]
					['ratesIByOrgD']
					[d.organizations_disciplines_id()]
				)
				&& checkMultiple().data.some((d) =>
					!d.disciplines_contractors.find((nr) =>
						nr.contractor_id()
						== r.contractor_id()
					)
				)
					? nTeamList.push(r)
					: null

				r.crews.forEach((t) => {

					checkMultiple().data.every((d) => {
						const ratesList =
							resRatesAvailable()
							[r.contractor_id()]
							['ratesIByOrgD']
							[d.organizations_disciplines_id()]

						return ratesList
						&& ratesList.find((teamRate) =>
							teamRate.crew_id()
							== t.crew_id()
						)
					})
					&& checkMultiple().data.some((d) =>
						!R.unnest(
							d.disciplines_contractors.map(
								R.prop('disciplines_contractors_crews')
							)
						)
						.some((dcc) => dcc.crew_id() == t.crew_id())
					)
						? nTeamList.push(t)
						: null
				})
			})
		}
		return rList(nTeamList)
	}

	var working_crews = function(d){
		var crew_list = []
		d.disciplines_contractors.forEach((c) => {
			crew_list = crew_list.concat(c.disciplines_contractors_crews)
		})
		return crew_list
	}

	var getDisciplines = function(){
		var disciplines = []
		disciplines  = form().project.disciplines.filter((d) => d.discipline_name != null )
		return disciplines
	}

	const firstproject =
		prop.merge(
			[
				projects
				,inverseProjectsList
			]
		)
		.map(([p, i]) => !p.length && !i.length)

	var permissions = data.permissions

	permissions.map(function(p){
		editResourcePermissions(Permissions.hasWrite(p, 'resource_management'))
		editPermissions(Permissions.hasWrite(p, 'project_management'))
		editContractRecognitions(Permissions.hasWrite(p, 'contract_recognition'))
		editContractPermissions(Permissions.hasWrite(p, 'contract_management'))
		m.redraw()
		return null
	})

	var dc_mapping = function(entity, focusArray){
		if(entity != null){
			if (entity.crew_name == null){
				var dc = {
					user_id: m.prop(entity.user_id())
					,discipline_id: m.prop("")
					,disciplines_contractors_id: m.prop("")
					,disciplines_contractors_name: m.prop( entity.contractor_name() )
					,contractor_id: m.prop( entity.contractor_id() )
					,disciplines_contractors_mixed_crews: m.prop( 0 )
					,disciplines_contractors_total_rating: m.prop( 0 )
					,disciplines_contractors_quality_rating: m.prop( 0 )
					,disciplines_contractors_common: m.prop( '' )
					,disciplines_contractors_utilized: m.prop( '' )
					,disciplines_contractors_crews: []
				}
				focusArray.forEach((selection) => {
					var resourceMapped = R.find(function(chr) { return chr.contractor_id() == entity.contractor_id() }, selection.disciplines_contractors)
					if(resourceMapped == null){ selection.disciplines_contractors.push(dc) }
					selection.discipline_custom(1)
				})
			} else {
				const contractorEntity = resources().find((r) => r.contractor_id() == entity.contractor_id())
				var dc = {
					user_id: m.prop(contractorEntity.user_id())
					,discipline_id: m.prop("")
					,disciplines_contractors_id: m.prop("")
					,disciplines_contractors_name: m.prop(contractorEntity.contractor_name())
					,contractor_id: m.prop( contractorEntity.contractor_id() )
					,disciplines_contractors_mixed_crews: m.prop( 0 )
					,disciplines_contractors_total_rating: m.prop( 0 )
					,disciplines_contractors_quality_rating: m.prop( 0 )
					,disciplines_contractors_common: m.prop( '' )
					,disciplines_contractors_utilized: m.prop( '' )
					,disciplines_contractors_crews: []
				}
				var dcc = {
					user_id: m.prop(entity.user_id())
					,contractor_id: m.prop( contractorEntity.contractor_id() )
					,disciplines_contractors_crews_name: m.prop(entity.crew_name())
					,disciplines_contractors_crews_contract_multiplier: m.prop(1)
					,disciplines_contractors_id: m.prop("")
					,disciplines_contractors_crews_work_completed: m.prop( 0 )
					,disciplines_contractors_crews_invalid: m.prop( '' )
					,disciplines_contractors_crews_excess: m.prop( '' )
					,disciplines_contractors_crews_utilized: m.prop( '' )
					,disciplines_contractors_crews_rating: m.prop( 0 )
					,disciplines_contractors_crews_common: m.prop( 0 )
					,disciplines_contractors_crews_id: m.prop("")
					,crew_id: m.prop(entity.crew_id())
				}
				dc.disciplines_contractors_crews.push(dcc)
				focusArray.forEach((selection) => {
					var resourceMapped = R.find(function(chr) {
						return chr.contractor_id()
							== contractorEntity.contractor_id()
					}, selection.disciplines_contractors)
					if(resourceMapped == null){
						selection.disciplines_contractors.push(dc)
					} else {
						var crewMapped = R.find(function(chr) { return chr.crew_id() == entity.crew_id() }, resourceMapped.disciplines_contractors_crews)
						if(crewMapped == null){
							dcc.disciplines_contractors_id(resourceMapped.disciplines_contractors_id())
							resourceMapped.disciplines_contractors_crews.push(dcc)
						}
					}
					selection.discipline_custom(1)
				})
			}
			resources_available()
		}
	}


	function reConnectDisciplines(projectExtrasIndex){
		R.mapObjIndexed((arrayValues, key) =>
			[
				'discipline_planned'
				,'discipline_completed'
			].map((dprop) => {

				if ( key != 'null' && false ){

					var disciplneObj =
						form().project.disciplines
						.find((d) =>
							d.discipline_id()
							== key
						)
						|| form().project.disciplines
						.find((d) =>
							d.discipline_name()
							+ " - "
							+ d.discipline_description()
							== key
						)

					var sum =
						R.sum(
							arrayValues
							.filter((v) =>

									dprop == 'discipline_completed'
									&& v['project_extras_date_completed']()

								|| dprop == 'discipline_planned'
							)
							.map((v) => v['project_extras_planned_amount']() )
							// this is not a bug.
							// Completed values are summed by the plannned values
							// Ee assume no variation to the amount. variaitons may apply but only if completed
							// via the updates route where you can change completed quantitties they then get added
							// to variations and go into the system as un-planned completions
						)

					// var currentPlannedWork = disciplneObj[dprop]()
					var currentVariations =
						R.sum(
							extraContractObligationData()
							.filter((v) =>
								v.allocations_vars_amount
								&& v.allocations_vars_approved()
							)
							.map((v) => v.allocations_vars_amount())
						)

					disciplneObj[dprop]( sum )
					// disciplneObj[dprop]( R.max( sum, currentPlannedWork ) )
					dprop == 'discipline_planned'
						? disciplneObj['discipline_work'](
							disciplneObj[dprop]()
							+ currentVariations
						)
						: null
				}

				return null
			})
			,projectExtrasIndex
		)
	}

	projectExtrasIndex.map((newIndex) => reConnectDisciplines(newIndex) )

	var filterFunction = {
		changeNames: [
			{ugly_name: 'disciplines_'}
			,{ugly_name: 'disciplines_contractors_'}
			,{ugly_name: 'disciplines_contractors', better_name: "Resources"}
			,{ugly_name: 'disciplines_contractors_name', better_name: "Resource Name"}
			,{ugly_name: 'disciplines_contractors_quality_rating', better_name: "Resource Quality Rating"}
			,{ugly_name: 'disciplines_contractors_crews', better_name: "Teams"}
			,{ugly_name: 'disciplines_contractors_crews_name', better_name: "Team Name"}
			,{ugly_name: 'disciplines_contractors_crews_contract_multiplier', better_name: "Team Rate Multiplier"}
			,{ugly_name: 'project_commissioning_date', better_name: "Handover Date"}
			,{ugly_name: 'project_final_acceptance', better_name: "Liability End Date"}
			,{ugly_name: 'project_completion_marker', better_name: "Project Completion"}
			,{ugly_name: 'disciplines_contractors_name', better_name: "Resource Name"}
			,{ugly_name: 'relatedDiscipline', better_name: "Related Discipline"}
			,{ugly_name: 'project_extras_type', better_name: "Task Name"}
			,{ugly_name: 'project_extras_frequency', better_name: "Task Frequency in Days"}
			,{ugly_name: 'project_extras_custom', better_name: "Task Manually Allocated"}
			,{ugly_name: 'project_extras_priority', better_name: "Task Prioriy"}
			,{ugly_name: 'project_extras_description', better_name: "Task Description"}
			,{ugly_name: 'project_extras_date_assigned', better_name: "Task Date Assigned"}
			,{ugly_name: 'project_extras_date_completed', better_name: "Task Date Completed"}
			,{ugly_name: 'project_extras_date_verified', better_name: "Task Date Verified"}
			,{ugly_name: 'project_extras_date_invalid', better_name: "Task Date Invalid"}
			,{ugly_name: 'project_extras_notes', better_name: "Task Notes"}
			,{ugly_name: 'project_extras_address', better_name: "Task Address"}
			,{ugly_name: 'project_extras_planned_amount', better_name: "Task Amount"}
			,{ugly_name: 'project_extras_actual_amount', better_name: "Task Actual Amount"}
			,{ugly_name: 'username', better_name: "Task Assignee"}
			,{ugly_name: 'filecount', better_name: 'File Count'}
			,{ugly_name: 'discipline_consolidate_start', better_name: 'Consolidate Schedule'}
			,{ugly_name: 'project_extras', better_name: 'Tasks'}
			,{ugly_name: 'discipline_contract_multiplier', better_name: 'Discipline Rate Multiplier'}
			,{ugly_name: 'projectPercentComplete', better_name: 'Percent Completed'}
		]
		,uniqueArrays: [
			{array_name: 'disciplines_contractors', unique: "contractor_id"}
			,{array_name: 'disciplines_contractors_crews', unique: "crew_id"}
			,{array_name: 'disciplines', unique: "discipline_id"}
			// ,{array_name: 'project_extras', unique: "project_extras_id"}
			// ,{array_name: 'project_contacts', unique: "project_contacts_id"}
			,{array_name: 'project_documents', unique: "project_documents_id"}
		]
		,template: {
			project_name: ""
			,project_priority:  0
			,contract_name: ""
			,project_draft_at: "date"
			,project_tendered_at: "date"
			,project_rejected_at: "date"
			,project_accepted_at: "date"
			,project_site_type: ""
			,project_suburb: ""
			,project_material_suburb: ""
			,project_start_date: "date"
			,project_dead_date: "date"
			,project_commissioning_date: "date"
			,project_final_acceptance: "date"
			,project_completion_marker: "date"
			,project_completion_comments: ""
			,project_documents:  []
			,status: ""
			,projectPercentComplete: 0
			// ,project_contacts:  [{
			// 	project_contacts_name: ""
			// 	,project_contacts_phone: ""
			// 	,project_contacts_email: ""
			// 	,project_contacts_type: ""
			// }]
			,disciplines: [{
				discipline_name: ""
				,discipline_description: ""
				,discipline_order: 0
				,discipline_contract_multiplier: 0
				,discipline_priority: 0
				,discipline_work: 0
				,discipline_planned: 0
				,discipline_combination_limit: 0
				,discipline_completed: 0
				,discipline_harshness: 0
				,discipline_percent: 0
				,discipline_flow: ""
				,discipline_start_date: "date"
				,discipline_end_date: "date"
				,discipline_recovery: true
				,discipline_auto_assign_tasks: true
				,discipline_consistency: true
				,discipline_strict_end: true
				,discipline_strict_start: true
				,discipline_uom: ""
				,discipline_consolidate_start: true
				,disciplines_contractors: [{
					disciplines_contractors_name:  ""
					,disciplines_contractors_quality_rating: 0
					,disciplines_contractors_crews: [{
						disciplines_contractors_crews_name: ""
						,disciplines_contractors_crews_contract_multiplier: 0
					}]
				}]
			}]
			,project_extras:[{
				relatedDiscipline: ""
				,project_extras_planned_amount: 0
				,project_extras_actual_amount: 0
				,project_extras_type: ""
				,project_extras_frequency: 0
				,project_extras_custom: true
				,project_extras_priority: 0
				,project_extras_description: ""
				,project_extras_date_assigned: "date"
				,project_extras_date_completed: "date"
				,project_extras_date_verified: "date"
				,project_extras_date_invalid: "date"
				,project_extras_notes: ""
				,project_extras_address: ""
				,username: ""
				,invite_email: ""
				,filecount: 0
			}]
		},
	}

	var getfilters = function(button, marker){
		var datainput =
			marker == "Project"
				? filterFunction.projectFilters
				: marker == 'Tasks'
					? filterFunction.projectExtrasFilters
					: filterFunction.disciplineFilters

		if (datainput == null){
			filterFunction.projectFilters = {
				filters: m.prop([])
				,operationalFilters: m.prop([])
				,changeNames: filterFunction.changeNames
				,uniqueArrays: filterFunction.uniqueArrays
				,template: R.omit('project_extras', filterFunction.template)
				,disabled: () => projects().length == 0
			},
			filterFunction.disciplineFilters = {
				filters: m.prop([])
				,operationalFilters: m.prop([])
				,changeNames: filterFunction.changeNames
				,uniqueArrays: filterFunction.uniqueArrays
				,template: filterFunction.template.disciplines[0]
				,disabled: () => form().project.disciplines.length == 0
			}
			filterFunction.projectExtrasFilters = {
				filters: m.prop([])
				,operationalFilters: m.prop([])
				,changeNames: filterFunction.changeNames
				,uniqueArrays: filterFunction.uniqueArrays
				,template: filterFunction.template.project_extras[0]
				,disabled: () => !form().project.project_extras || !form().project.project_extras.length
			}

			datainput =
				marker == "Project"
					? filterFunction.projectFilters
					: marker == "Tasks"
						? filterFunction.projectExtrasFilters
						: filterFunction.disciplineFilters
		}

		var gotfilters = datainput == null
			? null
			: datainput.operationalFilters(
				filterView(
					datainput.template
					, datainput.filters()
					, datainput.changeNames
					, datainput.uniqueArrays
					, datainput.disabled
					, marker
					, data.scoped
				)
		)
		datainput != null ? datainput.filters( gotfilters.filters ) : null
		return gotfilters.generatedElements
	}

	var checkallocatedResources = function(entity, path){
		var b = []
		checkMultiple().data.forEach((d) => {
			b = b.concat( R.pluck('contractor_id')(d.disciplines_contractors) )
			d.disciplines_contractors.forEach((dc) => {
				b = b.concat( R.pluck('crew_id')(dc.disciplines_contractors_crews) )
			})
		})
		return R.findIndex(
			function(chr) { return chr() == R.path([path], entity)()}
			, b) >= 0
	}

	var masterLIST = function(mcreate){
		if (mcreate){
			CONSTANTdisciplines(
				R.unnest(
					R.partition(
						(c) => c.created,
						form().project.disciplines
					)
				)
			)
		}

		if( !filterFunction.disciplineFilters ){
			getfilters(null, "Discipline")
		}

		return ListProcessing(
			CONSTANTdisciplines()
			,filterFunction.disciplineFilters.filters()
			,CONSTANTdisciplines().filter((c) => c.created).length > 0
				? null
				: "discipline_order"
			,filteredDisciplineOptions
		)
	}

	var masterPELIST = function(mcreate){
		if (mcreate){
			CONSTANTProjectExtras(form().project.project_extras)
			// CONSTANTProjectExtras(
			// 	R.unnest(
			// 		R.partition(
			// 			(c) => c.created,
						// form().project.project_extras
			// 		)
			// 	)
			// )
		}

		if( !filterFunction.projectExtrasFilters ){
			getfilters(null, "Tasks")
		}

		return ListProcessing(
			CONSTANTProjectExtras()
			,filterFunction.projectExtrasFilters.filters()
			,null
			,filteredTaskOptions
		)
	}

	const updateMultipliers = function(v, t){
		if(v){
			R.unnest(
				checkMultiple().data.map(
					(d) =>
						R.unnest(
							d.disciplines_contractors
								.map( dc =>
									dc.disciplines_contractors_crews
								)
						)
				)
			)
			.filter((dcc) => dcc.crew_id() == t.crew_id())
			.forEach((dcc) => dcc.disciplines_contractors_crews_contract_multiplier(v))
		}
		return t.disciplines_contractors_crews_contract_multiplier()
	}

	const setOptions = function(value, prop, container){

		var refContainter = container
			? container
			: activeTab() == 'Tasks'
			? checkMultipleProjectExtras().data
			: checkMultiple().data


		if(
			refContainter[0]
		){
			var dateEdit =
				'project_extras_date_assigned' == prop
				|| 'project_extras_date_completed' == prop
				|| 'project_extras_date_verified' == prop
				|| 'project_extras_date_invalid' == prop
				|| 'project_completion_marker' == prop

			var nullIdEdit =
				'user_id' == prop
				|| 'discipline_id' == prop
				|| 'invite_id' == prop
				|| 'invite_email' == prop

			var dtype = typeof refContainter[0][prop] == 'function'

			value || value == false || value == '' || value == 0
				? refContainter.forEach((d) => {

					value = (dateEdit || nullIdEdit) && !value
						? null
						: value

					var diffState =
						dateEdit
						&& (
							!value && d[prop]()
							|| value && !d[prop]()
						)
							? true
							: false

					prop == 'discipline_planned'
					&& dtype
						? d['discipline_work']( d['discipline_work']() - d['discipline_planned']() + value)
						: prop == 'discipline_planned'
							? d['discipline_work'] = ['discipline_work'] - d['discipline_planned'] + value
							: null

					dtype
						? d[prop](value)
						: d[prop] = value

					activeTab() == 'Tasks'
					&& dateEdit
					&& diffState
						? projectExtrasSum(d, prop)
						: null

					d.created = true
				})
				: null
		}

		return !refContainter.length
			? null
			: dtype
				? refContainter[0][prop]()
				: refContainter[0][prop]
	}

	const idealResourceName = m.prop('')
	const underlinedHeader =
		a => m('h4.pt3.bw1.bb.pb2.b--black-20', a)

	const availableUOMS =
		organizations_disciplines
		.map(R.uniqBy(R.prop('organizations_disciplines_uom')))


	const disciplineSelectAutocomplete = (D, { attrs={}, options={} }={}) =>
		m(autocomplete.Main, Object.assign({
			list: restrictedOrgDisc
			,key: D.key
			,initialValue: D.discipline_name()
			,oninput: (v) => {
				const ReturnedAutocompleteObj =
					organizations_disciplines()
					.find( R.propEq('orgDisciplineNameDescription', v) )
					|| organizations_disciplines().find(
						R.propEq(
							'organizations_disciplines_id'
							, D.organizations_disciplines_id()
						)
					)

				v || v == ''
					? D.discipline_name(
						ReturnedAutocompleteObj
							? ReturnedAutocompleteObj.organizations_disciplines_name
							: v
					)
					: null

				if (
					ReturnedAutocompleteObj
				){
					const appliedrate =
						ReturnedAutocompleteObj.applicablecontracts
						.find((ac) => ac.contract_id == form().project.contract_id())
						|| { contract_items_rate: 0 }

					D.organizations_disciplines_id(
						ReturnedAutocompleteObj.organizations_disciplines_id
					)
					D.discipline_description(
						ReturnedAutocompleteObj.organizations_disciplines_description
					)
					D.organizations_disciplines_nominal_revenue_rate(
						appliedrate.contract_items_rate
					)
					D.discipline_uom(
						ReturnedAutocompleteObj.organizations_disciplines_uom
					)
				} else if( !ReturnedAutocompleteObj ){
					D.organizations_disciplines_id(null)
				}

				return ReturnedAutocompleteObj
					? ReturnedAutocompleteObj.orgDisciplineNameDescription
					: D.discipline_name()
			}
			,field: 'orgDisciplineNameDescription'
			,attrs: Object.assign({
				title: `Autoselect work that is similar to other projects`
				,style: {
					height: '3em'
				}
				,required: true
				,key: D.key
			}, attrs)
			,sort: R.prop('completedcount')
		}, options))

	const disciplineSelect =
		(D, _) =>
			m.component(
				Pencil
				,() => D.discipline_name()
				,() => disciplineSelectAutocomplete(D)

			)

	const disciplineChargeRate =
		(value, d) => {

			const ref = d[0]

			if( value || value == 0){


				const [a, b]
					= R.partition((d) => !d.organizations_disciplines_id(), d)

				setOptions(value, 'organizations_disciplines_nominal_revenue_rate', a)

				b.forEach((r) => {
					const orgdref =
						organizations_disciplines()
						.find((od) => od.organizations_disciplines_id == ref.organizations_disciplines_id())

					setOptions(
						Number(Number( value/(r.organizations_disciplines_nominal_revenue_rate() || 1) ).toFixed(6))
						, !orgdref.organizations_disciplines_nominal_revenue_rate
							? 'organizations_disciplines_nominal_revenue_rate'
							: 'discipline_contract_multiplier'
						, [r]
					)
					r.nominalrate = value
				})
			}

			return (
				ref && ref.nominalrate
				|| ref && ref.organizations_disciplines_nominal_revenue_rate() * ref.discipline_contract_multiplier()
			)
		}

	const switchProjectsButton =
		(ctrl) =>
			m('button.btn.btn-secondary',
				{
					style: {
						backgroundColor: 'transparent'
						,border: 'solid 1px #3380c2'
						,position: 'relative'
						// ,top: '-0.1em'
						// ,height: '2.9em'
						// ,width: '5em'
						// ,color: '#434aa3'
						// ,textDecoration: 'underline'
					}
					,disabled: !inverseProjectsList().length
					,onclick: () => {

						ctrl.model.vm({})
						ctrl.model.checkMultipleProjects([])
						ctrl.model.completedProjects() == "completed"
							? ctrl.model.completedProjects("inprogress")
							: ctrl.model.completedProjects("completed")

						ctrl.model.projects([])
						ctrl.model.inverseProjectsList([])
						ctrl.model.loading(true)
						ctrl.model.metaDataLoading(true)
						return Promise.all([
								ctrl.intent.fetchProjects({depth:1})
								,ctrl.model.data.fetchProjectsBySchedule({
									schedule_id: ctrl.model.schedule_id()
									,completedProjects: ctrl.model.completedProjects() == 'inprogress'
										? 'completed'
										: 'inprogress'
									,depth: 1
									,props: {
										projects: [
											'project_name'
											,'project_id'
										]
									}
								})
									.then( ctrl.model.inverseProjectsList )
						])
						.then(R.F)
						.then(ctrl.model.loading)
						.then(ctrl.intent.fetchProjects)
						.then(R.F)
						.then(ctrl.model.metaDataLoading)
					}
					, title: "Switch between running and completed projects"
				}
				,'Switch'
			)


	const disciplineCloneButton =
		() =>
			m("button.btn.btn-secondary", {
				disabled:
					!!check_dnames(true)
					|| !discipline_vm().discipline_name
					|| checkMultiple().data.length > 1
				,onclick: () => {
					const newD = cloningProjectD(true, false)
					newD.created = true
					checkMultiple().data = []
					discipline_vm(newD)
					form().project.disciplines.unshift(newD)
					masterLIST(true)
					checkMultiple().data.unshift(newD)
					activeTab('Disciplines')
				}
				,style: {
					backgroundColor: 'transparent'
					,border: 'solid 1px #3380c2'
					,position: 'relative'
					,top: '-0.1em'
					,height: '2.9em'
					,width: '5em'
					,color: '#434aa3'
					,textDecoration: 'underline'
				}
			},"Clone")

	const taskCloneButton =
		() =>
			onProjectList()
			? null
			: m("button.btn.", {
				onclick: () => {

					checkMultipleProjectExtras().data =
						checkMultipleProjectExtras().data
						.map((t) => {
							pe_vm(t)
							const newt = cloningProjectE(true, false)
							newt.created = true
							projectExtrasSum(t, null)
							form().project.project_extras.unshift(newt)
							return newt
						})

					pe_vm(checkMultipleProjectExtras().data[0])
					masterPELIST(true)
				}
				,style: {
					backgroundColor: 'transparent'
					,border: 'solid 1px #3380c2'
					,position: 'relative'
					,top: '-0.1em'
					,height: '2.9em'
					,width: '5em'
					,color: '#434aa3'
					,textDecoration: 'underline'
				}
			},"Clone")

	const timepush = prop(7)

	const incrimentTimeButton =
		(ctrl) =>
			checkMultiple().data.every((d) => d.discipline_order() <= 1)
			&& !onProjectList()
			? null
			: m("button.btn.", {
				disabled: false
				,onclick: () => {


					if( onProjectList() ){

						const forwardtime = timepush() * milliperday

						return ctrl.intent.sendProjects(
							checkMultipleProjects().map((p) => {

								p.project_start_date(p.project_start_date() + forwardtime )
								p.project_dead_date(p.project_dead_date() + forwardtime)
								p.project_commissioning_date(p.project_commissioning_date() + forwardtime )
								p.project_final_acceptance(p.project_final_acceptance() + forwardtime)

								p.disciplines.forEach((d) => {
									d.discipline_start_date( new Date(d.discipline_start_date()).getTime() + forwardtime )
									d.discipline_end_date( new Date(d.discipline_end_date()).getTime() + forwardtime )
								})

								return p
							})
						)
							.then(() => {
								checkMultipleProjects([])
								vm({})
								projectOptions(false)
								return true
							})

					}
					else {

						return checkMultiple().data.filter((d) => d.discipline_order()).forEach((d) => {
							const ref =
								form().project.disciplines
								.find((fd) => fd.discipline_order() == d.discipline_order() - 1)

							if( ref ){
								d.discipline_start_date( new Date(ref.discipline_end_date()).getTime() + milliperday )
								d.discipline_end_date(
									new Date(d.discipline_start_date()).getTime()
									+ ( new Date(ref.discipline_end_date()).getTime() - new Date(ref.discipline_start_date()).getTime() )
								)
							}
						})
					}

				}
				,title: "Cascade the duration of the previous discipline"
				,style: {
					backgroundColor: 'transparent'
					,border: 'solid 1px #3380c2'
					,position: 'relative'
					,top: '-0.1em'
					,height: '2.9em'
					,width: '7.em'
					,color: '#434aa3'
					,textDecoration: 'underline'
				}
			}, onProjectList() ? 'Push Dates' : 'Cascade Duration')

	const detailPaneInputs = function(D, ctrl){

		const detailsPaneSave = () =>
			projectOptions()
				? projectOptionsView()
				: checkMultiple().data.length
				? [
					elements.list([

						elements.action(
							'Save'
							, () => {
								checkMultiple().data = []
								discipline_vm({})
								resources_available()
								return ctrl.intent.sendProjects()
							}
							, ctrl.model.savingProp
							, 'primary'
							, () =>
								!!check_dnames(true)
								|| identical(form().project.disciplines, ctrl.model.vm().disciplines)
						)

						,elements.undoDiscard({
							discard:
								{
									label: 'Discard'
									,attrs: {
										onclick: () => ctrl.intent.deleteDiscipline()
										,disabled:
											ctrl.model.discipline_vm().discipline_name == null
											&& ctrl.model.checkMultiple().data.length == 0
									}
								}
							,doneUndo: {
								label:
									identical(form().project.disciplines, ctrl.model.vm().disciplines)
									? 'Done'
									: ''
								,attrs: {
									onclick: () => {

										errors({})

										if(
											!identical(
												form().project.disciplines
												, ctrl.model.vm().disciplines
											)
										){
											discardAllDisciplineChanges()
										} else {
											discipline_vm({})
											checkMultiple().data = []
											masterLIST(true)
											resources_available()
										}

										return true
									}
									,disabled: ctrl.model.checkTaskDates(true)
									,title: `Discard all changes made to selected disciplines`
									,style: {
										opacity: ctrl.model.editPermissions() ? 1 : 0
										,transition: '0.5s'
										,width: '5em'
									}
								}
							}
						})
					])
					,ctrl.model.check_dnames(false, Object.keys(errors()))
				]
				: checkMultipleProjectExtras().data.length
				? elements.list([

					elements.action(
						'Save'
						, () => {
							if( spreading() ){
								disciplineSpread().splice(0, 1)
								form().project.project_extras =
									form().project.project_extras
									.concat(
										R.unnest(
											disciplineSpread()
											.map((d) => {

												const taskDiff =
													R.differenceWith(
														(a, b) =>
															a.project_extras_type()
															== b.project_extras_type()
														,checkMultipleProjectExtras().data
														,form().project.project_extras.filter((t) =>
															d.discipline_id
															&& t.discipline_id()
															== d.discipline_id()
														)
													)

												return taskDiff.map((t) => {
													pe_vm(t)
													const newt = cloningProjectE(true, false)
													newt.created = true
													newt.discipline_id(d.discipline_id ? d.discipline_id() : null)
													newt.project_extras_description(d.orgDisciplineNameDescription)
													// newt.project_extras_actual_amount(0)
													// newt.project_extras_date_assigned(null)
													// newt.project_extras_date_completed(null)
													// newt.project_extras_date_verified(null)
													// newt.project_extras_date_invalid(null)
													newt.relatedDiscipline = d.orgDisciplineNameDescription
													newt.organizations_disciplines_id = exposer(d.organizations_disciplines_id)
													projectExtrasSum(t, null)
													checkMultipleProjectExtras().data.unshift(newt)
													return newt
												})
											})
										)
									)
								disciplineSpread([])
								spreading(false)
							}

							masterPELIST(true)
							checkMultipleProjectExtras().data = []
							pe_vm({})
							projectExtrasIndex(
								R.groupBy(
									(a) =>
										cloning()
										? R.prop('relatedDiscipline', a)
										: R.pipe(R.prop('discipline_id'), R.call)(a)
									,form().project.project_extras
								)
							)
							return ctrl.intent.sendProjects()
						}
						, ctrl.model.savingProp
						, 'primary'
						, () =>
							ctrl.model.checkTaskDates(true)
							|| identical(form().project.project_extras, ctrl.model.vm().project_extras)
							&& !disciplineSpread().length
							&& !onProjectList()
					)

					,elements.undoDiscard({
						discard:
							{
								label: 'Discard'
								,attrs: {
									onclick: () => ctrl.intent.deleteProjectExtra()
									,disabled:
										!ctrl.model.pe_vm().project_extras_type
										&& ctrl.model.checkMultipleProjectExtras().data.length == 0
								}
							}
						,doneUndo: {
							label:
								identical(form().project.project_extras, ctrl.model.vm().project_extras)
								&& !disciplineSpread().length
								&& !onProjectList()
								? 'Done'
								: ''
							,attrs: {
								onclick: () => {

									errors({})

									if(
										(
											!identical(
												form().project.project_extras
												, ctrl.model.vm().project_extras
											)
											|| disciplineSpread().length
										)
										&& !onProjectList()
									){
										discardAllTaskChanges()
									} else {
										disciplineSpread([])
										spreading(false)
										masterPELIST(true)
										checkMultipleProjectExtras().data = []
										pe_vm({})
									}

									return true
								}
								,disabled: ctrl.model.checkTaskDates(true)
								,title: `Discard all changes made to selected Tasks`
								,style: {
									opacity: ctrl.model.editPermissions() ? 1 : 0
									,transition: '0.5s'
									,width: '5em'
								}
							}
						}
					})
				])
				: null

		function universal(){
			function WorkDetail(){
				return [
					checkMultiple().data.length == 1
					&& !D().discipline_id()
						? elements.list([
							m('label.control-label.mb1'
								, 'Discipline Name '
							)
							,disciplineSelect(D(), ctrl)
						])
						: m('div.pv2'
							,m('label.control-label.mb1', 'Discipline Name')
							,R.pluck(
								'discipline_name'
							)(ctrl.model.checkMultiple().data)
							.map(s => m('p.pv1.ma0',s()))
						)

					,checkMultiple().data.length == 1
						? elements.list([
							m('label.control-label.mb1'
								, 'Description '
								,Pencil(
									() => D().discipline_description()
									,() =>
										elements.textInput(
											D().discipline_description
											,{
												title: "Description of the discipline"
											}
										)
								)
							)
						])
						: null

					,elements.list([
						m('label.control-label.mb1'
							, 'Unit of Measure '
							,Pencil(
								() => D().discipline_uom()
								,() =>

									autocomplete.all(
										availableUOMS
										,(value) => {
											if(value){ setOptions(value, 'discipline_uom') }
										}
										,'organizations_disciplines_uom'
										, () => ({
											style: {
												height: '3em'
											},
											title: "Measuring units"
										})
									)
							)
						)
					])

					,ctrl.model.editContractPermissions()
					? elements.list([
						m('label.control-label', 'Rate Multiplier')
						,Pencil(
							() => checkMultiple().data[0].discipline_contract_multiplier()
							,() =>
								m(
									NumberInput
									,R.merge(
										data
										,{
											errors
											,errorLabel: 'Rate Multiplier '
										}
									)
									,{
										prop: checkMultiple().data.length >= 1
											? (value) => setOptions(value, 'discipline_contract_multiplier')
											: D().discipline_contract_multiplier
										,attrs: {
											min: 0
											, step: 0.001
											, title: "Percentage increase of current applied contract rates"
										}
									}
								)
						)
					])
					: !ctrl.model.data.readContractPermissions()
					|| ctrl.model.data.readContractPermissions()
						&& checkMultiple().data.length > 1

					? null
					: elements.list([
						m('label.control-label', 'Rate Multiplier ')
						,checkMultiple().data[0].discipline_contract_multiplier()
					])

					,elements.list([
						m('label.control-label', 'Proposed Amount ')
						,Pencil(
							() => checkMultiple().data[0].discipline_planned()
							,() =>
								m(
								NumberInput
								,R.merge(
									data
									,{
										errors
										,errorLabel: 'Proposed Amount'
									}
								)
								,{
									prop: checkMultiple().data.length >= 1
										? (value) => setOptions(value, 'discipline_planned')
										: D().discipline_planned
									,attrs: {
										min: 0.01
										, step: 0.01
										, title: "Amount of work there is to complete"
									}
								}
							)
						)
					])

					,elements.list([
						m('label.control-label', 'Actual Amount ')
						,Pencil(
							() => checkMultiple().data[0].discipline_completed()
							,() =>
								m(
								NumberInput
								,R.merge(
									data
									,{
										errors
										,errorLabel: 'Actual Amount'
									}
								)
								,{
									prop: checkMultiple().data.length >= 1
										? (value) => setOptions(value, 'discipline_completed')
										: D().discipline_completed
									,attrs: {
										min: 0
										, step: 0.01
										, title: "Amount of work that is already completed"
									}
							}
						)
						)
					])

					,elements.list([
						m('label.control-label', 'Chronological Order ')
						,Pencil(
							() => checkMultiple().data[0].discipline_order()
							,() =>
								m(
								NumberInput
								,R.merge(
									data
									,{
										errors
										,errorLabel: 'Chronological Order'
									}
								)
								,{
									prop: checkMultiple().data.length >= 1
										? (value) => setOptions(value, 'discipline_order')
										: D().discipline_order
									,attrs: {
										min: 0
										, title: "Chronological order of the "
											+ "discipline activity"
									}
								}
						)
						)
					])

					,elements.list([
						m('label.control-label', 'Strict')
						,m('label.control-label', ' | ')
						,m('label.control-label', 'Proposed Start')
					])

					,elements.list([
						elements.checkbox(
							{ onchange: m.withAttr(
								'checked'
								, checkMultiple().data.length >= 1
									? (value) => setOptions(value, 'discipline_strict_start')
									: D().discipline_strict_start
							)
							,checked: checkMultiple().data[0].discipline_strict_start()
							,title: "Recover disciplines if they strictly "
								+ "need to be completed by their end date. "
							}
						)
						,Pencil(
							() => moment(checkMultiple().data[0].discipline_start_date()).format("ll")
							,() =>
								elements.dateInput(
									checkMultiple().data.length >= 1
										? (value) => setOptions(value, 'discipline_start_date')
										: D().discipline_start_date
									,{
										title: "The date work is allowed "
										+ "to commence, this defaults to "
										+ "the project's Feasible Start Date"
									}
								)
						)
					])


					,elements.list([
						m('label.control-label', 'Strict')
						,m('label.control-label', ' | ')
						,m('label.control-label', 'Proposed End')
					])
					,elements.list([
						elements.checkbox(
							{ onchange: m.withAttr(
								'checked'
								, checkMultiple().data.length >= 1
									? (value) => setOptions(value, 'discipline_strict_end')
									: D().discipline_strict_end
							)
							,checked: checkMultiple().data[0].discipline_strict_end()
							,title: "Recover disciplines if they strictly "
								+ "need to be completed by their end date. "
							}
						)
						,Pencil(
							() => moment(checkMultiple().data[0].discipline_end_date()).format("ll")
							,() =>
								elements.dateInput(
									checkMultiple().data.length >= 1
										? (value) => setOptions(value, 'discipline_end_date')
										: D().discipline_end_date
									,{
										title: "The date work should be complete by, "
										+ "this defaults to the project's "
										+ "Feasible End Date "
									}
								)
						)
					])
					,elements.alert(
						`info`
						,`Use the Cascade button to match the previous disciplines duration and cascade the start and finish dates`
					)
					,incrimentTimeButton(ctrl)
				]
			}

			function FinanicalSummary(){
				return !checkMultiple().data.every(R.prop('newdiscipline'))
				? []
				: [
					data.editContractPermissions()
					? elements.list([
						m('label.control-label.mb1', 'Nominal Charge Rate')
						,Pencil(
							() =>
								makeMoney(
									checkMultiple().data[0].organizations_disciplines_nominal_revenue_rate()
									* checkMultiple().data[0].discipline_contract_multiplier()
								)
							,() =>
								m(
									NumberInput
									,R.merge(
										data
										,{
											errors
											,errorLabel: 'Nominal Charge Rate'
										}
									)
									,{
										prop: (value) => disciplineChargeRate(value, checkMultiple().data)
										,attrs: {
											min: 0
											,step: 0.01
											,title: `Average amount to charge clients when this disciplines is used. May be altered for specific clients via financials and the contract selection`
										}
									}
								)
						)
					])
					: data.readContractPermissions()
					? elements.list([
						m('label.control-label.mb1', 'Nominal Charge Rate')
						,makeMoney(
							checkMultiple().data[0].organizations_disciplines_nominal_revenue_rate()
							* checkMultiple().data[0].discipline_contract_multiplier()
						)
					])
					: null

					,errors()['Nominal Charge Rate']
					? elements.alert(
						`warning`
						, `Charge Amounts must be positive as this is money that would be gained as revenue by the organization`
					)
					: null



					,data.editContractPermissions()
					? elements.list([
						m('label.control-label.mb1', 'Nominal Pay Rate')
						,Pencil(
							() => makeMoney(checkMultiple().data[0].organizations_disciplines_nominal_expenditure_rate())
							,() =>
								m(
									NumberInput
									,R.merge(
										data
										,{
											errors
											,errorLabel: 'Nominal Pay Rate'
										}
									)
									,{
										prop:
											(value) => {
												const abvalue = Math.abs(value)*-1
												return setOptions(abvalue, 'organizations_disciplines_nominal_expenditure_rate')
											}
										,attrs: {
											max: 0
											,step: 0.01
											,title: `Average amount that would be paid when a team or resource conducts this discipline. May be altered for specific teams via financials and the contract selection`
										}
									}
								)
						)
					])
					: data.readContractPermissions()
					? elements.list([
						m('label.control-label.mb1', 'Nominal Pay Rate')
						,makeMoney(checkMultiple().data[0].organizations_disciplines_nominal_expenditure_rate())
					])
					: null

					,errors()['Nominal Pay Rate']
					? elements.alert(
						`warning`
						, `Pay Amounts must be negative as this is money that would be expenditures of the organization`
					)
					: null

					,data.editContractPermissions()
					? elements.list([
						m('label.control-label.mb1', 'Client Item Reference')
						,Pencil(
							() => checkMultiple().data[0].contract_items_client_reference()
							,() =>
								elements.textInput(
									(value) => setOptions(value, 'contract_items_client_reference')
									,{
										title: `Reference number or name the client will refer to contractually`
									}
								)
						)
					])
					: data.readContractPermissions()
					? elements.list([
						m('label.control-label.mb1', 'Client Item Reference')
						,checkMultiple().data[0].contract_items_client_reference()
					])
					: null
				]

			}

			function OperationAndRecovery(){
				return [

					elements.list([
						elements.checkbox(
							{ onchange:
								m.withAttr(
									'checked'
									,checkMultiple().data.length >= 1
										? (value) => setOptions(value, 'discipline_consolidate_start')
										: D().discipline_consolidate_start
								)

							, checked: checkMultiple().data[0].discipline_consolidate_start()
							, title: "Ensure work dates are consolidated and "
								+"are not sporadic"
							}
						)
						,m('label.control-label', 'Consolidate Schedule')
						,elements.alert('info', `Ensure project works are consolidated and not sporadically spread across the timeline`)
					])

					,elements.list([
						elements.checkbox({
							onchange: m.withAttr(
								'checked'
								, checkMultiple().data.length >= 1
									? (value) => setOptions(value, 'discipline_consistency')
									: D().discipline_consistency
							)
							, checked: checkMultiple().data[0].discipline_consistency()
							, title: "Ensure resources that worked in "
								+ "previous disciplines also work on "
								+ "this discipline"
						})
						,m('label.control-label', 'Consistent Resources')
						,elements.alert('info', `Ensure resources that worked on previous disciplines also work on this discipline if they are capable`)
					])

					,elements.list([
						elements.checkbox(
							{ onchange: m.withAttr(
								'checked'
								, checkMultiple().data.length >= 1
									? (value) => setOptions(value, 'discipline_recovery')
									: D().discipline_recovery
							)
							,checked: checkMultiple().data[0].discipline_recovery()
							,title: "Allow Odin to attempt to recover "
								+ "disciplines if the end dates slip "
								+ "out of control"
							}
						)
						,m('label.control-label', 'Recovery Permission')
						,elements.alert('info', `
							Allow Odin to attempt to recover disciplines if the end dates look like they won't be met.
							Odin will attempt to recover the discipline by allocating more resources and/or subsituting faster resources suited to the work`
						)
					])

					,elements.list([
						m('label.control-label', 'Auto-Assign Tasks')
						,elements.checkbox(
							{ onchange: m.withAttr(
								'checked'
								, checkMultiple().data.length >= 1
									? (value) => setOptions(value, 'discipline_auto_assign_tasks')
									: D().discipline_auto_assign_tasks
							)
							,checked: checkMultiple().data[0].discipline_auto_assign_tasks()
							,title: "Allow Odin to auto assign project tasks while allocating works"
							}
						)
					])

					,elements.list([
						m('label.control-label', 'Operational Regime')
						,elements.selectbuttons(
							['Consecutive', 'Workflows']
							, checkMultiple().data.length >= 1
								? (value) => setOptions(value, 'discipline_flow')
								: D().discipline_flow
							,{ title: `Discipline relationships can be managed
								with our Workflow Relationships
								or the relationships can be
								stacked back to back.
								(as in a Consecutive Operation).
								In a Consecutive
								operation the first
								discipline will have to finish
								before the second can start.
								In the Workflow operation, the
								relationships altered in Workflows
								will dictate their forward and backward offsets
								that will allow an efficient overlap of cascading activities`
						})
						,elements.alert(
							'info'
							, `Discipline relationships can be managed
							with our Workflow Relationships
							or the relationships can be
							stacked back to back.
							(as in a Consecutive Operation).
							In a Consecutive
							operation the first
							discipline will have to finish
							before the second can start.
							In the Workflow operation, the
							relationships altered in Workflows
							will dictate their forward and backward offsets
							that will allow an efficient overlap of cascading activities`
						)
					])

					,ctrl.model.data.advancedView()
					? elements.list([
						m('label.control-label', 'Environmental Rating')
						,Pencil(
							() => checkMultiple().data[0].discipline_harshness()
							,() =>
								m(
									NumberInput
									,R.merge(
										data
										,{
											errors
											,errorLabel: 'Environmental Rating'
										}
									)
									,{
										prop: checkMultiple().data.length >= 1
											? (value) => setOptions(value, 'discipline_harshness')
											: D().discipline_harshness
										,attrs: {
											min: 1
											, step: 0.1
											, title: "A ratio that represents environmental "
												+ "conditions and the affect it will "
												+ "have on working rates. If a workload is "
												+ "expected to take twice as long because "
												+ "of environmental conditions, this value "
												+ "would be 2."
										}
									}
							)
						)
						,elements.alert(
							'info'
							, "A ratio that represents environmental "
							+ "conditions and the affect it will "
							+ "have on working rates. If a workload is "
							+ "expected to take twice as long because "
							+ "of environmental conditions, this value "
							+ "would be 2."
						)
					])
					: null

					,elements.list([
						m('label.control-label', 'Resource Combination Limit')
						,!checkMultiple().data[0].discipline_recovery()
							? 'Intiate Recovery Permission'
							: Pencil(
								() => checkMultiple().data[0].discipline_combination_limit()
								,() =>
									m(
										NumberInput
										,R.merge(
											data
											,{
												errors
												,errorLabel: 'Resource Combination Limit'
											}
										)
									,{
										prop: checkMultiple().data.length >= 1
											? (value) => setOptions(value, 'discipline_combination_limit')
											: D().discipline_combination_limit
										,attrs: {
											min: 1
											, title: "How many resources Odin is "
												+ "allowed to propose during an "
												+ "attempt to recover it from slipping "
												+ "past its end dates"
										}
									}
								)
							)

						,elements.alert(
							'info'
							, "This limit is the maximum number of resources Odin is "
							+ "allowed to schedule to complete this discipline "
						)
					])

				]
			}

			function ResourceAllocation(){
				return !ctrl.model.rList().length
				&& ctrl.model.checkMultiple().data.some((d) => !d.discipline_id() || !d.organizations_disciplines_id())
				? elements.alert(`warning`, `Save these disciplines before allocating resources`)
				: [
					ctrl.model.rList().length
					? elements.list([
						Pencil(
							() => m(`label`, 'Available Resources')
							,() =>
								autocomplete.strict(
									ctrl.model.rList
									,R.when( Boolean, function(v){
										ctrl.model.dc_mapping(v, ctrl.model.checkMultiple().data)
										return v.ParentRecognition
									})
									, 'ParentRecognition'
									, () => ({
										disabled: ctrl.model.rList().length == 0
											|| ctrl.model.checkMultiple().data.length == 0
										,title: "Team or Resource nominated to work on this discipline"
									})
									, (value, element) => {
										element.value = ""
									}
								)
						)
					])
					: null

					,m('label.control-label', 'Resources ')
					,elements.list([
						ctrl.model.data.readResourcePermissions()
							? elements.strikeTable(
								R.uniqBy(
									(a) => a.contractor_id
									,R.unnest(
										checkMultiple().data.map(
											(d) =>
												d.disciplines_contractors
										)
									)
								)
								.map(
									label => ({
										label: label.disciplines_contractors_name()
										,action: () => {
											ctrl.model.deleteDisciplineC({ entity: label })
										}
									})
								)
							)
							: 'Requires Resource Permissions'
					])
					,m('.teams'
						, m('label.control-label'
							, 'Teams ' + (
								(ctrl.model.data.readContractPermissions()
								|| ctrl.model.editContractPermissions())
								&& ctrl.model.data.advancedView()
								? `× Rate Multiplier`
								: ''
							)
						)

						,ctrl.model.data.readResourcePermissions()
							? elements.strikeTable(
								R.uniqBy(
									byProp('crew_id')
									,R.unnest(
										checkMultiple().data.map(
											(d) =>
												R.unnest(
													d.disciplines_contractors
														.map( dc =>
															dc.disciplines_contractors_crews
														)
												)
										)
									)
								)
								.map(
									label => ({
										label: label.disciplines_contractors_crews_name()
										,action: () => { ctrl.model.deleteDisciplineC({ entity: label }) }
										,meta:
											!ctrl.model.data.advancedView()
											? []
											: [
												` × `
												,Pencil(
													() => label.disciplines_contractors_crews_contract_multiplier()
													,() =>
														m(
															NumberInput
															,R.merge(
																data
																,{
																	errors
																	,errorLabel: 'Team Multiplying Rate ' + label.disciplines_contractors_crews_name()
																}
															)
															,{
																prop: (v) => updateMultipliers(v, label)
																,attrs: {
																	min: 0
																	, step: 0.001
																	, title: "Percentage increase of current applied contract rates"
																}
															}
														)
												)
											]
									})
								)
							)
							: 'Requires Resource Permissions'
					)

					,m('br')

					,elements.list([
						m('button.btn.btn-secondary'
							,{
								disabled: ctrl.model.savingProp()
								,style: {
									backgroundColor: 'transparent'
									,border: 'solid 1px #3380c2'
									,position: 'relative'
									,top: '-0.1em'
									,height: '2.9em'
									,width: '5em'
									,color: '#434aa3'
									,textDecoration: 'underline'
								}
								,onclick: () => ctrl.intent.createIdealResource()
							}
							, "Create"
						)
						,Pencil(
							() => idealResourceName() || `a New Ideal Resource`
							,() =>
								elements.textInput(
									idealResourceName
									,{
										title: "Name of the ideal resource"
									}
								)
						)
					])

					,elements.list([
						ctrl.model.disciplineCloneButton()
						,ctrl.model.checkMultiple().data.length > 1
							? `these Disciplines`
							: `this Discipline`
					])
				]
			}

			return [
				underlinedHeader('Resourcing')
				, ResourceAllocation()
				, m('br')
				, underlinedHeader('Work Detail')
				, WorkDetail()
				, m('br')
				, underlinedHeader('Finanicals')
				, FinanicalSummary()
				, m('br')
				, underlinedHeader('Operation & Recovery')
				, OperationAndRecovery()
				, m('br')
			]
		}


		function linkCompeltion(v){
			if(v || v == 0){

				checkMultipleProjectExtras().data
				.forEach((o) => {

					let diffState =
						!o.project_extras_date_invalid()
						|| v == 0
						? false
						: true


					if (diffState){
						o.project_extras_date_invalid(null)
						projectExtrasSum(
							o
							,'project_extras_date_invalid'
						)
					}

					if ( v == 0 ){
						o.project_extras_date_completed(null)
						projectExtrasSum(
							o
							,'project_extras_date_completed'
						)
					}

					o.project_extras_actual_amount(v)

					if( !o.project_extras_date_completed() && v ){
						o.project_extras_date_completed(Date.now())
						projectExtrasSum(
							o
							,'project_extras_date_completed'
						)
					}

					o.created = true
				})
			}
			return checkMultipleProjectExtras().data[0].project_extras_actual_amount()
		}

		function taskDetails(t){

			function updateCompletedDate({ e, value, task}){
				if( e !== undefined ) {
					setOptions(!e ? false : value, 'project_extras_date_completed')
					setOptions(false, 'project_extras_date_invalid')

					!e ? setOptions(false, 'project_extras_date_verified') : null

					checkMultipleProjectExtras().data
					.map((t) => t.project_extras_actual_amount(e ? t.project_extras_planned_amount() : 0))
				}
				return task.project_extras_date_completed()
			}

			function updateInvalidDate({ e, value, task}){
				if( e !== undefined ) {

					setOptions(!e ? false : value, 'project_extras_date_invalid')
					setOptions(false, 'project_extras_date_completed')

					!e ? setOptions(false, 'project_extras_date_verified') : null

				}
				return task.project_extras_date_invalid()
			}

		return [
				underlinedHeader('Task Details')

				,elements.list([
					ctrl.model.taskCloneButton()
					,ctrl.model.checkMultipleProjectExtras().data.length > 1
						? `these Tasks`
						: `this Task`
				])

				,!spreading()
				? elements.list([
					m('button.btn.btn-secondary',
						{
							style: {
								backgroundColor: 'transparent'
								,border: 'solid 1px #3380c2'
								,position: 'relative'
								,top: '-0.1em'
								,height: '2.9em'
								,width: '5em'
								,color: '#434aa3'
								,textDecoration: 'underline'
							}
							,disabled: false
							,onclick: () => {
								spreading(true)

								const checkedorgds =
									checkMultipleProjectExtras().data
									.map((d) => exposer(d.organizations_disciplines_id))

								if( checkedorgds.length ){
									disciplineSpread(
										ctrl.model.restrictedOrgTask()
										.filter((od) => checkedorgds.find((sod) => od.organizations_disciplines_id == sod))
									)
								}
							}
							,title: "Spread tasks to disciplines on this project"
						}
						,'Spread'
					)
					,ctrl.model.checkMultipleProjectExtras().data.length > 1 ? ` these Tasks` : ` this Task`
				])
				: null

				,spreading()
				? [
					elements.list([
						m('button.btn.btn-secondary',
							{
								style: {
									backgroundColor: 'transparent'
									,border: 'solid 1px #3380c2'
									,position: 'relative'
									,top: '-0.1em'
									,height: '2.9em'
									,width: '5em'
									,color: '#434aa3'
									,textDecoration: 'underline'
								}
								,disabled: ctrl.model.checkTaskDates(true)
								,onclick: () => {
									fetchedExtras(false)
									projectExtrasTypes()['completed'] = 'any'
									projectExtrasTypes()['invalid'] = 'any'
									projectExtrasTypes()['verified'] = 'any'
									projectExtrasTypes()['assigned'] = 'any'

									return getTasks(
										[]
										,[vm()
										,activeTab()
										,projectExtrasTypes()
										]
									)
									.then(function(){

										form().project.project_extras =
											form().project.project_extras
											.concat(
												R.unnest(
													form().project.disciplines
													.map((d) => {

														const taskDiff =
															R.differenceWith(
																(a, b) =>
																	a.project_extras_type()
																	== b.project_extras_type()
																,checkMultipleProjectExtras().data
																,form().project.project_extras.filter((t) =>
																	t.discipline_id()
																	== d.discipline_id()
																)
															)

														return taskDiff.map((t) => {
															pe_vm(t)
															const newt = cloningProjectE(true, false)
															newt.created = true
															newt.discipline_id(d.discipline_id())
															// newt.project_extras_actual_amount(0)
															// newt.project_extras_date_assigned(null)
															// newt.project_extras_date_completed(null)
															// newt.project_extras_date_verified(null)
															// newt.project_extras_date_invalid(null)
															newt.project_extras_description(d.discplineNameDescription)
															newt.relatedDiscipline = d.discplineNameDescription
															newt.organizations_disciplines_id = exposer(d.organizations_disciplines_id)
															projectExtrasSum(t, null)
															checkMultipleProjectExtras().data.unshift(newt)
															return newt
														})
													})
												)
											)

										disciplineSpread([])
										spreading(false)

										return masterPELIST(true)
									})
								}
								,title: "Spread tasks to all disciplines on the project"
							}
							,'Save'
						)
						,m(`label`, ` to All Disciplines`)
					])
					,elements.list([
						m(`label`, ` Replicate selected tasks across all disciplines on this project with the Spread button or select a specific few disciplines and click Save`)
					])
				]
				: null

				,m('hr')

				// ,processedTasksSelected()
				// ? `Completed or Invalid tasks are not able to change relations`
				// : ''

				,elements.list([
					m('label.control-label', 'Discipline ')
					,processedTasksSelected() && !spreading()
					? checkMultipleProjectExtras().data[0].relatedDiscipline
					: Pencil(
						() => checkMultipleProjectExtras().data[0].relatedDiscipline
						,() =>
							m(autocomplete.Main, {
								list: ctrl.model.restrictedOrgTask
								,onselect(v, model){

									if( spreading() ){

										disciplineSpread(disciplineSpread().concat(v))
										model.input('')
										model.chosen(null)

									}
									else {

										setOptions(v.orgDisciplineNameDescription, 'relatedDiscipline')
										setOptions(v.organizations_disciplines_id, 'organizations_disciplines_id')

										const discipline =
											ctrl.model.projectDisciplines()
											.find((d) =>
												exposer(d.organizations_disciplines_id)
												== exposer(v.organizations_disciplines_id)
											)

										discipline
											? setOptions(discipline.discipline_id(), 'discipline_id')
											: setOptions(false, 'discipline_id')

										setOptions(v.orgDisciplineNameDescription, 'project_extras_description')
									}
								}
								,field: 'orgDisciplineNameDescription'
								,attrs: {
									placeholder: ""
									,disabled: !ctrl.model.organizations_disciplines().length
										|| processedTasksSelected() && !spreading()
									,title: "Related discipline"
								}
								,sort: R.prop('completedcount')
							})
					)
				])

				,spreading()
				? elements.strikeList(
					disciplineSpread()
					.map(
						od => ({
							label: od.orgDisciplineNameDescription
							,action: () => {
								disciplineSpread()
								.splice(
									disciplineSpread()
									.findIndex((ds) =>
										ds.orgDisciplineNameDescription
										== od.orgDisciplineNameDescription
									)
									,1
								)
								disciplineSpread(disciplineSpread())
							}
						})
					)
				)
				: ''

				,elements.list([
					m('label.control-label', 'Task Name ')
					,Pencil(
						() => checkMultipleProjectExtras().data[0].project_extras_type()
						,() =>
							elements.textInput(
								checkMultipleProjectExtras().data.length >= 1
									? (value) => setOptions(value, 'project_extras_type')
									: t.project_extras_type
								,{}
							)
					)
				])

				,checkTaskDates()

				,m('label.control-label.mb1', 'Task Description')
				,checkMultipleProjectExtras().data.length > 1
					? [elements.list([
						[ R.countBy(
							R.identity
							,ctrl.model.checkMultipleProjectExtras().data
								.map( s =>
									s['project_extras_description']()
										? 											s['project_extras_description']()
											+ ' - '
											+ s['project_extras_actual_amount']()
											+ ' of '
											+ s['project_extras_planned_amount']()

										: s['project_extras_description']()
								)
						)
						]
						.map(
							idx => Object.keys(idx).map(
								k => m(
									'p.pv1.ma0'
									, k
									, idx[k] > 1
										? m('span.b.pl2'
											, { title:
												'There are '+idx[k]
													+ ' total tasks'
													+ ' of the same type and'
													+ ' completion within this'
													+ ' project.'
											}
											,`(x${idx[k]})` )
										: null

								)
							)
						)
						.pop()
					])]
					.map(
						// todo-james use hickery
						list => {
							list.attrs.style =
								Object.assign( list.attrs.style || {}, {
									maxHeight: '15em'
									,overflowY: 'auto'
								})
							return list
						}
					)
					.pop()
					: elements.list([
						Pencil(
							() =>
								checkMultipleProjectExtras().data[0].project_extras_description()
								? 									checkMultipleProjectExtras().data[0].project_extras_description()
									+ ' - '
									+ checkMultipleProjectExtras().data[0].project_extras_actual_amount()
									+ ' of '
									+ checkMultipleProjectExtras().data[0].project_extras_planned_amount()

								: checkMultipleProjectExtras().data[0].project_extras_description()
							,() =>
								elements.textArea(
									checkMultipleProjectExtras().data.length >= 1
										? (value) => setOptions(value, 'project_extras_description')
										: t.project_extras_description
									,{
										placeholder: "Description of the task"
									}
								)
						)
					])

				// Need to check why timetravel is evaded when proposed amount is changed with actual amount
				,elements.list([
					m('label.control-label', 'Proposed Amount  ')
					, Pencil(
						() =>
							R.sum(
								checkMultipleProjectExtras().data
								.map((t) => t.project_extras_planned_amount())
							)

						,() =>
							m(
								NumberInput
								,R.merge(
									ctrl.model.data
									,{
										errors: ctrl.model.errors
										,errorLabel: 'Task Amount'
									}
								)
								,{
									prop: (value) => setOptions(value, 'project_extras_planned_amount')
									,attrs: {
										min: 0
										, step: 0.01
										, title: "Task Amount"
									}
								}
							)
					)
				])

				,elements.list([
					m('label.control-label', ' Actual Amount  ')
					,Pencil(
						() =>
							R.sum(
								checkMultipleProjectExtras().data
								.map((t) => t.project_extras_actual_amount())
							)

						,() =>
							m(
								NumberInput
								,R.merge(
									ctrl.model.data
									,{
										errors: ctrl.model.errors
										,errorLabel: 'Task Actual Amount'
									}
								)
								,{
									prop: (value) => linkCompeltion(value)
									,attrs: {
										min: 0
										, step: 0.01
										, title: "Task Actual Amount"
									}
								}
							)
					)
				])


				,metaDataLoading()
				? elements.centeredSpinner()
				: elements.list([
					m('label.control-label', newUser() ? 'Invite ' : 'Assigned ')
					,!newUser()
					? Pencil(
						() =>
							users().flatMap((u) =>
								!checkMultipleProjectExtras().data[0].invite_email
								&& u.user_id()
								== checkMultipleProjectExtras()
									.data[0].user_id()
								? [u]
								: []
							)
							.map( u => u.usersFullName )
							.shift()
							|| checkMultipleProjectExtras().data[0].invite_email

						,() =>
							autocomplete.strict(
								ctrl.model.users
								,(v) => {
									if (v){

										const editList =
											R.groupBy(
												(v) => exposer(v.organizations_disciplines_id)
												,checkMultipleProjectExtras().data
												.filter((v) => exposer(v.organizations_disciplines_id))
											)

										if( Object.keys(editList).length ){

											Object.keys(editList).forEach((orgdisciplineId) => {

												const newTeamResource =
													ctrl.model.allResourceTypes()
													.find((r) =>
														r.user_id()
														== v.user_id()
													)


												const theDiscipline =
													form().project.disciplines
													.find((d) =>
														d.organizations_disciplines_id()
														== orgdisciplineId
													)

												if( theDiscipline ){

													R.uniqBy(
														R.pipe(R.prop('user_id'), R.call)
														,checkMultipleProjectExtras().data
													)
													.filter((u) =>
														u.user_id()
														&& u.user_id() != v.user_id()
													)
													.forEach((u) => {

														const exisingTeamResource =
															[].concat(
																theDiscipline.disciplines_contractors
																,R.unnest(
																	theDiscipline
																	.disciplines_contractors
																	.map((dc) => dc.disciplines_contractors_crews)
																)
															)
															.find((dcc) =>
																dcc.user_id()
																== u.user_id()
															)

														exisingTeamResource
														&& ctrl.model.form().project.project_extras
														.filter((t) =>
															t.user_id()
															== exisingTeamResource.user_id()
															&& exposer(t.organizations_disciplines_id)
															== orgdisciplineId
														).length
														== editList[orgdisciplineId].length
															? ctrl.model.deleteDisciplineC({
																entity: exisingTeamResource
																,disciplineSpecific: theDiscipline
																,newTeamResource
															})
															: null

													})

													if( newTeamResource && theDiscipline){

														const totalRates =
															newTeamResource.crew_id
																? newTeamResource.crews_discipline_rates
																: R.unnest(
																	newTeamResource.crews.map((c) =>
																		c.crews_discipline_rates
																	)
																)

														totalRates.find((c) =>
															c.organizations_disciplines_id()
															== theDiscipline.organizations_disciplines_id()
														)
															? ctrl.model.dc_mapping(newTeamResource, [theDiscipline])
															: null

													}
												}

											})

										}

										setOptions(false, 'invite_id')
										setOptions(false, 'invite_email')

										v.user_username() != 'Unassigned'
											? setOptions(v.user_id(), 'user_id')
											: setOptions(false, 'user_id')

										v.user_username() != 'Unassigned'
											? setOptions(true, 'project_extras_custom')
											: setOptions(false, 'project_extras_custom')

										setOptions(v.user_username() , 'username')

										v.user_username() != 'Unassigned'
											? null // ? setOptions(Date.now(), 'project_extras_date_assigned')
											: setOptions(false, 'project_extras_date_assigned')
									}
									return v
										? v.usersFullName
										: (
											users().find((u) =>
												u.user_id()
												== checkMultipleProjectExtras().data[0].user_id()
											) || nullUser
										)
										.usersFullName
								}
								,'usersFullName'
								, () => ({
									style: {
										height: '3em'
									}
								})
							)
					)
					: m(''
						+ css`
							display: grid;
							grid-template-columns: 1fr max-content;
							gap: 0.5em;
							align-items: center;
							stroke-width: 2px;
						`
						,elements.textInput(
							(v) =>  setOptions(v, 'invite_email')
							,{placeholder: "Email of invitee"}
						)
						,m(`button`
							+ css`
								border-radius: 0.5em;
								background-color: white;
								border: solid 0.1px rgba(0,0,0,0.4);
								width: 1.8em;
								height: 1.8em;
								grid-template-columns: 0.8fr;
								justify-content: center;
								align-items: center;
							`
							,{
								onclick: () => {
									newUser(false)
								}
							}
							, m(''+css`
								stroke-width: 1px;
								color: #777;
							`, elements.icon.x() )
						)
					)

					,editPermissions() && !newUser()
					? m('button.btn.btn-secondary',
						{
							style: {
								backgroundColor: 'transparent'
								,border: 'solid 1px #3380c2'
								,position: 'relative'
								,top: '-0.1em'
								,height: '2.9em'
								,width: '5em'
								,color: '#434aa3'
								,textDecoration: 'underline'
							}
							,disabled: newUser()
							,onclick: () => { newUser(true) }
							,title: "Invite someone new to the organization to work on this task"
						}
						,'Invite'
					)
					: null
				])

				,elements.list([
					elements.dateCheckboxLabel({
						label: 'Assignment Date'
						,date: {
							title: 'Record Assignment Date'
							, prop: checkMultipleProjectExtras().data.length >= 1
								? (value) => setOptions(!value && value !== undefined ? false : value, 'project_extras_date_assigned')
								: t.project_extras_date_assigned
							, attrs: {
								disabled: !ctrl.model.editPermissions()
							}
						}
						,checkbox:{
							title: 'Mark Assignment Date'
							, prop: checkMultipleProjectExtras().data.length >= 1
								? (value) => setOptions(!value && value !== undefined ? false : value, 'project_extras_date_assigned')
								: t.project_extras_date_assigned
							, attrs: {
								onchange:
									m.withAttr('checked', function(e){
										const value =
											e
												? Date.now()
												: false
										setOptions(value, 'project_extras_date_assigned')
									})
									,disabled: !ctrl.model.editPermissions()
							}
						}
					})
				])

				,elements.list([
					elements.dateCheckboxLabel({
						label: 'Completed Date'
						,date: {
							title: 'Record Completed Date'
							, prop:
								(v) => updateCompletedDate({e: v, value: v, task: checkMultipleProjectExtras().data[0]})
							, attrs: {
								disabled: !ctrl.model.editPermissions()
							}
						}
						,checkbox:{
							title: 'Mark Completed Date'
							, prop:
								(v) => updateCompletedDate({e: v, value: v, task: checkMultipleProjectExtras().data[0]})
							, attrs: {
								onchange:
									m.withAttr('checked', function(e){
										const value = e ? Date.now() : false
										updateCompletedDate({ e, value, task: checkMultipleProjectExtras().data[0]})
									})
									,disabled: !ctrl.model.editPermissions()
							}
						}
					})
				])

				,elements.list([
					elements.dateCheckboxLabel({
						label: 'Verified Date'
						,date: {
							title: 'Record Verified Date'
							, prop: checkMultipleProjectExtras().data.length >= 1
								? (value) => setOptions(!value && value !== undefined ? false : value, 'project_extras_date_verified')
								: t.project_extras_date_verified
							, attrs: {
								disabled:
									!ctrl.model.editPermissions()
									|| checkMultipleProjectExtras().data.some((t) =>
										!t.project_extras_date_completed() && !t.project_extras_date_invalid()
									)
							}
						}
						,checkbox:{
							title: 'Mark Verified Date'
							, prop: checkMultipleProjectExtras().data.length >= 1
								? (value) => setOptions(!value && value !== undefined ? false : value, 'project_extras_date_verified')
								: t.project_extras_date_verified
							, attrs: {
								onchange:
									m.withAttr('checked', function(e){
										const value =
											e
												? Date.now()
												: false
										setOptions(value, 'project_extras_date_verified')
									})
									,disabled:
										!ctrl.model.editPermissions()
										|| checkMultipleProjectExtras().data.some((t) =>
											!t.project_extras_date_completed() && !t.project_extras_date_invalid()
										)
							}
						}
					})
				])

				,elements.list([
					elements.dateCheckboxLabel({
						label: 'Invalid Date'
						,date: {
							title: 'Record the date this task turned invalid'
							, prop:
								(v) => updateInvalidDate({e: v, value: v, task: checkMultipleProjectExtras().data[0]})
							, attrs: {
								disabled: !ctrl.model.editPermissions()
							}
						}
						,checkbox:{
							title: 'Mark the date this task turned invalid '
							, prop:
								(v) => updateInvalidDate({e: v, value: v, task: checkMultipleProjectExtras().data[0]})
							, attrs: {
								onchange:
									m.withAttr('checked', function(e){
										const value =
											e
												? Date.now()
												: false

										updateInvalidDate({ e, value, task: checkMultipleProjectExtras().data[0]})

									})
									,disabled: !ctrl.model.editPermissions()
							}
						}
					})
				])


				,elements.list([
					m('label.control-label', 'Priority ')
					,Pencil(
						() => checkMultipleProjectExtras().data[0].project_extras_priority()
						,() =>
							m(
								NumberInput
								,R.merge(
									ctrl.model.data
									,{
										errors: ctrl.model.errors
										,errorLabel: 'Task Priority'
									}
								)
								,{
									prop: (value) => setOptions(value, 'project_extras_priority')
									,attrs: {
										min: 0
										, title: "Task Priority"
									}
								}
							)
					)
				])

				,elements.list([
					m('label.control-label', 'Repeat Every ')
					,Pencil(
						() => checkMultipleProjectExtras().data[0].project_extras_frequency()
						,() =>
							m(
								NumberInput
								,R.merge(
									ctrl.model.data
									,{
										errors: ctrl.model.errors
										,errorLabel: 'Task Frequency'
									}
								)
								,{
									prop: (value) => setOptions(value, 'project_extras_frequency')
									,attrs: {
										min: 0
										, title: "Task Frequency"
									}
								}
							)
					)
					,m('label.control-label', ' Days')
				])

				,elements.list([
					m('label.control-label', 'Task Notes ')
					,Pencil(
						() => checkMultipleProjectExtras().data[0].project_extras_notes()
						,() =>
							elements.textArea(
								checkMultipleProjectExtras().data.length >= 1
									? (value) => setOptions(value, 'project_extras_notes')
									: t.project_extras_notes
								,{placeholder: "Task notes"}
							)
					)
				])

				,m('label.control-label', 'Address ')
				,Pencil(
					() => checkMultipleProjectExtras().data[0].project_extras_address()
					,() =>
						m(autocomplete.Main, {
							field: 'title'
							,throttleSearch: 500
							,inputClassName: 'form-control'
							,initialValue: checkMultipleProjectExtras().data[0].project_extras_address()
							,attrs: {
								placeholder: '14 Lonsdale St, Melbourne, Victoria'
								,name: 'project_extras_address'
								,required: true
								,value: checkMultipleProjectExtras().data[0].project_extras_address()
							}
							,oninput: x => setOptions(x, 'project_extras_address')
							,manuelConfig: {
								filter: () => true
							}
							,search(value){
								if(value){
									return data.api.suburbs.search(value)
								} else {
									return []
								}
							}
							,onselect(value){
								setOptions(value.title, 'project_extras_address')
								// collect data as we use it for later features
								data.api.suburbs.here.patch(value)
								// eslint-disable-next-line no-undef
								.catch( e => console.error('Could not save here data ', e))
							}
						})
				)

				,m('br')
				,m('label.control-label', 'Expenditure Ledgers ')
				,elements.list([
					R.uniq(
						R.unnest(
							ctrl.model.checkMultipleProjectExtras()
							.data
							.map((i) => i.costinvoices)
						)
					)
					.map(s => m('p.pv1.ma0',s))
				])

				,m('label.control-label', 'Revenue Ledgers ')
				,elements.list([
					R.uniq(
						R.unnest(
							ctrl.model.checkMultipleProjectExtras()
							.data
							.map((i) => i.revenueinvoices)
						)
					)
					.map(s => m('p.pv1.ma0',s))
				])
				,m('br')

				,checkMultiple().length > 1
				? elements.alert(`warning`, `Files must be uploaded to one task at a time`)
				: m(HarthUppy, {
					getFields: () => ctrl.model.uploadmetadata({uploadbox:'taskfiles'})
					, data: ctrl.model.data
				})

			]
		}

		function projectOptionsView(){
			return [

				!ctrl.model.discipline_vm().discipline_name
				? [

					m('br')

					,m(''
						+ css`
							display: grid;
							grid-auto-flow: column;
							grid-template-rows: 1fr;
							grid-template-columns: 0.3fr 0.3fr 0.4fr;
							gap: 1em;
							padding: 0.5em;
							margin-bottom: 3em
						`
						,[
							elements.undoDiscard({
								discard: null
								,doneUndo: {
									label: 'Done'
									,attrs: {
										onclick: () => {
											projectOptions(false)
											checkMultipleProjects([])
											ctrl.model.vm({})
										}
										,title: `Done`
										,style: {
											opacity: ctrl.model.editPermissions() ? 1 : 0
											,transition: '0.5s'
											,width: '5em'
										}
									}
								}
							})

							,elements.confirmDelete(
								ctrl.intent.deleteProject
								,ctrl.model.savingProp
								,() => ctrl.model.checkMultipleProjects().length == 0
								,ctrl.model.deleteStateProp
							)
						]
					)


					,m('hr')

					,inverseProjectsList().length
					? m(`label`,
						ctrl.model.completedProjects() == "completed"
							? 'Current Projects'
							: 'Completed Projects'
					)
					: null
					,inverseProjectsList()
					.map(R.pick(['project_name', 'project_id']))
					.map((s, sIndex) =>
						m('p.pv1.ma0',
							m('a'
								,{
									title: 'Move to current projects'
									,onclick: () => {
										return ctrl.model.data.fetchProjectsBySchedule({
											schedule_id: ctrl.model.schedule_id()
											,project_id: s.project_id()
										})
											.then((res) => {
												const project = R.last(res)
												projects().unshift(project)
												inverseProjectsList().splice(sIndex, 1)
												inverseProjectsList(inverseProjectsList())
												ctrl.model.vm(project)
												ctrl.model.cloningProject(false, true)
												ctrl.model.projectOptions(false)
												ctrl.model.checkMultiple().data = []
												ctrl.model.checkMultipleProjectExtras().data = []
												ctrl.intent.fetchAllocations()
												ctrl.model.viewType(EditProject)
												ctrl.model.activeTab('Tasks')
												ctrl.model.edit(true)
												ctrl.model.masterLIST(true)
												ctrl.model.masterPELIST(true)
												ctrl.model.fetchedExtras(false)
												ctrl.model.disciplineViewSet('Working Details')
												ctrl.model.data.routeids({
													routeids: [].concat(ctrl.model.vm().disciplines, ctrl.model.vm()).map(R.prop('contractrecognitionid'))
													,getledgers: true
													,ledgerdetails: true
													,getmatdata: true
													,specificproject: [ctrl.model.vm()['contractrecognitionid']]
												})
												return res
											})
									}
								}
								,sIndex + 1 + '. ' + s.project_name()
							)
						)
					)
					,m('hr')
				]
				: null


				,completedProjects()
				== 'inprogress'
				&& !ctrl.model.discipline_vm().discipline_name
				? [
					ctrl.intent.reOrganizeProjects()
					,m('hr')
				]
				: null

				,completedProjects()
				== 'inprogress'
				? [
					elements.list([
						m('button.btn.btn-secondary', {
							disabled:
								!ctrl.model.checkMultipleProjects().length
								|| ctrl.model.metaDataLoading()
								|| !!ctrl.model.discipline_vm().discipline_id
							,style: {
								backgroundColor: 'transparent'
								,border: 'solid 1px #3380c2'
								,position: 'relative'
								,top: '-0.1em'
								,height: '2.9em'
								,width: '5em'
								,color: '#434aa3'
								,textDecoration: 'underline'
							}
							,onclick: () => {
								// set the default discipline here
								// and open pane when assigned

								var newD = ctrl.model.resetDForm().discipline[0]
								ctrl.model.discipline_vm(newD)
								ctrl.model.checkMultiple().data.push(newD)
							}
						}, "Adjust" )
						,m(`label`, ` Disciplines`)
					])

					,m(
						'label'
						,`Selected projects can be quickly adjusted to absorb, remove or modify common disciplines`
					)

					,ctrl.model.discipline_vm().discipline_name
					? elements.list([
						elements.action(
							'Save'
							, ctrl.intent.bulkDisciplineAddition
							, ctrl.model.savingProp
							, 'primary'
							,() => !ctrl.model.discipline_vm().discipline_name()
 						)

						,elements.action(
							'Discard'
							, ctrl.intent.bulkDisciplineDeletion
							, ctrl.model.savingProp
							, 'warning'
							,() => !ctrl.model.discipline_vm().discipline_name()
								|| !ctrl.model.checkMultipleProjects()
									.some((p) =>
										p.disciplines.find((d) =>
											d.organizations_disciplines_id()
											== ctrl.model.discipline_vm().organizations_disciplines_id()
										)
									)
						)

						,m('button.btn.btn-warning', {
							disabled: false
							,onclick: () => {
								// clear discipline container and remove pane
								checkMultipleProjects()
								.map((p) =>
									p.disciplines = p.disciplines.filter((d) => d.discipline_id())
								)
								discipline_vm({})
								checkMultiple().data = []
							}
						}, "Discard" )

					])
					: null
					,m('hr')
				]
				: null

				,elements.list([
					incrimentTimeButton(ctrl)
					,timepush() > 0 ? ' + ' : ' '
					,Pencil(
						() => timepush()
						,() =>
							m(
								NumberInput
								,R.merge(
									data
									,{
										errors
										,errorLabel: 'Push Dates'
									}
								)
								,{
									prop: timepush
									,attrs: {
										step: 1
										, title: "Days this project should be moved backward or forward, negative amounts will pull the date forward and positive amounts will push it back"
									}
								}
							)
					)
					, 'Days'
				])
				,m('label', `Use the Push Dates button to move the project start and end date, positive amounts will push the project back and negative amounts will pull the date forward`)
				,m('hr')

				,!ctrl.model.availableSchedules().length
				? null
				: ctrl.model.metaDataLoading()
				? elements.centeredSpinner()
				: elements.list([
					m('button.btn.btn-secondary', {
						disabled:
							!ctrl.model.checkMultipleProjects().length
							|| ctrl.model.metaDataLoading()
							|| !ctrl.model.destinationSchedule().schedule_name
							|| !ctrl.model.availableSchedules().length
						,style: {
							backgroundColor: 'transparent'
							,border: 'solid 1px #3380c2'
							,position: 'relative'
							,top: '-0.1em'
							,height: '2.9em'
							,width: '5em'
							,color: '#434aa3'
							,textDecoration: 'underline'
						}
						,onclick: () => {
							ctrl.model.metaDataLoading(true)
							return ctrl.model.data.transferProjects({
								projects: ctrl.model.checkMultipleProjects().map((p) => p.project_id())
								,schedule_id: ctrl.model.destinationSchedule().schedule_id
							})
							.then(
								() =>
									ctrl.model.checkMultipleProjects()
									.map((p) => p.schedule_id(ctrl.model.destinationSchedule().schedule_id))
							)
							.then(ctrl.intent.fetchProjects)
							.then(() => {
								projectOptions(false)
								ctrl.model.metaDataLoading(false)
								return m.redraw()
							})
						}
					}, "Move" )
					,m('label', `Project to`)
					,Pencil(
						() => ctrl.model.destinationSchedule().schedule_name
						,() =>
							m(autocomplete.Main, {
							list: ctrl.model.availableSchedules
							,onselect: ctrl.model.destinationSchedule
							,field: 'schedule_name'
							,attrs: {
								title: `Transfer selected projects to a different schedule`
								,style: { height: '3em' }
								,key: D().key
							}
							,sort: R.prop('schedule_name')
						})
					)
				])
			]
		}

		return [

			detailsPaneSave()
			,checkMultipleProjectExtras().data.length
			&& ctrl.model.activeTab() == 'Tasks'
				? taskDetails(D)
				: D().discipline_id || checkMultiple().data.length
					? universal()
					: []


			,projectOptions() ? null : m(''+css`padding-top: 5em`, detailsPaneSave())
		]
	}

	var detailsPaneOpen = () =>

		checkMultiple().data.length >= 1
		&& viewType() != InitialProject
		&& activeTab() == 'Disciplines'

		|| checkMultipleProjectExtras().data.length >= 1
			&& activeTab() == 'Tasks'

		|| projectOptions()

	const dtableControl =
		() => m(''
			+ css`
				display: grid;
				grid-auto-flow: column;
				grid-template-rows: 1fr;
				grid-template-columns: 0.25fr 0.25fr 0.25fr 0.25fr;
				gap: 1em;
				padding: 0.5em;
				margin-bottom: 3em
			`
			,[
				'Financial Summary'
				,'Working Details'
				,'Resourcing'
				,'Operations and Recovery'
			]
			.map((h) =>
				m('a'+css(`opacity:` + (disciplineViewSet() != h ? 0.7 : 1))
					,{
						title: 'Show ' + h
						,onclick: () => { disciplineViewSet(h) }
					}
					,h
				)
			)
		)

    function editAllButton(){

		var refContainter = activeTab() == 'Tasks'
			? checkMultipleProjectExtras
			: checkMultiple

		var refVM = activeTab() == 'Tasks'
			? pe_vm
			: discipline_vm

		var errorFunction = activeTab() == 'Tasks'
			? R.F
			: check_dnames

		var originalArray = activeTab() == 'Tasks'
			? form().project.project_extras
			: form().project.disciplines

		var filteredContainer =
			activeTab() == 'Tasks'
				? filteredTaskOptions
				: filteredDisciplineOptions

        var activated =
			originalArray.length == refContainter().data.length
			&& originalArray.length
			|| filteredContainer().length == refContainter().data.length
			&& filteredContainer().length


		return elements.checkbox({
			onchange: () => {
				if( activated ){
					refContainter().data = []
					refVM({})
				}
				else {
					refContainter().data = []
					refVM({})
					refContainter().data =
						filteredContainer().length
							? filteredContainer()
							: originalArray
					refVM(refContainter().data[0])

					activeTab() == 'Tasks' ? null : resources_available()
				}
			}
			,title: "Select or De-select viewed items"
			,checked: activated
			,disabled: !!errorFunction(true) || !originalArray.length
		})
    }

	function getActivateContractDeleteButton(){

	}

	function getActivateDeleteButton(object, objectName){

		var refContainter = activeTab() == 'Tasks'
			? checkMultipleProjectExtras
			: checkMultiple

		var refVM = activeTab() == 'Tasks'
			? pe_vm
			: discipline_vm

		var errorFunction = R.F

		var activated =
			refContainter().data
			.findIndex( (chr) =>
					chr[ objectName +  '_description']() == object[ objectName +  '_description']()
					&& chr[ objectName +  '_id']() == object[ objectName +  '_id']()
					&& chr.key == object.key
			)

		var vmDetail = object.key == refVM().key

		return {
			disabled: !!errorFunction(true) || !editPermissions() && objectName == 'project_extras'
			,type: 'checkbox'
			,onclick: (v) => {
				if(!v.currentTarget.checked){
					refContainter().data.splice(activated, 1)
					if (vmDetail && refContainter().data.length >= 1 ){
						refVM(refContainter().data[0])
					} else if (vmDetail && refContainter().data.length <= 0){
						refVM({})
					}
					activeTab() == 'Tasks'
						? null
						: resources_available()
				}
				else {
					if ( refContainter().data.length > 0 ){
						refContainter().data.push(object)
					} else {
						refVM(object)
						refContainter().data.push(object)
					}
					activeTab() == 'Tasks'
						? null
						: resources_available()
				}
			}
			,checked: activated >= 0 || vmDetail
		}
	}

	const sameProperty = property => dc => chr =>
		chr[property]() == dc[property]()
	const sameContractorId = sameProperty('contractor_id')
	const sameCrewId = sameProperty('crew_id')

    function deleteDisciplineC({entity, disciplineSpecific, makeAPICalls, newTeamResource}){
		const disciplineEdits =	disciplineSpecific
			? R.uniqBy(
				R.invoker(0, 'discipline_id')
				,[disciplineSpecific].concat(
					checkMultiple().data
				)
			)
			: R.uniqBy(
				R.invoker(0, 'discipline_id')
				,checkMultiple().data
			)

		return makeAPICalls
			? Promise.all(
				deleteDisciplinesCrews().map((t) =>
					data.api.disciplines_contractors_crews.remove.one(
						t.disciplines_contractors_crews_id()
						,data.selectedVersion().schedule_version_id
						,t.crew_id()
						,t.discipline_id()
					)
				)
			)
				.then(function(){
					return Promise.all(
						deleteDisciplinesContractors().map((t) =>
							data.api.disciplines_contractors.remove.one(
								t.disciplines_contractors_id()
								,data.selectedVersion().schedule_version_id
								,t.contractor_id()
								,t.discipline_id()
							)
						)
					)
				})

			: entity.crew_id == null
				? disciplineEdits.map((d) => {
					const contractorIndex =
						R.findIndex(
							sameContractorId(entity),
							d.disciplines_contractors
						)

					if (contractorIndex > -1 ){
						!d.disciplines_contractors[contractorIndex]
								.disciplines_contractors_id()
							? null
							: deleteDisciplinesContractors().push(
								d.disciplines_contractors[contractorIndex]
							)

							d.disciplines_contractors
								.splice(contractorIndex, 1)

							if (d.disciplines_contractors.length == 0){
								d.discipline_custom(0)
							}
					}
					return contractorIndex
				})
				: disciplineEdits.map((d) => {

					const contractorIndex = R.findIndex(
						sameContractorId(entity),
						d.disciplines_contractors
					)

					if ( contractorIndex > -1 ) {

						const crewIndex = R.findIndex(
							sameCrewId(entity),
							d.disciplines_contractors[contractorIndex]
								.disciplines_contractors_crews
						)

						if ( crewIndex > -1 ){
							!d.disciplines_contractors[contractorIndex]
								.disciplines_contractors_crews[crewIndex]
									.disciplines_contractors_crews_id()
								? null
								: deleteDisciplinesCrews().push(
									R.merge(
										d.disciplines_contractors[contractorIndex]
											.disciplines_contractors_crews[crewIndex]
										,{
											discipline_id: d.disciplines_contractors[contractorIndex].discipline_id
										}
									)
								)

							d.disciplines_contractors[contractorIndex]
								.disciplines_contractors_crews
									.splice(crewIndex, 1)

							if (
								d.disciplines_contractors[contractorIndex].disciplines_contractors_crews.length == 0
								&& (!newTeamResource || (newTeamResource && newTeamResource.contractor_id() != d.disciplines_contractors[contractorIndex].contractor_id()))
							){
								d.disciplines_contractors.splice(contractorIndex, 1)
							}
							if (d.disciplines_contractors.length == 0){
								d.discipline_custom(0)
							}
						}
					}
					return contractorIndex
				}) && resources_available()
    }

	const moneytype =
		data.scoped({
			Accrued: 'Profits'
			,Received: 'Profits'
			,Forecasted: 'Profits'
			,Outstanding: 'Profits'
		})

	const moneydtype =
		data.scoped({
			Accrued: 'Profits'
			,Received: 'Profits'
			,Forecasted: 'Profits'
			,Outstanding: 'Profits'
		})

	const moneycycle =
		(h, d) =>
			m(''
				+ css`
					display: grid;
					grid-template-columns: 0.75fr 0.25fr;
					gap: 0.25em;
					align-items: center;
					margin-top: 0;
				`
				,m(`db.tl`, h + ' ' + (d ? moneydtype()[h] : moneytype()[h] ))
				,m('button'
					+ css`
						border-radius: 0.5em;
						background-color: white;
						padding: 0.3em;
						border: solid 0.1px rgba(0,0,0,0.4);
						width: 1.5em;
						height: 1.5em;
					`
					,{
						onclick: () => {
							if(d){
								return moneydtype()[h] == 'Profits'
								? moneydtype()[h] = 'Revenue'
								: moneydtype()[h] == 'Revenue'
								? moneydtype()[h] = 'Expenditures'
								: moneydtype()[h] = 'Profits'
							}
							else {
								return moneytype()[h] == 'Profits'
								? moneytype()[h] = 'Revenue'
								: moneytype()[h] == 'Revenue'
								? moneytype()[h] = 'Expenditures'
								: moneytype()[h] = 'Profits'
							}
						}
					}
					, ''
				)
			)

	const disciplinedata =
		(d) =>
			disciplineViewSet() == 'Resourcing'
			? {
				'Name - Description': d.discipline_name() + ' ' + ( d.discipline_description() || '' )
				,'Proposed Start': moment(d.discipline_start_date()).format("ll")
				,'Proposed End': moment(d.discipline_end_date()).format("ll")
				,'Amount': d.discipline_work()
				,'Completed': d.discipline_completed()
				,'Unit of Measure': d.discipline_uom()
				,'Daily Forecast': Number(d.workdayeffort).toFixed(1)
				,'Negligible Teams': d.badeffortteams ? d.badeffortteams.length : 0
				,'Resources': data.readResourcePermissions()
					? elements.inlinestrikeList(
						d.disciplines_contractors
							.map(
								label => ({
									label: label.disciplines_contractors_name()
									,action: () => {
										deleteDisciplineC({
											entity: label
											,disciplineSpecific: d
										})
									}
									,fstyle: R.intersection(d.badeffortteams, R.map(R.pipe(R.prop('crew_id'), R.call()), label.disciplines_contractors_crews)).length ? '.dark-red.fw6' : ''
								})
							)
					)
					: 'Requires Resource Permissions'
				,'Teams': data.readResourcePermissions()
					? elements.inlinestrikeList(
						working_crews(d)
							.map(
								label => ({
									label: label.disciplines_contractors_crews_name()
									,action: () => {
										deleteDisciplineC({
											entity: label
											,disciplineSpecific: d
										})
									}
									,fstyle: d.badeffortteams.find((a) => a == label.crew_id()) ? '.dark-red.fw6' : ''
								})
							)
					)
					: 'Requires Resource Permissions'
			}
			: disciplineViewSet() == 'Financial Summary'
			? {
				'Name - Description': d.discipline_name() + ' ' + ( d.discipline_description() || '' )
				,'Unit of Measure': d.discipline_uom()
				,'Variations - Delays - Extensions':
					(d.eotSum || '0')
					+ ` - `
					+ (d.nodSum || '0')
					+ ` - `
					+ (d.varSum || '0')
				,'Scheduled End Date': d.lastAllocationDate
					? moment(d.lastAllocationDate).format("ll")
					: ``
				,'End Date': moment(d.discipline_end_date()).format("ll")
				,'Build Complete (%)':
					d.discipline_completed() == 0
						? 0
						: d.discipline_completed() / d.discipline_work() >= 1
							? 100
					: (d.discipline_completed()/d.discipline_work() * 100)
						.toFixed(2)
				,'Rate Multiplier': d.discipline_contract_multiplier()
				,'Budget':
					!data.calculated()
						? ' ... '
						: makeMoney(
							(
								data.coalatedSelectedVersion()
									[d.contractrecognitionid]
								|| {}
							)
							.budget
						)
				,'Accrued':
					!data.calculated()
						? ' ... '
						: makeMoney(
							data.readContractPermissions()
							&& data.coalatedSelectedVersion()[d.contractrecognitionid]
							? data.coalatedSelectedVersion()[d.contractrecognitionid]['accrued' + moneydtype()['Accrued']]
								+ data.coalatedSelectedVersion()[d.contractrecognitionid]['outstanding' + moneydtype()['Outstanding']]

							: 0
						)
				,'Received':
					!data.calculated()
					? ' ... '
					: makeMoney(
						data.readContractPermissions()
						&& data.coalatedSelectedVersion()[d.contractrecognitionid]
						? data.coalatedSelectedVersion()[d.contractrecognitionid]['current' + moneydtype()['Received']]
						: 0
					)

				,'Forecasted' :
					!data.calculated()
						? ' ... '
						: makeMoney(
							(
								data.readContractPermissions()
								&& data.coalatedSelectedVersion()[d.contractrecognitionid]
								|| {}
							)['forecasted' + moneydtype()['Forecasted']]
						)
				,'Resources Allocated':
					data.readResourcePermissions()
						? elements.list(
						R.pipe(
							R.map(
								R.invoker(0, 'disciplines_contractors_name')
							)
						)
						(d.disciplines_contractors)
					)
						: 'Requires Resource Permissions'
			}
			:  disciplineViewSet() == 'Working Details'
			? {
				'Name - Description': d.discipline_name() + ' ' + ( d.discipline_description() || '' )
				,'Proposed Amount': d.discipline_planned()
				,'Total Amount': d.discipline_work()
				,'Completed Amount': d.discipline_completed()
				,'Unit of Measure': d.discipline_uom()
				,'Chronological Order': d.discipline_order()
				,'Proposed Start': moment(d.discipline_start_date()).format("ll")
				,'Strict Start': d.discipline_strict_start()
					? 'Yes'
					: 'No'
				,'Proposed End': moment(d.discipline_end_date()).format("ll")
				,'Strict End': d.discipline_strict_end()
					? 'Yes'
					: 'No'
			}
			: {
				'Name - Description': d.discipline_name() + ' ' + ( d.discipline_description() || '' )
				,'Amount': d.discipline_work()
				,'Unit of Measure': d.discipline_uom()
				,'Consolidate Schedule': d.discipline_consolidate_start()
					? 'Yes'
					: 'No'
				,'Consistent Resources': d.discipline_consistency()
					? 'Yes'
					: 'No'
				,'Recovery Permission': d.discipline_recovery()
					? 'Yes'
					: 'No'
				,'Auto Assign Tasks': d.discipline_auto_assign_tasks()
					? 'Yes'
					: 'No'
				,'Operational Regime': d.discipline_flow()
				,'Environmental Rating': d.discipline_harshness()
				,'Resource Combination Limit': d.discipline_recovery()
					? d.discipline_combination_limit()
					: ''
			}


	const largeDisciplinesTable = () =>
		[
			dtableControl()
			,scrollableTable.advanced({
				scoped: data.scoped
				,resize$: data.resize$
				,alwaysopen: true
				,rows:
					masterLIST()
					.map((d, tindex) => ({
						key: d.key
						,selection: getActivateDeleteButton(d, 'discipline')
						,header:
							tindex != 0
							? ''
							: m('.header'
								+ css`
									font-weight: bold;
									display: flex;
									justify-content: space-between;
									align-items: center;
									padding-bottom: 0.5em;
								`
								,m('span'
									+ css`
										font-weight: bold;
									`
									,''
								)
								,m('.data'
									+ css`
										display: grid;
										grid-template-rows: 0.25fr 0.5fr;
										overflow: hidden;
									`
									,m(`button.pl2`
										+ css`
											background-color: transparent;
											transition: 0.2s;
											color: #434aa3;
											border: none;
										`
										.$hover(`
											opacity: 0.8;
										`)
										.$active(`
											opacity: 0.5;
										`)
										,{
											onclick: addNewDiscipline
											,disabled: false
											,title: "Create a Discipline"
										}
										, 'Create a New Discipline'
									)
								)
								,m('span'
									+ css`
										font-weight: bold;
									`
									,''
								)
							)
						,layouts: {
							expanded: ({ x, headers, i }) =>
								m('.expanded'
									+ css`
										display: grid;
										justify-content: space-between;
										grid-template-columns: 1fr;
									`
									, m('.data'
										+ css(`
											display: grid;
											justify-content: space-between;
											grid-template-columns: 1.25fr `
												+ (
													disciplineViewSet() == 'Resourcing'
													? `0.375fr 0.375fr 0.375fr 0.375fr 0.375fr 0.375fr 0.375fr 1fr 2fr;`
													: disciplineViewSet() == 'Financial Summary'
													? `0.5fr 1fr 1fr 0.75fr 0.75fr 0.85fr 0.55fr 1.2fr 1.2fr 1.2fr 1.5fr;`
													:  disciplineViewSet() == 'Working Details'
													? `1fr 0.75fr 0.75fr 0.75fr 0.75fr 1.25fr 0.75fr 1.25fr 0.75fr;`
													: `0.5fr 0.5fr 1fr 1fr 1fr 1fr 1fr 0.875fr 0.875fr;`
												)
											+ ` overflow: hidden;
											gap: 1em;
											align-items: start;
											align-content: start;
										`)
										, headers
											.map(
												k => [
													i == 0
													? m('.data'
														+ css`
															display: grid;
															grid-template-rows: 0.25fr 0.5fr;
															overflow: hidden;
														`
														,[
															m('label.control-label'
																, k == 'Accrued'
																|| k == 'Received'
																|| k == 'Forecasted'
																? moneycycle(k, true)
																: k
															)
															, x[k]
														]
													)
													: m('.data'
														+ css`
															display: grid;
															overflow: hidden;
														`
														,x[k]
													)
												]
											)

									)
								)
						}
						,data: disciplinedata(d)
					}))
			})
		]

	const smallDisciplinesTable = () =>
		[
			dtableControl()
			,scrollableTable.advanced({
				scoped: data.scoped
				,resize$: data.resize$
				,alwaysopen: true
				,rows:
					masterLIST()
					.map((d) => ({
						key: d.key
						,header:
							m('span'
								+ css`
									font-weight: bold;
									display: flex;
									justify-content: space-between;
									align-items: center;
									padding-bottom: 0.5em;
								`
								,m('input'
									+ css`
										width: 25px;
										height: 25px;
										margin: 0px;
									`
									.$media('(hover: hover)',
										css`
											width: 20px;
											height: 20px;
										`
									)
									,getActivateDeleteButton(d, 'discipline')
								)
								,m(`button.pl2`
									+ css`
										background-color: transparent;
										transition: 0.2s;
										opacity: 1;
										color: #434aa3;
										border: none;
									`
									.$hover(`
										opacity: 0.8;
									`)
									.$active(`
										opacity: 0.5;
									`)
									,{
										onclick: addNewDiscipline
										,disabled: false
										,title: "Create a Discipline"
									}
									, 'Create a New Discipline'
								)
								,m('span'
									+ css`
										font-weight: bold;
									`
									,''
								)
							)
						,layouts: {
							expanded:
								disciplineViewSet() == 'Resourcing'
								? ({ x }) =>
									m('.expanded'
										+ css`
											display: grid;
											justify-content: space-between;
											grid-template-columns: 1fr;
										`
										,m('.data'
											+ css`
												display: grid;
												justify-content: space-between;
												grid-template-columns: 0.5fr 0.25fr 0.125fr 0.125fr;
												padding-bottom: 2em;
											`
											,['Name - Description', 'Unit of Measure', 'Amount', 'Completed']
											.map((k) =>
												m('.data'
													+ css`
														display: grid;
														grid-template-rows: 0.25fr 0.25fr;
														padding-right: 0.25em;
													`
													,[
														m('label.control-label.f4', k)
														,x[k]
													]
												)
											)
										)

										, m('.data'
											+ css`
												display: grid;
												justify-content: space-between;
												grid-template-columns: 0.25fr 0.25fr 0.25fr 0.25fr;
												padding-bottom: 1em;
											`
											,['Proposed Start', 'Proposed End', 'Daily Forecast', 'Negligible Teams']
											.map((k) =>
												m('.data'
													+ css`
														display: grid;
														grid-template-rows: 0.25fr 0.25fr;
														padding-right: 0.5em;
													`
													,[m('label.control-label.f4', k), x[k]]
												)
											)
										)

										, m('.data'
											+ css`
												display: grid;
												justify-content: space-between;
												grid-template-columns: 0.5fr 0.5fr;
												padding-bottom: 1em;
											`
											,['Resources', 'Teams']
											.map((k) =>
												m('.data'
													+ css`
														display: grid;
														grid-template-rows: 0.25fr 0.25fr;
														padding-right: 0.5em;
													`
													,[m('label.control-label.f4', k), x[k]]
												)
											)
										)

									)
								: disciplineViewSet() == 'Financial Summary'
								? ({ x }) =>
									m('.expanded'
										+ css`
											display: grid;
											justify-content: space-between;
											grid-template-columns: 1fr;
										`
										,m('.data'
											+ css`
												display: grid;
												justify-content: space-between;
												grid-template-columns: 0.5fr 0.25fr 0.25fr;
												padding-bottom: 2em;
											`
											,['Name - Description', 'Unit of Measure', 'Build Complete (%)']
											.map((k) =>
												m('.data'
													+ css`
														display: grid;
														grid-template-rows: 0.25fr 0.25fr;
														padding-right: 0.25em;
													`
													,[
														m('label.control-label.f4', k)
														,x[k]
													]
												)
											)
										)


										, m('.data'
											+ css`
												display: grid;
												justify-content: space-between;
												grid-template-columns: 0.25fr 0.25fr 0.25fr 0.25fr;
												padding-bottom: 1em;
											`
											,['Variations - Delays - Extensions', 'Rate Multiplier', 'End Date', 'Scheduled End Date']
											.map((k) =>
												m('.data'
													+ css`
														display: grid;
														grid-template-rows: 0.25fr 0.25fr;
														padding-right: 0.5em;
													`
													,[m('label.control-label.f4', k), x[k]]
												)
											)
										)

										, m('.data'
											+ css`
												display: grid;
												justify-content: space-between;
												grid-template-columns: 0.25fr 0.25fr 0.25fr 0.25fr;
												padding-bottom: 1em;
											`
											,['Budget', 'Accrued', 'Received', 'Forecasted']
											.map((k) =>
												m('.data'
													+ css`
														display: grid;
														grid-template-rows: 0.25fr 0.25fr;
														padding-right: 0.5em;
													`
													,[
														m('label.control-label'
															, k == 'Accrued'
															|| k == 'Received'
															|| k == 'Forecasted'
															? moneycycle(k, true)
															: k
														)
														, x[k]
													]
												)
											)
										)

										, m('.data'
											+ css`
												display: grid;
												justify-content: space-between;
												grid-template-columns: 1fr;
												padding-bottom: 1em;
											`
											,['Resources Allocated']
											.map((k) =>
												m('.data'
													+ css`
														display: grid;
														grid-template-rows: 0.25fr 0.25fr;
														padding-right: 0.5em;
													`
													,[m('label.control-label.f4', k), x[k]]
												)
											)
										)

									)
								: disciplineViewSet() == 'Working Details'
								? ({ x }) =>
									m('.expanded'
										+ css`
											display: grid;
											justify-content: space-between;
											grid-template-columns: 1fr;
										`
										,m('.data'
											+ css`
												display: grid;
												justify-content: space-between;
												grid-template-columns: 0.75fr 0.25fr;
												padding-bottom: 2em;
											`
											,['Name - Description', 'Unit of Measure']
											.map((k) =>
												m('.data'
													+ css`
														display: grid;
														grid-template-rows: 0.25fr 0.25fr;
														padding-right: 0.25em;
													`
													,[
														m('label.control-label.f4', k)
														,x[k]
													]
												)
											)
										)

										, m('.data'
											+ css`
												display: grid;
												justify-content: space-between;
												grid-template-columns: 0.25fr 0.25fr 0.25fr 0.25fr;
												padding-bottom: 1em;
											`
											,['Proposed Amount', 'Total Work', 'Actual Amount', 'Chronological Order']
											.map((k) =>
												m('.data'
													+ css`
														display: grid;
														grid-template-rows: 0.25fr 0.25fr;
														padding-right: 0.5em;
													`
													,[m('label.control-label.f4', k), x[k]]
												)
											)
										)

										, m('.data'
											+ css`
												display: grid;
												justify-content: space-between;
												grid-template-columns: 0.25fr 0.25fr 0.25fr 0.25fr;
												padding-bottom: 1em;
											`
											,['Proposed Start', 'Strict Start', 'Proposed End', 'Strict End']
											.map((k) =>
												m('.data'
													+ css`
														display: grid;
														grid-template-rows: 0.25fr 0.25fr;
														padding-right: 0.5em;
													`
													,[m('label.control-label.f4', k), x[k]]
												)
											)
										)
									)
									: ({ x }) =>
										m('.expanded'
											+ css`
												display: grid;
												justify-content: space-between;
												grid-template-columns: 1fr;
											`
											,m('.data'
												+ css`
													display: grid;
													justify-content: space-between;
													grid-template-columns: 0.5fr 0.25fr 0.25fr;
													padding-bottom: 2em;
												`
												,['Name - Description', 'Unit of Measure', 'Amount']
												.map((k) =>
													m('.data'
														+ css`
															display: grid;
															grid-template-rows: 0.25fr 0.25fr;
															padding-right: 0.25em
														`
														,[
															m('label.control-label.f4', k)
															,x[k]
														]
													)
												)
											)


											, m('.data'
												+ css`
													display: grid;
													justify-content: space-between;
													grid-template-columns: 0.25fr 0.25fr 0.25fr 0.25fr;
													padding-bottom: 1em;
												`
												,['Amount', 'Consolidate Schedule', 'Consistent Resources', 'Recovery Permission']
												.map((k) =>
													m('.data'
														+ css`
															display: grid;
															grid-template-rows: 0.25fr 0.25fr;
															padding-right: 0.5em;
														`
														,[m('label.control-label.f4', k), x[k]]
													)
												)
											)

											, m('.data'
												+ css`
													display: grid;
													justify-content: space-between;
													grid-template-columns: 0.25fr 0.25fr 0.25fr 0.25fr;
													padding-bottom: 1em;
												`
												,['Auto Assign Tasks', 'Operational Regime', 'Environmental Rating', 'Resource Combination Limit']
												.map((k) =>
													m('.data'
														+ css`
															display: grid;
															grid-template-rows: 0.25fr 0.25fr;
															padding-right: 0.5em;
														`
														,[m('label.control-label.f4', k), x[k]]
													)
												)
											)
										)

						}
						,data: disciplinedata(d)
					}))
			})
		]


	const disciplinesTable = () =>
		m(Responsive
			,
			{
				breakpoints: {
					mobile:0
					,desktop:900
					,tablet:700
					,hd:1800
				}
			}
			,({ hd, desktopOnly, mobileOnly, tabletOnly }) => [
				hd( largeDisciplinesTable )
				,desktopOnly( largeDisciplinesTable  )
				,mobileOnly( smallDisciplinesTable )
				,tabletOnly( smallDisciplinesTable )
			]
		)


	const disciplineViewSet = data.scoped('Resourcing')
	function disciplineView(){
		return [
			m('br')
			,!form().project.disciplines.length || !disciplineViewSet()
				? elements.alert('info',`Create some discipline that will contain work in this project`)
				: disciplinesTable()
		]
	}

	const cloneLink =
		(ctrl, project) => {

			vm(project)
			editPermissions()
				? checkMultipleProjects().push(project)
				: checkMultipleProjects([project])

			cloning(true)
			cloningProject(true, false)
			projectOptions(false)
			checkMultiple().data = []
			checkMultipleProjectExtras().data = []
			ctrl.intent.fetchAllocations()
			ctrl.model.viewType(EditProject)
			projectExtrasTypes()['completed'] = 'any'
			projectExtrasTypes()['invalid'] = 'any'
			projectExtrasTypes()['assigned'] = 'any'
			projectExtrasTypes()['verified'] = 'any'
			activeTab('Tasks')
			edit(false)
			masterLIST(true)
			masterPELIST(true)
			fetchedExtras(false)
			disciplineViewSet('Working Details')
			onProjectList(false)
		}

	const editLink =
		(ctrl, project, setactiveTab='Tasks') => {

			vm(project)
			editPermissions()
				? checkMultipleProjects().push(project)
				: checkMultipleProjects([project])

			cloningProject(false, true)
			projectOptions(false)
			checkMultiple().data = []
			checkMultipleProjectExtras().data = []
			showUpload(false)
			ctrl.intent.fetchAllocations()
			ctrl.model.viewType(EditProject)
			activeTab(setactiveTab)
			edit(true)
			masterLIST(true)
			masterPELIST(true)
			fetchedExtras(false)
			disciplineViewSet('Resourcing')
			reconloading(true)
			data.routeids({
				routeids:
					[].concat(vm().disciplines, vm())
					.map(R.prop('contractrecognitionid'))
				,getledgers: true
				,ledgerdetails: true
				,getmatdata: true
				,specificproject: [vm()['contractrecognitionid']]
			})
			onProjectList(false)

			if( ['Tasks','Reconciliation'].includes(setactiveTab) ) {
				setTimeout(() => {
					let $
					$= document.querySelector('table,.scrollable-table-3')

					$= $ && $.scrollIntoView({
						behavior: "smooth", block: "start", inline: "nearest"
					})
					return $
				}, 500)
			}
		}

	const addNewTaskLink =
		(ctrl, project, setactiveTab='Tasks') => {

			vm(project)
			checkMultipleProjects([project])

			cloningProject(false, true)
			projectOptions(false)
			activeTab(setactiveTab)

			addNewTask()
		}

	const addNewTask =
		() => {
			disciplineSpread([])
			spreading(false)
			masterLIST(true)
			const newD = resetPEForm()
			newD.created = true
			checkMultipleProjectExtras().data = []
			pe_vm(newD)
			form().project.project_extras.unshift(newD)
			masterPELIST(true)
			checkMultipleProjectExtras().data.unshift(newD)
		}


	const addNewDiscipline =
		() => {
			const newD = resetDForm().discipline[0]
			newD.created = true
			checkMultiple().data = []
			discipline_vm(newD)
			form().project.disciplines.unshift(newD)
			masterLIST(true)
			checkMultiple().data.unshift(newD)
			activeTab('Disciplines')
		}

	const discardAllTaskChanges =
		() => {
			form().project.project_extras = []
			if (edit() == true){
				var nonEditedExtras =
					R.filter(
						(c) => !c.created
						,form().project.project_extras
					)

				projectExtrasStat({
					project_extras_date_assigned: 0
					,project_extras_date_completed: 0
					,project_extras_date_verified: 0
					,project_extras_date_invalid: 0
				})

				form().project.project_extras =
					[].concat(
						nonEditedExtras
						,R.differenceWith(
							(x, y) => x.project_extras_id() == y.project_extras_id()
							,vm().project_extras
							,nonEditedExtras
						)
						.map((e) => {
							pe_vm(e)
							return cloningProjectE(false, true)
						})
					)

				form().project.project_extras
				.forEach((t) =>
					projectExtrasSum(t, null)
				)
			}

			checkMultipleProjectExtras().data = []
			pe_vm({})
			deleteProjectExtras([])
			masterPELIST(true)
			disciplineSpread([])
			spreading(false)
		}

	const discardAllDisciplineChanges =
		() => {
			errors({})
			form().project.disciplines = []
			if (edit() == true){
				vm().disciplines
					.forEach((d) => {
						discipline_vm(d)
						form().project.disciplines.push(
							cloningProjectD(false, true)
						)
					})
			}
			checkMultiple().data = []
			discipline_vm({})
			deleteDisciplines([])
			deleteDisciplinesContractors([])
			deleteDisciplinesCrews([])
			masterLIST(true)
		}

	const updateInvoiceItems =
		(oitems, states, parents) => {

			oitems.forEach((o) => {

				const tlist =
					o.subitemk ? [o.subitems] : o.subitems

				if(states['approved'] != null && o.itemk){

					tlist.forEach((ti) => {

						const invoice =
							baseInvoiceRender().find((i) => i.invoice_id() == ti.invoice_id)

						if(!invoice.invoice_items.length){
							invoice.approved = states['approved']
							baseInvoiceIndex()['i'][invoice.invoice_id()].approved = states['approved']
						}
						else {
							invoice.invoice_items
							.filter((ii) =>
								ii.invoice_items_id
								== o.invoice_items_id
								|| !o.invoice_items_id
							)
							.forEach((tii) => {
								tii.approved = states['approved']
							})
						}

						o.approved = states['approved']
						invoice.created = true
					})
				}

				if(states['received'] != null && o.itemk){

					o.recqty  =
						R.sum(
							tlist.map((ti) => {

								const invoice =
									baseInvoiceRender()
									.find((i) => i.invoice_id() == ti.invoice_id)

								let itemsum = 0

								if( !invoice.invoice_items.length ){

									if( !states['received'] ){
										invoice.received = states['received']
										invoice.receiveddate = states['receiveddate']
										baseInvoiceIndex()['i'][invoice.invoice_id()].received = states['received']
										baseInvoiceIndex()['i'][invoice.invoice_id()].receiveddate = states['receiveddate']
										itemsum = [0]
									} else {
										invoice.received = states['received']
										invoice.receiveddate = states['receiveddate']
										baseInvoiceIndex()['i'][invoice.invoice_id()].received = states['received']
										baseInvoiceIndex()['i'][invoice.invoice_id()].receiveddate = states['receiveddate']
										itemsum = [ti.invoice_amount]
									}

								} else {

									itemsum =
										invoice.invoice_items
										.filter((ii) =>
											ii.invoice_items_id
											== o.invoice_items_id
											|| !o.invoice_items_id
										)
										.map((tii) => {
											tii.received = states['received']
											tii.receiveddate = states['receiveddate']
											if(states['received']){
												tii.invoice_items_received_amount = tii.invoice_items_amount
											} else {
												tii.invoice_items_received_amount = 0
											}
											return tii.invoice_items_received_amount
										})
								}

								o.received = states['received']
								o.receiveddate = states['receiveddate']
								invoice.created = true

								return R.sum(itemsum)
							})
						)
				}

				if(states['paymentref'] != null && o.itemk){

					tlist.forEach((ti) => {
						const invoice =
							baseInvoiceRender()
							.find((i) => i.invoice_id() == ti.invoice_id)

						const invoiceitem =
							invoice.invoice_items
							.find((ii) =>
								ii.invoice_items_id
								== ti.invoice_items_id
							)

						if(invoiceitem){
							invoiceitem.paymentref = states['paymentref']
						} else {
							invoice.paymentref = states['paymentref'] || ''
							invoice.invoice_client_reference(states['paymentref'] || '')
							baseInvoiceIndex()['i'][invoice.invoice_id()].paymentref = states['paymentref'] || ''
						}

						invoice.created = true
						o.paymentref = states['paymentref']
					})

					parents.forEach((mp) => mp.paymentref = states['paymentref'])
				}
			})
		}

	prop.merge(
		[
			data.calculated
			,projects
		]
	)
	.map(([fd, p]) =>
		(fd && p ? p : []).forEach((p) =>
			p.status =
				!data.coalatedSelectedVersion()[p.contractrecognitionid]
				? '...'
				: data.coalatedSelectedVersion()[p.contractrecognitionid].currentProfits
				&& data.coalatedSelectedVersion()[p.contractrecognitionid].outstandingProfits == 0
				? 'Reconciled'
				: (data.coalatedSelectedVersion()[p.contractrecognitionid].currentProfits == 0
				|| data.coalatedSelectedVersion()[p.contractrecognitionid].currentProfits
				!= data.coalatedSelectedVersion()[p.contractrecognitionid].accruedProfits)
				&& data.coalatedSelectedVersion()[p.contractrecognitionid].outstandingProfits != 0
				? 'Outstanding Payments'
				: data.coalatedSelectedVersion()[p.contractrecognitionid].unapprovedinvoicecount > 0
				? 'Claims not finalised'
				: !p.project_tendered_at()
				? 'Draft'
				: !p.project_accepted_at()
				? 'Tendered'
				: 'Accepted'
		)
	)

	const expandNotes =
		() =>
			m(''
				+ css`
					display: grid;
					grid-auto-flow: column;
					width: 7em;
					gap: 1em;
					align-items: center;
				`
				,m('button'
					+ css`
						border-radius: 0.5em;
						background-color: white;
						padding: 0.3em;
						border: solid 0.1px rgba(0,0,0,0.4);
						width: 1.5em;
						height: 1.5em;
					`
					,{
						onclick:
							() => {
								if( !projects().some((p) => notesexpanded[p.project_id()]) ){
									projects().forEach((p) => notesexpanded[p.project_id()] = true)
								}
								else {
									projects().forEach((p) => delete notesexpanded[p.project_id()])
								}
							}
						,disabled: !projects().some(s => s.project_completion_comments())
					}
					, ''
				)
				,m(`db.tl`, ' ' + 'Project Notes')
			)


	const destinationSchedule = data.scoped({})
	const availableSchedules =
		prop.merge(
			[
				data.schedules
				,data.selectedVersion
			]
		)
		.map(([list]) =>
			list.filter((s) =>
				s.schedule_id
				!= data.selectedVersion().schedule_id
			)
		)

	const projectCompleted =
		({p: project, sendp, ctrl}) => {

			const autoSend =
				() => {
					if( sendp && project.created ){
						return ctrl.intent.sendProjects([
							R.merge(
								project
								, {
									disciplines: []
									,project_extras: []
									,project_contacts: []
									,project_documents: m.prop([])
								}
							)
						])
					}
					else { return R.F() }
				}

			const viewprops =
				(p) =>
					({
						date: {
							title: 'Record Completion Date'
							, prop: (v) => setOptions(v, 'project_completion_marker', [p])
							, attrs: {
									disabled: metaDataLoading()
									, style: {padding: '2em'}
									, onblur: autoSend
								}
							}
						,[sendp ? 'attrs' : 'checkbox']:{
							title: "Mark project Complete"
							, prop: p.project_completion_marker
							, attrs: {
								onchange:
									m.withAttr('checked', function(e){
										const value =
												e
											? Date.now()
											: null

										p.created = true

										return p.project_completion_marker(value)
									})
								, disabled: !editPermissions() || metaDataLoading()
								, style: {
									padding: '2em'
								}
								, onblur: autoSend
							}
						}
					})

			return sendp
				? Pencil(
					() =>
						project.project_completion_marker()
						? moment(project.project_completion_marker()).format("ll")
						: ''
					,() => elements.dateCheckbox( viewprops(project) )
				)
				: elements.dateCheckboxLabel( R.merge(viewprops(project), {label: 'Completed Date'}) )
		}

	const renderChart =
		({refresheronly, chartonly}) =>
			!chartview().chart && chartonly
			? null
			: !data.calculated()
			? refresheronly ? elements.spinner() : elements.centeredSpinner()
			: (!chartview().chart && !chartonly) || refresheronly
			? data.reChartButton({
				versions: {
					'Overheads': 'Overheads'
					,[data.currentVersion()]:
						data.currentVersion()
				}
			})
			: ifCharts(
				chartview()
				, () => [
					m('label.control-label.pt4', 'Financial Chart ')
					,chartview().chart({width:''})
					|| data.reChartButton({
						versions: {
							'Overheads': 'Overheads'
							,[data.currentVersion()]:
								data.currentVersion()
						}
					})
				]
		)

	function zipRequest(){
		vm().is_zip_processing = true
		m.redraw()
		return data.api.projects.fetch.zip({
			schedule_id: form().project.schedule_id()
			, project_id: form().project.project_id()
		})
		.then(([result]) => {

			if (result.watch ) {
				result.watch.map( data => {

						vm().is_zip_processing = !data.zip_processed && !data.zip_error
						vm().latest_zip_url = data.zip_url
						vm().latest_zip_date = data.created_at
						vm().zip_error = data.zip_error
						m.redraw()

					return null;
				})
			}
		})
		.then( m.redraw )
	}

	const qiSelection = data.scoped('Quote')
	const qiContactList =
		vm.map((p) =>
			// [
				({
					message:
						m.prop(
							'Please see attached a ' + qiSelection().toLowerCase()
							+ ' for works on project ' + (p.project_name ? p.project_name() : '')
						)
					,phones: m.prop([])
					,emails: m.prop()
					,id: uuid.v4()
				})
			// ]
		)
	const ieview = data.scoped(false)
	const irview = data.scoped(true)
	const requestedItems = data.scoped([])
	const newqi =
		contract.map((c) => {

			if( c && vm().project_name ){
				qiContactList({})
				requestedItems([])

				qiContactList({
					message:
						m.prop(
							'Hi '
							+ ( c.client_organization_name ? c.client_organization_name : '' )
							+ ' Attached is a ' + qiSelection().toLowerCase()
							+ ' for works on project ' + vm().project_name()
						)
					,phones: m.prop([])
					,emails: m.prop()
					,id: uuid.v4()
				})
			}

			return {
				contract_payment_term: m.prop(c.contract_payment_term || '')
				, contract_terms: m.prop(c.contract_terms || '')
			}
		})

	const qieqProp =
		qiSelection.map((qis) =>
			qis == 'Quote'
			? 'project_extras_id'
			: 'invoice_id'
		)

	const reflist =
		prop.merge(
			[
				invoiceRender
				,qiSelection
			]
		).map(([inv, qis]) =>
			qis == 'Quote'
			? masterPELIST()
			: inv
		)

	const revoptions =
		reflist.map((d) =>
			d.filter((ri) =>
				qiSelection() != 'Quote'
				? (ri.contract_items_rate * ri.invoice_amount)
				+ (ri.contract_items_service * ri.invoice_service_amount)
				+ (ri.contract_items_depreciation * ri.invoice_depreciation_amount)
				+ (ri.contract_items_appreciation * ri.invoice_appreciation_amount)
				> 0
				: true
			)
		)

	const expoptions =
		reflist.map((d) =>
			d.filter((ri) =>
				qiSelection() != 'Quote'
				? (ri.contract_items_rate * ri.invoice_amount)
				+ (ri.contract_items_service * ri.invoice_service_amount)
				+ (ri.contract_items_depreciation * ri.invoice_depreciation_amount)
				+ (ri.contract_items_appreciation * ri.invoice_appreciation_amount)
				< 0
				: true
			)
		)


	const qicheck = (i) =>
		qiSelection() != 'Quote'
		? (i.invoice_rate * i.invoice_amount)
		+ (i.invoice_service_rate * i.invoice_service_amount)
		+ (i.invoice_depreciation_rate * i.invoice_depreciation_amount)
		+ (i.invoice_appreciation_rate * i.invoice_appreciation_amount)
		: 1

	const invoiceSelections =
		prop.merge(
			[
				reflist
				,ieview
				,irview
				,CONSTANTProjectExtras
			]
		)
		.map(([ ref, ie, ir ]) => {

			// const reflist =
			// 	qiSelection() == 'Quote'
			// 	? masterPELIST()
			// 	: inv

			return ref.filter((i) => {
				const iv = qicheck(i)
				// quotes should have selections of negative or positive too?
				return (iv > 0 && ir) || (iv < 0 && ie) //||  true
			})
		})

	return {
		projects, form, data, vm, discipline_vm, cloningProject, cloningProjectD, edit
		,DeleteProjectMode, DeleteDisciplineMode, resetPForm, resetDForm, filterFunction
		,check_dnames, check_pnames, resources, dc_mapping, geographies, PercentComplete
		,SaveDisabled, process_geo, viewType, schedule_id, resources_available, allocations
        ,request: data.request, suburbs, editPermissions, permissions, getDisciplines, getfilters
		,working_crews, checkallocatedResources, rList
		, loading, masterLIST
		, updateInvoiceItems
		, placeLong
		, suburbsInput
		, organizations_disciplines
		, CONSTANTdisciplines
		, deleteDisciplines
		, deleteDisciplinesContractors
		, deleteDisciplinesCrews
		, detailsPaneOpen
		, checkMultiple
		, savingProp
		, detailPaneInputs
		, activeTab
		, deleteStateProp
		, disciplineView
		, disciplineViewSet
		, getActivateDeleteButton
		, editAllButton
		, extraContractObligationData
		, projectSummary
		, api: data.api
		, restrictedOrgDisc
		, checkMultipleProjects
		, missingPermissions
		, errors
		, projectPercentComplete
		, disciplineLastDateIndex
		, disciplineTabEditArray
		, disciplineTabReadArray
		, ActiveContractsFeature
		, ActiveDetailsFeature
		, editContractRecognitions
		, modules
		, cloningProjectE
		, pe_vm
		, deleteProjectExtras
		, checkMultipleProjectExtras
		, getActivateContractDeleteButton
		, masterPELIST
		, CONSTANTProjectExtras
		, projectDisciplines
		, projectExtras
		, fetchedExtras
		, projectExtrasStat
		, users
		, usersIndex
		, reConnectDisciplines
		, projectExtrasIndex
		, completedProjects
		, contractPatchIndex
		, clonedContracts
		, allocationIndex
		, resetPEForm
		, projectExtrasTypes
		, filteredTaskOptions
		, filteredDisciplineOptions
		, metaDataLoading
		, projectOptions
		, inverseProjectsList
		, projectsSortedResult
		, projectSortRequired
		, checkUserTaskMatch
		, allResourceTypes
		, cloning
		, projectExtrasSum
		, seperateTasks
		, editContractPermissions
		, checkTaskDates
		, disciplineSpread
		, spreading
		, restrictedOrgTask
		, deleteProjectContacts
		, removeContactForm
		, projectContactOptions
		, baseProjectDetailsHash
		, reconRender
		, reconloading
		, reconArray
		, totalRecon
		, showUpload
		, uploadmetadata
		, editLink
		, cloneLink
		, addNewTaskLink
		, onProjectList
		, projectTemplates
		, materials
		, materialloading
		, itemDetails
		, addNewTask
		, addNewDiscipline
		, discardAllDisciplineChanges
		, discardAllTaskChanges
		, invoiceRender
		, baseInvoiceRender
		, baseInvoiceIndex
		, disciplinesTable
		, resetLoaders
		, moneycycle
		, moneytype
		, moneydtype
		, destinationSchedule
		, availableSchedules
		, disciplineSelect
		, disciplineSelectAutocomplete
		, editResourcePermissions
		, firstproject
		, setOptions
		, idealResourceName
		, deleteDisciplineC
		, switchProjectsButton
		, taskCloneButton
		, disciplineCloneButton
		, newUser
		, expandNotes
		, projectCompleted
		, deleteInvoiceItemsHash
		, deleteInvoiceHash
		, checkInvoiceDeletion
		, contractItems
		, odinItems
		, newContracts
		, selectedInvoices
		, newInvoices
		, chartview
		, ifCharts
		, renderChart
		, notesexpanded
		, zipRequest
		, userSelectionIndex
		, disciplineChargeRate
		, oauths
		, qiSelection
		, qiContactList
		, newqi
		, invoiceSelections
		, ieview
		, irview
		, requestedItems
		, qieqProp
		, reflist
		, revoptions
		, expoptions
		, qicheck
	}
}

/*
	The controller is the glue that sticks the model and intent together.
	It exposes the model and intent to the view by returning them.

	The controller "controls" how each layer interacts.
*/
function Main({ attrs: { data }}){
	const model = Model(data)
	const intent = Intent(model, model.activeTab)
	const ctrl = { model, intent }

	return {
		view(){
			return view(ctrl)
		}
	}
}

/*
	The view provides the user ways to transfer their intent into really action within the system.
	The view accesses data from the model layer, and binds intent functions to user inputs.
*/

var view = function(ctrl){
	// once
	return m('div.grid-content-full-height' // enable with quick start
		// todo-james this should be closer to the scollable table
		// callsite, but I'll leave that to @eja as he knows
		// this route better than me
		+ H.css`
			& .scrollable-table-3 .highlight-ok {
				--border-color: #6b5b95;
				--shadow-color: hsl(257deg 24% 47% / 47%);
				box-shadow: -1px 3px 5px 0px var(--shadow-color);
				border-left-color: var(--border-color);
			}

			& .scrollable-table-3 .highlight-error {
				--border-color: #d64141;
				--shadow-color: hsl(0deg 100% 95%);
				box-shadow: -1px 3px 5px 0px var(--shadow-color);
				border-left-color: var(--border-color);
			}
		`
		+ css`
			display: grid;
			align-content: ${
				[EditProject, ListProject].includes(ctrl.model.viewType())
				? 'start'
				: 'center'
			};
		`
		,ctrl.model.viewType () (ctrl)
		,DetailsPane(
			ctrl.model.detailsPaneOpen
			,ctrl.model.checkMultiple().data.length > 1
			&& ctrl.model.discipline_vm().discipline_name
				? 'Edit Multiple Disciplines'
				: ctrl.model.checkMultipleProjectExtras().data.length > 1
				? 'Edit Multiple Tasks'
				: ctrl.model.projectOptions()
				? 'Project Options'
				: ''
			, () => ctrl.model.detailPaneInputs(
				ctrl.model.discipline_vm
				, ctrl
			)
		)
	)
}

var InitialProject = function(ctrl){
	return [
		ctrl.model.loading()
		? elements.centeredSpinner()
		: ctrl.model.vm().project_id
		&& ctrl.model.form().project.project_name
			? m(InitialProjectView, { ctrl, saveProjectButton, discardProjectButton })
			: ctrl.model.editPermissions()
			? m('div'
				+ css`
					display: grid;
					justify-items: center;
					justify-content: center;
					align-items: center;
					align-content: center;
					gap: 1em;
					/* max-width: 31em; */
					grid-template-columns: minmax(0em, 31em);
				`
				,createProjectButton(ctrl)

				,m('p'
					+ css`
						text-align: center;
						margin: 0em;
					`
					,`Create a fresh project for the business or choose from our custom made options to quickly setup the metadata relationships`
				)
			)
			: m('div'
				+ css`
					display: grid;
					justify-items: center;
					justify-content: center;
					align-items: center;
					align-content: center;
					gap: 1em;
					/* max-width: 31em; */
					grid-template-columns: minmax(0em, 31em);
				`
				,`Projects have not been created yet`
			)
	]
}
var createProjectAction = ctrl => {
	ctrl.model.projectOptions(false)
	ctrl.model.checkMultiple().data = []
	ctrl.model.checkMultipleProjectExtras().data = []

	const viewType =
		true
		|| ctrl.model.firstproject()
		&& ctrl.model.data.features().quickStartProject
		? InitialProject
		: EditProject

	ctrl.model.viewType(viewType)

	ctrl.model.activeTab('Tasks')
	ctrl.model.vm(ctrl.model.resetPForm())
	ctrl.model.cloningProject(false, true)
	ctrl.model.edit(false)
	ctrl.model.masterLIST(true)
	ctrl.model.masterPELIST(true)
	ctrl.model.fetchedExtras(true)
	ctrl.model.disciplineViewSet('Working Details')
	ctrl.model.onProjectList(false)
	if( ctrl.model.firstproject() ){
		ctrl.model.addNewDiscipline()
	}
}
var createProjectButton = function(ctrl, attrs={}){
	return m(DropdownButton, R.merge(
		attrs
		,{
			options: ctrl.model.projectTemplates().map((p) => p.project_name())
			,text: 'Create a Project'
			,onclick(){
				createProjectAction(ctrl)
			}
			,onselect(tp){
				const template = ctrl.model.projectTemplates().find((template) => template.project_name() == tp)
				if (tp){
					ctrl.model.projectOptions(false)
					ctrl.model.checkMultiple().data = []
					ctrl.model.checkMultipleProjectExtras().data = []
					ctrl.model.viewType(InitialProject)
					ctrl.model.vm(template)
					ctrl.model.cloningProject(true, false)
					ctrl.model.activeTab('Tasks')
					ctrl.model.edit(false)
					ctrl.model.masterLIST(true)
					ctrl.model.masterPELIST(true)
					ctrl.model.fetchedExtras(false)
					ctrl.model.disciplineViewSet('Working Details')
					ctrl.model.onProjectList(false)
				}
			}
			,disabled: ctrl.model.schedule_id() == null
				? true
				: !!ctrl.model.check_pnames()
		})
	)
}

var editableEndDate = function({project, ctrl}){

	const autoSend =
		() => {
			if( project.created ){
				return ctrl.intent.sendProjects([
					R.merge(
						project
						, {
							disciplines: []
							,project_extras: []
							,project_contacts: []
							,project_documents: m.prop([])
						}
					)
				])
			}
			else { return R.F() }
		}

	return Pencil(
		() => moment(project.project_dead_date()).format("ll")
		,() =>
			elements.dateInput(
				project.project_dead_date
				,{
					disabled: !ctrl.model.editPermissions() || ctrl.model.metaDataLoading()
					,title: "Cut off date for completing all disciplines"
					,onblur: autoSend
					,onchange: () => project.created = true
				}
			)
	)

}

var ListProject = function(ctrl){

	return m('div.harth.mb6'
		+ css`
			display: grid;
		`
		, [

		ctrl.model.editPermissions()
			? elements.list([
				createProjectButton(ctrl)
				,ctrl.model.switchProjectsButton(ctrl)
				,m(`label`,
					ctrl.model.completedProjects() == "completed"
						? ' to Current Projects'
						: ' to Completed Projects'
				)
			])
			: elements.list([
				m("button.btn.", {
					disabled: ctrl.model.checkMultipleProjects().length != 1
					,onclick: () => {
						ctrl.model.projectOptions(false)
						ctrl.model.cloningProject(false, true)
						ctrl.intent.fetchAllocations()
						ctrl.model.viewType(EditProject)
						ctrl.model.activeTab('Disciplines')
						ctrl.model.edit(true)
						ctrl.model.masterLIST(true)
						ctrl.model.masterPELIST(true)
						ctrl.model.fetchedExtras(false)
						ctrl.model.disciplineViewSet('Working Details')
					}
				}, "View")
			])


		,ctrl.model.getfilters(null, "Project")
		,m('br')
		,elements.table(ctrl.model.data,
			ctrl.model.data.advancedView()
			? [
				'Manage'
				, 'Project'
				, 'Contract'
				, 'Status'
				, 'Priority'
				, 'Assigned Work Days'
				, 'Incomplete Tasks'
				, 'End Date'
				, 'Complete (%)'
				, ctrl.model.moneycycle('Accrued')
				, ctrl.model.moneycycle('Received')
				, ctrl.model.moneycycle('Forecasted')
				, 'Contract Obligations'
				, ''
				, ctrl.model.expandNotes(ctrl)
				, 'Forecasted Completion'
				, 'Completed'
			]
			: [
				'Manage'
				, 'Project'
				, 'Contract'
				, 'Status'
				, 'Incomplete Tasks'
				, 'End Date'
				, 'Complete (%)'
				, ctrl.model.moneycycle('Accrued')
				, ctrl.model.moneycycle('Received')
				, ctrl.model.moneycycle('Forecasted')
				, ''
				, ctrl.model.expandNotes(ctrl)
				, 'Completed'
			]
			,ListProcessing(
				ctrl.model.loading()
					? []
					: ctrl.model.projects()
				, ctrl.model.filterFunction.projectFilters.filters()
				, 'project_priority'
			).map(
				function(project) {
				return  elements.table.row({ key: exposer(project.project_id) }, [

					elements.checkbox({
						onchange:
							function(event){
								if(event.currentTarget.checked == true){

									ctrl.model.vm(project)
									ctrl.model.editPermissions()
									? ctrl.model.checkMultipleProjects().push(
										project
									)
									: ctrl.model.checkMultipleProjects([project])

									ctrl.model.projectOptions(true)

								}
								else if (event.currentTarget.checked == false){

									ctrl.model.checkMultipleProjects().splice(
										ctrl.model.checkMultipleProjects().findIndex((p) =>
											p.project_id() == project.project_id()
										)
										, 1
									)
									ctrl.model.checkMultipleProjects().length == 0
										? ctrl.model.vm({}) && ctrl.model.projectOptions(false)
										: ctrl.model.vm(
											ctrl.model.checkMultipleProjects()[0]
										)
								}
							}
						,checked: ctrl.model.checkMultipleProjects().find((p) => p.project_id() == project.project_id())
					})

					,m(`a`
						,{
							onclick:
								() => {
									ctrl.model.projectExtrasTypes()['completed'] = 'any'
									ctrl.model.projectExtrasTypes()['invalid'] = 'any'
									ctrl.model.projectExtrasTypes()['assigned'] = 'any'
									ctrl.model.projectExtrasTypes()['verified'] = 'any'
									ctrl.model.editLink(ctrl, project)
								}
						}
						,project.project_name()
					)

					,project.contract_name || ''

					,!ctrl.model.data.calculated() ? ' ... ' : project.status

					,ctrl.model.data.advancedView() ? project.project_priority() : null

					,ctrl.model.data.advancedView()
					? m(
						project.disciplines.some((d) =>
							R.intersection(
								d.badeffortteams
								,R.unnest(d.disciplines_contractors.map(R.prop('disciplines_contractors_crews')))
								.map(R.pipe(R.prop('crew_id'), R.call()))
							).length
						)
						? '.dark-red.fw6'
						: ''
						,Number(project.workdays).toFixed(2)
					)
					: null

					,m(''
						+ css`
							display: grid;
							grid-auto-flow: column;
							width: max-content;
							gap: 1em;
							align-items: center;
						`
						,m(`button`
							+ css`
								border-radius: 0.5em;
								background-color: white;
								padding: 0.3em;
								border: solid 0.1px rgba(0,0,0,0.4);
								width: 1.8em;
								height: 1.8em;
							`
							,{
								onclick: () => ctrl.model.addNewTaskLink(ctrl, project)
							}
							, '+'
						)
						, m(`a.db.tl`
							,{
								onclick:
									() => {
										ctrl.model.projectExtrasTypes()['completed'] = ''
										ctrl.model.projectExtrasTypes()['invalid'] = ''
										ctrl.model.projectExtrasTypes()['assigned'] = 'any'
										ctrl.model.projectExtrasTypes()['verified'] = ''
										ctrl.model.editLink(ctrl, project)
									}
							}
							,project.incompletetaskcount + ' tasks'
						)
					)

					,editableEndDate({ project, ctrl })

					,ctrl.model.PercentComplete(project)

					,m(`a`
						,{
							onclick: () => ctrl.model.editLink(ctrl, project, 'Reconciliation')
							,disabled: ctrl.model.metaDataLoading() || !ctrl.model.data.readContractPermissions()
						}
						,!ctrl.model.data.calculated()
							? ' ... '
							: makeMoney(
								ctrl.model.data.readContractPermissions()
								&& ctrl.model.data.coalatedSelectedVersion()[project.contractrecognitionid]
								? ctrl.model.data.coalatedSelectedVersion()[project.contractrecognitionid]['current' + ctrl.model.moneytype()['Accrued']]
								+ ctrl.model.data.coalatedSelectedVersion()[project.contractrecognitionid]['outstanding' + ctrl.model.moneytype()['Accrued']]
								: 0
							)
					)

					,m(`a`
						,{
							onclick: () => ctrl.model.editLink(ctrl, project, 'Reconciliation')
							,disabled: ctrl.model.metaDataLoading() || !ctrl.model.data.readContractPermissions()
						}
						,!ctrl.model.data.calculated()
							? ' ... '
							: makeMoney(
								ctrl.model.data.readContractPermissions()
								&& ctrl.model.data.coalatedSelectedVersion()[project.contractrecognitionid]
								? ctrl.model.data.coalatedSelectedVersion()[project.contractrecognitionid]['current' + ctrl.model.moneytype()['Received']]
								: 0
							)
					)

					,m(`a`
						,{
							onclick: () => ctrl.model.editLink(ctrl, project, 'Reconciliation')
							,disabled: ctrl.model.metaDataLoading() || !ctrl.model.data.readContractPermissions()
						}
						,!ctrl.model.data.calculated()
							? ' ... '
							: makeMoney(
								(
									ctrl.model.data.readContractPermissions()
									&& ctrl.model.data.coalatedSelectedVersion()[project.contractrecognitionid]
									|| {}
								)['forecasted' + ctrl.model.moneytype()['Forecasted']]
							)
					)

					,ctrl.model.data.advancedView()
					? Number(project.eotSum) + Number(project.nodSum) + Number(project.varSum)
					: null

					,m('button'
						+ css`
							border-radius: 0.2em;
							background-color: white;
							padding: 0.3em;
							border: solid 0.1px rgba(0,0,0,0.1);
						`
						, {
							onclick: () => ctrl.model.cloneLink(ctrl, project)
							,disabled:
								ctrl.model.check_pnames()
								|| ctrl.model.metaDataLoading()
							,title: "Copy this project's details to form the base of a new project"
						}
						,'Clone'
					)

					,ctrl.intent.showComments(project)

					,ctrl.model.data.advancedView()
						? project.lastAllocationDate
							? moment(project.lastAllocationDate).format("ll")
							: ``
						: null

					,ctrl.model.projectCompleted({p: project, sendp: true, ctrl})

				]
				.filter((a) => a != null ))
				}
			)
		)
		,m('p')
		,m('br')
		,!ctrl.model.data.schedule_id()
			? elements.alert('info','Please select a schedule')
			: ctrl.model.projects().length
		 		? null
				: ctrl.model.loading()
					? elements.centeredSpinner()
					: elements.alert('info',`I have no projects designated to this schedule`)
	])
}


var ResourceTab = function(ctrl){
	return [
		m('br')
		,ctrl.model.rList().length != 0
			? elements.alert('info','Work can be assigned to multiple disciplines at a time')
			: !ctrl.model.rList().length && ctrl.model.data.readResourcePermissions() && ctrl.model.checkMultiple().data.length
			? elements.alert(
				'warning'
				, ctrl.model.checkMultiple().data.every((d) => !d.disciplines_contractors.length)
				? `There aren't any Resources or Teams available who have the capability to do this combination of work`
				: `There aren't any other Resources or Teams available who have the capability to do this combination of work`
			)
			: !ctrl.model.editResourcePermissions()
			? elements.alert('info',`Resource permissions are required to alter project resources`)
			: null

		,ctrl.model.checkMultiple().data.length && ctrl.model.rList().length
		? elements.list([
			m('label.control-label', 'Available Resources ')
			,autocomplete.strict(
				ctrl.model.rList
				,R.when( Boolean, function(v){
					ctrl.model.dc_mapping(v, ctrl.model.checkMultiple().data)
					return v.ParentRecognition
				})
				, 'ParentRecognition'
				, () => ({
					disabled: ctrl.model.rList().length == 0
						|| ctrl.model.checkMultiple().data.length == 0
					,title: "Team or Resource nominated to work on this discipline"
				})
				, (value, element) => {
					element.value = ""
				}
			)
		])
		: elements.alert(
			'info'
			,[
				'Select some disciplines to modify work assignments. If resource selections are left blank, Odin will assign the best choices based on this schedule\'s directives.  '
				,'Otherwise, selected Resources chosen will be assigned to the activated disciplines.  '
				,'Note: if resources aren\'t in the list, they don\'t have the capability to work on all activated disciplines'
			].join('\n')
		)

		,m("br")

		,ctrl.model.form().project.disciplines.length
			? ctrl.model.disciplinesTable()
			: null
	]
}

var exposeSublist = function(ctrl, o){

	const operator =
		o.itemk
		? R.map
		: R.mapObjIndexed

	const subitems =
		!o.itemk
		? ctrl.model.reconRender()[o.organizations_disciplines_id ? o.organizations_disciplines_id() : o.project_id()] || {}
		: o.subitems

	return operator(
		sk => {

			const refitem =
				o.itemk
				? sk
				: R.last(sk)

			const refname =
				(
					refitem.user_name
				 	? exposer(refitem.user_name) + ' - '
					: refitem.itemname && o.itemk
					? 'Order' + ' - '
					: refitem.itemname
					? 'Material' + ' - '
					: ''
				)
				+ (
					o.itemk && refitem.distribution_ordered
					? refitem.distribution_ordered
					: o.itemk
					? [refitem.itemname || '', refitem.invoice_no, refitem.invoice_items_client_reference].filter(R.identity).join(' - ')
					: refitem.project_extras_type || refitem.invoice_name || refitem.itemname
				)

			return {
				rendername: refname
				,rendernameapp:
					!o.itemk
					? ''
					: !(refitem.crew_id || refitem.contractuserid)
					? ' Units '
					: refitem.contract_items_recorded_reference == 'Timesheets'
					? ' Hours '
					: ' Days '

				,invoice_approval: refitem.invoice_approval
				,invoice_received: refitem.invoice_received
				,receiveddate: refitem.invoice_received_date
				,invoice_received_date: refitem.invoice_received_date
				,invoice_items_client_reference: refitem.invoice_items_client_reference
				,parent: o
				,paymentref:
					!o.itemk
					? sk
					&& R.uniq(
						(!o.itemk ? R.unnest(Object.keys(sk).map((si) => sk[si])) : sk)
						.map(R.prop('invoice_client_reference'))
						.filter(R.prop('invoice_client_reference'))
					).join(', ')
					: refitem.invoice_client_reference
				,invoice_inv_id: refitem.invoice_inv_id
				// ,paymentrefernces:
				,invoice_id: refitem.invoice_id
				,invoice_items_id: refitem.invoice_items_id

				,subitems: sk
				,recqty:
					o.itemk && !o.subitemk && sk.invoice_items_amount
					? sk.invoice_items_received_amount
					: o.itemk
					? refitem.invoice_received_date && refitem.invoice_amount
					: 0
				,qty:
					o.itemk && !o.subitemk && sk.invoice_items_amount
					? sk.invoice_items_received_amount || sk.invoice_items_amount
					: o.itemk
					? sk.qty
					: 0
				,qtymatch:
					(refitem.invoice_amount == refitem.invoice_service_amount
					|| !refitem.invoice_service_amount)
					&& (refitem.invoice_amount == refitem.invoice_depreciation_amount
					|| !refitem.invoice_depreciation_amount)
					&& (refitem.invoice_amount == refitem.invoice_appreciation_amount
					|| !refitem.invoice_appreciation_amount)
				,rate: o.itemk ? sk.rate : 0
				,itemk:
					o.organizations_disciplines_id
					? o.organizations_disciplines_id()
					: o.project_id
					? o.project_id()
					: o.itemk
				,subitemk: o.itemk && !o.subitemk ? o.itemk : null

				,materialvalue:
					o.itemk
					? sk.materialvalue
					: R.sum(R.unnest(sk.map(R.prop('materialvalue'))))
				,revenuevalue:
					o.itemk
					? sk.revenuevalue
					: R.sum(R.unnest(sk.map(R.prop('revenuevalue'))))
				,expenditurevalue:
					o.itemk
					? sk.expenditurevalue
					: R.sum(R.unnest(sk.map(R.prop('expenditurevalue'))))
			}
		}
		,subitems
	)
}

var expansionRender = function(ctrl, o, oi, oArray, returnsubitems){

	const formator =
		o.itemk
		? (a) => a
		: R.pipe(R.toPairs, R.unnest, R.filter((a) => R.type(a) != 'String'))

	const list = returnsubitems ? formator(exposeSublist(ctrl, o)) : null

	const subcount =
		oArray.findIndex((a, aii, aarray) =>
			aii == aarray.length - 1
			|| aii > oi
			&& (
				o.itemk
				? o.itemk && !a.subitemk
				: !o.itemk && !a.itemk
			)
		)

	const removals =
		subcount == oArray.length-1
		&& (R.last(oArray).itemk || oi == oArray.length-1)
		? subcount-oi
		: subcount-(oi+1)


	return returnsubitems && o.expanded
		? ctrl.model.reconArray().filter((oik, oii) => oii >= oi + 1 && oii <= oi + removals)
		: returnsubitems && !o.expanded
		? o.expanded = true && ctrl.model.reconArray(R.insertAll(oi + 1, list, ctrl.model.reconArray())) && list
		: !o.itemk
		&& ctrl.model.reconRender()
		[o.organizations_disciplines_id ? o.organizations_disciplines_id() : o.project_id()]
		|| o.itemk && !o.subitemk
		? m('a'
			,{
				title: 'Expand to see details of financial coverage'
				,onclick: () => {

					if (!o.expanded){

						o.expanded = true

						const sublist =
							formator(exposeSublist(ctrl, o))

						ctrl.model.reconArray(R.insertAll(oi + 1, sublist, ctrl.model.reconArray()))
					}
					else {
						ctrl.model.reconArray(R.remove(oi + 1, removals, ctrl.model.reconArray()))
						o.expanded = false
					}

				}
			}
			,'(' + (
				!o.itemk
				? Object.keys(
					ctrl.model.reconRender()[
						o.organizations_disciplines_id
						? o.organizations_disciplines_id()
						: o.project_id()
					] || {}
				).length
				: o.itemk && !o.subitemk
				? o.subitems.length
				: ''
			)
			+ ')'
		)
		: !o.itemk
		&& !ctrl.model.reconRender()
		[o.organizations_disciplines_id ? o.organizations_disciplines_id() : o.project_id()]
		? '(0)'
		: ''
}

var MaterialsTab = function(ctrl){
	return ctrl.model.materialloading()
	? elements.centeredSpinner()
	: [

		m('br')

		,ctrl.model.vm().newProject
		? elements.alert(`info`, `Save this project to start applying materials`)
		: !ctrl.model.materials().actual.length && !Object.keys(ctrl.model.materials().suggested).length
		? elements.alert(
			`info`
			, `Standard materials can be set against different types of work in the Materials section of Odin,
			Odin will then be able to summarize and prefill materials on all your projects`
		)
		: null

		,m('br')

		,ctrl.model.materials().actual.length
		|| Object.keys(ctrl.model.materials().suggested).length
		? elements.table(
			ctrl.model.data
			,[
				'Item Name'
				, 'New Stock Required'
				, 'Allocated Qty'
				, 'Received Qty'
				, 'Orders'
			]
			,(ctrl.model.materials().actual
			|| ctrl.model.vm().disciplines.concat(ctrl.model.vm()))
			.map((item) => [
				item.name
				,item.required
				,item.allocated
				,item.received
				,ctrl.model.itemDetails(item)
			])
		)
		: ''
	]
}

var addInvoice = function(ctrl){

	const invoiceData =
		(ni) =>
			({
				xremove:
					m(``
						+css`
						'display': 'flex'
						,'align-items': 'center'
						`
						,elements.xRemoval({
							onclick:
								() => {

									const relatedContract =
										ctrl.model.newContracts()
										.find((a) =>
											a.contract_items_id()
											== exposer(ni.contract_items_id)
										)


									if(
										relatedContract
										&& relatedContract.created
										&& ctrl.model.newInvoices()
										.filter((a) => a.contract_items_id() == ni.contract_items_id())
										.length == 1
									){
										ctrl.model.newContracts()
										.splice(
											ctrl.model.newContracts()
											.findIndex((t) =>
												t.contract_items_id()
												== relatedContract.contract_items_id()
											)
											, 1
										)

										ctrl.model.contractItems()
										.splice(
											ctrl.model.contractItems()
											.findIndex((t) =>
												t.contract_items_id()
												== relatedContract.contract_items_id()
											)
											, 1
										)
									}

									ctrl.model.newInvoices()
										.splice(
											ctrl.model.newInvoices()
											.findIndex((t) =>
												t.key
												== ni.key
											)
											, 1
										)

									ctrl.model.selectedInvoices()
										.splice(
											ctrl.model.selectedInvoices()
											.findIndex((t) =>
												t.key
												== ni.key
											)
											, 1
										)

								}

							,title: 'Delete this invoice'
						})
					)

				,itype:
				m(''
					+ css`
						display: grid;
						grid-auto-flow: column;
						grid-template-rows: 0.7fr 0.3fr;
						grid-template-columns: 1fr;
						gap: 0.25em;
						align-items: center;
					`
					,[
						m(``
							+ css`
								text-align: center;
							`
							,!ni.invoice_name
							? ''
								: ni.invoice_rate() * ni.invoice_amount() > 0
								? 'Revenue'
								: ni.invoice_rate() * ni.invoice_amount() < 0
								? 'Expenditure'
								: ni.invoice_rate() > 0
								? 'Revenue'
								: 'Expenditure'
						)

						,m(`button`
							+ css`
								border-radius: 0.5em;
								border: none;
								height: 2em;
								background-color: inherit;
							`
							,{
								onclick: () =>  ni.invoice_rate( (ni.invoice_rate() || 1) * -1 )
							}
							, !ni.invoice_name
								? ''
								: ni.invoice_rate() * ni.invoice_amount() > 0
								? elements.switchiconNeuRight()
								: elements.switchiconNeuLeft()
						)
					]
				)

				,name:
				m.component(
					Pencil
					,() =>
						ni.invoice_name
						? ni.invoice_name()
						: ''
					,() =>
						autocomplete.strict(
							ctrl.model.odinItems
							,(v) => {
								if (v){

									const existing =
										ctrl.model.contractItems()
										.find((ci) =>
											ci.contractrecognitionid
											== v.contractrecognitionid
										)

									const cobji =
										existing || ctrl.model.data.makeContractItem(v, [v])

									if( !existing ){
										ctrl.model.newContracts().push(cobji)
										cobji.contract_id(ctrl.model.form().project.contract_id())
										cobji.contract_items_id(uuid.v4())
										ctrl.model.contractItems().push(cobji)
									}

									const cobj =
										ctrl.model.data.contracts()
										.find((c) => c.contract_id == cobji.contract_id())

									const projectPrefill =
										{
											project_id: ctrl.model.vm().project_id
											,project_name: ctrl.model.vm().project_name()
											,allocation_id: m.prop(null)
										}

									const invmeta =
										cobji
										? ctrl.model.data.cloneInvoice(
											[cobji]
											,ctrl.model.data.auth.stream().user_id
											,cobj
											,projectPrefill
										)
										: ctrl.model.data.cloneInvoice(
											[cobji]
											,ctrl.model.data.auth.stream().user_id
											,cobj
											,projectPrefill
										)

									Object.assign(ni, R.last(invmeta))
									ni.invoice_rate(
										ni.invoice_rate()
										|| (
											cobji.organizations_disciplines_id
											|| cobji.project_id
											? 1
											: -1
										)
									)
								}
								return ni.invoice_name()
							}
							,'name'
							, () => ({})
						)
				)

				,rate:
				ni.invoice_name
				? m.component(
					Pencil
					,() => makeMoney( ni.invoice_rate() )
					,() =>
						m(
							NumberInput
							,R.merge(
								ctrl.model.data
								,{
									errors: ctrl.model.errors
									,errorLabel: ni.invoice_no() + ' Invoice Rate'
								}
							)
							,{
								prop:
									(v) => {
										if( v || v == 0 ){
											ctrl.model.setOptions(
												v
												, 'invoice_rate'
												, [ni]
											)
										}
										return ni.invoice_rate()
									}
								,attrs: {
									step: 0.01
									,placeholder: 'Rate'
									,key:  'Rate' + ni.key
								}
							}
						)
				)
				: ''

				,qty:
				ni.invoice_name
				? m.component(
					Pencil
					,() => ni.invoice_amount() || m('', {style: {opacity: '0.6'}}, 'Quantity')
					,() =>
						m(
							NumberInput
							,R.merge(
								ctrl.model.data
								,{
									errors: ctrl.model.errors
									,errorLabel: ni.invoice_no() + ' Invoice Amount'
								}
							)
							,{
								prop:
									(v) => {
										if( v || v == 0 ){
											ctrl.model.setOptions(
												v
												, 'invoice_amount'
												,[ni]
											)
										}
										return ni.invoice_amount()
									}
								,attrs: {
									step: 0.01
									,placeholder: 'Quantity'
									,key:  'Quantity' + ni.key
								}
							}
						)
				)
				: ''

				,total:
				ni.invoice_name
				? m('', makeMoney(ni.invoice_rate() * ni.invoice_amount()) )
				: ''

				,approval:
				ni.invoice_name
				? m.component(
					Pencil
					,() =>
						(
							!ni.invoice_approval()
							? 'Requires Approval'
							: !ni.invoice_approver()
							? 'Approved by ' + ctrl.model.data.organization().organization_name()
							: 'Approved by ' + ctrl.model.users().find((u) => u.user_id() == ni.invoice_approver() ).usersFullName
						)
					,() =>
						elements.checkbox({
							onchange:
								m.withAttr('checked',
									(checked) => {

										ctrl.model.setOptions(
											checked
											, 'invoice_approval'
											, [ni]
										)

										if ( checked ) {
											ctrl.model.setOptions(
												new Date().getTime() + Number(ni.contract_payment_term)*milliperday
												, 'invoice_due_date'
												, [ni]
											)
											ctrl.model.setOptions(
												ctrl.model.data.auth.stream().user_id
												, 'invoice_approver'
												, [ni]
											)
										}
									}
								)
							,checked: ni.invoice_approval()
						})
				)
				: ''

				,received:
				ni.invoice_name
				? m.component(
					Pencil
					,() =>
						'Received ' + (
							ni.invoice_received_date()
							? moment( ni.invoice_received_date() ).format("ll")
							: ''
						)
					,() =>
						elements.dateCheckbox({
							label: ''
							,date: {
								title: 'Received payment date'
								, prop:
									(a) => {
										if( a ){
											ctrl.model.setOptions(
												true
												, 'invoice_received'
												, [ni]
											)

											ctrl.model.setOptions(
												a
												, 'invoice_received_date'
												, [ni]
											)
										}

										return ni.invoice_received_date()
									}
								, attrs: {
									title: "Received payment date "
									,maxdate: new Date().toISOString().slice(0,10)
								}
							}
							,attrs:{
								title: 'Set ledger to be receipted'
								, prop: ni.invoice_received
								, checked: ni.invoice_received_date()
								, attrs: {
									onchange:
										m.withAttr('checked', function(e){
											if( e ){

												ctrl.model.setOptions(
													true
													, 'receipting'
													, [ni]
												)

												ctrl.model.setOptions(
													true
													, 'invoice_received'
													, [ni]
												)

												ctrl.model.setOptions(
													new Date().getTime()
													, 'invoice_received_date'
													, [ni]
												)

											}
											else {

												ctrl.model.setOptions(
													false
													, 'receipting'
													, [ni]
												)

												ctrl.model.setOptions(
													false
													, 'invoice_received'
													, [ni]
												)

												ctrl.model.setOptions(
													null
													, 'invoice_received_date'
													, [ni]
													, true
												)

											}
										})
								}
							}
						})
				)
				: ''

				,ref:
				ni.invoice_name
				? m.component(
					Pencil
					,() => ni.invoice_client_reference() || m('', {style: {opacity: '0.6'}}, 'Payment Ref')
					,() =>
						elements.textInput(
							(v) => {
								if( v || v == '' ){
									ctrl.model.setOptions(
										v
										, 'invoice_client_reference'
										, [ni]
									)
								}
								return ni.invoice_client_reference()
							}
							,{
								title: "Payment reference from the client"
								,placeholder: 'Payment Ref'
							}
						)
				)
				: ''

				,key: ni.key || Math.random().toString(15).slice(2, 8)
			})

	const largeInvoiceTable =
		() =>
			ctrl.model.newInvoices()
			.map((ni) => {
				return m(''
					+ css`
						display: grid;
						grid-auto-flow: column;
						grid-template-rows: 1fr;
						grid-template-columns: 0.05fr 0.05fr 0.2fr 0.1fr 0.1fr 0.15fr 0.05fr 0.15fr 0.15fr;
						gap: 1em;
						align-items: center;
						padding: 0.3em;
						padding-bottom: 2em;
					`
					,{key: ni.key}
					,R.pipe(
						R.toPairs
						,R.unnest
						,R.filter((pa) => typeof pa != 'string')
					)( invoiceData(ni) )
				)
			})

	const smallInvoiceTable =
		() =>
			ctrl.model.newInvoices()
			.map((ni) => {
				return m(''
					+ css`
						display: grid;
						grid-auto-flow: column;
						grid-template-rows: 1fr;
						grid-template-columns: 0.05fr 0.05fr 0.35fr 0.15fr 0.15fr 0.25fr;
						gap: 1em;
						align-items: center;
						padding: 0.3em;
						padding-bottom: 2em;
					`
					,{key: ni.key}
					,R.pipe(
						R.toPairs
						,R.unnest
						,R.filter((pa) => typeof pa != 'string')
					)(
						R.pick(
							[
								'xremove'
								,'itype'
								,'name'
								,'rate'
								,'qty'
								,'total'
							]
							,invoiceData(ni)
						)
					)
				)
			})

	const invoiceInputs = () =>
		!ctrl.model.newInvoices().length
		? null
		: m(Responsive
			,
			{
				breakpoints: {
					mobile:0
					,desktop:900
					,tablet:700
					,hd:1800
				}
			}
			,({ hd, desktopOnly, mobileOnly, tabletOnly }) => [
				hd( largeInvoiceTable )
				,desktopOnly( smallInvoiceTable  )
				,mobileOnly( smallInvoiceTable )
				,tabletOnly( smallInvoiceTable )
			]
		)

	return ctrl.model.data.editInvoicePermissions()
	? [
		elements.list([
			m('button.btn.btn-secondary',
				{
					style:
						{ backgroundColor: 'transparent'
						, border: 'solid 1px #3380c2'
						, position: 'relative'
						, top: '-0.1em'
						, height: '2.9em'
						, width: '5em'
						, color: '#434aa3'
						, textDecoration: 'underline'
						}
					,onclick: () => {
						const ni = {newinvoice: true, key: uuid.v4()}
						ctrl.model.newInvoices([ni].concat(ctrl.model.newInvoices()))
						ctrl.model.selectedInvoices([ni])
					}
					,disabled:
						ctrl.model.masterPELIST().some(R.prop('created'))
						|| ctrl.model.masterLIST().some(R.prop('created'))
				}
				, 'Add'
			)
			,m('label.dib.control-label', 'Ledgers')
		])

		,invoiceInputs()
	]
	: null
}

var ReconTab = function(ctrl){
	return !ctrl.model.data.editContractPermissions()
	&& !ctrl.model.data.readContractPermissions()
	? elements.alert(`info`, `Contract permissions are required for Reconciliation`)
	: ctrl.model.reconloading()
	? elements.centeredSpinner()
	: [

		m('br')

		,ctrl.model.reconArray().length == 1
		? elements.alert(`info`, `Save this project to start the reconciliation`)
		: null

		,m('div'
				+ css`
					display: grid;
					justify-items: end;
					justify-content: end;
					align-items: end;
					align-content: end;
					gap: 1em;
					/* max-width: 31em; */
					grid-template-columns: minmax(0em, 31em);
				`
				,m(DropdownButton, R.merge(
					{}
					,{
						options:
						[
							m(
								'a'
								, {
									title: 'Download imports for Xero'
									,href:
										ctrl.model.data.fetchXeroExport({
											sales:true
											, contract_items: ctrl.model.invoiceRender().map(R.prop('contract_items_id')).filter(R.identity).join(',')

											, invoices: ctrl.model.invoiceRender().map(R.prop('invoice_id')).filter(R.identity).join(',')

										})
								}
								, 'XERO - Sales'
							)
							,m(
								'a'
								, {
									title: 'Download imports for Xero'
									,href:
										ctrl.model.data.fetchXeroExport({
											bills:true
											, contract_items: ctrl.model.invoiceRender().map(R.prop('contract_items_id')).filter(R.identity).join(',')

											, invoices: ctrl.model.invoiceRender().map(R.prop('invoice_id')).filter(R.identity).join(',')

										})
								}
								, 'XERO - Bills'
							)
						]
						,text: 'Downloads'
						,onclick(){}
						,onselect(){}
						,disabled:
							!ctrl.model.editPermissions()
							|| ctrl.model.metaDataLoading()
					})
				)
		)
		,m('br')
		,elements.table(
			ctrl.model.data
			,[
				''
				, 'Category'
				, 'Item'
				, ''
				, 'Material ' + makeMoney(ctrl.model.totalRecon().materialvalue)
				, 'Expenditures ' + makeMoney(ctrl.model.totalRecon().expenditurevalue)
				, 'Revenue ' + makeMoney(ctrl.model.totalRecon().revenuevalue)
				, 'Profits '
				+ makeMoney(
					ctrl.model.totalRecon().materialvalue
					+ ctrl.model.totalRecon().expenditurevalue
					+ ctrl.model.totalRecon().revenuevalue
				)
				, 'Forecasted Profits '
				+ makeMoney(
					(ctrl.model.data.coalatedSelectedVersion()
					[ctrl.model.form().project.contractrecognitionid] || {})
					.forecastedProfits
				)
				,'Approve'
				,'Receive'
				,''
				,'Payment Ref'
				,''
			]
			,ctrl.model.reconArray()
			.map((o, oi, oArray) => [

				!o.itemk
				? expansionRender(ctrl, o, oi, oArray)
				: ''

				,!o.itemk
				? ctrl.model.checkInvoiceDeletion({
					o
					,v: o.discipline_name ? o.discplineNameDescription : o.project_name()
				})
				: !o.subitemk
				? expansionRender(ctrl, o, oi, oArray)
				: o.invoice_inv_id

				,ctrl.model.checkInvoiceDeletion({
					o
					,v: o.rendername ? o.rendername : ''
				})

				,o.subitemk
				? ctrl.model.checkInvoiceDeletion({
					o
					,v: [Number(o.qty).toFixed(3), o.rendernameapp, '  x  ', makeMoney(o.rate)].join(' ')
				})
				: ''

				,o.materialvalue
				? ctrl.model.checkInvoiceDeletion({
					o
					,e: !o.itemk ? '.underline' : ''
					,v: makeMoney(o.materialvalue)
				})
				: ''

				,o.expenditurevalue
				? ctrl.model.checkInvoiceDeletion({
					o
					,e: !o.itemk ? '.underline' : ''
					,v: makeMoney(o.expenditurevalue)
				})
				: ''

				,o.revenuevalue
				? ctrl.model.checkInvoiceDeletion({
					o
					,e: !o.itemk ? '.underline' : ''
					,v: makeMoney(o.revenuevalue)
				})
				: ''

				,ctrl.model.checkInvoiceDeletion({
					o
					,v: makeMoney(o.expenditurevalue + o.revenuevalue + o.materialvalue)
					,e: o.expenditurevalue + o.revenuevalue + o.materialvalue < 0
						? '.dark-red.fw6'
						: !o.itemk
						? '.underline'
						: ''
				})

				,!o.discipline_name
				? ''
				: ctrl.model.checkInvoiceDeletion({
					o
					,v: makeMoney(
						(ctrl.model.data.coalatedSelectedVersion()
						[o.contractrecognitionid]|| {})
						.forecastedProfits
					)
				})

				,elements.listNoPadding([
					!(!o.itemk && Object.keys(o.subitems).length || o.itemk && !o.subitemk || o.subitemk)
					? ''
					: elements.checkbox(
						{
							onchange: m.withAttr(
								'checked'
								, (e) => {

									var itemcounter = 0
									const oitems =
										!o.itemk
										? R.unnest(
											expansionRender(ctrl, o, oi, oArray, true)
											.map((osk, oski, oskarray) => {
												const newpos = !oskarray[oski-1] || osk.expanded ? oi+oski+1 : oi+oski+itemcounter+1
												itemcounter = itemcounter + osk.subitems.length
												return !osk.subitemk
												? expansionRender(ctrl, osk, newpos, oArray, true)
												: []
											})
										)
										: !o.subitemk
										? expansionRender(
											ctrl
											, o
											, oi
											, oArray
											, true
										)
										: [o]

									ctrl.model.updateInvoiceItems(oitems, {approved: e})

								}
							)
							,checked:
								o.approved
								|| o.approved == null && o.subitemk && o.invoice_approval
								|| (
									o.subitems && !o.subitemk
									? (!o.itemk ? R.unnest(Object.keys(o.subitems).map((si) => o.subitems[si])) : o.subitems)
									.every((so) => {
										const theitem =
											so.invoice_items_id
											? ctrl.model.baseInvoiceIndex()['ii'][so.invoice_items_id]
											: so.invoice_id
											? ctrl.model.baseInvoiceIndex()['i'][so.invoice_id]
											: {approved: false}
										return theitem['approved']
										|| theitem['approved'] == null
										&& ctrl.model.baseInvoiceIndex()['i'][so.invoice_id]['invoice_approval']
									})
									: false
								)
							,disabled: !ctrl.model.editPermissions() || ctrl.model.metaDataLoading()
						}
					)

					,o.subitemk && (o.approved || o.approved == null && o.invoice_approval || o.subitemk)
					? 'Approved'
					: o.subitemk && !o.approved
					? 'Not Approved'
					: ''
				])

				,elements.listNoPadding([
					!(!o.itemk && Object.keys(o.subitems).length || o.itemk && !o.subitemk || o.subitemk)
					|| !ctrl.model.editPermissions() || ctrl.model.metaDataLoading()
					? ''
					: elements.dateInput(
						(e) => {
							var itemcounter = 0
							let oitems = []
							if( e !== undefined ){

								oitems =
								!o.itemk
								? R.unnest(
									expansionRender(ctrl, o, oi, oArray, true)
									.map((osk, oski, oskarray) => {
										const newpos = !oskarray[oski-1] || osk.expanded ? oi+oski+1 : oi+oski+itemcounter+1
										itemcounter = itemcounter + osk.subitems.length
										return !osk.subitemk
										? expansionRender(ctrl, osk, newpos, oArray, true).concat(osk)
										: []
									})
								)
								: !o.subitemk
								? expansionRender(
									ctrl
									, o
									, oi
									, oArray
									, true
								)
								: [o]

								if (e){
									ctrl.model.updateInvoiceItems(oitems, {received: true, receiveddate: e })
									o.receiveddate = e
									o.received = true

								}
								else {
									ctrl.model.updateInvoiceItems(oitems, {received: false, receiveddate: null})
									o.receiveddate = null
									o.received = false
								}
							}

							return o.receiveddate
						}
						,{ disabled: !ctrl.model.editPermissions() || ctrl.model.metaDataLoading() }
					)

					,(o.subitemk && (o.received || o.invoice_received))
					|| o.subitems && Object.keys(o.subitems).length
					&& Object.keys(o.subitems).every((i) =>
						(o.subitems[i].invoice_received || o.subitems[i].receiveddate)
						|| Array.isArray(o.subitems[i]) && o.subitems[i].every((ii) => ii.invoice_received_date || ii.receiveddate)
					)
					? ( o.recqty || o.subitems ? 'Received' : '0 Received' )
					: (o.subitemk && !o.recqty && !o.received && !o.invoice_received)
					|| o.subitems && Object.keys(o.subitems).length
					&& !Object.keys(o.subitems).every((i) =>
						(o.subitems[i].invoice_received || o.subitems[i].receiveddate)
						|| Array.isArray(o.subitems[i]) && o.subitems[i].every((ii) => ii.invoice_received_date || ii.receiveddate)
					)
					? 'Outstanding'
					: ''
				])

				,
				(o.qtymatch && o.subitemk) || o.subitemk
				? Pencil(
					() =>
						[!o.rectotal ? o.recqty : o.savetotal ? o.savetotal*(o.rate < 0 ? -1 : 1) : null
						,o.recqty == o.qty && o.recqty && (o.subitemk || o.invoice_no)
						? 'Fully Paid'
						: (o.recqty < o.qty && o.recqty > 0
						|| o.recqty > o.qty && o.recqty < 0)
						&& o.recqty && (o.subitemk || o.invoice_no)
						? 'Under Paid - Track Remainder'
						: (o.recqty > o.qty && o.recqty > 0
						|| o.recqty < o.qty && o.recqty < 0)
						&& o.recqty && (o.subitemk || o.invoice_no)
						? 'Over Paid - Track Remainder'
						: 'Paid'
						].join(" ")
					,() =>
				m(''
					+ css`
						display: grid;
						grid-auto-flow: column;
						grid-template-rows: 0.6fr 0.4fr;
						grid-template-columns: 1fr;
						gap: 0.25em;
					`
					,[
						o.qtymatch && o.subitemk
						?
								m(''
									+ css`
										display: grid;
										grid-auto-flow: column;
										grid-template-rows: 0.5fr 0.5fr;
										grid-template-columns: 1fr;
										gap: 0.5em;
										width: 14em;
										padding: 0.5em;
									`
									,[
										m(''
											+ css`
												display: grid;
												grid-auto-flow: column;
												grid-template-rows: 1fr;
												grid-template-columns: 0.3fr 0.7fr;
											`
											,[
												m(''
													+ css`
														vertical-align: middle;
														text-align: center;
														padding-top: 0.4em;
													`
													, o.rectotal ? 'Total' : ' Qty'
												)
												,m(
													NumberInput
													,R.merge(
														ctrl.model.data
														,{
															errors: ctrl.model.errors
															,errorLabel: 'Receieved Qty'
														}
													)
													,{
														prop:
															(d) => {

																if(d || d == 0){
																	const invoice =
																		ctrl.model.baseInvoiceRender()
																		.find((i) => i.invoice_id() == o.invoice_id)

																	const invoiceitem =
																		invoice.invoice_items
																		.find((ii) =>
																			ii.invoice_items_id
																			== o.invoice_items_id
																		)

																	o.saveqty =
																		!o.rectotal
																		? d
																		: Math.abs(d/o.rate)

																	o.savetotal =
																		!o.rectotal
																		? Math.abs(d*o.rate)
																		: Math.abs(d)

																	invoiceitem
																		? invoiceitem.invoice_items_received_amount = o.saveqty
																		: invoice.recqty = o.saveqty

																	if (o.saveqty && o.saveqty != o.qty){
																		o.received = true
																		;(invoiceitem || invoice).received = true
																	}
																	else if ( !o.saveqty ){
																		o.received = false
																		;(invoiceitem || invoice).received = false
																	}

																	invoice.created = true
																	o.recqty = o.saveqty
																}

																return !o.rectotal ? o.recqty : o.savetotal ? o.savetotal*(o.rate < 0 ? -1 : 1) : null
															}
														,attrs: {
															disabled: !ctrl.model.editPermissions() || ctrl.model.metaDataLoading()
															,step: 'any'
															,style: {'max-width': '5em'}
														}
													}
												)
											]
										)

										,m(''
											+ css`
												display: grid;
												grid-auto-flow: column;
												grid-template-rows: 1fr;
												gap: 1em;
												align-items: center;
												justify-items: center;
												justify-content: start;
											`
											, o.rectotal ? "Show Quantity" : "Show Dollars"
											, m(`button`
												+ css`
													border-radius: 0.5em;
													padding: 0.3em;
													border: none;
													background-color: inherit;
												`
												,{
													onclick: () =>  o.rectotal = !o.rectotal
												}
												,o.rectotal ? elements.switchiconNeuRight() : elements.switchiconNeuLeft()
											)
										)
									]
								)
						: o.subitemk
						? ' Must be changed via financials'
						: ''

						,m(''
							+ css`
								display: grid;
								grid-auto-flow: column;
								grid-template-rows: 1fr;
								grid-template-columns: 0.7fr 0.3fr;
								gap: 0.2em;
							`
							,[

								m(''
									+ css`
										vertical-align: middle;
										text-align: center;
										padding-top: 0.4em;
									`
									, o.recqty == o.qty && o.recqty && (o.subitemk || o.invoice_no)
									? 'Fully Paid'
									: (o.recqty < o.qty && o.recqty > 0
									|| o.recqty > o.qty && o.recqty < 0)
									&& o.recqty && (o.subitemk || o.invoice_no)
									? 'Under Paid - Track Remainder'
									: (o.recqty > o.qty && o.recqty > 0
									|| o.recqty < o.qty && o.recqty < 0)
									&& o.recqty && (o.subitemk || o.invoice_no)
									? 'Over Paid - Track Remainder'
									: ''
								)


								,m(''
									+ css`
										display: flex;
										align-items: center;
									`
									,o.recqty != o.qty && o.recqty
									&& (o.subitemk || o.invoice_no)
									? elements.checkbox(
										{
											onchange: m.withAttr(
												'checked'
												, (e) => {

													const invoice =
														ctrl.model.baseInvoiceRender()
														.find((i) => i.invoice_id() == o.invoice_id)

													const invoiceitem =
														invoice.invoice_items
														.find((ii) =>
															ii.invoice_items_id
															== o.invoice_items_id
														)

													if(e){
														invoice.created = true
														invoice.forcesplit = true
														invoiceitem ? invoiceitem.forcesplit = true : null
														o.forcesplit = true
													} else {
														invoice.created =
															!o.paymentref
															&& !o.received
															&& !o.approved
															? false
															: invoice.created
														invoice.forcesplit = false
														invoiceitem ? invoiceitem.forcesplit = false : null
														o.forcesplit = false
													}
												}
											)
											,checked: o.forcesplit
											,title: 'Track Remaining quantity against summaries in Odin for future expectations of receipt'
										}
									)
									: ''
								)
							]
						)

					]
				)
				)
				: ''

				,!o.itemk && Object.keys(o.subitems).length || o.itemk && !o.subitemk || o.subitemk
				? Pencil(
					() => o.paymentref
					,() =>
						elements.textInput(
							(d) => {


								if(d || d == ''){

									var parents = []

									var itemcounter = 0
									const oitems =
										!o.itemk
										? R.unnest(
											expansionRender(ctrl, o, oi, oArray, true)
											.map((osk, oski, oskarray) => {
												const newpos = !oskarray[oski-1] || osk.expanded ? oi+oski+1 : oi+oski+itemcounter+1
												itemcounter = itemcounter + osk.subitems.length
												return !osk.subitemk
												? expansionRender(ctrl, osk, newpos, oArray, true)
												: []
											})
										)
										: !o.subitemk
										? expansionRender(
											ctrl
											, o
											, oi
											, oArray
											, true
										)
										: [o]

									o.paymentref = d

									ctrl.model.updateInvoiceItems(oitems, {paymentref: d}, parents)

								}

								return d || o.paymentref
							}
							,{
								placeholder: "Record the payment reference number"
								,title: "Reference number of payment"
								,disabled: !ctrl.model.editPermissions() || ctrl.model.metaDataLoading()
							}
						)
				)
				: ''

				,(!o.invoice_items_id && !o.invoice_id)
				|| !ctrl.model.data.editInvoicePermissions()
				? ``
				: m(''
					+ css`
						display: grid;
						grid-auto-flow: column;
						grid-template-rows: 1fr;
						grid-template-columns: 0.5fr 0.5fr;
						gap: 1em;
						padding: 0.5em;
						margin-bottom: 3em
					`
					,[
						elements.xRemoval({
							onclick: () => {

								const ditems =
									o.subitems.length
									? o.subitems
									: [o]

								const remove =
									ctrl.model.deleteInvoiceItemsHash()[o.invoice_items_id]
									|| ctrl.model.deleteInvoiceHash()[o.invoice_id]

								ditems.forEach((di) => {

									if( remove ){
										if( di.invoice_items_id){

											const peindex =
												ctrl.model.deleteProjectExtras()
												.findIndex((t) =>
													t == di.project_extras_id
												)

											delete ctrl.model.deleteInvoiceItemsHash()[di.invoice_items_id]

											peindex > -1
												? ctrl.model.deleteProjectExtras().splice(peindex, 1)
												: null

										}
										else {
											delete ctrl.model.deleteInvoiceHash()[di.invoice_id]
										}

									}
									else {
										di.invoice_items_id
											? ctrl.model.deleteInvoiceItemsHash()[di.invoice_items_id] = di.invoice_items_id
											: ctrl.model.deleteInvoiceHash()[di.invoice_id] = o.invoice_id
									}
								})

							}
							,undo: ctrl.model.deleteInvoiceItemsHash()[o.invoice_items_id]
							,title: ctrl.model.deleteInvoiceItemsHash()[o.invoice_items_id] ? 'Restore this invoice' : 'Delete this invoice'
							,disabled:
								(!o.invoice_items_id && !o.invoice_id)
								|| (o.invoice_items_id && ctrl.model.deleteInvoiceHash()[o.invoice_id])
						})

						,m(''
							+ css`
								display: grid;
								grid-auto-flow: column;
								grid-template-rows: 0.5fr 0.5fr;
								grid-template-columns: 1fr;
								gap: 1em;
							`
							,o.subitems.length
							&& ctrl.model.deleteInvoiceItemsHash()[o.invoice_items_id]
							&& !o.approved
							&& !o.received
								? [
									m(''
										+ css`
											display: grid;
											grid-auto-flow: column;
											text-align: center;
											gap: 1em;
										`
										,ctrl.model.deleteProjectExtras()
										.findIndex((t) =>
											t == R.last(o.subitems).project_extras_id
										) == -1
										? 'Keep Task'
										: 'Delete Task'
									)

									,m(''
										,[
											m(`button`
												+ css`
													border-radius: 0.5em;
													padding: 0.3em;
													border: none;
													background-color: inherit;
												`
												,{
													onclick:
														(e) => {
															if(
																e
																&& ctrl.model.deleteProjectExtras()
																.findIndex((t) =>
																	t == R.last(o.subitems).project_extras_id
																) == -1
															){
																ctrl.model.deleteProjectExtras()
																.push(R.last(o.subitems).project_extras_id)
															}
															else {
																ctrl.model.deleteProjectExtras()
																.splice(
																	ctrl.model.deleteProjectExtras()
																	.findIndex((t) =>
																		t == R.last(o.subitems).project_extras_id
																	)
																	,1
																)
															}
														}
												}
												,ctrl.model.deleteProjectExtras()
												.findIndex((t) =>
													t == R.last(o.subitems).project_extras_id
												) == -1
													? elements.switchiconNeuLeft()
													: elements.switchiconNeuRight()
											)
										]
									)
								]
								: []
						)
					]
				)
			])
		)
	]
}


var ContractualTab = function(ctrl){
	return ctrl.model.ActiveContractsFeature()
		? [

			// elements.list([
			// 	ctrl.model.contractAddView(ctrl)
			// 	,ctrl.model.ContractDeleteButton()
			// ])

			m('br')

			,elements.alert(`info`,
				`A Notice of Delay is a potential delay to today's work that releases liability to the current time frames. This notice is applied to every
				future day of work until an Extension of Time is created to flag that the delay has ended and works can resume. An Extension of Time as mentioned,
				indicates a previous Notification of Delay has been resolved and works can continue from this date with contractual liability to time frames`
			)

			// ,ctrl.model.DisableEmptySave()

			,ctrl.model.extraContractObligationData().length > 0
				? [
					elements.table(ctrl.model.data, ['', 'Date', 'Type', '+/- Work', 'Description', 'Contract Clause', 'Escalated', 'Approval', 'Created By'],
						ctrl.model.extraContractObligationData().map((c) => [
							ctrl.model.editContractRecognitions() ? ctrl.model.getActivateContractDeleteButton(c) : null
							,moment(c.contractDate).format("ll")
							,c.midfix == 'vars'
								? 'Variation'
								: c.midfix == 'nods'
									? 'Notice of Delay'
									: 'Extension of Time'
							,!ctrl.model.editContractRecognitions() || !ctrl.model.getActivateContractDeleteButton(c, true)
								? c['allocations_' + c.midfix + '_amount'] ? c['allocations_' + c.midfix + '_amount']() : null
								: 									c['allocations_' + c.midfix + '_amount']
										? m(
											NumberInput
											,R.merge(
												ctrl.model.data
												,{
													errors: ctrl.model.errors
													,errorLabel: c['allocations_' + c.midfix + '_description']() + ' +/- Work'
												}
											)
											,{
												prop: c['allocations_' + c.midfix + '_amount']
												,attrs: {
													step: 0.01
													,title: "plus or minus required works"
												}
											}
										)
										: null


							,!ctrl.model.editContractRecognitions() || !ctrl.model.getActivateContractDeleteButton(c, true)
								? c['allocations_' + c.midfix + '_description']()
								: elements.textInput( c['allocations_' + c.midfix + '_description'], {title: "A Description of why this contract occurred for formal documentation"})

							,!ctrl.model.editContractRecognitions() || !ctrl.model.getActivateContractDeleteButton(c, true)
								? c['allocations_' + c.midfix + '_clause']()
								: elements.textInput( c['allocations_' + c.midfix + '_clause'], {title: "The Formal contract clause that forces this to apply"})

							,ctrl.model.editContractRecognitions()
								? elements.checkbox(
									{
										onchange: m.withAttr(
											'checked'
											, (e) => {
												c['allocations_' + c.midfix + '_escalated'](e)
												ctrl.model.contractPatchIndex()[c.allocation_id()] = ctrl.model.allocationIndex()[c.allocation_id()]
											}
										)
										,checked: c['allocations_' + c.midfix + '_escalated']()
										,title: `Set to be escalated`
										,disabled: !ctrl.model.editContractRecognitions()
									}
								)
								: c['allocations_' + c.midfix + '_escalated']()
									? 'Yes'
									: 'No'


							,ctrl.model.editContractRecognitions()
								? elements.checkbox(
									{
										onchange: m.withAttr(
											'checked'
											, (e) => {
												c['allocations_' + c.midfix + '_approved'](e)
												ctrl.model.contractPatchIndex()[c.allocation_id()] = ctrl.model.allocationIndex()[c.allocation_id()]
											}
										)
										,checked: c['allocations_' + c.midfix + '_approved']()
										,title: `Set to be approved`
										,disabled: !ctrl.model.editContractRecognitions()
									}
								)
								: c['allocations_' + c.midfix + '_approved']()
									? 'Yes'
									: 'No'

							,ctrl.model.usersIndex()[c.user_id()].usersFullName

						])
					)
				]
				: elements.alert('info',`I have no contract obligations recorded for reference, these must be added via Updates`)
		]
		: elements.alert('info',`Activate the module for Recording EOTs, Variations and Delays Tools to use this tracking`)
}


var quoteModal =
	({ ctrl }) => {

		const qiContactList = ctrl.model.qiContactList
		const qiSelection = ctrl.model.qiSelection
		const newqi = ctrl.model.newqi

		const tasksAndInvoice =
			() =>
				m(''
					+ css`
						display: grid;
						gap: 2em;
					`
					,[

						// ,m(``
						// 	+ css`
						// 		display: grid;
						// 		grid-template-columns: 0.25fr 0.75fr;
						// 		align-items: center;
						// 	`
						// 	,elements.checkbox({
						// 		onchange:
						// 			function(event){
						// 				if(event.currentTarget.checked == true){
						// 					ctrl.model.ieview(true)
						// 					ctrl.model.expoptions().map((i) =>
						// 						!ctrl.model.requestedItems().find((ri) => exposer(ri[ctrl.model.qieqProp()]) == exposer(i[ctrl.model.qieqProp()]))
						// 						&& ctrl.model.requestedItems().push(i)
						// 					)
						// 				}
						// 				else if (event.currentTarget.checked == false){
						// 					ctrl.model.ieview(false)
						// 					ctrl.model.expoptions().map((i) => {
						// 						const index =
						// 							ctrl.model.requestedItems().findIndex((ri) => exposer(ri[ctrl.model.qieqProp()]) == exposer(i[ctrl.model.qieqProp()]) )

						// 							index > -1 && ctrl.model.requestedItems().splice(index, 1)
						// 					})
						// 				}
						// 			}
						// 		,checked:
						// 			ctrl.model.invoiceSelections().some((i) => ctrl.model.qicheck(i) < 0 )
						// 	})
						// 	,'Expenditures'
						// )

						// ,m(``
						// 	+ css`
						// 		display: grid;
						// 		grid-template-columns: 0.25fr 0.75fr;
						// 		align-items: center;
						// 	`
						// 	,elements.checkbox({
						// 		onchange:
						// 			function(event){
						// 				if(event.currentTarget.checked == true){
						// 					ctrl.model.irview(true)
						// 					ctrl.model.revoptions().map((i) =>
						// 						!ctrl.model.requestedItems().find((ri) => exposer(ri[ctrl.model.qieqProp()]) == exposer(i[ctrl.model.qieqProp()]))
						// 						&& ctrl.model.requestedItems().push(i)
						// 					)
						// 				}
						// 				else if (event.currentTarget.checked == false){
						// 					ctrl.model.irview(false)
						// 					ctrl.model.revoptions().map((i) => {
						// 						const index =
						// 							ctrl.model.requestedItems().findIndex((ri) => exposer(ri[ctrl.model.qieqProp()]) == exposer(i[ctrl.model.qieqProp()]) )

						// 							index > -1 && ctrl.model.requestedItems().splice(index, 1)
						// 					})
						// 				}
						// 			}
						// 		,checked:
						// 			ctrl.model.invoiceSelections().some((i) => ctrl.model.qicheck(i) > 0 )
						// 	})
						// 	, 'Revenues'
						// )

						m(``
							+ css`
								display: grid;
								gap: 1em;
								grid-auto-flow: row;
							`
							,[
								{
									header: [
										elements.checkbox({
											onchange:
												function(event){
													if(event.currentTarget.checked == true){
														ctrl.model.irview(true)
														ctrl.model.invoiceSelections().map((i) =>
															!ctrl.model.requestedItems().find((ri) => exposer(ri[ctrl.model.qieqProp()]) == exposer(i[ctrl.model.qieqProp()]))
															&& ctrl.model.requestedItems().push(i)
														)
													}
													else if (event.currentTarget.checked == false){
														ctrl.model.irview(false)
														ctrl.model.invoiceSelections().forEach((i) => {
															const index =
																ctrl.model.requestedItems().findIndex((ri) => exposer(ri[ctrl.model.qieqProp()]) == exposer(i[ctrl.model.qieqProp()]) )

																index > -1 && ctrl.model.requestedItems().splice(index, 1)
														})
													}
												}
											,checked:
												ctrl.model.invoiceSelections().length
												&& ctrl.model.invoiceSelections().every((i) =>
													ctrl.model.requestedItems().find((ri) =>
														exposer(ri[ctrl.model.qieqProp()]) == exposer(i[ctrl.model.qieqProp()])
													)
												)
										})
										,m('h5', 'Items (' + ctrl.model.invoiceSelections().length + ')')
										,m('h5', 'Quantity')
										,qiSelection() != 'Quote' ? m('h5', 'Approved') : m('h5', 'Notes')
										,qiSelection() != 'Quote' ? m('h5', 'Received') : null
										,qiSelection() != 'Quote' ? m('h5', 'Invoice') : null
									].filter(Boolean)
								}
							].concat(R.sortBy(
								(i) =>
									!i.invoice_name
										? new Date(i.project_extras_date_completed()).getTime()
										: new Date(i.invoice_date).getTime()
								,ctrl.model.invoiceSelections()
							))
							.map((i) =>
								qiSelection() != 'Quote'
								? m(``
									+ css`
										display: grid;
										gap: 1em;
										justify-content: left;
										grid-template-columns: 0.04fr 0.45fr 0.075fr 0.085fr 0.11fr 0.24fr;
										align-items: start
									`
									, i.header || [
										ctrl.model.reconloading()
										&& ctrl.model.requestedItems().find((is) =>
											exposer(is[ctrl.model.qieqProp()]) == exposer(i[ctrl.model.qieqProp()])
										)
										? elements.spinner()
										: elements.checkbox({
											onchange:
												function(event){
													if(event.currentTarget.checked == true){
														ctrl.model.requestedItems().push(i)
													}
													else if (event.currentTarget.checked == false){
														ctrl.model.requestedItems().splice(
															ctrl.model.requestedItems().findIndex((p) =>
																exposer(p[ctrl.model.qieqProp()]) == exposer(i[ctrl.model.qieqProp()])
															)
															, 1
														)
													}
												}
											,checked:
												ctrl.model.requestedItems().find((is) =>
													exposer(is[ctrl.model.qieqProp()]) == exposer(i[ctrl.model.qieqProp()])
												)
										})
										,m('',
											[i.invoice_name, i.invoice_description].join( '. ')
											,elements.nonStrikeList(
												i.invoice_items
												.map(
													label => ({
														label: label.project_extras_type
														,action: () => {}
													})
												)
											)
										)
										,m(``
											+ css`
												display: grid;
												gap: 0.05em;
												grid-auto-flow: column;
												justify-content: left;
												align-items: center
											`
											,elements.icons.x(), R.max(i.invoice_amount, i.itemssum)
										)
										, m('', i.invoice_approval ? elements.icons.greenTick() : elements.icons.redCross() || '' )
										, m(''
											,i.invoice_received_date
											? moment( i.invoice_received_date ).format("ll")
											: elements.icons.redCross()
										)
										, m('', i.invoice_inv_id)
									]
								)
								: m(``
									+ css`
										display: grid;
										gap: 1em;
										grid-auto-flow: column;
										justify-content: left;
										grid-template-columns: 0.04fr 0.475fr 0.1fr 0.385fr;
									`
									,i.header || [
										elements.checkbox({
											onchange:
												function(event){
													if(event.currentTarget.checked == true){
														ctrl.model.requestedItems().push(i)
													}
													else if (event.currentTarget.checked == false){
														ctrl.model.requestedItems().splice(
															ctrl.model.requestedItems().findIndex((p) =>
																exposer(p[ctrl.model.qieqProp()]) == exposer(i[ctrl.model.qieqProp()])
															)
															, 1
														)
													}
												}
											,checked:
												ctrl.model.requestedItems().find((is) =>
													exposer(is[ctrl.model.qieqProp()]) == exposer(i[ctrl.model.qieqProp()])
												)
										})
										,m('', [
												i.project_extras_date_completed()
												? moment(i.project_extras_date_completed()).format("Do MMM")
												: 'Not Completed'
												, i.project_extras_type()
											].join( ' - ')
										)

										,m(``
											+ css`
												display: grid;
												gap: 0.05em;
												grid-auto-flow: column;
												justify-content: left;
												align-items: center
											`
											,elements.icons.x()
											, i.project_extras_planned_amount()
										)
										, m('', i.project_extras_notes() )
									]
								)
							)
						)
					]
				)


		const contactsList =
			() =>
				m(''
					+ css`
						display: grid;
						gap: 1em
					`
					,[

						m(''
							+ css`
								display: grid;
								gap: 0.5em;
								grid-template-columns: 0.5fr 0.5fr;
								align-items: center;
								justify-items: start;
							`
							,[

								m('h4.mb1', 'Send To')

								,ctrl.model.form().project.project_contacts.length
								&& !ctrl.model.form().project.project_contacts
								.every((c) => (qiContactList().emails() || '').indexOf(c.project_contacts_email()) > -1 )
								? m('button.btn.btn-secondary',
									{
										style:
											{ backgroundColor: 'transparent'
											, border: 'solid 1px #3380c2'
											, position: 'relative'
											// , top: '-0.1em'
											, height: '2.9em'
											, width: '15em'
											, color: '#434aa3'
											// , textDecoration: 'underline'
											// , fontSize: '0.9em'
											// , padding: '0.2em 0.5em'
											}
										,onclick: () => {
											const qsdetails = qiContactList()
											// qsdetails.phones(
											// 	ctrl.model.form().project.project_contacts.map(R.pipe(R.prop('project_contacts_phone'), R.call)).join(';')
											// )
											qsdetails.emails(
												ctrl.model.form().project.project_contacts
												.map(R.pipe(R.prop('project_contacts_email'), R.call)).join(';')
											)
										}
									}
									, 'Add Contacts from the Project'
								)
								: null

							]
						)



						,m(''
							+ css`
								display: grid;
								gap: 1em
							`
							,
								// m(`button`
								// 	+ css`
								// 		border-radius: 0.5em;
								// 		background-color: white;
								// 		padding: 0.3em;
								// 		border: solid 0.1px rgba(0,0,0,0.4);
								// 		width: 1.8em;
								// 		height: 1.8em;
								// 	`
								// 	,{
								// 		onclick: () => newcontact()
								// 		,disabled: false
								// 	}
								// 	, '+'
								// )
								// ,
								elements.textInput(
									qiContactList().emails
									,{
										disabled:
											!ctrl.model.editPermissions()
											|| ctrl.model.metaDataLoading()
										,placeholder: 'john@example.com'
									}
								)
								// ,elements.textInput(
								// 	c.phones
								// 	,{
								// 		disabled:
								// 			!ctrl.model.editPermissions()
								// 			|| ctrl.model.metaDataLoading()
								// 		,placeholder: 'Example 0404 XXX XXX; 0404 YYY YYY'
								// 	}
								// )
								// ,elements.textArea(
								// 	c.message
								// 	,{
								// 		placeholder: "Message"
								// 	}
								// )
								// ,elements.xRemoval({
								// 	onclick:
								// 		() =>
								// 			qiContactList( qiContactList().filter((qic) => qic.id != c.id) )
								// })
						)

						// ,!qiContactList().emails()
						// ? elements.alert(
						// 	'warning'
						// 	,'Enter some emails to send the ' + qiSelection()
						// )
						// : null
					]
				)

		const terms =
			() =>
				m(''
					+ css`
						display: flex;
						gap: 2em;
						flex-direction: column;
					`
					,[

						m('h4.mb1', 'Payment Term ( Days )')
						,ctrl.model.editContractPermissions()
							? Pencil(
							() => newqi().contract_payment_term()
							,() =>
								m(
									NumberInput
									,R.merge(
										ctrl.model.data
										,{
											errors: ctrl.model.errors
											,errorLabel: 'Payment Term'
										}
									)
									,{
										prop: newqi().contract_payment_term
										,attrs: {
											step: 1
											,min: 0
											,title: `Terms of payment measure in days from date of invoice`
										}
									}
								)
						)
						: newqi().contract_payment_term() || 0



						,m('h4.mb1', 'Conditions')
						,elements.textArea(
							newqi().contract_terms
							,{
								placeholder: "Description of the terms of agreement"
							}
						)

					]
				)

		function openQuoteModal(){

			modalStack.add({
				title: 'Quote or Invoice'
				, view: () =>

					m('.idetails'
						+ H.css`
							& {
								display: grid;
								align-item: center;
								gap: 3em;
								grid-template-rows: max-content 1fr max-content;
							}
							& h1,& h2,& h3,& h4,& h5,& h6 {
								margin: 0em;
							}

							& {
								display: grid;
								gap: 1em;
							}
						`

						, m(''
							+ css`
								display: flex;
								align-items: center;
								gap: 0.5em;
								justify-content: center;
							`
							, elements.selectbuttons(
								['Quote', 'Invoice']
								,(t) => {
									if(t){
										qiSelection(t)
										ctrl.model.requestedItems([])
									}
									return qiSelection()
								}
								,{
									disabled: false
									,title: `Quote or Invoice`
								}
							)
						)
						, m('.form-items'
							+ css`
								display: grid;
								gap: 2em;
								overflow-y: auto;
								align-content: start;
							`
							,tasksAndInvoice()

							, !ctrl.model.ieview() && !ctrl.model.irview()
								? `Select revenues or expenditures to see available ` + qiSelection() + ` items`
								: !ctrl.model.invoiceSelections().length
								? `Create tasks or invoices to be generate or send quotes and invoices`
								: ''
							, (ctrl.model.invoiceSelections().length || '') && terms()
							, (ctrl.model.invoiceSelections().length || '') && contactsList()
						)
						, m('.actions'
							+ css`
								display: grid;
								gap: 1em;
							`
							.tablet`
								grid-template-columns: repeat(1, minmax(max-content, 20em));
								justify-content: center;
							`
							// , elements.action(
							// 	'Generate'
							// 	, () => ctrl.intent.sendQI({generateOnly: true})
							// 	, ctrl.model.savingProp
							// 	, 'primary'
							// 	, () =>
							// 		!ctrl.model.qiSelection().length
							// 		|| !ctrl.model.requestedItems().length
							// 		|| !qiContactList().emails()
							// )
							, elements.action(
								'Send'
								, () => ctrl.intent.sendQI({generateOnly: ''})
								, ctrl.model.savingProp
								, 'primary'
								, () =>
									!ctrl.model.qiSelection().length
									|| !ctrl.model.requestedItems().length
									|| !qiContactList().emails()
							)
						)
					)

				, type: 'example'
				, dismissable: true
			})
		}

		let showModal = !ctrl.model.loading()

		return showModal && m('.payment-read-only-summary'+css`padding: 2em;`
			,elements.mobileButtonStack(
				{
					container: {
						attrs: {
							onclick: () => openQuoteModal()
						}
					}
				}
				,[
					elements.mobileButtonStack.item({
						label: [
							m(''
								+ css`
									display: none;
								`
								.tablet`
									display: grid;
									align-content: center;
								`
								, 'Quote or Invoice' //elements.icons.invoice()
							)
						]
						,onclick(){
							openQuoteModal()
						}
					})
				]
			)
		)
	}


var ExtraDetailTab = function(ctrl){

	const taskTableHeader =
		(t, tindex) =>
			m('span'
				+ css`
					font-weight: bold;
					display: flex;
					justify-content: space-between;
					align-items: center;
					padding-bottom: 0.5em;
				`
				,tindex == 0
				? m('span'
					+ css`
						font-weight: bold;
					`
					,''
				)
				: m('input'
					+ css`
						width: 25px;
						height: 25px;
						margin: 0px;
					`
					.$media('(hover: hover)',
						css`
							width: 20px;
							height: 20px;
						`
					)
					,ctrl.model.getActivateDeleteButton(t, 'project_extras')
				)
				,m(`button.pl2`
					+ css`
						background-color: transparent;
						transition: 0.2s;
						opacity: 1;
						color: #434aa3;
						border: none;
					`
					.$hover(`
						opacity: 0.8;
					`)
					.$active(`
						opacity: 0.5;
					`)
					,{
						onclick:
							ctrl.model.activeTab() == 'Tasks'
							? ctrl.model.addNewTask
							: ctrl.model.addNewDiscipline
						,disabled:
							ctrl.model.activeTab() == 'Tasks'
							? false
							: !!ctrl.model.check_dnames(true)
						,title: 'Create a Task'

					}
					, 'Create a New Task '
				)
				,m('span'
					+ css`
						font-weight: bold;
					`
					,''
				)
			)

	const largeProjectDetailsTable = () =>
		scrollableTable.advanced({
			scoped: ctrl.model.data.scoped
			,resize$: ctrl.model.data.resize$
			,alwaysopen: true
			,rows:
				ctrl.model.masterPELIST()
				.map((t, tindex) => ({
					key: t.key
					,header: tindex != 0 ? '' : taskTableHeader(t, tindex)
					,selection: ctrl.model.getActivateDeleteButton(t, 'project_extras')
					,layouts: {
						expanded: ({ x, headers, i }) =>
							m('.expanded'
								+ css`
									display: grid;
									justify-content: space-between;
									grid-template-columns: 1fr;
								`
								, m('.data'
									+ css`
										display: grid;
										justify-content: space-between;
										grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
										overflow: hidden;
										gap: 1em;
										align-items: start;
										align-content: start;
									`
									.$media('(min-width: 1800px)', `
										grid-template-columns: 7.5fr 15fr 10fr repeat(3,minmax(6em,5fr)) 4em 4.5em 8fr 4fr 6fr 6fr 15fr;
									`)
									, headers
										.map(
											k => [
												i == 0
												? m('.data'
													+ css`
														display: grid;
														grid-template-rows: 0.25fr 0.5fr;
														margin-right: 0.75em;
														padding-right: 0.75em;
														overflow: hidden;
													`
													,[m('label.control-label', k), x[k]]
												)
												: m('.data'
													+ css`
														display: grid;
														margin-right: 0.75em;
														padding-right: 0.75em;
														overflow: hidden;
													`
													,x[k]
												)
											]
										)

								)
							)
					}
					,data: {
						'Name': t.project_extras_type()
						,'Description': t.project_extras_description()
						,'Address': t.project_extras_address()

						,'Completed': t.project_extras_date_completed()
							? moment(t.project_extras_date_completed()).format("ddd Do MMM")
							: null
						,'Invalid': t.project_extras_date_invalid()
							? moment(t.project_extras_date_invalid()).format("ddd Do MMM")
							: null
						,'Verified': t.project_extras_date_verified()
							? moment(t.project_extras_date_verified()).format("ddd Do MMM")
							: null

						,'Actual': t.project_extras_actual_amount()
						,'Planned': t.project_extras_planned_amount()
						,'Related Discipline': t.relatedDiscipline
						,'Files': t.filecount
						,'Ledgers':
							[].concat(
								t.revenueinvoices
								,t.costinvoices
							)
							.join(', ')

						,'Related User': t.username || t.invite_email
						,'Notes': t.project_extras_notes()

					}
					,attrs: {
						className: t.project_extras_date_completed()
								? t.project_extras_actual_amount()
									? 'highlight-ok'
									: 'highlight-error'
								: ''
					}
			}))
		})

	const mediumProjectDetailsTable = () =>
		scrollableTable.advanced({
			scoped: ctrl.model.data.scoped
			,resize$: ctrl.model.data.resize$
			,alwaysopen: true
			,rows:
				ctrl.model.masterPELIST()
				.map((t, tindex) => ({
					key: t.key
					,header: tindex != 0 ? '' : taskTableHeader(t, tindex)
					,selection: ctrl.model.getActivateDeleteButton(t, 'project_extras')
					,layouts: {
						expanded: ({ x, headers, i }) =>
							m('.expanded'
								+ css`
									display: grid;
									justify-content: space-between;
									grid-template-columns: 1fr;
								`
								, m('.data'
									+ css`
										display: grid;
										justify-content: space-between;
										grid-template-columns: 7fr 10fr 9fr repeat(3,minmax(6em,5fr)) 5em 5fr 8fr 7fr 10fr;
										overflow: hidden;
									`
									, headers
										.map(
											k => [
												i == 0
												? m('.data'
													+ css`
														display: grid;
														grid-template-rows: 0.25fr 0.5fr;
														margin-right: 0.75em;
														padding-right: 0.75em;
														overflow: hidden;
													`
													,[m('label.control-label', k), x[k]]
												)
												: m('.data'
													+ css`
														display: grid;
														margin-right: 0.75em;
														padding-right: 0.75em;
														overflow: hidden;
													`
													,x[k]
												)
											]
										)

								)
							)
					}
					,data: {
						'Name': t.project_extras_type()
						,'Description': t.project_extras_description()
						,'Address': t.project_extras_address()

						,'Completed': t.project_extras_date_completed()
							? moment(t.project_extras_date_completed()).format("ddd Do MMM")
							: null
						,'Invalid': t.project_extras_date_invalid()
							? moment(t.project_extras_date_invalid()).format("ddd Do MMM")
							: null
						,'Verified': t.project_extras_date_verified()
							? moment(t.project_extras_date_verified()).format("ddd Do MMM")
							: null

						,'A vs P':
							t.project_extras_actual_amount()
							+ ' / '
							+ t.project_extras_planned_amount()
						,'Files': t.filecount
						,'Ledgers':
							[].concat(
								t.revenueinvoices
								,t.costinvoices
							)
							.join(', ')

						,'Related User': t.username || t.invite_email
						,'Notes': t.project_extras_notes()

					}
					,attrs: {
						className: t.project_extras_date_completed()
								? t.project_extras_actual_amount()
									? 'highlight-ok'
									: 'highlight-error'
								: ''
					}
			}))
		})

	const smallProjectDetailsTable = () =>
		scrollableTable.advanced({
			scoped: ctrl.model.data.scoped
			,resize$: ctrl.model.data.resize$
			,alwaysopen: true
			,rows:
				ctrl.model.masterPELIST()
				.map((t) => ({
					key: t.key
					,header: taskTableHeader(t)
					,layouts: {
						expanded: ({ x }) =>
							m('.expanded'
								+ css`
									display: grid;
									justify-content: space-between;
									grid-template-columns: 1fr;
								`
								,m('.data'
									+ css`
										display: grid;
										justify-content: space-between;
										grid-template-columns: 0.25fr 0.75fr;
										padding-bottom: 2em;
									`
									,['Name', 'Description']
									.map((k) =>
										m('.data'
											+ css`
												display: grid;
												grid-template-rows: 0.25fr 0.25fr;
												padding-right: 0.25em
											`
											,[
												m('label.control-label.f4', k)
												, k != 'Description'
													? x[k]
													: x[k] == t.relatedDiscipline
														? x[k]
														: [x[k], t.relatedDiscipline].filter(R.identity).join(' - ')

											]
										)
									)
								)

								, m('.data'
									+ css`
										display: grid;
										justify-content: space-between;
										grid-template-columns: 0.25fr 0.25fr 0.25fr 0.25fr;
										padding-bottom: 1em;
									`
									,['Completed', 'Verified', 'Invalid', ' Assigned']
									.map((k) =>
										m('.data'
											+ css`
												display: grid;
												grid-template-rows: 0.25fr 0.25fr;
												padding-right: 0.5em;
											`
											,[m('label.control-label.f4', k), x[k]]
										)
									)
								)

								, m('.data'
									+ css`
										display: grid;
										justify-content: space-between;
										grid-template-columns: 0.25fr 0.25fr 0.25fr 0.25fr;
										padding-bottom: 2em;
									`
									,['Planned', 'Actual']
									.map((k) =>
										m('.data'
											+ css`
												display: grid;
												grid-template-rows: 0.25fr 0.25fr;
												padding-right: 0.5em;`
											,[
												m('label.control-label.f4', k)
												,x[k]
											]
										)
									)
								)


								, m('.data'
									+ css`
										display: grid;
										justify-content: space-between;
										grid-template-columns: 0.75fr 0.25fr;
										padding-bottom: 0.25em;
									`
									,['Address', 'Tasked']
									.map((k) =>
										m('.data'
											+ css`
												display: grid;
												grid-template-rows: 1fr;
												padding-right: 0.5em;
											`
											,[
												m('label.control-label.f4', k)
												,x[k]
											]
										)
									)
								)


								, m('.data'
									+ css`
										display: grid;
										justify-content: space-between;
										grid-template-columns: 0.75fr 0.25fr;
									`
									,['Invoice No', 'Files']
									.map((k) =>
										m('.data'
											+ css`
												display: grid;
												grid-template-rows: 1fr;
												padding-right: 0.5em;
											`
											,elements.list([
												m('label.control-label.f4', k)
												,x[k]
											])
										)
									)
								)


								, m('.data'
									+ css`
										display: grid;
										justify-content: space-between;
										grid-template-columns: 1fr;
										padding-bottom: 1em;
									`
									,['Notes']
									.map((k) =>
										m('.data'
											+ css`
												display: grid;
												grid-template-rows: 1fr;
												padding-right: 0.5em;
											`
											,[m('label.control-label.f4', k), x[k]]
										)
									)
								)
							)

					}
					,data: {
						'Name': t.project_extras_type()
						,'Description': t.project_extras_description()
						,'Address': t.project_extras_address()

						,'Completed': t.project_extras_date_completed()
							? moment(t.project_extras_date_completed()).format("ddd Do MMM")
							: null
						,'Invalid': t.project_extras_date_invalid()
							? moment(t.project_extras_date_invalid()).format("ddd Do MMM")
							: null
						,'Verified': t.project_extras_date_verified()
							? moment(t.project_extras_date_verified()).format("ddd Do MMM")
							: null
						,'Assigned': t.project_extras_date_assigned()
							? moment(t.project_extras_date_assigned()).format("ddd Do MMM")
							: null

						,'Actual': t.project_extras_actual_amount()
						,'Planned': t.project_extras_planned_amount()
						,'Files': t.filecount
						,'Ledgers':
							[].concat(
								t.revenueinvoices
								,t.costinvoices
							)
							.join(', ')

						,'Tasked': t.username || t.invite_email
						,'Notes': t.project_extras_notes()
					}
					,attrs: {
						className: t.project_extras_date_completed()
								? t.project_extras_actual_amount()
									? 'highlight-ok'
									: 'highlight-error'
								: ''
					}
			}))
		})


	const projectDetailsTable = () =>
		m(Responsive
			,
			{
				breakpoints: {
					mobile:0
					,desktop:900
					,tablet:700
					,hd:1800
				}
			}
			,({ hd, desktopOnly, mobileOnly, tabletOnly }) => [
				hd( largeProjectDetailsTable )
				,desktopOnly( mediumProjectDetailsTable  )
				,mobileOnly( smallProjectDetailsTable )
				,tabletOnly( smallProjectDetailsTable )
			]
		)

	return ctrl.model.ActiveDetailsFeature()
		? [

			m('br')
			,([
				elements.alert(
					'info'
					, `Tasks are small unscheduled activites that are useful for record keeping. Tasks can be related to disciplines and account for working quantities. `
					+ `The Search + Filter inputs can sort this data in many inclusion and exclusion search combinations, but to sort through the work promptly Odin also provides Quick Filters`
				)
				,elements.selectbuttons(
					['Assigned', 'Completed', 'Verified', 'Invalid', 'All Tasks']
					,(t) => {
						if(t){
							!ctrl.model.projectExtrasTypes()['completed']
								? ctrl.model.projectExtrasTypes()['completed'] = 'any'
								: ''
							!ctrl.model.projectExtrasTypes()['invalid']
								? ctrl.model.projectExtrasTypes()['invalid'] = 'any'
								: ''
							!ctrl.model.projectExtrasTypes()['verified']
								? ctrl.model.projectExtrasTypes()['verified'] = 'any'
								: ''
							!ctrl.model.projectExtrasTypes()['assigned']
								? ctrl.model.projectExtrasTypes()['assigned'] = 'any'
								: ''

							const type = t.toLowerCase()

							ctrl.model.projectExtrasTypes(
								R.merge(
									{
										completed: 'any'
										, invalid: 'any'
										, verified: 'any'
										, assigned: 'any'
									}
									,t == 'All Tasks'
									? {}
									: {
										[type]: ctrl.model.projectExtrasTypes()[type] == 'all'
											? ctrl.model.projectExtrasTypes()[type] = 'any'
											: ctrl.model.projectExtrasTypes()[type] = 'all'
									}
								)
							)


							if(
								ctrl.model.projectExtrasTypes()['completed'] == 'any'
								&& ctrl.model.projectExtrasTypes()['invalid'] == 'any'
								&& ctrl.model.projectExtrasTypes()['assigned'] == 'any'
								&& ctrl.model.projectExtrasTypes()['verified'] == 'any'
								&& t != 'All Tasks'
							){
								ctrl.model.projectExtrasTypes()['completed'] = ''
								ctrl.model.projectExtrasTypes()['invalid'] = ''
								ctrl.model.projectExtrasTypes()['assigned'] = ''
								ctrl.model.projectExtrasTypes()['verified'] = ''
							}

							ctrl.model.fetchedExtras(false)
							ctrl.model.projectExtrasTypes(ctrl.model.projectExtrasTypes())
						}
					}
					,{
						disabled:
							ctrl.model.form().project.project_extras
							.some((t) => !t.project_extras_id())
						,title: `Task selection`
					}
					,(notReq, t) =>
						ctrl.model.projectExtrasTypes()[t.toLowerCase()] == 'all'
						|| 						t == 'All Tasks'
							&& ctrl.model.projectExtrasTypes()['completed'] == 'any'
							&& ctrl.model.projectExtrasTypes()['invalid'] == 'any'
							&& ctrl.model.projectExtrasTypes()['assigned'] == 'any'
							&& ctrl.model.projectExtrasTypes()['verified'] == 'any'
				)
			])

			,ctrl.model.form().project.newproject
			&& !ctrl.model.cloning()
				? elements.alert(
					`warning`
					,`Save this project before creating tasks`
				)
				: null

			,ctrl.model.fetchedExtras()
				? ctrl.model.form().project.project_extras.length
					? projectDetailsTable()
					// todo-james This message assumes 0 tasks = no tasks created
					// but they may have not selected a task filter yet.
					: elements.alert(
						'info'
						,`Create some tasks to be added to this project. Tasks can be related to disciplines and account for working quantities`
					)
				: elements.centeredSpinner()
		]
		: elements.alert('info',`Activate the project tasks module to use this tracking`)
}

const discardProjectButton =
	(ctrl) =>
		m("button.btn.btn-warning", {
			disabled: false
			,onclick: () => {
				ctrl.model.cloning(false)
				ctrl.model.errors({})
				ctrl.model.checkMultiple().data = []
				ctrl.model.discipline_vm({})
				ctrl.model.pe_vm({})
				ctrl.model.form().project = {}
				ctrl.model.viewType(
					ctrl.model.firstproject()
					&& ctrl.model.data.features().quickStartProject
					? InitialProject
					: ListProject
				)
				ctrl.model.deleteProjectExtras([])
				ctrl.model.deleteDisciplines([])
				ctrl.model.deleteDisciplinesContractors([])
				ctrl.model.deleteDisciplinesCrews([])
				ctrl.model.contractPatchIndex({})
				ctrl.model.selectedInvoices([])
				ctrl.model.newInvoices([])
				ctrl.model.newContracts([])
			}
		},"Discard")

const saveProjectButton =
	(ctrl) =>
		ctrl.model.SaveDisabled(true)
		|| ctrl.model.check_dnames(true)
		|| ctrl.model.checkTaskDates(true)
		|| identical(ctrl.model.form().project, ctrl.model.vm())
		&& identical(
			ctrl.model.extraContractObligationData()
			,ctrl.model.clonedContracts()
		)
		&& !ctrl.model.disciplineSpread().length
		&& !ctrl.model.newInvoices().length
		&& !Object.keys(ctrl.model.deleteInvoiceItemsHash()).length
		&& !Object.keys(ctrl.model.deleteInvoiceHash()).length
			? m('button.btn', {
				disabled: true
				,title: "Project Name, Location and Material Store must be entered before saving"
			}, 'Save')
			: elements.action(
				'Save'
				, ctrl.intent.sendProjects
				, ctrl.model.savingProp
			)

var EditProject = function(ctrl){

	return m('div.harth.mb6'
		+ css`
			display: grid;
		`
		, [
		ctrl.model.editPermissions()
			? elements.list(
				ctrl.model.edit() == true
				? [
					elements.back(
						"Return to the main list without saving"
						,() => {
							ctrl.model.errors({})
							ctrl.model.form().project = {}
							ctrl.model.checkMultiple().data = []
							ctrl.model.discipline_vm({})
							ctrl.model.pe_vm({})
							ctrl.model.deleteProjectExtras([])
							ctrl.model.deleteDisciplines([])
							ctrl.model.deleteDisciplinesContractors([])
							ctrl.model.deleteDisciplinesCrews([])
							ctrl.model.viewType(ListProject)
							ctrl.model.projectExtras([])
							ctrl.model.contractPatchIndex({})
							ctrl.model.checkMultipleProjectExtras().data = []
							ctrl.model.vm({})
							ctrl.model.checkMultipleProjects([])
							ctrl.model.data.routeids({
								routeids: ctrl.model.projects().map(R.prop('contractrecognitionid'))
								,getledgers: false
								,ledgerdetails: false
								,getmatdata: false
								,specificproject: ctrl.model.projects().map(R.prop('contractrecognitionid'))
							})
							ctrl.model.onProjectList(true)
							ctrl.model.selectedInvoices([])
							ctrl.model.newInvoices([])
							ctrl.model.newContracts([])
							ctrl.model.data.loadchart({})
						}
						,{disabled: ctrl.model.savingProp()}
					)
					,m("button.btn.btn-warning", {
						disabled:
							ctrl.model.savingProp()
							|| identical(ctrl.model.form().project, ctrl.model.vm())
							&& identical(
								ctrl.model.extraContractObligationData()
								,ctrl.model.clonedContracts()
							)
							// && identical(ctrl.model.baseInvoiceRender(), ctrl.model.invoiceRender())
							&& ctrl.model.baseInvoiceRender().every((i) => !i.created)
							&& !ctrl.model.newInvoices().length

						,onclick: () => {
							ctrl.model.errors({})
							ctrl.model.cloningProject(false, true)
							ctrl.model.discipline_vm({})
							ctrl.model.pe_vm({})
							ctrl.model.checkMultiple().data = []
							ctrl.model.deleteProjectExtras([])
							ctrl.model.deleteDisciplines([])
							ctrl.model.deleteDisciplinesContractors([])
							ctrl.model.deleteDisciplinesCrews([])
							ctrl.model.contractPatchIndex({})
							ctrl.intent.fetchAllocations()
							var nonEditedExtras =
								R.filter(
									(c) => !c.created
									,ctrl.model.form().project.project_extras
								)

							ctrl.model.projectExtrasStat({
								project_extras_date_assigned: 0
								,project_extras_date_completed: 0
								,project_extras_date_verified: 0
								,project_extras_date_invalid: 0
							})

							ctrl.model.form().project.project_extras =
								[].concat(
									nonEditedExtras
									,R.differenceWith(
										(x, y) => x.project_extras_id() == y.project_extras_id()
										,ctrl.model.vm().project_extras
										,nonEditedExtras
									)
									.map((e) => {
										ctrl.model.pe_vm(e)
										return ctrl.model.cloningProjectE(false, true)
									})
								)

							ctrl.model.projectExtrasIndex(
								R.groupBy(
									R.pipe(R.prop('discipline_id'), R.call)
									,ctrl.model.form().project.project_extras
								)
							)

							ctrl.model.checkMultipleProjectExtras().data = []
							ctrl.model.form().project.project_extras
							.forEach((t) =>
								ctrl.model.projectExtrasSum(t, null)
							)

							ctrl.model.deleteInvoiceItemsHash()
							ctrl.model.deleteInvoiceHash()
							ctrl.model.selectedInvoices([])
							ctrl.model.newInvoices([])
							ctrl.model.newContracts([])
							ctrl.model.masterLIST(true)
							ctrl.model.masterPELIST(true)
							ctrl.model.disciplineSpread([])
							ctrl.model.spreading(false)

							const nowids = ctrl.model.data.routeids()
							ctrl.model.data.routeids({
								routeids: []
								,getledgers: false
								,ledgerdetails: false
								,getmatdata: false
								,specificproject: []
							})
							ctrl.model.data.routeids(nowids)

						}
						,title: "Discard all the details that have been changed"
					},"Discard Changes")
					,ctrl.model.SaveDisabled(true)
					|| ctrl.model.check_dnames(true)
					|| ctrl.model.checkTaskDates(true)
					|| identical(ctrl.model.form().project, ctrl.model.vm())
					&& identical(
						ctrl.model.extraContractObligationData()
						,ctrl.model.clonedContracts()
					)
					&& ctrl.model.baseInvoiceRender().every((i) => !i.created)
					&& !ctrl.model.disciplineSpread().length
					&& !ctrl.model.newInvoices().length
					&& !Object.keys(ctrl.model.deleteInvoiceItemsHash()).length
					&& !Object.keys(ctrl.model.deleteInvoiceHash()).length
					// && ctrl.model.baseInvoiceRender().every(R.prop('created'))
					// && identical(ctrl.model.baseInvoiceRender(), ctrl.model.invoiceRender())
						? m('button.btn', {
							disabled: true
							,title: "Project Name, Location and Material Store must be entered before saving"
						}, 'Save')
						: elements.action(
							'Save'
							, ctrl.intent.sendProjects
							, ctrl.model.savingProp
						)
				]
				: [
					discardProjectButton(ctrl)
					,saveProjectButton(ctrl)
				]
			)
			: elements.list([
				elements.back(
					"Return to the main list without saving"
					,() => {
						ctrl.model.errors({})
						ctrl.model.form().project = {}
						ctrl.model.pe_vm({})
						ctrl.model.checkMultiple().data = []
						ctrl.model.discipline_vm({})
						ctrl.model.viewType(ListProject)
						ctrl.model.projectExtras([])
						ctrl.model.contractPatchIndex({})
						ctrl.model.checkMultipleProjectExtras().data = []
						ctrl.model.vm({})
						ctrl.model.checkMultipleProjects([])
						ctrl.model.onProjectList(true)
						ctrl.model.deleteInvoiceItemsHash({})
						ctrl.model.deleteInvoiceHash({})
						ctrl.model.data.loadchart({})
					}
				)
			])

		,m('hr')

		,elements.splitPane(
			[


				ctrl.model.data.advancedView()
				? null
				: ctrl.model.renderChart({chartonly: true})

				,elements.list([
					m('label.control-label.span', 'Project Name '
						,m(''
							+css`
								width: 21em
							`
							,elements.textInput(
								ctrl.model.form().project.project_name
								,{
									disabled: !ctrl.model.editPermissions() || ctrl.model.metaDataLoading()
								}
							)
						)
					)
				])

				,elements.list([
					m(''
						,m('label.control-label', 'Project Notes ')
						,m('',
							ctrl.model.metaDataLoading() || ctrl.model.loading()
							? ctrl.model.form().project.project_completion_comments()
							: Pencil(
								() => ' ' + ( ctrl.model.form().project.project_completion_comments() || '' )
								,() =>
									elements.textArea(
										ctrl.model.form().project.project_completion_comments
										,{
											placeholder: "Any types of communications or status"
											, disabled: !ctrl.model.editPermissions() || ctrl.model.metaDataLoading()
											, style: {
												"padding-block-start": "0.5em"
											}
										}
									)
							)
						)
					)
				])

				,elements.list(
					[
						{
							name:'Feasible Start'
							,title: "First date work is able to commence"
							,prop: ctrl.model.form().project.project_start_date
						}
						,{
							name:'Feasible End'
							,title: "Cut off date for completing all disciplines"
							,prop: ctrl.model.form().project.project_dead_date
						}
					].map(
						t =>
						m('label.control-label'
							,t.name
							,elements.dateInput(
									t.prop
									,{ disabled: !ctrl.model.editPermissions() || ctrl.model.metaDataLoading()
									, title: t.title
								}
							)
						)
					)
				)

				,ctrl.model.data.advancedView()
				? elements.list(
					[
						{
							name:'Handover'
							,title: "Date this project is to be released to the client"
							,prop: ctrl.model.form().project.project_commissioning_date
						}
						,{
							name:'Liability Release'
							,title: "Date the client owns build liability"
							,prop: ctrl.model.form().project.project_final_acceptance
						}
					].map(
						t =>
						m('label.control-label'
							,t.name
						,elements.dateInput(
								t.prop
								,{ disabled: !ctrl.model.editPermissions() || ctrl.model.metaDataLoading()
								, title: t.title
							}
						)
						)
					)
				)
				: null

				,elements.list([
					m.component(
						Pencil
						,() =>
							m('label.control-label', 'Contract -  '
								,m('a', {
										href:
											'/data/contracts/contracts/edit/'
											+ ctrl.model.form().project.contract_id()
											+ '/forecastdateA/'
											+ ctrl.model.data.forecastdateA()
											+ '/forecastdateB/'
											+ ctrl.model.data.forecastdateB()
										, oncreate: m.route.link
										, title: "Go to linked contract"
										, disabled:
											!exposer(ctrl.model.form().project.contract_id)
											|| !ctrl.model.editContractPermissions()
									}
									, ctrl.model.form().project.contract_name || 'Disconnected Ledgers'
								)
							)
						,() =>
							m('label.control-label', 'Contract - '
								,autocomplete.strict(
									ctrl.model.data.contracts
									,(v) => {
										if (v){
											const cobj =
												ctrl.model.data.contracts()
												.find((u) => u.contract_id == v.contract_id )
												|| { contract_id: m.prop( uuid.v4() ), contract_name: v}

											ctrl.model.form().project.contract_id( cobj.contract_id )
											ctrl.model.form().project.contract_name = cobj.contract_name
										}
										return v
											? v.contract_name
											: ctrl.model.form().project.contract_name
									}
									,'contract_name'
									, () => ({
										disabled: !ctrl.model.editPermissions()
											|| ctrl.model.metaDataLoading()
											|| ctrl.model.loading()
									})
								)
							)
					)
				])

				,ctrl.model.data.advancedView()
				? elements.list([
					ctrl.model.metaDataLoading() || ctrl.model.loading()
					? m(
						'label.control-label'
						,'Location - '
						, ctrl.model.form().project.project_suburb()
					)
					: Pencil(
						() => m(
							'label.control-label'
							,'Location - '
							, ctrl.model.form().project.project_suburb()
						)
						,() =>
							m('label.control-label', 'Location'
								,m(
									autocomplete.Main
									,{
										list: ctrl.model.placeLong
										,field: null
										,onselect: ctrl.model.form().project.project_suburb
										,oninput: ctrl.model.suburbsInput
										,placeholder: "Suburb of choice"
										,disabled: !ctrl.model.editPermissions()
										,title: "The location that will be used to determine most efficient resource ratings during schedule procedures"
									}
								)
							)
					)
				])
				: ''

				,ctrl.model.data.advancedView()
				? elements.list([
					ctrl.model.metaDataLoading() || ctrl.model.loading()
					? m(
						'label.control-label'
						,'Material Store - '
						,ctrl.model.form().project.project_material_suburb()
					)
					: Pencil(
						() => m(
							'label.control-label'
							,'Material Store - '
							,ctrl.model.form().project.project_material_suburb()
						)
						,() =>
							m('label.control-label', 'Material Store '
								,m(
									autocomplete.Main
									,{
										list: ctrl.model.placeLong
										,field: null
										,onselect: ctrl.model.form().project.project_material_suburb
										,oninput: ctrl.model.suburbsInput
										,placeholder: "Suburb of choice"
										,disabled: !ctrl.model.editPermissions()
										,title: "The location that will accept material deliveries"
									}
								)
								// ea to finalise upgrade later, requires new interfacing with schema saves and scheduler pull
								// ,m(autocomplete.Main, {
								// 	field: 'title'
								// 	,throttleSearch: 500
								// 	,inputClassName: 'form-control'
								// 	,initialValue: ctrl.model.form().project.project_material_suburb()
								// 	,attrs: {
								// 		name: 'project_material_suburb'
								// 		,value: ctrl.model.form().project.project_material_suburb()
								// 		,required: true
								// 		,disabled: !ctrl.model.editPermissions()
								// 		,title: "The location that will accept material deliveries"
								// 	}
								// 	,oninput: x =>  ctrl.model.form().project.project_material_suburb(x)
								// 	,loading: ctrl.model.addressSearchLoading
								// 	,manuelConfig: {
								// 		filter: () => true
								// 	}
								// 	,search(value){
								// 		if(value){
								// 			return ctrl.model.data.api.suburbs.search(value)
								// 		} else {
								// 			return []
								// 		}
								// 	}
								// 	,onselect(value){
								// 		ctrl.model.form().project.project_material_suburb(value.title)
										// // collect data as we use it for later features
										// data.api.suburbs.here.patch(value)
										// // eslint-disable-next-line no-undef
										// .catch( e => console.error('Could not save here data ', e))
								// 	}
								// })
							)
					)
				])
				: ''



				,ctrl.model.data.advancedView()
				? elements.list([
					m('label.control-label', 'Work Zone'
					,elements.selectbuttons(
						['Rural', 'Suburban', 'CBD']
						, ctrl.model.form().project.project_site_type
						,{
							disabled: !ctrl.model.editPermissions() || ctrl.model.metaDataLoading()
							, title: "Area designation for resource comparisons"
						}
					)
					)
				])
				: null

				,ctrl.model.data.advancedView()
				? elements.list([
					m('label.control-label', 'Priority '
						,m(
							NumberInput
							,R.merge(
								ctrl.model.data
								,{
									errors: ctrl.model.errors
									,errorLabel: 'Project Priority'
								}
							)
							,{
								prop: ctrl.model.form().project.project_priority
								,attrs: {
									min: 1
									, disabled: !ctrl.model.editPermissions() || ctrl.model.metaDataLoading()
								}
							}
						)
					)
				])
				: null

				,ctrl.model.projectCompleted({p: ctrl.model.form().project, sendp: false, ctrl})

				,m(''+css`
						display: flex;
						align-items: center;
						gap: 0.5em;
						justify-content: center;
					`
					,quoteModal({type: 'gen', ctrl})
				)
			]
			,[

				ctrl.model.data.auth_permissions().auth_permissions_write
				== Math.pow(2, 31) -1 || process.env.DEV
				? m(OAuthConnector, {
					data: ctrl.model.data
					, attrs: { key: ctrl.model.data.initiateContractFetch().organization_id }
					, oauths: ctrl.model.oauths
					, saving: ctrl.model.savingProp
				})
				: null

				,elements.list(
					R.intersperse(
						' | '
						, [
							{s:'Budget'
							, v:
								makeMoney(
									(
										ctrl.model.data.coalatedSelectedVersion()
											[ctrl.model.form().project.contractrecognitionid]
										|| {}
									)
									.budget
								)
							}
							,{s:'Accrued Profits'
							, v:
								makeMoney(
									ctrl.model.data.readContractPermissions()
									&& ctrl.model.data.coalatedSelectedVersion()[ctrl.model.form().project.contractrecognitionid]
									? ctrl.model.data.coalatedSelectedVersion()[ctrl.model.form().project.contractrecognitionid].currentProfits
										+ ctrl.model.data.coalatedSelectedVersion()[ctrl.model.form().project.contractrecognitionid].outstandingProfits

									: 0
								)
							}
							,{s:'Received Profits'
							, v:
								makeMoney(
									ctrl.model.data.readContractPermissions()
									&& ctrl.model.data.coalatedSelectedVersion()[ctrl.model.form().project.contractrecognitionid]
									? ctrl.model.data.coalatedSelectedVersion()[ctrl.model.form().project.contractrecognitionid].currentProfits
									: 0
								)
							}
							,{s:'Forecasted Profits'
							, v:
								makeMoney(
									(
										ctrl.model.data.readContractPermissions()
										&& ctrl.model.data.coalatedSelectedVersion()
											[ctrl.model.form().project.contractrecognitionid]
										|| {}
									)
									.forecastedProfits
								)
							}
							,{s:'Chart'
							, v: ctrl.model.renderChart({refresheronly: true})
							}
						].map((i) =>
							m('p.tc.w4'
								, [
									i.s
									, m('hr')
									, i.v
								]
							)
						)
					)
				)

				,ctrl.model.data.advancedView()
					? ctrl.model.renderChart({chartonly: true})
					: null

				,ctrl.model.SaveDisabled()

				,m('br')
				,elements.list([
					ctrl.model.editPermissions()
					? m('button.btn.btn-secondary',
						{
							style:
								{ backgroundColor: 'transparent'
								, border: 'solid 1px #3380c2'
								, position: 'relative'
								, top: '-0.1em'
								, height: '2.9em'
								, width: '5em'
								, color: '#434aa3'
								, textDecoration: 'underline'
								// , fontSize: '0.9em'
								// , padding: '0.2em 0.5em'
								}
							,onclick: () =>
								ctrl.model.form().project.project_contacts =
									ctrl.model.form().project.project_contacts
									.concat(
										[
											{project_contacts_id: m.prop('')
											,project_contacts_type: m.prop('')
											,project_contacts_name: m.prop('')
											,project_contacts_phone: m.prop('')
											,project_contacts_email: m.prop('')
											,project_id: m.prop(ctrl.model.form().project.project_id())
											,user_id: m.prop(null)
											,key: Math.random().toString(15).slice(2, 8)
											}
										]
									)
						}
						, 'Add'
					)
					: ''
					,m('label.dib.control-label', 'Contacts')
				])
				,[
					ctrl.model.form().project.project_contacts
					.map((pd) =>
						elements.list(
							[
							''
							,'name'
							,'type'
							,'phone'
							,'email'
							]
							.map((ex) =>
								!ex && !ctrl.model.editPermissions()
								? ''
								: !ex
								? elements.xRemoval({onclick: () => ctrl.model.removeContactForm(pd) })
								: ex != 'user_id'
								? m(ex == 'type' ? 'label.control-label.p.tc.w5' : 'p.tc.w5',
									!ctrl.model.editPermissions()
									? pd['project_contacts_' +ex]()
									: ex == 'name'
									? Pencil(
										() => pd['project_contacts_' +ex]()
										,() =>
											autocomplete.all(
												ctrl.model.projectContactOptions
												,(v) => {
													// const pd = pd
													const ReturnedAutocompleteObj =
														ctrl.model.projectContactOptions()
														.find((pc) =>
															pc['project_contacts_name']() + ' - ' + pc['project_contacts_type']()
															== v
														)

													v || v == ''
														? pd['project_contacts_' +ex](
															ReturnedAutocompleteObj
																? ReturnedAutocompleteObj['project_contacts_' +ex]()
																: v
														)
														: null

													if ( ReturnedAutocompleteObj ){
														pd['project_contacts_name'](ReturnedAutocompleteObj.project_contacts_name())
														pd['project_contacts_type'](ReturnedAutocompleteObj.project_contacts_type())
														pd['project_contacts_phone'](ReturnedAutocompleteObj.project_contacts_phone())
														pd['project_contacts_email'](ReturnedAutocompleteObj.project_contacts_email())
													}

													return ReturnedAutocompleteObj
														? ReturnedAutocompleteObj['project_contacts_' +ex]()
														: pd['project_contacts_' +ex]()
												}
												,'nameRole'
												,() => ({
													title: `Select the contact name`
													,placeholder: ex[0].toUpperCase() + ex.slice(1)
													// ,style: { height: '3em'}
													,key: pd.key
												})
											)
									)
									: elements.textInput(
											pd['project_contacts_' +ex]
											,{
												placeholder: ex[0].toUpperCase() + ex.slice(1)
											}
										)
								)
								: m('p.tc.w4',
									!ctrl.model.editPermissions()
									? (ctrl.model.users().find((u) =>
										u.user_id() == pd[ex]())
										|| nullUser)
										.usersFullName
									: autocomplete.strict(
											ctrl.model.users
											,(v) => {
												if (v){
													const userObj =
														ctrl.model.users().find((u) =>
															u.user_id() == v.user_id()
														)
													pd[ex]( userObj.user_id() )
												}
												return v
													? v.usersFullName
													: ctrl.model.users().find((u) =>
														u.user_id()
														== pd[ex]()
													).usersFullName
												}
											,'usersFullName'
											, () => ({
												style: {
													height: '3em'
												}
												,disabled: !ctrl.model.editPermissions()
											})
										)
								)
							)
						)
					)
				]

				,addInvoice(ctrl)

				,elements.list([
					editProjectDocuments(
						ctrl.model.form().project.project_documents,
					)
				])

				,elements.list([
					ctrl.model.editPermissions()
					? m('button.btn.btn-secondary',
						{ style:
							{ backgroundColor: 'transparent'
							, border: 'solid 1px #3380c2'
							, position: 'relative'
							, top: '-0.1em'
							, height: '2.9em'
							, width: '5em'
							, color: '#434aa3'
							, textDecoration: 'underline'
							// , fontSize: '0.9em'
							// , padding: '0.2em 0.5em'
							}
							,onclick: () => {
								return !ctrl.model.showUpload() ? ctrl.model.showUpload(true) : null
							}
							,disabled: ctrl.model.showUpload()
						}
						, 'Add'
					)
					: ''
					,m('label.dib.control-label', 'Project Files')
					,ctrl.model.showUpload()
						? m('a'
							,{
								title: `Hide file uploads`
								,onclick: () => ctrl.model.showUpload(false)
							}
							, ' Hide'
						)
						: null
				])

				,ctrl.model.showUpload()
					? m(HarthUppy, {
						getFields: () => ctrl.model.uploadmetadata({uploadbox: 'projectfiles'})
						, data: ctrl.model.data
						, download: {
							reference: ctrl.model.vm()
							,request: ctrl.model.zipRequest
						}
					})
					: m(HarthUppy, {
						getFields: () => ctrl.model.uploadmetadata({uploadbox: 'projectfiles'})
						, data: ctrl.model.data
						, listOnly: true
						, download: {
							reference: ctrl.model.vm()
							,request: ctrl.model.zipRequest
						}
					})

				,m('br')
				,m('br')
				,ctrl.model.projectSummary().length > 0
					? elements.details(
						'Work Notes '
						, elements.nonStrikeList(
							ctrl.model.projectSummary()
							.map(
								label => ({
									label: label
									,action: () => {}
								})
							)
						)
						, ctrl.model.projectSummary().length
							? {}
							: { disabled: true }
					)
					: null
				,ctrl.model.check_dnames(false, Object.keys(ctrl.model.errors()))
			]
			,{css:
				`display: grid;
				gap: 1em;`
			}
		)
		,m('br')
		,m('hr')
		,m('br')

		,ctrl.model.activeTab() != 'Reconciliation'
		&& ctrl.model.activeTab() != 'Materials'
		? m('h4'
			,elements.list([
				ctrl.model.activeTab() == 'Tasks'
				? 'Project Tasks'
				: 'Project Disciplines'
				,m(`button`
					+ css`
						border-radius: 0.5em;
						background-color: white;
						padding: 0.7em 1em;
						border: solid 0.1px rgba(0,0,0,0.4);
						transition: 0.2s;
						opacity: 1;
					`
					.$hover(`
						opacity: 0.8;
					`)
					.$active(`
						opacity: 0.5;
					`)
					,{
						onclick:
							ctrl.model.activeTab() == 'Tasks'
							? ctrl.model.addNewTask
							: ctrl.model.addNewDiscipline
						,disabled:
							ctrl.model.activeTab() == 'Tasks'
							? false
							: !!ctrl.model.check_dnames(true)
					}
					, ctrl.model.activeTab() == 'Tasks'
					? '+ Task '
					: '+ Discipline'
				)
			])
		)
		: m('h4')

		,ctrl.model.metaDataLoading()
			? elements.centeredSpinner()
			: [
				m('br')
				, ctrl.model.activeTab() == 'Reconciliation'
				|| ctrl.model.activeTab() == 'Materials'
				|| !ctrl.model.data.advancedView()
				? null
				: ctrl.model.activeTab() != 'Tasks'
					? elements.list([
						m('label.control-label', 'Milestones At Risk')
						,ctrl.model.form().project.disciplines.filter((d) =>
							d.discipline_end_date()
							>= ( ctrl.model.disciplineLastDateIndex()[d.discipline_id()] || Infinity )
						).length + ' of ' + ctrl.model.form().project.disciplines.length
					])
					: elements.list([
						m('label.control-label', 'Task Reports')
						,[
							'assigned'
							,'completed'
							,'verified'
							,'invalid'
						].map((prop) =>
							prop.charAt(0).toUpperCase() + prop.slice(1)
							+ ': '
							+ ctrl.model.projectExtrasStat()['project_extras_date_' + prop]
							+ ' '
						).concat('Total: ' + ctrl.model.masterPELIST().length)
					])

				,elements.list([
					ctrl.model.activeTab() == 'Reconciliation'
					|| ctrl.model.activeTab() == 'Materials'
						? ''
						: ctrl.model.activeTab() == 'Tasks'
						? ctrl.model.getfilters(null, "Tasks")
						: ctrl.model.getfilters(null, "Discipline")
					,ctrl.model.activeTab() == 'Reconciliation'
					|| ctrl.model.activeTab() == 'Materials'
						? ''
						: ctrl.model.editAllButton()
				])

				,m('br')

				,ctrl.model.editPermissions()
					? elements.tabset(
						ctrl.model.disciplineTabEditArray.filter((dt) =>
							ctrl.model.data.advancedView()
							|| dt == 'Disciplines' || dt == 'Tasks' || dt == 'Materials'
						)
						, ctrl.model.activeTab
					)
					: elements.tabset(
						ctrl.model.disciplineTabReadArray.filter((dt) =>
							ctrl.model.data.advancedView()
							|| dt == 'Disciplines' || dt == 'Tasks' || dt == 'Materials'
						)
						, ctrl.model.activeTab
					)

				,ctrl.model.activeTab() == 'Disciplines'
					? ResourceTab(ctrl)
					: ctrl.model.activeTab() == 'Tasks'
						? ExtraDetailTab(ctrl)
						: ctrl.model.activeTab() == 'Reconciliation'
						? ReconTab(ctrl)
						: ctrl.model.activeTab() == 'Materials'
						? MaterialsTab(ctrl)
						: ContractualTab(ctrl)
			]
	])
}

export default Main