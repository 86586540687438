import tasks from './index'
import { Response, TaskState } from './types'
import { prop as stream } from '../../../../stream'
import { merge } from 'ramda'
import { Maybe } from '../../utils/safe'
import {o, pipe, prop} from 'ramda'

const Y = Maybe.Just
const N = () => Maybe.Nothing()

const extractProgress =

	pipe (

		Response.case ({
			Task: o (Y) (tasks.processTask)
			,_: N
		})

		, Maybe.chain (

			TaskState.case ({

				TaskRunning: o (Y) (prop ('task_progress'))
				,TaskStarted: o (Y) (prop ('task_progress'))
				,_: N

			})
		)
		, Maybe.map( String )
		, Maybe.fold ('', s => s + '%' )
	)

function update (p, r){
	return merge(
		p
		, {[r.task.schedule_version_id]:
			extractProgress(r)
		})
}

function main (responses) {
	const obj$ = stream.scan(update, {}, responses)

	return stream.dropRepeats(obj$)
}


export default main

