/* globals setTimeout */
import m from 'bacta'
import * as elements from '../components/elements'
import NumberInput from '../components/number'
import scrollableTable from '../components/scrollableTable'
import humanList from '../utils/human_list'
import { prop } from '../../../stream'
import moment from 'moment'
import Responsive from '../components/responsive'
import drawChart from '../components/harth-chartist'
import HarthUppy from '../components/harth-uppy'
import * as modalStack from '../components/modal-stack'
import * as H from '../../../how/index.js'

const nullUser = {
	user_id: m.prop('Unassigned')
	, user_username: m.prop('Unassigned')
	, usersFullName: 'Unassigned'
	, contractrecognitionname: 'Unassigned'
	, contractrecognitionid: null
}

const financialSummary = [
	'Accrued'
	,'Requisitions'
	,'Receipted'
	,'Forecasts'
]

const contractProps = [
	'contract_items_name'
	,'contract_items_description'
	,'contract_items_client_reference'
	,'contract_items_budget'
	,'contract_items_rate'
	,'contract_items_service'
	,'contract_items_depreciation'
	,'contract_items_appreciation'
	,'contract_items_uom'
	,'contract_items_damages_rate'
	,'contract_items_damages_period'
	,'contract_items_applied'
	,'contract_items_recorded_reference'
	,'contract_name'
	,'contractor_id'
	,'crew_id'
	,'crews_discipline_rates_id'
	,'modules_id'
	,'organizations_disciplines_id'
	,'tools_id'
	,'warehouse_id'
	,'contractprojectid'
	,'contractuserid'
]

const asyncMergeAll =
	xs => prop.afterSilence(
		500
		, prop.merge(xs)
	)


const checkprop = (a, b, c) =>
	c[a] && b && c[a][b]
		? typeof c[a][b] == 'function'
			? c[a][b]()
			: c[a][b]
		: typeof c[a] == 'function'
			? c[a]()
			: c[a]

import {
	filterView
	,ListProcessing
} from '../components/filter'

import { Light as DetailsPane } from '../components/detailspane2'
import Pencil from '../components/pencil'
import * as R from 'ramda'
import {identical} from '../utils/harth-identical'
import autocomplete from './autocomplete'
// import Permissions from '../models/permissions'
import Promise from 'bluebird'
import css from 'bss'

import { money as makeMoney } from '../utils/regExes'
const milliperday = 1000*60*60*24


function contractsRoute({ attrs: { data }}){

	const features = data.features

	const {
		originalInvoices
		,users
		,tools
		,projects
		,resources
		,contracts
		,focussedContracts
		,organizations_disciplines
		,contractEntities
		,forecastdateA
		,forecastdateB
		,focussedEntities
		,calculated
		,fetchOrganizationsDisciplines
		,fetchUsers
		,fetchResource
		,fetchProjects
		,fetchWarehouses
		,fetchTools
		,fetchContracts
		,makeContractItem
		,cloneInvoice
		,viewOptions
		,viewWindow
		,viewWindowList
		,loading
		,reMapdateChange
		,recordedFocus
		,accumulator
		,api
		,warehouses
		,initiateContractFetch
		,readContractPermissions
		/* eslint-disable */
		,readWarehousePermissions
		,readProjectPermissions
		,readSupplierPermissions
		,readResourcePermissions
		,readFlowPermissions
		,readInterruptionsPermissions
		,readSchedulePermissions
		,readToolPermissions
		,readInvoicePermissions
		,readTimesheetPermissions
		,readUpdatePermissions
		,readUserPermissions
		,readOrderPermissions
		,readMaterialPermissions
		,readModulePermissions
		,readAssignedTimesheetsPermissions
		,readPaymentPermissions
		,readContractObligationPermissions
		,renderSummary
		,editContractPermissions
		,editInvoicePermissions
		,currentRoute
		,currentVersion
		,overHEadVersion
		,allContractItemsIndexByContractItemId
		,indexedContractEntities
		,baseItems
		,contractEntitiesIndexedById
		,invoiceWindow
		,focussedInvoices
		,schedules
		,multiplierIndex
		,invoiceDataLoading
		,metaDataIndexing
		,metaDataLoading
		,progressCalculation
		,baseItemsList
		,ctypes
		,coption
		,hiddenInvoiceData
		,disconnectedcontract
		,advancedView
		,calculatedData
		,focus_schedules
		,route_contract_id
		,route_original_contract_id
		,route_view_type
		,summation
		,routeParam
		,prevview
		,routeids
		,fetchCalculations
		,scheduleData
		,chartCreation
		,fetchXeroExport
		,loadchart
		,baseItemsSelectList
		,debounce
		/* eslint-enable */
	} = data


	function sendContracts(fload){
		reMapdateChange(true)
		calculated(false)
		const payload =
			fload
			|| [
				R.merge(
					active
					,{
						contract_items:
							active.contract_items()
							.filter(ci => ci.created)
					}
				)
			]

		return deletetItem(true)
		.then(function(){
			return api.contracts.patch.many(payload)
				.then(function(res){
					deleteContractItems([])
					checkMultipleContracts({})
					checkMultiple({})
					contracts(res)
					calculated(true)
					reMapdateChange(false)

					const currentcontract =
						res.find((c) =>
							c.contract_name
							== active.contract_name()
						)

					contract(currentcontract)

					!active.contract_name()
						? null
						: m.route.set(
							'/data/contracts/contracts/edit/'
							+ currentcontract.contract_id
							+ '/forecastdateA/'
							+ forecastdateA()
							+ '/forecastdateB/'
							+ forecastdateB()
						)

					return res
				})
		})
	}


	function deleteContract(){
		loading(true)
		invoiceDataLoading(true)
		return Promise.all(
			Object.keys(checkMultipleContracts())
				.map((t) => api.contracts.remove.one(t))
		)
			.then(function(){
				reMapdateChange(true)
				const removed = Object.keys(checkMultipleContracts()).map((cid) => cid )
				focussedContracts(
					focussedContracts().filter((c) =>
						!removed.find((tc) =>
							tc == c.contract_id
						)
					)
				)
				checkMultipleContracts({})
				contract({ contract_items: [] })
				loading(false)
				calculated(false)
				reMapdateChange(false)
			})
	}

	function deletetItem(makeAPICalls){

		function deleteLocal(){
			Object.keys(checkMultiple())
				.forEach((ci) => {
					checkMultiple()[ci].contract_items_id()
					&& deleteContractItems().push(checkMultiple()[ci].contract_items_id())
				})

			R.map(
				(citem) => active.contract_items()
					.splice(
						active.contract_items()
							.findIndex((ci) =>
								ci.contract_items_id() == citem.contract_items_id()
								&& ci.contract_items_name() == citem.contract_items_name()
								&& ci.contract_items_description() == citem.contract_items_description()
								&& ci.contract_items_client_reference() == citem.contract_items_client_reference()
								&& ci.contract_items_rate() == citem.contract_items_rate()
								&& ci.contract_items_budget() == citem.contract_items_budget()
							)
						,1
					)
				,checkMultiple()
			)
			checkMultiple({})
			return true
		}

		return makeAPICalls
			? Promise.all(
				deleteContractItems().map(
					(t) => api.contract_items.remove.one(t)
				)
			)
			: deleteLocal()
	}

	function deletetInvoices(makeAPICalls){
		return makeAPICalls
			? Promise.all(
				deleteInvoiceItems().map(
					(t) => api.invoices.remove.one(t)
				)
			)
			: 				selectedInvoices()
					.map((i) =>
						i.invoice_id()
						&& deleteInvoiceItems().push(
							i.invoice_id()
						)
					)
				&& selectedInvoices().map((selectedInvoice) =>
					focussedInvoices().splice(
						focussedInvoices()
							.findIndex((i) =>
								i.invoice_id()
								== selectedInvoice.invoice_id()
							)
						,1
					)
				)
				&& selectedInvoices([])

	}


	function sendInvoices(){

		const payload = (
			route_view_type() == 'Make Ledgers'
				? selectedInvoices
				: focussedInvoices
		)().filter((i) =>
			i.created
			&& Number(
					i.invoice_amount() * i.invoice_rate()
					+ i.invoice_service_amount() * i.invoice_service_rate()
					+ i.invoice_depreciation_amount() * i.invoice_depreciation_rate()
					+ i.invoice_appreciation_amount() * i.invoice_appreciation_rate()
					+ i.invoice_damages_amount() * i.invoice_damages_rate()
			) != 0
		)

		loading(true)
		invoiceDataLoading(true)
		calculated(false)
		reMapdateChange(true)

		payload.forEach((i) => {
			i.user_id(
				data.auth.stream().user_id
			)
			i.invoice_type(
				Number(
					i.invoice_amount() * i.invoice_rate()
					+ i.invoice_service_amount() * i.invoice_service_rate()
					+ i.invoice_depreciation_amount() * i.invoice_depreciation_rate()
					+ i.invoice_appreciation_amount() * i.invoice_appreciation_rate()
					+ i.invoice_damages_amount() * i.invoice_damages_rate()
				) > 0
					? i.invoice_received()
						? 'Receipted Revenue'
						: 'Accrued Revenue'
					: i.invoice_received()
						? 'Receipted Expenditure'
						: 'Accrued Expenditure'
			)
		})

		return deletetInvoices(true)
		.then(function(){
			return api.invoices.patch.many(payload)
				.then(function(res){
					deleteInvoiceItems([])
					selectedInvoices([])
					filteredInvoiceOptions([])
					invoiceDataLoading(false)
					calculated(true)
					reMapdateChange(false)
					loading(false)
					allSelected(false)
					route_view_type() == 'All Ledgers'
						? null
						: route_view_type('All Contracts')

					checkMultiple({})
					originalInvoices(cloneInvoice(focussedInvoices()))
					return res
				})
		})
	}

	const filteredContractItemOptions = data.scoped([])
	const filteredOdinItemOptions = data.scoped([])
	const filteredInvoiceOptions = data.scoped([])
	const adjustPercenageOption = data.scoped(false)
	const adjustPercenageValue = data.scoped(100)
	const allSelected = data.scoped(false)
	const savingProp = data.scoped(false)
	const deleteStateProp = data.scoped(false)
	const deleteInvoiceItems = data.scoped([])
	const deleteContractItems = m.prop([])
	const dailyAverage = data.scoped('Total Period')

	const errors = data.scoped({})
	const donerContract = data.scoped(null)

	chartCreation.map(({fe}) => {

		const breakdownIndex =
			fe
			? R.groupBy(
				R.prop('contractrecognitionid')
				,(calculatedData()[
					fe.schedule_version_id
					+ forecastdateA()
					+ (forecastdateB() || Infinity)
				] || {chartdata: []}).chartdata
			)
			: {}

		const series =
			fe && !fe.breakdown
			? [
				calculatedData()[
					fe.schedule_version_id
					+ forecastdateA()
					+ (forecastdateB() || Infinity)
				]
			]
			: Object.keys( breakdownIndex )
			.map((c) => R.merge(baseItems()[c], {contractrecognitionid: c, chartdata: breakdownIndex[c]}))


		const makeGroupings =
			({fe, series}) => {
				return !fe.groupings.length ? series : fe.groupings.map((g) => {

					const rseries = series.filter((sf) => g.find((g) => g == sf.contractrecognitionname))
					return {
						chartdata: R.unnest(rseries.map(R.prop('chartdata')))
						,contractrecognitionname: rseries.map(R.prop('contractrecognitionname')).join('')
						,chartname: rseries.map(R.pipe(R.prop('contractrecognitionname'), R.split(' - '), R.head))
					}
				})
			}

		return !fe
		? null
		: drawChart({
			fe
			,data: { series: makeGroupings({fe, series}) }
			,features
		})
	})

	const viewtype =
		route_view_type
		.map((v) => {
			return v == 'All Contracts'
			? ContractSummaryList
			: v == 'All Items'
				? ListAllContractEntities
				: v == 'All Ledgers'
					? ListInvoicesPOs
					: v != 'Overview'
					? CreateInvoicePO
					: () => ''
		})


	// asyncMergeAll(
	// 	[
	// 		route_contract_id
	// 	]
	// )
	// .map(() => reMapdateChange(false))

	const selectedInvoices = data.scoped([])
	const activeItem = data.scoped({})
	const checkMultiple = data.scoped({})
	const checkMultipleContracts = data.scoped({})

	const missingPermissions =
		asyncMergeAll(
			[
				data.initiateContractFetch
			]
		)
		.map(() =>
			humanList(
				[
					'Project'
					,'Resource'
					,'Warehouse'
					,'Tool'
					,'Timesheet'
					,'User'
					,'Order'
					,'Material'
					// ,'Module'
					,'Contract'
					,'Invoice'
				]
					.map((p)=>
						data['read'+p+'Permissions']()
							? ''
							: p
					)
					.filter((p) => p)
			)
		)

	const invoicesReceipted =
		selectedInvoices.map((invoices) =>
			invoices.some((i) => i.invoice_received())
		)

	const invoicesapproved  =
		selectedInvoices.map((invoices) =>
			invoices.some((i) => i.invoice_approval())
		)


	const scheduleList = asyncMergeAll(
		[
			schedules
			,focussedEntities
		]
	).map(([s, fe]) =>
		R.differenceWith(
			(a,b) => a.schedule_version_id == b.schedule_version_id
			,R.concat(
				R.unnest(
					s.map(
						schedule =>
							schedule.schedule_versions
								.map((sv) =>
									R.merge(
										sv
										,schedule
									)
								)
					)
				)
				,[{
					scheduleNameVersion: 'Overheads'
					,schedule_version_id: 'Overheads'
					,schedule_id: null
				}]
			)
			,fe
		)
	)

	const SupplierEntities =
		asyncMergeAll(
			[
				resources
				,users
			]
		).map((data) =>
			[].concat(
				data[0] || []
				,data[1] || []
			)
		)


	function setQuartelyButtonDate(t){
		let dayA = 0
		let monthA = 0
		let dayB  = 0
		let monthB = 0
		let year = new Date().getYear() + 1900
		if(t == 'Q1 Jul-Sep'){
			dayA = 1
			monthA = 6
			dayB  = 30
			monthB = 8
		}
		if(t == 'Q2 Oct-Dec'){
			dayA = 1
			monthA = 9
			dayB  = 31
			monthB = 11
		}
		if(t == 'Q3 Jan-Mar'){
			dayA = 1
			monthA = 0
			dayB  = 31
			monthB = 2
		}
		if(t == 'Q4 Apr-Jun'){
			dayA = 1
			monthA = 3
			dayB  = 30
			monthB = 5
		}
		forecastdateA( new Date(year, monthA, dayA).getTime())
		forecastdateB( new Date(year, monthB, dayB).getTime())
		reMapdateChange(false)
	return t
	}

	 function cloneContractItems(o){

		o.contract_items =
			R.sortBy(
				(a) => a.contract_items_id()
				,o.contract_items
			)

		return R.sortBy(
			(a) => a.contract_items_id()
			,o.contract_items
		)
		.map((aItem) => {
			return {

				key: aItem.key
					? aItem.key
					: Math.random().toString(15).slice(2, 8)

				,contract_id: m.prop(
						route_original_contract_id()
							? null
							: aItem.contract_id()
						)
				,contract_items_id: m.prop(
						route_original_contract_id()
							? null
							: aItem.contract_items_id()
						)

				,contract_name: o.contract_name
				,organization_id: m.prop( aItem.organization_id() )
				,contract_items_name: m.prop( aItem.contract_items_name() )
				,contract_items_description: m.prop( aItem.contract_items_description() )
				,contract_items_client_reference: m.prop(aItem.contract_items_client_reference() )
				,contract_items_rate: m.prop( aItem.contract_items_rate() )
				,contract_items_budget: m.prop( aItem.contract_items_budget() )
				,contract_items_service: m.prop( aItem.contract_items_service() )
				,contract_items_depreciation: m.prop( aItem.contract_items_depreciation() )
				,contract_items_appreciation: m.prop( aItem.contract_items_appreciation() )
				,contract_items_uom: m.prop( aItem.contract_items_uom() )
				,contract_items_damages_rate: m.prop( aItem.contract_items_damages_rate() )
				,contract_items_damages_period: m.prop( aItem.contract_items_damages_period() )
				,contract_items_applied: m.prop( aItem.contract_items_applied() )
				,contract_items_recorded_reference: m.prop( aItem.contract_items_recorded_reference() )
				,contract_items_recorded_reference_standard: m.prop( aItem.contract_items_recorded_reference_standard() )

				,currentRevenue: aItem.currentRevenue || 0
				,currentExpenditures: aItem.currentExpenditures || 0
				,currentProfits: aItem.currentProfits || 0
				,forecastedRevenue: aItem.forecastedRevenue || 0
				,forecastedExpenditures: aItem.forecastedExpenditures || 0
				,forecastedProfits: aItem.forecastedProfits || 0
				,outstandingRevenue: aItem.outstandingRevenue || 0
				,outstandingExpenditures: aItem.outstandingExpenditures || 0
				,outstandingProfits: aItem.outstandingProfits || 0

				,accruedExpenditures: aItem.accruedExpenditures || 0
				,accruedRevenue: aItem.accruedRevenue || 0
				,accruedProfits: aItem.accruedProfits || 0
				,accruedDailyRevenue: aItem.accruedDailyRevenue || 0
				,accruedDailyExpenditures: aItem.accruedDailyExpenditures || 0
				,accruedDailyPeriodRevenue: aItem.accruedDailyPeriodRevenue || 0
				,accruedDailyPeriodExpenditures: aItem.accruedDailyPeriodExpenditures || 0
				,accruedDailyProfits: aItem.accruedDailyProfits || 0
				,accruedDailyPeriodProfits: aItem.accruedDailyPeriodProfits || 0

				,currentDailyPeriodRevenue: aItem.currentDailyPeriodRevenue || 0
				,currentDailyPeriodExpenditures: aItem.currentDailyPeriodExpenditures || 0
				,forecastedDailyPeriodRevenue: aItem.forecastedDailyPeriodRevenue || 0
				,forecastedDailyPeriodExpenditures: aItem.forecastedDailyPeriodExpenditures || 0
				,currentDailyPeriodProfits: aItem.currentDailyPeriodProfits || 0
				,forecastedDailyPeriodProfits: aItem.forecastedDailyPeriodProfits || 0
				,outstandingDailyPeriodRevenue: aItem.outstandingDailyPeriodRevenue || 0
				,outstandingDailyPeriodExpenditures: aItem.outstandingDailyPeriodExpenditures || 0
				,outstandingDailyPeriodProfits: aItem.outstandingDailyPeriodProfits || 0

				,currentDailyRevenue: aItem.currentDailyRevenue || 0
				,currentDailyExpenditures: aItem.currentDailyExpenditures || 0
				,forecastedDailyRevenue: aItem.forecastedDailyRevenue || 0
				,forecastedDailyExpenditures: aItem.forecastedDailyExpenditures || 0
				,currentDailyProfits: aItem.currentDailyProfits || 0
				,forecastedDailyProfits: aItem.forecastedDailyProfits || 0
				,outstandingDailyRevenue: aItem.outstandingDailyRevenue || 0
				,outstandingDailyExpenditures: aItem.outstandingDailyExpenditures || 0
				,outstandingDailyProfits: aItem.outstandingDailyProfits || 0

				,materialActual: aItem.materialActual || 0
				,materialForecasted: aItem.materialForecasted || 0
				,contractedDailyPeriodProgress: aItem.contractedDailyPeriodProgress || 0
				,contractedProgress: aItem.contractedProgress || 0
				,contractedDailyProgress: aItem.contractedDailyProgress || 0
				,budget: aItem.budget || 0
				,invoicecount: aItem.invoicecount
				,created: route_original_contract_id()
					? true
					: aItem.created

				,crews_discipline_rates_id: aItem.crews_discipline_rates_id
				,modules_id: aItem.modules_id
				,warehouse_id: aItem.warehouse_id
				,contractor_id: aItem.contractor_id
				,crew_id: aItem.crew_id
				,project_id: aItem.project_id
				,organizations_disciplines_id: aItem.organizations_disciplines_id
				,tools_id: aItem.tools_id
				,user_id: aItem.user_id
				,contractrecognitionid:
					aItem.modules_id
					|| aItem.warehouse_id
					|| aItem.contractor_id
					|| aItem.project_id
					|| aItem.organizations_disciplines_id
					|| aItem.tools_id
					|| aItem.user_id
					|| aItem.crew_id
					|| aItem.crews_discipline_rates_id
			}
		})
	}


	const contract =
	 	asyncMergeAll(
			[
				route_contract_id
				,route_original_contract_id
				,contracts
			]
		)
		.map(
			([routeContractID, routeOriginalContractID, fArray]) => {

				const matchID = routeContractID || routeOriginalContractID
				const selectedContract =
					routeContractID || routeOriginalContractID
						? fArray.find((c) => c.contract_id == matchID)
						: null

				return selectedContract || { contract_items: [] }
			}
		)

	const active = {
		organization_id: contract.map( R.propOr( null, 'organization_id' ) )
		,contract_id: contract.map( (c) =>
							route_original_contract_id()
								? null
								: R.propOr( null, 'contract_id')(c)
						)
		,contract_name: contract.map( (c) =>
							route_original_contract_id()
								? R.propOr( null, 'contract_name')(c) + ' (Clone)'
								: R.propOr( null, 'contract_name')(c)
						)
		,contract_description: contract.map( R.propOr( null, 'contract_description') )
		,contract_payment_term: contract.map( R.propOr(0, 'contract_payment_term') )
		,contract_count_invoices: contract.map( R.propOr( true, 'contract_count_invoices'))
		,contract_start_date: contract.map( R.propOr( null, 'contract_start_date') )
		,supplier_id: contract.map( R.propOr( null, 'supplier_id') )
		,supplier_name: contract.map( R.propOr( null, 'supplier_name') )
		,contract_end_date: contract.map( R.propOr( null, 'contract_end_date') )
		,contract_terms: contract.map( (a) => R.propOr(null, 'contract_terms')(a) )

		,client_id: contract.map( R.propOr( null, 'client_id') )
		,client_organization_name: contract.map( R.propOr( null, 'client_organization_name') )
		,client_incorporation_no: contract.map( R.propOr( null, 'client_incorporation_no') )
		,client_email: contract.map( R.propOr( null, 'client_email') )
		,client_phone: contract.map( R.propOr( null, 'client_phone') )
		,client_address: contract.map( R.propOr( null, 'client_address') )
		,client_state: contract.map( R.propOr( null, 'client_state') )
		,client_postcode: contract.map( R.propOr( null, 'client_postcode') )

		,account_id: contract.map( R.propOr( null, 'account_id') )
		,account_bsb: contract.map( R.propOr( null, 'account_bsb') )
		,account_no: contract.map( R.propOr( null, 'account_no') )
		,account_bank: contract.map( R.propOr( null, 'account_bank') )
		,account_bic: contract.map( R.propOr( null, 'account_bic') )
		,account_iban: contract.map( R.propOr( null, 'account_iban') )
		,account_swift_code: contract.map( R.propOr( null, 'account_swift_code') )

		,contract_items:
			contract.map((c) =>
				c
					? cloneContractItems(c)
					: []
			)
	}

	const hiddenItemData = data.scoped({})

	asyncMergeAll([
		advancedView
		,contract
	])
	.map(([av, contract]) =>
		hiddenItemData({
			contract_items_service: !av && !contract.contract_items.some((c) => c.contract_items_service())
			,contract_items_depreciation: !av && !contract.contract_items.some((c) => c.contract_items_depreciation())
			,contract_items_appreciation: !av && !contract.contract_items.some((c) => c.contract_items_appreciation())
			,contract_items_damages: !av && !contract.contract_items.some((c) => c.contract_items_damages_rate())
			,contract_items_damages_period: !av && !contract.contract_items.some((c) => c.contract_items_damages_rate())
		})
	)

	;contract() == null && active && active.contract_items([])

	const totalOverview =
		data.scoped({
			scheduleNameVersion: ''
			,currentProfits: 0
			,odincount: 0
			,invoicecount: 0
			,odinitems: []
			,tp: data.scoped(20)
		})
	const contractItemSums =
		asyncMergeAll(
			[
				active.contract_items
				,checkMultiple
				,calculated
			]
		).map(([activeItems]) =>
			R.reduce(
				accumulator
				,{
					currentRevenue: 0
					,currentExpenditures: 0
					,currentProfits: 0
					,forecastedRevenue: 0
					,forecastedExpenditures: 0
					,forecastedProfits: 0
					,outstandingRevenue: 0
					,outstandingExpenditures: 0
					,outstandingProfits: 0
					,currentDailyPeriodRevenue: 0
					,currentDailyPeriodExpenditures: 0
					,forecastedDailyPeriodRevenue: 0
					,forecastedDailyPeriodExpenditures: 0
					,currentDailyPeriodProfits: 0
					,forecastedDailyPeriodProfits: 0
					,outstandingDailyPeriodRevenue: 0
					,outstandingDailyPeriodExpenditures: 0
					,outstandingDailyPeriodProfits: 0
					,currentDailyRevenue: 0
					,currentDailyExpenditures: 0
					,forecastedDailyRevenue: 0
					,forecastedDailyExpenditures: 0
					,currentDailyProfits: 0
					,forecastedDailyProfits: 0
					,outstandingDailyRevenue: 0
					,outstandingDailyExpenditures: 0
					,outstandingDailyProfits: 0
					,accruedExpenditures: 0
					,accruedRevenue: 0
					,accruedProfits: 0
					,accruedDailyRevenue: 0
					,accruedDailyExpenditures: 0
					,accruedDailyPeriodRevenue: 0
					,accruedDailyPeriodExpenditures: 0
					,accruedDailyProfits: 0
					,accruedDailyPeriodProfits: 0
					,contractedProgress: 0
					,contractedDailyProgress: 0
					,contractedDailyPeriodProgress: 0
					,budget:0
					,invoicecount:0
				}
				,calculated()
					? activeItems.map((c) =>
						allContractItemsIndexByContractItemId()
						[c.contract_items_id()]
					)
					.filter((c) => c )
					: []
			)
		)


	const receiptedInvoices =
		focussedInvoices.map((invoiceList) =>
			invoiceList.filter((i) =>
				i.receipting && i.created
			)
		)

	const selectedInvoiceStats =
		asyncMergeAll(
			[
				selectedInvoices
				,focussedInvoices
			]
		)
		.map((invoiceLists) => {

			const stateOptions = [
				{type: 'Receipted Revenue', propStart: 'current', propEnd: 'Revenue'}
				,{type:'Accrued Revenue', propStart: 'outstanding', propEnd:'Revenue'}
				,{type:'Receipted Expenditure', propStart: 'current', propEnd:'Expenditures'}
				,{type:'Accrued Expenditure', propStart: 'outstanding', propEnd:'Expenditures'}
			]

			const statArray =
				invoiceLists.map((invoiceList) => {

					const statObject =
						R.reduce(
							(a, b) => {
								[
									'invoice_amount'
									,'invoice_service_amount'
									,'invoice_damages_amount'
									,'invoice_depreciation_amount'
									,'invoice_appreciation_amount'
								].map((propName) =>

									b[propName]() * b[propName.replace('_amount', '_rate')]() >= 0 && b['invoice_received']()
									? a.currentRevenue = a.currentRevenue +  b[propName]() * b[propName.replace('_amount', '_rate')]()
									: b[propName]() * b[propName.replace('_amount', '_rate')]() >= 0 && !b['invoice_received']()
									? a.outstandingRevenue = a.outstandingRevenue +  b[propName]() * b[propName.replace('_amount', '_rate')]()
									: b[propName]() * b[propName.replace('_amount', '_rate')]() < 0 && b['invoice_received']()
									? a.currentExpenditures = a.currentExpenditures +  b[propName]() * b[propName.replace('_amount', '_rate')]()
									: a.outstandingExpenditures = a.outstandingExpenditures +  b[propName]() * b[propName.replace('_amount', '_rate')]()
								)
								a.currentProfits = a.currentRevenue + a.currentExpenditures
								a.outstandingProfits = a.outstandingRevenue + a.outstandingExpenditures
								return a
							}
							,{
								currentRevenue: 0
								,outstandingRevenue: 0
								,currentExpenditures: 0
								,outstandingExpenditures: 0
								,currentProfits: 0
								,outstandingProfits: 0
								,forecastedRevenue: 0
								,forecastedExpenditures: 0
								,forecastedProfits: 0
								,accruedExpenditures: 0
								,accruedRevenue: 0
								,accruedProfits: 0
							}
							, invoiceList
						)

					stateOptions.map((ifilter) =>
						invoiceList.filter((i) =>
							i.invoice_type() == ifilter.type
						)
					)
					.forEach((iList, iIndex) => {
						const ref = stateOptions[iIndex]
						statObject[ref.propStart + 'Daily' + ref.propEnd] = 0
						statObject[ref.propStart + 'DailyPeriod' + ref.propEnd] = 0

						if(iList.length != 0){
							const invoiceDays =
								iList.length > 1
									? (
										new Date(iList[iList.length - 1].invoice_date()).getTime()
										- new Date(iList[0].invoice_date()).getTime()
									)/milliperday
									: 1
							statObject[ref.propStart + 'Daily' + ref.propEnd] = statObject[ref.propStart + ref.propEnd]/(invoiceDays || 1 )
							statObject[ref.propStart + 'DailyPeriod' + ref.propEnd] = statObject[ref.propStart + ref.propEnd]/(invoiceDays || 1 )
						}
					})

					return statObject
				})
			return {sInvoices: statArray[0], fInvoices: statArray[1]}
		}
	)

	const viewStats = asyncMergeAll(
		[
			route_view_type
			,viewWindowList
			,selectedInvoiceStats
		]
	)
	.map(([v, viewWindowList]) =>

		R.reduce(
			(a, b) =>
				R.mapObjIndexed((object, propIndex) =>
					object = object + checkprop(propIndex, null, b)
				)(a)
			,{

				currentRevenue: 0
				,currentExpenditures: 0
				,currentProfits: 0
				,forecastedRevenue: 0
				,forecastedExpenditures: 0
				,forecastedProfits: 0
				,outstandingRevenue: 0
				,outstandingExpenditures: 0
				,outstandingProfits: 0

				,currentDailyPeriodRevenue: 0
				,currentDailyPeriodExpenditures: 0
				,forecastedDailyPeriodRevenue: 0
				,forecastedDailyPeriodExpenditures: 0
				,currentDailyPeriodProfits: 0
				,forecastedDailyPeriodProfits: 0
				,outstandingDailyPeriodRevenue: 0
				,outstandingDailyPeriodExpenditures: 0
				,outstandingDailyPeriodProfits: 0

				,currentDailyRevenue: 0
				,currentDailyExpenditures: 0
				,forecastedDailyRevenue: 0
				,forecastedDailyExpenditures: 0
				,currentDailyProfits: 0
				,forecastedDailyProfits: 0
				,outstandingDailyRevenue: 0
				,outstandingDailyExpenditures: 0
				,outstandingDailyProfits: 0

				,accruedExpenditures: 0
				,accruedRevenue: 0
				,accruedProfits: 0
				,accruedDailyRevenue: 0
				,accruedDailyExpenditures: 0
				,accruedDailyPeriodRevenue: 0
				,accruedDailyPeriodExpenditures: 0
				,accruedDailyProfits: 0
				,accruedDailyPeriodProfits: 0

				,contractedDailyPeriodProgress: 0
				,contractedProgress: 0
				,contractedDailyProgress: 0
				,budget: 0

			}
			, v == 'All Contracts'
				? focussedContracts()
				: v == 'All Items'
				? viewWindowList
				: v == 'Make Ledgers'
				? R.unnest([selectedInvoiceStats().sInvoices || []])
				: R.unnest([selectedInvoiceStats().fInvoices || []])
		)
	)

	function contractIdentical(){

		return contract() && active
			? [
				'organization_id'
				,'contract_id'
				,'contract_name'
				,'contract_description'
				,'contract_payment_term'
				,'contract_count_invoices'
				,'contract_start_date'
				,'supplier_id'
				,'supplier_name'
				,'contract_end_date'
				,'contract_terms'

				,'client_id'
				,'client_organization_name'
				,'client_incorporation_no'
				,'client_email'
				,'client_phone'
				,'client_address'
				,'client_state'
				,'client_postcode'

				,'account_id'
				,'account_bsb'
				,'account_no'
				,'account_bank'
				,'account_bic'
				,'account_iban'
				,'account_swift_code'

			].every((a) =>
				active[a]() == contract()[a]
			)
			&& identical(
				active.contract_items()
				,contract().contract_items
			)
			: !contract()
				? false
				: true
	}



	function createReversals(){
		let reversal = cloneInvoice(selectedInvoices())
		reversal.forEach((i) => {
			i.invoice_id('')
			i.invoice_amount(
				i.invoice_amount() * -1
			)
			i.invoice_service_amount(
				i.invoice_service_amount() * -1
			)
			i.invoice_depreciation_amount(
				i.invoice_depreciation_amount() * -1
			)
			i.invoice_appreciation_amount(
				i.invoice_appreciation_amount() * -1
			)
			i.invoice_damages_amount(
				i.invoice_damages_amount() * -1
			)
			i.invoice_received(false)
			i.invoice_approval(false)
			i.invoice_reversal(true)
			i.created = true
			i.invoice_items.map((ii) =>
				R.merge(
					ii
					,{
						invoice_items_amount: ii.invoice_items_amount * -1
						,invoice_items_received_amount: 0
						,invoice_items_id: ''
						,invoice_id: ''
						,timesheets_id: ii.timesheets_id
						,project_extras_id: ii.project_extras_id
					}
				)
			)
		})
		route_view_type('Make Ledgers')
		selectedInvoices(reversal)
	}

	function invoiceIdenticals(){
		return !focussedInvoices()
		|| identical(
			focussedInvoices()
			,originalInvoices()
			,contractProps
		)
	}

	function saveDisabledInvoices(returnBoolean){
		const errorArray = [
			(i) =>
				Number(
						i.invoice_amount() * i.invoice_rate()
						+ i.invoice_service_amount() * i.invoice_service_rate()
						+ i.invoice_depreciation_amount() * i.invoice_depreciation_rate()
						+ i.invoice_appreciation_amount() * i.invoice_appreciation_rate()
						+ i.invoice_damages_amount() * i.invoice_damages_rate()
				) == 0
					? 'ledger value must not be zero'
					: ''
			,(i) => !i.project_id()
				&& (
					i.crews_discipline_rates_id
					|| i.contractor_id
					|| i.contractprojectid
					|| i.organizations_disciplines_id
					|| i.crew_id
				)
					? 'project must be designated'
					: ''
		]

		return elements.errorAlert(
			`To save, `
			,' and '
			,''
			, route_view_type() == 'Make Ledgers'
				? selectedInvoices() || []
				: focussedInvoices() || []
			,errorArray
			,returnBoolean
			,false
			,'warning'
			,null
			,[
				Object.keys(errors())
				.map((a) =>
					errors()[a]
						? a + ' ' + errors()[a]
						: ''
				)
				.filter((a) => a)
				.join(' ')
			]
		)
	}


	const dateDisabled = function(){
		return forecastdateA() > forecastdateB()
			? `Focus dates should be forward flowing `
				+ moment(forecastdateA()).format("ll")
				+ ' to the '
				+ moment(forecastdateB()).format("ll")
				+ ' flows backwards.'
			: ''
	}

	const notCalculated = function(returnBoolean){
		const errorArray = [
			() =>
				!identical(
					{
						schedules: focussedEntities()
							.map((a) => ({
								snv: a.scheduleNameVersion
								,dReceived: {
									coalatedSelectedVersion: false
									,possibleContractEntities: false
									,focussedInvoices: false
									,allContractItems: false
									,allContracts: false
								}
								,schedule_version_id: a.schedule_version_id
							}))
						,dateA: forecastdateA()
						,dateB: forecastdateB()
					}
					,recordedFocus()
				) && reMapdateChange()
					? `The focus has changed but hasn't been re-calculated`
					: null
		]
		return elements.errorAlert(
			``
			,''
			,''
			,[true]
			,errorArray
			,returnBoolean
			,false
			,'warning'
			,null
			,[dateDisabled()]
		)
	}

	const saveDisabled = function(returnBoolean){
		const count = R.countBy(
			chr => typeof chr.contract_name == 'function'
				? chr.contract_name()
				: chr.contract_name
			,focussedContracts()
			.filter((r) => r.contract_id != active.contract_id())
			.concat(active)
		)
		const errorArray = [
			(d, prop='contract_name') => !d[prop]() && calculated()
				? `Contract Name must not be empty` : null
			,(d, prop='contract_description') => !d[prop]() && calculated()
				? `Contract Description must not be empty` : null

			,(d, prop='contract_start_date') => !d[prop]() && calculated()
				? `Contract Start Date must not be empty` : null
			,(d, prop='contract_end_date') => !d[prop]() && calculated()
				? `Contract End Date must not be empty` : null

			,(d, prop='contract_name') => count[d[prop]()] > 1 && calculated()
				? `Contract Names must not be duplicated` : null
		]

		return elements.errorAlert(
				`To save, `
				,' and '
				,''
				,[active]
				,errorArray
				,returnBoolean
				,false
				,'warning'
				,null
				,Object.keys(errors())
					.map((a) =>
						errors()[a]
							? a + ' ' + errors()[a]
							: ''
					)
					.concat(
						forecastdateA() > forecastdateB()
							? `Focus dates should be forward flowing `
								+ moment(forecastdateA()).format("ll")
								+ ' - '
								+ moment(forecastdateB()).format("ll")
								+ ' flows backwards.'
							: ''
					)
					.filter((a) => a)
		)
	}


	const checkContractItem = function(returnBoolean, statistic){
		const count = R.countBy(
			(chr) => chr
				? chr.contract_items_name()
				+ chr.contract_items_description()
				: null
			,R.uniqBy(
				(a) => a.contract_items_name()
				+ a.contract_items_description()
				+ a.contract_items_id()
				,[].concat(
					active.contract_items() || []
					,R.filter(
						(a) => typeof a != 'string'
						,R.unnest(R.toPairs(checkMultiple()))
					)
				)
			)
		)
		const errorArray = [
			(d, prop='contract_items_name') => !d[prop]()
				? `Item Name must not be empty` : null
			,(d, prop='contract_items_description') => !d[prop]()
				? `Item Description must not be empty` : null
			,(d) => count[ !d['contract_items_name']() + !d['contract_items_description']() ] > 1
				? `Item Name and Description must not be duplicated on different rows`
				: null
		]
		return elements.errorAlert(
				`To save, `
				,' and the '
				,''
				,[].concat(
					active.contract_items() || []
					,R.filter(
						(a) => typeof a != 'string'
						,R.unnest(R.toPairs(checkMultiple()))
					)
				)
				,errorArray
				,returnBoolean
				,false
				,'warning'
				, statistic
		)
	}

	const duplicatedContractItem = function(returnBoolean, statistic){
		const count =
			R.countBy(
				(c) => c.contractrecognitionid
				,active.contract_items() || []
			)
		const errorArray = [
			(d) => count[d['contractrecognitionid']] > 1
				? d.contract_items_name() + ' x ' + count[d['contractrecognitionid']]
				: null
		]
		return elements.errorAlert(
				``
				,', '
				,''
				,R.uniqBy(
					(c) => c.contractrecognitionid
					, active.contract_items() || []
				)
				,errorArray
				,returnBoolean
				,false
				,'warning'
				, statistic
		)
	}


	const contractItemSelections = asyncMergeAll(
		[
			baseItemsList
			,coption
			,active.contract_items
		]
	).map(([, co]) =>
		co.list().filter((a) =>
			!a.discipline_id
			&& !(a.project_id && !a.discipline_id && a.contract_id && a.contract_id != active.contract_id())
			&& !a.contract_items_id
			&& (co.cname != 'Users' || a.user_id() != 'Unassigned')
			&& (
				a.contractor_id
				|| a.project_id
				|| !a.user_id
				|| !a.crew_id
			 	|| !active.contract_items().find((ac) =>
					a.user_id && ac.user_id == ac.user_id
					|| a.crew_id && ac.crew_id == ac.crew_id
				 )
			)
		)
	)


	const adjustPercenage = (value, dataObject) => {
		R.map(
			(aitem) =>
				[
					'contract_items_rate'
					,'contract_items_service'
					,'contract_items_depreciation'
					,'contract_items_appreciation'
					,'contract_items_damages_rate'
					,'contract_items_budget'
				]
				.forEach((iRate) => {
					aitem[iRate](
						aitem[iRate]() * ( 1 + Number(value/100) )
					)
				})
			,dataObject
		)
		adjustPercenageOption(false)
	}


	const setOptions = function(value, prop, dataObject, forceset){

		const standard = Array.isArray(dataObject)
			// typeof(dataObject) == 'object'
			? 0
			: Object.keys(dataObject)[0]

		let dtype = typeof  dataObject[standard][prop] == 'function'

		value || value == false || value == '' || value == 0 || forceset
			? (
				Array.isArray(dataObject)
					? dataObject
					: Object.keys(dataObject)
			)
			.forEach( (aitem) => {

				let structure =
					Array.isArray(dataObject)
						? aitem
						: dataObject[aitem]



				structure['created'] = true
				dtype ? structure[prop](value) : structure[prop] = value

				structure.invoice_type
					? structure.invoice_type(
						Number(
							structure.invoice_amount() * structure.invoice_rate()
							+ structure.invoice_service_amount() * structure.invoice_service_rate()
							+ structure.invoice_depreciation_amount() * structure.invoice_depreciation_rate()
							+ structure.invoice_appreciation_amount() * structure.invoice_appreciation_rate()
							+ structure.invoice_damages_amount() * structure.invoice_damages_rate()
						) > 0
							? structure.invoice_received()
								? 'Receipted Revenue'
								: 'Accrued Revenue'
							: structure.invoice_received()
								? 'Receipted Expenditure'
								: 'Accrued Expenditure'
							)
					: null

			})
			: null

		return dtype ? dataObject[standard][prop]() : dataObject[standard][prop]
	}


	function uploadmetadata({only}){
		return R.pickBy(
			(a, k) => a && (!only || !Object.keys(only).length || (only && only[k]) || k == 'user_id' || k == 'organization_id')
			,{
				organization_id: data.initiateContractFetch().organization_id
				, user_id: data.auth.stream().user_id
				, invoice_id: R.last(selectedInvoices() || {}).invoice_id()
			}
		)
	}


	const detailPaneInputs = function(){


		const contractDetails = function(){

			const nameProp =
				route_view_type() == 'All Items'
					? 'contractrecognitionname'
					: 'contract_name'

			const descriptionProp =
				route_view_type() == 'All Items'
					? 'contractrecognitiondescription'
					: 'contract_description'

			const SelectedValue =
				R.reduce(
					accumulator
					,{
						currentRevenue: 0
						,currentExpenditures: 0
						,currentProfits: 0
						,forecastedRevenue: 0
						,forecastedExpenditures: 0
						,forecastedProfits: 0
						,outstandingRevenue: 0
						,outstandingExpenditures: 0
						,outstandingProfits: 0
						,currentDailyPeriodRevenue: 0
						,currentDailyPeriodExpenditures: 0
						,forecastedDailyPeriodRevenue: 0
						,forecastedDailyPeriodExpenditures: 0
						,currentDailyPeriodProfits: 0
						,forecastedDailyPeriodProfits: 0
						,outstandingDailyPeriodRevenue: 0
						,outstandingDailyPeriodExpenditures: 0
						,outstandingDailyPeriodProfits: 0
						,currentDailyRevenue: 0
						,currentDailyExpenditures: 0
						,forecastedDailyRevenue: 0
						,forecastedDailyExpenditures: 0
						,currentDailyProfits: 0
						,forecastedDailyProfits: 0
						,outstandingDailyRevenue: 0
						,outstandingDailyExpenditures: 0
						,outstandingDailyProfits: 0
						,accruedExpenditures: 0
						,accruedRevenue: 0
						,accruedProfits: 0
						,accruedDailyRevenue: 0
						,accruedDailyExpenditures: 0
						,accruedDailyPeriodRevenue: 0
						,accruedDailyPeriodExpenditures: 0
						,accruedDailyProfits: 0
						,accruedDailyPeriodProfits: 0
						,contractedProgress: 0
						,contractedDailyProgress: 0
						,contractedDailyPeriodProgress: 0
						,budget: 0
					}
					, R.filter(
						(a) => typeof a != 'string'
						,R.unnest(R.toPairs(checkMultipleContracts()))
					)
				)

			return [

				elements.list([
					m('label.control-label.mb1', 'Name')
				])

				,elements.list([
					Object.keys(checkMultipleContracts())
						.map( (c) =>
							checkMultipleContracts()[c][nameProp]
							+ ' - '
							+ checkMultipleContracts()[c][descriptionProp]
						)
						.map(s => m('p.pv1.ma0', s ))
				])

				,elements.list([
					m('h4','Statistics - ')
					,invoiceDataLoading() || metaDataIndexing()
					? ` ... `
					: m('a'
						,{
							href: route_view_type() == 'All Items'
								? 									'/data/contracts/contractitems/forecastdateA/'
									+ forecastdateA()
									+ '/forecastdateB/'
									+ forecastdateB()
									+ '/ledger/'
									+ 										Object.keys(checkMultipleContracts())
										.map( (c) =>
											checkMultipleContracts()[c]
											['contractrecognitionid']
										)
										.join(',')


								: 									'/data/contracts/contracts/view/forecastdateA/'
									+ forecastdateA()
									+ '/forecastdateB/'
									+ forecastdateB()
									+ '/ledger/'
									+ 										Object.keys(checkMultipleContracts())
										.map((c) =>
											checkMultipleContracts()[c]
											['contract_id']
										)
										.join(',')


							, oncreate: m.route.link
							, title: "View Invoices"
							, disabled:
								Object.keys(checkMultipleContracts())
									.every( c => checkMultipleContracts()[c]['invoicecount'] == 0 )
								|| invoiceDataLoading()
						}
						, R.sum(
							Object.keys(checkMultipleContracts())
							.map((c) => checkMultipleContracts()[c]['invoicecount'] || 0)
						) + ' Ledgers'
					)
				])

				,m('br')
				,renderSummary(SelectedValue, 'Revenue', dailyAverage, null, '', false, {})
				,m('br')
				,renderSummary(SelectedValue, 'Expenditures', dailyAverage, null, '', false, {})
				,m('br')
				,renderSummary(SelectedValue, 'Profits', dailyAverage, null, '', true, {})
				,m('br')

				,route_view_type() == 'All Items'
				? null
				: [
					elements.list([
						m('label.control-label', 'Absorb similar rates from')
						,m( Pencil,
							() => donerContract()
								? donerContract().contract_name
								: ' ...'
							,() =>
								elements.list([
									autocomplete.strict(
										contracts
										,donerContract
										,'contract_name'
										, () => ({
											style: {
												height: '3em'
												,width: '12em'
											}

											,title:
												'Absorb similiar rates from another Contract, '
												+'matched based on similiar item descriptions'
										})
									)
									,m('button.btn.btn-secondary'
										,{
											title: 'Absorb rates from another contract'
											,disabled: !donerContract()
											,style: {
												backgroundColor: 'transparent'
												,border: 'solid 1px #3380c2'
												,position: 'relative'
												,top: '-0.1em'
												,height: '2.9em'
												,width: '5em'
												,color: '#434aa3'
												,textDecoration: 'underline'
											}
											,onmousedown: () => {

												const itemsIndex =
													R.groupBy(
														R.prop('contractrecognitiondescription')
														,R.unnest(
															Object.keys(checkMultipleContracts())
															.map((c) => checkMultipleContracts()[c].contract_items )
														)
													)

												Object.keys(itemsIndex)
												.forEach((c) => {
													const donerItem =
														donerContract()
														.contract_items
														.find((a) =>
															a.contractrecognitiondescription
															== c
														)

													!donerItem
														? null
														: itemsIndex[c]
														.forEach((ri) => {
															[
																'contract_items_damages_period'
																,'contract_items_recorded_reference'
																,'contract_items_rate'
																,'contract_items_service'
																,'contract_items_appreciation'
																,'contract_items_damages_rate'
																,'contract_items_depreciation'
																,'contract_items_budget'
															]
															.forEach((p) => {
																ri['created'] =true
																ri[p]( donerItem[p]() )
															})
														})
												})

												donerContract(null)
												sendContracts(
													R.filter(
														(a) => typeof a != 'string'
														,R.unnest(R.toPairs(checkMultipleContracts()))
													)
												)
											}
										}
										, 'Absorb'
									)
								])
						)
					])
					,elements.list([
						m('button.btn.btn-secondary'
							,{
								title: 'Consolidate multiple contracts'
								,disabled:
									Object.keys(checkMultipleContracts()).length <= 1
									|| checkMultipleContracts()[null]
								,style: {
									backgroundColor: 'transparent'
									,border: 'solid 1px #3380c2'
									,position: 'relative'
									,top: '-0.1em'
									,height: '2.9em'
									,width: '5em'
									,color: '#434aa3'
									,textDecoration: 'underline'
								}
								,onmousedown: () => {

									const uContract = checkMultipleContracts()[Object.keys(checkMultipleContracts())[0]]
									const newContractId = uContract.contract_id

									const newStartDateArray = []
									const newFinishDateArray = []

									const allContractItems =
										R.unnest(
											Object.keys(checkMultipleContracts())
											.map((c) => {
												newStartDateArray.push(new Date(checkMultipleContracts()[c].contract_start_date).getTime())
												newFinishDateArray.push(new Date(checkMultipleContracts()[c].contract_end_date).getTime())
												return checkMultipleContracts()[c].contract_items
											})
										)

									uContract.contract_end_date =
										R.pipe(
											R.filter(R.identity)
											,R.sort(R.identity)
											,R.last
											,R.tap(
												(x) => {
													const parsed = new Date(x)
													parsed.setMinutes( + parsed.getTimezoneOffset() )
													return x = parsed.getTime()
												}
											)
										)(newFinishDateArray)


									uContract.contract_start_date =
										R.pipe(
											R.filter(R.identity)
											,R.sort(R.identity)
											,R.reverse
											,R.last
											,R.tap(
												(x) => {
													const parsed = new Date(x)
													parsed.setMinutes( + parsed.getTimezoneOffset() )
													return x = parsed.getTime()
												}
											)
										)(newStartDateArray)


									delete checkMultipleContracts()[newContractId]

									Object.keys(checkMultipleContracts())
									.forEach((c) => {
										checkMultipleContracts()[c].contract_items
										.forEach((ci) => {
											ci.contract_id(newContractId)
											ci.contract_items_id('')
										})
									})

									uContract.contract_items = allContractItems

									return sendContracts([uContract])
									.then(function(){
										return deleteContract()
									})
								}
							}
							, 'Unify'
						)
						,m('label', ` Contracts `)
					])
					,m(
						'label'
						,`Unifying contracts will consolidate all contract items into a main contract
						and extend the main contracts terms to the earliest and latest dates of the existing contracts`
					)
				]
			]
		}

		const contractItemsDetails = function(){

			const SelectedValue =
				R.reduce(
					accumulator
					,{
						currentRevenue: 0
						,currentExpenditures: 0
						,currentProfits: 0
						,forecastedRevenue: 0
						,forecastedExpenditures: 0
						,forecastedProfits: 0
						,outstandingRevenue: 0
						,outstandingExpenditures: 0
						,outstandingProfits: 0
						,currentDailyPeriodRevenue: 0
						,currentDailyPeriodExpenditures: 0
						,forecastedDailyPeriodRevenue: 0
						,forecastedDailyPeriodExpenditures: 0
						,currentDailyPeriodProfits: 0
						,forecastedDailyPeriodProfits: 0
						,outstandingDailyPeriodRevenue: 0
						,outstandingDailyPeriodExpenditures: 0
						,outstandingDailyPeriodProfits: 0
						,currentDailyRevenue: 0
						,currentDailyExpenditures: 0
						,forecastedDailyRevenue: 0
						,forecastedDailyExpenditures: 0
						,currentDailyProfits: 0
						,forecastedDailyProfits: 0
						,outstandingDailyRevenue: 0
						,outstandingDailyExpenditures: 0
						,outstandingDailyProfits: 0
						,accruedExpenditures: 0
						,accruedRevenue: 0
						,accruedProfits: 0
						,accruedDailyRevenue: 0
						,accruedDailyExpenditures: 0
						,accruedDailyPeriodRevenue: 0
						,accruedDailyPeriodExpenditures: 0
						,accruedDailyProfits: 0
						,accruedDailyPeriodProfits: 0
						,contractedProgress: 0
						,contractedDailyProgress: 0
						,contractedDailyPeriodProgress: 0
						,budget: 0
					}
					,R.filter(
						(a) => typeof a != 'string'
						,R.unnest(R.toPairs(checkMultiple()))
					)
					.map((c) =>
						allContractItemsIndexByContractItemId()
						[c.contract_items_id()]
					)
					.filter((c) => c )
				)


			return !Object.keys(checkMultiple()).length
				? null
				: [

					checkContractItem(
						false
						, [
							m('br')
							,elements.list([
								m('h4','Statistics - ')
								,invoiceDataLoading() || metaDataIndexing()
								? ` ... `
								: m('a'
									,{
										href: '/data/contracts/contracts/edit/'
											+ contract().contract_id
											+ '/forecastdateA/'
											+ forecastdateA()
											+ '/forecastdateB/'
											+ forecastdateB()
											+ '/ledger/'
											+ 											Object.keys(checkMultiple())
												.map( (c) =>
													checkMultiple()[c]
													['contract_items_id']()
												)
												.join(',')

										, oncreate: m.route.link
										, title: "View Invoices"
										, disabled:
											Object.keys(checkMultiple())
												.every( c => c.invoicecount == 0 )
											|| invoiceDataLoading()
									}
									,R.sum(
										Object.keys(checkMultiple())
										.map((c) =>
											(allContractItemsIndexByContractItemId()
											[checkMultiple()[c].contract_items_id()] || {'invoicecount': 0})
											['invoicecount']
										)
									) + ' Ledgers'
								)
							])

							,m('br')
							,renderSummary(SelectedValue, 'Revenue', dailyAverage, null, '', false, {})
							,m('br')
							,renderSummary(SelectedValue, 'Expenditures', dailyAverage, null, '', false, {})
							,m('br')
							,renderSummary(SelectedValue, 'Profits', dailyAverage, null, '', true, {})
							,m('br')
						]
					)

					,elements.list([
						elements.checkbox(
							{
								onchange: m.withAttr(
									'checked'
									, Object.keys(checkMultiple()).length > 1
										? (value) => setOptions(value, 'contract_items_applied', checkMultiple())
										: activeItem().contract_items_applied
								)
								,checked: activeItem().contract_items_applied()
								,title: "Will be calculated in forecasts"
							}
						)
						,m('p.pv1.ma0', 'Applied to Forecasts')
					])

					,m('br')
					,elements.list([
						editInvoicePermissions()
							? m('button.btn.btn-secondary', {
									onclick: () => {
										selectedInvoices(
											cloneInvoice(
												checkMultiple()
												,data.auth.stream().user_id
												,active
											)
										)
										route_view_type('Make Ledgers')
									}
									, disabled:
										Object.keys(checkMultiple()).length == 0
										|| Object.keys(checkMultiple()).some((i) =>
											!checkMultiple()[i].contract_items_id()
										)
										|| !calculated()
										|| !editInvoicePermissions()
										|| !contractIdentical()

									, title: "Make a ledger for the selected items"
									, style: {
										backgroundColor: 'transparent'
										, border: 'solid 1px #3380c2'
										, position: 'relative'
										, top: '-0.1em'
										, height: '2.5em'
										, width: '8.5em'
										, color: '#434aa3'
									}
								}
								, 'Make Ledgers'
							)
							: null

						,!contractIdentical()
							? ' - Save prior to Invoicing'
							: ''
					])

					,elements.list([
						m('label.control-label.mb1', 'Item Name'
							,Object.keys(checkMultiple())
							.map( (c) =>
								!checkMultiple()[c]['contract_items_id']()
								? checkMultiple()[c]['contract_items_name']()
								: 									checkMultiple()[c]['contract_items_name']()
									+ ' - '
									+ checkMultiple()[c]['contract_items_description']()
									+ ' - '
									+ checkMultiple()[c]['contract_items_client_reference']()

							)
							.map(s => m('p.pv1.ma0', elements.plainTxt(s) ))
						)
					])

					,elements.list([
						m('label.control-label.mb1', 'Item Description')
						, m.component(
							Pencil
							,() => activeItem().contract_items_description()
							,() =>
								elements.textInput(
									Object.keys(checkMultiple()).length > 1
										? (value) => setOptions(value, 'contract_items_description', checkMultiple())
										: activeItem().contract_items_description
									, {}
								)
						)
					])

					,elements.list([
						m('label.control-label.mb1', 'Client Reference')
						, m.component(
							Pencil
							,() => activeItem().contract_items_client_reference()
							,() =>
								elements.textInput(
									Object.keys(checkMultiple()).length > 1
										? (value) => setOptions(value, 'contract_items_client_reference', checkMultiple())
										: activeItem().contract_items_client_reference
									, {}
								)

						)
					])

					,elements.list([
						m('label.control-label.mb1' ,'Adjust Rates by ')
						,adjustPercenageOption()
							? m(
								NumberInput
								,R.merge(
									data
									,{
										errors
										,errorLabel: 'Rate Adjustment'
									}
								)
								,{
									prop: adjustPercenageValue
									,attrs: {
										step: 0.01
									}
								}
							)
							: null
						,m('button.btn.btn', {
							onclick: () => {
								adjustPercenageOption()
									? adjustPercenage(adjustPercenageValue(), checkMultiple())
									: adjustPercenageOption(true)
							}
						}, adjustPercenageOption()
							? 'Adjust'
							: 'Percentage'
						)
					])

					,elements.list([
						m('label.control-label.mb1' , 'Item Rate $/UoM')
						,m.component(
							Pencil
							,() => activeItem().contract_items_rate()
							,() =>
								m(
									NumberInput
									,R.merge(
										data
										,{
											errors
											,errorLabel: 'Item Rate/UoM'
										}
									)
									,{
										prop: Object.keys(checkMultiple()).length > 1
											? (value) => setOptions(value, 'contract_items_rate', checkMultiple())
											: activeItem().contract_items_rate
										,attrs: {
											step: 0.01
											,disabled: activeItem().modules_id

										}
									}
								)
						)
					])


					,elements.list([
						elements.selectbuttons(
							['Expenditure', 'Revenue']
							,(a) => {
								if(a){
									a
									!= (
										R.sum(
											[
												'contract_items_rate'
												,'contract_items_service'
												,'contract_items_depreciation'
												,'contract_items_appreciation'
												,'contract_items_damages_rate'
											]
											.map((r) => checkMultiple()[Object.keys(checkMultiple())[0]][r]())
										)
										<= 0
										? 'Expenditure'
										: 'Revenue'
									)
									? adjustPercenage(-200, checkMultiple())
									: null
								}
							}
							,{title: `Switch rates to deductions or additions`}
							,(notReq, a) =>
								(
									R.sum(
										[
											'contract_items_rate'
											,'contract_items_service'
											,'contract_items_depreciation'
											,'contract_items_appreciation'
											,'contract_items_damages_rate'
										]
										.map((r) => checkMultiple()[Object.keys(checkMultiple())[0]][r]())
									)
									<= 0
									? 'Expenditure'
									: 'Revenue'
								)
								== a
						)
					])

					,hiddenItemData()['contract_items_service']
					? null
					: elements.list([
						m('label.control-label.mb1' ,'Servicing Rate $/UoM')
						,m.component(
							Pencil
							,() => activeItem().contract_items_service()
							,() =>
								m(
									NumberInput
									,R.merge(
										data
										,{
											errors
											,errorLabel: 'Servicing Rate/UoM'
										}
									)
									,{
										prop: Object.keys(checkMultiple()).length > 1
											? (value) => setOptions(value, 'contract_items_service', checkMultiple())
											: activeItem().contract_items_service
										,attrs: {
											step: 0.01

										}
									}
								)
						)
					])

					,hiddenItemData()['contract_items_depreciation']
					? null
					: elements.list([
						m('label.control-label.mb1', 'Depreciation Rate $/UoM')
						,m.component(
							Pencil
							,() => activeItem().contract_items_depreciation()
							,() =>
								m(
									NumberInput
									,R.merge(
										data
										,{
											errors
											,errorLabel: 'Depreciation Rate/UoM'
										}
									)
									,{
										prop: Object.keys(checkMultiple()).length > 1
											? (value) => setOptions(value, 'contract_items_depreciation', checkMultiple())
											: activeItem().contract_items_depreciation
										,attrs: {
											step: 0.01

										}
									}
								)
						)
					])

					,hiddenItemData()['contract_items_appreciation']
					? null
					: elements.list([
						m('label.control-label.mb1' ,'Appreciation Rate $/UoM')
						,m.component(
							Pencil
							,() => activeItem().contract_items_appreciation()
							,() =>
								m(
									NumberInput
									,R.merge(
										data
										,{
											errors
											,errorLabel: 'Appreciation Rate/UoM'
										}
									)
									,{
										prop: Object.keys(checkMultiple()).length > 1
											? (value) => setOptions(value, 'contract_items_appreciation', checkMultiple())
											: activeItem().contract_items_appreciation
										,attrs: {
											step: 0.01

										}
									}
								)
						)
					])

					,hiddenItemData()['contract_items_damages_rate']
					? null
					: elements.list([
						m('label.control-label.mb1' ,'Damages Rate UoM/Day  $')
						,m.component(
							Pencil
							,() => activeItem().contract_items_damages_rate()
							,() =>
								m(
									NumberInput
									,R.merge(
										data
										,{
											errors
											,errorLabel: 'Damages Rate UoM/Day'
										}
									)
									,{
										prop: Object.keys(checkMultiple()).length > 1
											? (value) => setOptions(value, 'contract_items_damages_rate', checkMultiple())
											: activeItem().contract_items_damages_rate
										,attrs: {
											step: 0.01

										}
									}
								)
						)
					])

					,elements.list([
						m('label.control-label.mb1' ,'Damages Exemption Period (Days)')
						,m.component(
							Pencil
							,() => activeItem().contract_items_damages_period()
							,() =>
								m(
									NumberInput
									,R.merge(
										data
										,{
											errors
											,errorLabel: 'Damages Exemption Period (Days)'
										}
									)
									,{
										prop: Object.keys(checkMultiple()).length > 1
											? (value) => setOptions(value, 'contract_items_damages_period', checkMultiple())
											: activeItem().contract_items_damages_period
										,attrs: {
											step: 0.01

										}
									}
								)
						)
					])

					,elements.list([
						m('label.control-label.mb1' , 'Item Budget $/UoM')
						,m.component(
							Pencil
							,() => activeItem().contract_items_budget()
							,() =>
								m(
									NumberInput
									,R.merge(
										data
										,{
											errors
											,errorLabel: 'Item Budget/UoM'
										}
									)
									,{
										prop: Object.keys(checkMultiple()).length > 1
											? (value) => setOptions(value, 'contract_items_budget', checkMultiple())
											: activeItem().contract_items_budget
										,attrs: {
											step: 0.01
											,disabled: false

										}
									}
								)
						)
					])

					,Object.keys(checkMultiple()).length
					&& Object.keys(checkMultiple())
					.every((k) =>
						checkMultiple()[k].user_id
						|| checkMultiple()[k].crew_id
					)
					? [
						elements.list([
							m('label.control-label.mb1'
								,'Reference'
								,elements.selectbuttons(
									['Timesheets', 'Contract Days']
									,(v) => {
										if (v){
											setOptions(v, 'contract_items_recorded_reference', checkMultiple())
											v == 'Timesheets'
												? setOptions('Per Hour', 'contract_items_uom', checkMultiple())
												: setOptions('Per Day', 'contract_items_uom', checkMultiple())
										}
									}
									,{ disabled: false
									, title: "Quantification of hours or days used to evaluate forecasts and actual expense"
									}
									,(notReq, v) => activeItem().contract_items_recorded_reference() == v
								)
							)
						])
						,`A Timesheet operation will only apply this rate to timesheets captured specifically for projects that belong to this contract (Casual Employees).
						A Contract Days operation will apply this rate to any timesheet for this entity, including those not connected to projects outside of this contract (Full Time Employees)`
					]
					: null

					,activeItem().contract_items_recorded_reference()
					!= 'Timesheets'
						? null
						: elements.list([
							m('label.control-label.mb1' , 'Standard Day')
							,m.component(
								Pencil
								,() => activeItem().contract_items_recorded_reference_standard()
								,() =>
									m(
										NumberInput
										,R.merge(
											data
											,{
												errors
												,errorLabel: 'Standard Day'
											}
										)
										,{
											prop: Object.keys(checkMultiple()).length > 1
												? (value) => setOptions(value, 'contract_items_recorded_reference_standard', checkMultiple())
												: activeItem().contract_items_recorded_reference_standard
											,attrs: {
												step: 0.01
												,disabled: false

											}
										}
									)
							)
						])

				]
		}


		const invoiceItemsDetails = function(){

			const noamountedits = selectedInvoices().some((i) => i.invoice_items.length)
			return [

				m('br')

				,elements.list([
					m('h4','Statistics')
					,reCalculateButton({spinner: false, forcereroute: true})
					// ,StatisticOptions()
				])

				,m('br')
				,renderSummary(selectedInvoiceStats().sInvoices, 'Revenue', dailyAverage, null, '', false, {})
				,m('br')
				,renderSummary(selectedInvoiceStats().sInvoices, 'Expenditures', dailyAverage, null, '', false, {})
				,m('br')
				,renderSummary(selectedInvoiceStats().sInvoices, 'Profits', dailyAverage, null, '', true, {})
				,m('br')

				,elements.list([
					m('label.control-label.mb1', 'Invoice Name | Ref | Approved | Received')
				])
				,elements.list([
					selectedInvoices()
						.map( (si, sindex) =>
							sindex + 1
							+ '. '
							+ si.invoice_name()
							+ ' | '
							+ si.invoice_no()
							+ ' | '
							+ (si.invoice_inv_id() || '')
							+ ' | '
							+ (
								si.invoice_approval()
									? 'APPROVED'
									: 'DECLINED'
							)
							+ ' | '
							+ (
								si.invoice_received()
									? 'Yes'
									: 'No'
							)
						)
					.map(s => m('p.pv1.ma0', s ))
				])


				,elements.list([
					m('label.control-label.mb1', 'Payment Type | Recipient | Approval By')
				])
				,elements.list([
					selectedInvoices()
						.map( (si, sindex) =>
							sindex + 1
							+ '. '
							+ si.invoice_type()
							+ ' | '
							+ (si.supplier_name() || `Unassigned`)
							+ ' | '
							+ (
								users().find((u) => u.user_id() == si.invoice_approver() )
								|| nullUser
							).usersFullName
						)
						.map(s => m('p.pv1.ma0', s ))
				])

				/* eslint-disable */
				,!invoicesapproved()
					? elements.list([
						m('label.control-label.mb1', 'Approval By')
						, m.component(
							Pencil
							,() => selectedInvoices().length == 1
								? (
									users().find((u) => u.user_id() == selectedInvoices()[0].invoice_approver() )
									|| nullUser
								).usersFullName
								: ''
							,() =>
								autocomplete.strict(
									users
									, (v) => {

										if (v){

											setOptions(
												v.user_id()
												, 'invoice_approver'
												, selectedInvoices()
											)

											setOptions(
												false
												, 'invoice_approval'
												, selectedInvoices()
											)

										}

										const firstUser = users().find((u) =>
											u.user_id()
											== selectedInvoices()[0].invoice_approver()
										)

										return v
											? v.usersFullName
											: firstUser
												? firstUser.usersFullName
												: null

									}
									,'usersFullName'
									, () => ({
										style: {
											height: '3em'
										}
									})
								)
						)
					])
					: null


				,elements.list([
					m('label.control-label.mb1', 'Invoice Date')
					,selectedInvoices().some((i) => i.invoice_approval())
					? elements.alert(`warning`, `The invoice date on approved invoices cannot be changed`)
					: m.component(
						Pencil
						,() =>
							selectedInvoices()[0].invoice_date()
							? moment( selectedInvoices()[0].invoice_date() || 0 ).format("ll")
							: ''
							,() =>
								elements.dateInput(
									(a) =>
										setOptions(
											a
											, 'invoice_date'
											, selectedInvoices()
										)
									,{
										title: "Date of invoice creation "
										,mindate: new Date().toISOString().slice(0,10)
										,disabled:
											!calculated()
											|| savingProp()
											|| selectedInvoices().some((i) => i.invoice_approval())
									}
								)
					)
				])

				,elements.list([
					m('label.control-label.mb1', 'Due Date')
					,m.component(
						Pencil
						,() =>
							selectedInvoices()[0].invoice_due_date()
							? moment( selectedInvoices()[0].invoice_due_date() || 0 ).format("ll")
							: ''
							,() =>
								elements.dateInput(
									(a) =>
										setOptions(
											a
											, 'invoice_due_date'
											, selectedInvoices()
										)
									,{
										title: "Payment due date "
										,mindate: new Date().toISOString().slice(0,10)
										,disabled: !calculated() || savingProp()
									}
								)
					)
				])

				,elements.list([
					m('label.control-label.mb1', 'Received Date')
					,m.component(
						Pencil
						,() =>
							selectedInvoices()[0].invoice_received_date()
							? moment( selectedInvoices()[0].invoice_received_date() ).format("ll")
							: ''
							,() =>

								elements.dateCheckbox({
									label: ''
									,date: {
										title: 'Received payment date'
										, prop:
											(a) => {
												if( a ){
													setOptions(
														true
														, 'invoice_received'
														, selectedInvoices()
													)

													setOptions(
														a
														, 'invoice_received_date'
														, selectedInvoices()
													)
												}

												return selectedInvoices()[0].invoice_received_date()
											}
										, attrs: {
											title: "Received payment date "
											,maxdate: new Date().toISOString().slice(0,10)
											,disabled: !calculated() || savingProp()
										}
									}
									,attrs:{
										title: 'Set ledger to be receipted'
										, prop: selectedInvoices()[0].invoice_received
										, checked: selectedInvoices()[0].invoice_received_date()
										, attrs: {
											onchange:
												m.withAttr('checked', function(e){
													if( e ){

														setOptions(
															true
															, 'receipting'
															, selectedInvoices()
														)

														setOptions(
															true
															, 'invoice_received'
															, selectedInvoices()
														)

														setOptions(
															new Date().getTime()
															, 'invoice_received_date'
															, selectedInvoices()
														)

														receiptedInvoices(
															focussedInvoices()
																.filter((i) =>
																	i.invoice_received()
																	&& i.created
																)
														)
													}
													else {

														setOptions(
															false
															, 'receipting'
															, selectedInvoices()
														)

														setOptions(
															false
															, 'invoice_received'
															, selectedInvoices()
														)

														setOptions(
															null
															, 'invoice_received_date'
															, selectedInvoices()
															, true
														)

														receiptedInvoices(
															focussedInvoices()
																.filter((i) =>
																	i.invoice_received()
																	&& i.created
																)
														)
													}
												})
										}
									}
								})
					)
				])

				,!invoicesReceipted()
					? elements.list([
						m('label.control-label.mb1', 'Recipient')
						,m.component(
							Pencil
							,() => selectedInvoices().length == 1
									? selectedInvoices()[0].supplier_name()
									: ''
							,() =>
								autocomplete.strict(
									SupplierEntities
									,R.when( Boolean, function(v){

										setOptions(
											v.contractrecognitionname
											, 'supplier_name'
											, selectedInvoices()
										)

										setOptions(
											v.contractrecognitionid
											, 'supplier_id'
											, selectedInvoices()
										)
									})
									,'contractrecognitionname'
									,() => ({
										disabled: !SupplierEntities() || SupplierEntities().length == 0
									})
								)
						)
					])
					: elements.list([
						m('label.control-label.mb1', `Unable to alter recipients of receipted invoices`)
					])


					/* eslint-enable */
					,elements.list([
						m('label.control-label.mb1', 'Description')
						,elements.textInput(
							(v) => setOptions(v, 'invoice_description', selectedInvoices())
							, {}
						)
					])


					/* eslint-disable */
					,elements.list([
						m('label.control-label.mb1', 'Project')
						,m.component(
							Pencil
							,() => setOptions(null, 'project_name', selectedInvoices())
							,() =>
								autocomplete.strict(
									projects
									,R.when( Boolean, function(v){

										setOptions(
											v.contractrecognitionname
											, 'project_name'
											, selectedInvoices()
										)

										setOptions(
											v.project_id()
											, 'project_id'
											, selectedInvoices()
										)

									})
									,'contractrecognitionname'
									,() => ({
										disabled: projects().length == 0
									})
								)
						)
					])
					/* eslint-enable */

					,!invoicesapproved()
					? [
						elements.list([
							m('button.btn.btn-secondary',
								{
									style: {
										backgroundColor: 'transparent'
										,border: 'solid 1px #3380c2'
										,position: 'relative'
										,top: '-0.1em'
										,height: '2.9em'
										,width: '5em'
										,color: '#434aa3'
										,textDecoration: 'underline'
									}
									,disabled:
										R.none(
											s => R.pathSatisfies(
												x => x != null
												[s.project_id(), s.contractrecognitionid],
												multiplierIndex()
											)
											, selectedInvoices()
										)
									,onclick: () => {
										selectedInvoices().forEach((s) => {

											const rateMulitplierName =

												s.crews_discipline_rates_id
												? 'disciplines_contractors_crews_contract_multiplier'
												: 'discipline_contract_multiplier'

											const rateMultiplier =
												!s.project_id()
												|| R.pathSatisfies(
													x => x != null
													[s.project_id(), s.contractrecognitionid],
													multiplierIndex()
												)
													? 1
													: multiplierIndex()
													[s.project_id()]
													[s.contractrecognitionid]
													[rateMulitplierName]()

											s.invoice_rate(
												s.invoice_rate()
												* ( rateMultiplier || 1 )
											)
											s.invoice_service_rate(
												s.invoice_service_rate()
												* ( rateMultiplier || 1 )
											)
											s.invoice_depreciation_rate(
												s.invoice_depreciation_rate()
												* ( rateMultiplier || 1 )
											)
											s.invoice_appreciation_rate(
												s.invoice_appreciation_rate()
												* ( rateMultiplier || 1 )
											)
											s.invoice_damages_rate(
												s.invoice_damages_rate()
												* ( rateMultiplier || 1 )
											)
										})

									}
									, title: "Apply Project and Work Multiplers to these invoices"
								}
								,'Apply'
							)
							,m(`label`, 'Project Rate Multipliers')
						])


						,elements.list([
							m('label.control-label.mb1', 'Working Rate | Amount')
							,m.component(
								Pencil
								,() => setOptions(null, 'invoice_rate', selectedInvoices())
								,() =>
									m(
										NumberInput
										,R.merge(
											data
											,{
												errors
												,errorLabel: 'Working Rate'
											}
										)
										,{
											prop: (v) => setOptions(v, 'invoice_rate', selectedInvoices())
											,attrs: {
												step: 0.01

											}
										}
									)
							)
							,noamountedits
							? R.head(selectedInvoices())['invoice_amount']()
							: m.component(
								Pencil
								,() => setOptions(null, 'invoice_amount', selectedInvoices())
								,() =>
									m(
										NumberInput
										,R.merge(
											data
											,{
												errors
												,errorLabel: 'Working Amount'
											}
										)
										,{
											prop: (v) => setOptions(v, 'invoice_amount', selectedInvoices())
											,attrs: {
												step: 0.01

											}
										}
									)
							)
						])

						,hiddenInvoiceData()['invoice_service_rate']
						? null
						: elements.list([
							m('label.control-label.mb1', 'Service Rate | Amount')
							,m.component(
								Pencil
								,() => setOptions(null, 'invoice_service_rate', selectedInvoices())
								,() =>
									m(
										NumberInput
										,R.merge(
											data
											,{
												errors
												,errorLabel: 'Service Rate'
											}
										)
										,{
											prop: (v) => setOptions(v, 'invoice_service_rate', selectedInvoices())
											,attrs: {
												step: 0.01

											}
										}
									)
							)
							,noamountedits
							? R.head(selectedInvoices())['invoice_service_amount']()
							: m.component(
								Pencil
								,() => setOptions(null, 'invoice_service_amount', selectedInvoices())
								,() =>
									m(
										NumberInput
										,R.merge(
											data
											,{
												errors
												,errorLabel: 'Service Amount'
											}
										)
										,{
											prop: (v) => setOptions(v, 'invoice_service_amount', selectedInvoices())
											,attrs: {
												step: 0.01

											}
										}
									)
							)
						])

						, hiddenInvoiceData()['invoice_depreciation_rate']
						? null
						: elements.list([
							m('label.control-label.mb1', 'Depreciation Rate | Amount')
							,m.component(
								Pencil
								,() => setOptions(null, 'invoice_depreciation_rate', selectedInvoices())
								,() =>
									m(
										NumberInput
										,R.merge(
											data
											,{
												errors
												,errorLabel: 'Depreciation Rate'
											}
										)
										,{
											prop: (v) => setOptions(v, 'invoice_depreciation_rate', selectedInvoices())
											,attrs: {
												step: 0.01

											}
										}
									)
							)
							,noamountedits
							? R.head(selectedInvoices())['invoice_depreciation_amount']()
							: m.component(
								Pencil
								,() => setOptions(null, 'invoice_depreciation_amount', selectedInvoices())
								,() =>
									m(
										NumberInput
										,R.merge(
											data
											,{
												errors
												,errorLabel: 'Depreciation Amount'
											}
										)
										,{
											prop: (v) => setOptions(v, 'invoice_depreciation_amount', selectedInvoices())
											,attrs: {
												step: 0.01

											}
										}
									)
							)
						])

						, hiddenInvoiceData()['invoice_appreciation_rate']
						? null
						: elements.list([
							m('label.control-label.mb1', 'Appreciation Rate | Amount')
							,m.component(
								Pencil
								,() => setOptions(null, 'invoice_appreciation_rate', selectedInvoices())
								,() =>
									m(
										NumberInput
										,R.merge(
											data
											,{
												errors
												,errorLabel: 'Appreciation Rate'
											}
										)
										,{
											prop: (v) => setOptions(v, 'invoice_appreciation_rate', selectedInvoices())
											,attrs: {
												step: 0.01

											}
										}
									)
							)
							,noamountedits
							? R.head(selectedInvoices())['invoice_appreciation_amount']()
							: m.component(
								Pencil
								,() => setOptions(null, 'invoice_appreciation_amount', selectedInvoices())
								,() =>
									m(
										NumberInput
										,R.merge(
											data
											,{
												errors
												,errorLabel: 'Appreciation Amount'
											}
										)
										,{
											prop: (v) => setOptions(v, 'invoice_appreciation_amount', selectedInvoices())
											,attrs: {
												step: 0.01

											}
										}
									)
							)
						])

						, hiddenInvoiceData()['invoice_damages_rate']
						? null
						: elements.list([
							m('label.control-label.mb1', 'Damages Rate | Amount')
							,m.component(
								Pencil
								,() => setOptions(null, 'invoice_damages_rate', selectedInvoices())
								,() =>
									m(
										NumberInput
										,R.merge(
											data
											,{
												errors
												,errorLabel: 'Damages Rate'
											}
										)
										,{
											prop: (v) => setOptions(v, 'invoice_damages_rate', selectedInvoices())
											,attrs: {
												step: 0.01

											}
										}
									)
							)
							,noamountedits
							? R.head(selectedInvoices())['invoice_damages_amount']()
							: m.component(
								Pencil
								,() => setOptions(null, 'invoice_damages_amount', selectedInvoices())
								,() =>
									m(
										NumberInput
										,R.merge(
											data
											,{
												errors
												,errorLabel: 'Damages Amount'
											}
										)
										,{
											prop: (v) => setOptions(v, 'invoice_damages_amount', selectedInvoices())
											,attrs: {
												step: 0.01

											}
										}
									)
							)
						])
					]
					: elements.list([
						m('label.control-label.mb1', `Unable to alter approved invoices`)
					])

				,m('label.control-label.mb1', 'Client Reference')
				,elements.list([
					m.component(
						Pencil
						,() =>
							selectedInvoices()
							.map((si, sindex) =>
								sindex + 1
								+ '. '
								+ si.invoice_items_client_reference()
							)
							.map(s => m('p.pv1.ma0', s))
						,() =>
							elements.textInput(
								(v) => setOptions(v, 'invoice_items_client_reference', selectedInvoices())
								,{
									title: "Reference name for the clients item"

								}
							)
					)
				])

				,m('label.control-label.mb1', 'Payment Reference')
				,elements.list([
					m.component(
						Pencil
						,() =>
							selectedInvoices()
							.map((si, sindex) =>
								sindex + 1
								+ '. '
								+ si.invoice_client_reference()
							)
							.map(s => m('p.pv1.ma0', s))
						,() =>
							elements.textInput(
								(v) => setOptions(v, 'invoice_client_reference', selectedInvoices())
								,{
									title: "Payment reference from the client"

								}
							)
					)
				])

				,invoicesReceipted()
					? elements.list([
						m('button.btn.btn-primary', {
							disabled: false
							,onclick: () => {
								createReversals()
							}
						}, 'Create Reversals')
					])
					: null

				,m(HarthUppy, {
					getFields: () => uploadmetadata({})
					, data
					, listOnly: selectedInvoices().length > 1
				})

			]
		}


		return activeItem().contract_items_id
			|| route_view_type() != 'Make Ledgers' && selectedInvoices().length >= 1
			|| Object.keys(checkMultipleContracts()).length > 0
			? [
				elements.list(
					[
						route_view_type() == 'All Ledgers'
						? elements.action(
							'Save'
							, sendInvoices
							, savingProp
							, 'primary'
							, () => invoiceIdenticals() || saveDisabledInvoices(true)
						)
						: route_view_type() != 'All Items'
						? elements.action(
							'Save'
							, sendContracts
							, savingProp
							, 'primary'
							, () => saveDisabled(true) || checkContractItem(true) || contractIdentical() || !calculated()
						)
						: m("button.btn.btn-"
							, {
								onclick: () => {
									checkMultipleContracts({})
									allSelected(false)
								}
							}
							, 'Done'
						)
					]
					.concat(

						Object.keys(checkMultipleContracts()).length == 0
						? elements.undoDiscard({
							discard:
								Object.keys(checkMultipleContracts()).length > 0
								|| !active.contract_items().find((i) => i.key == activeItem().key)
								&& activeItem().contract_items_name
								? null
								: {
									label: 'Discard'
									,attrs: {
										onclick: () => {
											activeItem().contract_items_name
											? deletetItem()
											: deletetInvoices()
										}
									}
								}

							,doneUndo: {
								label:
									(
										Object.keys(checkMultipleContracts()).length > 0
										? false
										: activeItem().contract_items_name && !activeItem().invoice_id
										? contractIdentical() && activeItem().contract_items_id()
										: invoiceIdenticals() || saveDisabledInvoices(true)
									)
										? 'Done'
										: ''
								,attrs: {
									onclick: () => {

										checkMultiple({})
										activeItem({})
										!donerContract()
											? checkMultipleContracts({})
											: null
										donerContract(null)

										route_contract_id()
										? contract(
											focussedContracts().find(
												(c) => c.contract_id == route_contract_id()
											)
										)
										: null

										errors({})
										deleteInvoiceItems([])
										deleteContractItems([])
										selectedInvoices().length >= 1
											? focussedInvoices(cloneInvoice(originalInvoices()))
											: null
										selectedInvoices([])
										allSelected(false)
									}
									,title: `Discard all changes made to these tools since last saving them`
									,style: {
										transition: '0.5s'
										,width: '5em'
									}
								}
							}
						})
						: route_view_type() == "All Contracts"
						? elements.confirmDelete(
							deleteContract
							,savingProp
							,() =>
								Object.keys(checkMultipleContracts()).length == 0
								|| checkMultipleContracts()[null]
							,deleteStateProp
						)
						: null
					)
				)

				,activeItem().contract_items_name
				&& !activeItem().invoice_id
					? contractItemsDetails()
					: selectedInvoices().length >= 1
						? invoiceItemsDetails()
						: Object.keys(checkMultipleContracts()).length > 0
							? contractDetails()
							: []
			]
			: []
	}

	let detailsPaneOpen = () =>

		activeItem().contract_items_name
		&& Object.keys(checkMultiple()).length >= 1
		&& route_view_type() != 'Make Ledgers'
		&& route_view_type() != 'All Ledgers'
		&& editContractPermissions()

		|| 	selectedInvoices().length >= 1
		&& route_view_type() != 'Make Ledgers'
		&& editInvoicePermissions()

		|| Object.keys(checkMultipleContracts()).length >= 1



	function ListAllContractEntities(){

		const gotfilters =
			getfilters(null, "Cross Contracts")

		const processedList =
			ListProcessing(
				viewWindowList()
				, filterFunction.allContractItemFilters.filters()
				, ''
				,filteredOdinItemOptions
			)

		const odinItemData =
			(e) =>
				({
					'Name': e.contractrecognitionname
					,'Description': e.contractrecognitiondescription
					,'Contracts': e.contractList.join(', ')
					,'Ledgers': invoiceDataLoading() || metaDataIndexing()
						? ` ... `
						: m('a', {
								href: '/data/contracts/contractitems/forecastdateA/'
									+ forecastdateA()
									+ '/forecastdateB/'
									+ forecastdateB()
									+ '/ledger/'
									+ e.contractrecognitionid

								, oncreate: m.route.link
								, title: "View Invoices"
								, disabled: e.invoicecount == 0 || invoiceDataLoading()
							}
							, e.invoicecount || ''
						)
					,'Profits': !calculated()
						? ` ... `
						: makeMoney(e.currentProfits)

					,'Final Billing Date': e.lastCalculatedWorkDate
				})

		const getActivateDeleteButton =
			(c) => {
				const keyref = route_view_type() == 'All Items'
					? 'key'
					: 'contract_id'

				return editContractPermissions()
				? {
					onchange: m.withAttr(
								'checked',
								(checked) => {
									if ( checked) {
										checkMultipleContracts()[c[keyref]] = c
										checkMultipleContracts()[c[keyref]]['created'] = true
									} else {
										delete checkMultipleContracts()[c[keyref]]
									}
								}
							)
					,checked: checkMultipleContracts()[c[keyref]] || false
					,disabled: !calculated()
					,type: 'checkbox'
				}
				: {style: {opacity:0} , disabled: true}
			}

		const largeOdinItemTable = () =>
			scrollableTable.advanced({
				scoped: data.scoped
				,resize$: data.resize$
				,alwaysopen: true
				,rows:
					processedList
					.map((e, eindex) => ({
						key: e.key
						,selection: getActivateDeleteButton(e)
						,header:
							eindex != 0
							? ''
							: m('.header'
								+ css`
									font-weight: bold;
									display: flex;
									justify-content: space-between;
									align-items: center;
									padding-bottom: 0.5em;
								`
								,m('span'
									+ css`
										font-weight: bold;
									`
									,viewWindow().join(', ')
								)
								,m('.data'
									+ css`
										display: grid;
										grid-template-rows: 0.25fr 0.5fr;
										justify-content: space-between;
									`
									,[

									]
								)
								,m('span'
									+ css`
										font-weight: bold;
									`
									,''
								)
							)
						,layouts: {
							expanded: ({ x, headers, i }) =>
								m('.expanded'
									+ css`
										display: grid;
										justify-content: space-between;
										grid-template-columns: 1fr;
									`
									, m('.data'
										+ css(
										`
											display: grid;
											justify-content: space-between;
											grid-template-columns: 1.25fr 1.75fr 1.25fr 0.5fr 0.5fr 1fr
											overflow: hidden;
											gap: 1em;
											align-items: start;
											align-content: start;
										`)
										, headers
											.map(
												k => [
													i == 0
													? m('.data'
														+ css`
															display: grid;
															grid-template-rows: 0.25fr 0.5fr;
															overflow: hidden;
														`
														,[m('label.control-label', k), x[k]]
													)
													: m('.data'
														+ css`
															display: grid;
															overflow: hidden;
														`
														,x[k]
													)
												]
											)
									)
								)
						}
						,data: odinItemData(e)
					}))
			})

		const smallOdinItemTable = () =>
			scrollableTable.advanced({
				scoped: data.scoped
				,resize$: data.resize$
				,alwaysopen: true
				,rows:
					processedList
					.map((e) => ({
						key: e.key
						,header:
							m('span'
								+ css`
									font-weight: bold;
									display: flex;
									justify-content: space-between;
									align-items: start;
									padding-bottom: 0.5em;
								`
								,m('input'
									+ css`
										width: 25px;
										height: 25px;
										margin: 0px;
									`
									.$media('(hover: hover)',
										css`
											width: 20px;
											height: 20px;
										`
									)
									,getActivateDeleteButton(e)
								)
								,m('.data'
									+ css`
										display: grid;
										grid-template-columns: 0.25fr 0.25fr 0.25fr;
										justify-content: space-between;
										align-items: start;
										padding-right: 0.25em
									`
									,[
										'Ledgers'
										,m('a'
											,{
												href: '/data/contracts/contractitems/forecastdateA/'
													+ forecastdateA()
													+ '/forecastdateB/'
													+ forecastdateB()
													+ '/ledger/'
													+ e.contractrecognitionid

												, oncreate: m.route.link
												, title: "View Invoices"
												, disabled: e.invoicecount == 0 || invoiceDataLoading()
											}
											, e.invoicecount || ''
										)
										,makeMoney(e.currentProfits)
									]
									.map((k) =>
										m('.data'
											+ css`
												display: grid;
												padding-right: 1em
											`
											,k
										)
									)
								)
							)
						,layouts: {
							expanded: ({ x }) =>
								m('.expanded'
									+ css`
										display: grid;
										justify-content: space-between;
										grid-template-columns: 1fr;
									`
									,m('.data'
										+ css`
											display: grid;
											justify-content: space-between;
											grid-template-columns: 0.25fr 0.5fr 0.25fr;
											padding-bottom: 2.25em;
										`
										,[
											'Name'
											,'Description'
											,'Final Billing Date'
										]
										.map((k) =>
											m('.data'
												+ css`
													display: grid;
													grid-template-rows: 0.25fr 0.25fr;
													padding-right: 0.25em
												`
												,[
													m('label.control-label.f4', k)
													, k != 'Description'
														? x[k]
														: x[k] == e.contractrecognitionname
															? x[k]
															: [x[k], e.contractrecognitionname].filter(R.identity).join(' - ')

												]
											)
										)
									)


									, m('.data'
										+ css`
											display: grid;
											justify-content: space-between;
											grid-template-columns: 1fr;
											padding-bottom: 1em;
										`
										,[
											'Contracts'
										]
										.map((k) =>
											m('.data'
												+ css`
													display: grid;
													grid-template-rows: 0.25fr 0.25fr;
													padding-right: 0.5em;
												`
												,[m('label.control-label.f4', k), x[k]]
											)
										)
									)
								)
						}
						,data: odinItemData(e)
					}))
			})


		const OdinItemTable = () =>
			m(Responsive
				,
				{
					breakpoints: {
						mobile: 0
						,desktop: 900
						,tablet: 700
						,hd: 1800
					}
				}
				,({ hd, desktopOnly, mobileOnly, tabletOnly }) => [
					hd( largeOdinItemTable )
					,desktopOnly( largeOdinItemTable  )
					,mobileOnly( smallOdinItemTable )
					,tabletOnly( smallOdinItemTable )
				]
			)

		return [

			m('br')
			,!calculated()
				? elements.alert('warning', `Calculating the monetary value of ` + viewOptions().join(', ')  + ` during focus periods.`)				: null

			,elements.list([gotfilters, contractItemsEditAll()])

			,elements.selectbuttons(
				viewOptions().map( (a) => a )
				,(a) => {
					if(a){
						let vIndex = viewWindow().findIndex((v) => v == a )
						let newList = vIndex >= 0
							? viewWindow().filter((s) => s != a )
							: viewWindow().concat(a)

						if( newList.length ){
							reMapdateChange(false)
						}

						viewWindow(newList)
					}
				}
				,{ title: `Show representative items`}
				,( notReq, a ) => viewWindow().find((v) => v == a )
				, R.F
				, () => !calculated()
			)

			,elements.alert(
				'warning'
				,
				`Selecting multiple categories might cause an overlap in summations.  `
				+ `As an example, the expenditures of a project will include the resource expenditure used for that project.
				The expenditures on a resource will include all expenditures used to complete work across all assigned projects.
				Hence, if projects and resources are selected togther the expenditure calculation will be duplicated as the
				expenditure of one will be a subset of the other.`
			)


			,metaDataIndexing()
				? elements.centeredSpinner()
				: Object.keys(indexedContractEntities()).length > 0
				? OdinItemTable()
				: elements.alert(`info`, `Create projects, resources, tools and much more around Odin to track their financial gains`)
		]
	}


	function ContractSummaryList(){
		const gotfilters =
			getfilters(null, "Contract")

		const processedList =
			ListProcessing(
				focussedContracts()
				, filterFunction.contractFilters.filters()
				, ''
			)

		const contractData =
			(c) =>
				({
					'Contract Name':
						m('a'
							, {
								href: '/data/contracts/contracts/edit/'
									+ c.contract_id
									+ '/forecastdateA/'
									+ forecastdateA()
									+ '/forecastdateB/'
									+ forecastdateB()

								, oncreate: m.route.link
								, title: "Edit a contract"
								, disabled:
									c.contract_name
									== 'Disconnected Ledgers'
									|| !calculated()
							}
							, c.contract_name
						)

					,'Description':
						m(
							Number(c.badcontractcount)
								? '.dark-red.fw6'
								: ''
							,c.contract_description
						)

					,'Items' : c.contract_items.length
					,'Payment Term': c.contract_payment_term
					,'Ledgers': invoiceDataLoading() || metaDataIndexing()
						? ` ... `
						: m('a', {
								href: '/data/contracts/contracts/view/forecastdateA/'
									+ forecastdateA()
									+ '/forecastdateB/'
									+ forecastdateB()
									+ '/ledger/'
									+ c.contract_id

								, oncreate: m.route.link
								, title: "View Invoices"
								, disabled: c.invoicecount == 0 || invoiceDataLoading()
							}
							, c.invoicecount || ''
						)

					,'Start Date': c.contract_name
					== 'Disconnected Ledgers'
						? ' - '
						: moment(c.contract_start_date).format("ll")

					,'End Date': c.contract_name
					== 'Disconnected Ledgers'
						? ' - '
						: moment(c.contract_end_date).format("ll")

					,'':
						m('a', {
								href: '/data/contracts/contracts/clone/'
									+ c.contract_id
									+ '/forecastdateA/'
									+ forecastdateA()
									+ '/forecastdateB/'
									+ forecastdateB()

								, oncreate: m.route.link
								, title: "Clone an existing contract"
								, disabled:
									c.contract_name
									== 'Disconnected Ledgers'
							}
							, 'Clone'
						)

					,'Profits': !calculated()
						? ` ... `
						: makeMoney(c.currentProfits)
				})

		const getActivateDeleteButton =
			(c) => {

				const keyref = route_view_type() == 'All Items'
					? 'key'
					: 'contract_id'

				return editContractPermissions()
				? {
					onchange: m.withAttr(
								'checked',
								(checked) => {
									if ( checked) {
										checkMultipleContracts()[c[keyref]] = c
										checkMultipleContracts()[c[keyref]]['created'] = true
									} else {
										delete checkMultipleContracts()[c[keyref]]
									}
								}
							)
					,checked: checkMultipleContracts()[c[keyref]] || false
					,disabled: !calculated()
					,type: 'checkbox'
				}
				: {style: {opacity:0} , disabled: true}
			}

		const largeContractTable = () =>
			scrollableTable.advanced({
				scoped: data.scoped
				,resize$: data.resize$
				,alwaysopen: true
				,rows:
					processedList
					.map((c, cindex) => ({
						key: c.key
						,selection: getActivateDeleteButton(c)
						,header:
							cindex != 0
							? ''
							: m('.header'
								+ css`
									font-weight: bold;
									display: flex;
									justify-content: space-between;
									align-items: center;
									padding-bottom: 0.5em;
								`
								,m('span'
									+ css`
										font-weight: bold;
									`
									,''
								)
								,m('.data'
									+ css`
										display: grid;
										grid-template-rows: 0.25fr 0.5fr;
										overflow: hidden;
									`
									,m(`a.pl2`
										+ css`
											backgroundColor: transparent;
											transition: 0.2s;
											opacity: 1;
											color: #434aa3;
											border: none;
										`
										.$hover(`
											opacity: 0.8;
										`)
										.$active(`
											opacity: 0.5;
										`)
										,{
											href: '/data/contracts/contracts/create/forecastdateA/'
												+ forecastdateA()
												+ '/forecastdateB/'
												+ forecastdateB()

											, oncreate: m.route.link
											, disabled: !editContractPermissions()
											, title: "Create a new contract"
										}
										, 'Create a New Contract'
									)
								)
								,m('span'
									+ css`
										font-weight: bold;
									`
									,''
								)
							)
						,layouts: {
							expanded: ({ x, headers, i }) =>
								m('.expanded'
									+ css`
										display: grid;
										justify-content: space-between;
										grid-template-columns: 1fr;
									`
									, m('.data'
										+ css(
										`
											display: grid;
											justify-content: space-between;
											grid-template-columns: 0.75fr 1.75fr 0.5fr 0.5fr 0.5fr 1fr 1fr 0.75fr 0.75fr
											overflow: hidden;
											gap: 1em;
											align-items: start;
											align-content: start;
										`)
										, headers
											.map(
												k => [
													i == 0
													? m('.data'
														+ css`
															display: grid;
															grid-template-rows: 0.25fr 0.5fr;
															overflow: hidden;
														`
														,[m('label.control-label', k), x[k]]
													)
													: m('.data'
														+ css`
															display: grid;
															overflow: hidden;
														`
														,x[k]
													)
												]
											)
									)
								)
						}
						,data: contractData(c)
					}))
			})

		const smallContractTable = () =>
			scrollableTable.advanced({
				scoped: data.scoped
				,resize$: data.resize$
				,alwaysopen: true
				,rows:
					processedList
					.map((c) => ({
						key: c.key
						,header:
							m('span'
								+ css`
									font-weight: bold;
									display: flex;
									justify-content: space-between;
									align-items: center;
									padding-bottom: 0.5em;
								`
								,m('input'
									+ css`
										width: 25px;
										height: 25px;
										margin: 0px;
									`
									.$media('(hover: hover)',
										css`
											width: 20px;
											height: 20px;
										`
									)
									,getActivateDeleteButton(c)
								)
								,m('a', {
										href: '/data/contracts/contracts/clone/'
											+ c.contract_id
											+ '/forecastdateA/'
											+ forecastdateA()
											+ '/forecastdateB/'
											+ forecastdateB()

										, oncreate: m.route.link
										, title: "Clone an existing contract"
										, disabled:
											c.contract_name
											== 'Disconnected Ledgers'
									}
									, 'Clone'
								)
								,m('.data'
									+ css`
										display: grid;
										grid-template-columns: 0.25fr 0.25fr 0.25fr;
										justify-content: space-between;
										align-items: start;
										padding-right: 0.25em
									`
									,[
										'Ledgers'
										,m('a', {
												href: '/data/contracts/contracts/view/forecastdateA/'
													+ forecastdateA()
													+ '/forecastdateB/'
													+ forecastdateB()
													+ '/ledger/'
													+ c.contract_id

												, oncreate: m.route.link
												, title: "View Invoices"
												, disabled: c.invoicecount == 0 || invoiceDataLoading()
											}
											, c.invoicecount || ''
										)
										,makeMoney(c.currentProfits)
									]
									.map((k) =>
										m('.data'
											+ css`
												display: grid;
												padding-right: 1em
											`
											,k
										)
									)
								)
							)
						,layouts: {
							expanded: ({ x }) =>
								m('.expanded'
									+ css`
										display: grid;
										justify-content: space-between;
										grid-template-columns: 1fr;
									`
									,m('.data'
										+ css`
											display: grid;
											justify-content: space-between;
											grid-template-columns: 0.25fr 0.75fr;
											padding-bottom: 2.25em;
										`
										,['Contract Name', 'Description']
										.map((k) =>
											m('.data'
												+ css`
													display: grid;
													grid-template-rows: 0.25fr 0.25fr;
													padding-right: 0.25em
												`
												,[
													m('label.control-label.f4', k)
													, x[k]
												]
											)
										)
									)


									, m('.data'
										+ css`
											display: grid;
											justify-content: space-between;
											grid-template-columns: 0.25fr 0.25fr 0.25fr 0.25fr;
											padding-bottom: 1em;
										`
										,['Items', 'Payment Term', 'Start Date', 'End Date']
										.map((k) =>
											m('.data'
												+ css`
													display: grid;
													grid-template-rows: 0.25fr 0.25fr;
													padding-right: 0.5em;
												`
												,[m('label.control-label.f4', k), x[k]]
											)
										)
									)

								)

						}
						,data: contractData(c)
					}))
			})


		const contractTable = () =>
			m(Responsive
				,
				{
					breakpoints: {
						mobile: 0
						,desktop: 900
						,tablet: 700
						,hd: 1800
					}
				}
				,({ hd, desktopOnly, mobileOnly, tabletOnly }) => [
					hd( largeContractTable )
					,desktopOnly( largeContractTable  )
					,mobileOnly( smallContractTable )
					,tabletOnly( smallContractTable )
				]
			)

		return [


			m('br')

			,!calculated()
				? elements.alert('warning',`Calculating the monetary value of all contracts under the focused criteria`)
				: null

			,gotfilters
			,m('br')

			,loading()
				? elements.centeredSpinner()
				: focussedContracts().length > 0
					? contractTable()
					: elements.alert(`info`, `Record some contract information to track contract and payments through the business`)

		]
	}

	// https://harth-technologies.slack.com/archives/D06A569EV/p1578489392069100
	// eslint-disable-next-line no-unused-vars
	function invoiceEditAll(){
		return elements.checkbox({
			onchange: () => {

				allSelected() == true
					? allSelected(false)
					: allSelected(true)

				if ( allSelected() ) {

					(
						filteredInvoiceOptions().length
							? filteredInvoiceOptions()
							: focussedInvoices()
					)
					.forEach(
						(i, index) => {

							i.created = true
							index == 0 ? activeItem(i) : null

							!selectedInvoices().find((si) => si.key == i.key )
								? selectedInvoices().push(i)
								: null
						}
					)
					selectedInvoices(selectedInvoices())
				} else {
					selectedInvoices([])
					activeItem({})
				}
			}
			, checked: allSelected()
			, disabled: false
			, title: "Select all invoice items"
		})
	}

	function contractItemsEditAll(){
		return elements.checkbox({
			onchange: () => {

				allSelected() == true
					? allSelected(false)
					: allSelected(true)

				const refarray =
					route_view_type() == 'All Items'
						? filteredOdinItemOptions
						: filteredContractItemOptions

				const refcontainer =
					route_view_type() == 'All Items'
						? checkMultipleContracts
						: checkMultiple

				if ( allSelected() ) {
					(
						refarray().length
							? refarray()
							: active.contract_items()

					).forEach(
						(ci, index) => {
							!refcontainer()[ci.key]
								? refcontainer()[ci.key] = ci
								: null

							if(route_view_type() != 'All Items'){
								refcontainer()[ci.key]['created'] = true
								index == 0 ? activeItem(ci) : null
							}
						}
					)

				} else {
					refcontainer({})
					activeItem({})
				}
			}
			, checked: allSelected()
			, disabled:
				!calculated()
				|| !editContractPermissions() && !editInvoicePermissions()
			, title: "Select all contract items"
		})
	}

	const receiveInvoiceBox =
		(i) =>
			m.component(
				Pencil
				,() =>
					i.invoice_received_date()
					? moment( i.invoice_received_date() ).format("ll")
					: ''
					,() =>

						elements.dateCheckbox({
							label: ''
							,date: {
								title: 'Received payment date'
								, prop:
									(a) => {
										if( a ){
											setOptions(
												true
												, 'invoice_received'
												, [i]
											)

											setOptions(
												a
												, 'invoice_received_date'
												, [i]
											)
										}

										return i.invoice_received_date()
									}
								, attrs: {
									title: "Received payment date "
									,maxdate: new Date().toISOString().slice(0,10)
									,disabled: !calculated() || savingProp()
								}
							}
							,attrs:{
								title: 'Set ledger to be receipted'
								, prop: i.invoice_received
								, checked: i.invoice_received_date()
								, attrs: {
									onchange:
										m.withAttr('checked', function(e){
											if( e ){

												i.receipting = true
												i.created = true
												i.invoice_received(true)
												receiptedInvoices(
													focussedInvoices()
														.filter((i) =>
															i.invoice_received()
															&& i.created
														)
												)
												i.invoice_received_date(new Date().getTime())
											}
											else {

												i.receipting = false
												i.created = true
												i.invoice_received(false)
												receiptedInvoices(
													focussedInvoices()
														.filter((i) =>
															i.invoice_received()
															&& i.created
														)
												)
												i.invoice_received_date(null)
											}
										})
								}
							}
						})
			)

	function ListInvoicesPOs(){
		const gotfilters =
			getfilters(null, "Invoices")

		const processedList =
			ListProcessing(
				focussedInvoices()
				, filterFunction.invoiceFilters.filters()
				, ''
				, filteredInvoiceOptions
			)

		const applyProjectMultuplier =
			(i) => {
				const rateMulitplierName =
					i.crews_discipline_rates_id
					? 'disciplines_contractors_crews_contract_multiplier'
					: 'discipline_contract_multiplier'

				const rateMultiplier =
					!i.project_id()
						? 1
						: multiplierIndex()
						[i.project_id()]
						[i.contractrecognitionid]
						[rateMulitplierName]()

				i.invoice_rate(
					i.invoice_rate()
					* ( rateMultiplier || 1 )
				)
				i.invoice_service_rate(
					i.invoice_service_rate()
					* ( rateMultiplier || 1 )
				)
				i.invoice_depreciation_rate(
					i.invoice_depreciation_rate()
					* ( rateMultiplier || 1 )
				)
				i.invoice_appreciation_rate(
					i.invoice_appreciation_rate()
					* ( rateMultiplier || 1 )
				)
				i.invoice_damages_rate(
					i.invoice_damages_rate()
					* ( rateMultiplier || 1 )
				)
			}


		const invoiceData =
			(i) =>
				R.reduce(
					(o, i) => {
						hiddenInvoiceData()[i]
						? delete o[
							i == 'invoice_service_rate'
							? 'Service Value'
							: i == 'invoice_depreciation_rate'
							? 'Depreciation Value'
							: i == 'invoice_appreciation_rate'
							? 'Appreciation Value'
							: i == 'invoice_damages_rate'
							? 'Damages Value'
							: i == 'invoice_rate'
							? 'Working Value'
							: i == 'invoice_type'
							? 'Payment Type'
							: null
						]
						: null
						return o
					}
					,{
					'Date':
						!editInvoicePermissions() || i.invoice_approval()
						? moment( i.invoice_date() ).format("ll")
						: m.component(
							Pencil
							,() => i.invoice_date()
								? moment( i.invoice_date() ).format("ll")
								: ''
							,() =>
								elements.dateInput(
									(a) => setOptions(a, 'invoice_date', [i])
									,{
										title: "Created invoice date "
										,mindate: new Date().toISOString().slice(0,10)
										,disabled: !calculated() || savingProp()
									}
								)
						)

					,'Due Date':
						!editInvoicePermissions()
						? i.invoice_due_date()
							? moment( i.invoice_due_date() ).format("ll")
							: ''
						: m.component(
							Pencil
							,() => i.invoice_due_date()
								? moment( i.invoice_due_date() ).format("ll")
								: ''
							,() =>
								elements.dateInput(
									(a) => setOptions(a, 'invoice_due_date', [i])
									,{
										title: "Payment due date "
										,mindate: new Date().toISOString().slice(0,10)
										,disabled: !calculated() || savingProp()
									}
								)
						)
					,'Description': i.invoice_name()
					,'Project': i.project_name()
					,'Payment Ref':
						!editInvoicePermissions()
							? i.invoice_client_reference()
							: elements.list([
								m.component(
									Pencil
									,() => i.invoice_client_reference()
									,() =>
										elements.textInput(
											(a) => setOptions(a, 'invoice_client_reference', [i])
											,{
												title: "Payment reference from the client"

											}
										)
								)
							])
					,'Working Value': i.invoice_amount() + ' x ' + makeMoney( i.invoice_rate() )
					,'Service Value': i.invoice_service_amount() + ' x ' + makeMoney( i.invoice_service_rate() )
					,'Depreciation Value': i.invoice_depreciation_amount() + ' x ' + makeMoney( i.invoice_depreciation_rate() )
					,'Appreciation Value': i.invoice_appreciation_amount() + ' x ' + makeMoney( i.invoice_appreciation_rate() )
					,'Damages Value': i.invoice_damages_amount() + ' x ' + makeMoney( i.invoice_damages_rate() )
					,'Total Amount': makeMoney(
						Number(
							i.invoice_amount() * i.invoice_rate()
							+ i.invoice_service_amount() * i.invoice_service_rate()
							+ i.invoice_depreciation_amount() * i.invoice_depreciation_rate()
							+ i.invoice_appreciation_amount() * i.invoice_appreciation_rate()
							+ i.invoice_damages_amount() * i.invoice_damages_rate()
						)
					)
					,'Received':
						!editInvoicePermissions()
						? !i.invoice_approval()
							? 'Approval Required'
							: moment( i.invoice_received_date() ).format("ll")
						: !i.invoice_approval()
						? 'Approval Required'
						: receiveInvoiceBox(i)
					,'Approval':
						(
							!i.invoice_approver()
							|| users().find((u) => u.user_id() == i.invoice_approver() )
						) && !i.invoice_approval()
							? elements.checkbox({
								onchange:
									m.withAttr('checked',
										(checked) => {
											i.created = true
											i.invoice_approval(checked)

											if ( checked ) {
												i.invoice_due_date(
													new Date().getTime()
													+ Number(i.contract_payment_term)*milliperday
												)
												i.invoice_approver() == 'Unassigned'
												|| !i.invoice_approver()
													? i.invoice_approver(
														data.auth.stream().user_id
													)
													: null
											}
										}
									)
								,checked: i.invoice_approval()
							})
							: !users().find((u) => u.user_id() == i.invoice_approver() )
								? 'Unassigned'
								: users().find((u) => u.user_id() == i.invoice_approver() ).usersFullName

					,'Recipient': i.supplier_name()
					,'Client Ref':
						!editInvoicePermissions()
						? i.invoice_items_client_reference()
						: elements.list([
							m.component(
								Pencil
								,() => i.invoice_items_client_reference()
								,() =>
									elements.textInput(
										(a) => setOptions(a, 'invoice_items_client_reference', [i])
										,{
											title: "Reference name for the clients item"

										}
									)
							)
						])
					,'Ledger Ref No.': i.invoice_no() + '|' + (i.invoice_inv_id() || '')
					,'Payment Type': i.invoice_type()
					,'Last Modified': (users().find((u) => u.user_id() == i.user_id()) || nullUser).usersFullName
					}
					,[
						'invoice_rate'
						,'invoice_service_rate'
						,'invoice_depreciation_rate'
						,'invoice_appreciation_rate'
						,'invoice_damages_rate'
						,'invoice_type'
					]
				)

		const largeInvoiceTable = () =>
			scrollableTable.advanced({
				scoped: data.scoped
				,resize$: data.resize$
				,alwaysopen: true
				,rows:
					processedList
					.map((i, iindex) => ({
						key: i.key
						,selection:
							editInvoicePermissions()
							? {
								onchange:
									m.withAttr('checked',
										(checked) => {
											if ( checked ) {
												i.created = true
												selectedInvoices(
													selectedInvoices().concat([i])
												)
												activeItem(i)
											} else {
												selectedInvoices().splice(
													selectedInvoices()
														.findIndex((si) =>
															si.invoice_id() == i.invoice_id()
														)
													,1
												)
												activeItem(selectedInvoices()[0] || {})
											}
											selectedInvoices(selectedInvoices())
										}
									)
								,type: 'checkbox'
								,checked:
									selectedInvoices()
									.find((selected) =>
										selected.key == i.key
									)
									|| false
							}
							: {style: {opacity:0} , disabled: true}
						,header:
							iindex != 0
							? ''
							: m('.header'
								+ css`
									font-weight: bold;
									display: flex;
									justify-content: space-between;
									align-items: center;
									padding-bottom: 0.5em;
								`
								,m('span'
									+ css`
										font-weight: bold;
									`
									,''
								)
								,m('.data'
									+ css`
										display: grid;
										grid-template-rows: 0.25fr 0.5fr;
										justify-content: space-between;
									`
									,[

									]
								)
								,m('span'
									+ css`
										font-weight: bold;
									`
									,''
								)
							)
						,layouts: {
							expanded: ({ x, headers, i }) =>
								m('.expanded'
									+ css`
										display: grid;
										justify-content: space-between;
										grid-template-columns: 1fr;
									`
									, m('.data'
										+ css(
										`
											display: grid;
											justify-content: space-between;
											grid-template-columns: 1fr 1fr 1.25fr 0.75fr 1fr `
												+ (!hiddenInvoiceData()['invoice_rate'] ? `1fr ` : ``)
												+ (!hiddenInvoiceData()['invoice_service_rate'] ? `1fr ` : ``)
												+ (!hiddenInvoiceData()['invoice_depreciation_rate'] ? `1fr ` : ``)
												+ (!hiddenInvoiceData()['invoice_appreciation_rate'] ? `1fr ` : ``)
												+ (!hiddenInvoiceData()['invoice_damages_rate'] ? `1fr ` : ``)
												+ `1fr 1.5fr 0.75fr 1.25fr 0.75fr 0.75fr `
												+ (!hiddenInvoiceData()['invoice_type'] ? `0.75fr ` : ``)
												+ `1fr;
											overflow: hidden;
											gap: 1em;
											align-items: start;
											align-content: start;
										`)
										, headers
											.map(
												k => [
													i == 0
													? m('.data'
														+ css`
															display: grid;
															grid-template-rows: 0.25fr 0.5fr;
															overflow: hidden;
														`
														,[m('label.control-label', k), x[k]]
													)
													: m('.data'
														+ css`
															display: grid;
															overflow: hidden;
														`
														,x[k]
													)
												]
											)
									)
								)
						}
						,data: invoiceData(i)
					}))
			})

		const smallInvoiceTable =
			() =>
				scrollableTable.advanced({
					scoped: data.scoped
					,resize$: data.resize$
					,alwaysopen: true
					,rows:
						processedList
						.map((i) => ({
							key: i.key
							,header:
								m('span'
									+ css`
										font-weight: bold;
										display: flex;
										justify-content: space-between;
										align-items: start;
										padding-bottom: 0.5em;
									`
									,m('input'
										+ css`
											width: 25px;
											height: 25px;
											margin: 0px;
										`
										.$media('(hover: hover)',
											css`
												width: 20px;
												height: 20px;
											`
										)
										,editInvoicePermissions()
										? {
											onchange:
												m.withAttr('checked',
													(checked) => {
														if ( checked ) {
															i.created = true
															selectedInvoices(
																selectedInvoices().concat([i])
															)
															activeItem(i)
														} else {
															selectedInvoices().splice(
																selectedInvoices()
																	.findIndex((si) =>
																		si.invoice_id() == i.invoice_id()
																	)
																,1
															)
															activeItem(selectedInvoices()[0] || {})
														}
														selectedInvoices(selectedInvoices())
													}
												)
											,type: 'checkbox'
											,checked:
												selectedInvoices()
												.find((selected) =>
													selected.key == i.key
												)
												|| false
										}
										: {style: {opacity:0} , disabled: true}
									)
									, [i.invoice_inv_id(), i.invoice_no()].filter(Boolean).join('|')
								)
							,layouts: {
								expanded: ({ x }) =>
									m('.expanded'
										+ css`
											display: grid;
											justify-content: space-between;
											grid-template-columns: 1fr;
										`
										,m('.data'
											+ css`
												display: grid;
												justify-content: space-between;
												grid-template-columns: 0.25fr 0.25fr 0.25fr 0.25fr;
												padding-bottom: 2.25em;
											`
											,[
												'Date'
												,'Due Date'
												,!hiddenInvoiceData()['invoice_rate'] ? 'Working Value' : ''
												,'Total Amount'
											]
											.map((k) =>
												m('.data'
													+ css`
														display: grid;
														grid-template-rows: 0.25fr 0.25fr;
														padding-right: 0.25em
													`
													,[
														m('label.control-label.f4', k)
														, k != 'Description'
															? x[k]
															: x[k] == i.invoice_name()
																? x[k]
																: [x[k], i.invoice_name()].filter(R.identity).join(' - ')

													]
												)
											)
										)


										, m('.data'
											+ css`
												display: grid;
												justify-content: space-between;
												grid-template-columns: 0.5fr 0.25fr 0.25fr;
												padding-bottom: 1em;
											`
											,[
												'Description'
												, 'Approval'
												, 'Received'
											]
											.map((k) =>
												m('.data'
													+ css`
														display: grid;
														grid-template-rows: 0.25fr 0.25fr;
														padding-right: 0.5em;
													`
													,[m('label.control-label.f4', k), x[k]]
												)
											)
										)


										, m('.data'
											+ css`
												display: grid;
												justify-content: space-between;
												grid-template-columns: 0.5fr 0.25fr 0.25fr;
												padding-bottom: 1em;
											`
											,[
												'Recipient'
												, 'Client Ref'
												, 'Project'
											]
											.map((k) =>
												m('.data'
													+ css`
														display: grid;
														grid-template-rows: 0.25fr 0.75fr;
														padding-right: 0.5em;
													`
													,[m('label.control-label.f4', k), x[k]]
												)
											)
										)

										, m('.data'
											+ css`
												display: grid;
												justify-content: space-between;
												grid-template-columns: 0.5fr 0.25fr 0.25fr;
												padding-bottom: 1em;
											`
											,[
												'Payment Ref'
												,'Project Multipliers'
												,'Last Modified'
											]
											.map((k) =>
												m('.data'
													+ css`
														display: grid;
														grid-template-rows: 0.25fr 0.75fr;
														padding-right: 0.5em;
													`
													,[
														m('label.control-label.f4', k)
														, k != 'Project Multipliers'
														?  x[k]
														: elements.list([
															m('button.btn.btn-secondary',
																{
																	style: {
																		backgroundColor: 'transparent'
																		,border: 'solid 1px #3380c2'
																		,position: 'relative'
																		,top: '-0.1em'
																		,height: '2.9em'
																		,width: '5em'
																		,color: '#434aa3'
																		,textDecoration: 'underline'
																	}
																	,disabled:
																		!i.project_id()
																		|| i.project_id()
																			&& !multiplierIndex()[i.project_id()]
																			|| !multiplierIndex()[i.project_id()]
																			[i.contractrecognitionid]

																	, onclick: () => applyProjectMultuplier(i)
																	, title: "Apply Project and Work Multiplers to this invoices"
																}
																,'Apply'
															)
														])
													]
												)
											)
										)


										, m('.data'
											+ css`
												display: grid;
												justify-content: space-between;
												grid-template-columns: 0.25fr 0.25fr 0.25fr 0.25fr;
												padding-bottom: 1em;
											`
											,[
												!hiddenInvoiceData()['invoice_service_rate'] ? 'Service Value' : ''
												,!hiddenInvoiceData()['invoice_depreciation_rate'] ? 'Depreciation Value' : ''
												,!hiddenInvoiceData()['invoice_appreciation_rate'] ? 'Appreciation Value' : ''
												,!hiddenInvoiceData()['invoice_damages_rate'] ? 'Damages Amount Value' : ''
											]
											.map((k) =>
												m('.data'
													+ css`
														display: grid;
														grid-template-rows: 1fr;
														padding-right: 0.5em;
													`
													,[m('label.control-label.f4', k), x[k]]
												)
											)
										)
									)

							}
							,data: invoiceData(i)
						}))
				})


		const invoiceTable = () =>
			m(Responsive
				,
				{
					breakpoints: {
						mobile: 0
						,desktop: 1800
						,tablet: 700
						,hd: 1800
					}
				}
				,({ hd, desktopOnly, mobileOnly, tabletOnly }) => [
					hd( largeInvoiceTable )
					,desktopOnly( largeInvoiceTable  )
					,mobileOnly( smallInvoiceTable )
					,tabletOnly( smallInvoiceTable )
				]
			)

		return m('div.harth.mb6', [

			m('br')
			,!calculated() && loading() || invoiceDataLoading()
				? elements.alert('warning',`Calculating the monetary value of your ledgers in the focus criteria`)
				: null

			,editInvoicePermissions()
				? elements.list([

					elements.backAnchor(
						`Back to Summary`
						,null
						,{
							href: m.route.get().slice(0, m.route.get().indexOf('/ledger/'))
							, oncreate: m.route.link
							, title: "Back to item list"
						}
					)

					,elements.action(
						'Save'
						, sendInvoices
						, savingProp
						, 'primary'
						, () => invoiceIdenticals() || saveDisabledInvoices(true)
					)

					,m('button.btn.btn-warning'
						, {
							onclick: () => {
								errors({})
								focussedInvoices(cloneInvoice(originalInvoices()))
								selectedInvoices([])
								deleteInvoiceItems([])
							}
							, disabled: invoiceIdenticals()
							, title: "Discard Changes since last save"
						}
						, 'Discard Changes'
					)
				])
				:[]

			,saveDisabledInvoices()

			,elements.list([
				gotfilters
				,invoiceEditAll()
			])
			,m('br')
			,loading() || !calculated() || invoiceDataLoading() || metaDataIndexing()
				? elements.centeredSpinner()
				: originalInvoices().length > 0
				? [
					null
					,receiptedInvoices().length > 0
						? [
							m('label.control-label', 'Receipting ledgers')
							,receiptedInvoices()
								.map( (si, sindex) =>
									sindex + 1
									+ '. '
									+ makeMoney(
										Number(
											si.invoice_amount() * si.invoice_rate()
											+ si.invoice_service_amount() * si.invoice_service_rate()
											+ si.invoice_depreciation_amount() * si.invoice_depreciation_rate()
											+ si.invoice_appreciation_amount() * si.invoice_appreciation_rate()
											+ si.invoice_damages_amount() * si.invoice_damages_rate()
										)
									)
									+ ' | '
									+ si.invoice_name()
									+ ' | '
									+ si.invoice_no()
									+ ' | '
									+ (si.invoice_inv_id() || '')
									+ ' | '
									+ (
										si.supplier_name()
											? si.supplier_name()
											: `Unassigned`
									)
								)
								.map(s => m('p.pv1.ma0', s ))

							,m('hr')

						]
						: null


					,invoiceTable()

				]
				: elements.alert(`info`, `Make some ledgers via your contract items to better track finances`)

		])
	}

	function CreateInvoicePO(){

		const processedList =
			ListProcessing(
				selectedInvoices()
				, filterFunction.invoiceFilters.filters()
				, ''
			)

		const applyProjectMultuplier =
			(i) => {
				const rateMulitplierName =
					i.crews_discipline_rates_id
					? 'disciplines_contractors_crews_contract_multiplier'
					: 'discipline_contract_multiplier'

				const rateMultiplier =
					!i.project_id()
						? 1
						: multiplierIndex()
						[i.project_id()]
						[i.contractrecognitionid]
						[rateMulitplierName]()

				i.invoice_rate(
					i.invoice_rate()
					* ( rateMultiplier || 1 )
				)
				i.invoice_service_rate(
					i.invoice_service_rate()
					* ( rateMultiplier || 1 )
				)
				i.invoice_depreciation_rate(
					i.invoice_depreciation_rate()
					* ( rateMultiplier || 1 )
				)
				i.invoice_appreciation_rate(
					i.invoice_appreciation_rate()
					* ( rateMultiplier || 1 )
				)
				i.invoice_damages_rate(
					i.invoice_damages_rate()
					* ( rateMultiplier || 1 )
				)
			}


		const invoiceData =
			(i) =>
				R.reduce(
					(o, i) => {
						hiddenInvoiceData()
						&& hiddenInvoiceData()[i]
						? delete o[
							i == 'invoice_service_rate'
							? 'Service Amount | Rate'
							: i == 'invoice_depreciation_rate'
							? 'Depreciation Amount | Rate'
							: i == 'invoice_appreciation_rate'
							? 'Appreciation Amount | Rate'
							: i == 'invoice_damages_rate'
							? 'Damages Amount | Rate'
							: null
						]
						: null
						return o
					}
					,{
						'Payment Type':
							Number(
								i.invoice_amount() * i.invoice_rate()
								+ i.invoice_service_amount() * i.invoice_service_rate()
								+ i.invoice_depreciation_amount() * i.invoice_depreciation_rate()
								+ i.invoice_appreciation_amount() * i.invoice_appreciation_rate()
								+ i.invoice_damages_amount() * i.invoice_damages_rate()
							) > 0
								? i.invoice_received()
									? 'Receipted Revenue'
									: 'Accrued Revenue'
								: i.invoice_received()
									? 'Receipted Expenditure'
									: 'Accrued Expenditure'

						,'Date':
							!editInvoicePermissions() || i.invoice_approval()
							? moment( i.invoice_date() ).format("ll")
							: m.component(
								Pencil
								,() => i.invoice_date()
									? moment( i.invoice_date() ).format("ll")
									: ''
								,() =>
									elements.dateInput(
										(a) => setOptions(a, 'invoice_date', [i])
										,{
											title: "Created invoice date"
											,mindate: new Date().toISOString().slice(0,10)
											,disabled: !calculated() || savingProp()
										}
									)
							)
						, 'Set Recipient':
							/* eslint-disable */
							m.component(
								Pencil
								,() => i.supplier_name()
								,() =>
									autocomplete.strict(
										SupplierEntities
										,R.when( Boolean, function(v){

											i.supplier_name(v.contractrecognitionname)
											i.supplier_id(
												v.contractrecognitionid
											)
										})
										,'contractrecognitionname'
										,() => ({
											disabled: !SupplierEntities() || SupplierEntities().length == 0
										})
									)
							)
							/* eslint-enable */
						, 'Client Ref':
							editInvoicePermissions()
							? elements.list([
								m.component(
									Pencil
									,() => i.invoice_items_client_reference()
									,() =>
										elements.textInput(
											i.invoice_items_client_reference
											,{
												title: "Reference name for the clients item"
											}
										)
								)
							])
							: i.invoice_items_client_reference()
						, 'Ledger Ref No.': i.invoice_no() + '|' + (i.invoice_inv_id() || '')
						, 'Payment Ref':
							!editInvoicePermissions()
							? i.invoice_client_reference()
							: elements.list([
								m.component(
									Pencil
									,() => i.invoice_client_reference()
									,() =>
										elements.textInput(
											i.invoice_client_reference
											,{
												title: "Payment reference from the client"
											}
										)
								)
							])
						, 'Name': i.invoice_name()
						, 'Description': elements.textInput(i.invoice_description, {})
						, 'Project':
							/* eslint-disable */
							m.component(
								Pencil
								,() => i.project_name()
								,() =>
									autocomplete.strict(
										projects
										,R.when( Boolean, function(v){
											i.project_name(v.contractrecognitionname)
											i.project_id(
												v.project_id()
											)
										})
										,'contractrecognitionname'
										,() => ({
											disabled: projects().length == 0
										})
									)
							)
							/* eslint-enable */
						, 'Project Multipliers':
							elements.list([
								m('button.btn.btn-secondary',
									{
										style: {
											backgroundColor: 'transparent'
											,border: 'solid 1px #3380c2'
											,position: 'relative'
											,top: '-0.1em'
											,height: '2.9em'
											,width: '5em'
											,color: '#434aa3'
											,textDecoration: 'underline'
										}
										,disabled:
											!i.project_id()
											|| i.project_id()
												&& !multiplierIndex()[i.project_id()]
												[i.contractrecognitionid]

										, onclick: () => applyProjectMultuplier(i)
										, title: "Apply Project and Work Multiplers to this invoices"
									}
									,'Apply'
								)
							])
						, 'Working Amount | Rate':
							elements.list([
								m(
									NumberInput
									,R.merge(
										data
										,{
											errors
											,errorLabel: i.invoice_no() + ' Invoice Amount'
										}
									)
									,{
										prop: i.invoice_amount
										,attrs: {
											step: 0.01
											,disabled: i.invoice_rate() == 0 || i.invoice_items.length
										}
									}
								)
								,m.component(
									Pencil
									,() => makeMoney( i.invoice_rate() )
									,() =>
										m(
											NumberInput
											,R.merge(
												data
												,{
													errors
													,errorLabel: i.invoice_no() + ' Invoice Rate'
												}
											)
											,{
												prop: i.invoice_rate
												,attrs: {
													step: 0.01

												}
											}
										)
								)
							])
						, 'Service Amount | Rate':
							elements.list([
								m(
									NumberInput
									,R.merge(
										data
										,{
											errors
											,errorLabel: i.invoice_no() + ' Invoice Service Amount'
										}
									)
									,{
										prop: i.invoice_service_amount
										,attrs: {
											step: 0.01
											,disabled: i.invoice_service_rate() == 0 || i.invoice_items.length
										}
									}
								)
								,m.component(
									Pencil
									,() => makeMoney( i.invoice_service_rate() )
									,() =>
										m(
											NumberInput
											,R.merge(
												data
												,{
													errors
													,errorLabel: i.invoice_no() + ' Invoice Service Rate'
												}
											)
											,{
												prop: i.invoice_service_rate
												,attrs: {
													step: 0.01

												}
											}
										)
								)
							])
						, 'Depreciation Amount | Rate':
							elements.list([
								m(
									NumberInput
									,R.merge(
										data
										,{
											errors
											,errorLabel: i.invoice_no() + ' Invoice Depreciation Amount'
										}
									)
									,{
										prop: i.invoice_depreciation_amount
										,attrs: {
											step: 0.01
											,disabled: i.invoice_depreciation_rate() == 0 || i.invoice_items.length
										}
									}
								)
								,m.component(
									Pencil
									,() => makeMoney( i.invoice_depreciation_rate() )
									,() =>
										m(
											NumberInput
											,R.merge(
												data
												,{
													errors
													,errorLabel: i.invoice_no() + ' Invoice Depreciation Rate'
												}
											)
											,{
												prop: i.invoice_depreciation_rate
												,attrs: {
													step: 0.01

												}
											}
										)
								)
							])
						, 'Appreciation Amount | Rate':
							elements.list([
								m(
									NumberInput
									,R.merge(
										data
										,{
											errors
											,errorLabel: i.invoice_no() + ' Invoice Appreciation Amount'
										}
									)
									,{
										prop: i.invoice_appreciation_amount
										,attrs: {
											step: 0.01
											,disabled: i.invoice_appreciation_rate() == 0 || i.invoice_items.length
										}
									}
								)
								,m.component(
									Pencil
									,() => makeMoney( i.invoice_appreciation_rate() )
									,() =>
										m(
											NumberInput
											,R.merge(
												data
												,{
													errors
													,errorLabel: i.invoice_no() + ' Invoice Apepreciation Rate'
												}
											)
											,{
												prop: i.invoice_appreciation_rate
												,attrs: {
													step: 0.01

												}
											}
										)
								)
							])
						, 'Damages Amount | Rate':
							elements.list([
								m(
									NumberInput
									,R.merge(
										data
										,{
											errors
											,errorLabel: i.invoice_no() + ' Invoice Damage Amount'
										}
									)
									,{
										prop: i.invoice_damages_amount
										,attrs: {
											step: 0.01
											,disabled: i.invoice_damages_rate() == 0 || i.invoice_items.length
										}
									}
								)
								,m.component(
									Pencil
									,() => makeMoney( i.invoice_damages_rate() )
									,() =>
										m(
											NumberInput
											,R.merge(
												data
												,{
													errors
													,errorLabel: i.invoice_no() + ' Invoice Damage Rate'
												}
											)
											,{
												prop: i.invoice_damages_rate
												,attrs: {
													step: 0.01

												}
											}
										)
								)
							])
						, 'Total Amount':
							makeMoney(
								Number(
									i.invoice_amount() * i.invoice_rate()
									+ i.invoice_service_amount() * i.invoice_service_rate()
									+ i.invoice_depreciation_amount() * i.invoice_depreciation_rate()
									+ i.invoice_appreciation_amount() * i.invoice_appreciation_rate()
									+ i.invoice_damages_amount() * i.invoice_damages_rate()
								)
							)
						, 'Approver':
							m.component(
								Pencil
								,() =>
									(
										users().find((u) =>
											u.user_id()
											== selectedInvoices()[0].invoice_approver()
										)
										|| nullUser
									)
									.usersFullName
								,() =>
									autocomplete.strict(
										users
										, (v) => {

											if (v){
												setOptions(
													v.user_id()
													, 'invoice_approver'
													, selectedInvoices()
												)
											}

											return v
												? v.usersFullName
												: users().find((u) =>
													u.user_id()
													== selectedInvoices()[0].invoice_approver()
												).usersFullName
										}

										,'usersFullName'
										, () => ({
											style: {
												height: '3em'
											}
										})
									)
							)
						, 'Approved':
							(
								!i.invoice_approver()
								|| users().find((u) => u.user_id() == i.invoice_approver() )
							) && !i.invoice_approval()
								? elements.checkbox({
									onchange:
										m.withAttr('checked',
											(checked) => {
												i.created = true
												i.invoice_approval(checked)

												if ( checked ) {
													i.invoice_due_date(
														new Date().getTime()
														+ Number(i.contract_payment_term)*milliperday
													)
													i.invoice_approver() == 'Unassigned'
													|| !i.invoice_approver()
														? i.invoice_approver(
															data.auth.stream().user_id
														)
														: null
												}
											}
										)
									,checked: i.invoice_approval()
								})
								: !users().find((u) => u.user_id() == i.invoice_approver() )
									? 'Unassigned'
									: users().find((u) => u.user_id() == i.invoice_approver() ).usersFullName
						, 'Received':

						!editInvoicePermissions()
						? !i.invoice_approval()
							? 'Approval Required'
							: moment( i.invoice_received_date() ).format("ll")
						: !i.invoice_approval()
						? 'Approval Required'
						: receiveInvoiceBox(i)
					}
					,[
						'invoice_service_rate'
						,'invoice_depreciation_rate'
						,'invoice_appreciation_rate'
						,'invoice_damages_rate'
					]
				)

		const largeInvoiceTable = () =>
			scrollableTable.advanced({
				scoped: data.scoped
				,resize$: data.resize$
				,alwaysopen: true
				,rows:
					processedList
					.map((i, iindex) => ({
						key: i.key
						,header:
							iindex != 0
							? ''
							: m('.header'
								+ css`
									font-weight: bold;
									display: flex;
									justify-content: space-between;
									align-items: center;
									padding-bottom: 0.5em;
								`
								,m('span'
									+ css`
										font-weight: bold;
									`
									,''
								)
								,m('.data'
									+ css`
										display: grid;
										grid-template-rows: 0.25fr 0.5fr;
										justify-content: space-between;
									`
									,[

									]
								)
								,m('span'
									+ css`
										font-weight: bold;
									`
									,''
								)
							)
						,layouts: {
							expanded: ({ x, headers, i }) =>
								m('.expanded'
									+ css`
										display: grid;
										justify-content: space-between;
										grid-template-columns: 1fr;
									`
									, m('.data'
										+ css(
										`
											display: grid;
											justify-content: space-between;
											grid-template-columns: 1fr 1fr 0.75fr 1fr 1fr 1fr 1fr 1.5fr 1fr 1.25fr `
												+ (!hiddenInvoiceData()['invoice_service_rate'] ? `1fr ` : ``)
												+ (!hiddenInvoiceData()['invoice_depreciation_rate'] ? `1fr ` : ``)
												+ (!hiddenInvoiceData()['invoice_appreciation_rate'] ? `1fr ` : ``)
												+ (!hiddenInvoiceData()['invoice_damages_rate'] ? `1fr ` : ``)
												+ `0.75fr 0.75fr 0.75fr 1.25fr;
											overflow: visible;
											gap: 1em;
											align-items: start;
											align-content: start;
										`)
										, headers
											.map(
												k => [
													i == 0
													? m('.data'
														+ css`
															display: grid;
															grid-template-rows: 0.25fr 0.5fr;
															overflow: visible;
														`
														,[m('label.control-label', k), x[k]]
													)
													: m('.data'
														+ css`
															display: grid;
															overflow: visible;
														`
														,x[k]
													)
												]
											)
									)
								)
						}
						,data: R.omit(['Payment Type'], invoiceData(i))
					}))
			})

		const smallInvoiceTable = () =>
			scrollableTable.advanced({
				scoped: data.scoped
				,resize$: data.resize$
				,alwaysopen: true
				,rows:
					processedList
					.map((i) => ({
						key: i.key
						,header:
							m('span'
								+ css`
									font-weight: bold;
									display: flex;
									justify-content: space-between;
									align-items: center;
									padding-bottom: 0.5em;
								`
								, [i.invoice_inv_id(), i.invoice_no()].filter(Boolean).join('|')
							)
						,layouts: {
							expanded: ({ x }) =>
								m('.expanded'
									+ css`
										display: grid;
										justify-content: space-between;
										grid-template-columns: 1fr;
									`
									,m('.data'
										+ css`
											display: grid;
											justify-content: space-between;
											grid-template-columns: 0.25fr 0.25fr 0.25fr 0.25fr;
											padding-bottom: 2.25em;
										`
										,[
											'Name'
											,'Date'
											,'Working Amount | Rate'
											,'Total Amount'
										]
										.map((k) =>
											m('.data'
												+ css`
													display: grid;
													grid-template-rows: 0.25fr 0.25fr;
													padding-right: 0.25em
												`
												,[
													m('label.control-label.f4', k)
													, k != 'Description'
														? x[k]
														: x[k] == i.invoice_name()
															? x[k]
															: [x[k], i.invoice_name()].filter(R.identity).join(' - ')

												]
											)
										)
									)


									, m('.data'
										+ css`
											display: grid;
											justify-content: space-between;
											grid-template-columns: 0.5fr 0.25fr 0.25fr;
											padding-bottom: 1em;
										`
										,[
											'Description'
											, 'Approved'
											, 'Received'
										]
										.map((k) =>
											m('.data'
												+ css`
													display: grid;
													grid-template-rows: 0.25fr 0.25fr;
													padding-right: 0.5em;
												`
												,[m('label.control-label.f4', k), x[k]]
											)
										)
									)


									, m('.data'
										+ css`
											display: grid;
											justify-content: space-between;
											grid-template-columns: 0.25fr 0.25fr 0.25fr 0.25fr;
											padding-bottom: 1em;
										`
										,[
											'Set Recipient'
											, 'Client Ref'
											, 'Approver'
											, 'Project'
										]
										.map((k) =>
											m('.data'
												+ css`
													display: grid;
													grid-template-rows: 0.25fr 0.75fr;
													padding-right: 0.5em;
												`
												,[m('label.control-label.f4', k), x[k]]
											)
										)
									)

									, m('.data'
										+ css`
											display: grid;
											justify-content: space-between;
											grid-template-columns: 0.25fr 0.5fr 0.25fr;
											padding-bottom: 1em;
										`
										,[
											'Project Multipliers'
											,'Payment Ref'
											,'Payment Type'
										]
										.map((k) =>
											m('.data'
												+ css`
													display: grid;
													grid-template-rows: 0.25fr 0.75fr;
													padding-right: 0.5em;
												`
												,[m('label.control-label.f4', k), x[k]]
											)
										)
									)


									, m('.data'
										+ css`
											display: grid;
											justify-content: space-between;
											grid-template-columns: 0.25fr 0.25fr 0.25fr 0.25fr;
											padding-bottom: 1em;
										`
										,[
											!hiddenInvoiceData()['invoice_service_rate'] ? 'Service Amount | Rate' : ''
											,!hiddenInvoiceData()['invoice_depreciation_rate'] ? 'Depreciation Amount | Rate' : ''
											,!hiddenInvoiceData()['invoice_appreciation_rate'] ? 'Appreciation Amount | Rate' : ''
											,!hiddenInvoiceData()['invoice_damages_rate'] ? 'Damages Amount | Rate' : ''
										]
										.map((k) =>
											m('.data'
												+ css`
													display: grid;
													grid-template-rows: 1fr;
													padding-right: 0.5em;
												`
												,[m('label.control-label.f4', k), x[k]]
											)
										)
									)
								)

						}
						,data: invoiceData(i)
					}))
			})


		const invoiceTable = () =>
			m(Responsive
				,
				{
					breakpoints: {
						mobile: 0
						,desktop: 900
						,tablet: 700
						,hd: 1800
					}
				}
				,({ hd, desktopOnly, mobileOnly, tabletOnly }) => [
					hd( largeInvoiceTable )
					,desktopOnly( largeInvoiceTable  )
					,mobileOnly( smallInvoiceTable )
					,tabletOnly( smallInvoiceTable )
				]
			)

		return m('div.harth.mb6', [
			editInvoicePermissions()
				? [
					m('br')
					,!calculated() || invoiceDataLoading() || metaDataIndexing()
						? elements.centeredSpinner()
						:  [
							m('h4', (active.contract_name() || 'Reversal') + ' - Create Ledgers' )
							,m('br')

							,missingPermissions()
								? elements.alert(
									`info`
									,'Detailed functionality requires '
									+ missingPermissions()
									+ ' permissions'
								)
								: null

							,elements.alert(`info`, `All negative rates are considered to be an expenditure, all positive rates are considered to be revenue streams`)
							,elements.list([

								elements.backAnchor(
									`Back to ` + active.contract_name() + ` Summary`
									,() => {
										errors({})
										route_view_type('All Contracts')
									}
									,{
										href: '/data/contracts/contracts/edit/'
											+ route_contract_id()
											+ '/forecastdateA/'
											+ forecastdateA()
											+ '/forecastdateB/'
											+ forecastdateB()

										, oncreate: m.route.link
										, title: "Back to item list"
									}
								)

								,elements.action(
									'Save'
									, sendInvoices
									, savingProp
									, 'primary'
									, () => saveDisabledInvoices(true)
								)
							])

							,saveDisabledInvoices()
							,invoiceTable()
						]
				]
				: missingPermissions()
					? elements.alert(
						`info`
						,'Detailed functionality requires '
						+ missingPermissions()
						+ ' permissions'
					)
					: null
		])
	}

	function contractDatesButton(){
		return forecastdateA() == 0 && forecastdateB() == Infinity
			? null
			: m('button.btn.btn'
				,{
					onclick: () => {
						forecastdateA(0)
						forecastdateB(Infinity)
					}
					, disabled: forecastdateA() == 0 && forecastdateB() == Infinity || !calculated() || savingProp()
					, title: "Set start and end period to contract start periods and periods respectively"
					, style:
						{ backgroundColor: 'transparent'
						, border: 'solid 1px #3380c2'
						, position: 'relative'
						, top: '-0.1em'
						, height: '2.5em'
						, width: '12em'
						, color: '#434aa3'
						}
				}
				, 'Reset Dates'
			)
	}

	const debounceDrawChart = debounce(drawChart, 1000)
	function reCalculateButton({forcereroute, spinner}){
		return m('button.btn.btn'
			, {
				onclick: () => {

					if (forcereroute){

						m.route.set(
							"/data/contracts/overview" + "/forecastdateA/" + forecastdateA() + "/forecastdateB/" + forecastdateB()
						)

					} else {
						!reMapdateChange()
							? reMapdateChange(true)
							: reMapdateChange(false)
					}

				}
				, disabled:
					!calculated()
					|| savingProp()
					|| dateDisabled()
				, title: "Set focus period and schedules calculated statistics"
				, style:
					{ backgroundColor: 'transparent'
					, position: 'relative'
					, top: '-0.1em'
					, color: '#434aa3'
					}
			}
			, spinner ? elements.refresher() : 'Refresh'
		)
	}

	function reChartButton({sv, breakdown, split}){
		return !calculated()
		|| savingProp()
		|| dateDisabled()
		? ''
		: m('button.btn.btn'
			,{
				onclick: () => {

					loadchart()[
						route_contract_id()
						? route_contract_id()
						: sv.schedule_version_id
					] = route_contract_id() ? route_contract_id() : sv.schedule_version_id


					if( breakdown ){
						sv.breakdown = breakdown
						sv.makeChart = null
					}

					if( split ){
						sv.makeChart = null
						sv.split = split
						sv.breakdown = 'breakdown'
					}


					fetchCalculations({
						startDate: forecastdateA()
						,endDate: forecastdateB()
						,currentRoute: 'financials'
						,scheduleonly:
							sv.schedule_version_id == "Overheads" ? '' : true
						,getchartdata:
							routeParam().viewType == 'overview'
							&& loadchart()[sv.schedule_version_id]
							? 'getcharts'
							: ''
						,breakdown: sv.breakdown || sv.split
						,nochildren: false
						,scheduleList: [
							{
								scheduleNameVersion: sv.scheduleNameVersion
								,schedule_priority: sv.schedule_priority
								,schedule_version_created: sv.schedule_version_created
								,schedule_version_id: sv.schedule_version_id
								,schedule_version_old_id: sv.schedule_version_old_id
								,schedules_parameters_id: sv.schedules_parameters_id
								,schedule_id: sv.schedule_id
							}
						]
					})

				}
				, disabled: !calculated()
					|| savingProp()
					|| dateDisabled()
				, title: "Load chart view"
				, style:
					{ backgroundColor: 'transparent'
					, position: 'relative'
					, top: '-0.1em'
					, color: '#434aa3'
					}
			}
			, split ? 'Split Charts' : breakdown ? 'Show Breakdown' : elements.refresher()
		)
	}


	// todo-james Keeping this because I assume @eja kept it for a reason
	// eslint-disable-next-line no-unused-vars
	function reSetCriteraButton(){
		return reMapdateChange()
			? m('button.btn.btn'
				, {
					onclick: () => {

						focussedEntities(
							recordedFocus().schedules.map((s) =>
								[].concat(
									focussedEntities()
									,scheduleList()
								)
									.find((sl) => sl.scheduleNameVersion == s.snv)
							)
						)

						forecastdateA(
							recordedFocus().dateA
						)
						forecastdateB(
							recordedFocus().dateB
						)
					}
					, disabled: !calculated()
						|| savingProp()
						|| !notCalculated(true)
					, title: "Set focus period and schedules to last calculated state"
					, style:
						{ backgroundColor: 'transparent'
						, border: 'solid 1px #3380c2'
						, position: 'relative'
						, top: '-0.1em'
						, height: '2.5em'
						, width: '8em'
						, color: '#434aa3'
						, textDecoration: 'underline'
						}
				}
				, 'Reset Criteria'
			)
			: null
	}

	function focusDates(){
		return m('h5'
			,elements.list([
				'Date Period is between '
				,m.component(
					Pencil
					,() =>

							forecastdateA() == 0
								? 'Contract start dates '
								: moment( forecastdateA() ).format("ll")

					,() =>
						elements.dateInput(
							(v) => v ? forecastdateA(new Date(v).getTime()) : forecastdateA()
							,{
								title: "Statistic Period Start"
								,maxdate: forecastdateB() == Infinity
									? null
									: new Date(forecastdateB()).toISOString().slice(0,10)
								,disabled: !calculated() || savingProp()
							}
						)
				)
				,' and '
				,m.component(
					Pencil
					,() =>

							forecastdateB() == Infinity
								? 'Contract end dates '
								: moment( forecastdateB() ).format("ll")

					,() =>
						elements.dateInput(
							(v) => v ? forecastdateB(new Date(v).getTime()) : forecastdateB()
							,{
								title: "Statistic Period End"
								,mindate: new Date(forecastdateA()).toISOString().slice(0,10)
								,disabled: !calculated() || savingProp()
							}
						)
				)
			])
		)
	}

	function focusViewA(){
		return [

			focusDates()

			,m(`div`
				+ css
				`
					display: grid;
					grid-template-columns: 0.05fr 0.5fr 0.05fr 0.4fr;
					gap: 0.5em
					align-items: baseline;
					margin-left: auto;
					margin-right: 0;
					align-items: center;			`
				,[
					m('h5', 'Focus')
					,m(autocomplete.Main, {
						list: scheduleList
						,onselect(v, model){
							focussedEntities(
								focussedEntities().concat(
									v
								)
							)
							model.input('')
							model.chosen(null)
						}
						,field: 'scheduleNameVersion'
						,attrs: {
							disabled:
								(scheduleList() || []).length == 0
								|| !calculated()
								|| dateDisabled()
								|| savingProp()
						}
					})
					,reCalculateButton({forcereroute: false, spinner: true})
					,contractDatesButton()
				]
			)

			,m('br')
			,(
				!calculated() || savingProp()
					? elements.nonStrikeList
					: elements.strikeList
			)(
				focussedEntities()
					.map(
						label => ({
							label: label.scheduleNameVersion
							,action: () => {
								focussedEntities().splice(
									focussedEntities().findIndex(
										R.propEq('scheduleNameVersion', label.scheduleNameVersion)
									)
									,1
								)
								focussedEntities(focussedEntities())
								reMapdateChange(true)
							}
						})
					)
			)

			,route_view_type() != 'All Contracts'
			&& route_view_type() != 'All Items'
			&& initiateContractFetch()
			? [
				m('br')
				,m('h4','Downloads')
				,m('hr')
				,elements.list([
					m(
						'a'
						, {
							title: 'Download imports for Xero'
							,href: fetchXeroExport({
								bills:true
								,auth_token: data.auth.stream().auth_token
							})
						}
						, 'Imports Bills to Xero'
					)
				])
				,elements.list([
					m(
						'a'
						, {
							title: 'Download imports for Xero'
							,href: fetchXeroExport({
								sales:true
								,auth_token: data.auth.stream().auth_token
							})
						}
						, 'Imports Sales to Xero'
					)
				])
			]
			: null

			,notCalculated()
		]
	}


	function focusViewB(){
		return [
			readContractPermissions()
				? m('div',
					!reMapdateChange()
						? []
						: [
							elements.alert(
								`info`
								,elements.details(
									`Quarterly Summary`
									,elements.selectbuttons(
										[
											'Q1 Jul-Sep'
											,'Q2 Oct-Dec'
											,'Q3 Jan-Mar'
											,'Q4 Apr-Jun'
										]
										,(v) => {if (v){setQuartelyButtonDate(v)}}
										,{
											disabled: !calculated() || savingProp()
											, title: "Set a financial quarter"
										}
										,(notReq, v) => {
											let year = new Date().getYear() + 1900
											return v == 'Q1 Jul-Sep'
											&& forecastdateA() == new Date(year, 6, 1).getTime() + 1
											&& forecastdateB() == new Date(year, 8, 31).getTime() - 1
											|| v == 'Q2 Oct-Dec'
											&& forecastdateA() == new Date(year, 9, 1).getTime() + 1
											&& forecastdateB() == new Date(year, 11, 31).getTime() - 1
											|| v == 'Q3 Jan-Mar'
											&& forecastdateA() == new Date(year, 0, 1).getTime() + 1
											&& forecastdateB() == new Date(year, 2, 31).getTime() - 1
											|| v == 'Q4 Apr-Jun'
											&& forecastdateA() == new Date(year, 3, 1).getTime() + 1
											&& forecastdateB() == new Date(year, 5, 30).getTime() - 1
										}
									)
								)
							)
						]
				)
				: []
		]
	}


	// function StatisticOptions(){
	// 	return elements.selectbuttons(
	// 		['Total Period', 'Daily Average', 'Work-Day Average']
	// 		['Total Period', 'Work-Day Average']
	// 		,dailyAverage
	// 		,{
	// 			disabled: !calculated()
	// 			,title: `Calulation representation`
	// 		}
	// 	)
	// }

	function mainViewLinks(){
		return elements.list([
			m('a', {
					href: '/data/contracts/overview/forecastdateA/'
						+ forecastdateA()
						+ '/forecastdateB/'
						+ forecastdateB()

					, oncreate: m.route.link
					, title: "Switch views"
					, disabled: !calculated()
				}
				, 'Overview'
			)
			,m('a', {
					href: '/data/contracts/contracts/view/forecastdateA/'
						+ forecastdateA()
						+ '/forecastdateB/'
						+ forecastdateB()

					, oncreate: m.route.link
					, title: "Switch views"
					, disabled: !calculated()
				}
				, 'View all Contracts'
			)


			,m.component(
				Pencil
				,() =>
					m('a', {
							href: '/data/contracts/contractitems/forecastdateA/'
								+ forecastdateA()
								+ '/forecastdateB/'
								+ forecastdateB()
							, oncreate: m.route.link
							, title: "Switch views"
							, disabled: !calculated()
						}
						, 'View Odin Summaries'
					)
				,() =>
					autocomplete.strict(
						baseItemsSelectList
						,(v) => {

							m.route.set(
								'/data/contracts/contractitems/forecastdateA/'
								+ forecastdateA()
								+ '/forecastdateB/'
								+ forecastdateB()
								+ '/ledger/'
								+ v.contractrecognitionid
							)

							invoiceWindow(v.contractrecognitionid)
							viewWindow([v.tag])
						}
						,'contractrecognitionname'
						,() => ({
							disabled: !contractItemSelections() || contractItemSelections().length == 0 || !calculated()
							,key: Math.random().toString(15).slice(2, 8)
							,style: {
								padding: `0.5em`
							}
						})
					)
			)
		])
	}

	function ContractSummary(){
		return m('div.harth.mb6', [

			m('h4','Financials')

			,missingPermissions()
				? elements.alert(
					`info`
					,'Detailed functionality requires '
					+ missingPermissions()
					+ ' permissions'
				)
				: m('hr')

			,mainViewLinks()

			,m('br')
			,elements.splitPane(
				focusViewA()
				,[
					elements.list([
						m('h4','Statistics')
						,reCalculateButton({spinner: true, forcereroute: false})
					])
					,elements.list(R.intersperse(' | ', financialSummary.map((s) => m('p.tc.b', {style:{width: '10rem'}}, s))))
					,m('br')
					,renderSummary(viewStats(), 'Revenue', dailyAverage, null, 'tabled', true, {style:{width: '10rem'}})
					,m('br')
					,renderSummary(viewStats(), 'Expenditures', dailyAverage, null, 'tabled', true, {style:{width: '10rem'}})
					,m('br')
					,renderSummary(viewStats(), 'Profits', dailyAverage, null, 'tabled', true, {style:{width: '10rem'}})
					,m('br')
					,focusViewB()
				]
			)

			,viewtype()()

		])
	}

	const ifCharts = (fe, f) => features().Charts && fe && fe.chart && f()

	function Overview(){

		const fedata =
			(fe, fep) => {

				const profits = fe.odinsum ? fe.odinsum.currentProfits : fe.currentProfits
				const fprofits = fe.odinsum ? fe.odinsum.forecastedProfits : fe.forecastedProfits
				const invoicecount = fe.invoicecount || fe.odinsum && fe.odinsum.invoicecount
				return {
					'Schedule':
						fe.project_name || fe.contractrecognitionname
						? m(`li.pb2.ml4`, fe.project_name && fe.project_name() || fe.contractrecognitionname)
						: fe.scheduleNameVersion || 'Total'
					,'Items':
						!fe.odincount && (metaDataIndexing() || !calculated() || isNaN(fe.odincount))
						? ``
						: m(`a`
							,{
								onclick:
									() => {
										;(fe.scheduleNameVersion == '' ? focussedEntities() : [fe])
										.forEach((fea) => fea.openchildren = fea.openchildren ? false : true)
									}

								, disabled: !fe.odincount
							}
							, fe.odincount
						)
					,'Invoices':
						!invoicecount && (metaDataIndexing() || !calculated() || isNaN(invoicecount))
						? ``
						: m('a'
							, {
								href: '/data/contracts/overview/forecastdateA/'
									+ forecastdateA()
									+ '/forecastdateB/'
									+ forecastdateB()
									+ '/focus/'
									+ (
										!fe.scheduleNameVersion
										&& fe.schedule_version_id != 'Overheads'
										&& !fep
										? focussedEntities().map(R.prop('schedule_version_id')).join(',')
										: fe.schedule_version_id == 'Overheads' || fep && fep.schedule_version_id == 'Overheads'
										? 'Overheads'
										: 'Overheads,' + (fe.schedule_version_id || fep.schedule_version_id)
									)
									+ '/ledger/'
									+ (
										fe.contractrecognitionid
										? fe.contractrecognitionid
										: (fe.odinitems || []).map(R.prop('contractrecognitionid')).join(',')
									)
								, oncreate: m.route.link
								, title: "View Invoices"
								, disabled: invoiceDataLoading() || !invoicecount
							}
							,invoicecount
						)
					,'Profits':
						!profits && (metaDataIndexing() || !calculated())
						? ``
						: m('p' + (!profits ? '' : profits < 0 ? '.dark-red.fw6' : '.green.fw6'), makeMoney(profits))
					,'Chart': !fprofits && !profits ? null : reChartButton({sv: fe})
				}
			}

		const chartToggles =
			(fe) =>
				m(`div`
					+ css
					`
					display: grid;
					grid-template-columns: 0.35fr 0.125fr 0.275fr 0.25fr 0.25fr;
					overflow: hidden;
					align-items: baseline;
					max-width: 24em;
					margin-left: auto;
					margin-right: 0;
					justify-items: end;
					justify-content: right;
					`
					, [
						m(
							NumberInput
							,R.merge(
								data
								,{
									errors
									,errorLabel: 'Margin'
								}
							)
							,{
								prop:
									(v) => {
										if( v ){
											fe.tp(v)
											debounceDrawChart({
												fe
												,data: calculatedData()[
													fe.schedule_version_id
													+ forecastdateA()
													+ (forecastdateB() || Infinity)
												]
												,features
											})
										}
										return fe.tp()
									}
								,attrs: {
									step: 0.1
									,min: 1
									,title: `Segreagtion percentage of values`
								}
							}
						)
						, m(`p.v-btm.pr2`, `%`)
						, m(`p.v-btm`, 'Margin')
						, m(`p.v-btm`, reChartButton({sv: fe, breakdown: 'breakdown'}))
						, m(`p.v-btm`, reChartButton({sv: fe, split: 'split'}))
					]
				)

		const largeSchedulesTable =
			({width}) =>
			focussedEntities().filter((a) => a.makeChart)
			.concat(focussedEntities().filter((a) => !a.makeChart))
			.concat(totalOverview())
			.map((fe, feindex, fearray) =>
				[
					m('.data'
						+ css(
						`
							display: grid;
							grid-template-columns: 0.25fr 0.175fr 0.075fr 0.1fr 0.3fr;
							overflow: hidden;
							gap: 1em;
							max-height: 20em;
							align-items: start;
							align-content: start;
							` + (feindex != 0 ? `padding-top: 2em;` : ``)
						)
						, [
							'Schedule'
							,'Profits'
							,'Items'
							,'Invoices'
							,'Chart'
						]
						.map((k) =>
							feindex == 0 && k != ' '
							? m('.data'
								+ css`
									display: grid;
									grid-template-rows: 0.75fr 0.25fr;
									overflow: hidden;
								`
								,[
									m('.data'
										+ css(
										`
											display: grid;
											grid-template-columns: 0.75fr 0.25fr;
											overflow: hidden;
											gap: 0.25em;
											align-items: start;
											align-content: start;
											padding-bottom: 4em;
										`)
										, [
											m('label.control-label', k)
											, k != 'Schedule'
											&& k != 'Chart' && !calculated() && k
											|| k == 'Items' && fearray.some((fea) => isNaN(fea.odincount))
											|| k == 'Invoices'
											&& fearray.some((fea) =>
												isNaN(
													!fea.scheduleNameVersion && fea.invoicecount
													|| fea.scheduleNameVersion && fea.odinsum && fea.odinsum.invoicecount
												)
											)
											|| k == 'Profits'
											&& fearray.some((fea) =>
												isNaN(
													!fea.scheduleNameVersion && fea.currentProfits
													|| fea.scheduleNameVersion && fea.odinsum && fea.odinsum.currentProfits
												)
											)
											? elements.spinner()
											: ' '
										]
									)
									, m('.data', fedata(fe)[k])
								]
							)
							: m('.data', fedata(fe)[k])
						)
					)
					,fe.openchildren
					? fe.odinitems.map((oi) =>
						m('.data'
							+ css(
							`
								display: grid;
								grid-template-columns: 0.25fr 0.175fr 0.075fr 0.1fr 0.3fr;
								overflow: hidden;
								gap: 1em;
								align-items: start;
								align-content: start;
							`)
							, [
								'Schedule'
								,'Profits'
								,''
								,'Invoices'
							]
							.map((k) => m('.data', fedata(oi, fe)[k]))
						)
					)
					: m('div', '')

					,m('br')

					,!fe.makeChart && loadchart()[fe.schedule_version_id]
					? elements.spinner()
					: ifCharts(loadchart()[fe.schedule_version_id] && fe, () => [
						chartToggles(fe)
						,fe.makeChart && fe.chart({width})
					])

				]
			)

		const smallSchedulesTable =
			({width}) =>
			focussedEntities().filter((a) => a.makeChart)
			.concat(focussedEntities().filter((a) => !a.makeChart))
			.concat(totalOverview())
			.map((fe, feindex, fearray) =>
				[
					m('.data'
						+ css(
						`
							display: grid;
							grid-template-columns: 0.35fr 0.225fr 0.15fr 0.15fr 0.125fr;
							overflow: hidden;
							gap: 1em;
							align-items: start;
							align-content: start;
							` + (feindex != 0 ? `padding-top: 4em;` : ``)
						)
						, [
							'Schedule'
							,'Profits'
							,'Items'
							,'Invoices'
							,'Chart'
						]
						.map((k) =>
							feindex == 0
							? m('.data'
								+ css`
									display: grid;
									grid-template-rows: 0.75fr 0.25fr;
									overflow: hidden;
								`
								,[
									m('.data'
										+ css(
										`
											display: grid;
											grid-template-columns: 0.75fr 0.25fr;
											overflow: hidden;
											gap: 0.25em;
											align-items: start;
											align-content: start;
											padding-bottom: 4em;
										`)
										, [
											m('label.control-label', k)
											, k != 'Schedule' && k != 'Chart' && metaDataIndexing()
											|| k != 'Schedule' && !calculated()
											|| k == 'Items' && fearray.some((fea) => isNaN(fea.odincount))
											|| k == 'Invoices'
											&& fearray.some((fea) =>
												isNaN(
													!fea.scheduleNameVersion && fea.invoicecount
													|| fea.scheduleNameVersion && fea.odinsum && fea.odinsum.invoicecount
												)
											)
											|| k == 'Profits'
											&& fearray.some((fea) =>
												isNaN(
													!fea.scheduleNameVersion && fea.currentProfits
													|| fea.scheduleNameVersion && fea.odinsum && fea.odinsum.currentProfits
												)
											)
											? elements.spinner()
											: ''
										]
									)
									, m('.data', fedata(fe)[k])
								]
							)
							: m('.data', fedata(fe)[k])
						)
					)
					,fe.openchildren
					? fe.odinitems.map((oi) =>
						m('.data'
							+ css(
							`
								display: grid;
								grid-template-columns: 0.35fr 0.225fr 0.15fr 0.15fr 0.125fr;
								overflow: hidden;
								gap: 1em;
								align-items: start;
								align-content: start;
							`)
							, [
								'Schedule'
								,'Profits'
								,''
								,'Invoices'
							]
							.map((k) => m('.data', fedata(oi, fe)[k]))
						)
					)
					: m('div', '')

					,m('br')

					,!fe.makeChart && loadchart()[fe.schedule_version_id]
					? elements.spinner()
					: ifCharts(loadchart()[fe.schedule_version_id] && fe, () => [
						chartToggles(fe)
						,fe.makeChart && fe.chart({width})
					])

					,m('hr')
				]
			)


		const scheduleTable = () =>
			m(Responsive
				,
				{
					breakpoints: {
						mobile: 0
						,desktop: 900
						,tablet: 700
						,hd: 1800
					}
				}
				,({ hd, desktopOnly, mobileOnly, tabletOnly, width }) => [
					hd( () => largeSchedulesTable({ width }) )
					,desktopOnly( () => largeSchedulesTable({ width }) )
					,mobileOnly( () => smallSchedulesTable({ width }) )
					,tabletOnly( () => smallSchedulesTable({ width }) )
				]
			)

		return m('div.harth.mb6', [

			m('h4','Financials')

			,missingPermissions()
				? elements.alert(
					`info`
					,'Detailed functionality requires '
					+ missingPermissions()
					+ ' permissions'
				)
				: m('hr')

			,mainViewLinks()

			// ,elements.list([ focusDates(), reCalculateButton({spinner: false, forcereroute: true})])

			,m('br')

			,scheduleTable()

		])
	}



	function ContractEditsOrAdditions(){
		const gotfilters =
			getfilters(null, "Contract Items")

		const processedList =
			ListProcessing(
				active.contract_items()
				, filterFunction.contractItemsFilters.filters()
				, ''
				,filteredContractItemOptions
			)

		const itemData =
			(ci) =>
				R.reduce(
					(o, i) => {
						hiddenItemData() && hiddenItemData()[i]
						? delete o[
							i == 'contract_items_service'
							? 'Servicing Rate/UoM'
							: i == 'contract_items_depreciation'
							? 'Depreciation Rate/UoM'
							: i == 'contract_items_appreciation'
							? 'Appreciation Rate/UoM'
							: i == 'contract_items_damages'
							? 'Damages Rate/Day'
							: i == 'contract_items_damages_period'
							? 'Damages Period'
							: null
						]
						: null
						return o
					}
					,{
						'Item Name': ci.contract_items_name()
						,'Description': ci.contract_items_description()
						,'Client Ref': ci.contract_items_client_reference()
						,'UoM': ci.contract_items_uom()
						,'Recorded References': ci.contract_items_recorded_reference()
						,'Standard Amount':
							ci.contract_items_recorded_reference() == 'Timesheets'
							? ci.contract_items_recorded_reference_standard()
							: 1
						,'Ledgers':
							!ci.contract_items_id()
							? 'Save prior to Invoicing'
							: invoiceDataLoading() || metaDataIndexing()
								? ` ... `
								: m('a', {
									href: '/data/contracts/contracts/edit/'
										+ ci.contract_id()
										+ '/forecastdateA/'
										+ forecastdateA()
										+ '/forecastdateB/'
										+ forecastdateB()
										+ '/ledger/'
										+ ci.contract_items_id()

									, oncreate: m.route.link
									, title: "View Invoices"
									, disabled:
										!R.propOr(0, 'invoicecount')
										(allContractItemsIndexByContractItemId()
										[ci.contract_items_id()] || {})
										|| invoiceDataLoading()
								}
								,R.propOr(0, 'invoicecount')
								(allContractItemsIndexByContractItemId()
								[ci.contract_items_id()])
							)
						,'Rate/UoM': makeMoney( ci.contract_items_rate() )
						,'Servicing Rate/UoM': makeMoney( ci.contract_items_service() )
						,'Depreciation Rate/UoM': makeMoney( ci.contract_items_depreciation() )
						,'Appreciation Rate/UoM': makeMoney( ci.contract_items_appreciation() )
						,'Damages Rate/Day': makeMoney( ci.contract_items_damages_rate() )
						,'Damages Period': ci.contract_items_damages_period() + ' Days'
						,'Budget Rate/UoM': makeMoney( ci.contract_items_budget() )
						,'Applied': ci.contract_items_applied()
							? 'Yes'
							: 'No'
						,'Profits': makeMoney(ci.currentProfits)
					}
					,[
						'contract_items_service'
						,'contract_items_depreciation'
						,'contract_items_appreciation'
						,'contract_items_damages'
						,'contract_items_damages_period'
					]
				)

		const getActivateDeleteButton =
			(ci) =>
				editContractPermissions() || editInvoicePermissions()
				? {
					onchange:
						m.withAttr('checked',
							(checked) => {
								if ( checked) {
									checkMultiple()[ci.key] = ci
									checkMultiple()[ci.key]['created'] = true
									activeItem(ci)
								} else {
									delete checkMultiple()[ci.key]
									activeItem( checkMultiple()[ Object.keys(checkMultiple())[0]] || {} )
								}

							}
						)
					,checked: checkMultiple()[ci.key] || false
					,disabled: !calculated()
					,type: 'checkbox'
				}
				: {style: {opacity:0} , disabled: true}

		const contractAdditions =
			[
				editContractPermissions()
					? m('label.control-label.w-100.pa2', 'Add ')
					: ''

				,editContractPermissions()
					? autocomplete.strict(
						ctypes
						,coption
						,'cname'
						,() => ({
							key: Math.random().toString(15).slice(2, 8)
							,style: {
								padding: `0.5em`
							}
						})
						,(value, element) => { element.value = "" }
					)
					: ''

				,editContractPermissions()
					? autocomplete.strict(
						contractItemSelections
						,(v, model) => {
							let newItem =
								makeContractItem(
									v
									,contractItemSelections()
								)
							activeItem(newItem)

							checkMultiple()[activeItem().key] = activeItem()

							;!active.contract_items()
							.find((i) => i.key == activeItem().key )
							&& activeItem().key
								? active.contract_items(
									[].concat(
										activeItem()
										,active.contract_items()
									)
								)
								: null

							model.input('')
							model.chosen(null)

						}
						,'contractrecognitionname'
						,() => ({
							disabled: !contractItemSelections() || contractItemSelections().length == 0 || !calculated()
							,key: Math.random().toString(15).slice(2, 8)
							,style: {
								padding: `0.5em`
							}
						})
						,(value, element) => {
							element.value = ""
						}
					)
					: ''
			]

		const largeItemsTable =
			() => [

				m('.data'
					+ css`
						display: grid;
						grid-template-rows: 0.25fr 0.5fr;
						grid-template-columns: 0.1fr 1fr 1fr;
						justify-content: space-between;
						margin-right: 30%;
						margin-left: 30%;
					`
					,contractAdditions
				)

				,scrollableTable.advanced({
					scoped: data.scoped
					,resize$: data.resize$
					,alwaysopen: true
					,rows:
						processedList
						.map((ci, _cindex) => ({
							key: ci.key
							,selection: getActivateDeleteButton(ci)
							,layouts: {
								expanded: ({ x, headers, i }) =>
									m('.expanded'
										+ css`
											display: grid;
											justify-content: space-between;
											grid-template-columns: 1fr;
										`
										, m('.data'
											+ css(
											`
												display: grid;
												justify-content: space-between;
												grid-template-columns: 1.25fr 1.75fr 1fr 1fr 1.25fr 1fr 1fr 1fr `
													+ (!hiddenItemData()['contract_items_service'] ? `1fr ` : ``)
													+ (!hiddenItemData()['contract_items_depreciation'] ? `1fr ` : ``)
													+ (!hiddenItemData()['contract_items_appreciation'] ? `1fr ` : ``)
													+ (!hiddenItemData()['contract_items_damages'] ? `1fr ` : ``)
													+ (!hiddenItemData()['contract_items_damages_period'] ? `1fr ` : ``)
													+ `1fr 1fr 1fr;
												overflow: hidden;
												gap: 1em;
												align-items: start;
												align-content: start;
											`)
											, headers
												.map(
													k => [
														i == 0
														? m('.data'
															+ css`
																display: grid;
																grid-template-rows: 0.25fr 0.5fr;
																overflow: hidden;
															`
															,[m('label.control-label', k), x[k]]
														)
														: m('.data'
															+ css`
																display: grid;
																overflow: hidden;
															`
															,x[k]
														)
													]
												)
										)
									)
							}
							,data: itemData(ci)
						}))
				})
			]

		const smallItemsTable =
			() => [

				m('.data'
					+ css`
						display: grid;
						grid-template-rows: 0.25fr 0.5fr;
						grid-template-columns: 0.1fr 1fr 1fr;
						justify-content: space-between;
					`
					,contractAdditions
				)

				,scrollableTable.advanced({
					scoped: data.scoped
					,resize$: data.resize$
					,alwaysopen: true
					,rows:
						processedList
						.map((ci) => ({
							key: ci.key
							,header:
								m('span'
									+ css`
										font-weight: bold;
										display: flex;
										justify-content: space-between;
										align-items: center;
										padding-bottom: 0.5em;
									`
									,m('input'
										+ css`
											width: 25px;
											height: 25px;
											margin: 0px;
										`
										.$media('(hover: hover)',
											css`
												width: 20px;
												height: 20px;
											`
										)
										,getActivateDeleteButton(ci)
									)
									,m('.data'
										+ css`
											display: grid;
											grid-template-columns: 0.25fr 0.25fr 0.25fr;
											justify-content: space-between;
											align-items: start;
											padding-right: 0.25em
										`
										,[
											'Ledgers'
											,!ci.contract_items_id()
												? 'Save prior to Invoicing'
												: invoiceDataLoading() || metaDataIndexing()
													? ` ... `
													: m('a', {
														href: '/data/contracts/contracts/edit/'
															+ ci.contract_id()
															+ '/forecastdateA/'
															+ forecastdateA()
															+ '/forecastdateB/'
															+ forecastdateB()
															+ '/ledger/'
															+ ci.contract_items_id()

														, oncreate: m.route.link
														, title: "View Invoices"
														, disabled:
															!R.propOr(0, 'invoicecount')
															(allContractItemsIndexByContractItemId()
															[ci.contract_items_id()] || {})
															|| invoiceDataLoading()
													}
													,R.propOr(0, 'invoicecount')
													(allContractItemsIndexByContractItemId()
													[ci.contract_items_id()])
												)
											,makeMoney(ci.currentProfits)
										]
										.map((k) =>
											m('.data'
												+ css`
													display: grid;
													padding-right: 1em
												`
												,k
											)
										)
									)
								)
							,layouts: {
								expanded: ({ x }) =>
									m('.expanded'
										+ css`
											display: grid;
											justify-content: space-between;
											grid-template-columns: 1fr;
										`
										,m('.data'
											+ css`
												display: grid;
												justify-content: space-between;
												grid-template-columns: 0.25fr 0.5fr 0.25fr;
												padding-bottom: 2.25em;
											`
											,['Item Name', 'Description', 'Client Ref']
											.map((k) =>
												m('.data'
													+ css`
														display: grid;
														grid-template-rows: 0.25fr 0.25fr;
														padding-right: 0.25em
													`
													,[
														m('label.control-label.f4', k)
														, k != 'Description'
															? x[k]
															: x[k] == ci.contract_items_name()
																? x[k]
																: [x[k], ci.contract_items_name()].filter(R.identity).join(' - ')

													]
												)
											)
										)


										, m('.data'
											+ css`
												display: grid;
												justify-content: space-between;
												grid-template-columns: 0.25fr 0.25fr 0.25fr 0.25fr;
												padding-bottom: 1em;
											`
											,['Applied', 'Rate/UoM', 'Budget Rate/UoM', 'UoM']
											.map((k) =>
												m('.data'
													+ css`
														display: grid;
														grid-template-rows: 0.25fr 0.25fr;
														padding-right: 0.5em;
													`
													,[m('label.control-label.f4', k), x[k]]
												)
											)
										)


										, m('.data'
											+ css`
												display: grid;
												justify-content: space-between;
												grid-template-columns: 0.25fr 0.25fr 0.25fr 0.25fr;
												padding-bottom: 1em;
											`
											,[
												'Standard Amount'
												,'Recorded References'
												,!hiddenItemData()['contract_items_damages_period'] ? 'Damages Period' : ''
												,''
											]
											.map((k) =>
												m('.data'
													+ css`
														display: grid;
														grid-template-rows: 1fr;
														padding-right: 0.5em;
													`
													,[m('label.control-label.f4', k), x[k]]
												)
											)
										)


										, m('.data'
											+ css`
												display: grid;
												justify-content: space-between;
												grid-template-columns: 0.25fr 0.25fr 0.25fr 0.25fr;
												padding-bottom: 1em;
											`
											,[
												!hiddenItemData()['contract_items_service'] ? 'Servicing Rate/UoM' : ''
												,!hiddenItemData()['contract_items_depreciation'] ? 'Depreciation Rate/UoM' : ''
												,!hiddenItemData()['contract_items_appreciation'] ? 'Appreciation Rate/UoM' : ''
												,!hiddenItemData()['contract_items_damages'] ? 'Damages Rate/Day' : ''
											]
											.map((k) =>
												m('.data'
													+ css`
														display: grid;
														grid-template-rows: 1fr;
														padding-right: 0.5em;
													`
													,[m('label.control-label.f4', k), x[k]]
												)
											)
										)
									)

							}
							,data: itemData(ci)
						}))
				})
			]

		const itemTable = () =>
			m(Responsive
				,
				{
					breakpoints: {
						mobile: 0
						,desktop: 900
						,tablet: 700
						,hd: 1800
					}
				}
				,({ hd, desktopOnly, mobileOnly, tabletOnly }) => [
					hd( largeItemsTable )
					,desktopOnly( largeItemsTable  )
					,mobileOnly( smallItemsTable )
					,tabletOnly( smallItemsTable )
				]
			)

		const textinput = ({t, p, a}) =>
			!editContractPermissions()
				? elements.plainTxt(active[p]())
				: m(''+ css`
							display: grid;
							gap: 0.25em;
						`,
						[
							elements.textInput(
								active[p]
								, R.merge(
									{
										disabled: !calculated()
									}
									, a
								)
							)
							,m('label.control-label.mb1.o-70', a, t )
						]
					)

		const invoiceinfo =
			() => {
			function openInvoiceDetailPaymentModal(){

				modalStack.add({
					title: 'Client Details'
					, view: () =>
						m('.idetails'
							+ H.css`
								padding: 2em;

								& h1,& h2,& h3,& h4,& h5,& h6 {
									margin: 0em;
								}

								& {
									display: grid;
									gap: 1em;
								}
							`
							, m(''
								+ css`
									display: grid;
									gap: 2em;
								`
								,[
									m(''
										+ css`
											display: grid;
											grid-template-columns: 0.55fr 0.45fr;
											gap: 2em 5em;
										`
										,
										textinput({
											t: `Client's Name`
											, p: 'client_organization_name'
											, a: ''
										})
										,textinput({
											t: 'Incorporation (ABN No.)'
											, p: 'client_incorporation_no'
											, a: ''
										})
										,textinput({
											t: 'Email'
											, p: 'client_email'
											, a: ''
										})
										,textinput({
											t: 'Phone'
											, p: 'client_phone'
											, a: ''
										})
									)

									,m(''
										+ css`
											display: grid;
											gap: 5em;
											grid-template-columns: 0.55fr 0.45fr;
										`
										,[
											textinput({
												t: 'Address'
												, p: 'client_address'
												, a: ''
											})

											,m(''
												+ css`
													display: grid;
													gap: 1em
													grid-template-columns: 0.5fr 0.5fr;
												`
												,[
													textinput({
														t: 'State / Province'
														, p: 'client_state'
														, a: ''
													})
													,textinput({
														t: 'Post / Zip Code'
														, p: 'client_postcode'
														, a: ''
													})
												]
											)
										]
									)
								]
							)

							,m(''
								+ css`
									display: grid;
									gap: 3em;
								`
								,[
									m('h4.tc.mb1', 'Deposit Details')


									,m(''
										+ css`
											display: grid;
											gap: 1em;
											align-items: center;
											grid-template-columns: 1fr 1fr 1fr;
											justify-items: center;
										`
										,[
											textinput({
												t: 'Bank'
												, p: 'account_bank'
												, a: {style: {"text-align": "center"}}
											})
											,textinput({
												t: 'BSB'
												, p: 'account_bsb'
												, a: {style: {"text-align": "center"}}
											})
											,textinput({
												t: 'Account'
												, p: 'account_no'
												, a: {style: {"text-align": "center"}}
											})
										]
									)
								]
							)


							,m(''
								+ H.css`
									display: grid;
									gap: 3em;

									& .form-control {
										max-width: unset;
									}
								`
								,[

									m('h4.tc.mb1', 'Terms and Conditions')

									,m('label.control-label.mb1.tc.o-70', 'Conditions')
									,elements.textArea(
										active.contract_terms
										,{
											placeholder: "Descriptions of the terms of agreement"
											,disabled: !editContractPermissions()
										}
									)

									,editContractPermissions()
									? [
										m('label.control-label.mb1.tc.o-70','Payment Term ( Days )')
										,m(
											NumberInput
											,R.merge(
												data
												,{
													errors
													,errorLabel: 'Payment Term'
												}
											)
											,{
												prop: active.contract_payment_term
												,attrs: {
													step: 1
													,min: 0
													,title: `Terms of payment measure in days from date of invoice`
													,style: {"text-align": "center"}
												}
											}
										)
									]
									: elements.list([
										m('label.control-label.mb1.tc.o-70','Payment Term ( Days )')
										,active.contract_payment_term() || 0
									])

									,editContractPermissions()
									? m('label.control-label.mb1.tc.o-70'
										+ css`
											display: grid;
											grid-auto-flow: row;
											text-align: center;
										`
										,active.contract_count_invoices()
										? 'Count Invoices Forward'
										: 'Use Structured Identifier'


										,m(`button`
											+ css`
												border-radius: 0.5em;
												border: none;
												background-color: inherit;
											`
											,{
												onclick:
													() => {
														active.contract_count_invoices(
															!active.contract_count_invoices()
														)
													}
											}
											,active.contract_count_invoices()
												? elements.switchiconNeuLeft()
												: elements.switchiconNeuRight()
										)
									)
									: null


								]
							)
						)
					, type: 'example'
					, dismissable: true
				})
			}

			let showModal = !loading()

			return showModal && m('.payment-read-only-summary'
				,elements.mobileButtonStack(
					{
						container: {
							attrs: {
								onclick: () => openInvoiceDetailPaymentModal()
							}
						}
					}
					,[
						elements.mobileButtonStack.item({
							label: [
								m(''
									+ css`
										display: none;
									`
									.tablet`
										display: grid;
										align-content: center;
									`
									, 'Enter Payment Details'
								)
							]
							,onclick(){
								openInvoiceDetailPaymentModal()
							}
						})
					]
				)
			)
		}

		return m('div.harth.mb6', [

			m('br')
			,route_view_type() == 'Make Ledgers'
			|| route_view_type() == 'All Ledgers'
				? viewtype()()
				: [
					m('h4'
						,(
							loading()
								? ''
								: editContractPermissions()
									? 'Edit - '
									: 'View - '
						)
						+ ( active.contract_name() || '' )
					)

					,missingPermissions()
						? elements.alert(
							`info`
							,'Detailed functionality requires '
							+ missingPermissions()
							+ ' permissions'
						)
						: null

					,m('hr')
					,mainViewLinks()

					,elements.splitPane(focusViewA(), focusViewB())

					,!calculated()
						? elements.alert('warning', `Calculating the monetary value of ` + active.contract_name() + ` under the focused criteria`)
						: null

					,m('hr')

					,elements.list([

						active.contract_id()
							? elements.backAnchor(
								`Back to Contract List`
								,null
								,{
									href: '/data/contracts/contracts/view/forecastdateA/'
										+ forecastdateA()
										+ '/forecastdateB/'
										+ forecastdateB()
									, oncreate: m.route.link
									, disabled: !calculated()
								}
							)
							: m('a.btn.btn-warning', {
									href: '/data/contracts/contracts/view/forecastdateA/'
										+ forecastdateA()
										+ '/forecastdateB/'
										+ forecastdateB()
									, oncreate: m.route.link
									, title: "Discard creation"
								}
								, 'Discard'
							)

						,editContractPermissions()
							? elements.action(
								'Save'
								, sendContracts
								, savingProp
								, 'primary'
								, () => saveDisabled(true) || checkContractItem(true) || contractIdentical() || !calculated()
							)
							: null

						,editContractPermissions() && active.contract_id()
							? m('button.btn.btn-warning'
								, {
									onclick: () => {
										errors({})
										contract(
											focussedContracts().find((c) =>
												c.contract_id == route_contract_id()
											)
										)
										deleteContractItems([])
									}
									, disabled: contractIdentical() || !calculated()
									, title: "Discard Changes since last save"
								}
								, 'Discard Changes'
							)
							: null
					])

					,saveDisabled()

					,elements.splitPane(
						[

							m('br')
							,m(''+css`
								display: flex;
								align-items: center;
								gap: 2em;
								margin-bottom: 3em;
								flex-direction: column;
								`
								,[
									m('label.control-label.mb1.tc','Contract Name'
										,!editContractPermissions()
										? elements.plainTxt(active.contract_name())
										: m.component(
											Pencil
											,() => active.contract_name()
											,() =>
												elements.textInput(
													active.contract_name
													, {
														disabled: !calculated()
													}
												)
										)
									)
									,m('label.control-label.mb1.tc','Contract Description'
										,!editContractPermissions()
										? elements.plainTxt(active.contract_description())
										: m.component(
											Pencil
											,() => active.contract_description()
											,() =>
												elements.textInput(
													active.contract_description
													, {
														disabled: !calculated()
													}
												)
										)
									)

									,m('label.control-label.mb1.tc','Contract Start Date'
										,!editContractPermissions()
										? elements.plainTxt( moment( active.contract_start_date() ).format("ll"))
										: m.component(
											Pencil
											,() => moment( active.contract_start_date() ).format("ll")
											,() =>
												elements.dateInput(
													active.contract_start_date
													,{
														title: `Start date of the contract`
														,disabled: !calculated()
													}
												)
										)
									)

									,m('label.control-label.mb1.tc','Contract End Date'
										,!editContractPermissions()
										? elements.plainTxt(moment( active.contract_end_date() ).format("ll"))
										: m.component(
											Pencil
											,() => moment( active.contract_end_date() ).format("ll")
											,() =>
												elements.dateInput(
													active.contract_end_date
													,{
														title: `End date of the contract`
														,disabled: !calculated()
													}
												)
										)
									)

								]
							)

							,m(''+css`
									display: flex;
									align-items: center;
									gap: 0.5em;
									justify-content: center;
								`
								, invoiceinfo()
							)
						]
						,[
							elements.list([
								m('h4','Statistics')
								,reCalculateButton({spinner: false, forcereroute: true})
								// ,StatisticOptions()
							])
							,m('br')
							,renderSummary(contractItemSums() || {}, 'Revenue', dailyAverage, null, 'tabled', true, {style:{width: '10rem'}})
							,m('br')
							,renderSummary(contractItemSums() || {}, 'Expenditures', dailyAverage, null, 'tabled', true, {style:{width: '10rem'}})
							,m('br')
							,renderSummary(contractItemSums() || {}, 'Profits', dailyAverage, null, 'tabled', true, {style:{width: '10rem'}})
							,m('br')
							,elements.list(R.intersperse(' | ', financialSummary.map((s) => m('p.tc', {style:{width: '10rem'}}, s))))
						]
					)

					,m('hr')
					,m('h4','Contract Items')

					,!contract()
					|| contract().contract_items.length
					&& !active.contract_items().length
					&& !deleteContractItems.length
						? elements.centeredSpinner()
						: [

							active.contract_items().length
							? elements.list([
								gotfilters
								,contractItemsEditAll()
							])
							: null

							,!active.contract_items().length
							? m('.data'
								+ css`
									display: grid;
									grid-template-rows: 0.25fr 0.5fr;
									grid-template-columns: 0.1fr 1fr 1fr;
									justify-content: space-between;
									margin-right: 30%;
									margin-left: 30%;
									margin-top: 5em
								`
								,contractAdditions
							)
							: checkContractItem()

							,m('br')
							,duplicatedContractItem(true)
							? elements.alert(
								`warning`
								,elements.details(
									`Duplicated Items`
									,duplicatedContractItem(true)
								)
							)
							: m('br')

							,active.contract_items().length > 0
								? itemTable()
								: null
						]


				]
		])
	}




	const baseStats = {
		currentRevenue: 0
		,currentExpenditures: 0
		,currentProfits: 0
		,forecastedRevenue: 0
		,forecastedExpenditures: 0
		,forecastedProfits: 0
		,outstandingRevenue: 0
		,outstandingExpenditures: 0
		,outstandingProfits: 0
		,currentDailyPeriodRevenue: 0
		,currentDailyPeriodExpenditures: 0
		,forecastedDailyPeriodRevenue: 0
		,forecastedDailyPeriodExpenditures: 0
		,currentDailyPeriodProfits: 0
		,forecastedDailyPeriodProfits: 0
		,outstandingDailyPeriodRevenue: 0
		,outstandingDailyPeriodExpenditures: 0
		,outstandingDailyPeriodProfits: 0
		,currentDailyRevenue: 0
		,currentDailyExpenditures: 0
		,forecastedDailyRevenue: 0
		,forecastedDailyExpenditures: 0
		,currentDailyProfits: 0
		,forecastedDailyProfits: 0
		,outstandingDailyRevenue: 0
		,outstandingDailyExpenditures: 0
		,outstandingDailyProfits: 0
		,contractedDailyPeriodProgress: 0
		,contractedProgress: 0
		,contractedDailyProgress: 0
		,accruedExpenditures: 0
		,accruedRevenue: 0
		,accruedProfits: 0
		,accruedDailyRevenue: 0
		,accruedDailyExpenditures: 0
		,accruedDailyPeriodRevenue: 0
		,accruedDailyPeriodExpenditures: 0
		,accruedDailyProfits: 0
		,accruedDailyPeriodProfits: 0
		,budget: 0
	}
	var filterFunction = {
		changeNames: [
			{ugly_name: 'contractrecognitionname', better_name: "Name"}
			,{ugly_name: 'contractrecognitiondescription', better_name: "Description"}
			,{ugly_name: 'currentRevenue', better_name: 'Current Revenue'}
			,{ugly_name: 'forecastedRevenue', better_name: 'Forecasted Revenue'}
			,{ugly_name: 'forecastedExpenditures', better_name: 'Forecasted Expenditures'}
			,{ugly_name: 'currentExpenditures', better_name: 'Current Expenditures'}
			,{ugly_name: 'currentProfits', better_name: 'Current Revenue'}
			,{ugly_name: 'forecastedProfits', better_name: 'Forecasted Revenue'}
			,{ugly_name: 'contractedProgress', better_name: 'Contracted Progress'}
			,{ugly_name: 'outstandingRevenue', better_name: "Requisition Revenue"}
			,{ugly_name: 'outstandingExpenditures', better_name: "Requisition Expenditures"}
			,{ugly_name: 'outstandingProfits', better_name: "Requisition Profits"}
			,{ugly_name: 'currentDailyPeriodRevenue', better_name: 'Current Daily (Total) Revenue'}
			,{ugly_name: 'currentDailyPeriodExpenditures', better_name: 'Current Daily (Total) Expenditures'}
			,{ugly_name: 'forecastedDailyPeriodRevenue', better_name: 'Forecasted Daily (Total) Revenue'}
			,{ugly_name: 'forecastedDailyPeriodExpenditures', better_name: 'Forecasted Daily (Total) Expenditures'}
			,{ugly_name: 'currentDailyPeriodProfits', better_name: 'Current Daily (Total) Revenue'}
			,{ugly_name: 'forecastedDailyPeriodProfits', better_name: 'Forecasted Daily (Total) Revenue'}
			,{ugly_name: 'contractedDailyPeriodProgress', better_name: 'Contracted Daily (Total) Progress'}
			,{ugly_name: 'outstandingDailyPeriodRevenue', better_name: "Requisition Daily (Total) Revenue"}
			,{ugly_name: 'outstandingDailyPeriodExpenditures', better_name: "Requisition Daily (Total) Expenditures"}
			,{ugly_name: 'outstandingDailyPeriodProfits', better_name: "Requisition Daily (Total) Profits"}
			,{ugly_name: 'currentDailyRevenue', better_name: 'Current Daily (Working) Revenue'}
			,{ugly_name: 'currentDailyExpenditures', better_name: 'Current Daily (Working) Expenditures'}
			,{ugly_name: 'forecastedDailyRevenue', better_name: 'Forecasted Daily (Working) Revenue'}
			,{ugly_name: 'forecastedDailyExpenditures', better_name: 'Forecasted Daily (Working) Expenditures'}
			,{ugly_name: 'currentDailyProfits', better_name: 'Current Daily (Working) Revenue'}
			,{ugly_name: 'forecastedDailyProfits', better_name: 'Forecasted Daily (Working) Revenue'}
			,{ugly_name: 'contractedDailyProgress', better_name: 'Contracted Daily (Working) Progress'}
			,{ugly_name: 'outstandingDailyRevenue', better_name: "Requisition Daily (Working) Revenue"}
			,{ugly_name: 'outstandingDailyExpenditures', better_name: "Requisition Daily (Working) Expenditures"}
			,{ugly_name: 'outstandingDailyProfits', better_name: "Requisition Daily (Working) Profits"}
			,{ugly_name: 'invoice_client_reference', better_name: "Payment Reference"}
			,{ugly_name: 'accruedExpenditures', better_name: "Accrued Expenditures" }
			,{ugly_name: 'accruedRevenue', better_name: "Accrued Revenue" }
			,{ugly_name: 'accruedProfits', better_name: "Accrued Profits" }
			,{ugly_name: 'accruedDailyRevenue', better_name: "Accrued Daily (Working) Revenue" }
			,{ugly_name: 'accruedDailyExpenditures', better_name: "Accrued Daily (Working) Expenditures" }
			,{ugly_name: 'accruedDailyProfits', better_name: "Accrued Daily (Working) Profits" }
			,{ugly_name: 'accruedDailyPeriodRevenue', better_name: "Accrued Daily (Total) Revenue" }
			,{ugly_name: 'accruedDailyPeriodExpenditures', better_name: "Accrued Daily (Total) Expenditures" }
			,{ugly_name: 'accruedDailyPeriodProfits', better_name: "Accrued Daily (Total) Profits" }
		]
		,uniqueArrays: [
			{array_name: 'contract_items', unique: "contract_items_id"}
		]
		,template:
			R.merge(
				{
					contract_name: ""
					,supplier_name: ""
					,contract_description: ""
					,contract_payment_term: 0
					,contract_count_invoices: true
					,contract_start_date: "date"
					,contract_end_date: "date"
					,client_organization_name: ""
					,client_incorporation_no: ""
					,client_email: ""
					,client_phone: ""
					,client_address: ""
					,client_state: ""
					,client_postcode: ""
					,account_bsb: ""
					,account_no: ""
					,account_bank: ""
					,contract_terms: ""
					,contract_items: [
						R.merge(
							{
								contract_items_name: ""
								,contract_items_description: ""
								,contract_items_client_reference: ""
								,contract_items_budget: 0
								,contract_items_rate: 0
								,contract_items_service: 0
								,contract_items_depreciation: 0
								,contract_items_appreciation: 0
								,contract_items_uom: ""
								,contract_items_damages_rate: 0
								,contract_items_damages_period: 0
								,contract_items_applied: true
							}
							,baseStats
						)
					]
				}
				,baseStats
			)
		,baseTemplate:
			R.merge(
				{
					contractrecognitionname: ""
					,contractrecognitiondescription: ""
				}
				,baseStats
			)
		,invoiceTemplate: {
			supplier_name: ""
			,invoice_type: ""
			,invoice_date: "date"
			,invoice_received_date: "date"
			,invoice_due_date: "date"
			,project_name: ""
			,invoice_no: ""
			,invoice_inv_id:""
			,invoice_received: true
			,invoice_name: ""
			,invoice_description: ""
			,invoice_client_reference: ""
			,invoice_items_client_reference: ""
			,invoice_uom: ""
			,invoice_amount_total: 0
			,invoice_total: 0
			,invoice_reversal: true
			,invoice_amount: 0
			,invoice_service_amount: 0
			,invoice_depreciation_amount: 0
			,invoice_appreciation_amount: 0
			,invoice_damages_amount: 0
			,invoice_rate: 0
			,invoice_service_rate: 0
			,invoice_depreciation_rate: 0
			,invoice_appreciation_rate: 0
			,invoice_damages_rate: 0
			,invoice_approval: true
			,invoice_approver: ""
		},
	}


	var getfilters = function(button, marker){
		let datainput =
			marker == "Contract"
				? filterFunction.contractFilters
				: marker == "Contract Items"
					? filterFunction.contractItemsFilters
					: marker == "Cross Contracts"
						? filterFunction.allContractItemFilters
						: filterFunction.invoiceFilters

		if (datainput == null){
			filterFunction.allContractItemFilters = {
				filters: m.prop([])
				,operationalFilters: m.prop([])
				,changeNames: filterFunction.changeNames
				,uniqueArrays: filterFunction.uniqueArrays
				,template: filterFunction.baseTemplate
				,disabled: () => contractEntities().length == 0
			},
			filterFunction.contractFilters = {
				filters: m.prop([])
				,operationalFilters: m.prop([])
				,changeNames: filterFunction.changeNames
				,uniqueArrays: filterFunction.uniqueArrays
				,template: filterFunction.template
				,disabled: () => focussedContracts().length == 0
			},
			filterFunction.contractItemsFilters = {
				filters: m.prop([])
				,operationalFilters: m.prop([])
				,changeNames: filterFunction.changeNames
				,uniqueArrays: filterFunction.uniqueArrays
				,template: filterFunction.template.contract_items[0]
				,disabled: () =>
					!active.contract_items()
					||  active.contract_items && !active.contract_items().length
			}
			filterFunction.invoiceFilters = {
				filters: m.prop([])
				,operationalFilters: m.prop([])
				,changeNames: filterFunction.changeNames
				,uniqueArrays: filterFunction.uniqueArrays
				,template: filterFunction.invoiceTemplate
				,disabled: () => !focussedInvoices() || focussedInvoices().length == 0
			}
			datainput =
				marker == "Contract"
					? filterFunction.contractFilters
					: marker == "Contract Items"
						? filterFunction.contractItemsFilters
						: marker == "Cross Contracts"
							? filterFunction.allContractItemFilters
							: filterFunction.invoiceFilters
		}
		let gotfilters = datainput == null ? null : datainput.operationalFilters(
			filterView( datainput.template, datainput.filters(), datainput.changeNames, datainput.uniqueArrays, datainput.disabled, marker, data.scoped )
		)
		datainput != null ? datainput.filters( gotfilters.filters ) : null
		return gotfilters.generatedElements
	}

	prop.merge(
		[
			currentVersion
		]
	)
	.map(function([sv]){
		if ( !recordedFocus().schedules.length ){
			const initFocus =
				sv && sv.schedule_version_id
				? schedules().map((s) => s.schedule_versions[0]).filter(R.identity)
				: []

			focussedEntities(
				[].concat(
					overHEadVersion
					,initFocus
				)
			)

			return recordedFocus({
				schedules: focussedEntities()
					.map((a) => ({
						snv: a.scheduleNameVersion
						,dReceived: {
							coalatedSelectedVersion: false
							,possibleContractEntities: false
							,focussedInvoices: false
							,allContractItems: false
							,allContracts: false
						}
						,schedule_version_id: a.schedule_version_id
					}))
				,dateA: forecastdateA()
				,dateB: forecastdateB()
			})
		}
		return true
	})


	/* eslint-disable */
	prop.merge(
		[
			initiateContractFetch
		]
	)
	.map(function([i]){

		const redirection =
			!data.routeInitialized()['financials']
			&& !i.ochange
			&& !i.schange

		const schange = i.schange || !data.routeInitialized()['financials']
		const ochange = i.ochange || !data.routeInitialized()['financials']
		const firstLoad = currentRoute() == 'Nothing'

		if( ochange || schange || redirection){

			recordedFocus().schedules = []
			loading(true)
			metaDataIndexing(true)
			metaDataLoading(true)
			invoiceDataLoading(false)
			routeids({
				routeids: []
				, getledgers: false
				, financialsubsets: []
				, ledgerdetails:false
				, getmatdata: false
				, specificproject: null
			})
			currentRoute('financials')
		}


		if(
			routeParam().viewType == 'overview'
			|| (routeParam().viewType == 'contractitems' && (redirection || firstLoad))
			|| !viewWindow().length
		){
			viewWindow(
				routeParam().viewType == 'overview'
				? [
					'Projects'
					,'Resources'
					// ,'Teams'
					// ,'Team Capabilities'
					,'Tools'
					,'Users'
					,'Warehouses'
					,'Odin Modules'
				]
				: []
			)
		}


		if( ochange ){
			data.focussedContracts([])
			data.contractEntities([])
			data.contracts([])
		}


		(
			redirection || firstLoad
			|| (routeParam().viewType == 'overview' && !routeParam().invoiceType)
			? Promise.all(
				[overHEadVersion]
				.concat(
					scheduleData
					.schedules().map((s) => s.schedule_versions[0])
					.filter(R.identity)
				)
				.map((sv) =>
					fetchCalculations({
						startDate: forecastdateA()
						,endDate: forecastdateB()
						,currentRoute: 'financials'
						,scheduleonly: sv.schedule_version_id == "Overheads" ? '' : true
						,getchartdata:
							routeParam().viewType == 'overview'
							&& loadchart()[sv.schedule_version_id]
							? 'getcharts'
							: ''
						,nochildren: true
						,scheduleList: [
							{
								scheduleNameVersion: sv.scheduleNameVersion
								,schedule_priority: sv.schedule_priority
								,schedule_version_created: sv.schedule_version_created
								,schedule_version_id: sv.schedule_version_id
								,schedule_version_old_id: sv.schedule_version_old_id
								,schedules_parameters_id: sv.schedules_parameters_id
								,schedule_id: sv.schedule_id
							}
						]
					})
				)
			)
			: Promise.resolve([])
		)
		.then(function(){

			loading(false)
			m.redraw()
			if( ochange || schange ){
				errors({})
				deleteInvoiceItems([])
				deleteContractItems([])
				reMapdateChange(false)

			}

			return (
				ochange
				? fetchContracts({})
				: Promise.resolve(contracts())
			)
			.then(function(res){
				data.contracts(R.concat(res, [disconnectedcontract]))
				data.focussedContracts(res)
				calculated(true)
				loading(false)

				return Promise.all([
					!ochange ? [] : fetchResource({
						props: {
							resources: [
								'contractor_id'
								,'contractor_name'
								,'user_id'
							]
							,teams: [
								'crew_id'
								,'crew_name'
								,'contractor_id'
								,'user_id'
							]
							,rates: [
								'crews_discipline_rates_id'
								,'crews_discipline_rates_name'
								,'crews_discipline_rates_uom'
								,'organizations_disciplines_id'
								,'crew_id'
							]
						}
					})
						.then(resources)
					,(i.schange || ochange)
					&& i.schedule_id
					? fetchProjects({
						completedProjects: "notcompleted"
						, depth: 1
						, props: {
							projects: [
								'project_id'
								,'project_name'
								,'schedule_id'
							]
							,disciplines: [
								'discipline_id'
								,'discipline_name'
								,'organizations_disciplines_id'
								,'project_id'
								,'discipline_contract_multiplier'
							]
						}
					})
						.then(projects)
					: []
					,!ochange ? [] : fetchUsers()
						.then(
							R.concat([ nullUser ])
						)
						.then(users)
					,!ochange ? [] : fetchWarehouses({
						depth: 1
						, props: {
							warehouses: [
								'warehouse_id'
								,'warehouse_name'
							]
						}
					})
						.then(warehouses)
					,!ochange ? [] : fetchTools({
						depth: 1
						, props: {
							tools: [
								'tools_name'
								,'tools_id'
							]
						}
					})
						.then(tools)
					, []
					,!ochange ? [] : fetchOrganizationsDisciplines({
						depth: 1
						, props: {
							organizations_disciplines: [
								'organizations_disciplines_name'
								,'organizations_disciplines_id'
								,'organizations_disciplines_uom'
							]
						}
					})
						.then(organizations_disciplines)
				])
				.then(function(data){
					i.ochange = false
					i.schange = false

					if(ochange || redirection || firstLoad){
						reMapdateChange(false)
						metaDataLoading(false)
					}

					return true
				})

			})
		})
	})

	const forceRedraw =
		asyncMergeAll(
			[
				viewStats
				,dailyAverage
				,calculated
				,errors
				,editContractPermissions
				,editInvoicePermissions
				,contractItemSums
				,checkMultiple
				,focussedInvoices
				,focussedContracts
				,route_original_contract_id
				,metaDataIndexing
				,invoiceDataLoading
				,loading
				,hiddenInvoiceData
			]
		).map(function(){
			setTimeout(function(){
				m.redraw()
			}, 0)
		})


	const dataProcessing = asyncMergeAll(
		[
			indexedContractEntities
			,focussedInvoices
			,focussedContracts
			,allContractItemsIndexByContractItemId
			,loading
		]
	).map(([e, c, s, l]) => {

		const done =
			recordedFocus().schedules.every((sch) => {
				const loaded =
					route_view_type() == 'All Items'
					|| route_view_type() == 'Overview'
					? sch.dReceived['possibleContractEntities']
					: route_view_type() == 'All Ledgers'
					? sch.dReceived['focussedInvoices']
					: route_view_type() == 'All Contracts'
					&& (
						m.route.param('contract_id')
						|| m.route.get().indexOf('create') > - 1
						|| m.route.get().indexOf('clone') > -1
					)
					? sch.dReceived['allContractItems']
					: sch.dReceived['allContracts']

				sch.loaded = loaded

				return loaded
			})
				? calculated(true)
				: calculated(false)

		if (done){

			totalOverview({
				scheduleNameVersion: ''
				,currentProfits: R.sum(focussedEntities().map(R.path(['odinsum', 'currentProfits'])))
				,odincount: R.sum(focussedEntities().map(R.path(['odinitems', 'length'])))
				,invoicecount: R.sum(focussedEntities().map(R.path(['odinsum', 'invoicecount'])))
				,odinitems: R.unnest(focussedEntities().map(R.path(['odinitems'])))
			})

			reMapdateChange(true)
		}

		return done
	})


	/* eslint-enable */

	try {

		let {
			viewType
			,modeType
			,invoiceType
			,contractId
			,focus
		} = routeParam()


		errors({})
		// reMapSelectedVersion(false)

		// var fdateA = m.route.param('forecastdateA')
		// var fdateB = m.route.param('forecastdateB')

		// if(fdateA){
		// 	forecastdateA(Number(fdateA))
		// }

		// if(fdateB){
		// 	forecastdateB(Number(fdateB))
		// }

		if(viewType != route_view_type()){
			selectedInvoices([])
			activeItem({})
			checkMultiple({})
			checkMultipleContracts({})
			errors({})
		}

		if ( modeType == 'create' ) {
			active.contract_id(null)
			active.contract_name(null)
			active.contract_description(null)
			active.contract_payment_term(42)
			active.contract_count_invoices(true)
			active.contract_start_date( new Date().getTime() )
			active.contract_end_date( new Date().getTime() + 365 * milliperday )
			active.client_organization_name(null)
			active.client_incorporation_no(null)
			active.client_email(null)
			active.client_phone(null)
			active.client_address(null)
			active.client_state(null)
			active.client_postcode(null)
			active.account_bsb(null)
			active.account_no(null)
			active.account_bank(null)
			active.account_bic(null)
			active.account_iban(null)
			active.account_swift_code(null)
			active.contract_terms(null)
			route_original_contract_id( null )
		} else if (modeType == 'clone') {
			route_original_contract_id( contractId )
		} else if (modeType == 'edit') {
			route_contract_id( contractId )
			route_original_contract_id( null )
		} else {
			route_contract_id( null )
			route_original_contract_id( null )
		}

		focus_schedules(focus.split(','))

		if ( invoiceType ){
			invoiceDataLoading(false)
			route_view_type('All Ledgers')
			invoiceWindow(R.uniq(invoiceType.split(',')))
			focussedInvoices([])
			prevview(viewType)
			m.redraw()
		} else if ( viewType == 'contracts' ){
			route_view_type('All Contracts')
			invoiceWindow('')
			focussedInvoices([])
			prevview(viewType)
			reMapdateChange(false)
			m.redraw()
		} else if ( viewType == 'contractitems' ) {
			// route_view_type() != 'All Ledgers'
			// || prevview() == 'overview'
			// 	? viewWindow(['Projects'])
			// 	: null

			route_view_type('All Items')
			invoiceWindow('')
			focussedInvoices([])
			prevview(viewType)
			// reMapdateChange(false)
			m.redraw()
		} else if ( viewType == 'overview') {
			invoiceWindow('')
			if( !metaDataLoading() ){
				viewWindow([
					'Projects'
					,'Resources'
					,'Teams'
					,'Team Capabilities'
					,'Tools'
					,'Users'
					,'Warehouses'
					,'Odin Modules'
				])
				reMapdateChange(false)
			}
			route_view_type('Overview')
			focussedInvoices([])
			prevview(viewType)
			m.redraw()
		}


	} catch (e) {
		debugger
	}


	return {
		view(){
			return m('div', [
				route_contract_id()
				|| m.route.get().indexOf('create') > - 1
				|| m.route.get().indexOf('clone') > -1
					? ContractEditsOrAdditions()
					: m.route.get().indexOf('overview') > -1
					&& m.route.get().indexOf('ledger') <= -1
					? Overview()
					: ContractSummary()
				,DetailsPane(
					detailsPaneOpen
					,Object.keys(checkMultiple()).length > 1
					|| selectedInvoices().length > 1 && route_view_type() != 'Make Ledgers'
						? 'Edit Multiple Items'
						: route_view_type() == 'All Contracts' && activeItem().contract_items_name
							? activeItem().contract_items_name()
							: activeItem().invoice_name && route_view_type() == 'All Ledgers'
								? activeItem().invoice_name()
								: route_view_type() == 'All Contracts'
									? 'Value Summation of Selected Contracts'
									: 'Value Summation of Selected Items'
					,() => detailPaneInputs()
				)
			])
		}
	}
}



export default contractsRoute
