/* globals innerWidth */

import m from 'bacta'
import * as R from 'ramda'

import moment from 'moment'
import css from '../../utils/css'

const {percent, em, px, vw, seconds} = css.units
const {translate, translateY} = css.funcs
import colors from '../../utils/colors'

/**
 *
 *	A touch and mouse friendly date/time indicator.
 *	Contextually shows years/months/days based on your zoom level.
 *
 *  Refer to ./readme.md for more information.
 */

export default function Dates({
	container
	, dateContainer
	, pointer
	, xScale
	, currentTime
	, redraw
	, DAY_MILLIS
}){

	const containerDimensions =
		container.map(function(el){
			const { width,height } =
				el.getBoundingClientRect()

			return {
				width
				,height
			}

		})

	const daysWidth = (days) => days * xScale * pointer.scale()

	const itemsRequired = (days, container) =>
		(
			container
			? container.width / daysWidth(days)
			: innerWidth / daysWidth(days)
		)
		// if it doesn't evenly divide we need to see the oncoming item
		+ 1

	const style = {
		main: () => R.merge(
			{
				left: em(0)
				,position: css.position.absolute
				,width: percent(100)
				,height: '3em'
				,backgroundColor: colors.mid
				,overflow: css.overflow.hidden
				,borderTop: '1px solid #1a2154'
				,boxSizing: 'content-box'
			}
			, { bottom: em(0) }
		)
		,container:function(){
			return {
				transform: translate(
					px(-currentTime()/DAY_MILLIS * xScale * pointer.scale()),
					px(0)
				)
			}
		}
		,label: (label) => {
			// const fontSize = R.clamp(1, 40, pointer.scale * label.size)

			const fontSize = R.clamp(1, 4)(pointer.scale() * label.size)

			return {

				lineHeight: em(3 / fontSize)
				,fontSize: em(fontSize)
				,textAlign: css.textAlign.center
				,position: css.position.absolute
				,transform: translate(
					px( label.day * xScale * pointer.scale() )
					,px(0)
				)
				,width: px( label.days * xScale * pointer.scale() )
			}
		}
		,currentTime: () => ({
			position: css.position.absolute
			,top: em(0)
			,left: em(0)
			,width: em(1)
			,height: vw(100)
		})
	}

	const lessThan = R.flip(R.lt)

	const monthVisible = pointer.scale.map(lessThan(3.8))

	const dayVisible = monthVisible.map(R.not)

	const Visibility = ({offY, onY, toggleOpacity}) => function(visible){

		return {
			opacity: toggleOpacity ? visible ? 1 : 0 : 1
			, transform: translateY(
				3 * (visible ? onY : offY) + 'em'
			)
			,transition: seconds(0.5)
		}
	}

	const monthVisibility = monthVisible.map(
		Visibility({offY:-1, onY:0, toggleOpacity: false})
	)
	const dayVisibility = dayVisible.map(
		Visibility({offY:1, onY:0, toggleOpacity: true})
	)
	const Labels = ({type, format, days, size, indexes}) =>
		function(currentTime) {
			return indexes().map(function(i){
				const m = moment(currentTime)
					.startOf(type)
					.add( moment.duration(i, type) )

				return {
					moment: m
					, day: m / DAY_MILLIS
					, days: days(m)
					, label: m.format(format())
					, size: size()
				}
			})
		}

	const dayLabels = currentTime.map(
		Labels({
			format: () =>
				pointer.scale() > 15 ? 'Do MMMM YYYY'
				:  pointer.scale() > 12 ? 'Do MMMM'
				:  pointer.scale() > 10 ? 'Do MMM'
				: 'DD/MM'
			, days: R.always(1)
			, type: 'day'
			, size: () => 0.01
			, indexes: () =>
				dayVisible()
				? R.range(0,
					// how many days can fit in the screen width
					itemsRequired(1, containerDimensions())
				)
				: []
		})
	)

	const monthLabels = currentTime.map(
		Labels({
			type: 'month'
			, format: () => "MMMM YYYY"
			, days: m => m.endOf('month').date()
			, size: R.always(0.2)
			, indexes:
				R.pipe(
					() => containerDimensions()
					// how many months fit in the screen?
					, d => itemsRequired(31, d)
					// build a list of indexes [1-n]
					, R.range(0)
				)
		})
	)

	monthLabels.map(function(){
		return redraw()
	})

	return function(){
		return m('div.datebar',
			{ oncreate: ({ dom }) => dateContainer(dom), style: style.main() }
			,m('div.time-container', { style: style.container() }
				,m('div', { style: monthVisibility() }
					,(monthLabels() || []).map(
						l => m('div', {
							style: style.label(l)
							,key: l.moment.valueOf()
						}, l.label)
					)
				)
				,m('div', { style: dayVisibility() }
					,(dayLabels() || []).map(
						l => m('div', {
							style:style.label(l)
							,key: l.moment.valueOf()
						}, l.label)
					)
				)
				,m('div', { style: style.currentTime() })
			)
		)
	}
}