import m from 'bacta'
import css from '../../utils/css'
import Pointer from 'pointer-stream'
import disableEvent from '../../utils/disable_event'
import Dates from './dates'
import Gantt from './gantt'
import { prop as stream } from '../../../../stream'
import colors from '../../utils/colors'

const { percent } = css.units
const xScale = 10
const DAY_MILLIS = 86400000

export default function (universe) {
	const container = universe.scoped()
	const dateContainer = universe.scoped()
	const routeState = universe.routeState

	const pointer = Pointer({
		offset: { x: 0, y: 0 }
		, scale: { min: 0.7, max: 40 }
		, container: container
		, manualActivation: true
	})

	const routeParams =
		// todo-james superouter injects trailing slash after query params
		m.route.parseQueryString( m.route.get().replace(/\//g,'') )

	stream.once(container).map(pointer.activateListeners)

	const now = Number(routeParams['currentTime'] || new Date().getTime())

	pointer.dragging(true)
	pointer.movement({
		x: 0
		, y: [routeParams['y']]
			.map(Number).filter(Number.isFinite).concat(0).shift()
	})
	pointer.dragging(false)

	pointer.scale(
		Number(routeParams['scale']) || 1
	)
	pointer.initialPinchScale(
		pointer.scale()
	)


	universe.scoped().end.map(function () {
		return pointer.end(true)
	})

	pointer.coords.map(function () {
		return universe.redraw()
	})

	const currentTime =
		stream.merge([
			stream.fromFlydStream(pointer.scale)
			, stream.fromFlydStream(pointer.coords)
		])
			.map(function ([scale, { x }]) {
				const xAsTime = x / xScale / scale * DAY_MILLIS
				return now - xAsTime
			})

	const world = Object.assign({
		pointer
		, dateContainer
		, container
		, DAY_MILLIS
		, currentTime
		, now
		, xScale
		, routeState
	}, universe)

	const style = {
		gantt: _ => (
			{
				color: 'white'
				, width: percent(100)
				, height: percent(100)
				, cursor: pointer.mousedown()
					? '-webkit-grabbing'
					: '-webkit-grab'
			}
		)
		, body:
		{
			backgroundColor: colors.blackboard
		}
	}

	const datebar = Dates(world)

	const gantt = Gantt(world)

	return function () {
		return m('div.gantt-index'
			, {
				style: style.gantt()
				, onupdate: ({ dom }) => container(dom)
				, oncontextmenu: disableEvent
			}
			, gantt()
			, datebar()
		)
	}
}