import * as R from 'ramda'

function byProp(prop){
    return R.pipe(
        R.prop(prop),  //get the property from the object
        R.nAry(0), //ensure no values are passed in when we call it
        R.call //call it to get the value
    )

}

export default byProp