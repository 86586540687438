function pointAsBounds({x,y}) {
	return [ y, x, y, x]
}

function normalize([top,left,bottom,right] ){
	const [TOP,BOTTOM] =
		top > bottom
		? [bottom,top]
		: [top,bottom]

	const [LEFT,RIGHT] =
		left > right
		? [right,left]
		: [left,right]

	return [TOP, LEFT, BOTTOM, RIGHT]
}

function contains(bounds, point){
	return intersects(bounds, pointAsBounds(point) )
}

function intersects(a, b){
	return !outside(a, b)
}

function outside (
	[top,left,bottom,right]
	,[TOP,LEFT,BOTTOM,RIGHT]
) {

	return RIGHT < left
		|| LEFT > right
		|| TOP > bottom
		|| BOTTOM < top
}

export default
	{ pointAsBounds
	, normalize
	, contains
	, intersects
	, outside
	}