/* globals setTimeout clearTimeout */

import {
	view
	, set
	, concat
	, equals
	, lensPath
	, merge
} from 'ramda'

import { prop } from '../../../stream'
import never from 'never-never'
import m from 'bacta'

function Driver({
	basePath
	, pathParts
	, paths
	, update
	, urlInterpreter
	, effects
	, Main
	, withData
	, getURL
	, getAdapters
}){

	return function UIDriver$data(data){

		const href = HREF(basePath)
		const initial =
			urlInterpreter(
				href.from(
					pathParts
					,getURL()
				)
			)


		const write = prop()
		const read = prop.scan(update, initial, write)
		const manager = Manager(read, write)

		const router = Router(href, manager)

		const streams =
			paths
			.map( a => [ a.slice(-1)[0], a ])
			.reduce(
				(streams, [name, path]) => {
					return merge(
						streams
						,{ [name+'$']: manager.streamFromPath(path) }
					)
				}
				,{}
			)


		const adapters =
			getAdapters(
				read
				,write
			)

		effects({
			data
			,write
			,read
			,router
			,streams
			,adapters
		})

		const o = Main({
			read
			,write
			,streams
			,router
			,manager
			,adapters
			,withData: withData( data )
		})

		return Object.assign(o, {
			onremove: () => prop.end(write)
		})
	}

}

function Manager(readState, writeState, identifier){

	const streamFromPath = path => {

		const lens = lensPath(path)

		const s =
			prop()

		prop.scan(function(p,n){

			identifier, path, s, lens, readState, writeState

			const v = view(lens)

			const reducerIntendedToChangeValue =
				!equals(v(p),v(n))

			const streamIsOutOfDateWithReducer =
				!equals(v(n), s())

			if( reducerIntendedToChangeValue ){
				if( streamIsOutOfDateWithReducer ){
					s(v(n))
				}

			}

			return n
		}, never, readState)

		s.map(function(n){
			identifier, path, s, lens, readState, writeState

			const state =
				set(
					lens
					,n
					,readState()
				)

			writeState({
				state
				,action: path
			})

			return null
		})

		s.end.map(function(){
			return prop.end(readState)
		})

		//eslint-disable-next-line
		s.lens = lens
		//eslint-disable-next-line
		s.path = path

		return s

	}

	return {
		streamFromPath
		, readState
		, writeState
	}
}


const toMaybe = x => x == null ? [] : [x]

const HREF = (baseURL) => ({

	from(pathKeys, href){

		const [path, search] =
			href.replace(baseURL, '').split('?')

		const pathValues =
			path
			.split('/')
			.filter(Boolean)

		const newPathObj =
			pathKeys
				.reduce(function(p, n, i){
					// eslint-disable-next-line
					p[n] = pathValues[i]
					return p
				}, {})

		const newSearchObj =
			m.route.parseQueryString(search)

		return {
			path: newPathObj
			,search: newSearchObj
		}
	}

	,to(state){

		const { path, search } = state
		const values = Object.keys(path)
			.filter( k => k[0] != '_' )
			.map( k => path[k])

		const qs = m.route.buildQueryString(

			Object.keys(search)
				.sort()
				.reduce((p,n) => Object.assign(p, { [n]: search[n] }),{})
		)

		const modePrefix = ''

		return (
			modePrefix
			+ [ baseURL ]
			.concat( values )
			.map( toMaybe )
			.reduce( concat )
			.join('/')
			+'/'
			+ ( qs ? '?' + qs : '')
		)

	}
})

function Router(href, manager) {

	const createPathStream = () => {

		const $href =
			manager.readState.map(href.to)

		return prop.afterSilence(0, $href)
	}

	const anchor = (text, sets, attrs) => {

		const statePreview = sets.reduce(function(state, [f,x]){
			return set( f.lens, x, state)
		}, manager.readState())

		return m(
			'a'
			,Object.assign(
				{ href: href.to(statePreview)
				, onclick (e) {
					sets.forEach(function([f,x]){

						manager.writeState(
							{ state: set( f.lens, x, manager.readState() )
							, action: f.path
							}
						)
					})

					e.preventDefault()
				}
			}, attrs)
		, text )

	}

	return {
		createPathStream
		, anchor
		, href
		, manager
	}
}


const LensWriter = (read, write) => actionPath => lens => value => {

	return write({
		state:
			set(
				lens
				, value
				, read()
			)
		,action: actionPath
	})
}

const LensReader = read => lens => {

	return () => {
		return view( lens, read() )
	}
}

const LensAdapter = ( read, write ) => actionPath => writePath => {

	const lens = lensPath(writePath)

	const writer = LensWriter( read, write )(actionPath)(lens)
	const reader = LensReader( read )( lens )


	return (...args) => {
		if( args.length ){
			writer( args[0] )
		}

		return reader()
	}
}

const ThrottledLensAdapter = (read, write) => actionPath => writePath => {

	const getParent =
		writePath.length > 1
		? view(lensPath(writePath.slice(0, -1)))
		: () => read()[ writePath[0] ]

	const key =
		writePath.slice(-1)[0]

	const lens = lensPath(writePath)

	const writer = LensWriter( read, write )(actionPath)(lens)
	const reader = LensReader( read )( lens )

	// eslint-disable-next-line no-var
	var debounceId;


	return (...args) => {

		if( args.length ){

			if( debounceId != null ) {
				clearTimeout( debounceId )
			}

			// Send latest value through the update function
			// to be processed when we stop receiving values

			debounceId = setTimeout(function(){
				writer( args[0] )
			}, 500)

			// directly mutate the current state immediately

			getParent(read())[key] = args[0]
		}

		return reader()
	}
}

export
	{ Manager
	, HREF
	, Router
	, LensWriter
	, LensReader
	, LensAdapter
	, ThrottledLensAdapter
	, Driver
	}