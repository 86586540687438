
import css from 'bss'
import m from 'bacta'
import * as elements from '../components/elements'
const heading = [
	m('h2', 'Exports'), m('hr')
	,m('h4', 'Export Odin data as an Excel Workbook')
]


const organization_tables = [
	'contractors'
	,'timesheets'
	,'tools'
	,'containers'
	,'suppliers'
	,'orders'
	,'warehouses'
	,'materials'
	,'interruptions'
	,'invoices'
	,'contracts'
	,'organizations_disciplines'
	,'crews'
	,'crews_discipline_rates'
	,'supplier_locations'
	,'supplier_items'
	,'interruptions_affected'
	,'invoice_items'
	,'contract_items'
]

const schedule_tables = [
	'projects'
	,'project_extras'
	,'project_contacts'
	,'project_documents'
	,'schedules'
	,'flows'
	,'allocations'
	,'schedule_versions'
	,'disciplines'
	,'flows_dependent_activities'
	,'flows_primary_activities'
	,'allocations_contractors'
	,'allocations_contractors_crews'
	,'allocations_eots'
	,'allocations_nods'
	,'allocations_vars'
]


function Main({ attrs: {data} }){
	function getExportHref(sheets){
		return data.api.buildRequest(
			'GET'
			, '/exports/workbook/'
			,{ sheets: sheets.join(',')
			, organization_id: data.organization_id()
			, schedule_id: data.schedule_id()
			, schedule_version_id: data.schedule_version_id()
			}
		)
		.url
	}

	data.routeInitialized('exports')

	function view(){
		return m('div.exports' + css`
			min-height: 600px;
		`
			, heading
			,elements.list([
				m('a.btn.btn-primary', {
					href: getExportHref(schedule_tables)
					,disabled: !getExportHref(schedule_tables)
					// ,download: "export.xlsx"
				}, 'Download Schedule data')
				,m('a.btn.btn-primary', {
					href: getExportHref(organization_tables)
					,disabled: !getExportHref(organization_tables)
					// ,download: "export.xlsx"
				}, 'Download Organization data')
			])
		)
	}

	return { view }
}


export default Main
