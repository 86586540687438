

import m from 'bacta'
import * as R from 'ramda'
const parseFloatDec = s => parseFloat(s, 10)
import { prop } from '../../../stream'

function NumberInput(vnode){
	const { scoped, errors, errorLabel } = vnode.attrs
	const attrs = scoped(R.merge(vnode.attrs,vnode.children[0]))
    const rawTextValue = scoped()
    const valueAsNumber = scoped()
    const el = scoped()
    const valid = scoped()
	const keyStroke = scoped(false)

	function initializeHTML(el){
		el.value = attrs().prop()
		if( attrs().attrs.min ){
			el.min = attrs().attrs.min
		}
		if( attrs().attrs.step ){
			el.step = attrs().attrs.step
		}
		if( attrs().attrs.max ){
			el.max = attrs().attrs.max
		}
	}

    const updateHTML =
		el.map(
			el =>
				attrs().prop() != null
				// string comparisons could yield errors e.g. "1.0" != "1"
				&& parseFloatDec( el.value )
					!== parseFloatDec( String(attrs().prop()) )
				&& el.validationMessage != ''
		)

    const desiredElValue =
		prop.merge([el, updateHTML])
			.map(function([el, update]){

				// we want to detect if the prop value changed without
				// the user interacting with the input
				// because prop's aren't streams, we can't simply subscribe
				// to the prop's changes, so we need to detect the change
				// the way we detect the change is:
				//
				// if the html input value is valid, that means
				// we're not currently editing it
				// and if the html input value is valid and different to the
				// prop that means the prop was update independently of the UI
				// (e.g. API call) so we want to update the input to have the
				// same data as the database / etc
				return keyStroke()
					? update
						? el.value
						: attrs().prop()

					: attrs().prop()
			})


	const inputErrors =
		prop.dropRepeats(
			el.map((el) => {
				return el.validationMessage
			})
		)

	const errorOutput =
		inputErrors.map(function(inputErrors){
			return Object.assign(
				{}
				,errors()
				,{
					[errorLabel]: inputErrors
				}
			)
		})


	// side effects
	prop.merge([valid, rawTextValue, valueAsNumber])
		// eslint-disable-next-line no-unused-vars
		.map(function([valid, rawTextValue, valueAsNumber]){
			attrs().prop(
				!rawTextValue
					? 0
					: valueAsNumber
			)
			return null
		})

	prop.merge([el, desiredElValue])
		.map(function([el, value]){
			if( el.value != value ){
				el.value = value
			}
			keyStroke( false )
			return null
		})

	errorOutput.map( errors )

	return {
		onupdate: (vnode) => attrs(R.merge(vnode.attrs,vnode.children[0]))
		,view(){
			return m("input.form-control" + ( attrs().novalidate ? '' : '.validate')
					,Object.assign(
						{
							oncreate: ({ dom }) => initializeHTML(dom)
							,onupdate: ({ dom }) => el(dom)
							,oninput: (event) => {
								const el = event.currentTarget
								rawTextValue(el.value)
								valueAsNumber(el.valueAsNumber)
								valid( el.validationMessage == "" )
								keyStroke( true )
							}
							,type: 'number'
						}
						,attrs().attrs || {}
					)
				)
		}
	}
}

NumberInput.legacyRenderProps = true

export default NumberInput