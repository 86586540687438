/* global console, setInterval, setTimeout, process */

import Auth from '../auth'

import max_time from './max-time.json'
import m, { Stream as stream } from 'bacta'
import { notification } from '../../components/notification'

import {
	Response
	,Request
	,Notification
	,$
	,env
} from './types'
import { Main, processNotificationRequest, taskRoute } from './index'


const min_poll_time = 1000 * 10
const def =
	$.create({
		checkTypes: !!process.env.CHECK_TYPES
		, env
	})

const notify =
	def('notify', {}, [Notification, $.Any]
		,notification
	)


function start(data){

	const processTaskRequest = responses =>
		function (schedule_version_id, delay) {

			function loop() {
				data.organization()
				&& data.api.tasks.fetch.byScheduleVersion(
					schedule_version_id
				)
				.then(function(task){
					responses(
						Response.Task(task)
					)
				})

			}

			setTimeout(loop, Math.max( delay, min_poll_time ) )
		}

	const processRequests = (requests, responses) =>
		Request.case({
			NotificationRequest:
				processNotificationRequest(requests, notify)

			,Task: processTaskRequest(responses)

			,RedirectGantt: task => {
				setTimeout(() => {
					const route = taskRoute(task)
					m.route.set(route)
				})
			}
			,Nothing: () => void 0
		})


	function pollScheduleTasks(responses, max_time) {

		// eslint-disable-next-line
		var lastResponseTime = Date.now()

		async function loop( p ){

			if ( Auth.stream().auth_token ) {
				try {
					let xs = await p( new Date(lastResponseTime) )
					for( let x of xs ) {
						const task = Response.Task(x)
						responses(task)
					}
				} catch (e) {
					console.error(e)
				} finally {
					lastResponseTime = Date.now()
				}
			}
		}

		// first we want all tasks that are running
		loop(
			() => data.organization()
			? data.api.tasks.fetch.inProgress()
			: []
		)

		// from then on we want tasks in any state
		// since lastResponseTime
		setInterval(
			loop
			, max_time
			, lastResponseTime => data.organization()
				? data.api.tasks.fetch.since( lastResponseTime )
				: []
		)
	}

	function Interpreter(component, max_time){

		const responses = stream()

		const {requests} = component({ responses })

		pollScheduleTasks(responses, max_time )

		requests.map(
			processRequests(requests, responses)
		)

		return responses

	}

	return Interpreter(Main, max_time )
}

// eslint-disable-next-line
export default {
	start: start
}