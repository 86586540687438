

import NotificationBar from './notification'

import '@shoelace-style/shoelace/dist/themes/light.css';
import '@shoelace-style/shoelace/dist/components/textarea/textarea.js';

import * as R from 'ramda'
import * as ContextBar from './context-bar'
import m from 'bacta'
import css from 'bss'
import * as H from '../../../how/index.js'
import Responsive from './responsive'

const M = R.curryN(2, m)
	Object.assign(M, m)

const I = R.identity

export function scrollPane(
	children
	, attrs={}
	, style={
		width:'30em'
		, maxHeight:'8em'
		, minHeight:'3em'
	}
){
	return m(
		'div.db.mt2.overflow-y-auto'
		,R.merge({ style },attrs)
		,children
	)
}

const link =
	css`
		text-decoration: none;
		color: currentColor;
	`
	.$visited(`
		color: currentColor;
	`)

export const icons = {
	info: () => m.trust(`<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-book-open"><path d="M2 3h6a4 4 0 0 1 4 4v14a3 3 0 0 0-3-3H2z"></path><path d="M22 3h-6a4 4 0 0 0-4 4v14a3 3 0 0 1 3-3h7z"></path></svg>`)
	,x: () => m.trust(`<svg xmlns="http://www.w3.org/2000/svg" width="1em" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>`)
	,box: () => m.trust(`<svg xmlns="http://www.w3.org/2000/svg" width="1em" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" class="feather feather-package"><line x1="16.5" y1="9.4" x2="7.5" y2="4.21"></line><path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path><polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline><line x1="12" y1="22.08" x2="12" y2="12"></line></svg>`)
	,tool: () => m.trust(`<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" class="feather feather-tool"><path d="M14.7 6.3a1 1 0 0 0 0 1.4l1.6 1.6a1 1 0 0 0 1.4 0l3.77-3.77a6 6 0 0 1-7.94 7.94l-6.91 6.91a2.12 2.12 0 0 1-3-3l6.91-6.91a6 6 0 0 1 7.94-7.94l-3.76 3.76z"></path></svg>`)
	,user: () => m.trust(`<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>`)
	,truck: () => m.trust(`<svg xmlns="http://www.w3.org/2000/svg" width="1em" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-truck"><rect x="1" y="3" width="15" height="13"></rect><polygon points="16 8 20 8 23 11 23 16 16 16 16 8"></polygon><circle cx="5.5" cy="18.5" r="2.5"></circle><circle cx="18.5" cy="18.5" r="2.5"></circle></svg>`)
	,mapPin: () => m.trust(`<svg xmlns="http://www.w3.org/2000/svg" width="1em" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-map-pin"><path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path><circle cx="12" cy="10" r="3"></circle></svg>`)
	,hash: () => m.trust(`<svg xmlns="http://www.w3.org/2000/svg" width="1em" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-hash"><line x1="4" y1="9" x2="20" y2="9"></line><line x1="4" y1="15" x2="20" y2="15"></line><line x1="10" y1="3" x2="8" y2="21"></line><line x1="16" y1="3" x2="14" y2="21"></line></svg>`)
	,invoice: () =>
		m.trust(`<svg version="1.1" id="Icons" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
				viewBox="0 0 32 32" style="enable-background:new 0 0 32 32;" xml:space="preserve">
			<style type="text/css">
			.st0{fill:none;stroke:#000000;stroke-width:1;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
			</style>
			<polyline class="st0" points="19,3 19,9 25,9 19,3 7,3 7,29 25,29 25,9 "/>
			<line class="st0" x1="18" y1="14" x2="21" y2="14"/>
			<line class="st0" x1="18" y1="18" x2="21" y2="18"/>
			<line class="st0" x1="16" y1="22" x2="21" y2="22"/>
			<line class="st0" x1="13" y1="12" x2="13" y2="13"/>
			<line class="st0" x1="13" y1="19" x2="13" y2="20"/>
			<path class="st0" d="M14,13h-1.5c-0.8,0-1.5,0.7-1.5,1.5v0c0,0.8,0.7,1.5,1.5,1.5h1c0.8,0,1.5,0.7,1.5,1.5v0c0,0.8-0.7,1.5-1.5,1.5
			H12"/>
			</svg>`
		)

	,send: () =>
		m.trust(`<svg version="1.1" id="Icons" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
				viewBox="0 0 32 32" style="enable-background:new 0 0 32 32;" xml:space="preserve">
			<style type="text/css">
			.st0{fill:none;stroke:#000000;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
			</style>
			<polygon class="st0" points="28.6,3.4 3.4,12.6 15.1,16.9 22.4,28.6 "/>
			<line class="st0" x1="28" y1="4" x2="15.1" y2="16.9"/>
			</svg>`
		)

	,greenTick: () =>
		m.trust(`
			<svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 48 48" width="1em"><path fill="#c8e6c9" d="M44,24c0,11.045-8.955,20-20,20S4,35.045,4,24S12.955,4,24,4S44,12.955,44,24z"/><path fill="#4caf50" d="M34.586,14.586l-13.57,13.586l-5.602-5.586l-2.828,2.828l8.434,8.414l16.395-16.414L34.586,14.586z"/></svg>
		`
		)

	,redCross: () =>
		m.trust(`
			<svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 48 48" width="1em"><path fill="#F44336" d="M21.5 4.5H26.501V43.5H21.5z" transform="rotate(45.001 24 24)"/><path fill="#F44336" d="M21.5 4.5H26.5V43.501H21.5z" transform="rotate(135.008 24 24)"/></svg>		`
		)


}

const btn =
	css`
		transition-duration: 0.2s;
		padding: 0.75em 1em;
		align-content: normal;
		margin: 0px;
		outline: none;
		border-radius: 0.25em;
		box-sizing: border-box;
		border: solid 0.5px rgba(0,0,0,0);
		background-color: var(--theme, rgba(0,0,0,0.1));
		text-align: center;
	`
	.$nest('&.btn-warning', `
		color: #fff;
		background-color: #f0ad4e;
		border-color: #eea236;
	`)
	.$hover('o 0.9')
	.$active(`
		box-shadow: 0px 2px 2px 2px inset rgba(0,0,0,0.2);
	`)
	.$focus(`
		border: solid 0.5px var(--theme, blue);
	`)

export const styles = {
	btn, link
}

export const checkboxSize = () => ({
  width: '1.35em'
  ,height: '1.35em'
})

export const plainTxt = (v) => m('p.fw1', v)

export const trashBin = () =>
	m.trust(`
	<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash-2"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg>
	`)

export const switchiconNeuLeft = () =>
	m.trust(`
	<svg width="3em" height="3em" viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
	<!-- Generator: Sketch 50 (54983) - http://www.bohemiancoding.com/sketch -->
	<title>40. Switch off</title>
	<desc>Created with Sketch.</desc>
	<defs></defs>
	<g id="40.-Switch-off" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
		<g transform="translate(50.000000, 50.000000) scale(-1, 1) translate(-50.000000, -50.000000) translate(2.000000, 25.000000)" stroke="#FF9F43" stroke-width="4">
			<rect id="Layer-1" x="0" y="0" width="96" height="49.1707317" rx="24.5853659"></rect>
			<rect id="Layer-2" x="46.8292683" y="0" width="49.1707317" height="49.1707317" rx="24.5853659"></rect>
		</g>
	</g>
	</svg>
	`)

export const switchiconNeuRight = () =>
	m.trust(`
	<svg width="3em" height="3em" viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
	<!-- Generator: Sketch 50 (54983) - http://www.bohemiancoding.com/sketch -->
	<title>39. Switch on</title>
	<desc>Created with Sketch.</desc>
	<defs></defs>
	<g id="39.-Switch-on" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
		<g transform="translate(2.000000, 25.000000)" stroke="#FF9F43" stroke-width="4">
			<rect id="Layer-1" x="0" y="0" width="96" height="49.1707317" rx="24.5853659"></rect>
			<rect id="Layer-2" x="46.8292683" y="0" width="49.1707317" height="49.1707317" rx="24.5853659"></rect>
		</g>
	</g>
	</svg>
	`)



export const switchiconon = () =>
	m.trust(`
	<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	viewBox="0 0 58 58" style="enable-background:new 0 0 58 58;" xml:space="preserve">
	<path style="fill:#38454F;" d="M41,46H17C7.65,46,0,38.35,0,29v0c0-9.35,7.65-17,17-17h24c9.35,0,17,7.65,17,17v0
	C58,38.35,50.35,46,41,46z"/>
	<circle style="fill:#E0E1E2;" cx="17" cy="29" r="12"/>
	<line style="fill:none;stroke:#61B872;stroke-width:2;stroke-linecap:round;stroke-miterlimit:10;" x1="36" y1="29" x2="40" y2="33"/>
	<line style="fill:none;stroke:#61B872;stroke-width:2;stroke-linecap:round;stroke-miterlimit:10;" x1="40" y1="33" x2="48" y2="25"/>
	<g>
	</g>
	<g>
	</g>
	<g>
	</g>
	<g>
	</g>
	<g>
	</g>
	<g>
	</g>
	<g>
	</g>
	<g>
	</g>
	<g>
	</g>
	<g>
	</g>
	<g>
	</g>
	<g>
	</g>
	<g>
	</g>
	<g>
	</g>
	<g>
	</g>
	</svg>
	`)



export const switchiconoff = () =>
	m.trust(`
		<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
		viewBox="0 0 58 58" style="enable-background:new 0 0 58 58;" xml:space="preserve">
		<path style="fill:#38454F;" d="M17,12h24c9.35,0,17,7.65,17,17v0c0,9.35-7.65,17-17,17H17C7.65,46,0,38.35,0,29v0
		C0,19.65,7.65,12,17,12z"/>
		<circle style="fill:#E0E1E2;" cx="41" cy="29" r="12"/>
		<line style="fill:none;stroke:#ED7161;stroke-width:2;stroke-linecap:round;stroke-miterlimit:10;" x1="16" y1="21" x2="16" y2="37"/>
		<g>
		</g>
		<g>
		</g>
		<g>
		</g>
		<g>
		</g>
		<g>
		</g>
		<g>
		</g>
		<g>
		</g>
		<g>
		</g>
		<g>
		</g>
		<g>
		</g>
		<g>
		</g>
		<g>
		</g>
		<g>
		</g>
		<g>
		</g>
		<g>
		</g>
		</svg>
	`)

export const refresher = () =>
	m.trust(`
	<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="feather feather-refresh-cw"><polyline points="23 4 23 10 17 10"></polyline><polyline points="1 20 1 14 7 14"></polyline><path d="M3.51 9a9 9 0 0 1 14.85-3.36L23 10M1 14l4.64 4.36A9 9 0 0 0 20.49 15"></path></svg>
	`)


export const guardedInput =
	(type, setGuard, getGuard=R.identity) => (prop, attr) =>
	m("input.form-control",
		R.mergeRight(
			{
				oncreate({ dom: el }){
					const received = getGuard(prop(), el)
					if( el.value != received && received != null ){

						el.value = received
					}
				}
				,onupdate({ dom: el }){
					const received = getGuard(prop(), el)
					if( el.value != received && received != null ){

						el.value = received
					}
				}
				,type
				,oninput: m.withAttr(
					'value'
					, value => setGuard(prop,value)
				)
			}
			, attr || {}
		)
	)

export function propAdapter({ get, set }){

	const out = function prop(...args){
		if( args.length ) {
			if( typeof args[0] == 'function' ) {
				set( args[0] )
			} else {
				set( () => args[0] )
			}
		}
		return get()
	}

	out.get = get
	out.set = set
	return out
}

export const headingSeparators = /\s|\//

export function title(str){
	return R.type(str) == 'String'
		? str.split('').reduce(
			(p,c) =>
				p + (
					headingSeparators
						.test(
							R.last(p)
						)
					|| p == ''
					? c.toUpperCase()
					: c
				)
			, ''
		)
		: str
}

export function errorAlert(
	prologue
	, conjunction
	, postlogue
	, items
	, errorArray
	, returnBoolean
	, symmetric
	, alertType='warning'
	, statPlacement=''
	, additionalChecks=[]
){
	const errorString =
		R.uniq(
			R.unnest(
				symmetric
					? errorArray.map((e, eindex) =>
						Array.isArray(items[eindex])
							? items[eindex].map((i) =>
								e(i)
							)
							: e(items[eindex])
					)
					: errorArray.map((e) =>
						items.map((i) =>
							e(i)
						)
					)
			)
		)
		.filter((label) => label )
		.join(conjunction)

	const errorStringAdd = additionalChecks.join(' ')
	const invisible = errorString + errorStringAdd ? '' : '-invisible'
	const errorPrologue = errorString ? prologue : ''
	const errorpostlogue = errorString ? postlogue : ''

	const displayederrorstring =
		errorString
		? (
			[ errorPrologue
			, errorString
			, errorpostlogue
			]
			.filter(Boolean)
			.join(' ')
			.replace(/ ,/g, ',')
			.replace(/\s+/, ' ')
			.trim()
			+'. '
			+ errorStringAdd
		).trim() + (errorStringAdd ? '.' : '')
		: errorStringAdd.trim()

	alertType = 'error'
	return returnBoolean
		? displayederrorstring
		: errorString + errorStringAdd
			? alert(
				alertType + invisible
				,displayederrorstring
			)
			: statPlacement
				? statPlacement
				: alert(alertType + invisible ,' ')
}



export const buttonDefaults = () => ({
	'color':'#333'
	,'background-color': 'buttonface'
})

export const unstyledIconLeft = element => iconStyle =>
	className => text => (title, onclick, attrs={}) =>
		m(element+'.btn.pl3'
			, R.mergeAll([
				attrs
				, {
					title
					,style: buttonDefaults()
				}
				,onclick
				? { onclick: (e) => {
					onclick(e);

					if( attrs.onclick ) {
						attrs.onclick(e)
					}
				}
				}
				: {}
			])
			, m('span'+iconStyle, { className })
			, m('span', text )
		)

export const unstyledIconRight = element => iconStyle =>
	className => text => (title, onclick, attrs={}, type='') =>
		m(element+'.btn.'+type+'.pl3'
			, R.merge( attrs, {
				onclick: onclick
				,title
			})
			, m('span', text )
			, m('span'+iconStyle, { className })
		)


export const unstyledIconLeftButton = unstyledIconLeft('button')
export const unstyledIconRightButton = unstyledIconRight('button')

export const unstyledIconLeftAnchor = unstyledIconLeft('a')
export const unstyledIconRightAnchor = unstyledIconRight('a')

export const iconLeftButton = unstyledIconLeftButton('.pl2.pr1')
export const iconRightButton = unstyledIconRightButton('.pl3.mt1')

export const iconLeftAnchor = unstyledIconLeftAnchor('.pl2.pr1.')
export const iconRightAnchor = unstyledIconRightAnchor('.pl3.mt1')


export const [backAnchor, back] =
	[iconLeftAnchor, iconLeftButton].map(
		f => f('fa fa-chevron-left')('Back')
	)

export const [forwardAnchor, forward] =
	[iconRightAnchor, iconRightButton]
		.map( f => f('fa fa-chevron-right') )

// export const left = iconLeftButton('fa fa-chevron-left')
export const down = iconRightButton('fa fa-chevron-down')

export const go = (title, onclick, attrs) =>
	unstyledIconRightButton('.w2.pl1.t2')('fa fa-chevron-right')('')(
		title
		, onclick
		, attrs
	)

export const ensureList = R.concat([])

export const list =
	(items, padding='.pv2') =>
		R.compose(
			M("ul.list-inline.hth-measure")
			,R.map(M('li' + padding))
			,ensureList
		)(items)

export const listNoPadding = (items) => list(items, '')

export const strikeItem = (tachyons="") => ({ action, label, fstyle }) =>
	m('span.hover-strike.underline'+tachyons+fstyle
	+ css.$animate('ease-in-out 0.5s forwards', {
		from: 'o 0', to: 'o 1'
	})
	,{ onclick: () => action(label) }
	, label
	)

export const strikeItemRow = (tachyons="") => (strikeObject) =>
	list([strikeItem(tachyons)(strikeObject)].concat(strikeObject.meta))

export const inlinestrikeList = R.map(strikeItem('.ph2.pr0'))

export function inlineStrikeList2(list){
	return m('ul'
		+ css`
			display: flex;
			list-style:none;
			gap: 0.5em;
			flex-flow: wrap;
		`
		.$nest('li', `
			border: solid 1px #EEE;
			border-radius: 0.25em;
			padding: 0.25em 0.5em;
		`)
		,list.map( strikeItem('') )
			.map( v => m('li', v) )
	)
}


export function strikeList(list){
	return m('ul.pl4'
		,list.map( strikeItem('') )
		.map( v => m('li.pa2', v) )
	)
}

export function strikeTable(list){
	return m('ul.pl4',list.map((o) => m('li.pa2', strikeItemRow()(o))))
}

export function nonStrikeList(list){
	return m('ul.pl4'
		,list.map( ({label}) => m('span', label))
		.map( v => m('li.pa2', v) )
	)
}

export function splitPane(a,b, attrs={css:''}){
	return m('.split-pane'
		+ css`
			display: grid;
			grid-template-columns: 1fr;
		`
		.desktop(
			css`
				grid-template-columns: 1fr 1fr;
			`
			.$nest('.pane', `
				border-left: solid 1px rgba(0,0,0,0.1);
				padding-left: 1em;
				`+ attrs.css + `
			`)
			.$nest('.pane:first-child', `
				border-left: none;
				padding-left: 0em;
				padding-right: 1em;
			`)
		)

		,m('.pane', a)
		,m('.pane', b)
	)
}

export const HorizontalForm = M('div.form-horizontal')
export function HorizontalInput(label, type, prop, attributes){
		return m('div', { className: (prop.error || !prop()) && 'has-error' }, [

			m('div.form-group', [
				m('label.col-sm-2.control-label', label)
				,m('div.col-sm-10',
					m('input.form-control',
						Object.assign({
							oninput: m.withAttr('value', prop)
							,value: prop()
							,type: type
						}, attributes)
					),
					[ prop.error && m('span.help-block', prop.error ) || null ]
				)
			])
		])
	}

export function HorizontalSelect(label, prop, options, attr){
	return m('div', { className: (prop.error || !prop()) && 'has-error' }, [
		m('div.form-group', [
			m('label.col-sm-2.control-label', label)
			,m('div.col-sm-10', [
				m('select.form-control.col-sm-2', Object.assign({
					onchange: m.withAttr('value', prop)
					,value: prop()
				}, attr),
					options
				)
			])
			,m('span.col-sm-10.help-block', prop.error || null )
		])
	])
}

/**
 * Indents the children to match the alignment of the Horizontal Inputs
 * useful for submit buttons at the end of a form
 */
export const HorizontalAlignment = R.compose(
	M('div.form-group'),
		M('div.col-sm-offset-2.col-sm-10')
)

export const HorizontalInputForm = R.compose(
		HorizontalForm,
			R.map(
				R.apply(HorizontalInput)
			)
	)

export const HorizontalCustomForm = R.compose(
		HorizontalForm,
			HorizontalAlignment,
				list
	)

export const thead = R.compose(
		M('thead'),
			M('tr'),
				R.map(
					R.pipe(title, M('th'))
				)
	)

export const table = (data, headings, rows) => {
	return m('table.table table-striped.mb4'
		, thead(headings)
		, m('tbody'
			, rows.map( x => {
				if ( x.type == 'table.row' ) {
					return x.content
				} else {
					return table.row({}, x).content
				}
			})
		)
	)
}

export const icon = {
	x: () => m.trust(`<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>`)
	, info: () => m.trust(`<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="feather feather-book-open"><path d="M2 3h6a4 4 0 0 1 4 4v14a3 3 0 0 0-3-3H2z"></path><path d="M22 3h-6a4 4 0 0 0-4 4v14a3 3 0 0 1 3-3h7z"></path></svg>`)
	, stop: () => m.trust(`<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x-octagon"><polygon points="7.86 2 16.14 2 22 7.86 22 16.14 16.14 22 7.86 22 2 16.14 2 7.86 7.86 2"></polygon><line x1="15" y1="9" x2="9" y2="15"></line><line x1="9" y1="9" x2="15" y2="15"></line></svg>`)
	, warn: () => m.trust(`<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="feather feather-alert-triangle"><path d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"></path><line x1="12" y1="9" x2="12" y2="13"></line><line x1="12" y1="17" x2="12.01" y2="17"></line></svg>`)
}

table.row = (attrs={}, content) => {
	return {
		type: 'table.row'
		,content: m('tr', attrs, content.map( x => m('td', x)))
	}
}


/*

	Will disable the button while the action is resolving.  When it resolves it will be enabled again.

	If there is a failure it will pass the err message into your fail function which defaults to window.alert.

	Usage:

			action('Go', callServer())

*/

export const selectbuttons = function(
	options=[]
	, prop
	, attr
	, style=R.equals
	, saving=R.F
	, disabled=R.F
	, _
	, fail=NotificationBar.Notifications.alertError
){
	return m('.cf db'
		, list(
			options.map((o) =>
				action(
					o
					, () => prop(o)
					, saving
					, style( prop(), o )
						? '.bg-black-30'
						: ''
					, disabled
					, fail
					, attr
				)
			)
		)
	)
}

export const dateCheckbox = ({
	 date = { title: '', prop: R.identity, attrs:{} }
	, attrs = { title: '', prop: R.identity, attrs:{} }
}) => {
	return m('.input-group'
		,m('span.input-group-addon'
			,{
				style: {
					"backgroundColor": 'transparent'
					,"border-bottom": '1px solid #ccc'
					,"border": 'none'
				}
			}
			,checkbox(
				R.merge({
					checked: attrs.prop()
					, onclick: m.withAttr('checked', attrs.prop )
					, title: attrs.title
				}, attrs.attrs )
			)
		)
		,dateInput(
			date.prop
			,R.merge({
				title: date.title
			}, date.attrs)
		)
	)
}


export const dateCheckboxLabel = ({
	label
	, date = { title: '', prop: R.identity, attrs:{} }
	, checkbox = { title: '', prop: R.identity, attrs:{} }
}) =>
	m('label.control-label.mw5'
		,label
		, dateCheckbox({ date, attrs: checkbox })
	)


export const actionEvent = function(
	label='Submit'
	, action=R.identity
	, saving=R.identity
	, type='primary'
	, disabled=R.F
	, fail=NotificationBar.Notifications.alertError
	, attr
){
	return m('button.btn.btn-' + type
		, { async onclick(e){
			saving(true)
			try {
				await action(e)
			} catch(err) {
				fail(err.message)
			} finally {
				saving(false)
				m.redraw()
			}

			return null
		}
		,disabled: disabled() || saving()
		, ...attr
		}
		,label
	)
}

export const action = function(
	label='Submit'
	, action=R.identity
	, saving=R.identity
	, type='primary'
	, disabled=R.F
	, fail=NotificationBar.Notifications.alertError
	, attr
){
	return m('button.btn.btn-' + type
		,R.merge(
			{
				onclick: function(){
					saving(true)
					Promise.resolve().then(function(){
						const out = action()
						m.redraw()
						return out
					})
					.then(function(){
						saving(false)
						m.redraw()
						return null
					})
					.catch(function(err){
						saving(false)
						fail(err.message)
						m.redraw()
						return null
					})

					return null
				}
				,disabled: disabled() || saving()
			}
			,attr
		)
		,label
	)
}



export const confirmDelete = function(
	actionFn=R.identity
	, saving=R.F
	, disabled=R.F
	, active=R.F
	, fail=NotificationBar.Notifications.alertError
	, initalLabel='Delete'
	, confirmedLabel='Irrecoverable'
	, recoveryFunction=R.T
	, forceRender=R.F
	, skipConfirm=R.F
){
	const discardButoon =
		(
			active()
			|| forceRender()
		)
		&& !skipConfirm()
			? m('button.btn'
				+ css`
					height: 2.4em;
					left: -5px;
					position: relative;
					background-color: #efefef;
					border-radius: 0em 0.3em 0.3em 0em;
					color: #000000;
					border-left: 1px solid #d52525;
				`
				,
				{
					onclick: function(){
						recoveryFunction()
						&& active(false)
					}
					,disabled: disabled() || saving()
					,title: 'Discard Changes or Hide Action'
					,className: `fa fa-undo`
				}
				,''
			)
			: null

	const actionButton =
		action(
			active()
				? confirmedLabel
				: initalLabel
			, active()
				? () => {
					active(false)
					return actionFn()
				}
				: () => active(true)
			, saving
			, active()
				? 'danger'
				: 'warning'
			, disabled
			, fail
		)

	return 	[
		actionButton
		,discardButoon
	]
}

export const undoDiscard = function({
	discard
	,doneUndo
}){
	const discardButoon =
		discard && discard.loading
		? confirmDelete(
			discard.attrs.onclick
			,discard.loading
			,discard.attrs.disabled
			,discard.state
			,null
			,trashBin()
		)
		: discard
		? m("button.btn.btn-warning"
			, discard.attrs
			, trashBin()
		)
		: null

	const undoButton =
		doneUndo && !doneUndo.attrs.disabled
	 	? m("button.btn.btn-"
			, doneUndo.attrs
			, doneUndo.label || 'Revert'
		)
		: null

	return [
		undoButton
		,discardButoon
	]
}

export function statusCheckboxGroup( input, label, status ){
	return m('.cf db'
		,m('.f6.black-80.fl.ba.br3.b--black-20.ma2'
			,m('span.fl.f3.pa2.pr3.pl3.br--left.br3.bg-black-05'
				,input
			)
			,m('span.fl.f3.bg-white.pv2.ph3.br.bl.b--black-20.w5'
				,label
			)
			,m('span.fl.f3.bg-black-05.pv2.ph3.br--right.br3'
				,status
			)
		)
	)
}

export function spinner(
	custom=
		{
			tachyons:''
			, attrs:{}
			, spinnerdimensions: {
				width: '1.5em'
				, height: '1.5em'
			}
		}
){
	return m('.centeredSpinner.w-100.h-100' + custom.tachyons
		,custom.attrs
		,m('img', {
			src: '/assets/spinner.gif'
			, style:
				{ width: custom.spinnerdimensions.width
				, height: custom.spinnerdimensions.height
				, userSelect: 'none'
				}
		})
	)
}

export function formInput ([label, prop, attrs]){
	return	m('div.form-group.hth-measure', [
		m('label.control-label.w-100', label
		,m('input.form-control'
			,R.merge(
				{
					type: 'text'
					, oninput: m.withAttr('value', prop)
					, value: prop() || null
				}
				,attrs
			)
		))
	])
}

export const telephoneInput = guardedInput('tel', (prop, value) => prop(value))

export const textArea = (prop, attrs) =>
	m('sl-textarea' + H.css`
		&::part(base) {
			font-size: 1.1em;
			font-family: Helvetica;
		}
	`, {
		value: prop(),
		['onsl-input']: m.withAttr('value', prop), ...attrs
	})

export const textInput = guardedInput('text', (prop, value) => prop(value))

/**
 * Accepts native JS dates and returns native JS dates
 */
export function dateInput2(prop, attrs){
	return m('input.form-control', {
		onupdate: ({ dom }) => dom.valueAsDate = prop()
		,oncreate: ({ dom }) => dom.valueAsDate = prop()
		,type: 'date'
		,onchange: e => prop(e.target.valueAsDate)
		, ...attrs
	})
}

/**
 * Accepts ISO Date Strings and returns ISO Date Strings
 */
export function dateInput(prop, attrs){
	let f = ({ dom }) => {
		let x = prop()
		if( x != null ) {
			dom.valueAsDate = new Date(x)
		} else {
			dom.valueAsDate = null
		}
	}
	return m('input.form-control', {
		onupdate: f
		,oncreate: f
		,type: 'date'
		,onchange: e =>
			prop(
				e.target.valueAsDate != null
					? e.target.valueAsDate.toJSON()
					: null
			)
		, ...attrs
	})
}

export const xRemoval = (attr) =>
	m(	attr.undo
		? 'span.dim.ml2.fw9.fa.fa-undo'
		: 'span.dim.ml2.fw9.fa.fa-remove'
		,Object.assign(
			{},
			attr,
			{ fontSize: '1.5em'
			, transform: 'translateY(-0.1em)'
			}
		)
	)
/*
	A select element.  Receives a list of options and a prop for persistence
*/
export const select = (options, prop, attr, placeholder='Select an item') => {
	const usePlaceholder =
		typeof prop() == "undefined"
		|| prop() == ""
		|| !options.includes(prop())

		return m("select.form-control", R.merge({
		title: prop()
		,onchange: prop && m.withAttr("value", prop)
		,style: {'min-width': '5em'}
	}, attr || {}),
		[]
		.concat(
			usePlaceholder
			? m('option'
				,{ value:""
				, disabled: true
				, selected: true
				, hidden: true
				}
				, placeholder
			)
			: []
		)
		.concat(
			options
			.map(
				(option) =>
				m("option", {
					value: option

					,selected: usePlaceholder
						? false
						: prop() == option

				}, option)
			)
		)
	)
}

export const objectSelect2 = function(list, key, objProp, placeholder, attr){

	function propGet(){
		return objProp() && objProp()[key]()
	}

	function propSet(value){
		const o = list.find( o => o[key]() == value )
		try {
			objProp(o)
		} catch (e) {
			debugger;
		}
	}

	function prop(...args){
		args.length && propSet(...args)
		return propGet()
	}

	return select(
		list.map(
			(o) =>  o[key]()
		),
		prop
		,attr || {}
		,placeholder
	)
}

/*
	Like a labelSelect but accepts a list of objects with props.
	You specify the key for display and objectSelect will assign
	the value back to your objProp
*/
export const objectSelect = function({
	list=[], key="", prop=R.identity
	, objProp=R.identity, Ddisabled=false, placeholder, attr
}){
	function setObjProp(){
		const val = prop()
		const found = R.find((record) => record[key]() == val, list )
		if( found != null  ){
			objProp(found)
		}
	}

	if(list.length && prop() && !objProp()){
		setObjProp()
	}

	return select(
		list.map(
			(o) =>  o[key]()
		),
		prop
		,R.merge({
			onchange: R.pipe(
				m.withAttr('value', prop),
				setObjProp
			)
			,title: prop()
			,disabled: Ddisabled
		}, attr || {}),
		placeholder
	)
}

export const navbar = function({home = '/dashboard', brand, brand_icon=null, collapsed=I, children=[], attrs={}}){
	return m('nav.navbar navbar-default', attrs,
		m('div.container-fluid',
			m('div.navbar-header',
				m('button.navbar-toggle.collapsed', {
					onclick: toggleProp(collapsed),
				}, [ m('div.span.icon-bar'), m('div.span.icon-bar'), m('div.span.icon-bar') ]),
				m('a.navbar-brand.pl5', { href: home , oncreate: m.route.link },
					brand_icon ? m('img', {src:brand_icon}) : brand
				)
			),
			m(`div.navbar-collapse collapse ${collapsed() ? '' : 'in'}` ,[
				m('ul.nav.navbar-nav.navbar-right', children )
			])

		)
	)
}


export const checkbox = function(attrs){
	const onchange = attrs.onchange
	delete attrs.onchange

	return m(
		'input[type=checkbox].checkbox-size'
		, Object.assign(
			{ style: checkboxSize() }
			,attrs
			,{
				onchange(e){
					onchange(e)
					// todo-james may not be necessary with mithril v2
					m.redraw()
				}
			}
		)
	)
}

export const labelCheckbox = function({label,prop,attrs}){
	const onchange = attrs.onchange
	delete attrs.onchange
	return m('div.checkbox',
		m('label', [
			m('input[type=checkbox].checkbox-size'
				, Object.assign(
					{ onchange: m.withAttr('checked', prop)
					, style: checkboxSize()
					, checked: prop()
					, onclick: onchange
					}
					,attrs
				)
			)
			,label
		])
	)
}

export const alert = function(channel='info', message){
	if( !message ) return null
	if(! (channel in ContextBar.Channel) ) return null

	return m(ContextBar.Content, {
		message
		,channel: ContextBar.Channel[channel]
		,icon: ContextBar.icon[channel]()
	})
}

export const toggleProp = (prop) => () => prop(! prop() )

// FOR LINKS IN NAVBAR
export const dropdown = function(title, open=I, children=[]){

	return (
		m(`li.dropdown ${open() ? 'open' : ''}`, [
			m('a.dropdown-toggle',{
				href: '#'
				,onclick: toggleProp(open)
			}, title, m('span.caret') )
			,m('ul.dropdown-menu',
				children.map(function(c){
					return m('li', c)
				})
			)
		])
	)

}

export const tabset = ( tabnames, active) =>

	m("ul",{className: "nav nav-tabs"},
		tabnames.map(
			(tab) => m("li"
			,{ role: "presentation"
			, onclick: () => { active(tab) }
			, className: tab == active() && "active"
			},
				m("a",{}, title(tab) )
			)
		)
	)

export const dropup = function(open){

	return function(title, children=[],current){
		let el = dropdown(title,open,children,current)


		el.attrs.className = el.attrs.className + ' dropup'
		return el
	}
}

export const details = (label, value, attrs={}) =>
	m(
		'details'
		, attrs
		, m('summary.control-label.b', label)
		, value
	)

export function centeredSpinner(){
	return spinner(
		{
			tachyons: '.pt6.pb2'
			,attrs: { style: { 'padding-left': 'calc( 50% - 1.5em )' } }
			,spinnerdimensions: { width: '3em', height: '3em'}
		}
	)
}


let normalizeInfoPillValue = v => {
	if ( v && v.value ) {
		return {
			value: v.value
			, icon: v.icon
		}
	} else {
		return {
			value: v
		}
	}
}

export const infoPills = (idx) => {

	idx = Object.entries(idx)
		.map( ([k,v]) => [k, normalizeInfoPillValue(v)])
		.filter( ([,v]) => v.value != null )

	return m('.infoPills'
		+ H.css`
			& {
				display: flex;
				gap: 1em;
				justify-content: start;
				align-items: start;
			}

			@media ( min-width: 700px ) {
				& {
					display: grid;
					grid-template-columns: repeat(${idx.length}, 1fr);
				}
			}

			& .info {
				border-radius: 0.25em;
				font-size: 0.9em;
				display: flex;
				gap: 0em;
				position: relative;
				display: grid;
				justify-content: start;
				align-content: center;
			}

			& .key {
				top: -1.2em;
				white-space: nowrap;
				left: 0em;
				border-radius: 0.5em;
				display: flex;
				align-content: center;
				align-items: center;
				gap: 0.3em;
			}
		`
		, idx
		.map( ([k,v]) =>
			m('.info'
				, m('.key'
					, k
					,  v.icon
				)
				, m('.value', v.value )
			)
		)
	)
}

export const infoPill = (key, value) => infoPills({ [key]: value })


export const centerdHorizontalRule = children => m('.division'
	+ css`
		display: grid;
		gap: 1em;
		grid-template-columns: 1fr auto 1fr;
		align-items: end;
	`
	, m('hr')
	, m('p', children)
	, m('hr')
)

function mobileButtonStackItem({ label, onclick, attrs }){
	return {
		label, onclick, attrs
	}
}

export const mobileButtonStack = ({ container }, list) =>
	m( Responsive, {
		breakpoints: {
			mobile: 0
			,tablet:700
			,desktop:900
			,hd:1800
		}
	}, ({ mobileOnly, tabletOnly, desktop }) => {

		let v = i => m('button.mobile-button-stack'
			, {
				...i.attrs
				, onclick: e =>  {
					e.stopPropagation();
					i.onclick(e)
				}
			}
			, i.label
		)

		let wrapper = x => m('button'
			+ css`
				background-color: unset;
				display: flex;
				border: none;
				gap: 1em;
				flex-shrink: 0;

			`
			.$nest('button',
				css`
					display: flex;
					justify-content: space-between;
					align-items: center;
					font-size: 1em;
					padding: 0.5em 0.4em;
					background-color: unset;
					text-align: left;
					gap: 0.6em;
				`
			)
			.tablet`
				flex-wrap: wrap;
			`
			.$nest('.mobile-button-stack', `
				min-height: 3em;
				padding: 0.5em 1em;
				border-radius: 0.25em;
				color: black;
				border: solid 1px rgb(0,0,0,5%);
			`)
			.$nest('.mobile-button-stack:hover', `
				color: #33a6dc;
				border-color: currentColor;
			`)
			, container.attrs
			, x
		)

		let truncate = (n) =>
			wrapper(
				list.slice(0, n).map(v).concat(
					list.length > n
					? m('button.mobile-button-stack'
						, '+ '+list.slice(n).length+' more'
					)
					: null
				)
			)

		return [
			mobileOnly( () => truncate(2) )
			, tabletOnly( () => truncate(3) )
			, desktop( () => truncate(8) )
		]
	})

mobileButtonStack.item = mobileButtonStackItem

export const loadingRetain = (loading, vtree) =>
	m('.loading-retain'
		,
		{ onbeforeupdate: (vnode, old) => {
			let content =
				[old]
				.flatMap( x =>
					x && x.dom
					? [x.dom.children[0]]
					: []
				)
				.filter(Boolean)

			content.forEach( content => {
				if( loading ) {
					content.classList.add('loading')
				} else {
					content.classList.remove('loading')
				}
			})

			return !loading
		}
		}
		, m('.loading-content'
			+ css
			.$nest('&.loading'
				,css`
					touch-action: none;
					pointer-events: none;
				`
				.$animate('ease-in-out 0.6s infinite alternate', {
					from: 'o 0.3', to: 'o 0.6'
				})
			)
			, vtree
		)
	)