import * as R from 'ramda'

const financialprops = {
	currentRevenue: 0
	,currentExpenditures: 0
	,currentProfits: 0
	,forecastedRevenue: 0
	,forecastedExpenditures: 0
	,forecastedProfits: 0
	,outstandingRevenue: 0
	,outstandingExpenditures: 0
	,outstandingProfits: 0

	,currentDailyPeriodRevenue: 0
	,currentDailyPeriodExpenditures: 0
	,forecastedDailyPeriodRevenue: 0
	,forecastedDailyPeriodExpenditures: 0
	,currentDailyPeriodProfits: 0
	,forecastedDailyPeriodProfits: 0
	,outstandingDailyPeriodRevenue: 0
	,outstandingDailyPeriodExpenditures: 0
	,outstandingDailyPeriodProfits: 0

	,currentDailyRevenue: 0
	,currentDailyExpenditures: 0
	,forecastedDailyRevenue: 0
	,forecastedDailyExpenditures: 0
	,currentDailyProfits: 0
	,forecastedDailyProfits: 0
	,outstandingDailyRevenue: 0
	,outstandingDailyExpenditures: 0
	,outstandingDailyProfits: 0

	,accruedExpenditures: 0
	,accruedRevenue: 0
	,accruedProfits: 0
	,accruedDailyRevenue: 0
	,accruedDailyExpenditures: 0
	,accruedDailyPeriodRevenue: 0
	,accruedDailyPeriodExpenditures: 0
	,accruedDailyProfits: 0
	,accruedDailyPeriodProfits: 0
	,budget: 0
	,contractList: []
	,totalScheduleData: []
	,chartdata: []
	,totalDayData: []
	,invoicecount: 0
	,materialActual: 0
	,materialForecasted: 0
}

const coalatorAccumulator =
	(a, b) =>
		R.mapObjIndexed((object, propIndex) =>
			object =
			Array.isArray(b[propIndex])
			|| propIndex == 'contractList'
			|| propIndex == 'totalScheduleData'
			|| propIndex == 'totalDayData'
			|| propIndex == 'chartdata'
				? object.concat( b[propIndex] || [])
				: object + b[propIndex]
		)(a)


const financialReduce =
	({items}) =>
		R.reduce(
			coalatorAccumulator
			,R.clone(financialprops)
			,items
		)


export { financialReduce, coalatorAccumulator, financialprops }