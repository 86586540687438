/* globals process, window */
import m from 'bacta'

const APP_VERSION =
	process.env.APP_VERSION || 'DEV'

export default function authSuccess({ auth }){

	auth.stream.filter( x => x._name == 'LoggedIn' ).once().map(
		({ user_username, user_id }) => {

			{
				if( typeof trackJs != 'undefined' ){
					// eslint-disable-next-line no-undef
					const t = trackJs

					t.addMetadata(
						"user_username", user_username()
					)

					t.configure({
						userId: user_id
						,version: APP_VERSION
					})
				}

			}

			const routeBeforeLogout =
				window.localStorage.getItem('route-before-logout')

			if (
				window.location.pathname === '/'
				|| window.location.pathname.startsWith('/access')
			) {
				m.route.set( routeBeforeLogout || '/data/updates')
			}
			window.localStorage.removeItem('route-before-logout')

			return null
		}
	)
}