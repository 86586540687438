export default function Organizations(auth, $){

	auth.stream.map( $.auth )

	$.organizations = []
	const organizations = $.organizations.$stream().map(
		xs => xs || []
	)

	$.auth.auth_permissions
		.$map(
			xs => xs.map( x => ({
				organization_id: x.organization_id
				, organization_name: x.organization_name
				, organization_incorporation_no: x.organization_incorporation_no
			}))
		)
		.$stream()
		.map( xs => {
			$.organizations(xs)
			return null
		})

	$.organization_id = null

	const organization_id =
		$.organization_id.$stream()

	const organization =
		$.organizations.$values
		.$filter( (x,y) => {
			const out = x.organization_id == y
			return out
		}, organization_id )


	function services({ auth_refresh }){
		organization_id.map($.organization_id)
		// organizations.map($.organizations)
	}


	return {
		organizations
		,organization: organization.$stream()
		,organization_id
		,organization_name: organization.organization_name.$stream()
		,services
	}
}