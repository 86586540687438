/* globals setTimeout */
import m from 'bacta'
import css from 'bss'
// todo-james we should do this globally for all focus
// it's so simple!
let editing = null
const focusQuery = 'input,textarea'

function Pencil(){
	const id = Math.random().toString(15).slice(2,8)

	function view({
		attrs: { value, view, toggleable },
	}){

		function select(){
			editing = id
			// this redraw was needed for m v1
			// but probably not m v2
			// as redraws are async in v2
			m.redraw()
		}

		const viewOutput =
			editing == id
			? toggleable
				? view((...args) => {
					if( args.length ) {
						if( args[0] ) {
							editing = id
						} else {
							editing = null
						}
					}
				})
				: view()

			: null

		const out =
			viewOutput
			|| m('p.dim.normal'
				+ css`
					margin-bottom: 0px;
				`
				,m('span', value())
				,m('span.fa.fa-pencil.pl2')
			)

		if( viewOutput ) {
			viewOutput.attrs.oncreate =
				({ dom }) =>
					setTimeout(
						() => {
							[dom]
							.filter( x => x.matches(focusQuery) )
							.concat( dom.querySelector(focusQuery) || [])
							.slice(0,1)
							.forEach(
								dom => {
									dom.focus()
								}
							)
						}

						, 100
					)
		}

		return m('div.pencil.measure'
			, {
				tabindex: editing == id ? -1 : 0
				,onfocus: editing != id && select
				,onfocusout(e){
					const notUs =
						e.relatedTarget
						&& !e.currentTarget.contains(e.relatedTarget)

					if(notUs && editing == id ) {
						editing = null
						m.redraw()
					}
				}
			}
			, out
		)
	}

	return { view }
}

function run(value, view, { toggleable=false }={}){
	return m(Pencil, { value, view, toggleable })
}

function compat(...args){

	if ( args.length > 1 ) {
		return run(...args)
	}

	return {
		view(vnode){
			const args =
				vnode.children

			return run(...args)
		}
	}
}

compat.legacyRenderProps = true
export default compat
