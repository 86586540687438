/* globals process */

import $ from 'sanctuary-def'
import SumType from 'sum-type-legacy'

const Type = SumType(
	$, {
		checkTypes:!!process.env.CHECK_TYPES
		, env: $.env
	}
)

const Functor =
	$.NullaryType(
		'Functor'
		, ''
		, list => list != null && typeof list.map == 'function'
	)

const ScheduleTask =
	$.RecordType({
		schedule_id: $.String
		,schedule_version_id: $.String
		,organization_id: $.String
		,task_message: $.String
		,task_progress: $.Number
		,task_failed: $.Boolean
		,task_cancelled: $.Boolean
		,schedule_name: $.String
		,schedule_priority: $.String
	})

const TaskState =
	Type.Named('TaskState', {
		TaskComplete: { task: ScheduleTask }
		,TaskFailed: { task: ScheduleTask }
		,TaskRunning: { task: ScheduleTask }
		,TaskStarted: { task: ScheduleTask }
		,TaskCancelled: { task: ScheduleTask }
	})

const NotificationButton =
	$.RecordType({
		label: $.String
		,action: $.AnyFunction
	})

const Notification =
	$.RecordType({
		error: $.Boolean
		,message: $.String
		,buttons: $.Array(NotificationButton)
	})

const NotificationRequest =
	Type.Named('NotificationRequest', {
		TaskComplete: { task: ScheduleTask }
		,TaskFailed: { task: ScheduleTask }
		,TaskStarted: { task: ScheduleTask }
		,TaskCancelled: { task: ScheduleTask }
	})

const Request =
	Type.Named('Request', {
		NotificationRequest: [NotificationRequest]
		,Task: { schedule_version_id: String, delay: Number }
		,RedirectGantt: { task: ScheduleTask }
		,Nothing: {}
	})

const Response =
	Type.Named('Response', {
		Task: { task: ScheduleTask }
		,TaskStarted: { task: ScheduleTask }
	})

const Sources =
	$.RecordType({ responses: Functor })

const Sinks =
	$.RecordType({ requests: Functor })

export const env = Response.prototype.env


export {
	Sinks
	, Sources
	, Response
	, Request
	, TaskState
	, ScheduleTask
	, Functor
	, $
	, Type
	, NotificationRequest
	, Notification
}