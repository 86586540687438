/* globals window */

export default function SQL({ auth_token, organization_id, schedule_id }){

	async function sql(query, args){
		return window.fetch('/api/sql/select', {
			method: 'POST'
			,headers: {
				'content-type': 'application/json'
				,authorization: 'Bearer ' + auth_token()
			}
			,body: JSON.stringify({
				query, args, organization_id: organization_id(), schedule_id: schedule_id()
			})
		})
		.then( async x => x.ok ? x.json() : Promise.reject(new Error(await x.text())))
	}

	return sql
}