/* globals window, setTimeout, clearTimeout */
import * as R from 'ramda'
import Promise from 'bluebird'
import m from 'bacta'
import moment from 'moment'
import css from '../../utils/css'
import colors from '../../utils/colors'
import AABB from './aabb'
import DetailsPane from './detailspane'
import { prop as stream } from '../../../../stream'
import svg from '../../utils/svg'
import { T } from '../../utils/safe'
import chroma from 'chroma-js'


const double = (v) => v * 2
const { percent, em, px, vw, seconds } = css.units
const lt = R.flip(R.lt)


const mapObj = R.map

const {
	translate
	, translateX
	, translateY
	, scale
} = css.funcs

function ProjectStyle(
	scale
	, scaleMax
	, projectScale
) {
	const ganttScale = scale / scaleMax

	return {
		position: css.position.absolute
		, textAlign: css.textAlign.left
		, fontSize: em(50)
		, color: colors.muted
		, width: vw(1 / ganttScale * 1000)
		, transformOrigin: 'top left'
		, transition: seconds(0.2)
		, transform: css.funcs.scale(1, 1)
			.map(R.multiply(projectScale))
		, '-webkit-user-select': 'none'
	}
}

export default function (world) {

	const {
		allocations
		, pointer
		, DAY_MILLIS
		, currentTime
	} = world

	const clicks = world.scoped()

	const resizes = world.scoped()

	function getWindowDimensions() {
		return {
			width: window.innerWidth
			, height: window.innerHeight
		}
	}

	const windowSize =
		stream
			.afterSilence(1000, resizes)
			.map(getWindowDimensions)

	windowSize(getWindowDimensions())

	// todo-james create a scoped prop.scoped.fromEvent
	window.addEventListener('resize', resizes)

	windowSize.end.map(function () {
		return window.removeEventListener('resize', resizes)
	})

	const xScale = world.xScale * pointer.settings.scale.max

	const style = {
		main: () => ({
			top: em(0)
			, left: em(0)
			, position: css.position.absolute
			, width: percent(100)
			, height: percent(100)
			, overflow: css.overflow.hidden
			, backgroundColor: colors.blackboard
		})

		, container: function () {
			const ganttScale = pointer.scale() / pointer.settings.scale.max
			const timeAsX = currentTime() / DAY_MILLIS * xScale
			const transform = css.sequence(
				translateY(px(pointer.coords().y))
				, scale(ganttScale, ganttScale)
				, translateX(px(- timeAsX))
			)

			return {
				position: css.position.absolute
				, transform
				, transformOrigin: 'top left'
			}
		}

	}

	const byProject =
		allocations.map(
			R.pipe(
				R.groupBy(R.prop('project_id'))
				, mapObj(
					R.sortBy(
						R.prop('allocations_allocation_date')
					)
				)
			)
		)

	const contractorKey =
		R.pipe(
			R.propOr([], 'allocations_contractors')
			, R.pluck('contractor_id')
			, R.uniq
			, R.sortBy(R.identity)
			, R.join('|')
		)



	const byProjectByContractor =
		byProject.map(
			mapObj(
				R.ifElse(
					R.isEmpty
					, R.always({})
					, R.groupBy(contractorKey)
				)
			)
		)

	const contractorLabel =
		R.pipe(
			R.propOr([], 'allocations_contractors')
			, R.pluck('allocations_contractors_name')
			, R.uniq
			, R.sortBy(R.identity)
			, R.join(' & ')
		)

	const contractorKeys =
		byProjectByContractor.map(
			mapObj(
				mapObj(function (allocations) {
					return contractorKey(allocations[0])
				})
			)
		)

	const byContractor =
		byProjectByContractor.map(
			R.pipe(
				R.values
				, R.mergeAll
			)
		)

	const allContractors = contractorKeys.map(
		R.pipe(
			mapObj(R.values)
			, R.values
			, R.unnest
			, R.uniq
		)
	)

	const byProjectByDiscipline =
		byProject.map(
			mapObj(
				R.groupBy(
					(allocation) => allocation.discipline_id
				)
			)
		)

	const byProjectByWorkname =
		byProject.map(
			mapObj(
				R.groupBy((a) => a.allocations_work_name)
			)
		)

	const allWorknames =
		byProjectByWorkname.map(
			R.pipe(
				(index) => R.values(index)
				, R.unnest
				, (list) => list.map(R.keys)
				, R.unnest
				, R.uniq
			)
		)

	const indexToColour = (i, _, length) => {

		//todo-james there's a bug where length=1 returns a value not a list
		const colour = [].concat(chroma.scale('Spectral').colors(length))[i]
		return chroma(colour)
			.saturate(1.5)
	}


	const worknameColours =
		allWorknames.map(
			(list) => list.reduce(
				(hash, workname, i) =>
					R.merge({
						[workname]: indexToColour(i, 30, allWorknames().length)
					}, hash)
				, {}
			)
		)

	const contractorColours =
		allContractors.map(
			function (list) {
				return list.reduce(function (p, contractorKey) {

					if (contractorKey && contractorKey in byContractor()) {
						const allocations = byContractor()[contractorKey]

						const allocation = allocations[0]
						return R.merge({
							[contractorKey]:
								worknameColours()
								[allocation.allocations_work_name]
									.desaturate(1)
									.darken(1.5)
						}, p)
					}


					return p
				}, {})
			}
		)

	/**
	 * Project ids sorted by the date of their earliest allocation
	 */
	const sortedProjectIds = byProject
		.map(function (byProject) {
			const projects = R.keys(byProject)

			return R.sortBy(function (project_id) {
				return byProject[project_id][0].allocations_allocation_date
			})(projects)
		})

	const projects =
		[ sortedProjectIds
		, stream.dropRepeats
		, s => s.map(
			ids => {
				Promise.all(ids)
					.map((i) =>
						world.readProjectPermissions()
						? world.fetchProjectsByProjectId({
							project_id: i
							, props: {
								disciplines: [
									'discipline_name'
									, 'discipline_order'
									, 'discipline_work'
									, 'discipline_completed'
								]
							}
							, depth: 2
						})
						: []
					)
					// emit here
					.then(xs => projects(xs))

				// don't emit yet
				return stream.SKIP
			}
		)
		]
		.reduce(T)

	const projectIndex =
		projects.map(R.indexBy(R.prop('project_id')))

	const ProjectCompletion = function (project) {

		const allocations =
			byProject()[project.project_id]
			// added because lower level users dont' have project permissions
			|| []
		// But its nice to see there worth in the gannt

		const earliestCompleteAllocation =
			R.find(a =>
				a.allocations_allocation_complete > 0
			)(allocations)

		const earliestAllocation =
			allocations[0]
			// added because lower level users dont' have project permissions
			|| {}
		// But its nice to see there worth in the gannt

		const earliestAllocationWorkDate =
			earliestCompleteAllocation
				? earliestCompleteAllocation.allocations_allocation_date
				: earliestAllocation.allocations_allocation_date

		const earliestAllocationDate =
			earliestAllocation.allocations_allocation_date

		const latestAllocationDate =
			byProject()[project.project_id]
			// added because lower level users dont' have project permissions
			|| [{ allocations_allocation_date: null }]
				// But its nice to see there worth in the gannt
				.slice(-1)[0]
				.allocations_allocation_date

		return {
			project_id: project.project_id
			, forecastStart:
				moment(earliestAllocationDate).startOf('day').valueOf()
			, forecastEnd:
				moment(latestAllocationDate).startOf('day').valueOf()
			, actualStart:
				moment(earliestAllocationWorkDate).startOf('day').valueOf()
			, actualEnd:
				project.project_completion_marker
					? moment(project.project_completion_marker)
						.startOf('day').valueOf()
					: null
		}
	}

	const projectCompletions =
		projects.map(
			R.pipe(
				R.map(ProjectCompletion)
				, R.indexBy(p => p.project_id)
			)
		)

	const sortedProjectDisciplines =
		byProjectByDiscipline.map(
			R.addIndex(mapObj)(
				function (byDiscipline, project_id) {
					const disciplines = R.keys(byDiscipline)
					return R.sortBy(function (discipline_id) {

						return byDiscipline[discipline_id][0]
							.allocations_allocation_date

					})(disciplines)
				}
			)
		)

	const disciplineIndexes =
		sortedProjectDisciplines.map(
			mapObj(function (disciplines) {
				return disciplines.reduce(function (p, discipline_id, i) {

					p[discipline_id] = i
					return p
				}, {})
			})
		)

	/**
	 * How much extra space between each discipline row as a ratio
	 * of the font height
	 */
	const discipline_row_height = 10
	const discipline_margin = em(3)

	const getAllocationCoords = (
		discipline_i
		, shiftAfterScale
		, metadataSize
		, allocations
	) => function (a) {

		const x = px(
			moment(
				a.allocations_allocation_date
				- allocations[0].allocations_allocation_date
			).valueOf() / DAY_MILLIS * xScale
		)



		/**
		 * The spacing between the heading and the rendered allocations
		 */
		const headerPadding = metadataSize.map(R.multiply(0.5))

		const y = em(
			discipline_i
			* discipline_row_height
			// todo-james lift these functors
			+ headerPadding.join()
			+ metadataSize.join()
			- shiftAfterScale * metadataSize.join()
		)

		return { x, y }
	}

	const scaleSettings =
		stream.fromFlydStream(pointer.scale).map(
			R.cond([
				[lt(7), R.always([0.9, 0])]
				, [lt(30), R.always([0.5, 0.8])]
				, [R.T, R.always([0.05, 1.4])]
			])
		)

	const projectScale = scaleSettings.map(R.head)
	const shiftAfterScale = scaleSettings.map(R.last)

	const projectStyle =
		stream.merge([
			stream.fromFlydStream(pointer.scale)
			, stream(pointer.settings.scale.max)
			, projectScale
		])
			.map(function (args) {
				return ProjectStyle(...args)
			})

	/**
	 * The height of the heading / metadata
	 */
	const metadataSize = projectStyle.map((s) => s.fontSize)

	const nestedIndexReduce = (
		visitor
	) => function (container) {
		return Object.keys(container).reduce(function (
			prev, containerKey
		) {
			const child = container[containerKey]


			prev[containerKey] =
				Object.keys(container[containerKey])
					.reduce(function (prev, childKey) {

						return visitor(
							prev
							, child[childKey]
							, childKey
							, containerKey
						)

					}, {})

			return prev

		}, {})
	}

	const allocationCoordsByProject =
		stream.merge([
			disciplineIndexes
			, shiftAfterScale
			, metadataSize
			, byProjectByDiscipline
			, byProject
		]).map(function ([
			projectDisciplines
			, shiftAfterScale
			, metadataSize
			, byProjectDiscipline
			, byProject
		]) {

			return nestedIndexReduce(function (
				p
				, discipline_i
				, discipline_id
				, project_id
			) {
				const projectAllocations =
					byProject[project_id]

				const disciplineAllocations =
					byProjectDiscipline[project_id][discipline_id]

				const ids =
					R.pluck('allocation_id')(disciplineAllocations)

				const coords = disciplineAllocations
					.map(getAllocationCoords(
						discipline_i
						, shiftAfterScale
						, metadataSize
						, projectAllocations
					))

				return R.merge(
					R.zipObj(ids)(coords)
				)(p)

			})(projectDisciplines)
		})

	function completionDiagram(
		completion
		, offset
	) {

		if (completion) {

			const {
				actualStart
				, actualEnd
				, forecastStart
				, forecastEnd
			} = completion

			const start = {
				fulfilled: !!actualStart
				, early: actualStart < forecastStart
				, late: actualStart > forecastStart
				, onTime: actualStart == forecastStart
				, isStart: true
			}

			const end = {
				fulfilled: !!actualEnd
				, early: actualEnd && actualEnd < forecastEnd
				, late: actualEnd && actualEnd > forecastEnd

				// if we haven't actually ended, we project that we are on time
				// but with an empty circle
				, onTime: !actualEnd || actualEnd == forecastEnd
				, isStart: false
			}

			const w = 150
			const stroke = 15

			const r = w - stroke

			const columns = 24
			const totalWidth = columns * w
			const positionWidth = totalWidth / columns
			const spacing = columns / 6 // 6 possible states


			const x = {
				start: {
					early: positionWidth * spacing * 0
					, onTime: positionWidth * spacing * 1
					, late: positionWidth * spacing * 2
				}
				, end: {
					early: positionWidth * spacing * 3
					, onTime: positionWidth * spacing * 4
					, late: positionWidth * spacing * 5
				}
			}

			const y = {
				forecast: w
				, actual: w * 5
			}

			const style = function ({ fulfilled, early, onTime }) {
				const color =
					early ? 'green'
						: onTime ? 'white'
							: 'red'

				return {
					fill: fulfilled ? color : 'none'
					, stroke: color
				}
			}

			const xAxis = function (
				p
				, { early, onTime, fulfilled, isStart }
			) {

				return early
					? p.early
					: onTime || isStart && !fulfilled
						? p.onTime
						: p.late
			}



			const forecastStyle =
				style({ fulfilled: false, early: false, onTime: true })

			const forecast =
				[
					[x.start.onTime, y.forecast, forecastStyle]
					, [x.end.onTime, y.forecast, forecastStyle]
				]

			const actual =
				[
					[xAxis(x.start, start), y.actual, style(start)]
					, [xAxis(x.end, end), y.actual, style(end)]
				]

			const forecastLine =
				svg.dashLine(
					{
						style: { stroke: 'white' }
					}
					, { x: x.start.onTime + w * 2, y: y.forecast }
					, { x: x.end.onTime, y: y.forecast }
				)

			const actualLine =
				svg.line(
					{
						style: { stroke: 'white' }
					}
					, { x: xAxis(x.start, start) + w * 2, y: y.actual }
					, { x: xAxis(x.end, end), y: y.actual }
				)

			const permutations =
				forecast
					.concat(actual)
					.map(

						([cx, cy, o]) => [cx + w, cy, o]

					)


			return m('svg'
				, {
					style:
					{
						position: 'absolute'
						, transform: translateY(offset)
						, 'stroke-width': stroke
					}
					, height: px(1200)
					, width: px(3500)

				}
				, forecastLine
				, actualLine
				, permutations.map(
					([cx, cy, attrs]) => svg.circle(
						attrs
						, { cx, cy, r }
					)
				)

			)
		} else {
			return null
		}
	}

	const pointerWasDragging = world.scoped()


	const selectedProject =
		allocations.map(() => null)

	const selectedAllocation =
		selectedProject.map(() => null)

	const timesheets = stream([])

	stream.dropRepeats(selectedAllocation).map(
		a => {
			if( a ) {
				world.fetchTimesheetsByAllocation({allocation_id: a.allocation_id, sharedtimesheet: 'none'})
					.then(timesheets)
			} else {
				timesheets([])
			}
			return null
		}
	)

	const onclickEmptySpace = function () {
		if (!pointerWasDragging()) {
			selectedProject(null)
			selectedAllocation(null)
		}
	}

	clicks.map(onclickEmptySpace)

	function ProjectView(project_id) {

		function projectTitle() {
			return m('div.truncate'
				, {
					style: projectStyle()
				}
				, project_name
			)
		}

		function contractorView(a) {
			const colour = contractorColours()[contractorKey(a)]

			return colour
				? m('span'
					, {
						style: style.contractor(
							a
							, allocationCoordsByProject()
							[project_id][a.allocation_id]
							, discipline_row_height
							, discipline_margin.map(R.multiply(1))
							, contractorColours()[contractorKey(a)]
						)
					}
					// cache this later
					, contractorLabel(a)
				)
				: []
		}

		function allocationView(a) {
			const index = allocationCoordsByProject()

			return m('span'
				, {
					style: style.allocation(
						a
						, index[project_id][a.allocation_id]
						, discipline_row_height
						, discipline_margin.map(R.multiply(1))
						, worknameColours()[a.allocations_work_name]
						, selectedAllocation()
						&& selectedAllocation()
							.allocation_id == a.allocation_id
						, selected
					)
					, title: a.allocations_work_name
					, onclick: selected && onclickAllocation(a)
				}
				, pointer.scale() > 20 ? a.allocations_work_name : ''
			)
		}

		const style = {

			contractor: function (
				a
				, { x, y }
				, height
			) {

				return {
					position: css.position.absolute
					, height: em(height / 2)
					, textAlign: css.textAlign.left
					, transform: translate(x, y.map(R.multiply(1 / 2)))
					, width: px(xScale)
					// ,backgroundColor: backgroundColor.hex()
					, overflow: css.overflow.hidden
					, fontSize: em(2)
					, paddingLeft: em(0.2)
					// ,color: css.colors.black
				}
			}

			, allocation: function (
				a
				, { x, y }
				, height
				, margin
				, backgroundColor
				, selected
				, selectedProject
			) {

				const doubleMargin = margin.map(double)

				const calcWidth = `calc( ${px(xScale)} - ${doubleMargin} )`
				const calcHeight = `calc( ${em(height)} - ${doubleMargin} )`

				const opacity =
					selectedProject
						&& selectedAllocation()
						&& !selected
						? 0.2
						: 1

				return {
					position: css.position.absolute
					, height: calcHeight
					, textAlign: css.textAlign.center
					, transform: translate(x, y)
					, width: calcWidth
					, margin: margin
					, backgroundColor
					, lineHeight: calcHeight
					, overflow: css.overflow.hidden
					, paddingLeft: em(1)
					, paddingRight: em(1)
					, color: css.colors.black
					, fontWeight: 'bold'
					, opacity
				}
			}
		}

		/**
		 * An approximation of the ratio of extra empty space that around a font
		 * */
		const fontPadding = 1.1

		/**
		 * The amount the height has reduce due to internal factors
		 * like smaller fontsizes at different scales
		 **/
		const amountShrunk = metadataSize().map(
			R.multiply(shiftAfterScale())
		)

		/**
		 * The space between the allocations and the heading text
		 */


		/**
		 * The spacing between the heading and the rendered allocations
		 */
		const headerPadding = metadataSize().map(R.multiply(0.5))

		const offset =
			R.lift(function (metadataSize, headerPadding) {
				return (metadataSize + headerPadding) * fontPadding
			})(metadataSize(), headerPadding)

		const allocations = byProject()[project_id]
		const disciplines = sortedProjectDisciplines()[project_id]
		const completion = projectCompletions()[project_id]

		/**
		* The earliest time of an allocation in the project
		* Layout managers can use this to sort/arrange projects
		*/
		const startTime =
			allocations[0]
				.allocations_allocation_date

		const project_name =
			allocations[0]
				.project_name



		/**
		 * The total height of the rendered project.
		 * Layout managers can then arrange the projects how they see fit.
		 */
		const atLeast = R.max
		const height =
			offset.map(
				R.add(disciplines.length * discipline_row_height)
			)
				// make sure the height at least gives us enough room
				// to render teh heading and the completion
				.map(atLeast(200))
				.map(
					(v) => v - amountShrunk.join()
				)

		const selected =
			selectedProject() && selectedProject().project_id == project_id

		const onclickProject = function (e) {
			if (!pointerWasDragging()) {

				selectedProject(projectIndex()[project_id])

				selectedAllocation(null)
				e.stopPropagation()
			}
		}

		const onclickAllocation = (a) => function (e) {
			if (selectedAllocation()
				&& selectedAllocation().allocation_id != a.allocation_id
			) {
				selectedAllocation(null)
			} else {
				selectedAllocation(a)
			}
			e.stopPropagation()
		}

		const a = allocations[0]
		const days = moment(a.allocations_allocation_date)
			.startOf('day').valueOf() / DAY_MILLIS * xScale

		const mode =
			pointer.scale() > 2
				? 'allocation'
				: 'project'

		const completionDiagramWidth =
			15 * xScale

		/**
		 * The expected width of the rendered Project
		 *
		 * In reality the width may be smaller if the project is offscreen.
		 */
		const onScreenWidth =
			mode == 'allocation'
				? moment(
					R.last(allocations)
						.allocations_allocation_date
				)
					.startOf('day').add(1, 'day') / DAY_MILLIS * xScale - days
				: completionDiagramWidth

		const view = function (
			{ transform
				, onScreen
			}
		) {

			return m('div'
				, {
					style:
						R.merge(
							{
								transform
								, width: px(onScreenWidth)
								, height
								, position: 'absolute'
							}
							, selected
								? {
									backgroundColor: '#133a50'
								}
								: {}
						)
					, onclick: onScreen && onclickProject
				}
				, projectTitle()
				, onScreen && mode == 'allocation'
					? allocations.map(function (a) {
						return [
							pointer.scale() > 14 ? contractorView(a) : []
							, allocationView(a)
						]
					})

					: completionDiagram(completion, offset)
			)
		}

		const meta = {
			startTime
			, height
			, width: onScreenWidth
			, amountShrunk
		}

		return {
			view
			, meta
		}
	}

	const scaleChange =
		stream.afterSilence(0, stream.fromFlydStream(pointer.scale))

	const coordsChange =
		stream.afterSilence(1000, stream.fromFlydStream(pointer.coords))
			.map(() => null)

	const viewport =
		stream.merge([
			windowSize, scaleChange, coordsChange
		])
			.map(function ([size]) {

				const ganttScale = pointer.scale() / pointer.settings.scale.max
				const timeAsX = currentTime() / DAY_MILLIS * xScale
				const dateBar = 0
				const projected = {
					width: size.width * 1 / ganttScale
					, height: (size.height - dateBar * 1.5) * 1 / ganttScale
					, y: (pointer.coords().y - dateBar) * 1 / ganttScale
					, x: timeAsX * 1 / ganttScale
				}

				/**
				 * The ratio of the dimensions of the screen where we rendered
				 * the viewport.
				 */
				const cutoffRatio = { w: 1, h: 1 }

				const offsetY = (1 - cutoffRatio.h) * projected.height * 0.5
				const offsetX = (1 - cutoffRatio.w) * projected.width * 0.5

				return {
					x: timeAsX + offsetX
					, y: -projected.y + offsetY
					, width: projected.width * cutoffRatio.w
					, height: projected.height * cutoffRatio.h
					, projected
				}
			})


	const viewportBounds =
		viewport.map(function (v) {
			return [v.y, v.x, v.y + v.height, v.x + v.width]
		})

	const ChronologicalLayout = function (projects) {


		let padding = em(0)
		let yOffset = em(0)


		const sumUnits = R.liftN(
			4
			, R.unapply(
				R.sum
			)
		)

		return projects.map(function (project_id) {

			const allocations = byProject()[project_id]
			const a = allocations[0]

			const days = moment(a.allocations_allocation_date)
				.startOf('day').valueOf() / DAY_MILLIS * xScale

			const p = ProjectView(project_id)

			const transform =
				translate(
					px(days)
					, R.lift(R.add)(
						yOffset
						, p.meta.amountShrunk
					)
				)
			/* tslint:disable */

			const x = days
			const y = transform.get('y').join() * 14
			const height = p.meta.height.join() * 14
			const width = p.meta.width

			const projectViewBox =
				[y, x, y + height, x + width]

			const onScreen =
				AABB.intersects(viewportBounds(), projectViewBox)


			yOffset = sumUnits(
				p.meta.height, yOffset, padding, p.meta.amountShrunk
			)

			return R.merge(p, {
				transform, onScreen, project_id, x, y, yOffset, projectViewBox
			})
			/* tslint:enable */
		})
	}

	const chronologicalLayout =
		stream.afterSilence(
			0
			, stream.merge([
				sortedProjectIds
				, viewportBounds
				, contractorColours
				, disciplineIndexes
				, projectCompletions
				, windowSize
				, selectedProject
				, selectedAllocation
				, projectIndex
				, allocationCoordsByProject
			])
		)
			.map(function ([
				sortedProjectIds
				, viewportBounds
				, contractorColours
				, disciplineIndexes
				, projectCompletions
				, windowSize
				, selectedProject
				, selectedAllocation
				, projectIndex
				, allocationCoordsByProject
			]) {
				return ChronologicalLayout(
					sortedProjectIds
					, viewportBounds
					, contractorColours
					, disciplineIndexes
					, projectCompletions
					, windowSize
					, selectedProject
					, selectedAllocation
					, projectIndex
					, allocationCoordsByProject
				)
			})

	const lost = chronologicalLayout.map(
		xs => xs.length > 0
		&& R.none(
			p => p.onScreen
			,xs
		)
	)

	const someOnScreen = chronologicalLayout.map(
		R.any(
			p => p.onScreen
		)
	)

	const localRouteState = stream.merge([
		stream.fromFlydStream(pointer.scale), currentTime
	]).map(function () {
		return {
			scale: pointer.scale().toFixed(2)
			, currentTime: Math.round(currentTime())
			, y: pointer.coords().y
		}
	})

	const chronologicalView =
		chronologicalLayout.map(function (children) {
			return m('div'
				, { style: { position: 'absolute', top: 0, left: 0 } }
				, children
					.map(function (p) {
						return p.view(p)
					})
			)
		})
	/*

	███████╗██╗██████╗ ███████╗
	██╔════╝██║██╔══██╗██╔════╝
	███████╗██║██║  ██║█████╗
	╚════██║██║██║  ██║██╔══╝
	███████║██║██████╔╝███████╗
	╚══════╝╚═╝╚═════╝ ╚══════╝

	███████╗███████╗███████╗███████╗ ██████╗████████╗███████╗
	██╔════╝██╔════╝██╔════╝██╔════╝██╔════╝╚══██╔══╝██╔════╝██╗
	█████╗  █████╗  █████╗  █████╗  ██║        ██║   ███████╗╚═╝
	██╔══╝  ██╔══╝  ██╔══╝  ██╔══╝  ██║        ██║   ╚════██║██╗
	███████╗██║     ██║     ███████╗╚██████╗   ██║   ███████║╚═╝
	╚══════╝╚═╝     ╚═╝     ╚══════╝ ╚═════╝   ╚═╝   ╚══════╝

	For Search: Side Effects
	*/
	// Some awkward code for debouncing drag state
	// todo-james make this all go away :)

	const isDragging = stream.dropRepeats(
		stream.fromFlydStream(world.pointer.dragging)
	)
		.map(Boolean)

	isDragging.map(function () {
		if (isDragging() && !pointerWasDragging()) {
			pointerWasDragging(true)
		}
		return null
	})

	let timeoutID

	stream.merge([
		stream.afterSilence(500, chronologicalView)
		, isDragging
	])
		.map(function () {
			clearTimeout(timeoutID)
			setTimeout(function () {
				pointerWasDragging(isDragging())
			}, 0)
			return null
		})


	const earliestProjectID =
		sortedProjectIds.map(
			xs => xs.length > 0 ? [xs[0]] : []
		)

	const earliestAllocationDate =
		stream.merge([
			byProject
			, earliestProjectID
		])
			.map(
				([byProject
					, earliestProject
				]) => earliestProject.flatMap(
					project_id => project_id in byProject
						? [byProject[project_id]]
						: []
				)
				.flatMap( xs => xs.slice(0,1) )
				.map( a => a.allocations_allocation_date )
			)

	// if they change schedule and they are lost, change their viewport
	stream.merge([
		earliestAllocationDate, lost
	])
		.map(function ([d, lost]) {

			d.map(
				d => {
					if (lost && Object.keys(projectIndex()).length > 0) {

						pointer.scale(1)

						const now = world.now

						// eslint-disable-next-line
						d = moment(d).subtract(4, 'days').valueOf()

						const timeAsPx =
							(now - d) / DAY_MILLIS * world.xScale
							* pointer.scale()

						const { x, y } = pointer.coords()

						pointer.dragging(true)
						pointer.movement(
							{
								x: -1 * (x - timeAsPx)
								, y: -1 * (y - 120)
							}
						)
						pointer.dragging(false)
					}

					return null
				}
			)


		return null
	})


	// Only save route state if they are not completely lost
	someOnScreen.map(function (someOnScreen) {
		if (someOnScreen && localRouteState()) {
			world.routeState(localRouteState())
		}
		return null
	})


	const selectedAllocationDiscipline = world.scoped()

	selectedAllocation.map(function (nullableAllocation) {
		selectedAllocationDiscipline({
			case: 'Loading'
		})

		nullableAllocation == null
			? { case: 'Empty' }
			: world.fetchAllocationsbyDiscipline(
				nullableAllocation.allocation_id
			)
				.then(function (value) {
					return { case: 'Loaded', value }
				})
				.catch(function () {
					return { case: 'Empty' }
				})
				.then(selectedAllocationDiscipline)

		return null
	})

	selectedAllocationDiscipline.map(
		() => world.redraw()
	)

	chronologicalView.map(() => world.redraw())

	return function () {

		return m('div.gantt',
			{
				style: style.main()
				, onclick: clicks
				, oncreate({ dom: el }){
					el.addEventListener('wheel', function (e) {
						e.preventDefault()
					})
				}
			}
			, m('div.gantt-container',
				{ style: style.container() }
				, chronologicalView()
			)
			, m(
				DetailsPane
				, R.merge(world, {
					selectedAllocation
					, selectedAllocationDiscipline
					, selectedProject
					, byProject
					, timesheets
					, projects
				})
			)
		)
	}
}