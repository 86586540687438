/* globals window */
import m from 'bacta'
import css from 'bss'
import * as R from 'ramda'
import flagon from 'flagon'
import permissions from '../../../api/permissions.json'

const links =
  [{ title: 'Gantt'
  , route: ({ Route }) => Route.HomeEmpty()
  , tags: ['data']
  , permissions: []
  }
  ,{ title: 'Updates'
  , route: ({ Route }) => Route.Updates({ args: '' })
  , tags: ['data']
  , permissions: ['updates_management', 'timesheet_management']
  }
  ,{ title: 'Projects'
  , route: ({ Route }) => Route.Projects({ args: '' })
  , tags: ['data']
  , permissions: ['project_management']
  }
  ,{ title: 'Resources'
  , route: ({ Route }) => Route.Resources({ args: '' })
  , tags: ['data']
  , permissions: ['resource_management']
  }
  ,{ title: 'Schedules'
  , route: ({ Route }) => Route.Schedules({ args: '' })
  , tags: ['data']
  , permissions: ['project_management']
  }
  ,{ title: 'Workflows'
  , route: ({ Route }) => Route.Workflows({ args: '' })
  , tags: ['data']
  , permissions: ['flow_management']
  }
  ,{ title: 'Interruptions'
  , route: ({ Route }) => Route.Interruptions({ args: '' })
  , tags: ['data']
  , permissions: ['interruption_management']
  }
  ,{ title: 'Tools'
  , route: ({ Route }) => Route.Tools({ args: '' })
  , tags: ['data']
  , permissions: ['tool_management', 'assigned_tools']
  }
  ,{ title: 'Financials'
  , route: ({ Route }) => Route.Financials({ args: 'contracts' })
  , tags: ['data']
  , permissions: ['contract_management', 'invoice_management']
  }
  ,{ title: 'Materials'
  , route: ({ Route }) => Route.Materials({ args: '' })
  , tags: ['data']
  , permissions: ['material_management', 'contract_recognition']
  }
  ,{ title: 'Payments'
  , onclick(e, { api }){
	e.preventDefault()

	api.request('post', '/payments/portal', {
		return_url: window.location.href
	})
	.then( ({ url }) => {
		window.location = url
	})
  }
  , tags: ['data']
  , permissions: ['payment_management']
  , filter: ({ features }) => {
	  return features().paymentsNav
  }
  }
  ,{ title: 'Exports'
  , route: ({ Route }) => Route.Exports({ args: '' })
  , tags: ['data']
  , permissions: ['organization_exports', 'schedule_exports']
  }
  ,{ title: 'Organizations'
  , route: ({ Route, state }) => Route.Organizations2({ args: state.organization_id || '' })
  , tags: ['data']
  , permissions: ['user_management', 'organization_management']
  }
  ,{ title: 'Login'
  , route: ({ Route }) => Route.Login()
  , tags: ['access']
  , permissions: []
  }
  ,{ title: 'Reset Password'
  , route: ({ Route }) => Route.Reset()
  , tags: ['access']
  , permissions: []
  }
  ]

const link_css =
	css`
		text-decoration: none;
		color: inherit;
		transform: scale(1);
		transition: 0.2s;
		transform-origin: top left;
	`
	.$hover(`
		text-decoration: underline;
		transform: scale(1.05);
	`)

const trunc_css =
	css`
		white-space: nowrap;
		text-overflow: ellipsis;
		width: 100%;
		overflow: hidden;
	`
const isActive = ({ Route, state }) => x =>
	x.route
	&& x.route({ Route, state }).tag == state.route.tag

const toLi = () => (state, { api, Route }) => x =>
  m('a', {
	  href: x.route ? Route.toURL(x.route({ Route, state }) ) : '#'
	  ,oncreate: x.route ? m.route.link : null
	  ,onclick: x.onclick ? e => x.onclick(e, { api }) : null
	}
    ,m('li',
      { className:
        isActive ({ state, Route }) (x)
        ? 'top-nav-active'
        : ''
      }
      , x.title
    )
  )

const logo =
  m('img'
    + css`
      height: 100%;
      transition: 1s ease-in-out;
      cursor: pointer;
    `
    , {
      src: 'https://harth.io/assets/logo/harth-logo-black-orange-bar.png'
    }
  )

const hideScrollbars =
  css`
    -ms-overflow-style: none;
    scrollbar-width: none;
  `
  .$nest('::-webkit-scrollbar', `
    display: none;
  `)

export const component = () => {

	return {
		view: ({
			attrs: {
				Route,
				data: {
					getState
					, link
					, organizations
					, schedule_name
					, schedules
					, actual: {
						schedule_id
					}
					, modal
					, advancedView
					, auth_permissions
					, api
					, topNavBanner
					, features
				}
			}
		}) => {

		const route = getState().route

		const tags = []
			.concat(
				Route.isDataRoute( route ) ? ['data'] :[]
			)
			.concat(
				Route.isAccessRoute( route ) ? ['access'] : []
			)

		const auth_check = (x, auth_permissions) => {
			// flagon; permissions;
			return (
				x.permissions.length == 0
				|| permissions.some(
					y => x.permissions.includes(y.permission_name)
					? flagon.contains(
						auth_permissions().auth_permissions_read
						, y.permission_flag
					)
					: false
				)
			)
		}

		const validLinks =
			links
			.filter(
				x => x.route
					? R.intersection(x.tags, tags).length > 0
					: x
			)
			.filter(
				x => auth_check(x, auth_permissions)
					&& (!x.filter || x.filter({ features }))
			)

			.map(
				toLi (link) (getState(), { api, Route })
			)

		return m('nav'
			+ css`
				display: grid;
				grid-template-rows: 4em 2.5em;
				width: 100vw;
				font-family: Helvetica;
				position: sticky;
				top: 0px;
				z-index: 1;
				max-height: var(--top-nav-height);
				transition: max-height 0.5s;
			`
			.$nest('*', `
				tap-highlight-color: rgba(255, 255, 255, 0);
				box-sizing: border-box;
			`)
			.$nest('*:focus', css`
				outline: none;
			`)
			,m('header'
				+css`
					display: grid;
					background-color: white;
					padding: 0.6em 1em 0.6em 1em;
					border-bottom: solid 0.3em #020b4d;
					/* 2em is for FireFox */
					grid-template-columns: 3em 1fr 3em;
					/* Rows is so the nav doesnt render on top of everything */
					grid-template-rows: 2.5em;
					justify-content: space-between;
					align-items: center;
				`
				,logo
				,m('label'
					+css`
						transition: 0.3s ease-in-out;
						position: relative;
						overflow: hidden;
						margin: 1em 0em;
					`
					.$media('(min-width: 700px)', css`
						max-width: 100%;
					`),
					{ onchange: e => {
						const { org: o, schedule: s } =
							JSON.parse(e.target.value)
						const O = organizations.organization_id
						const S = schedule_id

						if( O() != o) {
							O(o)
						}
						if( S() != s ){
							S(s)
						}
						e.preventDefault()
					}

					, title:
						organizations.organization_name()
						+ ' - '
						+ schedule_name()
					}
					,[organizations.organizations()]
					.map(
						xs =>
							m('select' +css`
								position: absolute;
								opacity: 0;
								width: 100%;
								height: 100%;
							`
							,xs
							.sort(
								R.descend( x => x.organization_name )
							)
							.sort(

								R.descend(
									x => x.organization_id
										== organizations.organization_id()
								)
							)
							.map(
								o => o.organization_id
									== organizations.organization_id()
									? m('optgroup',
										{ label: o.organization_name }
										,schedules().map(
											x =>
												m('option',
													{ value:
														JSON.stringify({
															org: o.organization_id
															,schedule: x.schedule_id
														})
													, selected:
														o.organization_id == organizations.organization_id()
														&& x.schedule_id == schedule_id()
													}
													, x.schedule_name
												)
										)
									)
									: m('option',
										{ value:
											JSON.stringify({
												org: o.organization_id
												,schedule: null
											})
										}
										, o.organization_name
									)
							))

					)
					, m('.nav-header'
						+ css`

							font-weight: bold;
							font-size: 1.2em;
							text-align: center;
						`
						,m('.org'
							+ trunc_css
							, organizations.organization_name()
						)
						,m('.schedule'
							+ trunc_css
							+ css`
								font-size: 0.8em;
							`
							, schedule_name()
						)
					)
				)
				, m('label'
					+ css`
						font-size: 1.5em;
						font-weight: bold;
						position: relative;
						text-align: right;
					`
					, { onclick(){
						modal({
							dismissable: true
							, content:
								() => m(''
									+ css`
										display: grid;
										height: 100%;
										border-top: solid 0.2em orange;
										font-size: 1.5em;
									`
									, m(''
										+ css`
											padding: 1.2em;
											display: grid;
											grid-template-rows: ${
												`max-content max-content 1fr`
											};
											row-gap: 0.2em;
										`
										,m('span', 'Odin')
										,m('hr' + css.m(0) + css`
											margin: 0em 0em 0.4em 0em;
											background-color: white;
										`)
										,m('.links'
											+ css`
												display: grid;
												position: relative;
												height: 100%;
												align-content: start;
												row-gap: 1em;
											`
											.tablet(`
												padding: 0.5em 0em;
											`),
											{
												onclick: () => modal(null)
											}

											,m('a' + link_css
												,{
													onclick:
														function(e){
															if( advancedView && !advancedView()){
																advancedView(e)
															}
															else { advancedView(false) }
														}
												}
												, advancedView()
													? 'Basic View'
													: 'Advanced View'
											)

											,m('a' + link_css
												,
												{ href: '/access/login'
												, oncreate: m.route.link
												, onclick(){
													window.localStorage.setItem('route-before-logout', m.route.get())
												}
												}
												,'Log Out'
											)

											,m('a' + link_css
												,
												{ href: 'mailto:support@harth.io'
												}
												,'Support'
											)

											,m('a' + link_css
												+ css.tablet(`
													position: absolute;
													bottom: 0em;
													right: 0em;
												`),
												{ href: 'https://harth.io'
												, target: '_blank'
												, rel: 'noopener noreferrer'
												}
												, 'About'
											)



										)


									)
								)
						})
					} }
					, '...'
				)
			)
			,m('ul.subnav'
			+ hideScrollbars
			+ css`
				display: grid;
				background-color: var(--harth-blue);
				border-bottom: solid 1px #1a2154;
				color: white;
				align-content: center;
				grid-template-rows: 1fr;
				text-align: center;
				align-items: center;
				list-style: none;
				padding-left: 0px;
				margin: 0px;
				cursor: pointer;
				grid-template-columns: repeat(${validLinks.length}, max-content);
				column-gap: 1em;
				overflow-x: auto;
			`
			.$nest('li',css`
				display: grid;
				align-content: center;
				transform-origin: center;
				transition: 0.1s ease-in-out;
				height: 2em;
				padding: 0em 1em;
			`)
			.$nest('li:hover',css`
				transform: scale(0.95);
				transition: 0.2s ease-in-out;
				box-shadow: 0px 15px 0px -10px rgba(255,255,255,1);
			`)
			.$nest('li.top-nav-active',css`
				transform: scale(0.95);
				transition: 0.2s ease-in-out;
				box-shadow: 0px 15px 0px -10px orange;
			`)
			.$nest('li.top-nav-active:hover', css`
				transform: scale(0.9);
			`)
			.$nest('a,a:visited', css`
				text-decoration: none;
				color: whitesmoke;
				letter-spacing: 1.5px;
				font-size: 15px;
			`)
			, {
				onwheel(e){
					// Simulate horizontal scroll for mouse wheel on desktop
					if( e.deltaX === 0){
						const el = e.currentTarget
						const left = el.scrollLeft + e.deltaY * 100
						el.scroll({ left: left, top: 0, behavior: 'smooth' })
					}
					e.stopPropagation()
					e.stopImmediatePropagation()
				}
			}
			, [].concat(validLinks)
			)
			, m('.notice'
				+ css`
					max-height: ${
						topNavBanner()
						? `var(--top-nav-alert)`
						: `0em`
					};
					min-height: ${
						topNavBanner()
						? `var(--top-nav-alert)`
						: `0em`
					};
					overflow: hidden;
					box-sizing: border-box;
					display: grid;
					transition: max-height 0.5s, min-height 0.5s;
				`
				, topNavBanner()
			)
		)
		}
	}
	}

	// export function ScrollActiveIntoViewService(state$){

	//   // eslint-disable-next-line no-undef
	//   const { setTimeout, clearTimeout, document } = window

	//   let timeoutId
	//   S.dropRepeats(state$.map( x => x.route ))
	//     .map(
	//       () => {
	//         clearTimeout(timeoutId)
	//         return timeoutId = setTimeout(
	//           () => [document.querySelector('.top-nav-active')]
	//             .filter(Boolean)
	//             .forEach(

	//               el => el.scrollIntoView({
	//                 behavior: 'smooth'
	//                 ,block: 'center'
	//                 ,inline: 'start'
	//               })
	//             )
	//           ,1000
	//         )
	//       }
	//     )

	//   return S.of()
	// }
