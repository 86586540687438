import * as R from 'ramda'

Object.keys =
	Object.keys
	|| R.keys

Object.values =
	Object.values
	|| R.values

/* eslint-disable */
Array.prototype.flatMap =
	Array.prototype.flatMap
	|| function flatMapPolyfill(f){
		return this.reduce(
			(p,n) => p.concat( f(n) )
			, []
		)
	}


// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/assign
if (typeof Object.assign !== 'function') {
	// Must be writable: true, enumerable: false, configurable: true
	Object.defineProperty(Object, "assign", {
		value: function assign(target, varArgs) { // .length of function is 2
		'use strict';
		if (target === null || target === undefined) {
			throw new TypeError('Cannot convert undefined or null to object');
		}

		let to = Object(target);

		for (let index = 1; index < arguments.length; index++) {
			let nextSource = arguments[index];

			if (nextSource !== null && nextSource !== undefined) {
			for (let nextKey in nextSource) {
				// Avoid bugs when hasOwnProperty is shadowed
				if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
				to[nextKey] = nextSource[nextKey];
				}
			}
			}
		}
		return to;
		}
		,writable: true
		,configurable: true
	});
}

if (!Object.entries) {
	Object.entries = function( obj ){
	  let ownProps = Object.keys( obj )
		  ,i = ownProps.length
		  ,resArray = new Array(i); // preallocate the Array
	  while (i--)
		resArray[i] = [ownProps[i], obj[ownProps[i]]];

	  return resArray;
	};
}

if (!String.prototype.startsWith) {
    Object.defineProperty(String.prototype, 'startsWith', {
        value: function(search, rawPos) {
            let pos = rawPos > 0 ? rawPos|0 : 0;
            return this.substring(pos, pos + search.length) === search;
        }
    });
}

if (!String.prototype.endsWith) {
	String.prototype.endsWith = function(search, this_len) {
		if (this_len === undefined || this_len > this.length) {
			this_len = this.length;
		}
		return this.substring(this_len - search.length, this_len) === search;
	};
}

if (!Array.prototype.includes) {
	Array.prototype.includes = function(x) {
		return this.indexOf(x) > -1
	};
}

if (!String.prototype.includes) {
	String.prototype.includes = function(search, start) {
		'use strict';

		if (search instanceof RegExp) {
			throw TypeError('first argument must not be a RegExp');
		}
		if (start === undefined) { start = 0; }
		return this.indexOf(search, start) !== -1;
	};
}

if (!Element.prototype.matches) {
	Element.prototype.matches =
	Element.prototype.matchesSelector
	|| Element.prototype.mozMatchesSelector
	|| Element.prototype.msMatchesSelector
	|| Element.prototype.oMatchesSelector
	|| Element.prototype.webkitMatchesSelector
	|| function(s) {
		let matches = (this.document || this.ownerDocument).querySelectorAll(s)
			,i = matches.length;
		while (--i >= 0 && matches.item(i) !== this) {}
		return i > -1;
	};
}