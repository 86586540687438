import $ from 'sanctuary-def'

const UUID_PATTERN =
	/^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i

const $UUID = $.NullaryType(
	'defunctzombie/node-uuid'
	, 'https://github.com/defunctzombie/node-uuid'
	, s => UUID_PATTERN.test(s)
)

export default $UUID