import m from 'bacta'
import * as R from 'ramda'


function d([ head, ...tail]){

	const c = c => p => c+" "+p.x+" "+p.y

	return []
		.concat(
			head ? c('M')(head) : ""
			,R.map(c('L'), tail)
		)
		.join(' ')
}

function line(attrs, {x:x1,y:y1}, {x:x2,y:y2}){
	return m('line', R.merge( { x1, y1, x2, y2 }, attrs ))
}

function dashLine(attrs, p1, p2){
	return line(R.merge({ 'stroke-dasharray':'100, 50' }, attrs), p1, p2 )
}

function circle(attrs, { cx, cy, r }){
	return m('circle', R.merge(attrs, { cx, cy, r }))
}

function shape(attrs, points){
	return m('path'
		,R.merge(
			attrs
			, {
				d: d(points).concat(
					points.length ? 'Z' : []
				)
			}
		)
	)
}

function path(attrs, points){
	return m('path', R.merge(attrs, { d: d(points)}) )
}

function svgShape(attrs, points){
	return m('svg', { style: {overflow: 'visible'} }, shape(attrs, points))
}

function svgPath(attrs, points){
	return m('svg', { style: {overflow: 'visible'} }, path(attrs, points))
}

function svg(children){
	return m('svg', { style: {overflow: 'visible'} }, children)
}


export default
	{ d
	, line
	, dashLine
	, circle
	, shape
	, path
	, svgShape
	, svgPath
	, svg
	}