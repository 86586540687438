/* globals window */
import css from 'bss'
import m from 'bacta'

function Main(){

	const transition =
		css
		.$animate('0.2s forwards', {
			'0%': `transform: translateY(100vh); opacity: 0;`
			,'100%': `transform: translateY(var(--modal-offset)); opacity: 1;`
		})
		.tablet(
			css
			.$animate('0.2s forwards', {
				'0%':
					`transform: translateX(calc(100vw)); opacity: 0;`
				,'100%':
					`transform: translateX(calc(100vw - 400px)); opacity: 1;`
			})
		)
		.class

	function view({ attrs: { content, offset={ mobile:{y:'50vh'}, desktop:{y:'0vh'} } }}){
		return m('.modal'
			+ css`
				--modal-offset: ${offset.mobile.y};
				background-color: black;
				color: white;
				position: absolute;
				left: 0px;
				top: 0px;
				width: 100vw;
				height: calc(100vh - var(--modal-offset));
				display: block;
			`
			.tablet(`
				--modal-offset: ${offset.desktop.y};
				width: 400px;
				height: 100vh;
			`)
			, content()
		)
	}

	return {
		view
		,oncreate({ dom }){
			window.requestAnimationFrame(
				() => dom.classList.add(transition)
			)
		}
		,onbeforeremove({ dom }){
			window.requestAnimationFrame(
				() => {
					dom.classList.replace(
						transition,
						css
						.$animate('0.2s forwards', {
							'0%': `transform: translateY(50vh); opacity: 1;`
							,'100%': `transform: translateY(100vh); opacity: 0;`
						})
						.tablet(
							css`
								width: 400px;
							`
							.$animate('0.2s forwards', {
								'0%':
									`transform: translateX(calc(100vw - 400px)); opacity: 1;`
								,'100%':
									`transform: translateX(calc(100vw)); opacity: 0;`
							})
						)
						.class
					)
				}
			)
			return new window.Promise(resolve => {
				// dom.style.maxHeight = 0
				dom.addEventListener(
					'animationend', resolve, { once: true }
				)
			  })
		}
	}
}

export default Main