/* global window */
/* eslint-disable no-unused-vars */ // temporary
import h, * as H from '../../../how/index'
import { Stream as stream } from 'bacta'
import * as E from '../components/elements'
import * as D from '../components/detailspane2'
import Form from '../components/form'
import DropdownButton from '../components/buttonDropdown'
import NotificationBar from '../components/notification'
import Autocomplete from '../modules/autocomplete'

import permissions from '../../../api/permissions.json'

function ConfirmDeleteOrg({
	attrs: { organization, $, Route, data, form }
 }) {

	let confirmed = false

	const view = () =>
		confirmed
		? E.actionEvent('Confirm Delete', async e => {
			e.preventDefault()
			const org = organization()

			// We need to change org context before we delete
			// So queries don't become invalid in a no-org context
			const diffOrg =
				$.organizations()
					.find( x => x.organization_id != org.organization_id )

			// To prevent duplicates in the stack we go back to (presumably) the list
			// or it may be editing a different org

			await Route.back()

			// We can be done here, but we need to check if we're focused on this org
			// if so, we need to replace with a new org
			if( Route.getValue().organization_id == org.organization_id ) {

				// We could use replace value but that could create an invalid (group|role,organization_id) pairing
				// So we go to List.  This means if you were editing another org before this one and you went
				// straight to editing a different org without going to list
				// we are effectively rewriting history, but thats pretty good users are unlikely to notice
				// the alternative would be branching on route which would be brittle
				Route.replace(
					Route.List({ organization_id: diffOrg.organization_id })
				)
			}

			// At this point we should have removed the org from the recent history stack
			// It should be fairly safe to delete it now

			await data.api.organizations.delete(org)

			// We now tell the app to refetch all the auth data
			// because the org data has changed
			// this ensures the top nav syncs up with the latest state
			// and ensures the rest of the app is up to date
			await data.auth_refresh()

			confirmed = false
		}, () => {}, 'danger', () => false, NotificationBar.alertError)
		: E.actionEvent('Delete', e => {
			e.preventDefault()
			confirmed = true
		}, () => form.$.unsaved() || $.organizations().length < 2, 'danger')

	return {
		view
	}
}

function ConfirmDeleteGroup({
	attrs: { group, data, form, $ }
 }) {

	let confirmed = false

	const view = () =>
		confirmed
		? E.actionEvent('Confirm Delete', async e => {
			e.preventDefault()
			const g = group()

			window.history.back()


			await data.api.groups2.delete(g)

			// We now tell the app to refetch all the auth data
			// because the org data has changed
			// this ensures the top nav syncs up with the latest state
			// and ensures the rest of the app is up to date
			await data.auth_refresh()

			$.groups = $.groups().filter( x => x.group_id != g.group_id )

			confirmed = false
		}, () => {}, 'danger')
		: E.actionEvent('Delete', e => {
			e.preventDefault()
			confirmed = true
		}, () => form.$.unsaved(), 'danger')

	return {
		view
	}
}

function ConfirmDeleteRole({
	attrs: { role, data, form, $ }
}) {

	let confirmed = false

	const view = () =>
		confirmed
		? E.actionEvent('Confirm Delete', async e => {
			e.preventDefault()

			const r = role()

			window.history.back()

			await data.api.roles.delete(r)

			// We now tell the app to refetch all the auth data
			// because the org data has changed
			// this ensures the top nav syncs up with the latest state
			// and ensures the rest of the app is up to date
			await data.auth_refresh()

			$.roles = $.roles().filter( x => x.role_id != r.role_id )

			confirmed = false
		}, () => {}, 'danger')
		: E.actionEvent('Delete', e => {
			e.preventDefault()
			confirmed = true
		}, () => form.$.unsaved(), 'danger')

	return {
		view
	}
}

const saveDone = ({ form, row }) =>
	h('.actions'
		+ H.css`
			display: grid;
			gap: 1em;
			grid-auto-flow: column;
		`
		,h('button.btn.btn-primary',
			{
				type: 'submit'
				,disabled: form.isSaveDisabled()
			}
			,'Save'
		)
		,h('button.btn.btn-default',
			{
				type: 'button'
				,onclick: () => window.history.back()
			}
			,row.unsaved() ? 'Cancel' : 'Done'
		)
	)

function EditOrg({ attrs: { organization, data, Route, $ } }){
	const view = () => !organization() ? null : h(Form, {
		initialValues: organization.$stream()
		,async onsubmit({ $ }){

			try {
				if( $.unsaved() ) {
					// Step 1: Patch the Name
					await data.api.organizations.create($())
					// remove unsaved property from form
					// $.unsaved.$remove()
				} else {
					// Step 1: Patch the Name
					await data.api.organizations.update($())
				}

				// remove unsaved property
				delete $.unsaved

				// Step 2: Orgs is complicated in Odin so we manually
				// sync the org.  This can go away when we replace
				// the org model and completely delete state-router
				await data.auth_refresh()

				let answer =
					await data.api.organizations.get({ organization_id: $().organization_id})
					.then( JSON.stringify ).then( JSON.parse)

				Object.assign(organization, answer)

				// Step 3: Tell this route about the new values
				// organization($())

			} catch(e) {
				NotificationBar.Notifications.alertError(e.message)
			} finally {
				// disable reset form
				return { values: organization() }
			}

		}
	}, form =>
		h('.edit-org'
			+ H.css`
				display: grid;
				gap: 1em;
				padding: 1em 0em;

				& label {
					display: grid;
					gap: 0.3em;
				}
				& input[type=text] {
					padding: 0em 0.5em;
				}

				& .alert {
					margin-bottom: 0px;
				}
			`
			, { key: Route.getValue().organization_id }
			, h('label'
				, h('span', 'Organization Name')
				,  h('input.form-control'
					,
					{ name: 'organization_name'
					, required: true
					, ...form.bind({ value: organization.organization_name })
					}
				)
			)
			, h('label'
				, h('span', 'Incorporation No.')
				, h('input.form-control'
					,
					{ name: 'organization_incorporation_no'
					, ...form.bind({
						value: organization.organization_incorporation_no
					})
					, required: false
					}
				)
			)
			, h('label'
				, h('span', 'Phone')
				, h('input.form-control'
					,
					{ name: 'organizations_details_phone'
					, ...form.bind({
						value: organization.organizations_details_phone
					})
					, required: false
					}
				)
			)
			, h('label'
				, h('span', 'Address')
				, h('input.form-control'
					,
					{ name: 'organizations_details_address'
					, ...form.bind({
						value: organization.organizations_details_address
					})

					, required: false
					}
				)
			)
			, h('label'
				, h('span', 'State / Province')
				, h('input.form-control'
					,
					{ name: 'organizations_details_state'
					, ...form.bind({
						value: organization.organizations_details_state
					})
					, required: false
					}
				)
			)
			, h('label'
				, h('span', 'Zip / Post Code')
				, h('input.form-control'
					,
					{ name: 'organizations_details_postcode'
					, ...form.bind({
						value: organization.organizations_details_postcode
					})
					, required: false
					}
				)
			)

			, saveDone({ form, row: organization })
			, form.$.unsaved() || E.alert('danger', 'Deleting an organization cannot be undone. This will eliminate all associated data')
			, form.$.unsaved()
			|| h(ConfirmDeleteOrg, { organization, form, data, Route, $ })
		)
	)

	return { view }
}

function GroupUsers(){
	const view = ({ attrs: { form, $ }}) => {
		const list =
			$.users.$map( (xs, ys) => {
				const ids = new Set( ys.map( x => x.user_id ))
				const out = xs.filter( x => !ids.has(x.user_id) )
				return out.map( x => x.user_username)
			}, form.$.users.$stream())
			.$stream()
			.default([])


		return h(''
			+ H.css`
				display: grid;
				gap: 1em;
			`
			, h('label'
				+ H.css`
					display: grid;
					gap: 0.5em;
				`
				, 'Add Users'
				, h(Autocomplete.Main, {
					list
					,disabled: list().length
					,title: list().length
						? 'Search for users to add to the group'
						: 'All users have been selected'
					,onselect(user_username, model){
						const { user_id, user_name, user_email } =
							$.users().find( x => x.user_username == user_username )

						form.$.users(
							xs => xs.concat({
								user_id, user_username, user_name, user_email
							})
						)

						window.setTimeout( () => {
							model.input('')
							model.open(false)
						}, 0)
					}
				})
			)
			, form.$.users.length() > 0 && h(''
				, h('p', 'Group Users:')
				, E.strikeList(
					form.$.users().map(
						x => ({
							label: x.user_name
							, action(){
								form.$.users(
									xs => xs.filter(
										y => y.user_id != x.user_id
									)
								)
							}
						})
					)
				)
			)
		)
	}
	return { view }
}

function GroupInvites(){
	let inviteNode;

	const view = ({ attrs: { form, $ } }) =>
		h(''
			+ H.css`
				display: grid;
				gap: 1em;
			`
			, h('form'
				+ H.css`
					display: grid;
				`
				, {
					onsubmit(e){
						e.preventDefault()
						form.$.invites( xs => xs.concat( $.invite_email () ))
						$.invite_email = ''
					}
				}
				, h('label'
					+ H.css`
						display: grid;
						gap: 0.5em;
					`
					, 'Invite Email'
					, E.textInput(
						$.invite_email
						,
						{ type: 'email'
						, oncreate: (vnode) => inviteNode = vnode
						, required: true
						}
					)
				)
				, h('button.btn.btn-default', 'Invite')
			)
			, form.$.invites.length() > 0
				&& h(''
					, h('p', 'Group Invites:')
					, E.strikeList(
						form.$.invites().map(
							x => ({
								label: x
								, action(){
									form.$.invites(
										xs => xs.filter(
											y => y != x
										)
									)
								}
							})
						)
					)
				)
	)

	return { view }
}

function GroupRoles(){
	const view = ({ attrs: { form, $ } }) => {

		const list =
			$.roles.$map( (xs, ys) => {
				const ids = new Set( ys.map( x => x.role_id ))
				const out = xs.filter( x => !ids.has(x.role_id) )
				return out.map( x => x.role_name)
			}, form.$.roles.$stream())
			.$stream()
			.default([])

		return h(''
			+ H.css`
				display: grid;
				gap: 1em;
			`
			, h('label'
				+ H.css`
					display: grid;
					gap: 0.5em;
				`
				, 'Add Roles'
				, h(Autocomplete.Main, {
					list
					,disabled: list().length
					,title: list().length
						? 'Search for roles to add to the group'
						: 'All roles have been selected'
					,onselect(role_name, model){
						const { role_id } =
							$.roles().find( x => x.role_name == role_name )

						form.$.roles(
							xs => xs.concat({ role_id, role_name })
						)

						window.setTimeout( () => {
							model.input('')
							model.open(false)
						}, 0)
					}
				})
			)
			, form.$.roles.length() > 0 && h(''
				, h('p', 'Group Roles:')
				, E.strikeList(
					form.$.roles().map(
						x => ({
							label: x.role_name
							, action(){
								form.$.roles(
									xs => xs.filter(
										y => y.role_id != x.role_id
									)
								)
							}
						})
					)
				)
			)
		)
	}

	return { view }
}

function EditGroup({
	attrs: { group, data, Route, $ }
}){

	const initialValues =
		group.$stream().filter(Boolean).map( group => {
			return {
				...group
				, invite_email: ''
				, _invites: JSON.stringify(group.invites)
				, _users: JSON.stringify(group.users.map( x => x.user_id ))
				, _roles: JSON.stringify(group.roles.map( x => x.role_id ))
			}
		})

	const view = () => {
		return !group() ? null : h(Form, {
			initialValues
			,async onsubmit({ $ }){

				try {
					if( $.unsaved() ) {
						// Step 1: Patch the Name
						await data.api.groups2.create($())
						// remove unsaved property from form
						// $.unsaved.$remove()
					} else {
						// Step 1: Patch the Name
						await data.api.groups2.update($())
					}

					// remove unsaved property
					delete $.unsaved

					// Step 2: Tell this route about the new values
					group($())

					// Step 3: Any time orgs/groups/roles change
					// we need to refetch auth permissions
					// or the UI will think we have access that we don't have
					await data.auth_refresh()

				} catch(e) {
					NotificationBar.Notifications.alertError(e.message)
				} finally {
					// disable reset form
					return { values: group() }
				}

			}
		}, form =>
			h('.edit-group'
				+ H.css`
					display: grid;
					gap: 1em;
					padding: 1em 0em;

					& label {
						display: grid;
						gap: 0.3em;
					}

					& input[type=text] {
						padding: 0em 0.5em;
					}

					& .alert {
						margin-bottom: 0px;
					}
				`
				, { key: Route.getValue().group_id || 'no-group' }
				, () => {
					// By default the form tracks changes oninput
					// But any interaction that uses custom input handling
					// like an onselect in an autocomplete, or a button
					// needs to be manually registered
					//
					// In this route we just create a JSON serialized
					// list of primary keys when the actual form is modified
					//
					// This is then diffed against the initial values
					// which propagates to the `changes` and `changed`
					// streams.
					//
					// One day it would be great to have Z automatically
					// call form.inputHandler when any query write happens.
					// Definitely possible, but for now this is pretty robust.
					form.$.invites.$stream().map( xs => {
						form.inputHandler({
							path: '_invites'
							,value: JSON.stringify(xs)
						})
						return null
					})

					form.$.users.$stream().map( xs => {
						form.inputHandler({
							path: '_users'
							,value: JSON.stringify(xs.map( x => x.user_id ))
						})
						return null
					})

					form.$.roles.$stream().map( xs => {
						form.inputHandler({
							path: '_roles'
							,value: JSON.stringify(xs.map( x => x.role_id ))
						})
						return null
					})
				}
				, h('label'
					, h('span', 'Group Name')
					, h('input.form-control'
						,
						{ name: 'group_name'
						, ...form.bind({ value: group.group_name})
						, required: true
						, disabled: group.group_name() == 'Worker'
						}
					)
				)
				, E.alert('info','Assign users, roles and data to groups to control how data is accessed.')
				, h(GroupRoles, { form, $ })
				, h(GroupUsers, { form, $ })
				, h(GroupInvites, { form, $ })
				, saveDone({ form, row: group })
				, form.$.unsaved() || E.alert('danger', 'Deleting a group cannot be undone. Make sure all users in this group belong to other groups or they will be removed from the organization.')
				, form.$.unsaved()
				|| h(ConfirmDeleteGroup, { group, data, form, $ })
			)
		)
	}

	return { view }
}

function PermissionsList({ attrs: { $, form }}){
	const list =
		$.permissions.$map( (xs, ys) => {
			const ids = new Set( ys.map( x => x.permission_id ))
			const out = xs.filter( x => !ids.has(x.permission_id) )
			return out.map( x => x.permission_name_pretty)
		}, form.$.permissions.$stream())
		.$stream()
		.default([])

	let selectPermission = () => {}

	const view = () => [
		h('label',
			'Search for Permissions',
			h(Autocomplete.Main, {
				list
				,onselect(y, model){
					form.$.permission = y

					window.setTimeout( () => {
						model.open(false)
					})

					const permission =
						$.permissions().find(
							x => x.permission_name_pretty == y
						)

					selectPermission = ({
						role_permissions_write
					}) => {
						model.input('')
						model.open(false)
						form.$.permission = null
						form.$.permissions(
							xs => xs.concat({
								...permission
								,role_permissions_write
							})
						)
					}
				}
			})
		)
		, h('.actions'
			+ (form.$.permission() ? '' : '.inactive')
			+ H.css`
				display: grid;
				grid-auto-flow: column;
				gap: 1em;
				opacity; 1;
				max-height: 10em;
				transition: opacity 2s 0.5s, max-height 1s 0.5s;


				& .fa-plus {
					color: #555;
					margin-right: 0.5em;
				}

				&.inactive {
					opacity: 0;
					max-height: 0;
					overflow-y: hidden;
					margin: 0em inherit;
					padding: 0em inherit;
					transition: opacity 0.1s, max-height 1s 0.5s;
				}
			`
			, h('button.btn.btn-default',
				{
					type: 'button'
					,onclick: () => selectPermission({
						role_permissions_write: false
					})
				},
				h('i.fa.fa-plus'), 'Read'
			)
			, h('button.btn.btn-default',
				{
					type: 'button'
					,onclick: () => selectPermission({
						role_permissions_write: true
					})
				},
				h('i.fa.fa-plus'), 'Write'
			)
		)
	]

	return { view }
}

function EditRole({
	attrs: { organization, role, data, Route, $ }
}){
	const initialValues =
		role.$stream().filter(Boolean).map( role => {
			return {
				...role
				, _permission: null
				, _permissions:
					JSON.stringify(
						role.permissions
							.map( x => x.permission_id + x.role_permissions_write )
					)
			}
		})

	const permissionLabel = x => {
		const name = x.permission_name_pretty
		const readWrite = x.role_permissions_write
			? '(Read & Write)' : '(Read Only)'

		return `${name} ${readWrite}`
	}

	const view = () => !role() ? null : h(Form, {
		initialValues
		,async onsubmit({ $ }){
			try {
				if( $.unsaved() ) {
					// Step 1: Patch the Name
					await data.api.roles.create($())
					// remove unsaved property from form
					// $.unsaved.$remove()
				} else {
					// Step 1: Patch the Name
					await data.api.roles.update($())
				}

				// remove unsaved property
				delete $.unsaved

				// Step 2: Tell this route about the new values
				role($())

				// Step 3: Any time orgs/groups/roles change
				// we need to refetch auth permissions
				// or the UI will think we have access that we don't have
				await data.auth_refresh()

			} catch(e) {
				NotificationBar.Notifications.alertError(e.message)
			} finally {
				// disable reset form
				return { values: role() }
			}
		}
	}, form =>
		h('.edit-role'
			+ H.css`
				display: grid;
				gap: 1em;
				padding: 1em 0em;

				& label {
					display: grid;
					gap: 0.3em;
				}

				& input[type=text] {
					padding: 0em 0.5em;
				}

				& .alert {
					margin-bottom: 0px;
				}
			`
			, { key: Route.getValue().role_id || 'no-role' }
			, () => {
				// By default the form tracks changes oninput
				// But any interaction that uses custom input handling
				// like an onselect in an autocomplete, or a button
				// needs to be manually registered
				//
				// In this route we just create a JSON serialized
				// list of primary keys when the actual form is modified
				//
				// This is then diffed against the initial values
				// which propagates to the `changes` and `changed`
				// streams.
				//
				// One day it would be great to have Z automatically
				// call form.inputHandler when any query write happens.
				// Definitely possible, but for now this is pretty robust.
				form.$.permissions.$stream().map( xs => {
					form.inputHandler({
						path: '_permissions'
						,value: JSON.stringify(
							xs.map( x => x.permission_id + x.role_permissions_write )
						)
					})
					return null
				})
			}
			, h('label'
				, h('span', 'Role Name')
				, h('input.form-control'
					,
					{ name: 'role_name'
					, ...form.bind({ value: role.role_name })
					, required: true
					}
				)
			)
			, E.alert('info', [
				'A role is a set of permissions that can be applied to a Group. '
				,'Users assigned to a Group will inherit permissions from this group. '
				,'See a full list of Odin permissions '
				, h('a',
					{ href: 'https://harth.io/documentation/permissions-descriptions.html'
					, target:  '_blank'
					, rel: "noopener noreferrer"
					}
					, 'here'
				)
			])
			, h(PermissionsList, { $, form })
			, form.$.permissions.length() > 0 && h(''
				, h('p', 'Role Permissions:')
				, E.strikeList(
					form.$.permissions().map(
						x => ({
							label: permissionLabel(x)
							, action(){
								form.$.permissions(
									xs => xs.filter(
										y => y.permission_id != x.permission_id
									)
								)
							}
						})
					)
				)
			)
			, saveDone({ form, row: role })
			, form.$.unsaved() || E.alert('danger', 'Deleting a role cannot be undone. This will alter the permissions for all groups that use this role and all users within those groups.')
			, form.$.unsaved()
			|| h(ConfirmDeleteRole, { role, data, form, $ })
		)
	)

	return { view }
}

export default h.routed(
	'Organizations2'
	, (x, attrs) => x.List({ organization_id: attrs.data.organization()} )
	, {
		List: '/:organization_id'
		,Organization: '/:organization_id/edit'
		,Group: '/:organization_id/groups/:group_id/edit'
		,Role: '/:organization_id/roles/:role_id/edit'
	}
	, { search: { tab: 'groups' } }
	, (v) => {
		const { attrs: { Route, $, $$, ParentRoute, data } } = v
		const _ = {
			all: H.options.otherwise(Route.tags)
			,edit: H.options.otherwise(['Organization', 'Group', 'Role'])
		}

		const isEdit = $.route.$stream().map(
			Route.fold({
				... _.all( () => false )
				, ..._.edit ( () => true )
			})
		)
		const organization_id = $.route.value.organization_id.$stream()

		const definedEquals = (x,y) => x != null & y != null && x == y
		// const organization_id = $.route.value.organization_id.$stream()
		const group_id = $.route.value.group_id.$stream()
		const role_id = $.route.value.role_id.$stream()

		const search = Route.search

		$.loading = true
		$.groups = []
		$.roles = []
		$.users = []
		$.invites = []
		$.permissions = permissions.filter( x => x.permission_selectable )
		$.auth_permissions = []
		$.organizations = data.organizations.organizations()
		$.detailsPaneTitle = null

		$.dev = true

		data.organizations.organizations.map( xs => {
			$.organizations = xs || []
			return null
		})

		const organization =
			$.organizations
			.$values.$filter( (x,y) => x.organization_id == y, organization_id )

		const group =
			$.groups
			.$values.$filter( (x,y) => definedEquals(x.group_id, y), group_id )

		const role =
			$.roles
			.$values.$filter( (x,y) => definedEquals(x.role_id, y), role_id )

		let formView = () => null
		let formTitle = null

		// details pane item
		stream.merge([
			role.role_name.$stream().default(null)
			,group.group_name.$stream().default(null)
			,organization.organization_name.$stream()
			,Route.$stream()
		])
		.map( () => {
			const f = Route.fold({
				... _.all( () => null )
				, Organization: () => {
					let $ = organization.organization_name()
					$= $ ? 'Editing ' + $ : 'Creating new Organization'
					return $
				}
				, Group: () => {
					let $ = group.group_name()
					$= $ ? 'Editing ' + $ : 'Creating new Group'
					return $
				}
				, Role: () => {
					let $ = role.role_name()
					$= $ ? 'Editing ' + $ : 'Creating new Role'
					return $
				}
			})

			const g = Route.fold({
				... _.all( () => () => null )
				, Organization: () => attrs => h(EditOrg, attrs)
				, Group: () => attrs => h(EditGroup, attrs)
				, Role: () => attrs => h(EditRole, attrs)
			})

			formTitle = f($.route())
			formView = g($.route())
			h.redraw()
			return null
		})

		// receive-global-org-id
		data.organizations.organization_id.map( globalOrgId => {

			if( globalOrgId == organization_id() ) return null;
			const next =
				Route.toURL(Route.List({ organization_id: globalOrgId }))

			Route.set(Route.fromURL(next)) // no search

			return null
		})

		// update-global-org-id
		organization.$stream().map( x => {
			if(x && !x.unsaved) {
				data.organizations.organization_id(x.organization_id)
			}
			return null
		})

		// fetch-extra-org-data
		Route.$stream().map( async ({ value: { organization_id }}) => {
			if( organization_id ) {

				// this binding may need to be tweaked a bit
				const answer = await data.api.organizations.get({ organization_id})
					.then( JSON.stringify ).then( JSON.parse).catch( () => null )

				// merging onto `organization` query should target the active
				// org automatically when the url has an org id in it
				if ( answer ) {
					Object.assign(organization, answer)
				}
			}
		})

		// fetch
		organization.$stream().map( async o => {
			if( o && !o.unsaved ) {
				$.loading = true

				$.groups = await data.api.groups2.fetch.byOrganization( o )
					.then( JSON.stringify ).then( JSON.parse)

				$.roles = await data.api.roles.fetch.byOrganization( o )
					.then( JSON.stringify ).then(JSON.parse)

				$.users = await data.api.users2.fetch.all()

				$.loading = false
			} else {
				$.groups = []
				$.roles = []
			}
			return null
		})

		// window-expose
		$.dev.$stream().map( dev => {
			dev && Object.assign(window, {
				H
				, h
				, Route
				, $
				, $$
				, ParentRoute
				, organization
				, organization_id
				, group
				, role
				, data
			})
			return null;
		})

		// auto-redraw
		stream.merge([
			$.route.$stream()
			,$.roles.$values.role_name.$stream()
			,$.roles.$values.group_name.$stream()
		]
		).map( () => h.redraw() )

		// Instead of initializing an empty group
		// on a click event, we create one when the state
		// would imply we are creating a new group
		// this means on a cold boot of the page the data
		// will be initialized just as if you had clicked "Create Group"

		// initialize-empty-group
		stream.merge([
			$.loading.$stream()
			,$.route.value.group_id.$stream()
			,group.$stream().default(null)
		])
		.map( ([loading, group_id, g]) => {
			if( group_id && !loading && !g ) {
				$.groups(
					xs => xs.filter( x => !x.unsaved )
					.concat({
						group_id
						, group_name: ''
						, roles: []
						, users: []
						, invites: []
						, unsaved: true
					})
				)
			}
			return null;
		})

		// Instead of initializing an empty role
		// on a click event, we create one when the state
		// would imply we are creating a new role
		// this means on a cold boot of the page the data
		// will be initialized just as if you had clicked "Create Group"
		stream.merge([
			$.loading.$stream()
			,$.route.value.role_id.$stream()
			,role.$stream().default(null)
		])
		.map(
			([loading, role_id, _role]) => {
				if( role_id && !loading && !_role ) {

					$.roles(
						xs => xs.filter( x => !x.unsaved )
						.concat({
							role_id
							, role_name: ''
							, organization_id: Route.getValue().organization_id
							, permissions: []
							, unsaved: true
						})
					)
				}
				return null;
			}
		)

		// Instead of initializing an empty org
		// on a click event, we create one when the state
		// would imply we are creating a new org
		// this means on a cold boot of the page the data
		// will be initialized just as if you had clicked "Create Organization"
		stream.merge([
			$.loading.$stream()
			,$.route.value.organization_id.$stream()
			,organization.$stream().default(null)
			,$.route.$stream()
		])
		.map( ([loading, organization_id, org, route]) => {
			if(
				organization_id
				&& !loading
				&& !org
				&& Route.isOrganization(route)
			) {

				$.organizations(
					xs => xs.filter( x => !x.unsaved )
					.concat({
						organization_id
						, organization_name: ''
						, organization_incorporation_no: ''
						, organizations_details_id: null
						, organizations_details_phone: ''
						, organizations_details_address: ''
						, organizations_details_state: ''
						, organizations_details_postcode: ''
						, unsaved: true
					})
				)
			}
			return null;
		})


		function view(){
			return h('.organizations2'
				+ H.css`
					display: grid;
					gap: 2em;
					padding: 1em;

					& * {
						margin: 0em;
						padding: 0em;
					}

					form input {
						background-color: transparent;
						color: black;
					}
				`
				, h('header'
					+ H.css`
						display: flex;
						justify-content: space-between;
						align-items: center;

						& .desktop {
							display: none;
						}

						@media( min-width: 1000px ) {
							& .desktop {
								display: inline;
							}
						}
					`
					, h('h4',
						h('.desktop', 'Groups, Users, Roles and Access: ')
						, organization.unsaved()
						? 'Creating new Organization'
						: organization.organization_name()
					)
					, h('.actions'
						, h(DropdownButton, {
							defaultOption:
								[
									['organizations', 'Create Organization']
									,['groups', 'Create Group']
									,['roles', 'Create Role']
								]
								.filter( ([x]) => x == search.$.tab() )
								.map( ([_, x]) => x)
								[0]
								|| 'Create Organization'
							,onselect(option){
								if( option == 'Create Organization' ) {
									const organization_id = H.id()

									Route.set( Route.Organization({ organization_id }))
								} else if( option == 'Create Group' ) {
									const group_id = H.id()

									const o = Route.getValue()
									Route.set( Route.Group({ ...o, group_id }))
								} else if( option == 'Create Role' ) {
									const role_id = H.id()

									const o = Route.getValue()
									Route.set( Route.Role({ ...o, role_id }))
								}
							}
							,options: [
								'Create Organization'
								,'Create Group'
								,'Create Role'
							]
							, text: 'Create'
						})
					)
				)
				, E.tabset(['organizations', 'groups', 'roles'], search.$.tab)
				, h('.tab-content-pane'
					+ H.css`
						display: grid;
						gap: 1em;
					`
					,

					search.$.tab() == 'organizations'
					&& $.organizations().map( ({ organization_id, organization_name }) =>
						h('a'
							, Route.link(
								Route.Organization({ organization_id })
								, {
									replace: isEdit()
									,back: () => Route.List({ organization_id})
								}
							)
							, organization_name
						)
					)
					, search.$.tab() == 'groups'
					&& $.groups().map( ({ group_id, group_name }) =>
						h('a'
							, Route.link(
								Route.Group({ organization_id: organization_id(), group_id })
								, { replace: isEdit() }
							)
							, group_name
						)
					)
					, search.$.tab() == 'roles'
					&& $.roles().map( ({ role_id, role_name }) =>
						h('a'
							, Route.link(
								Route.Role({ organization_id: organization_id(), role_id })
								, { replace: isEdit() }
							)
							, role_name
						)
					)
				)
				, D.Light(
					() => isEdit()
					, () => formTitle
					, () => h(''
						+ H.css`
							& input {
								background-color: transparent;
								color: black;
							}

							& .manuel-complete input {
								border: 0px;
								box-shadow: none;
								border-bottom: solid 1px #CCC;
								border-radius: 0px;
							}

							& .manuel-complete input:focus {
								border: 0px;
								border-bottom: 1px solid hsl(200, 80%, 60%);
							}
						`
						, formView({
							data
							, $
							, organization
							, group
							, role
							, Route
						})
					)
				)
			)
		}

		return {
			view
		}
	})