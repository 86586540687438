/* globals window */
import m from 'bacta'
import css from 'bss'
import * as M from 'motion'
/**
 * Manages the stacking of modals.  On desktop modals will visually stack, on mobile they will take up the full view.
 *
 * The stacking is history aware, so back button will take you back to the previous modal.
 *
 * This modal does not handle browser refresh, not that it couldn't, but the rest of the app
 * isn't history aware so it is something we should fix globally before making this component
 * support it as well.
 *
 * In theory, we could support it dynamically by registering a history record in local storage
 * for each modal, could just be an id.
 *
 * Then we hit the back button, we go retrieve the data for that id taken from the URL.
 *
 * Additional modal history shouldn't obstruct the URL of the main page.
 * So it is likely we'd want to encode almost all modal URL state in query params
 * and in a way that won't collide with existing query params.
 *
 * Because the modal stack manages all modals it is a singleton model.
 *
 * But it is also a view.
 */


function ModalItem({
	view
	, title
	, type
	, dismissable=true
	, onnext=null
}){
	return { view, title, type, dismissable, onnext }
}

const modals = []
let minimized = false

export function add({ view, title, type, dismissable, onnext }){
	modals.unshift(ModalItem({ view, title, type, dismissable, onnext }))
	m.redraw()
}

export function back(){
	if( modals.length && modals[0].dismissable ) {
		modals.shift()
		m.redraw()
	}
}

export function minimize(){
	minimized = !minimized
	m.redraw()
}

export function close(){
	modals.length = 0
	m.redraw()
}

export function isOpen(){
	return !minimized && modals.length > 0
}

function ModalItemView(){

	function onbeforeupdate(v, old){
		if( old && old.attrs.i != v.attrs.i ) {

			M.animate(
				old.dom
				, v.attrs.i == 0 ? {
					x: '0%'
					,scaleX: 1
					,scaleY: 1
				}
				: {
					x: 'calc( -100vw / 2 - var(--w) / 4)'
					,scaleX: 0.5
					,scaleY: 0.5
				}
				, {
					scaleX: { delay: 0.1 }
				}

			)
		}
		return true
	}


	function oncreate(v) {
		M.animate(v.dom,
			{ y: `var(--initial-y)`
			}
			, {duration: 0 }
		)
		window.setTimeout( () =>
			M.animate(
				v.dom, { y: '0px' }, { duration: 0.2 }
			)
		)
	}

	function onbeforeremove(v){
		return M.animate(
			v.dom
			, { y: 'var(--initial-y)' }
			, { delay: 0.1, duration: 0.2 }
		)
		.finished
	}


	return {
		view: ({ attrs: { i, modals }}) =>
			// v.attrs.i === 0
			// &&
			m('.modal-real-item'
				+ css`
					display: grid;
					grid-template-rows: auto 1fr;
					gap: 1em;
					position: absolute;
					background-color: white;
					z-index: 1;
					padding: 1em 0em 0em 0em;
					border-radius: 0.5em;
					width: 100%;
					height: 100%;
					bottom: 0px;
					left: 0px;
					--initial-y: calc( 100% + var(--vgap) );
				`
				.desktop`
					padding: 1em;
				`
				.$nest('.nav2 button', `
					color: #5f99ff;
					background-color: unset;
					border: none;
				`)
				.$nest('.nav2 .title', `
					font-weight: bold;
				`)
				, i == 0 && m('.nav2'
					+ css`
						display: grid;
						justify-content: space-between;
						grid-template-columns: 5em 1fr 5em;
					`
					.$nest('.title', `text-align: center`)
					.$lastChild(`text-align: right`)

					, i == 0 && modals[i].dismissable && m('button.back', { onclick: () => back() }, 'Back')
					, i == 0 && m('.title', modals[i].title)
					, i == 0 && modals[i].onnext
					&& m('button.next', {
						onclick(){
							modals[i].onnext()
						}
					}, 'Next')
				)
				, m('.content'
					+ css`
						display: grid;
						overflow: auto;
						padding: 1em;
					`
					.desktop`
						padding: 0.1em;
					`
					, i == 0 && modals[i].view()
				)
			)
		, oncreate
		, onbeforeremove
		, onbeforeupdate
	}
}

export function Main(){

	function view(){
		return isOpen() && m('.modal-stack'
			+ css`
				--w: 100vw;
				--h: 100%;
				--vgap: calc( 100% - var(--h) );
				--hgap: calc( 100vw - var(--w) );
				position: fixed;
				bottom: 0px;
				left: 0px;
				width: 100vw;
				height: 100%;
				display: grid;
				grid-template-columns: var(--w);
				grid-template-rows: var(--h);
				justify-content: center;
				align-content: center;
				z-index: 2;
			`
			.$media('(min-width: 65em)', `
				--w: 60em;
				--h: 95vh;
			`)
			+( minimized ? '.minimized' : '' )
			+( isOpen() ? '.open' : '.closed')
			, { async onbeforeremove(v){

				let list = []
				let stack = [v]

				while (stack.length) {
					let next = stack.shift()
					if( next.state && next.state.onbeforeremove ) {
						list.unshift({
							v: next
							,context: next.state
						})
					} else if ( next.attrs && next.attrs.onbeforeremove ){
						list.unshift({
							v: next
							,context: next.attrs
						})
					}
					if( next.children ) {
						stack.push( ...next.children )
					}
				}

				// self
				list.pop()

				await Promise.all(list.map( x => x.context.onbeforeremove(x.v) ))
			}
			}
			, m('.modal-item'
				+ css`
					position: relative;
					border-radius: 0.5em;
				`
				, modals.map( (_, i, { length }) =>
					m(ModalItemView, { key: i+'-'+length , i, modals })

				)
				.reverse()
			)
		)
	}

	return { view }
}

export function Overlay(){

	let f = e => {
		if(e.target.classList.contains('modal-stack')){
			close()

		}
	}

	let g = e => {
		if( e.key == 'Escape' ) {
			close()
		}
	}

	function view(){
		return isOpen()
			&& m('.modal-stack-overlay'
				+ css`
					position: absolute;
					top: 0px;
					left: 0px;
					width: 100vw;
					height: 100%;
					z-index: 2;
					pointer-events: none;
					background-color: black;
					opacity: 0;
				`
				, {
					oncreate: v => {
						window.setTimeout(
							() => M.animate(
								v.dom, { opacity: 0.3 }, { delay: 0.1, duration: 1 }
							)
						)


						window.document.addEventListener('keyup', g)
						window.document.addEventListener('click', f)

					}
					,onbeforeremove: v =>
						M.animate(
							v.dom, { opacity: 0 }, { delay: 0.1, duration: 0.2 }
						)
						.finished

					,onremove: () => {
						window.document.removeEventListener('click', f)
						window.document.removeEventListener('keyup', g)
					}
				}
			)

	}

	return { view }
}

window.animate = M.animate
window.modalStack = {
	minimize, close, isOpen, add, back
}