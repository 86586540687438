
import * as R from 'ramda'

/**
 * A CSS value that obey's the Functor API
 *
 */
class Unit {


  constructor(unitType, value){
    this.__value = value
    this.__unitType = unitType
  }

  join() {
	  return this.__value
  }

  valueOf (){
    return this.__value+this.__unitType
  }

  toString (){
	  return this.valueOf()
  }

  map (f){
    return Unit.of(this.__unitType, f(this.__value))
  }

  ap (otherUnit){
	  return Unit.of(
		  this.__unitType
		  ,otherUnit.__value
	  )
	  .map(this.__value)
  }

  static of(unitType, value){
    return new Unit(unitType, value)
  }

  static type(unitType){
	  return function(value){
		  return Unit.of(unitType, value)
	  }
  }


}


class Func {
	constructor(name, argKeys, args){
		this.__name = name
		this.__argKeys = argKeys
		this.__args = args
	}

	get(key){
		return this.__args[this.__argKeys.indexOf(key)]
	}

	map (f){
		return Func.of(this.__name, this.__argKeys)( ...this.__args.map(f) )
	}

	ap (otherFunc){
		return Func.of(
			this.__name
			,this.__argKeys
		)
		(...otherFunc.__args)
		.map(this.__args[0])
	}

	static of (name, argKeys){
		return function(...args){
			return new Func(name, argKeys, args)
		}
	}

	valueOf (){
		return this.__name + '('+this.__args+')'
	}

	toString (){
		return this.valueOf()
	}
}


const func = Func.of

const unit = Unit.type

const css = {
	Unit
	,unit, func
	,sequence: R.unapply(R.join(' '))
	,textAlign: {
		center: 'center'
		,left: 'left'
	}
	,colors: {
		white: 'white'
		,black: 'black'
	}
	,position: {
		relative:'relative'
		,absolute:'absolute'
		,fixed:'fixed'
	}
	,display: {
		inline: 'inline'
	}
	,fontWeight: {
		lighter: 'lighter'
	}
	,cursor: {
		// default rendered as text select in FF
		default: 'pointer'
	}
	,overflow: {
		scroll: 'scroll'
		,hidden: 'hidden'
	}
	,units: {
		em: Unit.type('em')
		,percent: Unit.type('%')
		,px: Unit.type('px')
		,seconds: Unit.type('s')
		,vw: Unit.type('vw')
		,vh: Unit.type('vh')
		,degrees: Unit.type('deg')
	}
	,funcs: {
		rgb: func('rgb', ['red', 'green', 'blue'])
		,rgba: func('rgba', ['red', 'green', 'blue', 'alpha'])
		,translate: func('translate', ['x', 'y'])
		,translateX: func('translateX', ['x'])
		,translateY: func('translateY', ['y'])
		,translate3d: func('translate3d', ['x', 'y', 'z'])
		,boxShadow: func('boxShadow', [])
		,rotate: func('rotate', ['angle'])
		,scale: func('scale', ['x', 'y'])
		,scale3d: func('scale3d', ['x', 'y', 'z'])
		,hsl: func('hsl', ['hue', 'saturation', 'lightness'])
	}
	// dev
	,class: (name) => '.'+name
	// production
	// ,class: (name) => '.'+name+Math.random().toString(15).slice(2, 10)
}

export default css