/* globals window */
import m from 'bacta'
import * as R from 'ramda'
import css from 'bss'
const downArrow =
	m.trust(`
		<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down"><polyline points="6 9 12 15 18 9"></polyline></svg>
	`)
function Main({
	attrs: {
		open=m.prop(false)
	}
}){
	let rootNode;
	function onclick(e){
		try {
			if( rootNode ){
				if(
						rootNode != e.target
						&& !rootNode.contains(e.target)
				) {
					open(false)
					m.redraw()
				}
			}
		} catch(e) {
			// console.error(e)
		}
	}
	window.addEventListener('click', onclick)
	function view({
	attrs
	}){
	const {
		options
		, text
		, onselect=()=>{}
		, defaultOption
	} = attrs
		return m('.dropdown-button'
			+ css`
				display: grid;
				grid-template-columns: auto auto;
				background-color: rgba(0,0,0,0.1);
				justify-content: start;
				max-width: max-content;
				transition: opacity 0.2s;
				border-radius: 4px
				user-select: none;
				position: relative;
				transform: translateY(1px);
				z-index: 1;
			`
			.$hover(
				!open()
				? `opacity: 0.8;`
				: `
					opacity: 1;
					transition: opacity 0s;
				`
			)
			, {
				oncreate: ({ dom }) => rootNode = dom
			}
			, m('button'
					+ css`
						background-color: rgba(0,0,0,0);
						border: none;
						outline: none;
						padding: 6px 12px
						min-width: 6em;
						min-height: 2.4em;
					`
					,R.merge(
						attrs
						,{
							onclick:
								function(e){
										open(false)

										onselect(defaultOption || options[0])
										if( attrs.onclick ) {
											attrs.onclick(e)
										}
									}
								}
					)
					, text
				)
			, m('.down-arrow'
				+ css`
					transform: scale(0.7) translateY(6px) translateX(-10px);
					position: relative;
				`
				,
				{
					onclick: e => {

						const [left, right, align] =
							e.x / window.innerWidth > 0.5
							? ['unset', '0%', 'right']
							: ['0%', 'unset', 'left']

						rootNode.style.setProperty(
							'--left'
							, left
						)

						rootNode.style.setProperty(
							'--right'
							, right
						)

						rootNode.style.setProperty('--text-align', align)

						open(!open())
					}
					,disabled: !options.length || attrs.disabled
				}
				, m('.hitbox'
					+ css`
						padding: 6px 12px
						min-width: 4em;
						min-height: 2.4em;
						position: absolute;
					`
				)
				, downArrow
			)
			, m('.popup'
				+ ( open() ? '.open' : '')
				+ css`
					border: solid 0.5px rgba(0,0,0,0.5)
					border-radius: 0.2em;
					padding: 1em;
					position: absolute;
					top: 105%;
					left: var(--left, unset);
					right: var(--right, unset);
					width: 10em;
					display: grid;
					gap: 1em;
					font-size: 0.8em;
					transform: scaleY(0);
					transition: 0.1s;
					transform-origin: top left;
					width: max-content;
					text-align: var(--text-align);
				`
				.$nest('&.open', `
					transform: scaleY(1);
					background-color: white;
				`)
				.$nest('.item', `
					transform-origin: top left;
					background-color: white;
					font-size: 1.25em;
				`)
				.$nest('.item:hover', `
					opacity: 0.8;
				`)
				.$nest('.item:active', `
					transform: scale(1.05);
				`)
				, options.map(
						option =>
							m('.item'
								,
								{ onclick: () => {
									onselect(option)
									open(false)
								} }
								, option
							)
				)
			)
		)
	}
	return {
		view
		,onremove(){
			window.removeEventListener('click', onclick)
		}
	}
}
export default Main