import m from 'bacta'
import css from 'bss'
import * as elements from '../components/elements'
import {
	filterView
	,ListProcessing
} from '../components/filter'
import NumberInput from '../components/number'
import humanList from '../utils/human_list'
import * as regExes from '../utils/regExes'
import { Light as DetailsPane } from '../components/detailspane2'
import Pencil from '../components/pencil'
import {identical} from '../utils/harth-identical'
import * as R from 'ramda'
import Promise from 'bluebird'
import { prop } from '../../../stream'
import Permissions from '../models/permissions'
import scrollableTable from '../components/scrollableTable'
import moment from 'moment'
import HarthUppy from '../components/harth-uppy'
import * as uuid from 'uuid'
const exposeObject =
	R.pipe(
		R.toPairs
		,R.unnest
		,R.filter(
			(a) => typeof a != 'string'
		)
	)

css.setDebug(true)

import autocomplete from './autocomplete'

const uniqItem = (a, b) => b.map((prop) => a[prop]()).join()

const uniqPItem =[
	'presets_id'
	,'presets_name'
	,'supplier_items_id'
	,'distribution_amount'
	,'distribution_stocked'
]

const uniqTItem =[
	'distribution_id'
]

const uniqDItem =[
	'distribution_id'
	,'distribution_entity_id'
	,'supplier_items_id'
	,'distribution_container_name'
	,'distribution_amount'
	,'distribution_stocked'
	,'distribution_ordered'
]

/*
	Model, View, Intent

	Model: Any data storage.  Both the current state of the user interface, and
		data that will be persisted to a database layer.
		It also includes any functions for selecting or manipulating that data.

	Intent: Any functions that represent the user performing an action.  These functions represent
		what the user wants to do.  Whether it be creating a new invite, logging in.
		That's all representing the users intent.

	View: The view is a representation of the Model and provides affordances for the user
		to express their intent.  These affordances are input elements such as
		dropdowns, text inputs, checkboxes etc...

	Controller: The Controller binds the Model, View and Intent together.  It do some
		setup code and faciliate communication between the three other layers.



/*
	Intent: Functions that help the user do what they want to do...
*/
function Intent(model){


	const checkprop = (a, b, c) =>
		c[a] && c[a][b]
			? typeof c[a][b] == 'function'
				? c[a][b]()
				: c[a][b]
			: null

	const fetchResource = function(){
		return model.data.fetchResource({
				depth: 1
				,props: {
					resources: [
						'contractor_id'
						,'contractor_name'
						,'contractor_yard'
					]
				}
			})
			.then(model.resources)
	}

	function fetchOrganizationsDisciplines(){
		return model.data.fetchOrganizationsDisciplines({
			props: {
				organizations_disciplines: [
					'organizations_disciplines_name'
					,'organizations_disciplines_id'
					,'organizations_disciplines_description'
				]
			}
		})
		.then(model.organizations_disciplines)
	}

	const fetchProjects = function(){
		if(  model.data.schedule_id() ){
			return model.data.fetchProjectsBySchedule({
				schedule_id: model.data.schedule_id()
				,completedProjects: model.completedProjects()
				,depth: 2
				, props: {
					projects: [
						'project_id'
						,'project_name'
						,'project_material_suburb'
						,'project_commissioning_date'
						,'project_final_acceptance'
						,'project_completion_marker'
					]
					,disciplines: [
						'discipline_id'
						,'discipline_name'
						,'discipline_work'
						,'project_id'
					]
				}
			})
				.then(model.projects)
				.then(model.statProjectMaterials)
				.then(function(){
					return model.data.fetchProjectsBySchedule({
						schedule_id: model.data.schedule_id()
						,completedProjects:
							model.completedProjects() == 'inprogress'
							? 'completed'
							: 'inprogress'
						,depth: 1
						,props: {
							projects: [
								'project_name'
								,'project_id'
							]
						}
					})
					.then( 	model.inverseProjectsList )
				})

		}
		return Promise.resolve([])
	}


	function fetchWarehouses(params){
		return model.data.fetchWarehouses(params)
			.then(function(res){
				return prepWarehouse(res)
			})
	}

	const fetchContainers = function(){
		return model.data.fetchContainers()
			.then(model.containers)
			.then(function(){
				model.containerIndex(
					R.indexBy(
						(a) => a.container_name()
						,model.containers()
					)
				)
				model.materials().forEach((material) => {
					const name = material.distribution_container_name()

					const containerDesignation =
						checkprop(name, 'container_id', model.containerIndex())

					containerDesignation
					!= material.distribution_container_id()
						? material.created = true
						: null

					material.distribution_container_id( containerDesignation )

					if (model.containerIndex()[name]){
						model.containerIndex()[name].holdings =
							Number(
								Number(checkprop(name, 'holdings', model.containerIndex()))
								+ Number(material.distribution_amount())
							)

						checkprop(name, 'capacity', model.containerIndex())
							? null
							: Number(
								model.supplierItemIndex()[material.supplier_items_id()]
								? model.supplierItemIndex()[material.supplier_items_id()].supplier_items_batch()
								: 0
							)

						model.containerIndex()[name].containerIsFull =
							Number(checkprop(name, 'holdings', model.containerIndex())) < Number(checkprop(name, 'capacity', model.containerIndex()))
							|| Number(checkprop(name, 'capacity', model.containerIndex())) == 0
								? false
								: true

					}
				})
				model.statProjectMaterials()
				return model.containerIndex()
			})
	}

	function prepSupplier(suppliersList){

		const comparisonProp =
			model.vm().supplier_id
			&& model.vm().supplier_id()
			? 'supplier_id'
			: 'supplier_name'


		model.suppliers(
			R.uniqBy(
				(c) => c.supplier_id()
				,R.sort(
					R.descend(R.prop('__requestedAt'))
					,[].concat(
						suppliersList
						,model.suppliers()
					)
				)
			)
		)

		const newSupplier =
			model.vm().supplier_id
				? model.suppliers().find((p) =>
					R.prop(comparisonProp, p)()
					== R.prop(comparisonProp,  model.vm())()
				)
				: null


		model.make_items()

		if(
			newSupplier
			&& (
				!model.vm()['__requestedAt']
				|| newSupplier['__requestedAt']
				>=  model.vm()['__requestedAt']
			)
		){
			model.cloneSupplier( newSupplier )
			model.masterILIST(true)
			model.masterLLIST(true)
			model.edit(true)
		}
	}

	function fetchSuppliers(params){
		return model.data.fetchSuppliers(params)
			.then(function(supplierList) {
				model.suppliers(supplierList)
				return prepSupplier(supplierList)
			})
	}

	function fetchPresets(){
        return model.data.fetchPresets()
			.then(function(res){
				model.presets(model.copy_items(res, false))
				model.originalPresets(res)
				model.presetsIndexByDiscipline(
					R.groupBy(
						(a) => a.distribution_entity_name()
						,res
					)
				)
				model.selected_preset(model.selected_preset())
				model.DeletePItems([])
				model.d_item_vm({})
				return Promise.resolve([])
			})
	}

	const materialPrep = function(res){
		model.materials(model.copy_items(res, false))
		model.originalMaterials(res)
		model.MaterialIndex(
			R.indexBy(
				(a) => a.distribution_id()
				,res
			)
		)
		model.originalMaterialIndex(
			R.indexBy(
				(a) => a.distribution_id()
				,model.originalMaterials()
			)
		)
		return Promise.resolve([])
	}

	const fetchMaterials = function(){
		return model.data.fetchMaterials({
			completedProjects: model.completedProjects()
			,projects: []
			,warehouse_id: null
		})
			.then( materialPrep )
			.then( m.redraw )
	}



	const fetchOrders = function(){
		return model.data.fetchOrders({
			completedProjects: model.completedProjects()
			,project_id: null
			,warehouse_id: null
		})
			.then(model.originalOrders)
			.then(m.redraw)
	}


	const sendSupplier = function(){
		const supplierlist = [model.vm()]
		model.location_vm({})
		model.item_vm({})
		return Promise.all([
			deleteLocation(true)
			,deleteItem(true)
		])
		.then(function(){
			model.DeleteSLocations([])
			model.DeleteSItems([])
			return model.api.suppliers.patch(supplierlist)
			.then(function(suppliersList){
				prepSupplier(suppliersList)
				model.consolidate()
				return true
			})
		})
    }

	function prepWarehouse(warehouseList){

		const comparisonProp =
			model.warehouse_vm().warehouse_id
			&& model.warehouse_vm().warehouse_id()
			? 'warehouse_id'
			: 'warehouse_name'

		model.warehouses(
			R.uniqBy(
				(c) => c.warehouse_id()
				,R.sort(
					R.descend(R.prop('__requestedAt'))
					,[].concat(
						warehouseList
						,model.warehouses()
					)
				)
			)
		)

		const newWarhouse =
			model.warehouse_vm().warehouse_id
				? model.warehouses().find((w) =>
					R.prop(comparisonProp, w)()
					== R.prop(comparisonProp,  model.warehouse_vm())()
				)
				: null

		// model.data.routeids(model.warehouses().map(R.prop('contractrecognitionid')))

		if (
			newWarhouse
			&& (
				!model.warehouse_vm()['__requestedAt']
				|| newWarhouse['__requestedAt']
				>=  model.warehouse_vm()['__requestedAt']
			)
		){
			model.warehouse_vm(newWarhouse)
			model.cloneWarehouse(newWarhouse)
		}
	}


    function sendWarehouse(){
		model.warehouse_vm().warehouse_items = []
        const warehouselist = [model.warehouse_vm()]
		model.data.currentRoute('Nothing')
		return model.api.warehouses.patch(warehouselist)
			.then(function(warehouseList){
				sendAllDistriution()
				.then(function(){
					prepWarehouse(warehouseList)
					return model.data.currentRoute('materials')
				})
			})
    }

    let sendPreset = function(){
		return deletePreset(true)
		 .then(function(){
			return model.api.presets.patch.many(
				model.presets().filter(c => c.created )
			)
				.then(fetchPresets)
				.then(() => model.preset_vm(null))
		 })
    }

	let transferItems = function({orders, operation}){

		const newmaterials =
		(
			operation == 'splitorders'
			? orders.map((anorder) => {
				const o = anorder.order
				// find item to send through mitosis
				const m =
					model.materials().find((mat) =>
						mat.distribution_id()
						== o.distribution_id()
					)
				m.distribution_amount(o.order_amount()-o.order_received())
				m.created = true
				// add props to materials so they can be transferred to the new order
				m.orderdetail = {
					order_notes: o.order_notes() + ' split from '  + o.order_name()
					,order_received: o.order_received()
					,order_stock_location: o.order_stock_location()
					,order_submission_date: o.order_submission_date()
					,order_eta: o.order_eta()
					,listindex: anorder.listindex
				}
				o.order_amount(o.order_received())
				o.created = true
				return m
			})
			: model.transfer_vm()
		)
		.map((item) => {
			const item_mitosis = model.resetNewMaterial()

			delete item.attrs
			let base_material = model.originalMaterials().find(
					(material) =>
						material.distribution_id()
						== item.distribution_id()
			)
			let edit_material = model.materials().find(
					(material) =>
						material.distribution_id()
						== item.distribution_id()
			)
			if (item.distribution_amount() == base_material.distribution_amount() ){
				edit_material.created = true
				edit_material.distribution_entity_id(model.transfer_object.to().transfer_id)
				edit_material.distribution_entity_name(model.transfer_object.to().transfer_name)
				edit_material.distribution_stocked(model.transfer_object.to().tranfer_location)
			}
			else {
				const keepqty = item.distribution_amount()
				const tranferqty = base_material.distribution_amount() - item.distribution_amount()
				item_mitosis.distribution_container_name(
					item.distribution_ordered()
					&& item.distribution_ordered() != 'Cancelled'
						? item.distribution_container_name()
						: null
				)
				item_mitosis.distribution_container_id(
					item.distribution_ordered()
					&& item.distribution_ordered() != 'Cancelled'
						? item.distribution_container_id()
						: null
				),
				item_mitosis.distribution_entity_id(
					orders
					? item.distribution_entity_id()
					: model.transfer_object.to().transfer_id
				)
				item_mitosis.distribution_entity_name(
					orders
					? item.distribution_entity_name()
					: model.transfer_object.to().transfer_name
				)
				item_mitosis.distribution_stocked(
					orders
					?item.distribution_stocked()
					: model.transfer_object.to().tranfer_location
				)
				item_mitosis.distribution_id( uuid.v4() )
				item_mitosis.distribution_amount( keepqty )
				item_mitosis.distribution_batch( item.distribution_batch() )
				item_mitosis.distribution_silo( item.distribution_silo() )
				item_mitosis.distribution_ordered( item.distribution_ordered() )

				if(
					operation == 'transferorders'
					&& item.distribution_ordered()
					&& item.distribution_ordered() != 'Cancelled'
				){

					const originalOrder = model.orders().find((o) => o.distribution_id() == item.distribution_id())
					originalOrder.created = true
					originalOrder.order_amount(tranferqty)
					originalOrder.order_received(tranferqty)

					model.orders().push(
						R.merge(
							originalOrder
							,{
								order_id: m.prop(null)
								,order_amount: m.prop(keepqty)
								,order_received: m.prop(keepqty)
								,created: true
								,distribution_id: m.prop(item_mitosis.distribution_id())
							}
						)
					)

				}

				item_mitosis.supplier_items_id( item.supplier_items_id() )
				item_mitosis.distribution_material_name( item.distribution_material_name() )
				item_mitosis.distribution_material_description( item.distribution_material_description() )
				item_mitosis.supplier_id( item.supplier_id() )
				item_mitosis.created = true
				item_mitosis.orderdetail = item.orderdetail
				edit_material.distribution_amount(tranferqty)
				edit_material.created = true
				model.materials().push(item_mitosis)
			}
			model.MaterialIndex()[item_mitosis.distribution_id()] = item_mitosis
			return item_mitosis
		})

		model.consolidate()
		model.transfer_object.from(model.transfer_object.from())
		model.transfer_object.to(model.transfer_object.to())

		// pump result through makeorders
		operation == 'splitorders' ? makeOrders(newmaterials) : null

		return operation == 'transferorders'
		|| operation == 'splitorders' ? sendAllDistriution() : true
	}


	const sendOrders = function(){
		const updateList = []

		model.order_vm.order().forEach((oitem) => {
			// oitem.order_eta(null)
			oitem.created = true
			const updateItem =
				model.materials()
				.find((m) =>
					m.distribution_id()
					== oitem.distribution_id()
				)

			updateItem.created = true
			updateItem.distribution_ordered('Cancelled')
			updateList.push(updateItem)
		})

		model.order_vm.orderUpdates()
			.forEach((u) => {
				const updateItem =
					model.materials()
					.find((m) =>
						m.distribution_id()
						== u.distribution_id()
					)
				if(updateItem){
					updateItem.distribution_amount(u.order_amount())
					updateItem.distribution_stocked(u.order_stock_location())
					updateItem.created = true
					updateList.push(updateItem)
				}
			})


		model.orders().forEach((o) => {
			o.order_submission_date(
				o.order_submission_date() <= 0
					? 0
					: new Date(o.order_submission_date()).getTime()
			)
			o.order_eta(
				!o.order_eta()
					? o.order_eta()
					: new Date(o.order_eta()).getTime()
			)
		})

		model.consolidate()

		return sendAllDistriution()
			.then(function(){
				model.order_vm['order']([])
				model.order_vm['orderUpdates']([])
				return true
            })
	}

	let makeOrders = function(newmaterials){
		const reflist = newmaterials || model.order_vm.order()
		let supplier_list = R.pipe(R.map( R.invoker(0, 'supplier_id')),R.uniq)(reflist)
		supplier_list.forEach((supplier) => {
			let supplierObj = R.find(function(chr) { return chr.supplier_name() == supplier}, model.suppliers())
			let filter_list = reflist.filter((o) => o.supplier_id() == supplier)

			let suOArray = supplierObj.supplier_name().split(' ').map((word) => word.replace(regExes.uniqPattern, '').slice(0,1).charAt(0).toUpperCase()).join('')
			let deOArray = filter_list[0].distribution_entity_name().split(' ').map((word) => word.replace(regExes.uniqPattern, '').slice(0,1).charAt(0).toUpperCase()).join('')

			let pot_order_No = suOArray + '-' + deOArray
			let incriment =
				R.uniq(
					model.orders().filter((o) =>
						o.order_name().slice(0, o.order_name().lastIndexOf('-')) == pot_order_No
						&& o.order_id()
					)
				).length + 1

			let order_No = pot_order_No + '-' + incriment
			filter_list.forEach((item) => {
				item.distribution_ordered(order_No)
				item.created = true
				let supplier_itemObj = R.find(
					function(chr) { return chr.supplier_items_id() == item.supplier_items_id()},
					supplierObj.supplier_items
				)
				let OrderItem = {
					created: true
					,order_id: m.prop()
					,organization_id: m.prop()
					,distribution_id: m.prop(item.distribution_id())
					,order_name: m.prop(order_No)
					,supplier_items_id: m.prop(item.supplier_items_id())
					,order_items_name: m.prop(item.distribution_material_name())
					,order_items_description: m.prop(item.distribution_material_description())
					,order_amount: m.prop(item.distribution_amount())
					,order_received: m.prop(0)
					,order_notes: m.prop(item.orderdetail ? item.orderdetail.order_notes : '')
					,order_items_cost: m.prop(
						item.distribution_silo() || !model.containerIndex()[item.distribution_container_name()].containerIsFull
						? supplier_itemObj.supplier_items_cost() * item.distribution_amount()
						: (
							supplier_itemObj.supplier_items_batch_cost()
							|| supplier_itemObj.supplier_items_cost()
						 ) * item.distribution_amount()
					)
					,supplier_id: m.prop(supplierObj.supplier_id())
					,order_supplier_name: m.prop(supplierObj.supplier_name())
					,order_stock_location: m.prop( item.orderdetail ? item.orderdetail.order_stock_location : item.distribution_stocked())
					,order_submission_date : m.prop( item.orderdetail ? item.orderdetail.order_submission_date : Date.parse( new Date() ) )
					,order_eta: m.prop( item.orderdetail ? item.orderdetail.order_eta : null)
				}
				item.orderdetail ? model.orders(R.insert(item.orderdetail.listindex, OrderItem, model.orders())) : model.orders().push(OrderItem)
			})
			// Send Order to supplier by email? Print Order to PDF?
		})
		model.order_vm.order([])
    }

    const sendContainer = function(){
		return model.api.containers.patch.many(model.containers())
    }

	const sendAllDistriution = function(){
		model.savingProp(true)
		model.assessContainers()
		const updateList = model.materials().filter((c) => c.created )
		const orderList = model.orders().filter((c) => c.created )
		return Promise.all([
			deleteDitem(true)
			,deleteContainer()
			,sendContainer()
		])
		.then(function(){
			return model.api.materials.patch.many(updateList)
			.then(function(){
				return orderList.length && model.editOrdersPermissions()
				? model.api.orders.patch.many(orderList)
				: R.T()
			})
				.then(function(){
					return Promise.all([
						fetchMaterials()
						,fetchOrders()
					])
					.then( fetchContainers )
					.then(function(){
						model.DeleteDItems([])
						model.d_item_vm({})
						model.transfer_object.to(model.transfer_object.to())
						model.available_yards()
						model.selected_pt(model.selected_pt())
						model.SelectedProject(model.SelectedProject())
						model.masterILIST(true)
						model.edit(true)
						model.savingProp(false)
						return true
					})
				})
		})
	}

	let deleteOrder = function(order){
        let orderid = order.order_id()
        return Promise.resolve(
            orderid && model.api.orders.remove.one(orderid)
		)
			.then(fetchOrders)
    }

    let deleteSupplier = function(){
        let supplier_id = model.vm().supplier_id()
        return Promise.resolve(
            supplier_id && model.api.suppliers.remove.one(supplier_id)
        )
            .then(model.deleteSupplier)
    }

    let deleteWarehouse = function(){
		const warehouse_id = model.warehouse_vm().warehouse_id()
        return (
			warehouse_id
			? model.api.warehouses.remove.one(warehouse_id)
			: Promise.resolve([])
		)
		.then(function(){
			model.deleteWarehouse()
			model.warehouse_vm({})
			return true
		})
    }

    var deleteLocation = function(makeAPICalls){
		if (!makeAPICalls){
			let lindex = model.vm().supplier_locations
				.findIndex((li) =>
					li.supplier_locations_location()
					== model.location_vm().supplier_locations_location()
				)
			model.DeleteSLocations().push(model.location_vm())
			model.vm().supplier_locations.splice(lindex, 1)
			model.location_vm({})
		}
		return makeAPICalls
			? 				Promise.all(

					model.DeleteSLocations().filter((p) => p.supplier_locations_id() )
						.map((slocation) =>
							model.api.supplier_locations.remove.one(slocation.supplier_locations_id())
					)
				)

			: Promise.resolve(null)

			.then(function(){
				model.masterLLIST(true)
				m.redraw()
			})
    }


    var deleteItem = function(makeAPICalls){
		if (!makeAPICalls){
			let sindex = model.vm().supplier_items
				.findIndex((si) =>
					si.supplier_items_name()
					== model.item_vm().supplier_items_name()
				)
			model.DeleteSItems().push(model.item_vm())
			model.vm().supplier_items.splice(sindex, 1)
			model.item_vm({})
		}
		return makeAPICalls
			? 				Promise.all(
					model.DeleteSItems().filter((p) => p.supplier_items_id())
						.map((sitem) =>
							model.api.supplier_items.remove.one(sitem.supplier_items_id())
					)
				)

			: Promise.resolve(null)

			.then(function(){
				model.masterILIST(true)
			})
    }


	function deleteDitem(makeAPICalls, /*iIndex*/){
		if ( Object.keys(model.d_item_vm()).length ){

			exposeObject(model.d_item_vm())
			.forEach((d) => {
				const orderIndex =
					model.order_vm.order()
				.findIndex((o) =>
						o.distribution_id() == d.distribution_id()
				)

				orderIndex > -1
				? model.order_vm.order().splice(orderIndex ,1)
				: null

				model.DeleteDItems().push(d)

				model.materials().splice(
					model.materials()
					.findIndex((pA) =>
						pA.key
						== d.key
					)
					, 1
				)
			})

			delete model.errors()['Float']
			delete model.errors()['Units']
			model.consolidate()
			model.d_item_vm({})
			model.masterILIST(true)
		}
		return makeAPICalls
			? Promise.all(
				model.DeleteDItems().filter((di) => di.distribution_id())
				.map((d) => model.api.materials.remove.one(d.distribution_id()))
			)
				// .then(() => {
				// 	model.DeleteDItems().forEach((d) =>
				// 		model.originalMaterials().splice(
				// 			model.originalMaterials()
				// 			.findIndex((pA) =>
				// 				pA.key
				// 				== d.key
				// 			)
				// 			, 1
				// 		)
				// 	)
				// 	return model.warehouse_vm(model.warehouse_vm())
				// })
			: Promise.resolve(null)
	}

	function deletePreset(makeAPICalls, name){
		if ( !makeAPICalls ) {

			const presetlist = !name
				? exposeObject(model.d_item_vm())
				: model.presets().filter((p) =>
					p.presets_name() == name
				)

			presetlist.forEach((p) => {
				model.DeletePItems().push(p)
				delete model.errors()[p.distribution_material_name() + ', Units']
				model.presets().splice(
					model.presets()
					.findIndex((pA) =>
						pA.key
						== p.key
					)
					, 1
				)
			})


			model.refreshList(true)
			model.d_item_vm({})
		}
		return makeAPICalls
			? Promise.all(
				model.DeletePItems().filter((p) => p.presets_id())
					.map((preset) =>
						model.api.presets.remove.one(preset.presets_id())
				)
			)
			: Promise.resolve(null)
	}

	function deleteContainer(){
		return Promise.all(
				model.container_vm().selected.container.map((c) =>
					model.api.containers.remove.one(c.container_id())
				)
			)
			.then(function(){
				model.container_vm().selected.container = []
			})
	}

	function clonePreset(){
		deletePreset(false, model.selected_preset())
		const newEntityID =
			model.organizations_disciplines()
			.find(
				R.propEq(
					'orgDisciplineNameDescription'
					,model.selected_preset()
				)
			)
			.organizations_disciplines_id

		model.presets().filter((p) =>
			p.presets_name()
			== model.preset_vm()
		)
		.forEach((item) => {
			let new_preset = model.makePreset(item)
			new_preset.presets_id(null)
			new_preset.presets_name( model.selected_preset() )
			new_preset.distribution_entity_name( model.selected_preset() )
			new_preset.distribution_entity_id( newEntityID )
			model.presets().push(new_preset)
		})
		model.selected_preset(model.selected_preset())
    }


	function applyPreset(project){
		const ditems = []
		project.disciplines.forEach((discipline) => {
			discipline
			.outstandingMaterials
			.forEach((om) => {
				let preset =
					model.presetsIndexByDiscipline()
					[discipline.discplineNameDescription]
					.find((p) =>
						p.supplier_items_id()
						== om.supplier_items_id
					)

				let damount = om.quantity
				let ditem = model.resetNewMaterial()
				ditem.organization_id(preset.organization_id()),
				ditem.distribution_entity_name(preset.distribution_entity_name()),
				ditem.distribution_entity_id(discipline.discipline_id()),
				ditem.supplier_id(preset.supplier_id()),
				ditem.supplier_items_id(preset.supplier_items_id()),
				ditem.distribution_material_name(preset.distribution_material_name()),
				ditem.distribution_material_description(preset.distribution_material_description()),
				ditem.distribution_amount(damount),
				ditem.distribution_batch(preset.distribution_batch()),
				ditem.distribution_container_name(null),
				ditem.distribution_container_id(null),
				ditem.distribution_silo(preset.distribution_silo()),
				ditem.distribution_ordered(''),
				ditem.distribution_stocked(project.project_name() + " - " + project.project_material_suburb()),
				ditem.distribution_float(preset.distribution_float())
				ditems.push(ditem)
			})
		})

		if( ditems.length ){
			model.materials( [].concat(ditems, model.materials()) )
			model.consolidate()
			model.selected_pt(model.selected_pt())
			model.masterILIST(true)
		}
		return Promise.resolve([])
	}

    function SupplierLocationsButton(location, returnSTATE){
        const name = model.location_vm().supplier_locations_location
        const activated =
			name
			&& name() == location.supplier_locations_location()
			&& model.editSupplierPermissions()
        return returnSTATE
			? activated
			: activated
			? m('button.btn', {
				onclick: () => {model.location_vm({})}
			}, 'Done')
			: m('button.btn', {
				onclick: () => {
					location.created = true
					model.location_vm(location)
				}
			}, 'Edit')
    }

    function SupplierItemsButton(item){
        const name = model.item_vm().supplier_items_name
        const activated =
			name
			&& name() == item.supplier_items_name()
			&& model.editSupplierPermissions()
        return {
			onclick: (v) => {
				if(!v.currentTarget.checked){
					model.item_vm({})
				}
				else {
					item.created = true
					model.item_vm(item)
				}
			}
			,type: 'checkbox'
			,checked: activated || false
			,disabled: !model.editSupplierPermissions()
		}
    }



    function PresetItemsButton(item){
        const activated =
			model.d_item_vm()[item.key]
			&& model.editMaterialsPermissions()

        return activated
			?  m('button.btn.btn', {
				onclick: () => { delete model.d_item_vm()[item.key] }
				,disabled: !!model.errorsMessage().length
				,title: 'Confirm Changes'
			}, 'Done' )
				: m('button.btn', {
				disabled: !!model.new_material().distribution_id
				,onclick: () => {
						item.created = true
					model.d_item_vm()[item.key] = item
					}
				}, 'Edit')
    }

    function DistributionItemsButton(item){
        const activated =
			model.d_item_vm()[item.key]
			&& model.editMaterialsPermissions()

        return {
			onclick: (v) => {
				if(!v.currentTarget.checked){
					delete model.d_item_vm()[item.key]
					model.consolidate()
				} else {
					item.created = true
					model.d_item_vm()[item.key] = item
				}
			}
			,type: 'checkbox'
			,disabled: model.errorsMessage().length > 0
				|| !!model.new_material().distribution_id
			,title: 'Edit'
			,checked: activated || false
		}
    }


    function WarehouseItemsButton(item){

        const activated =
			model.d_item_vm()[item.key]
			&& model.editWarehousePermissions()

        return {
			onclick: (v) => {
				if(!v.currentTarget.checked){
					delete model.d_item_vm()[item.key]
					model.consolidate()
				}
				else {
					item.created = true
					model.d_item_vm()[item.key] = item
				}
			}
			,type: 'checkbox'
			,title: 'Edit'
			,disabled: !!model.errorsMessage().length || !!model.new_material().distribution_id
			,checked: activated || false
		}
	}

	model.data.currentRoute('Nothing')
	prop.merge(
		[
			model.data.initiateContractFetch
		]
	)
		.map(function([i]){

			// const s = i.schedule_id
			const schange = i.schange || !model.data.routeInitialized()['materials']
			const ochange = i.ochange || !model.data.routeInitialized()['materials']

			if ( ochange ){
				model.viewtype(MainList)
				model.inverseProjectsList([])
				model.checkMultipleProjects([])
				model.refreshList(false)
				model.SelectedProject({})
				model.SelectedDiscipline({})
				model.selected_pt({})
				model.selected_preset(null)
				model.vm({})
				model.preset_vm(null)
				model.project_vm({})
				model.warehouse_vm({})
				model.new_material({})
				model.d_item_vm({})
				model.item_vm({})
				model.location_vm({})
				model.transfer_object.from(null)
				model.transfer_object.to(null)
				model.suppliers([])
				model.warehouses([])
				model.DeleteDItems([])
				model.DeletePItems([])
				model.DeleteSItems([])
				model.DeleteSLocations([])
				model.deleteWStateProp(false)
				model.deleteSStateProp(false)
			}

			model.loading(true)
			model.metaDataLoading(true)
			return Promise.all([
				!ochange ? [] : fetchOrganizationsDisciplines()
				,!ochange ? [] : fetchWarehouses({})
				,!ochange ? [] : fetchMaterials()
				,!ochange ? [] : fetchResource()
				,!schange ? [] : fetchProjects()
				,!ochange ? [] : fetchPresets()
				,!ochange ? [] : fetchOrders()
				,!ochange ? [] : fetchSuppliers({ depth: 1 })
			])
				.then(function(){
					i.schange = false
					i.ochange = false
					model.metaDataLoading(true)
					model.statProjectMaterials()
					model.loading(false)
					model.data.currentRoute('materials')
					return fetchSuppliers({})
					.then( fetchContainers )
					.then( function(){
						model.metaDataLoading(false)
						model.available_yards()
						return true
					})
				})
		})

	return {
		fetchContainers
		, fetchSuppliers
		, fetchProjects
		, fetchResource
		, fetchWarehouses
		, fetchPresets
		, fetchMaterials
		, fetchOrders
		, deleteSupplier
		, deleteLocation
		, deleteItem
		, deleteDitem
		, sendSupplier
		, deleteWarehouse
		, sendWarehouse
		, sendContainer
		, applyPreset
		, sendOrders
		, transferItems
		, sendPreset
		, deletePreset
		, deleteContainer
		, SupplierLocationsButton
		, SupplierItemsButton
		, clonePreset
		, sendAllDistriution
		, PresetItemsButton
		, DistributionItemsButton
		, WarehouseItemsButton
		, makeOrders
		, deleteOrder
	}
}

/*
	Model: Data storage and functions that interact with that data.

	The vm represents the view model, any thing to do with the state of the UI
	that doesn't map to a database record is stored on the view model.

	It could be whether or not a button is disabled due to a background process.
	Or it could be storing how many items are selected in order to render a tally.

*/
function Model(data, activeMTab, activeDTab, activeWTab){

	const metaDataLoading = data.scoped(true)
	const loading = data.scoped(true)
	const resources = m.prop([])
	const materials = data.scoped([])
	const suppliers = m.prop([])
	const projects = data.scoped([])
	const warehouses = data.scoped([])
	const presets = m.prop([])
	const containers = m.prop([])
	const projects_tasks = m.prop([])
	const originalOrders = data.scoped([])
	const items = data.scoped([])
	const users = m.prop([])
	const roles = m.prop([])
	const containerIndex = data.scoped([])
	const supplierItemIndex = m.prop([])
	const originalMaterialIndex = m.prop({})
	const uniqcontIndex = m.prop([])
	const MaterialIndex  = data.scoped([])
	const errors = data.scoped([])
	const errorsMessage = errors.map((e) =>
		Object.keys(e)
		.map((a) =>
			e[a]
				? a + ' ' + e[a]
				: ''
		)
		.filter((a) => a)
	)
	const completedProjects = data.scoped('inprogress')
	const originalPresets = m.prop([])
	const originalMaterials = data.scoped([])
	const presetsIndexByDiscipline = data.scoped({})
	const projectIndex =
		projects.map(R.indexBy(R.pipe(R.prop('project_id'), R.call)))
	const projectPresetsIndexByDiscipline =
		prop.merge(
			[
				presetsIndexByDiscipline
				,projects
				,materials
			]
		)
		.map(([i, p, /*m*/]) => {
			return p
			? R.unnest(p.map((project) => project.disciplines || []))
			.forEach((d) => {
				const pmaterials = i[d.discplineNameDescription] || []
				const dmaterials = materials().filter((m) => m.distribution_entity_id() == d.discipline_id() )

				d.outstandingMaterials =
					pmaterials.map((preset) => {
						const alreadySetArray =
							R.pipe(
								R.pluck('distribution_amount')
								,R.map(R.call)
							)(dmaterials.filter((m) => m.supplier_items_id() == preset.supplier_items_id()))

						const alreadySet = alreadySetArray.reduce( (prev, curr) => prev + curr, 0 )
						const entitledAmount = preset.distribution_amount() * d.discipline_work()

						return {
							supplier_items_id: preset.supplier_items_id()
							,quantity: alreadySet < entitledAmount
							? entitledAmount - alreadySet
							: 0
						}
					})
					.filter((a) => a.quantity)
			})
			: []
		})

	// fetch suppliers to discard/reset
	// push into stream from original container to discard/reset

	const DeleteDItems = m.prop([])
	const DeletePItems = m.prop([])
	const DeleteSItems = m.prop([])
	const DeleteSLocations = m.prop([])

	const organizations_disciplines = data.scoped([])
	const availableYardSelections = data.scoped([])
	const deleteWStateProp = m.prop(false)
	const deleteSStateProp = m.prop(false)
	const editWarehousePermissions = m.prop(false)
	const editSupplierPermissions = m.prop(false)
	const editOrdersPermissions = m.prop(false)
	const editMaterialsPermissions = m.prop(false)
	const inverseProjectsList = data.scoped([])
	const checkMultipleProjects = data.scoped([])
	const refreshList = data.scoped(false)
	const SelectedProject = data.scoped({})
	const SelectedDiscipline = data.scoped({})
	const selected_pt = data.scoped({})
	const selected_preset = data.scoped(null)
	const vm = data.scoped({})
	const preset_vm = data.scoped(null)
	const project_vm = data.scoped({})
	const warehouse_vm = data.scoped({})
	const transfer_object = {
		from: data.scoped(null)
		,to: data.scoped(null)
	}
	const new_material = data.scoped({})
	const d_item_vm = data.scoped({})
	const item_vm = m.prop({})
	const location_vm = m.prop({})
	const savingProp = m.prop(false)

	const SelectedProjectDisciplines =
		SelectedProject.map((p) => {

			const projectOption =
				p.project_name
				? R.merge(
					p
					,{discplineNameDescription: p.project_name()}
				)
				: []

			if( projectOption ){
				SelectedDiscipline(projectOption)
				selected_pt(projectOption)
			}

			return p.disciplines
				? [].concat(
					p.disciplines
					,projectOption
				)
				: []
		})

	function copiedOrders(oList){
		return oList.map((item) => ({
				created: false
				,filecount: Number(item.filecount)
				,order_id: m.prop(item.order_id())
				,organization_id: m.prop(item.organization_id())
				,distribution_id: m.prop(item.distribution_id())
				,supplier_id: m.prop(item.supplier_id())
				,supplier_items_id: m.prop(item.supplier_items_id())
				,order_name: m.prop(item.order_name())
				,order_items_name: m.prop(item.order_items_name())
				,order_items_description: m.prop(item.order_items_description())
				,order_amount: m.prop(item.order_amount())
				,order_received: m.prop(item.order_received())
				,order_notes: m.prop(item.order_notes())
				,order_items_cost: m.prop( item.order_items_cost() )
				,order_supplier_name: m.prop(item.order_supplier_name())
				,order_stock_location: m.prop(item.order_stock_location())
				,order_submission_date : m.prop( item.order_submission_date() )
				,order_eta: m.prop( item.order_eta() )
				,key: Math.random().toString(15).slice(2, 8)
			})
		)
	}

	const orders = originalOrders.map((oList) => copiedOrders(oList) )
	const cancelledOrders =
		prop.merge(
			[
				orders
				,MaterialIndex
			]
		)
		.map(([oList, ]) =>
			oList.filter((o) =>
				MaterialIndex()[o.distribution_id()]
				&& MaterialIndex()[o.distribution_id()].distribution_ordered() == 'Cancelled'
				&& (MaterialIndex()[o.distribution_id()].project_id || MaterialIndex()[o.distribution_id()].warehouse_id)
			)
		)

	const orderedOrders =
		prop.merge(
			[
				orders
				,MaterialIndex
			]
		)
		.map(([oList, ]) =>
			oList.filter((o) =>
				MaterialIndex()[o.distribution_id()]
				&& MaterialIndex()[o.distribution_id()].distribution_ordered() != 'Cancelled'
				&& (MaterialIndex()[o.distribution_id()].project_id || MaterialIndex()[o.distribution_id()].warehouse_id)
			)
		)

	const lostOrders =
		prop.merge(
			[
				orders
				,MaterialIndex
			]
		)
		.map(([oList, ]) =>
			oList.filter((o) =>
				MaterialIndex()[o.distribution_id()]
				&& !MaterialIndex()[o.distribution_id()].project_id
				&& !MaterialIndex()[o.distribution_id()].warehouse_id
			)
		)

	const presetOptions =
		organizations_disciplines.map((/*p*/) => {
			return R.pluck(
				'orgDisciplineNameDescription'
				,organizations_disciplines()
			)
		})

	const presetCloneOptions =
		selected_preset.map((p) => {
			new_material({})
			return R.filter(
				(c) => c != p
				,presetOptions()
				.filter((od) =>
					presets().some((p) =>
						p.presets_name() == od
					)
				)
			)
		})

	const order_vm = {
		order: data.scoped([])
		,orderUpdates: data.scoped([])
	}

	const transfer_vm = transfer_object.from.map(() => [])

	const container_vm = m.prop({
		selected: {
			container: []
		}
	})

	const missingPermissions =
		data.initiateContractFetch.map(() =>
			humanList(
				[
					'Material'
					,'Order'
				]
					.map((p)=>
						data['read'+p+'Permissions']()
							? ''
							: p
					)
					.filter((p) => p)
			)
		)


	let deleteSupplier = function(){
		let index = R.findIndex(
            function(chr) { return chr.supplier_id() == vm().supplier_id()},
            suppliers()
        )
		suppliers().splice(index, 1)
		vm({})
	}


	let deleteWarehouse = function(){
		let index = R.findIndex(
            function(chr) {
				return chr.warehouse_id()
				== warehouse_vm().warehouse_id()},
            warehouses()
        )
		warehouses().splice(index, 1)
		warehouse_vm({})
	}

	let resetForm = function(){
		return {
				organization_id: m.prop(null)
				,supplier_id: m.prop(null)
				,supplier_name: m.prop(null)
				,supplier_locations: []
				,supplier_items: []
				,supplier_delivery: m.prop(null)
			}
		}


	let resetIForm = function(){
		return {
				supplier_id: m.prop(null)
				,supplier_items_id: m.prop(uuid.v4())
				,supplier_items_name: m.prop(null)
				,supplier_items_description: m.prop(null)
				,supplier_items_cost: m.prop(0)
				,supplier_items_batch: m.prop(0)
				,supplier_items_batch_cost: m.prop(0)
				,supplier_items_supply_time: m.prop(2)
				,supplier_items_available: m.prop(true)
				,supplier_items_uom: m.prop('each')
				,supplier_items_minimum_order: m.prop(0)
				,created: true
				,key: Math.random().toString(15).slice(2, 8)
			}
		}


	let resetLForm = function(){
		return {
				supplier_id: m.prop(null)
				,supplier_locations_id: m.prop(null)
				,supplier_locations_location: m.prop(null)
				,supplier_locations_regular_operating_hours: m.prop('7am - 4pm Mon-Fri')
				,supplier_locations_weekend_operating_hours: m.prop('8am -12pm Sat-Sun')
				,supplier_locations_public_holiday_operating_hours: m.prop('Closed')
				,supplier_locations_phone: m.prop(null)
				,supplier_locations_email: m.prop(null)
				,created: true
				,key: Math.random().toString(15).slice(2, 8)
			}
		}

	let resetNewMaterial = function(){
		return {
				created: true
				,organization_id: m.prop(null)
				,distribution_id: m.prop(null)
				,distribution_entity_name: m.prop(null)
				,distribution_entity_id: m.prop(null)
				,supplier_id: m.prop(null)
				,supplier_items_id: m.prop(null)
				,distribution_material_name: m.prop(null)
				,distribution_material_description: m.prop(null)
				,distribution_amount: m.prop(0)
				,distribution_batch: m.prop(0)
				,distribution_container_name: m.prop(null)
				,distribution_container_id: m.prop(null)
				,distribution_silo: m.prop(false)
				,distribution_ordered: m.prop('')
				,distribution_float: m.prop(0)
				,distribution_stocked: m.prop(
					warehouse_vm().warehouse_name
						? warehouse_vm().warehouse_name() + " - " + warehouse_vm().warehouse_location()
						: !project_vm().project_name
								? null
								: SelectedProject().project_name() + " - " + SelectedProject().project_material_suburb()
					)
				,supplier_items_unique_recognition: m.prop(null)
				,key: Math.random().toString(15).slice(2, 14)
			}
		}

	let resetWForm = function(){
		return {
				warehouse_id: m.prop(null)
				,organization_id: m.prop(null)
				,warehouse_name: m.prop(null)
				,warehouse_location: m.prop(null)
				,warehouse_operating_hours_regular: m.prop('7am - 4pm Mon-Fri')
				,warehouse_operating_hours_weekend: m.prop('8am -12pm Sat-Sun')
				,warehouse_operating_hours_public_holiday: m.prop('Closed')
				,warehouse_phone: m.prop(null)
				,warehouse_email: m.prop(null)
				,warehouse_items: []
				,warehouse_delivery: m.prop(null)
			}
		}

	let makePreset = function(item /*, clone */){
		let preset = {
			created: true
			,presets_id: m.prop(null)
			,presets_name: m.prop(item.distribution_entity_name())
			,organization_id: m.prop(null)
			,distribution_entity_name: m.prop(item.distribution_entity_name())
			,distribution_entity_id: m.prop(item.distribution_entity_id())
			,supplier_id: m.prop(item.supplier_id())
			,supplier_items_id: m.prop(item.supplier_items_id())
			,distribution_material_name: m.prop(item.distribution_material_name())
			,distribution_material_description: m.prop(item.distribution_material_description())
			,distribution_amount: m.prop(item.distribution_amount())
			,distribution_batch: m.prop(item.distribution_batch())
			,distribution_container_name: m.prop(item.distribution_container_name())
			,distribution_container_id: m.prop(item.distribution_container_id())
			,distribution_silo: m.prop(item.distribution_silo())
			,distribution_ordered: m.prop(item.distribution_ordered())
			,distribution_stocked: m.prop(item.distribution_stocked())
			,distribution_float: m.prop(item.distribution_float())
			,key: Math.random().toString(15).slice(2, 14)
		}
		return preset
	}


	let DeleteWDisabled = function (returnBoolean){
		const errorArray = [
			(d) =>
				d.warehouse_id
				&& ItemConsolidation(d)
				.filter((i) =>
					i.distribution_ordered()
					&& i.distribution_ordered() != 'Cancelled'
				)
				.length != 0
					? `To delete this Warehouse, transfer its items to another location`
					: null
		]

		return elements.errorAlert(
			``
			,''
			,''
			,[warehouse_vm()]
			,errorArray
			,returnBoolean
			,true
		)
	}

	let edit = m.prop(false)

	let check_new_material = function(returnBoolean){
		const errorArray = [
			(d, prop='distribution_material_name') => items().length != 0 && d[prop] && !d[prop]() ? `Item Name` : null
			,(d, prop='distribution_stocked') => items().length != 0 && d[prop] && !d[prop]() ? `Item stocked location` : null
			,(d, prop='distribution_amount') => items().length != 0 && d[prop] && !d[prop]() ? `Units` : null
		]

		return elements.errorAlert(
			`To add this, `
			,' and '
			, ' must not be empty'
			, new_material().distribution_id
				? [new_material()]
				: exposeObject(d_item_vm())
			,errorArray
			,returnBoolean
			,false
			,'warning'
			,null
			,Object.keys(errors())
				.map((a) =>
					errors()[a]
						? a + ' ' + errors()[a]
						: ''
				)
				.concat(
					!data.readSupplierPermissions()
						? `Supplier permissions are required to Add Materials.`
						: items().length == 0
							? `Create items in Suppliers to then distribute around Projects, Automated Items and Warehouses`
							: ''
				)
				.filter((a) => a)
		)
	}

	let SItemDelete = function(returnBoolean){
		const errorArray = [
			() => item_vm().supplier_items_id
					&& materials().some((m) =>
						m.supplier_items_id()
						== item_vm().supplier_items_id()
						&& m.distribution_entity_name()
					)
				? `The selected supplier item can't be deleted, it is being used in projects or warehouses`
				: null
		]
		return elements.errorAlert(
			``
			,''
			, ''
			,[item_vm()]
			,errorArray
			,returnBoolean
			,true
		)
	}

	let check_inames = function (returnBoolean){
		const count = R.countBy( chr =>
			chr.supplier_items_name()
			, vm().supplier_items
		)
		const errorArray = [
			(d, prop='supplier_items_name') => !d[prop]()
				? `Item Name must not be empty` : null
			,(d, prop='supplier_items_name') => count[d[prop]()] > 1
				? `Item Names must not be duplicated, check ` + d[prop]() : null
			,(d, prop='supplier_items_description') => !d[prop]()
				? `Item Description must not be empty` : null
		]

		return elements.errorAlert(
			`To save, `
			,' and '
			, '. '
			,vm().supplier_items
			,errorArray
			,returnBoolean
			,false
			,'warning'
			,null
			,[SItemDelete(true)]
			,errorsMessage()
		)
	}

	let check_lnames = function (returnBoolean){
		const count = R.countBy( chr =>
			chr.supplier_locations_location()
			, vm().supplier_locations
		)
		const errorArray = [
			(d, prop='supplier_locations_location') => !d[prop]()
				? `Supplier Outlet Address must not be empty` : null
			,(d, prop='supplier_locations_location') => count[d[prop]()] > 1
				? `Supplier Outlet Addresses must not be duplicated, check ` + d[prop]() : null
		]

		return elements.errorAlert(
				`To save, `
				,' and '
				,''
			,vm().supplier_locations
				,errorArray
				,returnBoolean
				,false
			,'warning'
			,null
			,errorsMessage()
		)
	}


	let SaveSupplierDisabled = function(returnBoolean){
		const count = R.countBy(
			chr => chr.supplier_name()
			, suppliers().filter((c) =>
				c.supplier_id() != vm().supplier_id()
			).concat(vm())
		)
		const errorArray = [
			(d) => !d.supplier_name() ? `Supplier Name must not be empty` : null
			,(d) => count[d.supplier_name()] > 1 ? `Supplier Names must not be duplicated` : null
		]

		return elements.errorAlert(
			`To save, `
			,' and '
			, ''
			,[vm()]
			,errorArray
			,returnBoolean
			,false
			,'warning'
			,null
			,errorsMessage()
		)
	}


	let DeleteSDisabled = function(returnBoolean){
		const errorArray = [
			(d) => d.length == 0 ? returnBoolean ? `Suppliers are empty` : '' : null
			,(d) => !d.supplier_name || !d.supplier_name() ? returnBoolean ? `Supplier Name is empty` : '' : null
			,(d) => d.supplier_name
				&& materials().some((i) =>
					vm().supplier_items.find((si) =>
						si.supplier_items_id()
						== i.supplier_items_id()
					)
				).length
					? `This supplier can't be deleted, its items are being used in projects or warehouses`
					: null
		]

		return elements.errorAlert(
			``
			,''
			, ''
			,[
				suppliers()
				,vm()
				,vm()
			]
			,errorArray
			,returnBoolean
			,true
		)
	}

	let check_wnames = function(returnBoolean){
		const count = R.countBy(
			chr => chr.warehouse_name()
			, warehouses().filter((c) =>
				c.warehouse_id() != warehouse_vm().warehouse_id()
			).concat(warehouse_vm())
		)
		const errorArray = [
			(d) => !d.warehouse_name() ? `Warehouse Name must not be empty` : null
			,(d) => !d.warehouse_location() ? `Warehouse Address must not be empty` : null
			,(d) => count[d.warehouse_name()] > 1 ? `Warehouse Names must not be duplicated` : null

		]

		return elements.errorAlert(
			`To save, `
			,' and '
			, ''
			,[warehouse_vm()]
			,errorArray
			,returnBoolean
			,false
			,'warning'
			,null
			,errorsMessage()
		)
	}


	let check_preset = function(returnBoolean){
		const errorArray = [
			(d, prop='distribution_material_name') => d[prop] && items().length > 0 && !d[prop]() ? `Item Name` : null
			,(d, prop='distribution_amount') => d[prop] && items().length > 0 && !d[prop]() ? `Units` : null
		]

		return elements.errorAlert(
			items().length > 0 ? `To save this automated fill ` : ''
			,items().length > 0 ? ' and ' : ''
			,items().length > 0 ? ' must not be empty' : ``
			,new_material().distribution_id
				? [new_material()]
				: exposeObject(d_item_vm())
			,errorArray
			,returnBoolean
			,false
			,'warning'
			,null
			,Object.keys(errors())
				.map((a) =>
					errors()[a]
						? a + ' ' + errors()[a]
						: ''
				)
				.concat(
					!data.readSupplierPermissions()
						? `Supplier permissions are required to Add Materials.`
						: items().length == 0 && !metaDataLoading()
							? `Create items in Suppliers to then distribute around Projects, Automated Items and Warehouses`
							: ''
				)
				.filter((a) => a)
		)
	}

	let transferCheck = function(returnBoolean){
		const errorArray = [
			() => warehouse_vm().warehouse_id && warehouse_vm().warehouse_id() && data.schedule_id() == null ? `Select a schedule to include project options but save the warehouse first to keep its progress` : null
			,() => transfer_object.to() && transfer_object.to() == transfer_object.from() ? `Its not wise to transfer items to and from the same place` : null
		]
		return elements.errorAlert(
			''
			,''
			,``
			,[transfer_object]
			,errorArray
			,returnBoolean
			,true
			,'warning'
			,null
			,errorsMessage()
		)
	}


	let FilteredMaterialspt = function(project, useList){

		const mlist = useList || materials
		return !project.disciplines
			? []
			: [].concat(
				mlist().filter((p) =>
					p.distribution_entity_id()
					== project.project_id()
			)
				,R.unnest(
					project.disciplines.map((d) =>
						mlist().filter((m) =>
							m.distribution_entity_id()
							== d.discipline_id()
		)
					)
				)
				)
	}


	let available_yards = function(){
	 	let yards = []

	 	warehouses().forEach((w) => {
	 		let yard = { storage_location: w.warehouse_name() + " - " + w.warehouse_location()}
	 		yards.push(yard)
	 	})

	 	projects().forEach((p) => {
	 		let yard = { storage_location: p.project_name() + " - " + p.project_material_suburb()}
	 		yards.push(yard)
	 	})

	 	resources().forEach((r) => {
	 		let yard = { storage_location: r.contractor_name() + " - " + r.contractor_yard()}
	 		yards.push(yard)
	 	})

	 	materials().forEach((m) => {
	 		let index = R.findIndex(
                 function(chr) { return chr.storage_location == m.distribution_stocked() },
                 yards
            )
	 		if (index == -1 ){
				let yard = { storage_location: m.distribution_stocked() }
				yards.push(yard)
	 		}
	 	})
		return availableYardSelections(yards)
	}

	const transfers = prop.merge(
		[
			warehouses
			,projects
		]
	)
	.map(
		([warehouses, projects]) =>
			[].concat(
				editWarehousePermissions()
					? warehouses.map(
					w => ({
						transfer_name: w.warehouse_name()
						,transfer_id: w.warehouse_id()
						,tranfer_location: w.warehouse_location()
						,warehouse_id: w.warehouse_id()
					})
				)
					: []
				,
				editMaterialsPermissions() || editWarehousePermissions()
					? projects.map(
					p => ({
						transfer_name: p.project_name()
						,transfer_id: p.project_id()
						,tranfer_location: p.project_material_suburb()
						,project_id: p.project_id()
					})
				)
					: []
			)
	)

	const transfersFrom = prop.merge(
		[
			transfers
			,transfer_object.to
			,warehouse_vm
		]
	)
	.map(
		([transferList, selectedTransfer]) =>
			selectedTransfer
				? transferList.filter((t) => t.transfer_id != selectedTransfer.transfer_id)
				: transferList
	)


	const transfersTo = prop.merge(
		[
			transfers
			,transfer_object.from
			,warehouse_vm
		]
	)
	.map(
		([transferList, selectedTransfer]) =>
			selectedTransfer
				? transferList.filter((t) => t.transfer_id != selectedTransfer.transfer_id)
				: transferList
	)

	let make_items = function(){
		items([])
		suppliers().forEach((supplier) => {
			supplier.supplier_items.forEach((item) => {
				if (item.supplier_items_available()){
					let listed_item = {
						supplier_items_unique_recognition: item.supplier_items_name()  + ' - ' + item.supplier_items_description() + ' - ' + supplier.supplier_name()
						,supplier_id: supplier.supplier_id()
						,supplier_items_id: item.supplier_items_id()
						,supplier_items_name: item.supplier_items_name()
						,supplier_items_description: item.supplier_items_description()
						,supplier_items_cost: item.supplier_items_cost()
						,supplier_items_batch: item.supplier_items_batch()
						,supplier_items_batch_cost: item.supplier_items_batch_cost()
						,supplier_items_available: item.supplier_items_available()
						,supplier_items_uom: item.supplier_items_uom()
						,supplier_items_minimum_order: item.supplier_items_minimum_order()
					}
					items().push(listed_item)
				}
				item.supplier_name = supplier.supplier_name()
			})
		})

		supplierItemIndex(
			R.indexBy(
				(si) => si.supplier_items_id()
				,R.chain(
					(s) => s.supplier_items
					,suppliers()
				)
			)
		)

		return items(items())
	}


	let check_ordered = function(item){
		return order_vm.order().find((m) =>
			uniqItem(m, uniqDItem) == uniqItem(item, uniqDItem)
        )
	}

	let check_order_vm = function(item){
		let removal_list =
			order_vm.order()
			.filter((selected_container_item) =>
				selected_container_item.distribution_container_name()
				== item.distribution_container_name()
			)

		removal_list.forEach((removal_item) => {
			let index = R.findIndex(
				(chr) => chr.distribution_id()
				== removal_item.distribution_id()
                ,order_vm.order()
            )
			if (index >= 0){ order_vm.order().splice(index, 1) }
		})
	}




	let statProjectMaterials = function(){
		const sumDollars = (list) =>
				R.sum(
					list.map((m) =>
						!containerIndex()[m.distribution_container_name()]
							? 0
							: containerIndex()[m.distribution_container_name()].containerIsFull
							? supplierItemIndex()[m.supplier_items_id()].supplier_items_batch_cost() * m.distribution_amount()
							: supplierItemIndex()[m.supplier_items_id()].supplier_items_cost() * m.distribution_amount()
					)
				)

		if ( Object.keys(containerIndex()).length != 0 && Object.keys(supplierItemIndex()).length != 0){
			projects().forEach((p) => {
				let mlist = FilteredMaterialspt(p)
				let mlistOrdered = mlist.filter((item) =>
						item.distribution_ordered()
						&& item.distribution_ordered() != 'Cancelled'
					)
				p.project_items = mlist
				p.orderedStat =
					mlist.length == 0
						? `Not Created`
						: mlist.length != 0 && mlistOrdered.length == 0
							? 'Not ordered yet'
							: mlistOrdered.length/mlist.length * 100

				p.orderedValue =
					'$ ' + sumDollars(mlistOrdered).toFixed(2)

				p.materialValue =
					'$ ' + sumDollars(mlist).toFixed(2)
			})
		}

	}


	let copy_items = function(copyList, creatNewOrCopy){
		let copies = []
		if (copyList.length > 0){
			copyList.forEach((item) => {
				let copy_item = resetNewMaterial()
				item.presets_id
					? copy_item.presets_id = m.prop(item.presets_id())
					: null
				item.presets_name
					? copy_item.presets_name = m.prop(item.distribution_entity_name())
					: null
				item.distribution_id
					? copy_item.distribution_id( item.distribution_id() )
					: delete copy_item.distribution_id
				copy_item.created = creatNewOrCopy
				copy_item.organization_id(item.organization_id())
				copy_item.distribution_entity_id( item.distribution_entity_id() )
				copy_item.distribution_entity_name( item.distribution_entity_name() )
				copy_item.supplier_id( item.supplier_id() )
				copy_item.supplier_items_id( item.supplier_items_id() )
				copy_item.distribution_material_name( item.distribution_material_name() )
				copy_item.distribution_material_description( item.distribution_material_description() )
				copy_item.distribution_amount( item.distribution_amount() )
				copy_item.distribution_batch( item.distribution_batch() )
				copy_item.distribution_container_name( item.distribution_container_name() )
				copy_item.distribution_container_id( item.distribution_container_id() )
				copy_item.distribution_silo( item.distribution_silo() )
				copy_item.distribution_ordered( item.distribution_ordered() )
				copy_item.distribution_stocked( item.distribution_stocked() )
				copy_item.distribution_float( item.distribution_float() )
				copy_item.key = Math.random().toString(15).slice(2, 8)
				copy_item.supplier_name = (supplierItemIndex()[item.supplier_items_id()] || {}).supplier_name || ''
				copy_item.order_eta = item.order_eta
				copy_item.order_received = item.order_received
				copy_item.project_id = item.project_id
				copy_item.warehouse_id = item.warehouse_id
				copy_item.order_name = item.order_name
				copy_item.requiredby = item.requiredby
				copy_item.discipline_id = item.discipline_id
				copy_item.project_start_date = item.project_start_date
				copy_item.discipline_start_date = item.discipline_start_date
				delete copy_item.supplier_items_unique_recognition
				copies.push(copy_item)
			})
		}
		return copies
	}

	let getUniqueContainerName = function(yard){

		let container_list = R.pipe(R.map( R.invoker(0, 'distribution_container_name')), R.uniq)(materials())
		let container_count = container_list.length - 1
		do {
			container_count = container_count + 1

			let deOArray = yard.storage_location.split(' ').map((word) => word.replace(regExes.uniqPattern, '').slice(0,1).charAt(0).toUpperCase()).join('')

			var container_plausible = deOArray + '-' + container_count
			var container_check = R.findIndex(
				function(chr) { return chr == container_plausible },
				container_list
			)
		}
		while( container_check >=  0 )
		return container_plausible
	}

	let consolidate = function(){
		const ItemList =
			materials().filter((m) =>
				!m.distribution_ordered()
				&& m.distribution_ordered() != 'Cancelled'
			)
		// Make containers for siloed items

		let silo_list = ItemList.filter((material) => material.distribution_silo() == true)

		let silo_unordered = silo_list.filter((material) => !material.distribution_ordered())

		silo_unordered.forEach((material) => {
			let container_plausible = getUniqueContainerName({storage_location: material.distribution_stocked() })
			material.distribution_container_name(container_plausible)
			material.created = true
		})
		// Make containers for non-batchable items
		let nonBatchable = ItemList.filter((material) => material.distribution_batch() == 0)
		let nonBatchable_unordered = nonBatchable.filter((material) => !material.distribution_ordered())
		nonBatchable_unordered.forEach((material) => {
			let container_plausible = getUniqueContainerName({storage_location: material.distribution_stocked() })
			material.distribution_container_name(container_plausible)
			material.created = true
		})
		// Make containers for batchable items that have a greater quanitity than the batch quantiity. Would be special reauest from supplier, but is possible
		let specialOrder = ItemList.filter((material) => Number(material.distribution_amount()) >= Number(material.distribution_batch()))
		let specialOrder__unordered = specialOrder.filter((material) => !material.distribution_ordered())
		specialOrder__unordered.forEach((material) => {
			let container_plausible = getUniqueContainerName({storage_location: material.distribution_stocked() })
			material.distribution_container_name(container_plausible)
			material.created = true
		})
		// Consolidate all other materials
		let consolidation_list = ItemList.filter((material) => material.distribution_silo() == false)
		let batchable = consolidation_list.filter((material) => material.distribution_batch() != 0)
		let notspecial = batchable.filter((material) => Number(material.distribution_amount()) < Number(material.distribution_batch()) )
		let notordered = notspecial.filter((material) => !material.distribution_ordered())
		availableYardSelections().forEach((yard) => {
			let unsorted_common_yards = notordered.filter((material) => material.distribution_stocked() == yard.storage_location)
			let unique_item_descriptions = R.pipe(R.map( R.invoker(0, 'distribution_material_description')), R.uniq)(unsorted_common_yards)
			unique_item_descriptions.forEach((description) => {
                let unsorted_common_description = R.pipe(
                    R.filter((material) => material.distribution_material_description() == description),
                    R.sortBy(R.propEq("distribution_amount")),
                    //todo-james why are we reversing, why does the order matter? its a short cut to finding the best solution
                    R.reverse
                )(unsorted_common_yards)

                unsorted_common_description.forEach(function(item){
                    item.distribution_container_name( null )
                    item.distribution_container_id( null )
                })

				let container_plausible = getUniqueContainerName(yard)

				let allitemscount = unsorted_common_description.length
				let sumOnDrum = 0
				let x = 0
				let TotAllo = 0
				let Remainders = 0
				do {
					while( x <= 25 && allitemscount > TotAllo ){
						unsorted_common_description.forEach( (material) => {
							if ( material.distribution_container_name() == null ){
                                let distribution_batch = Number(material.distribution_batch())
								let intervals = [
                                    0,0.0008,0.001,0.002,0.004,0.008,0.0125
                                    ,0.0175,0.020,0.025 ,0.030,0.0375,0.045,0.06
                                    ,0.08,0.1,0.2,0.3,0.4,0.5,0.6,0.7,0.8,0.9,1
								,].map(R.multiply(distribution_batch))

								if ( TotAllo == 0){
									let i = 0
									Remainders = distribution_batch - sumOnDrum
									while (Remainders > intervals[i]){
										i = i + 1
									}
									x = i
								}

								let fitsOnDrum = (material, sumOnDrum, x) =>
									Number(material.distribution_amount()) <= distribution_batch - sumOnDrum
									&& Number(material.distribution_amount()) >= distribution_batch - sumOnDrum - Number(intervals[x])


							    if(sumOnDrum < distribution_batch ){
							    	if( fitsOnDrum(material, sumOnDrum, x) ){
										sumOnDrum = sumOnDrum + Number(material.distribution_amount())
										material.distribution_container_name(container_plausible)
										material.created = true
										TotAllo = TotAllo + 1
										x = 0
									}
								}

							}
						})
						x = x + 1
					}
					x = 0
					sumOnDrum = 0
					container_plausible = getUniqueContainerName(yard)
				}
				while(allitemscount > TotAllo)
			})
		})
	}





	let assessContainers = function(){
		const cContainers =
			R.uniqBy(
				(a) => a.distribution_container_name()
				,materials().filter((a) => a.distribution_container_name() )
			)

		uniqcontIndex(
			R.indexBy(
				(c) => c.distribution_container_name()
				,cContainers
			)
		)

		containers()
			.filter((a) =>
				!uniqcontIndex()[a.container_name()]
			)
			.map((m) =>

					containers().splice(
						containers().findIndex((c) =>
							c.container_name()
							== m.container_name()
						)
						, 1
					)

				&& m.container_id
				&& container_vm().selected.container.push(m)
			)


		const nContainers = cContainers
				.filter((a) =>
				!containerIndex()[a.distribution_container_name()]
				)
				.map( (material) =>
				({
					container_id: m.prop(null)
					,container_name: m.prop(material.distribution_container_name())
					,container_silo: m.prop(material.distribution_silo())
					,organization_id: m.prop(material.organization_id())
				})
			)

		containers(containers().concat(nContainers))
	}



	let check_float = function(item){
		let warehouse_amount = WarehouseItems().filter((m) =>  m.supplier_items_id() == item.supplier_items_id() )
		let count = 0
		warehouse_amount.forEach((witem) => {
			if(	witem.distribution_ordered() && witem.distribution_ordered() != "Cancelled"){
				count = count + Number(witem.distribution_amount())
			}
		})
		return count < item.distribution_float()
	}

	let updatefloats = function(value){
		if ( value != null ){

			const newMaterialList =
				Object.keys(d_item_vm()).length
					? []
					: [new_material()]

			const releventItems =
				R.uniqBy(
					R.pipe(R.prop('supplier_items_id'), R.call)
					,Object.keys(d_item_vm()).length
						? exposeObject(d_item_vm())
						: [new_material()]
				)

			WarehouseItems()
			.filter((m) =>
				releventItems.find((i) =>
					m.supplier_items_id()
					== i.supplier_items_id()
				)
			)
			.concat( newMaterialList )
			.forEach((i) => {
				i.created = true
				i.distribution_float(value)
			})

		}
		return Object.keys(d_item_vm()).length
			? d_item_vm()[Object.keys(d_item_vm())[0]].distribution_float()
			: new_material().distribution_float()
	}

	let permissions = data.permissions

	permissions.map(function(p){

		const hasWrite = k => Permissions.hasWrite(p, k)

		editWarehousePermissions( ['warehouse_management'].some(hasWrite) )
		editSupplierPermissions( ['supplier_management'].some(hasWrite) )
		editOrdersPermissions( ['order_management'].some(hasWrite) )
		editMaterialsPermissions( ['material_management'].some(hasWrite) )
		m.redraw()
		return null
	})

	var ItemConsolidation = function(object, uselist){

		const id =
			object
			&& (object.transfer_id || object.warehouse_id)
			? object.transfer_id
				? object.transfer_id
				: object.warehouse_id()
			: null

		const subids =
			object && object.project_id
			? projectIndex()[id].disciplines.map(R.pipe(R.prop('discipline_id'), R.call))
			: []

		const mlist = uselist || materials

		return R.sortBy(
			R.prop('order_eta')
			,mlist()
				.filter((m) =>
					m.distribution_entity_id() == id
					|| subids.find(R.equals(m.distribution_entity_id()))
					&& m.distribution_ordered() != 'Cancelled'
				)
			)
	}

	const transferToMaterialIndex =
		transfer_object.to.map((t) =>
			R.groupBy(
				(a) => a.distribution_material_name()
				,ItemConsolidation(t)
			)
		)

	let setnew_material = function(){ new_material(resetNewMaterial()) }

	const cloneSupplier = function(supplier){
		let newSupplier = resetForm()

		newSupplier.organization_id(supplier.organization_id()),
		newSupplier.supplier_id(supplier.supplier_id()),
		newSupplier.supplier_name(supplier.supplier_name()),
		newSupplier.supplier_delivery(supplier.supplier_delivery())
		newSupplier.__requestedAt = supplier.__requestedAt

		newSupplier.supplier_locations =
			supplier.supplier_locations.map((slocation) => {
				let newSLocations = resetLForm()
				newSLocations.supplier_id(slocation.supplier_id())
				newSLocations.supplier_locations_id(slocation.supplier_locations_id())
				newSLocations.supplier_locations_location(slocation.supplier_locations_location())
				newSLocations.supplier_locations_regular_operating_hours(slocation.supplier_locations_regular_operating_hours())
				newSLocations.supplier_locations_weekend_operating_hours(slocation.supplier_locations_weekend_operating_hours())
				newSLocations.supplier_locations_public_holiday_operating_hours(slocation.supplier_locations_public_holiday_operating_hours())
				newSLocations.supplier_locations_phone(slocation.supplier_locations_phone())
				newSLocations.supplier_locations_email(slocation.supplier_locations_email())
				newSLocations.created = false
				newSLocations.key = Math.random().toString(15).slice(2, 8)
				return newSLocations
			})

		newSupplier.supplier_items =
			supplier.supplier_items.map((sitem) => {
				let newSItems = resetIForm()
				newSItems.supplier_id(sitem.supplier_id())
				newSItems.supplier_items_id(sitem.supplier_items_id())
				newSItems.supplier_items_name(sitem.supplier_items_name())
				newSItems.supplier_items_description(sitem.supplier_items_description())
				newSItems.supplier_items_cost(sitem.supplier_items_cost())
				newSItems.supplier_items_batch(sitem.supplier_items_batch())
				newSItems.supplier_items_batch_cost(sitem.supplier_items_batch_cost())
				newSItems.supplier_items_supply_time(sitem.supplier_items_supply_time())
				newSItems.supplier_items_available(sitem.supplier_items_available())
				newSItems.supplier_items_uom(sitem.supplier_items_uom())
				newSItems.supplier_items_minimum_order(sitem.supplier_items_minimum_order())
				newSItems.created = false
				newSItems.key = Math.random().toString(15).slice(2, 8)
				newSItems.supplier_name = supplier.supplier_name()
				return newSItems
			})
		vm(newSupplier)
	}

	const cloneWarehouse = function(warehouse){
		let newWarehouse = resetWForm()
		newWarehouse.warehouse_id(warehouse.warehouse_id()),
		newWarehouse.organization_id(warehouse.organization_id()),
		newWarehouse.warehouse_name(warehouse.warehouse_name()),
		newWarehouse.warehouse_location(warehouse.warehouse_location()),
		newWarehouse.warehouse_operating_hours_regular(warehouse.warehouse_operating_hours_regular()),
		newWarehouse.warehouse_operating_hours_weekend(warehouse.warehouse_operating_hours_weekend()),
		newWarehouse.warehouse_operating_hours_public_holiday(warehouse.warehouse_operating_hours_public_holiday()),
		newWarehouse.warehouse_phone(warehouse.warehouse_phone()),
		newWarehouse.warehouse_email(warehouse.warehouse_email()),
		newWarehouse.warehouse_delivery(warehouse.warehouse_delivery())
		newWarehouse.warehouse_items = []
		newWarehouse.__requestedAt = warehouse.__requestedAt
		warehouse_vm(newWarehouse)
	}

	let viewtype = data.scoped(MainList)
	let activeMainTab = m.prop('Warehouses & Stock')

	const DeletePending = m.prop(false)
	const transferItems =
		prop.merge(
			[transfer_object.from
			,transfer_vm]
		)
		.map(([t, ]) =>
			ItemConsolidation(t).filter((i) =>
				i.distribution_id()
				&& (!i.order_received || i.order_received >= i.distribution_amount())
				&& i.distribution_ordered() != 'Cancelled'
			)
		)

	const ProjectItems =
		prop.merge(
			[selected_pt
			,new_material
			,d_item_vm
			,order_vm.orderUpdates
			,order_vm.order
			,transfer_object.from]
		)
		.map(([p,]) => FilteredMaterialspt(p))

	const WarehouseItems =
		prop.merge(
			[warehouse_vm
			,new_material
			,d_item_vm
			,order_vm.orderUpdates
			,order_vm.order
			,transfer_object.from]
		)
		.map(([w, ]) =>
			ItemConsolidation(w)
		)

	const PresetItems =
		prop.merge(
			[selected_preset
			,new_material
			,d_item_vm]
		)
		.map(([pr, ]) =>
			presets().filter((p) =>
				pr
				? p.presets_name() == pr
					: false
			)
		)

	let baseProjectString =
		prop.merge(
			[SelectedProject
			,containerIndex
			]
		)
		.map(([p, ]) => FilteredMaterialspt(p, originalMaterials) )

	let baseWarehouseString =
		prop.merge(
			[warehouse_vm
			,containerIndex
			]
		)
		.map(([w, ]) => {
			return ItemConsolidation(w, originalMaterials)
		})

	let basePresetString =
		selected_preset.map((pr) =>
			originalPresets().filter((p) =>
				p.presets_name()
				== pr
			)
		)

	let stockedMaterials =
		prop.merge(
			[
				WarehouseItems
				,MaterialIndex
				,materials
			]
		)
		.map(([wList, ]) =>
			wList.filter((o) =>
				o && o.distribution_ordered() != 'Cancelled'
			)
		)


	let cancelledMaterials =
		prop.merge(
			[
				WarehouseItems
				,MaterialIndex
				,materials
			]
		)
		.map(([wList, ]) =>
			wList.filter((o) =>
				o && o.distribution_ordered() == 'Cancelled'
			)
		)

	function uploadmetadata({only}){
		return R.pickBy(
			(a, k) => a && (!only || !Object.keys(only).length || (only && only[k]) || k == 'user_id' || k == 'organization_id')
			,{
				organization_id: data.initiateContractFetch().organization_id
				, user_id: data.auth.stream().user_id
				, supplier_items_id: activeMainTab() == 'Suppliers' && item_vm().supplier_items_id && item_vm().supplier_items_id()
				, order_id: only && only.order_id
			}
		)
	}

	const supplierItemsSide = data.scoped(-1)

	function setSide(prop, v){
		if(v){ prop(Math.abs(v)*supplierItemsSide()) }
		return prop()
	}

	const supplierdetailPaneInputs = function(item, ctrl){

		const locationView = ctrl.activeTab() == 'Outlets'
		const itemView = ctrl.activeTab() == 'Items'


		let itemInputs = function(){
			return [
				elements.list([
					m('label.control-label'
						, 'Item Name'
						,elements.textInput(item.supplier_items_name)
					)
				])
				,elements.list([
					m('label.control-label'
						, 'Description'
						,elements.textInput(item.supplier_items_description)
					)
				])
				,elements.list([
					m('label.control-label'
						, 'Unit Cost'
						,m(
							NumberInput
							,R.merge(
								data
								,{
									errors
									,errorLabel: 'Unit Cost'
								}
							)
							,{
								prop: (v) => setSide(item.supplier_items_cost, v)
								,attrs: {
									step: 0.01
								}
							}
						)
					)
				])

				,m(`button`
					+ css`
						border-radius: 0.5em;
						border: none;
						height: 2em;
						display: grid;
						gap: 1em;
						padding-bottom: 3em;
						grid-template-rows: 1fr;
						grid-template-columns: 0.5fr 0.5fr;
						background-color: inherit;
						align-content: center;
						align-items: center;
					`
					,{
						onclick: () =>  supplierItemsSide( supplierItemsSide() * -1 )
					}
					, [
						supplierItemsSide() < 0
							? 'Expense'
							: 'Revenue'
						,supplierItemsSide() < 0
							? elements.switchiconNeuRight()
							: elements.switchiconNeuLeft()
					]
				)

				,elements.list([
					m('label.control-label', 'Batch Qty'
						,m(
							NumberInput
							,R.merge(
								data
								,{
									errors
									,errorLabel: 'Batch Qty'
								}
							)
							,{
								prop: item.supplier_items_batch
								,attrs: {
									min: 0
									, step: 0.01
								}
							}
						)
					)
				])
				,elements.list([
					m('label.control-label', 'Batch Cost'
						,m(
							NumberInput
							,R.merge(
								data
								,{
									errors
									,errorLabel: 'Batch Cost'
								}
							)
							,{
								prop: (v) => setSide(item.supplier_items_batch_cost, v)
								,attrs: {
									step: 0.01
								}
							}
						)
					)
				])
				,elements.list([
					m('label.control-label', 'Availability')
						,elements.checkbox({
							onchange (event){
								if(event.currentTarget.checked == true){
									item.supplier_items_available(true)
								}
								else if (event.currentTarget.checked == false){
									item.supplier_items_available(false)
								}
							}
							,checked: item.supplier_items_available()
							,disabled: false
							,title: 'This item is catalogued for sale'
						})
				])
				,elements.list([
					m('label.control-label'
						, 'Supply Time (Days)'
						, m(
							NumberInput
							,R.merge(
								data
								,{
									errors
									,errorLabel: 'Supply Time'
								}
							)
							,{
								prop: item.supplier_items_supply_time
								,attrs: {placeholder: "2", step: 1, min:0}
							}
						)
					)
				])
				,elements.list([
					m('label.control-label'
						, 'Unit of Measure'
						,elements.select(['metres', 'each', 'predefined standard'], item.supplier_items_uom, {})
					)
				])
				,elements.list([
					m('label.control-label', 'Min. Order'
						,m(
							NumberInput
							,R.merge(
								data
								,{
									errors
									,errorLabel: 'Min. Order'
								}
							)
							,{
								prop: item.supplier_items_minimum_order
								,attrs: {
									min: 0
								}
							}
						)
					)
				])

				,m(HarthUppy, {
					getFields: () => uploadmetadata({})
					, data
				})

			]
		}

		let locationInputs = function(){
			return [
				elements.list([
					m('label.control-label'
						, 'Supplier Outlets'
						,elements.textInput(item.supplier_locations_location, {placeholder: "982 Homer Street, Greenvile, NSW, 2206"} )
					)
				])
				,elements.list([
					m('label.control-label'
						, 'Regular Operating Hours'
						,elements.textInput(item.supplier_locations_regular_operating_hours, {placeholder: "9am - 5pm"} )
					)
				])
				,elements.list([
					m('label.control-label'
						, 'Weekend Operating Hours'
						,elements.textInput(item.supplier_locations_weekend_operating_hours, {placeholder: "10am - 4pm"} )
					)
				])
				,elements.list([
					m('label.control-label'
						, 'PH Operating Hours'
						,elements.textInput(item.supplier_locations_public_holiday_operating_hours, {placeholder: "Closed"} )
					)
				])
				,elements.list([
					m('label.control-label'
						, 'Ph'
						,elements.telephoneInput(item.supplier_locations_phone, {placeholder: "02 XXXX XXXX"} )
					)
				])
				,elements.list([
					m('label.control-label'
						, 'Email'
						,elements.textInput(item.supplier_locations_email, {placeholder: "john@harth.io"})
					)
				])
			]
		}

		return [
			item.supplier_locations_location && locationView
			|| item.supplier_items_name && itemView
				? [
					elements.list([
						locationView
							? ctrl.model.editSupplierPermissions()
								? elements.list([
									m('button.btn', {
										onclick: () => {
											ctrl.model.location_vm({})
										}
									}, 'Done')
									,m('button.btn.btn-warning', {
										onclick: () => {
											ctrl.intent.deleteLocation()
										}
										,disabled: !ctrl.model.location_vm().supplier_locations_location
										,title: 'Delete a suppliers outlets'
									}, 'Delete' )
								])
								: null
							: ctrl.model.editSupplierPermissions()
								? elements.list([
									m('button.btn', {
										onclick: () => {
											ctrl.model.item_vm({})
										}
									}, 'Done')
									,m('button.btn.btn-warning', {
										onclick: () => {
											ctrl.intent.deleteItem()
										}
										,disabled: !!ctrl.model.SItemDelete(true)
											|| !ctrl.model.item_vm().supplier_items_name
										,title: 'Delete a supplier item'
									}, 'Delete' )
								])
								: null
					])
					,locationView
						? locationInputs()
						: itemInputs()
				]
				: []

		]
	}

	let supplierdetailsPaneOpen =
		() =>
			// vm().supplier_id && (
				location_vm().supplier_locations_location
				|| item_vm().supplier_items_name
			// )


	const detailPaneInputs = function(item, ctrl){

		const WarehouseView =
			warehouse_vm().warehouse_id != null
			&& activeMainTab() == 'Warehouses & Stock'

		const ProjectView =
			selected_pt().project_id
				&& ctrl.activeDTab() == 'Distribution'

		const PresetView = !WarehouseView && !ProjectView

		const commonInputs = function(){

			return [

				item.supplier_items_unique_recognition
					? elements.list([
						!editMaterialsPermissions()
						? ctrl.model.new_material().supplier_items_unique_recognition()
						: m('label.control-label.mb1'
							,'Item Name '
							,Pencil(
								() => ctrl.model.new_material().supplier_items_unique_recognition()
								,() =>
									autocomplete.strict(
										ctrl.model.items
										,(v) => {

											if (v){
													ctrl.model.new_material().distribution_material_name(v.supplier_items_name)
													ctrl.model.new_material().supplier_items_id(v.supplier_items_id)
													ctrl.model.new_material().supplier_id(v.supplier_id)

													if( WarehouseView ) {
														ctrl.model.new_material().distribution_entity_id(warehouse_vm().warehouse_id())
														ctrl.model.new_material().distribution_entity_name(warehouse_vm().warehouse_name())
													let indexObj = R.find(
															function(chr) { return chr.supplier_items_id() == ctrl.model.new_material().supplier_items_id()},
														WarehouseItems()
													)
													if ( indexObj ){
															ctrl.model.new_material().distribution_float( indexObj.distribution_float() )
														}
													}
													else if ( ProjectView ){
														ctrl.model.new_material().distribution_entity_id(SelectedProject().project_id())
														ctrl.model.new_material().distribution_entity_name(SelectedProject().project_name())
													}
													else if ( PresetView ){
														ctrl.model.new_material().distribution_entity_name(selected_preset())
														ctrl.model.new_material().distribution_entity_id(
														organizations_disciplines().find((o) =>

															o.organizations_disciplines_name
																	+ ' - '
																	+ o.organizations_disciplines_description

															== selected_preset()
														).organizations_disciplines_id
													)
												}

													ctrl.model.new_material().distribution_material_description(v.supplier_items_description)
													ctrl.model.new_material().distribution_batch(v.supplier_items_batch)
													ctrl.model.new_material().supplier_items_unique_recognition(
														v.supplier_items_unique_recognition
													)
													ctrl.model.new_material().supplier_name = (supplierItemIndex()[v.supplier_items_id] || {}).supplier_name || ''

											}
										}
										,'supplier_items_unique_recognition'
										,() => ({
											disabled: ctrl.model.items().length == 0
											,style: {
												height: '3em'
											}
										})
									)
							)
						)
					])
					: elements.list([
						exposeObject(d_item_vm())
						.map((a) =>
							m(
								'p.pv1.ma0'
								, a.distribution_amount()
								+ ' x '
								+ a.distribution_material_name()
								+ ' - '
								+ a.distribution_material_description()
								+ ' - '
								+ a.supplier_id()
							)
						)
				])

				,!(
					item.supplier_items_unique_recognition
					? supplierItemIndex()[item.supplier_items_id()]
					: supplierItemIndex()[item[Object.keys(item)[0]].supplier_items_id()]
				)
				? elements.alert(`warning`, `This item does not exist in any of the suppliers`)
				: ''

				,ProjectView
					? elements.list([
						m('label.control-label', 'Assigned To')
						,Pencil(
							() => item.supplier_items_unique_recognition
									? item.distribution_entity_name()
									: item[Object.keys(item)[0]].distribution_entity_name()
							,() =>
								autocomplete.strict(
									SelectedProjectDisciplines
									,R.when(Boolean, function(v){
										setOptions(
											!v.discipline_id && v.project_id
												? v.project_id()
												: v.discipline_id()
											, 'distribution_entity_id'
											, item.supplier_items_unique_recognition
												? [item]
												: item
										)

										setOptions(
											!v.discipline_id && v.project_id
												? v.project_name()
												: v.discplineNameDescription
											, 'distribution_entity_name'
											, item.supplier_items_unique_recognition
												? [item]
												: item
										)
									})
									,'discplineNameDescription'
									,() => ({
										placeholder: 'Select a discipline or assign to ' + project_vm().project_name()
										,disabled: !SelectedProjectDisciplines().length
									})
								)
						)
					])
					: null

				,elements.list([
					m('label.control-label.mb1'
					,WarehouseView || ProjectView ? 'Units' : 'Units/Per Discipline Rate')
					,!editMaterialsPermissions()
						? 'As Above '
						: m(
							NumberInput
							,R.merge(
								data
								,{
									errors //: m.prop({})
									,errorLabel:
										WarehouseView
										|| ProjectView ? 'Units' : 'Units/Per Discipline Rate'
								}
							)
							,{
								prop: (value) =>
									setOptions(
										value
										, 'distribution_amount'
										, item.supplier_items_unique_recognition
											? [item]
											: item
									)
								,attrs: {
									min: 0
									,step: 0.01
								}
							}
						)
				])

				,item.supplier_items_unique_recognition
					? elements.list([
						m('label.control-label', 'Batch Qty')
						,item.distribution_batch()
					])
					: null

				,WarehouseView || ProjectView
					? [
						elements.list([
							m('label.control-label', 'Silo')
							,m('input[type=checkbox]', {
								onchange(event){
									setOptions(
										event.currentTarget.checked
										, 'distribution_silo'
										, item.supplier_items_unique_recognition
											? [item]
											: item
									)
								}
								,checked: item.supplier_items_unique_recognition
									? item.distribution_silo()
									: item[Object.keys(item)[0]].distribution_silo()
								,disabled: !editMaterialsPermissions()
								,title: [
									'Selecting this will segregate this material in to its own container,'
									,'otherwise Odin will bunch the material to try'
									,'and make more efficient and cheaper bulk orders'
								].join(' ')
							})
						])


					,elements.list([
						m('label.control-label.mb1', 'Stocked Address ')
						,Pencil(
							() => item.supplier_items_unique_recognition
								? item.distribution_stocked()
								: item[Object.keys(item)[0]].distribution_stocked()
							,() =>
								autocomplete.all(
									ctrl.model.availableYardSelections
									,(value) =>
										setOptions(
											value
											, 'distribution_stocked'
											, item.supplier_items_unique_recognition
												? [item]
												: item
										)
									,'storage_location'
									, () => ({
										style: {
											height: '3em'
										}
										,disabled: !editMaterialsPermissions()
									})
								)
						)
					])

					,item.supplier_items_unique_recognition
					? elements.list([
						m('label.control-label', 'Auto Order')
						,m('input[type=checkbox]', {
							onchange(event){
								if( supplierItemIndex()[item.supplier_items_id()] ){
									item.autoreceived = event.currentTarget.checked
									item.autoorder = event.currentTarget.checked
									item.supplier_items_cost = supplierItemIndex()[item.supplier_items_id()].supplier_items_cost()
									item.supplier_items_batch_cost = supplierItemIndex()[item.supplier_items_id()].supplier_items_batch_cost()
									item.supplier_name = supplierItemIndex()[item.supplier_items_id()].supplier_name
								}
							}
							,checked: item.autoreceived
							,disabled: !editMaterialsPermissions() || !supplierItemIndex()[item.supplier_items_id()]
							,title: `Selecting this will set the material to be received today`
						})
					])
					: null
				]
				: []

				,WarehouseView
					? elements.list([
						m('label.control-label', 'Float')
						,!editMaterialsPermissions()
							? item[Object.keys(item)[0]].distribution_float()
							: Pencil(
								ctrl.model.updatefloats
								,() =>
									m(
										NumberInput
										,R.merge(
											data
											,{
												errors
												,errorLabel: 'Float'
											}
										)
										,{
											prop: ctrl.model.updatefloats
											,attrs: {
												min: 0
												,title: "Minimum amount that should be kept in the warehouse for daily use"
											}
										}
									)
							)
					])
					: []
			]
		}

		const projectOptionsList  = function(){
			return [

				m(`br`)
				,elements.list([
					m('button.btn.btn-secondary',
						{
							style: {
								backgroundColor: 'transparent'
								,border: 'solid 1px #3380c2'
								,position: 'relative'
								,top: '-0.1em'
								,height: '2.9em'
								,width: '5em'
								,color: '#434aa3'
								,textDecoration: 'underline'
							}
							,disabled: !inverseProjectsList().length
							,onclick: () => {
								ctrl.model.completedProjects() == "completed"
									? ctrl.model.completedProjects("inprogress")
									: ctrl.model.completedProjects("completed")
								return ctrl.intent.fetchProjects()

							}
							, title: "Switch between running and completed projects"
						}
						,'Switch'
					)
					,m(`label`,
						ctrl.model.completedProjects() == "completed"
							? ' to Current Projects'
							: ' to Completed Projects'
					)
				])
				,m('hr')
				,elements.list([
					m('button.btn.btn-secondary',
						{
							style: {
								backgroundColor: 'transparent'
								,border: 'solid 1px #3380c2'
								,position: 'relative'
								,top: '-0.1em'
								,height: '2.9em'
								,width: '5em'
								,color: '#434aa3'
								,textDecoration: 'underline'
							}
							,disabled:
								!checkMultipleProjects().some((p) =>
									p.disciplines.some((d) => (d.outstandingMaterials || []).length)
								)
							,onclick: () => {
								checkMultipleProjects().forEach((p) =>ctrl.intent.applyPreset(p))
								ctrl.intent.sendAllDistriution()
							}
							, title: "Switch between running and completed projects"
						}
						,'Apply'
					)
					,m(`label`,' Automated fill material to all projects' )
				])
				,m(`label`
					,`Add materials required to complete each project. Quantities will be matched to existing works on the project if the project is deficient`
				)
			]
		}

		return [
			checkMultipleProjects().length
			? projectOptionsList()
			: Object.keys(d_item_vm()).length
			|| new_material().distribution_material_name
				? [
					elements.list([
						m('button.btn.btn-primary', {
						disabled:
							PresetView
								? !!check_preset(true)
								: ProjectView
									? !!check_new_material(true)
							: !!check_new_material(true) || !!check_wnames(true)

						,onclick: () => {

							if(
								item.supplier_items_unique_recognition
								&& (
									WarehouseView
									|| ProjectView
									)
							){
								materials().unshift( item )
								materials(materials())
								}
							else if( item.supplier_items_unique_recognition ){
								presets().unshift(makePreset( item ))
							}

							consolidate()
								new_material({})
							d_item_vm({})
							masterILIST(true)
							}
					}, item.supplier_items_unique_recognition
						? 'Add'
						: 'Done'
					)

					,!item.distribution_id
						? m('button.btn.btn-warning', {
								onclick: () => {
								WarehouseView
								? ctrl.intent.deleteDitem(false, 'warehouse')
								: ProjectView
								? ctrl.intent.deleteDitem(false, 'project')
								: ctrl.intent.deletePreset(false, '')
							}
							,disabled:
								exposeObject(d_item_vm())
								.some((i) => i.distribution_ordered() )
							,title: 'Delete this item'
						}, 'Delete' )
						: m('button.btn.btn-warning', {
							disabled: false
							,onclick: () => {
								errors({})
									new_material({})
								}
							}, 'Discard')
					])
					,commonInputs()
				]
				: []
		]

	}
	let detailsPaneOpen = () =>
		new_material().distribution_material_name
		|| checkMultipleProjects().length
		|| Object.keys(d_item_vm()).length



	let filterFunction = {
		changeNames: [
			{ugly_name: 'supplier_'}
			,{ugly_name: 'supplier_locations_'}
			,{ugly_name: 'disciplines_'}
			,{ugly_name: 'disciplines_contractors_'}
			,{ugly_name: "distribution_material_name", better_name: "Material Name"}
			,{ugly_name: "distribution_material_description", better_name: "Material Description"}
			,{ugly_name: "distribution_entity_name", better_name: "Assigned To"}
			,{ugly_name: "distribution_amount", better_name: "Material Amount"}
			,{ugly_name: "distribution_batch", better_name: "Material Batch"}
			,{ugly_name: "distribution_container_name", better_name: "Material Container Name"}
			,{ugly_name: "distribution_silo", better_name: "Material Silo"}
			,{ugly_name: "distribution_ordered", better_name: "Material Ordered"}
			,{ugly_name: "distribution_float", better_name: "Material Float"}
			,{ugly_name: "distribution_stocked", better_name: "Material Stocked"}
			,{ugly_name: 'supplier_locations_location', better_name: "Supplier Outlets"}
			,{ugly_name: 'supplier_locations_regular_operating_hours', better_name: "Supplier Regular Operating Hours"}
			,{ugly_name: 'supplier_locations_weekend_operating_hours', better_name: "Supplier Weekend Operating Hours"}
			,{ugly_name: 'supplier_locations_public_holiday_operating_hours', better_name: "Supplier Public Holiday Operating Hours"}
			,{ugly_name: 'supplier_locations_phone', better_name: "Supplier Phone"}
			,{ugly_name: 'supplier_locations_email', better_name: "Supplier Email"}
			,{ugly_name: 'order_items_cost', better_name: "Order Cost"}
			,{ugly_name: 'project_commissioning_date', better_name: "Handover Date"}
			,{ugly_name: 'project_final_acceptance', better_name: "Liability End Date"}
			,{ugly_name: 'project_completion_marker', better_name: "Project Completion"}
			,{ugly_name: 'relatedDiscipline', better_name: "Related Discipline"}
			,{ugly_name: 'supplier_items_uom', better_name: "Unit of Measure"}
			// ,{ugly_name: 'project_extras_priority', better_name: "Task Prioriy"}
			// ,{ugly_name: 'project_extras_type', better_name: "Task Name"}
			// ,{ugly_name: 'project_extras_frequency', better_name: "Task Frequency"}
			// ,{ugly_name: 'project_extras_description', better_name: "Task Description"}
			// ,{ugly_name: 'project_extras_date_assigned', better_name: "Task Date Assigned"}
			// ,{ugly_name: 'project_extras_date_completed', better_name: "Task Date Completed"}
			// ,{ugly_name: 'project_extras_date_verified', better_name: "Task Date Verified"}
			// ,{ugly_name: 'project_extras_date_invalid', better_name: "Task Date Invalid"}
			// ,{ugly_name: 'project_extras_notes', better_name: "Task Notes"}
			// ,{ugly_name: 'project_extras_address', better_name: "Task Address"}
			// ,{ugly_name: 'project_extras_planned_amount', better_name: "Task Amount"}
			// ,{ugly_name: 'project_extras_actual_amount', better_name: "Task Actual Amount"}
		]
		,uniqueArrays: [
			{array_name: 'disciplines_contractors', unique: "contractor_id"}
			,{array_name: 'disciplines_contractors_crews', unique: "crew_id"}
			,{array_name: 'project_extras', unique: "project_extras_id"}
		]
		,Ptemplate: {
			project_name: ""
			,project_draft_at: "date"
			,project_tendered_at: "date"
			,project_rejected_at: "date"
			,project_accepted_at: "date"
			,project_priority:  0
			,contract_name: ""
			,project_site_type: ""
			,project_suburb: ""
			,project_material_suburb: ""
			,project_start_date: "date"
			,project_dead_date: "date"
			,project_commissioning_date: "date"
			,project_final_acceptance: "date"
			,project_completion_marker: "date"
			,project_documents:  ""
			,project_deliverables: ""
			,project_completion_comments: ""
			,disciplines: [{
				discipline_name: ""
				,discipline_order: 0
				,discipline_contract_multiplier:0
				,discipline_priority: 0
				,discipline_work: 0
				,discipline_planned: 0
				,discipline_combination_limit: 0
				,discipline_completed: 0
				,discipline_harshness: 0
				,discipline_percent: 0
				,discipline_flow: ""
				,discipline_start_date: "date"
				,discipline_end_date: "date"
				,discipline_recovery: true
				,discipline_auto_assign_tasks: true
				,discipline_strict_start: true
				,discipline_strict_end: true
				,discipline_consistency: true
				,discipline_consolidate_start: true
			}]
			,project_items: [{
				distribution_material_name: ""
				,distribution_material_description: ""
				,distribution_amount: 0
				,distribution_batch: 0
				,distribution_container_name: ""
				,distribution_silo: true
				,distribution_ordered: ""
				,distribution_float: 0
				,distribution_stocked: ""
				,distribution_entity_name: ""
			}]
		}
		,Mtemplate: {
			distribution_material_name: ""
			,distribution_material_description: ""
			,distribution_amount: 0
			,distribution_batch: 0
			,distribution_container_name: ""
			,distribution_silo: true
			,distribution_ordered: ""
			,distribution_float: 0
			,distribution_stocked: ""
		}
		,Stemplate: {
			supplier_name: ""
			,supplier_locations: [{
				supplier_locations_location: ""
				,supplier_locations_regular_operating_hours: ""
				,supplier_locations_weekend_operating_hours: ""
				,supplier_locations_public_holiday_operating_hours: ""
				,supplier_locations_phone: ""
				,supplier_locations_email: ""
			}]
			,supplier_items: [{
				supplier_items_uom: ""
				,supplier_items_minimum_order: 0
				,supplier_items_name: ""
				,supplier_items_description: ""
				,supplier_items_cost: 0
				,supplier_items_batch: 0
				,supplier_items_batch_cost: 0
				,supplier_items_available: true
				,supplier_items_supply_time: 0
			}]
			,supplier_delivery: true,
		}
		,Wtemplate: {
			warehouse_name: ""
			,warehouse_location: ""
			,warehouse_operating_hours_regular: ""
			,warehouse_operating_hours_weekend: ""
			,warehouse_operating_hours_public_holiday: ""
			,warehouse_phone: ""
			,warehouse_email: ""
			,warehouse_items: [{
				distribution_material_name: ""
				,distribution_material_description: ""
				,distribution_amount: 0
				,distribution_batch: 0
				,distribution_container_name: ""
				,distribution_silo: true
				,distribution_ordered: ""
				,distribution_float: 0
				,distribution_stocked: ""
			}]
			,warehouse_delivery: true
		}
		,Otemplate: {
			order_name: ""
			,order_items_name: ""
			,order_items_description: ""
			,order_amount: 0
			,order_notes: ""
			,order_received: 0
			,order_items_cost: 0
			,order_supplier_name: ""
			,order_stock_location: ""
			,order_submission_date : "date"
			,order_eta: "date"
		}
	}

	let getfilters = function(button, marker){
		let datainput = null
		let selectedFilter = null
		switch (marker){
			case "Project":
				datainput = filterFunction.projectFilters
				selectedFilter = 'projectFilters'
				break;
			case "Project Item":
				datainput = filterFunction.projectIFilters
				selectedFilter = 'projectIFilters'
				break;
			case "Warehouse":
				datainput = filterFunction.warehouseFilters
				selectedFilter = 'warehouseFilters'
				break;
			case "Warehouse Item":
				datainput = filterFunction.warehouseIFilters
				selectedFilter = 'warehouseIFilters'
				break;
			case "Supplier":
				datainput = filterFunction.supplierFilters
				selectedFilter = 'supplierFilters'
				break;
			case "Supplier Item":
				datainput = filterFunction.supplierIFilters
				selectedFilter = 'supplierIFilters'
				break;
			case "Supplier Address":
				datainput = filterFunction.supplierLFilters
				selectedFilter = 'supplierLFilters'
				break;
			case "Order":
				datainput = filterFunction.orderFilters
				selectedFilter = 'orderFilters'
				break;
		}
		if (datainput == null){
			filterFunction.projectFilters = {
				filters: m.prop([])
				,operationalFilters: m.prop([])
				,changeNames: filterFunction.changeNames
				,uniqueArrays: filterFunction.uniqueArrays
				,template: filterFunction.Ptemplate
				,disabled: () => projects().length == 0
			}
			filterFunction.projectIFilters = {
				filters: m.prop([])
				,operationalFilters: m.prop([])
				,changeNames: filterFunction.changeNames
				,uniqueArrays: filterFunction.uniqueArrays
				,template: filterFunction.Mtemplate
				,disabled: () => ProjectItems()
					? ProjectItems().length == 0
					: true
			}
			filterFunction.warehouseFilters = {
				filters: m.prop([])
				,operationalFilters: m.prop([])
				,changeNames: filterFunction.changeNames
				,uniqueArrays: filterFunction.uniqueArrays
				,template: filterFunction.Wtemplate
				,disabled: () => warehouses().length == 0
			}
			filterFunction.warehouseIFilters = {
				filters: m.prop([])
				,operationalFilters: m.prop([])
				,changeNames: filterFunction.changeNames
				,uniqueArrays: filterFunction.uniqueArrays
				,template: filterFunction.Wtemplate.warehouse_items[0]
				,disabled: () => WarehouseItems()
					? WarehouseItems().length == 0
					: true
			}
			filterFunction.supplierFilters = {
				filters: m.prop([])
				,operationalFilters: m.prop([])
				,changeNames: filterFunction.changeNames
				,uniqueArrays: filterFunction.uniqueArrays
				,template: filterFunction.Stemplate
				,disabled: () => suppliers().length == 0
			}
			filterFunction.supplierIFilters = {
				filters: m.prop([])
				,operationalFilters: m.prop([])
				,changeNames: filterFunction.changeNames
				,uniqueArrays: filterFunction.uniqueArrays
				,template: filterFunction.Stemplate.supplier_items[0]
				,disabled: () => vm().supplier_items
					? vm().supplier_items.length == 0
					: true
			}
			filterFunction.supplierLFilters = {
				filters: m.prop([])
				,operationalFilters: m.prop([])
				,changeNames: filterFunction.changeNames
				,uniqueArrays: filterFunction.uniqueArrays
				,template: filterFunction.Stemplate.supplier_locations[0]
				,disabled: () => vm().supplier_locations
					? vm().supplier_locations.length == 0
					: true
			}
			filterFunction.orderFilters = {
				filters: m.prop([])
				,operationalFilters: m.prop([])
				,changeNames: filterFunction.changeNames
				,uniqueArrays: filterFunction.uniqueArrays
				,template: filterFunction.Otemplate
				,disabled: () => orders().length == 0
			}
			datainput = filterFunction[selectedFilter]
		}
		let gotfilters = datainput == null ? null : datainput.operationalFilters(
			filterView( datainput.template, datainput.filters(), datainput.changeNames, datainput.uniqueArrays, datainput.disabled, marker, data.scoped)
		)
		datainput != null ? datainput.filters( gotfilters.filters ) : null
		return gotfilters
			? gotfilters.generatedElements
			: null
	}

	let CONSTANTitems = data.scoped([])
	var masterILIST = function(mcreate){
		if ( mcreate ){
			CONSTANTitems(
				R.unnest(
					R.partition(
						(c) => c.created
						, activeMainTab() == 'Warehouses & Stock'
							? activeMTab() == 'Stocked'
								? stockedMaterials()
								: cancelledMaterials()
							: activeMainTab() == 'Project Distributions'
								? ProjectItems() || []
								: activeMainTab() == 'Transfer Items'
								|| activeMainTab() == 'Automated Items'
								|| activeMainTab() == 'Manage Orders'
									? []
									: vm().supplier_items
						)
				)
			)
		}
		return ListProcessing(
			CONSTANTitems()
			,activeMainTab() == 'Warehouses & Stock'
				? filterFunction.warehouseIFilters.filters()
				: activeMainTab() == 'Project Distributions'
					? filterFunction.projectIFilters.filters()
					: filterFunction.supplierIFilters.filters()
		)
	}

	let CONSTANTlocations = data.scoped([])
	let masterLLIST = function(mcreate){
		if ( mcreate ){
			CONSTANTlocations(
				R.unnest(
					R.partition(
						(c) => c.created
						,vm().supplier_locations
						)
				)
			)
		}
		return ListProcessing(
				CONSTANTlocations()
				,filterFunction.supplierLFilters.filters()
			)
	}


	prop.merge(
		[
			activeMTab
		]
	)
	.map(([]) =>
		activeWTab() == 'Warehouse Items'
		|| activeDTab() == 'Items'
		? masterILIST(true)
		: null
	)

	function validOrder(order){
		return editOrdersPermissions()
		MaterialIndex()[order.distribution_id()]
		&& MaterialIndex()[order.distribution_id()]
			.distribution_ordered() != 'Cancelled'
		&& !order_vm.order().find(
			(chr) =>
				chr.order_id()
				== order.order_id()
		)
	}


	const setOptions = function(value, prop, dataObject){

		const standard =
			Array.isArray(dataObject)
			? 0
			: Object.keys(dataObject)[0]

		const dtype = typeof dataObject[standard][prop] == 'function'


		value || value == false || value == '' || value == 0
			? (
				Array.isArray(dataObject)
					? dataObject
					: exposeObject(dataObject)
			)
			.forEach((i) => {
				i['created'] = true

				if( activeMainTab() == 'Manage Orders' ){
					!order_vm.orderUpdates()
					.find((o) =>
						o.order_id()
								== i.order_id()
					)
					&& order_vm.orderUpdates().push(i)
				}

				return dtype
					? i[prop](value)
					: i[prop] = value

			})
			: null

		return dtype
			? dataObject[standard][prop]()
			: dataObject[standard][prop]
	}

	const resetView =
		() => {
			viewtype(MainList)
			errors({})
			materials(
				materials()
					.filter((m) => !m.created)
					.concat(DeleteDItems())
			)
			materials(
				materials().concat(
					copy_items(
						R.differenceWith(
							(x, y) => uniqItem(x, uniqDItem) == uniqItem(y, uniqDItem)
							,originalMaterials()
							,materials()
						)
						,false
					)
				)
			)
			new_material({})
			SelectedDiscipline({})
			selected_pt({})
			project_vm({})
			edit(false)
			DeleteDItems([])
		}

	const editLink =
		(o, tabProp, setTab) => {

			if(setTab == 'Warehouse Items'){
				cloneWarehouse(o)
				masterILIST(true)

				project_vm({})
				transfer_object.to(null)
				transfer_object.from(null)
				edit(true)
				tabProp(setTab)
				viewtype(EditWarehouse)
			}

			if(setTab == 'Items'){
				cloneSupplier(o)
				masterLLIST(true)
				masterILIST(true)

				viewtype(EditSupplier)
				edit(true)
				tabProp(setTab)
			}
		}

	const addMaterialsButton =
		(attrs={}) =>
			m(`button`
				+ css`
					border-radius: 0.5em;
					background-color: white;
					padding: 0.7em 1em;
					border: solid 0.1px rgba(0,0,0,0.4);
					transition: 0.2s;
					opacity: 1;
				`
				.$hover(`
					opacity: 0.8;
				`)
				.$active(`
					opacity: 0.5;
				`)
				,R.merge(
					{
						onclick:
							() => {
								d_item_vm({})
								setnew_material()
							}
					}
					,attrs
				)
				, '+ Materials '
			)
	// const forceRedraw =
		prop.merge(
			[ errors
			, CONSTANTlocations
			, CONSTANTitems
			, warehouses
			, metaDataLoading
			, projects
			, materials
			]
		).map(function(){
			// eslint-disable-next-line no-undef
			return setTimeout(function(){
				m.redraw()
			}, 0)
		})

	return {check_preset, projects
		,suppliers
		,resources
		,data
		,vm
		,edit
		,resetForm
		,resetLForm
		,deleteWarehouse
		,check_new_material
		,makePreset
		,check_lnames
		,SaveSupplierDisabled
		,DeleteSDisabled
		,viewtype
		,item_vm
		,location_vm
		,check_inames
		,resetIForm
		,projects_tasks
		,items
		,transfer_object
		,materials
		,project_vm
		,warehouses
		,warehouse_vm
		,DeleteWDisabled
		,resetWForm
		,check_wnames
		,presets
		,containers
		,transfers
		,selected_pt
		,FilteredMaterialspt
		,d_item_vm
		,orders
		,order_vm
		,check_ordered
		,available_yards
		,make_items
		,new_material
		,consolidate
		,resetNewMaterial
		,check_order_vm
		,transfer_vm
		,copy_items
		,SelectedProject
		,SelectedDiscipline
		,preset_vm
		,deleteSupplier
		,container_vm
		,assessContainers
		,selected_preset
		,check_float
		,updatefloats
		,setnew_material
		,request:data.request
		,editWarehousePermissions
		,editSupplierPermissions
		,users
		,roles
		,permissions
		,ItemConsolidation
		,getfilters
		,filterFunction
		,organizations_disciplines
		,availableYardSelections
		,loading
		,masterILIST
		,masterLLIST
		,SItemDelete
		,DeletePending
		,DeleteDItems
		,DeletePItems
		,DeleteSItems
		,DeleteSLocations
		,containerIndex
		,supplierItemIndex
		,statProjectMaterials
		,MaterialIndex
		,transferCheck
		,deleteWStateProp
		,deleteSStateProp
		,api: data.api
		,baseProjectString
		,baseWarehouseString
		,basePresetString
		,ProjectItems
		,WarehouseItems
		,PresetItems
		,transferItems
		,savingProp
		,originalPresets
		,originalMaterials
		,refreshList
		,activeMainTab
		,cloneSupplier
		,cloneWarehouse
		,detailsPaneOpen
		,detailPaneInputs
		,supplierdetailPaneInputs
		,supplierdetailsPaneOpen
		,transfersFrom
		,transfersTo
		,originalMaterialIndex
		,errors
		,errorsMessage
		,editOrdersPermissions
		,editMaterialsPermissions
		,missingPermissions
		,transferToMaterialIndex
		,presetOptions
		,presetCloneOptions
		,presetsIndexByDiscipline
		,projectPresetsIndexByDiscipline
		,validOrder
		,originalOrders
		,copiedOrders
		,setOptions
		,completedProjects
		,metaDataLoading
		,SelectedProjectDisciplines
		,inverseProjectsList
		,checkMultipleProjects
		,resetView
		,editLink
		,addMaterialsButton
		,cancelledOrders
		,orderedOrders
		,lostOrders
		,projectIndex
		,stockedMaterials
		,cancelledMaterials
		,uploadmetadata
	}
}




/*
	The controller is the glue that sticks the model and intent together.
	It exposes the model and intent to the view by returning them.

	The controller "controls" how each layer interacts.
*/
function Main({ attrs: { data } }){

	let activeTab = data.scoped('Summary')
	let activeDTab = data.scoped('Distribution')
	let activeWTab = data.scoped('Warehouse Details')
	let activeOTab = data.scoped('Ordered')
	let activeMTab = data.scoped('Stocked')
	let model = Model(data, activeMTab, activeDTab, activeWTab)
	let intent = Intent(model)

	return {
		view(){
			return view({
				model, intent, activeTab, activeDTab, activeWTab, activeOTab, activeMTab
			})
		}
	}
}

/*
	The view provides the user ways to transfer their intent into really action within the system.
	The view accesses data from the model layer, and binds intent functions to user inputs.
*/


let view = function(ctrl){
	return m('div'+ css`
			min-height: 600px;
		`
		,ctrl.model.viewtype()(ctrl)
		,DetailsPane(
			ctrl.model.activeMainTab() =='Suppliers'
				? ctrl.model.supplierdetailsPaneOpen
				: ctrl.model.detailsPaneOpen

			, ctrl.model.activeMainTab() =='Suppliers'
				? ctrl.activeTab() == 'Outlets'
					? ctrl.model.location_vm().supplier_locations_location
						? ctrl.model.location_vm().supplier_locations_location()
						: ''
					: ctrl.model.item_vm().supplier_items_name
						? ctrl.model.item_vm().supplier_items_name()
						: ''
				: ctrl.model.new_material().distribution_material_name
					? ctrl.model.new_material().distribution_material_name()
					: ctrl.model.checkMultipleProjects().length
					? 'Project Material Options'
					: ''

			, () =>
				ctrl.model.activeMainTab() =='Suppliers'
				? ctrl.model.supplierdetailPaneInputs(
					ctrl.activeTab() == 'Outlets'
						? ctrl.model.location_vm()
						: ctrl.model.item_vm()
					,ctrl
				)
				: ctrl.model.new_material().distribution_id
				? ctrl.model.detailPaneInputs(
					ctrl.model.new_material()
					,ctrl
				)
				: ctrl.model.detailPaneInputs(
					ctrl.model.d_item_vm()
					,ctrl
				)
		)
	)
}



function MainList(ctrl){
	return m('div.harth.mb6',[
		ctrl.model.editWarehousePermissions() || ctrl.model.editMaterialsPermissions()
			? elements.tabset(['Warehouses & Stock', 'Suppliers', 'Project Distributions', 'Manage Orders', 'Transfer Items', 'Automated Items'], ctrl.model.activeMainTab)
			: elements.tabset(['Warehouses & Stock', 'Suppliers', 'Project Distributions', 'Manage Orders'], ctrl.model.activeMainTab)

		,m('br')
			,ctrl.model.activeMainTab() == 'Warehouses & Stock'
			? [
				m('br')
				,ctrl.model.editWarehousePermissions()
				? elements.list([
						m("button.btn.", {
							disabled: false
							,onclick: () => {
								ctrl.model.project_vm({})
								ctrl.model.warehouse_vm(ctrl.model.resetWForm())
								ctrl.model.edit(false)
								ctrl.model.transfer_object.to(null)
								ctrl.model.transfer_object.from(null)
								ctrl.activeWTab('Warehouse Details')
								ctrl.model.viewtype(EditWarehouse)
							}
							,title: "Create a warehouse or a stockyard for ordered materials"
						},"Create")
						,elements.confirmDelete(
							ctrl.intent.deleteWarehouse
							,ctrl.model.warehouse_vm().pending
							,() => !ctrl.model.warehouse_vm().warehouse_name
								|| ctrl.model.DeleteWDisabled(true)
							,ctrl.model.deleteWStateProp
						)
					])
				: elements.list([
						m("button.btn.", {
							disabled: ctrl.model.warehouses().length > 0 ? ctrl.model.warehouse_vm().warehouse_name == null ? true : false : true
							,onclick: () => {
								ctrl.model.project_vm({})
								ctrl.model.viewtype(EditWarehouse)
								ctrl.model.edit(true)
								ctrl.activeWTab('Warehouse Details')
								ctrl.model.transfer_object.to(null)
								ctrl.model.transfer_object.from(null)
							}
							,title: "View a warehouse's details and stocked materials"
						},"View")
					])
				,ctrl.model.getfilters(null, "Warehouse")
				,ctrl.model.DeleteWDisabled()
				,elements.table(ctrl.model.data, ['Manage', 'Warehouse', 'Address', 'Operating Hours', 'Delivery', 'Phone', 'Email'],
					R.unnest(
						// R.partition(
						// 	(c) => c.created,
							ListProcessing(
								ctrl.model.warehouses()
								,ctrl.model.filterFunction.warehouseFilters.filters()
								, "warehouse_name"
							)
						// )
					).map(
						function(warehouse){
							return [
							elements.checkbox({
								onchange(event){
									if(event.currentTarget.checked == true){
										ctrl.model.cloneWarehouse(warehouse)
										ctrl.model.masterILIST(true)
									}
									else if (event.currentTarget.checked == false){
										ctrl.model.warehouse_vm({})
									}
								}
								,checked: ctrl.model.warehouse_vm().warehouse_id == null ? false : ctrl.model.warehouse_vm().warehouse_id() == warehouse.warehouse_id() ? true : false
								,disabled: ctrl.model.warehouse_vm().warehouse_id == null ? false : ctrl.model.warehouse_vm().warehouse_id() == warehouse.warehouse_id() ? false : true
							})
							,m('a'
								,{
									onclick: () => ctrl.model.editLink(warehouse, ctrl.activeWTab, 'Warehouse Items')
									,title: "Edit a warehouse"
								}
								,warehouse.warehouse_name()
							)
							,warehouse.warehouse_location()
							,elements.list([warehouse.warehouse_operating_hours_regular(), warehouse.warehouse_operating_hours_weekend(), warehouse.warehouse_operating_hours_public_holiday()])
							,warehouse.warehouse_delivery() ? 'Yes' : 'No'
							,warehouse.warehouse_phone()
							,warehouse.warehouse_email()
						,]}
					)
				)

				,ctrl.model.warehouses().length == 0
					? ctrl.model.loading()
						? elements.centeredSpinner()
						: ctrl.model.data.readWarehousePermissions()
							? elements.alert('info',`Create warehouses to track stored materials`)
							: elements.alert('info',`Requires Warehouse Permissions`)
					: null
			]
			: ctrl.model.activeMainTab() == 'Project Distributions'
			? [
				m('br')
				,elements.list([
					m("button.btn.btn", {
						onclick: () => {
							ctrl.model.checkMultipleProjects().length
								? ctrl.model.checkMultipleProjects([])
								: ctrl.model.checkMultipleProjects(ctrl.model.projects())
							}
							}
						,'Options'
						)
					])

				,m('br')
				,elements.list([ctrl.model.getfilters(null, "Project")])
				,elements.table(ctrl.model.data,
					[
						 'Project'
						, 'Material Ordered %'
						, 'Estimated Value $'
						, 'Ordered $'
						, 'Apply Automated Items'
						, 'Stocked Orders'
					]
					,R.unnest(
						// R.partition(
						// 	(c) => c.created,
							ListProcessing(
								ctrl.model.projects()
								,ctrl.model.filterFunction.projectFilters.filters()
								, "project_name"
							)
						// )
					).map(
						function(project) {
							return [
								m('a'
									,{
										title: 'Check, set or order project materials'
										,onclick: () => {
											ctrl.model.SelectedProject(project)
											ctrl.model.project_vm(project)
											ctrl.model.selected_pt(project)
											ctrl.model.edit(true)
											ctrl.model.warehouse_vm({})
											ctrl.model.viewtype(EditDistribution)
											ctrl.activeDTab('Distribution')
											ctrl.model.masterILIST(true)
											ctrl.model.checkMultipleProjects([])
										}
									}
									, project.project_name()
								)

								,project.orderedStat
								,project.materialValue
								,project.orderedValue

								,m('button.btn.btn-secondary'
									,{
										disabled: !ctrl.model.editMaterialsPermissions()
											|| ctrl.model.presets().length == 0
											|| !project.disciplines.some((d) => (d.outstandingMaterials || []).length)
										,style: {
											backgroundColor: 'transparent'
											,border: 'solid 1px #3380c2'
											,position: 'relative'
											,top: '-0.1em'
											,height: '2.9em'
											,width: '5em'
											,color: '#434aa3'
											,textDecoration: 'underline'
										}
										,onclick: () => {
											return ctrl.intent.applyPreset(project)
												.then(ctrl.intent.sendAllDistriution)
										}
										,title: `This will apply automated items to ` + project.project_name()
									}
									, "Apply"
								)

								, m('stocked-orders-breakdown'
									+ css`
										display: grid;
										gap: 0.5em;
									`
									,[
										R.partition(
											R.prop('order_eta')
											,R.uniqBy(
												(a) => a.order_name + a.distribution_stocked()
												, ctrl.model.FilteredMaterialspt(project)
											)
										)
									]
									.filter(
										xs => xs.some( xs => xs.length != 0 )
									)
									.map(
										xs => xs
										.map(
											r => r.map(
												s => ({
													s
													,alert:
														s.order_eta
														? new Date(s.order_eta).getTime() > new Date(s.requiredby || s.discipline_start_date || s.project_start_date).getTime()
														: !s.order_eta && new Date(s.requiredby || s.discipline_start_date || s.project_start_date).getTime() < new Date().getTime()
															|| new Date(s.order_eta).getTime() > new Date(s.requiredby || s.discipline_start_date || s.project_start_date).getTime()

												})
											)
										)
										.map(
											(r, i) => [
												r
												, !i
												, !i ? 'Received' : 'Outstanding'
												, r.every( x => !x.alert )
											]
										)
										.map(([r, isReceived, label, noAlerts]) =>
											m('.stocked-order-section'
												+ css`
													background-color: rgb(247, 247, 247);
													box-shadow: 0px 5px 10px -4px #0000001f;
												`
												,m('.stocked-orders-header'
													+ css`
														padding: 1em 1em 0em 1em;
													`
													,m('.stocked-order-label'
														+ css`
															display: grid;
															justify-content: space-between;
															grid-template-columns: auto auto;
														`
														, m('b',
															r.length == 0
																? 'No Orders ' + label
																: label + ':'
														)
														,r.length > 0
															? m(''
																+ css`
																	background-color: #484848;
																	padding: 0.3em 0.5em;
																	border-left: solid 0.2em ${ noAlerts ? `#0688f7` : 'red'};
																	border-radius: 0.2em;
																	box-shadow: -1px 4px 7px 0px #0000001f;
																	color: white;
																	position: relative;
																`
																, r.length + ' Order'
																	+ (r.length > 1 ? 's' : '')
															)
															: null
													)
												)
												,m('.stock-orders-content'
													+ css`
														padding: ${ r.length == 0 ? `0.5em` : `1em`};
													`
													,r
													.map( ({ s, alert }) =>
														m('.stocked-orders' + css`
															display: grid;
															align-content: center;
															gap: 0.4em;
															align-items: center;
															grid-template-columns: 5em 19em 7em;
															justify-content: space-between;
															padding: 1em 0em;
														`, [
															m('b'
																+ css`word-break: break-all`
																, {
																	title:
																		s.order_name
																}
																, s.order_name
															)
															,m('span'
																+ css`
																	display: grid;
																	grid-auto-flow: column;
																	gap: 1em;
																`
																,s.distribution_stocked()
																	.split(' - ')
																	.map( x => m('span', x))
															)
															,m('span'
																+ css`text-align: right;`
																+ (
																	alert
																	? '.dark-red'
																	: ''
																)
																,( isReceived ? '' : 'Required by ')
																+ (
																	s.order_eta
																	? moment(s.order_eta).format("ll")
																	: moment(s.requiredby || s.discipline_start_date || s.project_start_date).format("ll")
																)
															)
														])
													)
												)
											)
										)
									)
									.concat([
										null
										// m('b'
										// 	+ css`
										// 		background-color: rgb(247, 247, 247);
										// 		box-shadow: 0px 5px 10px -4px #0000001f;
										// 		padding: 1em;
										// 	`
										// 	, 'No Materials'
										// )
									])
									.shift()
								)

						]}
					)
				)
				,ctrl.model.data.schedule_id() == null
					? elements.alert('info',`Select a schedule to include project options`)
					: ctrl.model.projects().length == 0
						? ctrl.model.loading()
							? elements.centeredSpinner()
							: ctrl.model.data.readProjectPermissions()
								? elements.alert('info',`There aren't any projects in this schedule`)
								: elements.alert('info',`Requires Project Permissions`)
					: null

			]
			: ctrl.model.activeMainTab() == 'Suppliers'
			? [
				m('br')
				,ctrl.model.editSupplierPermissions()
				? elements.list([
						m("button.btn.", {
							disabled: false
							,onclick: () => {
								ctrl.model.vm({})
								ctrl.model.location_vm({})
								ctrl.model.item_vm({})
								ctrl.model.vm(ctrl.model.resetForm())
								ctrl.model.masterLLIST(true)
								ctrl.model.masterILIST(true)
								ctrl.model.viewtype(EditSupplier)
								ctrl.model.edit(false)
								ctrl.activeTab('Outlets')
							}
							,title: "Create a supplier, their details and products to track against warehouses and projects"
						},"Create")
						,elements.confirmDelete(
							ctrl.intent.deleteSupplier
							,ctrl.model.vm().pending
							,() => ctrl.model.DeleteSDisabled(true)
							,ctrl.model.deleteSStateProp
						)
				])
				: elements.list([
						m("button.btn.", {
							disabled: ctrl.model.suppliers().length > 0 ? ctrl.model.vm().supplier_name == null ? true : false : true
							,onclick: () => {
								return Promise.all([
									ctrl.model.viewtype(EditSupplier)
									,ctrl.model.edit(true)
									,ctrl.activeTab('Outlets')
								])
									// .then(function(){
									// 	ctrl.model.masterLLIST(true)
									// 	ctrl.model.masterILIST(true)
									// })
							}
							,title: "View a supplier's details and products"
						},"View")
					])
			,ctrl.model.getfilters(null, "Supplier")
			,ctrl.model.DeleteSDisabled()
			,elements.table(ctrl.model.data, ['Manage', 'Supplier', 'Outlets', 'Operating Hours', 'Delivery'],
					R.unnest(
						// R.partition(
						// 	(c) => c.created,
							ListProcessing(
								ctrl.model.suppliers()
								,ctrl.model.filterFunction.supplierFilters.filters()
								, "supplier_name"
							)
						// )
					).map(
						function(supplier) {
							return [
							elements.checkbox({
								onchange(event){
									if(event.currentTarget.checked == true){
										ctrl.model.cloneSupplier(supplier)
										ctrl.model.masterLLIST(true)
										ctrl.model.masterILIST(true)
									}
									else if (event.currentTarget.checked == false){
										ctrl.model.vm({})
									}
								}
								,checked: ctrl.model.vm().supplier_id == null ? false : ctrl.model.vm().supplier_id() == supplier.supplier_id() ? true : false
								,disabled: ctrl.model.vm().supplier_id == null ? false : ctrl.model.vm().supplier_id() == supplier.supplier_id() ? false : true
							})
							,m('a'
								,{
									onclick: () => ctrl.model.editLink(supplier, ctrl.activeTab, 'Items')
									,title: "Edit a supplier's details and products"
								}
								,supplier.supplier_name()
							)

							,supplier.supplier_locations.length > 0
								? R.join(
									' | '
									,R.pipe(
										R.map( R.invoker(0, 'supplier_locations_location'))
										,R.uniq
									)(supplier.supplier_locations)
								)
								: null
							,supplier.supplier_locations.length > 0
								? elements.list([
										supplier.supplier_locations[0].supplier_locations_regular_operating_hours()
										, supplier.supplier_locations[0].supplier_locations_weekend_operating_hours()
										, supplier.supplier_locations[0].supplier_locations_public_holiday_operating_hours()
								])
								: null
							,supplier.supplier_delivery() ? 'Yes' : 'No'
						]}
					)
			)
			,ctrl.model.suppliers().length == 0
					? ctrl.model.loading()
					? elements.centeredSpinner()
						: ctrl.model.data.readSupplierPermissions()
							? elements.alert('info',`Suppliers are used to catalogue materials that can be used to stock projects and warehouses`)
							: elements.alert('info',`Requires Supplier Permissions`)
				: null
			]
			: ctrl.model.activeMainTab() == 'Manage Orders'
			? [
				m('br')
				,ctrl.model.editOrdersPermissions()
					? elements.list([

						!ctrl.model.order_vm.order().length
						&& !ctrl.model.order_vm.orderUpdates().length
							? m('button.btn', {
								disabled: true
								,title: 'Save updated details'
							}, 'Save')
						: elements.action('Save', ctrl.intent.sendOrders, ctrl.model.savingProp)

						,m("button.btn.btn-warning", {
							disabled: ctrl.model.order_vm.order().length == 0
								&& ctrl.model.order_vm.orderUpdates().length == 0
							,onclick: () => {

								ctrl.model.order_vm.order([])
								ctrl.model.order_vm.orderUpdates([])
								ctrl.model.errors({})

								ctrl.model.orders(
									ctrl.model.orders()
									.filter((o) => !o.created)
								)
								ctrl.model.orders(
									[].concat(
										ctrl.model.orders()
										,ctrl.model.copiedOrders(
											R.differenceWith(
												(x, y) => x.order_id() == y.order_id()
												,ctrl.model.originalOrders()
												,ctrl.model.orders()
											)
										)
									)
								)
							}
							,title: "Discard all the details that have been changed"
						},"Discard Changes")
					])
					: null

				,ctrl.model.getfilters(null, "Order")
				// ,[
				// 	ctrl.model.order_vm.order()
				// 	,ctrl.model.order_vm.orderUpdates()
				// ]
				// .filter((oList) => oList.length )
				// .map((oList, oIndex) => {
				// 	const summation =
				// 		!oList.length
				// 			? ``
				// 			: (
				// 				oIndex == 1
				// 				? `Cancel `
				// 				: `Update `
				// 			)
				// 			+ oList.length
				// 			+ (
				// 				oList.length == 1
				// 					? ` Order`
				// 					: ` Orders`
				// 			)

				// 	return m(
				// 		'label.control-label'
				// 		,summation
				// 	)
				// })

				,m('br')

				,elements.tabset(['Ordered', 'Cancelled', 'Lost'], ctrl.activeOTab)

				,m('br')

				,ctrl.model.cancelledOrders().length == 0 && ctrl.activeOTab() == 'Cancelled'
				|| ctrl.model.orderedOrders().length == 0 && ctrl.activeOTab() == 'Ordered'
				|| ctrl.model.lostOrders().length == 0 && ctrl.activeOTab() == 'Lost'
				? ctrl.model.data.readOrderPermissions()
					? elements.alert(`info`, `Orders are verified items sent to the suppliers for delivery to the business`)
					: elements.alert(`info`, `Requires Order Management Permissions`)
				: ctrl.activeOTab() != 'Lost'
					? elements.table(
						ctrl.model.data
						,[
							ctrl.activeOTab() == 'Cancelled' ? 'Delete' : 'Cancel'
							, 'Created Date'
							, 'Order Number'
							, 'Supplementing'
							, 'Item Name'
							, 'Order Amount'
							, 'Received Amount'
							, 'Split'
							, 'Supplier'
							, 'Delivery Address'
							, 'Expected Arrival'
							, 'Notes'
							, 'Files'
							, ''
						]
						,R.unnest(
							ListProcessing(
								ctrl.activeOTab() == 'Cancelled' ? ctrl.model.cancelledOrders() : ctrl.model.orderedOrders()
								,ctrl.model.filterFunction.orderFilters.filters()
							)
						)
						.map(
							(order, oi) => [
								ctrl.model.editOrdersPermissions()
									? !ctrl.model.MaterialIndex()[order.distribution_id()]
									|| ctrl.model.MaterialIndex()[order.distribution_id()].distribution_ordered() == 'Cancelled'
									? elements.confirmDelete(
										() => ctrl.intent.deleteOrder(order)
										,R.F
										,R.F
										,R.T
										,null
										,elements.trashBin()
										,elements.trashBin()
										,R.F
										,R.F
										,R.T
									)
									: elements.checkbox({
										onchange(event){
											if(event.currentTarget.checked == true){
												ctrl.model.order_vm.order().push(order)
											}
											else if (event.currentTarget.checked == false){
												let index = R.findIndex(
													function(chr) { return chr.order_id() == order.order_id() },
															ctrl.model.order_vm.order()
												)
												ctrl.model.order_vm.order().splice(index, 1)
											}
										}
										,checked: R.findIndex(
													function(chr) { return chr.order_id() == order.order_id() },
															ctrl.model.order_vm.order()
												) >= 0
										,disabled:  R.find(	function(chr) {
															return chr.order_id() == order.order_id() },
																	ctrl.model.order_vm.orderUpdates()
													) != null
									})
								: null

								,moment( order.order_submission_date() ).format("ll")

								,order.order_name()

								,!ctrl.model.MaterialIndex()[order.distribution_id()]
								|| ctrl.model.MaterialIndex()[order.distribution_id()].distribution_ordered() == 'Cancelled'
								? 'Item Deleted '
								: !ctrl.model.projectIndex()[ctrl.model.MaterialIndex()[order.distribution_id()].project_id]
								&& ctrl.model.MaterialIndex()[order.distribution_id()].project_id
								? 'Closed Project - ' + ctrl.model.MaterialIndex()[order.distribution_id()].distribution_entity_name()
								: m('a'
									,{
										title: 'Check, set or order materials for this project or warehouse'
										,onclick: () => {

											if( ctrl.model.MaterialIndex()[order.distribution_id()].project_id ){

												const project =
													ctrl.model.projects().find((p) => p.project_id() == ctrl.model.MaterialIndex()[order.distribution_id()].project_id)

												ctrl.model.SelectedProject(project)
												ctrl.model.project_vm(project)
												ctrl.model.selected_pt(project)
												ctrl.model.edit(true)
												ctrl.model.warehouse_vm({})
												ctrl.model.viewtype(EditDistribution)
												ctrl.activeDTab('Distribution')
												ctrl.model.activeMainTab('Project Distributions')
												ctrl.model.masterILIST(true)
												ctrl.model.checkMultipleProjects([])

											}
											else if( ctrl.model.MaterialIndex()[order.distribution_id()].warehouse_id ){

												const warehouse =
													ctrl.model.warehouses().find((p) => p.warehouse_id() == ctrl.model.MaterialIndex()[order.distribution_id()].warehouse_id)

												ctrl.model.cloneWarehouse(warehouse)
												ctrl.model.project_vm({})
												ctrl.model.transfer_object.to(null)
												ctrl.model.transfer_object.from(null)
												ctrl.model.edit(true)
												ctrl.activeWTab('Warehouse Items')
												ctrl.model.activeMainTab('Warehouses & Stock')
												ctrl.model.masterILIST(true)
												ctrl.model.viewtype(EditWarehouse)

											}
										}
									}
									, ctrl.model.MaterialIndex()[order.distribution_id()].distribution_entity_name()
								)

								,order.order_items_name()

								,ctrl.model.validOrder(order)
									? Pencil(
										() => order.order_amount()
										,() =>
											m(
												NumberInput
												,R.merge(
													ctrl.model.data
													,{
														errors: ctrl.model.errors
														,errorLabel: order.order_name() + ' Order Amount'
													}
												)
												,{
													prop: (value) => ctrl.model.setOptions(value, 'order_amount', [order])
													,attrs: {
														min: 0
													}
												}
											)
										)
										: order.order_amount()

								,Pencil(
									() => order.order_received()
									,() =>
										m(
											NumberInput
											,R.merge(
												ctrl.model.data
												,{
													errors: ctrl.model.errors
													,errorLabel: order.order_name() + ' Order Recieved'
												}
											)
											,{
												prop: (value) => ctrl.model.setOptions(value, 'order_received', [order])
												,attrs: {
													min: 0
												}
											}
										)
								)

								,order.order_received() < order.order_amount()
								&& order.order_received() > 0
								&& ctrl.model.MaterialIndex()[order.distribution_id()]
								&& ctrl.model.MaterialIndex()[order.distribution_id()].distribution_ordered() != 'Cancelled'
								? m('a'
									, {
										title: "Split this order into 2, the resulting order will be for the remaining un-recieved items"
										,onclick: () =>  ctrl.intent.transferItems({orders:[{order:order, listindex:oi+1}], operation: 'splitorders'})
									}
									, 'Split'
								)
								: ''

								,order.order_supplier_name()

								,ctrl.model.validOrder(order)
									? Pencil(
										() => order.order_stock_location()
										,() =>
												autocomplete.all(
													ctrl.model.availableYardSelections
													,(value) => ctrl.model.setOptions(value, 'order_stock_location', [order])
													,'storage_location'
													, () => ({})
												)
										)
										: order.order_stock_location()

								,ctrl.model.validOrder(order)
									? Pencil(
										() => order.order_eta()
											? moment( order.order_eta() ).format("ll")
											: ''
										,() =>
											elements.dateInput(
												(value) => ctrl.model.setOptions(value, 'order_eta', [order])
											)
									)
									: ctrl.model.MaterialIndex()[order.distribution_id()]
										.distribution_ordered() == 'Cancelled'
											? 'Cancelled'
											: order.order_eta()
											? moment( order.order_eta() ).format("ll")
											: ''
								,Pencil(
									() => order.order_notes()
									,() =>
										elements.textInput(
											(value) => ctrl.model.setOptions(value, 'order_notes', [order])
										)
								)

								,[
									m('button.btn.btn-secondary'
										+css`
											backgroundColor: transparent;
											position: relative;
											top: -0.1em;
											height: 2.9em';
											width: 5em';
											color: #434aa3;
										`
										,{
											onclick: () => order.addFiles = !order.addFiles
										}
										, order.addFiles ? 'Minimize' : !order.filecount ? 'Add' :  order.filecount
									)

									,order.addFiles
									? m(HarthUppy, {
										getFields: () => ctrl.model.uploadmetadata({only:{order_id: order.order_id()}})
										, data: ctrl.model.data
									})
									: null
								]
							]
						)
					)
					: elements.table(
						ctrl.model.data
						,[
							'Delete'
							, 'Transfer'
							, 'Created Date'
							, 'Order Number'
							, 'Supplementing'
							, 'Item Name'
							, 'Order Amount'
							, 'Received Amount'
							, 'Supplier'
							, 'Delivery Address'
							, 'Expected Arrival'
							, 'Notes'
						]
						,R.unnest(
							ListProcessing(
								ctrl.model.lostOrders()
								,ctrl.model.filterFunction.orderFilters.filters()
							)
						)
						.map(
							(order) => [

								ctrl.model.editOrdersPermissions()
									? m('button'
										+ css`
											border-radius: 0.2em;
											background-color: white;
											padding: 0.3em;
											border: solid 0.1px rgba(0,0,0,0.1);
										`
										, {
											onclick: () => ctrl.intent.deleteOrder(order)
											,disabled: false
											,title: "Delete this order record"
										}
										,'Delete'
									)
									: null


								,autocomplete.strict(
									ctrl.model.transfers
									,R.when(Boolean, function(v){
										const item =
											ctrl.model.materials().find((o) =>
												o.distribution_id()
												== order.distribution_id()
											)
										item.distribution_entity_id(v.transfer_id)
										item.distribution_entity_name(v.transfer_name)

										ctrl.model.order_vm.orderUpdates().push(order)
									})
									,'transfer_name'
									,() => ({
										disabled: ctrl.model.transfersFrom().length == 0
									})
								)

								,moment( order.order_submission_date() ).format("ll")
								,order.order_name()

								,!ctrl.model.MaterialIndex()[order.distribution_id()]
								|| ctrl.model.MaterialIndex()[order.distribution_id()].distribution_ordered() == 'Cancelled'
								? 'Item Deleted '
								: ctrl.model.MaterialIndex()[order.distribution_id()].distribution_entity_name()

								,order.order_items_name()
								,order.order_amount()
								,order.order_received()
								,order.order_supplier_name()
								,order.order_stock_location()
								,order.order_eta()
								,order.order_notes()
							]
						)
					)
			]
			: ctrl.model.activeMainTab() == 'Automated Items'
			? EditPreset(ctrl)
			: TransferView(ctrl)

	])

}




function EditSupplier(ctrl){
	return m('div.harth.mb6',[
		m('br')
		,m('h4','Create or Edit Supplier Details')
		,m('hr')
		,elements.list([
			ctrl.model.edit()
				? elements.back(
					"Go back to the main view for projects, suppliers and warehouses"
					,() => {
							ctrl.model.errors({})
						ctrl.model.edit(false)
							ctrl.model.vm({})
						ctrl.model.viewtype(MainList)
						ctrl.model.DeleteSLocations([])
						ctrl.model.DeleteSItems([])
							ctrl.activeTab('Outlets')

						ctrl.model.location_vm({})
						ctrl.model.item_vm({})
					}
					,{disabled: ctrl.model.savingProp()}
				)
				: m("button.btn.", {
					disabled: ctrl.model.savingProp()
					,onclick: () => {
							ctrl.model.errors({})
						ctrl.model.edit(false)
							ctrl.model.vm({})
						ctrl.model.viewtype(MainList)
						ctrl.model.DeleteSLocations([])
						ctrl.model.DeleteSItems([])
							ctrl.activeTab('Outlets')
					}
					,title: "Discard this supplier and all information"
				},"Discard")

				,ctrl.model.SaveSupplierDisabled(true)
				|| ctrl.model.check_lnames(true)
				|| ctrl.model.check_inames(true)
				|| identical(
					ctrl.model.suppliers().find((s) =>
						s.supplier_id()
						== ctrl.model.vm().supplier_id()
					)
					,ctrl.model.vm()
				)

			|| !ctrl.model.editSupplierPermissions()
				? m('button.btn', {disabled: true}, 'Save')
				: elements.action('Save', ctrl.intent.sendSupplier, ctrl.model.savingProp)

			,ctrl.model.edit()
				? m("button.btn.btn-warning", {
					disabled:
						!ctrl.model.editSupplierPermissions()
						|| ctrl.model.savingProp()
						|| identical(
							ctrl.model.suppliers().find((s) =>
								s.supplier_id()
								== ctrl.model.vm().supplier_id()
							)
							,ctrl.model.vm()
						)
					,onclick: () => {
						ctrl.model.errors({})
						ctrl.model.item_vm({})
						ctrl.model.location_vm({})
						ctrl.model.DeleteSItems([])
						ctrl.model.DeleteSLocations([])
						ctrl.model.cloneSupplier(
							ctrl.model.suppliers().find((s) =>
								s.supplier_id()
								== ctrl.model.vm().supplier_id()
							)
						)
						ctrl.model.masterILIST(true)
						ctrl.model.masterLLIST(true)

					}
					,title: "Discard all the details that have been changed"
				},"Discard Changes")
				: null
		])
		,m('br')
		,elements.list([
			m('label.control-label', 'Supplier Name',
				elements.textInput( ctrl.model.vm().supplier_name, {disabled: ctrl.model.editSupplierPermissions() == false})
			)
			,elements.labelCheckbox({
				 label: 'Delivery'
				, prop: ctrl.model.vm().supplier_delivery
				, attrs: {
					checked: ctrl.model.vm().supplier_delivery()
					,disabled: ctrl.model.editSupplierPermissions() == false
					,title: "Does this supplier deliver"
				}
			})
		])
		,m('br')
		,ctrl.model.SaveSupplierDisabled()

		,ctrl.model.editSupplierPermissions()
			? elements.tabset(['Outlets', 'Items'], ctrl.activeTab)
			: elements.tabset(['Outlets'], ctrl.activeTab)

		,m('br')
		,ctrl.model.metaDataLoading()
			? elements.centeredSpinner()
			: ctrl.activeTab() == 'Outlets'
			? [
				m('br')

				,elements.list([
					m('h4','Supplier Outlets')
					,ctrl.model.editSupplierPermissions()
						? m(`button`
							+ css`
								border-radius: 0.5em;
								background-color: white;
								padding: 0.3em;
								border: solid 0.1px rgba(0,0,0,0.4);
								width: 5em;
								height: 2.9em;
							`
							,{
								disabled: !!ctrl.model.check_lnames(true)
								,onclick: () => {
									let newL = ctrl.model.resetLForm()
									ctrl.model.vm().supplier_locations.unshift(newL)
									ctrl.model.location_vm({})
									ctrl.model.masterLLIST(true)
									ctrl.model.location_vm(newL)
								}
								,title: "Create a supplier's outlet"
							}
							, '+ Outlet '
						)
						: null
				])

				,m('br')

				,ctrl.model.getfilters(null, "Supplier Address")
				,ctrl.model.check_lnames()
				,ctrl.model.masterLLIST().length != 0
					? elements.table(ctrl.model.data, ['', 'Supplier Outlets', 'Regular Operating Hours', 'Weekend Operating Hours', 'PH Operating Hours', 'Ph', 'Email'],
						ctrl.model.masterLLIST().map(
							(sLocation) => [
									ctrl.model.editSupplierPermissions()
									? ctrl.intent.SupplierLocationsButton(sLocation)
										: ''
								,sLocation.supplier_locations_location()
								,sLocation.supplier_locations_regular_operating_hours()
								,sLocation.supplier_locations_weekend_operating_hours()
								,sLocation.supplier_locations_public_holiday_operating_hours()
								,sLocation.supplier_locations_phone()
								,sLocation.supplier_locations_email()
							]
						)
					)
				: ctrl.model.vm().supplier_locations.length == 0
					? elements.alert('info',`Create Supplier Outlets to track potential pick up locations of required materials`)
					: null
			,]
			: [
				m('br')

				,elements.list([
					m('h4','Supplier items')
					,ctrl.model.editSupplierPermissions()
						? m(`button`
							+ css`
								border-radius: 0.5em;
								background-color: white;
								padding: 0.3em;
								border: solid 0.1px rgba(0,0,0,0.4);
								width: 5em;
								height: 2.9em;
							`
							,{
								disabled: !!ctrl.model.check_inames(true)
								,onclick: () => {
									let newI = ctrl.model.resetIForm()
									ctrl.model.vm().supplier_items.unshift(newI)
									ctrl.model.item_vm({})
									ctrl.model.masterILIST(true)
									ctrl.model.item_vm(newI)
								}
								,title: "Create a product of this supplier"
							}
							, '+ Item '
						)
						: null
				])
				,m('br')

				,ctrl.model.getfilters(null, "Supplier Item")
				,ctrl.model.check_inames()
				,ctrl.model.masterILIST().length != 0
					? scrollableTable.advanced({
						scoped: ctrl.model.data.scoped
						,resize$: ctrl.model.data.resize$
						,rows:
							ctrl.model.masterILIST().map((item) => ({
								key: item.key
								,i: item
								,selection: ctrl.intent.SupplierItemsButton(item)
								,data: {
									'Item Name': item.supplier_items_name()
									,'Description': item.supplier_items_description()
									,'Cost Per Unit': item.supplier_items_cost()
									,'Batch Qty': item.supplier_items_batch()
									,'Cost per Batch': item.supplier_items_batch_cost()
									,'Availability': item.supplier_items_available() ? "Yes" : "No"
									,'Supply Time': item.supplier_items_supply_time()
									,'UoM': item.supplier_items_uom()
									,'Min. Order': item.supplier_items_minimum_order()
								}
							}))
					})
					: ctrl.model.vm().supplier_items.length == 0
						? elements.alert(
							'info'
							, `Enter the items this supplier offers to consume and track them against projects and warehouses`
						)
						: null
			]
	])
}



function EditDistribution(ctrl){
	return m('div.harth.mb6',[
		m('br')
		,m('h4', ctrl.model.project_vm().project_name() + ' - Material Distribution' )

		,m('hr')
		,elements.list([
			elements.back(
				"Go back to the project list"
				,ctrl.model.resetView
			)
			,m("button.btn.btn-warning", {
				disabled: identical(
					ctrl.model.baseProjectString()
					,ctrl.model.ProjectItems()
				) || !ctrl.model.editMaterialsPermissions()
				,onclick: () => {
					ctrl.model.errors({})
					ctrl.model.materials(
						ctrl.model.materials()
							.filter((m) => !m.created)
							.concat(ctrl.model.DeleteDItems())
					)
					ctrl.model.materials(
						ctrl.model.materials().concat(
							ctrl.model.copy_items(
								R.differenceWith(
									(x, y) => uniqItem(x, uniqDItem) == uniqItem(y, uniqDItem)
									,ctrl.model.originalMaterials()
									,ctrl.model.materials()
								)
								,false
							)
						)
					)
					ctrl.model.DeleteDItems([])
					ctrl.model.selected_pt(ctrl.model.selected_pt())
					ctrl.model.masterILIST(true)
					ctrl.model.d_item_vm({})
				}
				,title: "Discard all the details that have been changed"
			},"Discard Changes")
			,ctrl.model.editMaterialsPermissions()
			&& !identical(
				ctrl.model.baseProjectString()
				,ctrl.model.ProjectItems()
			)
				? elements.action('Save', ctrl.intent.sendAllDistriution, ctrl.model.savingProp)
				: m('button.btn', {disabled: true}, 'Save')
		])
		,ctrl.model.metaDataLoading()
		? elements.centeredSpinner()
		: ctrl.model.editMaterialsPermissions()
		? [
			ctrl.model.check_new_material()
			,elements.list([
				ctrl.model.addMaterialsButton({
					title: "Check, set or order project materials"
					,disabled: ctrl.model.items().length == 0
				})

				,m('button.btn.btn-secondary',
					{
						onclick: () => {
							ctrl.intent.applyPreset(ctrl.model.project_vm())
							ctrl.intent.sendAllDistriution()
						}
						,disabled: ctrl.model.presets().length == 0
							|| !(
								ctrl.model.selected_pt().disciplines
								|| [ctrl.model.selected_pt()]
							)
							.some((d) => (d.outstandingMaterials || []).length)
						,style: {
							backgroundColor: 'transparent'
							,border: 'solid 1px #3380c2'
							,position: 'relative'
							,top: '-0.1em'
							,height: '2.9em'
							,width: '12em'
							,color: '#434aa3'
							,textDecoration: 'underline'
						}
						,title: 'Apply automated materials for all disciplines on this project'
					}
					, '+ Forecasted Items'
				)

				,ctrl.model.order_vm.order().length
				? m('button.btn.btn-secondary',
					{
						onclick: () => {
							ctrl.intent.makeOrders()
							ctrl.intent.sendAllDistriution()
						}
						,disabled:
							ctrl.model.order_vm.order().length == 0
							|| !ctrl.model.editOrdersPermissions()
						,style: {
							backgroundColor: 'transparent'
							,border: 'solid 1px #3380c2'
							,position: 'relative'
							,top: '-0.1em'
							,height: '2.9em'
							,width: '8em'
							,color: '#434aa3'
							,textDecoration: 'underline'
						}
						,title: 'Order selected Materials'
					}
					, 'Order Items'
				)
				: null
				,ctrl.model.order_vm.order().length
					? ` - All container items are ready to order`
					: ``
			])
			,ctrl.model.getfilters(null, "Project Item")
			,m('br')
			,ctrl.model.ProjectItems().length != 0
					? scrollableTable.advanced({
						scoped: ctrl.model.data.scoped
						,resize$: ctrl.model.data.resize$
						,rows:
							ctrl.model.masterILIST()
							.map((item) => ({
								key: item.key
								,i: item
								,selection:
									!item.distribution_ordered()
									? ctrl.intent.DistributionItemsButton(item)
									: {style: {opacity:0} , disabled: true}
								,data: {
									'Item Name': item.distribution_material_name()
									,'Supplier': item.supplier_id()
									,'Description': item.distribution_material_description()
									,'Order': !ctrl.model.editOrdersPermissions()
										? 'Requires Order Management Permissions'
										: !item.distribution_ordered() && item.distribution_id()
											? elements.checkbox({
												onchange(event){
													if(event.currentTarget.checked == true){
														ctrl.model.materials().forEach((another_container_item) => {
														if (
															another_container_item.distribution_container_name()
															== item.distribution_container_name()
														){
																			ctrl.model.order_vm.order().push(another_container_item)
															}
														})
													}
													else if (event.currentTarget.checked == false){
														ctrl.model.check_order_vm(item)
													}
												}
												,checked: ctrl.model.check_ordered(item)
												,disabled: !!item.distribution_ordered()
													|| !ctrl.model.editOrdersPermissions()
												,title: "Order this material and corresponding container"
											})
											: !item.distribution_id()
												? 'Save prior to Ordering'
											: m('a'
												,{
													onclick: () => {
														ctrl.model.resetView()
														ctrl.model.activeMainTab('Manage Orders')
													}
												}
												,item.distribution_ordered() + ' - ' + item.order_received + ' received'
											)

									,'Units': ctrl.model.d_item_vm()[item.key]
											? m(
												NumberInput
												,R.merge(
													ctrl.model.data
													,{
														errors: ctrl.model.errors
														,errorLabel: 'Units'
													}
												)
												,{
													prop: item.distribution_amount
													,attrs: {
														min: 0
													}
												}
											)
										: item.distribution_amount()
									,'Silo': elements.checkbox({
										onchange(event){
											if(event.currentTarget.checked == true){
												item.distribution_silo(true)
												ctrl.model.consolidate()
											}
											else if (event.currentTarget.checked == false){
												item.distribution_silo(false)
												ctrl.model.consolidate()
											}
										}
										,checked: item.distribution_silo()
										,disabled: !!item.distribution_ordered()
										,title: [
											'Selecting this will segregate this material in to its own container,'
											,'otherwise Odin will bunch the material to try'
											,'and make more efficient and cheaper bulk orders'
										].join(' ')
									})
									,'Stocked Address': ctrl.model.d_item_vm()[item.key]
											? autocomplete.all(
												ctrl.model.availableYardSelections
												,item.distribution_stocked
												,'storage_location'
												, () => ({})
											)
											: item.distribution_stocked()

									,'Batch Qty': item.distribution_batch()
									,'Container': item.distribution_container_name()
									,'Assigned': item.distribution_entity_name()
								}
							}))
					})
					: ctrl.model.data.readMaterialPermissions()
						? elements.alert(`info`, 'Add materials to this project to track its consumable products')
						: elements.alert(`info`, 'Requires Material Permissions')
		]
		: [
			ctrl.model.getfilters(null, "Project Item")
			,m('br')
			,ctrl.model.ProjectItems().length != 0
				? scrollableTable.advanced({
					scoped: ctrl.model.data.scoped
					,resize$: ctrl.model.data.resize$
					,rows:
						ctrl.model.masterILIST().map((i) => ({
							key: i.key
							,i
							,data: {
								'Item Name': i.distribution_material_name()
								,'Supplier': i.supplier_id()
								,'Description': i.distribution_material_description()
								,'Assigned': i.distribution_entity_name()
								,'Units': i.distribution_amount()
								,'Stocked Address': i.distribution_stocked()
								,'Batch Qty': i.distribution_batch()
								,'Container': i.distribution_container_name()
								,'Silo': i.distribution_silo() ? 'Yes' : 'No'
								,'Order': i.distribution_ordered()
							}
						}))
				})
				: ctrl.model.data.readMaterialPermissions()
					? elements.alert(`info`, 'Add materials to this project to track its consumable products')
					: elements.alert(`info`, 'Requires Material Permissions')
		]
	])
}



function TransferView(ctrl){
	return m('div.harth',[
		m('br')
		,ctrl.model.transferCheck()
		,ctrl.model.metaDataLoading()
		? elements.centeredSpinner()
		: ctrl.model.transfers().length != 0
		? [
			elements.list([
				m('label.control-label', 'Transfer items from '
					,autocomplete.strict(
						ctrl.model.transfersFrom
						,R.when(Boolean, function(v){
							ctrl.model.transfer_object.from(v)
						})
						,'transfer_name'
						,() => ({
							disabled: ctrl.model.transfersFrom().length == 0
						})
					)
				)

				,m('label.control-label', 'Transfer items to '
					,autocomplete.strict(
						ctrl.model.transfersTo
						,R.when(Boolean, function(v){
							ctrl.model.transfer_object.to(v)
						})
						,'transfer_name'
						,() => ({
							disabled: ctrl.model.transfersTo().length == 0
						})
					)
				)
			])
			,!ctrl.model.transfer_object.from()
				? elements.alert('info',`Select a Warehouses or project to transfer items between them`)
				: [
						elements.alert('info', ctrl.model.transferItems().length  + ` items available. Only orders that have been completely received or items not yet ordered can be transferred. Orders can easily be split under Manage Orders if required`)
						,m('br')
						,m('h4','Available stock for transfer')
						,m('br')

						,elements.list([

							m("button.btn.btn-warning", {
								disabled: !ctrl.model.transfer_vm().length
								,onclick: () => {
									ctrl.model.errors({})
									ctrl.model.materials(
										ctrl.model.materials()
											.filter((m) => !m.created)
									)
									ctrl.model.materials(
										ctrl.model.materials().concat(
											ctrl.model.copy_items(
												R.differenceWith(
													(x, y) => uniqItem(x, uniqTItem) == uniqItem(y, uniqTItem)
													,ctrl.model.originalMaterials()
													,ctrl.model.materials()
												)
												,false
											)
										)
									)
									ctrl.model.transfer_vm([])
									ctrl.model.transfer_object.to(ctrl.model.transfer_object.to())
									ctrl.model.transfer_object.from(ctrl.model.transfer_object.from())
								}
								,title: "Discard all the details that have been changed"
							},"Discard Changes")

							,elements.forward("Transfer")(
								"Transfer items from one place to another, maybe a project or warehouse",
								() => Promise.all([ ctrl.intent.transferItems({operation: 'transferorders'}) ])
								,{
									disabled:
										ctrl.model.transfer_vm().length == 0
										|| ctrl.model.transfer_object.to() == ctrl.model.transfer_object.from()
										|| !ctrl.model.transfer_object.to()
										|| Object.keys(ctrl.model.errors())
											.map((a) =>
												ctrl.model.errors()[a]
													? a + ' ' + ctrl.model.errors()[a]
													: ''
											)
											.filter((a) => a)
											.length > 0
								}
							)
						])

						,ctrl.model.savingProp()
						? elements.centeredSpinner()
						: scrollableTable.advanced({
							scoped: ctrl.model.data.scoped
							,resize$: ctrl.model.data.resize$
							,rows:
								ctrl.model.transferItems().map((item, iIndex) => ({
									key: item.key
									,i: item
									,selection: {
										onchange(event){
											if(event.currentTarget.checked == true){
												ctrl.model.transfer_vm().push(item)
											}
											else if (event.currentTarget.checked == false){
												ctrl.model.transfer_vm().splice(
													R.findIndex(
														(a) => a.distribution_id() == item.distribution_id(),
														ctrl.model.transfer_vm()
													)
													,1
												)
											}
										}
										,type: 'checkbox'
										,checked: ctrl.model.transfer_vm().find((iT) => iT.distribution_id() == item.distribution_id() )
										,disabled: ctrl.model.transfer_object.to() == ctrl.model.transfer_object.from()
										,title: "Transfer this item to a different project or warehouse"
									}
									,data: {

										'': ctrl.model.transfer_vm().find((iT) => iT.distribution_id() == item.distribution_id() )
											? 'Transferring'
											: null
										,'Name': item.distribution_material_name()
										,'Description': item.distribution_material_description()
										,'Amount': m(
											NumberInput
											,R.merge(
												ctrl.model.data
												,{
													errors: ctrl.model.errors
													,errorLabel: item.distribution_material_name() + ' at line ' + (iIndex + 1) + ', Transfer Out'
												}
											)
											,{
												prop: (value) => {
													if(value){
														item.distribution_amount(value)
														!ctrl.model.transfer_vm().find((iT) => iT.distribution_id() == item.distribution_id() )
														&& value != ctrl.model.originalMaterialIndex()[item.distribution_id()].distribution_amount()
															? ctrl.model.transfer_vm().push(item)
															: null
													}
													else if(value == 0){
														let index = R.findIndex(
															(a) => a.distribution_id() == item.distribution_id(),
															ctrl.model.transfer_vm()
														)
														index > -1
															? ctrl.model.transfer_vm().splice(index, 1)
															: null
													}
													return item.distribution_amount()
												}
												,attrs: {
													min: 0
													,max: ctrl.model.originalMaterialIndex()[item.distribution_id()].distribution_amount()
												}
											}
										)
										,'Total': ' of '  + ctrl.model.originalMaterialIndex()[item.distribution_id()].distribution_amount()
										,'Dest. Stock': ctrl.model.transfer_object.to()
											? R.reduce(
												(a, b) => a + b.distribution_amount()
												,0
												,ctrl.model.transferToMaterialIndex()[item.distribution_material_name()]
												|| [{distribution_amount: m.prop(0)}]
											)
											: ''

										,'Stocked Address': item.distribution_stocked()
										,'Supplier': item.supplier_id()
										,'Order': item.distribution_ordered()
									}
								}))
						})

						,Object.keys(ctrl.model.errors())
							.map((a) =>
								ctrl.model.errors()[a]
									? a + ' ' + ctrl.model.errors()[a]
									: ''
							)
							.filter((a) => a)
							.map(s => m('p.pv1.ma0', s ))
			]
		]
		: [
			ctrl.model.editWarehousePermissions()
				? elements.alert('warning',`Warehouses or projects need to be available to make transfers`)
				: null
		]
	])
}



function EditPreset(ctrl){
	return m('div.harth.mb6',[
		m('h4','Create and Manage Automated Items')
		,m('hr')
		,ctrl.model.organizations_disciplines().length == 0
		? elements.alert('warning',`Disciplines need to be created to assign automated fills`)
		: ctrl.model.metaDataLoading()
		? elements.centeredSpinner()
		: elements.list([
			m('label.control-label', 'Discipline ')
			,Pencil(
				() => ctrl.model.selected_preset() || ''
				,() =>
					autocomplete.strict(
						ctrl.model.presetOptions
						,(v) => {
							if(v){
								const ReturnedAutocompleteObj =
									ctrl.model.organizations_disciplines().find(
										R.propEq('orgDisciplineNameDescription', v)
									)
								ctrl.model.selected_preset(
										ReturnedAutocompleteObj
										.orgDisciplineNameDescription
								)
							}
							return v
						}
						,null
						,() => ({
							disabled: ctrl.model.organizations_disciplines().length == 0
							,key: Math.random().toString(15).slice(2, 14)
						})
					)
			)

			,!ctrl.model.editMaterialsPermissions()
			? m('button.btn.invisible', {
				onclick: () => {}
				,disabled: false
				,title: ''
			}, 'Invisible space saving button' )
			: elements.list([

				m('label.control-label', 'Copy from ')

				,Pencil(
					() => ctrl.model.preset_vm()
					,() =>
						autocomplete.strict(
							ctrl.model.presetCloneOptions
							,(v) => {
								if(v){
									const ReturnedAutocompleteObj =
										ctrl.model.organizations_disciplines().find(
											R.propEq('orgDisciplineNameDescription', v)
										)
									ctrl.model.preset_vm(
										ReturnedAutocompleteObj
										.orgDisciplineNameDescription
									)

									ctrl.intent.clonePreset()
								}
								return v
							}
							,null
							,() => ({
								disabled:
									ctrl.model.organizations_disciplines().length == 0
									|| !ctrl.model.selected_preset()
								,
							})
						)
				)
			])

		])


		,ctrl.model.preset_vm()
			? m(``+css`margin-bottom: 2em`
				,`Cloning `
				+ ctrl.model.selected_preset()
				+ ` to `
				+ ctrl.model.preset_vm()
				+  `, this will erase all of `
				+ ctrl.model.preset_vm()
				+ ` existing automated items`
			)
			: null

		,ctrl.model.check_preset()

		,elements.list([
			ctrl.model.check_preset(true)
			|| identical(
				ctrl.model.basePresetString()
				,ctrl.model.PresetItems()
			)
				? m('button.btn', {disabled: true}, 'Save')
				: elements.action('Save', ctrl.intent.sendPreset, ctrl.model.savingProp)

			,m("button.btn.btn-warning", {
				disabled:
					!ctrl.model.editMaterialsPermissions()
					|| identical(
						ctrl.model.basePresetString()
						,ctrl.model.PresetItems()
					)

				,onclick: () => {
					ctrl.model.errors({})
					ctrl.model.new_material({})
					ctrl.model.presets(
						ctrl.model.presets()
							.filter((m) => !m.created)
					)

					ctrl.model.presets(
						ctrl.model.presets()
						.concat(
							ctrl.model.copy_items(
								R.differenceWith(
									(x, y) => uniqItem(x, uniqPItem) == uniqItem(y, uniqPItem)
									,ctrl.model.originalPresets()
									,ctrl.model.presets()
								)
								,false
							)
						)
					)

					ctrl.model.DeletePItems([])
					ctrl.model.masterILIST(true)
					ctrl.model.d_item_vm({})
					ctrl.model.preset_vm(null)
				}
				,title: "Discard all the details that have been changed"
			},"Discard Changes")


			,m('button.btn.btn-warning', {
				onclick: () => {
					ctrl.intent.deletePreset(false, ctrl.model.selected_preset())
				}
				,disabled: ctrl.model.PresetItems().length == 0
				,title: 'Delete all items related to this automated items list'
			}, 'Delete' )

		])
		,m('hr')

		,elements.list([
			m('h4','Materials used in this Discipline')
			,m(`button`
				+ css`
					border-radius: 0.5em;
					background-color: white;
					padding: 0.3em;
					border: solid 0.1px rgba(0,0,0,0.4);
					width: 8em;
					height: 2.3em;
				`
				,{
					onclick:
						() => {
							ctrl.model.d_item_vm({})
							ctrl.model.setnew_material()
						}
					,disabled: !ctrl.model.selected_preset()
						|| ctrl.model.items().length == 0
						|| !ctrl.model.editMaterialsPermissions()
					,title: "Add this item to the automated items list selected"

				}
				, '+ Materials '
			)
		])
		,m('hr')
		,ctrl.model.PresetItems().length != 0
			? elements.table(ctrl.model.data, ['Action', 'Item Name', 'Description', 'Units/Discipline Rate', 'Applies to', 'Supplier'],
				ctrl.model.PresetItems()
					.map((item, /*iIndex*/) => [
						ctrl.model.editMaterialsPermissions()
							? ctrl.intent.PresetItemsButton(item)
							: null
						,item.distribution_material_name()
						,item.distribution_material_description()

						,ctrl.model.d_item_vm()[item.key]
						&& ctrl.model.editMaterialsPermissions()
							? m(
								NumberInput
								,R.merge(
									ctrl.model.data
									,{
										errors: ctrl.model.errors
										,errorLabel:
											(
												ctrl.model.warehouse_vm().warehouse_id != null
												&& ctrl.model.activeMainTab() == 'Warehouses & Stock'
											)
											|| (
												ctrl.model.selected_pt().project_id
												&& ctrl.activeDTab() == 'Distribution'
											)
											? 'Units' : 'Units/Per Discipline Rate'
									}
								)
								,{
									prop: item.distribution_amount
									,attrs: {
										min: 0
										,step: 0.01
									}
								}
							)
							: item.distribution_amount()
						,item.distribution_entity_name()
						,item.supplier_id()
				]
			)
		)
			: ctrl.model.data.readMaterialPermissions()
				? !Object.keys(ctrl.model.new_material()).length
					? elements.alert(`info`, 'Add materials to automate material ordering for project disciplines.')
					: null
				: elements.alert(`info`, 'Requires Material Permissions')
	])
}



function EditWarehouse(ctrl){
	return m('div.harth.mb6',[
		m('br')
		,m('h4', ctrl.model.warehouse_vm().warehouse_name() + ' - Material Distribution')

		,m('hr')
		,elements.list([
			ctrl.model.edit() == true
				? elements.back(
					"Go back to the main view for projects, suppliers and warehouses"
					,() => {
						ctrl.model.errors({})
						ctrl.model.materials(
							ctrl.model.materials()
								.filter((m) => !m.created)
								.concat(ctrl.model.DeleteDItems())
						)
						ctrl.model.materials(
							ctrl.model.materials().concat(
								ctrl.model.copy_items(
									R.differenceWith(
										(x, y) => uniqItem(x, uniqDItem) == uniqItem(y, uniqDItem)
										,ctrl.model.originalMaterials()
										,ctrl.model.materials()
									)
									,false
								)
							)
						)
						ctrl.model.new_material({})
						ctrl.model.warehouse_vm({})
						ctrl.model.viewtype(MainList)
						ctrl.model.edit(false)
						ctrl.model.DeleteDItems([])
					}
					,{disabled: ctrl.model.savingProp()}
				)
				: m("button.btn.", {
					disabled: ctrl.model.savingProp()
					,onclick: () => {
						ctrl.model.errors({})
						ctrl.model.new_material({})
						ctrl.model.warehouse_vm({})
						ctrl.model.viewtype(MainList)
						ctrl.model.edit(false)
						ctrl.model.DeleteDItems([])
					}
					,title: "Discard information and go back to the main view for projects, suppliers and warehouses"
				},"Discard")

			,!ctrl.model.editWarehousePermissions()
			|| 				identical(
					ctrl.model.baseWarehouseString()
					,ctrl.model.WarehouseItems()
				)
				&& identical(
					ctrl.model.warehouses().find((w) =>
						w.warehouse_id()
						== ctrl.model.warehouse_vm().warehouse_id()
					)
					,ctrl.model.warehouse_vm()
				)

			|| ctrl.model.check_wnames(true)
				? m('button.btn', {
					disabled: true
					,title: "Save Warehouse"
				}, 'Save')
				: elements.action('Save', ctrl.intent.sendWarehouse, ctrl.model.savingProp)

			,ctrl.model.edit() == true
				? m("button.btn.btn-warning", {
					disabled: ctrl.model.savingProp()
							|| !ctrl.model.editWarehousePermissions()
							|| 								identical(
									ctrl.model.baseWarehouseString()
									,ctrl.model.WarehouseItems()
								)
								&& identical(
									ctrl.model.warehouses().find((w) =>
										w.warehouse_id()
										== ctrl.model.warehouse_vm().warehouse_id()
									)
									,ctrl.model.warehouse_vm()
								)
					,onclick: () => {
						ctrl.model.errors({})
						ctrl.model.materials(
							ctrl.model.materials()
								.filter((m) => !m.created)
								.concat(ctrl.model.DeleteDItems())
						)
						ctrl.model.materials(
							ctrl.model.materials().concat(
								ctrl.model.copy_items(
									R.differenceWith(
										(x, y) => uniqItem(x, uniqDItem) == uniqItem(y, uniqDItem)
										,ctrl.model.originalMaterials()
										,ctrl.model.materials()
									)
									,false
								)
							)
						)
						ctrl.model.DeleteDItems([])
						ctrl.model.cloneWarehouse(
							ctrl.model.warehouses().find((w) =>
								w.warehouse_id()
								== ctrl.model.warehouse_vm().warehouse_id()
							)
						)
						ctrl.model.masterILIST(true)
						ctrl.model.d_item_vm({})
					}
					,title: "Discard all the details that have been changed"
				},"Discard Changes")
				: null

		])
		,ctrl.model.check_wnames()

		,!ctrl.model.warehouse_vm().warehouse_id()
		? [
			elements.alert('warning',`This warehouse must be saved before items can be added to it`)
			,elements.tabset(['Warehouse Details'], ctrl.activeWTab)
		]
		: elements.tabset(['Warehouse Details', 'Warehouse Items'], ctrl.activeWTab)
		,m('hr')
		,ctrl.activeWTab() == 'Warehouse Details'
		? [
			elements.list([
				m('label.control-label', 'Warehouse Name'
					,elements.textInput( ctrl.model.warehouse_vm().warehouse_name, {disabled: ctrl.model.editWarehousePermissions() == false})
				)
				,elements.labelCheckbox({
					label: 'Delivery'
					,prop: ctrl.model.warehouse_vm().warehouse_delivery
					,attrs: {
						checked: ctrl.model.warehouse_vm().warehouse_delivery()
						,disabled: ctrl.model.editWarehousePermissions() == false
						,title: "Does this warehouse deliver"
					}
				})
			])
			,elements.list([
				m('label.control-label', 'Address',
					elements.textInput(
						ctrl.model.warehouse_vm().warehouse_location
						,{ placeholder: "92 Woodnock Ave, Canterbury"
						, disabled: ctrl.model.editWarehousePermissions() == false
						}
					)
				)
				,m('label.control-label', 'Phone',
					elements.telephoneInput(
						ctrl.model.warehouse_vm().warehouse_phone
						,{ placeholder: "02 YYYY YYYY"
						, disabled: ctrl.model.editWarehousePermissions() == false
						}
					)
				)
				,m('label.control-label', 'Email',
					elements.textInput(
						ctrl.model.warehouse_vm().warehouse_email
						,{ placeholder: "jerry@harth.io"
						, disabled: ctrl.model.editWarehousePermissions() == false
						}
					)
				)
				,m('label.control-label', 'Regular Open Hours',
					elements.textInput(
						ctrl.model.warehouse_vm().warehouse_operating_hours_regular
						,{ placeholder: "Mon-Fri 8am-5pm"
						, disabled: ctrl.model.editWarehousePermissions() == false
						}
					)
				)
				,m('label.control-label', 'Weekend Open Hours',
					elements.textInput(
						ctrl.model.warehouse_vm().warehouse_operating_hours_weekend
						,{ placeholder: "Sat-Sun 8am-2pm"
						, disabled: ctrl.model.editWarehousePermissions() == false
						}
					)
				)
				,m('label.control-label', 'Public Holidays',
					elements.textInput(
						ctrl.model.warehouse_vm().warehouse_operating_hours_public_holiday
						,{ placeholder: "PH 9am-4pm"
						, disabled: ctrl.model.editWarehousePermissions() == false
						}
					)
				)
			])
		]
		: ctrl.model.metaDataLoading()
		? elements.centeredSpinner()
		: [
			ctrl.model.editMaterialsPermissions() && ctrl.model.editWarehousePermissions()
				? [
					ctrl.model.check_new_material()
					,elements.list([

						ctrl.model.addMaterialsButton({
							disabled: ctrl.model.items().length == 0
							,title: 'Add to warehouse materials'
						})

						,ctrl.model.order_vm.order().length
							? m('button.btn.btn-secondary',
								{
									onclick: () => {
										ctrl.intent.makeOrders()
										ctrl.intent.sendWarehouse()
									}
									,disabled:
										ctrl.model.order_vm.order().length == 0
										|| !ctrl.model.editOrdersPermissions()
									,style: {
										backgroundColor: 'transparent'
										,border: 'solid 1px #3380c2'
										,position: 'relative'
										,top: '-0.1em'
										,height: '2.9em'
										,width: '8em'
										,color: '#434aa3'
										,textDecoration: 'underline'
									}
									,title: 'Order selected Materials'
								}
								, 'Order Items'
							)
						: null

						,ctrl.model.order_vm.order().length
							? ` - All container items are ready to order`
							: ``
					])

					,ctrl.model.getfilters(null, "Warehouse Item")

					,m('br')
					,elements.tabset(['Stocked', 'Cancelled'], ctrl.activeMTab)
					,m('br')

					,m('br')
					,ctrl.model.WarehouseItems().length != 0
						? scrollableTable.advanced({
							scoped: ctrl.model.data.scoped
							,resize$: ctrl.model.data.resize$
							,rows:
								ctrl.model.masterILIST()
								.map((item) => ({
									key: item.key
									,i: item
									,selection:
										!item.distribution_ordered()
											? ctrl.intent.WarehouseItemsButton(item)
											: {style: {opacity:0} , disabled: true}
									,data: {
										'Item Name': item.distribution_material_name()
										,'Description': item.distribution_material_description()
										,'Supplier': item.supplier_name || item.supplier_id()
										,'Order': !item.distribution_ordered() && item.distribution_id()
											? elements.checkbox({
													onchange(event){
														if(event.currentTarget.checked == true){
															ctrl.model.materials().forEach((another_container_item) => {
																if (
																	another_container_item.distribution_container_name()
																	== item.distribution_container_name()
																){
																		ctrl.model.order_vm.order().push(another_container_item)
																}
															})
														}
														else if (event.currentTarget.checked == false){
															ctrl.model.check_order_vm(item)
														}
													}
													,checked: ctrl.model.check_ordered(item)
													,disabled: !!item.distribution_ordered()
															|| !ctrl.model.editOrdersPermissions()
												})
											: !item.distribution_id()
												? 'Save prior to Ordering'
												: m('a'
													,{
														onclick: () => {
															ctrl.model.resetView()
															ctrl.model.activeMainTab('Manage Orders')
														}
													}
													,item.distribution_ordered() + ' - ' + (item.order_received || 0 ) + ' received'
												)


										,'Units': ctrl.model.d_item_vm()[item.key]
											? m(
												NumberInput
												,R.merge(
													ctrl.model.data
													,{
														errors: ctrl.model.errors
														,errorLabel: 'Units'
													}
												)
												,{
													prop: item.distribution_amount
													,attrs: {
														min: 0
													}
												}
											)
											: item.distribution_amount()
										,'Silo' : elements.checkbox({
											onchange(event){
												if(event.currentTarget.checked == true){
													item.distribution_silo(true)
													ctrl.model.consolidate()
												}
												else if (event.currentTarget.checked == false){
													item.distribution_silo(false)
													ctrl.model.consolidate()
												}
											}
											,checked: item.distribution_silo()
											,disabled: !!item.distribution_ordered()
											,title: [
												'Selecting this will segregate this material in to its own container,'
												,'otherwise Odin will bunch the material to try'
												,'and make more efficient and cheaper bulk orders'
											].join(' ')
										})
										,'Stocked Address': ctrl.model.d_item_vm()[item.key]
											? autocomplete.all(
												ctrl.model.availableYardSelections
												, item.distribution_stocked
												, 'storage_location'
												, () => ({})
											)
											: item.distribution_stocked()
										,'': ctrl.model.check_float(item) && !item.distribution_ordered()
											? `Stock is Low. ` + (item.distribution_ordered() ? "" : "Order item")
											: ""
										,'Batch Qty': item.distribution_batch()
										,'Container': item.distribution_container_name()
										,'Float': item.distribution_float()
										,' ':
											elements.undoDiscard({
												discard:
													{
														label: 'Discard'
														,attrs: {
															onclick:
																() => {
																	ctrl.model.d_item_vm()[item.key] = item
																	ctrl.intent.deleteDitem()
																}
														}
													}
											})

									}
								}))
						})
						: ctrl.model.data.readMaterialPermissions()
							? elements.alert(`info`, 'Add materials to this warehouse to track stock')
							: elements.alert(`info`, 'Requires Material Permissions')
				]
			: [
				m('br')
				,ctrl.model.getfilters("button", "Warehouse Item")
				,ctrl.model.getfilters(null, "Warehouse Item")
				,ctrl.model.WarehouseItems().length != 0
					? scrollableTable.advanced({
						scoped: ctrl.model.data.scoped
						,resize$: ctrl.model.data.resize$
						,rows:
							ctrl.model.masterILIST().map((item) => ({
								key: item.key
								,i: item
								,data: {
									'Item Name': item.distribution_material_name()
									,'Description': item.distribution_material_description()
									,'Supplier': item.supplier_id()
									,'Units': item.distribution_amount()
									,'': ctrl.model.check_float(item)
										? `Stock is Low. `+ (
											item.distribution_ordered()
												? ""
												: "This item isn't ordered yet"
											)
										: ""
									,'Batch Qty': item.distribution_batch()
									,'Container': item.distribution_container_name()
									,'Silo': item.distribution_silo() ? 'Yes' : 'No'
									,'Order': item.distribution_ordered()
									,'Float': item.distribution_float()
									,'Stocked Address': item.distribution_stocked()
								}
							}))
						})
					: ctrl.model.data.readMaterialPermissions()
						? elements.alert(`info`, 'Add materials to this warehouse to track stock')
						: elements.alert(`info`, 'Requires Material Permissions')
			]
		]

	])
}


/*
	In order for mithril to mount this component.  We have to expose these two properties.
*/
export default Main
