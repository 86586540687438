import m from 'bacta'
import * as elements from '../components/elements'
import * as R from 'ramda'
import NotificationBar from '../components/notification'
import humanList from '../utils/human_list'
import { prop as stream } from '../../../stream'
import Permissions from '../models/permissions'
import moment from 'moment'

function Main({ attrs: { data } }){

	const {
		schedule_id: schedule_id_no_repeats
		,actual
		,deleteSchedule
		,fetchSchedulesLocal
		,schedules
		,progress
		,schedule_params
		,schedulesLoadable
		,createStatsStream
		,currentRoute
	} = data

	currentRoute('schedules')
	const statsIndex =
		createStatsStream()
	// eslint-disable-next-line no-unused-vars
	const forceRedraw =
		stream.merge(
			[
				schedules
				,progress
				,schedulesLoadable
				,statsIndex
			]
		)
		.map(function(){
			// eslint-disable-next-line no-undef
			return setTimeout(function(){
				m.redraw()
			}, 0)
		})



	statsIndex({})

	const scheduleTaskCompletionChange =
		stream.scan(function(p, n){
			const completed =
				R.keys(R.filter( x => x === '', n))

			return {
				changed: R.any( k => p.progress[k] !== '', completed)
				,progress: n
			}

		}, { progress: {}, changed: false }, progress)

	const refetchStats = () =>
		schedulesLoadable(
			schedulesLoadable()
		)

	scheduleTaskCompletionChange.map(
		({ changed }) => {
			if( changed ){
				m.redraw()
				refetchStats()
			}
			return null
		}
	)

	const pDelete = stream()
	const generating = stream(false)
	const deleting = stream(false)
	const loadingTemplate = stream(false)
	const pending =
		stream.merge([ generating, deleting, loadingTemplate ])
		.map(R.any(R.equals(true)))

	pending.map( () => m.redraw() )

	const schedule_parent = data.scoped()
	const permissions = data.permissions
	const showOption = data.scoped(false)
	const selectedTemplateShort = data.scoped("Building")
	const selectedTemplateLong =
		selectedTemplateShort.map(
			s =>
			s == 'Telecommunications'
			? 'Telecommunications Schedule'
			: s == 'Building'
				? 'Building & Construction Schedule'
				: 'Residential & Commercial Development Schedule'
		)

	const missingPermissions =
		data.initiateContractFetch.map(() =>
			humanList(
				[
					'Project'
					,'Resource'
				]
					.map((p)=>
						data['read'+p+'Permissions']()
							? ''
							: p
					)
					.filter((p) => p)
			)
		)

	const templateOptions =
		[{ name: 'Building & Construction Schedule'
		, shortName: 'Building'
		, loaded: false
		}
		,{ name: 'Telecommunications Schedule'
		, shortName: 'Telecommunications'
		, loaded: false
		}
		,{ name: 'Residential & Commercial Developments Schedule'
		, shortName: 'Developing'
		, loaded: false
		}
		]

	const editPermissions = m.prop()
	permissions.map(function(p){
		editPermissions(
			Permissions.hasWrite(p, 'schedule_management')
		)
		return null
	})

	const active =
		m.route.get().includes('create')
		? {
			//by mapping; local changes don't go up stream automatically
			//but we automatically receive upstream changes
			schedule_name: data.scoped()
			,schedule_priority: data.scoped()
			,schedule_id: data.scoped()
			,schedules_parameters_id: data.scoped()
			,schedule_params: data.scoped.clone( data.schedule_params )
			,schedule_shared_resources: data.scoped(false)
		}
		: {
			//by mapping; local changes don't go up stream automatically
			//but we automatically receive upstream changes
			schedule_name: data.scoped.clone( data.schedule_name )
			,schedule_shared_resources: data.scoped( false )
			,schedule_priority: data.scoped.clone( data.schedule_priority )
			,schedule_id: data.scoped.clone( schedule_id_no_repeats )
			,schedules_parameters_id: data.selectedVersion.map(
				R.propOr(null, 'schedules_parameters_id')
			)
			,schedule_params: data.scoped.clone( data.schedule_params )
		}

	try {


		if( m.route.param('schedule_parent') ){

			schedule_parent( m.route.param('schedule_parent') )

			schedules.map(function(list){
				const found = list
				.find(
					s => s.schedule_versions.find(
						sv => sv.schedule_version_id == schedule_parent()
					)
				)

				if( found ){

					const schedule_version =
						found.schedule_versions.find(
							sv => sv.schedule_version_id == schedule_parent()
						)

					active.schedule_name(
						found.schedule_name + ' (Clone)'
					)

					if( schedule_version ){

						active.schedule_priority(
							schedule_version.schedule_priority
						)
					}
					active.schedule_id(null)
					m.redraw()
				}
				return null
			})
		}

		if( m.route.param('schedule_id') ){
			const route_schedule_id = m.route.param('schedule_id')
			actual.schedule_id(route_schedule_id)
		}

	} catch (e) {
		debugger;
	}

	//create a stream of well formatted payload objects
	const payload = stream.afterSilence(0, stream.merge(
		[	active.schedule_name
			,active.schedule_priority
			,active.schedule_params
		]
	)).map(([ schedule_name, schedule_priority, schedule_params ]) => {

		const schedule_id = active.schedule_id()
		const sv = data.selectedVersion()

		const schedules_parameters_id =
			schedule_params.filter((p) =>
				p.schedules_parameters_name()
					== schedule_priority
			)
			.map( sp => sp.schedules_parameters_id() )
			.find( Boolean )

		const schedule_version_old_id =
			sv
			? sv.schedule_version_id
			: null

		return {
			schedule_name
			,schedule_id
			,schedule_shared_resources: active.schedule_shared_resources()
			,schedule_parent: schedule_parent()
			,schedule_versions: [
				{
					schedule_priority
					,schedules_parameters_id
					,schedule_version_old_id
				}
			]
		}
	})


	function loadTemplateSchedule(){
		return data.api.template_schedules.post(
			selectedTemplateLong()
		)
			.then(function({ organization_id }){
				return data.auth_refresh()
					.then(function(){
						data.organization_id(organization_id)
					})
			})
			.catch( NotificationBar.Notifications.alertError )
	}

	function canGenerate(schedule){

		const checkobj = schedule || active
		const originalSchedule =
			data.schedules().find((s) =>
				s.schedule_id
				==  checkobj.schedule_id
			) || {}

		const latestVersion =
			originalSchedule.schedule_versions
				? originalSchedule.schedule_versions[0]
				: active

		const schedule_id =
			schedule
				? checkobj.schedule_id
				: checkobj.schedule_id()

		const schedule_name =
			schedule
				? checkobj.schedule_name
				: checkobj.schedule_name()

		const priority = typeof latestVersion.schedule_priority == 'function'
			? latestVersion.schedule_priority()
			: latestVersion.schedule_priority

		return !!priority
			&& !!schedule_name
			&& (
				!!(statsIndex()[schedule_id] || {}).intersectdisciplinescount
				|| scheduleCreation()
			)
			&& !data.progress()[latestVersion.schedule_version_id]
	}


	function saveSchedule(forcedPayload){

		const realPayload =
			forcedPayload
				? forcedPayload
				: payload()

		return data.api.schedules.patch([realPayload])
			.then(fetchSchedulesLocal)
			.then(function(){

				const id =
					data.schedules().find((s) =>
						s.schedule_name
						== realPayload.schedule_name
					)
					.schedule_versions[0].schedule_version_id

				// todo-james we don't handle templates here
				data.progress(
					R.merge({
						[id]: '0 %'
					}, data.progress() )
				)

				m.route.set('/data/schedules')
			})
			.catch(function(error){
				throw error
			})
	}

	function checkErrors(schedule){


		const propValues =
			typeof schedule.schedule_name == 'function'


		const {
			scheduleId
			,sname
			,spriority
		} =
			propValues
			? {
				scheduleId:
					schedule.schedule_id()
				,sname:
					!schedule.schedule_name()
				,spriority:
					!schedule.schedule_priority()
			}
			:{ scheduleId:
				schedule.schedule_id
			, sname:
				!schedule.schedule_name
			, spriority:
				!schedule.schedule_priority
			}

		const stats =
			statsIndex()[scheduleId] != null
			? statsIndex()[scheduleId]
			: { crewcount: 0
			, projectcount: 0
			, intersectdisciplinescount: 0
			}

		const sccount =
			stats.crewcount

		const spcount =
			stats.projectcount

		const sdicount =
			stats.intersectdisciplinescount

		return elements.errorAlert(
			`To generate a schedule, `
			,' and '
			,' must be entered'
			,[false,false,false,false,false]
			,[
				() => sname
					? `a schedule name`
					: null
				,() => propValues && spriority
					? `a directive`
					: null
				,() => !sccount
					&& !scheduleCreation()
					? ` one or more projects`
					: null
				,() => !spcount
					&& !scheduleCreation()
					? `one or more resources`
					: null
				,() => !sdicount
					&& !scheduleCreation()
					? 'teams capable of working on the'
						+ ' related projects'
					: null
			]
			,false
			,true
			,'warning'
		)
	}

	function canEdit(s){
		return s
			? editPermissions()
			&& !data.progress()[s.schedule_versions[0].schedule_version_id]
			: editPermissions()
	}


	function list(){
		const cloneHref = s =>
			'/data/schedules/clone/schedule_parent/'+currentVersionId(s)

		return m('div', [
			[ missingPermissions()
				? elements.alert(
					`info`
					,'Detailed functionality requires '
					+ missingPermissions()
					+ ' permissions'
				)
				: [
					!data.organizations.organization_id()

						// todo-james check if they have orgs to choose...
						? elements.alert(`info`, `Choose or Create an organization`)
						: canEdit()
							? [
								schedules().length != 0
									? elements.table(data,
										['Schedule', '', '', '', '', 'Scheduled End', 'Projects', 'Shared Resources', 'Workable Resource', 'Workable Teams', 'Approx Work Days (1 Team)', 'Extensions of Time', 'Delays', 'Variations']
										,schedules().map((s) =>
											[
												s.schedule_name

												,m("a", {
													href: '/data/schedules/'+s.schedule_id
													, oncreate: m.route.link
													, disabled: !canEdit(s)
													, title: 'Change the directive that runs '
														+ 'this schedule. If Odin is allowed to '
														+ 'automatically choose resources for '
														+ 'projects our rating system will '
														+ 'change to pick best options for this directive '
													}
													, 'Edit'
												)

												,m("a", {
														href: cloneHref(s)
														,oncreate: m.route.link
														, disabled: !canEdit(s)
														, title: 'Safely explore alternative '
														+ 'scheduling timelines by copying all '
														+ 'projects Workflow relationships and '
														+ 'directions to a new schedule '
														+ 'and running alterations that '
														+ 'may expose different benefits '
													}
													, 'Clone'
												)

												,m("a", {
														href: '/dashboard/' + data.organizations.organization_id() + '/' + s.schedule_id
														, oncreate: m.route.link
														, title: 'Go to the Gantt and '
														+ 'view all scheduled works'
													}
													, 'View'
												)

												,data.progress()[s.schedule_versions[0].schedule_version_id]
													? m(
														'label.control-label'
														, `Generating ` + data.progress()[s.schedule_versions[0].schedule_version_id]
													)
													: elements.action(
														'Re-Generate'
														, () =>
															saveSchedule(
																R.merge(
																	s
																	,{
																		schedule_parent: null
																		,schedule_versions: [
																			{
																				schedule_priority: s.schedule_versions[0].schedule_priority
																				,schedules_parameters_id: s.schedule_versions[0].schedules_parameters_id
																				,schedule_version_old_id: s.schedule_versions[0].schedule_version_id
																			}
																		]
																	}
																)
															)
														, generating
														,'primary'
														, () => !canGenerate(s)
														, undefined
														, {
															disabled: !canEdit(s) || !canGenerate(s)
															,title: "Re-Generate this schedule to suspect with updates and parameter changes for a new or updated forecast"
														}
													)


												,!Object.keys(statsIndex()).length
													? '...'
													: (statsIndex()[s.schedule_id] || {}).lastallocationdate
														? moment( (statsIndex()[s.schedule_id] || {}).lastallocationdate ).format("ll")
														: [checkErrors(s)]
															.filter(Boolean)
															.filter( x => (x.children ? x.children[0] || '' : '').trim() )
															.concat( '...' )
															[0]

												,!Object.keys(statsIndex()).length
												? '...'
												: Number((statsIndex()[s.schedule_id] || {}).projectcount || 0)

												,!Object.keys(statsIndex()).length
												? '...'
												: elements.checkbox(
													{
														onchange:
															m.withAttr('checked', function(e){
																if( e ){
																	s.schedule_shared_resources = true
																}
																else {
																	s.schedule_shared_resources = false
																}
																return saveSchedule(
																	R.merge(
																		s
																		,{
																			schedule_parent: null
																			,schedule_versions: [
																				{
																					schedule_priority: s.schedule_versions[0].schedule_priority
																					,schedules_parameters_id: s.schedule_versions[0].schedules_parameters_id
																					,schedule_version_old_id: s.schedule_versions[0].schedule_version_id
																				}
																			]
																		}
																	)
																)
															})
														,disabled: data.progress()[s.schedule_versions[0].schedule_version_id]
														,checked: s.schedule_shared_resources
														,title: 'Resource availabilities will be shared with all other checked schedules'
													}
												)

												,!Object.keys(statsIndex()).length
												? '...'
												: Number((statsIndex()[s.schedule_id] || {}).resourcecount || 0)
												,!Object.keys(statsIndex()).length
												? '...'
												: Number((statsIndex()[s.schedule_id] || {}).crewcount || 0)
												,!Object.keys(statsIndex()).length
												? '...'
												: Number((statsIndex()[s.schedule_id] || {}).daycountsum || 0).toFixed(0)
												,!Object.keys(statsIndex()).length
												? '...'
												: Number((statsIndex()[s.schedule_id] || {}).eotsum || 0)
												,!Object.keys(statsIndex()).length
												? '...'
												: Number((statsIndex()[s.schedule_id] || {}).nodsum || 0)
												,!Object.keys(statsIndex()).length
												? '...'
												: Number((statsIndex()[s.schedule_id] || {}).varsum || 0)
											])
										)
									: null

									,m('br')
									,elements.list([
											schedules().length != 0
												? m("a.btn.btn-primary", {
											href: '/dashboard'
											, oncreate: m.route.link
											, disabled: !canEdit()
											, title: 'Go to the Gantt and '
											+ 'view all scheduled works'
										}
										, 'Gantt'
									)
												: null

									,m('a.btn.btn-primary', {
										href: '/data/schedules/create'
										, oncreate: m.route.link
										, disabled: !canEdit()
										, title: "Create a new schedule"
										}
										, 'Create'
									)
								])
								,schedules().length != 0
									? null
									: elements.alert(`info`
										,`I have no records of schedules, you may not have permissions to access them`
									)
							]
							: []
				]
				,true
				? null
				: elements.list([

					showOption()
						? elements.alert(
							'info'
							,['Templates are example data sets you can use'
							, 'to familiarize yourself with Odin\'s'
							, 'vast array of features.'
							].join(' ')
						)
						: null
					,[showOption() ? 'Hide' : 'Show']
					.map(
						verb =>
							m('button.btn.btn-', {
								onclick: () => {
									showOption(!showOption())
								}
								, disabled: loadingTemplate()
								, title: verb
									+ " templates available for loading"
								}
								, verb
									+ ' Available Templates'
							)
					)[0]

					,showOption()
						? elements.select(
							templateOptions
								.map( s => s.shortName )
							,selectedTemplateShort
							,{
								title: "The template to activate"
								,disabled: loadingTemplate()
							}
						)
						: null

					,showOption()
						? elements.forward("Activate Template")(
							"Activate Template",
							() => {
								showOption(false)
								loadingTemplate(true)
								loadTemplateSchedule()
									.then(function(){
										loadingTemplate(false)
										m.redraw()
									})
							}
							,{
								disabled: loadingTemplate()
							}
						)
						: null

				,loadingTemplate()
					? elements.alert(`info`
						,`Loading ` + selectedTemplateShort() + ` Template ...`
					)
					: null
				].filter(Boolean))
			]

		])
	}

	function generateButton(){
		return canGenerate()
			? elements.action(
				'Generate Schedule'
				, saveSchedule
				, generating
			)
			: m('button.btn.btn-primary',{
					disabled: true
					,title: 'Generate the work allocations for this '
					+ 'schedule with all current data and specifications'
				}
				, 'Generate Schedule'

			)
	}

	function scheduleCreation(){
		return m.route.get().includes('create')
			|| m.route.get().includes('clone')
	}

	function edit_table(){
		return [
			elements.list([
				elements.formInput([
					'Schedule Name'
					, active.schedule_name, {}
				])
				,m('div.form-group', [
					m('label.control-label', 'Schedule Directive'
						,elements.select(
							schedule_params()
							.map(
								s => s.schedules_parameters_name()
							)
							, active.schedule_priority
							, {
								title: 'This will change rating statistics in'
								+ ' our engine to find best matching'
								+ ' resources for each project.'
								+ ' Primarily to keep core directive'
								+ ' consistent'
							}
							, 'Select a Directive'
						)
					)
				])
			])

			,pending()
				? elements.alert(
					`info`
					, generating()
						? `Starting the next generation`
						: `Loading`
				)
				: checkErrors(active)

			,elements.list([
				generateButton()
				,m("a.btn.btn-warning", {
					href: '/data/schedules'
					,oncreate: m.route.link
					,title: "Discard changes to the schedule"
				}, 'Discard')
				,m.route.get().includes('create')
				? null
				: pDelete() == active.schedule_id
					? elements.action(
						'Permanantly Delete'
						,() => {
							pDelete(null)
							return deleteSchedule(active.schedule_id())
							.then(function(){
								schedule_id_no_repeats(null)
								m.route.set('/data/schedules')
								m.redraw()
							})
						}
						, pending
						, 'danger'
						, () => false
						, NotificationBar.Notifications.alertError
					)
					: m("button.btn.btn-warning", {
							onclick: () => {
								pDelete(
									active.schedule_id
								)

							}
							, disabled: pending()
							, title: 'Go to the Gantt and '
							+ 'view all scheduled works'
						}
						, 'Delete'
					)
			])
		]
	}

	function currentVersionId(schedule){
		return schedule.schedule_versions[0].schedule_version_id
	}


	function edit(){
		return m('div'
			,missingPermissions()
				? elements.alert(
					`info`
					,'Detailed functionality requires '
					+ missingPermissions()
					+ ' permissions'
				)
				: null
			,edit_table()
		)
	}

	function view(){
		return m('div.hth-schedule',
			(
				m.route.param('schedule_id')
				|| m.route.param('schedule_parent')
				|| m.route.get().includes('create')
					? edit
					: list
			)()
		)
	}

	return { view }
}

export default Main