/* global setTimeout, clearTimeout */


import traverse from 'traverse'
import * as R from 'ramda';
// eslint-disable-next-line no-undef
const DEV = process.env.DEV
// eslint-disable-previous-line no-unused-vars
// eslint-disable-next-line no-undef
const IDENTICAL_LOGS = process.env.IDENTICAL_LOGS
// eslint-disable-previous-line no-unused-vars

import m from 'bacta'
const callProp =
	R.when(
		(x) => typeof x == 'function'
		,R.call
	)

const exclusions = [
	'created'
	, 'interruptions_id'
	, 'interruptions_affected_id'
	, 'key'
	, 'ParentRecognition'
	, 'CommonRecognition'
	, 'ParentRecognitionID'
	, 'showWork'
	, 'discplineNameDescription'

	, 'materialDailyActual'
	, 'materialDailyForecasted'
	, 'materialForecasted'
	, 'materialActual'
	, 'contractedProgress'
	, 'contractedDailyPeriodProgress'
	, 'contractedDailyProgress'

	, 'budget'
	, 'outstandingRevenue'
	, 'outstandingExpenditures'
	, 'outstandingProfits'
	, 'outstandingDailyPeriodRevenue'
	, 'outstandingDailyPeriodExpenditures'
	, 'outstandingDailyPeriodProfits'
	, 'outstandingDailyRevenue'
	, 'outstandingDailyExpenditures'
	, 'outstandingDailyProfits'

	, 'currentExpenditures'
	, 'currentProfits'
	, 'currentRevenue'
	, 'currentDailyRevenue'
	, 'currentDailyExpenditures'
	, 'currentDailyProfits'
	, 'currentDailyPeriodRevenue'
	, 'currentDailyPeriodExpenditures'
	, 'currentDailyPeriodProfits'

	, 'forecastedDailyRevenue'
	, 'forecastedDailyProfits'
	, 'forecastedDailyExpenditures'
	, 'forecastedDailyPeriodRevenue'
	, 'forecastedDailyPeriodExpenditures'
	, 'forecastedDailyPeriodProfits'
	, 'forecastedExpenditures'
	, 'forecastedProfits'
	, 'forecastedRevenue'

	, 'accruedExpenditures'
	, 'accruedRevenue'
	, 'accruedProfits'
	, 'accruedDailyRevenue'
	, 'accruedDailyExpenditures'
	, 'accruedDailyPeriodRevenue'
	, 'accruedDailyPeriodExpenditures'
	, 'accruedDailyProfits'
	, 'accruedDailyPeriodProfits'
	, 'invoicecount'

	, 'contractrecognitionid'
	, 'contractrecognitionname'
	, 'contractrecognitiondescription'
	, 'scheduleNameVersion'
	, 'owner_id'
	, 'contractStart'
	, 'contractEnd'
	, 'damagesEnd'
	, 'contractList'
	, 'lastAllocationDate'
	, 'disciplineName'
	, 'relatedDiscipline'
	, 'DisciplineWork'
	, 'DisciplineCompleted'
	, 'teamresourcename'
	, 'dReceived'
	, 'coalatedSelectedVersion'
	, 'possibleContractEntities'
	, 'focussedInvoices'
	, 'allContractItems'
	, 'allContracts'
	, 'contractprojectid'
	, 'contractuserid'
	, 'invoiceno'
	, '__requestedAt'
	, 'depth'
	, 'distribution_container_id'
	, 'lastallocationdate'
	, 'eotsum'
	, 'nodsum'
	, 'varsum'
	, 'workdays'
	, 'workdayeffort'
	, 'badeffortteams'
	, 'filecount'
	, 'materialvalue'
	, 'revenuevalue'
	, 'expenditurevalue'
	, 'itemk'
	, 'expanded'
	, 'dateB'
	, 'orderdetail'
	, 'completedcount'
	, 'completedsum'
	, 'scheduledsum'
	, 'applicablecontracts'
	, 'newproject'
	, 'fmgroupedbyitem'
	, 'currentqty'
	, 'requiredqty'
	, 'wastediff'
	, 'newdiscipline'
	, 'invoice_total'
	, 'newtask'
	, 'receipting'
	, 'notesexpanded'
	, 'projectPercentComplete'
	, 'midfix'
	, 'oldusers'
	, 'addFiles'
	, 'zip_url'
	, 'contractrecognitionnametype'
]

let timeoutId
function deBounceTraverse(a, b, moreExclusions=[], returnKeys, identicalProp){
	clearTimeout(timeoutId)
	timeoutId =
		setTimeout(
			() => traverseCheck(a, b, moreExclusions, returnKeys, identicalProp)
			,500
		)
}


function traverseCheck(a, b, moreExclusions=[], returnKeys, identicalProp){
	const index = R.indexBy(R.identity, [].concat(exclusions, moreExclusions))
	const difference = {}
	const refObj = !a || !Object.keys(a).length ? b : a
	const checkObj = refObj == a ? b : a

	traverse(refObj).forEach(function(x){


		const parentPath = this.isRoot ? [] : this.parent.path

		const bParent = R.pathOr({}, parentPath, checkObj)

		const bprop = bParent[this.key]
		const bprophas = this.key in bParent
		const aVal = callProp(x)
		const bVal = callProp(bprop)

		if(
			!(this.key in index)
			&& parentPath.every((ckey) => !(ckey in index))
			&& (

					Array.isArray(aVal)
					&& (

							Array.isArray(bParent)
							&& aVal.length != bParent.length

						|| 							Array.isArray(bVal)
							&& aVal.length != bVal.length

					)

				|| 					this.isLeaf
					&& !this.isRoot
					&& !Array.isArray(aVal)
					&& (aVal != bVal || !bprophas)

			)
		){
			if( true ){
				const s = x => JSON.stringify(x)

				difference[this.key] =
					bprop
						? s(aVal) + ' - ' + s(bVal) + ' - ' + this.path
						: s(aVal) + ' - ' + 'No Path' + ' - ' + this.path
			} else {

				difference[this.key] = true

				if( !returnKeys ){
					this.stop()
				}
			}
		}
	})

	if( DEV && IDENTICAL_LOGS && Object.keys(difference) != 0 ){
		// eslint-disable-next-line no-undef
		console.log(difference)
	}

	const result =
		returnKeys
		? Object.keys(difference)
		: Object.keys(difference).length == 0

	if(identicalProp){
		identicalProp(result)
		m.redraw()
	}

	return result
}



export { deBounceTraverse as deBounceIdentical, traverseCheck as identical }