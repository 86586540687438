import Request from '../request'

export default function service({ responses, auth }){
	return responses.map( x => {
		if ( auth.stream()._name != 'LoggedIn' ) return false;
		const [errorType] =
			[x].flatMap(
				x => Request.Response.isN(x) ? [x.value] : []
			)
			.map( x => x.response.body.type )

		return x.value.request.url.startsWith('/auth') && errorType  == 'AuthExpiredError'
	})
}