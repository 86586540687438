/* globals setTimeout, window, document */
import * as R from 'ramda'
import m from 'bacta'
import css from 'bss'


const hideScrollbars =
  css`
    -ms-overflow-style: none;
    scrollbar-width: none;
  `
  .$nest('::-webkit-scrollbar', `
    display: none;
  `)


function Main (
	{
		attrs: {
			scoped
			, resize$
			, preventBlurSelectors=['.scrollable-table-3', 'detailspane2']
			, alwaysopen=false
		}
	}
) {

	const activeRow = scoped()

	let colCount
	let incriment = 50
	let slicepostiona = 0
	let slicepostionb = incriment
	resize$.map(
		() => {
			// eslint-disable-next-line no-undef
			colCount = Math.max(1, Math.floor(window.innerWidth / 150) - 1)
			return null
		}
	)

	function globalBlur(event){
		if( !activeRow() == null ) return
		const prevented =
			preventBlurSelectors
				.flatMap(
					selector => Array.from(
						document.querySelectorAll(selector)
					)
				)
				.some(
					el => el.contains(event.target)
				)

		if( !prevented ) {
			activeRow(null)
			m.redraw()
		}
	}

	return {
		oncreate(){
			window.addEventListener('click', globalBlur)
		}
		,onremove(){
			window.removeEventListener('click', globalBlur)
		}
		,view({ attrs: { rows } }){

			const headers =
				rows.slice(0,1).flatMap( x => Object.keys(x.data) )

			const renderedRows =
				rows.length > incriment
				? rows.slice(slicepostiona, slicepostionb)
				: rows


			return m(''+css`
				padding-bottom: 4em;
				`, [
					m('.scrollable-table-3'
					+ css`
						display: grid;
						grid-template-columns: 1fr;
						max-width: 100vw;
						max-height: 40em;
						overflow-y: auto;
						overflow-x: hidden;
					`
					+hideScrollbars
					,renderedRows
						.map( (x, i) => ({ active: i === activeRow() || alwaysopen, x, i }) )
						.map( ({x, active, i }) =>
							m('.hth-row' + ( active ? '.hth-active' : '.hth-inactive' )
							+ css`
								display: grid;
								column-gap: 0.1em;
								--padding: 0.5em;
								--padding-left:
									${ x.selection ? `3.5em` : `var(--padding)` }
								box-shadow: 1px 3px 8px 1px #d4d4d4;
								opacity: 0.8;
								transition: 0.3s;
								padding: var(--padding);
								margin: 0em 0.25em 0.5em 0em;
								position: relative;
								padding-left: var(--padding-left);
								min-height: 3.5em;
							`
							.desktop(`
								margin: 0.5em;
							`)
							.$nest('&.hth-active', `
								border-left: solid 0.4em #130f3c;
							`)
							.$nest('&:nth-child(even)', `
								background-color: #f8f8f8;
								color: black;
							`)
							.$nest('.hth-header', `
								font-weight: bold;
							`)
							.$nest('&.hth-inactive:hover',`
								opacity: 0.9;
								transform: scale(0.98);
							`)
							.$nest('&.hth-inactive .hth-inner.standard', css`
								grid-auto-flow: column;
								column-gap: 1em;
								justify-content: space-between;
								grid-template-columns: ${
									`repeat(${Math.min(headers.length, colCount)}, 1fr);`
								}
							`
								.$media('(min-width: 1368px)',
									css`
										justify-content: space-between;
									`
								)
							)
							.$nest('&.hth-active .hth-inner.standard', css`
								grid-template-columns: repeat(3, 1fr);
								row-gap: 1em;
								justify-content: space-between;
								opacity: 1;
								column-gap: 1em;
								opacity: 1
							`
								.$media('(min-width: 1000px)', css`
									grid-template-columns: repeat(6, 1fr);
								`
								)
							)

							.$nest('.hth-cell', `
								overflow: hidden;
							`)
							.$nest('.hth-child', `
								white-space: nowrap;
								text-overflow: ellipsis;
								overflow: hidden;
							`)
								,Object.assign(
									{ tabindex: 0
									, onclick(e){
										e.redraw = false
										if(
											!['BUTTON', 'A', 'INPUT'].includes(
												e.srcElement.tagName
											)
										) {
											setTimeout(
												() => {
													activeRow(i)
													m.redraw()
												}
												, 1000 * 0.3
											)
										}
										// account for filtering / sorting
									}
									}
									, x.key
										? { key: x.key }
										: {}
									, x.attrs || {}
								)
								, x.header
									&& m('.header-outer'
										+ css`
											margin: 0.4em 0.5em 1.5em 0.1em;
											border-bottom: solid 1px rgba(0,0,0,0.1);
											padding-bottom: 0.2em;
											font-size: 1em;
										`
										.$nest('>*>*', `
											white-space: nowrap;
											overflow: hidden;
											text-overflow: ellipsis;

											max-width: 35vw;
										`)
										, x.header
									)
								, m('.hth-inner'
									+ (
										active && x.layouts && x.layouts.expanded
										? '.custom'
										: '.standard'
									)
									+ css`
										display: grid;
									`
									,x.selection
										? m('input'
											+ css.width(25).height(25)
											.$media('(hover: hover)',
												css.width(20).height(20)
											)
											.position('absolute')
											.transform(`translate(-32px, -5px)`)
											,
											x.selection
										)
										: null
									, active && x.layouts && x.layouts.expanded
									? x.layouts.expanded({
										x: x.data
										, key: x.key
										, colCount
										, i
										, headers
									})
									: headers
										.slice(
											0,
											active
											? headers.length
											: colCount

										)
										.map(
											(key) =>
												m('.hth-cell', { key: 'cell-'+key }
													,m('.hth-header'
														, { title: key }
														, key
													)
													,m('.hth-child'
														, { title:
															typeof x.data[key] == 'string'
																? x.data[key]
																: null
														}
														, x.data[key]
													)
												)
										)
								)
							)
					)
				)
				,rows.length > incriment
					? m('.data'
						+ css`
							display: grid;
							grid-template-columns: 0.4fr 0.2fr 0.4fr;
							justify-content: space-between;
							gap: 1em;
							margin-left: 45%;
							margin-right: 45%;
							padding-top: 2em;
						`
						.$nest('a', `
							user-select: none;
						`)
						,[
							m('a', {
									onclick: () => {
										slicepostiona = slicepostiona - incriment
										slicepostionb = slicepostionb - incriment
									}
									, title: "Previous items"
									, disabled: slicepostionb <= incriment
								}
								,'Prev'
							)
							,Math.floor(slicepostionb/incriment)
							,m('a', {
									onclick: () => {
										slicepostiona = slicepostiona + incriment
										slicepostionb = slicepostionb + incriment
									}
									, title: "Next items"
									, disabled: slicepostionb >= rows.length-1
								}
								,'Next'
							)
						]
					)
					: null
				]
			)
		}
	}
}

function advanced({
	scoped
	, resize$
	, rows
	, preventBlurSelectors
	, alwaysopen
}){
	return m(Main,
		{ scoped
		, resize$
		, rows
		, preventBlurSelectors
		, alwaysopen
		}
	)
}

function legacy(
	{ scoped, resize$, preventBlurSelectors }
	, headers
	, rows
) {
	const ourHeaders = headers.map( x => x.text )
	const ourRows = rows().map(
		x => R.zipObj(ourHeaders, x.children)
	)
	.map(
		x => ({ data: x })
	)
	return m(
		Main,
		{ scoped
		, resize$
		, rows: ourRows
		, preventBlurSelectors
		}
	)
}

function compat(){
	return {
		view(vnode){
			const args =
				[vnode.attrs].concat(
					vnode.children
				)

			return legacy(...args)
		}
	}
}

export default compat
compat.legacyRenderProps = true
compat.legacy = legacy
compat.scrollableTable = legacy
compat.advanced = advanced