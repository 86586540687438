import $ from 'sanctuary-def'
import SumType from 'sum-type-legacy'

const Sum = SumType($, { checkTypes: false, env: $.env }).Named

const a = $.TypeVariable('a')
const b = $.TypeVariable('b')

const T = (a, f) => f(a)
const I = x => x
const K = a => () => a
const B = (f,g) => x => f(g(x))
const C = f => x => y => f(y)(x)

const NominalMaybe = ({ name, Just, Nothing }) => {

	const $T = Sum('harth/'+name, {
		[Just]: { value: a }
		,[Nothing]: {}
	})

	const Y = $T[Just]
	const N = $T[Nothing]

	$T.$Type =
		$.UnaryType(
			'harth/'+name
			,''
			, Ma => [Ma]
				.filter( x => x != null)
				.map( Ma => Ma._name )
				.filter( a => [Just, Nothing].find( b => b == a) )
				.some( () => true )
			, x => x._name == Just ? [x.value] : []
		)

	$T.fold = (b, f) =>
		$T.case({
			[Just]: a => f(a)
			,[Nothing]: () => b
		})

	$T.discard = (b,a) =>
		$T.fold(
			b
			,K(a)
		)

	$T['is'+Just] =
		$T.fold( false, () => true )

	$T.map = f =>
		$T.fold(
			N()
			,a => Y(f(a))
		)

	$T.chain = f =>
		$T.fold(
			N()
			,f
		)

	$T.assert = f => a =>
		f(a)
		? Y(a)
		: N()

	$T.filter = f =>
		$T.chain($T.assert(f))

	$T.alt = (Ma, Na) =>
		$T.fold(
			// we use fold for type checking
			// could just be a `Na` otherwise
			$T.fold( Na, () => Na )(Na)
			,() => Ma
		)(Ma)

	$T.and = (Ma, Na) =>
		$T.chain( K(Na) )(Ma)


	if( name != 'Maybe'){
		$T.toMaybe =
			$T.fold(
				Maybe.Nothing()
				,Maybe.Just
			)
	}

	$T.toBoolean =
		$T.fold( false, () => true )

	$T['to'+name] = Unknown =>
		Unknown != null
		? Y( Unknown )
		: N()

	$T['from'+name] = b =>
		$T.fold( b, i => i )

	$T.swap = b =>
		$T.fold(
			Y(b)
			,() => N()
		)

	$T.toNullable =
		$T.fold( null, i => i )

	$T.zero = $T[Nothing]()
	$T.empty = $T[Just]()


	$T.join =
		$T.fold(
			$T.zero
			,$T.fold( $T.zero, i => Y(i) )
		)

	$T.nominal = { Just, Nothing }

	$T.all = (Ms) =>
		Ms.some( M => M._name == Nothing )
		? $T.zero
		: $T[Just](
			Ms.map( M => M.value )
		)

	return {
		[name]: $T
		, [Just] : Y
		, [Nothing]: N
	}
}

const {Maybe} = NominalMaybe({
	name: 'Maybe'
	, Just: 'Just'
	, Nothing: 'Nothing'
})

const NominalEither = ({name, Left, Right}) => {

	const $T = Sum('harth/'+name, {
		[Left]: { value: $.Any }
		,[Right]: { value: $.Any }
	})

	const Y = $T[Right]
	const N = $T[Left]

	$T.tagBy = f => x => f(x)
		? Y(x)
		: N(x)

	$T.fold = (Fac, Fbc) =>
		$T.case({
			[Left]: Fac
			,[Right]: Fbc
		})

	$T.chain = FbEc =>
		$T.fold(
			N
			,FbEc
		)

	$T.swap =
		$T.fold( Y, N )

	$T['from'+name] =
		$T.fold(I,I)

	$T.both = (f) =>
		$T.bimap(f,f)

	$T.bimap = (Fac, Fbc) =>
		$T.fold(
			a => N( Fac(a) )
			,b => Y( Fbc(b) )
		)

	$T.map = (Fbc) =>
		$T.bimap( i => i, Fbc)

	$T.toMaybe = $T.fold( () => Maybe.Nothing(), Maybe.Just )

	$T.swapToMaybe = $T.fold( Maybe.Just, () => Maybe.Nothing() )

	$T.nominal = { Left, Right }

	$T.$Type = $.BinaryType(
		'harth/'+name
		,''
		, Ma => [Ma]
			.filter( x => x != null)
			.map( Ma => Ma._name )
			.filter( a => [Left, Right].find( b => b == a) )
			.some( () => true )
		, e => e != null
			&& e._name === Left ? [e.value] : []
		, e => e != null
			&& e._name === Right ? [e.value] : []
	)

	$T.toBoolean = $T.fold(
		() => false
		,() => true
	)

	$T.toBoolean =
		$T.fold(
			K(false)
			,K(true)
		)

	return {
		[name]: $T
		, [Left]: $T[Left]
		, [Right]: $T[Right]
	}
}

const {Either} = NominalEither({
	name: 'Either'
	, Left: 'Left'
	, Right: 'Right'
})

const MaybeToMaybe = (T1, T2) => a =>
	T1.case({
		[T1.nominal.just]: T2[T2.nominal.just]
		,[T1.nominal.nothing]: () => T2[T2.nominal.nothing]()
	}, a)

const EitherToEither = (T1, T2) => a =>
	T1.case({
		[T1.nominal.left]: T2[T2.nominal.left]
		,[T1.nominal.right]: T2[T2.nominal.right]
	}, a)

const get = k => o => Maybe.toMaybe(o[k])
const head = xs => Maybe.toMaybe(xs[0])
const find = f => xs =>
	Maybe.toMaybe(xs.find( x => f(x) ))

const apply2 = f => ([x,y]) => f(x)(y)


const caseIs = ($T, caseName) => Ta =>
	$T.case({
		[caseName]: () => Maybe.Just(Ta)
		,_: () => Maybe.Nothing()
	}, Ta)

const caseIsNot = ($T, caseName) => Ta =>
	$T.case({
		[caseName]: () => Maybe.Nothing()
		,_: () => Maybe.Just( Ta )
	}, Ta)


const diff = x => y =>
	x === y ? Maybe.Nothing() : Maybe.Just(y)

//eslint-disable-next-line
export {
	Maybe
	,Either
	,NominalMaybe
	,NominalEither
	,MaybeToMaybe
	,EitherToEither
	,caseIs
	,caseIsNot
	,get
	,head
	,find
	,T
	,I
	,K
	,B
	,C
	,diff
	,a
	,apply2
	,b
}