/* globals Promise, setTimeout, console */
import m from 'bacta'
import * as R from 'ramda'
import { prop } from '../../../stream'
import manuel from '../../../manuel'
import css from 'bss'

const delay = ms =>
	new Promise( Y => setTimeout(Y, ms))

const defaultStyle =
	css`
		width: 100%;
		position: relative;
		outline: none;
	`
	.$nest('*', `
		box-sizing: border-box;
	`)
	.$nest('.highlight', `
		background-color: gold;
	`)
	.$nest('ul', `
		list-style: none;
		z-index: 3;
		padding-left: 0em;
		font-size: 1em;
		margin: 0em;
		width: 100%;
		transition: 500ms;
		position: absolute;
		background-color: white;
		transition: 0.3s cubic-bezier(.4,.2,.5,1.4);
		transform-origin: 1.43em -.43em;
		opacity: 0;
		transform: scale(0);
		display: block;
		transition-timing-function: ease;
	`)
	.$nest('&.loaded.open ul', `
		border: solid 1px rgba(40, 142, 197, 0.17);
		opacity: 1;
		transform: scale(1);
		overflow: hidden;
		border-radius: 0em;
		margin-top: 0.3em;
		padding: 9px;
		line-height: 2em;
	`)
	.$nest('li', `
		padding: 0.2em;
	`)
	.$nest('li:hover', `
		background: hsl(200, 40%, 80%);
		color: black;
	`)


const defaultInputStyle =
	css`
		height: 100%;
		width: 100%;
		border: 0px;
		box-shadow: none;
		border-bottom: solid 1px #CCC;
		border-radius: 0px;
		background: hsla(0,0%,100%,.2);
		transition: 0.3s;
		font-size: 1em;
	`
	.$focus(`
		outline: none;
		border: 0;
		border-bottom:: 1px solid hsl(200, 80%, 60%);
	`)


function Main({
	attrs: {
		list=prop([])
		,loading=prop(false)
		, onselect
		, oninput
		, field
		, sort
		, search=null
		, throttleSearch=500
		, manuelConfig={}
		, inputStyle=defaultInputStyle
		, rootStyle=defaultStyle.$nest('input', inputStyle).class
		, inputClassName=''
		, initialValue=''
	}
}){
	function data(original){
		return field
			? {
				label: original[field]
				,value: original
			}
			: {
				label: original
				,value: original
			}
	}
	function processRawList(list){
		return list.map(
			data || R.identity
		)
	}
	const processedList =
		list.map(processRawList)
	const byLabel =
		processedList.map(
			R.indexBy( x => x.label )
		)
	const computedList = byLabel.map(R.keys)
	if( !Array.isArray(computedList()) ){
		computedList([])
	}
	const model = {
		// the current value in the input field
		input: prop(initialValue || '')
		// the value of the currently selected
		// value in the suggestions list
		, highlighted: prop('')
		// the list of suggestions
		, list: computedList
		// whether or not the suggestions list should be open
		, open: prop(false)
		// The value of an item that was explicitly
		//  selected from the list
		, chosen: prop(null)
	}
	if( onselect ) {
		model.chosen.map(
			value => {
				if(value != null){
					onselect( byLabel()[value].value, model )
				}
				return null
			}
		)
	}
	if( oninput ) {
		model.input.map(
			value => oninput(value, model)
		)
	}

	let lastRequestTime = 0;
	if (search) {
		model.input.map( value => {

			if( processedList().find( x => x.label == value ) ){
				return null;
			}

			if( value == initialValue ) {
				return null;
			}

			if( !value ) {
				return null;
			}

			loading(true)
			m.redraw()
			const then = Date.now()

			let delayAmount =
				then - lastRequestTime
				> throttleSearch
				? 0
				: throttleSearch

			lastRequestTime = then

			delay(delayAmount).then( () => {
				if( lastRequestTime != then ) return null;

				return Promise.resolve(search(value, model))
			})
			.then( xs => {

				if( lastRequestTime != then ) return;

				if( xs && Array.isArray(xs) ) {
					loading(false)
					list(xs)
					m.redraw()
				}
			})
			.catch( console.error )

			return null
		})
	}
	const autocomplete = manuel({
		hyperscript: m
		,get: key => model[key]()
		,set: (key, value) => {
			model[key](value)
			m.redraw()
		}
	})
	const addAttrs = attrs =>
		manuel.queries.input(
			vnode => {
				vnode.attrs =
					Object.assign({}, vnode.attrs || {}, attrs)

				if( !('value' in vnode.attrs) ) {
					vnode.attrs.value = initialValue
				}
				return vnode
			}
		)
	const addBSS =
		manuel.queries.root(
			vnode => {
				vnode.attrs.className += ' ' + rootStyle
				return vnode
			}
		)

	const addInputClass =
		manuel.queries.input(
			vnode => {
				vnode.attrs.className += ' ' + inputClassName
			}
		)
	return {
		view({ attrs: { attrs: theirAttrs } }){
			const attrs =
				typeof theirAttrs == 'function'
					? theirAttrs()
					: theirAttrs
			return addInputClass(
				addBSS(
					addAttrs(attrs) (
						autocomplete({
							input: 'input'
							,highlighted: 'highlighted'
							,open: 'open'
							,chosen: 'chosen'
							,list: 'list'
						}, Object.assign(
							{ minChars: 0, sort }
							, manuelConfig
						)
						)
					)
				)
			)
		}
	}
}
/**
 * Only saves exact match objects to a prop
 */
function strict(
	list
	,prop
	,field
	,attrs
){
	return m(Main, {
		list: list
		,field
		,onselect(value, model){
			prop(value, model)
			setTimeout(() => model.open(false), 1000)
		}
		, attrs: () => attrs ? attrs() : {}
	})
}
/**
 * Saves any user input value to the prop regardless of a match
 */
function all(
	list
	,prop
	,field
	,attrs
){
	return m(Main, {
		list
		,onselect(value, model){
			prop( field ? value[field] : value, model)
		}
		,field
		,oninput(input, model){
			prop(input, model)
		}
		,attrs: () => attrs ? attrs() : {}
	})
}
export default
	{ strict
	, all
	, Main
	}