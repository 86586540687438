/* globals window */
import m from 'bacta'
import { prop as stream } from '../../stream'

m.withAttr = (attribute, callback) => event =>  {
	callback(event.target[attribute])
}

const hrefToURL = ({ href, prefix }) => (
	'/'
	+ href
)
	.replace(window.location.origin, '')
	.replace(prefix || '', '')
	.replace(/(\/)+/g, '/') // /#/ -> //
	.split('?')
	[0]
	.replace(/\/$/,'') // trailing

let Route;
let getState;

m.route = {
	buildQueryString: m.buildQueryString
	,parseQueryString: m.parseQueryString
	,set Route(x) {
		Route = x
	}
	,set getState(x){
		getState = x
	}
	,get Route(){
		return Route
	}
	,set(url){
		const route = Route.matchOr(() => null, url)

		if( route ) {
			Route.set( route )
		}
		return url
	}
	,link({ dom }){
		dom.addEventListener('click', function(e){
			const href = hrefToURL({ href: dom.href, prefix: '' })
			const route = Route.matchOr(() => getState().route, href)

			Route.set( route )
			return e.preventDefault()
		})
	}
	,get(){
		return Route && Route.toURL(getState().route)
	}
	,param(param){
		if( param in getState().route.value ) {
			return getState().route.value[param]
		} else {
			return new window.URLSearchParams(window.location.search).get(param)
		}
	}
}

Object.assign(m, {
	prop: stream
	,component: m
})