/* globals setTimeout */
import m from 'bacta'
import * as R from 'ramda'
import * as elements from '../components/elements'
import Responsive from '../components/responsive'
import HarthUppy from '../components/harth-uppy'
import NumberInput2 from '../components/number2'
import Form from '../components/form'
import { money as makeMoney } from '../utils/regExes'
import css from 'bss'
import Autocomplete from '../modules/autocomplete'
import * as uuid from 'uuid'
import * as ContextBar from '../components/context-bar'

function Main({
	attrs: {
		ctrl, discardProjectButton, saveProjectButton
	}
}){

	const duration = ctrl.model.data.scoped(0)

	const pdata =
		(p) => ({
			'Name':
				elements.textInput(
					p.project_name
					,{
						name: 'Project Name'
						,required: true
						,disabled:
							!ctrl.model.editPermissions()
							|| ctrl.model.metaDataLoading()
					}
				)
			,'Duration':
				m(NumberInput2.component, {
					name: 'Duration'
					,prop:
						(v) => {
							if( v ){
								p.project_start_date(new Date().getTime())
								p.project_dead_date(new Date().getTime()+v*1000*60*60*24)

								p.disciplines
								.forEach((d) => d.discipline_end_date(p.project_dead_date()) )

								duration(v)
							}
							return duration()
						}
					,suffix: () => ' (Days)'
					,title: "Duration of the project"
					// ,key: Math.random().toString(15).slice(2, 8)
					,novalidate: true
					,required: true
					,min: 0
				})
			,'Contract':
				Autocomplete.all(
					ctrl.model.data.contracts
					,(v) => {
						if (v){
							const cobj =
								ctrl.model.data.contracts()
								.find((u) => u.contract_name == v)
								|| { contract_id: uuid.v4(), contract_name: v}

							ctrl.model.form().project.contract_id( cobj.contract_id )
							ctrl.model.form().project.contract_name = cobj.contract_name
						}
						return v
							? v.contract_name
							: ctrl.model.form().project.contract_name
						}
					,'contract_name'
					, () => ({
						disabled: !ctrl.model.editPermissions()
							|| ctrl.model.metaDataLoading()
							|| ctrl.model.loading()
					})
				)
		})

	const addDisplinePlus = () =>
		m('.data'
			, m(`button`
				+ css`
					border-radius: 0.5em;
					background-color: white;
					border: solid 0.1px rgba(0,0,0,0.4);
					width: 1.8em;
					height: 1.8em;
				`
				,{
					onclick:  ctrl.model.addNewDiscipline
				}
				, '+'
			)
		)

	const removeDiscipline = (d, after=x=>x) =>
		m('.data'
			, m(`button`
				+ css`
					border-radius: 0.5em;
					background-color: white;
					border: solid 0.1px rgba(0,0,0,0.4);
					width: 1.8em;
					height: 1.8em;
				`
				.$nest('.no-border &', `
					border: none;
				`)
				,{
					onclick(){
						ctrl.model.checkMultiple().data = [d]
						return Promise.resolve(ctrl.intent.deleteDiscipline())
							.then( () => new Promise(Y => setTimeout(Y, 100)))
							.then( after )
					}
				}
				, '×' // ⨉
			)
		)

	const inlineHeader = (title='', content) =>
		title
		? m('.inline-header'
			+ css`
				display: grid;
				gap: 0em;
			`
			.$nest('.title', `
				font-size: 0.6em;
				text-transform: uppercase;
				color: rgba(0,0,0,0.5);
				letter-spacing: 1.3px;
				font-weight: 600;
			`)
			, m('.title', title)
			, m('.content', content)
		)
		: content

	const disciplineTable2 = ({ scanFormElements }) => {


		const bigMobile = () => mobile({ big: true })
		const smallMobile = () => mobile({ big: false })

		const mobile = ({ big=false }) =>
			m('.discipline-table'
				+ (big ? '.big' : '')
				+ css`
					display: grid;
					gap: 1em;
				`
				.$nest('.data-rows', `
					display: grid;
					gap: 1em;
				`)
				.$nest('.data-row', `
					display: grid;
					gap: 1em;
					padding: 1em;
					box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.1);
					position: relative;
				`)
				.$nest('.actions', `
					position: absolute;
					top: 0.3em;
					right: 0.3em;
					z-index: 1;
				`)
				.$nest('.data-row>.group', `
					display: grid;
					justify-content: space-between;
					gap: 1em;
				`)
				.$nest('.data-row>.group.top', `
					grid-template-columns: 0.5fr 0.25fr 0.25fr;
					align-items: center;
				`)
				.$nest('.slash', `
					text-align: center;
				`)
				.$nest('.data-row>.group.middle', `
					grid-template-columns: 6em 1em 1fr;
					align-items: end;
				`)
				.$nest('&.big .data-row>.group.middle', `
					grid-template-columns: 6em 1em 1fr 1fr 1fr;
					align-items: end;
				`)
				.$nest('&.big .total-row', `
					grid-template-columns: 6em 1em 1fr 1fr 1fr;
					align-items: end;
				`)
				.$nest('.data-row>.group.bottom', `
					grid-template-columns: 1fr 1fr;
					align-items: end;
				`)
				.$nest('.total-row',`
					display: grid;
					justify-content: space-between;
					grid-template-columns: 1fr 1fr;
					padding: 1em;
					gap: 1em;
				`)
				.$nest('.total-row>*', `
					text-align: end;
				`)
				.$nest('.data-row:focus-within .inline-header .title', `
					color: var(--color-mid);
				`)
				, m('.data-rows'
					, ctrl.model.form().project.disciplines.slice().map( (d, i) =>
						m('.data-row'
							,m('.actions.no-border'
								, removeDiscipline(d, i)
							)
							,m('.top.group'
								, inlineHeader('Name', disciplineName(d,i))
								, inlineHeader('Workers', assignSomeone(d,i))
								, inlineHeader(' ', assignees(d,i))
							)
							,m('.middle.group'
								, inlineHeader('Amount', workAmount(d,i))
								, inlineHeader('', m('.slash', '/'))
								, inlineHeader('Unit of Measure', uom(d, i))
								, big && inlineHeader('Pay Rate', payRate(d,i))
								, big && inlineHeader('Charge Rate', chargeRate(d,i))
							)
							, !big && m('.bottom.group'
								, inlineHeader('Pay Rate', payRate(d,i))
								, inlineHeader('Charge Rate', chargeRate(d,i))
							)
						)
					)
				)
				, m('.total-row'
					, big && m('.spacer')
					, big && m('.spacer')
					, big && m('.spacer')
					, inlineHeader('Total Pay', totalPay())
					, inlineHeader('Total Charge', totalCharge())
				)
			)

		const removeItem = (d, i) =>
			m('.verb'
				,{ key: 'disciplines.'+i+'.removal' }
				, removeDiscipline(d, scanFormElements)
			)

		const disciplineName = (d,i) =>
			m('.value'
				,{ key: 'disciplines.'+i+'.discipline_name' }
				, ctrl.model.disciplineSelectAutocomplete(d, {
					options: {
						inputStyle: css``
						,inputClassName: 'form-control'
					}
					,attrs: {
						style: {} // overwrite height=3em
						,name: 'Discipline Name'
						,id: 'disciplines.'+i+'.discipline_name'
						,required: true
					}
				})
			)

		const workAmount = (d,i) =>
			m('.value.numeric'
				,{ key: 'disciplines.'+i+'.discipline_planned' }
				, m(NumberInput2.component, {
					prop: d.discipline_planned
					,name: 'Work Amount'
					,id: 'disciplines.'+i+'.discipline_planned'
					,moneyMode: true
					,min: 0.01
					,title: "Amount of work there is to complete"
					,toFixed: 2
				})
			)

		const assignSomeone = (d) =>
			m(``
				,{ key: d.key + 'assigned' + ctrl.model.userSelectionIndex()[d.key]().length }
				,Autocomplete.strict(
					ctrl.model.userSelectionIndex()[d.key]
					,(v, model) => {

						if(
							v.user_id()
							&& !d.users().find((du) =>
								du.user_id()
								== v.user_id()
							)
						){
							d.users().unshift(v)
							ctrl.model.users(ctrl.model.users())

						}

						model.input('')
						model.chosen(null)

					}
					, 'usersFullName'
					, () => ({
						placeholder: ""
						,disabled:
							!ctrl.model.userSelectionIndex()[d.key]
							|| !ctrl.model.userSelectionIndex()[d.key]
							&& !ctrl.model.userSelectionIndex()[d.key]().length
						,title: "Assigned to task"
						,id: d.key + 'assigned' + ctrl.model.userSelectionIndex()[d.key]().length
					})
				)
			)

		const assignees = (d) =>
			m(``
				,{key: d.key + 'assignees' + ctrl.model.userSelectionIndex()[d.key]().length}
				,d.users().length > 0
				&& elements.inlineStrikeList2(
					d.users()
					.map(
						od => ({
							label: od.user_username()
							,action: () => {
								d.users()
								.splice(
									d.users()
									.findIndex((ds) =>
										ds.user_id()
										== od.user_id()
									)
									,1
								)
							}
						})
					)
				)
			)

		const uom = (d,i) =>
			m(''
				,{ key: 'disciplines.'+i+'.discipline_uom' }
				,m(Autocomplete.Main, {
					list: ctrl.model.organizations_disciplines
					,field: 'organizations_disciplines_uom'
					,onselect(x){
						d.discipline_uom(x.organizations_disciplines_uom)
					}
					,oninput(x){
						x && d.discipline_uom(x)
					}
					,inputStyle: css`
						height: auto;
						text-align: end;
						line-height: calc(1.6em - 1px);
					`
					,inputClassName: 'form-control'
					,initialValue: d.discipline_uom()
					,attrs: {
						name: 'Unit of Measure'
						,id: 'disciplines.'+i+'.discipline_uom'
						,value: d.discipline_uom()
					}
				})
			)

		const payRate = (d,i) =>
			m('.value.numeric'
				,{ key: 'disciplines.'+i+'.organizations_disciplines_nominal_expenditure_rate' }
				, m(NumberInput2.MoneyInput, {
					prop:
						(v) => {
							v != null
							? d.organizations_disciplines_nominal_expenditure_rate(
								Math.abs(v) * -1
							)
							: null
							return d.organizations_disciplines_nominal_expenditure_rate()
						}
					,name: 'Pay Rate'
					,id: 'disciplines.'+i+'.organizations_disciplines_nominal_expenditure_rate'
					,moneyMode: true
					,max: 0
					,title: `Average amount that would be paid when a team or resource conducts this discipline. May be altered for specific teams via financials and the contract selection`

				})
			)

		const chargeRate = (d,i) =>
			m('.value.numeric'
				,{ key: 'disciplines.'+i+'.organizations_disciplines_nominal_revenue_rate' }
				, m(NumberInput2.MoneyInput, {
					prop: (value) => ctrl.model.disciplineChargeRate(value, [d])
					,name: 'Charge Rate'
					,id: 'disciplines.'+i+'.organizations_disciplines_nominal_revenue_rate'
					,min: 0
					,title: `Average amount to charge clients when this disciplines is used. May be altered for specific clients via financials and the contract selection`
				})
			)

		const totalPay = () =>
			m('.value.numeric'
				, m('.data'
					,makeMoney(
						R.sum(ctrl.model.form().project.disciplines.map( (d) =>
							d.discipline_planned()
							* d.organizations_disciplines_nominal_expenditure_rate()
						))
					)
				)
			)

		const totalCharge = () =>
			m('.value.numeric'
				, m('.data'
					,makeMoney(
						R.sum(ctrl.model.form().project.disciplines.map((d) =>
							d.discipline_planned()
							* d.organizations_disciplines_nominal_revenue_rate()
							* d.discipline_contract_multiplier()
						))
					)
				)
			)

		const tablet = () =>
			m('.discipline-table.tablet'
				+ css`
					--verb: 2em;
					--name: 5em;
					--uom: 5em;
					--work: 5em;
					--pay: 5em;
					--charge: 5em;
					--assign: 8em;
					--assignee: 15em;
					display: grid;
					gap: 1em;
				`
				.tablet`
					--name: 16em;
					--work: 6em;
					--pay: 6em;
					--charge: 6em;
					--uom: 9em;
					--assign: 8em;
					--assignee: 15em;
				`
				.$nest('.header-row,.data-row,.total-row', `
					display: grid;
					grid-template-columns: var(--verb) var(--name) var(--work) var(--uom) var(--pay) var(--charge) var(--assign) var(--assignee);
					justify-content: space-between;
					align-items: end;
				`)
				.$nest('.numeric', `
					text-align: right;
				`)
				.$nest('.numeric *', `
					text-align: right;
				`)
				, m('.header-row'
					, m('.spacer')
					, m('.header', 'Name')
					, m('.header.numeric', 'Work Amount')
					, m('.header.numeric', 'Unit of Measure')
					, m('.header.numeric', 'Pay Rate')
					, m('.header.numeric', 'Charge Rate')
					, m('.header', 'Workers')
					, m('.header', ' ')
				)
				, m('.data-rows'
					, ctrl.model.form().project.disciplines.slice().map( (d, i) =>
						// .row is bootstrap 😡
						m('.data-row'
							, { key: d.key || d.id }
							, removeItem(d, i)
							, disciplineName(d, i)
							, workAmount(d, i)
							, uom(d, i)
							, payRate(d, i)
							, chargeRate(d, i)
							, assignSomeone(d,i)
							, assignees(d,i)
						)
					)
				)
				, m('.total-row'
					, m('.verb'
						, addDisplinePlus()
					)
					, Array(3).fill().map( () =>
						m('.value.spacer'
							, m('.data', '')
						)
					)
					,totalPay()
					,totalCharge()
				)
			)

		return m(Responsive, {
			breakpoints: {
				smallMobile: 0
				,bigMobile: 500
				,tablet: 700
			}
		}, ({ smallMobileOnly, bigMobileOnly, tablet: gtMobile }) => [
			smallMobileOnly(smallMobile)
			, bigMobileOnly(bigMobile)
			, gtMobile(tablet)
		])
	}


	const legacy_errored = () =>
		ctrl.model.SaveDisabled(true)
		|| ctrl.model.check_dnames(true)
		|| ctrl.model.checkTaskDates(true)

	const view = () =>
		m(Form
			, { toRamdaPath: x => [x] } , ({ errors, errored, scanFormElements }) => [
			m('div.initial-project-view'
				+ css`
					display: grid;
					grid-template-columns: 1fr;
					justify-content: center;
					/* this lets us have position sticky later */
					position: relative;
					gap: 2em;
				`
				.tablet`
					/*
						This is a trick to make the content centered with a
						max width.  Effectively the container is full width
						because thats what grid does by default, but we've said
						the column width is max 60em

						No margins, no need for lots and lots of different
						breakpoints.
					*/
					grid-template-columns: minmax(0em, 90em);
				`
				.$nest('.sticky-header', `
					position: sticky;
					top: -1em;
					z-index: 2;
					background-color: white;
				`)
				, m('.project-details',
					m('.sticky-header'
						, m('h1'
							+ css`
								margin: 0em;
							`
							, 'Project Details'
						)
					)
					,m('.data'
						+ css`
							display: grid;
							grid-template-columns: 1fr;
							justify-content: space-between;
							gap: 5em;
							padding: 1em 0em;
						`
						// Keep this stuff as a once off override for now
						// We'll fix it via css vars organized centrally later
						.tablet(
							css`
								grid-template-columns: minmax(15em, 35em) 20em 15em;
								gap: 2em;
							`
							// number input align height with bootstrap input
							.$nest('&& .number-input', `
								grid-template-rows: 34px;
							`)

							// contract input align height with bootstrap input
							.$nest('&& .manuel-complete', `
								height: 34px;
							`)


						)
						,[
							{
								label: 'Name'
								, key: 'Name'
								, desc: 'A memorable name for your project'
								, subdesc: 'You can change this at any time.'
								, addon: null
							}
							,{
								label: 'Forecasted Duration'
								,key: 'Duration'
								,desc: 'Estimate how long your project will take to complete.'
								,subdesc: 'This helps Odin automatically generate a schedule for your project.'
								,addon: null
							}
							,{
								label: 'Contract'
								,key: 'Contract'
								,desc: 'Stores financial information for forecasting and invoicing'
								,subdesc: 'This helps automate tenders'
								,addon: null
							}
						]
						.map(({ key, label, desc, subdesc, addon }) =>
							m('.data.no-validate'
								+ css`
									display: grid;
									grid-template-rows: 0.2fr 0.2fr 4fr;
									gap: 0.5em;
								`
								,[
									m('label.control-label', label)
									,m('.addon'
										+ (
											addon
											? css`
												position: relative;
												display: grid;
												align-items: center;
												grid-template-columns: 1fr 0em;
											`
											: ''
										)
										, pdata(ctrl.model.form().project)[key]
										, m(''
											+ css`
												right: 1em;
												position: absolute;
											`
											, addon
										)
									)
									,m('.info'
										+ css`
											display: grid;
											gap: 0.5em;
											align-content: start;
										`
										.$nest('.subdesc', `
											color: #717171;
										`)
										, m('.desc', desc)
										, m('.subdesc', subdesc )
									)
								]
							)
						)
					)
				)
				,m('.project-files'
					+ css`
						display: grid;
						justify-content: start;
						gap: 1em;
						grid-template-columns: 1fr;
					`
					,m(''
						,m('label.control-label', 'Project Files')
						,m('.desc', 'Upload project plans, photos, spreadsheets, anything that would be helpful for this project.')
					)
					,m(Responsive, { breakpoints: { mobile: 0, tablet: 850 } }, ({ tablet, mobileOnly }) => [
						mobileOnly( () =>
							m('.uppy-wrapper'
								+ css`
									min-height: 350px;
									border-radius: 0.5em;
									display: grid;
									grid-template-columns: minmax(0px, 400px);
									justify-content: center;
								`
								,m(HarthUppy, {
									getFields: () => ctrl.model.uploadmetadata({uploadbox: 'projectfiles'})
									, data: ctrl.model.data
									, width: 400
									, height: 350
								})
							)
						)
						,tablet( () =>
							m(''
								+ css`
									display: grid;
									min-height: 350px;
									grid-template-columns: 840px;
									grid-template-rows: min(350px, max-content);
									justify-content: center;
									align-items: center;
								`
								,m('.uppy-wrapper'
									+ css`
										height: 100%;
										width: 100%;
										border-radius: 0.5em;
									`
									,m(HarthUppy, {
										getFields: () => ctrl.model.uploadmetadata({uploadbox: 'projectfiles'})
										, data: ctrl.model.data
										, width: 840
										, height: 350
									})
								)
							)
						)
					]
					)
				)
				,m('.discipline-table'
					+ css`
						transition: 0.2s;
						display: grid;
						gap: 1em;
					`
					// + css`
					// 	opacity: ${ duration() ? 1 : 0.4 };
					// `
					,m('.sticky-header'
						+ css`
							display: grid;
							justify-content: space-between;
							grid-auto-flow: column;
							align-items: center;
						`
						,m('h1'
							+ css`
								margin: 0em;
							`
							, 'Project Disciplines'
						)
						,addDisplinePlus()
					)
					,m('.desc', 'Types of work involved in completing your project, including how much work there is to do, what they are paid, and how much they cost.')
					, true
						? disciplineTable2({ scanFormElements })
						: null
				)

				,m(ContextBar.Content, {
					icon: errored() ? ContextBar.icon.error() : ContextBar.icon.info()
					, message:
						errored()
							? (Object.values(errors())[0] || '')
								.replace('Please fill out this field', 'must be completed')
							: legacy_errored()
					, actions: [
						discardProjectButton(ctrl)
						,saveProjectButton(ctrl)
					]
				})
			)
		])

	return {
		view
	}
}

export default Main