import localStorage from './localStorage.js'
import m from 'bacta'
export default function sql(strings, ...values) {
	let session_id = localStorage.getItem('how.session_id')
	if (session_id == 'null') {
		session_id = null
	}
	let device_id = localStorage.getItem('how.device_id')
	if (device_id == 'null') {
		device_id = null
	}
	return m.request({
		method: 'POST'
		,url: '/api/sql'
		,body: { strings, values, session_id },
	}).then(({ type, data }) => {
		if (type == 'data') {
			return data
		} else if (type == 'error') {
			const err = new Error(data.message)
			err.stack = data.stack
			throw err
		} else {
			throw new Error('Internal Server Error')
		}
	})
}