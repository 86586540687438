import m from 'bacta'
import css from 'bss'
import { prop } from '../../../stream'

const truncate =
  css`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `

const Container =
  'detailspane2'
  + css`
    z-index: 1;
    min-height: 100vh;
    max-height: 100vh;
    bc: white;
    position: absolute;
    box-shadow: -2px -2px 7px 0px rgba(0,0,0,0.1);
    overflow-x: hidden;
    overflow-y: auto;
    bc: #F4F4F4;
    top: 0em;
    right: 0em;
    transform: translateX(100%);
    transition: 0.5s;
    top: var(--top-nav-height);
    min-height: calc(100vh - var(--top-nav-height));
    max-height: calc(100vh - var(--top-nav-height));
    width: 100vw;
  `
  .$media('(min-width: 600px)', `
    width 22em;
  `)
  .$nest('&.open', `
    transform: translateX(0%);
  `)

const ContentPane =
  'detailspane2-scroll'
  + css`
    w: 100%;
    top: 0px;
    left: 0px;
    display: block;
    margin-bottom: 2em;
    --padding: 1em;
    padding: 0em var(--padding);
    padding-bottom: var(--context-bar-height);
  `
  .$media('(min-width: 600px)', `
    padding-bottom: 0em;
  `)

const HeaderPane =
  'detailspane2-header'
  + css`
    w: 100%;
    h: 3em;
    z-index: 1;
    display: block;
    position: sticky;
    top: 0px;
    background-color: white;
    width: 100%;
    display: grid;
    justify-content: start;
    align-items: center;
    box-shadow: 0px 2px 2px 0px rgba(0,0,0,0.05);
    padding-left: 1em;
  `
  + truncate

const stream = prop(() => null)

const fade = vnode => {
  vnode.attrs.className
    += ' '
    + css.$animate('0.5s ease-in-out forwards', {
      from: 'o 0', to: 'o 100'
    })

  vnode.attrs.onbeforeremove =
    css.$animate.out('0.5s ease-in-out forwards', {
      from: 'o 100', to: 'o 0'
    })

  return vnode
}

const openWatch = prop()

prop.dropRepeats(openWatch).map(
  () => m.redraw()
)

function Unstyled(
  Container
  ,HeaderPane
  ,ContentPane
){

  return function Main(open, header, children, attrs = {}){
    openWatch(open())
    stream( () =>
      m(Container
        + (open() ? '.open' : '')
        , attrs
        , open() && fade(m(HeaderPane, typeof header == 'function' ? header() : header )
          )
        , open() && fade(m(ContentPane, typeof children == 'function' ? children() : children ))
      )
    )
    return m('.details-pane-portal'
      + css`
        position: absolute;
      `,
      { onremove: () => {
        stream( () => null )
        open( false )
      }
      }
    )
  }
}

export const Light = Unstyled(
  Container
  ,HeaderPane
  ,ContentPane
)

export const Dark = Unstyled(
  Container.replace('.bg-white','.hth-bg-dark')
  ,HeaderPane
  ,ContentPane+'.black'
)

export { stream }
