/* globals Promise */
import * as R from 'ramda'
import h, * as H from '../../how/index'

// todo-james this is until I remove buildQueryString from the app completely
import m from 'bacta'
const p = R.pipe
import * as uuid from 'uuid'
import traverse from 'traverse'
import { prop as stream } from '../../stream'
/*
    used as a default function for a bunch of situations
*/
const empty = R.always("")
function normalizeErrorFormat(e){
    return new Error(
        e
            ? e.error
            : 'Unknown problem encountered.  Please reload the page.'
    )

}

// session_id -> { [uuid]: {
//    transport: Transport
//    , when: Epoch
//    , method: string
//    , url: string
// }}
//
const requests = {}
const requestStream = stream()
const responseStream = stream()

function Request({ url, method, body, baseURL }){
    return { url, method, body, baseURL }
}

let Response; {
    let $ = H.options.either('Response')
    let {N} = $
    $ = {
        ...$
        ,N({ request, response={ body:null, status: 500 } }){
            let message =
                response.body
                ? response.body.message || response.body.error
                : null

            return N({
                request: Request(request), response, message
            })
        }
        ,infer(request, x){
            if( x.status > 199 && x.status < 300 ) {
                return Response.Y({ request, response: x })
            } else {
                return Response.N({ request, response: x })
            }
        }
    }
    Response = $
}

function buildRequest(
    baseURL=""
    , headers={}
    , method='GET'
    , url=""
    , body={}
){

    const queryMerge = p(
        R.filter( x => x != null )
        ,m.route.buildQueryString
        ,R.when( Boolean, R.concat('?'))
    )

    const bodyMerge = p(
        R.objOf('data')
    )

    const [buildParams, buildBody] = method == 'GET'
        ? [queryMerge, empty]
        : [empty, bodyMerge ]

    const payload = R.mergeAll([
        { method }
        ,buildBody(body)
    ])

    const URL = (baseURL + url + buildParams(body))
        .replace('??','?')

    return { url: URL, method, headers, data: payload.data }
}

function MetadataTracker(){
    const requestedAt = Date.now()


    function removeMetaData(request){
        traverse(request).forEach(function(){
            const that = this
            if( that.key == '__requestedAt' ){
                that.remove()
            }
        })
        return request
    }


    function addMetaData(response){
        traverse(response).forEach(function(x){
            const that = this
            if( that.notLeaf && !Array.isArray(x) ){
                x.__requestedAt = requestedAt
            }
        })

        return response
    }


    return {
        removeMetaData
        ,addMetaData
    }
}

function request(
    baseURL=""
    , headers={}
    , method='GET'
    , url=""
    , body={}
    , transportProp
){
    const id = uuid.v4()
    const config = buildRequest(
        baseURL, headers, method, url, body
    )

    // mithril v1 -> mithril v2 hack
    config.body = config.data

    const promise = h.request(
        R.merge(
            config
            , { config: function(transport){
                requests[id] =
                    { id
                    , transport
                    , when: Date.now()
                    , method, url
                    }

                transport.addEventListener('abort', function handler(){
                    delete requests[id]

                    // probably not necessary
                    transport.removeEventListener('abort', handler)
                })

                if( transportProp != null ){
                    transportProp(transport)
                }

            }
            , background: true
            , withCredentials: true
            , extract: (xhr) => {
                return {
                    status: xhr.status
                    , body: JSON.parse(xhr.responseText)
                }
            }

            }
        )
    )

    const requestObject = {
        url, method, body, baseURL
    }
    requestStream(requestObject)

    // Note: This may seem more complicated than necessary but we're
    // fighting with Mithril 0.2x Promise implementation
    return new Promise(function(Y,N){
        promise
        .then( r => r )
        .then( (r) => {
            if ( id in requests ){
                delete requests[id]

                const response = Response.infer(requestObject, r)
                responseStream(response)

                if ( Response.isY(response) ) {
                    return Y(r.body)
                } else {
                    throw r.body
                }
            } else {
                return null // never respond
            }
        })
        .catch(function(e){
            requests; id; method; url;
            if( id in requests || e != null ){
                delete requests[id]
                N(normalizeErrorFormat(e))
            }
            return null // never respond
        })
    })

}

export default {
    request
    ,buildRequest
    ,requests
    ,MetadataTracker
    ,requestStream
    ,responseStream
    ,Response
}
