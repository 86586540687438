import m from 'bacta'
import * as R from 'ramda'
import * as elements from '../../components/elements'
import { money as makeMoney } from '../../utils/regExes'
import { Dark as newDetailsPane } from '../../components/detailspane2'
import moment from 'moment'
import { prop as stream } from '../../../../stream'

function DetailsPane({ attrs: world }){

	const active =
		world.selectedProject.map(Boolean)

	const selectedAllocationDiscipline =
		world.selectedAllocationDiscipline

	const heading =
		world.selectedProject.map(
			p => p ? p.project_name : ''
		)

	const fieldView =
		([heading, value]) =>
			[ elements.list([
				m('label.control-label', heading+':')
				,value
			])
			]
			.map(
				list => {

					list.attrs.style =
						Object.assign({}, list.attrs.style, {
							maxHeight: '20em'
							,overflowY: 'auto'
						})
					return list
				}
			)

	const hashToFieldViews = function(hash) {
		return hash
			? R.toPairs(hash).map(fieldView)
			// [
			// 	R.toPairs(hash).map(fieldView)
			// 	,elements.selectbuttons(
			// 		['Total Period', 'Work-Day Average']
			// 		,dailyAverage
			// 		,{
			// 			disabled: false
			// 			,title: `Calulation representation`
			// 		}
			// 	)
			// ]
			: null
	}


	const completionPercentage = function(project){
		return (project.project_completion * 100).toFixed(2) + '%'
	}

	const localDate = (v) => moment(v).format("ll")

	const forecastEnd = function( project ) {
		return localDate(
			R.last(
				world.byProject()[ project.project_id ]
			)
			.allocations_allocation_date
		)
	}

	const dailyAverage = world.scoped('Total Period')

	const disciplineCalculated =
		stream.merge(
			[
				world.selectedAllocation
				,world.coalatedSelectedVersion
			]
		).map(([a, c]) =>
			a && a.discipline_id
				? c[a.discipline_id] || {}
				: {}
		)

	const disciplineCalculatedView =
		stream.merge(
			[
				dailyAverage
				,disciplineCalculated
			]
		).map(([d, p]) =>
			d == 'Total Period'
				? makeMoney(p.accruedProfits) + " | "
					+ makeMoney(p.outstandingProfits) + " | "
					// makeMoney(p.currentProfits) + " | " +
					+ makeMoney(p.forecastedProfits)
				: makeMoney(p.accruedDailyProfits)
					+ " | " + makeMoney(p.outstandingDailyProfits)
					// + " | " + makeMoney(p.currentDailyProfits)
					+ " | " + makeMoney(p.forecastedDailyProfits)
		)

	const projectCalculated =
		stream.merge(
			[
				world.selectedAllocation
				,world.selectedProject
				,world.coalatedSelectedVersion
			]
		).map(([a, p, c]) =>
			a && a.project_id
				? c[a.project_id] || {}
			: p && p.project_id
				? c[p.project_id] || {}
				: {}
		)

	const projectCalculatedView =
		stream.merge(
			[
				dailyAverage
				,projectCalculated
			]
		).map(([d, p]) =>
			d == 'Total Period'
				? makeMoney(p.accruedProfits) + " | "
					+ makeMoney(p.outstandingProfits) + " | "
					// makeMoney(p.currentProfits) + " | " +
					+ makeMoney(p.forecastedProfits)
				: makeMoney(p.accruedDailyProfits)
					+ " | " + makeMoney(p.outstandingDailyProfits)
					// + " | " + makeMoney(p.currentDailyProfits)
					+ " | " + makeMoney(p.forecastedDailyProfits)
		)


	const todaysInvoices = stream.merge(
			[
				world.selectedAllocation
				,world.calculatedData
				,world.selectedVersion
			]
		).map(([a, cd, sv]) =>
			a && a.allocation_id
				? (cd[sv.schedule_version_id + '0Infinity'] || {focussedInvoices:[]})
				['focussedInvoices']
				.filter((i) =>
					i.allocation_id()
					== a.allocation_id
				)
				: []
		)

	const allocatioCrewnData =
		world.selectedAllocation.map((a) =>
			a
				? R.unnest(
					a.allocations_contractors
						.map((ac) =>
							ac.allocations_contractors_crews
						)
				)
				: []
		)

	const disciplineContract =
		stream.merge(
			[
				world.allContractItemsIndexByItemID
				,world.selectedAllocation
			]
		).map(([allContractItemsIndexByItemID, a]) =>
			a
				? allContractItemsIndexByItemID[a.organizations_disciplines_id] || []
				: []
		)

	const crewDayContracts =
		stream.merge(
			[
				world.allContractItemsIndexByItemID
				,allocatioCrewnData
				,world.auth.stream
			]
		).map(([allContractItemsIndexByItemID,allocatioCrewnData, auth]) =>
			R.unnest(
				allocatioCrewnData.map((acc) =>

						acc.user_id
						== auth.user_id
						|| world.readContractPermissions()

						? allContractItemsIndexByItemID[acc.crew_id] || []
						: []
				)
			)
		)

	const crewWorkContracts =
		stream.merge(
			[
				world.allContractItemsIndexByItemID
				,allocatioCrewnData
				,world.auth.stream
			]
		).map(([allContractItemsIndexByItemID, allocatioCrewnData, auth]) =>
			R.unnest(
				allocatioCrewnData.map((acc) =>

						acc.user_id
						== auth.user_id
						|| world.readContractPermissions()

						? allContractItemsIndexByItemID[
							acc.crews_discipline_rates[0].crews_discipline_rates_id
						] || []
						: []
				)
			)
		)

	const userDayContracts =
		stream.merge(
			[
				world.allContractItemsIndexByItemID
				,world.auth.stream
				,world.timesheets
			]
		).map(([allContractItemsIndexByItemID, auth, timesheets]) =>
			R.unnest(
				timesheets.map((t) =>
					t.user_id
					== auth.user_id
					|| world.readContractPermissions()
						? allContractItemsIndexByItemID[t.user_id()] || []
						: []
				)
			)
		)

	const resourceContracts =
		stream.merge(
			[
				world.allContractItemsIndexByItemID
				,world.auth.stream
				,world.selectedAllocation
			]
		).map(([allContractItemsIndexByItemID, auth, a]) =>
			R.unnest(
				a
					? a.allocations_contractors.map((ac) =>

							ac.user_id
							== auth.user_id
							|| world.readContractPermissions()

							? allContractItemsIndexByItemID[ac.contractor_id] || []
							: []
					)
					: []
			)
	)

	const allocationDollarsArray =
		stream.merge(
			[
				userDayContracts
				,crewDayContracts
				,crewWorkContracts
				,disciplineContract
				,todaysInvoices
				,world.selectedAllocation
				,world.timesheets
				,allocatioCrewnData
				,resourceContracts
			]
		).map(([
			userDayContracts
			,crewDayContracts
			,crewWorkContracts
			,disciplineContract
			,todaysInvoices
			,a
			,timesheets
			,allocatioCrewnData
			,resourceContracts
		]) =>
			a && a.allocations_allocation_date
				? R.unnest(
					[].concat(
						userDayContracts
						,crewDayContracts
						,crewWorkContracts
						,disciplineContract
						,todaysInvoices
						,resourceContracts
					)
					.filter((cItems) => cItems.contract_items_applied != false )
					.map((contractItem) => {

						// when resources are deleted and old versions still have resources the below errors at crews_discipline_rates[0]
						const fAmount = !contractItem.crew_id && !contractItem.user_id
							? contractItem.organizations_disciplines_id
								? R.sum(
									allocatioCrewnData.map((acc) =>
										acc.allocations_contractors_crews_work_forecasted
										* acc.allocations_contractors_crews_work_application
										|| 0
									)
								)
								: allocatioCrewnData.find((acc) =>
									acc.crews_discipline_rates[0]
									&& acc.crews_discipline_rates[0].crews_discipline_rates_id
									== contractItem.crews_discipline_rates_id
								) || {
									allocations_contractors_crews_work_forecasted: 0
									,allocations_contractors_crews_work_application: 1
								}
							: 1


						return world.criteriaBasedCalculation(
							contractItem
							, contractItem.invoice_id
								? {
									incriment: 'invoice'
									,forecastedWorkAmount: null
									,forecastedDamagesAmount: null
								}
								: {
									incriment: 'contract'
									,forecastedWorkAmount:
										!contractItem.crew_id && !contractItem.user_id
											? contractItem.organizations_disciplines_id
												? fAmount
												: fAmount.allocations_contractors_crews_work_forecasted
												* fAmount.allocations_contractors_crews_work_application
											: 1

									,accruedAmount: !contractItem.crew_id && !contractItem.user_id
										? contractItem.organizations_disciplines_id
											? R.sum(
												allocatioCrewnData.map((acc) =>
													acc.allocations_contractors_crews_work_completed || 0
												)
											)
											: (
												allocatioCrewnData.find((acc) =>
													acc.crews_discipline_rates[0].crews_discipline_rates_id
													== contractItem.crews_discipline_rates_id
												) || {allocations_contractors_crews_work_completed: 0}
											).allocations_contractors_crews_work_completed
										: contractItem.contract_items_recorded_reference != 'Timesheets'
											? 1
											: contractItem.crew_id
												? timesheets.filter((t) =>
													t.crew_id() == contractItem.crew_id
													&& t.allocation_id() == a.allocation_id
												).length
												: timesheets.filter((t) =>
													t.user_id() == contractItem.user_id
													&& t.allocation_id() == a.allocation_id
												).length

									,forecastedDamagesAmount:
										a.allocations_allocation_date
										>= new Date(contractItem.contractEnd).getTime()
										&& a.allocations_allocation_date
										<= new Date(contractItem.damagesEnd).getTime()
											? 1
											: 0
								}
						)
					})
				)
				: []
		)
	/* eslint-enable */

	const allocationDollars =
		allocationDollarsArray.map((iArray) =>
			R.reduce(
				world.accumulator
				,{
					currentRevenue: 0
					,forecastedRevenue: 0
					,forecastedExpenditures: 0
					,currentExpenditures: 0
					,currentProfits: 0
					,forecastedProfits: 0
					,outstandingRevenue: 0
					,outstandingExpenditures: 0
					,outstandingProfits: 0
					,accruedExpenditures: 0
					,accruedRevenue: 0
					,accruedProfits: 0
					,budget: 0
				}
				,R.unnest(iArray)
			)
		)

	const todaysProfits =
		allocationDollars.map((t) =>
			makeMoney(t.accruedProfits)
			+ " | "
			+ makeMoney(t.outstandingProfits)
			+ " | "
			// makeMoney(t.currentProfits)
			// + " | " +
			+ makeMoney(t.forecastedProfits)
		)


	const projectView =
		stream.merge(
			[
				world.selectedProject
				,projectCalculatedView
			]
		)
		.map(function([project, pProfits]){
				return project
				? {

					'Project': project.project_name
					,'Proposed Start':
						localDate(project.project_start_date)
					,'Proposed Completion' :
						localDate(project.project_dead_date)
					,'Completion %':
						completionPercentage(project)
					,'Forcasted Completion':
						forecastEnd( project )
					,'Actual Completion': project.project_completion_marker
						? localDate(project.project_completion_marker)
						: 'In Progress'
					,'Important Project Summaries' : (
						project.worksummaries
							? project.worksummaries
								.split("\n")

							: ['No notes recorded during work updates.']
					)
						.map( s => m('p', s) )

					,'Discipline Summary':
						world.selectedProject().disciplines
						.map((d) =>
							m(
								'p.ma0'
								, d.discipline_order
								+ '. '
								+ d.discplinenamedescription
								+ '. '
								+ d.discipline_completed
								+ ' of '
								+ d.discipline_work
							)
						)

					,'Project Profits': pProfits
					,'Accrued | Requisitions | Forecast': null
				}
				: null
			})

			.map(hashToFieldViews)

	const allocationView =
		stream.merge(
			[
				world.selectedAllocation
				,todaysProfits
				,disciplineCalculatedView
				,projectCalculatedView
				,selectedAllocationDiscipline
			]
		)
		.map(function([a, tProfits, dProfits, pProfits, Md]){

			if(a){

				const disciplineCompletion =
					Md.case == 'Loading'
					? '...'
					: Md.case == 'Empty'
					? 0
					: Number(
						Md.value.discipline_completed
					) / Number(Md.value.discipline_work)

				const disciplinePercentage =
					Md.case == 'Loading'
					? '...'
					: Md.case == 'Empty'
					? 0
					: disciplineCompletion >= 1
						? '100%'
						: (disciplineCompletion*100).toFixed(2)+'%'

				const team =
					R.pipe(
						(a) => a.allocations_contractors_crews
						,R.pluck('allocations_contractors_crews_name')
						,list => list.join(', ')
					)

				const teams =
					R.pipe(
						R.prop('allocations_contractors')
						,(list) => list.map(team)
						,(list) => list.join(', ')
					)

				const resources =
					R.pluck('allocations_contractors_name')
					(a.allocations_contractors || [])
					.join(', ')

				return {
					'Work Date':
						localDate(a.allocations_allocation_date)

					,'Work name':
						a.allocations_work_name
						+ ' - '
						+ a.discipline_description

					,'Discipline Completion %':
						disciplinePercentage

					,'Resources':
						resources

					,'Teams':
						teams(a)
						|| a.allocations_bad_day
						|| a.allocations_day_status
						|| a.allocations_weather_status

					,'Important Comments':
						a.allocations_completion_comments
						|| '(No Comments)'
					,'Work Completed':
						a.allocations_allocation_complete + ' of ' + a.allocations_allocation_forecast
					,'Variations':
						a.allocations_vars.length
					,'Notices Of Delay':
						a.allocations_nods.length
					,'Extensions of Time':
						a.allocations_eots.length

					,'Accrued | Requisitions | Forecast': null

					,'Todays Profits': tProfits

					,'Discipline Profits': dProfits

					,'Project Profits': pProfits

					// todo-eja ADD transposes to show why a person is allocated for work
				}
			} else {
				return null
			}
		})
		.map( hashToFieldViews )



	const dataView =
		stream.merge([
			projectView, allocationView
		])
		.map(function([p, a]){
			if(world.selectedAllocation()){
				return a
			} else if (world.selectedProject() ){
				return p
			} else {
				return null;
			}
		})

	const className = active.map(v => v ? 'active' : '')

	const stop = (e) => e.stopPropagation()
	const view = () =>
		newDetailsPane(
				active
				,heading() || null
				,dataView() || null
				,{
					onwheel: stop
					,onscroll: stop
					,onmousedown: stop
					,onmouseup: stop
					,ontouchdown: stop
					,ontouchend: stop
					,ontouchmove: stop
					,onclick: stop
			}
		)


	className("");

	return { view: () => view() }
}

export default DetailsPane