// todo-james paragraph isn't a regex, either rename module/function etc

/**
 * Formats a vlaue into money formats for human representation.
 *
 * e.g. prettyKey('500520') //=> $ 500,520.00
 */

const uniqPattern = /[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi
const moneyPattern = /(\d)(?=(\d{3})+\.)/g

function paragraphPattern(xs, ...ys){
  return xs.reduce((p,n,i) =>
		p + n + (ys[i] || "")
		,''
	).replace(/\t|\n/g, '').trim()
}


function nFormatter(num, digits, forceFromat) {
	let si = [
	  { value: 1, symbol: "" }
	  ,{ value: 1E3, symbol: "k" }
	  ,{ value: 1E6, symbol: "M" }
	  ,{ value: 1E9, symbol: "G" }
	  ,{ value: 1E12, symbol: "T" }
	  ,{ value: 1E15, symbol: "P" }
	  ,{ value: 1E18, symbol: "E" }
	];
	let rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
	let i;
	for (i = si.length - 1; i > 0; i--) {
	  if (Number(num) >= si[i].value) {
		break;
	  }
	}
	return num.length < 9 && !forceFromat
		? num
		: (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
}


export function money(n=0, forceFromat){
	return isNaN(n)
		? ``
		: Number(n) < 0
		? '$ (' + nFormatter(Number(Math.abs(n)).toFixed(2), 2, forceFromat).replace(moneyPattern, '$1,') + ')'
		: '$ ' + nFormatter(Number(n).toFixed(2), 2, forceFromat).replace(moneyPattern, '$1,')
}

export { uniqPattern, paragraphPattern }