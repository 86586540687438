/* globals localStorage */
/**
 * Like the browser Local Storage API but with a few super powers
 *
 * 1. Always saves and restores JSON values
 * 2. Namespaces all writes to prevent collisions with 3rd party scripts and for testing/migrations
 * 3. Provides operations for default values when a value doesn't exist, with a background set
 * 4. Exports a stream that lets you keep track of all writes/deletes
 */
import * as m from 'bacta'
const stream = m.Stream
const s = stream()

let namespace = ''

const api = {
	setNamespace(key) {
		namespace = key + '/'
		return key
	}
	,getItem(key) {
		return api.getItemOr(key, null)
	}
	,setItem(key, value) {
		localStorage.setItem(namespace + key, JSON.stringify(value))
		s({ key, value, op: 'set' })
		return value
	}
	,getItemOr(key, otherwise) {
		let out
		if (localStorage.getItem(namespace + key) != null) {
			try {
				out = JSON.parse(localStorage.getItem(namespace + key))
			} catch (e) {
				out = otherwise
			}
		} else {
			out = otherwise
		}

		s({ key, value: out, otherwise, op: 'get' })
		return out
	}
	,getItemOrSet(key, otherwise) {
		const out = api.getItemOr(key, null)
		if (out == null) {
			return api.setItem(key, otherwise)
		}
		return out
	}
	,unsetItem(key) {
		const out = delete localStorage[namespace + key]
		s({ key, op: 'unset' })
		return out
	}
	,stream: s,
}

export default new Proxy(api, {
	get(_, key) {
		if (typeof key == 'string' || typeof key == 'number') {
			if (key in api) {
				return api[key]
			} else {
				return api.getItem(key)
			}
		} else {
			return api[key]
		}
	}
	,set(_, key, value) {
		if (typeof key == 'string' || typeof key == 'number') {
			if (key in api) {
				return api[key]
			} else {
				return api.setItem(key, value)
			}
		} else {
			return api[key]
		}
	}
	,deleteProperty(_, k) {
		return api.unsetItem(k)
	},
})
