import $UUID from '../../../../types/uuid'
import $ from 'sanctuary-def'
import SumType from 'sum-type-legacy'

import moment from 'moment'
import {
	Loaded
	, Loading
	, Loadable
} from '../../../../types/loadable'

import {
	pluck
	,concat
	,merge
	,pipe
	,equals
	,map
	,prop
	,sortBy
	,over
	,lensProp
} from 'ramda'

import {
	T
	,I
	,K
	,Maybe
	,get
	,find
} from '../../utils/safe'

const Sum = SumType(
	$
	//eslint-disable-next-line no-undef
	,{ checkTypes: !!process.env.CHECK_TYPES

		, env: $.env.concat( $UUID )
	}
)



const {
	Just
	,Nothing
	,fromMaybe
	,toMaybe
} = Maybe

const $SortedScheduleVersionList = $.NullaryType(
  'harth/SortedScheduleVersionList'
  ,''
  ,x => $.test([], $.Array($ScheduleVersion), x )
  	&& equals(
		sortBy(prop('schedule_version_created'), x)
			.reverse(), x)
)

const $Schedule = Sum.Named('Schedule', {
	Empty: {}
	,Id: { schedule_id: $UUID }
	,Loaded: {
		schedule_id: $UUID
		,schedule_name: $.String
		,schedule_versions: $SortedScheduleVersionList
	}
})

const $ScheduleVersion = Sum.Named('ScheduleVersion', {
	Empty: {}
	,Id: { schedule_version_id: $UUID }
	,Loaded: {
		schedule_id: $UUID
		,schedule_version_id: $UUID
		,schedule_priority: $.String
		,label: $.String
		,schedule_version_created: $.Number
	}
})

const $or = (name, a,b) =>
   $.NullaryType(
     name + ' ' + [a,b].map( T => T.name ).join(' | ')
     , a.url || b.url
     , x => [a,b].some( T => $.test([], T, x) )
   )

const $and = (name, a,b) =>
   $.NullaryType(
     name + ' ' + [a,b].map( T => T.name ).join(' & ')
     , a.url || b.url
     , x => [a,b].every( T => $.test([], T, x) )
   )

const $Labelled = $.RecordType({
	label: $.String
})


const $Model = $.RecordType({
	schedule: $Schedule
	,schedule_version: $ScheduleVersion
	,schedules: Loadable.$Type( $.Array($Schedule) )
	,schedule_names: $.Array($.String)
	,schedule_params: $.Array($.Any)
	,versions:
		$and(
			'SortedScheduleVersionLabelList'
			,$SortedScheduleVersionList
			,$.Array($Labelled)
		)

	,progress: $.StrMap($.String)
})


const $ServerScheduleVersion =
	$.RecordType({
		schedule_id: $UUID
		,schedule_version_id: $UUID
		,schedule_priority: $.String
		,schedule_version_created: $.Number
	})

const q = s => '('+s+')'

const fromNow = d => moment(d).fromNow()

const paths =
	[
		['schedule', 'schedule_id']
		,['schedule', 'schedule_name']
		,['schedule']
		,['schedule_version']
		,['schedule_version', 'schedule_version_id']
		,['schedule_version', 'schedule_priority']
		,['schedule_version', 'label']
		,['schedules']
		,['schedule_params']
		,['versions']
		,['progress']
	]

const $Action =
	[paths]
	.map(
		paths => paths.reduce(function(cases, path){
			return merge( cases, { [path.join('$')]: {} })
		}, {})
	)
	.map(
		cases => Sum.Named('Action', cases)
	)
	[0]


const def = $.create({
	//eslint-disable-next-line no-undef
	checkTypes: !!process.env.CHECK_TYPES
	, env: $.env.concat( [$UUID] )
})

function formatLabel(progress, x){

	const id = 'schedule_version_id'
	const created = 'schedule_version_created'
	const name = 'schedule_priority'

	const suffix =
		T(progress, pipe(
			get( x[id] )
			// todo-james use Maybe.filter
			,Maybe.chain( x => x == '' ? Maybe.Nothing() : Maybe.Just(x) )
			,Maybe.map( concat( 'Generating ' ) )
			,fromMaybe( fromNow( x[created] ) )
			,q
		))

	return x[name] + ' ' + suffix
}

function versionsFromSchedule({ progress }, schedule){
	const xs =
		schedule.schedule_versions

	const f = (x) => formatLabel(progress, x)

	return T( xs ,pipe(
		map( x => merge(x, { label: f(x) } ) )
		,map( $ScheduleVersion.LoadedOf )
	))
}

const kit = {
	sv(id, schedule_version, schedule) {

		const ys =
			schedule.schedule_versions || []

		const x =
			schedule_version[id]

		const $T =
			$ScheduleVersion

		return {
			x, ys, id, $T
		}
	}
	,s(id, schedules, schedule){
		const ys = schedules

		const x =
			schedule[id]

		const $T = $Schedule

		return {
			x, ys, id, $T
		}
	}
}

const LoadableType =
	$.RecordType({
		Loaded: $.AnyFunction
		,LoadedOf: $.AnyFunction
		,Id: $.AnyFunction
		,IdOf: $.AnyFunction
		,Empty: $.AnyFunction
		,EmptyOf: $.AnyFunction
	})

const LoadableRecord =
	$or( 'LoadableRecord', $Schedule, $ScheduleVersion )

const findLoaded =
	def(
		'findLoaded'
		, {}
		,[$.Any
		, $.Array( LoadableRecord )
		, LoadableType
		, $.String
		, LoadableRecord
		]
		, (x, ys, $T, id) =>
			T( ys, pipe(
				find( y => y[id] == x)
				,Maybe.map( $T.LoadedOf )
				,Maybe.fromMaybe( $T.Empty() )
			))
	)

const scheduleVersionFromSchedule = (s, sv) =>
	T( s, pipe(
		$Schedule.case({
			Loaded: () => Just(true)
			,Id: () => Just(true)
			,Empty: () => Nothing()
		})
		,Maybe.map(function(){

			const {x, ys, $T, id} =
				kit.sv(
					'schedule_version_id'
					, sv
					, s
				)

			return findLoaded(x,ys,$T,id)
		})
	))

const initial =
	def('initial', {}, [$Model]
		,()=> ({
			schedule: $Schedule.Empty()
			,schedule_version: $ScheduleVersion.Empty()
			,schedule_names: []
			,schedule_params: []
			,schedules: Loading()
			,schedule_versions: []
			,versions: []
			,progress: {}
		})
	)

const update =

	def(
		'update'
		,{}
		,[$Model
		,$.RecordType({
			state: $Model
			,action: $.Array($.String)
		})
		,$Model
		]
		,function update(p, { state, action }){

			const $action =
				$Action[action.join('$')]()

			const result =
				$Action.case({
					schedule$schedule_id (){

						return T( p.schedules, pipe(
							Loadable.map(function(xs){

								const { x, ys, id, $T } =
									kit.s(
										'schedule_id'
										, xs
										, state.schedule
									)

								const schedule =
									x != null
									? ys.length
										? findLoaded(x,ys,$T,id)
										: $T.Id( x )
									: $T.Empty()

								const Y = a => Just(a)
								const N = () => Nothing()

								const schedule_version =
									T(
										p.schedule_version_id
										, pipe(
											toMaybe
											,Maybe.map( K( schedule ) )
											,Maybe.chain( $Schedule.case({
												Loaded(){

													const k =
													'schedule_version_id'

													const {x,ys,id,$T} =
														kit.sv(
															k
															, p.schedule_version
															, schedule
														)

													return Y(
														findLoaded(x,ys,$T,id)
													)
												}
												,Id: () => {
													const sv =
														p.schedule_version
															.schedule_version_id
													const id =
														sv.schedule_version_id

													return id != null
														? $ScheduleVersion
															.Id( id )
														: $ScheduleVersion
															.Empty()
												}
												,Empty: () => N()
											}) )
											,fromMaybe(
												$ScheduleVersion.Empty()
											)
										)
									)


								// if the schedule_version_id
								// exists in this new schedule
								// reverify it and keep it
								// otherwise, set it to empty


								const versions =
									$Schedule.case({
										Loaded: () =>
											versionsFromSchedule(
												p, schedule
											)
										,Id: K([])
										,Empty: K([])
									}, schedule)

								return {
									schedule
									,schedule_version
									,versions
									,schedule_names:
										p.schedule_names
									,schedule_params:
										p.schedule_params
									,schedules:
										p.schedules
									,progress:
										p.progress
								}

							})
							,Loadable.fromLoadable(
								merge(p, {
									versions: []
									,schedule:
										state.schedule.schedule_id != null
										? $Schedule.case({
											Id: () => p.schedule
											,Empty: () => $Schedule.Id(
												state.schedule.schedule_id
											)
											,Loaded: () => $Schedule.Id(
												state.schedule.schedule_id
											)
										}, p.schedule)
										: $Schedule.Empty()
								})
							)
						))
					}
					,schedule$schedule_name (){

						return T(p.schedules, pipe(
							Loadable.fromLoadable([])
							,find(
								s => equals(
									s.schedule_name
									,state.schedule.schedule_name
								)
							)
							,Maybe.map(function(schedule){
								return update(p, {
									state: merge(p, { schedule })
									,action: ['schedule', 'schedule_id']
								})
							})
							,fromMaybe(p)
						))
					}
					,schedules() {
						const Y = a => Just(a)
						const N = () => Nothing()

						const schedule =

							T(
								p.schedule
								,pipe(
									$Schedule.case({
										Id: Y
										,Loaded: Y
										,Empty: N
									})
									,() => Loadable.toMaybe(
										state.schedules
									)
									,Maybe.map(xs => {
										const {x, ys, $T, id } =
											kit.s(
												'schedule_id'
												, xs
												, p.schedule
											)

										const out =
											findLoaded(x,ys,$T,id)

										return out
									})

									,fromMaybe(
										p.schedule
											.schedule_id
											? $Schedule.Id(
												p.schedule
													.schedule_id
											)
											: $Schedule.Empty()
									)
								)
							)

						const schedule_version =
							T( schedule, pipe(
								$Schedule.case({
									Loaded(){
										const Ma =
											scheduleVersionFromSchedule(
												schedule
												,p.schedule_version
											)

										return Maybe.fromMaybe(
											$ScheduleVersion.Empty()
										) (Ma)
									}
									,Id(){
										return p.schedule_version
											.schedule_version_id
											!= null
											? $ScheduleVersion.Id(
												p.schedule_version
													.schedule_version_id
											)
											: $ScheduleVersion.Empty()
									}
									,Empty(){
										return $ScheduleVersion.Empty()
									}
								})
							))

						const schedule_names =
							T( state.schedules, pipe(
								Loadable.fromLoadable([])
								, pluck( 'schedule_name' )
							))

						const versions =
							T(
								schedule
								,pipe(
									$Schedule.case({
										Loaded(){
											return versionsFromSchedule(
												p, schedule
											)
										}
										,Id: () => []
										,Empty: () => []
									})
								)
							)

						return {
							schedule
							,schedule_version
							,schedule_names
							,schedules: state.schedules
							,schedule_params: p.schedule_params
							,versions
							,progress: p.progress
						}
					}
					,schedule(){
						return p
					}
					,schedule_version(){
						// todo-james just accept the state object
						// this function is called once per field anyway
						return merge(p, {
							schedule_version: state.schedule_version
						})
					}
					,schedule_version$schedule_version_id(){

						const schedule = p.schedule
						const Y = a => Just(a)
						const schedule_version =
							// todo-james replace with diff :: a -> a -> Maybe a
							T( schedule
							, pipe(
								$Schedule.case({
									Loaded(){

										const { x, ys, id, $T } =
											kit.sv(
												'schedule_version_id'
												, state.schedule_version
												, schedule
											)


										return Y(findLoaded(x,ys,$T,id))
									}
									,Id(){
										return Y(
											$ScheduleVersion.Id(
												state
													.schedule_version
													.schedule_version_id
											)
										)
									}
									,Empty(){
										return Y( $ScheduleVersion.Empty() )
									}
								})
								,fromMaybe( p.schedule_version )
							))

						return {
							schedule
							,schedules: p.schedules
							,schedule_version
							,schedule_names: p.schedule_names
							,schedule_params: p.schedule_params
							,versions: p.versions
							,progress: p.progress
						}
					}
					,schedule_version$schedule_priority(){
						return p
					}
					// todo-james possible race condition when switching
					// schedule_versions while the progress label is changing
					// need to compare the label in a more sophisiticated
					// manner
					,schedule_version$label(){

						return T(p.versions, pipe(
							find(
								v => equals(
									v.label
									,state.schedule_version.label
								)
							)
							,Maybe.map(function(schedule_version){
								return update(p, {
									state: merge(p, { schedule_version })
									,action:
										['schedule_version'
										, 'schedule_version_id'
										]
								})
							})
							,fromMaybe(p)
						))
					}
					,schedule_params(){
						return state
					}
					,versions(){
						return p
					}
					,progress(){

						return $Schedule.case({
							Loaded(){
								const progress =
									state.progress

								const versions =

									versionsFromSchedule(state, state.schedule)

								const schedule =
									p.schedule

								const schedule_version =
									scheduleVersionFromSchedule(
										schedule
										, p.schedule_version
									)

								return merge(p, {
									progress
									,versions
									,schedule_version:
										Maybe.fold(
											p.schedule_version
											, I
										)(schedule_version)
								})
							}
							,Id(){
								return p
							}
							,Empty(){
								return p
							}
						}, state.schedule)
					}

				}, $action)

			return result
		}
	)

const Streams = (streamFromPath, paths)=>
	paths
	.map( a => [ a.slice(-1)[0], a ])
	.reduce(
		(streams, [name, path]) => {
			return merge( streams, { [name]: streamFromPath(path) })
		}
	, {})

const processServerScheduleVersion =
	def(
		'processServerScheduleVersion'
		, {}
		, [$ServerScheduleVersion, $ScheduleVersion]
		, pipe(
			sv => merge(
				sv
				,{ label: formatLabel({}, sv)
				}
			)
			,$ScheduleVersion.LoadedOf
		)
	)

function processServerSchedules(schedules){

	return pipe(
		sortBy( s => s.schedule_name )
		// schedules
		,map( // schedule
			over( //schedule_versions
				lensProp('schedule_versions')
				,map( processServerScheduleVersion )
			)
		)

		,map( $Schedule.LoadedOf )
		,Loaded
	) ( schedules )
}

export {
	update
	,initial
	,paths
	,$Model
	,$Action
	,formatLabel
	,Streams
	,$Schedule
	,$ScheduleVersion
	,versionsFromSchedule
	,kit
	,findLoaded
	,scheduleVersionFromSchedule
	,processServerSchedules
}
export default {
	update
	,initial
	,paths
	,$Model
	,$Action
	,formatLabel
	,Streams
	,$Schedule
	,$ScheduleVersion
	,versionsFromSchedule
	,kit
	,findLoaded
	,scheduleVersionFromSchedule
	,processServerSchedules
}