import m from 'bacta'

const h = m
h.trust = m.trust
h.fragment = m.fragment
h.mount = m.mount
h.redraw = m.redraw
h.render = m.render
h.request = m.request

h.routed =
function RoutedComponent( name, defaultRoute, patterns, theirOptions, Comp ){

	const { ...options } = theirOptions

	return function RoutedComputed(vnode){

		const $ = vnode.attrs.$[name]

		const Route = vnode.attrs.Route
			.subroute(name, (x) => defaultRoute(x, vnode.attrs), patterns, {
				$, ...options
			})

		let subscriptions = 0;
		const subscribe = (create, destroy=null) => {
			const out = create()
			if( destroy == null && out && out.$type == 'how/stream' ) {
				destroy = () => out.end(true)
			} else if( destroy == null && out && out.$type == 'how/z2-proxy' ) {
				destroy = () => out.$end()
			} else {
				throw new Error('Custom subscriptions must provide a clean up function')
			}
			vnode.subscriptions['subscribe' + ++subscriptions] = destroy
			return out
		}

		return {
			view({ attrs }){
				return h(Comp, {
					...attrs
					,ParentRoute: vnode.attrs.Route
					,$$: vnode.attrs.$
					,$
					,Route
					,subscribe
				})
			}
			// todo-james this doesn't get called on logout for some reason?
			,onremove(){
				// clear params on route change
				Route.search.$({})
				$.$end()
				Route.end()
			}
		}
	}
	return null;
}

export default h
