import { Stream as stream2 } from 'bacta'
/* globals setTimeout, clearTimeout */
const J = x => {
	return JSON.stringify(x, function(_, x){
		if( typeof x == 'function' ) {
			return x + ''
		} else {
			return x
		}
	})
}

const afterSilence = ms => s => {
	let id

	const out = stream2()
	s.map(x => {
		clearTimeout(id)
		id = setTimeout(() => out(x), ms)
		return null
	})

	return out
}

const throttle = ms => s => {
	const out = stream2()
	let last = null
	let id = 0

	function process(x) {
		if (last == null) {
			last = Date.now()
		}
		let dt = Date.now() - last
		clearTimeout(id)

		if (dt >= ms) {
			out(x)
			last = Date.now()
		} else {
			id = setTimeout(process, Math.max(0, ms - dt), x)
		}
	}

	s.map(process)

	return out
}

const defaultStream = {
	hasVal: x => typeof defaultStream.getVal(x) != 'undefined'
	,getVal: x => x()
	,setVal: (x, a) => x(a)
	,of: (...args) => args.length ? stream2(args[0]) : stream2()
	,subscribe: (f, x) => x.map(f)
	,unsubscribe: x => x.end(true)
	,merge: stream2.merge
	,dropRepeatsWith: eq => s => {
		const sentinel = {}
		let prev = sentinel
		const out = defaultStream.of()

		s.map(x => {
			if (prev === sentinel || !eq(x, prev)) {
				prev = x
				defaultStream.setVal(out, x)
			}
			return null
		})

		return out
	}
	,afterSilence
	,throttle,
}

function NestedMirrorIndex(index) {
	const mirror = {}
	const self = {
		set(outer, inner, value) {
			index[outer] = index[outer] || {}
			index[outer][inner] = value

			mirror[inner] = mirror[inner] || {}
			mirror[inner][outer] = value

			return self
		}
		,get(outer, inner) {
			return index[outer][inner]
		}
		,getOuter(outer) {
			return index[outer] || {}
		}
		,getMirror(outer, inner) {
			return mirror[inner][outer]
		}
		,getMirrorOuter(inner) {
			return mirror[inner] || {}
		}
		,remove(key) {
			const mirrorDelete = Object.keys(index[key] || {})
			delete index[key]

			mirrorDelete.forEach(mirrorKey => delete mirror[mirrorKey][key])
			return self
		}
		,removeNested(outer, inner) {
			delete index[outer][inner]
			delete mirror[inner][outer]
			return self
		}
		,index
		,mirror,
	}

	return self
}

// An attempt at a rewrite of z2
// to rely on a simpler propagation dependency logic chain
// that just asks the parents who they rely on, and caches that list
// until one of those items is dropped/extended.
// we shouldn't need "children" or "parents" I think.
// but we will still rely on rank
function Z3({
	// The initial state
	initial = {},

	// The stream interface, can be used
	// as an interface to alternative stream libraries
	// defaults to mithril-stream
	Stream = defaultStream,

	// The default state stream
	// this exists for simple Z usages
	// where you already have a stream with state in it
	// and you want to use Z to query it
	state: state = Stream.of(initial),

	// The default getter for extracting state
	// from the state stream.
	//
	// Defined separately so we can later check if
	// a custom getState was passed in by the user.
	// If so we cannot make guarantees we'd normally make.
	_defaultGetState = () => Stream.getVal(state),

	// User provided way to get the current state from
	// the outside world.
	getState = _defaultGetState,

	// User provided way for Z to update the
	// state of the outside world.
	// Called during update/remove of a subquery.
	setState = f => {
		const out = state(f(state()))
		return out
	},
	// An optional user provided callback
	// to listen to the state of the outside world
	// and update Z's internal caches.
	// This is preferred to simply secretly updating
	// the state stream, as Z will be unaware a change has
	// occurred.
	updateState = getState == _defaultGetState
		? f => {
				state.map(f)
				return null
		  }
		: () => {
				return null
		  },
	// Used to convert values into primitive represenations
	// for comparison.
	// Can be overloaded to get different equality behaviour.
	serialize = J,

	// Used to compare values which is often used to prevent
	// propagation when a value is unchanged.
	// Also used when detecting infinite loops.
	equals = (a, b) => serialize(a) == serialize(b),

	// The sentinel value used to detect a query's intention
	// to delete it's current value.
	// Can be altered to `{}` if you want an update of `undefined`
	// to be treated as any other value.
	$delete = undefined,

	dev = {
		enforceArrowUsage: true,
	},
} = {}) {
	// An index of the operations list for each key.
	const operations = { '': [] }

	// Used to record internal stream creation.
	// That way we can know a stream is really just a listener
	// for a part of our managed state tree instead of a 3rd party
	// stream.
	//
	// Uses a weakmap because for external streams we have no
	// other way of relying on identity other than by reference.
	const queryStreams = new WeakMap()

	// Used to retrive query streams by key.
	// Technically queryStreams.get( cache[key] )
	// could be used, but it would be awkward to debug.
	const streams = {}

	// Used to track if a stream used as a dependency has been
	// seen before.  When we first encounter a stream we assign an id
	// so that two queries with the same visitor function but different
	// input streams have unique keys.
	const seenStreams = new WeakMap()

	// A sequential id counter used by seeStream.
	let streamId = 0

	// Any time we see a stream we pass it to this function to record
	// an id for that stream if it is the first time it has been passed
	// to a Z function.
	const seeStream = (stream, theirId) => {
		if (seenStreams.has(stream)) {
			return seenStreams.get(stream)
		} else {
			const id = theirId || ++streamId
			seenStreams.set(stream, id)
			return id
		}
	}

	// An internal cache of values key'd by the query key.
	// When reading, 99% of the time you'll be reading from
	// values instead of recomputing the lensed value.
	const values = {}

	// An internal cache of the compiled queries.
	// This cache holds the write query, not the staggered read query.
	const cache = {}

	// An internal cache of the compiled read queries.
	// This cache doesn't hold the write queries, it's for read only
	// queries, and must be passed the immediate parent value, not getState()
	// as input.
	const staggeredCache = {}

	// An internal marker that a query's cached value is no
	// longer fresh.
	//
	// Note presence in the index is all that matters, not the value
	// of stale[key] itself. By convention Z writes
	// `stale[key] = true` but the boolean that is stored is never
	// used.
	const stale = {}

	// An index of the parents of a key.
	// Used to compute the rank of a query in a propagation.
	const parents = { '': [] }

	// An index of the parents of a key that are simply property
	// access queries.
	// These queries have a higher rank in the propagation sort.
	const propParents = { '': [] }

	// An index of the parents of a key that are not simple
	// property access queries.
	// e.g. .$values, $map and .$filter.
	//
	// These queries have a lower rank in the propagation sort.
	const nonPropParents = { '': [] }

	// Each query is assigned a rank based on the composition
	// of its operations.
	//
	// The goal is to update in a wave from root to leaf
	// and for simply property access fields to be updated first.
	// This is so when a dynamic query is re-evaluated it
	// can rely on the assumption that all parent values are
	// in sync after the last update.
	//
	// This isn't better or worse than updating from leaves to root.
	// But it _is_ better than updating in definition order
	// which is often how stream propagation works.
	// Which is basically chaos at a sufficient level of complexity.
	const rank = { '': -Infinity }

	// An index of keys that a query requires in order to
	// exist.
	//
	// When one of these requirements propagates we also need to
	// propagate.  If any of these requirements are ended, we also
	// need to end.
	// If any of these requirements are removed, we also need to be
	// removed.
	//
	// Used to build the sorted propagation list when the tree is modified.
	//
	// Note it is called updateRequirements in contrast to propagationRequirements.
	// updateRequirements mean, if any of these requirements are directly modified from userland
	// we need to be re-propagated.
	//
	// A propagationRequirement is different, if a propagationRequirement is not directly updated
	// but forced to propagate due to some other change, then we also need to propagate.
	// This is used by dynamic queries.
	const updateRequirements = NestedMirrorIndex({})

	// If a direct prop parent of a dynamic query is propagated, it may affect
	// the logic in our visitor function.
	//
	// But if a non direct parent is propagated, it will not affect the logic in our
	// visitor function, it is simply being propagated in response to a sibling update
	// that our visitor function cannot access.
	//
	// So dynamics will have a propagationRequirement on direct prop parents.
	//
	// This will usually be triggered if a child of our prop parent was updated
	// forcing the parent to be propagated.  In turn our visitor function might
	// depend on that child value so we need to propagate too.
	const propagationRequirements = NestedMirrorIndex({})

	// An internal marker that is set whenever propagation starts.
	//
	// Used to dectect and react to infinite loops and postpone
	// stream updates to the edges of the propagation.
	let propagating = false

	// If an update causes a non query stream to emit while propagating
	// that is the dependency of another query - we stash the key of the stream
	// in a list.
	//
	// At the end of the propagation we loop through and process
	// those updates.
	//
	// We defer streams til the end of a propagation as streams
	// are considered external.  And the external world should always
	// read / write from/to a fully propagated state tree.
	let propagatingStreamEmits = []

	// streams that need to be written to at the end of a propagation
	let deferredStreamEmits = []

	// When we trigger an update internally we mark this flag as true
	// So that if we receive a call to `updateState` we can assume it
	// was triggered by ourselves calling `setState`.
	//
	// Once an update is complete, the flag is set back to false
	// so that future notifications of the state tree are received
	// and processed via a root update.
	let ignoreNotification = false

	// When updating, we record the key's of updates within a propagation.
	// That way we can detect repeating state transitions and stop an infinite
	// loop.
	//
	// See: stopPropagationLoop and propagationMemory
	let updates = []

	// When we have detected a repeating state transition in an update loop
	// we set this flag to true to terminate the propagation early.
	let stopPropagationLoop = false

	// propagationMemory records state transitions by storing
	//
	//	1. The key of the previous update
	//  2. The key of the current update
	//  3. The value of the previous update of this transition.
	//
	// This is stored as a nested index after each update:
	//
	// 	propagationMemory[prev][key] = value
	// When value == propagationMemory[prev][key] we set
	// set stopPropagationLoop = true as we've detected a redundant
	// loop.
	//
	// Note this will allow loops that result in new value each time
	// which requires the user to have their own base cases.
	//
	// Part of Z's philosophy is to do what you expect.
	// We stop loops that are obviously redundant but allow you to
	// write to the state tree mid propagation if you know what you
	// are doing.
	//
	let propagationMemory = {}

	// An index of proxies that have been created via the $ syntax.
	// This saves CPU cycles when accessing previously created queries.
	// Making it efficient to "create" a query in frequently called userland
	// functions e.g. a render/view function.
	let proxies = {}

	// An operation constructor for property access.
	const $prop = property => ({
		key: typeof property == 'string' ? '.' + property : '[' + property + ']'

		,type: 'Operation'
		,tag: 'Property'
		,value: {
			property
			,otherwise: undefined,
		},
	})

	// Creates a key for dependencies, making use of seeStream's auto id
	// for streams.
	const depKey = dependencies =>
		dependencies.length
			? ', streams:(' + dependencies.map(x => seeStream(x)).join(',') + ')'
			: ''

	// Creates an operation that filters the previous target of a query.
	// It works a lot like a where clause in SQL
	const $filter = (key, visitor, ...dependencies) => {
		if (typeof key == 'function') {
			if (visitor || dependencies.length || (key+'').includes('=>')) {
				const deps = visitor ? [visitor, ...dependencies] : dependencies
				return $filter(key.toString(), key, ...deps)
			} else {
				throw new Error(
					'Potential collision: A $filter without a key, and without dependencies could easily have a name collision.'
						+ '\n'
						+ 'Either provide a unique key e.g. $filter(x.id, '
						+ key
						+ ')'
						+ '\nor provide a unique dependency stream e.g. $filter('
						+ key
						+ ', yStream)'
						+ '\nor both.',
				)
			}
		}
		if (key != visitor) {
			key += ', ' + visitor
		}
		return {
			key: '.$filter(' + key + depKey(dependencies) + ')'
			,type: 'Operation'
			,tag: 'Filter'
			,value: {
				visitor: visitor
				,dependencies,
			},
		}
	}

	const $map = (key, visitor, ...dependencies) => {
		if (typeof key == 'function') {
			if (visitor || dependencies.length || (key+'').includes('=>') ) {
				const deps = visitor ? [visitor, ...dependencies] : dependencies
				return $map(key.toString(), key, ...deps)
			} else {
				throw new Error(
					'Potential collision: A $map without a key, and without dependencies could easily have a name collision.'
						+ '\n'
						+ 'Either provide a unique key e.g. $map(x.id, '
						+ key
						+ ')'
						+ '\nor provide a unique dependency stream e.g. $map('
						+ key
						+ ', yStream)'
						+ '\nor both.',
				)
			}
		}
		if (key != visitor) {
			key += ', ' + visitor
		}
		return {
			key: '.$map(' + key + depKey(dependencies) + ')'
			,type: 'Operation'
			,tag: 'Map'
			,value: {
				visitor: visitor
				,dependencies,
			},
		}
	}

	// An operation that visits each value in a list, often used
	// in combination with $filter as .$values.$filter(...) so that
	// the filter predicate evaluates the list item, not the list
	// itself.
	const $values = {
		key: '.$values'
		,type: 'Operation'
		,tag: 'Values',
	}

	// If a stream is a Z stream we get the value from the cache
	// instead of the stream beause the cache is updated before
	// the stream propagation phase
	function getDependencyValue(stream) {
		if (queryStreams.has(stream)) {
			const key = queryStreams.get(stream)
			return values[key][0]
		} else {
			return Stream.getVal(stream)
		}
	}

	// Intelligently adds a default value to the previous operation
	// by reading ahead in the operations list to infer user intent
	//
	// e.g. if a user's next operation uses a number as a property
	// we can infer the current operations default value is a list
	function injectOtherwise(operations) {
		const noOtherwise = []

		for (let op of operations) {
			if (op.tag != 'Property') {
				continue
			}
			if (noOtherwise.length) {
				const otherwise = typeof op.value.property == 'string' ? {} : []
				for (let empty of noOtherwise) {
					empty.value.otherwise = otherwise
				}
				noOtherwise.length = 0
			}
			if (typeof op.value.otherwise == 'undefined') {
				noOtherwise.push(op)
			}
		}
	}

	// Takes a list of operations and converts it into
	// a query interface { select, update, remove }.
	//
	// When doing updates `ys` is the total list of operations.
	// When doing reads `ys` is the last operation only and we
	// run that final operation over the cached value of its parent
	// query.
	//
	// This process is mangaged in staggeredCompile.
	const theirCompile = ys => {
		injectOtherwise(ys)

		const keywords = ys.map(operation => {
			if (operation.tag == 'Property') {
				const key = operation.value.property
				const otherwise = operation.value.otherwise

				return {
					select(o) {
						return o != null && key in Object(o) ? [o[key]] : []
					}
					,remove(o) {
						if (typeof key == 'number') {
							return o.filter((_, i) => i == key)
						} else {
							const copy = { ...o }
							delete copy[key]
							return copy
						}
					}
					,update(f, o) {
						let previous
						let copy
						if (typeof key == 'number') {
							copy = (o || []).slice()
							previous = key in o ? o[key] : otherwise
						} else {
							copy = { ...o }
							previous = key in o ? o[key] : otherwise
						}
						let result = f(previous)
						if (result == $delete) {
							delete copy[key]
						} else {
							copy[key] = result
						}
						return copy
					},
				}
			} else if (operation.tag == 'Values') {
				return {
					select(o) {
						return o
					}
					,remove(o) {
						return Array.isArray(o) ? [] : {}
					}
					,update(f, o) {
						return Array.isArray(o)
							? o.flatMap(x => {
									const result = f(x)
									if (result == $delete) {
										return []
									} else {
										return [result]
									}
							  })
							: Object.fromEntries(
									Object.entries(o).flatMap(([k, x]) => {
										const result = f(x)
										if (result == $delete) {
											return []
										} else {
											return [[k, f(x)]]
										}
									}),
							  )
					},
				}
			} else if (operation.tag == 'Filter') {
				return {
					select(o) {
						// for select we return [o] or [] depending
						// on the predicate
						const allDepsReady = operation.value.dependencies.every(x =>
							queryStreams.has(x)
								? queryStreams.get(x) in values
								: Stream.hasVal(x),
						)

						const args = allDepsReady
							? operation.value.dependencies.map(getDependencyValue)
							: []

						const f = operation.value.visitor

						return allDepsReady ? [o].filter(x => f(x, ...args)) : []
					}
					,remove(o) {
						const cond = operation.value.visitor(
							o,
							...operation.value.dependencies.map(getDependencyValue),
						)
						if (cond) {
							return $delete
						} else {
							return o
						}
					}
					,update(f, o) {
						// for update we always return the complete input
						// but we conditionally apply an update
						const cond = operation.value.visitor(
							o,
							...operation.value.dependencies.map(getDependencyValue),
						)

						if (cond) {
							const result = f(o)

							// todo-james just return f(o) after debugging
							if (result == $delete) {
								return $delete
							} else {
								return result
							}
						} else {
							return o
						}
					},
				}
			} else if (operation.tag == 'Map') {
				return {
					select(o) {
						// for select we return [o] or [] depending
						// on the predicate
						const allDepsReady = operation.value.dependencies.every(x =>
							queryStreams.has(x)
								? queryStreams.get(x) in values
								: Stream.hasVal(x),
						)

						const args = allDepsReady
							? operation.value.dependencies.map(getDependencyValue)
							: []

						const f = operation.value.visitor

						return allDepsReady ? [o].map(x => f(x, ...args)) : []
					}
					,remove(_, o) {
						return o
					}
					,update(_, o) {
						return o
					}
				}
			} else {
				return {
					select(o) {
						return [o]
					}
					,remove(o) {
						return o
					}
					,update(_, o) {
						return o
					},
				}
			}
		})

		const updates = keywords.map(kw => f => o => kw.update(f, o))

		const composedUpdate = updates.reduceRight(
			(g, f) => x => f(g(x)),
			x => x,
		)

		const composedParentUpdate = updates.slice(0, -1).reduceRight(
			(g, f) => x => f(g(x)),
			x => x,
		)

		const S =
			keywords.length == 1
				? x => keywords[0].select(x)
				: keywords
						.map(kw => kw.select)
						.reduce(
							(g, f) => x => g(x).flatMap(f),
							x => [x],
						)

		const D = composedParentUpdate(x => {
			if (keywords.length) {
				return keywords.slice(-1)[0].remove(x)
			} else {
				return $delete
			}
		})

		return {
			select(xs) {
				const Q = S
				keywords
				return xs.flatMap(x => Q(x))
			}
			,remove(xs) {
				return xs.map(x => D(x))
			}
			,update(F, xs) {
				const Q = composedUpdate(F)
				return xs.map(x => Q(x))
			},
		}
	}

	// A small wrapper around theirCompile.
	// in addition to compiling it
	// stores the key and the "type" on the compiled
	// instance.  This is used in the stream / proxy libs.
	//

	// It also marks the query as stale, and caches the output
	// for next time.
	function compile(cache, key, xs) {
		const output = theirCompile(xs)

		output.key = key
		output.$type = 'how/z2-instance'

		stale[key] = true
		cache[key] = output
		return cache[key]
	}

	// We check if an operation is dynamic via duck typing.
	// If the operation has a visitor, we assume it is dynamic.
	// This could be more concrete, e.g. as a metadata property?
	//
	// If a query is dynamic it is ranked differently and it affects
	// how queries propagate because a visitor function could technically
	// access any child value of the current position in the tree.
	// If a child of the dynamic is  propagated then the dynamic
	// needs to propagate too.
	//
	// e.g. a $filter can acces a nested property:
	//
	//	.$filter( x => x.a.b.c.d.e.f > 3 )
	//
	// without parsing the function body, we can't know how far down the tree
	// the dynamic function accessed.  So just assume it accesses all children.
	function isDynamic(x) {
		return x.value && x.value.visitor
	}

	const dropRepeats = Stream.dropRepeatsWith(equals)

	// Not all dependencies are queries (maybe they should be)
	// so we need to listen to the non query deps and propagate
	// our query when they emit.
	//
	// But there's some edge cases:
	//
	// 1. We should only propagate if the stream value that emitted has changed.
	// 2. We should only propagate if all the deps have a value.
	// 3. If we're already propagating, we should schedule our stream
	// 		update for the end of that propagation via a stack.
	// 4. If we're not propagating and 1 & 2 are satisified, propagate
	//		immediately.
	function subscribeToStreamDependencies({ node, key, xs }) {
		if (
			node.value
			&& node.value.dependencies
			&& node.value.dependencies.length
		) {
			const nonQueryDeps = node.value.dependencies.filter(
				x => !queryStreams.has(x),
			)

			// 2.
			defaultStream.subscribe(() => {
				const parentPropKey = propParents[key].slice(-1)[0]

				const parentPropReady =
					parentPropKey in values && !(parentPropKey in stale)

				const ready =
					parentPropReady && node.value.dependencies.every(Stream.hasVal)

				if (ready) {
					if (propagating) {
						propagatingStreamEmits.push(key)
					} else {
						update(x => x, xs, key)
					}
				}

				return null
			}, dropRepeats(Stream.merge(nonQueryDeps)))
		}
	}

	// Compile each component part of a query separately
	// and store in a staggeredCache.
	//
	// We can then efficiently jump to the specific
	// query that needs to be recalcuated during propagation
	// without recomputing everything from scratch.
	function staggeredCompile(_, xs) {
		const take = xs.slice()

		let _propParents = ['']
		let _nonPropParents = []

		let key = ''
		let p = null

		while (take.length) {
			const node = take.shift()
			const parent = key
			key += node.key

			// if the parents for this key have not been
			// processed before, process them
			// technically the if statement isn't necessary
			// we just need to know if the key is in the staggered
			// cache or not to indicate if its processed already.
			if (!(key in staggeredCache)) {
				parents[key] = parents[parent].concat([parent])

				propParents[key] = _propParents.slice()
				nonPropParents[key] = _nonPropParents.slice()

				rank[key] =
					1 * propParents[key].length
					+ (node.tag == 'Property' ? 1 : 100)
					+ 100 * nonPropParents[key].length
			}

			// The updateRequirements hash is an index of queries
			// that this query needs to be propagated in order to
			// propagate itself.
			//
			// In addition, if any of these updateRequirements are directly modified
			// this query should also propagate.
			if (!(key in staggeredCache)) {
				let [queryDeps, prevPropParent] = isDynamic(node)
					? [
							node.value.dependencies.flatMap(x =>
								queryStreams.has(x) ? [queryStreams.get(x)] : [],
							)
							,_propParents.slice(-1)[0]
					  ,]
					: [[], null]

				// This query requires all parents
				// if they update, we need to propagate.
				// This query also requires any query deps.
				// If they update, we also need to update.
				//
				// Technically we require any of our requirements'
				// requirements.  But that is evaluated in getAllChildren
				// as its a dynamic tree that changes when queries are
				// created/destroyed.
				//
				// So we cache and invalidate that cache accordingly.
				//
				// The query dep is updated which instigated a change that
				// we react to.
				//
				// Hence the query dep is the instigator, and we are the reactor

				parents[key].concat(queryDeps).forEach(instigator => {
					const reactor = key

					updateRequirements.set(instigator, reactor, true)

					delete computePropagationNodesCache[instigator]
				})

				// If we are dynamic and our immediate prop parent is
				// propagated we need to update.
				//
				// Because that symbolizes a child update that
				// maybe our visitor function makes use of.
				//
				// If one of our query deps is propagated we need to
				// propagate as well.  Because that propagtion implies
				// the dep could have changed, and therefore our visitor
				// might evaluate to a different result.
				//
				// The prop parent is propagated which instigates a change
				// which we react to.
				// Hence instigator=propParent and reactor=key

				if (prevPropParent) {
					const instigator = prevPropParent
					const reactor = key
					propagationRequirements.set(instigator, reactor, true)

					delete computePropagationNodesCache[instigator]
				}

				parents[key].forEach(orgQuery => {
					// things our parent reacts to
					const instigators = Object.keys(
						propagationRequirements.getMirrorOuter(orgQuery),
					)

					// inehrit all propagation requirements of our parents
					const reactor = key
					instigators.forEach(instigator => {
						propagationRequirements.set(instigator, reactor, true)
						delete computePropagationNodesCache[instigator]
					})
				})

				queryDeps.forEach(instigator => {
					const reactor = key
					propagationRequirements.set(instigator, reactor, true)

					delete computePropagationNodesCache[instigator]
				})
			}

			if (node && node.tag == 'Property') {
				_propParents.push(key)
			} else {
				_nonPropParents.push(key)
			}

			let uncached = !(key in staggeredCache)

			if (uncached) {
				compile(staggeredCache, parent + node.key, [node])
			}

			let out = staggeredCache[key]

			if (uncached) {
				let thisXS = xs.slice(0, xs.length-take.length)
				subscribeToStreamDependencies({ node, key, xs: thisXS })
			}

			p = out
		}

		// This ensures you can compile an empty query.
		{
			let out

			if (xs.length) {
				out = p
			} else {
				if (!('' in staggeredCache)) {
					compile(staggeredCache, '', [])
				}
				out = staggeredCache['']
			}

			return out
		}
	}

	// There's several steps to fully compile a query.
	// This function consolidates that logic to make it easier
	// to set breakpoints on compilation of a specific key
	// irregardless of the entry point.
	function compilation(key, xs) {
		if (!(key in cache)) {
			compile(cache, key, xs)
			staggeredCompile(key, xs)

			operations[key] = xs
		}
	}

	// Walks through the operations and recomputing
	// and stale values.
	// Most of the time, all parents are not stale and
	// the last op is the only query that requires evaluation.
	//
	// Staggered selecting guarantees that if a child is not stale
	// a operation parent will not be stale either.
	//
	// Note staggeredSelect assumes staggeredCompile has already
	// run.  If not, staggeredCache[key].* would crash.
	function staggeredSelect(operations) {
		let prefix = ''
		let out

		let streamUpdates = []

		for (let op of operations) {
			let key = prefix + op.key
			let isStale = key in stale || !(key in values)
			let parentValue = values[prefix]

			if (isStale) {
				const $ = staggeredCache[key]

				values[key] = $.select(parentValue)
				out = values[key]
				delete stale[key]
				if (
					key in streams
					&& !equals(values[key], Stream.getVal(streams[key]))
				) {
					streamUpdates.push(() => Stream.setVal(streams[key], values[key]))
				}
			}

			prefix = key
		}

		streamUpdates.forEach(f => f())

		if (operations.length == 0) {
			return [getState()]
		} else {
			return out
		}
	}

	// Logically reads a queries value from the state tree.
	//
	// May compile the query if it has not already been compiled.
	// May also grab a cached value is the current value is not
	// marked as stale.
	function select(operations, key = operations.map(x => x.key).join('')) {
		compilation(key, operations)

		if (key in values && !(key in stale)) {
			return values[key]
		} else {
			const value = staggeredSelect(operations)
			return values[key] = value
		}
	}

	function update(
		visitor,
		operations,
		key = operations.map(x => x.key).join(''),
	) {
		compilation(key, operations)

		const $ = cache[key]

		const prevResults = values[$.key]
		const newResults = []

		ignoreNotification = true
		setState(
			state =>
				$.update(
					previous => {
						const next = visitor(previous)
						newResults.push(next)
						return next
					},
					[state],
				)[0],
		)
		if (propagating) {
			const valueRep = serialize(newResults)
			const prev = updates.length ? updates[updates.length - 1] : ''

			propagationMemory[''] = propagationMemory[''] || {}
			propagationMemory[''][key] = propagationMemory[''][key] || {}
			propagationMemory[prev] = propagationMemory[prev] || {}
			propagationMemory[prev][key] = propagationMemory[prev][key] || {}

			if (
				// subsequent updates
				valueRep in propagationMemory[prev][key]
				// first update
				|| valueRep in propagationMemory[''][key]
			) {
				stopPropagationLoop = true
			} else {
				propagationMemory[prev][key][valueRep] = true
			}
			updates.push(key)
		}
		ignoreNotification = false
		let same =
			equals(prevResults, newResults)

		if (!same) {
			propagateFrom($)
		}
		return getState()
	}

	function $stream(operations, key = operations.map(x => x.key).join('')) {
		compilation(key, operations)
		let out
		// we've got a cache for this
		if (key in streams) {
			out = streams[key]
		} else if (key in values) {
			const value = values[key]
			if (value.length) {
				seeStream(streams[key] = Stream.of(values[key]), key)
			} else {
				seeStream(streams[key] = Stream.of(), key)
			}
			out = streams[key]
			queryStreams.set(out, key)
		} else {
			const value = staggeredSelect(operations)
			if (value.length) {
				values[key] = value
				seeStream(streams[key] = Stream.of(values[key]), key)
			} else {
				seeStream(streams[key] = Stream.of(), key)
			}
			out = streams[key]
			queryStreams.set(out, key)
		}
		return out
	}

	function remove(operations, key = operations.map(x => x.key).join('')) {
		compilation(key, operations)

		const $ = cache[key]
		setState(state => $.remove([state])[0])

		propagateFrom($)

		return getState()
	}

	const comparator = (x, y) => {
		return rank[x] > rank[y] ? 1 : rank[y] > rank[x] ? -1 : 0
	}

	const keySort = xs =>
		xs.sort((x, y) => {
			return comparator(x, y)
		})

	let computePropagationNodesCache = {}
	function computePropagationNodes(key) {
		if (!(key in cache)) {
			return []
		}
		// find all the nodes that have a requirement on this key
		// being updated.
		//
		// also, for each key we find, find any keys that have a
		// propagation requirement on that key being propagated.
		//
		// finally sort the list based on rank, then cache and return.

		// ok so naive, just scan requirements, doing double index look ups
		// that means, for as many queries as there are in tree we'll look up
		// that many + any requirements they have
		// so inefficient, but accurate.
		//
		// as we record requirements, we could record when this cache is
		// invalidated so we can cache aggressively.
		//
		// So if this key, or any of the keys mentioned are removed,
		// delete this cache.
		//
		// And, if a new key is compiled, that has a requirement on any
		// of the final resolved list, we drop this cache as well.
		//
		// aha!
		//
		// if we are about to add an item to the stack, we can instead
		// check if that key we are adding is already in the cache
		// and because we know our caches are always valid (as above)
		// we can skip the extra loop
		//
		// so really, most of the time, a new compute will rely on other
		// caches and by on average about O(2n) instead of O(n^2) or
		// O(log(n))

		if (key in computePropagationNodesCache) {
			return computePropagationNodesCache[key]
		} else {
			const set = new Set()

			const updateRequirementsList = Object.keys(
				updateRequirements.getOuter(key),
			)

			// keys that react to us, or the updates being propagated
			const stack = [].concat(parents[key], key, updateRequirementsList)

			while (stack.length) {
				const k = stack.shift()

				if (!set.has(k)) {
					set.add(k)

					{
						// propagationRequirements is used as a sentinel
						// that there is a dynamic that depends on a key
						// propagating
						//
						// if this sentinel is satisfied, we grab
						// all nodes that would have an update requirement
						// and propagate them too
						const xs = Object.keys(propagationRequirements.getOuter(k))

						// if $org has a propagationRequirement on
						// .organizations
						// then if we update $org.name all sub org queries
						// could be affected, so they need to re-propagate
						// when $org is ended, if its the only dynamic on that
						// path, then this check will no longer occur and those
						// children will no longer need to be propagated
						if (xs.length) {
							stack.push(...Object.keys(updateRequirements.getOuter(k)), ...xs)

							// dynamic children need to be included in the stack
							// too when this happens
							// e.g. $org.name
							//
							// $org.name doesn't have an update requirement on
							// org_id changing
							//
							// but it is a direct child of a dynamic
							// so it needs to update
							//
							// should queries inherit propagation requirements
							// at compile time, would that break cleanup?
							// I don't think so
						}
					}
				}
			}

			const out = keySort([...set]).map(k => staggeredCache[k])

			computePropagationNodesCache[key] = out

			return out
		}
	}

	// eslint-disable-next-line complexity
	function propagateFrom($) {
		let isParentPropagation = !propagating
		if (stopPropagationLoop) {
			return
		}
		let entryPropagationValue = propagating
		if (!propagating) {
			propagationMemory = {}
		}
		if (!entryPropagationValue) {
			propagating = true
		}

		let nodes = computePropagationNodes($.key)

		let nodeKeys = new Set()
		for (let $ of nodes) {
			let key = $.key
			nodeKeys.add(key)
			stale[key] = true

			const parentKey = parents[$.key][parents[$.key].length - 1]
			// should always be available
			let parentValue =
				parentKey == '' || $.key == '' ? [getState()] : values[parentKey]
			values[key] = $.select(parentValue)
		}

		// Because we marked as stale the stream visitors won't
		// re-evaluate automatically
		// But operations like .$filter will not use a stale value
		// because the dependency values are
		// read from the cache instead of from the stream.

		// update streams in a separate pass to ensure
		// cache is fully propagated custom stream visitors run

		if (isParentPropagation) {
			for (let $ of nodes) {
				let key = $.key
				if (key in streams && !equals(values[key], streams[key]())) {
					streams[key](values[key])
					if (stopPropagationLoop) {
						break
					}
				}
				delete stale[key]
			}

			for (let key of deferredStreamEmits) {
				if (key in streams && !equals(values[key], streams[key]())) {
					streams[key](values[key])
					if (stopPropagationLoop) {
						break
					}
				}
				delete stale[key]
			}

			for (let key of propagatingStreamEmits) {
				update(x => x, operations[key], key)
				if (stopPropagationLoop) {
					break
				}
				delete stale[key]
			}
		} else {
			for (let $ of nodes) {
				let key = $.key
				if (key in streams && !equals(values[key], streams[key]())) {
					deferredStreamEmits.push(key)
				}
				delete stale[key]
			}
		}

		stopPropagationLoop = false
		if (!entryPropagationValue) {
			propagating = false
			deferredStreamEmits = []
			propagatingStreamEmits = []
			updates = []
		}
	}

	// Z is designed to be used via a proxy.
	// The proxy allows you to construct queries by dot chaining
	// methods/properties and finally invoking the query to select/update.
	//
	// Behind the scenes the proxy is simply building a lost of operations
	// which are passed to the lower level functions.
	//
	// The operations are used to compile the query, and after compilation
	// the operations are used to compute the key which is used for most indexes
	// in Z
	function ProxyResponse(xs, key = xs.map(x => x.key).join('')) {
		function prop(...args) {
			if (args.length) {
				if (typeof args[0] == 'function') {
					return update(args[0], xs, key)
				} else {
					return update(() => args[0], xs, key)
				}
			} else {
				return select(xs, key)[0]
			}
		}

		prop.toString = function () {
			prop.valueOf() + ''
		}

		prop.valueOf = function () {
			return prop.$get()[0]
		}

		prop.$get = () => {
			return select(xs, key)
		}

		prop.$set = x => {
			return update(() => x, xs, key)
		}

		prop.$update = x => {
			return update(x, xs, key)
		}

		prop.$key = key

		prop.$all = function (...args) {
			if (args.length) {
				if (typeof args[0] == 'function') {
					return update(args[0], xs, key)
				} else {
					return update(() => args[0], xs, key)
				}
			} else {
				return select(xs, key)
			}
		}

		prop.$type = 'how/z2-proxy'

		prop.$remove = function () {

			// todo-james should we auto call $end() afterwards
			// why keep a query around for an object that won't be in the tree
			// anymore
			// if they reuse it, it will just recompile it
			return remove(xs, key)
		}

		prop.$throttled = function (ms = 500) {
			return throttle(ms)(prop.$stream())
		}

		prop.$afterSilence = function (ms = 500) {
			return afterSilence(ms)(prop.$stream())
		}

		prop.$stream = function () {
			const out = Stream.of()
			seenStreams.set(out, key)
			queryStreams.set(out, key)

			const all = $stream(xs)

			Stream.subscribe(xs => {
				if (xs.length) {
					out(xs[0])
				} else if (Stream.hasVal(out)) {
					out(xs[0])
				}
			}, all)

			return out
		}

		prop.$stream.all = function () {
			return $stream(xs)
		}

		prop.$end = function () {
			return end(xs, key)
		}

		prop.$operations = function () {
			return xs
		}

		prop.$filter = function (...args) {
			if (dev.enforceArrowUsage) {
				const visitor = args[0]
				if (typeof visitor == 'function') {
					const hasArrow = (visitor + '').includes('=>')

					if (!hasArrow) {
						throw new Error(
							`${key}.$filter(...) has no key and appears to be using a curried function`
								+ ` this can lead to two unrelated queries sharing the same key.`
								+ `  To fix this we recommend using an arrow function to invoke your arguments`
								+ ` or provide a key, e.g. ${key}.$filter('my-filter', '...').`
								+ `\n`
								+ `Note: You can disable this option by passing { dev: { enforceArrowUsage: false } }`
								+ ` when initializing Z.`,
						)
					}
				}
			}

			return ProxyResponse(xs.concat($filter(...args)))
		}

		prop.$map = function (...args) {
			if (dev.enforceArrowUsage) {
				const visitor = args[0]
				if (typeof visitor == 'function') {
					const hasArrow = (visitor + '').includes('=>')

					if (!hasArrow) {
						throw new Error(
							`${key}.$map(...) has no key and appears to be using a curried function`
								+ ` this can lead to two unrelated queries sharing the same key.`
								+ `  To fix this we recommend using an arrow function to invoke your arguments`
								+ ` or provide a key, e.g. ${key}.$map('my-map', '...').`
								+ `\n`
								+ `Note: You can disable this option by passing { dev: { enforceArrowUsage: false } }`
								+ ` when initializing Z.`,
						)
					}
				}
			}

			return ProxyResponse(xs.concat($map(...args)))
		}

		prop.$filterValues = function (...args) {
			return ProxyResponse(xs.concat($values)).$filter(...args)
		}

		prop.$mapValues = function (...args) {
			return ProxyResponse(xs.concat($values)).$map(...args)
		}

		const proxy = new Proxy(prop, {
			has(_, key) {
				return key in prop || key in (prop.$get()[0] || {})
			}
			,deleteProperty(_, key) {
				const x = proxy[key]
				return x.$remove()
			}
			,set(_, property, value) {
				if (property in prop && property != 'name') {
					return prop[property]
				} else {
					return proxy[property].$set(value)
				}
			}
			,get(_, property) {
				if (property == Symbol.toPrimitive) {
					return () => prop.$get()[0]
				} else if (property == Symbol.iterator) {
					return function* () {
						for (let x of prop.$get()) {
							yield x
						}
					}
				} else if (
					property in prop
					&& property != 'name'
					&& property != 'length'
				) {
					return prop[property]
				} else if (property == '$values') {
					return ProxyResponse(xs.concat($values))
				} else {
					const newKey = key + '.' + property

					if (newKey in proxies) {
						return proxies[newKey]
					} else {
						proxies[newKey] = ProxyResponse(xs.concat($prop(property)))
						return proxies[newKey]
					}
				}
			},
		})

		return proxy
	}

	function end(xs, $key = xs.map(x => x.key).join('')) {
		// You may have defined a child query and then
		// say you want to end the root, but that root was never
		// used directly.  And because Z is lazy, that will prevent
		// end from working correctly.
		//
		// e.g. you define route.value.user_id but you never read/write
		// directly to route, then the route query was never compiled
		//
		// so what we do is, compile the key if it doesn't exist so we can
		// remove all its children
		//
		// seems wasteful, but all the logic for understanding relationships
		// is inside compilation, so why duplicate it?
		// its also an edge case, hopefully in practice the context you are
		// ending is read and written directly so this branch never runs.
		if (!($key in cache)) {
			compilation($key, xs)
		}

		// Nodes to be removed...
		const pendingRemoval = [$key].concat(
			Object.keys(updateRequirements.getOuter($key)),
		)

		for (let key of pendingRemoval) {
			delete cache[key]
			delete staggeredCache[key]
			if (key != '') {
				delete values[key]
				delete operations[key]
				delete parents[key]
				delete propParents[key]
				delete nonPropParents[key]
				delete rank[key]
			}
			delete proxies[key]
			if (key in streams) delete streams[key]
			delete stale[key]
			delete rank[key]

			delete computePropagationNodesCache[key]

			// We're deleting a reactor, we need to remove our reactor
			// from any instigator indexes that included us.
			// updateRequirements has a mirror index of the main index
			// so we can lookup all the instigators that have us indexed
			// then remove ourselves from their cache
			Object.keys(updateRequirements.getMirrorOuter(key)).forEach(
				instigatorKey => {
					updateRequirements.remove(instigatorKey, key)
					delete computePropagationNodesCache[instigatorKey]
				},
			)
			Object.keys(propagationRequirements.getMirrorOuter(key)).forEach(
				instigatorKey => {
					propagationRequirements.remove(instigatorKey, key)
					delete computePropagationNodesCache[instigatorKey]
				},
			)

			updateRequirements.remove(key)
			propagationRequirements.remove(key)
		}
	}

	updateState(state => {
		if (!ignoreNotification) {
			update(() => state, [])
		}
	})

	$stream([])

	return {
		getState
		,setState
		,state: state
		,select
		,update
		,remove
		,cache
		,staggeredCache
		,stale
		,values
		,streams
		,queryStreams
		,$prop
		,$filter
		,$values
		,$stream
		,end
		,rank
		,parents
		,ProxyResponse
		,$: ProxyResponse([])
		,computePropagationNodes
		,computePropagationNodesCache
	}
}

function query(state) {
	return Z3({ initial: state }).$
}

function isZ2Proxy(x) {
	if (x == null) {
		return false
	} else {
		return x.$type == 'how/z2-proxy'
	}
}

export { Z3 as Z, isZ2Proxy, query }
