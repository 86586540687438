
import { map, compose } from 'ramda'

import {
	$
	,env
	,ScheduleTask
	,TaskState
	,Response
	,Request
	,Sources
	,Sinks
	,NotificationRequest
} from './types'

//eslint-disable-next-line no-undef
const checkTypes = !!process.env.CHECK_TYPES
const def =
	$.create({ checkTypes , env })

const taskPrefix = task => `${task.schedule_name} (${task.schedule_priority})`

const taskMessage =
	{
		Started: `has started`
		,Failed:  `failed to generate`
		,Complete: `completed successfully`
		,Cancelled: `successfully cancelled`
}

const taskRoute = task =>
	[ ''
	, 'dashboard'
	, task.organization_id
	, task.schedule_id
	, task.schedule_version_id
	]
		.join('/')

const processNotificationRequest = (requests, notify) =>
	NotificationRequest.case({
		TaskStarted: task =>
			notify({
				error: false
				, message: taskPrefix(task) +' '+ taskMessage.Started
				, buttons: [{
					label: 'Okay'
					,action: () => void 0
				}]
				})
		,TaskFailed: task =>
			notify({
				error: true
				, message: taskPrefix(task) +' '+ taskMessage.Failed
				, buttons: [{
					label: 'Okay'
					,action: () => void 0
				}]
			})
		,TaskComplete: task =>
			notify({
				error: false
				, message: taskPrefix(task) +' '+ taskMessage.Complete
				, buttons: [
					{
						label: 'Okay'
						,action: () => void 0
					}
					,{
					label: 'View'
					,action: () =>
						requests(Request.RedirectGantt(task))
				}]
			})
		,TaskCancelled: task =>
			notify({
				error: false
				, message: taskPrefix(task) +' '+ taskMessage.Cancelled
				, buttons: [{
					label: 'Okay'
					,action: () => void 0
				}]
			})
	})

const processTask =
	def('processTask', {}, [ScheduleTask, TaskState]
		,function(task){
			return task.task_failed
				? TaskState.TaskFailed(task)
			: task.task_progress == 100
				? TaskState.TaskComplete(task)
			: task.task_cancelled
				? TaskState.TaskCancelled(task)
				: TaskState.TaskRunning(task)
		}
	)

const processResponse =
	function(response){

		const taskState =
			Response.case({
				Task: processTask
				,TaskStarted: TaskState.TaskStarted
			}, response)

		const request =
			TaskState.case({
				TaskRunning: () => Request.Nothing()
				,TaskFailed:
					compose(
						Request.NotificationRequest
						, NotificationRequest.TaskFailed
					)

				,TaskComplete:
					compose(
						Request.NotificationRequest
						, NotificationRequest.TaskComplete
					)
				,TaskStarted:
					compose(
						Request.NotificationRequest
						, NotificationRequest.TaskStarted
					)
				,TaskCancelled:
					compose(
						Request.NotificationRequest
						, NotificationRequest.TaskCancelled
					)
			}, taskState)


		return request
	}

const Main =
	def('Main', {}, [ Sources, Sinks ]

		, function Main({responses}){

			const requests =
				map(
					processResponse
					,responses
				)

			return {requests}
		}
	)

//eslint-disable-next-line
export {
	Main
	, processNotificationRequest
	, taskMessage
	, taskPrefix
	, taskRoute
	, processTask
	}

export default {
	Main
	, processNotificationRequest
	, taskMessage
	, taskPrefix
	, taskRoute
	, processTask
	}