/* globals document, clearTimeout, setTimeout, requestAnimationFrame */
import m from 'bacta'
import Promise from 'bluebird'
import { prop as stream } from '../../../stream'
const delay = Promise.delay

const transform = (el,x,y) => () => {
	requestAnimationFrame(

		()	=> el.style.transform = `translate(${x}, ${y})`
	)

}
const transformX = (el, x) => transform(el, x, '0px')
const transformY = (el, y) => transform(el, '0px', y)

const notifications = []
const notification = stream()

const style = (() => {

	const thinRoundSolidWhiteBorder =
		' br2 b--white b--solid bw1'

	const interactive =
		' dim'

	const breathingRoom =
		' ml3 ph2'

	const baseNotification =
		' f3 absolute w-100 pv2 overflow-hidden'

	const errorNotification =
		baseNotification
		+ ' washed-blue bg-red'

	const standardNotification =
		baseNotification
		+ ' bg-blue light-gray'

	const button =
		thinRoundSolidWhiteBorder
		+ interactive
		+ breathingRoom
		+ ' border-box lh-title'

	const buttonHash =
		{ backgroundColor: 'inherit'
		, minWidth: '3em'
		, transition: '0.2s'
		, fontSize: '1em'
		}

	const container =
		'div.notification-container'
		+'.absolute.overflow-hidden.top-0.left-0.w-100.h-100'

	const containerHash =
		{ pointerEvents: 'none'
		}

	const notificationHash =
		{ top: '-2em'
		, left: '0px'
		, minHeight: '2em'
		, transform: 'translate(0px, 110vh)'
		, pointerEvents: 'all'
		, transition: '1s'
		}

	const notificationContentHash = {
		transform: 'translate(0px, 0px)'
		,transition: '1s'
		// sidebar closed width +/- padding
		,paddingLeft: '2.3em'
	}

	return {
		button
		,buttonHash
		,standardNotification
		,errorNotification
		,notificationHash
		,container
		,containerHash
		,notificationContentHash
	}

})()


function busyWhile(busy){
	//eslint-disable-next-line no-var
	var running = true;

	busy()
		.then(function(){

			running = false
			//eslint-disable-next-line no-param-reassign
			busy = null
		})

	return function(){
		return running
	}
}

const component = function Notification(container){

	const el = stream()
	const elContent = stream()
	const elMessage = stream()
	const elButtons = stream()

	//eslint-disable-next-line no-var
	var last

	const clearNotificationHTML = () => {

		elMessage().innerText = elButtons().innerHTML = ""
	}
	/**
	 * A: if there is a new notification
	 *  if the text is not empty
	 * 		clear it
	 * 	if the bar is hidden
	 * 		show it
	 *  if the content is not the right of the screen
	 * 		move it to the right
	 * 	populate the content el with the new notification message manually
	 * 	slide the message in
	 *
	 * B: if a notification is removed
	 *	slide out the current notification to the left
		empty the content
		slide to the right to prep for future notifications
		if there are no more notifications
			hide the bar
		if there are more notifications
			go to A
	*
	*/
		const newNotification = () => {
			clearNotificationHTML()

			transformX(elContent(), '100vw')()
			return delay(1000)
				.then(showBar)
				.then(
					() => manuallyRenderNotification(notifications[0])
				)
				.then(transformX(elContent(), '0px'))
				.then( () => delay(1000) )
		}

		const notificationRemoved = () => {
			return showBar()
				.then( transformX( elContent(), '-100vw') )
				.then( () => delay(1000) )
				.then(clearNotificationHTML)
				.then(
					transformX( elContent(), '100vw' )
				)
				.then( () => delay( 1000) )
				.then(function(){
					renderError(notifications[1] || {})
					if(notifications.length){
						return newNotification()
					} else {
						return hideBar()
					}
				})
		}

	const renderError = (notification) => {

		el().className =
			notification.error
			? el().className
				.replace(style.standardNotification, style.errorNotification)
			: el().className
				.replace(style.errorNotification, style.standardNotification)
	}

	const manuallyRenderNotification = (notification) => {
		requestAnimationFrame(function(){


			elMessage().innerText = notification.message
			renderError(notification)

			const timeoutID = setTimeout(
				next, notification.timeout || 30 * 1000
			)

			function next(){
				clearTimeout(timeoutID)
				notifications.shift()
				redraw()
			}


			Array.from(elButtons().children).forEach(function(el){
				//should remove old listeners too
				el.remove()
			})

			elButtons().innerHTML = ""
			notification.buttons.forEach(
				b => {

					const button = document.createElement('button')

					button.innerText = b.label

					button.className = style.button

					button.onclick = () => {
						b.action()
						next()
					}
					Object.assign(button.style, style.buttonHash)
					elButtons().appendChild(button)
				}
			)
		})
	}

	function MoveBarY(vh){
		return function(){
			if(el().style.transform.includes(vh)){
				return Promise.resolve()
			} else {
				transformY(el(), vh)()
				return delay(1000)
			}
		}
	}

	const hideBar = MoveBarY('110vh')
	const showBar = MoveBarY('100vh')

	function setupEls({ dom: node }){

			el(node)
			elContent(node.children[0])
			elMessage(node.children[0].children[0])
			elButtons(node.children[0].children[1])

	}


	/**
	 *	Redraw Logic
	*  ------------
	*
	*	if animating
	*		redraw again when animation completed
	*	if not animating
	*
	*		if last != notifications[0] //something has happened this frame
	*
	*			if last && notifications[0] //remove notification
	*			if !last && notifications[0] //new notification
	*
	*		if last == notifications[0] //most redraws, noop
	*/

	//eslint-disable-next-line no-var
	var animating = busyWhile( () => Promise.resolve() )

	function transitions(){
		if( animating() ){
		} else {
			const something_happened = last != notifications[0]

			if (something_happened) {
				const notification_removed = last && !notifications[0]
				const notification_added = !last && notifications[0]
				const notification_queued = last && notifications[0]
				if(notification_removed || notification_queued){

					animating = busyWhile(notificationRemoved)
				} else if ( notification_added){

					animating = busyWhile(newNotification)
				} else {}
			}
		}
	}

	function redraw(){
		transitions()

		last = notifications[0]
	}

	notification.map(function(notification){
		notifications.push(notification)
		redraw()

		return notification
	})

	requestAnimationFrame(function(){

		container.className = 'absolute w-100 h-100 top-0 left-0'
		// get the zIndex from some module that has all the zIndexes
		// sidebar + 1 instead of 3

		container.style.zIndex = '3'

		container.style.pointerEvents = "none"
	})

	m.render(
		container
		,m(style.container

			,m('div.notification'
				,{ oncreate: setupEls
				, style: style.notificationHash
				, className: style.standardNotification
				}
				,m('.notification-content.relative'
					,{ style: style.notificationContentHash }
					,m('span')
					,m('.notification-buttons.di.pl2')
				)
			)
		)
	)
}

const Notifications = {
	ScheduleGenerated: () => ({
		message: 'A schedule is currently being generated.  '
			+ 'It will complete in approximately 5 minutes.'
		,buttons: [
			{
				label: 'Ok'
				,action: () => {}
			}
		]
	})
	,ErrorNotification: () => ({
		message: 'This is an error'
		,error: true
		,buttons: [
			{
				label: 'Ok'
				,action: () => {}
			}
		]
	})
	,alert: (message) => notification({
		message
		,buttons: [{ label: 'Ok', action: (I) => I }]
	})
	,alertError: function(message){
		return notification({
			message
			,error: true
			,buttons: [{ label: 'Ok', action: (I) => I }]
		})
	}
}


export { Notifications, notification, component }
export default { Notifications, notification, component }