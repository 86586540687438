/**
 * Formats a database style key to be human readable.
 *
 * e.g. prettyKey('schedule_management') //=> Schedule Management
 */
export default function(key=''){
	return key
		.split('_')
		.map(
			(k) => k[0].toUpperCase() + k.slice(1)
		)
		.join(' ')
}