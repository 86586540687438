
/* globals setTimeout, clearTimeout */
import m from 'bacta'
import prettyKey from '../utils/prettyKey'
import traverse from 'traverse'
import * as R from 'ramda'
import css from 'bss'
import { prop as Stream } from '../../../stream/index'
import * as elements from './elements'
import NumberInput from './number'
const removeKeysFromJSON = R.replace(/\"(\w|_)+\":/g, '')
const removeSpecialCharactersFromJSON = R.replace(/\{|\[|\}|\]|\"/g, '')
const formatSearchList =
	R.pipe(
		JSON.stringify
		,R.toLower
		,removeKeysFromJSON
		,removeSpecialCharactersFromJSON
		,R.split(',')
		,R.unnest
	)

const numberOperators = {
	"is" : (a,b) => a == b
	,"isn't" : (a,b) => a != b
	, ">" : (a,b) => a > b
	, "<" : (a,b) => a < b
	, "≤" : (a,b) => a <= b
	, "≥" : (a,b) => a >= b
}

const stringOperators = {
	"Contains" : (a, b) => !b || a && R.toLower(a).indexOf(R.toLower(b)) >= 0
	,"Doesn't Contain": (a, b) => !b || a && R.toLower(a).indexOf(R.toLower(b)) == -1
	,"Blank" : (a) => !a
	,"Not Blank" : (a) => a
}

const dateOperators =
	R.merge(
		{
			"Blank" : (a) => !a
			,"Not Blank" : (a) => a
		}
		,numberOperators
	)

const allOperators =
	R.merge(
		numberOperators
		,stringOperators
	)

const INDEX_SENTINEL = '<index>'

const numberInequalities = Object.keys(numberOperators)
const stringInequalities = Object.keys(stringOperators)
const dateInequalities = Object.keys(dateOperators)

const renderTimeout = m.prop()
const listPotentiallyStale = Stream()

listPotentiallyStale.map(function(x){
	if( x ){
		clearTimeout( renderTimeout() )
		renderTimeout(setTimeout(
			() => {
				listPotentiallyStale(false)
				m.redraw()
			}
			,300
		))
	}
	return null;
})



function traversePaths(o, selectedPaths){

	const comparison =
		!selectedPaths
			? R.pipe(R.last, R.test(/\d/))
			: R.pipe(
				R.last
				,(a) => !selectedPaths.find((b) => a == b )
			)

	return traverse(o).paths()
		.filter(
			R.complement(
				R.anyPass([
					R.isEmpty
					,comparison
				])
			)
		)
}

function makeFilterOption(template, filter, changeNames, uniqueArrays){

	filter.propCondition().unique(uniqueArrays)

	const strip = (key, upgrade) => {
		const ugly = upgrade.ugly_name
		const start = key.indexOf(ugly)

		return key.slice(start + ugly.length)
	}

	const filterOptions =
		traversePaths(template)
			.map(function(propPath){
				const x = R.path(propPath, template)
				const key = R.last(propPath)

				const propType =
					Array.isArray(x)
						? 'array'
					: x == 'date'
						? 'date'
						: typeof x

				const prefix =
					propType == 'array'
						? 'amount_of_'
						: ''

				const upgrade =
					R.either(
						R.find(
							R.both(
								R.propSatisfies(R.equals(key), 'ugly_name')
								,R.prop('better_name')
							)
						)
						,R.find(
							R.propSatisfies(R.contains(R.__, key), 'ugly_name')
						)
					)(changeNames)

				const propName =
					upgrade
						? upgrade.better_name
							? prettyKey(prefix + upgrade.better_name)
							: prettyKey(prefix + strip(key, upgrade))
						: prettyKey(prefix+key)

				return {
					propPath: propPath
					,propName: propName
					,propType: propType
				}
			})
			.map(
				R.evolve({
					propPath: R.map(R.replace(/0/g, INDEX_SENTINEL))
				})
			)
			.map( R.map(m.prop) )

	return { filterOptions }
}

// todo-james assumes there's only 1 filter view at a time
// ( which I think is fine? )
const totalReturned = m.prop(0)
const totalSearched = m.prop(0)

function filterView(
	template
	, filters
	, changeNames
	, uniqueArrays
	, intialDisabled
	, marker
	, scoped
){

	const generatedElements = []
	const newFilter = {
		objProp: m.prop(null)
		,propName: m.prop(null)
		,propValue: m.prop(null)
		,propType: m.prop(null)
		,propCondition: m.prop({
			equality: m.prop(null)
			,unique: m.prop(null)
		})
		,propInclusive: m.prop(filters.length == 0 ? 'Or' : 'And' )
		,sortName: m.prop(null)
		,sortobjProp: m.prop(null)
		,sortActivate: m.prop(false)
	}
	filters.length == 0 ? filters.push(newFilter) : null

	filters.forEach(function(filter, index){

		let Afilter = []
		if (index == 0){
			filter.propType("search")
			filter.objProp({
				propType: m.prop("search")
			})
			Afilter.push(m('h4', 'Search '))
			Afilter.push(
				elements.textInput(
					filter.propValue
					, {autofocus: true}
				)
			)
			Afilter.push(
				m(`button.btn.btn-secondary`
					+ css`
						backgroundColor: transparent;
						border: solid 1px;
						position: relative;
						height: 2.5em;
						width: 5em;
					`
					.$hover(`
						opacity: 0.8;
					`)
					.$active(`
						opacity: 0.5;
					`)
					, {
						onclick: () => {
							filter.propValue(null)
						}
						,disabled: !filter.propValue()
					}
				,"Clear")
			)

			Afilter.push(
				m(`button.btn.btn-secondary`
					+ css`
						backgroundColor: transparent;
						border: solid 1px;
						position: relative;
						height: 2.5em;
						width: 5em;
					`
					.$hover(`
						opacity: 0.8;
					`)
					.$active(`
						opacity: 0.5;
					`)
					, {
						disabled: intialDisabled()
							? true
							: filters.length == 1 ? false
							: filters[filters.length - 1] == null ? true
							: filters[filters.length - 1].propValue() == null
							&& filters[filters.length - 1].propType() != 'date'
							|| filters[filters.length - 1].propType() == 'date'
							&& filters[filters.length - 1].propValue() == null
							&& filters[filters.length - 1].propCondition().equality() != 'Blank'
							&& filters[filters.length - 1].propCondition().equality() != 'Not Blank'
							? true : false
						,onclick: () => {
							filters.push(
								{
									objProp: m.prop(null)
									,propName: m.prop(null)
									,propValue: m.prop(null)
									,propType: m.prop(null)
									,propCondition: m.prop({
										equality: m.prop(null)
										,unique: m.prop(null)
									})
									,propInclusive: m.prop(filters.length == 0 ? 'Or' : 'And' )
									,sortName: m.prop(null)
									,sortobjProp: m.prop(null)
									,sortActivate: m.prop(false)
								}
							)
						}
						,title: filterTitle(marker)
				}, "+ Filter")
			)

			Afilter.push(
				m('label.control-label', {
					oncreate({ dom: el }){
						setTimeout(function(){

							el.innerText = totalReturned()
						})
					}
				}, totalReturned() )
				, ' of '
				,m('label.control-label', totalSearched() )
			)

		} else {
			let options = makeFilterOption(template, filter, changeNames, uniqueArrays)
			let filterOptions = options.filterOptions
			index > 0 && Afilter.push(elements.select(['And','Or'], filter.propInclusive ))

			Afilter.push(
				elements.objectSelect({
					list: filterOptions
					, key: 'propName'
					, prop: filter.propName
					, objProp: filter.objProp
				})
			)

			if (filter.objProp() != null){

				filter.propCondition().equality()
					? null
					: filter.objProp().propType() == "string"
						? filter.propCondition().equality('Contains')
						: filter.propCondition().equality('is')



				filter.propValue(
					filter.objProp().propType() == "date"
					? filter.propValue() // new Date()
					: filter.propType() == filter.objProp().propType()
					? filter.propValue()
					: null
				)

				//  If its a string, generate text box
				if (filter.objProp().propType() == "string" ){

					filter.propType("string")
					Afilter.push(
						elements.select(
							stringInequalities
							,(a) => {
								if (a){
									a == 'Blank' || a == 'Not Blank'
										? filter.propValue('')
										: null
									filter.propCondition().equality(a)
								}
								return filter.propCondition().equality()
							}
							, {}
						)
					)
					Afilter.push(
						elements.textInput(
							filter.propValue
							, {}
						)
					)
				}
				// If its a date
				if (filter.objProp().propType() == "date" ){
					filter.propType("date")
					Afilter.push(
						elements.select(
							dateInequalities
							, filter.propCondition().equality
							, {}
						)
					)

					if(
						!['Blank', 'Not Blank']
							.includes(
								filter.propCondition().equality()
							)
					){
						Afilter.push(
							elements.dateInput( filter.propValue )
						)
					}

				}
				//  If its a number, generate number input box
				if (filter.objProp().propType() == "number" ){
					filter.propType("number")
					Afilter.push(
						elements.select(
							numberInequalities
							, filter.propCondition().equality
							, {}
						)
					)
					Afilter.push(
						m(
							NumberInput
							,{
									scoped
									,errors: m.prop({})
									,errorLabel: filter.objProp().propName()
							}
							,{
								prop: filter.propValue
								,attrs: {}
							}
						)
					)
				}
				//  If its a boolean, generate checkbox box
				if (filter.objProp().propType() == "boolean" ){
					filter.propType("boolean")
					filter.propValue(
						filter.propValue() == null
							? filter.propValue(true)
							: filter.propValue()
					)
					Afilter.push(
						m('label.control-label'
							+ css`
								display: flex;
								gap: 0.5em;
								align-items: center;
							`
							,'Yes or No'
							,elements.checkbox({
								onchange: m.withAttr('checked', filter.propValue)
								,checked: filter.propValue()
							})
						)
					)
				}
				//  If its an array, generate array number box
				if ( filter.objProp().propType() == "array" ){
					filter.propType("array")
					Afilter.push(
						elements.select(
							numberInequalities
							, filter.propCondition().equality
							, {}
						)
					)
					Afilter.push(
						m(
							NumberInput
							,{
								scoped
								,errors: m.prop({})
								,errorLabel: filter.objProp().propName()
							}
							,{
								prop: filter.propValue
								,attrs: {}
							}
						)
					)
				}
			}

			Afilter.push(
				elements.xRemoval({
					onclick: () => {
						filters.splice(index, 1)
						generatedElements.splice(index, 1)
					}
					,title: 'Remove filter from search.'

				})
			)
		}
		const newfilter = elements.list(Afilter)
		generatedElements.push(newfilter)
	})

	function modifyHandler(vnode){
		if( vnode.tag in { input: 1, select: 1 } ){
			const oldChange = vnode.attrs.oninput
			return Object.assign(vnode.attrs, {
				oninput: oldChange
					? e => {
						listPotentiallyStale(true)
						return oldChange(e)
					}
					: () => {
						listPotentiallyStale(true)
					}
			})
		} else if( vnode.children ) {
			return [].concat(vnode.children).forEach(modifyHandler)
		} else {
			return null
		}

	}
	const generatedFilterView = {
		generatedElements: generatedElements.map( R.tap( modifyHandler))
		, filters: filters
	}
	return generatedFilterView
}


const validValue = (str, type) => str != null || type == "string" || type == "date"
const words = phrase => String(phrase).toLowerCase().split(/\s+/).filter(Boolean)
const searchingObject = filterValue => {
	const filterWords =
		validValue(filterValue)
			? words(filterValue)
			: []

	return filterValue
		? (itemValue) => {
			const itemWords = words(itemValue)
			return filterWords.every(
				 filterWord => itemWords.some( itemWord => itemWord.startsWith(filterWord))
			)
		}
		: () => true
}

function searchingObjects(itemValues, filterValue){
	return itemValues
		.find(searchingObject(filterValue) )
}

function checkSearchItem(item, f){
	const itemValues = formatSearchList( item )
	const filterValue = f.propValue()
	return searchingObjects(itemValues, filterValue)
}

function checkSpinItem(item, f){
	const type = f.objProp().propType()
	const filterValue = f.propValue()
	const operator = allOperators[f.propCondition().equality()]

	const path = f.objProp().propPath()
	const uniqurArray =
		f.propCondition().unique().find(
			(u) => u.array_name == path.slice(
				path.lastIndexOf('<index>') + 1, path.length
			)
		)

	const uniqueCondition = !uniqurArray
		? null
		: uniqurArray.unique

	return spinCycle(
		path
		,item
		,type
		,filterValue
		,operator
		,uniqueCondition
	)
}

const partitionActiveFilters =
	R.pipe(
		R.filter( (f) => validValue(f.propValue(), f.propType()) )
		,R.partition(f => f.propInclusive() == 'And')
	)

const eitherProp = (key) =>
	R.pipe(
		R.prop(key)
		,R.when(R.is(Function), (p) => p())
	)

function ListProcessing(list=[], filters, sortfield, returnContainer){
	const filteredList =
		list.filter(function(item){

			const [searchFilter, ...spinFilters] = filters

			const searchResult =
				!searchFilter || !searchFilter.propValue()
					? true
					: checkSearchItem(item, searchFilter)

			const [ands, ors] =
				searchResult
				? partitionActiveFilters(spinFilters)
				: [[],[]]

			const andResult =
				searchResult
				&& ands.every(function(f){
					return checkSpinItem(item, f)
				})

			const orResult =
				andResult
				|| ors.some(function(f){
					return checkSpinItem(item, f)
				})

			return orResult
		})

	const sort =
		R.ifElse(
			() => sortfield == null
			,R.identity
			,R.sortBy(
				R.pipe(
					eitherProp(sortfield)
					,R.ifElse(
						R.is(String)
						,R.toLower
						,R.identity
					)
				)
			)
		)

	if ( filters.length ){
		totalReturned(filteredList.length)
		totalSearched(list.length)
		returnContainer
			? returnContainer(filteredList)
			: null
	} else if( returnContainer ){
		returnContainer([])
	}


	return sort(filteredList)
}


function spinCycle(
	templatePath
	,item
	,type
	,filterValue
	,operator
	,uniqueConditionKey
){
	const f =
		type == 'boolean'
			? (a,b) => b == a
			: operator

	const callIfProp =
			R.ifElse(
				R.is(Function)
				,p => p()
				,R.identity
			)

	const getProp = key =>
		R.pipe(
			R.prop(key)
			,callIfProp
		)

	return traversePaths(
			item
			,templatePath
		)
		.map(function(itemPath){
			return callIfProp(R.path(itemPath, item))
		})
		.map(function(itemValue){
			return type == 'date'
			|| type == R.toLower(R.type(itemValue))
			?		type == 'array'
					? uniqueConditionKey
						? [
							R.uniqBy( getProp(uniqueConditionKey), itemValue).length
							, filterValue
						]
						: [itemValue.length, filterValue]
					: type == 'date'
						? [itemValue, filterValue]
							.map( v => v ? new Date(v).setHours(0,0,0,0) : null	)
					: [itemValue, filterValue]

			:[false, true]
		})
		.find( R.apply(f) )
}

let FilterInterface = {
	/** Maps database property names to names meant to be read by a user */
	changeNames : [{ ugly_name: String(), better_name: String() }]

	,uniqueArrays : [{
		/** The name of the array on the containing object */
		array_name: String()

		/** The key to be used to determine if the item is unique */
		,unique: String()
	}]

	/** An object that defines the types and shape of the raw data so a form can be generated */
	,template : Object()

	/** Human readable label for the filter */
	,marker : String()

	/** m.prop([])  */
	,filters : () => []

	/** A list of headings to be used when displaying the filtered items */
	,tableHeadings: [String()]

	/** Dynamically grab the list to be filtered at run time */
	,resources: () => []

	/** Converts a resource into a mithril table row */
	,resourceView: () => null

	/** The property ListProcessing will use to sort your resources before rendering them */
	,sortField: String()
}

function getFilters(filter){
	return filterView(
		filter.template
		, filter.filters()
		, filter.changeNames
		, filter.uniqueArrays
		, filter.disabled
		, filter.marker
		, filter.scoped
	)
}

function FilterInputs({ attrs: filter=FilterInterface }){


	function view(){
		//assumes filters as already been refresh by FilterButton
		return m('div.filtered-items',
			getFilters(filter).generatedElements || null
		)
	}

	return { view }
}

function FilterButton({ attrs: filter=FilterInterface }){

	function addNewEmptyFilter(){
		//triggers creation of a new filter object
		filter.filters().push(null)

		//add the new filters to our list
		filter.filters(getFilters(filter).filters)
	}

	function view(){

		let last_filter = R.last(filter.filters())
		let filter_created_but_empty =
				last_filter
				&& !last_filter.propValue()
				&& filter.filters().length > 1

		return m(`button`
		+ css`
			backgroundColor: transparent;
			border: solid 1px;
			position: relative;
			height: 2.5em;
			width: 5em;
		`
		.$hover(`
			opacity: 0.8;
		`)
		.$active(`
			opacity: 0.5;
		`)
		,{
			disabled:
				filter.disabled()
				|| filter_created_but_empty

			,onclick: addNewEmptyFilter
			,title: filterTitle(filter.marker)

		}, '+ Filter')
	}

	return { view }
}

// function FilterTable({ attrs: filter=FilterInterface }){

// 	function view(){
// 		let processed = ListProcessing(filter.resources(), filter.filters(), filter.sortField )

// 		return elements.table(data,
// 			filter.tableHeadings,
// 			processed.map(filter.resourceView)
// 		)
// 	}

// 	return { view }
// }

function filterTitle(marker){
	return "Focus or zoom into a specific subset"
		+ " to find the finer details in your "
		+ marker+"s."
		+ " Add multiple conditions to better your focus"
}

const component = {
	FilterInputs
	,FilterButton
	,ListProcessing
	,filterView
	,filterTitle
}

export default component
export {
	FilterInputs
	,FilterButton
	,ListProcessing
	,filterView
	,filterTitle
}