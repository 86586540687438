//eslint-disable-next-line no-undef

import { prop as stream } from '../../../stream'

export default function AuthPermissions(auths, organization_id){
	return stream.merge([auths, organization_id])
		.map(function([a, o]){
			const noAccess =
				{ auth_permissions_read: 0
				, auth_permissions_write: 0
				, organization_id: null
				}

			return a.case({
				LoggedIn: () => {
					return a.auth_permissions.find(
						ap => ap.organization_id == o
					)
				}
				,LoggedOut: () => null
				,Refresh: () => null
			}) || noAccess

		})
}