// /* globals Promise */
import { Manager } from '../../utils/state-router'
import { Loading, Loadable } from '../../../../types/loadable'
import Progress from '../../modules/tasks/progress'
import m from 'bacta'

import { prop as stream } from '../../../../stream'
import Tasks from '../../modules/tasks/ui'
import Permissions from '../permissions'
const asyncMergeAll =
	xs => stream.afterSilence(
		0
		, stream.merge(xs)
	)

import {
	update
	, initial
	, Streams
	, paths
	, $Schedule
	, $ScheduleVersion
	, processServerSchedules
} from './update'

import {
	indexBy
	,pluck
	,merge
	,pipe
	,prop
} from 'ramda'

export default function Model(world){

	const write = stream()
	const read = stream.scan(update, initial(), write)

	const { streamFromPath } = Manager(read, write, 'schedule_model')

	const streams = Streams(streamFromPath, paths)

	// todo-james this is already define in the reducer
	const schedule_names =
		streams.schedules.map(
			pipe(
				Loadable.map(pluck('schedule_name'))
				,Loadable.fromLoadable([])
			)
		)

	function fetchSchedulesLocal(){
		// return world.api.schedules.fetch.allStats()
		return (
			Permissions.hasRead(world.permissions(), 'schedule_management')
				? world.api.schedules.fetch.all()
				: Promise.resolve([])
		)
			.then( processServerSchedules )
			.then(streams.schedules)
			// unwrap value for other modules that use the result?
			.then(m.redraw)
	}

	function deleteSchedule(schedule_id){
		return world.api.schedules.remove(schedule_id)
			.then(fetchSchedulesLocal)
			.then(m.redraw)
	}

	function fetchSchedulesParameters(){
		return (
			Permissions.hasRead(world.permissions(), 'schedule_management')
				? world.api.schedules_parameters.fetch.all()
				: Promise.resolve([])
		)
			.then(streams.schedule_params)
			.then(m.redraw)
	}

	const progress =
		Progress(
			Tasks.start(world)
		)

	progress
		.map(streams.progress)

	stream.dropRepeats(
		world.permissions
	)
	.map(function(p){
		if( p.organization_id != null ){
			Promise.all(
				(streams.schedule_params() || []).length == 0
				? [ fetchSchedulesLocal(), fetchSchedulesParameters()]
				: [ fetchSchedulesLocal() ]
			)
			.then(m.redraw)
		} else {
			streams.schedules( Loading() )
		}

		return null
	})

	const schedules = streams.schedules.map(
		Loadable.fromLoadable([])
	)

	function createStatsStream(){

		const out = stream()

		stream.dropRepeats(
			asyncMergeAll([
				streams.schedules
				,progress
			])
		)
		.map(([xs, ]) =>
				Promise.resolve( xs )
				.then(Loadable.fold([], function(){
					if(
						world.permissions().organization_id
						&& Permissions.hasRead(
							world.permissions(), 'schedule_management'
						)
					){
						return world.api.schedules.fetch.allStats()
					} else {
						return []
					}

				}))
				.then( x => out(x) )
		)

		return out.map( indexBy( prop('schedule_id') ) )
	}

	return merge(streams, {
		selectedVersion:
			stream.dropRepeats(streams.schedule_version)
		, schedule_id:
			stream.dropRepeats(streams.schedule_id)

		, actual: streams
		, schedules:
			stream.dropRepeats(schedules)
		, schedulesLoadable: streams.schedules
		, scheduleModel: {
			read, write
		}
		, createStatsStream
		, fetchSchedulesLocal
		, deleteSchedule
		, schedule_names
		, fetchSchedulesParameters
		, types: {
			$Schedule
			,$ScheduleVersion
		}
	})
}