/* globals splitio, process, localStorage, window */
import * as R from 'ramda'
// todo-james reference odin stream module instead
import { prop as stream } from '../../../stream'

const service = ({ auth }) => {

	let factory;
	let client
	const out = stream()

	auth.map( ({ user_id='anonymous' }) => {

		const key = user_id

		if( factory ) {
			factory = null
			client.destroy()
		}

		factory = splitio({
			startup: {
				requestTimeoutBeforeReady: 10.5
				,retriesOnFailureBeforeReady: 3
				,readyTimeout: 10.5
				,eventsFirstPushWindow: 10
			}
			,core: {
			  authorizationKey: process.env.SPLIT_IO_BROWSER
			  // your internal user id, or the account id that
			  // the user belongs to.
			  // This coudld also be a cookie you generate
			  // for anonymous users
			  ,key
			  // an OPTIONAL traffic type, if provided will be
			  // used for event tracking with the SDK client.
			  ,trafficType: 'development'
			}
		})

		client = factory.client()

		const defaults = {
			topnav: 'off'
			,uppy: 'off'
			,uppyDropbox: 'off'
			,uppyGoogleDrive: 'off'
			,uppyWebcam: 'on'
			,payments: 'off'
			,paymentsNav: 'off'
			,paymentsEnforced: 'off'
			,paymentsBanner: 'off'
			,quickStartProject: 'off'
			,Charts: 'off'
		}

		const localOverrides = {
			quickStartProject: true
		}

		const expected = localStorage['features.'+key]
			? JSON.parse(localStorage['features.'+key])
			: defaults

		const features = stream(expected)

		features.map(
			x => localStorage.setItem('features.'+key, JSON.stringify(x))
		)

		function respond(){
			const treatments = client.getTreatments(Object.keys(defaults));
			features(treatments)
		}

		client.on(client.Event.SDK_READY, respond)
		client.on(client.Event.SDK_UPDATE, respond)

		features.map(R.map( x => x == 'on'))
		.map( x => {
			if(window.location.host.includes('localhost')){
				return Object.assign({}, x, localOverrides)
			}
			return x
		})
		.map(
			x => R.assocPath(['features'], x)
		)

		.map(out)

		return null
	})

	return out
}

export default { service }