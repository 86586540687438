/* globals document */
import localStorage from './localStorage.js'
import h from './hyperscript.js'
import { v4 as id } from 'uuid'
import * as Z from './z/index.js'
import router from './router.js'

export default function start({
	initial = {},
	namespace = null,
	routes: routeAndComps = {
		Home: ['/', () => null],
	},
	defaultRender = null,
	defaultRoute = x => x.Home(),
	services = ({ ...args }) => () => ({ ...args }),
	container = document.body,
} = {}) {
	if (namespace) {
		localStorage.setNamespace('bute.v1')
	}

	const defaultInitial = {
		session_id: localStorage.getItemOrSet('session_id', id())
		,device_id: localStorage.getItemOrSet('device_id', id()),
	}
	const z = Z.Z({ initial: { ...defaultInitial, ...initial } })

	const { $ } = z

	const routes = Object.fromEntries(
		Object.entries(routeAndComps).map(([k, [a, _]]) => [k, a]),
	)

	const comps = Object.fromEntries(
		Object.entries(routeAndComps).map(([k, [_, b]]) => [k, b]),
	)

	const Route = router.Root({
		$
		,name: 'Root'
		,defaultRoute
		,routes
		,redraw: h.redraw
		,z
	})

	$.route.$stream().map(() => h.redraw())

	const RouteComponent = Route.fold(comps)

	let currentRender = defaultRender
	let prevRenderResolved = null
	let prevRender = Promise.resolve(prevRenderResolved)
	let currentAttrs = { $, Route }
	let prevRoute = $.route().tag
	let loadingRouteComp = null
	let currentWrapperRouteComponent;


	// because the onRouteChange fn is returning a component
	// every route change will animate in, even if there is shared layout
	// code
	//
	// We want them to be able to supply lifecycle hooks
	// but we don't want a new component wrapper
	// so we could take the view attribute, invoke that in the view
	// then make a no-op component and merge the lifecycle hooks onto it
	// the `this` context would be retained for the hooks but not the view
	// but we could use `apply(context)` to handle that
	// even though we don't actually care about `this` at all
	let staticWrapper = {
		view(v){
			return staticWrapper._view ? staticWrapper._view(v) : null
		}
	}

	const onRouteChange = services(currentAttrs) || (() => null)
	async function loadRouteComp() {
		if (!loadingRouteComp || loadingRouteComp.tag != Route.get().tag) {

			loadingRouteComp = Route.get()
			let result = RouteComponent(Route.get())
			let render
			if ( result == null ) {
				render = () => result
			} else if ('then' in result) {
				render = await result
			} else {
				render = result
			}
			if ('default' in render) {
				render = render.default
			} else if ('Main' in render) {
				let Main = render.Main
				render = x => h(Main, x)
			} else if ('main' in render) {
				let main = render.main
				function Main({ attrs }) {
					const view = main(attrs)
					return { view }
				}
				render = x => h(Main, x)
			}

			currentWrapperRouteComponent =
				await onRouteChange(currentAttrs)

			currentWrapperRouteComponent = currentWrapperRouteComponent || {}
			currentWrapperRouteComponent.view =
			currentWrapperRouteComponent.view || ((v) => render(v.attrs.attrs))


			let view = currentWrapperRouteComponent.view
			let lifeCycleComponent = {
				...currentWrapperRouteComponent
				, view: () => null
			}
			delete lifeCycleComponent.key
			staticWrapper._view = (v) => h('.static-wrapper-view'
				,h('.real-view',view(v))
				,h(lifeCycleComponent)
			)

			// use promise as a simple atomic queue
			// so first request wins, not first response
			prevRender = prevRender.then( () => {
				const swap = currentRender
				currentRender = render
				prevRenderResolved = swap
				loadingRouteComp = null
				return swap
			} )


			h.redraw()
		}
	}
	loadRouteComp()

	h.mount(container, function () {
		function view() {
			let currentRoute = $.route.tag()
			if (currentRoute != prevRoute) {
				loadRouteComp()
			}
			prevRoute = currentRoute

			if ( currentWrapperRouteComponent ) {
				return h(staticWrapper, {
					attrs: currentAttrs
					,render: currentRender
					,prevRender:prevRenderResolved
					,currentRoute
					,prevRoute
				})
			} else {
				return null
			}
		}
		return { view }
	})

	return currentAttrs
}