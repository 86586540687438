/* global window */
import m from 'bacta'
import css from 'bss'
import renderStream from '../utils/render-stream'
import modalStream from '../utils/modal-stream'

import * as E from './elements'

// let modal = null
let content = []

// For animating out old view gracefully
let prevVTree = null

// When we last cached the prevVTree
let prevVTreeTime = Date.now()

// How long to retain the prevVTree for
// Should be longer than any animations
let MAX_TIME = 2 * 1000

// every render, reset content to null
// so we can handle `false && m(Content, ...)`
renderStream.map( () => content = [] )

export function Portal(){

	function view(){
		let found = content.find(
			x => x
			&& (window.innerWidth > 550 || x.channel < Channel.info )
		)
		if ( found ) {

			found = truncation({found})

			prevVTreeTime = Date.now()
			prevVTree = m('.content'
				+ css`
					display: flex;
					align-content: center;
					align-items: center;
					justify-content: end;
					padding: 0.5em;
					max-width: 50em;

					padding: 1em;
					border-radius: 0.5em 0.5em 0em 0em
					color: #000000a8;
					margin-top: 1em;
					background-color: white;
					box-shadow: 0px 2px 7px 0px rgba(0,0,0,0.1);
					gap: 1em;

					transition: flex-grow 1s;
					flex-grow: 1;

					pointer-events: all;
				`
				.thinTablet`
					margin-bottom: 1em;
					border-radius: 0.5em;
				`
				, found.message && found.icon
				, found.message && m('.message'
					+ css`
						min-width: 0em;
					`
					, found.message
				)
				, found.actions && found.actions.length > 0 && m('.actions'
					+ (found.message ? '.message' : '')
					+ css`
						display: grid;
						grid-auto-flow: column;
						gap: 1em;
						justify-content: end;
					`
					.$nest('&.message', `
						grid-auto-flow: row;
						gap: 1em;
						justify-content: space-between;
					`)
					.thinTablet(`
						grid-auto-flow: column;
					`)
					// todo-james globalize:
					.$nest('.btn:disabled', `
						background-color: hsl(247 5% 81% / 1);
						color: #8a8a8a;
					`)
					, found.actions
				)
			)
		} else if ( !found && prevVTreeTime > Date.now() + MAX_TIME ) {
			prevVTree = null
		}

		// position: fixed
		// makes bottom: 0px work as you'd expect
		// https://developers.google.com/web/updates/2016/12/url-bar-resizing
		return m('.context-bar-portal'
			+ css`
				display: grid;
				justify-content: center;
				position: fixed;
				bottom: 0;
				left: 0;
				min-height: 4em;
				z-index: 2;
				width: 100vw;
				max-width: 100vw;
				overflow: hidden;
				transition: transform 1s, opacity 0.2s;

				pointer-events: none;
			`
			.$nest('.resizing &', `
				opacity: 0;
			`)
			.tablet`
				justify-content: center;
			`
			.$nest('&.open', `
				transform: translateY(0%);
			`)
			.$nest('&.closed', `
				transform: translateY(100%);
			`)
			+ (
				found ? '.open' : '.closed'
			)
			, prevVTree
		)
	}

	return {
		view
	}
}

export const Channel = {

	// most critical to render
	error: 0

	// if no errors, render last warning
	,warn: 1
	,warning: 1

	// if no warnings, render last info
	,info: 2

	// verbose+silly will render if
	// there is nothing else
	// but we may use an elipsis and
	// only show the first part
	// then clicking it will
	// open a modal
	,verbose: 3
	,silly: 4
}

export function Content(){

	function view({ attrs: { message, icon, actions=[], channel=0 }}){
		content[channel] = { message, icon, actions, channel }
		return m('.context-bar-content')
	}

	return {
		view
	}
}

export function info(message, ...actions){
	return m(Content, {
		icon: icon.info()
		, message
		, actions
		, channel: Channel.info
	})
}

export function error(message, ...actions){
	return m(Content, {
		icon: icon.error()
		, message
		, actions
		, channel: Channel.error
	})
}

export function warn(message, ...actions){
	return m(Content, {
		icon: icon.warn()
		, message
		, actions
		, channel: Channel.warn
	})
}


const iconWrapperCSS =
	css`
		min-width: 1.5em;
		display: grid;
		align-items: center;
		align-content: center;
		stroke-width: 1px;
	`

export const icon = {
	error: () => m(''
		+ iconWrapperCSS
		+ '.context-icon'
		+ css`
			color: red;
		`
		, E.icon.stop()
	)
	,info: () => m(''
		+ iconWrapperCSS
		+ '.context-icon'
		, E.icon.info()
	)
	,warn: () => m(''
		+ iconWrapperCSS
		+ '.context-icon'
		+ css`
			color: orange;
		`
		, E.icon.warn()
	)
}

icon.warning = icon.warn
icon.verbose = icon.info
icon.silly = icon.info


function infoModalButton({
	originalMessage
}){
	return m('button.btn.btn-info', {
		onclick: () => {
			modalStream({
				dismissable: true
				, offset: { desktop: { y: '0vh'}, mobile: { y: '0vh' } }
				, content: () => m(''
					+ css`
						background-color: white;
						color: black;
						height: 100%;
						padding: 1em;
						display: grid;
						/* align-content: space-between; */
						grid-template-rows: auto 1fr auto;
						gap: 1em;
					`
					,m(''
						+ css`
							display: grid;
							grid-auto-flow: column;
							/* grid-template-columns: 2em 1fr; */
							gap: 0.5em;
							align-content: center;
							justify-content: center;
							border-bottom: solid 0.5px #EEE;
						`
						.$nest('.context-icon', `
							min-width: 1.9em;
						`)
						, icon.info()
						, m('h1', 'Odin Help')
					)
					,m(''
						+ css`
						font-size: 1.2em;
						display: grid;
						gap: 2em;
						align-content: baseline;
						background-color: #FEFEFE;
						padding: 1em;
						border-radius: 0.5em;
						overflow-y: auto;
						`
						, originalMessage.split('. ').map( x => m('p', x + '.'))
					)
					,m(''
						,m('h4'+css`text-align: center;`, 'More help topics:')
						, m('.links'
							+ css`
								display: grid;
								grid-template-columns: 1fr 1fr;
								gap: 1em;
								font-size: 1.3em;
								padding: 1em;
							`
							, [
								{ name: 'Create a Schedule', href: 'https://harth.io/documentation/create-a-schedule.html' }
								,{ name: 'Create a Project', href: 'https://harth.io/documentation/create-a-project.html' }
								,{ name: 'Create a Resource', href: 'https://harth.io/documentation/create-a-resource.html' }
								,{ name: 'Explore Odin Help', href: 'https://harth.io/documentation' }
							,]
							.map(
								x => m('a', { href:x.href, target: '_blank', rel: 'noopener noreferrer' }, x.name)
							)
						)
					)
					, m('button.btn.btn-info'
						+ css`
							font-size: 1.5em;
						`
						, { onclick: () => window.history.back() }
						, 'Done'
					)
				)
			})
		}
	}, 'Read More')
}

function truncation({ found}){
	let truncate =
		found.channel >= Channel.info
		&& typeof found.message == 'string'

	if( truncate && found.message.length > 160 ) {
		let originalMessage = found.message
		found.message = found.message.slice(0,160 -3) + '...'

		if (found.actions.length == 0) {
			found.actions.push(
				infoModalButton({ originalMessage })
			)
		}
	}

	return found
}